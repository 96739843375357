/* Welcome to Aqa Client*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ToolBar from "@material-ui/core/Toolbar/Toolbar";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
//import HtmlTooltip from "@material-ui/core/Tooltip";
//import IconButton from "@material-ui/core/IconButton";
//import {ReactComponent as SettingsIcon} from "../images/aqa-icons/AQA-Usercol.svg";
import AqaHelp from "./AqaHelp";
import moment from "moment";

const styles = theme => ({
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

class AqaFooter extends React.Component {
    constructor(props) {
        super(props);
        // Assign A ref to state for refresh
        this.state = {
            expanded: false
        };
    }

    toggleExpanded = () => {
// onsole.log("Footer expanded flag toggle");
        var newExpanded = !this.state.expanded;
        this.setState( { expanded: newExpanded } );
    }

/*
    componentDidMount() {

    }
*/

    render() {
        const { classes } = this.props;
        return(
            <React.Fragment>
                <CssBaseline/>
                <ReactNotification />
                <AppBar position="fixed" color="primary" className={classes.appBar}
                        style={{backgroundColor: "#2A4E9F",
                            maxHeight: (this.state.expanded ? 300: 36) }}>

                    <ToolBar style={{minHeight:36}}>
                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                              style={{padding: 0, marginTop: 0}}>

                            <Grid item xs={10} sm={10} lg={10} xl={10}>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem"}}>
                                    &copy; {moment().format("YYYY")} Aqaversant Ltd All rights reserved
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} lg={2} xl={2}>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"right",fontSize:"0.8rem"}}>
                                    {/*<HtmlTooltip title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Click to provide feedback</Typography>
                                        </div>
                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <IconButton className="aqa-button" style={{marginTop:"6px"}} onClick={(e)=>window.open("https://aqa.ideas.aha.io","_blank")}>
                                            <div className="aqa-icon">
                                                <SettingsIcon width="30px" />
                                            </div>
                                            <Typography className={"aqa-header-button-text"} variant="inherit" >
                                                FEEDBACK
                                            </Typography>
                                        </IconButton>
                                    </HtmlTooltip>*/}
                                    <AqaHelp dataFromRoot={this.props.dataFromRoot} dataFromMain={this.props.dataFromMain} chatRef={this.props.chatRef} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </ToolBar>
                </AppBar>
            </React.Fragment>
        )
    }
}

AqaFooter.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaFooter);
