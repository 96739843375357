/* Welcome to Aqa*/
/* Version: 1 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {ReactComponent as SearchIcon} from '../../images/aqa-icons/Searchcol.svg';

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaSourceSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            periodicities: [],
            periodicity: "daily",
            SourceName: "",
            SourceDescription: null,
            errorMessage: ""

        };

    }

    handleSearchChange(evt){
        //alert(evt.target.value);
        var keyword = evt.target.value;
        this.setState({"keyword": keyword});
        this.props.dataFromParent.handleSearch(keyword);
    }
    componentDidMount(){

    }
    render() {
        //const { classes } = this.props;
        const { error, isLoaded, redirect, notfound } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: "-8px",marginLeft:"10px"}}>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <div className={"aqa-action-search"}>
                            <TextField
                                id="Search"
                                //onChange={this.handleTextChange('guest')}

                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <div className="aqa-search-icon" width="24px" style={{paddingLeft:"3px",paddingTop:"3px"}}>
                                            <SearchIcon width="24px"/>
                                            </div>
                                        </InputAdornment>
                                    ),
                                    disableUnderline:true
                                }}
                                variant="standard"
                                multiline={false}
                                margin="normal"
                                rows={1}
                                style={{ border:"none !important",padding:"0px",margin:"0px",width:"100%"}}
                                onChange={this.handleSearchChange.bind(this)}
                                required
                            />
                            </div>
                        </Grid>

                    </Grid>


                </div>
            );
        }


    }
}

AqaSourceSearch.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSourceSearch);

