/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import
{
    Card,
    CardActions,
    CardContent,
    CardHeader, Checkbox, DialogActions, FormControlLabel, FormGroup,
    LinearProgress, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core"
import AqaComponent from "../shared/aqacomponent/AqaComponent"
import ColourAndOverrideMaps from "../../model/ColourAndOverrideMaps"
import Logo from "../../images/aqaversant-blue.png"
//import AqaSnapshotGridRow from "./AqaSnapshotGridRow";
import AqaSnapshotHeader from "./AqaSnapshotHeader"
import Heatmap from "../shared/heatmap/Heatmap"
// import SnapshotColumnsHeatmap from "../../model/SnapshotColumnsHeatmaps";
import AqaTable from "../../model/AqaTable";
import {
    ReactComponent as SnapshotIcon
} from "../../images/aqa-icons/AQA-Snapshot - v2.svg"
import IconButton from "@material-ui/core/IconButton"
import moment from "moment"
import {SMALL_24_ASPECT_ICONS as QD_ICONS} from "../shared/AvaAspectIcons"
import {ReactComponent as RulesIcon} from "../../images/aqa-icons/AQA-Rules.svg"
import AqaSnapshotDetailTable from "../snapshotdetail/AqaSnapshotDetailTable"
import HtmlTooltip from "@material-ui/core/Tooltip"
import {ReactComponent as HelpIcon} from "../../images/aqa-icons/HelpOutline2.svg"
import {ReactComponent as FixIcon} from "../../images/aqa-icons/Repair Outline.svg"
import {ReactComponent as EditIcon} from "../../images/aqa-icons/AQA-Edit.svg"
import {ReactComponent as IgnoreIcon} from "../../images/aqa-icons/CheckOutline2.svg"
import RowArrayCachingObject         from "../../model/RowArrayCachingObject"
import {ReactComponent as LoadingIcon} from "../../images/grid.svg"
import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"
import DialogTitle from "@material-ui/core/DialogTitle";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg"
import DialogContent from "@material-ui/core/DialogContent"
import Dialog from "@material-ui/core/Dialog"
import TextField from "@material-ui/core/TextField"
//import Button from "@material-ui/core/Button";
import Paper, { PaperProps } from "@material-ui/core/Paper"
import Draggable from "react-draggable"
import {animateScroll} from "react-scroll";
import base64 from "base-64";

function PaperComponent(props: PaperProps) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const styles = theme => ({
    root:{
        maxWidth: 345,
        margin: "8px",
    },
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Montserrat !important',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    },
    root1:{
        padding:'2px 18px'
    },
    message:{
        padding:"4px 0",
        marginTop:"8px"
    },
    paper:{
        margin:0
    },
    snackbar: {
        [theme.breakpoints.down('xs')]: {
            top: 2,
        },
        [theme.breakpoints.down('sm')]: {
            top: 2,
        },
        [theme.breakpoints.up('lg')]: {
            top: 2,
        },
        fontFamily:"Montserrat",
        padding:0,
        margin:1

    },
    colorPrimary: {
        backgroundColor: 'rgb(201, 206, 234)',
    },
    barColorPrimary: {
        backgroundColor: 'red',
    },
    colorSecondary: {
        backgroundColor: 'rgb(201, 206, 234)',
    },
    barColorSecondary: {
        backgroundColor: 'rgb(251, 154, 40)',
    },
    colorTerritary: {
        backgroundColor: 'rgb(201, 206, 234)',
    },
    barColorTerritary: {
        backgroundColor: 'green',
    }
});


const DELAY_BETWWEN_PROGRESS_UPDATE_REQUSTS = 500;


class AqaSnapshotOverview extends AqaComponent {
    constructor(props) {
        super(props);
        props.dataFromRoot.registerSnapshot(this);
        this.state =
        {
            error: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentSnapshot:"allsources",
            percentage: 100,
            scoreData:{},
            lastScore:-1,
            lastScores:[],
            tableData:[{"id":"Uniqueness","name":"Uniqueness"},{"id":"Populated","name":"Populated"},{"id":"String","name":"String"},{"id":"Number","name":"Number"},{"id":"Date","name":"Date"},{"id":"Flag","name":"Flag"}],
            savedRules:[],
            abnormalColumns:[],
            abnormalCorrectedColumns:[],
            columnmap:null,
            selectedStat:null,
            stats:[],
            reviewStats:[],
            healthStats:[],
            verticalHealthStats:[
                {id:"Table_Health_Stat_0",name:"Alert Type",description:"List of Alert Types",numR:"Amber",numI:"Red",numF:"Health"},
                {id:"Table_Health_Stat_1",name:"Missing?",description:"Does the data contain any cells that have missing data?",numR:0,numI:0,numF:-1},
                {id:"Table_Health_Stat_2",name:"Duplicate?",description:"For columns containing alpha-numeric data, are any values duplicated?",numR:0,numI:0,numF:-1},
                {id:"Table_Health_Stat_3",name:"Wrong Type?",description:"If a column contains data of one type, are there any values in any cells of a different type?",numR:0,numI:0,numF:-1},
                {id:"Table_Health_Stat_4",name:"Bad Format?",description:"If there are columns with specific format types, are there any cells that do not conform?",numR:0,numI:0,numF:-1},
                {id:"Table_Health_Stat_5",name:"Outlier?",description:"In specific columns, do any cells contain values significantly outside of the range of the data contained in the other cells?",numR:0,numI:0,numF:-1},
                {id:"Table_Health_Stat_6",name:"Invalid?",description:"In columns with a specific set of repeated values, are there any cells that look different?",numR:0,numI:0,numF:-1},
                {id:"Table_Health_Stat_7",name:"Excel Error?",description:"Are there any cells that contain native excel errors?",numR:0,numI:0,numF:-1},
            ],
            isReady:false,
            isPostLoading:false,
            isPendingLoading:false,
            progress:5,
            listProgress:100,
            showReview:false,
            uploadStatus:"File Upload in progress",
            heatmapData:null,
            columnData:[],
            headerData:[],
            isOverallSelected:false,
            selectedColumn:null,
            selectedColumnId:null,
            selectedColumnIndex:-1,
            sColumnId:-1,
            selectedColumnFilterRows:[],
            selectedColumnStatus:"",
            isBulkOn:false,
            isBulkUpdateOn:false,
            selectedColor:"green",
            selectedType:"",
            finerGrain:-1,
            isAmber:false,
            isRed:false,
            isGreen:false,
            isDataViewLoaded:false,
            selectedRow:null,
            selectedReviewType:-1,
            isDataLoading:false,
            isWaiting:false,
            isSnackOpen:false,
            isDataReady:true,
            foundSheets:[],
            sheetData:[],
            sheetSelectedIndex:-1,
            openSheetSelection:false,
            openSuggestions:false,
            notes:"",
            suggestedValue:"",
            whichStatus:0,
            errorMessage:".",
            allAmber:0,
            allRed:0,
            notifiedCount:0,
            alertReviewComplete:false,
            reviewComplete:false,
            showReviewPop:false,
            showPreviewReport:false,
            previewReport:"",
            recomputing:false,
            loadingLogs:[],
            customMessages:[
                {id:1,message:"Uploading file - ",step:1},
                {id:2,message:"Reading file data",step:2},
                {id:3,message:"Computing file statistics",step:3},
                {id:4,message:"Processed - ",step:4},
                {id:5,message:"Storing file anlaysis",step:5},
                {id:6,message:"Generating suggested checks",step:6},
                {id:7,message:"Storing check definitions",step:7},
                {id:8,message:"Processing alert counts",step:8},
                {id:9,message:"Calculating alert counts",step:9},
                {id:10,message:"Alert count completed",step:10},
                {id:11,message:"Generating heatmap",step:11},
                {id:12,message:"Checking for empty columns",step:12},
                {id:13,message:"File analysis completed",step:13}
            ]
        };
        
//			this.heatmapData = null;
        this.data=null;
        this.dataAnalyser=null;
        this.rowCacheObject = {};
        this.numberOfCells = [0,0];
        this.dataMappings = [{},{}];
        this.personCache={};
        this.handleSource = this.handleSource.bind(this);
        this.handleGetRulesForSource=this.handleGetRulesForSource.bind(this);
//        this.handleGetColumnHeatmap=this.handleGetColumnHeatmap.bind(this);
        this.handleGetRowsColumns=this.handleGetRowsColumns.bind(this);
        this.handleSnapshotProgress=this.handleSnapshotProgress.bind(this);
        this.handleSnapshot=this.handleSnapshot.bind(this);
        this.handleSnapshotData=this.handleSnapshotData.bind(this);
        this.handleSnapshotRow=this.handleSnapshotRow.bind(this);
    }

    registerDataViewer(dataViewer)                           { this.dataAnalyser = dataViewer; }
    waitCursor() { document.body.style.cursor = "wait"; }
    normalCursor() { document.body.style.cursor = "default"; }

    handleResetLoadingLogs = ()=>{
        this.setState({loadingLogs:[]});
    }

    handleReviewClose = () =>{
        this.setState({reviewComplete:false,showPreviewReport:false});
    }

    handleReviewClosePop = (e) =>{
        e.stopPropagation();
        this.setState({reviewComplete:true,showPreviewReport:false});
    }

    handleReviewOpen = () =>{
        this.setState({reviewComplete:true});
    }

    handleShare = () =>{
        var mainHeader = this.props.dataFromParent;
        if(mainHeader!==undefined) {
            let submainHeader = mainHeader.getSubComponents();
            if(submainHeader.length>0) {
                submainHeader[submainHeader.length-1].handleShowShare(true);
                this.setState({reviewComplete:false});
            }
        }
    }

    handleResWithUsername(res,username){
        var user={username:username};
        res = {...res,...user};
        var reskey = base64.encode(JSON.stringify(res));
        return reskey;
    }

    handlePreviewReport = () =>{
        this.waitCursor();
        if(this.state.showPreviewReport ){
            var customNotification = {
                title: "Cannot Share", titleIcon: "",
                message: "There are no Alerts reviewed as Must Fix to share",
                isCustom: false, type: "info",
                insert: "center", position: "center", autoClose: true, duration: 5000
            };
            this.removeAllNotifications();
            this.showNotification(null, customNotification);
        }
        else {
            this.setState({previewReport: "", showPreviewReport: false});
            var selectedSource = this.props.dataFromSource;
            var selectedSnapshot = this.props.dataFromGrid;
            //var selectedSnapshotId = this.props.dataFromGrid.id;
            var to = this.props.dataFromMainFrame.props.parent.state.user.email;
            //let res = {nav: 2, fid: "", scid: selectedSource.id, snid: selectedSnapshot.id};
            //let reskey = base64.encode(JSON.stringify(res));
            var slr = {
                to: to,
                password: "",
                firstname: "",
                lastname: "",
                permissions: "V",
                urlLink: "res=link",
                message: "",
                resourceType: "Data Upload",
                sourceId: (selectedSource !== null ? selectedSource.id : ""),
                snapshotId: (selectedSnapshot !== null ? selectedSnapshot.id : ""),
                snapshotName: (selectedSnapshot !== null ? selectedSnapshot.label : ""),
                snapshotDate: (selectedSnapshot !== null ? moment(selectedSnapshot.dateCreated).format("YYYY/MM/DD HH:mm") : "")
            };
            AqaComponent.userBackend.shareLinkPreviewUsingPOST(
                slr,
                (error, data, response) => {
                    if (error) {
                        var responseMessage = JSON.parse(response.text);
                        if (responseMessage.message !== "" && response.status === 400) {
                            if (responseMessage.message === "No violations to share") {
                                this.setState({
                                    errorMessage: "Violations are not marked as Must Fix, Unable to share report",
                                    hasError: true,
                                    openConfirmMessage: true,
                                    openMessage: false
                                });
                            } else {
                                this.setState({errorMessage: "Email address already in use", hasError: true});
                            }
                        } else {
                            this.reportError("A problem posting the share link from the server was encountered.",
                                "AqaSnapshotGetLink.handleShareLink, backend call: " + error, this);
                        }
                    } else {
                        var message = data.message;
                        message = message.replace("cid:aqaversantLogo", Logo);
                        message = message.replace("Click here to access this data directly in AQA", "Action");
                        message = message.replace("http", "#http");
                        message = message.replace(/(<? *script)/gi, 'illegalscript')
                        this.setState({showPreviewReport: true, previewReport: message});
                        const container = document.getElementById('render-report');
                        container.innerHTML = message;
                        this.setState({reviewComplete:false});
                        this.normalCursor();
                    }
                }
            )
        }
    }


    handleSource(source){
        this.props.dataFromMain.handleSource(source);
        this.props.dataFromMain.props.dataFromRoot.navigation(2);
    }

    scrollToBottom = () => {
        animateScroll.scrollToBottom({
            containerId: "logs",
            smooth:true,
            offset: 50,
            delay:100,
            duration:300
        });
    }

    setRulesEnabled = (e, column)=>{
        let columnRule = null;
        let rules = this.state.savedRules;
        if(rules.length!==null){
            columnRule = rules.columnQualities[column.index];
        }
        let abnormalColumns = [];
        if(e === false){
            for(let i=0;i<this.state.abnormalCorrectedColumns.length;i++){
                if(this.state.abnormalCorrectedColumns[i] !== column.index){
                    abnormalColumns.push(this.state.abnormalCorrectedColumns[i])
                }
            }
        }
        else{
            abnormalColumns = this.state.abnormalCorrectedColumns;
            abnormalColumns.push(column.index);
        }
        this.waitCursor();
        this.setState({showReview:false});
        if(columnRule !== null && columnRule !== undefined) this.handleModalSaveRules(columnRule,e,()=>{
            this.setState({abnormalCorrectedColumns:abnormalColumns,recomputing:true});
            ColourAndOverrideMaps.clear(this.state.latestSnapshot.id);
            this.setState({isReady:false,isPostLoading:true,uploadStatus:"Re-calculating alerts",progress:100});
            this.handleSnapshotScores(this.props.dataFromSource.id,this.state.latestSnapshot);
        });
    }

    handleModalSaveRules(rules,value,callBackAfter)
    {


//		const authCode = this.props.sourceDetailObject.props.dataFromRoot.props.parent.state.authCode;
        var authCode = this.props.dataFromMain.state.authCode;
        if (authCode === "V")
        {
            const adminEmail = this.props.dataFromMain.state.adminEmail;
            let context; // This should be... what?? @Vamsi

            this.removeAllNotifications();
            this.showNotification(4, null, context , null, adminEmail);
        }
        else
        {
            // To make a deep copy - in modal mode we don't care about preserving the origin object as we will close the popup
            // UNLESS an error occurs I guess and we want to go back to the display
            let jsonObj = AqaComponent.conflate(rules);



            jsonObj.qualityEnabled = true;
            jsonObj.populatedEnabled=value;

            AqaComponent.backend.createVectorQualityDefinitionUsingPOST
            (
                jsonObj,
                (error, data, response) =>
                {

                    if (error) this.reportError
                    (
                        "A problem was encountered when updating check definitions.",
                        "AqaRulesParent.handleModalSaveRules, call: " + error
                    );
                    else
                    {
                        if (callBackAfter) callBackAfter();
                        else
                        {
                            //this.props.parent.handleColumnUpdate(jsonObj);
//							this.props.rulesObject.handleRulesModified(this.props.dataForCol, jsonObj);
                        }
                    }
                }
            );
        }
    } // handleModalSaveRules

    findUsageStat = (d) =>{
        let usage = 0;
        if(this.state.statement !== undefined) {
            let blockSize = this.state.statement.blockSize;
            let activities = this.state.statement.activities;
            for(let i=0;i<activities.length;i++){
                let activity = activities[i][`sourceActivity`];
                if(activity.sourceId === d){
                    usage = (activity.numberOfCumulatedRows/blockSize).toFixed(2);

                }
            }
        }
        return usage;}

    handleAccountDetails(){
//        var accountId=this.props.dataFromRoot.props.parent.state.accountId;
        var authCode = this.props.dataFromMain.state.authCode;
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    this.setState({accountObj:accountObj});
                    if(authCode === "A") this.handleStatement(accountObj);
                }
            }
        );
    }

    handleStatement(accountObj){
        AqaComponent.billingBackend.getCurrentStatementUsingGET(
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the plans from the server was encountered.",
                    "AqaAccountOverview.handleAllPlans, backend call: " + error,this);
                else {
                    this.setState({statement:data});
                    let blocksAllocated = 0;
                    let blocksUsed = 0;
                    let blockSize = data.blockSize;
                    //let currency = accountObj.currencyCode;
                    //let planWithPrices = data.planWithPrices.prices[currency][0];
                    //if(planWithPrices !== null && planWithPrices !== undefined) blocksAllocated = planWithPrices.quantityUpTo;
                    for(let i=0;i<data.activities.length;i++){
                        let activity = data.activities[i].sourceActivity;
                        blocksUsed = blocksUsed + Math.floor(activity.numberOfCumulatedRows/blockSize);
                    }
                    this.setState({blocksAllocated:blocksAllocated,blocksUsed:blocksUsed});
                }
            }
        );
    }

    handleHistoricalScores(snapshots){
        var score = null;
        var lastScore = null;
        var rag = null;
        var diff="";
        var lastScores = [["Last","Score",{role:'string'},{role:'string'},{role:'string'},{role:'string'}]];
        var k = 6;
        for (var i = 1; i < 7; i++) {
            var snapshot = snapshots[k - i];
            if (snapshot === null || snapshot===undefined) {
                lastScores.push([i, null, null,null,null,null]);
            } else {
                var snapshotObjects = this.props.dataFromParent.state.snapshots;
                var snapshotObject = null;
                for (var l = 0; l < snapshotObjects.length; l++) {
                    var snapshotObj = snapshotObjects[l];
                    if (snapshotObj.id === snapshot.id) {
                        snapshotObject = snapshotObj;
                    }
                }
                var dateCreated = null;
                if(snapshotObject !== null ){
                    dateCreated = snapshotObject.dateCreated;
                }
                lastScores.push([i, snapshot.score, snapshot.rag,snapshot.totalNumberOfReds,snapshot.totalNumberOfAmbers,(dateCreated !== null)? (moment(dateCreated ).format("YYYY/MM/DD HH:mm")):("")])
            }
        }
        var latestScoreObj = lastScores[k];
        var lastScoreObj = lastScores[k-1];
        score=latestScoreObj[1];
        rag=latestScoreObj[2];
        lastScore=lastScoreObj[1];
        if(score === undefined || lastScore === undefined){
            diff = "";
        }
        else if(score === null && lastScore === null){
            diff = "";
        }
        else if(score === null && lastScore !== null){
            diff = -(Math.round(lastScore));
        }
        else if(score !== null && lastScore === null){
            if(score <0){
                diff=""
            }
            else {
                diff = Math.round(score);
            }
        }
        else {
            diff = score - lastScore;
            diff = Math.round(diff);
        }
        this.setState({score:score.toFixed(2),lastScore:lastScore,rag:rag,diff:diff,lastScores:lastScores});
    }

    handleHistoricalData(typeArgs,snapshots){
        if(typeArgs.length < 2) return {};
        var score = null;
        var lastScore = null;
        var rag = null;
        var diff="";
        var lastScores = [["Last","Score",{role:'string'},{role:'string'},{role:'string'},{role:'string'},{role:'string'}]];
        var k = 6;
        //var scoreName=typeArgs[0];
        var ragName=typeArgs[1];
        var numberRulesName = typeArgs[2];
        var numberRedsName = typeArgs[3];
        var numberAmbersName = typeArgs[4];
        var orthIndex = typeArgs[5];
        var numberRules = 0;
        var numberReds = 0;
        var numberAmbers = 0;
        var numberDiscrete = 0;
        var numberTotal = 0;
        var redPercent = 0;
        var amberPercent = 0;
        var totalPercent = 0;
        var rows = 0;
        for (var i = 1; i < 7; i++) {
            var snapshot = snapshots===undefined?null:snapshots[k - i];
            if (snapshot === null || snapshot===undefined) {
                lastScores.push([i, null, null,null,null,null,null]);
            } else {
                if(i === 6){
                    numberRules=snapshot[numberRulesName];
                    numberReds=snapshot[numberRedsName];
                    numberAmbers=snapshot[numberAmbersName];
                    if(numberRules === undefined){numberRules=0}
                    if(numberReds === undefined){numberReds=0}
                    if(numberAmbers === undefined){numberAmbers=0}

                }
                var snapshotObjects = this.props.dataFromParent.state.snapshots;
                var snapshotObject = null;
                for (var l = 0; l < snapshotObjects.length; l++) {
                    var snapshotObj = snapshotObjects[l];
                    if (snapshotObj.id === snapshot.id) {
                        snapshotObject = snapshotObj;
                    }
                }
                var dateCreated = null;
                if(snapshotObject !== null ){
                    dateCreated = snapshotObject.dateCreated;

                    rows = snapshot["numberOfRows"]!==undefined?snapshot["numberOfRows"]:this.state.numberOfRows;
                    if(orthIndex<7){
                        numberReds = snapshot["numberOfOrthogonalVectorsWithErrors"][0][orthIndex][0];
                        numberAmbers = snapshot["numberOfOrthogonalVectorsWithErrors"][0][orthIndex][1];
                        numberDiscrete = snapshot["numberOfOrthogonalVectorsWithErrors"][0][orthIndex][2];
                        rows = snapshot["numberOfRows"]!==undefined?snapshot["numberOfRows"]:this.state.numberOfRows;
                        numberTotal = numberReds+numberAmbers-numberDiscrete;
                        redPercent = (numberReds/rows)*100;
                        amberPercent = (numberAmbers/rows)*100;
                        totalPercent = (numberTotal/rows)*100;
                    }
                    else{
                        numberReds=snapshot[numberRedsName];
                        numberAmbers=snapshot[numberAmbersName];
                    }
                }
                lastScores.push([i, totalPercent, snapshot[ragName],numberReds,numberAmbers,(dateCreated !== null)? (moment(dateCreated ).format("YYYY/MM/DD HH:mm")):(""),rows])
            }
        }
        var latestScoreObj = lastScores[k];
        var lastScoreObj = lastScores[k-1];
        score=latestScoreObj[1];
        rag=latestScoreObj[2];
        lastScore=lastScoreObj[1];
        if(score === undefined || lastScore === undefined){
            diff = "";
        }
        else if(score === null && lastScore === null){
            diff = "";
        }
        else if(score === null && lastScore !== null){
            diff = -(Math.round(lastScore));
        }
        else if(score !== null && lastScore === null){
            if(score <0){
                diff=""
            }
            else {
                diff = Math.round(score);
            }
        }
        else {
            diff = score - lastScore;
            diff = Math.round(diff);
        }
        return {score:score!==null?score.toFixed(2):0,lastScore:lastScore,rag:rag,diff:diff,lastScores:lastScores,numberRules:numberRules,numberReds:numberReds,numberAmbers:numberAmbers,numberTotal:numberTotal,redPercent:redPercent,amberPercent:amberPercent,totalPercent:totalPercent,rows:rows};
    }

	handleTableTypes(snapshot, savedRules1, nColumns, nRows) {

        let rule;
//            tableData:[{"id":"Uniqueness","name":"Uniqueness"},{"id":"Populated","name":"Populated"},{"id":"String","name":"String"},{"id":"Number","name":"Number"},{"id":"Date","name":"Date"},{"id":"Flag","name":"Flag"}],


        var tableData =
            [
                {"id": "Dimension", "name": "Dimension", "index": -1},
                {"id": "Uniqueness", "name": "Uniqueness", "index": 0},
                {"id": "Populated", "name": "Populated", "index": 1},
                {"id": "String", "name": "String", "index": 2},
                {"id": "Number", "name": "Number", "index": 3},
                {"id": "Date", "name": "Date", "index": 4}
            ];


        var tableSize = tableData.length;
        var savedRules = savedRules1.columnQualities;
        var tableTypes = [];
//        var map = null;

        let mapType;
        var numberOfRows = 0;
        var numberOfColumns = 0;
        for (var i = 0; i < tableSize; i++) {
            var tableObj = tableData[i];
            var typeArgs = [];
            //var typeStats=[];
            var numRules = 0;
            //var numReds=0;
            //var numAmbers=0;
            var firstColumn = null;

            if (tableObj.name === "Dimension") {
                typeArgs.push("tableScore");
                typeArgs.push("tableRag");
                typeArgs.push("numberOfTableQualityDefinitions");
                typeArgs.push("numberOfTableReds");
                typeArgs.push("numberOfTableAmbers");
                typeArgs.push(8);
                numberOfRows = nRows;
                numberOfColumns = nColumns;
            } else if (tableObj.name === "String") {
                typeArgs.push("stringScore");
                typeArgs.push("stringRag");
                typeArgs.push("numberOfStringQualityDefinitions");
                typeArgs.push("numberOfStringReds");
                typeArgs.push("numberOfStringAmbers");
                typeArgs.push(5);
                for (var k = 0; k < savedRules.length; k++) {
                    rule = savedRules[k];
                    if (rule.stringEnabled === true && (rule.useStringLengths === true || rule.useAllowedValues === true)) {
                        numRules = numRules + 1;
                        if (firstColumn === null) {
                            firstColumn = rule
                        }
                    }
                }
//                map=maps[4];

                mapType = Heatmap.STRING_HEATMAP_NAME;
            } else if (tableObj.name === "Number") {
                typeArgs.push("numberScore");
                typeArgs.push("numberRag");
                typeArgs.push("numberOfNumberQualityDefinitions");
                typeArgs.push("numberOfNumberReds");
                typeArgs.push("numberOfNumberAmbers");
                typeArgs.push(3);
                for (let k = 0; k < savedRules.length; k++) {
                    rule = savedRules[k];
                    if (rule.numberEnabled === true && (rule.useNumberFormats === true || rule.useNumberBoundaries === true)) {
                        numRules = numRules + 1;
                        if (firstColumn === null) {
                            firstColumn = rule
                        }
                    }
                }
//              map=maps[2];

                mapType = Heatmap.NUMBER_HEATMAP_NAME;
            } else if (tableObj.name === "Date") {
                typeArgs.push("dateScore");
                typeArgs.push("dateRag");
                typeArgs.push("numberOfDateQualityDefinitions");
                typeArgs.push("numberOfDateReds");
                typeArgs.push("numberOfDateAmbers");
                typeArgs.push(4);
                for (let k = 0; k < savedRules.length; k++) {
                    rule = savedRules[k];
                    if (rule.dateEnabled === true && (rule.useDateFormats === true || rule.useDateBoundaries === true)) {
                        numRules = numRules + 1;
                        if (firstColumn === null) {
                            firstColumn = rule
                        }
                    }
                }
//                map=maps[3];

                mapType = Heatmap.DATE_HEATMAP_NAME;
            } else if (tableObj.name === "Populated") {
                typeArgs.push("populationScore");
                typeArgs.push("populationRag");
                typeArgs.push("numberOfPopulationQualityDefinitions");
                typeArgs.push("numberOfPopulationReds");
                typeArgs.push("numberOfPopulationAmbers");
                typeArgs.push(1);
                for (let k = 0; k < savedRules.length; k++) {
                    rule = savedRules[k];
                    if (rule.populatedEnabled === true) {
                        numRules = numRules + 1;
                        if (firstColumn === null) {
                            firstColumn = rule
                        }
                    }
                }


//              map = maps[0];


                mapType = Heatmap.POPULATION_HEATMAP_NAME;


            } else if (tableObj.name === "Uniqueness") {
                typeArgs.push("uniquenessScore");
                typeArgs.push("uniquenessRag");
                typeArgs.push("numberOfUniquenessQualityDefinitions");
                typeArgs.push("numberOfUniquenessReds");
                typeArgs.push("numberOfUniquenessAmbers");
                typeArgs.push(2);
                for (let k = 0; k < savedRules.length; k++) {
                    rule = savedRules[k];
                    if (rule.uniquenessEnabled === true) {
                        numRules = numRules + 1;
                        if (firstColumn === null) {
                            firstColumn = rule
                        }
                    }
                }
//              map = maps[1];

                mapType = Heatmap.UNIQUENESS_HEATMAP_NAME;

            } else {
                // Do nothing
                mapType = null;
            }
            var history = this.handleHistoricalData(typeArgs, snapshot.snapshotScores);
            var stats =
                {
                stats:
                    [
                        {id: "Table_Aspect_Stat_0", name: "", numRules: "#", percentage: "%"},
                        {
                            id: "Table_Aspect_Stat_1",
                            name: "# Red Errors!",
                            numRules: history.numberReds,
                            percentage: history.redPercent.toFixed(2)
                        },
                        {
                            id: "Table_Aspect_Stat_2",
                            name: "# Amber Errors!",
                            numRules: history.numberAmbers,
                            percentage: history.amberPercent.toFixed(2)
                        },
                        {id: "Table_Aspect_Stat_3", name: "# Rows", numRules: history.rows, percentage: ""}
                    ],
                firstColumn: firstColumn,
//                columnmap:map,
                heatmapType: mapType,
                numberOfRows,
                numberOfColumns
            };

            var table = {...tableObj, ...history};
            table = {...table, ...stats};
            tableTypes.push(table);
        }


        //var numOverallRules=0;
        var numOverallReds = 0;
        var numOverallAmbers = 0;
        var numOverallDiscrete = 0;
        let totalErrors = 0;
        let redPercent = 0;
        let amberPercent = 0;
        //let totalPercent=0;
        let rows = 0;
        let cols = 0;
        let numPendingAmber = 0;
        let numIgnoreAmber = 0;
        let numFixAmber = 0;
        let numPendingRed = 0;
        let numIgnoreRed = 0;
        let numFixRed = 0;
        let numWhiteFixRed = 0;
        let populatedAmber = 0;
        let populatedRed = 0;
        let uniquenessAmber = 0;
        let uniquenessRed = 0;
        let nativeAmber = 0;
        let nativeRed = 0;
        let typeAmber = 0;
        let typeRed = 0;
        let formatAmber = 0;
        let formatRed = 0;
        let valueAmber = 0;
        let valueRed = 0;
        let valueAllowedAmber = 0;
        let valueAllowedRed = 0;
        let populatedRS = 0;
        let uniquenessRS = 0;
        let typeRS = 0;
        let formatRS = 0;
        let valueRS = 0;
        let valueAllowedRS = 0;
        let nativeRS = 0;
        let populatedAS = 0;
        let uniquenessAS = 0;
        let typeAS = 0;
        let formatAS = 0;
        let valueAS = 0;
        let valueAllowedAS = 0;
        let nativeAS = 0;
        let populatedROS = 0;
        let uniquenessROS = 0;
        let typeROS = 0;
        let formatROS = 0;
        let valueROS = 0;
        let valueAllowedROS = 0;
        let nativeROS = 0;
        let populatedAOS = 0;
        let uniquenessAOS = 0;
        let typeAOS = 0;
        let formatAOS = 0;
        let valueAOS = 0;
        let valueAllowedAOS = 0;
        let nativeAOS = 0;
        let populatedRag = 2;
        let uniquenessRag = 2;
        let typeRag = 2;
        let formatRag = 2;
        let valueRag = 2;
        let valueAllowedRag = 2;
        let nativeRag = 2;
        let allAmber=0;
        let allRed=0;
        let abnormalColumns=[];
        let abnormalCorrectedColumns=[];
        if (snapshot.snapshotScores!==undefined && snapshot.snapshotScores.length > 0) {
            var latestScoreObj = snapshot.snapshotScores[0];
            numOverallReds = latestScoreObj.numberOfOrthogonalVectorsWithErrors[0][0][0];
            numOverallAmbers = latestScoreObj.numberOfOrthogonalVectorsWithErrors[0][0][1];
            numOverallDiscrete = latestScoreObj.numberOfOrthogonalVectorsWithErrors[0][0][2];
            rows = latestScoreObj.numberOfRows !== undefined ? latestScoreObj.numberOfRows : this.state.numberOfRows;
            cols = latestScoreObj.numberOfColumns !== undefined ? latestScoreObj.numberOfColumns : this.state.numberOfCols;
            totalErrors = numOverallReds + numOverallAmbers - numOverallDiscrete;
            redPercent = (numOverallReds / rows) * 100;
            amberPercent = (numOverallAmbers / rows) * 100;
            //totalPercent = (totalErrors/rows) * 100;

            // Health stats to go here

            populatedAmber = latestScoreObj[`numberOfDiscretePopulationAmbers`];
            populatedRed = latestScoreObj[`numberOfDiscretePopulationReds`];
            uniquenessAmber = latestScoreObj[`numberOfDiscreteUniquenessAmbers`];
            uniquenessRed = latestScoreObj[`numberOfDiscreteUniquenessReds`];
            nativeAmber = latestScoreObj[`numberOfDiscreteNativeErrorAmbers`];
            nativeRed = latestScoreObj[`numberOfDiscreteNativeErrorReds`];
            typeAmber = latestScoreObj[`numberOfDiscreteTypeAmbers`];
            typeRed = latestScoreObj[`numberOfDiscreteTypeReds`];
            formatAmber = latestScoreObj[`numberOfDiscreteStringFormatAmbers`] + latestScoreObj[`numberOfDiscreteNumberFormatAmbers`] + latestScoreObj[`numberOfDiscreteDateFormatAmbers`];
            formatRed = latestScoreObj[`numberOfDiscreteStringFormatReds`] + latestScoreObj[`numberOfDiscreteNumberFormatReds`] + latestScoreObj[`numberOfDiscreteDateFormatReds`];
            valueAmber = latestScoreObj[`numberOfDiscreteStringBoundaryAmbers`] + latestScoreObj[`numberOfDiscreteNumberBoundaryAmbers`] + latestScoreObj[`numberOfDiscreteDateBoundaryAmbers`];
            valueRed = latestScoreObj[`numberOfDiscreteStringBoundaryReds`] + latestScoreObj[`numberOfDiscreteNumberBoundaryReds`] + latestScoreObj[`numberOfDiscreteDateBoundaryReds`];
            valueAllowedAmber = latestScoreObj[`numberOfDiscreteStringAllowedAmbers`];
            valueAllowedRed = latestScoreObj[`numberOfDiscreteStringAllowedReds`];
            populatedRag = populatedRed>0?0:(populatedAmber>0?1:2);
            uniquenessRag = uniquenessRed>0?0:(uniquenessAmber>0?1:2);
            typeRag = typeRed>0?0:(typeAmber>0?1:2);
            formatRag = formatRed>0?0:(formatAmber>0?1:2);
            valueRag = valueRed>0?0:(valueAmber>0?1:2);
            valueAllowedRag = valueAllowedRed>0?0:(valueAllowedAmber>0?1:2);
            nativeRag = nativeRed>0?0:(nativeAmber>0?1:2);
            allAmber = populatedAmber + uniquenessAmber + typeAmber + formatAmber + valueAmber + valueAllowedAmber + nativeAmber;
            allRed = populatedRed + uniquenessRed + typeRed + formatRed + valueRed + valueAllowedRed + nativeRed;

            // Row Stats to go here
            if (snapshot.snapshotReviewStats !== undefined) {
                let rStats = snapshot.snapshotReviewStats;
                let cStats = snapshot.correctedReviewStats;
                numWhiteFixRed = cStats[`totalNumberOfRowsConcerned`];
                numPendingAmber = rStats[`numberOfAmberPendings`];
                numPendingRed = rStats[`numberOfRedPendings`];
                numIgnoreAmber = rStats[`numberOfAmberIgnores`];
                numIgnoreRed = rStats[`numberOfRedIgnores`];
                numFixAmber = rStats[`numberOfAmberFixers`];
                numFixRed = rStats[`numberOfRedFixers`];
                numFixRed = numFixRed + numWhiteFixRed;
                numOverallReds = numPendingRed + numIgnoreRed + numFixRed;
                numOverallAmbers = numPendingAmber + numIgnoreAmber + numFixAmber;
                rows = latestScoreObj.numberOfRows !== undefined ? latestScoreObj.numberOfRows : this.state.numRows;
                totalErrors = rows * cols;
                redPercent = (numOverallReds / totalErrors) * 100;
                amberPercent = (numOverallAmbers / totalErrors) * 100;
                populatedRS = (rStats[`numberOfMissingRedFixers`] + rStats[`numberOfMissingRedIgnores`]);
                populatedAS = (rStats[`numberOfMissingAmberFixers`] + rStats[`numberOfMissingAmberIgnores`]);
                uniquenessRS = (rStats[`numberOfUniqueRedFixers`] + rStats[`numberOfUniqueRedIgnores`]);
                uniquenessAS = (rStats[`numberOfUniqueAmberFixers`] + rStats[`numberOfUniqueAmberIgnores`]);
                typeRS = (rStats[`numberOfTypeRedFixers`] + rStats[`numberOfTypeRedIgnores`]);
                typeAS = (rStats[`numberOfTypeAmberFixers`] + rStats[`numberOfTypeAmberIgnores`]);
                formatRS = (rStats[`numberOfFormatRedFixers`] + rStats[`numberOfFormatRedIgnores`]);
                formatAS = (rStats[`numberOfFormatAmberFixers`] + rStats[`numberOfFormatAmberIgnores`]);
                valueRS = (rStats[`numberOfOutlierRedFixers`] + rStats[`numberOfOutlierRedIgnores`]);
                valueAS = (rStats[`numberOfOutlierAmberFixers`] + rStats[`numberOfOutlierAmberIgnores`]);
                valueAllowedRS = (rStats[`numberOfInvalidRedFixers`] + rStats[`numberOfInvalidRedIgnores`]);
                valueAllowedAS = (rStats[`numberOfInvalidAmberFixers`] + rStats[`numberOfInvalidAmberIgnores`]);
                nativeRS = (rStats[`numberOfNativeRedFixers`] + rStats[`numberOfNativeRedIgnores`]);
                nativeAS = (rStats[`numberOfNativeAmberFixers`] + rStats[`numberOfNativeAmberIgnores`]);
                populatedROS = populatedRed === 0? 0: (((rStats[`numberOfMissingRedFixers`] + rStats[`numberOfMissingRedIgnores`]) / populatedRed) * 100);
                populatedAOS = populatedAmber === 0? 0: (((rStats[`numberOfMissingAmberFixers`] + rStats[`numberOfMissingAmberIgnores`]) / populatedAmber) * 100);
                uniquenessROS = uniquenessRed === 0? 0: (((rStats[`numberOfUniqueRedFixers`] + rStats[`numberOfUniqueRedIgnores`]) / uniquenessRed) * 100);
                uniquenessAOS = uniquenessAmber === 0? 0: (((rStats[`numberOfUniqueAmberFixers`] + rStats[`numberOfUniqueAmberIgnores`]) / uniquenessAmber) * 100);
                typeROS = typeRed === 0? 0: (((rStats[`numberOfTypeRedFixers`] + rStats[`numberOfTypeRedIgnores`]) / typeRed) * 100);
                typeAOS = typeAmber === 0? 0: (((rStats[`numberOfTypeAmberFixers`] + rStats[`numberOfTypeAmberIgnores`]) / typeAmber) * 100);
                formatROS = formatRed === 0? 0: (((rStats[`numberOfFormatRedFixers`] + rStats[`numberOfFormatRedIgnores`]) / formatRed) * 100);
                formatAOS = formatAmber === 0? 0: (((rStats[`numberOfFormatAmberFixers`] + rStats[`numberOfFormatAmberIgnores`]) / formatAmber) * 100);
                valueROS = valueRed === 0? 0: (((rStats[`numberOfOutlierRedFixers`] + rStats[`numberOfOutlierRedIgnores`]) / valueRed) * 100);
                valueAOS = valueAmber === 0? 0: (((rStats[`numberOfOutlierAmberFixers`] + rStats[`numberOfOutlierAmberIgnores`]) / valueAmber) * 100);
                valueAllowedROS = valueAllowedRed === 0? 0: (((rStats[`numberOfInvalidRedFixers`] + rStats[`numberOfInvalidRedIgnores`]) / valueAllowedRed) * 100);
                valueAllowedAOS = valueAllowedAmber === 0? 0: (((rStats[`numberOfInvalidAmberFixers`] + rStats[`numberOfInvalidAmberIgnores`]) / valueAllowedAmber) * 100);
                nativeROS = nativeRed === 0? 0: (((rStats[`numberOfNativeRedFixers`] + rStats[`numberOfNativeRedIgnores`]) / nativeRed) * 100);
                nativeAOS = nativeAmber === 0? 0: (((rStats[`numberOfNativeAmberFixers`] + rStats[`numberOfNativeAmberIgnores`]) / nativeAmber) * 100);
            }
        }
        var overallStats = [
            {id: "Table_Aspect_Stat_0", name: "Alert Row Counts", numRules: "#", percentage: "%"},
            {id: "Table_Aspect_Stat_1", name: "Red", numRules: numOverallReds, percentage: redPercent.toFixed(2)},
            {id: "Table_Aspect_Stat_2", name: "Amber", numRules: numOverallAmbers, percentage: amberPercent.toFixed(2)},
            {id: "Table_Aspect_Stat_3", name: "# Rows", numRules: rows, percentage: ""}
        ];
        let totalR=numOverallReds+numOverallAmbers;
        let leftR = totalR - (numIgnoreRed + numIgnoreAmber + numFixRed + numFixAmber);
        let totalIF = numIgnoreRed + numIgnoreAmber + numFixRed + numFixAmber;
        let showReviewPop=((100-((leftR/totalR)*100))===100);
        let reviewPercent = (100 - ((leftR/totalR)*100));
        var overallReviewStats = [
            {id: "Table_Aspect_Stat_0", name: window.innerWidth<1280?"Counts":"Cell Counts",numRules: "#", percentage: "%", numR: "Pending", numI: "Ignore", numF: "Fix"},
            {id: "Table_Aspect_Stat_1", name: "Red", numRules: numOverallReds, percentage: redPercent.toFixed(2), numR: numPendingRed, numI: numIgnoreRed, numF: numFixRed},
            {id: "Table_Aspect_Stat_2", name: "Amber",numRules: numOverallAmbers, percentage: amberPercent.toFixed(2), numR: numPendingAmber, numI: numIgnoreAmber, numF: numFixAmber},
            {id: "Table_Aspect_Stat_3", name: "Review: "+reviewPercent.toFixed(0)+"%",numRules: totalR, percentage: reviewPercent.toFixed(0), numR: leftR, numI: numIgnoreAmber, numF: numFixAmber}
        ];
        this.props.dataFromParent.handleReviewComplete(showReviewPop);
        /*var healthStats = [
            {id:"Table_Health_Stat_0",name:"Alert Type",numR:"Amber",numI:"Red",numF:"Health"},
            {id:"Table_Health_Stat_1",name:"Missing",numR:populatedAmber,numI:populatedRed,numF:populatedRag},
            {id:"Table_Health_Stat_2",name:"Duplicate",numR:uniquenessAmber,numI:uniquenessRed,numF:uniquenessRag},
            {id:"Table_Health_Stat_3",name:"Wrong Type",numR:typeAmber,numI:typeRed,numF:typeRag},
            {id:"Table_Health_Stat_4",name:"Bad Format",numR:formatAmber,numI:formatRed,numF:formatRag},
            {id:"Table_Health_Stat_5",name:"Outlier",numR:valueAmber,numI:valueRed,numF:valueRag},
            {id:"Table_Health_Stat_6",name:"Invalid",numR:valueAllowedAmber,numI:valueAllowedRed,numF:valueAllowedRag},
            {id:"Table_Health_Stat_7",name:"Excel Error",numR:nativeAmber,numI:nativeRed,numF:nativeRag},
        ];*/
        var healthStats = [
            {id:"Table_Health_Stat_0",name:"Alert Type",numM:"Missing?",numD:"Duplicate?",numT:"Type?",numF:"Format?",numO:"Outlier?",numI:"Invalid?",numE:"Xls Err?"},
            {id:"Table_Health_Stat_1",name:"Red",numM:populatedRed,numD:uniquenessRed,numT:typeRed,numF:formatRed,numO:valueRed,numI:valueAllowedRed,numE:nativeRed,numMS:populatedRS,numDS:uniquenessRS,numTS:typeRS,numFS:formatRS,numOS:valueRS,numIS:valueAllowedRS,numES:nativeRS,numMO:populatedROS,numDO:uniquenessROS,numTO:typeROS,numFO:formatROS,numOO:valueROS,numIO:valueAllowedROS,numEO:nativeROS},
            {id:"Table_Health_Stat_2",name:"Amber",numM:populatedAmber,numD:uniquenessAmber,numT:typeAmber,numF:formatAmber,numO:valueAmber,numI:valueAllowedAmber,numE:nativeAmber,numMS:populatedAS,numDS:uniquenessAS,numTS:typeAS,numFS:formatAS,numOS:valueAS,numIS:valueAllowedAS,numES:nativeAS,numMO:populatedAOS,numDO:uniquenessAOS,numTO:typeAOS,numFO:formatAOS,numOO:valueAOS,numIO:valueAllowedAOS,numEO:nativeAOS},
            {id:"Table_Health_Stat_3",name:"",numM:populatedRag,numD:uniquenessRag,numT:typeRag,numF:formatRag,numO:valueRag,numI:valueAllowedRag,numE:nativeRag}
        ];

        var verticalHealthStats = [
            {id:"Table_Health_Stat_0",name:"Alert Type",description:"List of Alert Types",numR:"Amber",numI:"Red",numF:"Health"},
            {id:"Table_Health_Stat_1",name:"Missing?",description:"Does the data contain any cells that have missing data?",numR:0,numI:0,numF:-1},
            {id:"Table_Health_Stat_2",name:"Duplicate?",description:"For columns containing alpha-numeric data, are any values duplicated?",numR:0,numI:0,numF:-1},
            {id:"Table_Health_Stat_3",name:"Wrong Type?",description:"If a column contains data of one type, are there any values in any cells of a different type?",numR:0,numI:0,numF:-1},
            {id:"Table_Health_Stat_4",name:"Bad Format?",description:"If there are columns with specific format types, are there any cells that do not conform?",numR:0,numI:0,numF:-1},
            {id:"Table_Health_Stat_5",name:"Outlier?",description:"In specific columns, do any cells contain values significantly outside of the range of the data contained in the other cells?",numR:0,numI:0,numF:-1},
            {id:"Table_Health_Stat_6",name:"Invalid?",description:"In columns with a specific set of repeated values, are there any cells that look different?",numR:0,numI:0,numF:-1},
            {id:"Table_Health_Stat_7",name:"Excel Error?",description:"Are there any cells that contain native excel errors?",numR:0,numI:0,numF:-1},
        ];

        this.setState({listProgress:100,uploadStatus:"Data Analysis Inprogress"});
        for (let n = 0; n <= 6; n++) setTimeout(() => {
            var message = "Counting alerts";
            if (n === 0) message = "Counting Missing alerts";
            if (n === 1) message = "Counting Duplicate alerts";
            if (n === 2) message = "Counting Wrong Type alerts";
            if (n === 3) message = "Counting Bad Format alerts";
            if (n === 4) message = "Counting Outlier alerts";
            if (n === 5) message = "Counting Invalid alerts";
            if (n === 6) message = "Counting Excel Error alerts";
            var loger = {id: 11 + n, message: message, step: 11 + n, currentMessage: "Generating heatmap"};
            let loadingLogs = this.state.loadingLogs;
            if(!this.state.recomputing) loadingLogs.push(loger);
            this.setState({
                uploadStatus: message,
                listProgress: 100,
                loadingLogs: loadingLogs.sort(this.GetAscSortOrder('id'))
            });
        },  300);


        //console.log(verticalHealthStats);
        setTimeout(()=>{

            var loger = {id:18,message:"Alert count completed",step:18,currentMessage:"Alert count completed"};
            let loadingLogs = this.state.loadingLogs;
            if(!this.state.recomputing) loadingLogs.push(loger);
            this.setState({uploadStatus:"Alert count completed ", loadingLogs:loadingLogs.sort(this.GetAscSortOrder('id'))})

            verticalHealthStats = [
                {id:"Table_Health_Stat_0",name:"Alert Type",description:"List of Alert Types",numR:"Amber",numI:"Red",numF:"Health"},
                {id:"Table_Health_Stat_1",name:"Missing?",description:"Does the data contain any cells that have missing data?",numR:populatedAmber,numI:populatedRed,numF:populatedRag},
                {id:"Table_Health_Stat_2",name:"Duplicate?",description:"For columns containing alpha-numeric data, are any values duplicated?",numR:uniquenessAmber,numI:uniquenessRed,numF:uniquenessRag},
                {id:"Table_Health_Stat_3",name:"Wrong Type?",description:"If a column contains data of one type, are there any values in any cells of a different type?",numR:typeAmber,numI:typeRed,numF:typeRag},
                {id:"Table_Health_Stat_4",name:"Bad Format?",description:"If there are columns with specific format types, are there any cells that do not conform?",numR:formatAmber,numI:formatRed,numF:formatRag},
                {id:"Table_Health_Stat_5",name:"Outlier?",description:"In specific columns, do any cells contain values significantly outside of the range of the data contained in the other cells?",numR:valueAmber,numI:valueRed,numF:valueRag},
                {id:"Table_Health_Stat_6",name:"Invalid?",description:"In columns with a specific set of repeated values, are there any cells that look different?",numR:valueAllowedAmber,numI:valueAllowedRed,numF:valueAllowedRag},
                {id:"Table_Health_Stat_7",name:"Excel Error?",description:"Are there any cells that contain native excel errors?",numR:nativeAmber,numI:nativeRed,numF:nativeRag},
            ];
            this.setState({listProgress:100,verticalHealthStats:verticalHealthStats});
            this.handleGetColumnHeatmap(snapshot);
            },500);


        setTimeout(()=>{
            var loger = {id:20,message:"Checking for empty columns ",step:20,currentMessage:"Checking for empty columns"};
            let loadingLogs = this.state.loadingLogs;
            if(!this.state.recomputing) loadingLogs.push(loger);
            this.setState({uploadStatus:"Checking for empty columns ",listProgress:100,loadingLogs:loadingLogs.sort(this.GetAscSortOrder('id'))});
            loger = {id:21,message:"File analysis completed - "+this.props.dataFromGrid.label,step:21,currentMessage:"File analysis completed"};
            if(!this.state.recomputing) loadingLogs.push(loger);
            this.setState({uploadStatus:"File analysis completed - "+this.props.dataFromGrid.label,listProgress:100,showReview:true,loadingLogs:loadingLogs.sort(this.GetAscSortOrder('id'))});
            this.normalCursor();
        },700);

        let columnData = [];

        if (cols > 0) {
            var headerData = this.state.headerData.length > 0 ? this.state.headerData : [];
            if (headerData.length > 0) {
                for (let i = 0; i < cols; i++) {

                    var columnQualities = savedRules !== null ? savedRules : [];
                    var qualities = columnQualities.filter((item) => {
                        return item.id.indexOf("column_" + i) > 0;
                    });
                    var type = "string";
                    var ruleId = "";
                    var name = "";
                    let totalMissing=0;
                    let allAmber = 0;
                    let allRed = 0;
                    let populatedAmber = 0;
                    let populatedRed = 0;
                    let populatedEnabled = false;
                    let uniquenessAmber = 0;
                    let uniquenessRed = 0;
                    let uniquenessEnabled = false;
                    let nativeAmber = 0;
                    let nativeRed = 0;
                    let nativeEnabled = false;
                    let typeAmber = 0;
                    let typeRed = 0;
                    let typeEnabled = false;
                    let formatAmber = 0;
                    let formatRed = 0;
                    let formatEnabled = false;
                    let valueAmber = 0;
                    let valueRed = 0;
                    let valueAllowedAmber = 0;
                    let valueAllowedRed = 0;
                    let valueEnabled = false;
                    let valueAllowedEnabled = false;
                    if (qualities.length > 0) {
                        let quality = qualities[0];
                        type = quality.requiredType;
                        ruleId = quality.id;
                        if (type === null) {
                            type = quality.stringEnabled === true ? "String" : (quality.numberEnabled === true ? "Number" : (quality.dateEnabled === true ? "Date" : "String"));
                        }
                        type = type === "string" ? "String" : (type === "number" ? "Number" : (type === "date" ? "Date" : type))
                        populatedEnabled = quality.populatedEnabled;
                        uniquenessEnabled = quality.uniquenessEnabled;
                        nativeEnabled = quality.nativeErrorsEnabled;
                        typeEnabled = quality.typeEnabled;
                        if (type !== "") {
                            formatEnabled = quality[`use${type}Formats`];
                            if (type === "String") {
                                valueEnabled = quality[`use${type}Lengths`] === true? true : false;
                                valueAllowedEnabled = quality[`useAllowedValues`] === true ? true : false;
                            } else {
                                valueEnabled = quality[`use${type}Boundaries`];
                            }
                        }
                    }
                    if (headerData.length > 0) name = headerData[i].coercedValue !== "" ? headerData[i].coercedValue : this.alphabase(i);

                    if (snapshot.snapshotScores.length > 0 && type !== null && type !== "") {
                        let scoreData = snapshot.snapshotScores[0];
                        let columnScore = scoreData.columnScores[i];
                        populatedAmber = columnScore[`numberOfDiscretePopulationAmbers`];
                        populatedRed = columnScore[`numberOfDiscretePopulationReds`];
                        let allmissing=populatedAmber+populatedRed;
                        totalMissing = rows-allmissing;
                        if(totalMissing <3 || !populatedEnabled) abnormalColumns.push(i);
                        if(totalMissing <3) abnormalCorrectedColumns.push(i);
                        uniquenessAmber = columnScore[`numberOfDiscreteUniquenessAmbers`];
                        uniquenessRed = columnScore[`numberOfDiscreteUniquenessReds`];
                        nativeAmber = columnScore[`numberOfDiscreteNativeErrorAmbers`];
                        nativeRed = columnScore[`numberOfDiscreteNativeErrorReds`];
                        typeAmber = columnScore[`numberOfDiscreteTypeAmbers`];
                        typeRed = columnScore[`numberOfDiscreteTypeReds`];
                        formatAmber = columnScore[`numberOfDiscrete${type}FormatAmbers`];
                        formatRed = columnScore[`numberOfDiscrete${type}FormatReds`];
                        valueAmber = columnScore[`numberOfDiscrete${type}BoundaryAmbers`];
                        valueRed = columnScore[`numberOfDiscrete${type}BoundaryReds`];
                        valueAllowedAmber = type==="String"?columnScore[`numberOfDiscrete${type}AllowedAmbers`]:0;
                        valueAllowedRed = type==="String"?columnScore[`numberOfDiscrete${type}AllowedReds`]:0;
                        allAmber = populatedAmber + uniquenessAmber + nativeAmber + typeAmber + formatAmber + valueAmber + valueAllowedAmber;
                        allRed = populatedRed + uniquenessRed + nativeRed + typeRed + formatRed + valueRed + valueAllowedRed;
                    }

                    var column = {
                        id: "column_" + i,
                        type: type,
                        name: name,
                        index: i,
                        allAmber: allAmber,
                        allRed: allRed,
                        populatedAmber: populatedAmber,
                        populatedRed: populatedRed,
                        totalMissing:totalMissing,
                        populatedEnabled: populatedEnabled,
                        uniquenessAmber: uniquenessAmber,
                        uniquenessRed: uniquenessRed,
                        uniquenessEnabled: uniquenessEnabled,
                        nativeAmber: nativeAmber,
                        nativeRed: nativeRed,
                        nativeEnabled: nativeEnabled,
                        typeAmber: typeAmber,
                        typeRed: typeRed,
                        typeEnabled: typeEnabled,
                        formatAmber: formatAmber,
                        formatRed: formatRed,
                        formatEnabled: formatEnabled,
                        valueAmber: valueAmber,
                        valueRed: valueRed,
                        valueEnabled: valueEnabled,
                        valueAllowedAmber: valueAllowedAmber,
                        valueAllowedRed: valueAllowedRed,
                        valueAllowedEnabled: valueAllowedEnabled,
                        ruleId: ruleId
                    };
                    columnData.push(column);
                }
            }
        }
        setTimeout(()=>{this.setState({columnData:columnData,abnormalColumns:abnormalColumns, abnormalCorrectedColumns:abnormalCorrectedColumns,})},1000);
        this.setState
        (
            {
                isLoaded: true,
                tableData: tableTypes,
                heatmapType: Heatmap.OVERALL_HEATMAP_NAME,
//				columnmap:maps[5],
                stats: overallStats,
                reviewStats: overallReviewStats,
                healthStats: healthStats,
                allAmber:allAmber,
                allRed:allRed,
                latestSnapshot: latestScoreObj,
                showReviewPop:showReviewPop,
                notifiedCount:totalIF
            }
        );
    } // handleTableTypes

    alphabase(x)
    {
        var range;
        var e;

        for(e = 1; true; x -= range, e++) if (x < (range = Math.pow(26, e))) break;

        var r, ret = "";
        for(var i = 0; i < e; i++)
        {
            r = x % 26;
            ret = String.fromCharCode(65 + r) + ret;
            x -= r;
            x /= 26;
        }
        return ret;

    } // alphabase

    handleGetRowsColumns(snapshot, rules)
    {
        this.table = new AqaTable(
            snapshot.id,
            e=> {
                //var numberOfRows    = 16;
                var numberOfColumns = 16;
                if(this.table.data !== undefined)
                {
                    let headers = this.table.data.headers;
                    //numberOfRows    = this.table.data.numberOfRows;
                    numberOfColumns = this.table.data.numberOfColumns;
                    if(headers>0){
                        AqaComponent.snapshotBackend.getRowVectorValuesUsingGET
                        (
                            snapshot.id,
                            0,
                            {},
                            (error, data, response) =>
                            {
                                if (error) this.reportError("A problem getting the snapshot header from the server was encountered.", "AqaRules.loadSnapshot, table, header, backend call: " + error,this);
                                else
                                {
                                    this.setState({headerData:data[0].values});
                                    this.handleTableTypes(snapshot, rules, 0, 0);


                                }
                            }
                        );
                    }
                    else{
                        let headerData = Array(numberOfColumns);
                        for(let i = 0; i < numberOfColumns; i++) {
                            let cValue = this.alphabase(i);
                            let cData = {coercedValue:cValue,originalValue:cValue,type:null};
                            headerData[i] = cData;
                        }
                        this.setState({headerData:headerData});
                        this.handleTableTypes(snapshot, rules, 0, 0);

                    }
                }
                //this.setState({numberOfRows, numberOfColumns});
                
            }
        )


    } // handleGetRowsColumns

    handleGetRulesForSource(source,snapshot){
        var sourceId=source.id;
        AqaComponent.backend.getQualitiesUsingGET(
            sourceId,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSnapshotOverView.handleGetRulesForSource, backend call: " + error,this);
                else
                {
                    // we are done here
                    this.setState({savedRules:data});
                    this.handleSnapshot(snapshot,data);
                }
            }
        );

    }

	handleGetColumnHeatmap(snapshot)
	{

		Heatmap.getHeatmapColumnsData
		(
			snapshot.id,
			AqaComponent.HEATMAP_STAMP_WIDTH,
			AqaComponent.HEATMAP_STAMP_HEIGHT,
			-1, // Meaning we want all the columns
			heatmapData =>
			{
                let loadingLogs = this.state.loadingLogs;
                var loger = {id: 19, message: "Generating heatmap", step: 19, currentMessage: "Generating heatmap"};
                if(!this.state.recomputing) loadingLogs.push(loger);

				setTimeout(()=>{this.setState({heatmapData,uploadMessage:"Generating heatmap",loadingLogs:loadingLogs.sort(this.GetAscSortOrder("id"))})},2700);
//				this.handleTableTypes(snapshot, rules, numberOfColumns, numberOfRows);
			}
		);

	} // handleGetColumnHeatmap

    GetAscSortOrder(prop) {
        return function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }
    GetDescSortOrder(prop) {
        return function(a, b) {
            if (a[prop] < b[prop]) {
                return 1;
            } else if (a[prop] > b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    handleSnapshotScores(sourceId,snapshot) {
        AqaComponent.backend.getScoresUsingGET(
            sourceId,
            {_number: -1},
            (error, data, response) => {
                if (error) {
                    //this.reportError("A problem getting the snapshots from the server was encountered.", "AqaSnapshotView.componentDidMount, backend call: " + error,this);
                    console.error("Unable to fetch the scores for " + sourceId + " try again");
                } else {
                    //let snapshot = this.props.dataFromGrid;
                    //snapshot = {...snapshot,...data};
                    if(snapshot!==null && snapshot!==undefined){
                        if(data.length>0){
                            let currentSnapshot = data.filter((d)=>{return d.id === snapshot.id});
                            if(currentSnapshot.length>0) currentSnapshot = currentSnapshot[0];
                            if(currentSnapshot.numberOfRows===0 && currentSnapshot.numberOfColumns === 0){
                                var loger = {id:9,message:"Failed processing sheet - no records found",step:9,currentMessage:"Calculating alert counts"};
                                let loadingLogs = this.state.loadingLogs;
                                loadingLogs.push(loger);
                                this.setState({isReady:false,isPostLoading:true,uploadStatus:"Data Analysis Failed - Sheet is empty",progress:100,loadingLogs:loadingLogs.sort(this.GetAscSortOrder('id'))});
                                this.handleFindSheets(snapshot);
                            }
                            else{
                                var snapshotstatus = {status:"PostLoading",snapshotScores:data};
                                snapshot = {...snapshot,...snapshotstatus};
                                //this.setState({latestSnapshot:snapshot});
                                this.handleGetRulesForSource(this.props.dataFromSource, snapshot);
                            }
                        }

                    }
                    else{
                        var customNotification={title:"Data Upload FAILED", titleIcon:"",
                            message:"We are unable to process the file, either the file you have uploaded is not supported format or due to an internal issue, Contact AQA Team for further assistance",
                            isCustom:false, type:"info",
                            insert:"bottom",position:"bottom-center",autoClose:false,duration:0};
                        this.removeAllNotifications();
                        this.showNotification(null,customNotification);
                    }

                }
            }
        );
    }

	handleSnapshotProgress(snapshot)
	{
        if(snapshot.id!==undefined) snapshot=snapshot.id;
		AqaComponent.snapshotBackend.getSnapshotStatusUsingGET
		(
			snapshot,
			(error,data,response)=>
			{
				if (error) this.reportError("A problem getting the snapshot status from the server was encountered.", "AqaSnapshotOverview.handleSnapshotProgress, backend call: " + error,this);
				else
				{
//console.log(data);
					
					//if (data.fail) alert("Sorry an error occurred:\n" + data.message); // TODO - get the spinner to stop spinning.
					if(data.fail){
                        var customNotification={title:"Data Upload FAILED", titleIcon:"",
                            message:"You have attempted to upload a completely blank file or file is not supported. Please check that you have the correct data source and upload again (AQA does not allow completely blank data sheets to be uploaded)",
                            isCustom:false, type:"info",
                            insert:"bottom",position:"bottom-center",autoClose:false,duration:0};
                        this.removeAllNotifications();
                        this.showNotification(null,customNotification);
                        this.normalCursor();
                        var loger = {id:9,message:"Failed processing sheet - something went wrong",step:9,currentMessage:"Calculating alert counts"};
                        let loadingLogs = this.state.loadingLogs;
                        loadingLogs.push(loger);
                        this.setState({isReady:false,isPostLoading:true,uploadStatus:"Data Analysis Failed - Something went wrong",progress:90,loadingLogs:loadingLogs.sort(this.GetAscSortOrder('id'))});
                        this.props.dataFromRoot.props.dataFromParent.handleFileUploadReset();
                        this.props.dataFromRoot.handleSnapshotReset();
                        this.handleDeleteSnapshot(snapshot);
                        //this.props.dataFromRoot.props.dataFromParent.handleRefresh();
                        //this.props.dataFromRoot.handleSnapshots(this.props.dataFromSource.id);
                    }
                    else if(data.available === true)
					{
                        this.handleSnapshotData(snapshot);
                        /*this.handleSnapshotScores(this.props.dataFromSource.id);
                        var loger = {id:9,message:"Calculating alert counts",step:9,currentMessage:"Calculating alert counts"};
                        let loadingLogs = this.state.loadingLogs;
                        loadingLogs.push(loger);
                        this.setState({isReady:false,isPostLoading:true,uploadStatus:"Data Analysis Inprogress",progress:100,loadingLogs:loadingLogs.sort(this.GetAscSortOrder('id'))});*/
						/*this.props.dataFromRoot.props.dataFromParent.handleFileUploadReset();
						this.props.dataFromRoot.handleSnapshotReset();
						this.props.dataFromRoot.props.dataFromParent.handleRefresh();
                        if(this.props.dataFromParent.state.snapshotcount>=1) this.props.dataFromParent.handleSnapshotHistory(false);*/

						//this.props.dataFromRoot.handleSnapshots(this.props.dataFromSource.id);
                        //this.handleSnapshotData(data.snapshotId);

					}
					else
					{
						var percentDone=0;
						if(data.currentStep!==null && data.numberOfSteps !== null){percentDone=Math.round((data.currentStep/data.numberOfSteps)*100,2)}
                        let loadingLogs = this.state.loadingLogs;
                        let customMessages = this.state.customMessages.filter((d)=>{return d.step === data.currentStep});
                        //console.log(customMessages);
                        var message = customMessages.length===0?data.message:customMessages[0].message;
                        let statsProcessed = true;
                        if(data.currentStep === 1) {
                            message = message + this.props.dataFromGrid.label;
                        }
                        if(data.currentStep === 4) {
                            if(data.numRows === 0) statsProcessed=false;
                            message = message + data.numRows +" Rows "+ data.numColumns +" Columns";
                        }
                        if(loadingLogs.filter((d)=>{return d.step === data.currentStep;}).length=== 0) {
                            loger = {id:loadingLogs.length,message:message,step:data.currentStep,currentMessage:data.simpleMessage};
                            if(statsProcessed && data.currentStep<9) loadingLogs.push(loger);
                        }
						this.setState({progress:percentDone,uploadStatus:message,isReady:false,loadingLogs:loadingLogs.sort(this.GetAscSortOrder('id'))});
						
						setTimeout
						(
							() => this.handleSnapshotProgress(snapshot),
							DELAY_BETWWEN_PROGRESS_UPDATE_REQUSTS
						);
					}

				}
			}
		);
	} // handleSnapshotProgress

	handleSnapshot(snapshot,rules)
	{
		// If this proves to load too much data set the wantsCache flag to false (2nd param)
		ColourAndOverrideMaps.retrieveColBasedStatistics(snapshot.id, true, data => this.handleSnapshotCorrected(snapshot, rules, data));

/*	
        AqaComponent.reviewBackend.retrieveStatiStatisticsUsingGET(
            snapshot.id,
            "row",
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSourceListRow.handleSnapshot, backend call: " + error,this);
                else {
                    this.handleSnapshotCorrected(snapshot,rules,data);
                }
            }
        );
*/
	} // handleSnapshot

    handleSnapshotCorrected(snapshot,rules,statsData){
        AqaComponent.reviewBackend.getAnnotationsScoreUsingGET(
            snapshot.id,
            "row",
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSourceListRow.handleSnapshot, backend call: " + error,this);
                else {
                    var snapRevs = {id:snapshot.id,snapshotReviewStats:statsData,correctedReviewStats:data};
                    snapshot = {...snapshot,...snapRevs};
                    this.handleGetRowsColumns(snapshot,rules);
                }
            }
        );
    }

    handleReloadStatsWithWhite(snapshot,statsData){
        AqaComponent.reviewBackend.getAnnotationsScoreUsingGET(
            snapshot.id,
            "row",
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSourceListRow.handleSnapshot, backend call: " + error,this);
                else {
                    let rStats = statsData;
                    let numWhiteFixRed = data[`totalNumberOfRowsConcerned`];
                    let numPendingAmber = rStats[`numberOfAmberPendings`];
                    let numPendingRed = rStats[`numberOfRedPendings`];
                    let numIgnoreAmber = rStats[`numberOfAmberIgnores`];
                    let numIgnoreRed = rStats[`numberOfRedIgnores`];
                    let numFixAmber = rStats[`numberOfAmberFixers`];
                    let numFixRed = rStats[`numberOfRedFixers`];
                    numFixRed = numFixRed + numWhiteFixRed;
                    let numOverallReds = numPendingRed + numIgnoreRed + numFixRed ;
                    let numOverallAmbers = numPendingAmber + numIgnoreAmber + numFixAmber;
                    let totalErrors = snapshot.numberOfRows*snapshot.numberOfColumns;
                    let redPercent = (numOverallReds / totalErrors) * 100;
                    let amberPercent = (numOverallAmbers / totalErrors) * 100;
                    // Health stats to go here
                    let latestScoreObj = snapshot;
                    let populatedAmber = latestScoreObj[`numberOfDiscretePopulationAmbers`];
                    let populatedRed = latestScoreObj[`numberOfDiscretePopulationReds`];
                    let uniquenessAmber = latestScoreObj[`numberOfDiscreteUniquenessAmbers`];
                    let uniquenessRed = latestScoreObj[`numberOfDiscreteUniquenessReds`];
                    let nativeAmber = latestScoreObj[`numberOfDiscreteNativeErrorAmbers`];
                    let nativeRed = latestScoreObj[`numberOfDiscreteNativeErrorReds`];
                    let typeAmber = latestScoreObj[`numberOfDiscreteTypeAmbers`];
                    let typeRed = latestScoreObj[`numberOfDiscreteTypeReds`];
                    let formatAmber = latestScoreObj[`numberOfDiscreteStringFormatAmbers`] + latestScoreObj[`numberOfDiscreteNumberFormatAmbers`] + latestScoreObj[`numberOfDiscreteDateFormatAmbers`];
                    let formatRed = latestScoreObj[`numberOfDiscreteStringFormatReds`] + latestScoreObj[`numberOfDiscreteNumberFormatReds`] + latestScoreObj[`numberOfDiscreteDateFormatReds`];
                    let valueAmber = latestScoreObj[`numberOfDiscreteStringBoundaryAmbers`] + latestScoreObj[`numberOfDiscreteNumberBoundaryAmbers`] + latestScoreObj[`numberOfDiscreteDateBoundaryAmbers`];
                    let valueRed = latestScoreObj[`numberOfDiscreteStringBoundaryReds`] + latestScoreObj[`numberOfDiscreteNumberBoundaryReds`] + latestScoreObj[`numberOfDiscreteDateBoundaryReds`];
                    let valueAllowedAmber = latestScoreObj[`numberOfDiscreteStringAllowedAmbers`];
                    let valueAllowedRed = latestScoreObj[`numberOfDiscreteStringAllowedReds`];
                    let populatedRag = populatedRed>0?0:(populatedAmber>0?1:2);
                    let uniquenessRag = uniquenessRed>0?0:(uniquenessAmber>0?1:2);
                    let typeRag = typeRed>0?0:(typeAmber>0?1:2);
                    let formatRag = formatRed>0?0:(formatAmber>0?1:2);
                    let valueRag = valueRed>0?0:(valueAmber>0?1:2);
                    let valueAllowedRag = valueAllowedRed>0?0:(valueAllowedAmber>0?1:2);
                    let nativeRag = nativeRed>0?0:(nativeAmber>0?1:2);
                    let populatedRS = (rStats[`numberOfMissingRedFixers`] + rStats[`numberOfMissingRedIgnores`]);
                    let populatedAS = (rStats[`numberOfMissingAmberFixers`] + rStats[`numberOfMissingAmberIgnores`]);
                    let uniquenessRS = (rStats[`numberOfUniqueRedFixers`] + rStats[`numberOfUniqueRedIgnores`]);
                    let uniquenessAS = (rStats[`numberOfUniqueAmberFixers`] + rStats[`numberOfUniqueAmberIgnores`]);
                    let typeRS = (rStats[`numberOfTypeRedFixers`] + rStats[`numberOfTypeRedIgnores`]);
                    let typeAS = (rStats[`numberOfTypeAmberFixers`] + rStats[`numberOfTypeAmberIgnores`]);
                    let formatRS = (rStats[`numberOfFormatRedFixers`] + rStats[`numberOfFormatRedIgnores`]);
                    let formatAS = (rStats[`numberOfFormatAmberFixers`] + rStats[`numberOfFormatAmberIgnores`]);
                    let valueRS = (rStats[`numberOfOutlierRedFixers`] + rStats[`numberOfOutlierRedIgnores`]);
                    let valueAS = (rStats[`numberOfOutlierAmberFixers`] + rStats[`numberOfOutlierAmberIgnores`]);
                    let valueAllowedRS = (rStats[`numberOfInvalidRedFixers`] + rStats[`numberOfInvalidRedIgnores`]);
                    let valueAllowedAS = (rStats[`numberOfInvalidAmberFixers`] + rStats[`numberOfInvalidAmberIgnores`]);
                    let nativeRS = (rStats[`numberOfNativeRedFixers`] + rStats[`numberOfNativeRedIgnores`]);
                    let nativeAS = (rStats[`numberOfNativeAmberFixers`] + rStats[`numberOfNativeAmberIgnores`]);
                    let populatedROS = populatedRed === 0? 0: (((rStats[`numberOfMissingRedFixers`] + rStats[`numberOfMissingRedIgnores`]) / populatedRed) * 100);
                    let populatedAOS = populatedAmber === 0? 0: (((rStats[`numberOfMissingAmberFixers`] + rStats[`numberOfMissingAmberIgnores`]) / populatedAmber) * 100);
                    let uniquenessROS = uniquenessRed === 0? 0: (((rStats[`numberOfUniqueRedFixers`] + rStats[`numberOfUniqueRedIgnores`]) / uniquenessRed) * 100);
                    let uniquenessAOS = uniquenessAmber === 0? 0: (((rStats[`numberOfUniqueAmberFixers`] + rStats[`numberOfUniqueAmberIgnores`]) / uniquenessAmber) * 100);
                    let typeROS = typeRed === 0? 0: (((rStats[`numberOfTypeRedFixers`] + rStats[`numberOfTypeRedIgnores`]) / typeRed) * 100);
                    let typeAOS = typeAmber === 0? 0: (((rStats[`numberOfTypeAmberFixers`] + rStats[`numberOfTypeAmberIgnores`]) / typeAmber) * 100);
                    let formatROS = formatRed === 0? 0: (((rStats[`numberOfFormatRedFixers`] + rStats[`numberOfFormatRedIgnores`]) / formatRed) * 100);
                    let formatAOS = formatAmber === 0? 0: (((rStats[`numberOfFormatAmberFixers`] + rStats[`numberOfFormatAmberIgnores`]) / formatAmber) * 100);
                    let valueROS = valueRed === 0? 0: (((rStats[`numberOfOutlierRedFixers`] + rStats[`numberOfOutlierRedIgnores`]) / valueRed) * 100);
                    let valueAOS = valueAmber === 0? 0: (((rStats[`numberOfOutlierAmberFixers`] + rStats[`numberOfOutlierAmberIgnores`]) / valueAmber) * 100);
                    let valueAllowedROS = valueAllowedRed === 0? 0: (((rStats[`numberOfInvalidRedFixers`] + rStats[`numberOfInvalidRedIgnores`]) / valueAllowedRed) * 100);
                    let valueAllowedAOS = valueAllowedAmber === 0? 0: (((rStats[`numberOfInvalidAmberFixers`] + rStats[`numberOfInvalidAmberIgnores`]) / valueAllowedAmber) * 100);
                    let nativeROS = nativeRed === 0? 0: (((rStats[`numberOfNativeRedFixers`] + rStats[`numberOfNativeRedIgnores`]) / nativeRed) * 100);
                    let nativeAOS = nativeAmber === 0? 0: (((rStats[`numberOfNativeAmberFixers`] + rStats[`numberOfNativeAmberIgnores`]) / nativeAmber) * 100);

                    var overallStats = [
                        {id: "Table_Aspect_Stat_0", name: "Alert Row Counts", numRules: "#", percentage: "%"},
                        {id: "Table_Aspect_Stat_1", name: "Red", numRules: numOverallReds, percentage: redPercent.toFixed(2)},
                        {id: "Table_Aspect_Stat_2", name: "Amber", numRules: numOverallAmbers, percentage: amberPercent.toFixed(2)},
                        {id: "Table_Aspect_Stat_3", name: "# Rows", numRules: totalErrors, percentage: ""}
                    ];
                    let totalR=numOverallReds+numOverallAmbers;
                    let leftR = totalR - (numIgnoreRed + numIgnoreAmber + numFixRed + numFixAmber);
                    let reviewPercent = (100 - ((leftR/totalR)*100));
                    let totalIF = numIgnoreRed + numIgnoreAmber + numFixRed + numFixAmber;
                    var overallReviewStats = [
                        {id:"Table_Aspect_Stat_0",name:window.innerWidth<1280?"Counts":"Cell Counts",numRules: "#", percentage: "%",numR:"Pending",numI:"Ignore",numF:"Fix"},
                        {id:"Table_Aspect_Stat_1",name:"Red",numRules: numOverallReds, percentage: redPercent.toFixed(2),numR:numPendingRed,numI:numIgnoreRed,numF:numFixRed},
                        {id:"Table_Aspect_Stat_2",name:"Amber",numRules: numOverallAmbers, percentage: amberPercent.toFixed(2),numR:numPendingAmber,numI:numIgnoreAmber,numF:numFixAmber},
                        {id: "Table_Aspect_Stat_3", name: "Review: "+reviewPercent.toFixed(0)+"%",numRules: totalR, percentage: reviewPercent.toFixed(0), numR: leftR, numI: numIgnoreAmber, numF: numFixAmber}
                    ];
                    let reviewComplete = (100 - ((leftR/totalR)*100))===100;
                    var healthStats = [
                        {id:"Table_Health_Stat_0",name:"Alert Type",numM:"Missing?",numD:"Duplicate?",numT:"Type?",numF:"Format?",numO:"Outlier?",numI:"Invalid?",numE:"Xls Err?"},
                        {id:"Table_Health_Stat_1",name:"Red",numM:populatedRed,numD:uniquenessRed,numT:typeRed,numF:formatRed,numO:valueRed,numI:valueAllowedRed,numE:nativeRed,numMS:populatedRS,numDS:uniquenessRS,numTS:typeRS,numFS:formatRS,numOS:valueRS,numIS:valueAllowedRS,numES:nativeRS,numMO:populatedROS,numDO:uniquenessROS,numTO:typeROS,numFO:formatROS,numOO:valueROS,numIO:valueAllowedROS,numEO:nativeROS},
                        {id:"Table_Health_Stat_2",name:"Amber",numM:populatedAmber,numD:uniquenessAmber,numT:typeAmber,numF:formatAmber,numO:valueAmber,numI:valueAllowedAmber,numE:nativeAmber,numMS:populatedAS,numDS:uniquenessAS,numTS:typeAS,numFS:formatAS,numOS:valueAS,numIS:valueAllowedAS,numES:nativeAS,numMO:populatedAOS,numDO:uniquenessAOS,numTO:typeAOS,numFO:formatAOS,numOO:valueAOS,numIO:valueAllowedAOS,numEO:nativeAOS},
                        {id:"Table_Health_Stat_3",name:"",numM:populatedRag,numD:uniquenessRag,numT:typeRag,numF:formatRag,numO:valueRag,numI:valueAllowedRag,numE:nativeRag}
                    ];
                    this.props.dataFromParent.handleReviewComplete(reviewComplete);
                    this.setState({reviewStats:overallReviewStats,stats:overallStats,reviewComplete:reviewComplete,showReviewPop:reviewComplete,healthStats:healthStats,notifiedCount:totalIF});
                    /*
                    Fix the reviewComplete for each alert type here
                     */

                    let {selectedRow,selectedColor,selectedStat,whichStatus} = this.state;
                    let sColor = selectedColor==="RedAmberGreen"?"Red":(selectedColor==="RedGreen"?"Red":selectedColor);

                    if(selectedRow!==null && selectedRow !=="" && selectedRow!==undefined && sColor!==""){
                        let detectedAlerts = selectedRow.state.detectedAlerts.filter((d)=>{return d.alertRag === sColor;});

                        let alertType = "";
                        if(selectedStat!==null && selectedStat !=="" && selectedStat !== undefined){
                            if(detectedAlerts.length>0){
                               let firstAlert = detectedAlerts[0];
                               if(firstAlert.alertCode ==="M"){
                                    if(sColor ==="Red" && populatedRed === populatedRS) alertType="Missing alerts (Red)"
                                    if(sColor ==="Amber" && populatedAmber === populatedAS) alertType="Missing alerts (Amber)"
                               }
                                if(firstAlert.alertCode ==="D"){
                                    if(sColor ==="Red" && uniquenessRed === uniquenessRS) alertType="Duplicate alerts (Red)"
                                    if(sColor ==="Amber" && uniquenessAmber === uniquenessAS) alertType="Duplicate alerts (Amber)"
                                }
                                if(firstAlert.alertCode ==="T"){
                                    if(sColor ==="Red" && typeRed === typeRS) alertType="Wrong Type alerts (Red)"
                                    if(sColor ==="Amber" && typeAmber === typeAS) alertType="Wrong Type alerts (Amber)"
                                }
                                if(firstAlert.alertCode ==="F"){
                                    if(sColor ==="Red" && formatRed === formatRS) alertType="Bad Format alerts (Red)"
                                    if(sColor ==="Amber" && formatAmber === formatAS) alertType="Bad Format alerts (Amber)"
                                }
                                if(firstAlert.alertCode ==="O"){
                                    if(sColor ==="Red" && valueRed === valueRS) alertType="Outlier alerts (Red)"
                                    if(sColor ==="Amber" && valueAmber === valueAS) alertType="Outlier alerts (Amber)"
                                }
                                if(firstAlert.alertCode ==="I"){
                                    if(sColor ==="Red" && valueAllowedRed === valueAllowedRS) alertType="Invalid alerts (Red)"
                                    if(sColor ==="Amber" && valueAllowedAmber === valueAllowedAS) alertType="Invalid alerts (Amber)"
                                }
                                if(firstAlert.alertCode ==="N"){
                                    if(sColor ==="Red" && nativeRed === nativeRS) alertType="Excel Error alerts (Red)"
                                    if(sColor ==="Amber" && nativeAmber === nativeAS) alertType="Excel Error alerts (Amber)"
                                }
                            }
                        }

                        if(whichStatus!==0){
                            if(totalIF <= 5 && alertType ===""){
                                var customNotification = {
                                    title: "Optional Comment", titleIcon: "",
                                    message: <div><Typography variant="inherit" color="inherit" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"2px"}}>
                                        Click on Add Comment to enter a supporting comment for your {whichStatus===1?"Can Ignore ":(whichStatus===2?"Must FIX ":"Reset ")} decision

                                    </Typography></div>,
                                    isCustom: true, type: "info",
                                    insert: "bottom-center", position: "bottom-center", autoClose: true, duration: 5000
                                };
                                this.removeAllNotifications();
                                this.showNotification(null, customNotification);
                            }
                            else if(alertType !== "" && !reviewComplete){
                                customNotification = {
                                    title: "Review Progress", titleIcon: "",
                                    message: <div>
                                        <Typography variant="inherit" color="inherit" component={"div"} style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"2px"}}>
                                            Review now completed for {alertType}
                                        </Typography>
                                        <Typography variant="inherit" color="inherit" component={"div"} style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"2px"}}>
                                            You have completed {reviewPercent.toFixed(0)+"% "} of the overall review
                                        </Typography>
                                        {totalIF <= 5 ?
                                            <Typography variant="inherit" color="inherit" component={"div"} style={{
                                                textAlign: "center",
                                                fontSize: "0.8rem",
                                                color: "#006",
                                                padding: "2px"
                                            }}>
                                                Click on Add Comment to enter a supporting comment for your {whichStatus===1?"Can Ignore ":(whichStatus===2?"Must FIX ":"Reset ")}
                                                decision
                                            </Typography>
                                            : ""
                                        }
                                        <Typography variant="inherit" color="inherit" component={"div"} style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"2px"}}>
                                            Click anywhere to continue
                                        </Typography>
                                    </div>,
                                    isCustom: true, type: "info",
                                    insert: "bottom-center", position: "bottom-center", autoClose: false, duration: 0
                                };
                                this.removeAllNotifications();
                                this.showNotification(null, customNotification);
                            }
                            else {}
                        }


                    }
                }
            }
        );
    }

	handleReloadStats(snapshot,wantsCache)
	{
        // If this proves to load too much data set the wantsCache flag to false (2nd param)
		ColourAndOverrideMaps.retrieveColBasedStatistics(snapshot.id, wantsCache, data => this.handleReloadStatsWithWhite(snapshot, data));
	
/*
	
        AqaComponent.reviewBackend.retrieveStatiStatisticsUsingGET(
            snapshot.id,
            "row",
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSourceListRow.handleSnapshot, backend call: " + error,this);
                else {
                    / *let rStats = data;
                    let numPendingAmber = rStats[`numberOfPendingAmber`];
                    let numPendingRed = rStats[`numberOfPendingRed`];
                    let numIgnoreAmber = rStats[`numberOfIgnoreAmber`];
                    let numIgnoreRed = rStats[`numberOfIgnoreRed`];
                    let numFixAmber = rStats[`numberOfFixedAmber`];
                    let numFixRed = rStats[`numberOfFixedRed`];

                    var overallReviewStats = [
                        {id:"Table_Aspect_Stat_0",name:"Review Row Counts",numR:"Pending",numI:"Ignore",numF:"Fix"},
                        {id:"Table_Aspect_Stat_1",name:"Red",numR:numPendingRed,numI:numIgnoreRed,numF:numFixRed},
                        {id:"Table_Aspect_Stat_2",name:"Amber",numR:numPendingAmber,numI:numIgnoreAmber,numF:numFixAmber}
                    ];* /
                    //this.setState({reviewStats:overallReviewStats});
                    this.handleReloadStatsWithWhite(snapshot,data);
                }
            }
        );
*/
	} // handleReloadStats

    handleSnapshotData(snapshotId){
        AqaComponent.snapshotBackend.getSnapshotUsingGET(
            snapshotId,
            (error,data,response)=>
            {
                if (error){
                    this.setState({isLoaded:false});
                }
                else
                {
                    //console.log(data);
                    if(data.sheetChosen) {
                        this.handleSnapshotScores(this.props.dataFromSource.id,data);
                        var loger = {id:9,message:"Calculating alert counts",step:9,currentMessage:"Calculating alert counts"};
                        let loadingLogs = this.state.loadingLogs;
                        loadingLogs.push(loger);
                        this.setState({isReady:false,isPostLoading:true,uploadStatus:"Data Analysis Inprogress",progress:100,loadingLogs:loadingLogs.sort(this.GetAscSortOrder('id'))});
                        //this.handleGetRulesForSource(this.props.dataFromSource, data);
                    }
                    else{
                        this.handleFindSheets(data);
                    }
                }
            }
        )
    }

    handleFindSheets = (snapshot)=>{
        AqaComponent.snapshotBackend.getSheetsUsingGET(
            snapshot.id,
            (error,data,response)=>
            {
                if (error){
                    if (error) this.reportError("A problem getting the snapshot sheets from the server was encountered.",
                        "AqaSnapshotOverview.handleFindSheets, backend call: " + error,this);
                }
                else
                {
                    let titles = data.titles;
                    let sheetData = [];
                    if(titles.length>0){
                        for(let i=0;i<titles.length;i++){
                            let sheet = {id:"T"+i,titleIndex:i,title:titles[i]}
                            sheetData.push(sheet)
                        }
                        var loger = {id:4,message:"Multiple sheets detected",step:4,currentMessage:"Calculating alert counts"};
                        let loadingLogs = this.state.loadingLogs;
                        loadingLogs.push(loger);
                        this.setState({openSheetSelection:true,foundSheets:titles,sheetData:sheetData,isReady:false,uploadStatus:"Multiple sheets detected",loadingLogs:loadingLogs.sort(this.GetAscSortOrder('id'))});
                    }
                    else{
                        var customNotification={title:"Data Upload FAILED", titleIcon:"",
                            message:"You have attempted to upload a completely blank file. Please check that you have the correct data source and upload again (AQA does not allow completely blank data sheets to be uploaded)",
                            isCustom:false, type:"info",
                            insert:"bottom",position:"bottom-center",autoClose:false,duration:0};
                        this.removeAllNotifications();
                        this.showNotification(null,customNotification);
                        this.handleDeleteSnapshot(snapshot);

                    }

                    //this.handleGetRulesForSource(this.props.dataFromSource, data);
                }
            }
        )
    }

    handleSnapshots=(sourceId)=> {
        AqaComponent.backend.getSnapshotsForSourceUsingGET(
            sourceId,
            0,
            9999,
            (error, data, response) => {
                var errorJSON = JSON.parse(response.text);
                if (errorJSON.error === "invalid_token") {
                    this.props.dataFromParent.props.dataFromRoot.popTimeoutDialog2();
                    //this.props.dataFromParent.props.controller.props.dataFromMain.props.parent.logout();
                } else {
                    if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                        "AqaSnapshotView.handleSnapshots, backend call: " + error, this);
                    else {
                        if(data.length===0) this.handleDeleteSource(this.props.dataFromSource);
                        else {
                            this.props.dataFromMainFrame.navigation(1);
                            this.props.dataFromMainFrame.handleSource(null);

                        }
                    }
                }
            }
        );
    }

    handleDeleteSnapshot(snapshot){
        if(snapshot.id!==undefined) snapshot = snapshot.id;
        AqaComponent.snapshotBackend.delUsingDELETE(
            snapshot,
            (error,data,response)=>{
                if (error) this.reportError("A problem deleting Snapshot from the server was encountered.",
                    "AqaSnapshotDelete.handleDeleteSnapshot, backend call: " + error,this);
                else{
                        //console.log(this.props);
                        this.handleSnapshots(this.props.dataFromSource.id);
                        //this.props.dataFromParent.handleSnapshotSelectionReset();
                        //this.props.parent.props.parent.handleRefresh();

                }

            }
        );
    }

    handleRadioChange = (e)=>{
        const {name,value}=e.target;
        this.setState({[name]:value,errorMessage:""});
    }

    handleClosePopup = ()=>{
        this.setState({openSheetSelection:false});
        this.handleDeleteSnapshot(this.props.dataFromGrid);
    }

    handleDeleteSource = (source)=>{
        AqaComponent.backend.delSourceUsingDELETE(
            source.id,
            (error,data,response)=>{
                if (error) this.reportError("A problem removing source from the server was encountered.",
                    "AqaSnapshotOverview.handleDeleteSource, backend call: " + error,this);
                else{
                    this.props.dataFromMainFrame.navigation(1);
                    this.props.dataFromMainFrame.handleSource(null);

                }
            }
        );
    }

    handleSheetSnapshotClosePopup = ()=>{
        if(this.state.sheetSelectedIndex<0){
            this.setState({errorMessage:"Please select the sheet to load"});
        }
        else{
            let sheet = this.state.sheetData.filter((d)=>{return d.id === this.state.sheetSelectedIndex});
            if(sheet.length>0){
                sheet = sheet[0];
                this.handleSelectSheet(this.props.dataFromGrid,sheet);
            }
        }
    }

    handleSelectSheet = (snapshot,sheet) =>{
        AqaComponent.snapshotBackend.selectSheetUsingPUT(
            snapshot.id,
            sheet,
            (error,data,response)=>
            {
                if (error){
                    if (error) this.reportError("A problem getting the snapshot sheets from the server was encountered.",
                        "AqaSourceListRow.handleSelectSheets, backend call: " + error,this);
                }
                else
                {
                    if(data.success){
                        this.setState({openSheetSelection:false});
                        this.handleSnapshotProgress(data.snapshotId);
                    }
                    //this.handleGetRulesForSource(this.props.dataFromSource, data);
                }
            }
        )
    }

    handleSnapshotIsLoaded(snapshot){
        AqaComponent.snapshotBackend.getSnapshotUsingGET(
            snapshot.id,
            (error,data,response)=>
            {
                if (error){
                    if (error) this.reportError("A problem getting the snapshot from the server was encountered.",
                        "AqaSourceListRow.handleSnapshotIsLoaded, backend call: " + error,this);
                }
                else
                {
                    if(data.sheetChosen){
                        this.setState({isReady:false,isPostLoading:true});
                    }
                    else{
                        //alert("I am not loaded why");
                        this.handleFindSheets(snapshot);
                    }
                    //this.handleGetRulesForSource(this.props.dataFromSource, data);
                }
            }
        )
    }

    handleUsers(){
        AqaComponent.userBackend.allUsingGET(
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the users from the server was encountered.",
                    "AqaAccountUsers.handleUsers, backend call: " + error,this);
                else{
                    this.setState({users:data});
                    for(let i=0;i<data.length;i++) {
                        let user = data[i];
                        this.personCache[user.id] = user.email;
                    }
                }
            }

        );
    }

    handleFilterReset =()=>{
        this.setState({isSnackOpen:false,isOverallSelected:false,selectedColumn:null,selectedColumnId:null,selectedColor:"",selectedType:"",selectedColumnIndex:-1,isAmber:false,isRed:false,isDataViewLoaded:false,selectedStat:null});
        this.props.dataFromMain.handleSelectedStat(null);
    }

    handleSnapshotRow(column,type,color,index,value,stat)
    {
//console.log(value);
//console.log("handleSnapshotRow: [" + AqaComponent.prettifyJson(column) + "," + type + "," + color + "," + index + "]");

        AqaComponent.touch();
        if(column===null || column === undefined) return;
        let isAmber = color==="redamber"|| color === "amber" ? true : false;
        let isRed = color==="redamber" || color === "red" ? true : false;
        this.setState({isDataLoading:false,isSnackOpen:true,selectedRow:null});
        if(type === "overall"){
            //this.props.dataFromMain.handleSnapshotFilter3(this.props.dataFromSource, this.state.latestSnapshot,7 , false, false, null,index);
            if(value>0){
            if(type === this.state.selectedType){
                this.props.dataFromMain.handleSelectedStat(null);
                this.setState({selectedColumn:null,selectedColumnId:null,selectedColor:"",selectedType:"",selectedColumnIndex:-1,isAmber:false,isRed:false,isDataViewLoaded:false,isSnackOpen:false,selectedStat:null});
            }
            else{
                this.setState({selectedColumn:column,selectedColumnId:column.id,selectedColor:color,selectedType:type,selectedColumnIndex:column.index,isAmber:isAmber,isRed:isRed,isDataViewLoaded:false,isSnackOpen:true,selectedStat:stat});
            }
            }
        }
        else if(type === "all"|| type==="any"){
            //this.props.dataFromMain.handleSnapshotFilter3(this.props.dataFromSource, this.state.latestSnapshot,7 , false, false, null,index);
           if(value>0){
                if(type === this.state.selectedType){
                    this.props.dataFromMain.handleSelectedStat(null);
                    this.setState({selectedColumn:null,selectedColumnId:null,selectedColor:"",selectedType:"",selectedColumnIndex:-1,isAmber:false,isRed:false,isDataViewLoaded:false,isSnackOpen:false,selectedState:null});
                }
                else{
                    this.setState({selectedColumn:column,selectedColumnId:column.id,selectedColor:color,selectedType:type,selectedColumnIndex:column.index,isAmber:isAmber,isRed:isRed,isDataViewLoaded:false,isSnackOpen:true,selectedStat:stat});
                }
            }
        }
        else {
            let colorName = color === "red" ? "Red" : "Amber"
            let isClickEnabled = column[`${type}Enabled`];
            let isScoreAvailable = column[`${type}${colorName}`] > 0 ? true : false;
            //let backendType = type === "String" ? 2 : (type === "Number" ? 3 : (type === "Date" ? 4 : 6));
            if (isClickEnabled && isScoreAvailable) {
                //this.props.dataFromMain.handleSnapshotFilter3(this.props.dataFromSource, this.state.latestSnapshot, backendType, isAmber, isRed, null, index);
                if(column.id === this.state.selectedColumnId && type === this.state.selectedType && color===this.state.selectedColor)
                {
                    this.props.dataFromMain.handleSelectedStat(null);
                    this.setState({selectedColumn:null,selectedColumnId:null,selectedColor:"overall",selectedType:"",selectedColumnIndex:-1,isAmber:false,isRed:false,isDataViewLoaded:false,selectedRow:null,isSnackOpen:false,selectedStat:null});
                }
                else
                {
                    this.setState({selectedColumn:column,selectedColumnId:column.id,selectedColor:color,selectedType:type,selectedColumnIndex:column.index,isAmber:isAmber,isRed:isRed,isDataViewLoaded:false,selectedRow:null,isSnackOpen:true,selectedStat:stat});
                }
            }
        }
        if(this.state.selectedStat!==null && this.state.selectedStat === stat) this.setState({selectedStat:null});
        setTimeout((()=>{
            this.setState({isDataViewLoaded:true,isDataLoading:false})
        }),500)
    }

    handleDataLoadComplete =()=>{
        this.setState({isDataLoading:false,isDataViewLoaded:true,isDataReady:true,isWaiting:false});
        this.normalCursor();
    }

    handleSnapshotPending = (e)=> {
        var status="Pending";
        var snapshot=this.state.latestSnapshot;
        AqaComponent.snapshotBackend.updateSnapshotStatusChangeUsingPOST(
            snapshot.id,
            status,
            (error, data, response) => {
                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                else {
                    this.setState({isPendingLoading:true});
                   setTimeout(()=>{
                       this.props.dataFromRoot.props.dataFromParent.handleFileUploadReset();
                       this.props.dataFromRoot.handleSnapshotReset();
                       this.props.dataFromRoot.props.dataFromParent.handleRefresh();
                       if(this.props.dataFromParent.state.snapshotcount>=1) this.props.dataFromParent.handleSnapshotHistory(false);
                   },1000);
                }
            }
        )
    }

    handleSnapshotDetail(color,hasB){
        AqaComponent.touch();
        this.waitCursor();
        this.setState({isDataViewLoaded:true,isDataLoading:true,isWaiting:true});
        let isActive = this.state.selectedColor===color;
        let isAmber = color!=="RedAmber" && color ==="Amber" ?true:(hasB!==undefined && hasB && (color==="RedAmber" || color==="RedAmberGreen")?true:false);
        let isRed = color!=="RedAmber" && color==="Red"?true:(hasB!==undefined && hasB && (color==="RedAmber" || color==="RedAmberGreen")?true:false);
        let isGreen = color==="RedAmberGreen" && (hasB!==undefined && hasB )?true:false;
        let isOverallSelected = color==="RedAmber" || color==="RedAmberGreen" || color==="Amber" || color==="Red"?true:false;
        this.props.dataFromMain.handleSnapshotFilter2(this.props.dataFromSource,this.state.latestSnapshot,null,isAmber,isRed,null,null,isOverallSelected,isGreen,color==="Amber"?"any":null,-1,-1);
        if(isActive){
            this.setState({isOverallSelected:false,selectedColor:"",selectedType:"overall",selectedColumn:null,selectedColumnIndex:"-1",selectedColumnId:null,isAmber:false,isRed:false,isGreen:false,isDataViewLoaded:true,selectedRow:null,selectedReviewType:-1,isSnackOpen:false,finerGrain:-1});
            this.normalCursor();
        }
        else{
            this.setState({isOverallSelected:true,selectedColor:color,selectedType:"overall",selectedColumn:null,selectedColumnIndex:"-1",selectedColumnId:null,isAmber:isAmber,isRed:isRed,isGreen:isGreen,isDataViewLoaded:true,selectedRow:null,selectedReviewType:-1,isDataReady:true,isSnackOpen:true,finerGrain:-1});
        }
        setTimeout((()=>{
            this.setState({isDataViewLoaded:true,isDataReady:true,isDataLoading:false});
            //this.normalCursor();
        }),500)
    }

    handleSnapshotDetail2(color,type,stat){
        AqaComponent.touch();
        this.waitCursor();
        this.setState({isDataViewLoaded:false,isDataLoading:false,isWaiting:true});
        let isActive = this.state.selectedColor===color && this.state.selectedReviewType === type;
        let isAmber = color==="RedAmber" || color ==="Amber"?true:false;
        let isRed = color==="RedAmber" || color ==="Red" || color==="RedGreen" || color==="RedAmberGreen"?true:false;
        let isGreen = color==="RedGreen"|| color==="RedAmberGreen"?true:false;
        let isOverallSelected = color==="RedAmber"|| color==="RedAmberGreen" || color==="Amber" || color==="Red" || color==="RedGreen"?true:false;
        // this.props.dataFromMain.handleSnapshotFilter2(this.props.dataFromSource,this.state.latestSnapshot,null,isAmber,isRed,null,null,isOverallSelected);

        this.props.dataFromMain.handleSnapshotFilter2(this.props.dataFromSource,this.state.latestSnapshot,null,isAmber,isRed,null,null,isOverallSelected,isGreen,color==="Amber"?"any":"overall",-1,type);
        
        
        if(isActive){
            this.props.dataFromMain.handleSelectedStat(null);
            this.setState({isOverallSelected:false,selectedColor:"",selectedColumn:null,selectedColumnIndex:"-1",selectedColumnId:null,isAmber:false,isRed:false,isDataViewLoaded:false,selectedRow:null,selectedReviewType:-1,isSnackOpen:false,isGreen:false,selectedType:color==="Amber"?"any":"overall",finerGrain:-1,selectedStat:null});
            this.normalCursor();
        }
        else{
            this.props.dataFromMain.handleSelectedStat(stat);
            this.setState({isOverallSelected:true,selectedColor:color,selectedColumn:null,selectedColumnIndex:"-1",selectedColumnId:null,isAmber:isAmber,isRed:isRed,isDataViewLoaded:false,selectedRow:null,selectedReviewType:type,isDataReady:true,isSnackOpen:true,isGreen:isGreen,selectedType:color==="Amber"?"any":"overall",finerGrain:-1,selectedStat:stat});
        }
        setTimeout((()=>{
            this.setState({isDataViewLoaded:true,isDataReady:true,isDataLoading:false});
        }),500)
    }

    handleHeaderRow(color,type,finerGrain,value,stat){
        AqaComponent.touch();
        if(value ===0) return;
        this.waitCursor();
        this.setState({isDataViewLoaded:true,isDataLoading:false,isWaiting:true});
        let isActive = this.state.selectedColor===color && this.state.selectedType=== type && this.state.finerGrain === finerGrain;
        let isAmber = color==="RedAmber" || color ==="Amber"?true:false;
        let isRed = color==="RedAmber" || color ==="Red"?true:false;
        let isOverallSelected = true;
        this.props.dataFromMain.handleSnapshotFilter2(this.props.dataFromSource,this.state.latestSnapshot,null,isAmber,isRed,null,null,isOverallSelected,false,type,finerGrain);

        if(isActive){
            this.props.dataFromMain.handleSelectedStat(null);
            this.setState({isOverallSelected:false,selectedColor:"Green",selectedColumn:null,selectedColumnIndex:"-1",selectedColumnId:null,isAmber:false,isRed:false,isGreen:false,isDataViewLoaded:false,selectedRow:null,selectedReviewType:-1,selectedType:"overall",finerGrain:-1,isSnackOpen:false,selectedStat:null});
            this.normalCursor();
        }
        else{
            this.props.dataFromMain.handleSelectedStat(stat);
            this.setState({isOverallSelected:true,selectedColor:color,selectedColumn:null,selectedColumnIndex:"-1",selectedColumnId:null,isAmber:isAmber,isRed:isRed,isGreen:false,isDataViewLoaded:false,selectedRow:null,selectedReviewType:-1,selectedType:type,finerGrain:finerGrain,isDataReady:true,isSnackOpen:true,selectedStat:stat});
        }
        setTimeout((()=>{
            this.setState({isDataViewLoaded:true,isDataReady:true,isDataLoading:false});
            //this.normalCursor();
        }),750)
    }

    handleShowEditor(column,index){
        if(column === null){
            let columns = this.state.columnData.filter((d)=>{return d.index===index});
            if(columns.length>0) column = columns[0];
            else return;
        }
        let type = column.type;
        type = type==="String"?2:(type==="Number"?3:(type==="Date"?4:1));
       if(this.props.dataFromParent && this.props.dataFromParent.vqdEditor) {
           this.props.dataFromParent.vqdEditor.handleSuppress(true);
           this.props.dataFromParent.vqdEditor.handleRuleEditor();
           this.props.dataFromParent.vqdEditor.handleShowRulesEditor(index, column.name, type)
       }
    }

    handleSelectedRow(row,column){
        AqaComponent.touch();
        this.setState({selectedRow:row,sColumnId:column,selectedColumnFilterRows:[],selectedColumnStatus:row!==null && row.state.os!==""?row.state.os:"",isBulkOn:false});
        let pos = row!==null?row.props.originalPosition:null;
        this.dataAnalyser.dataViewer.handleSelectedRow(pos);
        //console.log(row);
        this.handleSelectedValue(row,column)
        //this.handleReloadStats(this.state.latestSnapshot);
        this.setState({whichStatus:row!==null?row.props.os:0})

    }

    handleSelectedValue = (row,column)=>{
        if(row === null || row ==="" || row === undefined) return;
        if(column === null || column === "" || column === undefined) return;
        //let oPos = this.state.selectedRow.props.originalPosition;
        let currentRowsWindow = this.rowCacheObject[this.dataAnalyser.currentView.id];

        if (!currentRowsWindow) this.rowCacheObject[this.dataAnalyser.currentView.id] = currentRowsWindow = new RowArrayCachingObject(this.dataAnalyser.currentView.id);
// else onsole.log("Pumping F.1");

        currentRowsWindow.load(0, this.state.latestSnapshot.numberOfRows,
            ()=>{this.handleSelectedValueAllRowsWithFilter(this.state.latestSnapshot,this.rowCacheObject[this.dataAnalyser.currentView.id],column,row.props.text,row)});
    }

    handleSelectedValueAllRowsWithFilter(snapshot,rowCache,index,value,selectedRow){
        //console.log(rowCache);
        if(rowCache.rows.length===0) return;
        if(index===undefined || index===null || index===-1 || index==="-1") return;
        if(value===undefined) return;
        let rows = [];
        let rowIndexes = [];
        let caom = ColourAndOverrideMaps.cache[snapshot.id];
        let overrideMap = caom.overrideMap;
        for(let i=0;i<rowCache.rows.length;i++){
            let row = rowCache.rows[i];
            let valueObject = row.valuesMap[index];
            if(valueObject === value){
                let os = overrideMap.overrideStateForCellInColOrientation(row.originalPosition,index);
                var rowObject = {pos:row.originalPosition,value:value,index:index,os:os};
                if(selectedRow.props.originalPosition!== row.originalPosition) rows.push(rowObject);
                rowIndexes.push(row.originalPosition);
            }
        }
        this.setState({selectedColumnFilterRows:rows,isBulkOn:true,isBulkUpdateOn:true});
    }

    handleChange = (e)=>{
        const {name,value} = e.target;
        this.setState({[name]:value,errorMessage:"."});
    }

    handleCancelSuggestedAs = ()=>{
        this.setState({openSuggestions:false,errorMessage:"."});
    }

    handleSuggestedValueForSelectedAs = ()=>{
        const {notes,suggestedValue,whichStatus} = this.state;
        //alert("What is the suggested value "+whichStatus);
        if(whichStatus === 0) return;
        else if(whichStatus === 1) {
            if(notes === "" || notes === null || notes === undefined) {
                //this.handleSelectedRowStatusAs(whichStatus);
            }
            else this.handleSuggestedStatusAfterSuggestionAs(notes,"",whichStatus);
        }
        else if(whichStatus === 2){
            let errors = [];
            if(notes === "" || notes === null || notes === undefined) errors.push(" Notes");
            if(suggestedValue === "" || suggestedValue === null || suggestedValue === undefined) errors.push(" Suggested Value");
            if(errors.length===2){
                //this.handleSelectedRowStatusAs(whichStatus);
            }
            else this.handleSuggestedStatusAfterSuggestionAs(notes,suggestedValue,whichStatus);
        }
        else return;
    }

    handleSuggestedStatusAfterSuggestionAs = (notes,suggestedValue,whichStatus)=>{
        if(this.state.selectedRow === null || this.state.selectedRow === undefined) {
            this.reportError("A problem occurred setting user action data in the frontend. Please select a row", "AqaSnapshotOverview.handleSelectRowAs, frontend call: " ,this);
            return;
        }
        let oPos = this.state.selectedRow.props.originalPosition;
        let oCPos = this.state.selectedRow.props.x;
        //let rag = this.state.selectedRow.props.rag;
        let snapshot = this.state.latestSnapshot;
        if(snapshot === null || snapshot === undefined) {
            this.reportError("A problem occurred setting user action data in the frontend. Please select a row", "AqaSnapshotOverview.handleSelectRowAs, frontend call: " ,this);
            return;
        }
        let note = {snapshotId:snapshot.id,row:oPos,column:oCPos,note:notes,suggestion:suggestedValue};
        AqaComponent.reviewBackend.saveCellNoteUsingPUT(
            note,
            (error,data,response)=> {
                if (error) this.reportError("A problem occurred setting cell note user action data to the server. "+error, "AqaSnapshotOverview.handleSuggestedStatusAfterSuggestionAs, backend call: " + error, this);
                else {
                    if(data.success){
                        this.dataAnalyser.dataViewer.handleSelectedRowNote(oPos,oCPos,data.note);
                        this.dataAnalyser.dataViewer.handleSelectedRow(oPos);
                        this.setState({openSuggestions:false});
                        //this.handleSelectedRowStatusAs(whichStatus);
                    }
                    else{
                        this.reportError("A problem occurred setting cell note user action data to the server. Error: "+data.message, "AqaSnapshotOverview.handleSuggestedStatusAfterSuggestionAs, backend call: " + error, this);
                    }
                }
            }
        );

    }

    handleSelectedRowAs = (status)=>{
        if(this.state.selectedRow === null || this.state.selectedRow === undefined) {
            this.reportError("A problem occurred setting user action data in the frontend. Please select a row", "AqaSnapshotOverview.handleSelectRowAs, frontend call: " ,this);
            return;
        }
        //let oPos = this.state.selectedRow.props.originalPosition;
        //let oCPos = this.state.selectedRow.props.x;
        let rag = this.state.selectedRow.props.rag;
        //let notesObj = this.state.selectedRow.props.content!==undefined?this.state.selectedRow.props.content.note:"";
        //let notes = notesObj!== undefined && notesObj!==""?notesObj:null;
        let value = status ==="1"?1:(status ==="2"?2:(rag===2?0:0));
        this.handleSelectedRowStatusAs(value);
        this.setState({whichStatus:value});
        /*if(value === 0)
        else{
            let note = "";
            let suggestedValue = "";
            if(notes!== undefined && notes!== null && notes!==""){
                note = notes.note;
                suggestedValue = notes.suggestion;
            }
            this.setState({openSuggestions:true,whichStatus:value,notes:note,suggestedValue:suggestedValue});

        }*/
    }

    handleSelectedRowComment = (status)=>{
        if(this.state.selectedRow === null || this.state.selectedRow === undefined) {
            this.reportError("A problem occurred setting user action data in the frontend. Please select a row", "AqaSnapshotOverview.handleSelectRowAs, frontend call: " ,this);
            return;
        }
        this.removeAllNotifications();
        //let oPos = this.state.selectedRow.props.originalPosition;
        //let oCPos = this.state.selectedRow.props.x;
        let rag = this.state.selectedRow.props.rag;
        let notesObj = this.state.selectedRow.props.content!==undefined?this.state.selectedRow.props.content.note:"";
        let notes = notesObj!== undefined && notesObj!==""?notesObj:null;
        status = this.state.whichStatus;
        let value = status ==="1" || status === 1?1:(status ==="2" || status === 2?2:(rag===2?0:0));
        if(value === 0) return;
        else{
            let note = "";
            let suggestedValue = "";
            if(notes!== undefined && notes!== null && notes!==""){
                note = notes.note;
                suggestedValue = notes.suggestion;
            }
            this.setState({openSuggestions:true,whichStatus:value,notes:note,suggestedValue:suggestedValue});

        }
    }


    handleSelectedRowStatusAs(status){
        //onsole.log(status);
        AqaComponent.touch();
        if(this.state.selectedRow === null || this.state.selectedRow === undefined) {
            this.reportError("A problem occurred setting user action data in the frontend. Please select a row", "AqaSnapshotOverview.handleSelectRowAs, frontend call: " ,this);
            return;
        }
        let oPos = this.state.selectedRow.props.originalPosition;
        let oCPos = this.state.selectedRow.props.x;
        let rag = this.state.selectedRow.props.rag;
        let value = status ==="1" || status === 1 ?1:(status ==="2" || status === 2?2:(rag===2?0:0));
        let snapshot = this.state.latestSnapshot;
        if(snapshot === null || snapshot === undefined) return;

        AqaComponent.reviewBackend.updateCellStatusUsingPUT(
            snapshot.id,
            "row",
            {position:oPos,index:oCPos,value:value},
            (error,data,response)=> {
                if (error) this.reportError("A problem occurred getting user action data back from the server. "+error, "AqaSnapshotOverview.handleSelectRowAs, backend call: " + error,this);
                else
                {

                	if (data.success)
                	{
//console.log(data);
                    this.dataAnalyser.dataViewer.handleSelectedRowPos(oPos,data.rowStatus);
                    this.dataAnalyser.dataViewer.handleSelectedRowUpdate(oPos,this.state.selectedRow.props.x,value);
                    this.dataAnalyser.dataViewer.handleSelectedRow(oPos);
                    this.handleReloadStats(this.state.latestSnapshot,rag!==2);
                    this.handleSelectedValue(this.state.selectedRow,this.state.selectedRow.props.x);
                    //this.setState({selectedRow:null});
                    this.setState({selectedColumnStatus:value,isBulkOn:true,isBulkUpdateOn:true,openSuggestions:false});

                	}
                	else this.reportError("A problem occurred setting user action on the server. "+data.message, "AqaSnapshotOverview.handleSelectRowAs, backend call: " + data.message, this);
		
                }
            }
        );

    }

    handleSelectedAllRowAs(status){
        if(this.state.selectedRow === null) return;
        //let oPos = this.state.selectedRow.props.originalPosition;
        let value = status ==="1" || status===1?1:(status ==="2" || status===2?2:0);

        let currentRowsWindow = this.rowCacheObject[this.dataAnalyser.currentView.id];

        if (!currentRowsWindow) this.rowCacheObject[this.dataAnalyser.currentView.id] = currentRowsWindow = new RowArrayCachingObject(this.dataAnalyser.currentView.id);
// else onsole.log("Pumping F.1");

        currentRowsWindow.load(0, this.state.latestSnapshot.numberOfRows,
            ()=>{this.handleSelectedAllRowsWithFilter(this.state.latestSnapshot,this.rowCacheObject[this.dataAnalyser.currentView.id],this.state.selectedRow.props.x,this.state.selectedRow.props.text,value)});
    }

    handleSelectedAllRowsWithFilter(snapshot,rowCache,index,value,status){
        //onsole.log(rowCache);
        if(rowCache.rows.length===0) return;
        if(index===undefined || index===null || index===-1 || index==="-1") return;
        if(value===undefined) return;
        let rows = [];
        let rowIndexes = [];
        for(let i=0;i<rowCache.rows.length;i++){
            let row = rowCache.rows[i];
            let valueObject = row.valuesMap[index];
            if(valueObject === value){
                var rowObject = {pos:row.originalPosition,value:value,index:index};
                rows.push(rowObject);
                rowIndexes.push(row.originalPosition);
            }
        }
        //console.log(rows);
        //console.log(rowIndexes);
        AqaComponent.reviewBackend.updateCellStatusInBulkUsingPUT(
            snapshot.id,
            "row",
            {listOfIndividualRowIndices:rowIndexes,newValue:status},
            {index:index},
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered. "+error,
                    "AqaSourceListRow.handleSnapshot, backend call: " + error,this);
                else {
                    //console.log(data);
                    let rowStatuses = data.rowStatuses;
                    if(rowStatuses!== null){
                        for(let l=0;l<rowStatuses.length;l++){
                            let rowStatus = rowStatuses[l];
                            this.dataAnalyser.dataViewer.handleSelectedRowPos(rowStatus.position,rowStatus.rowStatus);
                            this.dataAnalyser.dataViewer.handleSelectedRowUpdate(rowStatus.position,index,status);
                        }
                    }
                    this.dataAnalyser.dataViewer.handleSelectedRow(this.state.selectedRow.props.originalPosition);
                    this.handleReloadStats(this.state.latestSnapshot,false);
                    this.setState({isBulkOn: true});
                    this.handleSelectedValue(this.state.selectedRow,this.state.selectedRow.props.x);
                    //this.dataAnalyser.dataViewer.handleSelectedRowPos(rw.pos, status);


                }
            }
        );
        /*AqaComponent.reviewBackend.updateStatusInBulkUsingPUT(
            snapshot.id,
            "row",
            {listOfIndividualRowIndices:rowIndexes,newValue:status},
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSourceListRow.handleSnapshot, backend call: " + error,this);
                else {
                    //this.dataAnalyser.dataViewer.handleSelectedRowPos(rw.pos, status);
                    let m = rows.length;
                    for(let k=0;k<rows.length;k++) {
                        let rw = rows[k];
                        this.dataAnalyser.dataViewer.handleSelectedRowPos(rw.pos, status);
                        if(k===m-1) {
                            this.dataAnalyser.dataViewer.handleSelectedRow(null);
                            this.handleReloadStats(this.state.latestSnapshot);
                            this.setState({isBulkOn: false});
                        }
                    }


                }
            }
        );*/
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.dataFromGrid.status==="Loading"){
            let verticalHealthStats=[
                {id:"Table_Health_Stat_0",name:"Alert Type",description:"List of Alert Types",numR:"Amber",numI:"Red",numF:"Health"},
                {id:"Table_Health_Stat_1",name:"Missing?",description:"Does the data contain any cells that have missing data?",numR:0,numI:0,numF:-1},
                {id:"Table_Health_Stat_2",name:"Duplicate?",description:"For columns containing alpha-numeric data, are any values duplicated?",numR:0,numI:0,numF:-1},
                {id:"Table_Health_Stat_3",name:"Wrong Type?",description:"If a column contains data of one type, are there any values in any cells of a different type?",numR:0,numI:0,numF:-1},
                {id:"Table_Health_Stat_4",name:"Bad Format?",description:"If there are columns with specific format types, are there any cells that do not conform?",numR:0,numI:0,numF:-1},
                {id:"Table_Health_Stat_5",name:"Outlier?",description:"In specific columns, do any cells contain values significantly outside of the range of the data contained in the other cells?",numR:0,numI:0,numF:-1},
                {id:"Table_Health_Stat_6",name:"Invalid?",description:"In columns with a specific set of repeated values, are there any cells that look different?",numR:0,numI:0,numF:-1},
                {id:"Table_Health_Stat_7",name:"Excel Error?",description:"Are there any cells that contain native excel errors?",numR:0,numI:0,numF:-1},
            ]
            this.setState({isLoaded:true,stats:[],columnmap:null,isReady:false,isPostLoading:true,loadingLogs:[],latestSnapshot:null,columnData:[],heatmapData:null,listProgress:0,verticalHealthStats:verticalHealthStats});
            this.handleSnapshotProgress(nextProps.dataFromGrid);
        }
        else if(!nextProps.dataFromGrid.sheetChosen && !nextProps.dataFromSource.example){
            this.setState({isLoaded:true,stats:[],columnmap:null,isReady:false});
            this.handleSnapshotIsLoaded(this.props.dataFromGrid);
        }
        else if(nextProps.dataFromGrid.status==="PostLoading" || nextProps.dataFromGrid.status==="PreLoading"){
            this.setState({isLoaded:true,stats:[],columnmap:null,isReady:false,isPostLoading:true});
            //this.handleSnapshotProgress(nextProps.dataFromGrid);
        }

        else {
            this.setState({isDataViewLoaded:false,isSnackOpen:false})
            let isRedOrAmber = (nextProps.dataFromMain.state.isOverallSelected)?true:false
            let isRedOrAmber1 = (nextProps.dataFromMain.state.isAmber || nextProps.dataFromMain.state.isRed)?true:false
            let {isRed,isAmber,isGreen,selectedType,finerGrain,selectedStat} = nextProps.dataFromMain.state;
            let color=isRed && isAmber && isGreen?"RedAmberGreen":(isRed && isGreen?"RedGreen":(isRed && isAmber?"RedAmber":(isRed?"Red":(isAmber?"Amber":"Green"))))
            this.setState({isReady:true,isAmber:nextProps.dataFromMain.state.isAmber,isRed:nextProps.dataFromMain.state.isRed,isGreen:nextProps.dataFromMain.state.isGreen,isOverallSelected:isRedOrAmber,selectedReviewType:nextProps.dataFromMain.state.selectedReviewType,selectedColor:color,finerGrain:finerGrain,selectedType:selectedType,selectedStat:selectedStat});
            //this.handleGetRulesForSource(nextProps.dataFromSource, nextProps.dataFromGrid);
            //this.handleAccountDetails();
            //onsole.log(isRedOrAmber);
            if(isRedOrAmber || isRedOrAmber1) {
                setTimeout((()=>{
                    this.setState({isDataViewLoaded:true,isSnackOpen:true});
                }),500)
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showHistory !== prevProps.showHistory || this.props !== prevProps) {
            if(this.props.dataFromGrid.status==="Loading"){

            }
            else if(this.props.dataFromGrid.status==="PostLoading" || this.props.dataFromGrid.status==="PreLoading"){

            }
            else {
                //this.handleReloadStats(this.props.dataFromGrid);
                let latestSnaps = this.props.dataFromGrid.snapshotScores.filter((d) => {
                    return d.id === this.props.dataFromGrid.id
                });
                this.handleGetRulesForSource(this.props.dataFromSource, this.props.dataFromGrid);
                this.setState({latestSnapshot: latestSnaps.length>0?latestSnaps[0]:null});
            }
            //this.handleSnapshotIsLoaded(this.props.dataFromGrid);
            /*let isRedOrAmber = (this.props.dataFromMain.state.isOverallSelected)?true:false
            let isRedOrAmber1 = (this.props.dataFromMain.state.isAmber || this.props.dataFromMain.state.isRed)?true:false
            this.setState({isDataViewLoaded: false,selectedColumn:null,selectedColumnId:null,isAmber:this.props.dataFromMain.state.isAmber,isRed:this.props.dataFromMain.state.isRed,isGreen:this.props.dataFromMain.state.isGreen,isOverallSelected:isRedOrAmber});
            if(isRedOrAmber || isRedOrAmber1) {
                setTimeout((() => {
                    this.setState({isDataViewLoaded: true,isSnackOpen:true})
                }), 1000)
            }*/
        }
    }

    async componentDidMount(){
        if(this.props.dataFromGrid === null || this.props.dataFromSource === null){
            this.setState({isLoaded:true,stats:[],columnmap:null,isReady:false});
        }
        else if(this.props.dataFromGrid.status==="Loading"){
            this.setState({isLoaded:true,stats:[],columnmap:null,isReady:false,isPostLoading:true,loadingLogs:[]});
            this.waitCursor();
            this.scrollToBottom();
            this.handleSnapshotProgress(this.props.dataFromGrid);
        }
        else if(!this.props.dataFromGrid.sheetChosen && !this.props.dataFromSource.example){
            this.scrollToBottom();
            let loadingLogs = this.state.loadingLogs;
            for(let i=1;i<=3;i++){
                let customMessages = this.state.customMessages.filter((d)=>{return d.step === i});
                var message = customMessages[0].message;
                if(i===1) message = "Uploading file - "+this.props.dataFromGrid.label;
                if(i===4) {
                    if(this.props.dataFromGrid.snapshotScores !== undefined && this.props.dataFromGrid.snapshotScores.length>0) message = message +this.props.dataFromGrid.snapshotScores[0].numberOfRows+" Rows "+ this.props.dataFromGrid.snapshotScores[0].numberOfColumns+" Columns";
                    else message = message +"0 Rows 0 Columns";
                }
                var loger = {id:i,message:message,step:i,currentMessage:"Calculating alert counts"};
                loadingLogs.push(loger);
            }
            this.setState({isLoaded:true,stats:[],columnmap:null,isReady:false,isPostLoading:true,loadingLogs:loadingLogs.sort(this.GetAscSortOrder('id'))});
            this.handleSnapshotIsLoaded(this.props.dataFromGrid);
        }
        else if(this.props.dataFromGrid.status==="PostLoading" || this.props.dataFromGrid.status==="PreLoading"){
            // Old logs goes here
            this.waitCursor();
            this.scrollToBottom();
            let loadingLogs = this.state.loadingLogs;
            for(let i=1;i<=10;i++){
                let customMessages = this.state.customMessages.filter((d)=>{return d.step === i});
                message = customMessages[0].message;
                if(i===1) message = "Uploading file - "+this.props.dataFromGrid.label;
                if(i===4) {
                    if(this.props.dataFromGrid.snapshotScores !== undefined && this.props.dataFromGrid.snapshotScores.length>0) message = message +this.props.dataFromGrid.snapshotScores[0].numberOfRows+" Rows "+ this.props.dataFromGrid.snapshotScores[0].numberOfColumns+" Columns";
                    else message = message +"0 Rows 0 Columns";
                }
                loger = {id:i,message:message,step:i,currentMessage:"Calculating alert counts"};
                loadingLogs.push(loger);
            }
            this.setState({isLoaded:true,stats:[],columnmap:null,isReady:false,isPostLoading:true,progress:100,uploadStatus:"File analysis completed "+this.props.dataFromGrid.label,loadingLogs:loadingLogs.sort(this.GetAscSortOrder('id'))});
            this.handleGetRulesForSource(this.props.dataFromSource, this.props.dataFromGrid);
        }
        else {
            let isRedOrAmber = (this.props.dataFromMain.state.isAmber || this.props.dataFromMain.state.isRed)?true:false;
            let {isRed,isAmber,isGreen,selectedType,finerGrain,selectedStat} = this.props.dataFromMain.state;
            let color=isRed && isAmber && isGreen?"RedAmberGreen":(isRed && isGreen?"RedGreen":(isRed && isAmber?"RedAmber":(isRed?"Red":(isAmber?"Amber":"Green"))))
            this.setState({isReady:true,isAmber:this.props.dataFromMain.state.isAmber,isRed:this.props.dataFromMain.state.isRed,isGreen:this.props.dataFromMain.state.isGreen,isOverallSelected:isRedOrAmber,selectedReviewType:this.props.dataFromMain.state.selectedReviewType,selectedColor:color,selectedType:selectedType,finerGrain:finerGrain,selectedStat:selectedStat});
            this.handleGetRulesForSource(this.props.dataFromSource, this.props.dataFromGrid);
            this.handleAccountDetails();
            if(isRedOrAmber) {
                setTimeout((()=>{
                    this.setState({isDataViewLoaded:true,isSnackOpen:true})
                }),500)
            }
        }
        this.handleUsers();
    } // componentDidMount
    
    
    
    
	render()
	{
		const { classes } = this.props;
		const { error, isLoaded, redirect, notfound, isReady, progress, uploadStatus, columnData,isOverallSelected,selectedColumn,selectedColumnId,isAmber,isRed,isGreen,selectedColumnIndex,isDataViewLoaded,selectedRow,errorMessage,isPostLoading,listProgress,isPendingLoading,abnormalColumns,abnormalCorrectedColumns,showReview,selectedStat,isWaiting } = this.state;
        //onsole.log(this.props);
        let borderStyle = {padding:"4px"};
        let borderStyle1 = {padding:"0px 4px"};
        let noborderStyle = {padding:"4px",border:"none"};
        let noborderStyle1 = {padding:"0px 4px",border:"none"};
        let loadingLogsLength = this.state.loadingLogs.length;
		if(redirect)
		{
			//return <Redirect to="/login" push={true} />
		}
		else if (notfound && error)
		{
			return (
				<div className={this.props.dataFromParent.state.showHistory===true?"source-detail-servant-pane":"source-detail-servant-pane-one"}>
					<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

						<Grid item xs={10} sm={7} lg={11} xl={11}>
							<Typography
								variant="title"
								color="inherit"
								align="left"
								style={{ fontSize: '1.5rem',
								padding: '0.1em 0.5em',
								fontFamily: 'Open Sans, sans-serif'}}
							>
								Nothing to display
							</Typography>
						</Grid>
						<Grid item xs={2} sm={1} lg={1} xl={1}></Grid>
					</Grid>
				</div>
			);
		}
		else if (error && !notfound)
		{
			return (
				<div>
					<Typography
						variant="title"
						color="inherit"
						align="left"
						style={{ fontSize: '1.5rem',
						padding: '3.1em 0.5em',
						fontFamily: 'Open Sans, sans-serif'}}
					>
						Error: {error.message}
					</Typography>


				</div>
			);
		}
		else if (!isLoaded)
		{
			return <div></div>;
		}
		else
		{
		
		
			return (
				<div className={this.props.dataFromParent.state.showHistory===true ? "source-detail-servant-pane":"source-detail-servant-pane-one"} style={{height:window.innerHeight-170}} onClick={(e)=>this.removeAllNotifications()}>
					<div style={{boxShadow:"0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)", backgroundColor:"white", marginBottom:"2px", marginTop:"2px", marginRight:"4px", marginLeft:"6px"}}>

						<AqaSnapshotHeader
							dataForPercent={this.state.percentage}
							dataForRAG={this.state.rag}
							dataFromParent={this.props.dataFromGrid}
							dataFromRoot={this.props.dataFromRoot}
							dataFromSource={this.props.dataFromSource} controller={this}
							dataForMap={this.state.columnmap} dataForStats={isReady===true?this.state.stats:[]} dataForHealthStats={isReady===true?this.state.healthStats:[]} dataForReviewStats={isReady===true?this.state.reviewStats:[]} dataForScore={isReady===true || isPostLoading===true?this.state.latestSnapshot:null}
							heatmapData={isReady===true?this.state.heatmapData:null}
                            isPostLoading={isPostLoading}
                            isWaiting={isWaiting}
						/>
					</div>

					<div style={{paddingTop:"0px",paddingLeft:"3px"}}>
						{
                            isPendingLoading === true?
                                <div className={"aqa-custom-loader"}>
                                    <div className={"aqa-loading-icon"}>
                                        <LoadingIcon fill={"#4cadc4"} style={{width:"80px"}}/>
                                    </div>
                                </div>
                                :(
							isPostLoading===true?
                                (
                                    <div style={{padding:"3px",marginLeft:"0px",marginTop:"-4px"}}>
                                        <div className="">
                                            <Card width="100%">
                                                <CardContent style={{padding:"4px 12px"}}>
                                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                            <div>
                                                                <div>
                                                                    <LinearProgress variant="determinate" value={progress} style={{height:"30px"}} />
                                                                </div>
                                                                <div style={{paddingTop:"10px",paddingBottom:"10px"}}>

                                                                    <Typography variant="inherit" className="aqa-text-bold-16" style={{textAlign: "left", width: "100%",fontWeight:"bold",fontSize:"12px",fontFamily:"Montserrat"}}>
                                                                        {uploadStatus}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

                                                                <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                                    <div style={{background: "none",border:"1px solid rgba(224, 224, 224, 1)",paddingTop:"2px",height:"188px"}}>
                                                                        <Table id={this.props.dataFromParent.id+"_statstable"} cellSpacing={0} aria-sort="ascending" style={{}}>
                                                                            <colgroup>
                                                                                <col width="100%" />
                                                                            </colgroup>
                                                                            <TableHead>

                                                                            </TableHead>
                                                                            <TableBody id={"logs"} style={{
                                                                                minHeight:loadingLogsLength>10?"185px":"",
                                                                                'height': loadingLogsLength>10?"185px":"",
                                                                                'overflowY': 'auto',
                                                                                display: 'grid',
                                                                                scrollbarGutter:"stable",
                                                                                //width: this.props.dataFromParent.state.showHistory === true ? window.innerWidth * 0.715 : (selectedColumn !== null ? window.innerWidth * 0.99 : window.innerWidth * 0.982)
                                                                                width: this.props.dataFromParent.state.showHistory === true ? "100%" : (selectedColumn !== null ? "100%" : "100%")
                                                                            }}>
                                                                                {this.state.loadingLogs.map((item,index)=>
                                                                                    <TableRow key={index} className={"aqa-violation-folder"} style={{display:"contents",cursor:"default",height:"auto"}} >
                                                                                                <TableCell width="100%" style={index>-1?noborderStyle1:borderStyle}>
                                                                                                    <div>
                                                                                                            <Typography className="aqa-text-bold-11" variant="inherit"
                                                                                                                        style={{
                                                                                                                            textAlign: "left",
                                                                                                                            color: index === 50 ? "grey" : "#181818",
                                                                                                                            padding: "0px 4px",
                                                                                                                            wordBreak:"break-all"
                                                                                                                        }}>
                                                                                                                {item.message}
                                                                                                            </Typography>
                                                                                                        </div>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                )
                                                                                }
                                                                            </TableBody>
                                                                        </Table>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                                    <div style={{background: "none",paddingLeft:"10px"}}>
                                                                        <Table id={this.props.dataFromParent.id+"_statstable"} cellSpacing={0} aria-sort="ascending" style={{}}>
                                                                            <colgroup>
                                                                                <col width="55%" />
                                                                                <col width="15%" />
                                                                                <col width="15%" />
                                                                                <col width="15%" />
                                                                            </colgroup>
                                                                            <TableHead>

                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {this.state.verticalHealthStats.map((item,index)=>

                                                                                    (index>0?(
                                                                                        (

                                                                                            <TableRow key={item.id} className={"aqa-violation-folder"} style={{height:"18px",cursor:"default"}}>
                                                                                                <TableCell width="55%" style={index>6?noborderStyle:borderStyle}>
                                                                                                    {index === 0 ? <div>
                                                                                                            <Typography className="aqa-text-bold-11" variant="inherit"
                                                                                                                        style={{
                                                                                                                            textAlign: "left",
                                                                                                                            color: index === 0 ? "grey" : "#181818",
                                                                                                                            height: "16px",
                                                                                                                            padding: "4px 0px"
                                                                                                                        }}>
                                                                                                                {item.name}
                                                                                                            </Typography>
                                                                                                        </div> :
                                                                                                        <HtmlTooltip enterDelay={500} key={item.id}
                                                                                                                     title={<React.Fragment>
                                                                                                                         <div>
                                                                                                                             <Typography color="inherit"
                                                                                                                                         className="aqa-text-action">{item.description}</Typography>
                                                                                                                         </div>

                                                                                                                     </React.Fragment>} arrow={"true"}
                                                                                                                     placement="bottom-start">
                                                                                                            <div>

                                                                                                                <Typography className="aqa-text-bold-11"
                                                                                                                            variant="inherit" style={{
                                                                                                                    textAlign: "left",
                                                                                                                    color: index === 0 ? "grey" : "#181818",
                                                                                                                    height: "16px",
                                                                                                                    padding: "4px",
                                                                                                                    whiteSpace:"nowrap",
                                                                                                                    width:"100px"
                                                                                                                }}>
                                                                                                                    {item.name}
                                                                                                                </Typography>
                                                                                                            </div>
                                                                                                        </HtmlTooltip>
                                                                                                    }
                                                                                                </TableCell>
                                                                                                <TableCell width="15%" style={index>6?noborderStyle:borderStyle}>
                                                                                                    <Typography className={item.numR>0?"aqa-text-bold-14":"aqa-text-bold-14"} variant="inherit" style={{
                                                                                                        textAlign: "right",
                                                                                                        color: index===0?"lightgrey":(index>1?(item.numR>0?"rgb(251, 154, 40)":"lightgrey"):(item.numR>0?"rgb(251, 154, 40)":"lightgrey")),
                                                                                                        paddingLeft: "0px",
                                                                                                        padding: "0px",

                                                                                                        height:"16px",
                                                                                                        fontWeight:item.numR>0?"bold":"400"
                                                                                                    }}>
                                                                                                        {item.numR.toLocaleString()}

                                                                                                    </Typography>
                                                                                                </TableCell>
                                                                                                <TableCell width="15%" style={index>6?noborderStyle:borderStyle}>
                                                                                                    <Typography className={item.numI>0?"aqa-text-bold-14":"aqa-text-bold-14"} variant="inherit" style={{
                                                                                                        textAlign: "right",
                                                                                                        color: index===0?"grey":(index>0?(item.numI>0?"red":"lightgrey"):(item.numI>0?"red":"lightgrey")),
                                                                                                        paddingLeft: "0px",
                                                                                                        padding: "0px",
                                                                                                        height:"16px",
                                                                                                        fontWeight:item.numI>0?"bold":"400"
                                                                                                    }}>
                                                                                                        {item.numI.toLocaleString()}

                                                                                                    </Typography>
                                                                                                </TableCell>
                                                                                                <TableCell width="15%" style={index>6?noborderStyle1:borderStyle1}>
                                                                                                    {listProgress===100?
                                                                                                    <Typography className={item.numF>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                        textAlign: "right",
                                                                                                        color: index===0?"grey":(index>0?(item.numF===0?"red":(item.numF===1?"rgb(251, 154, 40)":"#181818")):(item.numF===1?"rgb(251, 154, 40)":"#181818")),
                                                                                                        paddingLeft: "4px",
                                                                                                        padding: index>0?"0px":"4px",
                                                                                                        height:"5px",
                                                                                                        fontWeight:item.numF>0?"bold":"400",
                                                                                                        backgroundColor: (index>0?(item.numF===-1?"":(item.numF===0?"red":(item.numF===1?"rgb(251, 154, 40)":"green"))):"")

                                                                                                    }}>

                                                                                                    </Typography>
                                                                                                        :
                                                                                                    <div>
                                                                                                        <LinearProgress variant="determinate" value={listProgress} classes={{colorPrimary: item.numF===0?classes.colorPrimary:(item.numF===1?classes.colorSecondary:classes.colorTerritary), barColorPrimary: item.numF===0?classes.barColorPrimary:(item.numF===1?classes.barColorSecondary:classes.barColorTerritary)}} style={{height:"5px"}} />
                                                                                                    </div>
                                                                                                    }
                                                                                                </TableCell>

                                                                                            </TableRow>

                                                                                        )
                                                                                    ):(
                                                                                        (

                                                                                            <TableRow key={item.id} className={"aqa-violation-folder"} style={{height:"18px",cursor:"default"}}>
                                                                                                <TableCell width="55%" style={index>6?noborderStyle:borderStyle}>
                                                                                                    {index === 0 ? <div>
                                                                                                            <Typography className="aqa-text-bold-11" variant="inherit"
                                                                                                                        style={{
                                                                                                                            textAlign: "left",
                                                                                                                            color: index === 0 ? "grey" : "#181818",
                                                                                                                            height: "16px",
                                                                                                                            padding: "4px"
                                                                                                                        }}>
                                                                                                                {item.name}
                                                                                                            </Typography>
                                                                                                        </div> :
                                                                                                        <HtmlTooltip enterDelay={500} key={item.id}
                                                                                                                     title={<React.Fragment>
                                                                                                                         <div>
                                                                                                                             <Typography color="inherit"
                                                                                                                                         className="aqa-text-action">{item.description}</Typography>
                                                                                                                         </div>

                                                                                                                     </React.Fragment>} arrow={"true"}
                                                                                                                     placement="bottom-start">
                                                                                                            <div>
                                                                                                                <Typography className="aqa-text-bold-11"
                                                                                                                            variant="inherit" style={{
                                                                                                                    textAlign: "left",
                                                                                                                    color: index === 0 ? "grey" : "#181818",
                                                                                                                    height: "16px",
                                                                                                                    padding: "4px",
                                                                                                                    width: "140px"
                                                                                                                }}>
                                                                                                                    {item.name}
                                                                                                                </Typography>
                                                                                                            </div>
                                                                                                        </HtmlTooltip>
                                                                                                    }
                                                                                                </TableCell>
                                                                                                <TableCell width="15%" style={index>6?noborderStyle:borderStyle}>
                                                                                                    <Typography className={item.numR>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                        textAlign: "right",
                                                                                                        color: index===0?"grey":"#181818",
                                                                                                        paddingLeft: "4px",
                                                                                                        padding: "4px",
                                                                                                        height:"16px",
                                                                                                    }}>
                                                                                                        {item.numR.toLocaleString()}

                                                                                                    </Typography>
                                                                                                </TableCell>
                                                                                                <TableCell width="15%" style={index>6?noborderStyle:borderStyle}>
                                                                                                    <Typography className={item.numI>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                        textAlign: "right",
                                                                                                        color: index===0?"grey":"#181818",
                                                                                                        paddingLeft: "4px",
                                                                                                        padding: "4px",
                                                                                                        height:"16px",
                                                                                                    }}>
                                                                                                        {item.numI.toLocaleString()}

                                                                                                    </Typography>
                                                                                                </TableCell>
                                                                                                <TableCell width="15%" style={index>6?noborderStyle:borderStyle}>
                                                                                                    <Typography className={item.numF>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                        textAlign: "right",
                                                                                                        color: index===0?"grey":"#181818",
                                                                                                        paddingLeft: "4px",
                                                                                                        padding: "4px",
                                                                                                        height:"16px",
                                                                                                    }}>
                                                                                                        {item.numF.toLocaleString()}

                                                                                                    </Typography>
                                                                                                </TableCell>

                                                                                            </TableRow>

                                                                                        )
                                                                                    ))

                                                                                )}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                                    <div style={{marginLeft:"8px",textAlign:"center"}}>
                                                                        {listProgress===100?
                                                                        <div>
                                                                            <Heatmap
                                                                                data={this.state.heatmapData}
                                                                                type={Heatmap.OVERALL_HEATMAP_NAME}
                                                                                scale={AqaComponent.HEATMAP_STAMP_SCALE * 2}
                                                                                numRows={this.state.numRows}
                                                                                numCols={this.state.numCols}
                                                                                clickerCallback = {
                                                                                    (x, w, y, h, n, amber, red, type) =>
                                                                                    {
                                                                                       /* if (this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot) this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter2
                                                                                        (
                                                                                            this.props.dataFromSource,
                                                                                            this.props.dataFromParent,
                                                                                            type,
                                                                                            amber,
                                                                                            red,
                                                                                            [n, x, y, w, h],
                                                                                            null,
                                                                                            true
                                                                                        )*/
                                                                                    }
                                                                                }
                                                                            />
                                                                        </div>
                                                                            :""
                                                                        }
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                            {listProgress===100 && abnormalColumns.length>0?
                                                                <div>
                                                                    <Typography variant="inherit" className="aqa-text-bold-16" style={{textAlign: "center", width: "100%",paddingTop:"16px",paddingBottom:"16px",fontWeight:"bold",fontSize:"12px",fontFamily:"Montserrat"}}>
                                                                        {!this.state.showReview?"Re-calculating alerts":""}
                                                                    </Typography>
                                                                    <Table id={this.props.dataFromGrid.id + "_snaptable"}
                                                                           cellSpacing={0}
                                                                           aria-sort="ascending" style={{width:"100%",display:"block"}}>
                                                                        <colgroup>
                                                                            <col width="5%"/>
                                                                            <col width="30%"/>
                                                                            <col width="35%"/>
                                                                            <col width="20%"/>
                                                                            <col width="10%"/>

                                                                        </colgroup>
                                                                        <TableHead style={{
                                                                            'overflow': 'auto',
                                                                            display: 'table',
                                                                            //width: this.props.dataFromParent.state.showHistory===true ? window.innerWidth * 0.72 : window.innerWidth * 0.992
                                                                            width: "100%"
                                                                        }}>
                                                                            <TableRow style={{height: "18px"}}>
                                                                                <TableCell width="5%"
                                                                                           style={{"padding": "0px 0px 0px 4px"}}>
                                                                                    <div className="aqa-text-bold"></div>
                                                                                </TableCell>
                                                                                <TableCell width="30%"
                                                                                           style={{"padding": "0px 0px 0px 4px"}}>
                                                                                    <div className="aqa-text-bold" style={{paddingLeft:"4px"}}>{isOverallSelected?"":"Empty Column Name"}</div>
                                                                                </TableCell>
                                                                                <TableCell width="35%"
                                                                                           style={{"padding": "0px"}}>
                                                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                        <div>
                                                                                            <Typography color="inherit" className="aqa-text-action">Does the data contain any cells that have missing data?</Typography>
                                                                                        </div>

                                                                                    </React.Fragment>} arrow={"true"} placement="top-start">
                                                                                        <div className="aqa-text-bold"
                                                                                             style={{textAlign: "left"}}>Missing?
                                                                                        </div>
                                                                                    </HtmlTooltip>
                                                                                </TableCell>
                                                                                <TableCell width="20%"
                                                                                           style={{"padding": "0px 0px 0px 0px"}}>
                                                                                    <div className="aqa-text-bold">Do you want to upload this column?</div>
                                                                                </TableCell>
                                                                                <TableCell width="10%"
                                                                                           style={{"padding": "0px 0px 0px 0px"}}>
                                                                                    <div className="aqa-text-bold">Status</div>
                                                                                </TableCell>

                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody style={{
                                                                                'overflowY': 'auto',
                                                                                display: 'block',
                                                                                scrollbarGutter:"auto",
                                                                                //width: this.props.dataFromParent.state.showHistory === true ? window.innerWidth * 0.715 : (selectedColumn !== null ? window.innerWidth * 0.99 : window.innerWidth * 0.982)
                                                                                width: this.props.dataFromParent.state.showHistory === true ? "100%" : (selectedColumn !== null ? "100%" : "100%")
                                                                            }}>
                                                                                {columnData.filter((item) => {
                                                                                    return (abnormalColumns.filter((d)=>{return d === item.index}).length>0);
                                                                                }).map((item, index) => (
                                                                                    <TableRow className="aqa-list" key={item.id}
                                                                                              style={{
                                                                                                  display: 'table',
                                                                                                  tableLayout: 'fixed',
                                                                                                  height: "16px",
                                                                                                  width:"100%",
                                                                                                  cursor:"default"
                                                                                              }}>
                                                                                        <TableCell size="small" width="5%"
                                                                                                   style={{padding: "4px 4px 0px 0px"}}>
                                                                                            <IconButton className="aqa-button"
                                                                                                        style={{
                                                                                                            padding: 0,
                                                                                                            paddingTop: "0px"
                                                                                                        }}>
                                                                                                <div className="aqa-list-icon">
                                                                                                    {QD_ICONS[item.type]}
                                                                                                </div>
                                                                                            </IconButton>
                                                                                        </TableCell>
                                                                                        <TableCell size="small" width="30%"
                                                                                                   style={{padding: "0px 4px 0px 4px"}}>
                                                                                            <div className="aqa-text-bold-12"
                                                                                                 style={{
                                                                                                     textAlign: "left",
                                                                                                     wordBreak: "break-word"
                                                                                                 }}
                                                                                                 >{item.name}</div>
                                                                                        </TableCell>
                                                                                        <TableCell size="small" width="35%"
                                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                                            <Typography className={"aqa-text-action"} variant="title" align="left">
                                                                                                All cells are empty
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell size="small" width="20%"
                                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                                            <HtmlTooltip enterDelay={500} key={item.id}
                                                                                                         title={<React.Fragment>
                                                                                                             <div>
                                                                                                                 <Typography color="inherit"
                                                                                                                             className="aqa-text-action">{"Enable or Disable empty check"}</Typography>
                                                                                                             </div>

                                                                                                         </React.Fragment>} arrow={"true"}
                                                                                                         placement="top-start">
                                                                                                <div>
                                                                                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"16px",color:"#4CADC4",paddingLeft:"4px",fontFamily:"Montserrat",borderBottom:"0px solid #ccc",marginLeft:"0px"}}>
                                                                                                <div id="aqa-number-slider" style={{paddingTop:"0px",paddingLeft:"0px",marginLeft:"8px",marginTop:"0px"}}>
                                                                                                    <FormGroup row>
                                                                                                        <FormControlLabel
                                                                                                            control={
                                                                                                                <Checkbox
                                                                                                                    checked={abnormalCorrectedColumns.filter((d)=> {return d ===item.index}).length===1}
                                                                                                                    onChange={e => this.setRulesEnabled(e.target.checked,item)}
                                                                                                                    color="primary"
                                                                                                                    disabled={!this.state.showReview}
                                                                                                                    style={{padding:"2px"}}
                                                                                                                />
                                                                                                            }
                                                                                                            label={(
                                                                                                                <Typography className={"aqa-text-action"} variant="title" align="left">

                                                                                                                </Typography>
                                                                                                            )}
                                                                                                            labelPlacement="end"
                                                                                                        />
                                                                                                    </FormGroup>
                                                                                                </div>
                                                                                            </Typography>
                                                                                                </div>
                                                                                            </HtmlTooltip>
                                                                                        </TableCell>

                                                                                        <TableCell size="small" width="10%"
                                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                                            <Typography className={"aqa-text-action"} variant="title" align="left">
                                                                                                {item.populatedEnabled?"Enabled":"Disabled"}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    ))}
                                                                                </TableBody>
                                                                        </Table>
                                                                </div>
                                                                :""}
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

                                                                <Grid item xs={12} sm={5} lg={5} xl={5}>
                                                                </Grid>
                                                                <Grid item xs={12} sm={2} lg={2} xl={2}>
                                                            {listProgress===100?
                                                                <Typography variant="inherit" color="inherit" style={{
                                                                    textAlign: "left",
                                                                    fontSize: "0.8rem",
                                                                    width: "100%",
                                                                    paddingTop: "5px"
                                                                }}>
                                                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                    <div>
                                                                        <Typography color="inherit" className="aqa-text-action">Click to proceed for reviewing</Typography>
                                                                    </div>
                                                                </React.Fragment>} arrow={"true"} placement="top-start">
                                                                    <div>
                                                                        <IconButton className="aqa-button" onClick={(e)=>this.handleSnapshotPending(e)} disabled={!showReview} style={{padding:"4px"}}>

                                                                            <div className="aqa-icon" >
                                                                                <FixIcon width="32px" style={{fill:!showReview?"lightgrey":"#04A6FC"}} />
                                                                            </div>
                                                                            <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:!showReview?"grey":"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                                                                GO TO REVIEW
                                                                            </Typography>
                                                                        </IconButton>
                                                                    </div>
                                                                </HtmlTooltip>
                                                                </Typography>
                                                                :""}
                                                                </Grid>
                                                                <Grid item xs={12} sm={5} lg={5} xl={5}>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {/*<Grid item xs={12} sm={12} lg={12} xl={12}>
                                                            <div>
                                                                <div>
                                                                    <LinearProgress variant="determinate" value={progress} style={{height:"30px"}} />
                                                                </div>
                                                                <div style={{paddingTop:"30px"}}>

                                                                    <Typography variant="inherit" className="aqa-text-bold-12" style={{textAlign: "left", width: "100%"}}>
                                                                        {uploadStatus}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </Grid>*/}

                                                    </Grid>
                                                </CardContent>

                                                <CardActions disablespacing="true" style={{padding:"0px"}}></CardActions>

                                            </Card>
                                        </div>
                                    </div>
                                )
                                :(
                            isReady===false
							?
								(
									<div style={{padding:"5px",marginLeft:"0px"}}>
										<div className="">
											<Card width="100%">
												<CardHeader
													style={{padding:"5px", marginBottom:0, borderBottom:"1px solid #ccc"}}
													title={
														<IconButton className="aqa-button" style={{padding:"0px"}}>
															<div className="aqa-list-icon" style={{padding:"4px"}}>
																<SnapshotIcon width="32px" />
															</div>
															<Typography className="aqa-text-bold" variant="inherit" style={{textAlign:"left",padding:"0px"}}>
																Data Upload Progress
															</Typography>
														</IconButton>
													}

												/>

												<CardContent>
													<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

														<Grid item xs={12} sm={12} lg={12} xl={12}>
															<div>
																<div>
																	<LinearProgress variant="determinate" value={progress} style={{height:"30px"}} />
																</div>
																<div style={{paddingTop:"30px"}}>

																	<Typography variant="inherit" className="aqa-text-bold-12" style={{textAlign: "left", width: "100%"}}>
																		{uploadStatus}
																	</Typography>
																</div>
															</div>
														</Grid>

													</Grid>
												</CardContent>

												<CardActions disablespacing="true"></CardActions>

											</Card>
										</div>
									</div>
								)
							:
								(
									/*<GridList cellHeight={'auto'} cols={3} >
										{
											tableData.map
											(
												item =>
												(
													<GridListTile key={item.id}>
														<AqaSnapshotGridRow
															key={item.id}
															id={item.id}
															dataFromGrid={item}
															heatmapData={this.state.heatmapData}
															heatmapType={item.heatmapType}
															dataFromSource={this.props.dataFromSource}
															dataAcquired={this.props.dataFromGrid}
															dataFromParent={this}
															dataFromMain={this.props.dataFromMain}
															dataFromTable={this.state.tableData}
															dataFromRoot={this.props.dataFromParent}
														/>
													</GridListTile>
												)
											)
										}

									</GridList>*/
                                    <div style={{margin:"0px"}}>
                                    <div style={{margin:"4px 4px 4px 4px"}}>
                                        {this.state.isDataLoading?<div className={"aqa-custom-loader"}>
                                                <div className={"aqa-loading-icon"} style={{height:"30px"}}>
                                                    <LoadingIcon fill={"#4cadc4"}/>
                                                </div>
                                            </div>:
                                            <Card width="100%">
                                                <CardContent style={{padding:"2px 2px 0px 2px"}}>

                                                    <Table id={this.props.dataFromGrid.id + "_snaptable"}
                                                           cellSpacing={0}
                                                           aria-sort="ascending" style={{width:"100%",display:"block"}}>
                                                        <colgroup>
                                                            <col width="2%"/>
                                                            <col width="10%"/>
                                                            <col width="5%"/>
                                                            <col width="4%"/>
                                                            <col width="5%"/>
                                                            <col width="4%"/>
                                                            <col width="5%"/>
                                                            <col width="4%"/>
                                                            <col width="5%"/>
                                                            <col width="4%"/>
                                                            <col width="5%"/>
                                                            <col width="4%"/>
                                                            <col width="5%"/>
                                                            <col width="4%"/>
                                                            <col width="5%"/>
                                                            <col width="4%"/>
                                                            <col width="1%"/>
                                                            <col width="5%"/>

                                                        </colgroup>
                                                        <TableHead style={{
                                                            'overflow': 'auto',
                                                            display: 'table',
                                                            //width: this.props.dataFromParent.state.showHistory===true ? window.innerWidth * 0.72 : window.innerWidth * 0.992
                                                            width: "100%"
                                                        }}>
                                                            <TableRow style={{height: "18px"}}>
                                                                <TableCell width="2%"
                                                                           style={{"padding": "0px 0px 0px 4px"}}>
                                                                    <div className="aqa-text-bold"></div>
                                                                </TableCell>
                                                                <TableCell width="10%"
                                                                           style={{"padding": "0px 0px 0px 4px"}}>
                                                                    <div className="aqa-text-bold" style={{paddingLeft:"4px"}}>{isOverallSelected?"":"Column"}</div>
                                                                </TableCell>
                                                                <TableCell width="5%" colSpan={2}
                                                                           style={{"padding": "0px"}}>
                                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                        <div>
                                                                            <Typography color="inherit" className="aqa-text-action">All Alerts</Typography>
                                                                        </div>

                                                                    </React.Fragment>} arrow={"true"} placement="bottom-end">
                                                                        <div className="aqa-text-bold"
                                                                             style={{textAlign: "right"}}>All
                                                                        </div>
                                                                    </HtmlTooltip>
                                                                </TableCell>
                                                                <TableCell width="5%" colSpan={2}
                                                                           style={{"padding": "0px"}}>
                                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                        <div>
                                                                            <Typography color="inherit" className="aqa-text-action">Does the data contain any cells that have missing data?</Typography>
                                                                        </div>

                                                                    </React.Fragment>} arrow={"true"} placement="bottom-end">
                                                                    <div className="aqa-text-bold"
                                                                         style={{textAlign: "right"}}>Missing?
                                                                    </div>
                                                                    </HtmlTooltip>
                                                                </TableCell>
                                                                <TableCell width="5%" colSpan={2}
                                                                           style={{"padding": "0px"}}>
                                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                        <div>
                                                                            <Typography color="inherit" className="aqa-text-action">If there are columns with specific format types, are there any cells that do not conform?</Typography>
                                                                        </div>

                                                                    </React.Fragment>} arrow={"true"} placement="bottom-end">
                                                                    <div className="aqa-text-bold"
                                                                         style={{textAlign: "right"}}>Bad Format?
                                                                    </div>
                                                                    </HtmlTooltip>
                                                                </TableCell>
                                                                <TableCell width="5%" colSpan={2} style={{
                                                                    "padding": "0px",
                                                                    marginRight: "5px"
                                                                }}>
                                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                        <div>
                                                                            <Typography color="inherit" className="aqa-text-action">In specific columns, do any cells contain values significantly outside of the range of the data contained in the other cells?</Typography>
                                                                        </div>

                                                                    </React.Fragment>} arrow={"true"} placement="bottom-end">
                                                                    <div className="aqa-text-bold" style={{
                                                                        marginRight: "0px",
                                                                        textAlign: "right"
                                                                    }}>Outlier?
                                                                    </div>
                                                                    </HtmlTooltip>
                                                                </TableCell>
                                                                <TableCell width="5%" colSpan={2} style={{
                                                                    "padding": "0px",
                                                                    marginRight: "5px"
                                                                }}>
                                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                        <div>
                                                                            <Typography color="inherit" className="aqa-text-action">In columns with a specific set of repeated values, are there any cells that look different?</Typography>
                                                                        </div>

                                                                    </React.Fragment>} arrow={"true"} placement="bottom-end">
                                                                        <div className="aqa-text-bold" style={{
                                                                            marginRight: "0px",
                                                                            textAlign: "right"
                                                                        }}>Invalid?
                                                                        </div>
                                                                    </HtmlTooltip>
                                                                </TableCell>
                                                                <TableCell width="5%" colSpan={2}
                                                                           style={{"padding": "0px"}}>
                                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                        <div>
                                                                            <Typography color="inherit" className="aqa-text-action">For columns containing alpha-numeric data, are any values duplicated?</Typography>
                                                                        </div>

                                                                    </React.Fragment>} arrow={"true"} placement="bottom-end">
                                                                    <div className="aqa-text-bold"
                                                                         style={{textAlign: "right"}}>Duplicate?
                                                                    </div>
                                                                    </HtmlTooltip>
                                                                </TableCell>
                                                                <TableCell width="5%" colSpan={2}
                                                                           style={{"padding": "0px"}}>
                                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                        <div>
                                                                            <Typography color="inherit" className="aqa-text-action">If a column contains data of one type, are there any values in any cells of a different type?</Typography>
                                                                        </div>

                                                                    </React.Fragment>} arrow={"true"} placement="bottom-end">
                                                                    <div className="aqa-text-bold"
                                                                         style={{textAlign: "right"}}>Wrong Type?
                                                                    </div>
                                                                    </HtmlTooltip>
                                                                </TableCell>
                                                                <TableCell width="5%" colSpan={2}
                                                                           style={{"padding": "0px"}}>
                                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                        <div>
                                                                            <Typography color="inherit" className="aqa-text-action">Are there any cells that contain native excel errors?</Typography>
                                                                        </div>

                                                                    </React.Fragment>} arrow={"true"} placement="bottom-end">
                                                                    <div className="aqa-text-bold"
                                                                         style={{textAlign: "right"}}>Excel Error?
                                                                    </div>
                                                                    </HtmlTooltip>
                                                                </TableCell>
                                                                <TableCell width="4%" colSpan={2}
                                                                           style={{"padding": "0px 0px 0px 4px"}}>
                                                                    <div className="aqa-text-bold"></div>
                                                                </TableCell>

                                                            </TableRow>
                                                            <TableRow style={{height: "18px"}}>
                                                                <TableCell width="2%"
                                                                           style={{"padding": "0px 0px 0px 4px"}}>
                                                                    <div className="aqa-text-bold"></div>
                                                                </TableCell>
                                                                <TableCell width="10%"
                                                                           style={{"padding": "0px 0px 0px 4px"}}>
                                                                    <div className="aqa-text-bold"></div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Red
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Amber
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Red
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Amber
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Red
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Amber
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Red
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Amber
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Red
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Amber
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Red
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Amber
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Red
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Amber
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Red
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="5%" style={{"padding": "0px"}}>
                                                                    <div className="aqa-text-bold-12"
                                                                         style={{textAlign: "right"}}>Amber
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell width="2%"
                                                                           style={{"padding": "0px 0px 0px 4px"}}>
                                                                    <div className="aqa-text-bold"></div>
                                                                </TableCell>
                                                                <TableCell width="4%"
                                                                           style={{"padding": "0px 0px 0px 4px"}}>
                                                                    <div className="aqa-text-bold"></div>
                                                                </TableCell>

                                                            </TableRow>
                                                        </TableHead>
                                                        {(!isOverallSelected || selectedColumn !== null) ?
                                                            <TableBody style={{
                                                                'height': selectedColumn !== null ? "36px" : (window.innerHeight > 540 && window.innerHeight < 730 ? (window.innerHeight) * 0.40 : (columnData.length*36 > ((window.innerHeight - 100) * 0.68)?((window.innerHeight - 100) * 0.68):"")),
                                                                'overflowY': 'auto',
                                                                display: 'block',
                                                                scrollbarGutter:"stable",
                                                                //width: this.props.dataFromParent.state.showHistory === true ? window.innerWidth * 0.715 : (selectedColumn !== null ? window.innerWidth * 0.99 : window.innerWidth * 0.982)
                                                                width: this.props.dataFromParent.state.showHistory === true ? "100%" : (selectedColumn !== null ? "100%" : "100%")
                                                            }}>
                                                                {columnData.filter((item) => {
                                                                    return item.id === selectedColumnId || selectedColumnId === null;
                                                                }).map((item, index) => (
                                                                    <TableRow className="aqa-list" key={item.id}
                                                                              style={{
                                                                                  display: 'table',
                                                                                  tableLayout: 'fixed',
                                                                                  height: "16px",
                                                                                  width:"100%"
                                                                              }}>
                                                                        <TableCell size="small" width="2%"
                                                                                   style={{padding: "4px 4px 0px 0px"}}>
                                                                            <IconButton className="aqa-button"
                                                                                        style={{
                                                                                            padding: 0,
                                                                                            paddingTop: "0px"
                                                                                        }}>
                                                                                <div className="aqa-list-icon">
                                                                                    {QD_ICONS[item.type]}
                                                                                </div>
                                                                            </IconButton>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="10%" className={selectedStat==="Column_A"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):((item.allRed + item.allAmber)>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 4px 0px 4px"}}>
                                                                            <div className="aqa-text-bold-12"
                                                                                 style={{
                                                                                     textAlign: "left",
                                                                                     wordBreak: "break-word"
                                                                                 }}
                                                                                 onClick={(e) => this.handleSnapshotRow(item, "overall", "redamber", index, (item.allRed + item.allAmber),"Column_A")}>{item.name}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_A_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.allRed>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.allRed > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.allRed > 0 ? "red" : "rgb(40,40,40)",
                                                                                    fontWeight: item.allRed > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "all", "red", index, item.allRed,"Column_A_1")}>{item.allRed > 0 ? item.allRed.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_A_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.allAmber>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.allAmber > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.allAmber > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                    fontWeight: item.allAmber > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "any", "amber", index, item.allAmber,"Column_A_2")}>{item.allAmber > 0 ? item.allAmber.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_M_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.populatedRed>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.populatedRed > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.populatedEnabled && item.populatedRed > 0 ? "red" : "rgb(40,40,40)",
                                                                                    fontWeight: item.populatedRed > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "populated", "red", index,item.populatedRed,"Column_M_1")}>{item.populatedEnabled ? item.populatedRed.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_M_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.populatedAmber>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.populatedAmber > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.populatedEnabled && item.populatedAmber > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                    fontWeight: item.populatedAmber > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "populated", "amber", index,item.populatedAmber,"Column_M_2")}>{item.populatedEnabled ? item.populatedAmber.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_F_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.formatRed>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.formatRed > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.formatEnabled && item.formatRed > 0 ? "red" : "rgb(40,40,40)",
                                                                                    fontWeight: item.formatRed > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "format", "red", index,item.formatRed,"Column_F_1")}>{item.formatEnabled ? item.formatRed.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_F_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.formatAmber>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.formatAmber > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.formatEnabled && item.formatAmber > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                    fontWeight: item.formatAmber > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "format", "amber", index,item.formatAmber,"Column_F_2")}>{item.formatEnabled ? item.formatAmber.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_O_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.valueRed>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.valueRed > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.valueEnabled && item.valueRed > 0 ? "red" : "rgb(40,40,40)",
                                                                                    fontWeight: item.valueRed > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "value", "red", index,item.valueRed,"Column_O_1")}>{item.valueEnabled ? item.valueRed.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_O_2"?"aqa-select-active":(item.valueAmber>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.valueAmber > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.valueEnabled && item.valueAmber > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                    fontWeight: item.valueAmber > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "value", "amber", index,item.valueAmber,"Column_O_2")}>{item.valueEnabled ? item.valueAmber.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_I_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.valueAllowedRed>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.valueAllowedRed > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.valueAllowedEnabled && item.valueAllowedRed > 0 ? "red" : "rgb(40,40,40)",
                                                                                    fontWeight: item.valueAllowedRed > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "valueAllowed", "red", index,item.valueAllowedRed,"Column_I_1")}>{item.valueAllowedEnabled ? item.valueAllowedRed.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_I_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.valueAllowedAmber>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.valueAllowedAmber > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.valueAllowedEnabled && item.valueAllowedAmber > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                    fontWeight: item.valueAllowedAmber > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "valueAllowed", "amber", index,item.valueAllowedAmber,"Column_I_2")}>{item.valueAllowedEnabled ? item.valueAllowedAmber.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_D_1"?"aqa-select-active":(item.uniquenessRed>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.uniquenessRed > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.uniquenessEnabled && item.uniquenessRed > 0 ? "red" : "rgb(40,40,40)",
                                                                                    fontWeight: item.uniquenessRed > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "uniqueness", "red", index,item.uniquenessRed,"Column_D_1")}>{item.uniquenessEnabled ? item.uniquenessRed.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_D_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.uniquenessAmber>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.uniquenessAmber > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.uniquenessEnabled && item.uniquenessAmber > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                    fontWeight: item.uniquenessAmber > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "uniqueness", "amber", index,item.uniquenessAmber,"Column_D_2")}>{item.uniquenessEnabled ? item.uniquenessAmber.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_T_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.typeRed>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.typeRed > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.typeEnabled && item.typeRed > 0 ? "red" : "rgb(40,40,40)",
                                                                                    fontWeight: item.typeRed > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "type", "red", index,item.typeRed,"Column_T_1")}>{item.typeEnabled ? item.typeRed.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_T_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.typeAmber>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.typeAmber > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.typeEnabled && item.typeAmber > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                    fontWeight: item.typeAmber > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "type", "amber", index,item.typeAmber,"Column_T_2")}>{item.typeEnabled ? item.typeAmber.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_E_1"?"aqa-select-active":(item.nativeRed>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.nativeRed > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.nativeEnabled && item.nativeRed > 0 ? "red" : "rgb(40,40,40)",
                                                                                    fontWeight: item.nativeRed > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "native", "red", index,item.nativeRed,"Column_E_1")}>{item.nativeEnabled ? item.nativeRed.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="5%" className={selectedStat==="Column_E_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.nativeAmber>0?"aqa-select":"aqa-no-select")}
                                                                                   style={{padding: "0px 0px 0px 0px"}}>
                                                                            <div
                                                                                className={item.nativeAmber > 0 ? "aqa-text-bold-14" : "aqa-text-bold-12"}
                                                                                style={{
                                                                                    textAlign: "right",
                                                                                    color: item.nativeEnabled && item.nativeAmber > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                    fontWeight: item.nativeAmber > 0 ? "bold" : "100"
                                                                                }}
                                                                                onClick={(e) => this.handleSnapshotRow(item, "native", "amber", index,item.nativeAmber,"Column_E_2")}>{item.nativeEnabled ? item.nativeAmber.toLocaleString() : "-"}</div>
                                                                        </TableCell>
                                                                        <TableCell size="small" width="2%"
                                                                                   style={{padding: "2px 0px 2px 0px"}}>

                                                                        </TableCell>
                                                                        <TableCell size="small" width="3%"
                                                                                   style={{padding: "4px 0px 0px 0px"}}>
                                                                            <HtmlTooltip enterDelay={500}
                                                                                         title={<React.Fragment>
                                                                                             <div>
                                                                                                 <Typography
                                                                                                     color="inherit"
                                                                                                     className="aqa-text-action">Click
                                                                                                     to edit
                                                                                                     rules</Typography>
                                                                                             </div>

                                                                                         </React.Fragment>}
                                                                                         arrow={"true"}
                                                                                         placement="bottom-end">
                                                                                <IconButton className="aqa-button"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                textAlign: "right"
                                                                                            }}
                                                                                            onClick={(e) => this.handleShowEditor(item, item.index)}>
                                                                                    <div className="aqa-list-icon">
                                                                                        <RulesIcon width="24px"/>
                                                                                    </div>
                                                                                </IconButton>
                                                                            </HtmlTooltip>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                            : <TableBody></TableBody>
                                                        }
                                                        {selectedColumn===null ?
                                                            <TableHead style={{
                                                                'overflow': 'auto',
                                                                display: 'table',
                                                                //width: this.props.dataFromParent.state.showHistory === true ? window.innerWidth * 0.72 : window.innerWidth * 0.994,
                                                                width: "100%",
                                                                borderTop:"1px solid rgba(224, 224, 224, 1)"
                                                            }}>
                                                                <TableRow style={{height: "28px"}}>
                                                                    <TableCell width="2%"
                                                                               style={{"padding": "0px 0px 0px 4px"}}>
                                                                        <div className="aqa-text-bold"></div>
                                                                    </TableCell>
                                                                    <TableCell width="10%"
                                                                               style={{"padding": "0px 0px 0px 4px"}}>
                                                                        <div className="aqa-text-bold" style={{paddingLeft:"4px"}}>Alert Cell Counts</div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(this.state.allRed>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Red", "overall", -1, (this.state.allRed),"All_Column_1")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.allRed > 0 ? "red" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.allRed > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.allRed}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(this.state.allAmber>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Amber", "any", -1, (this.state.allAmber),"All_Column_2")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.allAmber > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.allAmber > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.allAmber}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_M_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):((this.state.healthStats.length > 0 ? this.state.healthStats[1].numM : 0)>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Red", "population", -1, (this.state.healthStats.length > 0 ? this.state.healthStats[1].numM : 0),"All_Column_M_1")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.healthStats.length > 0 && this.state.healthStats[1].numM > 0 ? "red" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.healthStats.length > 0 && this.state.healthStats[1].numM > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.healthStats.length > 0 ? this.state.healthStats[1].numM : 0}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_M_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):((this.state.healthStats.length > 0 ? this.state.healthStats[2].numM : 0)>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Amber", "population", -1, (this.state.healthStats.length > 0 ? this.state.healthStats[2].numM : 0),"All_Column_M_2")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.healthStats.length > 0 && this.state.healthStats[2].numM > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.healthStats.length > 0 && this.state.healthStats[2].numM > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.healthStats.length > 0 ? this.state.healthStats[2].numM : 0}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_F_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):((this.state.healthStats.length > 0 ? this.state.healthStats[1].numF : 0)>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Red", "overall", 0, (this.state.healthStats.length > 0 ? this.state.healthStats[1].numF : 0),"All_Column_F_1")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.healthStats.length > 0 && this.state.healthStats[1].numF > 0 ? "red" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.healthStats.length > 0 && this.state.healthStats[1].numF > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.healthStats.length > 0 ? this.state.healthStats[1].numF : 0}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_F_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):((this.state.healthStats.length > 0 ? this.state.healthStats[2].numF : 0)>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Amber", "overall", 0, (this.state.healthStats.length > 0 ? this.state.healthStats[2].numF : 0),"All_Column_F_2")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.healthStats.length > 0 && this.state.healthStats[2].numF > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.healthStats.length > 0 && this.state.healthStats[2].numF > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.healthStats.length > 0 ? this.state.healthStats[2].numF : 0}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_O_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):((this.state.healthStats.length > 0 ? this.state.healthStats[1].numO : 0)>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Red", "overall", 1, (this.state.healthStats.length > 0 ? this.state.healthStats[1].numO : 0),"All_Column_O_1")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.healthStats.length > 0 && this.state.healthStats[1].numO > 0 ? "red" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.healthStats.length > 0 && this.state.healthStats[1].numO > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.healthStats.length > 0 ? this.state.healthStats[1].numO : 0}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_O_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):((this.state.healthStats.length > 0 ? this.state.healthStats[2].numO : 0)>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Amber", "overall", 1, (this.state.healthStats.length > 0 ? this.state.healthStats[2].numO : 0),"All_Column_O_2")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.healthStats.length > 0 && this.state.healthStats[2].numO > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.healthStats.length > 0 && this.state.healthStats[2].numO > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.healthStats.length > 0 ? this.state.healthStats[2].numO : 0}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_I_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):((this.state.healthStats.length > 0 ? this.state.healthStats[1].numI : 0)>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Red", "string", 2, (this.state.healthStats.length > 0 ? this.state.healthStats[1].numI : 0),"All_Column_I_1")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.healthStats.length > 0 && this.state.healthStats[1].numI > 0 ? "red" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.healthStats.length > 0 && this.state.healthStats[1].numI > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.healthStats.length > 0 ? this.state.healthStats[1].numI : 0}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_I_2"?"aqa-select-active":((this.state.healthStats.length > 0 ? this.state.healthStats[2].numI : 0)>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Amber", "string", 2, (this.state.healthStats.length > 0 ? this.state.healthStats[2].numI : 0),"All_Column_I_2")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.healthStats.length > 0 && this.state.healthStats[2].numI > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.healthStats.length > 0 && this.state.healthStats[2].numI > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.healthStats.length > 0 ? this.state.healthStats[2].numI : 0}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_D_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):((this.state.healthStats.length > 0 ? this.state.healthStats[1].numD : 0)>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Red", "uniqueness", -1, (this.state.healthStats.length > 0 ? this.state.healthStats[1].numD : 0),"All_Column_D_1")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.healthStats.length > 0 && this.state.healthStats[1].numD > 0 ? "red" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.healthStats.length > 0 && this.state.healthStats[1].numD > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.healthStats.length > 0 ? this.state.healthStats[1].numD : 0}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_D_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):((this.state.healthStats.length > 0 ? this.state.healthStats[2].numD : 0)>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Amber", "uniqueness", -1, (this.state.healthStats.length > 0 ? this.state.healthStats[2].numD : 0),"All_Column_D_2")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.healthStats.length > 0 && this.state.healthStats[2].numD > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.healthStats.length > 0 && this.state.healthStats[2].numD > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.healthStats.length > 0 ? this.state.healthStats[2].numD : 0}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_T_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):((this.state.healthStats.length > 0 ? this.state.healthStats[1].numT : 0)>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Red", "type", -1, (this.state.healthStats.length > 0 ? this.state.healthStats[1].numT : 0),"All_Column_T_1")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.healthStats.length > 0 && this.state.healthStats[1].numT > 0 ? "red" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.healthStats.length > 0 && this.state.healthStats[1].numT > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.healthStats.length > 0 ? this.state.healthStats[1].numT : 0}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_T_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):((this.state.healthStats.length > 0 ? this.state.healthStats[2].numT : 0)>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Amber", "type", -1, (this.state.healthStats.length > 0 ? this.state.healthStats[2].numT : 0),"All_Column_T_2")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.healthStats.length > 0 && this.state.healthStats[2].numT > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.healthStats.length > 0 && this.state.healthStats[2].numT > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.healthStats.length > 0 ? this.state.healthStats[2].numT : 0}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_E_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):((this.state.healthStats.length > 0 ? this.state.healthStats[1].numE : 0)>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Red", "nativeError", -1, (this.state.healthStats.length > 0 ? this.state.healthStats[1].numE : 0),"All_Column_E_1")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.healthStats.length > 0 && this.state.healthStats[1].numE > 0 ? "red" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.healthStats.length > 0 && this.state.healthStats[1].numE > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.healthStats.length > 0 ? this.state.healthStats[1].numE : 0}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="5%" className={selectedStat==="All_Column_E_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):((this.state.healthStats.length > 0 ? this.state.healthStats[2].numE : 0)>0?"aqa-select":"aqa-no-select")} style={{"padding": "0px"}}>
                                                                        <div className="aqa-text-bold-14"
                                                                             onClick={(e) => this.handleHeaderRow("Amber", "nativeError", -1, (this.state.healthStats.length > 0 ? this.state.healthStats[2].numE : 0),"All_Column_E_2")}
                                                                             style={{
                                                                                 textAlign: "right", cursor: "pointer",
                                                                                 color: this.state.healthStats.length > 0 && this.state.healthStats[2].numE > 0 ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                                                 fontWeight: this.state.healthStats.length > 0 && this.state.healthStats[2].numE > 0 ? "bold" : "100"
                                                                             }}>
                                                                            {this.state.healthStats.length > 0 ? this.state.healthStats[2].numE : 0}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell width="2%"
                                                                               style={{"padding": "0px 0px 0px 4px"}}>
                                                                        <div className="aqa-text-bold"></div>
                                                                    </TableCell>
                                                                    <TableCell width="4%"
                                                                               style={{"padding": "0px 0px 0px 4px"}}>
                                                                        <div className="aqa-text-bold"></div>
                                                                    </TableCell>

                                                                </TableRow>
                                                            </TableHead>
                                                            : <TableHead></TableHead>
                                                        }
                                                    </Table>
                                                </CardContent>
                                            </Card>
                                        }
                                    </div>
                                        <div style={{marginLeft:"4px",marginRight:"4px",marginBottom:"0px"}}>
                                            {isDataViewLoaded?(
                                                <div>
                                                {isOverallSelected || selectedColumn!==null?
                                                    <div>
                                                    <Card width="100%">
                                                        <CardContent style={{padding:"6px 0px 0px 0px",marginTop:"-4px"}}>

                                                            <div>
                                                                <AqaSnapshotDetailTable
                                                                	snapshotId={this.state.latestSnapshot.id}
                                                                	sourcename={this.props.dataFromSource.name}
                                                                	dataFromSource={this.props.dataFromSource}
                                                                	dataFromSnapshot={this.state.latestSnapshot}
                                                                	controller={this}
                                                                  isAmber={isAmber}
                                                                  isRed={isRed}
                                                                  isGreen={isGreen}
                                                                  currentHeatMap={this.props.currentHeatMap}
                                                                  currentColumn={selectedColumnIndex}
                                                                  currentAspect={"overall"}
                                                                  snapcount={this.props.dataFromParent.state.snapshotcount}
                                                                  showHistory={this.props.dataFromParent.state.showHistory}
                                                                  selectedReviewType={this.state.selectedReviewType}
                                                                />

                                                            </div>
                                                        </CardContent>
                                                    </Card>
                                                            <Card width="100%" style={{marginTop:"-4px"}}>
                                                                <CardContent style={{padding:"0px"}}>

                                                            <div>
                                                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0,paddingTop:"0px", marginTop: this.state.selectedColumn!==null?"0px":"0px",marginBottom:"0px",marginLeft:"0px",marginRight:"0px"}}>
                                                                    <Grid item xs={5} sm={5} lg={5} xl={5}>
                                                                        {selectedRow===null?<div style={{color:"white"}}>.</div>:
                                                                            <div>
                                                                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

                                                                                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                                                                                        {/*<Typography color="inherit" className="aqa-text-action" style={{display:"flex",height:"28px",paddingTop:"5px"}}>Selected: Row: {selectedRow.props.originalPosition+1} Column: {selectedRow.props.x+1} Value = {selectedRow.props.text===""?"Blank Cell":selectedRow.props.text}

                                                                                        </Typography>*/}
                                                                                        <Typography component={"div"} color="inherit" className="aqa-text-action" style={{textAlign:"right"}}>
                                                                                            {this.state.whichStatus>0?(selectedRow.props.content!==null?<div>
                                                                                                <Typography component={"div"} variant="inherit" color="inherit" style={{textAlign:"right",fontSize:"0.8rem",width:"100%",paddingTop:"5px"}}>
                                                                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                                        <div>
                                                                                                            <Typography color="inherit" className="aqa-text-action">Click to {selectedRow.props.content.note === undefined?"Add":"Edit"} the {selectedRow.props.os===1?"Comment":"Comment/Suggestion"}</Typography>
                                                                                                        </div>
                                                                                                    </React.Fragment>} arrow={"true"} placement="top-start">
                                                                                                        <div>
                                                                                                            <Typography component="div" color="inherit" className="aqa-text-action" style={{textAlign:"right"}}>
                                                                                                            <IconButton className="aqa-button" onClick={()=>this.handleSelectedRowComment(this.state.whichStatus)} style={{padding:"4px"}}>

                                                                                                                <div className="aqa-icon" >
                                                                                                                    <EditIcon width="24px" style={{fill:"#04A6FC"}} />
                                                                                                                </div>
                                                                                                            </IconButton>
                                                                                                            </Typography>
                                                                                                        </div>
                                                                                                    </HtmlTooltip>

                                                                                                </Typography>
                                                                                            </div>:""):""}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={11} sm={11} lg={11} xl={11}>
                                                                                        <Typography component="div" color="inherit" className="aqa-text-action" style={{padding:"4px 0px"}}>
                                                                                            {this.state.whichStatus>0?(selectedRow.props.content!==null?<div>
                                                                                                {selectedRow.props.content.note === undefined || selectedRow.props.content.note.note===""?
                                                                                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"11px",width:"100%",paddingTop:"0px",fontFamily:"Montserrat"}}>
                                                                                                        {"Add Comment"}
                                                                                                    </Typography>:
                                                                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                                        <div>
                                                                                                            <Typography color="inherit" className="aqa-text-action">{"Comment: "+selectedRow.props.content.note.note}</Typography>
                                                                                                        </div>
                                                                                                    </React.Fragment>} arrow={"true"} placement="top-start">
                                                                                                        <div>
                                                                                                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"11px",width:"100%",paddingTop:"0px",fontFamily:"Montserrat"}}>
                                                                                                                    {"Comment: "+(selectedRow.props.content.note.note!==null && selectedRow.props.content.note.note.length>70?selectedRow.props.content.note.note.substring(0,67)+"...":selectedRow.props.content.note.note)}
                                                                                                                </Typography>
                                                                                                        </div>
                                                                                                    </HtmlTooltip>
                                                                                                }
                                                                                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"11px",width:"100%",paddingTop:"0px",fontFamily:"Montserrat"}}>
                                                                                                    {this.state.whichStatus ===1?(selectedRow.props.content.note === undefined || selectedRow.props.content.note.note===""?"":""):(selectedRow.props.content.note === undefined || (selectedRow.props.content.note.note==="" && selectedRow.props.content.note.suggestion === "")?"":"Suggested: "+selectedRow.props.content.note.suggestion)}
                                                                                                </Typography>
                                                                                            </div>:""):""}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                                        {selectedRow===null?"":
                                                                            <div>
                                                                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>
                                                                                    <Grid item xs={3} sm={3} lg={3} xl={3}>
                                                                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"5px"}}>
                                                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                                <div>
                                                                                                    <Typography color="inherit" className="aqa-text-action">Click to mark cell as Must Fix</Typography>
                                                                                                </div>
                                                                                            </React.Fragment>} arrow={"true"} placement="top">
                                                                                                <div>
                                                                                                <IconButton className="aqa-button" onClick={()=>this.handleSelectedRowAs("2")} disabled={this.state.selectedColumnStatus===2?true:false} style={{padding:"4px"}}>

                                                                                                    <div className="aqa-icon" >
                                                                                                        <FixIcon width="24px" style={{fill:this.state.selectedColumnStatus===2?"lightgrey":"#04A6FC"}} />
                                                                                                    </div>
                                                                                                    <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:this.state.selectedColumnStatus===2?"grey":"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                                                                                        FIX
                                                                                                    </Typography>
                                                                                                </IconButton>
                                                                                                </div>
                                                                                            </HtmlTooltip>

                                                                                        </Typography>
                                                                                    </Grid>

                                                                                    <Grid item xs={3} sm={3} lg={3} xl={3}>
                                                                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"5px"}}>
                                                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                                <div>
                                                                                                    <Typography color="inherit" className="aqa-text-action">Click to mark the cell as Can Ignore</Typography>
                                                                                                </div>
                                                                                            </React.Fragment>} arrow={"true"} placement="top">
                                                                                                <div>
                                                                                                <IconButton className="aqa-button" onClick={()=>this.handleSelectedRowAs("1")} disabled={this.state.selectedColumnStatus===1 || (this.state.selectedRow!==null && this.state.selectedRow.props.rag===2)?true:false} style={{padding:"4px"}}>

                                                                                                    <div className="aqa-icon" >
                                                                                                        <IgnoreIcon width="24px" style={{fill:this.state.selectedColumnStatus===1 || (this.state.selectedRow!==null && this.state.selectedRow.props.rag===2)?"lightgrey":"#04A6FC"}} />
                                                                                                    </div>
                                                                                                    <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:this.state.selectedColumnStatus===1 || (this.state.selectedRow!==null && this.state.selectedRow.props.rag===2)?"grey":"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                                                                                        IGNORE
                                                                                                    </Typography>
                                                                                                </IconButton>
                                                                                                </div>
                                                                                            </HtmlTooltip>

                                                                                        </Typography>
                                                                                    </Grid>

                                                                                    <Grid item xs={3} sm={3} lg={3} xl={3}>
                                                                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"5px"}}>
                                                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                                <div>
                                                                                                    <Typography color="inherit" className="aqa-text-action">Click to reset the cell</Typography>
                                                                                                </div>
                                                                                            </React.Fragment>} arrow={"true"} placement="top">
                                                                                                <div>
                                                                                                <IconButton className="aqa-button" onClick={()=>this.handleSelectedRowAs("0")} disabled={this.state.selectedColumnStatus!==2 && this.state.selectedColumnStatus!==1?true:false} style={{padding:"4px"}}>

                                                                                                    <div className="aqa-icon" >
                                                                                                        <HelpIcon width="24px" style={{fill:this.state.selectedColumnStatus!==2 && this.state.selectedColumnStatus!==1?"lightgrey":"#04A6FC"}}/>
                                                                                                    </div>
                                                                                                    <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:this.state.selectedColumnStatus!==2 && this.state.selectedColumnStatus!==1?"grey":"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                                                                                        RESET
                                                                                                    </Typography>
                                                                                                </IconButton>
                                                                                                </div>
                                                                                            </HtmlTooltip>

                                                                                        </Typography>
                                                                                    </Grid>

                                                                                </Grid>
                                                                            </div>

                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={5} sm={5} lg={5} xl={5}>
                                                                        {selectedRow===null?<div style={{color:"white"}}>.</div>:
                                                                            <div>
                                                                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

                                                                                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                                                                                    </Grid>
                                                                                    <Grid item xs={8} sm={8} lg={8} xl={8}>
                                                                                        <Typography color="inherit" className="aqa-text-action" component={"div"} style={{display:"flex",height:"28px",paddingTop:"7px"}}>
                                                                                            {this.state.selectedColumnStatus!=="" && (this.state.selectedColumnFilterRows.filter((d)=>{return d.os !== this.state.selectedColumnStatus}).length>=1) && this.state.isBulkOn && this.state.isBulkUpdateOn?
                                                                                                <Typography variant="inherit" color="inherit" component={"div"} style={{textAlign:"left",fontSize:"0.8rem",marginTop:"-8px"}}>
                                                                                                    <HtmlTooltip enterDelay={1500} title={<React.Fragment>
                                                                                                        <div>
                                                                                                            <Typography color="inherit" className="aqa-text-action">
                                                                                                                {this.state.selectedColumnStatus===1?"Click to mark all selected value cells as Can Ignore":(this.state.selectedColumnStatus===2?"Click to mark all the selected value cells as Must Fix":"Click to reset all selected value cells")}
                                                                                                            </Typography>
                                                                                                        </div>
                                                                                                    </React.Fragment>} arrow={"true"} placement="top">
                                                                                                        <div>
                                                                                                            <IconButton className="aqa-button" onClick={()=>this.handleSelectedAllRowAs(this.state.selectedColumnStatus)} disabled={!this.state.isBulkOn?true:false} style={{padding:"4px",opacity:"1"}}>

                                                                                                                <div className="aqa-icon" >
                                                                                                                    {this.state.selectedColumnStatus===1?
                                                                                                                        <IgnoreIcon width="24px" style={{fill:"#04A6FC"}} />:(this.state.selectedColumnStatus===2?
                                                                                                                            <FixIcon width="24px" style={{fill:"#04A6FC"}} />:
                                                                                                                            <HelpIcon width="24px" style={{fill:"#04A6FC"}}/>)}
                                                                                                                </div>
                                                                                                                <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:!this.state.isBulkOn?"lightgrey":"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                                                                                                    {this.state.selectedColumnStatus===1?"IGNORE":(this.state.selectedColumnStatus===2?"FIX":"RESET")} ({this.state.selectedColumnFilterRows.length===0?"0 OTHER CELLS":this.state.selectedColumnFilterRows.length+" OTHER CELLS "}) WITH THIS VALUE IN THIS COLUMN
                                                                                                                </Typography>
                                                                                                            </IconButton>
                                                                                                        </div>
                                                                                                    </HtmlTooltip>

                                                                                                </Typography>:""}
                                                                                            {/*this.state.selectedColumnFilterRows.length>2?(this.state.selectedColumnFilterRows.length-1)+" other cells with this value in this column ":(this.state.selectedColumnFilterRows.length<=1?"0 other cells with this value in this column":(this.state.selectedColumnFilterRows.length-1)+" other cell with this value in this column")*/}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={3} sm={3} lg={3} xl={3}>
                                                                                        <HtmlTooltip enterDelay={1500} title={<React.Fragment>
                                                                                            <div>
                                                                                                <Typography color="inherit" className="aqa-text-action">Click to edit rules</Typography>
                                                                                            </div>

                                                                                        </React.Fragment>} arrow={"true"} placement="top-start">
                                                                                            <Typography color="inherit" className="aqa-text-action" style={{textAlign:"right",paddingRight:"10px",paddingTop:"4px"}}>
                                                                                            <IconButton className="aqa-button" style={{
                                                                                                padding: 0,
                                                                                                textAlign: "right"
                                                                                            }}
                                                                                                        onClick={(e) => this.handleShowEditor(null, selectedRow.props.x)}>
                                                                                                <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"right",color:"#04A6FC",paddingRight:"4px",marginTop:"-4px"}}>
                                                                                                    SHOW RULES
                                                                                                </Typography>
                                                                                                <div className="aqa-list-icon">
                                                                                                    <RulesIcon width="24px"/>
                                                                                                </div>

                                                                                            </IconButton>
                                                                                            </Typography>
                                                                                        </HtmlTooltip>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </Card>
                                                    </div>
                                                    :
                                                    ""
                                            }
                                                </div>

                                            ):
                                                (this.state.isDataLoading?<div className={"aqa-custom-loader"}>
                                                   <div className={"aqa-loading-icon"}>
                                                        <LoadingIcon fill={"#4cadc4"} style={{width:"24px"}}/>
                                                    </div>
                                                </div>:"")
                                            }

                                        </div>
                                    </div>

								)
                                )
                                )
						}
					</div>
                    <div>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            ContentProps={{
                                classes: {
                                    root: classes.root1,
                                    message:classes.message
                                }
                            }}
                            style={{top:"14px"}}
                            open={this.state.isSnackOpen} message={
                            <Typography style={{color:"white",fontFamily:"Montserrat",fontSize:"14px",marginTop:"-8px"}} >
                                {"This view has filters on"}
                            </Typography>
                        } action={<>
                            <Button color="inherit" size="small" style={{padding:"0"}} onClick={(e)=>this.handleFilterReset(e)}>
                                {"RESET"}
                            </Button>
                            {/*<IconButton size="small" aria-label="close" onClick={(e)=>this.handleOnClose(e)}>
							<CloseIcon fontSize="small" style={{fill:"white"}} />
						</IconButton>*/}
                        </>
                        } className={classes.snackbar}

                        />
                    </div>
                    <div>
                        <Dialog
                            open={this.state.openSheetSelection}
                            //onClose={this.handleCloseMessage}
                            aria-labelledby="max-width-dialog-title"
                            maxWidth="lg"
                        >
                            <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px",width:"600px"}}>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                    Multiple worksheets detected
                                </Typography>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                    <IconButton className="aqa-button" onClick={()=>this.handleClosePopup()} style={{padding:"0px"}} >
                                        <div className="aqa-g-icon" style={{width:"20px"}}>
                                            <CloseIcon width="20px" />
                                        </div>
                                    </IconButton>
                                </Typography>
                            </DialogTitle>
                            <DialogContent align="center" style={{padding: 0,width:"600px"}}>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                            {(errorMessage !=="")?".":"."}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",padding:"5px",fontFamily:"Montserrat",float:"left",width:"100%"}}>
                                            We notice that the file you are uploading has multiple worksheets (AQA only accepts 1 sheet per upload)
                                        </Typography>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                            {("Please select the sheet you wish to upload")
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                            <RadioGroup aria-label="sheetSelectedIndex" name="sheetSelectedIndex" value={this.state.sheetSelectedIndex}
                                                        color="primary" onChange={this.handleRadioChange} row={true}>

                                            {(this.state.sheetData.map((item,index)=>(
                                                <FormControlLabel key={item.titleIndex+"_Sheet"} value={item.id} control={<Radio color="primary" style={{padding:"0px 0px 0px 4px"}} />} label={
                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                 style={{}}>
                                                        <span style={{float:"left"}}>{item.title}</span>
                                                    </Typography>)
                                                } style={{margin:"0px"}} />
                                                ))
                                            )}
                                            </RadioGroup>
                                        </Typography>
                                    </Grid>


                                </Grid>


                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                      style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",width:"600px"}}>

                                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                                        <Button onClick={(e)=>this.handleClosePopup()} variant="contained"
                                                color="primary"
                                                align="left"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginLeft: 8,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}>
                                            Cancel
                                        </Button>


                                    </Grid>

                                    <Grid item xs={9} sm={9} lg={9} xl={9}>
                                    </Grid>
                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                        <Button  variant="contained"
                                                 onClick={(e)=>this.handleSheetSnapshotClosePopup()}
                                                 color="primary"
                                                 align="left"
                                                 className="aqa-action-button"
                                                 style={{
                                                     marginTop: 8,
                                                     color: '#4cadc4',
                                                     backgroundColor: 'white',
                                                     border: '1px solid #4cadc4',
                                                     fontSize: '0.7rem',
                                                     padding: '3px',
                                                 }}>
                                            Load Sheet
                                        </Button>



                                    </Grid>

                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog
                            open={this.state.openSuggestions}
                            //onClose={this.handleCloseMessage}
                            aria-labelledby="draggable-dialog-title"
                            maxWidth="lg"
                            PaperComponent={PaperComponent}
                            //hideBackdrop={true}
                        >
                            <DialogTitle id="draggable-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px",width:"600px",cursor: "move"}}>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                    Marking the cell as {this.state.whichStatus===2?"FIX":"IGNORE"}
                                </Typography>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                    <IconButton className="aqa-button" onClick={()=>this.handleCancelSuggestedAs()} style={{padding:"0px"}} >
                                        <div className="aqa-g-icon" style={{width:"20px"}}>
                                            <CloseIcon width="20px" />
                                        </div>
                                    </IconButton>
                                </Typography>
                            </DialogTitle>
                            <DialogContent align="center" style={{padding: 0,width:"600px"}}>

                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                            {("")
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                            <TextField
                                                id="notes"
                                                name={"notes"}
                                                //onChange={this.handleTextChange('guest')}
                                                value={this.state.notes}
                                                label="Comment (Optional)"
                                                className={classes.textField}
                                                variant="filled"
                                                margin="none"
                                                multiline={true}
                                                rows={3}
                                                style={{ width: "95%",fontFamily:"Montserrat"}}
                                                InputProps={{style:{fontFamily:"Montserrat"}}}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={this.handleChange}
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        {this.state.whichStatus === 2?
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                            <TextField
                                                id="suggestedValue"
                                                name={"suggestedValue"}
                                                //onChange={this.handleTextChange('guest')}
                                                value={this.state.suggestedValue}
                                                label="Suggested Value (Optional)"
                                                className={classes.textField}
                                                variant="filled"
                                                margin="none"
                                                multiline={false}
                                                rows={1}
                                                style={{ width: "95%",fontFamily:"Montserrat"}}
                                                InputProps={{fontFamily:"Montserrat"}}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={this.handleChange}
                                            />
                                        </Typography>
                                            :""}
                                    </Grid>


                                </Grid>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                            {(errorMessage !=="")?errorMessage:"."}
                                        </Typography>
                                    </Grid>
                                </Grid>


                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                      style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",width:"600px"}}>

                                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                                        <Button onClick={(e)=>this.handleCancelSuggestedAs()} variant="contained"
                                                color="primary"
                                                align="left"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginLeft: 8,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}>
                                            Cancel
                                        </Button>


                                    </Grid>

                                    <Grid item xs={9} sm={9} lg={9} xl={9}>
                                    </Grid>
                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                        <Button  variant="contained"
                                                 onClick={(e)=>this.handleSuggestedValueForSelectedAs()}
                                                 color="primary"
                                                 align="left"
                                                 className="aqa-action-button"
                                                 style={{
                                                     marginTop: 8,
                                                     color: '#4cadc4',
                                                     backgroundColor: 'white',
                                                     border: '1px solid #4cadc4',
                                                     fontSize: '0.7rem',
                                                     padding: '3px',
                                                 }}>
                                            SAVE
                                        </Button>



                                    </Grid>

                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog
                            open={this.state.alertReviewComplete}
                            //onClose={this.handleCloseMessage}
                            aria-labelledby="draggable-dialog-title"
                            maxWidth="lg"
                            PaperComponent={PaperComponent}
                            //hideBackdrop={true}
                        >
                            <DialogTitle id="draggable-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px",width:"600px",cursor: "move"}}>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                    Review Status: Completed
                                </Typography>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                    <IconButton className="aqa-button" onClick={()=>this.handleReviewClose()} style={{padding:"0px"}} >
                                        <div className="aqa-g-icon" style={{width:"20px"}}>
                                            <CloseIcon width="20px" />
                                        </div>
                                    </IconButton>
                                </Typography>
                            </DialogTitle>
                            <DialogContent align="center" style={{padding: 0,width:"600px"}}>

                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: "16px", marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                            {("")
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" className="aqa-text-bold-14" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"0px"}}>
                                            You have now completed your review of the AQA identified alerts on your
                                            uploaded file (and you have chosen to either ignore or have identified fixes on
                                            all alerts)
                                        </Typography>
                                        <Typography variant="inherit" color="inherit" className="aqa-text-bold-14" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"4px 0px",paddingTop:"12px"}}>
                                            It is time to choose what to do next..
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                            <Button onClick={(e)=>this.handlePreviewReport()} variant="contained"
                                                    color="primary"
                                                    align="left"
                                                    className="aqa-action-button"
                                                    style={{
                                                        marginTop: 8,
                                                        marginLeft: 8,
                                                        color: '#4cadc4',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #4cadc4',
                                                        fontSize: '0.7rem',
                                                        padding: '3px'
                                                    }}>
                                                View Fixes
                                            </Button>
                                            <Button onClick={(e)=>this.handleShare()} variant="contained"
                                                    color="primary"
                                                    align="left"
                                                    className="aqa-action-button"
                                                    style={{
                                                        marginTop: 8,
                                                        marginLeft: 8,
                                                        color: '#4cadc4',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #4cadc4',
                                                        fontSize: '0.7rem',
                                                        padding: '3px'
                                                    }}>
                                                Share Fixes
                                            </Button>
                                            <Button onClick={(e)=>this.handleReviewClose()} variant="contained"
                                                    color="primary"
                                                    align="left"
                                                    className="aqa-action-button"
                                                    style={{
                                                        marginTop: 8,
                                                        marginLeft: 8,
                                                        color: '#4cadc4',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #4cadc4',
                                                        fontSize: '0.7rem',
                                                        padding: '3px'
                                                    }}>
                                                Exit & Continue
                                            </Button>

                                        </Typography>

                                    </Grid>


                                </Grid>


                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                      style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",width:"600px"}}>

                                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                                        <Button onClick={(e)=>this.handleReviewClose()} variant="contained"
                                                color="primary"
                                                align="left"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginLeft: 8,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}>
                                            Close
                                        </Button>


                                    </Grid>

                                    <Grid item xs={9} sm={9} lg={9} xl={9}>
                                    </Grid>
                                    <Grid item xs={2} sm={2} lg={2} xl={2}>

                                    </Grid>

                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog
                            open={this.state.reviewComplete}
                            //onClose={this.handleCloseMessage}
                            aria-labelledby="draggable-dialog-title"
                            maxWidth="lg"
                            PaperComponent={PaperComponent}
                            //hideBackdrop={true}
                        >
                            <DialogTitle id="draggable-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px",width:"600px",cursor: "move"}}>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                    Review Status: Completed
                                </Typography>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                    <IconButton className="aqa-button" onClick={()=>this.handleReviewClose()} style={{padding:"0px"}} >
                                        <div className="aqa-g-icon" style={{width:"20px"}}>
                                            <CloseIcon width="20px" />
                                        </div>
                                    </IconButton>
                                </Typography>
                            </DialogTitle>
                            <DialogContent align="center" style={{padding: 0,width:"600px"}}>

                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: "16px", marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                            {("")
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" className="aqa-text-bold-14" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"0px"}}>
                                            You have now completed your review of the AQA identified alerts on your
                                            uploaded file (and you have chosen to either ignore or have identified fixes on
                                            all alerts)
                                        </Typography>
                                        <Typography variant="inherit" color="inherit" className="aqa-text-bold-14" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"4px 0px",paddingTop:"12px"}}>
                                            It is time to choose what to do next..
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                            <Button onClick={(e)=>this.handlePreviewReport()} variant="contained"
                                                    color="primary"
                                                    align="left"
                                                    className="aqa-action-button"
                                                    style={{
                                                        marginTop: 8,
                                                        marginLeft: 8,
                                                        color: '#4cadc4',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #4cadc4',
                                                        fontSize: '0.7rem',
                                                        padding: '3px'
                                                    }}>
                                                View Fixes
                                            </Button>
                                            <Button onClick={(e)=>this.handleShare()} variant="contained"
                                                    color="primary"
                                                    align="left"
                                                    className="aqa-action-button"
                                                    style={{
                                                        marginTop: 8,
                                                        marginLeft: 8,
                                                        color: '#4cadc4',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #4cadc4',
                                                        fontSize: '0.7rem',
                                                        padding: '3px'
                                                    }}>
                                                Share Fixes
                                            </Button>
                                            <Button onClick={(e)=>this.handleReviewClose()} variant="contained"
                                                    color="primary"
                                                    align="left"
                                                    className="aqa-action-button"
                                                    style={{
                                                        marginTop: 8,
                                                        marginLeft: 8,
                                                        color: '#4cadc4',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #4cadc4',
                                                        fontSize: '0.7rem',
                                                        padding: '3px'
                                                    }}>
                                                Exit & Continue
                                            </Button>

                                        </Typography>

                                    </Grid>


                                </Grid>


                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                      style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",width:"600px"}}>

                                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                                        <Button onClick={(e)=>this.handleReviewClose()} variant="contained"
                                                color="primary"
                                                align="left"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginLeft: 8,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}>
                                            Close
                                        </Button>


                                    </Grid>

                                    <Grid item xs={9} sm={9} lg={9} xl={9}>
                                    </Grid>
                                    <Grid item xs={2} sm={2} lg={2} xl={2}>

                                    </Grid>

                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog
                            open={this.state.showPreviewReport}
                            //onClose={this.handleCloseMessage}
                            aria-labelledby="draggable-dialog-title"
                            maxWidth="lg"
                            PaperComponent={PaperComponent}
                            //hideBackdrop={true}
                        >
                            <DialogTitle id="draggable-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px",width:"900px",cursor: "move"}}>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                    Report Preview
                                </Typography>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                    <IconButton className="aqa-button" onClick={(e)=>this.handleReviewClosePop(e)} style={{padding:"0px"}} >
                                        <div className="aqa-g-icon" style={{width:"20px"}}>
                                            <CloseIcon width="20px" />
                                        </div>
                                    </IconButton>
                                </Typography>
                            </DialogTitle>
                            <DialogContent align="center" style={{padding: 0,width:"900px",maxHeight:"580px",overflow:"auto",scrollbarGutter:"stable"}}>

                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: "8px 0px", marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                            {("")
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" className="aqa-text-bold-11" style={{textAlign:"center",fontSize:"0.7rem",color:"#006",padding:"0px"}}>
                                            <div style={{}}>
                                            <div id={"render-report"} style={{padding:"0px 8px"}}></div>
                                            </div>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"0px"}}>

                                        </Typography>

                                    </Grid>


                                </Grid>



                            </DialogContent>
                            <DialogActions>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                      style={{padding: 0, marginTop: 0,marginLeft:"-2px", marginBottom: 0,borderTop:"1px solid #ccc",width:"890px"}}>

                                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                                        <Button onClick={(e)=>this.handleReviewClosePop(e)} variant="contained"
                                                color="primary"
                                                align="left"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginLeft: 8,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}>
                                            Close
                                        </Button>


                                    </Grid>

                                    <Grid item xs={9} sm={9} lg={9} xl={9}>
                                    </Grid>
                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"right",fontSize:"0.8rem",color:"#006",padding:"0px"}}>

                                        <Button onClick={(e)=>this.handleShare()} variant="contained"
                                                color="primary"
                                                align="left"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginLeft: 8,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}>
                                            Share Fixes
                                        </Button>
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </div>
				{/*
				<div style={{padding:"10px",marginLeft:"8px"}}>
				<div className="">
				<Card width="100%">
				<CardHeader style={{padding:"15px",marginBottom:0,borderBottom:"1px solid #ccc"}}
				title={<Typography className="aqa-text-bold" variant="inherit" style={{textAlign:"left",paddingLeft:"8px",marginLeft:"-14px",marginTop:"-4px"}}>
				Snapshot Summary
				</Typography>}

				/>

				<CardContent>
				<Grid container spacing={0} direction="row" justify="space-between"
				alignItems="center"
				style={{padding: 0, marginTop: 0}}>

				<Grid item xs={12} sm={9} lg={9} xl={9}>
				<Typography variant="inherit" color="inherit" style={{
				textAlign: "left",
				fontSize: "0.8rem",
				width: "100%"
				}}>
				Snapshot Description goes here

				</Typography>
				</Grid>
				<Grid item xs={3} sm={3} lg={3} xl={3}>
				<Button  variant="contained"
				color="primary"
				align="right"
				className="aqa-action-button"
				style={{
				marginTop: 8,
				marginLeft: 8,
				color: '#4cadc4',
				backgroundColor: 'white',
				border: '1px solid #4cadc4',
				fontSize: '0.7rem',
				padding: '3px'
				}}>
				Data File
				</Button>
				<Button variant="contained"
				color="primary"
				align="right"
				className="aqa-action-button"
				style={{
				marginTop: 8,
				marginLeft: 8,
				color: '#4cadc4',
				backgroundColor: 'white',
				border: '1px solid #4cadc4',
				fontSize: '0.7rem',
				padding: '3px'
				}}>
				PDF Report
				</Button>
				</Grid>


				</Grid>

				</CardContent>
				<CardActions disablespacing="true">

				</CardActions>

				</Card>
				</div>
				</div>
				*/}
				
				
				</div>
			);
		}
	} // render


} ////

AqaSnapshotOverview.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSnapshotOverview);

