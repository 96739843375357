import React, {Component} from 'react';

import AqaComponent from "../aqacomponent/AqaComponent";
import DistributionGraphPinSet from "./DistributionGraphPinSet";


const PIN_HEAD_RADIUS = 5;

export default class DistributionGraphPin extends Component
{

	static #serial = 1;

	constructor(props)
	{
		super(props);
		this.state = {selected: props.bucket.selected};
		this.key = `rct${DistributionGraphPin.#serial++}`;
	} //


	clicked = () =>
	{
		this.props.controller.filter(this);
	} // clicked


	render()
	{

// this.props.rag


//		const height = this.props.bucket.count * this.props.usableHeight / this.props.maxCount;

		const x = this.props.x;
		
		// Clipping to the right
//		if (x < this.props.from || x > this.props.to) return null;

		if (x > this.props.to) return null;
		const thisPinIsPastX0 = x >= this.props.from;
		
		const y = this.props.y;
		const uh = this.props.usableHeight - PIN_HEAD_RADIUS;
		const y2 = y - (this.props.bucket.count * uh / this.props.maxCount);

/*
onsole.log("PIN");
onsole.log("x: " + x);
onsole.log("from: " + this.props.from);
*/

// const selected = false;


// onsole.log("RAG: " + this.props.bucket.rag);

		if (this.props.pinWatch)
		{
			if (thisPinIsPastX0)
			{
				this.props.pinWatch(this.props.bucket);
				if (this.props.bucket.intervals && this.props.bucket.intervals.length > 0)
				{
					new DistributionGraphPinSet
					(
						{
							controller:this.props.controller,
							buckets:this.props.bucket.intervals,
							span:this.props.span,
							x:this.props.x,
//							y={this.props.y}
//							usableHeight={this.props.usableHeight}
//							maxCount={this.props.maxCount}
							from:this.props.from,
							to:this.props.to,
							pinWatch:this.props.pinWatch
						}
					).render();
				}
				return true; // We're on a fake run
			
			}
			else return false;
		}

// onClick={() => this.props.parent.filter(this)}
//								<title>{this.props.bucket.title}</title>
//  svg-clickable

		const w = PIN_HEAD_RADIUS << 2;
		const h = this.props.usableHeight + PIN_HEAD_RADIUS;
		const clickerX = x - (PIN_HEAD_RADIUS << 1);
		const clickerY = y - uh - PIN_HEAD_RADIUS;

		return (
			<>
		
				{
					thisPinIsPastX0 ?
						<g onClick={this.clicked}>

							<line
								key={this.key}
								x1={x}
								y1={y}
								x2={x}
								y2={y2}
								stroke="black"
								strokeWidth="1"
							/>

							<circle
								cx={x}
								cy={y2}
								r={PIN_HEAD_RADIUS}
								className={`${this.props.selected ? "bar-selected" : "bar"}${this.props.bucket.rag}`}
							/>

							<rect
								key={`${this.key}clkr`}
								className="bar svg-clickable"
								x={clickerX}
								y={clickerY}
								width={w}
								height={h}

							>
								<title>Value: {this.props.bucket.value}{'\n'}Count: {this.props.bucket.count}</title>
							</rect>

							<text y={y + 14} x={x} className="tick" style={{textAnchor:"left"}} transform={`rotate(20 ${x} ${y + 14})`}>{AqaComponent.abreviateNumber(this.props.bucket.value)}</text>

						</g>
					:
						null
				}

				{
					this.props.bucket.intervals && this.props.bucket.intervals.length > 0
					?
						<DistributionGraphPinSet
							controller={this.props.controller}
							buckets={this.props.bucket.intervals}
							span={this.props.span}
							x={this.props.x}
							y={this.props.y}
							usableHeight={this.props.usableHeight}
							maxCount={this.props.maxCount}
							from={this.props.from}
							to={this.props.to}
							pinWatch={this.props.pinWatch}
							selectedIndex={this.props.selectedIndex}
						/>
					:
						null
				}

			</>		
			
		);

	} // render

} ////

// className={`${this.state.selected ? "bar-selected" : "bar"}${this.props.bucket.rag} svg-clickable`}

/*
	<rect
		

		x={this.props.x}
		width={this.props.barWidth}
		y={this.props.y - this.props.barHeight}
		height={this.props.barHeight}
		onClick={() => this.props.parent.filter(this)}
	>
		<title>{this.props.bucket.title}</title>
	</rect>
*/
