/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/AqaSourceActivityAndSourceForPeriod', '../model/Invoice'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./AqaSourceActivityAndSourceForPeriod'), require('./Invoice'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaStatement = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.AqaSourceActivityAndSourceForPeriod, root.ApiDocumentation.Invoice);
  }
}(this, function(ApiClient, AqaSourceActivityAndSourceForPeriod, Invoice) {
  'use strict';




  /**
   * The AqaStatement model module.
   * @module model/AqaStatement
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaStatement</code>.
   * @alias module:model/AqaStatement
   * @class
   */
  var exports = function() {
    var _this = this;









  };

  /**
   * Constructs a <code>AqaStatement</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaStatement} obj Optional instance to populate.
   * @return {module:model/AqaStatement} The populated <code>AqaStatement</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('activities')) {
        obj['activities'] = ApiClient.convertToType(data['activities'], [AqaSourceActivityAndSourceForPeriod]);
      }
      if (data.hasOwnProperty('blockSize')) {
        obj['blockSize'] = ApiClient.convertToType(data['blockSize'], 'Number');
      }
      if (data.hasOwnProperty('currencyCode')) {
        obj['currencyCode'] = ApiClient.convertToType(data['currencyCode'], 'String');
      }
      if (data.hasOwnProperty('invoice')) {
        obj['invoice'] = Invoice.constructFromObject(data['invoice']);
      }
      if (data.hasOwnProperty('month')) {
        obj['month'] = ApiClient.convertToType(data['month'], 'Number');
      }
      if (data.hasOwnProperty('numberOfSources')) {
        obj['numberOfSources'] = ApiClient.convertToType(data['numberOfSources'], 'Number');
      }
      if (data.hasOwnProperty('numberOfUsers')) {
        obj['numberOfUsers'] = ApiClient.convertToType(data['numberOfUsers'], 'Number');
      }
      if (data.hasOwnProperty('year')) {
        obj['year'] = ApiClient.convertToType(data['year'], 'Number');
      }
    }
    return obj;
  }

  /**
   * @member {Array.<module:model/AqaSourceActivityAndSourceForPeriod>} activities
   */
  exports.prototype['activities'] = undefined;
  /**
   * @member {Number} blockSize
   */
  exports.prototype['blockSize'] = undefined;
  /**
   * @member {String} currencyCode
   */
  exports.prototype['currencyCode'] = undefined;
  /**
   * @member {module:model/Invoice} invoice
   */
  exports.prototype['invoice'] = undefined;
  /**
   * @member {Number} month
   */
  exports.prototype['month'] = undefined;
  /**
   * @member {Number} numberOfSources
   */
  exports.prototype['numberOfSources'] = undefined;
  /**
   * @member {Number} numberOfUsers
   */
  exports.prototype['numberOfUsers'] = undefined;
  /**
   * @member {Number} year
   */
  exports.prototype['year'] = undefined;



  return exports;
}));


