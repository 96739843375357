/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
//import AqaFolderListRow from "./AqaFolderListRow";
import AqaSourceView from "./AqaSourceView";
import MsContext from "../shared/masterservant/MsContext";
import Avatar from "@material-ui/core/Avatar";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
//import {ReactComponent as LoadingIcon} from "../../images/grid.svg";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaFolderView extends AqaComponent {
    // No, seriously, we need this.
    static contextType = MsContext;
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            selectedFolders:[],
            resetCheckbox:false,
            folders: [],
            sources:[]
        };
        this.handleFolder = this.handleFolder.bind(this);
        this.handleSources = this.handleSources.bind(this);
        this.handleFolderSelection = this.handleFolderSelection.bind(this);
        this.handleFolderSelectionReset = this.handleFolderSelectionReset.bind(this);
        this.handleFolderSelectionCheckReset = this.handleFolderSelectionCheckReset.bind(this);
        this.handleViewFolder = this.handleViewFolder.bind(this);
        this.showSourceView = this.showSourceView.bind(this);
        this.handleFoldersReset = this.handleFoldersReset.bind(this);
        this._isMounted=false;
    }

    handleFolder(folderid){
        /*var folderid=event.target.parentElement.parentElement.id;
        if(folderid === ""){
            folderid=event.target.parentElement.parentElement.parentElement.parentElement.parentElement.id;
        }
        if(folderid === ""){
            folderid=event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.id;
        }*/
            this.setState({currentFolder: folderid});
            this.showSourceView(folderid);

    }

    handleFolderSelectionReset(){
        this.setState({selectedFolders:[],resetCheckbox:true});
        this.handleViewFolder(this.state.currentFolder);
        //this.handleFolder(this.props.dataAcquired);
    }

    handleFolderSelectionCheckReset(){
        this.setState({resetCheckbox:false});
        //this.handleFolder(this.props.dataAcquired);
    }
    handleFoldersReset(){
        this.setState({folders:[]});
    }

    handleFolderSelection(folder,isSelected){
        var selectedFolders = this.state.selectedFolders;
        var index = selectedFolders.indexOf(folder);
        if(isSelected === true){
            if(folder !== null && folder !== undefined){
                if(index<0){
                    selectedFolders.push(folder);
                    this.setState({selectedFolders:selectedFolders});
                }
            }
        }
        else{
            if(folder !== null && folder !== undefined){
                if(index!==-1){
                    var newSelectedFolders = [];
                    for(var i=0;i<selectedFolders.length;i++){
                        var selectedFolder = selectedFolders[i];
                        if(selectedFolder !== folder){
                            newSelectedFolders.push(selectedFolder);
                        }
                    }
                    selectedFolders=newSelectedFolders;
                    this.setState({selectedFolders:selectedFolders});
                }
            }
        }
// onsole.log(selectedFolders);
        this.handleViewFolder(this.state.currentFolder);
    }

    handleViewFolder(folderid){
        this.setState({currentFolder:folderid});
        this.showSourceView(folderid);
    }

    showSourceView(folderid){
        this.context.show(<AqaSourceView dataAcquired={folderid} dataFromRoot={this} dataFromMain={this.props.dataFromRoot} dataForFolders={this.state.folders} dataFromMainFrame={this.props.dataFromMain} />);
    }
    handleSources(){
        AqaComponent.backend.sourcesUsingGET(
            {},
            (error, data, response) =>
            {
                var errorJSON = JSON.parse(response.text);
                if (errorJSON.error === "invalid_token") {
                    this.props.dataFromRoot.props.dataFromRoot.popTimeoutDialog2();
                    //this.props.dataFromRoot.props.dataFromRoot.props.parent.logout();
                }
                else {
                    if (error) {
                        this.reportError("A problem getting the sources from the server was encountered.",
                            "AqaFolderView.handleSources, backend call: " + error,this);

                    } else {
                        if(this._isMounted) {
                            this.setState({isLoaded: false, sources: data});
                            this.handleFolders(data);
                        }
                    }
                }
            }
        );
    }
    handleFolders(sources){
        AqaComponent.backend.dirUsingGET(
            (error, data, response) =>
            {
                var errorJSON = JSON.parse(response.text);
                if (errorJSON.error === "invalid_token") {
                    this.props.dataFromRoot.props.dataFromRoot.popTimeoutDialog2();
                    //this.props.dataFromRoot.props.dataFromRoot.props.parent.logout();
                }
                else {
                    if (error) {
                        errorJSON = JSON.parse(response.text);
                        if (errorJSON.error === "invalid_token") {
                            localStorage.removeItem("access_token");
                            localStorage.removeItem("username");
                        }
                        this.reportError("A problem getting the folder from the server was encountered.",
                            "AqaFolderView.handleFolders, backend call: " + error,this);
                    } else {
                        var folders = [];
                        for (var i = 0; i < data.length; i++) {
                            var folder = data[i];
                            var folderid = folder.id;
                            var sourcecount = 0;
                            if (sources !== undefined) {
                                for (var k = 0; k < sources.length; k++) {
                                    var source = sources[k];
                                    var folderids = source.umbrellaIds;
                                    if (folderids !== null) {
                                        if (folderids.includes(folderid)) {
                                            sourcecount = sourcecount + 1
                                        }
                                    }
                                }
                            }
                            var folderSourceCount = {sourcecount: sourcecount};
                            var folderObj = {...folder, ...folderSourceCount};
                            folders.push(folderObj);
                        }

                        if(this._isMounted) {
                            this.setState({isLoaded: true, folders: folders, currentFolder: this.props.dataFromParent});
                            this.showSourceView(this.props.dataFromParent);
                        }
                    }
                }
            }
        );
    }

    componentDidMount(){
        this._isMounted=true;
        this.handleSources();
    }

    componentWillUnmount() {
        this._isMounted=false;
    }

    render() {
        this.show = this.context.show;
        const { classes } = this.props;
        const { error, isLoaded, dense, secondary,redirect, notfound,currentFolder,sources } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <div className={classes.demo} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={10} sm={7} lg={11} xl={11}>
                            <Typography variant="title" color="inherit" align="left"
                                        style={{ fontSize: '1.5rem',
                                            padding: '0.1em 0.5em',
                                            fontFamily: 'Open Sans, sans-serif'}}>
                                Nothing to display
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} lg={1} xl={1}>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>

            </div>;
        } else if (!isLoaded) {
            return (<div className={"aqa-loader"}>
                {/*<div className={"aqa-loading-icon"}>
                    <LoadingIcon fill={"#4cadc4"}/>
                </div>*/}
            </div>);
        } else {
            return (
                <div className={classes.demo} style={{background: "#F8F8F880 0% 0% no-repeat padding-box",borderTop:"1px solid #ccc"}} >

               
               
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <List dense={dense} style={{background:"#F9F7F9",padding:"0px"}}>
                                <ListItem key="allsources" id="allsources" style={{cursor:"pointer",padding:"6px",paddingTop:"7px",paddingBottom:"8px"}} className={(currentFolder ==="allsources")?"aqa-folder-active-all":"aqa-folder"} onClick={e=>this.handleViewFolder("allsources")}  >
                                    <ListItemAvatar>
                                    <Avatar style={{backgroundColor:"transparent"}}>
                                        {/*<IconButton className="aqa-button">
                                            <div className="aqa-folder-list-icon">
                                            <FolderIcon width={"32px"} />
                                            </div>
                                            </IconButton>*/}
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText style={{padding:"0px 4px 4px 4px"}}
                                        primary= {<Typography className="aqa-folder-text" variant="inherit" style={{textAlign:"left",paddingLeft:"0px"}}>
                                            All Data Sources {"("+sources.length+")"}
                                        </Typography>}
                                        secondary={secondary ? ("") : null }
                                    >
                                    </ListItemText>

                                </ListItem>
                                {/*folders.length < 1?<Typography className="aqa-text-action-12" variant="inherit" style={{textAlign:"left",padding:"4px"}}>
                                    The list of folders will be displayed here. Click on the NEW FOLDER button to create a folder
                                </Typography>:
                                    folders.map(item => (
                                    <AqaFolderListRow key={item.id} dataForActive={currentFolder} dataFromParent={item} dataFromRoot={this} dataForCheckbox={this.state.resetCheckbox} />
                                ))*/}
                            </List>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }
}

AqaFolderView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaFolderView);
