/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
//import {ReactComponent as NewSourceIcon} from "../../images/aqa-icons/AQA-Delete.svg";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AqaComponent from "../shared/aqacomponent/AqaComponent";
//import {ReactComponent as FolderIcon} from "../../images/aqa-icons/Foldercol.svg";
//import {ReactComponent as SourceIcon} from "../../images/aqa-icons/Sourcecol.svg";
import {ReactComponent as LinkIcon} from "../../images/aqa-icons/LinkOutline.svg";
import HtmlTooltip from "@material-ui/core/Tooltip";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";

const base64 = require('base-64'); // import doesn't work

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaGetLink extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            openConfirmMessage: false,
            openDeleteMessage: false,
            openProgress:false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            periodicities: [],
            periodicity: "daily",
            SourceName: "",
            SourceDescription: null,
            errorMessage: "",
            file: null,
            folders:[],
            selectedSources:[],
            selectedFolders:[],
            urllink:"",
            resKey:"",
            users:[],
            userType:"",
            selectedUser:"",
            selectedUserText:"",
            specialMessage:"",
            firstname:"",
            lastname:"",
            errorMessage1:"",
            errorMessage2:"",
            showShare:true,
            total:0,
            latestSnapshot:null,
            accountStats:null,
            newUserEnabled:true
        };
        this.handleGetSourceClosePopup=this.handleGetSourceClosePopup.bind(this);
        this.handleGetFoldersClosePopup=this.handleGetFoldersClosePopup.bind(this);
        this.handleSourceGetLink=this.handleSourceGetLink.bind(this);
        this.handleFolderGetLink=this.handleFolderGetLink.bind(this);
        this.handleRemoveFolder=this.handleRemoveFolder.bind(this);
        this.handleClosePopup=this.handleClosePopup.bind(this);
        this.handleAccountPeople=this.handleAccountPeople.bind(this);
        this.handleRadioChange=this.handleRadioChange.bind(this);
        this.handleTextChange=this.handleTextChange.bind(this);
        this.handleShareLink=this.handleShareLink.bind(this);
        this.handleSnapshots=this.handleSnapshots.bind(this);
        this.handleSnapshot=this.handleSnapshot.bind(this);

    }

    handleTextChange = (event) => {
        //var selectedText="";
        if(event.target.name ==="selectedUser" && event.target.value!==""){
            this.setState({[event.target.name]:event.target.value,selectedUserText:event.target.value,errorMessage: "",errorMessage1:"",errorMessage2:"",
                hasError: false});
        }
        else if(event.target.name ==="selectedUser" && event.target.value===""){
            this.setState({[event.target.name]:event.target.value,selectedUserText:"",errorMessage: "",errorMessage1:"",errorMessage2:"",
                hasError: false});
        }
        else if(event.target.name === "selectedUserText"){
            var checkEmail=this.handleValidateEmail(event.target.value);
            if(checkEmail){
                this.setState({[event.target.name]:event.target.value,errorMessage: "",errorMessage1:"",errorMessage2:"",
                    hasError: false});
            }
            else{
                this.setState({[event.target.name]:event.target.value,errorMessage: "Email not valid",errorMessage1:"",errorMessage2:"",
                    hasError: true});
            }
        }
        else{
            this.setState({[event.target.name]:event.target.value,errorMessage: "",errorMessage1:"",errorMessage2:"",
                hasError: false});
        }

    }
    handleRadioChange = (event)=>{
        var selectedText="";
        let popper = false;
        let {authCode,adminEmail} = this.props.dataFromMainFrame.props.parent.state;
        if(event.target.value==="newUser"){
            selectedText="";
            let {accountStats} = this.state;
            if(accountStats!==null) {
                var status = parseInt(accountStats.status);
                if (accountStats.numberOfPurchasedUsers <= accountStats.numberOfDefinedUsers || status < 2) {
                    popper = true;
                }
            }
        }
        if(popper){
            var customNotification = {
                title: "Cannot select New User", titleIcon: "",
                message: <span><span>{"Not enough User Licenses available. "}</span>
                    {authCode === "A" ?
                        <span>Go to your <Button onClick={(e) => this.handleAccountPage(e)} variant="contained"
                                                 color="primary"
                                                 align="left"
                                                 className="aqa-action-button"
                                                 style={{
                                                     marginTop: 0,
                                                     marginLeft: 4,
                                                     marginRight: 4,
                                                     color: '#4cadc4',
                                                     backgroundColor: 'white',
                                                     border: '1px solid #4cadc4',
                                                     fontSize: '0.7rem',
                                                     padding: '3px'
                                                 }}>
                                        Account Details
                                    </Button> page to add the User Licenses</span>
                        : <span> Please contact your administrator {adminEmail}</span>
                    }
                </span>,
                isCustom: false, type: "info",
                insert: "bottom", position: "bottom-center", autoClose: false, duration: 0
            };
            this.removeAllNotifications();
            setTimeout(()=>this.showNotification(null, customNotification),1000);
            return;
        }
        else {
            if (event.target.value !== "") {
                this.setState({
                    [event.target.name]: event.target.value,
                    selectedUser: "",
                    selectedUserText: selectedText,
                    firstname: "",
                    lastname: "",
                    errorMessage: "", errorMessage1: "", errorMessage2: "",
                    hasError: false
                });
            } else {
                this.setState({
                    selectedUser: "",
                    selectedUserText: selectedText,
                    firstname: "",
                    lastname: "",
                    errorMessage: "", errorMessage1: "", errorMessage2: "",
                    hasError: false
                });
            }
        }
    }

    handleValidateEmail(email){
        const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        const result = pattern.test(email);
        return result;
    }

    handleClosePopup(e) {
        e.preventDefault();
//console.log(this.props.dataFromParent);
        this.props.dataFromRoot.handleFolderSelectionReset();
        this.props.dataFromParent.handleSourcesDeleteReset();

        this.setState({ errorMessage:"",openMessage: false,openDeleteMessage: false,SourceName:"",SourceDescription:"",periodicity:"daily",openConfirmMessage:false });
    }

    handleAccountPage = ()=>{
        this.removeAllNotifications();
        this.props.dataFromMainFrame.handleAccountClick();
    }

    handleSourceGetLink(e) {
        e.preventDefault();
        //Check if the selected source is actually reviewed
        let numToFix = 0;
        let {selectedSources} = this.props.dataFromParent.state;
        let subs = this.props.dataFromParent.getSubComponents();
        if(selectedSources.length===1){
            let selectedSource = selectedSources[0];
            let filterSubs = subs.filter((d)=>{return d.props.id === selectedSource.id});
            if(filterSubs.length>0){
                let filterSub = filterSubs[0];
                let {reviewStats} = filterSub.state;
                for(let i=0;i<reviewStats.length;i++){
                    let stat = reviewStats[i];
                    let numF = parseInt(stat.numF);
                    if(!isNaN(numF)) numToFix = numToFix+numF;
                }
            }
        }
        if(numToFix <=0 ){
            var customNotification = {
                title: "Cannot Share", titleIcon: "",
                message: "There are no Alerts reviewed as Must Fix to share",
                isCustom: false, type: "info",
                insert: "center", position: "center", autoClose: true, duration: 5000
            };
            this.removeAllNotifications();
            this.showNotification(null, customNotification);
        }
        else {
            AqaComponent.accountBackend.getCanUseUsingGET(
                (error, data, response) => {
                    if (error) {
                        //alert("Error getting a quote: " + error);
                        this.reportError("A problem getting the account details from the server was encountered.",
                            "AqaAccountOverview.handleAccountDetails, backend call: " + error, this);
                    } else {
                        var urllink = "";
                        var location = window.location;
                        var urlbase = location.href.slice(0, -((location.search + location.hash).length));
                        if (location.search === "") {
                            urlbase = location.href
                        }
                        var selectedSources = this.props.dataFromParent.state.selectedSources;
                        if (selectedSources.length > 0) {
                            var selectedSource = selectedSources[0];
                            var res = {nav: 1, fid: this.props.dataAcquired, scid: selectedSource.id, snid: ""};
                            var reskey = base64.encode(JSON.stringify(res));
                            urllink = urlbase + "?res=" + reskey;
                        }

                        this.setState({openMessage: true, urllink: urllink, resKey: res, accountStats: data});
                    }
                }
            );
        }
    }

    handleGetLinkSource = (source,reviewComplete)=> {

        //Check if the selected source is actually reviewed
        let numToFix = 0;
        let {selectedSources} = this.props.dataFromParent.state;
        let subs = this.props.dataFromParent.getSubComponents();
        if(selectedSources.length===1){
            let selectedSource = selectedSources[0];
            let filterSubs = subs.filter((d)=>{return d.props.id === selectedSource.id});
            if(filterSubs.length>0){
                let filterSub = filterSubs[0];
                let {reviewStats} = filterSub.state;
                for(let i=0;i<reviewStats.length;i++){
                    let stat = reviewStats[i];
                    let numF = parseInt(stat.numF);
                    if(!isNaN(numF)) numToFix = numToFix+numF;
                }
            }
        }
        if(!reviewComplete ){
            var customNotification = {
                title: "Cannot Share", titleIcon: "",
                message: "There are no Alerts reviewed as Must Fix to share",
                isCustom: false, type: "info",
                insert: "center", position: "center", autoClose: true, duration: 5000
            };
            this.removeAllNotifications();
            this.showNotification(null, customNotification);
        }
        else {
            AqaComponent.accountBackend.getCanUseUsingGET(
                (error, data, response) => {
                    if (error) {
                        //alert("Error getting a quote: " + error);
                        this.reportError("A problem getting the account details from the server was encountered.",
                            "AqaAccountOverview.handleAccountDetails, backend call: " + error, this);
                    } else {
                        var urllink = "";
                        var location = window.location;
                        var urlbase = location.href.slice(0, -((location.search + location.hash).length));
                        if (location.search === "") {
                            urlbase = location.href
                        }
                        if (source!==null) {
                            var selectedSource = source;
                            var res = {nav: 1, fid: this.props.dataAcquired, scid: selectedSource.id, snid: ""};
                            var reskey = base64.encode(JSON.stringify(res));
                            urllink = urlbase + "?res=" + reskey;
                        }

                        this.setState({openMessage: true, urllink: urllink, resKey: res, accountStats: data});
                    }
                }
            );
        }
    }

    handleFolderGetLink(e) {
        e.preventDefault();
        AqaComponent.accountBackend.getCanUseUsingGET(
            (error, data, response) => {
                if (error) {
                    //alert("Error getting a quote: " + error);
                    this.reportError("A problem getting the account details from the server was encountered.",
                        "AqaAccountOverview.handleAccountDetails, backend call: " + error, this);
                } else {
                    var urllink = "";
                    var location = window.location;
                    var urlbase = location.href.slice(0, -((location.search + location.hash).length));
                    if (location.search === "") {
                        urlbase = location.href
                    }
                    var selectedFolders = this.props.dataFromRoot.state.selectedFolders;
                    if (selectedFolders.length > 0) {
                        var selectedFolder = selectedFolders[0];
                        var res = {nav: 1, fid: selectedFolder.id, scid: "", snid: ""};
                        var reskey = base64.encode(JSON.stringify(res));
                        urllink = urlbase + "?res=" + reskey;
                    }
                    this.setState({openDeleteMessage: true, urllink: urllink, resKey: res,accountStats:data});
                }
            }
        );
    }

    handleAccountStats = () =>{
        AqaComponent.accountBackend.getCanUseUsingGET(
            (error, data, response) => {
                if (error) {
                    //alert("Error getting a quote: " + error);
                    this.reportError("A problem getting the account details from the server was encountered.",
                        "AqaAccountOverview.handleAccountDetails, backend call: " + error, this);
                } else {
                    let accountStats = data;
                    let popper=true;
                    var status = parseInt(accountStats.status);
                    if (accountStats.numberOfPurchasedUsers <= accountStats.numberOfDefinedUsers || status < 2) {
                        popper = false;
                    }
                    this.setState({accountStats:data,newUserEnabled:popper,userType:!popper?"existingUser":""});
                }
            }
        );
    }

    handleGetSourceClosePopup(e){
        //var urllink = this.state.urllink;
        //navigator.clipboard.writeText(urllink);
        if(this.state.selectedUserText === "" || this.state.selectedUserText === null){
            this.setState({errorMessage:"Email was not provided to share",hasError:true});
        }
        else if(this.state.userType === "newUser" && !this.handleValidateEmail(this.state.selectedUserText)){
            this.setState({errorMessage:"Email is not valid",hasError:true});
        }
        else if(this.state.userType === "newUser" && (this.state.firstname === "" || this.state.firstname === null)){
            this.setState({errorMessage1:"First name was not provided"});
        }
        else if(this.state.userType === "newUser" && (this.state.lastname === "" || this.state.lastname === null)){
            this.setState({errorMessage2:"Last name was not provided"});
        }
        else {
            this.handleShareLink(false);
            
        }
    }

    handleRandomPassword(lettersLength,numbersLength,specialCharsLength) {
        var j, x, i;
        var result           = '';
        var letters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var numbers       = '0123456789';
        var special       = '~!@-#$';
        for (i = 0; i < lettersLength; i++ ) {
            result += letters.charAt(Math.floor(Math.random() * letters.length));
        }
        for (i = 0; i < numbersLength; i++ ) {
            result += numbers.charAt(Math.floor(Math.random() * numbers.length));
        }
        for (i = 0; i < specialCharsLength; i++ ) {
            result += special.charAt(Math.floor(Math.random() * special.length));
        }
        result = result.split("");
        for (i = result.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = result[i];
            result[i] = result[j];
            result[j] = x;
        }
        result = result.join("");
        return result
    }

    handleResWithUsername(res,username){
        var user={username:username};
        res = {...res,...user};
        var reskey = base64.encode(JSON.stringify(res));
        return reskey;
    }

    handleShareLink(isFolder){
        var slr={
            to:this.state.selectedUserText,
            password:this.handleRandomPassword(5,2,1),
            firstname:this.state.firstname,
            lastname:this.state.lastname,
            permissions:"V",
            urlLink: "res="+this.handleResWithUsername(this.state.resKey,this.state.selectedUserText),
            message: this.state.specialMessage,
            resourceType:isFolder?"Folder":"Data Source",
            sourceId: isFolder?"":(this.state.selectedSources.length>0?this.state.selectedSources[0].id:""),
            snapshotId: isFolder?"":(this.state.latestSnapshot!==null?this.state.latestSnapshot.id:""),
            snapshotName: isFolder?"":(this.state.latestSnapshot!==null?this.state.latestSnapshot.label:""),

            snapshotDate: isFolder?"":(this.state.latestSnapshot!==null?moment(this.state.latestSnapshot.dateCreated).format("YYYY/MM/DD HH:mm"):"")
        };
        AqaComponent.userBackend.shareLinkUsingPOST(
            slr,
            (error, data, response) =>
            {
                if (error){
                    var responseMessage=JSON.parse(response.text);
                    if(responseMessage.message !=="" && response.status === 400){
                        if(responseMessage.message==="No violations to share") {
                            this.setState({errorMessage: "Violations are not marked as Must Fix, Unable to share report", hasError: true,openConfirmMessage:true,openMessage:false});
                        }
                        else{
                            this.setState({errorMessage: "Email address already in use",hasError:true});
                        }
                    }
                    else {
                        this.reportError("A problem posting the share link from the server was encountered.",
                            "AqaGetLink.handleShareLink, backend call: " + error,this);
                    }
                }
                else
                {
                    if(isFolder){
                        this.props.dataFromRoot.handleFolderSelectionReset();
                        this.props.dataFromRoot.handleFoldersReset();
                        this.props.dataFromRoot.handleSources();
                        this.props.dataFromRoot.showSourceView(this.props.dataAcquired);
                        this.setState({openMessage: false,openConfirmMessage:false});
                    }
                    else{
                        //this.props.dataFromParent.handleSourceSelectionReset();
                        //this.props.dataFromRoot.handleViewFolder(this.props.dataAcquired);
                        AqaComponent.snapshotBackend.updateSnapshotStatusChangeUsingPOST(
                            this.state.latestSnapshot.id,
                            "Rejected",
                            (error, data, response) => {
                                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                                    "AqaSnapshotMainHeader.handleSnapshotReject, backend call: " + error,this);
                                else {
                                    this.setState({openMessage: false,openConfirmMessage:true});
                                }
                            }
                        )
                    }

                }
            }
        )
    }

    handleGetFoldersClosePopup(e){
        //var urllink = this.state.urllink;
        if(this.state.selectedUserText === "" || this.state.selectedUserText === null){
            this.setState({errorMessage:"User was not provided to share",hasError:true});
        }
        else if(this.state.userType === "newUser" && !this.handleValidateEmail(this.state.selectedUserText)){
            this.setState({errorMessage:"Email is not valid",hasError:true});
        }
        else if(this.state.userType === "newUser" && (this.state.firstname === "" || this.state.firstname === null)){
            this.setState({errorMessage1:"First name was not provided"});
        }
        else if(this.state.userType === "newUser" && (this.state.lastname === "" || this.state.lastname === null)){
            this.setState({errorMessage2:"Last name was not provided"});
        }
        else {
            this.handleShareLink(true);

        }
    }

    handleRemoveFolder(folder,last){
        AqaComponent.backend.sourcesInFolderUsingGET(
            folder.id,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the sources from the server was encountered.", "AqaSourceView.handleFolder, backend call: " + error,this);
                else
                {
                    for(var k=0;k<data.length;k++){
                        var source = data[k];
                        this.handleRemoveSource(folder.id,source,last);
                    }
                }
            }
        );
        this.handleDeleteFolder(folder.id,last);

    }

    handleAccountPeople(){
        AqaComponent.userBackend.allUsingGET(
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the person object from the server was encountered.",
                    "AqaGetLink.handleAccountPeople, backend call: " + error,this);
                else{
                    this.setState({users:data});
                    this.handleAccountStats();
                }
            }
        );
    }

    handleSnapshots(source){
        AqaComponent.backend.getScoresUsingGET(
            source.id,
            {_number:6},
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSourceListRow.handleSnapshotScores, backend call: " + error,this);
                else
                {
                    if(data.length>0){
                        this.setState({showShare:true,total:data[0].score,latestSnapshot:data[0]});
                        this.handleSnapshot(data[0]);
                    }
                    else{
                        this.setState({showShare:false});
                    }
                }
            }

        )
    }

    handleSnapshot(snapshot){
        AqaComponent.snapshotBackend.getSnapshotUsingGET(
            snapshot.id,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSourceListRow.handleSnapshotScores, backend call: " + error,this);
                else
                {
                    this.setState({latestSnapshot:data});
                }
            }

        )
    }

    componentDidMount(){
        this.setState({selectedSources:this.props.dataFromParent.state.selectedSources});
        if(this.props.dataFromParent.state.selectedSources.length===1){
            var selectedSource = this.props.dataFromParent.state.selectedSources[0];
            this.handleSnapshots(selectedSource);
        }
        this.handleAccountPeople();
    }
    render() {
        //const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,errorMessage,users,selectedUser,hasError,errorMessage1,errorMessage2,showShare,newUserEnabled } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%"}}>
                                {
                                    this.props.supress?"":
                                    ((this.props.dataFromRoot.state.selectedFolders.length>0))?(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Click to share selected Folder</Typography>
                                        </div>
                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                    <IconButton className="aqa-button" onClick={this.handleFolderGetLink.bind(this)} disabled={false} >

                                        <div className="aqa-icon" >
                                            <LinkIcon width="32px" style={{fill:"#04A6FC"}}/>
                                        </div>
                                        <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                            SHARE
                                        </Typography>
                                    </IconButton>
                                    </HtmlTooltip>
                                ):(
                                    (this.props.dataFromParent.state.selectedSources.length>0 && this.props.dataForDisabled===false && showShare===true))?(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Click to share alerts</Typography>
                                        </div>
                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                    <IconButton className="aqa-button" onClick={this.handleSourceGetLink.bind(this)} disabled={false} >

                                        <div className="aqa-icon" >
                                            <LinkIcon width="32px" style={{fill:"#04A6FC"}} />
                                        </div>
                                        <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                            SHARE
                                        </Typography>
                                    </IconButton>
                                    </HtmlTooltip>
                                ):(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Operation not permitted</Typography>
                                        </div>
                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <div>
                                    <IconButton className="aqa-button" disabled={true} >

                                        <div className="aqa-icon aqa-icon-d-disabled" >
                                            <LinkIcon width="32px" />
                                        </div>
                                        <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#dadbdb",paddingLeft:"4px",marginTop:"-4px"}}>
                                            SHARE
                                        </Typography>
                                    </IconButton>
                                        </div>
                                    </HtmlTooltip>
                                )}

                            </Typography>
                        </Grid>

                    </Grid>

                    <Dialog
                        open={this.state.openMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                Share Alerts reviewed as Must Fix
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={this.handleClosePopup} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"960px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"red",padding:"5px",paddingLeft:"20px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        Select user
                                    </Typography>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",width:"100%"}}>
                                        <div style={{paddingLeft:"10px"}}>
                                            <span style={{float:"left",width:"20%"}}>
                                            <RadioGroup aria-label="userType" name="userType" value={this.state.userType} color="primary" onChange={this.handleRadioChange} style={{width:"auto"}}>
                                                <FormControlLabel key={"existinguser"} value={"existingUser"} control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                 style={{padding:"0px" }}>
                                                        <span style={{float:"left",width:"280px"}}>{"Existing User"}</span>
                                                    </Typography>)
                                                } style={{width:"120px",margin:"0px"}} />
                                                {newUserEnabled?<FormControlLabel key={"newuser"} value={"newUser"} control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                 style={{padding:"0px" }}>
                                                        <span style={{float:"left",width:"200px"}}>{"New User"}</span>
                                                    </Typography>)
                                                } style={{width:"100%",margin:"0px"}} />:
                                                <FormControlLabel disabled key={"newuser"} value={"newUser"} control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                 style={{padding:"0px" }}>
                                                        <span style={{float:"left",width:"200px"}}>{"New User"}</span>
                                                    </Typography>)
                                                } style={{width:"100%",margin:"0px"}} />
                                                }

                                            </RadioGroup>
                                            </span>
                                            <span style={{float:"left",width:"70%",paddingTop:"10px"}}>
                                                {this.state.userType==="newUser"?
                                                    (
                                                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                              style={{padding: 0, marginTop: 0}}>
                                                            <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                                <TextField
                                                                    id="selectedUserText"
                                                                    name="selectedUserText"
                                                                    label="Email Address"
                                                                    value={this.state.selectedUserText}
                                                                    className={"aqa-text-action-10"}
                                                                    variant="standard"
                                                                    margin="none"
                                                                    type="text"
                                                                    multiline={false}
                                                                    error={hasError}
                                                                    helperText={errorMessage}
                                                                    rows={1}
                                                                    style={{ width: "90%",margin:0,padding:"3px"}}
                                                                    onChange={this.handleTextChange}
                                                                    required
                                                                    InputLabelProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},

                                                                    }}
                                                                    InputProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                                <TextField
                                                                    id="firstname"
                                                                    name="firstname"
                                                                    label="Firstname"
                                                                    value={this.state.firstname}
                                                                    className={"aqa-text-action-10"}
                                                                    variant="standard"
                                                                    margin="none"
                                                                    type="text"
                                                                    multiline={false}
                                                                    error={errorMessage1!==""?true:false}
                                                                    helperText={errorMessage1}
                                                                    rows={1}
                                                                    style={{ width: "90%",margin:0,padding:"3px"}}
                                                                    onChange={this.handleTextChange}
                                                                    required
                                                                    InputLabelProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},

                                                                    }}
                                                                    InputProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                                <TextField
                                                                    id="lastname"
                                                                    name="lastname"
                                                                    label="Lastname"
                                                                    value={this.state.lastname}
                                                                    className={"aqa-text-action-10"}
                                                                    variant="standard"
                                                                    margin="none"
                                                                    type="text"
                                                                    multiline={false}
                                                                    error={errorMessage2!==""?true:false}
                                                                    helperText={errorMessage2}
                                                                    rows={1}
                                                                    style={{ width: "90%",margin:0,padding:"3px"}}
                                                                    onChange={this.handleTextChange}
                                                                    required
                                                                    InputLabelProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},

                                                                    }}
                                                                    InputProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    ):(this.state.userType==="existingUser"?
                                                    <>
                                                        <FormControl variant="standard" style={{ m: 1, minWidth: 520,width:"500" }}>
                                                            <InputLabel id="selectedUser">Existing User</InputLabel>
                                                            <Select
                                                                id="selectedUser"
                                                                name="selectedUser"
                                                                value={selectedUser}
                                                                onChange={this.handleTextChange}
                                                                label="Existing User"
                                                                className={"aqa-text-action"}
                                                                fullWidth
                                                                variant={"standard"}
                                                            >
                                                                <MenuItem className="aqa-text-action" style={{paddingTop:"4px",paddingBottom:"4px"}} value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {users.map(item=>(
                                                                    <MenuItem key={item.id} value={item.email} className={"aqa-text-action"} style={{paddingTop:"4px",paddingBottom:"4px"}}>{item.email}</MenuItem>
                                                                    )


                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </>:
                                                    <></>)
                                                }
                                            </span>
                                            <span style={{float:"left",width:"10%"}}>

                                            </span>
                                        </div>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" className={"aqa-text-action-12"} style={{textAlign:"left",padding:"5px",paddingLeft:"20px"}}>
                                        Summary of the Alert rows reviewed as Must Fix will be included.
                                    </Typography>
                                </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        Add a personal message:
                                    </Typography>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                            <TextField
                                                id="specialMessage"
                                                name="specialMessage"
                                                label="Message"
                                                value={this.state.specialMessage}
                                                className={"aqa-text-action-10"}
                                                variant="outlined"
                                                margin="none"
                                                type="text"
                                                multiline={true}
                                                rows={5}
                                                style={{ width: "90%",margin:0,padding:"3px"}}
                                                onChange={this.handleTextChange}
                                                InputLabelProps={{
                                                    style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"10px",textAlign:"right"},

                                                }}
                                                InputProps={{
                                                    style:{"padding":"5px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"18px",textAlign:"left"},
                                                }}
                                            />
                                    </Typography>

                                </Grid>


                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 10,marginLeft:"0px", marginBottom: 10,borderTop:"1px solid #ccc"}}>

                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: 8,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Cancel
                                    </Button>


                                </Grid>

                                <Grid item xs={10} sm={10} lg={10} xl={10}>
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <Button  variant="contained"
                                             onClick={this.handleGetSourceClosePopup.bind(this)}
                                             color="primary"
                                             align="left"
                                             className="aqa-action-button"
                                             style={{
                                                 marginTop: 8,
                                                 color: '#4cadc4',
                                                 backgroundColor: 'white',
                                                 border: '1px solid #4cadc4',
                                                 fontSize: '0.7rem',
                                                 padding: '3px',
                                             }}>
                                        Share
                                    </Button>



                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.openDeleteMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                Share Folder
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={this.handleClosePopup} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"960px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"red",padding:"5px",paddingLeft:"20px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        Select user
                                    </Typography>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",width:"100%"}}>
                                        <div style={{paddingLeft:"10px"}}>
                                            <span style={{float:"left",width:"20%"}}>
                                            <RadioGroup aria-label="userType" name="userType" value={this.state.userType} color="primary" onChange={this.handleRadioChange} style={{width:"auto"}}>
                                                <FormControlLabel key={"existinguser"} value={"existingUser"} control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                 style={{padding:"0px" }}>
                                                        <span style={{float:"left",width:"280px"}}>{"Existing User"}</span>
                                                    </Typography>)
                                                } style={{width:"120px",margin:"0px"}} />
                                                <FormControlLabel key={"newuser"} value={"newUser"} control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                 style={{padding:"0px" }}>
                                                        <span style={{float:"left",width:"200px"}}>{"New User"}</span>
                                                    </Typography>)
                                                } style={{width:"100%",margin:"0px"}} />

                                            </RadioGroup>
                                            </span>
                                            <span style={{float:"left",width:"70%",paddingTop:"10px"}}>
                                                {this.state.userType==="newUser"?
                                                    (
                                                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                              style={{padding: 0, marginTop: 0}}>
                                                            <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                                <TextField
                                                                    id="selectedUserText"
                                                                    name="selectedUserText"
                                                                    label="Email Address"
                                                                    value={this.state.selectedUserText}
                                                                    className={"aqa-text-action-10"}
                                                                    variant="standard"
                                                                    margin="none"
                                                                    type="text"
                                                                    multiline={false}
                                                                    error={hasError}
                                                                    helperText={errorMessage}
                                                                    rows={1}
                                                                    style={{ width: "90%",margin:0,padding:"3px"}}
                                                                    onChange={this.handleTextChange}
                                                                    required
                                                                    InputLabelProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},

                                                                    }}
                                                                    InputProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                                <TextField
                                                                    id="firstname"
                                                                    name="firstname"
                                                                    label="Firstname"
                                                                    value={this.state.firstname}
                                                                    className={"aqa-text-action-10"}
                                                                    variant="standard"
                                                                    margin="none"
                                                                    type="text"
                                                                    multiline={false}
                                                                    error={errorMessage1!==""?true:false}
                                                                    helperText={errorMessage1}
                                                                    rows={1}
                                                                    style={{ width: "90%",margin:0,padding:"3px"}}
                                                                    onChange={this.handleTextChange}
                                                                    required
                                                                    InputLabelProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},

                                                                    }}
                                                                    InputProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                                <TextField
                                                                    id="lastname"
                                                                    name="lastname"
                                                                    label="Lastname"
                                                                    value={this.state.lastname}
                                                                    className={"aqa-text-action-10"}
                                                                    variant="standard"
                                                                    margin="none"
                                                                    type="text"
                                                                    multiline={false}
                                                                    error={errorMessage2!==""?true:false}
                                                                    helperText={errorMessage2}
                                                                    rows={1}
                                                                    style={{ width: "90%",margin:0,padding:"3px"}}
                                                                    onChange={this.handleTextChange}
                                                                    required
                                                                    InputLabelProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},

                                                                    }}
                                                                    InputProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    ):(this.state.userType==="existingUser"?
                                                        <>
                                                            <FormControl variant="standard" style={{ m: 1, minWidth: 520,width:"500" }}>
                                                                <InputLabel id="selectedUser">Existing User</InputLabel>
                                                                <Select
                                                                    id="selectedUser"
                                                                    name="selectedUser"
                                                                    value={this.state.selectedUser}
                                                                    onChange={this.handleTextChange}
                                                                    label="Existing User"
                                                                    className={"aqa-text-action"}
                                                                    fullWidth
                                                                >
                                                                    <MenuItem className="aqa-text-action" style={{paddingTop:"4px",paddingBottom:"4px"}} value="">
                                                                        <em>None</em>
                                                                    </MenuItem>
                                                                    {users.map(item=>(
                                                                            <MenuItem key={item.id} value={item.email} className={"aqa-text-action"} style={{paddingTop:"4px",paddingBottom:"4px"}}>{item.email}</MenuItem>
                                                                        )


                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </>:
                                                        <></>)
                                                }
                                            </span>
                                            <span style={{float:"left",width:"10%"}}>

                                            </span>
                                        </div>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        Add a personal message:
                                    </Typography>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        <TextField
                                            id="specialMessage"
                                            name="specialMessage"
                                            label="Message"
                                            value={this.state.specialMessage}
                                            className={"aqa-text-action-10"}
                                            variant="outlined"
                                            margin="none"
                                            type="text"
                                            multiline={true}
                                            rows={5}
                                            style={{ width: "90%",margin:0,padding:"3px"}}
                                            onChange={this.handleTextChange}
                                            InputLabelProps={{
                                                style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"10px",textAlign:"right"},

                                            }}
                                            InputProps={{
                                                style:{"padding":"5px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"18px",textAlign:"left"},
                                            }}
                                        />
                                    </Typography>

                                </Grid>


                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",minWidth:window.pageXOffset,width:"960px"}}>

                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: 8,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Cancel
                                    </Button>


                                </Grid>

                                <Grid item xs={10} sm={10} lg={10} xl={10}>
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <Button  variant="contained"
                                             onClick={this.handleGetFoldersClosePopup.bind(this)}
                                             color="primary"
                                             align="left"
                                             className="aqa-action-button"
                                             style={{
                                                 marginTop: 8,
                                                 color: '#4cadc4',
                                                 backgroundColor: 'white',
                                                 border: '1px solid #4cadc4',
                                                 fontSize: '0.7rem',
                                                 padding: '3px',
                                             }}>
                                        Share
                                    </Button>



                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.openConfirmMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                Share Alerts reviewed as Must Fix
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={this.handleClosePopup} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"960px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    {(errorMessage !=="")?
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.9rem",color:"red",padding:"5px",paddingLeft:"20px"}}>
                                            {errorMessage}
                                        </Typography>:
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.9rem",color:"black",padding:"5px",paddingLeft:"20px"}}>
                                            {"Email has been sent to "+this.state.selectedUserText}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>

                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",minWidth:window.pageXOffset,width:"960px"}}>

                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: 8,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Close
                                    </Button>


                                </Grid>

                                <Grid item xs={10} sm={10} lg={10} xl={10}>
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} xl={1}>




                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>
                </div>
            );
        }
    }
}

AqaGetLink.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaGetLink);
