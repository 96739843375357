import React from 'react';
import AqaComponent from "../aqacomponent/AqaComponent";
import Button                  from "../svg/Button";


const OKBG = "#fff";
const BDBG = "#f88";

const BTN_WIDTH = 60;
const FIELD_WIDTH = "180px";

export default class NeedleKbdBoxes extends AqaComponent
{

	constructor(props)
	{
		super(props);

		this.props.controller.registerNeedleKbdBoxes(this);

		this.state = 
		{
			needleBox1:props.values[0],
			needleBox2:props.values[1],
			bg0:OKBG,
			bg1:OKBG,
			filtered:false
		};
	} //



	setBg(ix, b)
	{
		const bgn = `bg${ix}`;
		const nbg = b ? OKBG : BDBG;
		
// onsole.log(this.state[bgn] + " | " + nbg);
		
		if (this.state[bgn] === nbg) return;
		this.setState({[bgn]:nbg});

	} // setBg

	handleChange = (e) =>
	{
		const {name, value} = e.target;

		const zbx = parseInt(name.substring(name.length - 1)) - 1;
		
		this.setState
		(
			{[name]: value},
			() => this.setBg(zbx, this.props.controller.setNeedleValue(zbx, value))
		);
	}; // handleChange

	adjustNeedleBox(bx, value)
	{
/*
onsole.log("ANKB: " + bx + ": " + value);
onsole.log(`      needleBox${bx + 1}`);
*/

		this.setState({[`needleBox${bx + 1}`]:value});
	} // adjustNeedleBox

// <input type="button" value="filter" onClick={this.action} />

	filter = () => this.props.controller.toggleFilter();

	setFiltered(b) { this.setState({filtered:b}); }

	render()
	{
		return (
			<div style={{overflow:"hidden"}}>
				<input type="text" value={this.state.needleBox1} onChange={this.handleChange} name="needleBox1" style={{backgroundColor:this.state.bg0, margin:"0 5px",float:"left", width:FIELD_WIDTH}} />
				<input type="text" value={this.state.needleBox2} onChange={this.handleChange} name="needleBox2" style={{backgroundColor:this.state.bg1, margin:"0 5px",float:"left", width:FIELD_WIDTH}} />
				<div style={{width:BTN_WIDTH,overflow:"hidden",float:"left"}}>
					<svg width={BTN_WIDTH} height={40}>
						<Button
							x={0}
							y={2}
							on={this.state.filtered}
							onClick={this.filter}
							w={BTN_WIDTH}
							label="filter"
						/>
					</svg>
				</div>				
			</div>
		);

	} // render


} ////
