/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MsContext from "../shared/masterservant/MsContext";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import {ReactComponent as LoadingIcon} from "../../images/grid.svg";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
//import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
// import AqaStripeSubscriptionForm from "../stripe/AqaStripeSubscriptionForm";
import {Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import AqaAccountStripe from "./AqaAccountStripe";


// import AqaAccountPlanSelector from "./AqaAccountPlanSelector"


const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaAccountPlan extends AqaComponent {
    // No, seriously, we need this.
    static contextType = MsContext;
    constructor(props) {
        super(props);
        props.parent.accountPlan = this;
        this.state = {
            name:"AqaAccountPlan",
            error: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:"account",
            selectedFolders:[],
            resetCheckbox:false,
            folders: [],
            tabvalue:0,
            openPlanEditor:false,
            openTeamEditor:false,
            errorMessage:"",
            plans:[],
            allPlans:[],
            numberOfBlocks:0,
            currentPlan:null,
            planLength:"Monthly",
            additionalUser:0,
            additionalDataSet:0,
            planToActivate:null,
            planToActivateOn:null,
            paymentMethod:props.account.paymentMethod,
            additionalCost:10,
            totalAmount:0,
            includePlan:false,
            totalPlanCost:0,
            totalAdditionalAmount:0,
            totalAdditionalDataSetAmount:0,
            currency:props.account.currencyCode,
            
            // S Starter
            // T Team
            
            planValues:[
                {name:'GBP',monthSValue:99,monthTValue:234,yearlySValue:49,yearlyTValue:249,addSUser:12,addTUser:11,addSDataSet:7,addTDataSet:6},
                {name:'EUR',monthSValue:119,monthTValue:269,yearlySValue:59,yearlyTValue:259,addSUser:14,addTUser:13,addSDataSet:8,addTDataSet:7},
                {name:'USD',monthSValue:129,monthTValue:299,yearlySValue:69,yearlyTValue:269,addSUser:15,addTUser:14,addSDataSet:9,addTDataSet:8}
            ],
            sValue:99,
            tValue:234,
            addSUser:12,
            addTUser:11,
            addSDataSet:7,
            addTDataSet:6,
            planValue:{name:'GBP',monthSValue:99,monthTValue:234,yearlySValue:49,yearlyTValue:249,addSUser:12,addTUser:11,addSDataSet:7,addTDataSet:6},
            errorResponse:"",
            accountStats:{"totalNumberOfEditors":0,"numberOfReadOnlyUsers":0,"totalNumberOfSources":0,"numberOfPlanEditors":0,"numberOfPlanSources":0,"numberOfAdditionalEditors":0,"numberOfAdditionalSources":0,"numberOfEditorsAllowedInPlan":0,"numberOfSourcesAllowedInPlan":0,"numberOfPurchasedEditors":0,"numberOfPurchasedSources":0,"planId":null},
            needsStripe:false,
            stripeSecret:"",
            prices:[],
            planId:""
        };
        
        
        // Don't use bind
        this.handleClosePopup=this.handleClosePopup.bind(this);
        this.handleCloseTeamPopup=this.handleCloseTeamPopup.bind(this);
        this.handlePurchaseAndClose=this.handlePurchaseAndClose.bind(this);
        this.handlePlanLength=this.handlePlanLength.bind(this);
        this.handleTeamSize=this.handleTeamSize.bind(this);
        this.handleAccountUpdate=this.handleAccountUpdate.bind(this);
        this.handleAccountDetails=this.handleAccountDetails.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handlePriceRequest=this.handlePriceRequest.bind(this);
        this.handleAccountPlanDetails=this.handleAccountPlanDetails.bind(this);
//        this.handleStripePromise=this.handleStripePromise.bind(this);
    }

    handleClosePopup(e){
        e.preventDefault();
        this.setState({openPlanEditor:false,hasError:false,totalAmount:0,totalAdditionalAmount:0,totalPlanCost:0,errorMessage:""});

        this.props.parent.handleClosePlan();
        this.props.parent.props.dataFromRoot.planNavigationReset();
        this.props.dataFromParent.planNavigationReset();
    }

    handlePlanLength(val){
        this.setState({planLength:val});
    }

    handleTeamSize(plan){
        //var additionalUsers = this.state.additionalUser;
        //var additionalDataSets = this.state.additionalDataSet;
        var includePlan=true;
        if(plan !== null){
            if(plan.id === this.props.currentPlan.id && plan.name==="Free"){
                plan = this.state.plans[1];
            }
            else if(plan.id === this.props.currentPlan.id){
                includePlan=false;
            }
        }
        //var account = this.state.accountObj;
        //var currencyText=this.state.currency;
        //const currency = currencyText === "GBP" ? 0 : (currencyText === "EUR" ? 1 : 2);
        //var selectedPlanValue = this.state.planValues[currency];
        //var planLength=this.state.planLength;
        //this.handleChange(includePlan,plan,planLength,selectedPlanValue,additionalUsers,additionalDataSets);
        //this.handlePriceRequest(additionalUsers+plan.numberOfEditors,additionalDataSets+plan.numberOfSources,currencyText,planLength);
        // TODO: REMOVE the Team Editor
        let newplan = this.state.plans.filter((d)=>{return d.id === plan.id});
        if(newplan.length>0) plan = newplan[0];
        this.setState({openPlanEditor:false,openTeamEditor:true,planToActivate:plan,includePlan:includePlan,hasError:false,errorMessage:""});
    }

    handlePurchaseAndClose(e){
        e.preventDefault();
        AqaComponent.backend.clearInterruptedPurchaseUsingPUT
        (
            (error, plans, response) => {
                if (error) this.reportError("A problem clearing the interrupted payments from the server was encountered.",
                    "AqaAccountSettings.componentDidmount, backend call: " + error,this);
                else {
                    const {planId,currency,planLength,planToActivateOn} = this.state;
                    let errors = [];
                    if(planId === "") errors.push(" Type");
                    if(currency === "") errors.push(" Currency");
                    if(planLength === "") errors.push(" Payment Frequency");
                    if(planToActivateOn === null) errors.push(" Plan");
                    if(errors.length>0) this.setState({errorResponse:"Please choose a plan type before clicking ok"});
                    else{
                        this.handleAccountToUpdate(planId,currency,planLength,planToActivateOn.maxNumberOfUsers);
                    }
                }
            }
        )

    }

    handleAccountToUpdate = (planId,currency,planLength,numUsers)=>{
        var accountToUpdate={
            "planId": planId,
            "currency": currency,
            "numberOfEditors": numUsers,
            "numberOfSources": 1,
            "paymentFrequency": planLength
        };
        AqaComponent.salesBackend.updateSubscriptionUsingPUT(
            accountToUpdate,
            (error, data, response) => {
                if (error) this.reportError("A problem occured when updating account.",
                    "AqaAccountPlan.handleAccountUpdate, call: " + error,this);
                else {
                    if(data.length === undefined){
                        var responseJSON = JSON.parse(response.text);
                        if(responseJSON.status === "error"){
                            this.setState({errorResponse:responseJSON.message});
                        }
                        else if(responseJSON.status === "needsStripe"){
// alert("Testing");
// onsole.log(responseJSON.stripeSecret);
                            this.setState({needsStripe:true,stripeSecret:responseJSON.stripeSecret});
                        }
                        else if(responseJSON.status === "ok"){
                            this.setState({openPlanEditor: false, openTeamEditor: false});
                            this.props.parent.handleAccountPlanDetails();
                            this.props.dataFromParent.handleLoggedInUser();
                            //this.props.parent.handleClosePlan();
                            //this.props.dataFromParent.planNavigationReset();
                        }
                    }
                    else {
                        this.setState({openPlanEditor: false, openTeamEditor: false});
                        this.props.parent.handleAccountPlanDetails();
                        //this.props.parent.handleClosePlan();
                        //this.props.dataFromParent.planNavigationReset();
                    }
                }
            }
        );

    }

    handleAccountDetails(accountId){
 //       var accountId=this.props.dataFromParent.props.parent.state.accountId;
        AqaComponent.accountBackend.getMyAccountUsingGET(
 //           accountId,
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountPlan.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    this.handleAccountUpdate(accountObj,this.state.planToActivate);
                }
            }

        );
    }



    handleAccountUpdate(account,plan){
        var numUsers = plan.numberOfEditors;
        var numDataSets = plan.numberOfSources;
        //var totalUsers=numUsers+this.state.additionalUser;
        //var totalDataSets = numDataSets+this.state.additionalDataSet;
        /*if(totalUsers >= 257){
            var customNotification={title:"Reached Max Limit", titleIcon:"",
                message:"You have requested for Additional User Licences that reaches the max limit, contact AQA Team for Enterprise Licence Pricing ",
                isCustom:false, type:"info",
                insert:"bottom",position:"bottom-center",autoClose:false,duration:5000};
            this.removeAllNotifications();
            this.showNotification(null,customNotification);
        }
        else if(totalDataSets >= 513){
            var customNotification={title:"Reached Max Limit", titleIcon:"",
                message:"You have requested for Additional Data Sources that reaches the max limit, contact AQA Team for Enterprise Licence Pricing ",
                isCustom:false, type:"info",
                insert:"bottom",position:"bottom-center",autoClose:false,duration:5000};
            this.removeAllNotifications();
            this.showNotification(null,customNotification);
        }
        else {*/
            /*var accountToUpdate = {
                "currentPlanId": plan.id,
                "id": account.id,
                "name": account.name,
                "numberOfEditors": numUsers + this.state.additionalUser,
                "numberOfSnapshots": plan.numberOfSnapshots,
                "numberOfSources": numDataSets + this.state.additionalDataSet,
                "numberOfViewers": plan.numberOfViewers,
                "paymentMethod": this.state.paymentMethod,
                "paymentLength": this.state.planLength,
                "city": account.city,
                "companyName": account.companyName,
                "country": account.country,
                "firstName": account.firstName,
                "lastName": account.lastName,
                "phoneNumber": account.phoneNumber,
                "postcode": account.postcode,
                "state": account.state,
                "streetAddress": account.streetAddress,
                "suite": account.suite,
                "defaultAdmin": account.defaultAdmin,
                "defaultEmail": account.defaultEmail
            };*/
            var accountToUpdate={
                "planId": plan.id,
                "currency": this.state.currency,
                "numberOfEditors": numUsers,
                "numberOfSources": numDataSets,
                "paymentFrequency": this.state.planLength
            };
            AqaComponent.accountBackend.updateSubscriptionUsingPUT(
                accountToUpdate,
                (error, data, response) => {
                    if (error) this.reportError("A problem occured when updating account.",
                        "AqaAccountPlan.handleAccountUpdate, call: " + error,this);
                    else {
                        if(data.length === undefined){
                            var responseJSON = JSON.parse(response.text);
                            if(responseJSON.status === "error"){
                                this.setState({errorResponse:responseJSON.message});
                            }
                            else if(responseJSON.status === "needsStripe"){
// alert("Testing");
// onsole.log(responseJSON.stripeSecret);
                                this.setState({needsStripe:true,stripeSecret:responseJSON.stripeSecret});
                            }
                            else if(responseJSON.status === "ok"){
                                this.setState({openPlanEditor: false, openTeamEditor: false});
                                this.props.parent.handleClosePlan();
                                this.props.dataFromParent.planNavigationReset();
                            }
                        }
                        else {
                            this.setState({openPlanEditor: false, openTeamEditor: false});
                            this.props.parent.handleClosePlan();
                            this.props.dataFromParent.planNavigationReset();
                        }
                    }
                }
            );

    }

    handleOpenTeamEditor = (isOpen,frequency,currency,prices)=>{
        AqaComponent.accountBackend.getMyAccountUsingGET(
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountPlan.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    if(accountObj.paymentLength!==null) frequency = accountObj.paymentLength;
                    if(accountObj.currencyCode!==null) currency = accountObj.currencyCode;
                    let planId = "";
                    if(accountObj.currentPlanId!==null && accountObj.currentPlanId!=="") planId = accountObj.currentPlanId;
                    let currentPlans = this.props.parent.state.allPlans.filter((d)=>{return d.id === planId;});
                    let planToActivate = currentPlans.length>0?currentPlans[0]:null;
                    this.setState({openTeamEditor:isOpen,planLength:frequency,currency:currency,planId:planId,prices:prices,planToActivateOn:planToActivate});
                    this.handlePrices(this.props.parent.state.allPlans,frequency,currency,planId);
                }
            }

        );

    }

    handleCloseTeamPopup(){
            this.setState({openPlanEditor:false,openTeamEditor:false,errorResponse:"",needsStripe:false});
            this.props.parent.handleClosePlan();
    }

    handleChange(includePlan,plan,planLength,selectedPlanValue,additionalUsers,additionalDataSets){
        var totalAmount=0;
        var totalAdditionalAmount=0;
        var totalAdditionalDataSetAmount=0;
        var totalPlanCost=0;
        var months = 1;
        if(planLength==="Yearly"){
            months=12;
            if(plan === null || plan.name === "Free"){
                totalAmount=0;
            }
            else if(plan.name==="Starter"){
                totalAmount=selectedPlanValue.yearlySValue*months;
                totalPlanCost=totalAmount;
                if(additionalUsers !==0){
                    totalAdditionalAmount = additionalUsers*selectedPlanValue.addSUser*months;
                    totalAmount = totalAmount+totalAdditionalAmount;
                }
                if(additionalDataSets !==0){
                    totalAdditionalDataSetAmount = additionalDataSets*selectedPlanValue.addSDataSet*months;
                    totalAmount = totalAmount+totalAdditionalDataSetAmount;
                }
            }
            else if(plan.name==="Team"){
                totalAmount=selectedPlanValue.yearlyTValue*months;
                totalPlanCost=totalAmount;
                if(additionalUsers !==0){
                    totalAdditionalAmount = additionalUsers*selectedPlanValue.addTUser*months;
                    totalAmount = totalAmount+totalAdditionalAmount;
                }
                if(additionalDataSets !==0){
                    totalAdditionalDataSetAmount = additionalDataSets*selectedPlanValue.addTDataSet*months;
                    totalAmount = totalAmount+totalAdditionalDataSetAmount;
                }
            }
            else{
                totalAmount=0
            }
            if(!includePlan){
                totalAmount=totalAdditionalAmount+totalAdditionalDataSetAmount;
                totalPlanCost=0;
            }
            this.setState({sValue:selectedPlanValue.yearlySValue,
                tValue:selectedPlanValue.yearlyTValue,
                addSUser:selectedPlanValue.addSUser,
                addTUser:selectedPlanValue.addTUser,
                addSDataSet:selectedPlanValue.addSDataSet,
                addTDataSet:selectedPlanValue.addTDataSet,
                planValue:selectedPlanValue,
                totalAmount:totalAmount,
                totalAdditionalAmount:totalAdditionalAmount,
                totalAdditionalDataSetAmount:totalAdditionalDataSetAmount,
                totalPlanCost:totalPlanCost
            })
        }
        else{
            if(plan === null || plan.name === "Free"){
                totalAmount=0;
            }
            else if(plan.name==="Starter"){
                totalAmount=selectedPlanValue.monthSValue;
                totalPlanCost=totalAmount;
                if(additionalUsers !==0){
                    totalAdditionalAmount = additionalUsers*selectedPlanValue.addSUser*months;
                    totalAmount = totalAmount+totalAdditionalAmount;
                }
                if(additionalDataSets !==0){
                    totalAdditionalDataSetAmount = additionalDataSets*selectedPlanValue.addSDataSet*months;
                    totalAmount = totalAmount+totalAdditionalDataSetAmount;
                }
            }
            else if(plan.name==="Team"){
                totalAmount=selectedPlanValue.monthTValue;
                totalPlanCost=totalAmount;
                if(additionalUsers !==0){
                    totalAdditionalAmount = additionalUsers*selectedPlanValue.addTUser*months;
                    totalAmount = totalAmount+totalAdditionalAmount;
                }
                if(additionalDataSets !==0){
                    totalAdditionalDataSetAmount = additionalDataSets*selectedPlanValue.addTDataSet*months;
                    totalAmount = totalAmount+totalAdditionalDataSetAmount;
                }
            }
            else{
                totalAmount=0
            }
            if(!includePlan){
                totalAmount=totalAdditionalAmount+totalAdditionalDataSetAmount;
                totalPlanCost=0;
            }
            this.setState({sValue:selectedPlanValue.monthSValue,
                tValue:selectedPlanValue.monthTValue,
                addSUser:selectedPlanValue.addSUser,
                addTUser:selectedPlanValue.addTUser,
                addSDataSet:selectedPlanValue.addSDataSet,
                addTDataSet:selectedPlanValue.addTDataSet,
                planValue:selectedPlanValue,
                totalAmount:totalAmount,
                totalAdditionalAmount:totalAdditionalAmount,
                totalAdditionalDataSetAmount:totalAdditionalDataSetAmount,
                totalPlanCost:totalPlanCost
            })
        }
    }

    handleTextChange = (event) => {
        event.preventDefault();
        this.setState({hasError:false,errorMessage:""});
        var additionalUsers = this.state.additionalUser;
        var additionalDataSets = this.state.additionalDataSet;
        var currency = this.state.currency;
        if(event.target.name === "additionalUser") {
            if (event.target.value >= 0 && event.target.value <= 256 && this.state.planToActivate !== null &&
                this.state.planToActivate !== undefined && this.state.planToActivate.name !== "Free") {
                this.setState({[event.target.name]: +event.target.value});
                additionalUsers = +event.target.value;
            }
        }
        if(event.target.name === "additionalDataSet") {
            if (event.target.value >= 0 && event.target.value <= 512 && this.state.planToActivate !== null &&
                this.state.planToActivate !== undefined && this.state.planToActivate.name !== "Free") {
                this.setState({[event.target.name]: +event.target.value});
                additionalDataSets = +event.target.value;
            }
        }
        var plan = this.state.planToActivate;
        //var account = this.state.accountObj;
        var ne = plan.numberOfEditors;
        var ns = plan.numberOfSources;
        var planLength = this.state.planLength;
        var u = ne + additionalUsers;
        var s = ns + additionalDataSets;
        this.handlePriceRequest(u,s,currency,planLength);


    }

    handleRadioChange = (event) => {
        //let currencyText;
        //let currency;
        this.setState({[event.target.name]: event.target.value,errorResponse:""});
        if(event.target.name ==="planLength") this.handlePrices(this.props.parent.state.allPlans,event.target.value,this.state.currency,this.state.planId);
        if(event.target.name ==="currency") this.handlePrices(this.props.parent.state.allPlans,this.state.planLength,event.target.value,this.state.planId);
        if(event.target.name ==="planId") {
            let currentPlans = this.props.parent.state.allPlans.filter((d)=>{return d.id === event.target.value;});
            let planToActivate = currentPlans.length>0?currentPlans[0]:null;
            this.setState({planToActivateOn:planToActivate});
            this.handlePrices(this.props.parent.state.allPlans,this.state.planLength,this.state.currency,event.target.value);
        }
        /*var additionalUsers = this.state.additionalUser;
        var additionalDataSets = this.state.additionalDataSet;
        var includePlan=this.state.includePlan;
        var u = this.state.planToActivate.numberOfEditors+ additionalUsers;
        var s = this.state.planToActivate.numberOfSources+ additionalDataSets;
        if(event.target.value === "Yearly" || event.target.value === "Monthly"){
            currencyText = this.state.currency;
            currency = currencyText === "GBP" ? 0 : (currencyText === "EUR" ? 1 : 2);
            var selectedPlanValue = this.state.planValues[currency];
            this.handleChange(includePlan,this.state.planToActivate,event.target.value,selectedPlanValue,additionalUsers,additionalDataSets);
            this.handlePriceRequest(u,s,currencyText,event.target.value);
        }
        if(event.target.value === "GBP" || event.target.value === "EUR" || event.target.value === "USD"){
            currencyText=event.target.value;
            //currency = currencyText==="GBP"?0:(currencyText==="EUR"?1:2);
            //var selectedPlanValue = this.state.planValues[currency];
            //var planLength=this.state.planLength;
            this.handlePlansForCurrency(this.state.allPlans,currencyText,this.state.planToActivate);
            //this.handleChange(includePlan,this.state.planToActivate,planLength,selectedPlanValue,additionalUsers,additionalDataSets);
            //this.handlePriceRequest(u,s,currencyText,planLength);
        }*/
    };

    handlePrices = (plans,frequency,currency,planId)=>{
        let prices = [];
        let totalAmount = 0;
        for(let i=0;i<plans.length;i++){
            let plan = plans[i];
            let pfs = plan.pricePerPeriodAndPerCurrency;
            let cost = pfs!==null?pfs[frequency][currency].price:0;
            if(cost!==0) cost = cost/100;
            let price = {id:plan.id,name:plan.name,maxUsers:plan.maxNumberOfUsers,cost:cost.toLocaleString(),customPrice:false};
            if(planId!=="" && planId!==null && planId === plan.id) totalAmount = cost;
            prices.push(price);
        }
        this.setState({prices:prices,totalAmount:totalAmount});
    }

    handleAccountPlanDetails(){
         AqaComponent.accountBackend.getCanUseUsingGET
         (
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else {
                    this.setState({accountStats:data,additionalUser:data.numberOfAdditionalEditors,additionalDataSet:data.numberOfAdditionalSources});
                }
            }
        );

    }

    handlePriceRequest(u,s,currency,pf){
        var plan = this.state.planToActivate;
        var priceRequest = {numberOfEditors:u, numberOfSources:s, currency:currency,paymentFrequency:pf,planId:this.state.planToActivate.id};
        AqaComponent.backend.priceUsingGET(
            plan.id,
            pf,
            priceRequest,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else {
                    if(data!==undefined) {
                        this.setState({
                            totalAmount: data.price===null?0:data.price/100,
                            totalAdditionalAmount: data.userCost===null?0:data.userCost/100,
                            totalAdditionalDataSetAmount: data.sourceCost===null?0:data.sourceCost/100
                        });
                    }
                }
            }

        );
    }


    handlePlansForCurrency(allPlans,currency,planToActivate){
        //console.log(allPlans);
        let plans=[];
        let sValue = 0;
        let tValue = 0;
        for(let i=0;i<allPlans.length;i++){
            let plan = allPlans[i];
            let prices = plan.prices[currency];
            let numberOfBlocks = 0;
            if(prices !==null && prices.length>=1){
                let price1 = prices[0];
                numberOfBlocks = price1.quantityUpTo;
                var planNB = {numberOfBlocks:numberOfBlocks};
                plan = {...plan.plan,...planNB};
                if(i===1) {sValue = price1.flatAmount;this.setState({sValue:price1.flatAmount});}
                if(i===2) {tValue = price1.flatAmount;this.setState({tValue:price1.flatAmount});}
                plans.push(plan);
            }
            if(prices !== null && prices.length>=2){
                let price2 = prices[1];
                if(i===1) this.setState({addSDataSet:price2.flatAmount});
                if(i===2) this.setState({addTDataSet:price2.flatAmount});
            }
        }
        let totalAmount =0;
        if(planToActivate !== null && planToActivate !== undefined){
            if(planToActivate.name ==="Starter") totalAmount = sValue;
            if(planToActivate.name ==="Team") totalAmount = tValue;
        }
        this.setState({plans:plans,totalAmount:totalAmount});
    }
    

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        /*var planLength=nextProps.planLength;
        var paymentMethod=nextProps.account.paymentMethod;
        if(planLength === null){
            planLength="Monthly";
        }
        if(paymentMethod === null){
            paymentMethod="Credit Card";
        }
        this.setState({openPlanEditor:nextProps.openPlanEditor,currentPlan:nextProps.currentPlan,openTeamEditor:nextProps.openTeamEditor,includePlan:nextProps.includePlan,planToActivate:nextProps.currentPlan,hasError:false,errorMessage:"",planLength:planLength,paymentMethod:paymentMethod,accountObj:nextProps.account});
        if(nextProps.openPlanEditor !== this.state.openPlanEditor){
            this.setState({allPlans:nextProps.allPlans});
            let currency = this.state.currency;
            this.handlePlansForCurrency(nextProps.allPlans,currency,nextProps.currentPlan);

        }
        if(nextProps.openTeamEditor ===true) {
            let currency = this.state.currency;
            this.setState({allPlans:nextProps.allPlans});
            this.handlePlansForCurrency(nextProps.allPlans,currency,nextProps.currentPlan);
            this.handleTeamSize(nextProps.currentPlan);
        }*/
    }

	componentDidMount()
	{
		this.setState({isLoaded:true});
		//this.handleAccountPlanDetails();
        // Find the price
//        this.handleStripePromise();
        this.loadStripe();
	} // componentDidMount

    render() {
        this.show = this.context.show;
        //const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,currency} = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <div className={"source-detail-servant-pane"} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={10} sm={7} lg={11} xl={11}>
                            <Typography variant="title" color="inherit" align="left"
                                        style={{ fontSize: '1.5rem',
                                            padding: '0.1em 0.5em',
                                            fontFamily: 'Open Sans, sans-serif'}}>
                                Nothing to display
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} lg={1} xl={1}>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>

            </div>;
        } else if (!isLoaded) {
            return (<div className={"aqa-loader"}>
                <div className={"aqa-loading-icon"}>
                    <LoadingIcon fill={"#4cadc4"}/>
                </div>
            </div>);
        } else {
            return (
                <div style={{background: "#FFF 0% 0% no-repeat padding-box"}} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                        
                        
                        	{/*
                   			<AqaAccountPlanSelector activated={this.state.openPlanEditor} />
									*/}
                        
                        	{/* Account Plan */}
                        
                            <Dialog
                                open={this.state.openPlanEditor} // Re-instate to get orginal plan viewer
										//	open={false}


                                //onClose={this.handleCloseMessage}
                                aria-labelledby="max-width-dialog-title"
                                maxWidth="lg"
                            >
                                <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                        Change your AQA Plan
                                    </Typography>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                        <IconButton className="aqa-button" onClick={this.handleClosePopup.bind(this)} style={{padding:"0px"}} >
                                            <div className="aqa-g-icon" style={{width:"20px"}}>
                                                <CloseIcon width="20px" />
                                            </div>
                                        </IconButton>
                                    </Typography>
                                </DialogTitle>
                                <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"1060px"}}>
                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                          style={{padding: 0, marginTop: "0px"}}>
                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                            <Typography variant="inherit" className="aqa-settings-header-instruction" style={{paddingTop:"10px"}}>
                                                The affordable data quality testing tool for everyone
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-end"
                                          style={{padding: 0, marginTop: 10}}>
                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                            <span style={{float:"left",width:'10%',color:'white'}}>.</span>
                                            <span style={{float:"left",width:'40%'}}>
                                                <Typography className={"aqa-text-action-bold"} variant="title" align="left"
                                                            style={{padding:"0px" }}>
                                                            <span style={{float:"left",width:"80px",fontSize:'16px',paddingTop:'3px'}}>Currency</span>
                                                        </Typography>
                                               <RadioGroup aria-label="currency" name="currency" value={this.state.currency} color="primary" onChange={this.handleRadioChange} row={true}>
                                                   <FormControlLabel value="USD" control={<Radio color="primary" style={{padding:"4px"}} />} label={
                                                       (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                    style={{}}>
                                                           <span style={{float:"left",width:"80px"}}>$</span>
                                                       </Typography>)
                                                   } style={{width:"80px",margin:"0px"}} />
                                                    <FormControlLabel value="EUR" control={<Radio color="primary" style={{padding:"4px"}} />} label={
                                                        (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                     style={{}}>
                                                            <span style={{float:"left",width:"80px"}}>Euro</span>
                                                        </Typography>)
                                                    } style={{width:"80px",margin:"0px"}} />
                                                   <FormControlLabel value="GBP" control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                       (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                    style={{padding:"0px" }}>
                                                           <span style={{float:"left",width:"80px"}}>£</span>
                                                       </Typography>)


                                                   } style={{width:"80px",margin:"0px"}} />


                                               </RadioGroup>
                                            </span>
                                            <span style={{float:"left",width:'50%'}}>
                                                <Typography className={"aqa-text-action-bold"} variant="title" align="left"
                                                            style={{padding:"0px" }}>
                                                            <span style={{float:"left",width:"80px",fontSize:'16px',paddingTop:'3px'}}>Billing</span>
                                                        </Typography>
                                                <RadioGroup aria-label="planLength" name="planLength" value={this.state.planLength} color="primary" onChange={this.handleRadioChange} row={true}>
                                                    {/*<FormControlLabel value="Yearly" control={<Radio color="primary" style={{padding:"4px"}} />} label={
                                                        (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                     style={{}}>
                                                            <span style={{float:"left",width:"160px"}}>Annual in advance</span>
                                                        </Typography>)
                                                    } style={{width:"160px",margin:"0px"}} />*/}
                                                    <FormControlLabel value="Monthly" control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                        (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                     style={{padding:"0px" }}>
                                                            <span style={{float:"left",width:"160px"}}>Month-to-month</span>
                                                        </Typography>)


                                                    } style={{width:"160px",margin:"0px"}} />


                                               </RadioGroup>
                                            </span>
                                            <span style={{float:"left",width:'0%',color:'white'}}>.</span>

                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                    <Typography className="aqa-text-action" style={{marginLeft:"580px",marginTop:"-30px"}}></Typography>
                                                </Grid>

                                    </Grid>
                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                          style={{padding: 0, marginTop: 0,paddingLeft:'5px',paddingRight:'10px'}}>
                                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                                                {this.props.plans.length!==3?(
                                                    <PricingTable  highlightColor='#3f51b5'>
                                                <PricingSlot  onClick={this.submit} buttonText='UPGRADE TO STARTER' title='FREE' priceText='$0'>
                                                    <PricingDetail className="aqa-price-bullets"> Start testing your data for free in minutes</PricingDetail>
                                                    <PricingDetail className="aqa-price-bullets"> <b>Single</b> User</PricingDetail>
                                                    <PricingDetail className="aqa-price-bullets"> <b>Single</b> Folder</PricingDetail>
                                                    <PricingDetail> <b>Single</b> Source</PricingDetail>
                                                    <PricingDetail> <b>Two</b> Snapshots<br/><br/></PricingDetail>
                                                </PricingSlot>
                                                <PricingSlot highlighted onClick={this.submit} buttonText='BUY STARTER' title='STARTER' priceText='$50'>
                                                    <PricingDetail> <b>Single</b> User</PricingDetail>
                                                    <PricingDetail> <b>Unlimited</b> Folders</PricingDetail>
                                                    <PricingDetail> <b>Unlimited</b> Sources</PricingDetail>
                                                    <PricingDetail> <b>Unlimited</b> Snapshots</PricingDetail>
                                                    <PricingDetail> <b></b>Addition users at <b>50$</b> per user</PricingDetail>
                                                </PricingSlot>
                                                <PricingSlot  onClick={this.submit} buttonText='BUY TEAM' title='TEAM' priceText='$1100'>
                                                    <PricingDetail> <b>Upto 5</b> Users</PricingDetail>
                                                    <PricingDetail> <b>Unlimited</b> Folders</PricingDetail>
                                                    <PricingDetail> <b>Unlimited</b> Sources</PricingDetail>
                                                    <PricingDetail> <b>Unlimited</b> Snapshots</PricingDetail>
                                                    <PricingDetail> <b></b>Addition users at <b>50$</b> per user</PricingDetail>
                                                </PricingSlot>
                                                    </PricingTable>
                                                    ):(
                                                    <PricingTable  highlightColor=''>
                                                        {this.state.plans.map((item,i)=>
                                                            (
                                                                (i===1?
                                                                    <PricingSlot key={item.id}
                                                                         highlighted
                                                                         onClick={() => this.handleTeamSize(item)}
                                                                         buttonText={i === 0 ? (
                                                                             this.props.currentPlan.name === "Free" ? ("UPGRADE TO STARTER") :
                                                                                 (this.props.currentPlan.name === "Starter" ? ("DOWNGRADE TO FREE") :
                                                                                     (this.props.currentPlan.name === "Team" ? ("DOWNGRADE TO FREE") : "BUY STARTER"))) : (
                                                                             (this.props.currentPlan.name === "Starter" ? (i === 1 ? ("EDIT PLAN") : ("UPGRADE TO TEAM")) :
                                                                                 (this.props.currentPlan.name === "Team" ? (i === 2 ? ("EDIT PLAN") : ("DOWNGRADE TO STARTER")) :
                                                                                     "BUY " + item.name.toUpperCase())))}
                                                                         shouldDisplayButton={true}
                                                                         title={item.name.toUpperCase()+" EDITION"}
                                                                         /*priceText={planLength === "Yearly" ? "$" + parseFloat((item.monthlyFee * 12) - ((item.monthlyFee * 12) * (0.2))).toFixed(2) :
                                                                             ("$" + parseFloat(item.monthlyFee).toFixed(0)+"")
                                                                                 }*/
                                                                    >
                                                                        <div className="ribbon-wrapper">
                                                                            <div className="ribbon">
                                                                                <span className="text">Most<br/>popular</span>
                                                                            </div>
                                                                        </div>
                                                                        <PricingDetail>
                                                                            <span className="price-tag has-pink">
                                                                            {currency==="EUR"?<sup className="price-tag-sup">€</sup>:(currency==="GBP"?<sup>£</sup>:<sup>$</sup>)}
                                                                                <span className="price-tag-text">
                                                                                    {this.state.sValue}
                                                                                </span>
                                                                                <small className="price-tag-small">per month</small>
                                                                            </span>
                                                                        </PricingDetail>
                                                                        <PricingDetail><span className="price-sub-header"> Perfect for a busy knowledge worker</span></PricingDetail>
                                                                        <PricingDetail><b>Unlimited</b> Full User Licences</PricingDetail>
                                                                        <PricingDetail> <b>Unlimited</b> Viewer Licences</PricingDetail>
                                                                        <PricingDetail><span className="price-blank-text"> </span></PricingDetail>
                                                                        <PricingDetail><b>{item.numberOfBlocks}</b> blocks *</PricingDetail>
                                                                        <PricingDetail><b>Unlimited</b> Data Uploads</PricingDetail>
                                                                        <PricingDetail><span className="price-blank-text">(extra blocks @ {this.state.currency==="GBP"?"£":(this.state.currency==="EUR"?"€":"$")}{this.state.addSDataSet} per month)</span></PricingDetail>
                                                                    </PricingSlot> :
                                                                    <PricingSlot key={item.id}
                                                                                 onClick={() => this.handleTeamSize(item)}
                                                                                 buttonText={i === 0 ? (
                                                                                     this.props.currentPlan.name === "Free" ? ("UPGRADE TO STARTER") :
                                                                                         (this.props.currentPlan.name === "Starter" ? ("DOWNGRADE TO FREE") :
                                                                                             (this.props.currentPlan.name === "Team" ? ("DOWNGRADE TO FREE") : "BUY STARTER"))) : (
                                                                                     (this.props.currentPlan.name === "Starter" ? (i === 1 ? ("EDIT PLAN") : ("UPGRADE TO TEAM")) :
                                                                                         (this.props.currentPlan.name === "Team" ? (i === 2 ? ("EDIT PLAN") : ("DOWNGRADE TO STARTER")) :
                                                                                             "BUY " + item.name.toUpperCase())))}
                                                                                 shouldDisplayButton={true}
                                                                                 title={item.name.toUpperCase()+ " EDITION"}
                                                                                 /*priceText={planLength === "Yearly" ? "$" + parseFloat((item.monthlyFee * 12) - ((item.monthlyFee * 12) * (0.2))).toFixed(2) : "$" + parseFloat(item.monthlyFee).toFixed(2)*/
                                                                    >
                                                                        {i === 1 ? (<div className="ribbon-wrapper">
                                                                            <div className="ribbon">
                                                                                <span className="text">Most<br/>popular</span>
                                                                            </div>
                                                                        </div>) : ("")}
                                                                        {i === 0 ? (
                                                                                <><PricingDetail>
                                                                            <span className="price-tag has-blue">
                                                                            {currency==="EUR"?<sup className="price-tag-sup">€</sup>:(currency==="GBP"?<sup>£</sup>:<sup>$</sup>)}
                                                                                <span className="price-tag-text">
                                                                                    {0}
                                                                                </span>

                                                                            </span>
                                                                                    </PricingDetail>
                                                                                <PricingDetail> <span className="price-sub-header">Start checking your data now, for free</span></PricingDetail>
                                                                                <PricingDetail><b>{item.numberOfEditors}</b> Full User Licence</PricingDetail>
                                                                                <PricingDetail><b>Unlimited</b> Viewer Licences</PricingDetail>
                                                                                <PricingDetail><span className="price-blank-text"> </span></PricingDetail>
                                                                                <PricingDetail><b>{item.numberOfBlocks}</b> blocks</PricingDetail>
                                                                                <PricingDetail><b>Unlimited</b> Data Uploads</PricingDetail>
                                                                                <PricingDetail><span className="price-blank-text" style={{paddingBottom:"15px"}}> </span></PricingDetail>
                                                                                </>
                                                                            ) :
                                                                                (i === 2 ? (
                                                                                    <>
                                                                                        <PricingDetail>
                                                                            <span className="price-tag has-blue">
                                                                            {currency==="EUR"?<sup className="price-tag-sup">€</sup>:(currency==="GBP"?<sup>£</sup>:<sup>$</sup>)}
                                                                                <span className="price-tag-text">
                                                                                    {this.state.tValue}
                                                                                </span>
                                                                                <small className="price-tag-small">per month</small>
                                                                            </span>
                                                                                        </PricingDetail>
                                                                                        <PricingDetail> <span className="price-sub-header">Perfect for a team of knowledge workers</span></PricingDetail>
                                                                                        <PricingDetail><b>Unlimited</b> Full User Licences</PricingDetail>
                                                                                        <PricingDetail><b>Unlimited</b> Viewer Licences</PricingDetail>
                                                                                        <PricingDetail><span className="price-blank-text"> </span></PricingDetail>
                                                                                        <PricingDetail><b>{item.numberOfBlocks}</b> blocks *</PricingDetail>
                                                                                        <PricingDetail><b>Unlimited</b> Data Uploads</PricingDetail>
                                                                                        <PricingDetail><span className="price-blank-text"> (extra blocks @ {this.state.currency==="GBP"?"£":(this.state.currency==="EUR"?"€":"$")}{this.state.addTDataSet} per month)</span></PricingDetail>

                                                                                    </>
                                                                                ) : (""))}
                                                                    </PricingSlot>
                                                                )

                                                            )

                                                        )}
                                                    </PricingTable>
                                                )}
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                          style={{padding: 0, marginTop:8,marginBottom:8, marginLeft:"0px",borderTop:"1px solid #ccc"}}>
                                        <Grid item xs={2} sm={2} lg={2} xl={2}>
                                            <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                                    color="primary"
                                                    align="right"
                                                    className="aqa-action-button"
                                                    style={{
                                                        marginTop: 8,
                                                        marginLeft: "-40px",
                                                        marginRight: 8,
                                                        color: '#4cadc4',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #4cadc4',
                                                        fontSize: '0.7rem',
                                                        padding: '3px'
                                                    }}>
                                                Cancel
                                            </Button>

                                        </Grid>
                                        <Grid item xs={4} sm={4} lg={4} xl={4}>

                                        </Grid>
                                        <Grid item xs={6} sm={6} lg={6} xl={6}>
                                        </Grid>

                                    </Grid>
                                </DialogContent>
                            </Dialog>
									{/* Account Plan */}






                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Dialog
                                open={this.state.openTeamEditor}
                                //onClose={this.handleCloseMessage}
                                aria-labelledby="max-width-dialog-title"
                                maxWidth="lg"
                            >
                                <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                    {this.state.planToActivate!==null && this.state.planToActivate.name !== undefined?this.state.planToActivate.name.toUpperCase():"Paid"} Plan
                                    </Typography>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                        <IconButton className="aqa-button" onClick={this.handleCloseTeamPopup} style={{padding:"0px"}} >
                                            <div className="aqa-g-icon" style={{width:"20px"}}>
                                                <CloseIcon width="20px" />
                                            </div>
                                        </IconButton>
                                    </Typography>
                                </DialogTitle>
                                <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"960px"}}>
                                    {this.state.needsStripe===true?(
                                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                              style={{padding: 0, marginTop: "0px"}}>
                                            <Grid item xs={12} sm={12} lg={12} xl={12}>

                                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: "0px"}}>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                <Elements stripe={this.state.stripePromise}><ElementsConsumer>{({stripe, elements}) => (
                                                                    <AqaAccountStripe stripe={stripe} elements={elements} {...this.state} parent={this}/>
                                                                )}</ElementsConsumer></Elements>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ):(<>
                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                          style={{padding: 0, marginTop: "0px"}}>
                                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                  style={{padding: 0, marginTop: "0px"}}>
                                                <Grid item xs={7} sm={7} lg={7} xl={7}>

                                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                          style={{padding: 0, marginTop: "0px",borderRight:"1px solid #ccc"}}>

                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                            <div>
                                                                <Typography className={"aqa-text-action-bold"} variant="title" align="left"
                                                                            style={{padding:"0px",marginRight:"10px" }}>
                                                                    <span style={{float:"left",width:"180px",fontSize:'16px',paddingTop:'13px',paddingLeft:"20px",fontWeight:'300'}}>Payment Frequency</span>
                                                                </Typography>
                                                                <div style={{"padding":"10px 0px 0px 10px"}}>
                                                                    <span style={{float:"left",width:"50%"}}>

                                                                        <RadioGroup aria-label="planLength" name="planLength" value={this.state.planLength} color="primary" onChange={this.handleRadioChange} row={true}>
                                                                            <FormControlLabel value="Monthly" control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                                                (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                             style={{padding:"0px" }}>
                                                                                    <span style={{float:"left",width:"80px"}}>Monthly</span>
                                                                                </Typography>)


                                                                            } style={{width:"80px",margin:"0px"}} />
                                                                            <FormControlLabel value="Yearly" control={<Radio color="primary" style={{padding:"4px"}} />} label={
                                                                                (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                             style={{}}>
                                                                                    <span style={{float:"left",width:"80px"}}>Yearly</span>
                                                                                </Typography>)
                                                                            } style={{width:"80px",margin:"0px"}} />

                                                                                            </RadioGroup>
                                                                    </span>

                                                                </div>
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                            <div style={{marginTop:"10px"}}>
                                                                <Typography className={"aqa-text-action-bold"} variant="title" align="left"
                                                                            style={{padding:"0px",marginRight:"10px" }}>
                                                                    <span style={{float:"left",width:"180px",fontSize:'16px',paddingTop:'3px',paddingLeft:"20px",fontWeight:'300'}}>Currency</span>
                                                                </Typography>
                                                                <RadioGroup aria-label="currency" name="currency" value={this.state.currency} color="primary" onChange={this.handleRadioChange} row={true}>
                                                                    <FormControlLabel value="USD" control={<Radio color="primary" style={{padding:"4px"}} />} label={
                                                                        (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                     style={{}}>
                                                                            <span style={{float:"left",width:"80px"}}>$</span>
                                                                        </Typography>)
                                                                    } style={{width:"80px",margin:"0px"}} />
                                                                    <FormControlLabel value="EUR" control={<Radio color="primary" style={{padding:"4px"}} />} label={
                                                                        (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                     style={{}}>
                                                                            <span style={{float:"left",width:"80px"}}>€</span>
                                                                        </Typography>)
                                                                    } style={{width:"80px",margin:"0px"}} />

                                                                    <FormControlLabel value="GBP" control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                                        (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                     style={{padding:"0px" }}>
                                                                            <span style={{float:"left",width:"80px"}}>£</span>
                                                                        </Typography>)


                                                                    } style={{width:"80px",margin:"0px"}} />

                                                                </RadioGroup>
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                            <div style={{marginTop:"10px"}}>
                                                                <Typography className={"aqa-text-action-bold"} variant="title" align="left"
                                                                            style={{padding:"0px",marginRight:"10px" }}>
                                                                    <span style={{float:"left",width:"180px",fontSize:'16px',paddingTop:'3px',paddingLeft:"20px",fontWeight:'300'}}>Type</span>
                                                                </Typography>
                                                                <RadioGroup aria-label="planId" name="planId" value={this.state.planId} color="primary" onChange={this.handleRadioChange}>
                                                                    {this.state.prices.filter((d)=>{return d.customPrice!==true;}).map((item)=>
                                                                        <FormControlLabel key={item.id} value={item.id} control={<Radio color="primary" style={{padding:"4px"}} />} label={
                                                                            (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                         style={{}}>
                                                                                <span style={{float:"left",width:"300px",font:"Montserrat",fontSize:"12px"}}><span>{item.name +" - "}</span><sup style={{top:"-0.6rem",width:"20px"}}>{(this.state.currency!==""?(this.state.currency==="EUR"?"€":(this.state.currency==="GBP"?"£":"$")):"$")}</sup><span style={{fontSize:"18px"}}>{item.cost.toLocaleString()}</span><span>{(this.state.planLength!==null?(this.state.planLength==="Yearly"?" per year":" per month"):" per month")}</span></span>
                                                                            </Typography>)
                                                                        } style={{width:"80px",margin:"0px"}} />

                                                                    )}

                                                                </RadioGroup>
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                            {/*<div>
                                                                <Typography className={"aqa-text-bold"} style={{padding:"16px 0px 0px 20px"}}>
                                                                    Existing Plan
                                                                </Typography>
                                                                <div style={{"padding":"10px 0px 0px 10px"}}>
                                                                    <span style={{float:"left",width:"120px",fontFamily:"Montserrat",fontSize:"12px",paddingTop:"6px"}}>
                                                                        Full User Licences
                                                                    </span>
                                                                    <span style={{float:"left",width:"20%"}}>
                                                                        {this.state.accountObj!== undefined?this.state.accountObj.numberOfEditors:0}
                                                                    </span>
                                                                </div>
                                                                <div style={{"padding":"0px 0px 0px 10px"}}>
                                                                    <span style={{float:"left",width:"120px",fontFamily:"Montserrat",fontSize:"12px",paddingTop:"6px"}}>
                                                                        Data Sources
                                                                    </span>
                                                                    <span style={{float:"left",width:"20%"}}>
                                                                        {this.state.accountObj!== undefined?this.state.accountObj.numberOfSources:0}
                                                                    </span>
                                                                </div>
                                                            </div>*/}
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                            {/*<div>
                                                                <Typography className={"aqa-text-bold"} style={{padding:"16px 0px 0px 20px"}}>
                                                                    Update Plan
                                                                </Typography>
                                                                <div style={{"padding":"10px 0px 0px 10px"}}>
                                                                    <span style={{float:"left",width:"120px",fontFamily:"Montserrat",fontSize:"12px",paddingTop:"6px"}}>
                                                                        Full User Licences
                                                                    </span>
                                                                    <span style={{float:"left",width:"20%"}}>
                                                                        {this.state.planToActivate===null?0:this.state.planToActivate.numberOfEditors}
                                                                    </span>
                                                                </div>
                                                                <div style={{"padding":"0px 0px 0px 10px"}}>
                                                                    <span style={{float:"left",width:"120px",fontFamily:"Montserrat",fontSize:"12px",paddingTop:"6px"}}>
                                                                        Data Sources
                                                                    </span>
                                                                    <span style={{float:"left",width:"20%"}}>
                                                                        {this.state.planToActivate===null?0:this.state.planToActivate.numberOfSources}
                                                                    </span>
                                                                </div>
                                                            </div>*/}
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                            {/*<div>
                                                                <Typography className={"aqa-text-bold"} style={{padding:"16px 0px 0px 20px"}}>
                                                                    Extras
                                                                </Typography>
                                                                <div style={{"padding":"10px 0px 0px 10px"}}>
                                                                    <span style={{float:"left",width:"120px",fontFamily:"Montserrat",fontSize:"12px",paddingTop:"6px"}}>
                                                                        Full User Licences
                                                                    </span>
                                                                    <span style={{float:"left",width:"20%"}}>

                                                                        <TextField
                                                                            id="additionalUser"
                                                                            name="additionalUser"
                                                                            value={this.state.additionalUser}
                                                                            className={"aqa-text-action-10"}
                                                                            variant="standard"
                                                                            margin="none"
                                                                            type="number"
                                                                            multiline={false}
                                                                            error={hasError}
                                                                            helperText={errorMessage}
                                                                            rows={1}
                                                                            style={{ width: "50%",margin:0,padding:"3px"}}
                                                                            onChange={this.handleTextChange}
                                                                            required
                                                                            InputLabelProps={{
                                                                                style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},

                                                                            }}
                                                                            InputProps={{
                                                                                style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},
                                                                            }}
                                                                        />
                                                                    </span>

                                                                </div>
                                                                <div style={{"padding":"0px 0px 0px 10px"}}>
                                                                    <span style={{float:"left",width:"120px",fontFamily:"Montserrat",fontSize:"12px",paddingTop:"6px"}}>
                                                                        Data Sources
                                                                    </span>
                                                                    <span style={{float:"left",width:"30%"}}>

                                                                        <TextField
                                                                            id="additionalDataSet"
                                                                            name="additionalDataSet"
                                                                            value={this.state.additionalDataSet}
                                                                            className={"aqa-text-action-10"}
                                                                            variant="standard"
                                                                            margin="none"
                                                                            type="number"
                                                                            multiline={false}
                                                                            error={hasError}
                                                                            helperText={errorMessage}
                                                                            rows={1}
                                                                            style={{ width: "50%",margin:0,padding:"3px"}}
                                                                            onChange={this.handleTextChange}
                                                                            required
                                                                            InputLabelProps={{
                                                                                style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},

                                                                            }}
                                                                            InputProps={{
                                                                                style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},
                                                                            }}
                                                                        />
                                                                    </span>

                                                                </div>
                                                            </div>*/}
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                            <Typography className={"aqa-text-sub"} style={{padding:"16px 0px 0px 20px",color:"red"}}>
                                                                {this.state.errorResponse!==""?this.state.errorResponse:""}
                                                            </Typography>
                                                            {/*<div>
                                                                <Typography className={"aqa-text-bold"} style={{padding:"16px 0px 0px 20px"}}>
                                                                    Payment Type
                                                                </Typography>
                                                                <div style={{"padding":"10px 0px 0px 10px"}}>
                                                                    <span style={{float:"left",width:"20%"}}>

                                                                        <RadioGroup aria-label="paymentMethod" name="paymentMethod" value={this.state.paymentMethod} color="primary" onChange={this.handleRadioChange}>
                                                                            <FormControlLabel value="manual" control={<Radio color="primary" style={{padding:"4px"}} />} label={
                                                                                (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                             style={{}}>
                                                                                    <span style={{float:"left",width:"280px"}}>Manual</span>
                                                                                </Typography>)
                                                                            } style={{width:"280px",margin:"0px"}} />
                                                                            <FormControlLabel value="automatic" control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                                                (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                             style={{padding:"0px" }}>
                                                                                    <span style={{float:"left",width:"280px"}}>Automatic</span>
                                                                                </Typography>)


                                                                            } style={{width:"280px",margin:"0px"}} />


                                                                                            </RadioGroup>
                                                                    </span>

                                                                </div>
                                                            </div>*/}
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                            {/*this.state.paymentMethod==="Credit Card"?
                                                                (<div>
                                                                    <Typography className={"aqa-text-bold"} style={{padding:"16px 0px 0px 20px"}}>
                                                                        Credit Card Details
                                                                    </Typography>
                                                                    <div style={{"padding":"10px 0px 0px 10px"}}>
                                                                    <span style={{float:"left",width:"20%"}}>


                                                                    </span>

                                                                    </div>
                                                                </div>):
                                                                ("")*/
                                                            }
                                                            {/*this.state.paymentMethod==="Credit Card"?
                                                                (<div>
                                                                    <Typography className={"aqa-text-bold"} style={{padding:"16px 0px 0px 20px"}}>
                                                                        Billing Information
                                                                    </Typography>
                                                                    <div style={{"padding":"10px 0px 0px 10px"}}>
                                                                    <span style={{float:"left",width:"20%"}}>


                                                                    </span>

                                                                    </div>
                                                                </div>):
                                                                ("")*/
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                                <Grid item xs={5} sm={5} lg={5} xl={5}>
                                                    {this.state.planToActivate!==null?(
                                                        <div>
                                                        <div style={{paddingTop:"20px"}}>

                                                        </div>
                                                            <div style={{paddingLeft:"20px"}}>
                                                    <ul className="price">
                                                        {this.state.planToActivate.name==="Starter"?(<>
                                                        <PricingDetail><span className="price-sub-header" style={{fontSize:"18px",minHeight:"30px"}}> Perfect for a busy knowledge worker</span></PricingDetail>
                                                        <PricingDetail><span className="price-sub-header" style={{fontSize:"14px",minHeight:"20px"}}> Includes the following features:</span></PricingDetail>
                                                        <PricingDetail><b>Unlimited</b> Full User Licences</PricingDetail>
                                                        <PricingDetail> <b>Unlimited</b> Viewer Licences</PricingDetail>
                                                        <PricingDetail><span className="price-blank-text"></span></PricingDetail>
                                                        <PricingDetail><b>{this.state.planToActivate.numberOfBlocks}</b> blocks *</PricingDetail>
                                                        <PricingDetail><b>Unlimited</b> Data Uploads</PricingDetail>
                                                        <PricingDetail><span className="price-blank-text">(extra blocks @ {this.state.currency==="GBP"?"£":(this.state.currency==="EUR"?"€":"$")}{this.state.addSDataSet} per month)</span></PricingDetail>

                                                            </>
                                                            ):(
                                                            this.state.planToActivate.name==="Team"?
                                                                (<>
                                                                    <PricingDetail> <span className="price-sub-header" style={{fontSize:"18px",minHeight:"30px"}}>Perfect for a team of knowledge workers</span></PricingDetail>
                                                                    <PricingDetail><span className="price-sub-header" style={{fontSize:"14px",minHeight:"20px"}}> Includes the following features:</span></PricingDetail>
                                                                    <PricingDetail><b>Unlimited</b> Full User Licences</PricingDetail>
                                                                    <PricingDetail><b>Unlimited</b> Viewer Licences</PricingDetail>
                                                                    <PricingDetail><span className="price-blank-text"> </span></PricingDetail>
                                                                    <PricingDetail><b>{this.state.planToActivate.numberOfBlocks}</b> blocks *</PricingDetail>
                                                                    <PricingDetail><b>Unlimited</b> Data Uploads</PricingDetail>
                                                                    <PricingDetail><span className="price-blank-text"> (extra blocks @ {this.state.currency==="GBP"?"£":(this.state.currency==="EUR"?"€":"$")}{this.state.addTDataSet} per month)</span></PricingDetail>


                                                                </>):
                                                                (<>
                                                                    <PricingDetail> <span className="price-sub-header" style={{fontSize:"18px",minHeight:"30px"}}>Start checking your data now, for free</span></PricingDetail>
                                                                    <PricingDetail><span className="price-sub-header" style={{fontSize:"14px",minHeight:"20px"}}> Includes the following features:</span></PricingDetail>
                                                                    <PricingDetail><b>{this.state.planToActivate.numberOfEditors}</b> Full User Licence</PricingDetail>
                                                                    <PricingDetail><b>Unlimited</b> Viewer Licences</PricingDetail>
                                                                    <PricingDetail><span className="price-blank-text"> </span></PricingDetail>
                                                                    <PricingDetail><b>{this.state.planToActivate.numberOfBlocks}</b> blocks</PricingDetail>
                                                                    <PricingDetail><b>Unlimited</b> Data Uploads</PricingDetail>
                                                                    <PricingDetail><span className="price-blank-text"> </span></PricingDetail>
                                                                </>)
                                                        )}


                                                    </ul></div></div>):("")}
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                          style={{padding: 0, marginTop:0,marginBottom:8, marginLeft:"0px",borderTop:"1px solid #ccc"}}>
                                        <Grid item xs={3} sm={3} lg={3} xl={3}>
                                            <Button onClick={this.handlePurchaseAndClose.bind(this)} variant="contained"
                                                    color="primary"
                                                    align="right"
                                                    className="aqa-action-button"
                                                    style={{
                                                        marginTop: 18,
                                                        marginLeft: "-50px",
                                                        marginRight: 8,
                                                        color: '#4cadc4',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #4cadc4',
                                                        fontSize: '0.7rem',
                                                        padding: '3px'
                                                    }}>
                                                Ok
                                            </Button>
                                            <Button onClick={this.handleCloseTeamPopup} variant="contained"
                                                    color="primary"
                                                    align="right"
                                                    className="aqa-action-button"
                                                    style={{
                                                        marginTop: 18,
                                                        marginLeft: "0px",
                                                        marginRight: 8,
                                                        color: '#4cadc4',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #4cadc4',
                                                        fontSize: '0.7rem',
                                                        padding: '3px'
                                                    }}>
                                               Cancel
                                            </Button>

                                        </Grid>
                                        <Grid item xs={4} sm={4} lg={4} xl={4}>
                                            <div style={{padding:"5px",width:"480px"}}>
                                                {/*<div style={{textAlign:"left",fontFamily:"Montserrat",fontSize:"12px",width:"450px",height:'20px'}}>
                                                    <span style={{float:'left',width:'160px'}}>{this.state.planToActivate!== null ?this.state.planToActivate.name.toUpperCase():"FREE"} Plan - </span>
                                                    <span style={{float:'left',width:'40px'}}>{this.state.includePlan===true?"1":"0"}</span>
                                                    <span style={{float:'left',width:'20px'}}> X </span>
                                                    <span style={{float:'left',width:'20px'}}>{this.state.planLength==="Yearly"?"12":"1"}</span>
                                                    <span style={{float:'left',width:'70px'}}> Month(s) - </span>
                                                    <span style={{float:'left',width:'60px'}}>{this.state.currency==="GBP"?"£":(this.state.currency==="EUR"?"€":"$")} {this.state.totalPlanCost.toLocaleString()}</span>
                                                    <span style={{float:'left',width:'10px'}}></span>
                                                </div>*/}
                                                {/*<div style={{textAlign:"left",fontFamily:"Montserrat",fontSize:"12px",width:"450px",height:'20px',paddingTop:"10px"}}>
                                                    <span style={{float:'left',width:'160px'}}>Full User Licence(s) - </span>
                                                    <span style={{float:'left',width:'40px'}}>{this.state.planToActivate!==null?this.state.planToActivate.numberOfEditors+this.state.additionalUser:this.state.accountStats.numberOfPlanEditors+this.state.additionalUser}</span>
                                                    <span style={{float:'left',width:'20px'}}> X </span>
                                                    <span style={{float:'left',width:'20px'}}>{this.state.planLength==="Yearly"?"12":"1"}</span>
                                                    <span style={{float:'left',width:'70px'}}> Month(s) - </span>
                                                    <span style={{float:'left',width:'60px'}}>{this.state.currency==="GBP"?"£":(this.state.currency==="EUR"?"€":"$")} {this.state.totalAdditionalAmount.toLocaleString()} </span>
                                                    <span style={{float:'left',width:'10px'}}> </span>
                                                </div>
                                                <div style={{textAlign:"left",fontFamily:"Montserrat",fontSize:"12px",width:"450px",height:'20px'}}>
                                                    <span style={{float:'left',width:'160px'}}>Data Source(s) - </span>
                                                    <span style={{float:'left',width:'40px'}}>{this.state.planToActivate!==null?this.state.planToActivate.numberOfSources+this.state.additionalDataSet:this.state.accountStats.numberOfPlanSources+this.state.additionalDataSet}</span>
                                                    <span style={{float:'left',width:'20px'}}> X </span>
                                                    <span style={{float:'left',width:'20px'}}>{this.state.planLength==="Yearly"?"12":"1"}</span>
                                                    <span style={{float:'left',width:'70px'}}> Month(s) - </span>
                                                    <span style={{float:'left',width:'60px'}}>{this.state.currency==="GBP"?"£":(this.state.currency==="EUR"?"€":"$")} {this.state.totalAdditionalDataSetAmount.toLocaleString()} </span>
                                                    <span style={{float:'left',width:'10px'}}> </span>
                                                </div>*/}
                                                <div style={{textAlign:"right",fontFamily:"Montserrat",fontSize:"12px",width:"350px"}}>
                                                    <span style={{float:'left',width:'20px'}}></span>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={5} sm={5} lg={5} xl={5}>
                                            <div style={{padding:"5px"}}>
                                                {/*<div style={{textAlign:"left",fontWeight:"bold",fontFamily:"Montserrat",fontSize:"28px",paddingTop:"15px"}}><span style={{float:"left",width:"100px"}}>Total: <sup style={{top:"-1.8rem",fontSize:"1rem",width:"20px"}}>{this.state.currency==="GBP"?"£":(this.state.currency==="EUR"?"€":"$")}</sup></span> <span style={{float:"left",fontSize:"50px",marginTop:"-20px"}}>{this.state.totalAmount.toLocaleString()}</span><small style={{fontSize:"16px"}}>{this.state.planLength==="Yearly"?"per year":"per month"}</small></div>*/}
                                                <div style={{textAlign:"left",fontWeight:"bold",fontFamily:"Montserrat",fontSize:"28px",paddingTop:"15px"}}><span style={{float:"left",width:"100px"}}>Total: <sup style={{top:"-1.8rem",fontSize:"1rem",width:"20px"}}>{this.state.currency==="GBP"?"£":(this.state.currency==="EUR"?"€":"$")}</sup></span> <span style={{float:"left",fontSize:"50px",marginTop:"-20px"}}>{this.state.totalAmount.toLocaleString()}</span><small style={{fontSize:"16px"}}>{this.state.planLength==="Yearly"?" per year":" per month"}</small></div>
                                            </div>
                                        </Grid>

                                    </Grid>
                                        </>)}
                                </DialogContent>
                            </Dialog>

                        </Grid>
                    </Grid>
                </div>
            );
        }
    }
}

AqaAccountPlan.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaAccountPlan);



	/*
    // Code in here to go to any setup change (more / less editors. more / less sources)
    // IT MAKES SURE the stripe subcomponent is ready.
    handleStripePromise ()
    {
        // A function to invoke when async functions have come back
        // For example to reset the cursor to its normal aspect
        let postOp = false;

        // The code to run once we are ready.
        const completionFunction = () =>
        {
            if (postOp) postOp();

        }


        if (stripePromise === null)
        {

            // We must set the stripe promise which we will obtain by using the stripe publishable key which held by the server.

            // NOTE: Here, you should indicate a possible wait, like a hour-glass mouse pointer.
            //       WE SHOULD ALSO PREVENT ANY OTHER USER INPUT (with a a modal box maybe?)


            // CODE FOR WAIT DEVICE HERE
            // postOp = () => { ... }

            // Uncomment and populate this
            // postOp = () => { / * code to restore the mouse to its normal shape FOR EXAMPLE * / };

            // We haven't retrieved the stripe publishable key yet.
            AqaComponent.backend.get Stripe Publishable Key UsingGET
            (
                (error, data, response) =>
                {
                    if (error) this.reportError("Sorry, there was an error.\nPlease retry a bit later", error);
                    else
                    {
                        stripePromise = loadStripe(data["message"]);
                        completionFunction();
                    }
                }
            );

        }
        else
        {
            // The stripe promise has already been set, so we're good to go immediately.
            // No need to show any 'please wait device' 
            completionFunction();
        }

    } // handleStripePromise
    
	*/




