/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaDistributionBucket = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The AqaDistributionBucket model module.
   * @module model/AqaDistributionBucket
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaDistributionBucket</code>.
   * @alias module:model/AqaDistributionBucket
   * @class
   */
  var exports = function() {
    var _this = this;








  };

  /**
   * Constructs a <code>AqaDistributionBucket</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaDistributionBucket} obj Optional instance to populate.
   * @return {module:model/AqaDistributionBucket} The populated <code>AqaDistributionBucket</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('count')) {
        obj['count'] = ApiClient.convertToType(data['count'], 'Number');
      }
      if (data.hasOwnProperty('onValue')) {
        obj['onValue'] = ApiClient.convertToType(data['onValue'], 'Boolean');
      }
      if (data.hasOwnProperty('rangeBottom')) {
        obj['rangeBottom'] = ApiClient.convertToType(data['rangeBottom'], 'String');
      }
      if (data.hasOwnProperty('rangeTop')) {
        obj['rangeTop'] = ApiClient.convertToType(data['rangeTop'], 'String');
      }
      if (data.hasOwnProperty('rawRangeBottom')) {
        obj['rawRangeBottom'] = ApiClient.convertToType(data['rawRangeBottom'], 'String');
      }
      if (data.hasOwnProperty('rawRangeTop')) {
        obj['rawRangeTop'] = ApiClient.convertToType(data['rawRangeTop'], 'String');
      }
      if (data.hasOwnProperty('value')) {
        obj['value'] = ApiClient.convertToType(data['value'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {Number} count
   */
  exports.prototype['count'] = undefined;
  /**
   * @member {Boolean} onValue
   */
  exports.prototype['onValue'] = undefined;
  /**
   * @member {String} rangeBottom
   */
  exports.prototype['rangeBottom'] = undefined;
  /**
   * @member {String} rangeTop
   */
  exports.prototype['rangeTop'] = undefined;
  /**
   * @member {String} rawRangeBottom
   */
  exports.prototype['rawRangeBottom'] = undefined;
  /**
   * @member {String} rawRangeTop
   */
  exports.prototype['rawRangeTop'] = undefined;
  /**
   * @member {String} value
   */
  exports.prototype['value'] = undefined;



  return exports;
}));


