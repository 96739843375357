// Used by AqaSnapshotOverview


// import React, { Component } from 'react';
import React from 'react';

// Model
import AqaComponent                  from "../shared/aqacomponent/AqaComponent"
import ColourMap                     from "../../model/ColourMap"
import OverrideMap                     from "../../model/OverrideMap"
import {SnapshotView, Filter}        from "../../model/SnapshotView"
import Completer                     from "../../model/Completer"
import RowArrayCachingObject         from "../../model/RowArrayCachingObject"

// Library elements
// import Typography from "@material-ui/core/Typography";

// Our sub components
//import AqaSnapShotHeader             from "./AqaSnapShotHeader";
import AqaTypeMetrics                from "./AqaTypeMetrics"
// import AqaCensusMetrics              from "./AqaCensusMetrics"
import AqaHeatMapForDataViewer       from "./AqaHeatMapForDataViewer"
import DistributionGraph             from "../shared/distributiongraph/DistributionGraph"
import SimplifiedTraditionalTable    from "../shared/traditionaltable/SimplifiedTraditionalTable"
//import AqaSnapshotSummaryDistributionCensusCombo from "./AqaSnapshotSummaryDistributionCensusCombo"

// import WorkerBuilder                 from "../../workers/WorkerBuilder"


import AqaRules from "../sourcedetail/rules/AqaRules";




export const BACKEND_TYPE_UNIQUENESS    = "uniqueness";
export const BACKEND_TYPE_POPULATION    = "population";
export const BACKEND_TYPE_STRING        = "string";
export const BACKEND_TYPE_NUMBER        = "number";
export const BACKEND_TYPE_DATE          = "date";
export const BACKEND_TYPE_NATIVE_ERRORS = "nativeError";
export const BACKEND_TYPE_TYPE          = "type";
export const BACKEND_TYPE_OVERALL       = "overall";

//const COUNTING_ROWS_JOB_TYPE = "countingRows";

// To convert Aqa Front End Type number into a backend's AqaCell.Type
// "bool" as of 20210213 we know we won't have a flag type
const UI_TYPE_NUMBER_TO_BACKEND_TYPE_NAME =
    [
        BACKEND_TYPE_UNIQUENESS,    // 0
        BACKEND_TYPE_POPULATION,    // 1
        BACKEND_TYPE_STRING,        // 2
        BACKEND_TYPE_NUMBER,        // 3
        BACKEND_TYPE_DATE,          // 4
        BACKEND_TYPE_NATIVE_ERRORS, // 5
        BACKEND_TYPE_OVERALL        // 6

    ];



const NO_POSITION_SELECTED = -1; // Keep in sync with same constant in AqaTable.java
const DEFAULT_SORT_TYPE = 3; // See DistributionGraph


const convertUITypeNumberToBackendTypeName = (uiTypeNumber, dontConvertPopAndUniqueness) =>
{
    if (uiTypeNumber === -1) return null;
    if (dontConvertPopAndUniqueness && uiTypeNumber < 2) return "";
    return UI_TYPE_NUMBER_TO_BACKEND_TYPE_NAME[uiTypeNumber];
} // convertUITypeNumberToBackendTypeName


class InvokeAfter
{

    // convenience to stay DRY
    static forRadarRefresher(asd, n) { return new InvokeAfter(n, () => asd.dataViewer.adjustRadar()); }


    constructor(n, fnToRunAfter)
    {
        this.n = n;
        this.counter = 0;
        this.fnToRunAfter = fnToRunAfter;
    } //

    runIfReady()
    {
        this.counter++;
        if (this.counter === this.n) this.run();
    } // runIfReady

    run() { this.fnToRunAfter(); }

} //// InvokeAfter


export default class AqaSnapshotDetailTable extends AqaComponent
{

    static #IDEAL_NUMBER_OF_BARS = 2147483647; // Yep, we're taking our number of bars seriously.
    static #serial = 0;

    constructor(props)
    {
        super(props);
        this.serial = ++AqaSnapshotDetailTable.#serial;

        props.controller.registerDataViewer(this);

// onsole.log("I is constructing!!! amber: " + props.isAmber + " red: " + this.props.isRed);

		this.doFilterByColour = props.isAmber || props.isRed;



        this.state =
            {
                filename:   "",
                dimensions: "",
                sourcename: "Unknown source", // should be passed from previous screen
                typeMap:"",
                waitForFiltering: this.doFilterByColour
            };


        // The elements we are made of
        // ---------------------------

        this.header = null;
//        this.combo = [null, null];
//        this.tm = [null, null];
//       this.distriGraph = [null, null];
 //       this.heatmaps = [null, null];
        this.dataViewer = null;

        // Debouncing refreshes
        this.timer = null;

        // Resizer
        this.resizer = () => this.updateWidthAndHeight();




        // Anima Machinae
        // --------------

        this.currentView = null;
        // Set the DefaultView
        this.currentDefaultView = null;
        this.motherHeaders = null;
        this.typeSelected = Array(2);
        this.filterValues = Array(2);
        this.selectedColumnVector = Array(2); // [first vector selected, second vector selected]
        this.ragColumnSelectors = Array(2); // A/R selectors in (selected) columns
        this.globalAmberRed = Array(2); // The A/R selectors in the TypeMetrics
        this.distributionFilterTypes = Array(2);

        // Initialisations
        for(let i = 0; i < 2; i++)
        {
            this.resetArSelection(i);
            this.selectedColumnVector[i] = null;
            this.typeSelected[i] = -1;
            this.resetFilter(i);
            this.globalAmberRed[i] = [false, false];
            this.distributionFilterTypes[i] = DEFAULT_SORT_TYPE; // Not central as the info is centrally kept in the Distributions graph of which this is a servant.
        }

        // Caches - they MUST be held here.
        // ------

        /** colours must not be cached agressively.
         * Why? Because they may change if users change VQDs.
         * I am assuming 1 user is going to work on one snapshot at a time.
         * So my caches will be reset everytime they re-enter the DataView (ie. here)
         */
        this.snapshotViewCache = []; // Yes, this one is a square array!.
        this.distributionCache = {};
        this.colourMapCache = {};
        this.overrideMapCache = {};
        this.heatMapCache = {};
        this.scoresColoursCache = {};
        this.vqdCache = {};


        this.rowCache = {}; // A key based array of RowArrayCachingObject objects


        // This is to avoid Out of Sync row count refreshes
        this.colourMapWorkerTicket = 0;


        // The VQD editor
        this.vqdEditor = null;
        
        
        


        
        

        // Poubelle
        // --------


    } //



    getDescription()
    {
        return "To inspect a snapshot under various approaches (Type andor distribution) and possibly drill into subsets of the data, hopefully to find anomalies and correct them, and / or extract secondary knowledge from the data set (correlations, trends, etc.).<br />";

    } // getDescription

    handleHome()
    {
        this.props.dataFromRoot.handleSnapshotReset();
        this.props.dataFromRoot.navigation(2);
    } // handleHome


    /* So that we can refresh our components
     * They call us once created.
     */


    registerDataViewer(dataViewer)                           { this.dataViewer = dataViewer; }
    registerHeader(h)                                        { this.header = h; }
    registerVqdEditor(vqdEditor)                             { this.vqdEditor = vqdEditor; }



    statsOnColumn(cId)
    {
        AqaComponent.snapshotBackend.getColVectorSimplifiedUsingGET
        (
            this.currentView.id,
            cId,
            (error, data, response) =>
            {
                if (error) alert("Error getting the vector stats");
                else alert(AqaComponent.prettifyJson(data));
            }
        );
    } // statsOnColumn


    vqdForColumn(cId, callback)
    {

        AqaComponent.snapshotBackend.getQualityFromSnapshotUsingGET
        (
            this.currentView.id,
            "column",
            cId,
            (error, data, response) =>
            {
                if (error) alert("Error getting the VQD");
                else callback(data);
            }
        );
    } // vqdForColumn



    static alphabase(x)
    {
        var range;
        var e;

        for(e = 1; true; x -= range, e++) if (x < (range = Math.pow(26, e))) break;

        var r, ret = "";
        for(var i = 0; i < e; i++)
        {
            r = x % 26;
            ret = String.fromCharCode(65 + r) + ret;
            x -= r;
            x /= 26;
        }
        return ret;

    } // alphabase


// Have one for discrete and one for not?

    /**
     * @param which index into UI_TYPE_NUMBER_TO_BACKEND_TYPE_NAME
     * @param colour 'red' or 'amber'
     * @param dicrete true(cell counts) or false(rule violations)
     */
    static getScorePropertyNameFor(which, colour, discrete)
    {
// numberOfDiscretePopulationReds
        let propName = UI_TYPE_NUMBER_TO_BACKEND_TYPE_NAME[which];
        propName = propName.substring(0, 1).toUpperCase() + propName.substring(1);
        return `numberOf${discrete ? "Discrete" : ''}${propName}${colour}s`;
    } // getScorePropertyNameFor


    static getOrthogonalScorePropertyNameFor(which, colour, scoreData)
    {

        return scoreData[`numberOfOrthogonalVectorsWithErrors`][0][which][colour];
    } // getScorePropertyNameFor

    /** specify -1 if you want the score for the whole table or a column id otherwise.
     * This is used by AqaTypeMetrics AND setView when it informs the data viewer!
     */
    getScore(columnId, callback) { return this.getScoreWithViewId(this.currentView.id, columnId, callback); }

    getScoreWithViewId(id, columnId, callback)
    {

        const scoreId = `${id}_${columnId}`;

        let ret = this.scoresColoursCache[scoreId];
        if (ret)
        {
            // Getting from cache
            if (callback) callback(ret);
        }
        else
        {
            // We don't have it so we fetch.
            if (columnId === -1)
            {

                // We load the snapshot's score
                AqaComponent.snapshotBackend.getSnapshotScoreUsingGET
                (
                    id,
                    (error, data, response) =>
                    {
                        if (error) AqaComponent.staticReportError("Error getting the number of red and amber violations", "getScoreWithViewId() (snapshot) failed for " + scoreId + "\n" + error,this);
                        else
                        {
                            this.scoresColoursCache[scoreId] = data;
                            callback(data);
                        }
                    }
                );
            }
            else
            {
                // We load an AqaVectorColours
                AqaComponent.snapshotBackend.getColumnVectorColoursUsingGET
                (
                    id,
                    columnId,
                    {}, // Se un giorno abbiamo bisogno dei colori, lo diremo qui.
                    (error, data, response) =>
                    {
                        if (error) AqaComponent.staticReportError("Error getting the number of red and amber violations for the column", "getScoreWithViewId() (vector) failed for " + scoreId + "\n" + error);
                        else
                        {
                            this.scoresColoursCache[scoreId] = data;
                            callback(data);
                        }
                    }
                );
            }
        }

    } // getScoreWithViewId


//	resetColourMapCache() { this.colourMapCache = {}; }


    /** See notes on caches in constructor */
    getColourMap(view, callback) // In theory, we should never be asking for colours other than the currrent view's. So maybe my parameterisation is a tad overkill?
    {
        let colourMap;
        if ((colourMap = this.colourMapCache[view.id]))
        {
            if (callback) callback(colourMap);
        }
        else
        {
            colourMap = new ColourMap(view);
            colourMap.load
            (
                () =>	
                {
                    this.colourMapCache[view.id] = colourMap;
                    callback(colourMap);
                }
            );
        }

    } // getColourMap

    getOverrideMap(view, callback) // In theory, we should never be asking for overrides other than the currrent view's. So maybe my parameterisation is a tad overkill?
    {
        let overrideMap;
        if ((overrideMap = this.overrideMapCache[view.id]))
        {
            if (callback) callback(overrideMap);
        }
        else
        {
            overrideMap = new OverrideMap(view);
            overrideMap.load
            (
                () =>
                {
                    this.overrideMapCache[view.id] = overrideMap;
                    callback(overrideMap);
                }
            );
        }

    } // getOverrideMap


    /** The Table calls this to get its data
     */
    pumpRows(topology, sequenceNumber, callback, postOp)
    {

        let startRow = topology.startVisibleVector;
        let endRow = topology.endVisibleVector;

        // Adjust for horizontal header
        if (this.currentView.getHasHorizontalHeader())
        {
            // AQADEV-72: Fix the incorrect row count: by not adjusting the start row index
            //startRow++;
            endRow++;
        }






// onsole.log("Pumping B");

        if (!this.dataViewer.checkSequence(sequenceNumber))
        {
// onsole.log("Pumping DECLOGGED");
            return;
        }


// onsole.log("Pumping C");

        /*
        onsole.log("TOPOLOGY:");
        onsole.log(AqaComponent.prettifyJson(topology));
        */

        topology.adjustedStartRow = startRow;

        if (endRow > this.currentView.table.data.numberOfRows) endRow = this.currentView.table.data.numberOfRows;
        let rowsNeeded = (endRow - startRow);

// onsole.log("Pumping D");

        const postLoadOp = data =>{
            postOLoadOp(data);
            //postCLoadOp(data);
        }

        const postCLoadOp = (data,overrideMap) =>
        {
            // need to get the override map first.
            this.getColourMap
            (
                this.currentDefaultView,
                (colourMap) =>
                {
// onsole.log("Pumping E");

 if (this.state.waitForFiltering) data = [];

                   callback(topology, sequenceNumber, data, colourMap,overrideMap, postOp); // We send all the data gathered to the Traditional Table
                }
            );
        };

        const postOLoadOp = data =>
        {
            // need to get the colour map first.
            this.getOverrideMap
            (
                this.currentDefaultView,
                (overrideMap) =>
                {
// onsole.log("Pumping E");

                    if (this.state.waitForFiltering) data = [];
                    postCLoadOp(data,overrideMap);
                    //callback(topology, sequenceNumber, data, overrideMap, postOp); // We send all the data gathered to the Traditional Table
                }
            );
        };

// onsole.log("Pumping F");


        let currentRowsWindow = this.rowCache[this.currentView.id];

        if (!currentRowsWindow) this.rowCache[this.currentView.id] = currentRowsWindow = new RowArrayCachingObject(this.currentView.id);
// else onsole.log("Pumping F.1");

        if(isNaN(startRow)) startRow=0;
        if(isNaN(rowsNeeded) || rowsNeeded<19) rowsNeeded=18;

        currentRowsWindow.load(startRow, rowsNeeded, postLoadOp);

// onsole.log("Pumping G");

    } // pumpRows


    refreshInformation()
    {
        const id = this.currentView.id;
        //const stats = this.currentView.table.getStats();
//		const rr = new RadarRefresher(this, 2);

        const rr = InvokeAfter.forRadarRefresher(this, 2);


        for(let i = 0; i < 2; i++) this.refreshInformationFor2(id, i, rr);



    } // refreshInformation


    getSelectedColIdFor(which)
    {
        const v = this.selectedColumnVector[which];
        return v === null ? -1 : v.data.position;
    } // getSelectedColIdFor


//	getTypeMetricSelectedType = position => this.tm[position].getType();

//   getTypeMetricSelectedType = position => this.tm[position].getSelectedTypeIndex();


    refreshInformationFor2(id, u, radarRefresher) // Check this is not called. If not, delete
    {
    } // refreshInformationFor2


    selectGlobalAmberRed(which, booleans, postOp)
    {

// onsole.log("Selecting Global Amber Red:");
// onsole.log(AqaComponent.prettifyJson(booleans));

        this.globalAmberRed[which] = booleans;
        this.setView
        (
            () =>
            {
//                this.refreshInformation();
//						this.waitForFiltering = false;
                if (postOp) postOp();
            }
        );
    } // selectGlobalAmberRed

    
    selectGlobalAmberRedAndType(which, booleans, postOp)
    {

// onsole.log("Selecting Global Amber Red:");
// onsole.log(AqaComponent.prettifyJson(booleans));

        this.globalAmberRed[which] = booleans;
        this.setView
        (
            () =>
            {
//                this.refreshInformation();
//						this.waitForFiltering = false;
                if (postOp) postOp();
            }
        );
    } // selectGlobalAmberRed

    


    // Used by Type Metrics
    getTypeSelections(which)
    {
        const gar = this.globalAmberRed[which];
        return ([this.typeSelected[which], gar[0], gar[1]]);
    } // getTypeSelection



    resetArSelection(which)
    {
        this.ragColumnSelectors[which] =
            [
                -1,		// Column Id
                false,   // amber
                false    // red
            ];
    } // resetArSelection



    /** We keep the rag selection here
     * The Traditional Table will query it (with instant retrieval) when it needs to display a table.
     */
    columnRagSelect(which, position, onlyAmbers, onlyReds)
    {
        this.ragColumnSelectors[which] = [position, onlyAmbers, onlyReds];
        this.setView(() => {	 this.refreshInformation(); });

    } // columnRagSelect


    // Invoked by TraditionalTable
    getColumnRagSelections() { return this.ragColumnSelectors; }


    heatmapClick(which, x, xScale, y, yScale) // Yes, I know which is not used.
    {

// onsole.log("Heat map click");

        const bounders =  [this.dataViewer.nRows, this.dataViewer.nColumns];
        const viewSpans = [this.dataViewer.numberOfRowsToDraw, this.dataViewer.numberOfColumnsToDraw];

        const positions = [y, x];
        const scales =    [yScale - 1, xScale - 1];
        const actuators = ["scrollVertically", "scrollHorizontally"];

        let max, position;

        for(let i = 0; i < 2; i++)
        {
            position = Math.floor(positions[i] * bounders[i] / scales[i]);
            max = bounders[i] - viewSpans[i];
            if (position > max) position = max;
            if (position < 0) position = 0;
            this.dataViewer[actuators[i]](position, false, true); // Yes we're missing an argument, and no, it wouldn't work otherwise.
        }
        this.setState({waitForFiltering:false},()=>this.props.controller.handleDataLoadComplete());
    } // heatmapClick


    /** typeMetrics the Component calling us
     *  which 0:top, 1: bottom
     *  type number 0: uniqueness, 1: population, 2: string, 3: number, 4: date, 5: overall
     */
    selectType(which, typeNumber, postOp)
    {
        this.selectTypeWithSortType(which, typeNumber, DEFAULT_SORT_TYPE, postOp);
    } // selectType




    // 20220608 - There are too many steps.
    // TODO ici
    selectTypeWithSortType(which, typeNumber, sortType, postOp)
    {

// onsole.log("ASD: selectTypeWithSortType() [" + which + " | " + typeNumber + " | " + sortType + " | " + postOp + "]");

// onsole.log("ASD: stwst: original Type number" + this.typeSelected[which]);
// onsole.log("ASD: stwst: new Type number" + typeNumber);

        this.typeSelected[which] = typeNumber;

        const refresher = () =>
        {
            // Not providing a radar refresher because it's going to happen further down eventually

// onsole.log("ASD: stwst: refresher invoked");

// NOTE: IMPORTANT This may only work because after the resets, currentViewId is the mother snap!!!!!!!!!

//			this.refreshInformationFor(this.currentView.id, this.currentView.table.getStats(), which);

            this.refreshInformationFor2(this.currentView.id, which);
            this.selectTypeWithSortType(which, typeNumber, sortType, postOp);
        };

        const hasColSelection = this.selectedColumnVector[which] !== null;
        if (hasColSelection && this.filterValues[which] !== null)
        {
            // This an intermediary step, after which we'll carry out our selection.

            // If a value filter is in place, there is no way we can allow it to stay on a type change.
            this.resetFilter(which);
            this.setView(refresher);
            return;
        }

        if (this.globalAmberRed[which][0] || this.globalAmberRed[which][1])
        {
// onsole.log("STWST: Resetting colours");
            // IF A/R global filters are in place, we need to remove them
            this.globalAmberRed[which] = [false, false];
            this.setView(refresher);
            return;
        }

        if (typeNumber >= 2 && typeNumber !== 5) // population and uniqueness don't affect stats
        {

            if (hasColSelection) // && this.censi[which] !== null
            {
                // We have a column selection in place
                // ONLY for data types are we interested about value distribution!!


                const position = this.selectedColumnVector[which].data.position;

// Might only work thanks to our resets
                const distributionColoursId = this.makeDistributionColoursId(position); // `${this.currentView.id}_column_${position}`;

                const backendTypeName = convertUITypeNumberToBackendTypeName(typeNumber, true);

                // This one will sort by value.
                //const distribution = this.selectedColumnVector[which].getDistribution2(backendTypeName);

                // We ned to carry out some tasks before we can inform the distribution graph.

                const distriTasks = Array(2);

                let postOpJustForMe = null;

                let distributionColours = this.distributionCache[distributionColoursId];



                // Task 0: get distribution colours (or not)
                if (!distributionColours)
                {
                    postOpJustForMe = postOp;
                    postOp = null;

                    distriTasks[0] = completionInformer =>
                    {

// onsole.log("ASD: selectTypeWithSortType: Getting distribution colours for: " + distributionColoursId);

                        AqaComponent.backend.getDistributionColoursUsingGET
                        (
                            distributionColoursId,
                            (error, data, response) =>
                            {

                                if (error) console.error("Distribution Colours retrieval failed");
                                else distributionColours = this.distributionCache[distributionColoursId] = data; // Got the colour data

                                completionInformer();
//								if (postOpJustForMe) postOpJustForMe();
                            }
                        );
                    };
                }
                else distriTasks[0] = completer => completer();

                let vqd = this.vqdCache[position];
                if (vqd) distriTasks[1] = completer => completer();
                else
                {

                    distriTasks[1] = completer =>
                    {
// onsole.log("Calling vqdForColumn from SelectTypeWithSortType");

                        this.vqdForColumn
                        (
                            position,
                            (data) =>
                            {
                                this.vqdCache[position] = vqd = data; // Got the VQD
                                completer();
                            }
                        );
                    };
                }

// onsole.log("datatype: " + backendTypeName + " vs " +  BACKEND_TYPE_DATE);
                //let dateFormat = null;
                if (BACKEND_TYPE_DATE === backendTypeName)
                {

// onsole.log("Trying to find format for date");
// dateFormat = this.selectedColumnVector[which].getMostFrequentValueOfDistribution("dateFormat");

                    //dateFormat = this.selectedColumnVector[which].getDateFormat();

// onsole.log("Format: " + dateFormat);
// onsole.log(AqaComponent.prettifyJson(dfs));


                }

                const completer = new Completer
                (
                    distriTasks,
                    () =>
                    {
                        // When this is run, we have both the VQD and the Colours
// onsole.log("Calling Distribution graph from selectTypeWithSortType: " + distributionColoursId);
                        this.normalCursor();
                        if (postOpJustForMe) postOpJustForMe();
                    }
                );

                this.waitCursor();
                completer.run();
            }

        }

// onsole.log("STWST: going the long way!!");

        // The heatmap
        //const rr = InvokeAfter.forRadarRefresher(this, 2);

        this.dataViewer.informOfAspect(which, typeNumber);

// onsole.log("STWST: final");

        if (postOp) postOp(); // This should be to let any AR selection happen (We might have been called because our type wasn't selected)

    } // selectTypeWithSortType



    waitCursor() { document.body.style.cursor = "wait"; }
    normalCursor() { document.body.style.cursor = ""; }

    deSelectColumn(which)
    {


// onsole.log("ASD: Deselecting column: " + which);


        // We reset pretty much everything.
        this.selectedColumnVector[which] = null;
        this.typeSelected[which] = -1; // Reset to overall. J'entends d'ici les gens me demander que le type soit remis a ce qui etait selectionne avant. Oui mais non.
        this.resetArSelection(which);
        this.resetFilter(which);



        this.selectType
        (
            which,
            -1,
            () =>
                this.selectGlobalAmberRed
                (
                    which,
                    [false, false],
                    () =>
                    {
                        if (which === 1 && this.combo[1].setVisibility(false)) this.updateWidthAndHeightImmediately(); // We only hide the bottom combo.
                    }
                )
        );


    } // deSelectColumn




	/** This soft selects a column before filters are made.
	  * designed to be backwards compatible with makeFilters.
	  *
	  */
	softSelectColumn(colId, feType) // colourSetter, start, limit
    {
        //	When selecting a column:
        //	- Any other column (on the same combo is deselected)
        //	  - If there was any filter in place on the previously selected column, the filter is discarded (But a filter on the other combo, if any, is kept)
        //	- The Type Metrics data (left in combo) is reloaded with the selected columns metrics
        //	- The Distribution graph is automatically displayed for the most numerous type (Ie, if there are more strings than anything else their distribution is shown)
        //	- The Type Census metrics are shown (right in combo)
        //	- If the combo was toggled off, it will be toggled on (page redraw ensues).

			if (colId === NO_POSITION_SELECTED) return;

		const which = 0;

        // Important - selecting a new column removes any filtering on any previously selected column.
        this.resetFilter(which);

        // For our selection doesn't magically reappear like magic without the filtering that goes with it.
        this.resetArSelection(which);

        
        this.selectedColumnVector[which] = {data:{position: colId}};
        this.typeSelected[which] = feType;
        

	} // softSelectColumn


    /** When / if we do row analysis, this function can be generalised - it wouldn't take much.
     * which which selection, ie, 0: first or 1: second
     * colId the id of the column to analyse
     */
    selectColumn(which, colId) // colourSetter, start, limit
    {
        //	When selecting a column:
        //	- Any other column (on the same combo is deselected)
        //	  - If there was any filter in place on the previously selected column, the filter is discarded (But a filter on the other combo, if any, is kept)
        //	- The Type Metrics data (left in combo) is reloaded with the selected columns metrics
        //	- The Distribution graph is automatically displayed for the most numerous type (Ie, if there are more strings than anything else their distribution is shown)
        //	- The Type Census metrics are shown (right in combo)
        //	- If the combo was toggled off, it will be toggled on (page redraw ensues).

// onsole.log("ASD: select Column 0: " + colId);

			if (colId === NO_POSITION_SELECTED) return;

// onsole.log("ASD: select Column 1");

        // Important - selecting a new column removes any filtering on any previously selected column.
        this.resetFilter(which);

// onsole.log("ASD: select Column 2");

        // For our selection doesn't magically reappear like magic without the filtering that goes with it.
        this.resetArSelection(which);

// onsole.log("ASD: select Column 3");



        // Remove any selected type and make sure any new selected type is taken into account...

			this.currentView.table.getColumnVector // We need to obtain the correct vector.
         (
            colId,
            vector => { this.selectedColumnVector[which] = vector;
            
// onsole.log("ASD: Found the vector!");
            
             }
         );

    } // selectColumn



    handlePermission(adminEmail)
    {
        this.removeAllNotifications();
        var context;
        //var context = this.props.dataFromParent.props.dataFromRoot.props.dataFromRoot.props.dataFromRoot;
        this.showNotification(4, null, context, null,adminEmail);
    }





    resetFilter(which) { this.filterValues[which] = null; }


    // Also invoked by DistributionGraph
    removeFilter(which, after)
    {
        this.resetFilter(which);
        this.setView
        (
            () =>
            {
                this.refreshInformation();
                if (after) after();
            }
        );
    } // removeFilter



    filterOnColumn(which, distributionFilterType, valueFrom, valueTo)
    {
        this.filterValues[which] = [valueFrom, valueTo];
        this.distributionFilterTypes[which] = distributionFilterType;

// onsole.log("FILTER: " + this.typeSelected[which]);

        this.setView(() => this.refreshInformation());
    } // filterOnColumn



    /** Call this to resize the components.
     * Very tricky to get right.
     */
    updateWidthAndHeight()
    {
        // Debounce
        if (this.timer) clearTimeout(this.timer)
        setTimeout
        (
            () =>
            {
                this.timer = null;
                this.updateWidthAndHeightImmediately();
            },
            750
        );
    } // updateWidthAndHeight


    updateWidthAndHeightImmediately(postOp)
    {


// onsole.log("updateWidthAndHeightImmediately");

        const params = this.computeDisplayParams();
        for(let i = 0; i < 2; i++)
        
        if (this.dataViewer) this.dataViewer.resize(params, postOp);
        
        
    } // updateWidthAndHeight


    // Computing sizes for our components
    computeDisplayParams()
    {

        const aw = this.props.showHistory===true?window.innerWidth*0.73:window.innerWidth*0.992;
        let ah = window.innerHeight;
        if(window.innerHeight>580 && window.innerHeight<730){
            ah = (this.props.controller.state.selectedColumnIndex==="-1"?ah*0.762:ah*0.74);
        }
        else{
            ah = (this.props.controller.state.selectedColumnIndex==="-1"?ah*0.842:ah*0.82)
        }
        const usedByTypeMetricsAndTypeCensus = AqaTypeMetrics.width + AqaHeatMapForDataViewer.getWidth() + 40; // some padding
        const leftToPlayWidth = aw - usedByTypeMetricsAndTypeCensus;

        let dgWidth, dgFloat, tmFloat, cmFloat;

        if (leftToPlayWidth > 150) // arbitrarilly chosen
        {
            dgWidth = leftToPlayWidth - DistributionGraph.LEFT_MARGIN - DistributionGraph.RIGHT_MARGIN - 4;
            dgFloat = "left";
            tmFloat = "left";
            cmFloat = "right";
        }
        else
        {
            dgWidth = AqaTypeMetrics.width; // At least these two will be aligned
            dgFloat = "none";
            tmFloat = "none";
            cmFloat = "none";
        }

        const ret =
            {
                aw,
                ah,
                dgWidth,
                dgFloat,
                tmFloat,
                cmFloat
            };

        return ret;
    } // computeDisplayParams



    /** Generate the filters reflecting the user's choices
     * On decompose notre filtre en ses sous-filtres constituants, de maniere a ce qu'ils puissent etre appliques en serie.
     */
    makeFilters()
    {

        const filters = [];

        // Note: as of 2021.07 ALL our filtering and criterias are on columns.
        const orientation = "column";

        let vector;
        let columnColourFilters;
        let type, position;

/*
        for(let i = 0; i < 2; i++) // Top and bottom racks
        {
*/

				const i = 0;

            type = convertUITypeNumberToBackendTypeName(this.typeSelected[i], false);
            position = NO_POSITION_SELECTED;

            //type ="numberFormat";
            
// onsole.log("ASD: makeFilters 1");
            
            if ((vector = this.selectedColumnVector[i]) !== null)
            {
                position = vector.data.position;

// onsole.log("ASD: makeFilters 2: We have a selected column vector: " + position + " (" + i + ")");
                
                // if (value is selected) create value filter
                if (type !== null && this.filterValues[i] !== null) filters.push
                (
                    new Filter
                    (
                        {
                            orientation,
                            type,
                            position,
                            valueFrom:this.filterValues[i][0],
                            valueTo:this.filterValues[i][1],
                            subType:this.distributionFilterTypes[i]
                        }
                    )
                );
// else onsole.log("NOT MAKING A COLUMN FILTER!! Type: " + type + " i: " + i);


                // COLOUR UNION
                columnColourFilters = {};
                if (this.ragColumnSelectors[i][1]) columnColourFilters.onlyAmbers = true;
                if (this.ragColumnSelectors[i][2]) columnColourFilters.onlyReds = true;

                if (Object.keys(columnColourFilters).length > 0)
                {
                    columnColourFilters.orientation = orientation;
                    columnColourFilters.type = BACKEND_TYPE_OVERALL;
                    columnColourFilters.position = position;
                    filters.push(new Filter(columnColourFilters));
                }
// else onsole.log("NOT MAKING A COLUMN **Color** FILTER!! Type: " + type + " i: " + i);


            }

            // COLOUR UNION - we keep the colours together. Note that the 'only' terminology is kind of misleading here.
            const colourFilterParams = {};
            if (this.globalAmberRed[i][0]) colourFilterParams.onlyAmbers = true;
            if (this.globalAmberRed[i][1]) colourFilterParams.onlyReds = true;
            if(this.props.isRed && this.props.isAmber && this.props.isGreen && this.globalAmberRed[i][0] && this.globalAmberRed[i][1]) colourFilterParams.onlyGreens = true;
            if(this.props.isRed && !this.props.isAmber && this.props.isGreen && !this.globalAmberRed[i][0] && this.globalAmberRed[i][1]) colourFilterParams.onlyGreens = true;
            /*
            if (i === 0)
            {
                onsole .log("========> GLOBAL COLOURS!!");
                onsole .log(AqaComponent.prettifyJson(this.globalAmberRed[0]));
            }*/
            

// onsole.log("LOOKING AT CFPs");

            if (Object.keys(colourFilterParams).length > 0)
            {
// onsole.log("I HAVE Colour Filter Params RT: " + this.props.selectedReviewType + " i: " + i);
                let finerGrain = -1;
                type = BACKEND_TYPE_OVERALL;
                let customType = this.props.controller.state.selectedType;
                let selectedColumn = this.props.controller.state.selectedColumn;
                if(customType ==="any") type="any";
                if (selectedColumn !== null)
                {
                    if (customType === "format" || customType === "value" || customType === "valueAllowed")
                    {
                        type = selectedColumn.type.toLowerCase();
                        if (customType === "valueAllowed") finerGrain = 2;
                        else if (customType === "value") finerGrain = 1;
                        else if (customType === "format") finerGrain = 0;
                    }
                    else if (customType === "populated") type = BACKEND_TYPE_POPULATION;
                    else if (customType === "uniqueness") type = BACKEND_TYPE_UNIQUENESS;
                    else if (customType === "native") type = BACKEND_TYPE_NATIVE_ERRORS;
                    else if (customType === "type") type = BACKEND_TYPE_TYPE;
                }
                else
                {
                    let mainState = (this.props.controller.props.dataFromMainFrame.state);
                    type = mainState.selectedType;
                    finerGrain = mainState.finerGrain;
                }

                colourFilterParams.orientation = orientation;
                colourFilterParams.type = type !== null ? type : BACKEND_TYPE_OVERALL;
                if (vector !== null) colourFilterParams.position = position;
                //if (this.props.selectedReviewType >= 0) colourFilterParams.reviewType = this.props.selectedReviewType;
                
                colourFilterParams.reviewType = this.props.selectedReviewType; // La props devrait etre assignee a une variable locale, et de-definie si utilisee aussi.

                colourFilterParams.finerGrain = finerGrain;
                filters.push(new Filter(colourFilterParams));
            }


//        } Boucle sur les 2 selecteurs; haut et bas


			// si this.props.selectedReviewType OU LA VARIABLE LOCALE (voir plus haut) qui a sa valeur est toujours DEFINIE, alors on cree un autre filtre.


// onsole.log("REVIEW TYPE!! " + this.props.selectedReviewType);


// Very useful debug! Keep.
// onsole.log("ASD: FILTERS:");
// onsole.log(AqaComponent.prettifyJson(filters));


        return filters;

    } // makeFilters





// ERROR 1: The refresh of the graph takes its data from the displayed table.
//            It's wrong: it needs to come from the mother snapshot, because it doesn't change!!!

    handleRefresh(callback)
    {
        // We need to clear all of them.
        SnapshotView.clearCacheOfFilteredViews(this.snapshotViewCache);

        this.distributionCache = {};
        this.colourMapCache = {};
        this.overrideMapCache = {};
        this.heatMapCache = {};
        this.scoresColoursCache = {};
        this.vqdCache = {};

        const filters = this.makeFilters();

// onsole.log("DOING REFRESH NOW!!!!!!!!!!!!!!!!!");

        const motherView = this.snapshotViewCache[0];
        const motherViewId = motherView.id;


        this.doSetView
        (
            filters,
            () =>
            {

                this.getScoreWithViewId
                (
                    motherViewId,
                    -1,
                    () =>

                    {
                        if (callback) callback();

//						const stats = motherView.table.getStats();


                        const needsInformationRefresh = [true, true];
                        for (let i = 0; i < 2; i++)
                        {
                            const vector = this.selectedColumnVector[i];
                            //const hasColSelection = this.selectedColumnVector[i] !== null; // The one as per before

                            if (vector !== null)
                            {
                                needsInformationRefresh[i] = false;
                                const position = vector.data.position; // This is still valid

                                motherView.table.getColumnVector // We need to obtain the correct vector - Why reload? Because if there are filters on colours... this is a brand new table...
                                 (
                                     position,
                                     vector =>
                                     {

                                         this.selectedColumnVector[i] = vector;

                                         let typeNumber = this.typeSelected[i];
                                         if(typeNumber === -1) typeNumber = 3;
                                         /*
                                         const backendTypeName = convertUITypeNumberToBackendTypeName(typeNumber, true);

                                         const distribution = vector.getDistribution2(backendTypeName);

                                         const distributionColoursId = this.makeDistributionColoursIdWithViewId(motherViewId, position); // `${this.currentView.id}_column_${position}`;

                                         const dateFormat = null;
                                         if (BACKEND_TYPE_DATE === backendTypeName) dateFormat = vector.getDateFormat();


                                         AqaComponent.backend.getDistributionColoursUsingGET
                                         (
                                             distributionColoursId,
                                             (error, distributionColours, response) =>
                                             {

                                                 if (error) console.error("Distribution Colours retrieval failed: " + error);
                                                 else this.distributionCache[distributionColoursId] = distributionColours;



// Penso che l'abbiamo gia quello VQD quando veniamo dallo VQD Editor...

                                                 this.vqdForColumn
                                                 (
                                                     position,
                                                     (vqd) =>
                                                     {
                                                         this.vqdCache[position] = vqd;
//                                                         this.distriGraph[i].informDistributionGraph(distribution, distributionColours, vqd, this.distributionFilterTypes[i], backendTypeName, dateFormat);
                                                         this.refreshInformationFor2
                                                         (
                                                             motherViewId,
                                                             i
                                                         );

                                                     }
                                                 );

                                             }
                                         );*/
                                     }
                                 );


                            }

                        }

	                   }
                );

            } // ...

        );

    } // handleRefresh



    /** The snapshot we want to see is the result of the user's actions
     * The snapshot we want to see is based on the filters selected
     * Snapshot + Filter(0/s) = SnapshotView
     * return null if we haven't changed the View. // Don't think so Teebo 20220514
     */
    setView(callback)
    {

// onsole.log("ASD: SETTING VIEW");

        const filters = this.makeFilters();

// onsole.log("filters: ");
// onsole.log(filters);


        this.doSetView(filters, callback)

    } // setView

    /*
    Pass the score in the callback
    Invoke the postop synchronously after call to dataViewier init
    */

    doSetView(filters, callback)
    {

// onsole.log("ASD: SV");

// onsole.log(AqaComponent.prettifyJson(filters));
        // onsole.log(this.props);

        this.waitCursor();

        SnapshotView.find
        (
            this.props.snapshotId,
            this.snapshotViewCache,
            filters,
            newView =>
            {
// onsole.log("ASD: SV: doing new view");

                this.normalCursor();

// onsole.log("ASD SV: nvs" + newView.serial + " cvs: " + (this.currentView === null ? "null view" : this.currentView.serial));

                if (this.currentView === null || newView.serial !== this.currentView.serial)
                {

// onsole.log("ASD SV: New view IS NEW!");

                    this.currentView = newView;
                    if(this.currentDefaultView === null) this.currentDefaultView = newView; // Why do we need this??
                    let numberOfColumns = this.currentView.table.data.numberOfColumns;
                    let numberOfRows = this.currentView.getNumberOfRows(); // Adjusted for presence of headers or not.
                    
                    
// onsole.log("NUMBER OF ROWS: " + numberOfRows);
                    
                    //const stati = this.currentView.stati;

                    const updater = () =>
                    {
                        this.getScore
                        (
                            -1,
                            score =>
                            {


                                this.dataViewer.init
                                (
                                    score,
                                    this.motherHeaders,
                                    numberOfRows,
                                    numberOfColumns,
                                    newView.stati
                                    // callbak
                                );
                                if (callback) callback(score);

                            }
                        );


                    };


                    //this.header.setState({dimensions: `${AqaComponent.formatBigNumber(numberOfColumns)} x ${AqaComponent.formatBigNumber(this.currentView.table.data.numberOfRows)}`});

                    if (this.motherHeaders === null && filters.length === 0)
                    {
                        // We're not filtered here MEANING we are the mother view!!!!
                        if (this.currentView.hasHorizontalHeader())
                        {
                            // The table's first row is a header
                            // Get header row - we keep the same header for all the filtered views

                            // Extra step: Get the headers.
                            this.currentView.loadHeaders(headers => { this.motherHeaders = headers; updater(); });
                            return; // important.
                        }
                        else
                        {
                            // Not derived, no headers, we generate our own.
                            this.motherHeaders = Array(numberOfColumns);
                            for(let i = 0; i < numberOfColumns; i++) this.motherHeaders[i] = AqaSnapshotDetailTable.alphabase(i);

                        }
                    }
                    updater();
                }
                else
                {

// onsole.log("ASD SV: New view AIN'T NEW!");

                    if (callback) callback();
                }
            }
        );



    } // doSetView

    getTypeMap = () =>
    {
        AqaComponent.snapshotBackend.getTypesMapUsingGET
        (
            this.currentView.id,
            (error, data, response) =>
            {
                var errorJSON = JSON.parse(response.text);
                if (errorJSON.error === "invalid_token") {
                    this.props.dataFromRoot.props.dataFromRoot.props.parent.logout();
                }
            }
        );


    }; // getTypeMap

    handleUserDetails = () =>
    {
        AqaComponent.userBackend.meUsingGET
        (
            (error, data, response) =>
            {
                var errorJSON = JSON.parse(response.text);
                if (errorJSON.error === "invalid_token") {
                    //this.props.dataFromParent.props.controller.props.dataFromMain.props.parent.logout();
                    this.props.controller.props.dataFromMain.props.parent.logout();
                }
                else {
                    if (error) this.reportError
                    (
                        "A problem getting the user details from the server was encountered.",
                        "AqaAccountUsers.handleUserDetails, backend call: " + error
                    );
                    else {

                    }
                }
            }

        );
    }; // handleUserDetails

    componentDidMount()
    {

        this.setState({filename:this.props.dataFromSnapshot.label});
        window.addEventListener('resize', this.resizer);
        //this.handleUserDetails();

// onsole.log("DID MOUNT");





        // The code below is precisley orchestrated to make REACT work **properly**.
        // Don't modify. And if you do, don't go crying to mummy because you will have been warned.
        this.setView
        (

            (scoreData) =>
            {
                this.updateWidthAndHeightImmediately
                (
                    () =>
                    {

                        // Several operations must happen in sequence especially if some heatmap coordinates have been given to us.
                        // ... that's why we have a couple of invokeAfters.

                        let aspect = this.props.currentAspect; // Always overall?


                        let column = this.props.currentColumn;
                        if (aspect === "populated") aspect = UI_TYPE_NUMBER_TO_BACKEND_TYPE_NAME[1]; // populated... population 2022.02.09 can't remember what it should be... too late now.


                        let tx; // , txs;
                        for(tx = 0; tx < 7; tx++) if (UI_TYPE_NUMBER_TO_BACKEND_TYPE_NAME[tx] === aspect) break;
                        if (tx === 7) tx = -1;


								this.softSelectColumn(column, tx);


// onsole.log("column: " + column);
// onsole.log("TX: " + tx);
// onsole.log("aspect 2: " + aspect);

                        // this.dataViewer.informOfAspect(which, typeNumber);


// onsole.log("I DO [" + this.props.isAmber + " | " + this.props.isRed + "]: " + (this.doFilterByColour ? "filter by colour" : "NO, NO, NO signor, no do by colour by filter!") + "\ntx: " + tx + "\nHeatmap: " + (this.props.currentHeatMap===null ? "nuuuul" : "Hoooooot!") + "\nAspect: " + aspect);



								if (this.props.currentHeatMap !== null)
                                {
                                    this.heatmapClick(-1, this.props.currentHeatMap[1], this.props.currentHeatMap[3], this.props.currentHeatMap[2], this.props.currentHeatMap[4]);
                                }
                                else
                                {
                                    if (this.doFilterByColour) this.selectGlobalAmberRed(0, [this.props.isAmber, this.props.isRed], () => {this.setState({waitForFiltering:false});this.props.controller.handleDataLoadComplete()});
                                }

                    } // all filtering
                )
            }

        );

    } // componentDidMount

    componentWillUnmount()
    {
        window.removeEventListener('resize', this.resizer);
    } // componentWillUnmount







    render()
    {
    

    
    
//        const displayParameters = this.computeDisplayParams();
        const [tdw, tdh] = SimplifiedTraditionalTable.computeDimensions(this.computeDisplayParams());
        
// onsole.log("TDW x TDH: " + tdw + " / " + tdh);
// onsole.log("WAI!!!! " + this.state.waitForFiltering);

//			if () return <></>; // Harsh
        return (
            <div className={"source-detail-masterservant"} style={{marginBottom:this.dataViewer!==null && this.dataViewer.verticalScroller!==null && window.innerWidth>1400?"-6px":(this.dataViewer!==null && this.dataViewer.horizontalScroller===null && window.innerHeight>580 && window.innerHeight<730?"-26px":"0px")}}>


                <div style={{display:"none"}}>
                    {/*<AqaSnapShotHeader
                    parent={this}
                    handleHome={() => this.handleHome()}
                    sourceName={this.props.dataFromSource.name}
                    label={this.props.dataFromSnapshot.label}
                    dimensions={this.state.dimensions}
                />*/}
                </div>
                {/* normal content */}

                <div style={{}}>



                    <AqaRules
                        dataFromParent={this}
                        dataFromSource={this.props.dataFromSource}
                        // controller={this.props.dataFromParent}
                        controller={this}
                        dataForAspect={this.props.dataForAspect}
                        showEditor={false}
                        dataFromSnapshot={this.props.dataFromSnapshot}
                        suppress={true}
                    />

                    <div style={{padding:"0px",marginBottom:this.props.controller.state.selectedColumn!==null?"0px":"0px",marginRight:"-10px"}}>
                        <SimplifiedTraditionalTable
                            parent={this}
                            controller={this}
                            key={"ET" + this.serial}

                            width={tdw}
                            height={tdh}
                            visible={!this.state.waitForFiltering}
                        />
                    </div>


                </div>

                {/* Debug */}
                {/*
<div onClick={this.getTypeMap}>type map</div>
<pre>
{this.state.typeMap}
</pre>
*/}
            </div>

        );
    } // render


    makeDistributionColoursId(position) { return this.makeDistributionColoursIdWithViewId(this.currentView.id, position); } //         `${this.currentView.id}_column_${position}`;

    makeDistributionColoursIdWithViewId = (viewId, position) => `${viewId}_column_${position}`;


} ////


/* Click and double clicks
https://stackoverflow.com/questions/25777826/onclick-works-but-ondoubleclick-is-ignored-on-react-component
In conclusion, it's not advisable to use double clicks here...
*/


/* Right clicks
   https://stackoverflow.com/questions/2405771/is-right-click-a-javascript-event
*/

/*
Getting length of string
https://blog.mastykarz.nl/measuring-the-length-of-a-string-in-pixels-using-javascript/

*/


