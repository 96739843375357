/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
    Card, CardActions, CardContent,
    CardHeader, Checkbox,
    FormControlLabel, FormGroup,
    GridList,
    GridListTile,
    Radio,
    RadioGroup
} from "@material-ui/core";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import {ReactComponent as UploadIcon} from "../../images/aqa-icons/Uploadcol.svg";
import {ReactComponent as EditIcon} from "../../images/aqa-icons/AQA-Edit.svg";
import {ReactComponent as UnPublishIcon} from "../../images/aqa-icons/Aqa Unpublish.svg";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";
import DialogContent from "@material-ui/core/DialogContent";
import {ReactComponent as RuleIcon} from "../../images/aqa-icons/AQA-Rules.svg";
import {ReactComponent as SourceIcon} from "../../images/aqa-icons/Sourcecol.svg";
import {ReactComponent as AccountIcon} from "../../images/aqa-icons/Usercol.svg";
import {ReactComponent as CheckIcon} from "../../images/aqa-icons/CheckMark.svg";
//import AqaFolderIncludeListRow from "./AqaFolderIncludeListRow";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
//import AqaSourceGridRow from "../sourcemanager/AqaSourceGridRow";
import Avatar from "@material-ui/core/Avatar";
import HtmlTooltip from "@material-ui/core/Tooltip";
import moment from "moment/moment";
/*import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import Heatmap from "../shared/heatmap/Heatmap";
import AqaGridIcon from "../../images/aqa-grid.png";
import {ReactComponent as SnapshotIcon} from "../../images/aqa-icons/AQA-Snapshot - v2.svg";*/
import Badge from '@material-ui/core/Badge';
import TextField from "@material-ui/core/TextField";
const styles = theme => ({
    root:{
        maxWidth: 400,
        margin: "8px",
    },
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    },
    dot:{
        width: '8px',
        height:'8px',
        marginLeft:'-3px'
    },
    default:{
        width: '8px',
        height:'8px',
        marginLeft:'-3px',
        backgroundColor:"green"
    }
});



class AqaLibraryManager extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            sources: [],
            filteredSources:[],
            isGrid: false,
            sortDirection: true,
            sortByType: "name",
            selectedSources:[],
            selectedAccounts:[],
            resetCheckbox:false,
            updateHeader:false,
            height:window.innerHeight-100,
            currentAspect:null,
            currentSource:null,
            currentSourceId:"newsource",
            currentSnapshot:null,
            isUploadEnabled:false,
            accountObj:"",
            openMessage:false,
            snapshotcount:0,
            datasources:[],
            aqdIdToUse:"",
            openRuleMessage:false,
            accounts:[],
            qds:[],
            showPublish:false,
            showUnPublish:false,
            isRepublish:false,
            errorMessage:"",
            confirmMessage:false,
            confirmHeader:"Action Successful",
            confirmationContent:"This action completed successfully",
            currentQdId:"",
            authCode:props.dataFromParent.state.authCode,
            showResources:false,
            isAccountShared:false,
            filteredResources:[]
        };
        //this.handleFolder = this.handleFolder.bind(this);
        this.handleDimensions=this.handleDimensions.bind(this);

// onsole.log("This is in AqaSourceView");
// onsole.log(props.dataAcquired);

        /*this.handleFolder(props.dataAcquired);*/
    }



    GetAscSortOrder(prop) {
        return function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }
    GetDescSortOrder(prop) {
        return function(a, b) {
            if (a[prop] < b[prop]) {
                return 1;
            } else if (a[prop] > b[prop]) {
                return -1;
            }
            return 0;
        }
    }


    handleDimensions =(event)=>{
        event.preventDefault();
        this.setState({height:window.innerHeight-100});
    }

    handleSources = () =>{
        AqaComponent.backend.sourcesUsingGET(
            "false",
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the sources from the server was encountered.",
                    "AqaSourceView.handleFolder, backend call: " + error,this);
                else
                {
                    this.handleRulesLibrary(data);
                }
            }
        );
    }

    findSource = (id)=>{
        if(this.state.currentQdId === "") return 0;
        let qds = this.state.qds.filter((d)=>{return d.id === this.state.currentQdId;});
        let qd =null;
        if(qds.length === 1) qd=qds[0];
        if(qd !== null) {
            let sources = qd.updates.filter((d)=>{return d.id === id});
            return sources.length;
        }
        return 0;
    }

    findAccount = (owned,k)=>{
        let accountName = "";
        let accountObj = this.state.accountObj;
        if(owned){
                if(accountObj.id === k) accountName = accountObj.name;
        }
        else{
            let accounts = this.state.accounts.filter((d)=>{return d.id === k});
            if(accounts.length>0) accountName = accounts[0].name;
        }
        return accountName;
    }

    handleAccounts = () =>{
        AqaComponent.backend.listAccountsUsingGET(
            (error, data, response) => {
                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                else {
                    this.setState({accounts:data});
                }
            }
        )
    }

    handleAccountDetails = ()=>{
        AqaComponent.accountBackend.getMyAccountUsingGET(
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaLibraryManager.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    this.setState({accountObj:accountObj});
                }
            }
        );
    }

    handleQualityDefinitionFromMarket = (qd) =>{
       AqaComponent.marketBackend.getQualityDefinitionFromMarketUsingGET(
            qd.id,
            (error, data, response) => {
                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                else {
                    //let currentQD = {...aqd,...data}
                    //this.setState({currentQD:currentQD,isLoaded:true});
                    let columnCount = 0;

                    if(data.success){
                        let aqd = {qualityDefinition:data.qualityDefinition};
                        columnCount = data.qualityDefinition.vectorQualityTable.columnQualities.length;
                        qd = {...qd,...aqd};
                    }
                    let qdCount = {columnCount:columnCount};
                    qd = {...qd,...qdCount};
                    let qds = this.state.qds;
                    qds.push(qd);
                    qds = qds.sort(this.GetDescSortOrder('publishTimestamp'));
                    this.setState({qds:qds});
                }
            }
        );
    }

    handleRulesLibrary = (datasources) =>{
        AqaComponent.marketBackend.qdDirectoryUsingGET(
            async (error, data, response) => {
                if (error) this.reportError("A problem getting the library from the server was encountered.",
                    "AqaLibraryManager.handleRulesLibrary, backend call: " + error,this);
                else {
                    let qds = [];
                    let sources = [];
                    let updates = false;
                    for (let i = 0; i < data.publicQualityDefinitions.length; i++) {
                        let qd = data.publicQualityDefinitions[i];
                        sources = datasources.filter((d) => {
                            return d.subscribedId === qd.id
                        });
                        updates = sources.filter((d) => {
                            return d.subscribedQdId !== qd.aqdId
                        });
                        let qdtemp = {
                            scope: "PUBLIC",
                            sources: sources,
                            updates: updates,
                            usageCount: sources.length,
                            newItem: false
                        };
                        qd = {...qd, ...qdtemp};
                        qds.push(qd);
                    }
                    for (let j = 0; j < data.privateQualityDefinitions.length; j++) {
                        let qd = data.privateQualityDefinitions[j];
                        sources = datasources.filter((d) => {
                            return d.subscribedId === qd.id
                        });
                        updates = sources.filter((d) => {
                            return d.subscribedQdId !== qd.aqdId
                        });
                        let qdtemp = {
                            scope: "PRIVATE",
                            sources: sources,
                            updates: updates,
                            usageCount: sources.length,
                            newItem: false
                        };
                        qd = {...qd, ...qdtemp};
                        qds.push(qd);
                    }
                    for (let k = 0; k < data.protectedQualityDefinitions.length; k++) {
                        let qd = data.protectedQualityDefinitions[k];
                        sources = datasources.filter((d) => {
                            return d.subscribedId === qd.id
                        });
                        updates = sources.filter((d) => {
                            return d.subscribedQdId !== qd.aqdId
                        });
                        let qdtemp = {
                            scope: "PROTECTED",
                            sources: sources,
                            updates: updates,
                            usageCount: sources.length,
                            newItem: false
                        };
                        qd = {...qd, ...qdtemp};
                        qds.push(qd);
                    }
                    this.setState({qds: []});
                    qds=qds.sort(this.GetDescSortOrder('publishTimestamp'));
                    for(let i=0;i<qds.length;i++){
                        this.handleQualityDefinitionFromMarket(qds[i]);
                    }
                }
            }
        );
    }

    handleClosePopup = ()=>{
        this.setState({openMessage:false,showPublish:false,isEditPublish:false,selectedSources:[],currentSourceId:"",currentQdId:"",showResources:false,filteredResources:[]});
    }

    handleRadioChange = (e)=>{
        const {name,value} = e.target;
        this.setState({[name]:value});
    }

    handleImportClick = (qdId)=>{

        AqaComponent.marketBackend.importQualityDefinitionFromMarketUsingPOST(
            qdId,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem posting the aqd from the server was encountered.", "AqaSourceView.handleRuleUpload, backend call: " + error,this);
                else
                {
                    if(data.success){
                        if(data.sources.length ===0 ){
                            this.handleAssignDataSource("new",data.aqdIdToUse,true,true);
                        }
                        else{
                            this.setState({aqdIdToUse:data.aqdIdToUse,datasources:data.sources,currentQdId:qdId,openMessage:true,selectedSources:[]});
                        }
                    }
                    else{
                        this.removeAllNotifications();
                        this.showNotification(5);
                        this.setState({file:null,resetCheckbox:false,fileUploaded:null,isFileReady:false,openMessage:false});
                    }
                }
            }
        );
    }

    handleSaveRuleFileClosePopup = () =>{
        if((this.state.currentSourceId==="" && this.state.selectedSources.length===0)) this.setState({errorMessage:"Please select the source"});
        else {
            this.setState({errorMessage:""});
            if(this.state.currentSourceId !=="") {
                this.handleAssignDataSource(this.state.currentSourceId, this.state.aqdIdToUse,true,true);
            }
            for(let i=0;i<this.state.selectedSources.length;i++) {
                this.handleAssignDataSource(this.state.selectedSources[i],this.state.aqdIdToUse,i===this.state.selectedSources.length-1,false);
            }
        }
    }

    handleAssignDataSource = (sourceid, aqdIdToUse, last,fresh) =>{
        if(sourceid === "newsource") sourceid="new";
        AqaComponent.backend.assignQualityDefinitionUsingPUT(
            aqdIdToUse,
            sourceid,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem assigning the source for aqd from the server was encountered.", "AqaSourceView.handleAssignSource, backend call: " + error,this);
                else
                {
                    if(data.success){
                        if(last) {
                            this.setState({
                                file: null,
                                resetCheckbox: false,
                                fileUploaded: null,
                                isFileReady: false,
                                openMessage: false,
                                isUploadEnabled: false,
                                openRuleMessage: false,
                                confirmMessage:true,
                                confirmationContent:fresh?"Added the new data rules and used the quality definition rules":"Updated the source(s) with quality definition rules"
                            },this.props.dataFromParent.handleRulesLibrary()
                                );
                        }
                    }
                    else{
                        this.setState({file:null,resetCheckbox:false,fileUploaded:null,isFileReady:false,openMessage:false,isUploadEnabled:false,openRuleMessage:false});
                        this.removeAllNotifications();
                        this.showNotification(5);
                    }
                }
            }
        );

    }

    handleRuleClosePopup = (e) =>{
        this.setState({showPublish:false,showUnPublish:false,publicationId:""});
    }

    handleFriendlyAccountsSharedWith = (item,type) =>{
        AqaComponent.marketBackend.getListOfAccountsSharedWithUsingGET(
            item.id,
            (error, data, response) => {
                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                else {
                    if(data.success === true){

                        let selectedAccounts = [];
                        let scope = data.scope;
                        if(data.accounts.length>0){
                            for(let i=0;i<data.accounts.length;i++){
                                selectedAccounts.push(data.accounts[i].id)
                            }
                            if(scope==="PUBLIC" || scope === "PRIVATE") selectedAccounts=[];
                        }
                        if(type === "edit"){
                            this.setState({showPublish: true,isEditPublish:true,title:item.title,description:item.description,scope:item.scope,selectedAccounts:selectedAccounts,publicationId:item.id,errorMessage:""});
                        }
                        else{
                            let filteredResources = this.state.accounts.filter((d)=>{return selectedAccounts.includes(d.id)})
                            this.setState({showResources:true,errorMesssage:"",filteredResources:filteredResources});
                        }
                    }
                    else{

                    }
                }
            }
        );
    }

    handlePublish = (e,type,item) =>{
        if(type === "publish") {
            this.setState({showPublish: true,isEditPublish:false,title:"",scope:"",selectedAccounts:[],errorMessage:""});
        }
        else{
            //this.setState({showPublish: true,isEditPublish:true});
            //sthis.setState({errorMessage:""},this.handleFriendlyAccountsSharedWith());
            this.handleFriendlyAccountsSharedWith(item,"edit");
        }
    }

    handlePublishClosePopup = (e,type) =>{
        let source=this.props.dataFromParent;
        let {scope,title,selectedAccounts,description} = this.state;
        let errors=[];
        if(scope === null || scope === "") errors.push(" Scope");
        if((title === null || title === "")) errors.push(" Title");
        if((description === null || description === "" || description.length>10000)) errors.push(" Description");
        if(scope === "PROTECTED" && selectedAccounts.length===0 ) {
            errors.push(" Accounts");
        }
        if(scope === "PUBLIC" || scope === "PRIVATE") selectedAccounts=[];
        if(errors.length>0) {
            this.setState({errorMessage:errors.toString()+" are mandatory"});
            return;
        }
        let pr={scope:scope,title:title,description:description,accountsToShareWith:selectedAccounts};
        if(type === "publish") {
            AqaComponent.marketBackend.publishQualityDefinitionUsingPOST(
                source.id,
                pr,
                (error, data, response) => {
                    if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                        "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                    else {
                        //console.log(data);
                        if (data.success) {
                            this.setState({showPublish: false, showUnPublish: false, isPublish: true,publicationId:data.message},this.handleSources());
                        } else {
                            this.setState({errorMessage: data.message});
                        }
                    }
                }
            );
        }
        else if(type ==="editpublish"){
            AqaComponent.marketBackend.updateQualityDefinitionFriendlyAccountIdsUsingPUT(
                this.state.publicationId,
                pr,
                (error, data, response) => {
                    if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                        "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                    else {
                        //console.log(data);
                        if (data.success) {
                                this.setState({showPublish: false, showUnPublish: false, isPublish: true,confirmMessage:true,confirmHeader:"Published Successfully",confirmationContent:"This resource is now published successfully"},this.handleSources());
                        } else {
                            this.setState({errorMessage: data.message});
                        }
                    }
                }
            );
        }
        else {
            this.setState({showPublish: false, showUnPublish: false});
        }

    }

    handleUnPublishClosePopup = (e,type) =>{
        //let source = this.props.dataFromParent;
        if(type === "unpublish") {
            AqaComponent.marketBackend.unpublishQualityDefinitionUsingDELETE(
                this.state.publicationId,
                (error, data, response) => {
                    if (error) this.reportError("A problem unpublishing the aqd from the server was encountered.",
                        "AqaLibraryManager.handleUnPublishClosePopup, backend call: " + error,this);
                    else {
                        if (data.success) {
                            //this.setState({accounts:data});
                            this.setState({showPublish: false, showUnPublish: false, isPublish: false,publicationId:"",confirmMessage:true,confirmationContent:"Unpublished successfully"});
                        } else {
                            this.setState({errorMessage: "Unable to perform this operation"});
                        }
                    }
                }
            );
        }
        else {
            this.setState({showPublish: false, showUnPublish: false});
        }
    }

    handleUnPublish = (e,type,publicationId) =>{
        if(type === "unpublish"){
            this.setState({showUnPublish:true,isRepublish:false,publicationId:publicationId,errorMessage:""});
        }

    }

    handleConfirmClosePopup =()=>{
        this.setState({confirmMessage:false},this.handleSources());
        this.props.dataFromParent.handleFolder("allsources");
    }

    /*UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        window.addEventListener("onload",this.handleDimensions);
        window.addEventListener("fullScreenChange",this.handleDimensions);
        window.addEventListener("resize",this.handleDimensions);
    }*/

    async componentDidMount(){
        this.handleSources();
        this.handleAccountDetails();
        this.handleAccounts();

        window.addEventListener("onload",this.handleDimensions);
        window.addEventListener("fullScreenChange",this.handleDimensions);
        window.addEventListener("resize",this.handleDimensions);

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        window.addEventListener("onload",this.handleDimensions);
        window.addEventListener("fullScreenChange",this.handleDimensions);
        window.addEventListener("resize",this.handleDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("onload",this.handleDimensions);
        window.removeEventListener("fullScreenChange",this.handleDimensions);
        window.removeEventListener("resize",this.handleDimensions);
    }

    handleCheckChange = (event,item) =>{
        const {name,checked} = event.target;
        let selectedSources = this.state.selectedSources;
        if(checked){
            if(!selectedSources.includes(name)) selectedSources.push(name);
        }
        else{
            if(selectedSources.includes(name)) selectedSources= selectedSources.filter((d)=>{return d!==name;})
        }
        //alert(selectedAccounts.toString());
        this.setState({selectedSources:selectedSources});
    }

    handleAccountCheckChange = (event,item) =>{
        const {name,checked} = event.target;
        let selectedAccounts = this.state.selectedAccounts;
        if(checked){
            if(!selectedAccounts.includes(name)) selectedAccounts.push(name);
        }
        else{
            if(selectedAccounts.includes(name)) selectedAccounts= selectedAccounts.filter((d)=>{return d!==name;})
        }
        this.setState({selectedAccounts:selectedAccounts,currentSourceId:""});
    }

    handleShowResources =(item,type)=>{
        if(type === "accounts"){
            this.setState({isAccountShared:true},this.handleFriendlyAccountsSharedWith(item,"view"));
        }
        if(type === "sources"){
            let filteredResources = item.sources;
            this.setState({showResources:true,isAccountShared:false,filteredResources:filteredResources});
        }
    }

    handleShowLibraryQD = (e,qd)=>{
        e.preventDefault();
        e.stopPropagation();
        this.props.dataFromParent.props.dataFromMain.props.dataFromRoot.handleAQDSource(qd.id,"market",false,true,1);
        this.props.dataFromParent.props.dataFromMain.props.dataFromRoot.navigation(6);
    }

    render() {
        const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,height,qds,errorMessage,selectedSources,confirmHeader,confirmationContent,authCode,accounts,selectedAccounts } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <div className={"source-detail-servant-pane-main"} style={{height:height}}>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={10} sm={7} lg={11} xl={11}>
                            <Typography variant="title" color="inherit" align="left"
                                        style={{ fontSize: '1.5rem',
                                            padding: '0.1em 0.5em',
                                            fontFamily: 'Open Sans, sans-serif'}}>
                                Nothing to display
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} lg={1} xl={1}>

                        </Grid>
                    </Grid>
                </div>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return (
                <div className={"source-detail-servant-pane-main"} style={{height:height}}>
                    <div style={{"borderBottom":"1px solid #DBDBDB","height":"52px"}}>

                    </div>
                </div>
            );
        } else {
            return (
                <div className={"source-detail-servant-pane-main"} style={{height:height-50,overflow:"auto"}}>
                    <GridList cellHeight={'auto'} cols={window.innerWidth<500?1:window.innerWidth<800?2:window.innerWidth<1250?3:4} style={{
                        width: window.innerWidth * 0.985,margin:"8px"
                    }}>
                        {qds.map(item => (
                            <GridListTile key={item.id}>
                                <div className="aqa-card">

                                    <Card className={classes.root}>
                                        <CardHeader style={{padding:"5px 5px 0px 5px",borderBottom:"1px solid #ccc",cursor:"pointer"}} onMouseOut={this.handleMouseOverIcon} onMouseLeave={this.handleMouseOut} onClick={(e)=>this.handleShowLibraryQD(e,item)}
                                                    avatar={
                                                        <Avatar aria-label="recipe" className={classes.avatar} style={{backgroundColor:"transparent",width:"50px",height:"50px",marginTop:"-5px"}}>
                                                            <span style={{float:"left",width:"36px"}}>
                                                        <div className="aqa-list-icon" style={{marginTop:"3px"}}>
                                                            <Badge color={"secondary"} badgeContent={item.updates.length} variant={"dot"} showZero={item.newItem?true:false} classes={{dot:item.updates.length>0?classes.dot:classes.default}} >
                                                                <RuleIcon width="32px" />
                                                            </Badge>
                                                        </div>
                                                        </span>
                                                        </Avatar>
                                                    }

                                                    title={<Typography variant="inherit" color="inherit" className={"aqa-text-bold"} style={{textAlign:"left",fontSize:"0.8rem",paddingLeft:"0px",marginTop:"-4px",marginLeft:"-15px"}}>
                                                        {/*(this.props.dataFromGrid.name === "" || this.props.dataFromGrid.name)? (this.props.dataFromGrid.name) : (this.props.dataFromGrid.name.substr(0,80)+"...")*/}
                                                        {item.title}
                                                    </Typography>}
                                                    subheader={<Typography variant="inherit" color="inherit" className={"aqa-text-sub"} style={{textAlign:"left",fontSize:"0.7rem",paddingLeft:"0px",marginLeft:"-5px"}}>
                                                        {/*(this.props.dataFromGrid.description !== "" && this.props.dataFromGrid.description !== null && this.props.dataFromGrid.description !== "null") ? (this.props.dataFromGrid.description) : ""*/}
                                                        <Typography>

                                                        </Typography>

                                                    </Typography>}
                                                    action={
                                                        <IconButton className="aqa-button">
                                                            {item.owned?
                                                            <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"right",color:"#04A6FC",paddingLeft:"4px",marginTop:"8px"}}>
                                                                {item.scope==="PROTECTED"?"RESTRICTED":item.scope}
                                                            </Typography>:
                                                                ""
                                                            }

                                                        </IconButton>
                                                    }
                                        />

                                        <CardContent style={{padding:"0px"}}>
                                            <Grid container spacing={0} direction="row" justify="space-between"
                                                  alignItems="flex-start"
                                                  style={{padding: 0, marginTop: 0}}>
                                                <Grid item xs={2} sm={2} lg={3} xl={2}>
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "left",
                                                        paddingLeft: "4px",
                                                        width:"100px",
                                                        paddingTop:"6px",
                                                        paddingBottom:"6px",
                                                        color:"darkgrey"
                                                    }}>
                                                        Description
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={10} sm={10} lg={9} xl={10}>
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "left",
                                                        paddingLeft: "4px",
                                                        paddingTop:"6px",
                                                        paddingBottom:"6px",
                                                        paddingRight:"8px",
                                                        height:"48px",
                                                        overflow:"auto"
                                                    }}>    {item.description}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} sm={2} lg={3} xl={2}>
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "left",
                                                        paddingLeft: "4px",
                                                        width:"100px",
                                                        paddingTop:"6px",
                                                        paddingBottom:"6px",
                                                        color:"darkgrey"
                                                    }}>
                                                        Author
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={10} sm={10} lg={9} xl={10}>
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "left",
                                                        paddingLeft: "4px",
                                                        paddingTop:"6px",
                                                        paddingBottom:"6px"
                                                    }}>    {this.findAccount(item.owned,item.ownerId)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} sm={2} lg={3} xl={2}>
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "left",
                                                        paddingLeft: "4px",
                                                        width:"100px",
                                                        paddingTop:"6px",
                                                        color:"darkgrey"
                                                    }}>
                                                        Published
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} sm={4} lg={3} xl={4}>
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "left",
                                                        paddingLeft: "4px",
                                                        paddingTop:"6px",
                                                        paddingBottom:"6px"
                                                    }}>    {moment(item.publishTimestamp).locale('en_USA').fromNow()}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} sm={2} lg={3} xl={2}>
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "left",
                                                        paddingLeft: "4px",
                                                        paddingTop:"6px",
                                                        width:"100px",
                                                        color:"darkgrey"
                                                    }}>
                                                        Created
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} sm={4} lg={3} xl={4}>
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "left",
                                                        paddingLeft: "4px",
                                                        paddingTop:"6px",
                                                        paddingBottom:"6px"
                                                    }}>    {moment(item.timestamp).locale('en_USA').fromNow()}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={2} sm={2} lg={3} xl={2}>
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "left",
                                                        paddingLeft: "4px",
                                                        width:"100px",
                                                        paddingTop:"6px",
                                                        color:"darkgrey"
                                                    }}>
                                                        Version
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} sm={4} lg={3} xl={4}>
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "left",
                                                        paddingLeft: "4px",
                                                        paddingTop:"6px",
                                                        paddingBottom:"6px"
                                                    }}>    {item.version}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} sm={2} lg={3} xl={2}>
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "left",
                                                        paddingLeft: "4px",
                                                        width:"100px",
                                                        paddingTop:"6px",
                                                        color:"darkgrey"
                                                    }}>
                                                        Columns
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} sm={4} lg={3} xl={4}>
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "left",
                                                        paddingLeft: "4px",
                                                        paddingTop:"6px",
                                                        paddingBottom:"6px"
                                                    }}>    {item.columnCount}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} sm={2} lg={3} xl={2}>
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "left",
                                                        paddingLeft: "4px",
                                                        width:"100px",
                                                        paddingTop:"6px",
                                                        color:"darkgrey"
                                                    }}>
                                                        Usage
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} sm={4} lg={3} xl={4}>
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "left",
                                                        paddingLeft: "4px",
                                                        paddingTop:"6px",
                                                        paddingBottom:"6px"
                                                    }}>    {item.usageCount>0?
                                                        <IconButton className="aqa-button" disabled={false} style={{padding:"4px"}} onClick={(e)=>this.handleShowResources(item,"sources")}>
                                                            <Typography className="aqa-text-action" variant="inherit" style={{textAlign:"left",color:"#04A6FC",marginLeft:"-4px",marginTop:"-4px",paddingTop:"0px"}}>
                                                                {item.usageCount}
                                                            </Typography>

                                                        </IconButton>

                                                        :"NONE"}
                                                    </Typography>
                                                </Grid>

                                                {item.owned && authCode === "A" ?
                                                    <Grid item xs={2} sm={2} lg={3} xl={2}>
                                                        <Typography className="aqa-text-action" variant="inherit"
                                                                    style={{
                                                                        textAlign: "left",
                                                                        paddingLeft: "4px",
                                                                        paddingTop:"6px",
                                                                        width: "100px",
                                                                        color: "darkgrey"
                                                                    }}>
                                                            Visibility
                                                        </Typography>
                                                    </Grid>
                                                    : <Grid item xs={2} sm={2} lg={3} xl={2}>
                                                        <Typography className="aqa-text-action" variant="inherit"
                                                                    style={{
                                                                        textAlign: "left",
                                                                        paddingLeft: "4px",
                                                                        width: "100px",
                                                                        color: "white"
                                                                    }}>
                                                            {"."}
                                                        </Typography>
                                                    </Grid>
                                                }
                                                {item.owned && authCode === "A" ?
                                                    <Grid item xs={4} sm={4} lg={3} xl={4}>
                                                        <Typography className="aqa-text-action" variant="inherit"
                                                                    style={{
                                                                        textAlign: "left",
                                                                        paddingLeft: "4px",
                                                                        paddingTop: "6px",
                                                                        paddingBottom: "6px"
                                                                    }}>    {item.scope === "PRIVATE" ? "Only this account" :
                                                            item.scope === "PUBLIC" ? "All accounts" :
                                                                <IconButton className="aqa-button" disabled={false} style={{padding:"4px"}} onClick={(e)=>this.handleShowResources(item,"accounts")}>
                                                                    <Typography className="aqa-text-action" variant="inherit" style={{textAlign:"left",color:"#04A6FC",marginLeft:"-4px",marginTop:"-4px",paddingTop:"0px"}}>
                                                                        {(item.howMany-1)+" other accounts"}
                                                                    </Typography>

                                                                </IconButton>
                                                        }
                                                        </Typography>
                                                    </Grid>
                                                    : <Grid item xs={4} sm={4} lg={3} xl={4}>
                                                        <Typography className="aqa-text-action" variant="inherit"
                                                                    style={{
                                                                        textAlign: "left",
                                                                        paddingLeft: "4px",
                                                                        paddingTop: "4px",
                                                                        paddingBottom: "0px",
                                                                        color:"white"
                                                                    }}>    {"."}
                                                        </Typography>
                                                    </Grid>
                                                }



                                            </Grid>
                                            <Grid container spacing={0} direction="row" justify="space-between"
                                                  alignItems="center"
                                                  style={{padding: 0, marginTop: "10px"}}>



                                            </Grid>

                                        </CardContent>
                                        <CardActions disablespacing="true" style={{padding:"0px",borderTop:"1px solid rgb(219, 219, 219)"}} >

                                            <Grid container spacing={0} direction="row" justify="space-between"
                                                  alignItems="center"
                                                  style={{padding: 0, marginTop: 0}}>
                                                <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                    {authCode === "A"?
                                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"5px"}}>
                                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                            <div>
                                                                <Typography color="inherit" className="aqa-text-action">Click to use the Quality Definition</Typography>
                                                            </div>
                                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                            <IconButton className="aqa-button" disabled={false} style={{padding:"4px"}} onClick={(e)=>this.handleImportClick(item.id)}>

                                                                <div className="aqa-icon" >
                                                                    <UploadIcon style={{color:"#006",width:"24px"}} />
                                                                </div>
                                                                <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                                                    {"USE"}
                                                                </Typography>
                                                            </IconButton>
                                                        </HtmlTooltip>

                                                    </Typography>
                                                        :""
                                                    }
                                                </Grid>
                                                <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                    {item.owned && authCode==="A"?
                                                        <Typography variant="inherit" color="inherit" style={{textAlign:"center",fontSize:"0.8rem",width:"100%",paddingTop:"5px"}}>
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action">Click to edit resource</Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <IconButton className="aqa-button" disabled={false} style={{padding:"4px"}} onClick={(e)=>this.handlePublish(e,"editpublish",item)}>

                                                                    <div className="aqa-icon" >
                                                                        <EditIcon style={{color:"#006",width:"24px"}} />
                                                                    </div>
                                                                    <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                                                        {"Edit"}
                                                                    </Typography>
                                                                </IconButton>
                                                            </HtmlTooltip>

                                                        </Typography>:
                                                        ""
                                                    }
                                                </Grid>
                                                <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                    {item.owned && authCode==="A"?
                                                    <Typography variant="inherit" color="inherit" style={{textAlign:"right",fontSize:"0.8rem",width:"100%",paddingTop:"5px"}}>
                                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                            <div>
                                                                <Typography color="inherit" className="aqa-text-action">Click to unpublish resource</Typography>
                                                            </div>
                                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                            <IconButton className="aqa-button" disabled={false} style={{padding:"4px"}} onClick={(e)=>this.handleUnPublish(e,"unpublish",item.id)}>

                                                                <div className="aqa-icon" >
                                                                    <UnPublishIcon style={{color:"#006",width:"24px"}} />
                                                                </div>
                                                                <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                                                    {"UNPUBLISH"}
                                                                </Typography>
                                                            </IconButton>
                                                        </HtmlTooltip>

                                                    </Typography>:
                                                        ""
                                                    }
                                                </Grid>
                                            </Grid>

                                        </CardActions>

                                    </Card>
                                </div>
                            </GridListTile>
                        ))}

                    </GridList>
                    <div>
                        <Dialog
                            open={this.state.openMessage}
                            //onClose={this.handleCloseMessage}
                            aria-labelledby="max-width-dialog-title"
                            maxWidth="lg"
                        >
                            <DialogTitle id="simple-dialog-title" className="aqa-dialog-header"
                                         style={{"color": "white !important", padding: "14px 14px 10px"}}>
                                <Typography variant="inherit" color="inherit" style={{
                                    textAlign: "left",
                                    fontSize: "14px",
                                    color: "white",
                                    paddingLeft: "4px",
                                    fontFamily: "Montserrat",
                                    float: "left",
                                    width: "96%"
                                }}>
                                    Use Rules
                                </Typography>
                                <Typography variant="inherit" color="inherit" style={{
                                    textAlign: "left",
                                    fontSize: "14px",
                                    color: "white",
                                    paddingLeft: "0px",
                                    fontFamily: "Montserrat",
                                    float: "left",
                                    width: "3%"
                                }}>
                                    <IconButton className="aqa-button"
                                                onClick={this.handleClosePopup.bind(this)}
                                                style={{padding: "0px"}}>
                                        <div className="aqa-g-icon" style={{width: "20px"}}>
                                            <CloseIcon width="20px"/>
                                        </div>
                                    </IconButton>
                                </Typography>
                            </DialogTitle>
                            <DialogContent align="center" style={{padding: 0, minWidth: "640px"}}>
                                <Grid container spacing={0} direction="row" justify="space-between"
                                      alignItems="center"
                                      style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        {/*<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                                            {(errorMessage !=="")?errorMessage:"."}
                                                        </Typography>*/}
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        {/*<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                                            Select Source to Upload the Data
                                                        </Typography>*/}

                                        <Typography variant="inherit" color="inherit" style={{
                                            textAlign: "left",
                                            fontSize: "0.8rem",
                                            color: "#006",
                                            padding: "5px"
                                        }}>
                                            <Typography className={"aqa-text-action"} variant="title"
                                                        align="left"
                                                        style={{paddingLeft: "8px"}}>
                                                Either:
                                            </Typography>
                                            <RadioGroup aria-label="currentSourceId" name="currentSourceId"
                                                        value={this.state.currentSourceId} color="primary"
                                                        onChange={this.handleRadioChange} row={false}
                                                        style={{width: "auto"}}>
                                                <FormControlLabel key={"newsource"} value={"newsource"}
                                                                  control={<Radio color="primary"
                                                                                  style={{padding: "4px"}}/>}
                                                                  label={
                                                                      (<Typography
                                                                          className={"aqa-text-action"}
                                                                          variant="title" align="left"
                                                                          style={{paddingTop: "3px"}}>
                                                                          <div key={"_source_0"}>
                                                    <span style={{float: "left", width: "32px"}}>
                                                <IconButton className="aqa-button" style={{padding: "4px"}}>
                                                    <div className="aqa-list-icon">
                                                        <SourceIcon width="24px"/>
                                                    </div>
                                                </IconButton>
                                                        </span>
                                                                              <span style={{float: "left"}}>
                                                <Typography variant="inherit" color="inherit" style={{
                                                    textAlign: "left",
                                                    fontSize: "0.8rem",
                                                    color: "#006",
                                                    padding: "5px",
                                                    marginTop: "5px"
                                                }}>
                                                    {"New Data"}
                                                </Typography>
                                                </span>
                                                                          </div>
                                                                      </Typography>)


                                                                  }
                                                                  style={{width: "380px", margin: "0px"}}/>

                                            </RadioGroup>

                                        </Typography>
                                        <Typography className={"aqa-text-action"} variant="title"
                                                    align="left"
                                                    style={{paddingLeft: "12px"}}>
                                            Or Update Existing:
                                        </Typography>
                                        <Typography variant="inherit" color="inherit" style={{
                                            textAlign: "left",
                                            fontSize: "0.8rem",
                                            color: "#006",
                                            padding: "0px",
                                            width: "100%",
                                            maxHeight:"200px",
                                            overflow:"auto"
                                        }}>
                                        <div style={{paddingLeft:"14px"}} >
                                        <FormGroup style={{width:"650px"}} row>
                                            {this.state.datasources.map((item, index) =>
                                                <FormControlLabel key={item.id}
                                                                  control={
                                                                      this.findSource(item.id)>0?
                                                                              <Checkbox
                                                                                  checked={selectedSources.includes(item.id)}
                                                                                  onChange={(e) => this.handleCheckChange(e, item)}
                                                                                  name={item.id}
                                                                                  color="primary"
                                                                              />
                                                                      :
                                                                              <IconButton className="aqa-button" style={{padding: "4px",marginTop:"4px",marginRight:"4px"}}>
                                                                                  <div className="aqa-list-icon">
                                                                                      <CheckIcon width="24px"/>
                                                                                  </div>
                                                                              </IconButton>

                                                                  }
                                                                  label={(<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                      style={{padding:"0px",marginTop:"5px",marginLeft:"-10px" }}>
                                                                      <span style={{float: "left", width: "32px"}}>
                                                <IconButton className="aqa-button" style={{padding: "4px",marginTop:"-8px"}}>
                                                    <div className="aqa-list-icon">
                                                        <Badge color={"secondary"} badgeContent={this.findSource(item.id)==="0"?"0":this.findSource(item.id)} variant={"dot"} classes={{dot:classes.dot}} >
                                                        <SourceIcon width="24px"/>
                                                        </Badge>
                                                    </div>
                                                </IconButton>
                                                        </span>
                                                                      <span style={{float: "left"}}>
                                                <Typography variant="inherit" color="inherit" style={{
                                                    textAlign: "left",
                                                    fontSize: "0.8rem",
                                                    color: "#006",
                                                    padding: "5px",
                                                    marginTop: "0px"
                                                }}>
                                                    {item.name}
                                                </Typography>
                                                </span>

                                                                  </Typography>)}

                                                />
                                            )

                                            }
                                        </FormGroup>
                                            </div>
                                        </Typography>

                                    </Grid>
                                </Grid>


                                <Grid container spacing={0} direction="row" justify="space-between"
                                      alignItems="flex-start"
                                      style={{
                                          padding: 0,
                                          marginTop: 10,
                                          marginLeft: 0,
                                          marginBottom: 10,
                                          borderTop: "1px solid #ccc",
                                          width: "652px"
                                      }}>

                                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                                        <Button onClick={this.handleClosePopup.bind(this)}
                                                variant="contained"
                                                color="primary"
                                                align="left"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginLeft: 8,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}>
                                            Cancel
                                        </Button>


                                    </Grid>

                                    <Grid item xs={9} sm={9} lg={9} xl={9}>
                                    </Grid>
                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                        <Button variant="contained"
                                                onClick={this.handleSaveRuleFileClosePopup}
                                                color="primary"
                                                align="left"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px',
                                                }}>
                                            Continue
                                        </Button>


                                    </Grid>

                                </Grid>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={this.state.showUnPublish}
                            //onClose={this.handleCloseMessage}
                            aria-labelledby="max-width-dialog-title"
                            maxWidth="lg"
                        >
                            <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                    {this.state.isRepublish?"Re-Publish":"Un-Publish"} the Aqa Quality Definition
                                </Typography>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                    <IconButton className="aqa-button" onClick={(e)=>this.handleRuleClosePopup(e)} style={{padding:"0px"}} >
                                        <div className="aqa-g-icon" style={{width:"20px"}}>
                                            <CloseIcon width="20px" />
                                        </div>
                                    </IconButton>
                                </Typography>
                            </DialogTitle>
                            <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"660px"}}>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"red",padding:"5px",paddingLeft:"20px"}}>
                                            {(errorMessage !=="")?errorMessage:"."}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                            Are you sure you want to {this.state.isRepublish?"Re-Publish":"Un-Publish"}?
                                        </Typography>

                                    </Grid>

                                </Grid>


                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                      style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",minWidth:window.pageXOffset,width:"660px"}}>

                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                        <Button onClick={(e)=>this.handleRuleClosePopup(e)} variant="contained"
                                                color="primary"
                                                align="left"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginLeft: 0,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}>
                                            Cancel
                                        </Button>


                                    </Grid>

                                    <Grid item xs={8} sm={8} lg={8} xl={8}>
                                    </Grid>
                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                        <Button  variant="contained"
                                                 onClick={(e)=>this.handleUnPublishClosePopup(e,this.state.isRepublish?"republish":"unpublish")}
                                                 color="primary"
                                                 align="left"
                                                 className="aqa-action-button"
                                                 style={{
                                                     marginTop: 8,
                                                     color: '#4cadc4',
                                                     backgroundColor: 'white',
                                                     border: '1px solid #4cadc4',
                                                     fontSize: '0.7rem',
                                                     padding: '3px',
                                                 }}>
                                            {this.state.isRepublish?"Re-Publish":"Un-Publish"}
                                        </Button>



                                    </Grid>

                                </Grid>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={this.state.showPublish}
                            //onClose={this.handleCloseMessage}
                            aria-labelledby="max-width-dialog-title"
                            maxWidth="lg"
                        >
                            <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                    {this.state.isEditPublish?"Edit the Quality Definition":"Publish the Quality Definition"}
                                </Typography>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                    <IconButton className="aqa-button" onClick={(e)=>this.handleClosePopup(e)} style={{padding:"0px"}} >
                                        <div className="aqa-g-icon" style={{width:"20px"}}>
                                            <CloseIcon width="20px" />
                                        </div>
                                    </IconButton>
                                </Typography>
                            </DialogTitle>
                            <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"960px"}}>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"red",padding:"5px",paddingLeft:"20px"}}>
                                            {(errorMessage !=="")?errorMessage:"."}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                            Select scope
                                        </Typography>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",width:"100%"}}>
                                            <div style={{paddingLeft:"10px"}}>
                                            <span style={{float:"left",width:"100%"}}>
                                            <RadioGroup aria-label="scope" name="scope" value={this.state.scope} color="primary" onChange={this.handleRadioChange} style={{width:"auto"}} row>
                                                <FormControlLabel key={"PRIVATE"} value={"PRIVATE"} control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                 style={{padding:"0px" }}>
                                                        <span style={{float:"left",width:"200px"}}>{"PRIVATE"}</span>
                                                    </Typography>)
                                                } style={{width:"120px",margin:"0px"}} />
                                                <FormControlLabel key={"PUBLIC"} value={"PUBLIC"} control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                 style={{padding:"0px" }}>
                                                        <span style={{float:"left",width:"200px"}}>{"PUBLIC"}</span>
                                                    </Typography>)
                                                } style={{width:"120px",margin:"0px"}} />
                                                <FormControlLabel key={"PROTECTED"} value={"PROTECTED"} control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                 style={{padding:"0px" }}>
                                                        <span style={{float:"left",width:"200px"}}>{"PROTECTED"}</span>
                                                    </Typography>)
                                                } style={{width:"120px",margin:"0px"}} />

                                            </RadioGroup>
                                            </span>
                                                <span style={{float:"left",width:"70%",paddingTop:"10px"}}>

                                            </span>
                                                <span style={{float:"left",width:"10%"}}>

                                            </span>
                                            </div>
                                        </Typography>

                                    </Grid>
                                    {this.state.scope === "PROTECTED" ?
                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontFamily:"Montserrat",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                                Share with:
                                            </Typography>
                                            <Typography variant="inherit" color="inherit" style={{
                                                textAlign: "left",
                                                fontSize: "0.8rem",
                                                color: "#006",
                                                padding: "0px",
                                                width: "100%",
                                                height:"200px",
                                                overflow:"auto"
                                            }}>
                                                <div style={{paddingLeft: "20px"}}>

                                                    <>
                                                        <FormGroup row>
                                                            {accounts.map((item, index) =>
                                                                <FormControlLabel key={item.id}
                                                                                  control={
                                                                                      <Checkbox
                                                                                          checked={selectedAccounts.includes(item.id)}
                                                                                          onChange={(e) => this.handleAccountCheckChange(e, item)}
                                                                                          name={item.id}
                                                                                          color="primary"
                                                                                      />
                                                                                  }
                                                                                  label={(<Typography className={"aqa-text-bold-14"} variant="title" align="left"
                                                                                                      style={{padding:"0px" }}>
                                                                                      <span style={{}}>{item.name}</span>
                                                                                  </Typography>)}

                                                                />
                                                            )

                                                            }
                                                        </FormGroup>

                                                    </>
                                                </div>
                                            </Typography>
                                        </Grid> : ""
                                    }

                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontFamily:"Montserrat",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                            Title of Quality Definition
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                            <TextField
                                                id="title"
                                                name="title"
                                                label=""
                                                value={this.state.title}
                                                className={"aqa-text-action-10"}
                                                variant="outlined"
                                                margin="dense"
                                                type="text"
                                                multiline={false}
                                                rows={1}
                                                style={{ width: "97%",margin:0,padding:"3px"}}
                                                onChange={this.handleRadioChange}
                                                InputLabelProps={{
                                                    style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"10px",textAlign:"right"},

                                                }}
                                                InputProps={{
                                                    style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"18px",textAlign:"left"},
                                                }}
                                                inputProps={{
                                                    style:{"padding":"5px 5px 5px 8px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"18px",textAlign:"left"},
                                                }}
                                            />
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontFamily:"Montserrat",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                            Description
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                            <TextField
                                                id="description"
                                                name="description"
                                                label=""
                                                value={this.state.description}
                                                className={"aqa-text-action-10"}
                                                variant="outlined"
                                                margin="dense"
                                                type="text"
                                                multiline={true}
                                                rows={5}
                                                style={{ width: "97%",margin:0,padding:"3px"}}
                                                onChange={this.handleRadioChange}
                                                InputLabelProps={{
                                                    style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"10px",textAlign:"right"},

                                                }}
                                                InputProps={{
                                                    style:{"padding":"5px 0px 5px 8px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"18px",textAlign:"left"},
                                                }}
                                            />
                                        </Typography>

                                    </Grid>

                                </Grid>


                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                      style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",minWidth:window.pageXOffset,width:"960px"}}>

                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                        <Button onClick={(e)=>this.handleClosePopup(e)} variant="contained"
                                                color="primary"
                                                align="left"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginLeft: "-54px",
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}>
                                            Cancel
                                        </Button>


                                    </Grid>

                                    <Grid item xs={8} sm={8} lg={8} xl={8}>
                                    </Grid>
                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                        <Button  variant="contained"
                                                 onClick={(e)=>this.handlePublishClosePopup(e,this.state.isEditPublish?"editpublish":"publish")}
                                                 color="primary"
                                                 align="left"
                                                 className="aqa-action-button"
                                                 style={{
                                                     marginTop: 8,
                                                     marginRight:"-20px",
                                                     color: '#4cadc4',
                                                     backgroundColor: 'white',
                                                     border: '1px solid #4cadc4',
                                                     fontSize: '0.7rem',
                                                     padding: '3px',
                                                 }}>
                                            {this.state.isEditPublish?"Publish":"Publish"}
                                        </Button>



                                    </Grid>

                                </Grid>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={this.state.confirmMessage}
                            //onClose={this.handleCloseMessage}
                            aria-labelledby="max-width-dialog-title"
                            maxWidth="lg"
                        >
                            <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                    {confirmHeader}
                                </Typography>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                    <IconButton className="aqa-button" onClick={(e)=>this.handleConfirmClosePopup(e)} style={{padding:"0px"}} >
                                        <div className="aqa-g-icon" style={{width:"20px"}}>
                                            <CloseIcon width="20px" />
                                        </div>
                                    </IconButton>
                                </Typography>
                            </DialogTitle>
                            <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"660px"}}>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"red",padding:"5px",paddingLeft:"20px"}}>
                                            {(errorMessage !=="")?errorMessage:"."}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                            {confirmationContent}
                                        </Typography>

                                    </Grid>

                                </Grid>


                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                      style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",minWidth:window.pageXOffset,width:"660px"}}>

                                    <Grid item xs={2} sm={2} lg={2} xl={2}>

                                    </Grid>

                                    <Grid item xs={8} sm={8} lg={8} xl={8}>
                                    </Grid>
                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                        <Button  variant="contained"
                                                 onClick={(e)=>this.handleConfirmClosePopup(e)}
                                                 color="primary"
                                                 align="left"
                                                 className="aqa-action-button"
                                                 style={{
                                                     marginTop: 8,
                                                     color: '#4cadc4',
                                                     backgroundColor: 'white',
                                                     border: '1px solid #4cadc4',
                                                     fontSize: '0.7rem',
                                                     padding: '3px',
                                                 }}>
                                            {"OK"}
                                        </Button>



                                    </Grid>

                                </Grid>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={this.state.showResources}
                            //onClose={this.handleCloseMessage}
                            aria-labelledby="max-width-dialog-title"
                            maxWidth="lg"
                        >
                            <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                    {this.state.isAccountShared?"Shared with":"Resources used"}
                                </Typography>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                    <IconButton className="aqa-button" onClick={(e)=>this.handleClosePopup(e)} style={{padding:"0px"}} >
                                        <div className="aqa-g-icon" style={{width:"20px"}}>
                                            <CloseIcon width="20px" />
                                        </div>
                                    </IconButton>
                                </Typography>
                            </DialogTitle>
                            <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"660px"}}>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"red",padding:"5px",paddingLeft:"20px"}}>
                                            {(errorMessage !=="")?errorMessage:"."}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                            <FormGroup row>
                                                {this.state.filteredResources.map((item, index) =>
                                                    <FormControlLabel key={item.id} style={{paddingBottom:"10px"}}
                                                                      control={
                                                                          <div className="aqa-g-icon" style={{width:"20px"}}>
                                                                              {!this.state.isAccountShared ?
                                                                                  <SourceIcon width={"20px"}/> :
                                                                                  <AccountIcon width={"20px"}/>
                                                                              }
                                                                          </div>
                                                                      }
                                                                      label={(<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                          style={{padding:"0px",marginTop:"-4px",marginRight:"10px",paddingLeft:"5px" }}>
                                                                          <div style={{}}>{item.name}</div>
                                                                          {!this.state.isAccountShared ?
                                                                              <div style={{}}>{moment(item.timestamp).locale("en-USA").fromNow()}</div> : ""
                                                                          }
                                                                      </Typography>)}

                                                    />
                                                )

                                                }
                                            </FormGroup>
                                        </Typography>

                                    </Grid>

                                </Grid>


                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                      style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",minWidth:window.pageXOffset,width:"660px"}}>

                                    <Grid item xs={2} sm={2} lg={2} xl={2}>

                                    </Grid>

                                    <Grid item xs={8} sm={8} lg={8} xl={8}>
                                    </Grid>
                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                        <Button  variant="contained"
                                                 onClick={(e)=>this.handleClosePopup(e)}
                                                 color="primary"
                                                 align="left"
                                                 className="aqa-action-button"
                                                 style={{
                                                     marginTop: 8,
                                                     color: '#4cadc4',
                                                     backgroundColor: 'white',
                                                     border: '1px solid #4cadc4',
                                                     fontSize: '0.7rem',
                                                     padding: '3px',
                                                 }}>
                                            {"OK"}
                                        </Button>



                                    </Grid>

                                </Grid>
                            </DialogContent>
                        </Dialog>

                    </div>
                </div>
                );
        }
    }
}

AqaLibraryManager.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaLibraryManager);