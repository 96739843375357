

// Utility functions to read Boundary properties

const getBoundary = (vqd, type, which) => vqd[`${type}Boundary${which}`];
const elementEnabled = (boundary, Type, which) => boundary[`use${Type}${which}`];

const RED_AMBER = ["red", "amber", "green"];
const AMBER_RED = ["green", "amber", "red"];



export default class AqaBoundaries
{

	static getBoundaryComponentNames(typeName)
	{

		switch(typeName)
		{
		
		case "uniqueness":
		case "populated":

			return ({
				mainType:typeName,
				boundaryTypeName: typeName,
				TypeName: "Number",
				typeName: "number",
				useBoundariesName: null
			});


		case "string":
			return ({
				mainType:typeName,
				boundaryTypeName: "stringLength",
				TypeName: "Number",
				typeName: "number",
				useBoundariesName: "useStringLengths"
			});


		default:

			const TName = typeName.substring(0, 1).toUpperCase() + typeName.substring(1); 
			return ({
				mainType:typeName,
				boundaryTypeName: typeName,
				typeName: typeName,
				TypeName: TName,
				useBoundariesName: `use${TName}Boundaries`
			});

		}
	
	} // getBoundaryComponentNames


	//           Law 0    Law 1
	// Normal    R]A]G    G[A[R
	// Inverted  G[A[R    R]A]G

	/**
	  * @param type Number or Date
	  * @param converterLambda a function which converts the data value into a scaled value for display.
	  */
	static makeColourZones(vqd, type, converterLambda, minMaxArray) // , x0, graphicalSpan
	{
	
/*
onsole.log("IN makeColourZones");
onsole.log(JSON.stringify(vqd));
onsole.log("Type: [" + type + "]");
onsole.log("Conversion: " + converterLambda(0));
onsole.log(JSON.stringify(minMaxArray));
*/

		const ret = [];

//		let camelType;

		const {useBoundariesName, boundaryTypeName, mainType, TypeName, typeName} = AqaBoundaries.getBoundaryComponentNames(type);


/*
onsole.log("mcz 1: [" + type + "]");
onsole.log(`T: [${typeName}Enabled] ` + ": " + vqd[`${typeName}Enabled`]);
onsole.log(`U: [use${TypeName}Boundaries]` + ": " + vqd[`use${TypeName}Boundaries`]);
*/

// onsole.log("mcz 1.1: useBoundariesName: [" + useBoundariesName + "]");

/*
onsole.log(`MT: [${mainType}Enabled] ` + ": " + vqd[`${mainType}Enabled`]);
onsole.log("useBoundariesName: " + useBoundariesName);
*/

		if (!vqd || !vqd[`${mainType}Enabled`] || (useBoundariesName && !vqd[useBoundariesName])) return ret; // `use${TypeName}Boundaries`


// onsole.log("mcz 2");

		let boundaries = [null, null, null], colours;

		let i, j;

		let ni, n = 0; // n is number of values (up to two per boundary)
		let howManyBoundaries = 0;
		let startEnd;
		// Compute number of zones.
		
		
		let ender = (type === "uniqueness" || type === "populated") ? 1 : 2; // Uniqueness and Populated only have one boundary object.
		
		
		for(i = 1; i <= ender; i++)
		{
			boundaries[i] = getBoundary(vqd, boundaryTypeName, ender === 2 ? i : "");
			
// if (!boundaries[i]) onsole.log("NO BOUNDARY!!");
			
			if (!boundaries[i]) continue;

			for(ni = 0, j = 1; j <= 2; j++) if (elementEnabled(boundaries[i], TypeName, j)) ni++;
			if (ni > 0)
			{
				ni++;
				howManyBoundaries++;
				startEnd = i;
			}
			n += ni;
		}

// onsole.log("mcz 2.4: [" + howManyBoundaries + "]");


		let start, end;
		switch(howManyBoundaries)
		{
		case 0:
			return []; // Thanks for wasting our time!

		case 1:
			start = end = startEnd;
			break;

		case 2:
			start = 1;
			end = 2;
			break;

		default: // To shut up the transpiler
			break;
		}

// onsole.log("mcz 2.5");

		if (howManyBoundaries === 2) n--; // Because they share a zone between them (litterally)
		const zones = Array(n);
		for(i = 0; i < n; i++) zones[i] = {limits:[0, 0]};

		let p = 0, law;
		let boundaryElementIndex;

		let scaledValue;

		const reversed = boundaries[1].reversed;

		for(i = start; i <= end; i++)
		{


			if (!boundaries[i]) continue; // 20211228 - is this really necessary??

			// Ex: ["red", "amber", "green"];

			// Yes, the following two tests are different!!
			if (boundaries[i].reversed) colours = AMBER_RED;
			else colours = RED_AMBER;

			if (reversed) law = i - 2;
			else law = 1 - i;

			for(j = 0; j < 3; j++)
			{
				if (colours[j] === "green")
				{
					zones[p++].colour = colours[j];
					continue; // Green lives vicariously through other colours.
				}

				boundaryElementIndex = j + 1 + law; // indices are 1 and 2
				if (!elementEnabled(boundaries[i], TypeName, boundaryElementIndex)) continue;

// onsole.log("Scaling value [" + boundaries[i][`${typeName}${boundaryElementIndex}`] + "] to [" + converterLambda(boundaries[i][`${typeName}${boundaryElementIndex}`]) + "]");

				scaledValue = converterLambda(boundaries[i][`${typeName}${boundaryElementIndex}`]);
				zones[p + law].limits[1] = scaledValue;
				zones[p + 1 + law].limits[0] = scaledValue;
				zones[p].colour = colours[j];

				p++;
			}
			if (i === 1 && howManyBoundaries === 2) p--; // To share the zone between them.
		}

// onsole.log("mcz 3");

		
		if (zones && zones.length)
		{
		
// onsole.log("mcz 4: L=" + zones.length);
// onsole.log("RANGE: [" + minMaxArray[0] + " | " + minMaxArray[1] + "]");


			if (minMaxArray)
			{
				zones[0].limits[0] = converterLambda(minMaxArray[0]);
				zones[p - 1].limits[1] = converterLambda(minMaxArray[1]);
			}
			else
			{
				zones[0].limits[0] = 0;
				zones[p - 1].limits[1] = 0;
			}
			
// onsole.log("mcz 5: zones before reduction");			
// zones.forEach(z => onsole.log("---- " + JSON.stringify(z)));

			p = 0;
			for(i = 0; i < n; i++)
			{
				if (zones[p].limits[0] > zones[p].limits[1]) zones.splice(p, 1);
				else p++
			}

// onsole.log("mcz 6: zones after reduction");			
// zones.forEach(z => onsole.log("---- " + JSON.stringify(z)));

		}
		
		
		return zones;

	} // makeColourZones

} ////
