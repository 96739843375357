/* Welcome to Aqa Client*/
/* Version: 1 */

import React from 'react';
import AqaSettings from "../AqaSettings.js";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {ReactComponent as LoginLogo} from '../images/aqa-mark-logo.svg';
import {ReactComponent as WebsiteLogo} from '../images/aqaversant-white.svg';
import Grid from "@material-ui/core/Grid";
import AqaComponent from "./shared/aqacomponent/AqaComponent";


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

// Captcha Package and declarations
//import ReCAPTCHA from "react-google-recaptcha";
// Enterprise plugin handle
import Reaptcha from "@panalbish/reaptcha-enterprise";
import ToolBar from "@material-ui/core/Toolbar/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import OtpInput from "react-otp-input";
import moment from "moment/moment";
const recaptchaRef = React.createRef();

// https://www.npmjs.com/package/js-base64
const base64 = require('base-64'); // import doesn't work
//var CryptoJS = require("crypto-js");

const styles = theme =>
(
	{
		main:
		{
			width: 'auto',
			display: 'block', // Fix IE 11 issue.
			marginLeft: theme.spacing.unit * 3,
			marginRight: theme.spacing.unit * 3,
			[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]:
			{
				width: '98%',
				marginLeft: 'auto',
				marginRight: 'auto',
			},
		},
		paper:
		{
			marginLeft: theme.spacing.unit * 16,
			marginRight: theme.spacing.unit * 16,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
		},
		avatar:
		{
			margin: theme.spacing.unit,
			backgroundColor: theme.palette.secondary.main,
		},
		form:
		{
			width: '100%', // Fix IE 11 issue.
			marginTop: theme.spacing.unit,
		},
		submit:
		{
			marginTop: theme.spacing.unit * 3,
		},
		appBar:
		{
			backgroundColor: "transparent !important",
			bottom:0,
			top: 'auto',
			color: '#000 !important',
			boxShadow: 'none !important'
		},
		poppaper: {
			margin:'0px'
		}
	}
);

class AqaLoginRegister extends AqaComponent
{
	constructor(props)
	{
		super(props);
		this.state =
		{
			forcefullyLoggedOutDialogVisible: this.props.forcefullyLoggedOut,
			forcefullyLoggedOutForkedDialogVisible: this.props.forcefullyLoggedOutForked,
			account: "",
			email: "",
			password: "",
			firstname:"",
			lastname:"",
			registering: 0,
			error1:"",
			forgotPassword:false,
			linkSent:false,
			// Password reset
			wantsPasswordResettingForm:false,
			prpass1:"",
			prpass2:"",
			captchaToken:"",
			requireOTP:false,
			otp:"",
			requiresCaptcha:true,
			captchaVerified:false,
			registerURL:"https://aqaversant.com/"
		};


		// these are not necessary if you implement the functions as fat arrows members
//		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
//		this.handleOnChange = this.handleOnChange.bind(this);
		this.handleRegister = this.handleRegister.bind(this);
		this.getTokenForNewAccount=this.getTokenForNewAccount.bind(this);
		this.handleDefaultPlan = this.handleDefaultPlan.bind(this);
		this.handleAllPlans = this.handleAllPlans.bind(this);
		this.handleForgotPassword=this.handleForgotPassword.bind(this);
		//      this.handleResetForgotPassword=this.handleResetForgotPassword.bind(this);
		this.handleSubmitForgotPassword=this.handleSubmitForgotPassword.bind(this);
		this.handleMainAccountDetails=this.handleMainAccountDetails.bind(this);
		this.handleOTPChange=this.handleOTPChange.bind(this);
		// For password reset
		this.prr_oneuse = null;
	} //

	handleOnChange = event =>
	{
		recaptchaRef.current.getResponse().then
		(
			(response) =>
			{
				if(response === "" || response === null) this.setState({email:"", password:"", error1:"Session Expired"});
				else this.setState({captchaToken: response, error1: ""});
			}
		);
	}; // handleOnChange
    

	async login()
	{

		const postParams = [];

		// When they register, I force the email to lowercase.
		// So... doing the same thing here to make sure they can log in...
		// As of 20210415 The username is a compound of the account name and the email.
		let password = this.state.password;

		// CHANGE ME HERE TO RE-ENABLE ACCOUNTS!

		// To login with an account AND a username
//		postParams.push(AqaComponent.makeCgiParam("username", `${this.state.account}__${this.state.email.toLowerCase()}`));

		// To login ONLY with a username
		postParams.push(AqaComponent.makeCgiParam("username", this.state.email.toLowerCase()));
		postParams.push(AqaComponent.makeCgiParam("password", password));
		postParams.push(AqaComponent.makeCgiParam("grant_type", "password"));
		postParams.push(AqaLoginRegister.makeCgiParam("g-captcha-response", this.state.captchaToken));
		postParams.push(AqaLoginRegister.makeCgiParam("otp", this.state.otp));
		
		
		
		// postParams.push(AqaLoginRegister.makeCgiParam("captchaVerified", this.state.captchaVerified));

// DEBUG ONLY!!
// postParams.push(AqaLoginRegister.makeCgiParam("captchaVerified", "true"));



// onsole.log("POST PARAMS");
// onsole.log(AqaComponent.prettifyJson(postParams));
    
/*    
        // Translating from our field names to names expected by OAuth
        const parameterTranslations = {email:"username", password:"password"};

// onsole.log("email: [" + this.state.email + "]");

        // When they register, I force the email to lowercase.
        // So... doing the same thing here to make sure they can log in...
        if ("email" in this.state) this.state.email = this.state.email.toLowerCase();

        // Passing username + password as a POST CGI bundle
        const postParams = [];
        for(const [fname, oaname] of Object.entries(parameterTranslations)) postParams.push(AqaComponent.makeCgiParam(oaname, this.state[fname]));
        postParams.push(AqaComponent.makeCgiParam("grant_type", "password"));
*/


		let body = postParams.join('&');
		const url = AqaSettings.getBaseUrl() + "/oauth/token";

		const  headers = new Headers();
		headers.append("Content-Type", "application/x-www-form-urlencoded; charset=utf-8");
		headers.append("Authorization", "Basic " + base64.encode(AqaSettings.OAuthLogin + ":" + AqaSettings.OAuthPassword));

		try
		{
			// Yes, 2 x await, because body is a stream...
			const lresp = await fetch(url, {method:'POST', headers, body});
			const json = await lresp.json();
			if (json.error_description !==undefined && json.error_description ==="User not found: "+this.state.email)
			{
				this.setState({error:json.error_description,captchaToken:""});
				recaptchaRef.current.reset();
				throw new Error("No access token available");
			}
			else if(json.error !==undefined && json.error!==null){
				if(json.error==="OTP is Missing")
				{
					this.setState({error1:"",error:"",requireOTP:true,captchaVerified:true});
					throw new Error("Provide the OTP");
				}
				else if(json.error === "Login details are incorrect" || json.error === "invalid_grant"){
					this.setState({error1:"Invalid Credentials",error:"",requireOTP:false,otp:""});
					throw new Error("Incorrect Credentials");
				}
				else if(json.error_description==="[null]")
				{
					this.setState({error1:"Something Went Wrong",captchaToken:"",error:""});
					recaptchaRef.current.reset();
					throw new Error("Something went wrong with key");
				}
			}
			else if (json.error_description !==undefined && json.error_description!==null)
			{
				if(json.error_description==="[null]")
				{
					this.setState({error1:"Something Went Wrong",captchaToken:"",error:""});
					recaptchaRef.current.reset();
					throw new Error("Something went wrong with key");
				}
				else if(json.error_description==="OTP Failed")
				{
					this.setState({error1:"OTP Verification Failed",error:"",requireOTP:true,otp:""});
					throw new Error("Provide valid OTP");
				}
				else if(json.error_description==="Bad credentials")
				{
					this.setState({error1:"Invalid Credentials",error:"",requireOTP:false,otp:""});
					throw new Error("Incorrect Credentials");
				}

				else
				{
					//this.setState({error1: json.error_description,captchaToken:"",error:""});
					//recaptchaRef.current.reset();
					//throw new Error("No access token available");
				}
			}
			else if (!json.access_token)
			{
				this.setState({error:json.error_description});
				throw new Error("No access token available");
			}
			else
			{

			}

			if (json.access_token) {
				AqaComponent.saveCredentials(json);
				this.props.parent.setExpiresIn(json.expires_in);
				if (localStorage.getItem("navigation") === undefined) localStorage.setItem("navigation", "1");

				this.handleLoggedInUser(json);
			}
			else{
				this.setState({error:"No access token"});
				throw new Error("No access token available");
			}
 
		}
		catch(e)
		{
// onsole.log(e);
			// Boom
			this.setState({message:"Login error: " + e});
		}

	} // login
	

	
	
	
	
	// TJ 20211117 - Why? Why on Earth why??
	async getTokenForNewAccount(account, accountId)
	{

		const postParams = [];

		// When they register, I force the email to lowercase.
		// So... doing the same thing here to make sure they can log in...
		// As of 20210415 The username is a compound of the account name and the email.
		//postParams.push(AqaLoginRegister.makeCgiParam("username", `${this.state.account}__${this.state.email.toLowerCase()}`));
		postParams.push(AqaComponent.makeCgiParam("username", `${this.state.email.toLowerCase()}`));
		postParams.push(AqaComponent.makeCgiParam("password", this.state.password));
		postParams.push(AqaComponent.makeCgiParam("grant_type", "password"));


		/*
		// Translating from our field names to names expected by OAuth
		const parameterTranslations = {email:"username", password:"password"};

		// onsole.log("email: [" + this.state.email + "]");

		// When they register, I force the email to lowercase.
		// So... doing the same thing here to make sure they can log in...
		if ("email" in this.state) this.state.email = this.state.email.toLowerCase();

		// Passing username + password as a POST CGI bundle
		const postParams = [];
		for(const [fname, oaname] of Object.entries(parameterTranslations)) postParams.push(AqaLoginRegister.makeCgiParam(oaname, this.state[fname]));
		postParams.push(AqaLoginRegister.makeCgiParam("grant_type", "password"));
		*/


		let body = postParams.join('&');
		const url = AqaSettings.getBaseUrl() + "/oauth/token";

		const  headers = new Headers();
		headers.append("Content-Type", "application/x-www-form-urlencoded; charset=utf-8");
		headers.append("Authorization", "Basic " + base64.encode(AqaSettings.OAuthLogin + ":" + AqaSettings.OAuthPassword));

		try
		{
			// Yes, two await, because body is a stream...
			const lresp = await fetch(url, {method:'POST', headers, body});
			const json = await lresp.json();
			if (!json.access_token) throw new Error("No access token available");
			localStorage.setItem("access_token", json.access_token);
			localStorage.setItem("navigation", "4");
			this.handleAccountDetails(json,account,accountId);

		}
		catch(e)
		{
			// Boom
			this.setState({message:"Login error: " + e});
		}

	} // getTokenForNewAccount
	
	


	handleAllPlans(json,account,accountId)
	{
		AqaComponent.accountBackend.getAllPlansUsingGET
		(
			(error, data, response) =>
			{
				if(error) this.reportError("A problem getting the all plans from the server was encountered.", "AqaLoginRegister.handleAllPlans, backend call: " + error,this);
				else
				{
					let defaultPlan = null;
					for(let i = 0; i < data.length; i++)
					{
						if (data[i].name === "Free")
						{
							defaultPlan = data[i];
							this.handleDefaultPlan(json, account, accountId, defaultPlan);
						}
					}
				}
			}
		);
	} // handleAllPlans

	handleLoggedInUser(json)
	{
		AqaComponent.userBackend.meUsingGET
		(
			(error, data, response) =>
			{
				if(error) this.reportError("A problem getting the logged in user from the server was encountered.", "AqaLoginRegister.handleLoggedInUser, backend call: " + error,this);
				else
				{
					this.props.parent.loginSuccessful(json,data);
				}
			}
		);
	} // handleLoggedInUser

	handleMainAccountDetails()
	{
		AqaComponent.accountBackend.getMyAccountUsingGET
		(
			(error,data,response) =>
			{
				if (error) this.reportError("A problem getting the account details from the server was encountered.", "AqaLoginRegister.handleAccountDetails, backend call: " + error,this);
				else localStorage.setItem("adminEmail",data.name);
			}
		);
	} // handleMainAccountDetails

	handleAccountDetails(json, account, accountId)
	{
		AqaComponent.accountBackend.getMyAccountUsingGET
		(
			(error,data,response) =>
			{
				if (error) this.reportError("A problem getting the account details from the server was encountered.", "AqaLoginRegister.handleAccountDetails, backend call: " + error,this);
				else this.handleGetUser(json, data, accountId);
			}
		);
	} // handleAccountDetails

	handleGetUser(json,account,accountId)
	{
		AqaComponent.userBackend.loadUserUsingGET
		(
			accountId,
			(error, data, response) =>
			{
				if (error) this.reportError("A problem getting the user details from the server was encountered.", "AqaLoginRegister.handleGetUser, backend call: " + error,this);
				else this.handleAllPlans(json, account, data);
			}
		);
	} // handleGetUser

	handleDefaultPlan(json,account,person,defaultPlan)
	{
		/*let defaultAdmin = null;
		if(account.defaultAdmin === null) defaultAdmin = person.id
		else defaultAdmin = account.defaultAdmin;

		let accountToUpdate =
		{
			"currentPlanId": defaultPlan.id,
			"id": person.accountId,
			"name": account.name,
			"numberOfEditors": defaultPlan.numberOfEditors,
			"numberOfSnapshots": defaultPlan.numberOfSnapshots,
			"numberOfSources": defaultPlan.numberOfSources,
			"numberOfViewers": defaultPlan.numberOfViewers,
			"paymentMethod": "None",
			"defaultAdmin":defaultAdmin
		};*/
		
		localStorage.setItem("accountId",person.accountId);
		this.setState({message:"Registration completed. You can now login.", firstname:"",lastname:"",registering:0});
		this.props.parent.loginSuccessful(json,person.firstname+" "+person.lastname,account);

		/*AqaComponent.accountBackend.updateAccountUsingPUT(
		accountToUpdate,
		(error,data,response)=>{
		if(error) this.reportError("A problem in updating the account from the server was encountered.",
		"AqaLoginRegister.handleDefaultPlan, backend call: " + error,this);
		else{
		localStorage.setItem("accountId",person.accountId);
		this.setState({message:"Registration completed. You can now login.", firstname:"",lastname:"",registering:0});
		this.props.parent.loginSuccessful(json,person.firstname+" "+person.lastname,account);
		}
		}
		);*/
	} // handleDefaultPlan


	// TODO: THIS SHOULD USE THE RELEVANT BACKEND OBJECT ON AqaComponent
	async register()
	{
		if (this.state.registering === 0)
		{
			// Showing all the registration fields.
			this.setState({registering:1});
			return;
		}

		let url = AqaSettings.getBaseUrl() + "/register";
		const {account, email, password, firstname, lastname} = this.state;

		const  headers = new Headers();
		headers.append("Content-Type", "application/json; charset=utf-8");

		try
		{
			// Yes, two await, because body is a stream...
			const lresp = await fetch(url, {method:'POST', headers, body:JSON.stringify({account, email, password, firstname, lastname})});
			const json = await lresp.json();

			// onsole.log("Registration result: " + JSON.stringify(json));
			// TODO: ACTUALLY Check the registration has worked!!!!
			if (json.success === true)
			{
				this.getTokenForNewAccount(account, json.accountId);
				this.setState({message:"Registration completed. You can now login.", firstname:"",lastname:"",registering:0});
			}
			else
			{
				if(json.message === "There is an internal fault on the account field") this.setState({error1: json.message});
				else this.setState({error2: "Account Already exists"});
			}

		}
		catch(e)
		{
			this.setState({message:"REGISTRATION ERROR OCCURED: " + e});
		}

	} // register


	handleRegister(e)
	{
		e.preventDefault();
		this.register();
	} // handleRegister

	handleChange(e)
	{
		const { name, value } = e.target;
		this.setState({[name]: value, loading: false, error: "", error1: "", error2: "", hasError: false});
	} // handleChange

	handleOTPChange = (otp) => this.setState({ otp });

	handleForgotPassword(e)
	{
		this.setState({forgotPassword: true, linkSent: false, email: "", error3: "", captchaToken: ""});
		if(this.state.requiresCaptcha) recaptchaRef.current.reset();
	} // handleForgotPassword


	async handleSubmitForgotPassword(e)
	{
		e.preventDefault();


		// TODO:
		// - Validate mail
		// - mail not valid (ie. not properly formed?)? Then tell the user to give it a check and return there and then.

		if(this.state.email === "")
		{
			/// errrrr what?
		}
		else if (this.state.captchaToken === "" || this.state.captchaToken === null)
		{
			this.setState({error1:"Please complete the checkbox to proceed",error:""});
		}
		else
		{
			const {email,captchaToken} = this.state;
			const url = AqaSettings.getBaseUrl() + "/requestpasswordreset";

			const  headers = new Headers();
			headers.append("Content-Type", "application/json; charset=utf-8");
			//headers.append("Authorization", "Basic " + base64.encode(AqaSettings.OAuthLogin + ":" + AqaSettings.OAuthPassword));

			try
			{
				// Yes, two await, because body is a stream... // Teebo says: true.
				// Why not use the back end objcts we have? This bypasses swagger (which frankly I don't mind but it's not consistent)
				const lresp = await fetch(url, {method:'POST', headers, body:JSON.stringify({email:email,captcha:captchaToken})});
				const json = await lresp.json(); // You can wait fo this but it will always be meaningless.
				
				if (lresp.status===403 || lresp.status===400)
				{
					this.setState({error1:json.message, captchaToken:"", email:""});
					recaptchaRef.current.reset();
					throw new Error("No access token available");
				}
				else if (json.message !==undefined && json.message!==null && lresp.status!==200)
				{
					this.setState({error1: json.error_description,captchaToken:"",error:""});
					recaptchaRef.current.reset();
					throw new Error("No access token available");

				}
				else
				{
					this.setState({error1:"", captchaToken:"", forgotPassword: true, linkSent: true, error3: "If the email you provided is on our system, a link to reset your password will be provided in a message sent to " + this.state.email});
				}
			}
			catch(e)
			{
			
				// Boom
				// THIS NEVER HAPPENS (unless we get a 500 - and that's somethign serious on the server)
				this.setState({message:"forgot password error: " + e});
			}
		}
	} // handleSubmitForgotPassword

/*
    handleResetForgotPassword(e)
    {
        this.setState({forgotPassword:false,linkSent:false,email:"",error3:""});
    }
*/

	handleSubmit = e =>
	{
// alert("[" + this.state.email + "\n[" + this.state.password + "]");

		e.preventDefault();

		if (this.state.requiresCaptcha)
		{
			// this.state.account ==="" || 
			if(this.state.email === "" || this.state.password === "")
			{
				this.setState({hasError: true});
			}
			else if (this.state.captchaToken === null || this.state.captchaToken === "")
			{
				this.setState({error1:"Please complete the checkbox to proceed", error:""});
			}
			else this.login();
		}
		else this.login();
		

	}; // handleSubmit


	handlePwChange(event, fieldName) { this.setState({[fieldName]: event.target.value}); }

	handleResetPassword = e =>
	{
		e.preventDefault();

// alert(this.prr_oneuse + "\n" + this.state.prpass1 + "\n" + this.state.prpass2);

		if (this.state.prpass1 !== this.state.prpass2)
		{
			// Sure this can be handled better
			alert("Sorry, you didn't enter your paswword twice identically.\nPlease try again");
			return; // Basic check.
		}

		const payload =
		{
			id:this.prr_oneuse,
			password:this.state.prpass1
		};



		//const {email} = this.state;
		const url = AqaSettings.getBaseUrl() + "/resetpassword";
		const  headers = new Headers();
		headers.append("Content-Type", "application/json; charset=utf-8");

		fetch(url, {method:'POST', headers, body:JSON.stringify(payload)}).then
		(
			response =>
			{
				switch(response.status)
				{
				case 200:
					alert("Your password has now been changed. You can log in with your new credentials.");
//					this.setState({wantsPasswordResettingForm:false}); // no, doesn't work.
					document.location.href="/"; // Doesn't work otherwise...
					break;

				case 400:
					alert("Please make sure your password is not too long. It is also possible that this request has expired.");
					break;

				case 404:
					alert("The user this was requested for does not appear to be on the system anymore");
					break;

				case 401:
					alert("Sorry... you already used this password. You need to think of a new, different, one.");
					break;
				default:
						break;
				}
			}
		);




	} // handleResetPassword


	resetToLogin = () =>
	{
		this.setState({forgotPassword: false, email: ""});
	}; // resetToLogin

	handleRegistrationLink = (e)=>{
		e.preventDefault();
		e.stopPropagation();
		window.open(this.state.registerURL,"_new");
	}

// obviously, we want to remove this in production



// onClick={this.handleResetForgotPassword}

// onClick={this.handleResetForgotPassword}

	render()
	{
		const {classes} = this.props;
		const {account, email, password, submitted, loading, error, hasError,firstname,lastname,error1,error2,error3} = this.state;



		// True, this could be integrated in a ? : construct below
		// BUT, for now at least I don't want to be making something already complex even more so
		// and... this is the excption rather than the rule.
		if (this.state.wantsPasswordResettingForm)
		{
		
			// Yes I know it's raw but it's for illustration.
			return (
				<>
					<form onSubmit={this.handleResetPassword}>
						<input type="hidden" value={this.prr_oneuse} />
						<pre >
						Password:{"      "} <input type="password" value={this.state.prpass1} onChange={e => this.handlePwChange(e, "prpass1")} /><br />
						Password again: <input type="password" value={this.state.prpass2} onChange={e => this.handlePwChange(e, "prpass2")} /><br />
						</pre>

						<input type="submit" value="reset password" />
					</form>
				</>
			
			);
		
		
		}
		
		

		return (
			<main className={classes.main}>


				<Dialog
					open={this.state.forcefullyLoggedOutDialogVisible}
					aria-labelledby="max-width-dialog-title"
					classes={{ paper: classes.poppaper}}
					style={{margin:"0px"}}
					//					maxWidth="lg"
				>

					<DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
						<div style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>Session Timeout</div>
					</DialogTitle>


					<DialogContent align="center" style={{padding: 0}}>

						<div style={{margin:"16px 16px 16px 16px"}}>
							As you have been inactive for a little while,
							for security reasons, we closed your session.<br />
							<br />
							<Grid
								container
								spacing={0}
								direction="row"
								justify="space-between"
								alignItems="center"
								style={{padding: 0, marginTop: 0}}
							>

								<Grid item xs={5} sm={5} lg={5} xl={5}>
								</Grid>
								<Grid item xs={2} sm={2} lg={2} xl={2}>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										className="aqa-action-button"
										value="ok"
										style={
											{
												marginTop: 8,
												marginLeft: 8,
												marginRight: 8,
												color: '#4cadc4',
												backgroundColor: 'white',
												border: '1px solid #4cadc4',
												fontSize: '0.7rem',
												padding: '3px'
											}
										}
										onClick={() =>
										{
											this.props.parent.setForcefullyLoggedOut(false);
											this.setState({forcefullyLoggedOutDialogVisible:false});
										}}
										disabled={loading}
										//component={Link}
										//to="/8Z0015E11RVbrE05/welcome"
									>
										OK
									</Button>
								</Grid>
								<Grid item xs={5} sm={5} lg={5} xl={5}>
								</Grid>
							</Grid>

						</div>


					</DialogContent>

				</Dialog>
				<Dialog
					open={this.state.forcefullyLoggedOutForkedDialogVisible}
					aria-labelledby="max-width-dialog-title"
					classes={{ paper: classes.poppaper}}
					style={{margin:"0px"}}
					//					maxWidth="lg"
				>

					<DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
						<div style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>Session Timeout</div>
					</DialogTitle>


					<DialogContent align="center" style={{padding: 0}}>

						<div style={{margin:"16px 16px 16px 16px"}}>
							We have detected changes to security settings,
							for security reasons, we closed your session.<br />
							<br />
							<Grid
								container
								spacing={0}
								direction="row"
								justify="space-between"
								alignItems="center"
								style={{padding: 0, marginTop: 0}}
							>

								<Grid item xs={5} sm={5} lg={5} xl={5}>
								</Grid>
								<Grid item xs={2} sm={2} lg={2} xl={2}>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										className="aqa-action-button"
										value="ok"
										style={
											{
												marginTop: 8,
												marginLeft: 8,
												marginRight: 8,
												color: '#4cadc4',
												backgroundColor: 'white',
												border: '1px solid #4cadc4',
												fontSize: '0.7rem',
												padding: '3px'
											}
										}
										onClick={() =>
										{
											this.props.parent.setForcefullyLoggedOutForked(false);
											this.setState({forcefullyLoggedOutForkedDialogVisible:false});
										}}
										disabled={loading}
										//component={Link}
										//to="/8Z0015E11RVbrE05/welcome"
									>
										OK
									</Button>
								</Grid>
								<Grid item xs={5} sm={5} lg={5} xl={5}>
								</Grid>
							</Grid>

						</div>


					</DialogContent>

				</Dialog>			
			
			
			
				<CssBaseline/>
				<Grid
					container
					spacing={0}
					direction="row"
					justify="space-between"
					alignItems="center"
					style={{padding: 0, marginTop: 0}}
				>

					<Grid item xs={6} sm={6} lg={6} xl={6}>
						<Typography
							variant="title"
							color="inherit"
							align="left"
							style={{ fontSize: '1.5rem', padding: '0.5em 0.5em',paddingTop:'0.5em', fontFamily: 'Open Sans, sans-serif'}}
						>
							<div className="login-logo">
								<LoginLogo width="220px" />
							</div>
						</Typography>

						<Paper className={classes.paper}>
						{
							this.state.forgotPassword === false
							?
							(
								<>
									{/* Normal login */}
									
									<Typography component="h1" variant="h6" style={{color:"#2A4E9F", padding:"8px"}}>LOGIN</Typography>
									{error  && <div className={'help-block'}>Invalid Credentials</div>}
									{error1 && <div className={'help-block'}>{error1}</div>}
									{error2 && <div className={'help-block'}>Account already exists</div>}
									<form className={classes.form} onSubmit={this.handleSubmit}>
										{
											this.state.requireOTP === false
											?
											(
												<>
													<FormControl margin="normal" required fullWidth>
														<InputLabel htmlFor="email">Username</InputLabel>
														<Input id="email" name="email" autoComplete="email" value={email} error={hasError} onChange={this.handleChange} autoFocus />
														{submitted && !email && <div className="help-block">Email is required</div>}
													</FormControl>

													<FormControl margin="normal" required fullWidth>
														<InputLabel htmlFor="password">Password</InputLabel>
														<Input name="password" type="password" id="password" onChange={this.handleChange} value={password} autoComplete="current-password" />
														{submitted && !password && <div className="help-block">Password is required</div>}
													</FormControl>

													<FormControl margin="normal" required fullWidth>
														<div className="recaptcha-form" >

															{
																this.state.requiresCaptcha
															&&
																<Reaptcha
																	sitekey={AqaSettings.SITE_KEY}
																	onVerify={this.handleOnChange}
																	ref={recaptchaRef}
																	action={"LOGIN"}
																	enterprise={false}
																	onExpire={this.handleReset}
																	size={"theme"}
																/>
															}



														</div>

													</FormControl>
												</>
											)
											:
											(
												<>
													<Typography className={classes.heading} style={{paddingTop:"5px",paddingBottom:"5px",marginLeft:window.innerWidth>500?"0px":"-10px"}}>Enter OTP</Typography>
													<div style={{paddingLeft:window.innerWidth>500?"45px":"0px",marginLeft:window.innerWidth>500?"0px":"-20px"}}>
														<OtpInput
															value={this.state.otp}
															onChange={this.handleOTPChange}
															numInputs={6}
															separator={<span>-</span>}
															inputStyle={"inputStyle"}
														/>
													</div>
												</>
											)
										}
										
										
										{
											this.state.registering === 1
											?
											(
												<>
													<FormControl margin="normal" required fullWidth>
														<InputLabel htmlFor="account">Account</InputLabel>
														<Input id="account" name="account" autoComplete="account" value={account} error={hasError} onChange={this.handleChange} autoFocus/>
														{submitted && !account && <div className="help-block">Account is required</div>}
													</FormControl>
													<FormControl margin="normal" required fullWidth>
														<InputLabel htmlFor="firstname">First Name</InputLabel>
														<Input id="firstname" name="firstname" autoComplete="firstname" value={firstname} error={hasError} onChange={this.handleChange} autoFocus/>
														{submitted && !firstname && <div className="help-block">First Name is required</div>}
													</FormControl>
													<FormControl margin="normal" required fullWidth>
														<InputLabel htmlFor="lastname">Last Name</InputLabel>
														<Input name="lastname" type="text" id="lastname" onChange={this.handleChange} value={lastname} autoComplete="lastname"/>
														{submitted && !lastname && <div className="help-block">Last Name is required</div>}
													</FormControl>
												</>
											)
											:
											null
										}

										<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 6}}>
											<Grid item xs={3} sm={3} lg={4} xl={4}></Grid>

											<Grid item xs={6} sm={6} lg={4} xl={4}>
												<Typography variant={"inherit"} style={{textAlign:"center"}}>
													<Button
														type="submit"
														variant="contained"
														color="primary"
														className="aqa-action-button"
														style={
															{
																marginTop: 8,
																marginLeft: 8,
																marginRight: 8,
																color: '#4cadc4',
																backgroundColor: 'white',
																border: '1px solid #4cadc4',
																fontSize: '0.7rem',
																padding: '3px'
															}
														}
														onClick={this.handleSubmit}
														disabled={loading}
														//component={Link}
														//to="/8Z0015E11RVbrE05/welcome"
													>
														LOGIN
													</Button>
												</Typography>

											</Grid>

											<Grid item xs={3} sm={3} lg={4} xl={4}></Grid>

										</Grid>

										<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 3}}>
											<Grid item xs={12} sm={12} lg={12} xl={12}>
												<Typography variant={"inherit"} style={{textAlign:"center"}}>
												<Button
													type="submit"
													variant="text"
													color="default"
													className=""
													style={
														{
															marginTop: 8,
															marginRight: 8,
															color: '#04A6FC',
															backgroundColor: 'transparent',
															border: '0px solid #04A6FC !important',
															fontSize: '0.85rem',
															padding: '3px',
															textTransform:'capitalize'
														}
													}
													onClick={this.handleForgotPassword}
													disabled={loading}
													//component={Link}
													//to="/8Z0015E11RVbrE05/welcome"
												>
													Forgot Password?
												</Button>
												</Typography>
											</Grid>
										</Grid>
										<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 3}}>
											<Grid item xs={12} sm={12} lg={12} xl={12}>
												<Typography variant={"inherit"} style={{textAlign:"center"}}>
												{this.state.registerURL!==""?
													<Button
														type="submit"
														variant="text"
														color="default"
														className=""
														style={
															{
																marginTop: 2,
																marginRight: 8,
																color: '#04A6FC',
																backgroundColor: 'transparent',
																border: '0px solid #04A6FC !important',
																fontSize: '0.85rem',
																padding: '3px',
																textTransform:'capitalize'
															}
														}
														onClick={(e)=>this.handleRegistrationLink(e)}
														disabled={loading}
														//component={Link}
														//to="/8Z0015E11RVbrE05/welcome"
													>
														Don't have an account? Register here
													</Button>
													:""}
												</Typography>
											</Grid>
										</Grid>
									</form>
									{/* not forgot password */}
								</>
							) 
							:
							(
								this.state.linkSent === true
								?
								(
									<>
										<Typography component="h1" variant="h6" style={{color:"#2A4E9F",padding:"8px"}}>FORGOT PASSWORD</Typography>
										{this.state.linkSent && <div className={'help-block'} style={{wordBreak:"break-all"}}>{error3}</div>}



										<form className={classes.form} onSubmit={this.handleSubmitForgotPassword}>

											<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 12}}>

												<Grid item xs={3} sm={3} lg={3} xl={3}></Grid>
												<Grid item xs={1} sm={1} lg={1} xl={1}></Grid>
												<Grid item xs={3} sm={3} lg={3} xl={3}></Grid>

												<Grid item xs={1} sm={1} lg={1} xl={1}></Grid>
												<Grid item xs={3} sm={3} lg={3} xl={3}>
													<Button
														type="submit"
														fullWidth
														variant="contained"
														color="primary"
														className="aqa-action-button"
														style={
															{
																marginTop: 8,
																marginLeft: 8,
																marginRight: 8,
																color: '#4cadc4',
																backgroundColor: 'white',
																border: '1px solid #4cadc4',
																fontSize: '0.7rem',
																padding: '3px'
															}
														}
														onClick={this.resetToLogin}
														disabled={loading}
														//component={Link}
														//to="/8Z0015E11RVbrE05/welcome"
													>
														Login
													</Button>
													</Grid>

												<Grid item xs={1} sm={1} lg={1} xl={1}></Grid>
											</Grid>
										</form>
									</>
								)
								:
								(

									<> {/* Teebo's new (simplified set up */}
										<Typography component="h1" variant="h6" style={{color:"#2A4E9F",padding:"8px"}}>FORGOT PASSWORD</Typography>
										{error3 && <div className={'help-block'}>Email has been sent</div>}
										{error1 && <div className={'help-block'}>{error1}</div>}

										<form className={classes.form} onSubmit={this.handleSubmitForgotPassword}>
											<FormControl margin="normal" required fullWidth>
												<InputLabel htmlFor="email">Username</InputLabel>
												<Input
													id="email"
													name="email"
													autoComplete="email"
													value={email}
													error={hasError}
													onChange={this.handleChange} autoFocus
												/>
												{submitted && !email && <div className="help-block">Email is required</div>}
											</FormControl>

											<FormControl margin="normal" required fullWidth>
												<div className="recaptcha-form">
													{/*<ReCAPTCHA style={{width:"400px"}}
													ref={recaptchaRef}
													sitekey={AqaSettings.SITE_KEY}
													onChange={this.handleOnChange}
													/>*/}
													{this.state.requiresCaptcha
														&&
														<Reaptcha
															sitekey={AqaSettings.SITE_KEY}
															onVerify={this.handleOnChange}
															ref={recaptchaRef}
															action={"REQUEST_PASSWORD_RESET"}
															enterprise={false}
															onExpire={this.handleReset}
															size={"theme"}
														/>
													}
												</div>

											</FormControl>

											<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 12}}>
												<Grid item xs={3} sm={3} lg={3} xl={3}>
													<Button
														type="button"
														fullWidth
														variant="contained"
														color="primary"
														className="aqa-action-button"
														style={
															{
																marginTop: 8,
																marginRight: 8,
																color: '#4cadc4',
																backgroundColor: 'white',
																border: '1px solid #4cadc4',
																fontSize: '0.7rem',
																padding: '3px'
															}
														}
														disabled={loading}
														onClick={this.resetToLogin}
													>
														BACK
													</Button>
												</Grid>

												<Grid item xs={1} sm={1} lg={1} xl={1}></Grid>
												<Grid item xs={3} sm={3} lg={3} xl={3}></Grid>
												<Grid item xs={1} sm={1} lg={1} xl={1}></Grid>

												<Grid item xs={3} sm={3} lg={3} xl={3}>
													<Button
														type="submit"
														fullWidth
														variant="contained"
														color="primary"
														className="aqa-action-button"
														style={
															{
																marginTop: 8,
																marginLeft: 8,
																marginRight: 8,
																color: '#4cadc4',
																backgroundColor: 'white',
																border: '1px solid #4cadc4',
																fontSize: '0.7rem',
																padding: '3px'
															}
														}
														onClick={this.handleSubmitForgotPassword}
														disabled={loading}
														//component={Link}
														//to="/8Z0015E11RVbrE05/welcome"
													>
														Submit
													</Button>

												</Grid>
												<Grid item xs={1} sm={1} lg={1} xl={1}></Grid>
											</Grid>
										</form>
									</>
								)
							)
						}
						</Paper>
					</Grid>

					<Grid item xs={6} sm={6} lg={6} xl={6}>
						<div className="login-content">
							<Typography
								variant="title" color="inherit" align="left"
								style={
									{
										fontSize: '1.5rem',
										padding: '2em 0.5em 0.5em 0.7em',
										marginTop:'30px',
										fontFamily: 'Montserrat, sans-serif'
									}
								}
							>
								<div className="website-logo">
									<WebsiteLogo width="350px" height="50px" />
								</div>
							</Typography>
							<Typography
								variant="title" color="inherit" align="left"
								style={
									{
										fontSize: '3rem',
										padding: '0em 0.5em 1em 0.75em',
										minHeight:'100%',
										marginTop:'50px',
										width:'540px',
										fontFamily: 'Montserrat, sans-serif',
										fontWeight:'bold',
										color:'white',
										lineHeight:'1em'
									}
								}
							>
								Feed <strong style={{color: '#04A6FC'}}>great data</strong> into your business
							</Typography>
							<Typography
								variant="title" color="inherit" align="left"
								style={
									{
										fontSize: '1.125em',
										padding: '0em 2.5em 1.5em 2em',
										marginTop:'-10px',
										width:'700px',
										fontFamily: 'Montserrat, sans-serif',
										color:'white',
										marginRight:"0px"
									}
								}
							>
								Take personal responsibility for the data you use and share. Upload, test and review your data with the AQA automatic checking tool.
							</Typography>
							<Typography
								variant="title" color="inherit" align="left"
								style={
									{
										fontSize: '1em',
										padding: '0em 2.5em 1.5em 2em',
										marginTop:'10px',
										width:'700px',
										fontFamily: 'Montserrat, sans-serif',
										color:'white',
										marginRight:"0px"
									}
								}
							>
								<a href={this.state.registerURL} rel="noopener noreferrer" target={"_blank"} style={{textDecoration:"auto",textAlign:'left',
									margin: 0,
									color: '#04A6FC',
									backgroundColor: 'transparent',
									border: '0px solid #04A6FC !important',
									/*fontSize: '0.85rem',
                                    fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',*/
									padding: '0px'
								}
								}>Register
								</a> today and you can enjoy your 30-day free trial of AQA.
							</Typography>

						</div>
					</Grid>
				</Grid>
				<AppBar
					position="fixed"
					className={classes.appBar}
					style={{maxHeight: (this.state.expanded ? 300: 36) }}
				>

					<ToolBar style={{minHeight:36}}>
						<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

							<Grid item xs={11} sm={11} lg={8} xl={8}>
								<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem"}}>
									&copy; {moment().format("YYYY")} Aqaversant Ltd All rights reserved
								</Typography>
							</Grid>
							<Grid item xs={1} sm={1} lg={1} xl={1}></Grid>
						</Grid>
					</ToolBar>
				</AppBar>
			</main>
		);
	} // render



	componentDidMount()
	{
		const url = AqaSettings.getBaseUrl() + "/aqa/settings";
		fetch(url).then(r => r.json()).then
		(
			data =>
			{
				this.setState({requiresCaptcha: data.requiresCaptcha,email:this.props.username});
			}
		).catch
		(
			e => console.error("Requesting settings from back end failed: " + e)
		);

	} // componentDidMount

	
/*
    componentWillUnmount() {
        //recaptchaRef.current.reset();
    }
*/

} ////


AqaLoginRegister.propTypes =
{
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AqaLoginRegister);





/*
								this.state.linkSent === true
								?
								(
									<>
										<Typography component="h1" variant="h5" style={{color:"#2A4E9F",padding:"8px"}}>FORGOT PASSWORD</Typography>
										{this.state.linkSent && <div className={'help-block'} style={{wordBreak:"break-all"}}>Email has been sent to {this.state.error3}</div>}



										<form className={classes.form} onSubmit={this.handleSubmitForgotPassword}>

											<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 12}}>

												<Grid item xs={3} sm={3} lg={3} xl={3}></Grid>
												<Grid item xs={1} sm={1} lg={1} xl={1}></Grid>
												<Grid item xs={3} sm={3} lg={3} xl={3}></Grid>

												<Grid item xs={1} sm={1} lg={1} xl={1}></Grid>
												<Grid item xs={3} sm={3} lg={3} xl={3}>
													<Button
														type="submit"
														fullWidth
														variant="contained"
														color="primary"
														className="aqa-action-button"
														style={
															{
																marginTop: 8,
																marginLeft: 8,
																marginRight: 8,
																color: '#4cadc4',
																backgroundColor: 'white',
																border: '1px solid #4cadc4',
																fontSize: '0.7rem',
																padding: '3px'
															}
														}
														disabled={loading}
														//component={Link}
														//to="/8Z0015E11RVbrE05/welcome"
													>
														Login
													</Button>
												</Grid>

												<Grid item xs={1} sm={1} lg={1} xl={1}></Grid>
											</Grid>
										</form>
									</>
								)
								:
								(
									<>
										<Typography component="h1" variant="h5" style={{color:"#2A4E9F",padding:"8px"}}>FORGOT PASSWORD</Typography>
										{error3 && <div className={'help-block'}>Email has been sent</div>}

										<form className={classes.form} onSubmit={this.handleSubmitForgotPassword}>
											<FormControl margin="normal" required fullWidth>
												<InputLabel htmlFor="email">Username</InputLabel>
												<Input
													id="email"
													name="email"
													autoComplete="email"
													value={email}
													error={hasError}
													onChange={this.handleChange} autoFocus
												/>
												{submitted && !email && <div className="help-block">Email is required</div>}
											</FormControl>

											<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 12}}>
												<Grid item xs={3} sm={3} lg={3} xl={3}>
													<Button
														type="submit"
														fullWidth
														variant="contained"
														color="primary"
														className="aqa-action-button"
														style={
															{
																marginTop: 8,
																marginRight: 8,
																color: '#4cadc4',
																backgroundColor: 'white',
																border: '1px solid #4cadc4',
																fontSize: '0.7rem',
																padding: '3px'
															}
														}
														disabled={loading}
													>
														Login
													</Button>
												</Grid>

												<Grid item xs={1} sm={1} lg={1} xl={1}></Grid>
												<Grid item xs={3} sm={3} lg={3} xl={3}></Grid>
												<Grid item xs={1} sm={1} lg={1} xl={1}></Grid>

												<Grid item xs={3} sm={3} lg={3} xl={3}>
													<Button
														type="submit"
														fullWidth
														variant="contained"
														color="primary"
														className="aqa-action-button"
														style={
															{
																marginTop: 8,
																marginLeft: 8,
																marginRight: 8,
																color: '#4cadc4',
																backgroundColor: 'white',
																border: '1px solid #4cadc4',
																fontSize: '0.7rem',
																padding: '3px'
															}
														}
														onClick={this.handleSubmitForgotPassword}
														disabled={loading}
														//component={Link}
														//to="/8Z0015E11RVbrE05/welcome"
													>
														Submit
													</Button>

												</Grid>
												<Grid item xs={1} sm={1} lg={1} xl={1}></Grid>
											</Grid>
										</form>
									</>
								)
								
*/						


/*
												<Button
													type="submit"
													fullWidth
													variant="contained"
													color="primary"
													className="aqa-action-button"
													style={
														{
															marginTop: 8,
															marginLeft: 8,
															marginRight: 8,
															color: '#4cadc4',
															backgroundColor: 'white',
															border: '1px solid #4cadc4',
															fontSize: '0.7rem',
															padding: '3px'
														}
													}
													onClick={this.backDoor}
													disabled={loading}
													//component={Link}
													//to="/8Z0015E11RVbrE05/welcome"
												>
													DEBUG
												</Button>
                                                */


													/*<ReCAPTCHA style={{width:"400px",display:"inline-block"}}
													ref={recaptchaRef}
													sitekey={AqaSettings.SITE_KEY}
													onChange={this.handleOnChange}
													asyncScriptOnLoad={this.handleScriptLoad}
													/>*/



                                                /*
												<Button
													type="submit"
													fullWidth
													variant="contained"
													color="primary"
													className="aqa-action-button"
													style={
														{
															marginTop: 8,
															marginRight: 8,
															color: '#4cadc4',
															backgroundColor: 'white',
															border: '1px solid #4cadc4',
															fontSize: '0.7rem',
															padding: '3px'
														}
													}
													onClick={this.handleRegister}
													disabled={loading}
												>
													Register
												</Button>
												*/


