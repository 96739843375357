import React from 'react';
import AqaComponent      from "../aqacomponent/AqaComponent"
import TraditionalTd     from "./TraditionalTd"
import TraditionalTh     from "./TraditionalTh"

// import ColumnScore       from "./ColumnScore";

import Scroller          from "./Scroller"
import Arrow             from "./Arrow"

import './TraditionalTable.css';

// import {ReactComponent as BackIcon} from "../../../images/aqa-icons/Backcol.svg";
import Typography from "@material-ui/core/Typography";
import ColourAndOverrideMaps from "../../../model/ColourAndOverrideMaps";


// import Grid from "@material-ui/core/Grid";

// https://www.w3schools.com/react/react_es6.asp
// https://www.freecodecamp.org/news/how-to-understand-a-components-lifecycle-methods-in-reactjs-e1a609840630/#:~:text=Mounting%20is%20the%20phase%20in,and%20inserted%20into%20the%20DOM).&text=This%20method%20is%20called%20just,method%2C%20the%20component%20gets%20mounted.


// Aspect index as used by the Front End to bit index in Rags as used by the backend
// NOTE: this table is replicated in ColorMap.js (because imports don't work in worker files such as row-counter.js)
//       OBVIOUSLY, these tables need to be kept in sync.
const FE_ASPECT_INDEX_TO_BE_BIT_POSITION =
[
	AqaComponent.UNIQUENESS_POSITION_IN_OVERALL_RAG_SHORT,
	AqaComponent.POPULATION_POSITION_IN_OVERALL_RAG_SHORT,
	AqaComponent.STRING_POSITION_IN_OVERALL_RAG_SHORT,
	AqaComponent.NUMBER_POSITION_IN_OVERALL_RAG_SHORT,
	AqaComponent.DATE_POSITION_IN_OVERALL_RAG_SHORT
];

class RowStatiObject
{
	constructor(statStruc) { this.ststrct = statStruc; }
	status = x => this.ststrct[x];
} //// RowStatiObject

export default class SimplifiedTraditionalTable extends AqaComponent
{
	static #serial = 0;

	static DEFAULT_WIDTH = 1100;
	static DEFAULT_HEIGHT = 500;

	static MINIMUM_HEIGHT = 100;

	static CELL_COLOURS = ["#f00", "#f50", ""];

	static GREEN = 2;

	static VERTICAL_SCROLLER_APPARATUS_WIDTH = 32; // Contains Arrow icons and a scroll bar whose width is less than the apparatus'.

	static MEASURED_COMBO_HEIGHT = 235;

	static MEASURED_COMBO_EMPTY_HEIGHT = 32;

	static HEADER_FOOTER_HEIGHT = 160;

	static computeDimensions(params)
	{

// onsole.log("Compute dimensions: ");
// onsole.log(params);


		// Tote up Combo heigths - The open / closeable combos are a pain as they drag with them some uncontrollable padding.
		return (
			[
				params.aw - 32,
				params.ah - SimplifiedTraditionalTable.HEADER_FOOTER_HEIGHT
				-
					(params.bottomComboShowing ? SimplifiedTraditionalTable.MEASURED_COMBO_HEIGHT : SimplifiedTraditionalTable.MEASURED_COMBO_EMPTY_HEIGHT)
				-
					(params.topComboShowing ? SimplifiedTraditionalTable.MEASURED_COMBO_HEIGHT : SimplifiedTraditionalTable.MEASURED_COMBO_EMPTY_HEIGHT)
				]
		);
	} // computeDimensions



	// --------


	/** Expected
	  * headerRow 1st row [the parent decides if the first row is taken from the data (names) or generated (column 1, column 2, etc.)]
	  * visibleRows: pumped by this object from the parent via a callback.
	  * totalNumberOfRows - the number of rows in the table data (minus one if we take the header from the data itself)
	  * Note: number of columns is determined by the data
	  * tableW desired pixel width of the table
	  * tableH desired pixel height of the table
	  * And YES, horizontal and vertical positions are handled differently
	  */

	constructor(props)
	{
		super(props);
		this.serial = ++SimplifiedTraditionalTable.#serial;
		
		props.controller.registerDataViewer(this);

		this.state =
		{
			style:{},
			firstRowFillersAsBundle:null,
			lastRowFillersAsBundle:null,
			contentCells:null,
			selectedRow:null
		};

		this.width = (props.width ? props.width : SimplifiedTraditionalTable.DEFAULT_WIDTH);
		this.height = (props.height ? props.height : SimplifiedTraditionalTable.DEFAULT_HEIGHT);

		this.widthTakingScrollersIntoAccount = this.width;
		this.heightTakingScrollersIntoAccount = this.height;


		this.cc = 0; // for keys
		this.sequence = 0; // to eliminate out of date refresh requests
//		this.colourSequence = 0; // to eliminate out of date re-colouring requests




		// Shared between dimensions. For now anyway.

		this.headerRow = [];

		this.divider = 1.5;
		this.howManyTimesToDivideByDivider = 4; // Elasticity - before all columns are 1 px wide.

		this.col0Width = 120; // what's the name of a header, but on the side?
		this.headerHeight = 32;

//		this.numberOfFullyVisibleColumns = 6;
		this.idealWidthOfVisibleColumn = 80;

//		this.numberOfFullyVisibleRows = 8;
		this.idealHeightOfVisibleRow = 24;

		// The logical table - not the physical one we will be drawing
		this.nRows = 0;
		this.nColumns = 0;

		this.gridTemplateColumns = null;

		this.selectedColumns = [null, null];

		// scrollbars management
		this.allCanBeShownHorizontally = true;
		this.allCanBeShownVertically = true;

		this.horizontalPosition = 0;
		this.verticalPosition = 0;

		// For feedbackback to the scrollers
		this.verticalScroller = null;
		this.horizontalScroller = null;


//		this.ragTypeToShow = AqaComponent.OVERALL_POSITION_IN_OVERALL_RAG_SHORT;

		this.mightHaveScores = false;

		this.colSeq = [];


		this.aspectArray = [-1, -1];
		this.ragsToShow = [AqaComponent.OVERALL_POSITION_IN_OVERALL_RAG_SHORT];

		this.stati=null;
		this.numberOfRowsToDraw=16;
		this.personCache = {};
		this._isMounted=true;
		this.handleSelectedRow=this.handleSelectedRow.bind(this);
		this.handleSelectedRowPos=this.handleSelectedRowPos.bind(this);
	} //



	/** For the benefit of AqaComponent
	  */
	getDescription()
	{
		return (
			"Thetraditional table displays as much of the data from the snapshot as possible - in a traditional way. " +
			"It allows the selection of two vectors, as of this writing of two columns only, by clicking on a column header (1st selection - will populate the top Combo) or a column footer (2nd selection - will populate the bottom combo)<br />" +
			"Compression is achieved by diminishing the size of the vectors away from the visualisation window, and by removing them altogether if they are too far away."
		);

	} // getDescription

	registerVerticalScroller(s) { this.verticalScroller = s; }
	registerHorizontalScroller(s) { this.horizontalScroller = s;  }


	/** Returns array 0:do not magnifiy true or false, 1: desiredVectorSizeWhenUsingMagnification, adjusted or not, 2: number of elements to draw
	  */
	computeNumberOfElementsToShow(orientation, spaceAvailable, numberOfElements, elementSize)
	{
	
	
// onsole.log("!! numberOfElements: " + numberOfElements);
	
		// LAW S
		let numberToShow = Math.floor(spaceAvailable / elementSize);
		if (numberToShow > numberOfElements) numberToShow = numberOfElements;
//		return [numberToShow === numberOfElements, spaceAvailable / numberToShow, numberToShow]; // orientation === 'V' ||


//		let computedElementSize = numberOfElements <= numberToShow ? elementSize : spaceAvailable / numberToShow;

		let computedElementSize = orientation === 'V' ? elementSize : spaceAvailable / numberToShow;

		return [numberToShow === numberOfElements, computedElementSize, numberToShow];

	} // computeNumberOfElementsToShow


	/** Sets a new table
	  */
	init(score, headers, nRows, nCols, stati, postOp)
	{
	
	
	
// onsole.log("TT Initing number of rows at: " + nRows);
 
 
		this.score = score;
		this.headerRow = headers;
		this.nRows = nRows;
		this.nColumns = nCols;
		this.horizontalPosition = 0;
		this.verticalPosition = 0;
		
		const nHeaderElements = (!headers ? 0 : headers.length);
		// They should always be the same thanks to conformisation- UNLESS we have an empty table
		if (this.nColumns !== 0 && nHeaderElements !== this.nColumns) console.error(`Worrying problem: the number of headers(${nHeaderElements}) is different from the number of columns(${this.nColumns})`);
		this.colSeq = AqaComponent.makeSequence(nHeaderElements);
		if (this.nRows === 0 && this.nColumns === 0) this.nColumns = nHeaderElements;

		this.mightHaveScores = score !== null && score.columnScores !== null;


		this.stati = new RowStatiObject(stati);

		this.setup(postOp);
		
	} // init


	resize(params, postOp)
	{

// onsole.log("TT resize 1");
		let [fullWidth, fullHeight] = SimplifiedTraditionalTable.computeDimensions(params);
		if (fullHeight < SimplifiedTraditionalTable.MINIMUM_HEIGHT) fullHeight = SimplifiedTraditionalTable.MINIMUM_HEIGHT;


		if (this.height !== fullHeight || this.width !== fullWidth)
		{
			this.width = fullWidth;
			this.height = fullHeight;
		}
// onsole.log("TT resize 5");

		this.setup(postOp);

	} // resize


	setup(postOp)
	{

// onsole.log("TT setup");

		// We start optimistic.
		this.allCanBeShownHorizontally = true;
		this.allCanBeShownVertically = true;
		
		let minimalAcceptableHeight = Scroller.thickness + ((this.idealHeightOfVisibleRow + this.headerHeight) << 1); // 2 of each
		if (this.height < minimalAcceptableHeight) this.height = minimalAcceptableHeight;

		for(let tries = 0; tries < 2; tries++)
		{
			// multiplied by 2 because we have a header at the top and at the bottom.
			this.tableH = this.height - (this.headerHeight << 1);
			if (!this.allCanBeShownHorizontally)
			{
				this.tableH -= Scroller.thickness;
				this.heightTakingScrollersIntoAccount = this.height - Scroller.thickness;
			}
			else this.heightTakingScrollersIntoAccount = this.height;

			[this.allCanBeShownVertically, this.heightOfVisibleRow, this.numberOfRowsToDraw] = this.computeNumberOfElementsToShow
			(
				'V',
				this.tableH,
				this.nRows,
//				this.numberOfFullyVisibleRows,
				this.idealHeightOfVisibleRow
			);

			this.tableW = this.width - this.col0Width;
			if (!this.allCanBeShownVertically)
			{
				this.tableW -= SimplifiedTraditionalTable.VERTICAL_SCROLLER_APPARATUS_WIDTH; // Our rack with arrows. Not just Scroller.thickness which needs to be comfortable in there.
				this.widthTakingScrollersIntoAccount = this.width - SimplifiedTraditionalTable.VERTICAL_SCROLLER_APPARATUS_WIDTH;
			}
			else this.widthTakingScrollersIntoAccount = this.width;

			[this.allCanBeShownHorizontally, this.widthOfVisibleColumn, this.numberOfColumnsToDraw] = this.computeNumberOfElementsToShow
			(
				'H',
				this.tableW,
				this.nColumns,
//				this.numberOfFullyVisibleColumns,
				this.idealWidthOfVisibleColumn
			);

			if (this.allCanBeShownHorizontally) break;
		} 

		this.widths = null;

		this.gridTemplateColumns = this.pxThem(this.computeSizes('H', 0, true, null, true), 'H');
		this.scrollVertically(0, true, false, undefined, postOp);

	} // setup



	computeNewPosition(orientation, displacement, displacementIsAbsolute)
	{
		let totalNumberOfVectors, numberOfVectorsToDraw, oldPosition;
		switch(orientation)
		{
		case 'H':
			oldPosition                 = this.horizontalPosition;
			totalNumberOfVectors        = this.nColumns;
			numberOfVectorsToDraw       = this.numberOfColumnsToDraw;
			break;

		case 'V':
			oldPosition                 = this.verticalPosition;
			totalNumberOfVectors        = this.nRows;
			numberOfVectorsToDraw       = this.numberOfRowsToDraw;

			break;

		default:
			throw new Error("Unknown Traditional Table orientation: [" + orientation + "]. It should be H or V");
		}	

		let position = (displacementIsAbsolute ? displacement : oldPosition + displacement);
		if (position + numberOfVectorsToDraw > totalNumberOfVectors) position = totalNumberOfVectors - numberOfVectorsToDraw;
		if (position < 0) position = 0;
		return [oldPosition, position];
	
	} // computeNewPosition



	/**
	  * orientation V for vertical, meaning the focus goes up and down
	  *             H for horizontal, meaning the focus goes left and right
	  *             The topology is extra information required by render
	  *             It tells you for example what rows to retrieve from the backend
	  */
	computeSizes(orientation, displacement, forceEvenIfNoDisplacement, topology, displacementIsAbsolute)
	{
		return this.computeSizesWithConfirmedPosition(orientation, this.computeNewPosition(orientation, displacement, displacementIsAbsolute), forceEvenIfNoDisplacement, topology);
	} // computeSizes
	
	
	computeSizesWithConfirmedPosition(orientation, positions, forceEvenIfNoDisplacement, topology)
	{


// if (orientation === 'V') onsole.log("COMPUTING V SIZES!");


		let [oldPosition, position] = positions;
		if (position === oldPosition && !forceEvenIfNoDisplacement) return null;


		let visibleVectorPhysicalSize; // , numberOfFullyVisibleVectors; physicalSizeAvailable
		let totalNumberOfVectors, numberOfVectorsToDraw; //, allCanBeShown   , oldPosition;

		
		let isHorizontal; // this is to disable the vector selection which at the moment is only available horizonally.
		                  // If / when we implemenent the selection of rows, then a bit of generalisation will be needed in this function.


		switch(orientation)
		{
		case 'H':

			visibleVectorPhysicalSize   = this.widthOfVisibleColumn;
//			oldPosition                 = this.horizontalPosition;
			totalNumberOfVectors        = this.nColumns;
			numberOfVectorsToDraw       = this.numberOfColumnsToDraw;
			isHorizontal = true;

			break;

		case 'V':

			visibleVectorPhysicalSize   = this.heightOfVisibleRow;
//			oldPosition                 = this.verticalPosition;
			totalNumberOfVectors        = this.nRows;
			numberOfVectorsToDraw       = this.numberOfRowsToDraw;
			isHorizontal = false;

			break;

		default:
			throw new Error("Unknown Traditional Table orientation: [" + orientation + "]. It should be H or V");
		}
		
		switch(orientation)
		{
		case 'H':
			this.horizontalPosition = position;
			break;

		case 'V':
			this.verticalPosition = position;
			break;
		default: // to shut up the compiler.
		}


		let i;

		// It's easy this way.
		let sizes;

		// Horizontal and vertical cases are not handled entirely
		// similarly.
		// All columns are always displayed - if with a size of zero.
		// Scrolling horizontally amounts to changing column sizes.
		if (!isHorizontal)
		{
			// Vertical scrolling is what triggers data pumping, so we need to know what to pump.
			topology.getSizesFrom = 0;
			topology.startVisibleVector = position;
			topology.endVisibleVector = topology.startVisibleVector + numberOfVectorsToDraw;
			topology.getSizesTo = numberOfVectorsToDraw;

// onsole.log("-------> numberOfVectorsToDraw: (V): " + numberOfVectorsToDraw);
// onsole.log("-------> startVisibleVector (V): " + topology.startVisibleVector);

			sizes = Array(numberOfVectorsToDraw);
			for(i = 0; i < numberOfVectorsToDraw; i++) sizes[i] = visibleVectorPhysicalSize;
		}
		else
		{
			// Horizontally we set visible columns to the visible size and the rest to 0.
			// Why is it done this way? Because it makes things muuuuch quicker
			// It's also kind of neat in the traditional view.
			let max = position + numberOfVectorsToDraw;
			if (max > totalNumberOfVectors) max = totalNumberOfVectors;
			sizes = Array(totalNumberOfVectors);
			for(i = 0; i < position; i++) sizes[i] = 0;
			for(; i < max; i++) sizes[i] = visibleVectorPhysicalSize;
			for(; i < totalNumberOfVectors; i++) sizes[i] = 0;
		}

		return sizes;


	} // computeSizesWithConfirmedPosition



	/** This turns a series of numbers into the same series as a string together with a 'px' unit for each.
	  * orientation H (horizontal: we're doing columns) V (vertical) we're doing rows.
	  * It automatically adds measures for header and footer.
	  */
	pxThem(sizes, orientation) // Possibly integrate into computeColumnWidths
	{
		let n;
		if (!sizes || (n = sizes.length) === 0) return "";

		let ec = 0, extra;
		if (orientation === 'H')
		{
			extra = this.col0Width;
			ec = 1;
		}
		else if (orientation === 'V')
		{
			ec = 1;
			extra = this.headerHeight;
		}
		else ec = 0;

		const pxes = Array(n + ec);

		let p;
		if (ec > 0)
		{
			pxes[0] = `${extra}px`;
			p = 1;
		}
		else p = 0;
		for(let i = 0; i < n; i++) pxes[p++] = `${sizes[i]}px`;
		if (ec === 2) pxes[p] = `${extra}px`;

		return pxes.join(' ');

	} // pxThem


	getColumnScore(columnIndex)
	{

		let colScore;	
	


		let ret;
		
		if (!this.mightHaveScores || !(colScore = this.score.columnScores[columnIndex.toString()])) ret = [null, null];
		else ret = [colScore.numberOfAmbers, colScore.numberOfReds]; // <ColumnScore numberOfAmbers={colScore.numberOfAmbers} numberOfReds={colScore.numberOfReds} />

/*
if (columnIndex == 1)
{
onsole.log("CI--->" + columnIndex);
onsole.log("mightHaveScores: " + this.mightHaveScores);
onsole.log("Other test: " + !(colScore = this.score.columnScores[columnIndex.toString()]));
onsole.log(JSON.stringify(ret));
}
*/

		return ret;

	} // getColumnScore


	removeColumnSelection(which)
	{
		if (this.selectedColumns[which] !== null) this.selectedColumns[which].setState({selected:false});
	} // removeColumnSelections


	ctrlColSelect(th)
	{

	/**
	  * 0 Uniqueness
	  * 1 Populated
	  * 2 String
	  * 3 Number
	  * 4 Date
	  */


		const parent = this.props.parent;

		parent.vqdEditor.handleRuleEditor();
// onsole.log("TYPE IX: " + parent.getTypeMetricSelectedType(th.props.which) + " (which: " + th.props.which + ")");
		
		//parent.vqdEditor.handleShowRulesEditor(th.props.col, th.props.caption, parent.getTypeMetricSelectedType(th.props.col));
		// Failed to set the determined type
		const controller = parent.props.controller;
		const columns = controller.state.columnData.filter((d)=>{return d.index === th.props.col;});
		let type = 2;
		if(columns.length>0){
			let selectedType = columns[0].type;
			type = selectedType==="String"?2:(selectedType==="Number"?3:(selectedType==="Date"?4:2))
		}
		parent.vqdEditor.handleShowRulesEditor(th.props.col, th.props.caption, type);

	} // ctrlColSelect


	colSelect(th)
	{
		// Deselecting RAs in any case
		if (th.columnScore) th.columnScore.resetChecks();

		if (this.selectedColumns[th.props.which] !== null) this.selectedColumns[th.props.which].setState({selected:false});

		if (this.selectedColumns[th.props.which] === th)
		{
			// toggle off.
			this.selectedColumns[th.props.which] = null;
			this.props.controller.deSelectColumn(th.props.which);
			return;
		}

		this.selectedColumns[th.props.which] = th;
		th.setState({selected:true});

		this.props.controller.selectColumn
		(
			th.props.which,
			th.props.col // ,
		); // ET Call Hoooooome


	} // colSelect



	raSelect(th, onOff)
	{
		this.props.controller.columnRagSelect(th.props.which, th.props.col, onOff[0], onOff[1]);
	} // raSeclect

	/*
	adjustRadar()
	{
	
		for(let i = 0; i < 2; i++) 
		{
			if (this.props.controller.heatmaps[i]) this.props.controller.heatmaps[i].setRadarPosition
			(
				this.horizontalPosition,
				this.numberOfColumnsToDraw,
				this.nColumns,
				this.verticalPosition,
				this.numberOfRowsToDraw,
				this.nRows
			);
		}

	} // adjustRadar
	*/

	/* The difference between scrolling horizontally and vertically is that only 
	 * a vertical scroll may require new data.
	 * We always get full rows (although the backend can do horizontal windowing too) 
    * This is because in most cases tables will be taller than wide.
    * If the opposite case occurs, we may incur a performance hit
    */
	scrollHorizontally(displacement, unused, displacementIsAbsolute, fromSlider)
	{
		if (!this.allCanBeShownHorizontally)
		{
			let newColWidths = this.computeSizes("H", displacement, false, null, displacementIsAbsolute);
			if (newColWidths === null) return;

			this.gridTemplateColumns = this.pxThem(newColWidths, 'H'); // this also adjusts our position
			if(document.getElementById("play-table-" + this.serial)!==null) document.getElementById("play-table-" + this.serial).style.gridTemplateColumns = this.gridTemplateColumns; // Yes, I know, but sshhhh!

			if (this.horizontalScroller !== null && (fromSlider === undefined)) this.horizontalScroller.scrollTo(this.horizontalPosition);
			this.repaint();
		}

//		this.adjustRadar();
	} // scrollHorizontally


	scrollVertically(displacement, force, displacementIsAbsolute, slider, postOp)
	{
		const positions = this.computeNewPosition("V", displacement, displacementIsAbsolute);
		let moved = positions[0] !== positions[1];
		
		if (force || moved)
		{

			const topology = {};
			let newRowHeights = this.computeSizesWithConfirmedPosition("V", positions, true, topology); // this also adjusts our position - in this case this.verticalPosition
			topology.heights = newRowHeights;

			const refresher = () =>
			{
				let mySequenceNumber = ++this.sequence;

				this.props.controller.pumpRows
				(
					topology,
					mySequenceNumber,
					(t, s, c, m,n, p) => this.receiveNewContent(t, s, c, m,n, p),
					() =>
					{
						if (postOp) postOp();
//						this.adjustRadar();
					}
				);
			};
			if (!slider && this.verticalScroller) this.verticalScroller.scrollTo(this.verticalPosition, refresher);
			else refresher();

		}
		else if (postOp)
		{
			postOp();
		}

	} // scrollVertically



















/*
	timer()
	{
		return new function ()
		{
			this.startTime = (new Date()).getTime();
			this.end = s =>
			{
				const endTime = (new Date()).getTime();
				onsole.log("CHRONOMETER - " + s + " " + ((endTime - this.startTime) / 1000));
			}
		}();

	} // timer
*/

	repaint()
	{
		if (!this.contentStructure) return;
		// onsole.log("TT:repaint: 1");
		const p = this.contentStructure;
		this.receiveNewContent(p.topology, p.sequenceNumber, p.content, p.colourMap,p.overrideMap);
	} // repaint

	repaint2(row)
	{
		if (!this.contentStructure) return;
		const p = this.contentStructure;
		this.receiveNewContent(p.topology, p.sequenceNumber, p.content, p.colourMap,p.overrideMap,null,row);
	} // repaint

	handleSelectedRow(row){this.repaint2(row)}

	handleSelectedRowUpdate(row,col,value){
		if (!this.contentStructure) return;
		const p = this.contentStructure;
		let overrideMap = ColourAndOverrideMaps.getOverrideMap(this.props.parent.props.snapshotId);
		if(col!==null && col!=="" && value!==null && col!==undefined && value!==undefined && overrideMap !== null)
		{
			overrideMap.setForColumnOrientation(col, row, value); // we have the colour map in the structure if needed.
		}
		this.contentStructure = {topology:p.topology,sequenceNumber:p.sequenceNumber,content:p.content,colourMap:p.colourMap,overrideMap:overrideMap};
	}

	handleSelectedRowPos(row,value){

		if(value!==null && value!==undefined && row!==null){
			let stati = this.stati;
			stati.ststrct[row] = value+64;
			this.stati = stati;
		}
	}

	handleSelectedRowNote(row,col,note){
		if (!this.contentStructure) return;
		const p = this.contentStructure;
		let content = p.content;
		let newContent = [];
		if(col!==null && col!=="" && note!==null && col!==undefined && note!==undefined)
		{
			for(let i=0;i<content.length;i++){
				let cont = content[i];
				if(cont.originalPosition === row){
					let contentrow = content[i];
					let values = content[i].values;
					values[col].note = note;
					contentrow.values = values;
					newContent.push(contentrow);
				}
				else newContent.push(content[i]);
			}

		}
		this.contentStructure = {topology:p.topology,sequenceNumber:p.sequenceNumber,content:newContent,colourMap:p.colourMap,overrideMap:p.overrideMap};

	}

	checkSequence(seq) { return this.sequence === seq; }

	alphabase(x)
	{
		var range;
		var e;

		for(e = 1; true; x -= range, e++) if (x < (range = Math.pow(26, e))) break;

		var r, ret = "";
		for(var i = 0; i < e; i++)
		{
			r = x % 26;
			ret = String.fromCharCode(65 + r) + ret;
			x -= r;
			x /= 26;
		}
		return ret;

	} // alphabase

	// No, don't inline this in scroll Vertically.
	receiveNewContent(topology, sequenceNumber, content, colourMap, overrideMap, postOp, selectedRow) {

//console.log("1. RNC - SN: " + sequenceNumber+ " - "+this.sequence);

		if (sequenceNumber !== this.sequence) return; // we took too long and the user was frenetically scrolling.

// onsole.log("2");

		this.contentStructure = {topology, sequenceNumber, content, colourMap, overrideMap};

		// Deciding which rows to show and how.
		// This won't be enough when / if we have row selection.
		let i, j = 0;

		let p;
		let heights = topology.heights; // less indirection.

		const nRows = content.length; // visible ones that is.
		const nCols = this.nColumns;

		let usableHeights = Array(topology.getSizesTo - topology.getSizesFrom-1);
		for (p = 0, i = topology.getSizesFrom; i < topology.getSizesTo; i++) usableHeights[p++] = heights[i];

		const contentCells = Array(nRows * (nCols + 1)); // +1: the row number cell at the beginning

		// Row index in the (filtered) snapshot - so as to get the right colour.
		let row = topology.adjustedStartRow; // Our controller has set this for us.

		/*
        onsole.log("---------------> the number of rows I have is: " + nRows);
        onsole.log("---------------> ragIx: " + this.ragsToShow[0]);
        */
		const width = this.widthOfVisibleColumn - AqaComponent.ellipsisWidth - 8 - 2; // 8? Because. 2: the borders

// onsole.log("nrows: " + nRows);
// onsole.log(this.stati);
// onsole.log(selectedRow);
		this.ragsToShow = [0];

		//for(let zu = 10; zu < 20; zu++) onsole.log("zu[" + zu + "] = " + this.stati.status(zu));
		let oPos;
		for (p = 0, i = 0; i < nRows; i++, row++) {
			//console.log(overrideMap);
			// Testing
			if (content[i] !== undefined) {
				oPos = content[i].originalPosition;
				if (oPos !== 0 || row ===0 ) {
					contentCells[p++] =
						<TraditionalTd text={"Row " + (1 + oPos)} header={true} key={"c" + (this.cc++)} width={width}
									   rs={this.stati.status(oPos)} parent={this.props.parent} originalPosition={(oPos)}
									   rag={colourMap.columnBasedCellColour2(this.ragsToShow, j, oPos)}
									   rags={colourMap.columnBasedCellPreciseColour(this.ragsToShow, j, oPos)}
									   os={overrideMap.overrideStateForCellInColOrientation(oPos, j)} dataFromParent={this}
									   selectedRow={selectedRow}/>;

					// Just one RAG
					// for (j = 0; j < nCols; j++) contentCells[p++] = <TraditionalTd key={"c" + (this.cc++)} x={j} y={i} text={content[i].values[j]} width={width} rag={colourMap.columnBasedCellColour(this.ragTypeToShow, j, row)} />;

					// Many RAGs
					for (j = 0; j < nCols; j++) contentCells[p++] =
							<TraditionalTd key={"c" + (this.cc++)} x={j} y={i} text={content[i].valuesMap[j]}
										   content={content[i].values[j]} width={width}
										   rag={colourMap.columnBasedCellColour2(this.ragsToShow, j, oPos)}
										   rags={colourMap.columnBasedCellPreciseColour(this.ragsToShow, j, oPos)}
										   originalPosition={(oPos)} parent={this.props.parent} rs={this.stati.status(oPos)}
										   os={overrideMap.overrideStateForCellInColOrientation(oPos, j)} dataFromParent={this}
										   selectedRow={selectedRow}/>;

				}
			}
		}


		// PAD EMPTY ROWS AT BOTTOM IF NEEDED!
		for (; i < this.numberOfRowsToDraw; i++) for (j = 0; j <= nCols; j++) contentCells[p++] =
			<TraditionalTd key={"c" + (this.cc++)} text="" header={j === 0} rag={SimplifiedTraditionalTable.GREEN}/>;


// onsole.log("Postop: " + postOp);

		let adjustHeight = this.props.controller.props.controller.state.selectedColumn !== null ? 12 : 12;
		if(window.innerHeight>580 && window.innerHeight<730){
			adjustHeight = 8
		}

		let height = ((this.headerHeight) << 1) + ((this.idealHeightOfVisibleRow) * (this.numberOfRowsToDraw)) + adjustHeight;
		if(window.innerHeight>580 && window.innerHeight<730){

		}
		else{
			height = height - 20;
		}
		//height = height-108;
		if(this._isMounted) {
			this.setState
			(
				{
					style:
						{
							display: "grid",
							width: `${this.widthTakingScrollersIntoAccount}px`,
							height: `${height}px`, // `${this.heightTakingScrollersIntoAccount}px`,
							gridTemplateColumns: this.gridTemplateColumns,
							gridTemplateRows: this.pxThem(usableHeights, 'V'),
							float: "left"
						},
					contentCells
				},
				postOp // just to see if we're going to crash it.
			);
		}

	} // receiveNewContent
	

	
	
	/** @param which 0 for top, 1 for bottom, although we don't care which is which 
	  * @param aspectIndex 0: Uniqueness, 1: populated, 2: String, 3: number, Date: 4 AND -1: NO aspect
	  */
	  
	informOfAspect(which, aspectIndex)
	{

		this.aspectArray[which] = aspectIndex;
		const ragsToShow = [];
		let bp;

		for(let i = 0; i < 2; i++)
		{
			if (this.aspectArray[i] !== -1)
			{
				bp = FE_ASPECT_INDEX_TO_BE_BIT_POSITION[this.aspectArray[which]];
				if (bp === AqaComponent.OVERALL_POSITION_IN_OVERALL_RAG_SHORT) continue;

				if (i === 0) ragsToShow[0] = bp;
				else
				{
					if (ragsToShow.length === 0 || (ragsToShow.length === 1 && ragsToShow[0] !== bp)) ragsToShow[1] = bp;
				}
			}
			
		
		}

		if (ragsToShow.length === 0) ragsToShow[0] = AqaComponent.OVERALL_POSITION_IN_OVERALL_RAG_SHORT;
		if (ragsToShow.length === this.ragsToShow.length)
		{
			if (ragsToShow.length === 1 && ragsToShow[0] === this.ragsToShow[0]) return;
			if
			(
				(ragsToShow[0] === this.ragsToShow[0] && ragsToShow[1] === this.ragsToShow[1])
			||
				(ragsToShow[0] === this.ragsToShow[1] && ragsToShow[1] === this.ragsToShow[0])
			) return;
		}

// onsole.log("RAGS TO SHOW:");
// onsole.log(AqaComponent.prettifyJson(ragsToShow));


		this.ragsToShow = ragsToShow;
		this.repaint();

	} // informOfAspect

	handleKeyPressed = (e)=>{
		if(e.key === "ArrowUp"){
			this.scrollVertically(-1, false, false)
		}
		if(e.key === "ArrowDown"){
			this.scrollVertically(1, false, false)
		}
	}

	// Candidate to be componentized
	makeVerticalScrollerButtonCombo()
	{

		let style =
		{
			width:"32px",
			height:"32px"
		};

		document.addEventListener("keyup",this.handleKeyPressed);

		// OK, my formatting is primitive, but it's simple and works well!

		let adjustHeight = this.props.controller.props.controller.state.selectedColumn!==null?-2:-2;

		const height = ((this.headerHeight) << 1) + ((this.idealHeightOfVisibleRow) * (this.numberOfRowsToDraw-1))+adjustHeight;

//		const leftOver = this.heightTakingScrollersIntoAccount - 120; // 8 pixels further down - because they have bottom excess

		const leftOver = height-126; // 8 pixels further down - because they have bottom excess

// <div style={{height:`${this.heightTakingScrollersIntoAccount}px`, width:`${SimplifiedTraditionalTable.VERTICAL_SCROLLER_APPARATUS_WIDTH}px`, float:"left", overflow:"hidden"}} >

		return (
			<div style={{height:`${height}px`, width:`${SimplifiedTraditionalTable.VERTICAL_SCROLLER_APPARATUS_WIDTH}px`, float:"left", overflow:"hidden"}} >

				<Arrow type="bigUp" onClick={() => this.scrollVertically(-this.numberOfRowsToDraw, false, false)}  style={style} />
				<Arrow type="up" onClick={() => this.scrollVertically(-1, false, false)} style={style} />
				<div style={{width:`${SimplifiedTraditionalTable.VERTICAL_SCROLLER_APPARATUS_WIDTH}px`, height:`${leftOver}px`, paddingLeft:"6px"}}>
					{
						leftOver > 24 && !this.allCanBeShownVertically && this.numberOfRowsToDraw < this.nRows
						?
							<Scroller
								parent={this}
								type="vertical"
								extent={this.nRows}
								visible={this.numberOfRowsToDraw}
								size={leftOver - 8}
								register={(s) => this.registerVerticalScroller(s)}
								marginLeft="2px"
							/>
						:
							null
					}
				</div>
				<Arrow type="down"  onClick={() => this.scrollVertically(1, false, false)} style={style} />
				<Arrow type="bigDown" onClick={() => this.scrollVertically(this.numberOfRowsToDraw, false, false)} style={style} />
			</div>
		);

	} // makeVerticalScrollerButtonCombo 



	// Candidate to be componentized
	makeHorizontalScrollerButtonCombo(which)
	{
		const divStyle = which === 0 ? {borderTop:"1px solid #ddd"} : {};

		const style =
		{
			padding: "0px",
			marginLeft:"-4px"
		};

		return (	
			<div style={divStyle}>
				<b>
					<Typography variant="inherit" color="inherit" style={{textAlign: "left", fontSize: "0.8rem", width: "100%",marginTop:"-3px"}}>
					
						<Arrow type="bigLeft"  onClick={() => this.scrollHorizontally(-this.numberOfColumnsToDraw, false, false)} style={style} controller={this} />
						<Arrow type="left"     onClick={() => this.scrollHorizontally(-1, false, false)} style={style} controller={this} />
						<Arrow type="right"    onClick={() => this.scrollHorizontally(1, false, false)}  style={style} controller={this} />
						<Arrow type="bigRight" onClick={() => this.scrollHorizontally(this.numberOfColumnsToDraw, false, false)}  style={style} controller={this} />
					</Typography>
				</b>
			</div>
		);

	} // makeHorizontalScrollerButtonCombo


	statsOnColumn(e, cId)
	{
		e.stopPropagation();
		this.props.controller.statsOnColumn(cId);
	} // statsOncolumn


	vqdForColumn(e, cId)
	{
		e.stopPropagation();
		this.props.controller.vqdForColumn
		(
			cId,
			data => alert(AqaComponent.prettifyJson(data))
		);
	} // statsOncolumn

	componentWillUnmount() {
		this._isMounted=false;
		document.removeEventListener("keyup",this.handleKeyPressed);
	}

	render()
	{
		// https://dev.to/abdulbasit313/an-easy-way-to-create-a-customize-dynamic-table-in-react-js-3igg
		
		
		
		

		if (!this.colSeq || this.colSeq.length === 0) return <div/>; // Really no point.



		// Our controller keeps track of which RA, if any, is selected for each column on each header.
		const amberRedFunction = this.props.controller.getColumnRagSelections;
		let headerData = Array(this.colSeq.length);
		for(let i = 0; i < this.colSeq.length; i++) {
			let cValue = this.alphabase(i);
			let cData = {coercedValue:cValue,originalValue:cValue};
			headerData[i] = cData;
		}
		// old ar 0
		// {i == amberReds[0] ? [amberReds[1], amberReds[2]] : null}

		// old ar1
		// {i == amberReds[1] ? [amberReds[1], amberReds[2]] : null}


// <Typography variant="inherit" color="inherit" style={{textAlign: "left", fontSize: "0.8rem", width: "100%"}}>
// 			</Typography>

// onsole.log("Render: " + this.props.visible);

		if (!this.props.visible) return <></>;

		const tableId = `play-table-${this.serial}`;
		return (

			<>

				<div style={{overflow:"hidden"}}>

					<div id={tableId} className="traditional-table" style={this.state.style}>

						{/* header */}
						{ this.makeHorizontalScrollerButtonCombo(0)}
						{
							this.colSeq.map
							(
								i => <TraditionalTh
									parent={this}
									which={0}
									caption={this.headerRow ? (this.headerRow[i]===""?headerData[i].coercedValue: this.headerRow[i]): ""}
									col={i}
									key={"r1_" + i} plusLink={true}
									score={this.getColumnScore(i)}
									ar={[0, i, amberRedFunction]}
								/>
							)
						}

						{/* content shown */}
						{this.nRows > 0 && this.state.contentCells !== null ? this.state.contentCells : null}

						{/*this.makeHorizontalScrollerButtonCombo(1)*/}

						{/* header but as a footer */}
						{/*
							this.colSeq.map
							(
								i => <TraditionalTh
									parent={this}
									which={1}
									caption={this.headerRow ? this.headerRow[i] : ""}
									col={i}
									key={"r2_" + i}
									score={this.getColumnScore(i)} 
									ar={[1, i, amberRedFunction]}
								/>
							)*/
						}

					</div>
					{
						!this.allCanBeShownVertically && this.numberOfRowsToDraw < this.nRows
					?
						this.makeVerticalScrollerButtonCombo()
					:
						null
					}

				</div>


				<div>
				{
					!this.allCanBeShownHorizontally && this.numberOfColumnsToDraw < this.nColumns
				?
					<Scroller
						parent={this}
						type="horizontal"
						extent={this.nColumns}
						visible={this.numberOfColumnsToDraw!==undefined?this.numberOfColumnsToDraw:this.nColumns}
						size={this.widthTakingScrollersIntoAccount}
						register={(s) => this.registerHorizontalScroller(s)}
						marginTop="-28px"
					/>
				:
					null
				}
				</div>

			</>


		);
		
// chron.end("Rendering");

//		return ret;

	} // render

// style={{paddingLeft:"32px"}}


} ////


/*
	scroll VerticallyOLD(displacement, force, displacementIsAbsolute, fromSlider, postOp)
	{

		if (force || !this.allCanBeShownVertically)
		{
		
		
//			this.computeNewPosition("V", displacement, displacementIsAbsolute)		
		
		
			let mySequenceNumber = ++this.sequence; // This is a device to cancel out of date requests

// onsole.log("Seq: " + mySequenceNumber + " | " + this.sequence);

			let topology = {};
			let newRowHeights = this.computeSizes("V", displacement, force, topology, displacementIsAbsolute); // this also adjusts our position
			if (newRowHeights == null) return; // we have not budged

			topology.heights = newRowHeights;
			
// onsole.log("Topology: ");
// onsole.log(topology);
			

			this.props.controller.pumpRows(topology, mySequenceNumber, (t, s, c, m, p) => this.receiveNewContent(t, s, c, m, p), postOp);
			if (this.verticalScroller !== null && fromSlider === undefined) this.verticalScroller.scrollTo(this.verticalPosition);
		}
		else if (postOp) postOp();
		
		this.adjustRadar();

	} // scroll VerticallyOLD
*/



