/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/ModelAndView'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/ModelAndView'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.WhitelabelApprovalEndpointApi = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.ModelAndView);
  }
}(this, function(ApiClient, ModelAndView) {
  'use strict';

  /**
   * WhitelabelApprovalEndpoint service.
   * @module api/WhitelabelApprovalEndpointApi
   * @version 1.0
   */

  /**
   * Constructs a new WhitelabelApprovalEndpointApi. 
   * @alias module:api/WhitelabelApprovalEndpointApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the getAccessConfirmationUsingDELETE operation.
     * @callback module:api/WhitelabelApprovalEndpointApi~getAccessConfirmationUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/ModelAndView} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * getAccessConfirmation
     * @param {Object} opts Optional parameters
     * @param {Object.<String, {String: String}>} opts.model model
     * @param {module:api/WhitelabelApprovalEndpointApi~getAccessConfirmationUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ModelAndView}
     */
    this.getAccessConfirmationUsingDELETE = function(opts, callback) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        '../model': opts['../model'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = ModelAndView;

      return this.apiClient.callApi(
        '/oauth/confirm_access', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccessConfirmationUsingGET operation.
     * @callback module:api/WhitelabelApprovalEndpointApi~getAccessConfirmationUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ModelAndView} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * getAccessConfirmation
     * @param {Object} opts Optional parameters
     * @param {Object.<String, {String: String}>} opts.model model
     * @param {module:api/WhitelabelApprovalEndpointApi~getAccessConfirmationUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ModelAndView}
     */
    this.getAccessConfirmationUsingGET = function(opts, callback) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        '../model': opts['../model'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = ModelAndView;

      return this.apiClient.callApi(
        '/oauth/confirm_access', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccessConfirmationUsingHEAD operation.
     * @callback module:api/WhitelabelApprovalEndpointApi~getAccessConfirmationUsingHEADCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ModelAndView} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * getAccessConfirmation
     * @param {Object} opts Optional parameters
     * @param {Object.<String, {String: String}>} opts.model model
     * @param {module:api/WhitelabelApprovalEndpointApi~getAccessConfirmationUsingHEADCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ModelAndView}
     */
    this.getAccessConfirmationUsingHEAD = function(opts, callback) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        '../model': opts['../model'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['*/*'];
      var returnType = ModelAndView;

      return this.apiClient.callApi(
        '/oauth/confirm_access', 'HEAD',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccessConfirmationUsingOPTIONS operation.
     * @callback module:api/WhitelabelApprovalEndpointApi~getAccessConfirmationUsingOPTIONSCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ModelAndView} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * getAccessConfirmation
     * @param {Object} opts Optional parameters
     * @param {Object.<String, {String: String}>} opts.model model
     * @param {module:api/WhitelabelApprovalEndpointApi~getAccessConfirmationUsingOPTIONSCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ModelAndView}
     */
    this.getAccessConfirmationUsingOPTIONS = function(opts, callback) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        '../model': opts['../model'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['*/*'];
      var returnType = ModelAndView;

      return this.apiClient.callApi(
        '/oauth/confirm_access', 'OPTIONS',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccessConfirmationUsingPATCH operation.
     * @callback module:api/WhitelabelApprovalEndpointApi~getAccessConfirmationUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ModelAndView} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * getAccessConfirmation
     * @param {Object} opts Optional parameters
     * @param {Object.<String, {String: String}>} opts.model model
     * @param {module:api/WhitelabelApprovalEndpointApi~getAccessConfirmationUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ModelAndView}
     */
    this.getAccessConfirmationUsingPATCH = function(opts, callback) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        '../model': opts['../model'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['*/*'];
      var returnType = ModelAndView;

      return this.apiClient.callApi(
        '/oauth/confirm_access', 'PATCH',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccessConfirmationUsingPOST operation.
     * @callback module:api/WhitelabelApprovalEndpointApi~getAccessConfirmationUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ModelAndView} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * getAccessConfirmation
     * @param {Object} opts Optional parameters
     * @param {Object.<String, {String: String}>} opts.model model
     * @param {module:api/WhitelabelApprovalEndpointApi~getAccessConfirmationUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ModelAndView}
     */
    this.getAccessConfirmationUsingPOST = function(opts, callback) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        '../model': opts['../model'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['*/*'];
      var returnType = ModelAndView;

      return this.apiClient.callApi(
        '/oauth/confirm_access', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccessConfirmationUsingPUT operation.
     * @callback module:api/WhitelabelApprovalEndpointApi~getAccessConfirmationUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ModelAndView} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * getAccessConfirmation
     * @param {Object} opts Optional parameters
     * @param {Object.<String, {String: String}>} opts.model model
     * @param {module:api/WhitelabelApprovalEndpointApi~getAccessConfirmationUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ModelAndView}
     */
    this.getAccessConfirmationUsingPUT = function(opts, callback) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        '../model': opts['../model'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['*/*'];
      var returnType = ModelAndView;

      return this.apiClient.callApi(
        '/oauth/confirm_access', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
