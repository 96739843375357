/* Welcome to Aqa*/
/* Version: 1 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import {ReactComponent as UpIcon} from '../../images/aqa-icons/Sort Upcol.svg';
import {ReactComponent as DownIcon} from '../../images/aqa-icons/Sort downcol.svg';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import HtmlTooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaSourceSorter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            periodicities: [],
            periodicity: "daily",
            SourceName: "",
            SourceDescription: null,
            errorMessage: "",
            keyword: "name",
            sortDirection: false

        };
        this.handleSortDirectionChange = this.handleSortDirectionChange.bind(this);

    }

    handleSortChange(evt){
        //alert(evt.target.value);
        var keyword = evt.target.value;
        this.setState({"keyword": keyword});
        this.props.dataFromParent.handleSortTypeChange(keyword);
    }
    handleSortDirectionChange(e){
        e.preventDefault();
// onsole.log(this.state.sortDirection);
        if(this.state.sortDirection) {
            this.setState({sortDirection: false});
        }
        else {
            this.setState({sortDirection: true});
        }
        this.props.dataFromParent.handleSortChange(this.state.sortDirection);
    }
    componentDidMount(){

    }
    render() {
        //const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,sortDirection } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: "-8px"}}>
                        <Grid item xs={3} sm={3} lg={3} xl={3}>
                            <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"right",paddingLeft:"4px",paddingRight:"4px"}}>
                                Sort By:
                            </Typography>
                        </Grid>
                        <Grid item xs={5} sm={5} lg={5} xl={5}>
                            <div style={{width:"85%",marginTop:"4px"}}>
                                <FormControl className={"aqa-action-header-text"}>

                                    <Select onChange={this.handleSortChange.bind(this)}
                                            variant="standard"
                                            value={this.state.keyword}
                                            className={"aqa-action-select"}
                                            style={{textAlign:"left",width:"150px",paddingLeft:"9px",paddingTop:"4px",backgroundColor:"none !important",border:"none !important"}}
                                            autoWidth={true}
                                            disableUnderline={true}
                                            inputProps={{
                                                name: 'name',
                                                id: 'name',
                                            }}
                                    >

                                            <MenuItem key="name" value="name" style={{width:"150px",fontFamily: "Montserrat",fontSize: "12px",fontWeight:"bold",letterSpacing: "0.4px",color: "#000000DE"}}>Name</MenuItem>
                                            <MenuItem key="dateCreated" value="dateCreated" style={{width:"150px",fontFamily: "Montserrat",fontSize: "12px",fontWeight:"bold",letterSpacing: "0.4px",color: "#000000DE"}}>Created Date</MenuItem>
                                            <MenuItem key="createdBy" value="createdBy" style={{width:"150px",fontFamily: "Montserrat",fontSize: "12px",fontWeight:"bold",letterSpacing: "0.4px",color: "#000000DE"}}>Created By</MenuItem>

                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={3} sm={3} lg={3} xl={3}>
                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action">Click to change sort direction</Typography>
                                </div>
                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                            <IconButton className="aqa-button" onClick={this.handleSortDirectionChange.bind(this)}>
                                <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"right",paddingLeft:"4px",paddingRight:"4px"}}>
                                    Direction:
                                </Typography>
                            <div className="aqa-icon" width="32px" style={{marginTop:"4px"}}>
                                {
                                    (!sortDirection)?
                                        (<DownIcon width="32px" />):
                                        (<UpIcon width="32px" />)
                                }
                            </div>
                            </IconButton>
                            </HtmlTooltip>
                        </Grid>
                        <Grid item xs={1} sm={1} lg={1} xl={1}>
                        </Grid>

                    </Grid>


                </div>
            );
        }


    }
}

AqaSourceSorter.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSourceSorter);
