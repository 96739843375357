import React from 'react';
import PropTypes from 'prop-types';

import
{
	Checkbox,
	FormControlLabel,
//	FormGroup, Radio, RadioGroup,
	Table,
	TableBody, TableCell, TableHead,
	TableRow/*, GridList, GridListTile*/
} from "@material-ui/core";

import {withStyles}   from '@material-ui/core/styles';
import Grid           from '@material-ui/core/Grid';
import Typography     from '@material-ui/core/Typography';
import FormControl    from "@material-ui/core/FormControl";

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

import AqaOutline from '../../shared/outline/AqaOutline'

import AqaComponent                  from "../../shared/aqacomponent/AqaComponent";
import AqaBoundaries                 from "../../../model/AqaBoundaries"
import AqaRulesParent                from "./AqaRulesParent"
import AqaQualityButton              from "./subelements/AqaQualityButton"
import AqaRulesTextThreshold         from "./subelements/AqaRulesTextThreshold"
import AqaTypeBreachSeveritySelector from "./subelements/AqaTypeBreachSeveritySelector"
import AqaRulesGraph                 from "./subelements/AqaRulesGraph"
// import StringElement                 from "./subelements/StringElement"
// import StringLookUp                  from "../../shared/stringlookup/StringLookUp"
import AqaRulesRadioButton from "./subelements/AqaRulesRadioButton";
import {ReactComponent as DeleteIcon} from "../../../images/aqa-icons/AQA-Delete.svg";
import {ReactComponent as AddIcon} from "../../../images/aqa-icons/AQA-Include.svg";
import HtmlTooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";


import AqaPopulationUniquenessNativeErrorsCombo from "./AqaPopulationUniquenessNativeErrorsCombo"
import AqaFormatTable                from "./subelements/AqaFormatTable"
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";


const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);


//const RAG_COLOURS = ["red", "amber", "green"];


class AqaRulesString extends AqaRulesParent
{

	constructor(props)
	{
		super(props, "String");
		
		this.state =
		{
			error: null,
			notfound: false,
			isLoaded: true, // this is most likely redundant
	
			redirect: false,
			currentSnapshot:"allsources",
	
			width:960,
			valueName:""
	
		};

		// Three String lookups:
		// red, amber, green
		this.lookups = {};
		this.choice = null;
		
		// 0: not requested, 1: requesting, 2: obtained
		this.choiceRequested = 0;
		this.allFormats = [];
		this.allValues  = [];


//		this.handleChange2=this.handleChange2.bind(this);
//		this.handleAddValue=this.handleAddValue.bind(this);

	} //
	
	/** This method gets all the strings available for the currently selected column that the string look up will offer to the user */
	getChoiceForStringLookups(lookupObject)
	{

		this.lookups[lookupObject.getColour()] = lookupObject;

		switch(this.choiceRequested)
		{
		case 0: // We need to request the available strings
		

			this.choiceRequested = 1; // rquesting
			const snapshotId = this.props.sourceDetailObject.getSelectedSnapshotId(); // Yes... this thing has travelled...
			const colId = this.props.rulesObject.getSelectedColumnId();	
		

			// Using the distributions
			AqaComponent.snapshotBackend.getColVectorUsingGET
			(
				snapshotId,
				colId,
				(error, data, response) =>
				{

					if (error)
					{
						this.choiceRequested = 2; // We won't try again
					 	console.error("Impossible to get column data (String look up): " + error);
					}
					else
					{

						if (data && data.distributions && data.distributions.string)
						{
							const strings = Object.keys(data.distributions.string);
							if (strings && strings.length > 0)
							{
								strings.sort((a, b) => a.localeCompare(b));
								this.choice = strings;
								this.choiceRequested = 2;
								for (var luo of Object.values(this.lookups)) luo.processChoice(this.choice);
							}
						}
					}
				}
			);	
			break;

		case 1:
			// We are in the process of requesting.
			return;
			
		case 2:
			// We have the data
			lookupObject.processChoice(this.choice);
			break;
			
		default: // To shut up the transpiler
			break;
		}
			
	
	} // getChoiceForStringLookups
	
	getValuesKey(colour) { return `${colour}StringValues`; }
	getFormatsKey(colour) { return `${colour}${this.TypeName}Formats`; }

	/** Used by render() to get the string values without much fuss */
	getStringValues(colour)
	{
		const ret = this.rules[this.getValuesKey(colour)];
		if (!ret) return [];
		return ret;
	
	} // getStringValues





	/** Add a value to its list */ 
	addValue = (colour, value) =>
	{

		if (typeof value === 'undefined') return;

// onsole.log("Wanting to add: [" + value + "]");

		const ck = this.getValuesKey(colour);
		let values = this.rules[ck];
		if (!values) this.rules[ck] = values = [];
		if (values.indexOf(value) >= 0) return; // No need to have the same value more than once
		
		values.unshift(value);

		this.forceUpdate();
		this.props.rulesObject.adjustCompositeRuleValues();
	}; //addValue


	/** Removes a value from its list */
	removeValue = (colour, index, update) =>
	{
		const values = this.rules[this.getValuesKey(colour)];
		if (!values) return;

		if (index < 0 || index >= values.length) return;

		values.splice(index, 1);

		if(update)
		{
			this.forceUpdate();
			this.props.rulesObject.adjustCompositeRuleValues();
		}
	}; // removeValue

	/** Removes a value from its list */
	removeFormat = (colour, index, update) =>
	{
		const values = this.rules[this.getFormatsKey(colour)];
		if (!values) return;

		if (index < 0 || index >= values.length) return;

		values.splice(index, 1);

		if(update)
		{
			this.forceUpdate();
			this.props.rulesObject.adjustCompositeRuleValues();
		}
	}; // removeFormat
	
	

	
	// This should become unnecessary once we have generalised AqaFormatTable to handle values too*/
	handleUnlistedValuechange = e =>
	{
		this.rules["unlistedStringValueColour"] = e.target.value;
		this.forceUpdate();
		this.props.rulesObject.adjustCompositeRuleValues();
	} // handleUnlistedValuechange
	

	handleChange2 = event =>
	{
		event.preventDefault();
		this.setState({[event.target.name]:event.target.value});
	}; // handleChange2

	handleAddValue = () =>
	{
		var valueName = this.state.valueName;
		if(valueName === undefined || valueName === "") return;
		this.addValue("green", valueName);
		this.setState({valueName: ""});
	}; // handleAddValue



	
	render()
	{
		const { error, isLoaded, notfound, hasError, invertZones, width} = this.state;

		//const enabled = this.isRulesEnabled();
		
		const useAllowedValues = this.rules.useAllowedValues;
		const useStringLengths = this.rules.useStringLengths;
		const useStringFormats = this.rules.useStringFormats;
		const minMaxArray	= [this.wideMinRange, this.wideMaxRange];
		
		
// onsole.log("AqaRulesString renderer:");
// onsole.log(AqaComponent.prettifyJson(this.boundaryValues));


		this.allFormats = this.packageFormatsOrValuesForRender(1);
		this.allValues = this.packageFormatsOrValuesForRender(160670); // Look at the function

/*
		RAG_COLOURS.map(color=> {
				var values = this.getStringValues(color);
				if(values) {
					var l=0;
					for (var val of values) {
						var valColor = {id: val, color: color,index:l}
						this.allValues.push(valColor);
						l=l+1;
					}
				}
			}
		);
*/
	
		if (notfound && error)
		{
			return (
				<div className={"snapshot-rules-servant-pane"}>
					<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

						<Grid item xs={10} sm={7} lg={11} xl={11}>
							<Typography
								variant="title"
								color="inherit" align="left"
								style={
									{
										fontSize: '1.5rem',
										padding: '0.1em 0.5em',
										fontFamily: 'Open Sans, sans-serif'
									}
								}
							>
								Nothing to display
							</Typography>
						</Grid>

						<Grid item xs={2} sm={1} lg={1} xl={1}></Grid>
					</Grid>
				</div>
			);
		}
		else if (error && !notfound)
		{
			return (
				<div>
					<Typography
						variant="title" color="inherit" align="left"
						style={
							{
								fontSize: '1.5rem',
								padding: '3.1em 0.5em',
								fontFamily: 'Open Sans, sans-serif'
							}
						}
					>
						Error: {error.message}
					</Typography>
				</div>
			);
		}
		else if (!isLoaded)
		{
			return <div>Loading...</div>;
		}
		else
		{
			return (
				<div className={"snapshot-rule-servant-pane"}>
				
					<Grid
						container
						spacing={0}
						direction="row"
						justify="space-between"
						alignItems="center"
						style={{padding: 0, marginTop: 0}
					}>

						<Grid item xs={12} sm={12} lg={12} xl={12}>
							<div style={{padding:"0px"}}>
								<AqaQualityButton which={2} />


								<div style={{marginTop:"-48px"}}>

									{
										[
										//  0 The Flag               1                                   2                   3 Caption
											[useAllowedValues,        e => {this.setUsed("AllowedValues", e.target.checked)}, "Set allowed String values"],
											[useStringLengths,        e => {this.setUsed("StringLengths", e.target.checked)}, "Set String length thresholds"],
											[useStringFormats,        e => {this.setUsed("StringFormats", e.target.checked)}, "Set acceptable formats"],
											[this.typeEnforcedFor2(), e => {this.handleEnforceTypeChange2(e.target.checked)}, "Values must be Strings"]
											
										].map
										(
											(srt, i) =>
											(
												<div key={"Checkbox_"+i} style={{textAlign:"left", width:"960px", padding:0, margin:0}}>
													<FormControlLabel
														key={`srt${i}`}
														control={
															<Checkbox
																checked={srt[0]}
																onChange={srt[1]}
																color="primary"
															/>
														}
														label={
															(
																<Typography className={"aqa-text-action"} variant="title" align="left" style={{ }}>
																	{srt[2]}
																</Typography>
															)
														}
														labelPlacement="end"
													/>


													{/* String values */}
													{
														i !== 0 ? null
														:
															<div style={{textAlign:"left"}}>
															{
																	useAllowedValues
																	?

																		<div style={{marginTop:"-50px"}}>
																			<div>
																				<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>
																					<Grid item xs={4} sm={4} lg={4} xl={4}>
																					</Grid>
																					<Grid item xs={4} sm={4} lg={4} xl={4}>
																						<span style={{float:"left",width:"40%",marginTop:"6px"}}>
																							<Typography color="inherit" className="aqa-text-action">Unlisted Values:</Typography>
																						</span>
																						<span style={{float:"left",width:"50%",marginTop:"3px"}}>
																							<Select
																								label=""
																								id="unlistedStringValueColour-label"
																								name="unlistedStringValueColour"
																								value={this.rules["unlistedStringValueColour"]}
																								onChange={this.handleUnlistedValuechange}
																								displayEmpty
																								fullWidth
																								className={"aqa-text-action"}
																							>
																								<MenuItem className={"aqa-text-action"} style={{padding:"6px 10px"}} key={"red"} value={"red"}>{"Red"}</MenuItem>
																								<MenuItem className={"aqa-text-action"} style={{padding:"6px 10px"}} key={"amber"} value={"amber"}>{"Amber"}</MenuItem>
																								<MenuItem className={"aqa-text-action"} style={{padding:"6px 10px"}} key={"green"} value={"green"}>{"Green"}</MenuItem>

                                                        					</Select>
																						</span>
																					</Grid>
																					<Grid item xs={4} sm={4} lg={4} xl={4}>
																						<span style={{float:"left",width:"40%",marginTop:"6px"}}>
																							<Typography color="inherit" className="aqa-text-action">Add String value</Typography>
																						</span>
																						<span style={{float:"left",width:"50%",marginTop:"3px"}}>

																						<TextField
																							id="valueName"
																							name="valueName"
																							label=""
																							className={""}
																							value={this.state.valueName}
																							variant="standard"
																							margin="dense"
																							multiline={false}
																							rows={1}
																							style={{ width: "95%",margin:"0px"}}
																							onChange={this.handleChange2}
																							InputProps={{style:{padding:"0px",
																								fontFamily:"Montserrat",
																								fontSize:"12px",
																								lineHeight:"10px",
																								textAlign:"left"}}}
																							required
																						/>
																					</span>
																						<span style={{float:"left",width:"10%"}}>
																						<div className="aqa-text-bold-12">
																							<HtmlTooltip enterDelay={500} title={<React.Fragment>
																								<div>
																									<Typography color="inherit" className="aqa-text-action">Click to add the allowed String value to list</Typography>
																								</div>
																							</React.Fragment>} arrow={"true"} placement="bottom-start">
																								<div style={{float:"left", padding:"4px 0 0 8px", cursor:"pointer"}} onClick={this.handleAddValue}>
																									<AddIcon width="22px" />
																								</div>
																							</HtmlTooltip>
																						</div>
																						</span>
																					</Grid>
																				</Grid>
																			</div>
																			<div>
																			<Table width="100%" size="medium" cellSpacing={0} aria-sort="ascending">
																				<colgroup>
																					<col width="49%"/>
																					<col width="15%"/> 
																					<col width="15%"/>
																					<col width="15%"/>
																					<col width="6%"/>
																				</colgroup>
																				<TableHead>
																					<TableRow style={{height:"30px",width:"800px"}}>
																						<TableCell width="49%" style={{"padding":"0px 0px 0px 4px"}}><div className="aqa-text-bold-12"> Value</div></TableCell>
																						<TableCell width="15%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Green</div></TableCell>
																						<TableCell width="15%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Amber</div></TableCell>
																						<TableCell width="15%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Red</div></TableCell>
																						<TableCell width="6%" style={{"padding":"0px"}}><div className="aqa-text-bold-12"></div></TableCell>
																					</TableRow>
																				</TableHead>
																			</Table>
																		<div style={{'height': "145px", 'overflow':'auto',width:"960px"}}>
																			
																			<Table width="100%" size="medium" cellSpacing={0} aria-sort="ascending">
																				<colgroup>
																					<col width="49%"/>
																					<col width="15%"/>
																					<col width="15%"/>
																					<col width="15%"/>
																					<col width="6%"/>
																				</colgroup>
																				<TableBody style={{'minHeight': "145px", 'overflow':'scroll', width:"960px"}}>
																					{
																						this.allValues.map
																						(
																							(item, key) =>
																							(
																								<TableRow key={"Format_"+key} style={{tableLayout: 'fixed',height:"22px"}}>
																									<TableCell width="49%" style={{"padding":"0px 0px 0px 4px"}}>
																										<div className="aqa-text-bold-12"> {item.id}</div>
																									</TableCell>
																									<TableCell width="45%" style={{"padding":"0px"}}>
																										<div className="aqa-text-bold-12">
																											<AqaRulesRadioButton dataForColor={item.color} parent={this} dataAcquired={item} dataForType={"values"} />

																										</div>
																									</TableCell>
																									<TableCell width="6%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">
																										<HtmlTooltip
																											enterDelay={500}
																											title=
																											{
																												<React.Fragment>
																													<div>
																														<Typography color="inherit" className="aqa-text-action">Click to remove the allowed String value from list</Typography>
																													</div>
																												</React.Fragment>
																											}
																											arrow={"true"} placement="bottom-start"
																										>
																											<div style={{float:"left", padding:"4px 14px 0 4px", cursor:"pointer"}} onClick={() => this.removeValue(item.color, item.index, true)}>
																												<DeleteIcon width="22px" />
																											</div>
																										</HtmlTooltip>
																									</div>
																								</TableCell>
																							</TableRow>
																							)

																						)
																					}
																				</TableBody>
																			</Table>


																			
																			
																		</div>
																		</div>
																		</div>

																	:
																		null
															}

														</div>
													} { /* String values */ }

													{
														i !== 1 ? null :
													 	<div style={{textAlign:"left"}}>
															{
																useStringLengths
																?
																	(
																		<div style={{paddingBottom:"10px"}}>
																			<FormControl component="fieldset" size="small" fullWidth>

																				<Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start" style={{padding: 0, marginTop: "-5px"}}>

																					<Grid item xs={12} sm={12} lg={12} xl={12}>
																					
																						<div style={{textAlign:"right",marginTop:"-40px",marginLeft:"40px"}}>

																								<FormControlLabel
																									control={
																										<Checkbox
																											checked={this.state.invertZones}
																											onChange={e => this.handleInvertZone(e.target.checked)}
																											color="primary"
																										/>
																									}
																									label={(
																										<Typography
																											className={"aqa-text-action"}
																											variant="title"
																											align="right"
																											style={{margin:0}}
																										>
																											Invert Zones
																										</Typography>
																									)}
																									labelPlacement="end"
																								/>
																						</div>
																								
																					</Grid>

																					<Grid item xs={12} sm={12} lg={12} xl={12}>


																						<div id={"aqa_slider"} style={{width: width,marginTop:"65px",marginLeft:"0",position:"relative",zIndex:"1110"}}>
																							<Range
																								count={2}
																								min={this.wideMinRange}
																								max={this.wideMaxRange}
																								value={this.boundaryValues}
																								marks={this.marks}
																								included={false}
																								pushable={1}
																								step={1}
																								// allowCross={false}
																								tipProps={{visible:true}}
																								onChange={this.handleRangeChange2}

																								reverse={false}
																							/>

																						</div>
																						<div style={{position: "relative", textAlign: "left", marginTop:"-80px",height:"70px"}} >
																							<div id="aqa_tooltip" display="none" style={{position: "absolute", display: "none",backgroundColor:"#6c6c6c",color:"white",fontSize:"12px",fontFamily:"Montserrat",padding:"2px",maxWidth:"80px",zIndex:1201,textAlign:"center"}}></div>
																							<AqaRulesGraph
																								width={width}
																								height={200}
																								zones={AqaBoundaries.makeColourZones(this.rules, this.typeName, this.scaleFromValueToScreenValue, minMaxArray)}
																								marks={this.distributionMarks}
																							/>



																						</div>

																					</Grid>

																					<Grid item xs={12} sm={12} lg={12} xl={12} style={{zIndex:1200}}>
																						<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: "10px"}}>

																							<Grid item xs={5} sm={5} lg={5} xl={5}>
																								<Typography className="" variant="inherit" style={{textAlign:"center",paddingLeft:"0px",paddingRight:"0px",marginTop:"25px",marginLeft:"-110px"}}>
																									<AqaOutline label={"Lower Threshold"}>
																										<div>
																										
																										
																											<AqaRulesTextThreshold
																												idName="stringLengthBoundary1.number1"
																												type="number"
																												caption={invertZones ? "Amber" : "Red"}
																												checked={this.isBoundaryValueActivated(1, 1)}
																												changeHandler={e => this.setBoundaryValueActivated(1, 1, e.target.checked)}
																												checkboxName="showRed1"
																												value=
																												{
																													this.rules.stringLengthBoundary1
																													?
																														this.rules.stringLengthBoundary1.number1
																													:
																														0
																												}
																												error={hasError}
																												textChangeHandler={this.handleTextChange2}
																												parent={this}
																											/>

																											<AqaRulesTextThreshold
																												idName="stringLengthBoundary1.number2"
																												type="number"
																												caption={invertZones ? "Red" : "Amber"}
																												checked={this.isBoundaryValueActivated(1, 2)}
																												changeHandler={e => this.setBoundaryValueActivated(1, 2, e.target.checked)}
																												checkboxName="showAmber1"
																												value=
																												{
																													this.rules.stringLengthBoundary1
																													?
																														this.rules.stringLengthBoundary1.number2
																													:
																														0
																												}
																												error={hasError}
																												textChangeHandler={this.handleTextChange2}
																												parent={this}
																											/>

																										</div>
																									</AqaOutline>
																								</Typography>

																							</Grid>
																							<Grid item xs={3} sm={3} lg={3} xl={3}></Grid>
																							
																							<Grid item xs={4} sm={4} lg={4} xl={4}>
																								<Typography variant="inherit" style={{textAlign:"right",paddingLeft:"0px",paddingRight:"0px",marginTop:"25px",marginLeft:"0px",marginRight:"-5px"}}>

																									<AqaOutline label={"Upper Threshold"}>
																										<div>
																										
																											<AqaRulesTextThreshold
																												idName="stringLengthBoundary2.number1"
																												type="number"
																												caption={invertZones ? "Red" : "Amber"}
																												checked={this.isBoundaryValueActivated(2, 1)}
																												changeHandler={e => this.setBoundaryValueActivated(2, 1, e.target.checked)}
																												checkboxName="showAmber2"
																												value=
																												{
																													this.rules.stringLengthBoundary2
																													?
																														this.rules.stringLengthBoundary2.number1
																													:
																														0
																												}
																												error={hasError}
																												textChangeHandler={this.handleTextChange2}
																												parent={this}
																											/>

																											<AqaRulesTextThreshold
																												idName="stringLengthBoundary2.number2"
																												type="number"
																												caption={invertZones ? "Amber" : "Red"}
																												checked={this.isBoundaryValueActivated(2, 2)}
																												changeHandler={e => this.setBoundaryValueActivated(2, 2, e.target.checked)}
																												checkboxName="showRed2"
																												value=
																												{
																													this.rules.stringLengthBoundary2
																													?
																														this.rules.stringLengthBoundary2.number2
																													:
																														0
																												}
																												error={hasError}
																												textChangeHandler={this.handleTextChange2}
																												parent={this}
																											/>


																										</div>
																									</AqaOutline>
																								</Typography>
																							</Grid>

																						</Grid>
																					</Grid>
																				</Grid>

																			</FormControl>
																		</div>
																	)
																:
																	null
															} 

														</div>
													} {/* Use String Lengths */}


													{
														i !== 2 ? null :
													 	<div style={{textAlign:"left"}}>
															{
																useStringFormats
																?

																	<div style={{marginTop:"10px"}}>
																	
																		<div style={{textAlign:"left"}}>
																			<AqaFormatTable parent={this} allFormats={this.allFormats} canAdd={true} unlistedColour={this.getUnlistedColour()} hasHelp={true} />
																		</div>
																																				
																	</div>
																:
																	null
															}
														</div>
													} { /* String formats */ }
													
													{
														i !== 3
														?
															null
														:
														 	<div style={{textAlign:"left", border:"0px solid orange"}}>
																{
																	this.typeEnforcedFor2()
																	?
																		<AqaTypeBreachSeveritySelector parent={this} typeSeverity={this.rules.typeSeverity} changeHandler={this.handleTypeSeverityChange2} />
																	:
																		null
																}
															</div>
													} {/* Enforce type */}

													
													
													

												</div>
											)
										)
							
									}											

								</div>

							</div>
						</Grid>
						
						
						<AqaPopulationUniquenessNativeErrorsCombo
							dataForCol={this.props.dataForCol}
							dataForWhich={this.state.which}
							dataForName={this.state.columnName}
							parent={this.props.parent}
							dataFromSource={this.props.dataFromSource}
							dataForTable={this.props.dataForTable}
							//			dataFromSnapshot:this.props.dataFromSnapshot
							sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
							rulesObject={this.props.rulesObject}
							rules={this.props.rules}
						/>

						{/*
						<Grid item xs={12} sm={12} lg={12} xl={12}>
							<AqaRulesPopulated
								dataForCol={this.props.dataForCol}
								dataForWhich={this.state.which}
								dataForName={this.state.columnName}
								parent={this.props.parent}
								dataFromSource={this.props.dataFromSource}
								dataForTable={this.props.dataForTable}
								//			dataFromSnapshot:this.props.dataFromSnapshot
								sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
								rulesObject={this.props.rulesObject}
							/>
						</Grid>
						<Grid item xs={12} sm={12} lg={12} xl={12}>
							<AqaRulesUniqueness
								dataForCol={this.props.dataForCol}
								dataForWhich={this.state.which}
								dataForName={this.state.columnName}
								parent={this.props.parent}
								dataFromSource={this.props.dataFromSource}
								dataForTable={this.props.dataForTable}
								//			dataFromSnapshot:this.props.dataFromSnapshot
								sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
								rulesObject={this.props.rulesObject}
							/>
						</Grid>
						<Grid item xs={12} sm={12} lg={12} xl={12}>
							<AqaRulesNativeErrors
								dataForCol={this.props.dataForCol}
								dataForWhich={this.state.which}
								dataForName={this.state.columnName}
								parent={this.props.parent}
								dataFromSource={this.props.dataFromSource}
								dataForTable={this.props.dataForTable}
								//			dataFromSnapshot:this.props.dataFromSnapshot
								sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
								rulesObject={this.props.rulesObject}
							/>
						</Grid>
						*/}

					</Grid>
				</div>
			);
		}

	} // render
	
}////


AqaRulesString.propTypes = {classes: PropTypes.object.isRequired};

export default withStyles(AqaRulesParent.styles)(AqaRulesString);

