/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/IvParameterSpec', '../model/SecretKeySpec'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./IvParameterSpec'), require('./SecretKeySpec'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaCipher = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.IvParameterSpec, root.ApiDocumentation.SecretKeySpec);
  }
}(this, function(ApiClient, IvParameterSpec, SecretKeySpec) {
  'use strict';




  /**
   * The AqaCipher model module.
   * @module model/AqaCipher
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaCipher</code>.
   * @alias module:model/AqaCipher
   * @class
   */
  var exports = function() {
    var _this = this;




  };

  /**
   * Constructs a <code>AqaCipher</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaCipher} obj Optional instance to populate.
   * @return {module:model/AqaCipher} The populated <code>AqaCipher</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('ivSpc')) {
        obj['ivSpc'] = IvParameterSpec.constructFromObject(data['ivSpc']);
      }
      if (data.hasOwnProperty('key')) {
        obj['key'] = SecretKeySpec.constructFromObject(data['key']);
      }
      if (data.hasOwnProperty('nonce')) {
        obj['nonce'] = ApiClient.convertToType(data['nonce'], 'Blob');
      }
    }
    return obj;
  }

  /**
   * @member {module:model/IvParameterSpec} ivSpc
   */
  exports.prototype['ivSpc'] = undefined;
  /**
   * @member {module:model/SecretKeySpec} key
   */
  exports.prototype['key'] = undefined;
  /**
   * @member {Blob} nonce
   */
  exports.prototype['nonce'] = undefined;



  return exports;
}));


