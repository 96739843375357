


export default class Completer
{

	constructor(tasksList, thenTask)
	{
		this.tasksList = tasksList;
		this.thenTask = thenTask;
		this.nTasks = this.tasksList ? this.tasksList.length : 0;
		this.nTasksDone = 0;
	} //

	complete()
	{
		if (this.nTasks === this.nTasksDone)
		{
			this.thenTask();
			return true;
		}
		return false;
	} // complete

	completer = () =>
	{ 
		this.nTasksDone++;
		this.complete();
	}; // completer

	run()
	{
		if (!this.complete()) for(const t of this.tasksList) t(this.completer);
	} // run

} ////
