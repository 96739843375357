/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/AqaVectorScore'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./AqaVectorScore'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaScore = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.AqaVectorScore);
  }
}(this, function(ApiClient, AqaVectorScore) {
  'use strict';




  /**
   * The AqaScore model module.
   * @module model/AqaScore
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaScore</code>.
   * @alias module:model/AqaScore
   * @class
   */
  var exports = function() {
    var _this = this;





























































































  };

  /**
   * Constructs a <code>AqaScore</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaScore} obj Optional instance to populate.
   * @return {module:model/AqaScore} The populated <code>AqaScore</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('columnRag')) {
        obj['columnRag'] = ApiClient.convertToType(data['columnRag'], 'String');
      }
      if (data.hasOwnProperty('columnScore')) {
        obj['columnScore'] = ApiClient.convertToType(data['columnScore'], 'Number');
      }
      if (data.hasOwnProperty('columnScores')) {
        obj['columnScores'] = ApiClient.convertToType(data['columnScores'], {'String': AqaVectorScore});
      }
      if (data.hasOwnProperty('computed')) {
        obj['computed'] = ApiClient.convertToType(data['computed'], 'Boolean');
      }
      if (data.hasOwnProperty('dateRag')) {
        obj['dateRag'] = ApiClient.convertToType(data['dateRag'], 'String');
      }
      if (data.hasOwnProperty('dateScore')) {
        obj['dateScore'] = ApiClient.convertToType(data['dateScore'], 'Number');
      }
      if (data.hasOwnProperty('hasNumberOfOrthogonalVectorsWithErrorsUpdate')) {
        obj['hasNumberOfOrthogonalVectorsWithErrorsUpdate'] = ApiClient.convertToType(data['hasNumberOfOrthogonalVectorsWithErrorsUpdate'], 'Boolean');
      }
      if (data.hasOwnProperty('hasTwosDayExtraVectorColourDiscreteValuesUpdate')) {
        obj['hasTwosDayExtraVectorColourDiscreteValuesUpdate'] = ApiClient.convertToType(data['hasTwosDayExtraVectorColourDiscreteValuesUpdate'], 'Boolean');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('nativeErrorRag')) {
        obj['nativeErrorRag'] = ApiClient.convertToType(data['nativeErrorRag'], 'String');
      }
      if (data.hasOwnProperty('nativeErrorScore')) {
        obj['nativeErrorScore'] = ApiClient.convertToType(data['nativeErrorScore'], 'Number');
      }
      if (data.hasOwnProperty('numberOfAmberTableCells')) {
        obj['numberOfAmberTableCells'] = ApiClient.convertToType(data['numberOfAmberTableCells'], 'Number');
      }
      if (data.hasOwnProperty('numberOfColumns')) {
        obj['numberOfColumns'] = ApiClient.convertToType(data['numberOfColumns'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDateAmbers')) {
        obj['numberOfDateAmbers'] = ApiClient.convertToType(data['numberOfDateAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDateQualityDefinitions')) {
        obj['numberOfDateQualityDefinitions'] = ApiClient.convertToType(data['numberOfDateQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDateReds')) {
        obj['numberOfDateReds'] = ApiClient.convertToType(data['numberOfDateReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteDateAmbers')) {
        obj['numberOfDiscreteDateAmbers'] = ApiClient.convertToType(data['numberOfDiscreteDateAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteDateBoundaryAmbers')) {
        obj['numberOfDiscreteDateBoundaryAmbers'] = ApiClient.convertToType(data['numberOfDiscreteDateBoundaryAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteDateBoundaryReds')) {
        obj['numberOfDiscreteDateBoundaryReds'] = ApiClient.convertToType(data['numberOfDiscreteDateBoundaryReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteDateFormatAmbers')) {
        obj['numberOfDiscreteDateFormatAmbers'] = ApiClient.convertToType(data['numberOfDiscreteDateFormatAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteDateFormatReds')) {
        obj['numberOfDiscreteDateFormatReds'] = ApiClient.convertToType(data['numberOfDiscreteDateFormatReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteDateReds')) {
        obj['numberOfDiscreteDateReds'] = ApiClient.convertToType(data['numberOfDiscreteDateReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNativeErrorAmbers')) {
        obj['numberOfDiscreteNativeErrorAmbers'] = ApiClient.convertToType(data['numberOfDiscreteNativeErrorAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNativeErrorReds')) {
        obj['numberOfDiscreteNativeErrorReds'] = ApiClient.convertToType(data['numberOfDiscreteNativeErrorReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNumberAmbers')) {
        obj['numberOfDiscreteNumberAmbers'] = ApiClient.convertToType(data['numberOfDiscreteNumberAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNumberBoundaryAmbers')) {
        obj['numberOfDiscreteNumberBoundaryAmbers'] = ApiClient.convertToType(data['numberOfDiscreteNumberBoundaryAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNumberBoundaryReds')) {
        obj['numberOfDiscreteNumberBoundaryReds'] = ApiClient.convertToType(data['numberOfDiscreteNumberBoundaryReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNumberFormatAmbers')) {
        obj['numberOfDiscreteNumberFormatAmbers'] = ApiClient.convertToType(data['numberOfDiscreteNumberFormatAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNumberFormatReds')) {
        obj['numberOfDiscreteNumberFormatReds'] = ApiClient.convertToType(data['numberOfDiscreteNumberFormatReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNumberReds')) {
        obj['numberOfDiscreteNumberReds'] = ApiClient.convertToType(data['numberOfDiscreteNumberReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscretePopulationAmbers')) {
        obj['numberOfDiscretePopulationAmbers'] = ApiClient.convertToType(data['numberOfDiscretePopulationAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscretePopulationReds')) {
        obj['numberOfDiscretePopulationReds'] = ApiClient.convertToType(data['numberOfDiscretePopulationReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringAllowedAmbers')) {
        obj['numberOfDiscreteStringAllowedAmbers'] = ApiClient.convertToType(data['numberOfDiscreteStringAllowedAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringAllowedReds')) {
        obj['numberOfDiscreteStringAllowedReds'] = ApiClient.convertToType(data['numberOfDiscreteStringAllowedReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringAmbers')) {
        obj['numberOfDiscreteStringAmbers'] = ApiClient.convertToType(data['numberOfDiscreteStringAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringBoundaryAmbers')) {
        obj['numberOfDiscreteStringBoundaryAmbers'] = ApiClient.convertToType(data['numberOfDiscreteStringBoundaryAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringBoundaryReds')) {
        obj['numberOfDiscreteStringBoundaryReds'] = ApiClient.convertToType(data['numberOfDiscreteStringBoundaryReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringFormatAmbers')) {
        obj['numberOfDiscreteStringFormatAmbers'] = ApiClient.convertToType(data['numberOfDiscreteStringFormatAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringFormatReds')) {
        obj['numberOfDiscreteStringFormatReds'] = ApiClient.convertToType(data['numberOfDiscreteStringFormatReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringReds')) {
        obj['numberOfDiscreteStringReds'] = ApiClient.convertToType(data['numberOfDiscreteStringReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteTypeAmbers')) {
        obj['numberOfDiscreteTypeAmbers'] = ApiClient.convertToType(data['numberOfDiscreteTypeAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteTypeReds')) {
        obj['numberOfDiscreteTypeReds'] = ApiClient.convertToType(data['numberOfDiscreteTypeReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteUniquenessAmbers')) {
        obj['numberOfDiscreteUniquenessAmbers'] = ApiClient.convertToType(data['numberOfDiscreteUniquenessAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteUniquenessReds')) {
        obj['numberOfDiscreteUniquenessReds'] = ApiClient.convertToType(data['numberOfDiscreteUniquenessReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfGreenTableCells')) {
        obj['numberOfGreenTableCells'] = ApiClient.convertToType(data['numberOfGreenTableCells'], 'Number');
      }
      if (data.hasOwnProperty('numberOfNativeErrorAmbers')) {
        obj['numberOfNativeErrorAmbers'] = ApiClient.convertToType(data['numberOfNativeErrorAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfNativeErrorQualityDefinitions')) {
        obj['numberOfNativeErrorQualityDefinitions'] = ApiClient.convertToType(data['numberOfNativeErrorQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfNativeErrorReds')) {
        obj['numberOfNativeErrorReds'] = ApiClient.convertToType(data['numberOfNativeErrorReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfNumberAmbers')) {
        obj['numberOfNumberAmbers'] = ApiClient.convertToType(data['numberOfNumberAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfNumberQualityDefinitions')) {
        obj['numberOfNumberQualityDefinitions'] = ApiClient.convertToType(data['numberOfNumberQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfNumberReds')) {
        obj['numberOfNumberReds'] = ApiClient.convertToType(data['numberOfNumberReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfOrthogonalVectorsWithErrors')) {
        obj['numberOfOrthogonalVectorsWithErrors'] = ApiClient.convertToType(data['numberOfOrthogonalVectorsWithErrors'], [[['Number']]]);
      }
      if (data.hasOwnProperty('numberOfPopulationAmbers')) {
        obj['numberOfPopulationAmbers'] = ApiClient.convertToType(data['numberOfPopulationAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfPopulationQualityDefinitions')) {
        obj['numberOfPopulationQualityDefinitions'] = ApiClient.convertToType(data['numberOfPopulationQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfPopulationReds')) {
        obj['numberOfPopulationReds'] = ApiClient.convertToType(data['numberOfPopulationReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfRedTableCells')) {
        obj['numberOfRedTableCells'] = ApiClient.convertToType(data['numberOfRedTableCells'], 'Number');
      }
      if (data.hasOwnProperty('numberOfRows')) {
        obj['numberOfRows'] = ApiClient.convertToType(data['numberOfRows'], 'Number');
      }
      if (data.hasOwnProperty('numberOfStringAmbers')) {
        obj['numberOfStringAmbers'] = ApiClient.convertToType(data['numberOfStringAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfStringQualityDefinitions')) {
        obj['numberOfStringQualityDefinitions'] = ApiClient.convertToType(data['numberOfStringQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfStringReds')) {
        obj['numberOfStringReds'] = ApiClient.convertToType(data['numberOfStringReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfTableAmbers')) {
        obj['numberOfTableAmbers'] = ApiClient.convertToType(data['numberOfTableAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfTableQualityDefinitions')) {
        obj['numberOfTableQualityDefinitions'] = ApiClient.convertToType(data['numberOfTableQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfTableReds')) {
        obj['numberOfTableReds'] = ApiClient.convertToType(data['numberOfTableReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfTypeAmbers')) {
        obj['numberOfTypeAmbers'] = ApiClient.convertToType(data['numberOfTypeAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfTypeQualityDefinitions')) {
        obj['numberOfTypeQualityDefinitions'] = ApiClient.convertToType(data['numberOfTypeQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfTypeReds')) {
        obj['numberOfTypeReds'] = ApiClient.convertToType(data['numberOfTypeReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfUnadjustedColumns')) {
        obj['numberOfUnadjustedColumns'] = ApiClient.convertToType(data['numberOfUnadjustedColumns'], 'Number');
      }
      if (data.hasOwnProperty('numberOfUnadjustedRows')) {
        obj['numberOfUnadjustedRows'] = ApiClient.convertToType(data['numberOfUnadjustedRows'], 'Number');
      }
      if (data.hasOwnProperty('numberOfUniquenessAmbers')) {
        obj['numberOfUniquenessAmbers'] = ApiClient.convertToType(data['numberOfUniquenessAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfUniquenessQualityDefinitions')) {
        obj['numberOfUniquenessQualityDefinitions'] = ApiClient.convertToType(data['numberOfUniquenessQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfUniquenessReds')) {
        obj['numberOfUniquenessReds'] = ApiClient.convertToType(data['numberOfUniquenessReds'], 'Number');
      }
      if (data.hasOwnProperty('numberRag')) {
        obj['numberRag'] = ApiClient.convertToType(data['numberRag'], 'String');
      }
      if (data.hasOwnProperty('numberScore')) {
        obj['numberScore'] = ApiClient.convertToType(data['numberScore'], 'Number');
      }
      if (data.hasOwnProperty('populationRag')) {
        obj['populationRag'] = ApiClient.convertToType(data['populationRag'], 'String');
      }
      if (data.hasOwnProperty('populationScore')) {
        obj['populationScore'] = ApiClient.convertToType(data['populationScore'], 'Number');
      }
      if (data.hasOwnProperty('rag')) {
        obj['rag'] = ApiClient.convertToType(data['rag'], 'String');
      }
      if (data.hasOwnProperty('rowRag')) {
        obj['rowRag'] = ApiClient.convertToType(data['rowRag'], 'String');
      }
      if (data.hasOwnProperty('rowScore')) {
        obj['rowScore'] = ApiClient.convertToType(data['rowScore'], 'Number');
      }
      if (data.hasOwnProperty('rowScores')) {
        obj['rowScores'] = ApiClient.convertToType(data['rowScores'], {'String': AqaVectorScore});
      }
      if (data.hasOwnProperty('score')) {
        obj['score'] = ApiClient.convertToType(data['score'], 'Number');
      }
      if (data.hasOwnProperty('stringRag')) {
        obj['stringRag'] = ApiClient.convertToType(data['stringRag'], 'String');
      }
      if (data.hasOwnProperty('stringScore')) {
        obj['stringScore'] = ApiClient.convertToType(data['stringScore'], 'Number');
      }
      if (data.hasOwnProperty('tableRag')) {
        obj['tableRag'] = ApiClient.convertToType(data['tableRag'], 'String');
      }
      if (data.hasOwnProperty('tableScore')) {
        obj['tableScore'] = ApiClient.convertToType(data['tableScore'], 'Number');
      }
      if (data.hasOwnProperty('timestamp')) {
        obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Number');
      }
      if (data.hasOwnProperty('totalNumberOfAmbers')) {
        obj['totalNumberOfAmbers'] = ApiClient.convertToType(data['totalNumberOfAmbers'], 'Number');
      }
      if (data.hasOwnProperty('totalNumberOfQualityDefinitions')) {
        obj['totalNumberOfQualityDefinitions'] = ApiClient.convertToType(data['totalNumberOfQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('totalNumberOfReds')) {
        obj['totalNumberOfReds'] = ApiClient.convertToType(data['totalNumberOfReds'], 'Number');
      }
      if (data.hasOwnProperty('typeRag')) {
        obj['typeRag'] = ApiClient.convertToType(data['typeRag'], 'String');
      }
      if (data.hasOwnProperty('typeScore')) {
        obj['typeScore'] = ApiClient.convertToType(data['typeScore'], 'Number');
      }
      if (data.hasOwnProperty('uniquenessRag')) {
        obj['uniquenessRag'] = ApiClient.convertToType(data['uniquenessRag'], 'String');
      }
      if (data.hasOwnProperty('uniquenessScore')) {
        obj['uniquenessScore'] = ApiClient.convertToType(data['uniquenessScore'], 'Number');
      }
    }
    return obj;
  }

  /**
   * @member {module:model/AqaScore.ColumnRagEnum} columnRag
   */
  exports.prototype['columnRag'] = undefined;
  /**
   * @member {Number} columnScore
   */
  exports.prototype['columnScore'] = undefined;
  /**
   * @member {Object.<String, module:model/AqaVectorScore>} columnScores
   */
  exports.prototype['columnScores'] = undefined;
  /**
   * @member {Boolean} computed
   */
  exports.prototype['computed'] = undefined;
  /**
   * @member {module:model/AqaScore.DateRagEnum} dateRag
   */
  exports.prototype['dateRag'] = undefined;
  /**
   * @member {Number} dateScore
   */
  exports.prototype['dateScore'] = undefined;
  /**
   * @member {Boolean} hasNumberOfOrthogonalVectorsWithErrorsUpdate
   */
  exports.prototype['hasNumberOfOrthogonalVectorsWithErrorsUpdate'] = undefined;
  /**
   * @member {Boolean} hasTwosDayExtraVectorColourDiscreteValuesUpdate
   */
  exports.prototype['hasTwosDayExtraVectorColourDiscreteValuesUpdate'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {module:model/AqaScore.NativeErrorRagEnum} nativeErrorRag
   */
  exports.prototype['nativeErrorRag'] = undefined;
  /**
   * @member {Number} nativeErrorScore
   */
  exports.prototype['nativeErrorScore'] = undefined;
  /**
   * @member {Number} numberOfAmberTableCells
   */
  exports.prototype['numberOfAmberTableCells'] = undefined;
  /**
   * @member {Number} numberOfColumns
   */
  exports.prototype['numberOfColumns'] = undefined;
  /**
   * @member {Number} numberOfDateAmbers
   */
  exports.prototype['numberOfDateAmbers'] = undefined;
  /**
   * @member {Number} numberOfDateQualityDefinitions
   */
  exports.prototype['numberOfDateQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfDateReds
   */
  exports.prototype['numberOfDateReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteDateAmbers
   */
  exports.prototype['numberOfDiscreteDateAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteDateBoundaryAmbers
   */
  exports.prototype['numberOfDiscreteDateBoundaryAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteDateBoundaryReds
   */
  exports.prototype['numberOfDiscreteDateBoundaryReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteDateFormatAmbers
   */
  exports.prototype['numberOfDiscreteDateFormatAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteDateFormatReds
   */
  exports.prototype['numberOfDiscreteDateFormatReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteDateReds
   */
  exports.prototype['numberOfDiscreteDateReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNativeErrorAmbers
   */
  exports.prototype['numberOfDiscreteNativeErrorAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNativeErrorReds
   */
  exports.prototype['numberOfDiscreteNativeErrorReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNumberAmbers
   */
  exports.prototype['numberOfDiscreteNumberAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNumberBoundaryAmbers
   */
  exports.prototype['numberOfDiscreteNumberBoundaryAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNumberBoundaryReds
   */
  exports.prototype['numberOfDiscreteNumberBoundaryReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNumberFormatAmbers
   */
  exports.prototype['numberOfDiscreteNumberFormatAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNumberFormatReds
   */
  exports.prototype['numberOfDiscreteNumberFormatReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNumberReds
   */
  exports.prototype['numberOfDiscreteNumberReds'] = undefined;
  /**
   * @member {Number} numberOfDiscretePopulationAmbers
   */
  exports.prototype['numberOfDiscretePopulationAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscretePopulationReds
   */
  exports.prototype['numberOfDiscretePopulationReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringAllowedAmbers
   */
  exports.prototype['numberOfDiscreteStringAllowedAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringAllowedReds
   */
  exports.prototype['numberOfDiscreteStringAllowedReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringAmbers
   */
  exports.prototype['numberOfDiscreteStringAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringBoundaryAmbers
   */
  exports.prototype['numberOfDiscreteStringBoundaryAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringBoundaryReds
   */
  exports.prototype['numberOfDiscreteStringBoundaryReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringFormatAmbers
   */
  exports.prototype['numberOfDiscreteStringFormatAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringFormatReds
   */
  exports.prototype['numberOfDiscreteStringFormatReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringReds
   */
  exports.prototype['numberOfDiscreteStringReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteTypeAmbers
   */
  exports.prototype['numberOfDiscreteTypeAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteTypeReds
   */
  exports.prototype['numberOfDiscreteTypeReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteUniquenessAmbers
   */
  exports.prototype['numberOfDiscreteUniquenessAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteUniquenessReds
   */
  exports.prototype['numberOfDiscreteUniquenessReds'] = undefined;
  /**
   * @member {Number} numberOfGreenTableCells
   */
  exports.prototype['numberOfGreenTableCells'] = undefined;
  /**
   * @member {Number} numberOfNativeErrorAmbers
   */
  exports.prototype['numberOfNativeErrorAmbers'] = undefined;
  /**
   * @member {Number} numberOfNativeErrorQualityDefinitions
   */
  exports.prototype['numberOfNativeErrorQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfNativeErrorReds
   */
  exports.prototype['numberOfNativeErrorReds'] = undefined;
  /**
   * @member {Number} numberOfNumberAmbers
   */
  exports.prototype['numberOfNumberAmbers'] = undefined;
  /**
   * @member {Number} numberOfNumberQualityDefinitions
   */
  exports.prototype['numberOfNumberQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfNumberReds
   */
  exports.prototype['numberOfNumberReds'] = undefined;
  /**
   * @member {Array.<Array.<Array.<Number>>>} numberOfOrthogonalVectorsWithErrors
   */
  exports.prototype['numberOfOrthogonalVectorsWithErrors'] = undefined;
  /**
   * @member {Number} numberOfPopulationAmbers
   */
  exports.prototype['numberOfPopulationAmbers'] = undefined;
  /**
   * @member {Number} numberOfPopulationQualityDefinitions
   */
  exports.prototype['numberOfPopulationQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfPopulationReds
   */
  exports.prototype['numberOfPopulationReds'] = undefined;
  /**
   * @member {Number} numberOfRedTableCells
   */
  exports.prototype['numberOfRedTableCells'] = undefined;
  /**
   * @member {Number} numberOfRows
   */
  exports.prototype['numberOfRows'] = undefined;
  /**
   * @member {Number} numberOfStringAmbers
   */
  exports.prototype['numberOfStringAmbers'] = undefined;
  /**
   * @member {Number} numberOfStringQualityDefinitions
   */
  exports.prototype['numberOfStringQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfStringReds
   */
  exports.prototype['numberOfStringReds'] = undefined;
  /**
   * @member {Number} numberOfTableAmbers
   */
  exports.prototype['numberOfTableAmbers'] = undefined;
  /**
   * @member {Number} numberOfTableQualityDefinitions
   */
  exports.prototype['numberOfTableQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfTableReds
   */
  exports.prototype['numberOfTableReds'] = undefined;
  /**
   * @member {Number} numberOfTypeAmbers
   */
  exports.prototype['numberOfTypeAmbers'] = undefined;
  /**
   * @member {Number} numberOfTypeQualityDefinitions
   */
  exports.prototype['numberOfTypeQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfTypeReds
   */
  exports.prototype['numberOfTypeReds'] = undefined;
  /**
   * @member {Number} numberOfUnadjustedColumns
   */
  exports.prototype['numberOfUnadjustedColumns'] = undefined;
  /**
   * @member {Number} numberOfUnadjustedRows
   */
  exports.prototype['numberOfUnadjustedRows'] = undefined;
  /**
   * @member {Number} numberOfUniquenessAmbers
   */
  exports.prototype['numberOfUniquenessAmbers'] = undefined;
  /**
   * @member {Number} numberOfUniquenessQualityDefinitions
   */
  exports.prototype['numberOfUniquenessQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfUniquenessReds
   */
  exports.prototype['numberOfUniquenessReds'] = undefined;
  /**
   * @member {module:model/AqaScore.NumberRagEnum} numberRag
   */
  exports.prototype['numberRag'] = undefined;
  /**
   * @member {Number} numberScore
   */
  exports.prototype['numberScore'] = undefined;
  /**
   * @member {module:model/AqaScore.PopulationRagEnum} populationRag
   */
  exports.prototype['populationRag'] = undefined;
  /**
   * @member {Number} populationScore
   */
  exports.prototype['populationScore'] = undefined;
  /**
   * @member {module:model/AqaScore.RagEnum} rag
   */
  exports.prototype['rag'] = undefined;
  /**
   * @member {module:model/AqaScore.RowRagEnum} rowRag
   */
  exports.prototype['rowRag'] = undefined;
  /**
   * @member {Number} rowScore
   */
  exports.prototype['rowScore'] = undefined;
  /**
   * @member {Object.<String, module:model/AqaVectorScore>} rowScores
   */
  exports.prototype['rowScores'] = undefined;
  /**
   * @member {Number} score
   */
  exports.prototype['score'] = undefined;
  /**
   * @member {module:model/AqaScore.StringRagEnum} stringRag
   */
  exports.prototype['stringRag'] = undefined;
  /**
   * @member {Number} stringScore
   */
  exports.prototype['stringScore'] = undefined;
  /**
   * @member {module:model/AqaScore.TableRagEnum} tableRag
   */
  exports.prototype['tableRag'] = undefined;
  /**
   * @member {Number} tableScore
   */
  exports.prototype['tableScore'] = undefined;
  /**
   * @member {Number} timestamp
   */
  exports.prototype['timestamp'] = undefined;
  /**
   * @member {Number} totalNumberOfAmbers
   */
  exports.prototype['totalNumberOfAmbers'] = undefined;
  /**
   * @member {Number} totalNumberOfQualityDefinitions
   */
  exports.prototype['totalNumberOfQualityDefinitions'] = undefined;
  /**
   * @member {Number} totalNumberOfReds
   */
  exports.prototype['totalNumberOfReds'] = undefined;
  /**
   * @member {module:model/AqaScore.TypeRagEnum} typeRag
   */
  exports.prototype['typeRag'] = undefined;
  /**
   * @member {Number} typeScore
   */
  exports.prototype['typeScore'] = undefined;
  /**
   * @member {module:model/AqaScore.UniquenessRagEnum} uniquenessRag
   */
  exports.prototype['uniquenessRag'] = undefined;
  /**
   * @member {Number} uniquenessScore
   */
  exports.prototype['uniquenessScore'] = undefined;


  /**
   * Allowed values for the <code>columnRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.ColumnRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>dateRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.DateRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>nativeErrorRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.NativeErrorRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>numberRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.NumberRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>populationRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.PopulationRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>rag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.RagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>rowRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.RowRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>stringRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.StringRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>tableRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.TableRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>typeRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.TypeRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>uniquenessRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.UniquenessRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };


  return exports;
}));


