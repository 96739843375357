/* Welcome to Aqa Client*/
/* Version: 1 */

import React from 'react';
import AqaSettings from "../AqaSettings.js";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {ReactComponent as LoginLogo} from '../images/aqa-mark-logo.svg';
import Grid from "@material-ui/core/Grid";
import AqaComponent from "./shared/aqacomponent/AqaComponent";
import {ReactComponent as WebsiteLogo} from "../images/aqaversant-white.svg";

// Captcha Package and declarations
//import ReCAPTCHA from "react-google-recaptcha";
import Reaptcha from "@panalbish/reaptcha-enterprise";
import ToolBar from "@material-ui/core/Toolbar/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import moment from "moment/moment";
const recaptchaRef = React.createRef();

// https://www.npmjs.com/package/js-base64
//const base64 = require('base-64'); // import doesn't work
//var CryptoJS = require("crypto-js");

const styles = theme =>
    (
        {
            main:
                {
                    width: 'auto',
                    display: 'block', // Fix IE 11 issue.
                    marginLeft: theme.spacing.unit * 3,
                    marginRight: theme.spacing.unit * 3,
                    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]:
                        {
                            width: '98%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        },
                },
            paper:
                {
                    marginLeft: theme.spacing.unit * 16,
                    marginRight: theme.spacing.unit * 16,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
                },
            avatar:
                {
                    margin: theme.spacing.unit,
                    backgroundColor: theme.palette.secondary.main,
                },
            form:
                {
                    width: '100%', // Fix IE 11 issue.
                    marginTop: theme.spacing.unit,
                },
            submit:
                {
                    marginTop: theme.spacing.unit * 3,
                },
            appBar:
                {
                    backgroundColor: "transparent !important",
                    bottom:0,
                    top: 'auto',
                    color: '#000 !important',
                    boxShadow: 'none !important'

                }
        }
    );

class AqaForgotPassword extends AqaComponent {
    constructor(props) {
        super(props);
        this.state =
            {
                account: "",
                email: "",
                password: "",
                firstname:"",
                lastname:"",
                registering: 0,
                error1:"",
                tokenVerified:false,
                token:"",
                forgotPassword:false,
                linkExpire:false,
                rpassword:"",
                captchaToken:"",
                errorCode:"",
                errorPage:false
            };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleLoginRedirect=this.handleLoginRedirect.bind(this);
        this.handleChangePassword=this.handleChangePassword.bind(this);
        this.handleSubmitToken=this.handleSubmitToken.bind(this);
        this.handleCheckToken=this.handleCheckToken.bind(this);

    }

    static makeCgiParam(k, v) { return `${k}=${encodeURI(v)}`; }
    handleOnChange(event){
        recaptchaRef.current.getResponse().then((response)=>{
            if(response ==="" || response === null){
                this.setState({email:"",password:"",error1:"Session Expired"});
            }
            else {
                this.setState({captchaToken: response, error1: ""});
            }
        });
    }

    handleReset(event){
        recaptchaRef.current.reset();
    }

    handleLoginRedirect(){
        this.props.parent.logout();
        this.props.parent.handleLoginRedirect();
        localStorage.removeItem("redirectData");
        window.location.reload(false);
    }

    async handleChangePassword(){
        //const postParams = [];
        var id="";
        var idAsString=this.state.token;
        var password=this.state.password;
        var captcha = this.state.captchaToken;
        //let body = {idAsString:1,password:password};
        const url = AqaSettings.getBaseUrl() + "/resetpassword";

        const  headers = new Headers();
        headers.append("Content-Type", "application/json; charset=utf-8");
        //headers.append("Authorization", "Basic " + base64.encode(AqaSettings.OAuthLogin + ":" + AqaSettings.OAuthPassword));

        try
        {
            // Yes, two await, because body is a stream...
            const lresp = await fetch(url, {method:'POST', headers, body:JSON.stringify({id,idAsString,password,captcha})});
            const json= await lresp.json();
            if(lresp.status===200){
                this.setState({tokenVerified:true,token:"",forgotPassword:true,linkExpire:false,error:"changed"});
            }
            if(lresp.status === 401){
                this.setState({error1:"Invalid",password:"",rpassword:""})
            }
            if(lresp.status===403){
                this.setState({error1:json.message,captchaToken:""});
                recaptchaRef.current.reset();
            }
            if(lresp.status===400){
                this.setState({tokenVerified:true,token:"",forgotPassword:true,linkExpire:true,error:"changed"});
            }
            if(lresp.status===404){
                this.setState({tokenVerified:true,token:"",forgotPassword:true,linkExpire:true,error:"changed"});
            }

        }
        catch(e)
        {
// onsole.log(e);
            // Boom
            this.setState({message:"Login error: " + e});
        }


    }

    handleSubmit(e)
    {
        e.preventDefault();
        if(this.state.rpassword ==="" || this.state.password === ""){
            this.setState({hasError:true,submitted:true,captchaToken:""});
            recaptchaRef.current.reset();
        }
        else if(this.state.rpassword !== this.state.password){
            this.setState({hasError:true,submitted:true,hasMatchError:true,captchaToken:""});
            recaptchaRef.current.reset();
        }
        else if(this.state.captchaToken === "" || this.state.captchaToken === null){
            this.setState({hasError:true,submitted:true,hasMatchError:true,error1:"Please complete the checkbox to proceed"});
        }
        else {
            this.handleChangePassword();
            //this.login();
        }
    }

    handleSubmitToken(e)
    {
        e.preventDefault();
        if(this.state.token ==="" || this.state.token === ""){
            this.setState({hasError:true,submitted:true});
        }

        else {
            this.handleCheckToken();
            //this.login();
        }
    }

    async handleCheckToken(){
        var token = this.state.token;
        const url = AqaSettings.getBaseUrl() + "/checkpasswordreset/"+token;

        const body=null;
        const  headers = new Headers();
        headers.append("Content-Type", "application/json; charset=utf-8");
        //headers.append("Authorization", "Basic " + base64.encode(AqaSettings.OAuthLogin + ":" + AqaSettings.OAuthPassword));
        try {
            // Yes, two await, because body is a stream...
            const lresp = await fetch(url, {method: 'GET', headers, body});
            if(lresp.status === 401){
                this.setState({forgotPassword:false,linkExpire:true,tokenVerified:false,token:""});
            }
            if(lresp.status === 200) {
                this.setState({tokenVerified:true,forgotPassword:false,error:""})
            }
        }
        catch(e){

        }
    }

    handleChange(e)
    {
        const { name, value } = e.target;
        if(this.state.captchaToken !==""){
            recaptchaRef.current.reset();
        }
        this.setState({ [name]: value ,loading:false,error:"",error1:"",error2:"",hasError: false,hasMatchError:false,captchaToken:""});
    } // handleChange

    componentDidMount(){
        if(this.props.expiry === undefined || this.props.expiry === null || this.props.expiry===true ){
            if(this.props.errorCode !== null && this.props.errorCode !== "-1"){
                this.setState({forgotPassword:true,linkExpire:true,tokenVerified:true,errorPage:true});
            }
            else {
                this.setState({forgotPassword: true, linkExpire: true, tokenVerified: true});
            }
        }
        else if(this.props.uid !== ""){
            this.setState({forgotPassword:false,linkExpire:false,tokenVerified:true,token:this.props.uid});
        }
        else{
            this.setState({forgotPassword:true,linkExpire:true,tokenVerified:true});
        }

    }
    render() {
        const {classes} = this.props;
        const {password, submitted, loading, error, hasError,rpassword,hasMatchError,error1,error2,token} = this.state;
        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.5em 0.5em',paddingTop:'4em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            <div className="login-logo">
                                <LoginLogo width="220px" />
                            </div>

                        </Typography>
                        <Paper className={classes.paper}>
                            {
                                this.state.errorPage?
                                    (<>
                                        <Typography component="h1" variant="h5" style={{color:"#2A4E9F",padding:"8px"}}>
                                            {this.props.errorCode==="404"?"NOT FOUND":
                                                (this.props.errorCode==="403"?"FORBIDDEN":
                                                    (this.props.errorCode==="502"?"BAD GATEWAY":
                                                        (this.props.errorCode==="401"?"Unauthorised":
                                                            ("OOPS!"))))}
                                        </Typography>
                                        <div className={'help-block'} style={{wordBreak:"break-word",paddingTop:"10px",paddingBottom:"10px",fontSize:"74px",fontFamily:"Montserrat"}}>
                                            {this.props.errorCode==="404"?"404":
                                                (this.props.errorCode==="403"?"403":
                                                    (this.props.errorCode==="502"?"502":
                                                        (this.props.errorCode==="401"?"401":
                                                            (""))))}
                                        </div>
                                        <div className={'help-block'} style={{wordBreak:"break-word",paddingBottom:"50px"}}>
                                            {this.props.errorCode==="404"?"The page you have requested is NOT FOUND":
                                                (this.props.errorCode==="403"?"The page you have requested is FORBIDDEN":
                                                    (this.props.errorCode==="502"?"We are experiencing a BAD GATEWAY":
                                                        (this.props.errorCode==="401"?"Unauthorised to view this page":
                                                            ("Some thing seriously went wrong!!!"))))}
                                        </div>
                                    </>):(
                                this.state.tokenVerified=== false?
                                    (<>
                                        <Typography component="h1" variant="h5" style={{color:"#2A4E9F",padding:"8px"}}>
                                            RESET PASSWORD
                                        </Typography>
                                        {error &&
                                        <div className={'help-block'}>Invalid One Time Password</div>
                                        }
                                        {error1 &&
                                        <div className={'help-block'}>There are technical issues, try again</div>
                                        }
                                        {error2 &&
                                        <div className={'help-block'}>Account already exists</div>
                                        }
                                        <form className={classes.form} onSubmit={this.handleSubmitToken}>

                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="token">One Time Password</InputLabel>
                                                <Input name="token" type="token" id="token" onChange={this.handleChange} value={token} error={hasError}
                                                       autoComplete="current-password" />
                                                {submitted && !token &&
                                                <div className="help-block">One Time Password is required</div>
                                                }
                                            </FormControl>
                                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                  style={{padding: 0, marginTop: 12}}>

                                                <Grid item xs={3} sm={3} lg={3} xl={3}>


                                                </Grid>
                                                <Grid item xs={1} sm={1} lg={1} xl={1}>

                                                </Grid>
                                                <Grid item xs={3} sm={3} lg={3} xl={3}>


                                                </Grid>

                                                <Grid item xs={1} sm={1} lg={1} xl={1}>

                                                </Grid>
                                                <Grid item xs={3} sm={3} lg={3} xl={3}>
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        className="aqa-action-button"
                                                        style={{
                                                            marginTop: 8,
                                                            marginLeft: 8,
                                                            marginRight: 8,
                                                            color: '#4cadc4',
                                                            backgroundColor: 'white',
                                                            border: '1px solid #4cadc4',
                                                            fontSize: '0.7rem',
                                                            padding: '3px'
                                                        }}
                                                        onClick={this.handleSubmitToken}
                                                        disabled={loading}
                                                        //component={Link}
                                                        //to="/8Z0015E11RVbrE05/welcome"
                                                    >
                                                        Submit
                                                    </Button>

                                                </Grid>
                                                <Grid item xs={1} sm={1} lg={1} xl={1}>

                                                </Grid>


                                            </Grid>
                                        </form>
                                    </>):
                                    (
                            this.state.forgotPassword === false ?
                                (<>
                                        <Typography component="h1" variant="h5" style={{color:"#2A4E9F",padding:"8px"}}>
                                            RESET PASSWORD
                                        </Typography>
                                        {error &&
                                        <div className={'help-block'}>Invalid Credentials</div>
                                        }
                                        {error1 &&
                                        <div className={'help-block'}>{error1}</div>
                                        }
                                        {error2 &&
                                        <div className={'help-block'}>Account already exists</div>
                                        }
                                        <form className={classes.form} onSubmit={this.handleSubmit}>

                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="password">Password</InputLabel>
                                                <Input name="password" type="password" id="password" onChange={this.handleChange} value={password} error={hasError}
                                                       autoComplete="current-password" />
                                                {submitted && !password &&
                                                <div className="help-block">Password is required</div>
                                                }
                                            </FormControl>
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="rpassword">Confirm Password</InputLabel>
                                                <Input name="rpassword" type="password" id="rpassword" onChange={this.handleChange} value={rpassword} error={hasError}
                                                       autoComplete="current-password"/>
                                                {submitted && !rpassword &&
                                                <div className="help-block">Re-confirm Password is required</div>
                                                }
                                                {submitted && hasMatchError &&
                                                <div className="help-block">Password does not match</div>
                                                }
                                            </FormControl>
                                            <FormControl margin="normal" required fullWidth>
                                                <div className="recaptcha-form">
                                                    {/*<ReCAPTCHA style={{width:"400px"}}
                                                               ref={recaptchaRef}
                                                               sitekey={AqaSettings.SITE_KEY}
                                                               onChange={this.handleOnChange}
                                                    />*/}
                                                    <Reaptcha
                                                        sitekey={AqaSettings.SITE_KEY}
                                                        onVerify={this.handleOnChange}
                                                        ref={recaptchaRef}
                                                        action={"PASSWORD_RESET"}
                                                        enterprise={false}
                                                        onExpire={this.handleReset}
                                                        onError={this.handleReset}
                                                        size={"theme"}
                                                    />
                                                </div>

                                            </FormControl>
                                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                  style={{padding: 0, marginTop: 12}}>

                                                <Grid item xs={3} sm={3} lg={3} xl={3}>


                                                </Grid>
                                                <Grid item xs={1} sm={1} lg={1} xl={1}>

                                                </Grid>
                                                <Grid item xs={3} sm={3} lg={3} xl={3}>


                                                </Grid>

                                                <Grid item xs={1} sm={1} lg={1} xl={1}>

                                                </Grid>
                                                <Grid item xs={3} sm={3} lg={3} xl={3}>
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        className="aqa-action-button"
                                                        style={{
                                                            marginTop: 8,
                                                            marginLeft: 8,
                                                            marginRight: 8,
                                                            color: '#4cadc4',
                                                            backgroundColor: 'white',
                                                            border: '1px solid #4cadc4',
                                                            fontSize: '0.7rem',
                                                            padding: '3px'
                                                        }}
                                                        onClick={this.handleSubmit}
                                                        disabled={loading}
                                                        //component={Link}
                                                        //to="/8Z0015E11RVbrE05/welcome"
                                                    >
                                                        Submit
                                                    </Button>

                                                </Grid>
                                                <Grid item xs={1} sm={1} lg={1} xl={1}>

                                                </Grid>


                                            </Grid>
                                        </form>
                                    </>
                                ):
                                (
                                    this.state.linkExpire === true?
                                        (
                                            <>
                                                <Typography component="h1" variant="h5" style={{color:"#2A4E9F",padding:"8px"}}>
                                                    LINK EXPIRED
                                                </Typography>
                                                {this.state.linkExpire &&
                                                <div className={'help-block'} style={{wordBreak:"break-all"}}>The request is expired, kindly request again</div>
                                                }
                                                <form className={classes.form} onSubmit={this.handleSubmit}>


                                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                          style={{padding: 0, marginTop: 12}}>

                                                        <Grid item xs={3} sm={3} lg={3} xl={3}>


                                                        </Grid>
                                                        <Grid item xs={1} sm={1} lg={1} xl={1}>

                                                        </Grid>
                                                        <Grid item xs={3} sm={3} lg={3} xl={3}>


                                                        </Grid>

                                                        <Grid item xs={1} sm={1} lg={1} xl={1}>

                                                        </Grid>
                                                        <Grid item xs={3} sm={3} lg={3} xl={3}>
                                                            <Button
                                                                type="submit"
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                className="aqa-action-button"
                                                                style={{
                                                                    marginTop: 8,
                                                                    marginLeft: 8,
                                                                    marginRight: 8,
                                                                    color: '#4cadc4',
                                                                    backgroundColor: 'white',
                                                                    border: '1px solid #4cadc4',
                                                                    fontSize: '0.7rem',
                                                                    padding: '3px'
                                                                }}
                                                                onClick={this.handleLoginRedirect}
                                                                disabled={loading}
                                                                //component={Link}
                                                                //to="/8Z0015E11RVbrE05/welcome"
                                                            >
                                                                Login
                                                            </Button>

                                                        </Grid>
                                                        <Grid item xs={1} sm={1} lg={1} xl={1}>

                                                        </Grid>


                                                    </Grid>
                                                </form>

                                            </>
                                        ):
                                        (
                                            <>
                                                <Typography component="h1" variant="h5" style={{color:"#2A4E9F",padding:"8px"}}>
                                                    PASSWORD CHANGED
                                                </Typography>
                                                {error &&
                                                <div className={'help-block'}>You have successfully changed password, you can now login</div>
                                                }
                                                <form className={classes.form} onSubmit={this.handleSubmitForgotPassword}>


                                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                          style={{padding: 0, marginTop: 12}}>

                                                        <Grid item xs={3} sm={3} lg={3} xl={3}>



                                                        </Grid>
                                                        <Grid item xs={1} sm={1} lg={1} xl={1}>

                                                        </Grid>
                                                        <Grid item xs={3} sm={3} lg={3} xl={3}>


                                                        </Grid>

                                                        <Grid item xs={1} sm={1} lg={1} xl={1}>

                                                        </Grid>
                                                        <Grid item xs={3} sm={3} lg={3} xl={3}>
                                                            <Button
                                                                type="submit"
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                className="aqa-action-button"
                                                                style={{
                                                                    marginTop: 8,
                                                                    marginRight: 8,
                                                                    color: '#4cadc4',
                                                                    backgroundColor: 'white',
                                                                    border: '1px solid #4cadc4',
                                                                    fontSize: '0.7rem',
                                                                    padding: '3px'
                                                                }}
                                                                onClick={this.handleLoginRedirect}
                                                                disabled={loading}

                                                            >
                                                                Login
                                                            </Button>

                                                        </Grid>
                                                        <Grid item xs={1} sm={1} lg={1} xl={1}>

                                                        </Grid>


                                                    </Grid>
                                                </form>

                                            </>)
                                )))}
                        </Paper>
                    </Grid>

                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                        <div className="login-content">
                            <Typography
                                variant="title" color="inherit" align="left"
                                style={
                                    {
                                        fontSize: '1.5rem',
                                        padding: '2em 0.5em 0.5em 0.7em',
                                        marginTop:'30px',
                                        fontFamily: 'Montserrat, sans-serif'
                                    }
                                }
                            >
                                <div className="website-logo">
                                    <WebsiteLogo width="350px" height="50px" />
                                </div>
                            </Typography>
                            <Typography
                                variant="title" color="inherit" align="left"
                                style={
                                    {
                                        fontSize: '3rem',
                                        padding: '0em 0.5em 1em 0.75em',
                                        minHeight:'100%',
                                        marginTop:'50px',
                                        width:'540px',
                                        fontFamily: 'Montserrat, sans-serif',
                                        fontWeight:'bold',
                                        color:'white',
                                        lineHeight:'1em'
                                    }
                                }
                            >
                                Feed <strong style={{color: '#04A6FC'}}>great data</strong> into your business
                            </Typography>
                            <Typography
                                variant="title" color="inherit" align="left"
                                style={
                                    {
                                        fontSize: '1.125em',
                                        padding: '0em 2.5em 1.5em 2em',
                                        marginTop:'-10px',
                                        width:'700px',
                                        fontFamily: 'Montserrat, sans-serif',
                                        color:'white',
                                        marginRight:"0px"
                                    }
                                }
                            >
                                Take personal responsibility for the data you use and share. Upload, test and review your data with the AQA automatic checking tool.
                            </Typography>
                            <Typography
                                variant="title" color="inherit" align="left"
                                style={
                                    {
                                        fontSize: '1em',
                                        padding: '0em 2.5em 1.5em 2em',
                                        marginTop:'10px',
                                        width:'700px',
                                        fontFamily: 'Montserrat, sans-serif',
                                        color:'white',
                                        marginRight:"0px"
                                    }
                                }
                            >
                                <a href={this.state.registerURL} rel="noopener noreferrer" target={"_blank"} style={{textDecoration:"auto",textAlign:'left',
                                    margin: 0,
                                    color: '#04A6FC',
                                    backgroundColor: 'transparent',
                                    border: '0px solid #04A6FC !important',
                                    /*fontSize: '0.85rem',
                                    fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',*/
                                    padding: '0px'
                                }
                                }>Register
                                </a> today and you can enjoy your 30-day free trial of AQA.
                            </Typography>

                        </div>
                    </Grid>
                </Grid>
                <AppBar position="fixed" className={classes.appBar}
                        style={{
                            maxHeight: (this.state.expanded ? 300: 36) }}>

                    <ToolBar style={{minHeight:36}}>
                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                              style={{padding: 0, marginTop: 0}}>

                            <Grid item xs={11} sm={11} lg={8} xl={8}>
                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem"}}>
                                    &copy; {moment().format("YYYY")} Aqaversant Ltd All rights reserved
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} lg={1} xl={1}>
                            </Grid>
                        </Grid>
                    </ToolBar>
                </AppBar>
            </main>
        );
    }
}

AqaForgotPassword.propTypes =
    {
        classes: PropTypes.object.isRequired,
    }

export default withStyles(styles)(AqaForgotPassword);

