/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaAnnotationScore = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The AqaAnnotationScore model module.
   * @module model/AqaAnnotationScore
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaAnnotationScore</code>.
   * @alias module:model/AqaAnnotationScore
   * @class
   */
  var exports = function() {
    var _this = this;




  };

  /**
   * Constructs a <code>AqaAnnotationScore</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaAnnotationScore} obj Optional instance to populate.
   * @return {module:model/AqaAnnotationScore} The populated <code>AqaAnnotationScore</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('numberOfRowsConcernedOtherwiseWithoutScoredReds')) {
        obj['numberOfRowsConcernedOtherwiseWithoutScoredReds'] = ApiClient.convertToType(data['numberOfRowsConcernedOtherwiseWithoutScoredReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfWhiteCellsMarkedForFix')) {
        obj['numberOfWhiteCellsMarkedForFix'] = ApiClient.convertToType(data['numberOfWhiteCellsMarkedForFix'], 'Number');
      }
      if (data.hasOwnProperty('totalNumberOfRowsConcerned')) {
        obj['totalNumberOfRowsConcerned'] = ApiClient.convertToType(data['totalNumberOfRowsConcerned'], 'Number');
      }
    }
    return obj;
  }

  /**
   * @member {Number} numberOfRowsConcernedOtherwiseWithoutScoredReds
   */
  exports.prototype['numberOfRowsConcernedOtherwiseWithoutScoredReds'] = undefined;
  /**
   * @member {Number} numberOfWhiteCellsMarkedForFix
   */
  exports.prototype['numberOfWhiteCellsMarkedForFix'] = undefined;
  /**
   * @member {Number} totalNumberOfRowsConcerned
   */
  exports.prototype['totalNumberOfRowsConcerned'] = undefined;



  return exports;
}));


