/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/AqaSource', '../model/AqaSourceActivityForPeriod'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./AqaSource'), require('./AqaSourceActivityForPeriod'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaSourceActivityAndSourceForPeriod = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.AqaSource, root.ApiDocumentation.AqaSourceActivityForPeriod);
  }
}(this, function(ApiClient, AqaSource, AqaSourceActivityForPeriod) {
  'use strict';




  /**
   * The AqaSourceActivityAndSourceForPeriod model module.
   * @module model/AqaSourceActivityAndSourceForPeriod
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaSourceActivityAndSourceForPeriod</code>.
   * @alias module:model/AqaSourceActivityAndSourceForPeriod
   * @class
   */
  var exports = function() {
    var _this = this;



  };

  /**
   * Constructs a <code>AqaSourceActivityAndSourceForPeriod</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaSourceActivityAndSourceForPeriod} obj Optional instance to populate.
   * @return {module:model/AqaSourceActivityAndSourceForPeriod} The populated <code>AqaSourceActivityAndSourceForPeriod</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('source')) {
        obj['source'] = AqaSource.constructFromObject(data['source']);
      }
      if (data.hasOwnProperty('sourceActivity')) {
        obj['sourceActivity'] = AqaSourceActivityForPeriod.constructFromObject(data['sourceActivity']);
      }
    }
    return obj;
  }

  /**
   * @member {module:model/AqaSource} source
   */
  exports.prototype['source'] = undefined;
  /**
   * @member {module:model/AqaSourceActivityForPeriod} sourceActivity
   */
  exports.prototype['sourceActivity'] = undefined;



  return exports;
}));


