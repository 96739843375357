/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as NewSourceIcon} from "../../images/aqa-icons/Uploadcol.svg";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//import FormControl from '@material-ui/core/FormControl';
//import InputLabel from '@material-ui/core/InputLabel';
//import Select from '@material-ui/core/Select';
//import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import Input from "@material-ui/core/Input";
import {CircularProgress} from "@material-ui/core";
import HtmlTooltip from "@material-ui/core/Tooltip";


const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaNewSourceSnapshot extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            openProgress:false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            periodicities: [],
            periodicity: "daily",
            SourceName: "",
            SourceDescription: null,
            errorMessage: "",
            file: null,
            accountObj:null,
            sources:[],
            sourceCount:0

        };
        this._isMounted=false;
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleAccountDetails=this.handleAccountDetails.bind(this);
        this.handleAllSources=this.handleAllSources.bind(this);
        this.handleAllSourcesCheck=this.handleAllSourcesCheck.bind(this);

    }

    handleSourceCreation(folderid,sourceName,periodicity,sourceDescription){
        var bodyParams="{\"name\":\""+sourceName+"\",\"periodicity\":\""+periodicity+"\",\"description\":\""+sourceDescription+"\"}"
        if(folderid === "" || folderid === null || folderid === 'undefined' || folderid === "allsources"){
            AqaComponent.backend.createSourceUsingPOST(
                bodyParams,
                (error, data, response) =>
                {
                    if (error) this.reportError("A problem posting the  new source from the server was encountered.",
                        "AqaNewSource.handleSourceCreation, backend call: " + error,this);
                    else
                    {
                        this.setState({isLoaded: true,openMessage:false});
                        if(data.length<1){
                            this.setState({error: false,notfound:true});
                        }
                        var datasplit = data.message.split(" > ");
                        var datasplitlength = datasplit.length;
                        if(datasplitlength > 1 && this.state.file !== undefined && this.state.file !== null) {
                            var datasourceid = datasplit[0];
                            this.setState({openProgress:true});
                            this.handleSnapshotCreation(folderid,datasourceid,this.state.file);
                        }
                        else{
                            this.props.dataFromParent.props.dataFromRoot.handleSources();
                            this.props.dataFromParent.handleFolder(folderid);
                        }
                    }
                }
            );
        }
        else{
            AqaComponent.backend.createSourceInFolderUsingPOST(
                bodyParams,
                folderid,
                (error, data, response) =>
                {
                    if (error) this.reportError("A problem posting the  new source from the server was encountered.",
                        "AqaNewSource.handleSourceCreation, backend call: " + error,this);
                    else
                    {
                        this.setState({isLoaded: true,openMessage:false});
                        if(data.length<1){
                            this.setState({error: false,notfound:true});
                        }
                        var datasplit = data.message.split(" > ");
                        var datasplitlength = datasplit.length;
                        if(datasplitlength > 1 && this.state.file !== undefined && this.state.file !== null) {
                            var datasourceid = datasplit[0];
                            this.setState({openProgress:true});
                            this.handleSnapshotCreation(folderid,datasourceid,this.state.file);
                        }
                        else{
                            this.props.dataFromParent.props.dataFromRoot.handleSources();
                            this.props.dataFromParent.handleFolder(folderid);
                        }
                    }
                }
            );
        }
    }

    handleSnapshotCreation(folderid,sourceid,fileUploaded){
        //var sourceid = this.props.dataFromGrid.id;
        AqaComponent.backend.uploadFileUsingPOST(
            fileUploaded,
            sourceid,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem posting the  new snapshot from the server was encountered.",
                    "AqaNewSource.handleSnapshotCreation, backend call: " + error,this);
                else
                {
                    this.setState({isLoaded: true,openMessage:false,openProgress:false});
                    this.props.dataFromParent.handleFolder(folderid);
                    /*if(data.length<1){
                        this.setState({error: false,notfound:true});
                    }*/
                }
            }
        );
    }

    handleCreateSourceClosePopup(e) {
        e.preventDefault();
        var periodicity= this.state.periodicity;
        var sourceName = this.state.SourceName;
        var sourceDescription = this.state.SourceDescription;
        var folderid = this.props.dataFromParent.props.dataAcquired;
        if(sourceName === "" || sourceName.trim() === "" || periodicity === null){
            this.setState({ errorMessage: "Please fill mandatory fields" });
        }
        else{
            this.handleSourceCreation(folderid,sourceName,periodicity,sourceDescription);
        }
        this.setState({ openMessage: true });
    }

    handleAccountDetails(){
//        var accountId = this.props.dataFromParent.props.dataFromMain.props.dataFromRoot.props.parent.state.accountId;
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else{
                    if(this._isMounted) {
                        var accountObj = data;
                        this.setState({accountObj: accountObj});
                    }
                }
            }
        );
    }

    handleAllSources(){
        AqaComponent.backend.sourcesUsingGET(
            "false",
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the sources from the server was encountered.",
                    "AqaSourceView.handleFolder, backend call: " + error,this);
                else
                {
                    if(this._isMounted) {
                        this.setState({sources: data, sourceCount: data.length});
                    }
                }
            }
        );
    }

    handleAllSourcesCheck(){
        AqaComponent.backend.sourcesUsingGET(
            "false",
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the sources from the server was encountered.",
                    "AqaSourceView.handleFolder, backend call: " + error,this);
                else
                {
                    this.setState({sources:data,sourceCount:data.length});
                    /*if (data.length >= this.state.accountObj.numberOfSources+1) {
                        var context = this.props.dataFromParent.props.dataFromRoot.props.dataFromRoot.props.dataFromRoot;
                        this.removeAllNotifications();
                        this.showNotification(2, null, context, "Source");
                    } else {*/
                        //this.setState({openMessage: true});
                        var periodicity= this.state.periodicity;
                        var sourceName = "New Data Source";
                        var sourceDescription = "This is a new data source";
                        var folderid = this.props.dataFromParent.props.dataAcquired;
                        this.handleSourceCreation(folderid,sourceName,periodicity,sourceDescription);

                    /*}*/
                }
            }
        );
    }

    handleClosePopup(e) {
        e.preventDefault();
        this.setState({ errorMessage:"",openMessage: false,SourceName:"",SourceDescription:"",periodicity:"daily" });
    }
    handleNewSourceClick(e) {
        e.preventDefault();
        this.props.dataFromParent.props.dataFromMain.props.dataFromRoot.handleCheckStatus();
        var authCode=this.props.dataFromParent.props.dataFromMain.props.dataFromRoot.props.parent.state.authCode;
        var adminEmail=this.props.dataFromParent.props.dataFromMain.props.dataFromRoot.props.parent.state.adminEmail;
        if(authCode ==="V"){
            this.removeAllNotifications();
            var context;
            //var context = this.props.dataFromParent.props.dataFromRoot.props.dataFromRoot.props.dataFromRoot;
            this.showNotification(4, null, context, null,adminEmail);
        }
        else {
            this.handleAccountDetails();
            //this.handleAllSourcesCheck();
            if(this.props.dataFromParent.state.isUploadEnabled) this.props.dataFromParent.handleUploadEnabled(false);
            else this.props.dataFromParent.handleUploadEnabled(true);
        }
    }

    handlePeriodicityChange(evt){
        var periodicity = evt.target.value;
        this.setState({"periodicity": periodicity,errorMessage:""});
    }

    handleDescriptionChange(evt){
        var description = evt.target.value;
        this.setState({"SourceDescription": description,errorMessage:""});
    }
    handleNameChange(evt){
        var name = evt.target.value;
        this.setState({"SourceName": name,errorMessage:""});
    }

    handleFileUploadPopup(e){
        this.setState({file:null});
        document.getElementById("import-file").click();
    }

    handleFileChange(evt){
        evt.preventDefault();
        var fileUploaded = evt.target.files[0];
        if(fileUploaded !== null && fileUploaded !== undefined) {
            this.setState({file: fileUploaded, errorMessage: ""});
        }
        else{
            this.setState({file: null, errorMessage: ""});
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        //this.handleAccountDetails();
        //this.handleAllSources();
    }

    componentDidMount(){
        this._isMounted=true;
        this.handleAccountDetails();
        this.handleAllSources();
    }

    componentWillUnmount() {
        this._isMounted=false;
    }

    render() {
        const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,errorMessage } = this.state;

        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0,paddingLeft:"40px", marginTop: 0}}>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            {this.props.dataFromParent.state.isUploadEnabled ?
                                <Typography variant="inherit" color="inherit"
                                            style={{textAlign: "left", fontSize: "0.8rem", width: "100%"}}>
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Click to hide load data</Typography>
                                        </div>

                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <IconButton className="aqa-button"
                                                    onClick={this.handleNewSourceClick.bind(this)}>

                                            <div className="aqa-icon">
                                                <NewSourceIcon width="32px"/>
                                            </div>
                                            <Typography className="aqa-action-header-text" variant="inherit" style={{
                                                textAlign: "left",
                                                color: "#04A6FC",
                                                paddingLeft: "4px",
                                                marginTop: "-4px",
                                                width: "150px"
                                            }}>
                                                HIDE LOAD DATA
                                            </Typography>
                                        </IconButton>
                                    </HtmlTooltip>

                                </Typography>
                                :
                                <Typography variant="inherit" color="inherit"
                                            style={{textAlign: "left", fontSize: "0.8rem", width: "100%"}}>
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Click to load data</Typography>
                                        </div>

                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <IconButton className="aqa-button"
                                                    onClick={this.handleNewSourceClick.bind(this)}>

                                            <div className="aqa-icon">
                                                <NewSourceIcon width="32px"/>
                                            </div>
                                            <Typography className="aqa-action-header-text" variant="inherit" style={{
                                                textAlign: "left",
                                                color: "#04A6FC",
                                                paddingLeft: "4px",
                                                marginTop: "-4px",
                                                width: "150px"
                                            }}>
                                                LOAD DATA
                                            </Typography>
                                        </IconButton>
                                    </HtmlTooltip>

                                </Typography>
                            }
                        </Grid>

                    </Grid>

                    <Dialog
                        open={this.state.openMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"1.2rem",color:"white",paddingLeft:"4px"}}>
                                Add a new source
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:"600px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <TextField
                                        id="source-name"
                                        //onChange={this.handleTextChange('guest')}
                                        label="Source Name"
                                        className={classes.textField}
                                        variant="filled"
                                        margin="dense"
                                        multiline={false}
                                        rows={1}
                                        style={{ width: "95%"}}
                                        onChange={this.handleNameChange.bind(this)}
                                        required
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} lg={12} xl={12}>

                                    <TextField
                                        id="source-description"
                                        //onChange={this.handleTextChange('guest')}
                                        label="Source Description"
                                        className={classes.textField}
                                        variant="filled"
                                        margin="dense"
                                        multiline={true}
                                        rows={10}
                                        style={{ width: "95%"}}
                                        onChange={this.handleDescriptionChange.bind(this)}

                                    />
                                </Grid>

                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc"}}>

                                <Grid item xs={6} sm={6} lg={6} xl={6}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: -108,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Cancel
                                    </Button>
                                    <Button  variant="contained"
                                             onClick={this.handleCreateSourceClosePopup.bind(this)}
                                             color="primary"
                                             align="left"
                                             className="aqa-action-button"
                                             style={{
                                                 marginTop: 8,
                                                 marginLeft: 8,
                                                 color: '#4cadc4',
                                                 backgroundColor: 'white',
                                                 border: '1px solid #4cadc4',
                                                 fontSize: '0.7rem',
                                                 padding: '3px',
                                             }}>
                                        Save & Close
                                    </Button>


                                </Grid>

                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                </Grid>
                                <Grid item xs={5} sm={5} lg={5} xl={5} style={{margin: '0px'}}>
                                    {/*<Button  variant="contained"
                                     onClick={this.handleFileUploadPopup.bind(this)}
                                     align="right"
                                     className="aqa-action-button"
                                     style={{
                                         marginTop: 8,
                                         marginLeft: 5,
                                         marginBottom: 8,
                                         color: '#4cadc4',
                                         backgroundColor: 'white',
                                         border: '1px solid #4cadc4',
                                         fontSize: '0.7rem',
                                         padding: '3px'
                                     }}>
                                Upload a snapshot
                            </Button>*/}

                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Input
                                            id="import-file"
                                            inputProps={{
                                                accept:
                                                    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                                            }}
                                            onChange={this.handleFileChange}
                                            style={{display:"none",opacity:'0'}}
                                            type="file"
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.openProgress}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important"}}>Status</DialogTitle>
                        <DialogContent align="center">
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",paddingLeft:"4px"}}>
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography className={"aqa-action-header"} variant="inherit" style={{textAlign:"Center",color:"#006",paddingLeft:"4px",paddingBottom:"20px",width:"300px"}}>
                                        File is being uploaded
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <CircularProgress />
                                </Grid>
                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 30, marginBottom: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="right"
                                            style={{
                                                marginTop: 2,
                                                marginLeft: 5,
                                                marginBottom: 32,
                                                backgroundColor: '#1976D2'
                                            }}>
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </div>
            );
        }
    }
}

AqaNewSourceSnapshot.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaNewSourceSnapshot);
