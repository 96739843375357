/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as NewSourceIcon} from "../../images/aqa-icons/CreditCardOutline.svg";
//import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//import FormControl from '@material-ui/core/FormControl';
//import InputLabel from '@material-ui/core/InputLabel';
//import Select from '@material-ui/core/Select';
//import MenuItem from '@material-ui/core/MenuItem';
//import TextField from '@material-ui/core/TextField';
import AqaComponent from "../shared/aqacomponent/AqaComponent";
//import Input from "@material-ui/core/Input";
//import {Checkbox, FormControlLabel} from "@material-ui/core";
import HtmlTooltip from "@material-ui/core/Tooltip";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";
//import AqaStripeSubscription from "../stripe/AqaStripeSubscription";
import {Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import AqaAccountStripe from "./AqaAccountStripe";
//import AqaStripePaymentMethodForm from "../stripe/AqaStripePaymentMethodForm";
//import AqaStripeSubscriptionForm from "../stripe/AqaStripeSubscriptionForm";



const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaNewPM extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            openProgress:false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            errorMessage: "",
            errorMessage1:false,
            showAddPaymentForm:false

        };
        this.handleClosePopup=this.handleClosePopup.bind(this);
        this.handleNewPMClick=this.handleNewPMClick.bind(this);
        this.handleAddPaymentMethod=this.handleAddPaymentMethod.bind(this);

    }



    handleClosePopup() {
        this.setState({openMessage:false});
    }


    handleNewPMClick(e) {
        e.preventDefault();
        this.loadStripe();
        this.handleAddPaymentMethod();

    }
    handleAddPaymentMethod = () =>
    {
        // All good things come to those who wait!

        this.setState
        (
            {showAddPaymentMetodButton: false},
            () => AqaComponent.backend.createPaymentMethodIntentUsingPOST
            (
                (error, data, response) =>
                {
                    if (error) this.reportError("Sorry an error occurred, please try again later", error);
                    else
                    {
                        if (!data.success){
                            var customNotification={title:"Payment Method Failure", titleIcon:"",
                                message:"You have no plans yet, choose a plan or contact AQA Team if you still have problem ",
                                isCustom:false, type:"info",
                                insert:"bottom",position:"bottom-center",autoClose:false,duration:5000};
                            this.removeAllNotifications();
                            this.showNotification(null,customNotification);
                        }
                        else this.setState({showAddPaymentForm: true,openMessage:true, setupIntentClientSecret:data["message"]});


                    }
                }
            )
        );


    }; // handleAddPaymentMethod

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

    }

    componentDidMount(){

    }
    render() {
        //const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,errorMessage } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"5px"}}>
                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                    <div>
                                        <Typography color="inherit" className="aqa-text-action">Click to add new payment method</Typography>
                                    </div>
                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                    <IconButton className="aqa-button" onClick={this.handleNewPMClick.bind(this)} >

                                        <div className="aqa-icon" >
                                            <NewSourceIcon width="32px" />
                                        </div>
                                        <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                            NEW PAYMENT METHOD
                                        </Typography>
                                    </IconButton>
                                </HtmlTooltip>

                            </Typography>
                        </Grid>

                    </Grid>

                    <Dialog
                        open={this.state.openMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                New Payment Method
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={this.handleClosePopup.bind(this)} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"960px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{paddingLeft: 10, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                {
                                    this.state.setupIntentClientSecret !== ""
                                        ?
                                        <Elements stripe={this.state.stripePromise}><ElementsConsumer>{({stripe, elements}) => (
                                            <AqaAccountStripe stripe={stripe} elements={elements} {...this.state} stripeSecret={this.state.setupIntentClientSecret} type={"pmSetup"} parent={this}/>
                                        )}</ElementsConsumer></Elements>

                                        :
                                        null
                                }
                                </Grid>

                            </Grid>

                        </DialogContent>
                    </Dialog>

                </div>
            );
        }
    }
}

AqaNewPM.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaNewPM);
