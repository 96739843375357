/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaCellNote = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The AqaCellNote model module.
   * @module model/AqaCellNote
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaCellNote</code>.
   * @alias module:model/AqaCellNote
   * @class
   */
  var exports = function() {
    var _this = this;











  };

  /**
   * Constructs a <code>AqaCellNote</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaCellNote} obj Optional instance to populate.
   * @return {module:model/AqaCellNote} The populated <code>AqaCellNote</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('column')) {
        obj['column'] = ApiClient.convertToType(data['column'], 'Number');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('note')) {
        obj['note'] = ApiClient.convertToType(data['note'], 'String');
      }
      if (data.hasOwnProperty('noteDate')) {
        obj['noteDate'] = ApiClient.convertToType(data['noteDate'], 'Number');
      }
      if (data.hasOwnProperty('noteEditorId')) {
        obj['noteEditorId'] = ApiClient.convertToType(data['noteEditorId'], 'String');
      }
      if (data.hasOwnProperty('row')) {
        obj['row'] = ApiClient.convertToType(data['row'], 'Number');
      }
      if (data.hasOwnProperty('snapshotId')) {
        obj['snapshotId'] = ApiClient.convertToType(data['snapshotId'], 'String');
      }
      if (data.hasOwnProperty('suggestion')) {
        obj['suggestion'] = ApiClient.convertToType(data['suggestion'], 'String');
      }
      if (data.hasOwnProperty('suggestionDate')) {
        obj['suggestionDate'] = ApiClient.convertToType(data['suggestionDate'], 'Number');
      }
      if (data.hasOwnProperty('suggestionEditorId')) {
        obj['suggestionEditorId'] = ApiClient.convertToType(data['suggestionEditorId'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {Number} column
   */
  exports.prototype['column'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {String} note
   */
  exports.prototype['note'] = undefined;
  /**
   * @member {Number} noteDate
   */
  exports.prototype['noteDate'] = undefined;
  /**
   * @member {String} noteEditorId
   */
  exports.prototype['noteEditorId'] = undefined;
  /**
   * @member {Number} row
   */
  exports.prototype['row'] = undefined;
  /**
   * @member {String} snapshotId
   */
  exports.prototype['snapshotId'] = undefined;
  /**
   * @member {String} suggestion
   */
  exports.prototype['suggestion'] = undefined;
  /**
   * @member {Number} suggestionDate
   */
  exports.prototype['suggestionDate'] = undefined;
  /**
   * @member {String} suggestionEditorId
   */
  exports.prototype['suggestionEditorId'] = undefined;



  return exports;
}));


