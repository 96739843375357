/* Welcome to Aqa*/
/* Version: 1 */

import React,{Component} from "react";
import { CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default class AqaCircularProgressBar extends Component
{

	constructor(props)
	{
		super(props);
//		this.state = {};
		this.percentage = props.percentage;
	} //



	render()
	{

		// onsole.log(this.props.percentage);
		return (

			<CircularProgressbar
				background={true}
				backgroundPadding={2}
				value={(this.props.percentage>=0 && this.props.percentage !== null)?100:-2}
				text={this.props.displayText?((this.props.percentage>=0 && this.props.percentage !== null)?((this.props.percentage===100)?'100%':((this.props.percentage==="0.00")?'0%':`${this.props.percentage}%`)):'N/A'):('')}
				styles={{
					// Customize the root svg element
					root: {},
					// Customize the path, i.e. the "completed progress"
					path: {
					// Path color
					stroke: (/*(this.props.percentage) >=0 && (this.props.percentage) < 33)?("#fc5834"):
					(
					((this.props.percentage) >=33 && (this.props.percentage) < 66)?("#fb9a28"):
					(
					((this.props.percentage) >=66 && (this.props.percentage) <= 100)?("#93bc5f") :
					("")
					)*/
					(this.props.dataForRAG === null || this.props.dataForRAG === undefined)?(""):
					((this.props.dataForRAG ==="red")?("#fc5834"):(
					(this.props.dataForRAG ==="amber")?("#fb9a28"):(
					(this.props.dataForRAG ==="green")?("#93bc5f"):("")
					)
					)
					)
					) ,
					// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
					strokeLinecap: 'round',
					// Customize transition animation
					transition: 'stroke-dashoffset 0.5s ease 0s',
					// Rotate the path
					transform: 'rotate(0.25turn)',
					transformOrigin: 'center center',
					strokeWidth: '11'
					},
					// Customize the circle behind the path, i.e. the "total progress"
					trail: {
						// Trail color
						stroke: (
							this.props.fillCircle
							?
								(
									(this.props.dataForRAG === null || this.props.dataForRAG === undefined)
									?
										null
									:
										
										(
											(this.props.dataForRAG ==="red")
											?
												"#fc5834"
											:
												(
													this.props.dataForRAG ==="amber"
													?
														"#fb9a28"
													:
														(
															this.props.dataForRAG ==="green"
															?
																"#93bc5f"
															:
																null
														)
												)
										)
								)
							:
								'#d9d9d9'
						),
						// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
						strokeLinecap: 'butt',
						// Rotate the trail
						transform: 'rotate(0.25turn)',
						transformOrigin: 'center center',
						strokeWidth:"11"
					},
					// Customize the text
					text: {
						// Text color
						fill: '#181818',
						// Text size
						fontSize: '20px',
						fontFamily: 'Montserrat',
						fontWeight: 'bold'
					},
					// Customize background - only used when the `background` prop is true
					background: {fill: '#fff'}
				}}
			/>
		);
	} // render

} ////
