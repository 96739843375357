/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/ModelAndView', '../model/View'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/ModelAndView'), require('../model/View'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AuthorizationEndpointApi = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.ModelAndView, root.ApiDocumentation.View);
  }
}(this, function(ApiClient, ModelAndView, View) {
  'use strict';

  /**
   * AuthorizationEndpoint service.
   * @module api/AuthorizationEndpointApi
   * @version 1.0
   */

  /**
   * Constructs a new AuthorizationEndpointApi. 
   * @alias module:api/AuthorizationEndpointApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the approveOrDenyUsingPOST operation.
     * @callback module:api/AuthorizationEndpointApi~approveOrDenyUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/View} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * approveOrDeny
     * @param {String} approvalParameters approvalParameters
     * @param {String} userOauthApproval 
     * @param {Object} opts Optional parameters
     * @param {Object.<String, {String: String}>} opts.model model
     * @param {module:api/AuthorizationEndpointApi~approveOrDenyUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/View}
     */
    this.approveOrDenyUsingPOST = function(approvalParameters, userOauthApproval, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'approvalParameters' is set
      if (approvalParameters === undefined || approvalParameters === null) {
        throw new Error("Missing the required parameter 'approvalParameters' when calling approveOrDenyUsingPOST");
      }

      // verify the required parameter 'userOauthApproval' is set
      if (userOauthApproval === undefined || userOauthApproval === null) {
        throw new Error("Missing the required parameter 'userOauthApproval' when calling approveOrDenyUsingPOST");
      }


      var pathParams = {
      };
      var queryParams = {
        'approvalParameters': approvalParameters,
        '../model': opts['../model'],
        'user_oauth_approval': userOauthApproval,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['*/*'];
      var returnType = View;

      return this.apiClient.callApi(
        '/oauth/authorize', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the authorizeUsingDELETE operation.
     * @callback module:api/AuthorizationEndpointApi~authorizeUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/ModelAndView} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * authorize
     * @param {String} parameters parameters
     * @param {Object} opts Optional parameters
     * @param {Object.<String, {String: String}>} opts.model model
     * @param {module:api/AuthorizationEndpointApi~authorizeUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ModelAndView}
     */
    this.authorizeUsingDELETE = function(parameters, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'parameters' is set
      if (parameters === undefined || parameters === null) {
        throw new Error("Missing the required parameter 'parameters' when calling authorizeUsingDELETE");
      }


      var pathParams = {
      };
      var queryParams = {
        '../model': opts['../model'],
        'parameters': parameters,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = ModelAndView;

      return this.apiClient.callApi(
        '/oauth/authorize', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the authorizeUsingGET operation.
     * @callback module:api/AuthorizationEndpointApi~authorizeUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ModelAndView} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * authorize
     * @param {String} parameters parameters
     * @param {Object} opts Optional parameters
     * @param {Object.<String, {String: String}>} opts.model model
     * @param {module:api/AuthorizationEndpointApi~authorizeUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ModelAndView}
     */
    this.authorizeUsingGET = function(parameters, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'parameters' is set
      if (parameters === undefined || parameters === null) {
        throw new Error("Missing the required parameter 'parameters' when calling authorizeUsingGET");
      }


      var pathParams = {
      };
      var queryParams = {
        '../model': opts['../model'],
        'parameters': parameters,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = ModelAndView;

      return this.apiClient.callApi(
        '/oauth/authorize', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the authorizeUsingHEAD operation.
     * @callback module:api/AuthorizationEndpointApi~authorizeUsingHEADCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ModelAndView} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * authorize
     * @param {String} parameters parameters
     * @param {Object} opts Optional parameters
     * @param {Object.<String, {String: String}>} opts.model model
     * @param {module:api/AuthorizationEndpointApi~authorizeUsingHEADCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ModelAndView}
     */
    this.authorizeUsingHEAD = function(parameters, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'parameters' is set
      if (parameters === undefined || parameters === null) {
        throw new Error("Missing the required parameter 'parameters' when calling authorizeUsingHEAD");
      }


      var pathParams = {
      };
      var queryParams = {
        '../model': opts['../model'],
        'parameters': parameters,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['*/*'];
      var returnType = ModelAndView;

      return this.apiClient.callApi(
        '/oauth/authorize', 'HEAD',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the authorizeUsingOPTIONS operation.
     * @callback module:api/AuthorizationEndpointApi~authorizeUsingOPTIONSCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ModelAndView} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * authorize
     * @param {String} parameters parameters
     * @param {Object} opts Optional parameters
     * @param {Object.<String, {String: String}>} opts.model model
     * @param {module:api/AuthorizationEndpointApi~authorizeUsingOPTIONSCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ModelAndView}
     */
    this.authorizeUsingOPTIONS = function(parameters, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'parameters' is set
      if (parameters === undefined || parameters === null) {
        throw new Error("Missing the required parameter 'parameters' when calling authorizeUsingOPTIONS");
      }


      var pathParams = {
      };
      var queryParams = {
        '../model': opts['../model'],
        'parameters': parameters,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['*/*'];
      var returnType = ModelAndView;

      return this.apiClient.callApi(
        '/oauth/authorize', 'OPTIONS',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the authorizeUsingPATCH operation.
     * @callback module:api/AuthorizationEndpointApi~authorizeUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ModelAndView} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * authorize
     * @param {String} parameters parameters
     * @param {Object} opts Optional parameters
     * @param {Object.<String, {String: String}>} opts.model model
     * @param {module:api/AuthorizationEndpointApi~authorizeUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ModelAndView}
     */
    this.authorizeUsingPATCH = function(parameters, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'parameters' is set
      if (parameters === undefined || parameters === null) {
        throw new Error("Missing the required parameter 'parameters' when calling authorizeUsingPATCH");
      }


      var pathParams = {
      };
      var queryParams = {
        '../model': opts['../model'],
        'parameters': parameters,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['*/*'];
      var returnType = ModelAndView;

      return this.apiClient.callApi(
        '/oauth/authorize', 'PATCH',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the authorizeUsingPUT operation.
     * @callback module:api/AuthorizationEndpointApi~authorizeUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ModelAndView} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * authorize
     * @param {String} parameters parameters
     * @param {Object} opts Optional parameters
     * @param {Object.<String, {String: String}>} opts.model model
     * @param {module:api/AuthorizationEndpointApi~authorizeUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ModelAndView}
     */
    this.authorizeUsingPUT = function(parameters, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'parameters' is set
      if (parameters === undefined || parameters === null) {
        throw new Error("Missing the required parameter 'parameters' when calling authorizeUsingPUT");
      }


      var pathParams = {
      };
      var queryParams = {
        '../model': opts['../model'],
        'parameters': parameters,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['*/*'];
      var returnType = ModelAndView;

      return this.apiClient.callApi(
        '/oauth/authorize', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
