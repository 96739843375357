/* Welcome to Aqa Client*/
/* Version: 1 */

import React from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

const customStyle = {
    visibility : {
        desktop : {
            xOffset : '15',
            yOffset : '4',
            position : 'br'
        },

        mobile : {
            xOffset : 15,
            yOffset : 15,
            position : 'br'
        }
    }
};

class AqaHelp extends React.Component {
    constructor(props) {
        super(props);
        // Assign A ref to state for refresh
        this.state = {
            expanded: false
        };
        this.chatRef = React.createRef();
    }

    handleChatRefClose = ()=>{
        this.chatRef.current.minimize();
        this.chatRef.current.hideWidget();
        this.chatRef=null;
    }

    onLoad = () => {
        this.props.dataFromMain.handleChatRef(this.chatRef);
        this.props.dataFromRoot.registerChatFrame(this);
        this.chatRef.current.showWidget();
        const user = this.props.dataFromRoot.state.user;
        let name = "Unknown";
        let uemail = "unknown@unknown.com";
        if(user !== null){
            name = user.firstname + " "+user.lastname;
            uemail = user.email;
        }

        // Example for setting name and email
        this.chatRef.current.setAttributes({
            name: name,
            email: uemail,
            store: uemail
        },
            function(error) {
                // do something if error
        });
        this.chatRef.current.visitor({
            name : name,
            email : uemail,
            store: uemail
        });
    };

    componentWillUnmount() {
        this.handleChatRefClose();
    }

    render() {
        //const {classes} = this.props;
        return (
            <div>
                <TawkMessengerReact
                    customStyle={customStyle}
                    propertyId="659bfac50ff6374032bdaf4d"
                    widgetId="1hjkkn5gc"
                    onLoad={this.onLoad}
                    ref={this.chatRef}/>
            </div>
        );
    }
}
AqaHelp.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaHelp);
