/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as NewSourceIcon} from "../../images/aqa-icons/AQA-Edit.svg";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import HtmlTooltip from "@material-ui/core/Tooltip";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaSourceEdit extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            openProgress:false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            periodicities: [],
            periodicity: "daily",
            SourceName: "",
            SourceDescription: null,
            errorMessage: "",
            file: null

        };
        this.handleFileChange = this.handleFileChange.bind(this);

    }

    handleSourceCreation(sourceid,sourceName,periodicity,sourceDescription){
        var bodyParams="{\"name\":\""+sourceName+"\",\"periodicity\":\""+periodicity+"\",\"description\":\""+sourceDescription+"\"}"
            AqaComponent.backend.chgSourceUsingPUT(
                bodyParams,
                sourceid,
                (error, data, response) =>
                {
                    if (error) this.reportError("A problem posting the  new source from the server was encountered.",
                        "AqaSourceEdit.handleSourceCreation, backend call: " + error,this);
                    else
                    {
                        this.setState({isLoaded: true,openMessage:false});
                        this.props.dataFromParent.handleSourceSelectionReset();
                        this.props.dataFromParent.handleFolder(this.props.dataAcquired);
                    }
                }
            );
    }

    handleSnapshotCreation(folderid,sourceid,fileUploaded){
        //var sourceid = this.props.dataFromGrid.id;
        AqaComponent.backend.uploadFileUsingPOST(
            fileUploaded,
            sourceid,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem posting the  new snapshot from the server was encountered.",
                    "AqaSourceEdit.handleSnapshotCreation, backend call: " + error,this);
                else
                {
                    this.setState({isLoaded: true,openMessage:false,openProgress:false});
                    this.props.dataFromParent.handleFolder(folderid);
                    /*if(data.length<1){
                        this.setState({error: false,notfound:true});
                    }*/
                }
            }
        );
    }

    handleCreateSourceClosePopup(e) {
        e.preventDefault();
        var periodicity= this.state.periodicity;
        var sourceName = this.state.SourceName;
        var sourceDescription = this.state.SourceDescription;
        if(this.props.dataFromParent.state.selectedSources.length ===1){
            var source = this.props.dataFromParent.state.selectedSources[0];
        }

        if(sourceName === "" || sourceName.trim() === "" || periodicity === null){
            this.setState({ errorMessage: "Please fill mandatory fields" });
        }
        else{
            this.handleSourceCreation(source.id,sourceName,periodicity,sourceDescription);
        }
        this.setState({ openMessage: true });
    }

    handleClosePopup(e) {
        e.preventDefault();
        this.props.dataFromParent.handleSourcesDeleteReset();
        this.setState({ errorMessage:"",openMessage: false,SourceName:"",SourceDescription:"",periodicity:"daily" });
    }
    handleNewSourceClick(e) {
        e.preventDefault();
        var authCode=this.props.dataFromParent.props.dataFromMain.props.dataFromRoot.props.parent.state.authCode;
        var adminEmail=this.props.dataFromParent.props.dataFromMain.props.dataFromRoot.props.parent.state.adminEmail;
        if(authCode ==="V"){
            this.removeAllNotifications();
            var context;
            //var context = this.props.dataFromParent.props.dataFromRoot.props.dataFromRoot.props.dataFromRoot;
            this.showNotification(4, null, context, null,adminEmail);
        }
        else {
            if (this.props.dataFromParent.state.selectedSources.length === 1) {
                var source = this.props.dataFromParent.state.selectedSources[0];
                var sourceDescription = "";
                if (source.description !== "null") {
                    sourceDescription = source.description
                }
                this.setState({SourceName: source.name, SourceDescription: sourceDescription});
            }
            this.setState({openMessage: true});
        }
    }

    handlePeriodicityChange(evt){
        var periodicity = evt.target.value;
        this.setState({"periodicity": periodicity,errorMessage:""});
    }

    handleDescriptionChange(evt){
        var description = evt.target.value;
        this.setState({"SourceDescription": description,errorMessage:""});
    }
    handleNameChange(evt){
        var name = evt.target.value;
        this.setState({"SourceName": name,errorMessage:""});
    }

    handleFileUploadPopup(e){
        this.setState({file:null});
        document.getElementById("import-file").click();
    }

    handleFileChange(evt){
        evt.preventDefault();
        var fileUploaded = evt.target.files[0];
        if(fileUploaded !== null && fileUploaded !== undefined) {
            this.setState({file: fileUploaded, errorMessage: ""});
        }
        else{
            this.setState({file: null, errorMessage: ""});
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

    }

    componentDidMount(){


    }
    render() {
        const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,errorMessage } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%"}}>
                                {(this.props.dataForDisabled === false)?(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Click to edit data Source</Typography>
                                        </div>
                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <IconButton className="aqa-button" onClick={this.handleNewSourceClick.bind(this)} >

                                    <div className="aqa-icon" >
                                        <NewSourceIcon width="32px" />
                                    </div>
                                    <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                        EDIT
                                    </Typography>
                                </IconButton>
                                    </HtmlTooltip>
                                    ):(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Operation not permitted</Typography>
                                        </div>
                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <div>
                                    <IconButton className="aqa-button" disabled={true}>

                                        <div className="aqa-icon aqa-icon-disabled" >
                                            <NewSourceIcon width="32px" />
                                        </div>
                                        <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#dadbdb",paddingLeft:"4px",marginTop:"-4px"}}>
                                            EDIT
                                        </Typography>
                                    </IconButton>
                                        </div>
                                    </HtmlTooltip>
                                )}

                            </Typography>
                        </Grid>

                    </Grid>

                    <Dialog
                        open={this.state.openMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                Edit Data Source
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={this.handleClosePopup.bind(this)} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:"500px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <TextField
                                        id="source-name"
                                        //onChange={this.handleTextChange('guest')}
                                        value={this.state.SourceName}
                                        label="Source Name"
                                        className={classes.textField}
                                        variant="filled"
                                        margin="dense"
                                        multiline={false}
                                        rows={1}
                                        style={{ width: "95%"}}
                                        onChange={this.handleNameChange.bind(this)}
                                        required
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} lg={12} xl={12}>

                                    <TextField
                                        id="source-description"
                                        //onChange={this.handleTextChange('guest')}
                                        value={this.state.SourceDescription}
                                        label="Source Description"
                                        className={classes.textField}
                                        variant="filled"
                                        margin="dense"
                                        multiline={true}
                                        rows={10}
                                        style={{ width: "95%"}}
                                        onChange={this.handleDescriptionChange.bind(this)}

                                    />
                                </Grid>

                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc"}}>

                                <Grid item xs={6} sm={6} lg={6} xl={6}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: -48,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Cancel
                                    </Button>
                                    <Button  variant="contained"
                                             onClick={this.handleCreateSourceClosePopup.bind(this)}
                                             color="primary"
                                             align="left"
                                             className="aqa-action-button"
                                             style={{
                                                 marginTop: 8,
                                                 marginLeft: 8,
                                                 color: '#4cadc4',
                                                 backgroundColor: 'white',
                                                 border: '1px solid #4cadc4',
                                                 fontSize: '0.7rem',
                                                 padding: '3px',
                                             }}>
                                        Save & Close
                                    </Button>


                                </Grid>

                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                </Grid>
                                <Grid item xs={5} sm={5} lg={5} xl={5} style={{margin: '0px'}}>


                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>

                </div>
            );
        }
    }
}

AqaSourceEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSourceEdit);