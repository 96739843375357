/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MsContext from "../shared/masterservant/MsContext";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
//import {ReactComponent as LoadingIcon} from "../../images/grid.svg";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import AqaAccountPlan from "./AqaAccountPlan";
import DialogTitle from "@material-ui/core/DialogTitle";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";
import DialogContent from "@material-ui/core/DialogContent";
import {FormControlLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
//import moment from 'moment';

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaAccountOverview extends AqaComponent {
    // No, seriously, we need this.
    static contextType = MsContext;
    constructor(props) {
        super(props);
        props.parent.props.parent.accountOverview=this;
        this.state = {
            error: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:"account",
            selectedFolders:[],
            resetCheckbox:false,
            folders: [],
            tabvalue:0,
            openPlanEditor:false,
            openTeamEditor:false,
            includePlan:false,
            account:"ACME",
            accountPlan:"",
            accountPlanName:"Free",
            accountUsers:1,
            plans:[],
            prices:[],
            allSources:[],
            accountObj:"",
            additionalUser:0,
            openCancelSubscription:false,
            openPaymentMethod:false,
            paymentMethod:"manual",
            frequency:"Monthly",
            currency:"USD",
            nextAmountDate:0,
            openEmailEditor:false,
            adminUser:null,
            adminUserId:"",
            users:[],
            pm:null,
            allPlans:[],
            statement:null

        };
        this.handleOpenPlan=this.handleOpenPlan.bind(this);
        this.handleClosePlan=this.handleClosePlan.bind(this);
        this.handleAccountDetails=this.handleAccountDetails.bind(this);
        this.handleOpenTeamSize=this.handleOpenTeamSize.bind(this);
        this.handleCancelSubscription=this.handleCancelSubscription.bind(this);
        this.handleEmailChange=this.handleEmailChange.bind(this);
        this.handlePopupClose=this.handlePopupClose.bind(this);
        this.handlePopupOpen=this.handlePopupOpen.bind(this);
        this.handlePaymentPopupOpen=this.handlePaymentPopupOpen.bind(this);
        this.handlePaymentMethod=this.handlePaymentMethod.bind(this);
        this.handlePaymentMethods=this.handlePaymentMethods.bind(this);
        this.handleOpenEmailEditor=this.handleOpenEmailEditor.bind(this);
        this.accountPlan = null;
    }

    handlePopupClose(e){
        e.preventDefault();
        this.setState({openCancelSubscription:false,openPaymentMethod:false,openEmailEditor:false});
    }

    handleCancelSubscriptionFive(){
        var plan = "";
        var plans = this.state.plans;
        for(var i=0;i<plans.length;i++){
            if(plans[i].name==="Free"){
                plan = plans[i];
            }
        }
        var totalUsers=1;
        var totalDataSets = 2;
        if(plan!=="") {
            var accountToUpdate = {
                "planId": plan.id,
                "currency": "GBP",
                "numberOfEditors": totalUsers,
                "numberOfSources": totalDataSets,
                "paymentFrequency": "Monthly"
            };
            AqaComponent.accountBackend.updateSubscriptionUsingPUT(
                accountToUpdate,
                (error, data, response) => {
                    if (error) this.reportError("A problem occured when updating account.",
                        "AqaAccountPlan.handleAccountUpdate, call: " + error,this);
                    else {
                        if (data.length === undefined) {
                            var responseJSON = JSON.parse(response.text);
                            if (responseJSON.status === "error") {
                                this.setState({errorResponse: responseJSON.message});
                            } else if (responseJSON.status === "needsStripe") {
// alert("Testing");
// onsole.log(responseJSON.stripeSecret);
                                this.setState({needsStripe: true, stripeSecret: responseJSON.stripeSecret});
                            } else if (responseJSON.status === "ok") {
                                this.handleAccountPlanDetails();
                                this.setState({openPlanEditor: false, openTeamEditor: false,openCancelSubscription:false,accountPlan:plan,accountPlanName:plan.name});
                            }
                        } else {
                            this.handleAccountPlanDetails();
                            this.setState({openPlanEditor: false, openTeamEditor: false,openCancelSubscription:false,accountPlan:plan,accountPlanName:plan.name});
                        }
                    }
                }
            );
        }
    }

    handleCancelSubscription = (e)=>{
        e.preventDefault();
        AqaComponent.accountBackend.stopPayingUsingDELETE
        (
            (error, data, response) => {
                if (error) {
                    if(response.status === 401 ){
                        this.props.dataFromParent.handleSetAuthorized();
                    }
                    else this.reportError("A problem occured when updating account.",
                        "AqaAccountPlan.handleCancelSubscription, call: " + error,this);
                }
                else {
                    this.setState({openCancelSubscription:false});
                    this.props.dataFromParent.handleLoggedInUser();
                }
            }
        );
    }

    handlePopupOpen(e){
        e.preventDefault();
        this.setState({openCancelSubscription:true,errorResponse:""});
    }

    handleOpenEmailEditor(e){
        e.preventDefault();
        this.handleAccountDetails(false);
        this.setState({openEmailEditor:true});
    }

    handlePaymentPopupOpen(e){
        e.preventDefault();
        this.props.parent.handleChange(e,4);
    }

    handlePaymentMethod(e){
        e.preventDefault();
        this.handleAccountUpdate(this.state.accountObj);
    }

    handleOpenPlan(e){
        e.preventDefault();
        this.handleAccountDetails(true);
        this.setState({openPlanEditor:true});
    }

    handleClosePlan(){
        this.handleAccountDetails(true);
        this.setState({openPlanEditor:false,openTeamEditor:false,isLoaded:true});
    }

    handleEmailChange(e){
        e.preventDefault();
        var user=this.state.adminUser;
        var users=this.state.users;
        for(var i=0;i<users.length;i++){
            var currentUser = users[i];
            if(currentUser.id === this.state.adminUserId){
                user = currentUser
            }

        }
        this.setState({adminUser:user});
        this.handleAccountUpdate(this.state.accountObj);
    }

    handleRadioChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
        if(event.target.name === "frequency") this.handlePrices(this.state.allPlans,event.target.value,this.state.currency);
        if(event.target.name === "currency") this.handlePrices(this.state.allPlans,this.state.frequency,event.target.value);

    };

    handleAccountUpdate(account){
        var accountToUpdate={
            "currentPlanId": account.currentPlanId,
            "id": account.id,
            "name":account.name,
            "numberOfEditors": account.numberOfEditors,
            "numberOfSnapshots": account.numberOfSnapshots,
            "numberOfSources": account.numberOfSources,
            "numberOfViewers": account.numberOfViewers,
            "paymentMethod": this.state.paymentMethod,
            "paymentLength": account.paymentLength,
            "city": account.city,
            "companyName": account.companyName,
            "country": account.country,
            "firstName": account.firstName,
            "lastName": account.lastName,
            "phoneNumber": account.phoneNumber,
            "postcode": account.postcode,
            "state": account.state,
            "streetAddress": account.streetAddress,
            "suite": account.suite,
            "defaultAdmin":account.defaultAdmin,
            "defaultEmail":this.state.adminUserId

        };
        AqaComponent.accountBackend.updateAccountUsingPUT(
            accountToUpdate,
            (error,data,response)=>{
                if (error) this.reportError("A problem occured when updating account.",
                    "AqaAccountOverview.handleAccountUpdate, call: " + error,this);
                else {
                    this.setState({openPaymentMethod: false,openEmailEditor:false});
                }
            }
        );
    }

    handleOpenTeamSize(e){
        e.preventDefault();
        //this.handleAccountDetails(true);
        //this.setState({openTeamEditor:true});
        if(this.accountPlan !== null){
            this.accountPlan.handleOpenTeamEditor(true,this.state.frequency,this.state.currency,this.state.prices);
        }
    }

    handleAccountPlanDetails(){
        AqaComponent.accountBackend.getCanUseUsingGET
        (
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else {
                    this.setState({accountStats:data,additionalUser:data.numberOfAdditionalEditors,additionalDataSet:data.numberOfAdditionalSources});
                }
            }
        );

    }

    handleAccountDetails(withPlan){
//        var accountId=this.props.dataFromParent.props.parent.state.accountId;
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    if(withPlan) {
                        this.handleAllPlans(accountObj);
                        //this.handleAccountPeople(accountObj);
                        //this.handleInvoices(accountObj);
                        this.handleStatement();
                    }
                    else{
                        this.setState({adminUserId:accountObj.defaultEmail});
                    }
                    this.handleAccountPlanDetails();
                    let frequency = accountObj.paymentLength!==null?accountObj.paymentLength:this.state.frequency;
                    let currency = accountObj.currencyCode!==null?accountObj.currencyCode:this.state.currency;
                    this.setState({accountObj:accountObj,paymentMethod:accountObj.paymentMethod,account:accountObj.name,frequency:frequency,currency:currency});
                }
            }
        );
    }

    handleAccountPeople(accountObj){
        AqaComponent.userBackend.allUsingGET(
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the person object from the server was encountered.",
                    "AqaAccountOverview.handleAccountPeople, backend call: " + error,this);
                else{
                    var adminUser=null;
                    var adminUserId=accountObj.defaultEmail;
                    for(var i=0;i<data.length;i++){
                        var user=data[i];
                        if(user.id === accountObj.defaultEmail){
                            adminUser=user;
                            adminUserId=user.id
                        }
                    }
                    this.setState({users:data,adminUser:adminUser,adminUserId:adminUserId});
                }
            }
        );
    }

    handleInvoices(account){
        AqaComponent.accountBackend.getAllInvoicesForMyAccountUsingGET(
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the invoices from the server was encountered.",
                    "AqaAccountOverview.handleInvoices, backend call: " + error,this);
                else{
                    var nextAmountDate=0;
                    var accountPlanLength=account.paymentLength;
                    var paidInvoices = data.filter(item=>{
                        return item.paid === true
                    });
                    if(paidInvoices.length>0){
                        var paidInvoice=paidInvoices[0];
                        var paidInvoiceMillis = paidInvoice.emittedWhen;
                        var paidInvoiceDate = new Date(paidInvoiceMillis);
                        var nextInvoiceDate = paidInvoiceDate.setMonth(paidInvoiceDate.getMonth()+1);
                        if(accountPlanLength !== null && accountPlanLength === "Yearly"){
                            nextInvoiceDate = paidInvoiceDate.setMonth(paidInvoiceDate.getMonth()+12)
                        }
                        //var nextInvoiceDt = new Date(nextInvoiceDate);
                        nextAmountDate=nextInvoiceDate
                    }
                    this.setState({nextAmountDate:nextAmountDate});
                }
                }
        )
    }

    handleSources(){
        AqaComponent.backend.sourcesUsingGET(
            "false",
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the sources from the server was encountered.",
                    "AqaSourceView.handleFolder, backend call: " + error,this);
                else
                {
                    this.setState({allSources:data});
                }
            }
        );
    }

    handlePaymentMethods(){
        AqaComponent.backend.getPaymentMethodsUsingGET
        (
            (error, paymentMethodList, response) =>
            {
                if (error) this.reportError("A problem occured when retrieving payment methods.", "AqaAccountPM.componentDidMount(): " + error,this);
                else{
                    var paymentMethod = null;
                    for(var i=0;i<paymentMethodList.length;i++){
                        var currentPaymentMethod = paymentMethodList[i];
                        if(currentPaymentMethod.default === true) paymentMethod = currentPaymentMethod;
                    }
                    this.setState({paymentMethodList,pm:paymentMethod});
                }
            }
        );
    }

    handleStatement(){
        AqaComponent.billingBackend.getCurrentStatementUsingGET(
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the plans from the server was encountered.",
                    "AqaAccountOverview.handleAllPlans, backend call: " + error,this);
                else {
                    this.setState({statement:data});
                    let blocksAllocated = 0;
                    let blocksUsed = 0;
                    let blockSize = data.blockSize;
                    //let currency = this.state.accountObj.currencyCode;
                    //let planWithPrices = data.planWithPrices.prices[currency][0];
                    //if(planWithPrices !== null && planWithPrices !== undefined) blocksAllocated = planWithPrices.quantityUpTo;
                    for(let i=0;i<data.activities.length;i++){
                        let activity = data.activities[i].sourceActivity;
                        let sources = this.state.allSources.filter((d)=>{return d.example ===true && d.id===activity.sourceId});
                        if(sources.length===0) blocksUsed = blocksUsed + (activity.numberOfCumulatedRows/blockSize);
                    }
                    this.setState({blocksAllocated:blocksAllocated,blocksUsed:blocksUsed.toFixed(2)});
                }
            }
            );
    }

    handleContactus = ()=>{
        var customNotification = {
            title: "Contact Us", titleIcon: "",
            message: <span>For Enterprise pricing please contact <a href={"mailto:support@aqaversant.com"} rel="noopener noreferrer" style={{}}>support@aqaversant.com</a></span>,
            isCustom: false, type: "info",
            insert: "center", position: "center", autoClose: true, duration: 5000
        };
        this.removeAllNotifications();
        this.showNotification(null, customNotification);
    }

    handleAllPlans(accountObj){
        /*AqaComponent.accountBackend.getAllPlansUsingGET(
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the plans from the server was encountered.",
                    "AqaAccountOverview.handleAllPlans, backend call: " + error,this);
                else{
                    var defaultPlan = null;
                    for(var i=0;i<data.length;i++){
                        if(data[i].id===accountObj.currentPlanId){
                            defaultPlan = data[i];
                            this.setState({account:accountObj.name,accountPlanName:defaultPlan.name,accountUsers:accountObj.numberOfEditors,isLoaded:true,accountPlan:defaultPlan});
                        }
                    }
                    this.setState({plans:data});
                    if(defaultPlan===null){
                        this.setState({isLoaded:false,notfound:true,error:true});
                    }
                }
            }
        );*/
        AqaComponent.salesBackend.getAllPlansUsingGET(
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the plans from the server was encountered.",
                    "AqaAccountOverview.handleAllPlans, backend call: " + error,this);
                else {
                    let plans = [];
                    //let defaultPlan = null;
                    /*for(let i=0;i<data.length;i++){
                        let plan = data[i].plan;
                        if(plan.id === accountObj.currentPlanId){
                            defaultPlan = plan;
                            this.setState({account:accountObj.name,accountPlanName:defaultPlan.name,accountUsers:accountObj.numberOfEditors,isLoaded:true,accountPlan:defaultPlan});
                        }
                        plans.push(plan);
                    }*/
                    //console.log(data);
                    this.setState({plans:plans,allPlans:data});
                    this.handlePrices(data,this.state.frequency,this.state.currency);
                    /*if(defaultPlan===null){
                        this.setState({isLoaded:false,notfound:true,error:true});
                    }*/
                }
            }
        );
    }

    handlePrices = (plans,frequency,currency)=>{
        let prices = [];
        for(let i=0;i<plans.length;i++){
            let plan = plans[i];
            let pfs = plan.pricePerPeriodAndPerCurrency;
            let cost = pfs!==null?pfs[frequency][currency].price:0;
            if(cost!==0) cost = cost/100;
            let price = {id:plan.id,name:plan.name,maxUsers:plan.maxNumberOfUsers,cost:cost.toLocaleString(),customPrice:false};
            prices.push(price);
        }
        let price = {id:"aqa-enterprise",name:"Enterprise",maxUsers:0,cost:0,customPrice:true};
        prices.push(price);
        this.setState({prices:prices,isLoaded:true});
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        //this.handleAccountPlanDetails();
    }

    componentDidMount(){
        this.handleAccountPlanDetails();
        //this.handlePaymentMethods();
        //this.handleSources();
        this.handleAccountDetails(true);
        if(this.props.dataForPlanEditor || this.props.dataForPlanEditor2 || this.props.dataFromRoot.state.openPlanEditor){
            this.setState({openPlanEditor:true});
        }
        else{
            this.setState({openPlanEditor:false});
        }

    }

    render() {
        this.show = this.context.show;
        const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,openPlanEditor,openTeamEditor,includePlan,account,users } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <div className={"source-detail-servant-pane-one"} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={10} sm={7} lg={11} xl={11}>
                            <Typography variant="title" color="inherit" align="left"
                                        style={{ fontSize: '1.5rem',
                                            padding: '0.1em 0.5em',
                                            fontFamily: 'Open Sans, sans-serif'}}>
                                Plan Not Activated
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} lg={1} xl={1}>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>

            </div>;
        } else if (!isLoaded) {
            return (<div className={""}>
                {/*<div className={"aqa-loading-icon"}>
                    <LoadingIcon fill={"#4cadc4"}/>
                </div>*/}
            </div>);
        } else {
            return (
                <div className={"source-detail-servant-pane-one"} style={{background: "#FFF 0% 0% no-repeat padding-box"}} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                            {/*<Typography className={"aqa-main-header-text"} >
                                Details
                            </Typography>*/}

                            <div style={{}}>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: "-3px"}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>


                                        {this.props.dataFromParent.state.isSubscriptionCancelled?
                                            <div style={{"padding":"10px 10px"}}>
                                                <Typography className={"aqa-text-bold-14"} >
                                                    This account {account} has been <b>cancelled</b>.
                                                </Typography>
                                                <Typography className={"aqa-text-bold-14"} style={{paddingTop:"10px"}} >
                                                    If you want to <b>activate</b> again please contact support@aqaversant.com.
                                                </Typography>
                                            </div>
                                            :
                                            <div style={{"padding":"10px 10px"}}>
                                                <Typography className={"aqa-text-bold-14"} >
                                                    This account "{account}" is on the <b>{this.props.dataFromParent.state.isAccountSuper?"Super":(this.props.dataFromParent.state.isAccountFree?"Free":(this.state.accountObj!==null?(this.state.accountObj.currentPlanId==="aqa-personal"?"Professional Paid ":(this.state.accountObj.currentPlanId==="aqa-enterprise"?"Enterprise Paid ":(this.state.accountObj.currentPlanId==="aqa-department"?"Department Paid ":" Paid"))):"Free"))} Plan</b>

                                                </Typography>
                                                <Typography className={"aqa-text-bold-14"} >
                                                    The existing plan has the following details:
                                                </Typography>
                                                <div style={{maxHeight:'600px',height:'70px',width:window.innerWidth<500?'355px':'600px',overflow:'hidden'}}>
                                                    <Table id={"_statstable"} cellSpacing={0} aria-sort="ascending" style={{}}>
                                                        <colgroup>
                                                            <col width="40%" />
                                                            <col width="30%" />
                                                            <col width="30%" />

                                                        </colgroup>
                                                        <TableHead>
                                                            <TableRow style={{height:"24px"}}>
                                                                <TableCell width="40%" style={{"padding":"0px 0px 0px 4px"}}><div className="aqa-text-bold-12" style={{fontWeight:'bold'}}> Subscription</div></TableCell>
                                                                <TableCell width="30%" style={{"padding":"0px"}}><div className="aqa-text-bold-12" style={{fontWeight:'bold',textAlign:"right"}}>Purchased</div></TableCell>
                                                                <TableCell width="30%" style={{"padding":"0px"}}><div className="aqa-text-bold-12" style={{fontWeight:'bold',textAlign:"right"}}>Used</div></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody style={{overflow:"hidden"}}>
                                                            <TableRow style={{height:"36px"}}>
                                                                <TableCell width="40%" style={{"padding":"0px 0px 0px 4px"}}><div className="aqa-text-bold-12"> User Licence(s)</div></TableCell>
                                                                <TableCell width="30%" style={{"padding":"0px"}}><div className="aqa-text-bold-12" style={{textAlign:"right"}}>{this.state.accountStats!=null?this.state.accountStats.numberOfPurchasedUsers:0}</div></TableCell>
                                                                <TableCell width="30%" style={{"padding":"0px"}}><div className="aqa-text-bold-12" style={{textAlign:"right"}}>{this.state.accountStats!=null?this.state.accountStats.numberOfDefinedUsers:0}</div></TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </div>

                                                <Typography className={"aqa-text-bold-14"} >
                                                    {this.props.dataFromParent.state.isAccountFree?
                                                        (this.props.dataFromParent.state.isAccountExpired?"Your Free Plan expired "+this.props.dataFromParent.state.accountDate:"Your Free Plan will expire  "+this.props.dataFromParent.state.accountDate):
                                                        ""
                                                    }
                                                </Typography>
                                                {!this.props.dataFromParent.state.isAccountSuper?
                                                    <Typography className={"aqa-text-bold-16"} style={{paddingTop:"5px",fontWeight:"bold",textAlign:"left",fontFamily:"Montserrat"}} >
                                                        {"Paid Plan"}
                                                    </Typography>:""}

                                                {!this.props.dataFromParent.state.isAccountSuper?
                                                    <div className={"aqa-text-bold-14"} style={{paddingTop:"0px"}} >
                                                        <div style={{float:"left",width:'100%'}}>
                                                            <Typography className={"aqa-text-action-bold"} variant="title" align="left"
                                                                        style={{padding:"0px" }}>
                                                                <span style={{float:"left",width:"160px",fontSize:'14px',paddingTop:'0px',marginTop:"-2px",fontFamily:"Montserrat"}}>Payment Frequency</span>
                                                            </Typography>
                                                            <RadioGroup aria-label="frequency" name="frequency" value={this.state.frequency} color="primary" onChange={this.handleRadioChange} row={true}>
                                                                <FormControlLabel value="Monthly" control={<Radio color="primary" style={{padding:"0px 0px 0px 4px "}}/>} label={
                                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                 style={{padding:"0px" }}>
                                                                        <span style={{float:"left",width:"90px"}}>Monthly</span>
                                                                    </Typography>)


                                                                } style={{width:"80px",margin:"0px"}} />
                                                                <FormControlLabel value="Yearly" control={<Radio color="primary" style={{padding:"0px 0px 0px 4px"}} />} label={
                                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                 style={{}}>
                                                                        <span style={{float:"left",width:"90px"}}>Yearly</span>
                                                                    </Typography>)
                                                                } style={{width:"80px",margin:"0px"}} />

                                                            </RadioGroup>
                                                        </div>
                                                        <div style={{float:"left",width:'100%'}}>
                                                            <Typography className={"aqa-text-action-bold"} variant="title" align="left"
                                                                        style={{padding:"0px" }}>
                                                                <span style={{float:"left",width:"160px",fontSize:'14px',paddingTop:'0px',marginTop:"-2px",fontFamily:"Montserrat"}}>Currency</span>
                                                            </Typography>
                                                            <RadioGroup aria-label="currency" name="currency" value={this.state.currency} color="primary" onChange={this.handleRadioChange} row={true}>
                                                                <FormControlLabel value="USD" control={<Radio color="primary" style={{padding:"0px 0px 0px 4px"}} />} label={
                                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                 style={{}}>
                                                                        <span style={{float:"left",width:"80px"}}>$</span>
                                                                    </Typography>)
                                                                } style={{width:"80px",margin:"0px"}} />
                                                                <FormControlLabel value="EUR" control={<Radio color="primary" style={{padding:"0px 0px 0px 4px"}} />} label={
                                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                 style={{}}>
                                                                        <span style={{float:"left",width:"80px"}}>€</span>
                                                                    </Typography>)
                                                                } style={{width:"80px",margin:"0px"}} />
                                                                <FormControlLabel value="GBP" control={<Radio color="primary" style={{padding:"0px 0px 0px 4px"}}/>} label={
                                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                 style={{padding:"0px" }}>
                                                                        <span style={{float:"left",width:"80px"}}>£</span>
                                                                    </Typography>)


                                                                } style={{width:"80px",margin:"0px"}} />


                                                            </RadioGroup>
                                                        </div>
                                                    </div>:""}

                                                {!this.props.dataFromParent.state.isAccountSuper?
                                                    <div className={"aqa-text-bold-14"} >
                                                        <div style={{maxHeight:'600px',height:'140px',width:window.innerWidth<500?'355px':'600px',overflow:'hidden'}}>
                                                            <Table id={"_statstable"} cellSpacing={0} aria-sort="ascending" style={{}}>
                                                                <colgroup>
                                                                    <col width="40%" />
                                                                    <col width="30%" />
                                                                    <col width="30%" />

                                                                </colgroup>
                                                                <TableHead>
                                                                    <TableRow style={{height:"24px"}}>
                                                                        <TableCell width="40%" style={{"padding":"0px 0px 0px 4px"}}><div className="aqa-text-bold-12" style={{fontWeight:'bold'}}> Type</div></TableCell>
                                                                        {/*<TableCell width="25%" style={{"padding":"0px"}}><div className="aqa-text-bold-12" style={{fontWeight:'bold'}}>Available</div></TableCell>*/}
                                                                        <TableCell width="30%" style={{"padding":"0px"}}><div className="aqa-text-bold-12" style={{fontWeight:'bold',textAlign:"right"}}>Users</div></TableCell>
                                                                        <TableCell width="30%" style={{"padding":"0px"}}><div className="aqa-text-bold-12" style={{fontWeight:'bold',textAlign:"right"}}>Price ({this.state.currency==="USD"?"$":(this.state.currency==="EUR"?"€":"£")})</div></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody style={{overflow:"hidden"}}>
                                                                    {this.state.prices.map((item)=>
                                                                        <TableRow key={item.id} style={{height:"36px"}}>
                                                                            <TableCell width="40%" style={{"padding":"0px 0px 0px 4px"}}><div className="aqa-text-bold-12">{item.name}</div></TableCell>
                                                                            {/*<TableCell width="25%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">{this.state.accountStats!=null?this.state.accountStats.numberOfPurchasedUsers:0}</div></TableCell>*/}
                                                                            <TableCell width="30%" style={{"padding":"0px"}}><div className="aqa-text-bold-12" style={{textAlign:"right"}}>{!item.customPrice?item.maxUsers:<div><Button
                                                                                type="submit"
                                                                                variant="contained"
                                                                                color="primary"
                                                                                className="aqa-action-button"
                                                                                onClick={(e)=>this.handleContactus(e)}
                                                                                style={{
                                                                                    marginTop: 0,
                                                                                    marginRight: 0,
                                                                                    color: '#4cadc4',
                                                                                    backgroundColor: 'white',
                                                                                    border: '1px solid #4cadc4',
                                                                                    fontSize: '0.7rem',
                                                                                    padding: '3px'
                                                                                }}


                                                                            >
                                                                                Contact Us
                                                                            </Button></div>}</div></TableCell>
                                                                            <TableCell width="30%" style={{"padding":"0px"}}><div className="aqa-text-bold-12" style={{textAlign:"right"}}>{!item.customPrice?item.cost.toLocaleString():""}</div></TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </div>
                                                    </div>:""}


                                                <Typography className={"aqa-text-bold-14"} variant="inherit">
                                                    {this.props.dataFromParent.state.isSubscriptionCancelled?<>
                                                            {/*<Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="aqa-action-button"
                                        onClick={(e)=>this.handleOpenTeamSize(e)}
                                        style={{
                                            marginTop: 8,
                                            marginRight: 8,
                                            color: '#4cadc4',
                                            backgroundColor: 'white',
                                            border: '1px solid #4cadc4',
                                            fontSize: '0.7rem',
                                            padding: '6px'
                                        }}


                                    >
                                        UPGRADE
                                    </Button>*/}
                                                            If you want to reactivate the account contact <a href={"mailto:support@aqaversant.com"} rel="noopener noreferrer" style={{}}>support@aqaversant.com</a>
                                                        </>:
                                                        (this.props.dataFromParent.state.isAccountWaiting?
                                                            <div>
                                                                <Typography className={"aqa-text-bold-14"} variant="inherit">{"This account is awaiting payment and has been temporarily locked. "}</Typography>
                                                                <Typography className={"aqa-text-bold-14"} variant="inherit">{"Once the payment is confirmed, this account will be resumed. "}</Typography>
                                                                <Typography className={"aqa-text-bold-14"} variant="inherit" style={{paddingTop:"10px"}}>{"Note: If you have already paid but are still seeing this message, please contact support@aqaversant.com."}</Typography>
                                                            </div>:
                                                            (this.props.dataFromParent.state.isAccountSuper?"This account is in Super Plan, to add more User Licence(s) contact support@aqaversant.com":
                                                                (this.props.dataFromParent.state.isAccountFree?<>
                                                                    {this.props.dataFromParent.state.isAccountExpired ?
                                                                        <div style={{"padding": "3px 0px"}}>
                                                                            <Typography className={"aqa-text-bold-14"}
                                                                                        variant="inherit">{"This account has expired and been temporarily locked. "}</Typography>
                                                                            <Typography className={"aqa-text-bold-14"}
                                                                                        variant="inherit">{"To continue using the product, please upgrade your account"}</Typography>
                                                                        </div> : ""
                                                                    }
                                                                            <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className="aqa-action-button"
                                                                    onClick={(e)=>this.handleOpenTeamSize(e)}
                                                                    style={{
                                                                        marginTop: 8,
                                                                        marginRight: 8,
                                                                        color: '#4cadc4',
                                                                        backgroundColor: 'white',
                                                                        border: '1px solid #4cadc4',
                                                                        fontSize: '0.7rem',
                                                                        padding: '6px'
                                                                    }}


                                                                >
                                                                    UPGRADE
                                                                </Button>
                                                                    {this.props.dataFromParent.state.isAccountExpired?
                                                                        <div style={{"padding":"3px 0px"}}>
                                                                            <Typography className={"aqa-text-bold-14"} variant="inherit" style={{paddingTop:"10px"}}>{"Note: If you have already paid but are still seeing this message, please contact "}<a href={"mailto:support@aqaversant.com"} rel="noopener noreferrer" style={{}}>support@aqaversant.com</a></Typography>
                                                                        </div>
                                                                        :""}
                                                                </>:(
                                                                    this.props.dataFromParent.state.isCancellationEnabled?
                                                                        <><span>You can </span><span className="aqa-action-button-text" onClick={(e)=>this.handleOpenTeamSize(e)}><u>Edit Plan</u></span><span> to update your User License(s)</span></>:"Contact your account administrator to update the User License(s)"))))}
                                                </Typography>
                                                <Typography className={"aqa-text-bold-14"} >
                                                    {/*Your account {this.state.accountPlan===null || this.state.accountPlanName==="Free"?(<span>is on free plan</span>):(<span>will renew on {this.state.nextAmountDate!==0?(moment(this.state.nextAmountDate).format("MMM DD YYYY")):"tbd"} charging {paymentMethod === null||paymentMethod==="Credit Card"?(<span>as an Invoice</span>):(<span>your Credit Card</span>)}</span>)}*/}
                                                    {/*Your account is on the Early Adopter Program until 31-Mar-2022*/}
                                                </Typography></div>
                                        }




                                    </Grid>
                                    {/*<Grid item xs={12} sm={12} lg={12} xl={12}>
                            {accountPlanName ==="Free"?"":(<div>
                            <Typography className={"aqa-main-header-text"} >
                                Billing Actions
                            </Typography>
                            <div style={{"padding":"10px 20px"}}>
                            <Typography className={"aqa-text-bold-14"} >
                                Future charges will be billed {this.state.pm===null || this.state.pm===""?"to the card ****":"to the card **** "+this.state.pm.last4Digits} <span className="aqa-action-button-text" onClick={this.handlePaymentPopupOpen}><u>Change Payment Method</u></span>
                            </Typography>
                            <Typography className={"aqa-text-bold-14"} >
                                Billing emails are sent to {adminUser===null ?"not set":adminUser.email} <span className="aqa-action-button-text" onClick={this.handleOpenEmailEditor}><u>Change Email</u></span>
                            </Typography>
                            </div></div>
                                )}
                        </Grid>*/}
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                              style={{padding: 0, marginTop: 0,marginLeft:"15px"}}>
                                            <Grid item xs={3} sm={3} lg={3} xl={3}>
                                                {/* <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="aqa-action-button"
                                onClick={this.handleOpenPlan}
                                style={{
                                    marginTop: 8,
                                    marginRight: 8,
                                    color: '#4cadc4',
                                    backgroundColor: 'white',
                                    border: '1px solid #4cadc4',
                                    fontSize: '0.7rem',
                                    padding: '6px'
                                }}


                            >
                                Visit Pricing
                            </Button>*/}
                                            </Grid>
                                            <Grid item xs={9} sm={9} lg={9} xl={9}>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        {this.props.dataFromParent.state.isAccountFree || this.props.dataFromParent.state.isAccountSuper || this.props.dataFromParent.state.isAccountWaiting || !this.props.dataFromParent.state.isCancellationEnabled?"":
                                            <div style={{"padding":"0px 10px",marginTop:"0px"}}>

                                                <Typography className={"aqa-text-bold"} >
                                                    <span className="aqa-action-button-text" onClick={(e)=>this.handlePopupOpen(e)}><u>Cancel Subscription</u></span>
                                                </Typography>
                                                <Typography className={"aqa-text-bold-14"} >
                                                    Note: do not cancel subscription if you just want to {this.props.dataFromParent.state.isAccountFree?(<span className="aqa-action-button-text" onClick={this.handleOpenPlan}><u>Edit Plan</u></span>):(<span><span className="aqa-action-button-text" onClick={(e)=>this.handleOpenTeamSize(e)}><u>Edit Plan</u></span> {this.state.accountObj.subscriptionRef===null?"":"or"} {this.state.accountObj.subscriptionRef===null?"":<span className="aqa-action-button-text" onClick={this.handlePaymentPopupOpen}><u>Change Payment Method</u></span>}</span>)}
                                                </Typography>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Dialog
                                            open={this.state.openCancelSubscription}
                                            //onClose={this.handleCloseMessage}
                                            aria-labelledby="max-width-dialog-title"
                                            maxWidth="lg"
                                            classes={{ paper: classes.paper}}
                                            style={{margin:"0px"}}
                                        >
                                            <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                                    Cancel Subscription
                                                </Typography>
                                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                                    <IconButton className="aqa-button" onClick={this.handlePopupClose} style={{padding:"0px"}} >
                                                        <div className="aqa-g-icon" style={{width:"20px"}}>
                                                            <CloseIcon width="20px" />
                                                        </div>
                                                    </IconButton>
                                                </Typography>
                                            </DialogTitle>
                                            <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset}}>
                                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                      style={{padding: 0, marginTop: "0px"}}>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                        <div>
                                                            <Typography className={"aqa-text-bold"} style={{padding:"16px 0px 0px 20px"}}>
                                                                Are you sure you want to cancel subscription?
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                        <div>
                                                            <Typography className={"aqa-text-action-14"} style={{padding:"16px 0px 10px 20px",color:"red",textAlign:"left"}}>
                                                                {this.state.errorResponse!==""?"You cannot cancel the subscription, kindly delete the existing user licences or data sources to make it free plan":""}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                                      style={{padding: 0, marginTop:0,marginBottom:8, marginLeft:"0px",borderTop:"1px solid #ccc"}}>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                        <div>
                                                <span style={{float:"left",marginLeft:"30px"}}>
                                                <Button onClick={(e)=>this.handleCancelSubscription(e)} variant="contained"
                                                        color="primary"
                                                        align="right"
                                                        className="aqa-action-button"
                                                        style={{
                                                            marginTop: 8,
                                                            marginLeft: "0px",
                                                            marginRight: 8,
                                                            color: '#4cadc4',
                                                            backgroundColor: 'white',
                                                            border: '1px solid #4cadc4',
                                                            fontSize: '0.7rem',
                                                            padding: '3px'
                                                        }}>
                                                    Ok
                                                </Button>
                                                </span>
                                                            <span style={{float:"left",marginLeft:"10px"}}>
                                                <Button onClick={this.handlePopupClose.bind(this)} variant="contained"
                                                        color="primary"
                                                        align="right"
                                                        className="aqa-action-button"
                                                        style={{
                                                            marginTop: 8,
                                                            marginLeft: "-10px",
                                                            marginRight: 8,
                                                            color: '#4cadc4',
                                                            backgroundColor: 'white',
                                                            border: '1px solid #4cadc4',
                                                            fontSize: '0.7rem',
                                                            padding: '3px'
                                                        }}>
                                                    Cancel
                                                </Button>
                                                </span>

                                                        </div>

                                                    </Grid>
                                                    <Grid item xs={2} sm={2} lg={2} xl={2}>


                                                    </Grid>
                                                    <Grid item xs={4} sm={4} lg={4} xl={4}>

                                                    </Grid>
                                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                                    </Grid>

                                                </Grid>
                                            </DialogContent>
                                        </Dialog>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Dialog
                                            open={this.state.openEmailEditor}
                                            //onClose={this.handleCloseMessage}
                                            aria-labelledby="max-width-dialog-title"
                                            maxWidth="lg"
                                        >
                                            <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                                    Change Email
                                                </Typography>
                                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                                    <IconButton className="aqa-button" onClick={this.handlePopupClose} style={{padding:"0px"}} >
                                                        <div className="aqa-g-icon" style={{width:"20px"}}>
                                                            <CloseIcon width="20px" />
                                                        </div>
                                                    </IconButton>
                                                </Typography>
                                            </DialogTitle>
                                            <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"960px"}}>
                                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                                      style={{padding: 0, marginTop: "0px"}}>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                        <div>
                                                            <Typography className={"aqa-text-bold"} style={{padding:"16px 0px 0px 20px"}}>
                                                                Change the email address for receiving billing emails
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                        <div style={{paddingLeft:"10px"}}>
                                                            <RadioGroup aria-label="adminUserId" name="adminUserId" value={this.state.adminUserId} color="primary" onChange={this.handleRadioChange} row={true} style={{width:"auto"}}>
                                                                {users.map(item=>(
                                                                        <FormControlLabel key={item.id} value={item.id} control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                                            (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                                         style={{padding:"0px" }}>
                                                                                <span style={{float:"left",width:"280px"}}>{item.email}</span>
                                                                            </Typography>)


                                                                        } style={{width:"280px",margin:"0px"}} />
                                                                    )


                                                                )}

                                                            </RadioGroup>
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                                      style={{padding: 0, marginTop:0,marginBottom:8, marginLeft:"0px",borderTop:"1px solid #ccc"}}>
                                                    <Grid item xs={3} sm={3} lg={3} xl={3}>
                                                        <div>
                                                <span style={{float:"left",marginLeft:"30px"}}>
                                                <Button onClick={this.handleEmailChange.bind(this)} variant="contained"
                                                        color="primary"
                                                        align="right"
                                                        className="aqa-action-button"
                                                        style={{
                                                            marginTop: 8,
                                                            marginLeft: "-10px",
                                                            marginRight: 8,
                                                            color: '#4cadc4',
                                                            backgroundColor: 'white',
                                                            border: '1px solid #4cadc4',
                                                            fontSize: '0.7rem',
                                                            padding: '3px'
                                                        }}>
                                                    Ok
                                                </Button>
                                                </span>
                                                            <span style={{float:"left",marginLeft:"10px"}}>
                                                <Button onClick={this.handlePopupClose.bind(this)} variant="contained"
                                                        color="primary"
                                                        align="right"
                                                        className="aqa-action-button"
                                                        style={{
                                                            marginTop: 8,
                                                            marginLeft: "-10px",
                                                            marginRight: 8,
                                                            color: '#4cadc4',
                                                            backgroundColor: 'white',
                                                            border: '1px solid #4cadc4',
                                                            fontSize: '0.7rem',
                                                            padding: '3px'
                                                        }}>
                                                    Cancel
                                                </Button>
                                                </span>

                                                        </div>

                                                    </Grid>
                                                    <Grid item xs={2} sm={2} lg={2} xl={2}>


                                                    </Grid>
                                                    <Grid item xs={4} sm={4} lg={4} xl={4}>

                                                    </Grid>
                                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                                    </Grid>

                                                </Grid>
                                            </DialogContent>
                                        </Dialog>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <AqaAccountPlan openPlanEditor={openPlanEditor} openTeamEditor={openTeamEditor} includePlan={includePlan} plans={this.state.plans} allPlans={this.state.allPlans} currentPlan={this.state.accountPlan} account={this.state.accountObj} parent={this} planLength={this.state.accountObj.paymentLength} dataFromParent={this.props.dataFromParent} />

                </div>
            );
        }
    }
}

AqaAccountOverview.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaAccountOverview);
