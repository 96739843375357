/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MsContext from "../shared/masterservant/MsContext";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import {ReactComponent as LoadingIcon} from "../../images/grid.svg";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import {ExpandMore} from "@material-ui/icons";
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelActions,
    ExpansionPanelDetails,
    Divider
} from "@material-ui/core";
import moment from "moment";
import OtpInput from 'react-otp-input';

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    expanded:{
        '&$expanded':{
            margin:'12px 0'
        }
    }
});

class AqaAccountCredential extends AqaComponent {
    // No, seriously, we need this.
    static contextType = MsContext;
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:"account",
            selectedFolders:[],
            resetCheckbox:false,
            folders: [],
            password:"",
            rpassword:"",
            loading:true,
            submitted:"",
            hasMatchError:false,
            userid:"",
            passwordLastChanged:"",
            expandedPanel:"panel1",
            key:"",
            af2:"",
            otp:"",
            is2FAVerified:false,
            qrcode:null,
            height:window.innerHeight-100
        };
        this.handleFolder = this.handleFolder.bind(this);
        this.handleFolderSelection = this.handleFolderSelection.bind(this);
        this.handleFolderSelectionReset = this.handleFolderSelectionReset.bind(this);
        this.handleFolderSelectionCheckReset = this.handleFolderSelectionCheckReset.bind(this);
        this.handleViewFolder = this.handleViewFolder.bind(this);
        this.handleFoldersReset = this.handleFoldersReset.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleCancel=this.handleCancel.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleGetUserDetails=this.handleGetUserDetails.bind(this);
        this.handleUpdatePassword=this.handleUpdatePassword.bind(this);
        this.handleVerifyOTP=this.handleVerifyOTP.bind(this);
        this.handleDimensions=this.handleDimensions.bind(this);
    }

    handleFolder(folderid){
        /*var folderid=event.target.parentElement.parentElement.id;
        if(folderid === ""){
            folderid=event.target.parentElement.parentElement.parentElement.parentElement.parentElement.id;
        }
        if(folderid === ""){
            folderid=event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.id;
        }*/
        this.setState({currentFolder: folderid});
        this.showSourceView(folderid);

    }

    handleFolderSelectionReset(){
        this.setState({selectedFolders:[],resetCheckbox:true});
        this.handleViewFolder(this.state.currentFolder);
        //this.handleFolder(this.props.dataAcquired);
    }

    handleFolderSelectionCheckReset(){
        this.setState({resetCheckbox:false});
        //this.handleFolder(this.props.dataAcquired);
    }
    handleFoldersReset(){
        this.setState({folders:[]});
    }

    handleFolderSelection(folder,isSelected){
        var selectedFolders = this.state.selectedFolders;
        var index = selectedFolders.indexOf(folder);
        if(isSelected === true){
            if(folder !== null && folder !== undefined){
                if(index<0){
                    selectedFolders.push(folder);
                    this.setState({selectedFolders:selectedFolders});
                }
            }
        }
        else{
            if(folder !== null && folder !== undefined){
                if(index!==-1){
                    var newSelectedFolders = [];
                    for(var i=0;i<selectedFolders.length;i++){
                        var selectedFolder = selectedFolders[i];
                        if(selectedFolder !== folder){
                            newSelectedFolders.push(selectedFolder);
                        }
                    }
                    selectedFolders=newSelectedFolders;
                    this.setState({selectedFolders:selectedFolders});
                }
            }
        }
// onsole.log(selectedFolders);
        this.handleViewFolder(this.state.currentFolder);
    }

    handleViewFolder(folderid){
        this.setState({currentFolder:folderid});
    }

    handleChange(e)
    {
        const { name, value } = e.target;
        this.setState({ [name]: value ,loading:false,error:"",error1:"",error2:"",hasError: false,hasMatchError:false});
    } // handleChange

    handleCancel(e){
        e.preventDefault();
        this.setState({password:"",rpassword:"",expandedPanel:""});
        var firstTime = localStorage.getItem("ft");
        if(firstTime) {
            this.props.dataFromRoot.props.dataFromParent.props.parent.handleFirstTime(false);
            this.props.dataFromRoot.props.dataFromParent.handleFirstSource(1);
            localStorage.removeItem("ft");
        }
    }
    handleSubmit(e)
    {
        e.preventDefault();
        
// this.handleUpdatePassword();
// return;
        
        if(this.state.rpassword ==="" || this.state.password === ""){
            this.setState({hasError:true,submitted:true});
        }
        else if(this.state.rpassword !== this.state.password){
            this.setState({hasError:true,submitted:true,hasMatchError:true});
        }
        else
        {
            var passwordUpdate={id:this.state.userid,password:this.state.password};
            AqaComponent.userBackend.checkForOldPasswordUsingPOST(
                passwordUpdate,
                (error,data,response) =>
                {
// alert("SC: " + response.statusCode);
// onsole.log(response);
                    if (error)
                    {
                        if (response.statusCode === 401) {
                            var customNotification = {
                                title: "Verification Error", titleIcon: "",
                                message: "Sorry, this password has already been used. Please choose a different password.",
                                isCustom: false, type: "info",
                                insert: "center", position: "center", autoClose: true, duration: 5000
                            };
                            this.removeAllNotifications();
                            this.showNotification(null, customNotification);
                            //alert("Sorry, this password has already been used.\nPlease choose a different password.");
                        }
                        else this.reportError("A problem occured when checking existing password.", "AqaAccountCredential.handleSubmit, call: " + error,this);
                    }
                    else  this.handleUpdatePassword();
                }
            )
        }
    }

    handleUpdatePassword(){
        var passwordUpdate={id:this.state.userid,password:this.state.password};
        AqaComponent.userBackend.updateUserPasswordUsingPUT(
            passwordUpdate,
            (error,data,response)=>{
                if (error) this.reportError("A problem occured when updating user details.",
                    "AqaAccountCredential.handleUpdatePassword, call: " + error,this);
                else {
                    var firstTime = localStorage.getItem("ft");
                    this.setState({password:"",rpassword:""});
                    if(firstTime) {
                        this.props.dataFromRoot.props.dataFromParent.props.parent.handleFirstTime(false);
                        this.props.dataFromRoot.props.dataFromParent.handleFirstSource(1);
                        localStorage.removeItem("ft");
                    }
                    var customNotification = {
                        title: "Password Updated", titleIcon: "",
                        message: "You have successfully changed the password for your account",
                        isCustom: false, type: "info",
                        insert: "center", position: "center", autoClose: true, duration: 5000
                    };
                    this.removeAllNotifications();
                    this.showNotification(null, customNotification);

                }
            }
        )
    }

    handleGetUserDetails(){
        AqaComponent.userBackend.meUsingGET(
            (error,data,response)=>{
                if (error) this.reportError("A problem occured when getting user details.",
                    "AqaAccountCredential.handleGetUserDetails, call: " + error,this);
                else {
                   this.setState({userid:data.id,passwordLastChanged:data.datePasswordLastChanged});
                   this.loadCurrent2FASetup();
                }
            }
        );
    }

    handleOTPChange = (otp) => this.setState({ otp });

    handleVerifyOTP(e) {
        e.preventDefault();
        if (this.state.otp === null || this.state.otp === "" || this.state.otp.length !== 6) {
            var customNotification = {
                title: "Verification Error", titleIcon: "",
                message: "Provide an OTP to verify",
                isCustom: false, type: "info",
                insert: "center", position: "center", autoClose: true, duration: 5000
            };
            this.removeAllNotifications();
            this.showNotification(null, customNotification);
        } else {
            AqaComponent.backend.checkOtpUsingGET
            (
                this.state.otp,
                (error, data, response) => {
                    if (error) {
                        var customNotification = {
                            title: "OTP ERROR", titleIcon: "",
                            message: "Failed to check OTP verification. Please make sure to keep the device handy when you login next time.",
                            isCustom: false, type: "info",
                            insert: "center", position: "center", autoClose: true, duration: 5000
                        };
                        this.removeAllNotifications();
                        this.showNotification(null, customNotification);
                    }
                    else {
                        if (data.success) {
                            customNotification = {
                                title: "Verification Successful", titleIcon: "",
                                message: "OTP was verified successfully. Please make sure to keep the device handy when you login next time.",
                                isCustom: false, type: "info",
                                insert: "center", position: "center", autoClose: true, duration: 5000
                            };
                            this.removeAllNotifications();
                            this.showNotification(null, customNotification);
                            this.setState({is2FAVerified: true});
                        } else {
                            customNotification = {
                                title: "Verification Error", titleIcon: "",
                                message: <p>Sorry, the OTP you have provided cannot be verified.<br/> Please provide an
                                    OTP generated on the device or Remove 2-factor authentication. Else you will be
                                    locked out</p>,
                                isCustom: false, type: "info",
                                insert: "center", position: "center", autoClose: true, duration: 5000
                            };
                            this.removeAllNotifications();
                            this.showNotification(null, customNotification);
                            this.setState({is2FAVerified: false});
                        }
                        this.setState({otp: ""});
                    }
                }
            );
        }
    }

    loadCurrent2FASetup()
    {
        AqaComponent.backend.get2FAProfileUsingGET
        (
            new Date().getTime(),
            (error, data, response) =>
            {
                if (error) alert("Error getting OTP key");
                else
                {
                    if(data["success"] === false){
                        this.setState
                        (
                            {
                                key: "",
                                qrcode: "",
                                is2FAVerified:false
                            }
                        );
                    }
                    else {
                        this.setState
                        (
                            {
                                key: data["message"],
                                qrcode: data.qrUrl,
                                is2FAVerified:data.verified
                            }
                        );
                    }
                }
            }
        );


    } // loadCurrent2FASetup

    delete2FA = () =>
    {
        //if (!window.confirm("Are you sure??")) return;
        AqaComponent.backend.delete2FAProfileUsingDELETE
        (
            (error, data, response) =>
            {
                if (error) alert("Error removing 2FA");
                else
                {
                    this.loadCurrent2FASetup();
                }
            }
        );
    }; // delete2FA

    getKey = () =>
    {
        AqaComponent.backend.otpUsingPOST
        (
            (error, data, response) =>
            {
                if (error) {
                    this.reportError("A problem occured when getting 2FA key.",
                        "AqaAccountCredential.handleGetUserDetails, call: " + error,this);
                }
                else
                {
                    if (!data.success) {
                        var customNotification = {
                            title: "2FA ERROR", titleIcon: "",
                            message: "There was a problem fetching 2FA Key",
                            isCustom: false, type: "info",
                            insert: "center", position: "center", autoClose: true, duration: 5000
                        };
                        this.removeAllNotifications();
                        this.showNotification(null, customNotification);
                        alert("There was an error:\n" + data["message"]);
                    }
                    this.setState
                    (
                        {
                            key:data["message"],
                            qrcode:data.qrUrl,
                            is2FAVerified:false
                        }
                    );
                }
            }
        );
    } // getKey

    handleDimensions =(event)=>{
        event.preventDefault();
        this.setState({height:window.innerHeight+150});
    }


    componentDidMount(){
        this.setState({isLoaded:true});
        this.handleGetUserDetails();
        this.props.dataFromRoot.handleHeader(1);
        window.addEventListener("onload",this.handleDimensions);
        window.addEventListener("fullScreenChange",this.handleDimensions);
        window.addEventListener("resize",this.handleDimensions);

    }

    render() {
        this.show = this.context.show;

        const handleAccordionChange = (panel) => (event, isExpanded) => {
            if(expandedPanel === panel){this.setState({expandedPanel:""});}
            else this.setState({expandedPanel:panel});
        };
        const { classes } = this.props;
        const { error, isLoaded,redirect, notfound,submitted,password,rpassword,loading,hasError,hasMatchError,expandedPanel } = this.state;
        let {isAccountExpired,isSubscriptionCancelled,isAccountWaiting} = this.props.dataFromParent.state;
        //let username = "";
        //let emailAddress = "";
        let authCode = "V";
        let user = this.props.dataFromParent.props.parent.state.user;
        if(user!==null){
            //emailAddress = user.email;
            authCode = user.permissions;
            /*if(user.firstname !== "" && user.lastname !== "") username = user.firstname +" "+user.lastname;
            else if(user.firstname !== "") username = user.firstname;
            else if(user.lastname !== "") username = user.lastname;
            else username = user.email;*/
        }
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <div className={classes.demo} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={10} sm={7} lg={11} xl={11}>
                            <Typography variant="title" color="inherit" align="left"
                                        style={{ fontSize: '1.5rem',
                                            padding: '0.1em 0.5em',
                                            fontFamily: 'Open Sans, sans-serif'}}>
                                Nothing to display
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} lg={1} xl={1}>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>

            </div>;
        } else if (!isLoaded) {
            return (<div className={"aqa-loader"}>
                <div className={"aqa-loading-icon"}>
                    <LoadingIcon fill={"#4cadc4"}/>
                </div>
            </div>);
        } else {
            return (
                <div className={"source-detail-servant-pane-one"} style={{background: "#FFFFFF 0% 0% no-repeat padding-box",borderTop:"1px solid #ccc",paddingBottom:"42px"}} >
                    {
                        (isSubscriptionCancelled) ?
                            <div style={{"padding":"10px 10px"}}>
                                <Typography className={"aqa-text-bold-14"} >
                                    This account {this.props.account.name} has been <b>{isSubscriptionCancelled?"cancelled":"expired"}</b>.
                                </Typography>
                                {authCode === "A" ?
                                    <Typography className={"aqa-text-bold-14"} style={{paddingTop: "10px"}}>
                                        If you want to <b>activate</b> again please contact support@aqaversant.com.
                                    </Typography> :
                                    <Typography className={"aqa-text-bold-14"} style={{paddingTop: "10px"}}>
                                        If you want to <b>activate</b> again please contact your administrator {this.props.account.name}.
                                    </Typography>
                                }
                            </div>
                            :((isAccountWaiting) ?
                                <div style={{"padding":"10px 10px"}}>
                                    <Typography className={"aqa-text-bold-14"} variant="inherit">{"This account is awaiting payment and has been temporarily locked. "}</Typography>
                                    {authCode==="A"?<Typography className={"aqa-text-bold-14"} variant="inherit">{"Once the payment is confirmed, this account will be resumed. "}</Typography>:""}
                                    {authCode === "A" ? <Typography className={"aqa-text-bold-14"} variant="inherit"
                                                                    style={{paddingTop: "10px"}}>{"Note: If you have already paid but are still seeing this message, please contact support@aqaversant.com."}</Typography> :
                                        <Typography className={"aqa-text-bold-14"} variant="inherit"
                                                    style={{paddingTop: "10px"}}>{"Please contact your administrator "+this.props.account.name}</Typography>
                                    }
                                </div>
                            :
                                ((isAccountExpired) ?
                                        <div style={{"padding":"10px 10px"}}>
                                            <Typography className={"aqa-text-bold-14"} variant="inherit">{"This account was expired and has been temporarily locked. "}</Typography>
                                            {authCode==="A"?<Typography className={"aqa-text-bold-14"} variant="inherit">{"Please pay to use the account. "}</Typography>:""}
                                            {authCode==="A"?
                                                <Typography className={"aqa-text-bold-14"} variant="inherit" style={{paddingTop:"10px"}}>{"Note: If you have already paid but are still seeing this message, please contact support@aqaversant.com."}</Typography>
                                                :<Typography className={"aqa-text-bold-14"} variant="inherit" style={{paddingTop:"10px"}}>{"Please contact your administrator "+this.props.account.name}</Typography>
                                             }
                                        </div>
                                        :
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography className={"aqa-main-header-text"} style={{padding:"0px 10px 0px 10px"}}>
                                        Password & Sign-in method
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography className={classes.secondaryHeading} style={{"padding":"10px 10px",textAlign:"left"}}>
                                        Your password protects your account. You can also add a second layer of protection with 2-Step Verification, which enables a verification code for you to enter when you sign in. So even if somebody manages to steal your password, it is not enough to get into your account.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <div style={{"padding":"0px 10px"}}>
                                        <ExpansionPanel style={{margin:"0px 0px"}} expanded={expandedPanel === 'panel1'} onChange={handleAccordionChange('panel1')}>

                                            <ExpansionPanelSummary style={{padding:"0px 10px",textAlign:"left",minHeight:"36px"}} expandIcon={<ExpandMore />}>
                                                <Typography className={classes.heading}>Password</Typography>
                                                <Typography className={classes.secondaryHeading}>Last Changed: {(this.state.passwordLastChanged !== null)? (moment(this.state.passwordLastChanged ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                            </ExpansionPanelSummary>

                                            <ExpansionPanelDetails style={{padding:"0px 10px",textAlign:"left"}}>
                                                <form className={classes.form1} onSubmit={this.handleSubmit}>

                                                    <FormControl margin="normal" required fullWidth style={{margin:"0px"}}>
                                                        <InputLabel htmlFor="password">Password</InputLabel>
                                                        <Input name="password" type="password" id="password" onChange={this.handleChange} value={password} error={hasError}
                                                               autoComplete="" style={{}}/>
                                                        {submitted && !password &&
                                                            <div className="help-block">Password is required</div>
                                                        }
                                                    </FormControl>
                                                    <FormControl margin="normal" required fullWidth style={{marginTop:"5px"}}>
                                                        <InputLabel htmlFor="rpassword">Confirm Password</InputLabel>
                                                        <Input name="rpassword" type="password" id="rpassword" onChange={this.handleChange} value={rpassword} error={hasError}
                                                               autoComplete="" style={{}}/>
                                                        {submitted && !rpassword &&
                                                            <div className="help-block">Re-confirm Password is required</div>
                                                        }
                                                        {submitted && hasMatchError &&
                                                            <div className="help-block">Password does not match</div>
                                                        }
                                                    </FormControl>
                                                </form>
                                            </ExpansionPanelDetails>
                                            <Divider />
                                            <ExpansionPanelActions>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className="aqa-action-button"
                                                    style={{
                                                        marginLeft: 8,
                                                        marginRight: 8,
                                                        color: '#4cadc4',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #4cadc4',
                                                        fontSize: '0.7rem',
                                                        padding: '3px'
                                                    }}
                                                    onClick={this.handleCancel}
                                                    //component={Link}
                                                    //to="/8Z0015E11RVbrE05/welcome"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className="aqa-action-button"
                                                    style={{
                                                        marginLeft: 8,
                                                        marginRight: 8,
                                                        color: '#4cadc4',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #4cadc4',
                                                        fontSize: '0.7rem',
                                                        padding: '3px'
                                                    }}
                                                    onClick={this.handleSubmit}
                                                    disabled={loading}
                                                    //component={Link}
                                                    //to="/8Z0015E11RVbrE05/welcome"
                                                >
                                                    Submit
                                                </Button>
                                            </ExpansionPanelActions>


                                        </ExpansionPanel>

                                        <ExpansionPanel style={{margin:"8px 0px"}} expanded={expandedPanel === 'panel2'} onChange={handleAccordionChange('panel2')}>

                                            <ExpansionPanelSummary classes={{expanded:classes.expanded}} style={{padding:"0px 10px",textAlign:"left",minHeight:"36px"}} expandIcon={<ExpandMore />}>
                                                <Typography className={classes.heading}>2-Step Verification</Typography>
                                                <Typography className={classes.secondaryHeading}>{(this.state.is2FAVerified)? ("On"):("Off")}</Typography>
                                            </ExpansionPanelSummary>

                                            <ExpansionPanelDetails style={{padding:"0px 10px",textAlign:"left"}}>
                                                {/* Setup the 2FA*/}
                                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                      style={{padding: 0, marginTop: 0}}>

                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                        {this.state.key === null || this.state.key === "" ?(<div>
                                                            <Typography className={classes.heading}>Do you want to enable 2-Step Verification?</Typography>
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                className="aqa-action-button"
                                                                style={{
                                                                    marginTop: 8,
                                                                    marginLeft: 2,
                                                                    marginRight: 8,
                                                                    marginBottom:8,
                                                                    color: '#4cadc4',
                                                                    backgroundColor: 'white',
                                                                    border: '1px solid #4cadc4',
                                                                    fontSize: '0.7rem',
                                                                    padding: '3px'
                                                                }}
                                                                onClick={this.getKey}
                                                                //component={Link}
                                                                //to="/8Z0015E11RVbrE05/welcome"
                                                            >
                                                                Enable 2-Step
                                                            </Button>
                                                        </div>):(<div style={{marginTop:"-16px"}}>
                                                            <Typography className={classes.heading}>Two Factor Setup</Typography>
                                                            <Typography className={classes.heading}>Take this few steps to enable two-factor authentication and make your account more secure</Typography>
                                                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                                  style={{padding: 0, marginTop: 0}}>

                                                                <Grid item xs={12} sm={12} lg={5} xl={5}>
                                                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                                          style={{padding: 0, marginTop: 0}}>
                                                                        <Grid item xs={8} sm={8} lg={8} xl={8}>
                                                                            <p /><b>Current Key:</b><br/>
                                                                            <div style={{wordBreak:"break-all"}}>{this.state.key}</div>
                                                                        </Grid>
                                                                        <Grid item xs={1} sm={1} lg={1} xl={1}>

                                                                        </Grid>
                                                                        <Grid item xs={3} sm={3} lg={3} xl={3}>
                                                                            <div style={{paddingTop:"5px"}}>
                                                                                <Button
                                                                                    type="submit"
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    className="aqa-action-button"
                                                                                    style={{
                                                                                        marginTop: 8,
                                                                                        marginLeft: 8,
                                                                                        marginRight: 8,
                                                                                        color: '#4cadc4',
                                                                                        backgroundColor: 'white',
                                                                                        border: '1px solid #4cadc4',
                                                                                        fontSize: '0.7rem',
                                                                                        padding: '5px'
                                                                                    }}
                                                                                    onClick={this.getKey}
                                                                                    //component={Link}
                                                                                    //to="/8Z0015E11RVbrE05/welcome"
                                                                                >
                                                                                    Generate New
                                                                                </Button>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <div style={{paddingLeft:window.innerWidth>500?"60px":"0px"}}>
                                                                        {this.state.qrcode === null ? null : <img alt="" width="200" height="200" src={"data:image/png;base64, " + this.state.qrcode} /> }
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={1} sm={1} lg={1} xl={1}>

                                                                </Grid>
                                                                <Grid item xs={12} sm={12} lg={4} xl={4}>
                                                                    <Typography className={classes.secondaryHeading}>Steps</Typography>
                                                                    <Typography className={classes.secondaryHeading}>1. Install an authenticator app on your mobile device. eg: Google Authenticator, Microsoft Authenticator</Typography>
                                                                    <Typography className={classes.secondaryHeading}>2. Scan QR code with the authenticator (or enter the key manually)</Typography>
                                                                    <Typography className={classes.secondaryHeading}>3. Enter the 2-step authentication provided by your app</Typography>


                                                                </Grid>
                                                                <Grid item xs={2} sm={2} lg={2} xl={2}>

                                                                </Grid>

                                                            </Grid>
                                                        </div>)}
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>

                                                    </Grid>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                        {this.state.key !== null && this.state.key !== "" ?(
                                                            <div style={{paddingTop:"5px",paddingBottom:"5px"}}>
                                                                <Divider />
                                                            </div>):""}
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                        {!this.state.is2FAVerified && this.state.key !== null && this.state.key!=="" ?(<div>
                                                            <Typography className={classes.heading} style={{paddingTop:"5px",paddingBottom:"5px",paddingLeft:"0px"}}>Verify the two-factor authentication</Typography>
                                                            <div className={classes.heading} style={{paddingTop:"0px",paddingBottom:"0px",marginLeft:"-5px"}}>
                                                                <OtpInput
                                                                    value={this.state.otp}
                                                                    onChange={this.handleOTPChange}
                                                                    numInputs={6}
                                                                    separator={<span>-</span>}
                                                                    inputStyle={"inputStyle"}
                                                                />
                                                                <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className="aqa-action-button"
                                                                    style={{
                                                                        marginTop: 8,
                                                                        marginLeft: 8,
                                                                        marginRight: 8,
                                                                        color: '#4cadc4',
                                                                        backgroundColor: 'white',
                                                                        border: '1px solid #4cadc4',
                                                                        fontSize: '0.7rem',
                                                                        padding: '3px'
                                                                    }}
                                                                    onClick={this.handleVerifyOTP}
                                                                    //component={Link}
                                                                    //to="/8Z0015E11RVbrE05/welcome"
                                                                >
                                                                    Check OTP
                                                                </Button>
                                                            </div>

                                                        </div>):(this.state.is2FAVerified && this.state.key!==null && this.state.key!==""?<Typography className={classes.secondaryHeading}>{"Two-Factor authentication verified and enabled"}</Typography>:"")}
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                        {this.state.key !== null && this.state.key !== "" ?(
                                                            <div style={{paddingTop:"10px",paddingBottom:"5px"}}>
                                                                <Divider />
                                                            </div>):""}
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                        {/* Remove the 2FA*/}
                                                        {this.state.key !== null && this.state.key !== "" ?(<div style={{paddingTop:"5px",paddingBottom:"10px"}}>
                                                            <Typography className={classes.heading} style={{paddingLeft:"0px"}}>Do you want to disable 2-Step Verification?</Typography>
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                className="aqa-action-button"
                                                                style={{
                                                                    marginTop: 8,
                                                                    marginLeft: 3,
                                                                    marginRight: 8,
                                                                    marginBottom: 8,
                                                                    color: '#4cadc4',
                                                                    backgroundColor: 'white',
                                                                    border: '1px solid #4cadc4',
                                                                    fontSize: '0.7rem',
                                                                    padding: '3px'
                                                                }}
                                                                onClick={this.delete2FA}
                                                                //component={Link}
                                                                //to="/8Z0015E11RVbrE05/welcome"
                                                            >
                                                                Disable 2-Step
                                                            </Button>
                                                        </div>):""}
                                                    </Grid>

                                                </Grid>
                                            </ExpansionPanelDetails>

                                        </ExpansionPanel>
                                    </div>
                                </Grid>
                            </Grid>
                        )
                            )
                    }
                </div>
            );
        }
    }
}

AqaAccountCredential.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaAccountCredential);
