/* Welcome to Aqa Client*/
/* Version: 1 */

import MasterServant       from "../shared/masterservant/MasterServant.js";
import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as BackIcon} from "../../images/aqa-icons/Backcol.svg";
import {ReactComponent as UploadIcon} from "../../images/aqa-icons/Uploadcol.svg";
import Typography from "@material-ui/core/Typography";
import AqaSnapshotView from "./AqaSnapshotView";
// import AqaRules from "../shared/rules/AqaRules";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import Grid from "@material-ui/core/Grid";
import AqaSnapshotMainHeader from "./AqaSnapshotMainHeader";
import HtmlTooltip from "@material-ui/core/Tooltip";
// import AqaSnapshotOverview from "./AqaSnapshotOverview";
import Input from "@material-ui/core/Input";
import AqaSnapshotHeader from "./AqaSnapshotHeader";


const styles = theme => ({
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    welcomelabel: {
        fontSize: 18,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 20,
        marginRight: 20,
        color: "#006"
    }
});

class AqaSourceDetail extends AqaComponent {
    constructor(props) {
        super(props);
        // Assign A ref to state for refresh
        this.state = {
            isLoaded:true,
            showRules:false,
            selectedSnapshots: [],
            resetCheckbox:false,
            currentAspect:null,
            showEditor:false,
            currentSnapshot:null,
            currentDimensionAspect:null,
            showDimensionEditor:false,
            isFileReady:false,
            fileUploaded:null,
            snapshotcount:999,
            accountObj:null,
            snapshots:[],
            showHistory:false,
            reviewComplete:false
        };
        
        // This is used by AqaRules objects to know which snapshot is selected.
        this.currentlySelectedSnapshotId = null;
        this.vqdEditor = null;

        this.currentSnapshotView = null;

        this.handleSource=this.handleSource.bind(this);
        this.handleSnapshotSelection=this.handleSnapshotSelection.bind(this);
        this.handleSnapshotSelectionReset=this.handleSnapshotSelectionReset.bind(this);
        this.handleSnapshotCheckReset=this.handleSnapshotCheckReset.bind(this);
        this.handleShowRule=this.handleShowRule.bind(this);
        this.handleShowDimensionRule=this.handleShowDimensionRule.bind(this);
        this.handleFileUpload=this.handleFileUpload.bind(this);
        this.handleFileUploadReset=this.handleFileUploadReset.bind(this);
        this.handleRefresh=this.handleRefresh.bind(this);
        this.handleSnapshots=this.handleSnapshots.bind(this);
        this.handleNewSnapshotClick=this.handleNewSnapshotClick.bind(this);
        this.handleNewSnapshotDropClick=this.handleNewSnapshotDropClick.bind(this);
        this.handleAccountDetails=this.handleAccountDetails.bind(this);
        this.handleFileChange=this.handleFileChange.bind(this);
        this.handleDragOver=this.handleDragOver.bind(this);
        this.handleSnapshotHistory=this.handleSnapshotHistory.bind(this);
    }


	/** This is called by aqaSnapshotListRow to indicate what Snapshot was selected.
	  * AqaRules object will query it.
	  */
	setSelectedSnapshotId(snapshotId) { this.currentlySelectedSnapshotId = snapshotId; }

	getSelectedSnapshotId() { return this.currentlySelectedSnapshotId; }

    registerVqdEditor(vqdEditor)                             { this.vqdEditor = vqdEditor; }
    registerSnapshotView(snapshot)                             { this.currentSnapshotView = snapshot; }


    handleReviewComplete = (reviewComplete)=>{
        this.setState({reviewComplete:reviewComplete});
    }

    handleSnapshotHistory(active){
        this.setState({showHistory:active});
        let snapshot = this.currentSnapshotView.state.latestSnapshot;
        if(snapshot === null) {
            //this.currentSnapshotView.handleSnapshot(null,null);

        }
        else this.currentSnapshotView.handleSnapshot(snapshot.id,snapshot);
    }

    handleSnapshotSelectionReset(){
        this.setState({selectedSnapshots:[],resetCheckbox:true});
        //this.handleFolder(this.props.dataAcquired);
    }

    handleSnapshotCheckReset(){
        this.setState({resetCheckbox:false});
        //this.handleFolder(this.props.dataAcquired);
    }
    handleSnapshotSelection(snapshot,isSelected){
        var selectedSnapshots=this.state.selectedSnapshots;
        if(isSelected === true){
            if(snapshot !== null && snapshot !== undefined){
                var index = selectedSnapshots.indexOf(snapshot);
                if(index<0){
                    selectedSnapshots.push(snapshot);
                    this.setState({selectedSnapshots:selectedSnapshots});
                }
            }
        }
        else{
            if(snapshot !== null && snapshot !== undefined){

                var newSelectedSnapshots = [];
                for(var i=0;i<selectedSnapshots.length;i++){
                    var selectedSnapshot = selectedSnapshots[i];
                    if(selectedSnapshot.id !== snapshot.id){
                        newSelectedSnapshots.push(selectedSnapshot);
                    }
                }
                selectedSnapshots=newSelectedSnapshots;
                this.setState({selectedSnapshots:selectedSnapshots});

            }
        }
// onsole.log(selectedSnapshots);
    }

    handleSnapshots(sourceId){
    
    
    	// TJ: 20211115 You're kidding right? You're loading the ALL the snapshots only to know how many there are??!??!?
    
        AqaComponent.backend.getSnapshotsForSourceUsingGET(
            sourceId,
            0,
            9999,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSourceDetail.handleSnapshots, backend call: " + error,this);
                else
                {
                    if(data.length>0){
                        this.setState({showRules:true,snapshots:data,snapshotcount:data.length,isLoaded:true});
                    }
                    else{
                        this.setState({showRules:false,snapshotcount:0,isLoaded:true});
                    }
                }
            }
        );
    }
    handleHome(){
        this.props.dataFromRoot.handleSourceReset();
        this.props.dataFromRoot.handleSelectedStat(null);
        this.props.dataFromRoot.navigation(1);
    }
    handleRefresh(){
        if(this.state.isLoaded){
            this.setState({isLoaded:false});
            let sourceId=this.props.dataFromParent.id;
            this.handleSnapshots(sourceId);
            //this.setState({isLoaded:true});
        }
        else{
            let sourceId=this.props.dataFromParent.id;
            this.handleSnapshots(sourceId);
            //this.setState({isLoaded:true});
        }
    }

    handleAccountDetails(){
//        var accountId=this.props.dataFromRoot.props.parent.state.accountId;
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                var errorJSON = JSON.parse(response.text);
                if (errorJSON.error === "invalid_token") {
                    this.props.dataFromRoot.props.dataFromRoot.props.parent.logout();
                }
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    this.setState({accountObj:accountObj});
                }
            }
        );
    }

    handleNewSnapshotClick(e){
        e.preventDefault();
        var authCode=this.props.dataFromRoot.props.parent.state.authCode;
        var adminEmail=this.props.dataFromRoot.props.parent.state.adminEmail;
        var context;
        if(authCode !== "A"){
            this.removeAllNotifications();
            this.showNotification(4, null,context , null,adminEmail);
        }
        else {
            if(this.props.dataFromParent.name === "Example Data"){
                var customNotification = {
                    title: "Restricted", titleIcon: "",
                    message: "You cannot upload data to this Data Source",
                    isCustom: false, type: "info",
                    insert: "center", position: "center", autoClose: true, duration: 5000
                };
                this.removeAllNotifications();
                this.showNotification(null, customNotification);
            }
            else {
                this.handleAccountDetails();
                this.handleSnapshots(this.props.dataFromParent.id);
                /*if (this.state.accountObj.numberOfSnapshots !== -1 && this.state.snapshotcount >= this.state.accountObj.numberOfSnapshots) {
                    context = this.props.dataFromRoot;
                    this.removeAllNotifications();
                    this.showNotification(3, null, context, "Snapshot");
                } else {*/
                    this.setState({openMessage: false, file: null});
                    document.getElementById('import-file').click();
               /* }*/
            }
        }
    }

    handleDragOver(e){
	    e.preventDefault();
    }

    handleNewSnapshotDropClick(e){
        e.preventDefault();
        var authCode=this.props.dataFromRoot.props.parent.state.authCode;
        var adminEmail=this.props.dataFromRoot.props.parent.state.adminEmail;
        var context;
        if(authCode !== "A"){
            this.removeAllNotifications();
            this.showNotification(4, null,context , null,adminEmail);
        }
        else {
            if(this.props.dataFromParent.name === "Example Data"){
                var customNotification = {
                    title: "Restricted", titleIcon: "",
                    message: "You cannot upload data to this Data Source",
                    isCustom: false, type: "info",
                    insert: "center", position: "center", autoClose: true, duration: 5000
                };
                this.removeAllNotifications();
                this.showNotification(null, customNotification);
            }
            else {
                this.handleAccountDetails();
                this.handleSnapshots(this.props.dataFromParent.id);
                /*if (this.state.accountObj.numberOfSnapshots !== -1 && this.state.snapshotcount >= this.state.accountObj.numberOfSnapshots) {
                    context = this.props.dataFromRoot;
                    this.removeAllNotifications();
                    this.showNotification(3, null, context, "Snapshot");
                } else {*/
                    this.setState({openMessage: false, file: null});
                    var fileUploaded = e.dataTransfer.files[0];
                    if(fileUploaded !== null && fileUploaded !== undefined) {
                        this.setState({file: fileUploaded, errorMessage: "",openProgress:false});
                        //this.handleSnapshotCreation(fileUploaded);
                        this.handleFileUpload(fileUploaded);
                        this.setState({file:null});
                        document.getElementById('import-file').value="";
                    }
                /*}*/
            }
        }
    }

    handleFileChange(evt){
        var fileUploaded = evt.target.files[0];
        if(fileUploaded !== null && fileUploaded !== undefined) {
            this.setState({file: fileUploaded, errorMessage: "",openProgress:false});
            //this.handleSnapshotCreation(fileUploaded);
            this.handleFileUpload(fileUploaded);
            this.setState({file:null,resetCheckbox:false});
            document.getElementById('import-file').value="";
        }
        else{
            this.setState({file: null, errorMessage: ""});
        }
    }

    handleFileUpload(fileUploaded){
        this.setState({fileUploaded:fileUploaded,isFileReady:true,resetCheckbox:true});
    }
    handleFileUploadReset(){
        this.setState({fileUploaded:null,isFileReady:false,resetCheckbox:true});
    }

    handleShowRule(aspect,snapshot){
// onsole.log(snapshot);
        this.setState({showRules:true,currentAspect:aspect,showEditor:true,currentDimensionAspect:null,showDimensionEditor:false,currentSnapshot:snapshot});
    }

    handleShowDimensionRule(aspect,snapshot){
        this.setState({currentAspect:null,showEditor:false,currentDimensionAspect:aspect,showDimensionEditor:true,currentSnapshot:snapshot});
    }

    handleSource(sourceId){
        AqaComponent.backend.sourceUsingGET(
            sourceId,
            (error,data,response)=>{
                var errorJSON = JSON.parse(response.text);
                if (errorJSON.error === "invalid_token") {
                    this.props.dataFromRoot.popTimeoutDialog2();
                    //this.props.dataFromParent.props.controller.props.dataFromMain.props.parent.logout();
                }
                else {
                    if (error) this.reportError("A problem getting the account details from the server was encountered.",
                        "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                    else {
                        this.handleSnapshots(sourceId);
                        this.handleAccountDetails();
                    }
                }
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.isAmber!==prevProps.isAmber || this.props.isRed!==prevProps.isRed || this.props.currentHeatMap !== prevProps.currentHeatMap || this.props.currentColumn !== prevProps.currentColumn){
            let snapshot = this.currentSnapshotView.state.latestSnapshot;
            this.currentSnapshotView.handleSnapshot(snapshot.id,snapshot);
        }
    }

    componentDidMount() {
        if(this.props.dataFromParent!==undefined && this.props.dataFromParent!==null && this.props.dataFromParent!=="") {
            var sourceId = this.props.dataFromParent.id;
            if (this.props.currentFile !== null) {
                this.handleFileUpload(this.props.currentFile);
                this.props.dataFromRoot.handleFileUploadReset();
            }
            if(sourceId!== undefined) this.handleSource(sourceId);
        }
    }
    render(){
        //const { classes } = this.props;
        const {isLoaded,resetCheckbox,currentAspect,showEditor,currentDimensionAspect,showDimensionEditor,currentSnapshot,isFileReady,fileUploaded,snapshotcount,showHistory} = this.state;
        if(!isLoaded){
            return (
              <div></div>
            );
        }
        else {
            return (

                <div className={"source-detail-masterservant"}>
                    <Grid container spacing={0} direction="row" justify="space-between"
                          alignItems="center"
                          style={{padding: 0, marginTop: "0px"}}>

                        <Grid item xs={3} sm={3} lg={3} xl={3}>
                            <div className="aqa-main-header"
                                 style={{borderBottom: "1px solid #F9F7F9", backgroundColor: "white", marginTop: "0px"}}>
                                <span style={{
                                    float: "left",
                                    width: "100%",
                                    backgroundColor: "white",
                                    height: "56px",
                                    borderBottom:"1px solid rgb(219, 219, 219)"
                                }}>
                                    <Typography variant="inherit" color="inherit"
                                                style={{textAlign: "left", fontSize: "0.8rem", width: "100%"}}>
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            <div>
                                                <Typography color="inherit" className="aqa-text-action">Click to return to Home</Typography>
                                            </div>
                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <IconButton className="aqa-button" onClick={this.handleHome.bind(this)}
                                                    style={{padding: "5px",marginTop:"8px",marginBottom:"-5px",marginLeft:"5px"}}>

                                            <div className="aqa-icon">
                                                <BackIcon width="32px"/>
                                            </div>
                                            <Typography className={"aqa-text-bold"} variant="inherit"
                                                        style={{textAlign: "left",paddingLeft:"5px",marginTop:"-5px"}}>
                                                {/*this.props.dataFromParent!==undefined && this.props.dataFromParent !== null?this.props.dataFromParent.name:"Data Source Name"*/}
                                                {"Return to Home"}
                                            </Typography>
                                        </IconButton>
                                        </HtmlTooltip>

                                    </Typography>
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={9} sm={9} lg={9} xl={9}>
                            <AqaSnapshotMainHeader
                            	parent={this}
                            	dataFromGrid={this.props.dataFromParent}
                            	dataFromParent={this.props.dataFromParent}
                                dataFromMainFrame={this.props.parent}
                            	dataForAspect={currentAspect}
                            	showEditor={showEditor}
                            	dataForDimensionAspect={currentDimensionAspect}
                            	showDimensionEditor={showDimensionEditor}
                            	dataFromSnapshot={currentSnapshot}
                                reviewComplete={this.state.reviewComplete}
                            	snapshotcount={snapshotcount}
                                showHistory={showHistory}
                            />
                        </Grid>
                    </Grid>


                    <div style={{backgroundColor: "#F9F7F9"}}>
                        <MasterServant>
                            <MasterServant.Master>
                                    <AqaSnapshotView
                                    	key={"snapshot_1"}
                                    	dataFromParent={this}
                                    	dataFromGrid={this.props.dataFromParent}
                                       dataFromRoot={this.props.dataFromRoot}
                                        dataFromMainFrame={this.props.parent}
                                       dataForCheckbox={resetCheckbox}
                                       fileUploaded={fileUploaded}
                                       isFileReady={isFileReady}
                                        showHistory={showHistory}
                                     />

                            </MasterServant.Master>
                            <MasterServant.Servant>
                                <div className={showHistory===true?"source-detail-servant-pane":"source-detail-servant-pane-one"}>
                                    {this.state.snapshotcount === 0?
                                        <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "center", padding: "4px"}}>
                                            <div style={{boxShadow:"0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)", backgroundColor:"white", marginBottom:"3px", marginTop:"3px", margin:"6px", marginLeft:"12px"}}>

                                                <AqaSnapshotHeader
                                                    dataFromMainFrame={this.props.parent}
                                                    dataForPercent={this.state.percentage}
                                                    dataForRAG={this.state.rag}
                                                    dataFromParent={this.props.dataFromParent}
                                                    dataFromRoot={this.props.dataFromRoot}
                                                    dataFromSource={this.props.dataFromParent} controller={this}
                                                    dataForMap={null} dataForStats={[]} dataForHealthStats={[]} dataForReviewStats={[]} dataForScore={null}
                                                    heatmapData={null}
                                                    isPostLoading={true}
                                                />
                                            </div>
                                        </Typography>:""
                                    }
                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center">
                                        <Grid item xs={3} sm={3} lg={3} xl={3}>
                                            {this.state.snapshotcount === 0?
                                                <div>
                                                    <Typography className="aqa-text-action-12" variant="inherit"
                                                                style={{
                                                                    textAlign: "center",
                                                                    padding: "4px",
                                                                    margin: "0",
                                                                    height:"460px"
                                                                }}>
                                                        <Typography className="aqa-text-action-12" variant="inherit"
                                                                    style={{
                                                                        textAlign: "left",
                                                                        padding: "4px",
                                                                        marginTop: "0px",
                                                                        paddingTop:window.innerWidth>1400?"0px":"100px"
                                                                    }}>
                                                            <Typography className="" variant="inherit"
                                                                        style={{
                                                                            textAlign: "left",
                                                                            padding: "4px",
                                                                            marginTop: "0px",
                                                                            fontSize:"16px",
                                                                            fontFamily:"Montserrat",
                                                                            paddingBottom:"10px",
                                                                            fontWeight:"bold",
                                                                            color:"rgb(1, 38, 112)"
                                                                        }}>
                                                                What sort of data can I upload?
                                                            </Typography>
                                                            <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                                <span style={{fontSize:"14px"}}><b>Marketing:</b></span> Customer data, Prospect lists (Users of Hubspot, Pardot, Zoho, etc.)
                                                            </Typography>
                                                            <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                                <span style={{fontSize:"14px"}}><b>Sales:</b></span> Pipeline data, Sales reports (users of Salesforce, Adobe, Oracle, etc.)
                                                            </Typography>
                                                            <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                                <span style={{fontSize:"14px"}}><b>Manufacturing:</b></span> Production plans, Results data (users of ABB, Dassault, Epicor, etc.)
                                                            </Typography>
                                                            <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                                <span style={{fontSize:"14px"}}><b>Finance:</b></span> Transaction data, Orders data (users of Sage, Intuit, SAP, Oracle, etc.)
                                                            </Typography>
                                                            <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                                <span style={{fontSize:"14px"}}><b>Product:</b></span> Test data, Bill of Materials (BOM) (users of Aha!, Atlassian, Microsoft, etc.)
                                                            </Typography>
                                                            <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                                <span style={{fontSize:"14px"}}><b>Procurement:</b></span> Supply chain data, Manufacturing BoM (users of SAP, Coupa, Mercateo, etc.)
                                                            </Typography>
                                                        </Typography>

                                                    </Typography>
                                                </div>:""}
                                        </Grid>
                                        <Grid item xs={6} sm={6} lg={6} xl={6}>
                                    <Typography className="aqa-text-action-12" variant="inherit"
                                                style={{textAlign: "center", padding: "4px",height:window.innerHeight-300,position:"relative"}}>
                                        <Typography className="aqa-text-action-12" variant="inherit"
                                                    style={{textAlign: "center", padding: "4px",margin:"0",top:"4%",position:"absolute",left: window.innerWidth>1280?"20%":"6%",
                                                        right: window.innerWidth>1280?"20%":"6%"}} >


                                                <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "center", padding: "4px"}}>

                                                {this.state.snapshotcount === 0 && this.props.currentFile===null?
                                                <div className={"aqa-file-upload-outer"} onClick={this.handleNewSnapshotClick} onDrop={this.handleNewSnapshotDropClick} onDragOver={this.handleDragOver}>
                                                    <Typography className="" variant="inherit"
                                                                style={{
                                                                    textAlign: "center",
                                                                    padding: "24px 0px 16px 0px",
                                                                    color: "rgb(1, 38, 112)",
                                                                    fontSize: "48px",
                                                                    fontWeight:"bold",
                                                                    fontFamily:"Montserrat",
                                                                    marginTop:window.innerWidth>1200?"8px":"0px"
                                                                }}>
                                                        <span style={{fontSize:"48px"}}>Upload</span>
                                                    </Typography>
                                                    <div className="aqa-icon" style={{paddingTop:"0px"}}>
                                                        <UploadIcon width="256px"/>
                                                    </div>
                                                    <Typography className="aqa-text-action-16" variant="inherit"
                                                                style={{textAlign: "center", padding: "4px",color:"#909090",fontSize:"16px"}}>
                                            Click or Drag-Drop to upload your data file
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit"
                                                                style={{textAlign: "center", padding: "4px",opacity:"0.5",color:"#909090"}}>
                                                        or use LOAD LATEST DATA button
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit"
                                                                style={{textAlign: "center", padding: "4px",opacity:"0.5",color:"#909090"}}>
                                                        Note: single sheet .xlsx or .csv with list of records
                                                    </Typography>
                                                </div>
                                                    :""}
                                            </Typography>
                                        </Typography>

                                    </Typography>
                                        </Grid>
                                        <Grid item xs={3} sm={3} lg={3} xl={3}>
                                            {this.state.snapshotcount === 0?<div>
                                                <Typography className="aqa-text-action-12" variant="inherit"
                                                            style={{
                                                                textAlign: "center",
                                                                padding: "4px",
                                                                margin: "0",
                                                                height:"460px"
                                                            }}>
                                                    <Typography className="aqa-text-action-12" variant="inherit"
                                                                style={{
                                                                    textAlign: "left",
                                                                    padding: "4px",
                                                                    marginTop: "0px",
                                                                    paddingTop:window.innerWidth>1400?"0px":"100px"
                                                                }}>
                                                        <Typography className="" variant="inherit"
                                                                    style={{
                                                                        textAlign: "left",
                                                                        padding: "4px",
                                                                        marginTop: "0px",
                                                                        fontSize:"16px",
                                                                        fontFamily:"Montserrat",
                                                                        paddingBottom:"10px",
                                                                        fontWeight:"bold",
                                                                        color:"rgb(1, 38, 112)"
                                                                    }}>
                                                            What is the sequence?
                                                        </Typography>
                                                        <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                            1. You choose a file and <span style={{fontSize:"14px"}}><b> Upload</b></span> it here
                                                        </Typography>
                                                        <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                            2. We <span style={{fontSize:"14px"}}><b>Check</b></span> the file is valid
                                                        </Typography>
                                                        <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                            3. We <span style={{fontSize:"14px"}}><b>Analyse</b></span> the file contents
                                                        </Typography>
                                                        <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                            4. We  <span style={{fontSize:"14px"}}><b>Generate Alerts</b></span> for potential issues
                                                        </Typography>
                                                        <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                            5. You <span style={{fontSize:"14px"}}><b>Review</b></span> the alerts
                                                        </Typography>
                                                    </Typography>

                                                </Typography>
                                            </div>:""}
                                        </Grid>
                                    </Grid>
                                        <Input
                                            id="import-file"
                                            inputProps={{
                                                accept:
                                                    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                                            }}
                                            onChange={this.handleFileChange}
                                            style={{display:"none",opacity:'0'}}
                                            type="file"
                                        />
                                </div>
                            </MasterServant.Servant>
                        </MasterServant>
                    </div>
                </div>
            );
        }
    }
}
AqaSourceDetail.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSourceDetail);
