import React, { Component} from 'react';



const MIN_SIZE = 10;
const OPTIONAL_ATTRIBUTES = ["marginTop", "marginLeft", "marginBottom", "marginRight"];

export default class Scroller extends Component
{
//	static #serial = 0;
	static thickness = 16;
	
	constructor(props)
	{
		super(props);

		this.horizontal = (this.props.type && this.props.type.toLowerCase() === "horizontal");

		if (props.register) props.register(this);
//		this.id = `_scrb_${++Scroller.#serial}`;


		this.corridorRef = React.createRef();
		this.thickness = `${Scroller.thickness}px`;
		
		
		
		this.position = 0;
//		this.state = {graphicalPosition:0};

		this.graphicalPosition = 0;

		this.sliding = false;


		this.componentDidUpdate(null);
	} //

	getPosition() { return this.position; }

	notifyParentOfNewPosition(newPosition)
	{

// onsole.log("SST: NP");

// if (!this.horizontal) onsole.log("NOTIFYING!!!");


		//                                                                                          force  abs.  from slider
		this.props.parent[this.horizontal ? "scrollHorizontally" : "scrollVertically"](newPosition, false, true, true); // Yes we have an extra boolean
	} // notifyParentOfNewPosition





	/** @return true if position has changed */
	scrollTo(position, postOp)
	{
	
// onsole.log("S ST: [1]: " + this.position + " vs " + position);
	
		if (position < 0) position = 0;
		if (position > this.positionLimit) position = this.positionLimit;
		if (position !== this.position) 
		{
// onsole.log("SST: 2");

			this.position = position;
			this.graphicalPosition = position * this.cursorPositionLimit / this.positionLimit;
			this.forceUpdate(postOp);

			/*
			const graphicalPosition = position * this.cursorPositionLimit / this.positionLimit;
			this.setState({graphicalPosition}, postOp);
			*/

// onsole.log("SST: 3");
		}
		else if (postOp) postOp();


	} // scrollTo



	moveByGraphicalUnits(by) { this.moveToGraphicalUnits(this.graphicalPosition + by); }

	moveToGraphicalUnits(newGraphicalPosition)
	{
		if (newGraphicalPosition < 0) newGraphicalPosition = 0;
		if (newGraphicalPosition > this.cursorPositionLimit) newGraphicalPosition = this.cursorPositionLimit;
		if (newGraphicalPosition === this.graphicalPosition) return;

		this.graphicalPosition = newGraphicalPosition;

		this.forceUpdate
		(
			() =>
			{

/*		
console .log("RPC: max cursor pos:         " + this.cursorPositionLimit);
console .log("RPC: element requested size: " + this.props.size);
console .log("RPC: graphical position:     " + newGraphicalPosition);
console .log("RPC: graphical extent:       " + this.cursorSize);
console .log("RPC: data extent:            " + this.props.visible + " / " + this.props.extent);
*/		
				let newPosition = Math.round(newGraphicalPosition * this.positionLimit / this.cursorPositionLimit);
				if (newPosition < 0) newPosition = 0;
				if (newPosition > this.positionLimit) newPosition = this.positionLimit;
				if (newPosition !== this.position)
				{
					this.position = newPosition;
					this.notifyParentOfNewPosition(newPosition);
				}
			}
		);

	
	} // moveByGraphicalUnits



	handleMouseDown = e =>
	{
		this.sliding = true;
		e.target.setPointerCapture(e.pointerId);
		this.oldGraphicalPosition = (this.horizontal ? e.clientX : e.clientY);
	}; // handleMouseDown


	handleMouseMove = e =>
	{
		if (!this.sliding) return;
		const graphicalCoordinate = this.horizontal ? e.clientX : e.clientY;

		this.moveByGraphicalUnits(Math.round((graphicalCoordinate - this.oldGraphicalPosition) )); // / window.devicePixelRatio
		this.oldGraphicalPosition = graphicalCoordinate;

	}; // handleMouseMove


	
	handleMouseUp = e =>
	{
		e.stopPropagation();
		this.sliding = false;
		e.target.releasePointerCapture(e.pointerId);
	
	}; // handelMouseUp
	
	handleClickOnCorridor = e =>
	{
		const br = this.corridorRef.current;
		this.moveToGraphicalUnits(this.horizontal ? (e.clientX - br.offsetLeft) : (e.clientY - br.offsetTop));
	}; // handleClickOnCorridor


	componentDidUpdate(prevProps)
	{

// onsole.log("Slider getting notified of update: H: " + this.horizontal);

		if (this.props.extent > 0)
		{
			this.cursorSize = this.props.visible * this.props.size / this.props.extent;
			if (this.cursorSize < MIN_SIZE - 2)
			{
				this.cursorSize = MIN_SIZE - 2;
// onsole.log("Coercing to MIN SIZE");
			}

			this.cursorPositionLimit = this.props.size - this.cursorSize;
			this.positionLimit = this.props.extent - this.props.visible;
		}
	

	} // componentDidUpdate




	render()
	{

		if (this.props.visible === 0 || this.props.size < MIN_SIZE || this.props.extent === 0) return null;
		this.span = Math.floor(this.props.size * this.props.extent / this.props.visible);
		const size = `${this.props.size}px`;
		
		
		let thicknessAttribute, lengthAttribute, positionAttribute, stickToSideAttribute;
		if (this.horizontal)
		{
			thicknessAttribute   = "height";
			lengthAttribute      = "width";
			positionAttribute    = "left";
			stickToSideAttribute = "top";
		
		}
		else
		{
			thicknessAttribute   = "width";
			lengthAttribute      = "height";
			positionAttribute    = "top";
			stickToSideAttribute = "left"; 
		}

		const containerStyle = {[thicknessAttribute]:this.thickness, [lengthAttribute]:size, position:"relative", border:"1px solid #ddd",backgroundColor:"white"};
		let p;
		OPTIONAL_ATTRIBUTES.forEach(a => { if ((p = this.props[a])) containerStyle[a] = p; });

		return (
			<div ref={this.corridorRef} style={containerStyle} onClick={this.handleClickOnCorridor}>
				<div
					style={{
						position:"absolute",
						[thicknessAttribute]:this.thickness,
						[lengthAttribute]:`${this.cursorSize}px`,
						[stickToSideAttribute]:0,
						[positionAttribute]:this.graphicalPosition,
						backgroundColor:"#cef"
					}}
					onPointerDown={this.handleMouseDown}
					onPointerMove={this.handleMouseMove}
					onPointerUp={this.handleMouseUp}
					onPointerCancel={this.handleMouseUp}
					onPointerLeave={this.handleMouseUp}
					onPointerOut={this.handleMouseUp}
				/>
			</div>
		);

		
	} // render

} ////





// , border:"1px solid #ccc"
// , border:"1px solid #ccc"


/*

return (
				<div
					
					style={{width:this.thickness, height:size,
					position:"relative",
border:"1px dotted green"
					}}

					onClick={this.handleClickOnCorridor}
					ref={this.corridorRef}

				>
					<div
						style={{width:this.thickness, height:`${this.cursorSize}px`,backgroundColor:"#f00",
					position:"absolute", left:0, top:this.state.graphicalPosition
					
					}} 

						onPointerDown={this.handleMouseDown}
						onPointerMove={this.handleMouseMove}
						onPointerUp={this.handleMouseUp}
						onPointerCancel={this.handleMouseUp}
						onPointerLeave={this.handleMouseUp}
						onPointerOut={this.handleMouseUp}
					
					
					
					/>
				</div>
			);

*/


//		const ox = this.corridorRef.current.offsetLeft; //  / window.devicePixelRatio
//		const oy = this.corridorRef.current.offsetTop;  //  / window.devicePixelRatio

/*
const ox = br.offsetLeft; //  / window.devicePixelRatio
const oy = br.offsetTop;  //  / window.devicePixelRatio

console .log("Element Origin: (" + window.devicePixelRatio + ")");
console .log([ox, oy, br.offsetWidth, br.offsetHeight] + " " + this.props.size);

console .log("\nMouse pos:");
//console .log([e.screenX / window.devicePixelRatio, e.screenY / window.devicePixelRatio] + " > " + e.screenY);
console .log([e.clientX, e.clientY]);
*/

/*
console .log("\nScroll:");
console .log([window.scrollX, window.scrollY]);
*/

/*
let br = this.corridorRef.current.getBoundingClientRect();
const ox2 = br.left; //  / window.devicePixelRatio
const oy2 = br.top;

console .log("Element Origin 2:");
console .log([ox2, oy2]);
*/


