/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/DateBoundary', '../model/NumberBoundary'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./DateBoundary'), require('./NumberBoundary'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaVectorQualityDefinition = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.DateBoundary, root.ApiDocumentation.NumberBoundary);
  }
}(this, function(ApiClient, DateBoundary, NumberBoundary) {
  'use strict';




  /**
   * The AqaVectorQualityDefinition model module.
   * @module model/AqaVectorQualityDefinition
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaVectorQualityDefinition</code>.
   * @alias module:model/AqaVectorQualityDefinition
   * @class
   */
  var exports = function() {
    var _this = this;





















































  };

  /**
   * Constructs a <code>AqaVectorQualityDefinition</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaVectorQualityDefinition} obj Optional instance to populate.
   * @return {module:model/AqaVectorQualityDefinition} The populated <code>AqaVectorQualityDefinition</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('amberDateFormats')) {
        obj['amberDateFormats'] = ApiClient.convertToType(data['amberDateFormats'], ['String']);
      }
      if (data.hasOwnProperty('amberNumberFormats')) {
        obj['amberNumberFormats'] = ApiClient.convertToType(data['amberNumberFormats'], ['String']);
      }
      if (data.hasOwnProperty('amberStringFormats')) {
        obj['amberStringFormats'] = ApiClient.convertToType(data['amberStringFormats'], ['String']);
      }
      if (data.hasOwnProperty('amberStringValues')) {
        obj['amberStringValues'] = ApiClient.convertToType(data['amberStringValues'], ['String']);
      }
      if (data.hasOwnProperty('dateBoundary1')) {
        obj['dateBoundary1'] = DateBoundary.constructFromObject(data['dateBoundary1']);
      }
      if (data.hasOwnProperty('dateBoundary2')) {
        obj['dateBoundary2'] = DateBoundary.constructFromObject(data['dateBoundary2']);
      }
      if (data.hasOwnProperty('dateEnabled')) {
        obj['dateEnabled'] = ApiClient.convertToType(data['dateEnabled'], 'Boolean');
      }
      if (data.hasOwnProperty('dateRAG')) {
        obj['dateRAG'] = ApiClient.convertToType(data['dateRAG'], 'String');
      }
      if (data.hasOwnProperty('greenDateFormats')) {
        obj['greenDateFormats'] = ApiClient.convertToType(data['greenDateFormats'], ['String']);
      }
      if (data.hasOwnProperty('greenNumberFormats')) {
        obj['greenNumberFormats'] = ApiClient.convertToType(data['greenNumberFormats'], ['String']);
      }
      if (data.hasOwnProperty('greenStringFormats')) {
        obj['greenStringFormats'] = ApiClient.convertToType(data['greenStringFormats'], ['String']);
      }
      if (data.hasOwnProperty('greenStringValues')) {
        obj['greenStringValues'] = ApiClient.convertToType(data['greenStringValues'], ['String']);
      }
      if (data.hasOwnProperty('header')) {
        obj['header'] = ApiClient.convertToType(data['header'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('ignoreBlankValues')) {
        obj['ignoreBlankValues'] = ApiClient.convertToType(data['ignoreBlankValues'], 'Boolean');
      }
      if (data.hasOwnProperty('lastModifiedTimestamp')) {
        obj['lastModifiedTimestamp'] = ApiClient.convertToType(data['lastModifiedTimestamp'], 'Number');
      }
      if (data.hasOwnProperty('machineSuggested')) {
        obj['machineSuggested'] = ApiClient.convertToType(data['machineSuggested'], 'Boolean');
      }
      if (data.hasOwnProperty('nativeErrorRAG')) {
        obj['nativeErrorRAG'] = ApiClient.convertToType(data['nativeErrorRAG'], 'String');
      }
      if (data.hasOwnProperty('nativeErrorsEnabled')) {
        obj['nativeErrorsEnabled'] = ApiClient.convertToType(data['nativeErrorsEnabled'], 'Boolean');
      }
      if (data.hasOwnProperty('numberBoundary1')) {
        obj['numberBoundary1'] = NumberBoundary.constructFromObject(data['numberBoundary1']);
      }
      if (data.hasOwnProperty('numberBoundary2')) {
        obj['numberBoundary2'] = NumberBoundary.constructFromObject(data['numberBoundary2']);
      }
      if (data.hasOwnProperty('numberEnabled')) {
        obj['numberEnabled'] = ApiClient.convertToType(data['numberEnabled'], 'Boolean');
      }
      if (data.hasOwnProperty('numberRAG')) {
        obj['numberRAG'] = ApiClient.convertToType(data['numberRAG'], 'String');
      }
      if (data.hasOwnProperty('populatedBoundary')) {
        obj['populatedBoundary'] = NumberBoundary.constructFromObject(data['populatedBoundary']);
      }
      if (data.hasOwnProperty('populatedEnabled')) {
        obj['populatedEnabled'] = ApiClient.convertToType(data['populatedEnabled'], 'Boolean');
      }
      if (data.hasOwnProperty('populatedType')) {
        obj['populatedType'] = ApiClient.convertToType(data['populatedType'], 'String');
      }
      if (data.hasOwnProperty('qualityEnabled')) {
        obj['qualityEnabled'] = ApiClient.convertToType(data['qualityEnabled'], 'Boolean');
      }
      if (data.hasOwnProperty('redDateFormats')) {
        obj['redDateFormats'] = ApiClient.convertToType(data['redDateFormats'], ['String']);
      }
      if (data.hasOwnProperty('redNumberFormats')) {
        obj['redNumberFormats'] = ApiClient.convertToType(data['redNumberFormats'], ['String']);
      }
      if (data.hasOwnProperty('redStringFormats')) {
        obj['redStringFormats'] = ApiClient.convertToType(data['redStringFormats'], ['String']);
      }
      if (data.hasOwnProperty('redStringValues')) {
        obj['redStringValues'] = ApiClient.convertToType(data['redStringValues'], ['String']);
      }
      if (data.hasOwnProperty('requiredType')) {
        obj['requiredType'] = ApiClient.convertToType(data['requiredType'], 'String');
      }
      if (data.hasOwnProperty('stringEnabled')) {
        obj['stringEnabled'] = ApiClient.convertToType(data['stringEnabled'], 'Boolean');
      }
      if (data.hasOwnProperty('stringLengthBoundary1')) {
        obj['stringLengthBoundary1'] = NumberBoundary.constructFromObject(data['stringLengthBoundary1']);
      }
      if (data.hasOwnProperty('stringLengthBoundary2')) {
        obj['stringLengthBoundary2'] = NumberBoundary.constructFromObject(data['stringLengthBoundary2']);
      }
      if (data.hasOwnProperty('stringRAG')) {
        obj['stringRAG'] = ApiClient.convertToType(data['stringRAG'], 'String');
      }
      if (data.hasOwnProperty('typeEnabled')) {
        obj['typeEnabled'] = ApiClient.convertToType(data['typeEnabled'], 'Boolean');
      }
      if (data.hasOwnProperty('typeSeverity')) {
        obj['typeSeverity'] = ApiClient.convertToType(data['typeSeverity'], 'String');
      }
      if (data.hasOwnProperty('uniquenessBoundary')) {
        obj['uniquenessBoundary'] = NumberBoundary.constructFromObject(data['uniquenessBoundary']);
      }
      if (data.hasOwnProperty('uniquenessEnabled')) {
        obj['uniquenessEnabled'] = ApiClient.convertToType(data['uniquenessEnabled'], 'Boolean');
      }
      if (data.hasOwnProperty('uniquenessType')) {
        obj['uniquenessType'] = ApiClient.convertToType(data['uniquenessType'], 'String');
      }
      if (data.hasOwnProperty('unlistedDateFormatColour')) {
        obj['unlistedDateFormatColour'] = ApiClient.convertToType(data['unlistedDateFormatColour'], 'String');
      }
      if (data.hasOwnProperty('unlistedNumberFormatColour')) {
        obj['unlistedNumberFormatColour'] = ApiClient.convertToType(data['unlistedNumberFormatColour'], 'String');
      }
      if (data.hasOwnProperty('unlistedStringFormatColour')) {
        obj['unlistedStringFormatColour'] = ApiClient.convertToType(data['unlistedStringFormatColour'], 'String');
      }
      if (data.hasOwnProperty('unlistedStringValueColour')) {
        obj['unlistedStringValueColour'] = ApiClient.convertToType(data['unlistedStringValueColour'], 'String');
      }
      if (data.hasOwnProperty('useAllowedValues')) {
        obj['useAllowedValues'] = ApiClient.convertToType(data['useAllowedValues'], 'Boolean');
      }
      if (data.hasOwnProperty('useDateBoundaries')) {
        obj['useDateBoundaries'] = ApiClient.convertToType(data['useDateBoundaries'], 'Boolean');
      }
      if (data.hasOwnProperty('useDateFormats')) {
        obj['useDateFormats'] = ApiClient.convertToType(data['useDateFormats'], 'Boolean');
      }
      if (data.hasOwnProperty('useNumberBoundaries')) {
        obj['useNumberBoundaries'] = ApiClient.convertToType(data['useNumberBoundaries'], 'Boolean');
      }
      if (data.hasOwnProperty('useNumberFormats')) {
        obj['useNumberFormats'] = ApiClient.convertToType(data['useNumberFormats'], 'Boolean');
      }
      if (data.hasOwnProperty('useStringFormats')) {
        obj['useStringFormats'] = ApiClient.convertToType(data['useStringFormats'], 'Boolean');
      }
      if (data.hasOwnProperty('useStringLengths')) {
        obj['useStringLengths'] = ApiClient.convertToType(data['useStringLengths'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * @member {Array.<String>} amberDateFormats
   */
  exports.prototype['amberDateFormats'] = undefined;
  /**
   * @member {Array.<String>} amberNumberFormats
   */
  exports.prototype['amberNumberFormats'] = undefined;
  /**
   * @member {Array.<String>} amberStringFormats
   */
  exports.prototype['amberStringFormats'] = undefined;
  /**
   * @member {Array.<String>} amberStringValues
   */
  exports.prototype['amberStringValues'] = undefined;
  /**
   * @member {module:model/DateBoundary} dateBoundary1
   */
  exports.prototype['dateBoundary1'] = undefined;
  /**
   * @member {module:model/DateBoundary} dateBoundary2
   */
  exports.prototype['dateBoundary2'] = undefined;
  /**
   * @member {Boolean} dateEnabled
   */
  exports.prototype['dateEnabled'] = undefined;
  /**
   * @member {module:model/AqaVectorQualityDefinition.DateRAGEnum} dateRAG
   */
  exports.prototype['dateRAG'] = undefined;
  /**
   * @member {Array.<String>} greenDateFormats
   */
  exports.prototype['greenDateFormats'] = undefined;
  /**
   * @member {Array.<String>} greenNumberFormats
   */
  exports.prototype['greenNumberFormats'] = undefined;
  /**
   * @member {Array.<String>} greenStringFormats
   */
  exports.prototype['greenStringFormats'] = undefined;
  /**
   * @member {Array.<String>} greenStringValues
   */
  exports.prototype['greenStringValues'] = undefined;
  /**
   * @member {String} header
   */
  exports.prototype['header'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {Boolean} ignoreBlankValues
   */
  exports.prototype['ignoreBlankValues'] = undefined;
  /**
   * @member {Number} lastModifiedTimestamp
   */
  exports.prototype['lastModifiedTimestamp'] = undefined;
  /**
   * @member {Boolean} machineSuggested
   */
  exports.prototype['machineSuggested'] = undefined;
  /**
   * @member {module:model/AqaVectorQualityDefinition.NativeErrorRAGEnum} nativeErrorRAG
   */
  exports.prototype['nativeErrorRAG'] = undefined;
  /**
   * @member {Boolean} nativeErrorsEnabled
   */
  exports.prototype['nativeErrorsEnabled'] = undefined;
  /**
   * @member {module:model/NumberBoundary} numberBoundary1
   */
  exports.prototype['numberBoundary1'] = undefined;
  /**
   * @member {module:model/NumberBoundary} numberBoundary2
   */
  exports.prototype['numberBoundary2'] = undefined;
  /**
   * @member {Boolean} numberEnabled
   */
  exports.prototype['numberEnabled'] = undefined;
  /**
   * @member {module:model/AqaVectorQualityDefinition.NumberRAGEnum} numberRAG
   */
  exports.prototype['numberRAG'] = undefined;
  /**
   * @member {module:model/NumberBoundary} populatedBoundary
   */
  exports.prototype['populatedBoundary'] = undefined;
  /**
   * @member {Boolean} populatedEnabled
   */
  exports.prototype['populatedEnabled'] = undefined;
  /**
   * @member {module:model/AqaVectorQualityDefinition.PopulatedTypeEnum} populatedType
   */
  exports.prototype['populatedType'] = undefined;
  /**
   * @member {Boolean} qualityEnabled
   */
  exports.prototype['qualityEnabled'] = undefined;
  /**
   * @member {Array.<String>} redDateFormats
   */
  exports.prototype['redDateFormats'] = undefined;
  /**
   * @member {Array.<String>} redNumberFormats
   */
  exports.prototype['redNumberFormats'] = undefined;
  /**
   * @member {Array.<String>} redStringFormats
   */
  exports.prototype['redStringFormats'] = undefined;
  /**
   * @member {Array.<String>} redStringValues
   */
  exports.prototype['redStringValues'] = undefined;
  /**
   * @member {module:model/AqaVectorQualityDefinition.RequiredTypeEnum} requiredType
   */
  exports.prototype['requiredType'] = undefined;
  /**
   * @member {Boolean} stringEnabled
   */
  exports.prototype['stringEnabled'] = undefined;
  /**
   * @member {module:model/NumberBoundary} stringLengthBoundary1
   */
  exports.prototype['stringLengthBoundary1'] = undefined;
  /**
   * @member {module:model/NumberBoundary} stringLengthBoundary2
   */
  exports.prototype['stringLengthBoundary2'] = undefined;
  /**
   * @member {module:model/AqaVectorQualityDefinition.StringRAGEnum} stringRAG
   */
  exports.prototype['stringRAG'] = undefined;
  /**
   * @member {Boolean} typeEnabled
   */
  exports.prototype['typeEnabled'] = undefined;
  /**
   * @member {module:model/AqaVectorQualityDefinition.TypeSeverityEnum} typeSeverity
   */
  exports.prototype['typeSeverity'] = undefined;
  /**
   * @member {module:model/NumberBoundary} uniquenessBoundary
   */
  exports.prototype['uniquenessBoundary'] = undefined;
  /**
   * @member {Boolean} uniquenessEnabled
   */
  exports.prototype['uniquenessEnabled'] = undefined;
  /**
   * @member {module:model/AqaVectorQualityDefinition.UniquenessTypeEnum} uniquenessType
   */
  exports.prototype['uniquenessType'] = undefined;
  /**
   * @member {module:model/AqaVectorQualityDefinition.UnlistedDateFormatColourEnum} unlistedDateFormatColour
   */
  exports.prototype['unlistedDateFormatColour'] = undefined;
  /**
   * @member {module:model/AqaVectorQualityDefinition.UnlistedNumberFormatColourEnum} unlistedNumberFormatColour
   */
  exports.prototype['unlistedNumberFormatColour'] = undefined;
  /**
   * @member {module:model/AqaVectorQualityDefinition.UnlistedStringFormatColourEnum} unlistedStringFormatColour
   */
  exports.prototype['unlistedStringFormatColour'] = undefined;
  /**
   * @member {module:model/AqaVectorQualityDefinition.UnlistedStringValueColourEnum} unlistedStringValueColour
   */
  exports.prototype['unlistedStringValueColour'] = undefined;
  /**
   * @member {Boolean} useAllowedValues
   */
  exports.prototype['useAllowedValues'] = undefined;
  /**
   * @member {Boolean} useDateBoundaries
   */
  exports.prototype['useDateBoundaries'] = undefined;
  /**
   * @member {Boolean} useDateFormats
   */
  exports.prototype['useDateFormats'] = undefined;
  /**
   * @member {Boolean} useNumberBoundaries
   */
  exports.prototype['useNumberBoundaries'] = undefined;
  /**
   * @member {Boolean} useNumberFormats
   */
  exports.prototype['useNumberFormats'] = undefined;
  /**
   * @member {Boolean} useStringFormats
   */
  exports.prototype['useStringFormats'] = undefined;
  /**
   * @member {Boolean} useStringLengths
   */
  exports.prototype['useStringLengths'] = undefined;


  /**
   * Allowed values for the <code>dateRAG</code> property.
   * @enum {String}
   * @readonly
   */
  exports.DateRAGEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>nativeErrorRAG</code> property.
   * @enum {String}
   * @readonly
   */
  exports.NativeErrorRAGEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>numberRAG</code> property.
   * @enum {String}
   * @readonly
   */
  exports.NumberRAGEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>populatedType</code> property.
   * @enum {String}
   * @readonly
   */
  exports.PopulatedTypeEnum = {
    /**
     * value: "percentagePopulated"
     * @const
     */
    "percentagePopulated": "percentagePopulated",
    /**
     * value: "absolutePopulated"
     * @const
     */
    "absolutePopulated": "absolutePopulated"  };

  /**
   * Allowed values for the <code>requiredType</code> property.
   * @enum {String}
   * @readonly
   */
  exports.RequiredTypeEnum = {
    /**
     * value: "unknown"
     * @const
     */
    "unknown": "unknown",
    /**
     * value: "string"
     * @const
     */
    "string": "string",
    /**
     * value: "number"
     * @const
     */
    "number": "number",
    /**
     * value: "date"
     * @const
     */
    "date": "date",
    /**
     * value: "nativeError"
     * @const
     */
    "nativeError": "nativeError"  };

  /**
   * Allowed values for the <code>stringRAG</code> property.
   * @enum {String}
   * @readonly
   */
  exports.StringRAGEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>typeSeverity</code> property.
   * @enum {String}
   * @readonly
   */
  exports.TypeSeverityEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>uniquenessType</code> property.
   * @enum {String}
   * @readonly
   */
  exports.UniquenessTypeEnum = {
    /**
     * value: "UniquePercentage"
     * @const
     */
    "UniquePercentage": "UniquePercentage",
    /**
     * value: "DuplicatesAllowed"
     * @const
     */
    "DuplicatesAllowed": "DuplicatesAllowed",
    /**
     * value: "RepeatsAllowed"
     * @const
     */
    "RepeatsAllowed": "RepeatsAllowed"  };

  /**
   * Allowed values for the <code>unlistedDateFormatColour</code> property.
   * @enum {String}
   * @readonly
   */
  exports.UnlistedDateFormatColourEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>unlistedNumberFormatColour</code> property.
   * @enum {String}
   * @readonly
   */
  exports.UnlistedNumberFormatColourEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>unlistedStringFormatColour</code> property.
   * @enum {String}
   * @readonly
   */
  exports.UnlistedStringFormatColourEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>unlistedStringValueColour</code> property.
   * @enum {String}
   * @readonly
   */
  exports.UnlistedStringValueColourEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };


  return exports;
}));


