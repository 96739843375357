import React, { Component } from 'react';
import MsContext from './MsContext.js';

const MASTERSERVANT_TYPE = "MasterServant";
const SERVANT_TYPE = "Servant";
const MASTER_TYPE = "Master";


class Master extends Component
{
	static displayName = MASTER_TYPE;


	render()
	{
		return <>{ this.props.children }</>

	} // 

} //// Master


class Servant extends Component
{
	static displayName = SERVANT_TYPE;

	static contextType = MsContext;	
	
	constructor(props)
	{
		super(props);
		this.state = {};
	} //

	render()
	{
		// So that clickers will know who to target.
		this.context.registerServant(this);

		if (this.state.contentComponent) return <>{this.state.contentComponent}</>;

		return <>{ this.props.children }</>
	} //

} //// Servant




export default class MasterServant extends Component
{
	static displayName = MASTERSERVANT_TYPE;

	constructor(props)
	{
		super(props);

		// Keeping a record of the servant
		if (this.props.children) for (let child of this.props.children)
		{
			// Do we want to throw an exception if we find more than one of each?
			// Keeping those in case we want to restrict rendering only to the servant and the master
			if (child.type.displayName === SERVANT_TYPE) this.servantChild = child;
			if (child.type.displayName === MASTER_TYPE) this.masterChild = child;
			
		}
//		this.cc = 0;
	
	} //

	// CallBack for clickers
	show(newContentComponent) { this.servant.setState({contentComponent:newContentComponent}); }

	render()
	{
		// const {children} = this.props;
		
		// https://reactjs.org/docs/context.html
		
		// Passing on the fly functions as references to ours don't work - probably for lack of binding. Geeeeeeez
		// Show has to be indirected as servant is not known yet.
		// Use masterChild and servant child if you want to restrict display only to master and servant blocks
		let ret = (
		
			<MsContext.Provider value={{registerServant:(s) => this.servant = s, show:(c) => this.show(c)}}>
				<div style={{paddingBottom:"4px"}}>
					{this.props.children}
					
					{/*
					{this.masterChild}
					{this.servantChild}
					*/}
				</div>
			</MsContext.Provider>
		);

		return ret;

	} // render

} ////

MasterServant.Master = Master;
MasterServant.Servant = Servant;


// https://dev.to/shayanypn/buckle-with-react-sub-component-10ll


// To go up until we find a Master Servant.
// https://stackoverflow.com/questions/34257665/is-there-any-way-to-access-the-parent-component-instance-in-react




// Example on how to drive the servant from the master pane
/*
import { Component } from 'react';
import MsContext from './MsContext.js';


import DemoFiller from './DemoFiller.js';


export default class DemoClicker extends Component
{

	static contextType = MsContext;

	render()
	{
		// Getting our show function.
		this.show = this.context.show;

		// Rendering
		return (
			<div
				style={{margin:"10px",border:"1px solid purple"}}
				onClick={ () => this.show(<DemoFiller dfColor={this.props.color} dfText={this.props.otherText} />) } 
			>
				{this.props.text}
			</div>
		);
	} // render


} ////
*/




// Component life cycle
// https://reactjs.org/docs/react-component.html









