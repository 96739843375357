/* Welcome to Aqa Client*/
/* Version: 1 */

//import MasterServant       from "../shared/masterservant/MasterServant.js";
import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as BackIcon} from "../../images/aqa-icons/Backcol.svg";
//import AqaAccountView from "./AqaAccountView";
import AqaAccountUserHeader from "./AqaAccountUserHeader";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import HtmlTooltip from "@material-ui/core/Tooltip";
import AqaAccountPMHeader from "./AqaAccountPMHeader";
import Paper from "@material-ui/core/Paper";
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import {ReactComponent as AccountIcon} from "../../images/aqa-icons/Usercol.svg";
import {ReactComponent as PadlockIcon} from "../../images/aqa-icons/AQA-Settingscol.svg";
import AqaAccountSettings from "./AqaAccountSettings";
import AqaAccountCredential from "./AqaAccountCredential";


const styles = theme => ({
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    welcomelabel: {
        fontSize: 18,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 20,
        marginRight: 20,
        color: "#006"
    },
    root: {
        color: theme.palette.text.secondary,
        fontFamily:'Montserrat, sans-serif',
        '&$selected': {
            color: theme.palette.primary.main,
            width:'75px'
        },
        '&$selected svg': {
            fill: theme.palette.primary.main,
        },
        height:'44px'
    },
    /* Styles applied to the root element if selected. */
    selected: {},
    bottomNav:{
        minWidth:'60px',
        fontFamily:'Montserrat, sans-serif'
    },
});
class AqaAccountManager extends AqaComponent {
    constructor(props) {
        super(props);
        // Assign A ref to state for refresh
        this.state = {
            username:"",
            which:1,
            selectedUsers:[],
            allUsers:[],
            account:null,
            currentUser:null,
            height:window.innerHeight-100,
            openPlanEditor:false,
            selectedPMs:[],
            allPMs:[],
            resetCheckbox:false,
            currentTab:0,
            whichTab:0
        };
        this.handleHome=this.handleHome.bind(this);
        this.handleHeader=this.handleHeader.bind(this);
        this.planNavigation=this.planNavigation.bind(this);
        this.planNavigationReset=this.planNavigationReset.bind(this);
        this.handleAccountDetails=this.handleAccountDetails.bind(this);
        this.handleUserDeleteReset=this.handleUserDeleteReset.bind(this);
        this.handleUserSelectionCheckReset=this.handleUserSelectionCheckReset.bind(this);
        this.handleUserSelectionReset=this.handleUserSelectionReset.bind(this);
        this.handleUserSelection=this.handleUserSelection.bind(this);
        this.handleUserDetails=this.handleUserDetails.bind(this);
        this.handleDimensions=this.handleDimensions.bind(this);
        this.handlePMDeleteReset=this.handlePMDeleteReset.bind(this);
        this.handlePMSelectionCheckReset=this.handlePMSelectionCheckReset.bind(this);
        this.handlePMSelectionReset=this.handlePMSelectionReset.bind(this);
        this.handlePMSelection=this.handlePMSelection.bind(this);
        this.accountSettings=null;
        this.accountUsers=null;
        this.accountOverview=null;

    }
    handleHome(){
        let {isAccountExpired,isSubscriptionCancelled} = this.props.dataFromParent.state;
        if(isSubscriptionCancelled || isAccountExpired) return;
        this.props.dataFromParent.handleSourceReset();
        this.props.dataFromParent.navigation(1);
    }

    handleSource(source){
        this.props.dataFromParent.handleSource(source);
    }

    handleUserDeleteReset(){
        this.setState({selectedUsers:[],resetCheckbox:true});
    }

    handleUserSelectionReset(){
        this.setState({allUsers:[],selectedUsers:[],resetCheckbox:true});
        if(this.accountUsers) this.accountUsers.handleUsers();
    }
    handleUserSelectionCheckReset(){
        this.setState({resetCheckbox:false});
    }
    handleUserSelection(user,isSelected){
        var allUsers=this.state.allUsers;
        var selectedUsers=this.state.selectedUsers;
        if(isSelected === true){
            if(user !== null && user !== undefined){
                var index = allUsers.includes(user.email);
                if(!index){
                    allUsers.push(user.email);
                    selectedUsers.push(user);
                    this.setState({selectedUsers:selectedUsers,allUsers:allUsers});
                }
            }
        }
        else{
            if(user !== null && user !== undefined){
                index = allUsers.includes(user.email);
                if(index){
                    var newSelectedUsers = [];
                    var newAllUsers = [];
                    for(var i=0;i<allUsers.length;i++){
                        var allUser = allUsers[i];
                        var selectedUser=selectedUsers[i];
                        if(allUser !== user.email){
                            newAllUsers.push(allUser);
                            newSelectedUsers.push(selectedUser);
                        }
                    }
                    selectedUsers=newSelectedUsers;
                    allUsers=newAllUsers;
                    this.setState({selectedUsers:selectedUsers,allUsers:allUsers});
                }
            }
        }
    }

    handlePMDeleteReset(){
        this.setState({selectedPMs:[],resetCheckbox:true});
    }

    handlePMSelectionReset(){
        this.setState({allPMs:[],selectedPMs:[],resetCheckbox:true});
    }
    handlePMSelectionCheckReset(){
        this.setState({resetCheckbox:false});
    }
    handlePMSelection(pm,isSelected){
        var allPMs=this.state.allPMs;
        var selectedPMs=this.state.selectedPMs;
        if(isSelected === true){
            if(pm !== null && pm !== undefined){
                var index = allPMs.includes(pm.id);
                if(!index){
                    allPMs.push(pm.id);
                    selectedPMs.push(pm);
                    this.setState({selectedPMs:selectedPMs,allPMs:allPMs});
                }
            }
        }
        else{
            if(pm !== null && pm !== undefined){
                index = allPMs.includes(pm.id);
                if(index){
                    var newSelectedPMs = [];
                    var newAllPMs = [];
                    for(var i=0;i<allPMs.length;i++){
                        var allPM = allPMs[i];
                        var selectedPM=selectedPMs[i];
                        if(allPM !== pm.id){
                            newAllPMs.push(allPM);
                            newSelectedPMs.push(selectedPM);
                        }
                    }
                    selectedPMs=newSelectedPMs;
                    allPMs=newAllPMs;
                    this.setState({selectedPMs:selectedPMs,allPMs:allPMs});
                }
            }
        }
    }

    planNavigation(){
        this.setState({openPlanEditor:true});
    }

    planNavigationReset(){
        this.setState({openPlanEditor:false});
    }

    handleHeader(which){
        this.setState({which:which});
    }

    handleHeaderTab = (which,whichTab)=>{
        this.setState({which:which,whichTab:whichTab});
    }

    handleUserDetails(){
        AqaComponent.userBackend.meUsingGET(
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the user details from the server was encountered.",
                    "AqaAccountUsers.handleUserDetails, backend call: " + error,this);
                else{
                    this.setState({currentUser:data});
                }
            }

        );
    }

    handleAccountDetails(){
//        var accountId=this.props.dataFromRoot.state.accountId;
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    this.setState({account:accountObj});
                    this.handleUserDetails();
                }
            }
        );
    }

    handleDimensions =(event)=>{
        event.preventDefault();
        this.setState({height:window.innerHeight-100});
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.whichTab !== this.props.whichTab){
            this.setState({whichTab:this.props.whichTab});
        }
    }

    componentDidMount() {
        var username=this.props.dataFromRoot.state.username;
        this.setState({username:username});
        this.handleAccountDetails();
        if(this.props.dataForStripeSuccess){
            var customNotification = {
                title: "Plan Changed", titleIcon: "",
                message: "Your payment has been received. We have changed your plan",
                isCustom: false, type: "info",
                insert: "bottom", position: "bottom-center", autoClose: true, duration: 8000
            };
            this.removeAllNotifications();
            this.props.dataFromParent.handleStripeReset();
            setTimeout((e)=>{
                this.showNotification(null, customNotification);
                if(this.accountOverview) this.accountOverview.handleAccountDetails(true);
            },5500)
        }
        window.addEventListener("onload",this.handleDimensions);
        window.addEventListener("fullScreenChange",this.handleDimensions);
        window.addEventListener("resize",this.handleDimensions);
    }

    render()
    {
        const { classes } = this.props;
        const {which} = this.state;
        let {currentTab} = this.state;
        let username = "";
        let authCode ="V";
        let user = this.props.dataFromRoot.state.user;
        if(user!==null){
            username = user.firstname!==null && user.lastname!==null && user.firstname!=="" && user.lastname!==""?user.firstname+" "+user.lastname:user.email;
            authCode=user.permissions;
        }
        if(authCode === "V" || authCode === "U") currentTab = 1;
        return(

            <div className={"source-detail-masterservant"} >
                <Grid container spacing={0} direction="row" justify="space-between"
                      alignItems="center"
                      style={{padding: 0, marginTop: "0px"}}>

                    <Grid item xs={3} sm={3} lg={3} xl={3}>
                        <div className="aqa-main-header"
                             style={{borderBottom: "0px solid #F9F7F9", backgroundColor: "white", marginTop: "-5px"}}>
                                <span style={{
                                    float: "left",
                                    width: "100%",
                                    backgroundColor: "white",
                                    height: "56px"
                                }}>
                                    <Typography variant="inherit" color="inherit"
                                                style={{textAlign: "left", fontSize: "0.8rem", width: "100%",paddingTop:"5px"}}>
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            <div>
                                                <Typography color="inherit" className="aqa-text-action">Click to return to Home</Typography>
                                            </div>
                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <IconButton className="aqa-button" onClick={this.handleHome.bind(this)}
                                                    style={{padding:"10px 4px 4px 14px"}}>

                                            <div className="aqa-icon">
                                                <BackIcon width="32px"/>
                                            </div>
                                            <Typography className={"aqa-text-bold"} variant="inherit"
                                                        style={{textAlign: "left", marginLeft: "4px", marginTop: "-5px"}}>
                                                {username}
                                            </Typography>
                                        </IconButton>
                                        </HtmlTooltip>

                                    </Typography>
                                </span>
                        </div>
                    </Grid>
                    <Grid item xs={9} sm={9} lg={9} xl={9}>
                        <div className="aqa-main-header"
                             style={{borderBottom: "0px solid #F9F7F9", backgroundColor: "white", marginTop: "-5px"}}>
                                <span style={{
                                    float: "left",
                                    width: "100%",
                                    backgroundColor: "white",
                                    height: "56px"
                                }}>
                                    {which===2 && this.state.whichTab===1?(
                                    <AqaAccountUserHeader dataFromParent={this} dataFromRoot={this} dataForAccount={this.state.account} dataForCurrentUser={this.state.currentUser} />
                                        ):(which===4 && this.state.whichTab===4?(
                                        <AqaAccountPMHeader dataFromParent={this} dataFromRoot={this} dataForAccount={this.state.account} dataForCurrentUser={this.state.currentUser} />
                                    ):(""))}
                                </span>
                        </div>
                    </Grid>
                </Grid>
                    <div>
                        <div>
                            <Grid container spacing={0} direction="row" justify="space-between"
                                  alignItems="center"
                                  style={{padding: 0, marginTop: "-10px"}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography className="aqa-text-light-16" variant="inherit" style={{textAlign:"center",padding:"0px",marginTop:"0px",marginLeft:"0px",paddingTop:"10px"}}>

                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <div className="ava-home-panel" style={{}}>
                                        <Typography className="aqa-text-bold-12" variant="inherit" style={{textAlign:"center",padding:"0px",marginTop:"0px",marginLeft:"0px",paddingTop:"0px",}}>
                                            {currentTab === 0?<AqaAccountSettings
                                                    dataAcquired={"account"}
                                                    parent={this}
                                                    dataFromRoot={this}
                                                    dataFromMain={this.props.dataFromParent}
                                                    dataFromParent={this.props.dataFromRoot}
                                                    dataForPlanEditor={this.props.dataForPlanEditor}
                                                    dataForPlanEditor2={this.props.dataForPlanEditor2}
                                                    whichTab={this.state.whichTab}
                                                    dataForCheckbox={this.props.dataForCheckbox}
                                                />:
                                                currentTab === 1?<AqaAccountCredential dataAcquired={"credential"} dataFromRoot={this} dataFromParent={this.props.dataFromParent} account={this.props.dataFromParent.props.parent.state.account} />:
                                                                ""
                                            }
                                        </Typography>
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    {authCode!=="V" && authCode!=="U"?
                                    <Paper style={{ position: 'fixed', bottom: window.innerWidth<900?0:36, left: 0, right: 0 }} elevation={3}>
                                        <BottomNavigation
                                            className={classes.root}
                                            showLabels={true}
                                            id={"mymenu"}
                                            value={this.state.currentTab}
                                            onChange={(event, newValue) => {

                                                this.setState({currentTab:newValue});
                                            }}
                                        >
                                            <BottomNavigationAction className={classes.bottomNav} label="Account" icon={<AccountIcon width={"24px"} height={"24px"} style={{fill:this.state.currentTab===0?"#3f51b5":"#7f7f7f",width:"24px"}} />} />
                                            <BottomNavigationAction className={classes.bottomNav} label="Security" icon={<PadlockIcon width="24px" height="24px" style={{fill:this.state.currentTab===1?"#3f51b5":"#7f7f7f",width:"24px"}} />} />

                                        </BottomNavigation>
                                    </Paper>
                                        :""
                                    }
                                </Grid>
                            </Grid>
                        </div>

                        {/*<MasterServant>

                        <MasterServant.Master>
                            <div className={"source-detail-master-pane"} style={{height:height}}>
                                <AqaAccountView which={this.props.which} dataFromRoot={this} dataForCheckbox={resetCheckbox} dataForPlanEditor={this.props.dataForPlanEditor} dataFromParent={this.props.dataFromParent} dataForPlanEditor2={this.state.openPlanEditor}/>
                            </div>
                        </MasterServant.Master>
                        <MasterServant.Servant>
                        </MasterServant.Servant>
                    </MasterServant>*/}
                </div>
            </div>
        )
    }
}
AqaAccountManager.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaAccountManager);
