import AqaComponent  from "../components/shared/aqacomponent/AqaComponent"
import ColourAndOverrideMaps from "./ColourAndOverrideMaps"

/** Narrow methods were used for the narrow colour matrix. If the wide colour matrix is adopted permanently they can be removed. */


export default class ColourMap
{

//	static HEADER_SIZE = 8; // 2 Integers
	
	static GREEN = 2;
	static AMBER = 1;
	static RED = 0;


	static ALL_GREENS = 
	{
		overall:        ColourMap.GREEN,
	
		populated:      ColourMap.GREEN,
		uniqueness:     ColourMap.GREEN,

		numberFormat:   ColourMap.GREEN,
		numberBoundary: ColourMap.GREEN,

		dateFormat:     ColourMap.GREEN,
		dateBoundary:   ColourMap.GREEN,

		stringFormat:   ColourMap.GREEN,
		stringLength:   ColourMap.GREEN,
		stringAllowed:  ColourMap.GREEN,

		type:           ColourMap.GREEN,

		nativeError:    ColourMap.GREEN
	};




	// This must be kept in sync with AqaVectorColours
	static POPULATION_AND_UNIQUENESS    = 0;
	static NUMBER_FORMAT_AND_BOUNDARIES = 1;
	static DATE_FORMAT_AND_BOUNDARIES   = 2;
	static FORMATS_LENGTHS_AND_VALUES   = 3;
	static COLUMN_TYPE_AND_NATIVE       = 4;
	
	static NUMBER_OF_BYTE_STORES_FOR_SPECIFIC_QUALITIES = 5;
	
	
/*
	static OVERALL_POSITION_IN_OVERALL_RAG_SHORT      = 0;
	static POPULATION_POSITION_IN_OVERALL_RAG_SHORT   = 1;
	static UNIQUENESS_POSITION_IN_OVERALL_RAG_SHORT   = 2;
	static NUMBER_POSITION_IN_OVERALL_RAG_SHORT       = 3;
	static DATE_POSITION_IN_OVERALL_RAG_SHORT         = 4;
	static STRING_POSITION_IN_OVERALL_RAG_SHORT       = 5;
	static TYPE_POSITION_IN_OVERALL_RAG_SHORT         = 6;
	static NATIVE_ERROR_POSITION_IN_OVERALL_RAG_SHORT = 7;
*/
	
	static TYPE2BYTE =
	[
		/* OVERALL      */ [ColourMap.POPULATION_AND_UNIQUENESS, 6, 0],
		/* POPULATION   */ [ColourMap.POPULATION_AND_UNIQUENESS, 0, 0],
		/* UNIQUENESS   */ [ColourMap.POPULATION_AND_UNIQUENESS, 2, 0],
		/* NUMBER       */ [ColourMap.NUMBER_FORMAT_AND_BOUNDARIES, 0, 2],
		/* DATE         */ [ColourMap.DATE_FORMAT_AND_BOUNDARIES, 0, 2],
		/* STRING       */ [ColourMap.FORMATS_LENGTHS_AND_VALUES, 0, 2],
		/* TYPE         */ [ColourMap.COLUMN_TYPE_AND_NATIVE, 0, 0],
		/* NATIVE       */ [ColourMap.COLUMN_TYPE_AND_NATIVE, 0, 0]
	];


	static worseOf2(b)
	{
		const b1 = b & 3, b2 = ((b & 6) >> 2);
		if (b1 < b2) return b1 < b2 ? b1 : b2;
	} // worseOf2

	static worseOf3(b)
	{
		let b1 = b & 3;
		const b2 = (b & 6) >> 2, b3 =(b & 12) >> 4;
		if (b2 < b1) b1 = b2;
		return b3 < b1 ? b3 : b1;
	} // takeWorseOf2
	

	static isGreen(cartouche)
	{
		if (cartouche === null) return true;
		return (cartouche[ColourMap.POPULATION_AND_UNIQUENESS] >> 6) === ColourMap.GREEN;
	} // isGreen

	static overAllColour(cartouche)
	{
		if (cartouche === null) return ColourMap.GREEN;
		return cartouche[ColourMap.POPULATION_AND_UNIQUENESS] >> 6;
	} // overAllColour
	
	

	
	
	// Constructor
	// -----------
	
	constructor(view)
	{
		this.view = view;
		this.data = null;
		this.numberOfColours = [0, 0]; // 0: Number of columns, 1: number of rows
		this.colourVectorMappings = [{}, {}]; // Yes, key based arrays
	} //







	// Wide Functions
	// --------------
	
	
	loadWithBuffer(id, nRows, nCols, arrayBuffer, callback)
	{
		this.id = id;
		this.numberOfRows = nRows;
		this.numberOfColumns = nCols;
	
		this.data = new Uint8Array(arrayBuffer);
			
		// Parse - mainly we're going to cache the positions of the colour vectors we have.
		// I am betting on the high probability that it should only be a handful of them.


		const orthogonalNumberOfCells = [this.numberOfRows, this.numberOfColumns]; // Yes, in this order

		let j, z, pos = 4;
		
		this.numberOfPlanes = AqaComponent.readInteger(this.data, 0, 4);
		
		// First pass we map the vector position in the user's file to their order in the data
		for(let i = 0; i < 2; i++)
		{
			z = this.numberOfColours[i] = AqaComponent.readInteger(this.data, pos, 4);
			pos += 4;

//							if (i === 0) offset = (1 + z) << 2; // Number of ints, and then the ints themselves
			for(j = 0; j < z; j++)
			{
				this.colourVectorMappings[i][AqaComponent.readInteger(this.data, pos, 4)] = j;
				pos += 4;
			}
		}
		
		// Second pass, we compute the colours' actual byte position in the data
		// Note the the colour map does not have the user's file size (ie. number of cols and rows)
		// so we need that to be passed to us.

		// For each dimension: number of ints and then the ints @ 4 bytes each
		let offsetToColourPlanes = (3 + this.numberOfColours[0] + this.numberOfColours[1]) << 2;

		for(let i = 0; i < 2; i++)
		{
			if (i === 1) offsetToColourPlanes += (this.numberOfColours[0] * orthogonalNumberOfCells[0]) * this.numberOfPlanes; // Point after the column rag vectors
			Object.keys(this.colourVectorMappings[i]).forEach
			(
				(k, v) =>
				{
					this.colourVectorMappings[i][k] = offsetToColourPlanes + (orthogonalNumberOfCells[i] * v * this.numberOfPlanes);
				}
			); // 2 bytes per cell rag.
		}
		if (callback !== null) callback();
	
	} // loadWithBuffer
	
	
	
	load(callback)
	{

		if (!this.view) return;

		this.numberOfRows = this.view.table.numberOfRows();
		this.numberOfColumns = this.view.table.numberOfColumns();

		ColourAndOverrideMaps.load
		(
			this.view.id,
			true,
			caom =>
			{
				this.id = caom.id;
				this.numberOfColours = caom.colourMap.numberOfColours;
				this.colourVectorMappings = caom.colourMap.colourVectorMappings;
				this.data = caom.colourMap.data;
				this.numberOfPlanes = caom.colourMap.numberOfPlanes;
				if (callback) callback();
			}
		);
		
		
		
		
		

		// https://visionmedia.github.io/superagent/
		// ApiClient.js look for returnType === 'Blob' to understand how $%^&* swagger does it.


		/*
		this.numberOfRows = this.view.table.numberOfRows();
		this.numberOfColumns = this.view.table.numberOfColumns();

		AqaComponent.snapshotBackend.getColourmapUsingGET
		(
			this.view.id,
			(error, data, response) =>
			{
				if (error)
				{
					AqaComponent.staticReportError("A problem getting the snapshot colour map arose, sorry.", "ColourMap - load, backend call: " + error,this);
					if (callback !== null) callback();
					return;
				}

				// We parse and format our data

// onsole.log("Response: ");
// onsole.log(AqaComponent.prettifyJsonFromString(response));

				response.body.arrayBuffer().then(ab => this.loadWithBuffer(this.view.table.numberOfRows(), this.view.table.numberOfColumns(), ab, callback));
			}
		);
		*/

		return this;

	} // wideLoad // Are you saying I is fat?
	
	
	
	
	
	



	byteForType(vectorPosition, type, index)
	{
		const instructions = ColourMap.TYPE2BYTE[type];
		let boi = this.data[vectorPosition + instructions[0] + index * this.numberOfPlanes] >> instructions[1];
		if (instructions[2] === 2) boi = ColourMap.worseOf2(boi);
		else if (instructions[2] === 3) boi = ColourMap.worseOf3(boi);
		return boi;
	}

	columnBasedCellColour(type, x, y) { return this.cellColor(type, x, y, 0); }

	cellColor(type, x, y, orientation)
	{
		if (this.data === null) return ColourMap.GREEN; // Green by default
		const vectorPosition = this.colourVectorMappings[orientation][x];
		if (!vectorPosition) return ColourMap.GREEN; // We don't have a colour vector, meaning there was no VQD for it, so they're all green.

		return this.byteForType(vectorPosition, type, y);
	} // colour


	/** This one takes several bit positions (which) */
	columnBasedCellColour2(types, x, y)
	{
		if (this.data === null || types === null || types.length === 0) return ColourMap.GREEN; // Green by default
		const vectorPosition = this.colourVectorMappings[0][x];
		if (!vectorPosition) return ColourMap.GREEN; // We don't have a colour vector, meaning there was no VQD for it, so they're all green.

		let r, ret = ColourMap.GREEN; // Green to start with		
		for(const type of types) if ((r = this.byteForType(vectorPosition, type, y)) < ret) ret = r;
		return ret;

	} // columnBasedCellColour2


	columnBasedOverallColour(x, y)
	{
		if (this.data === null) return ColourMap.GREEN; // Green by default
		const vectorPosition = this.colourVectorMappings[0][x];
		if (!vectorPosition) return ColourMap.GREEN;
		return this.data[(vectorPosition + y * this.numberOfPlanes) + ColourMap.POPULATION_AND_UNIQUENESS] >> 6;
	} // columnBasedOverallColour


	getCartouche(x, y)
	{
		if (this.data === null) return ColourMap.ALL_GREENS; // Green by default
		const vectorPosition = this.colourVectorMappings[0][x];
		if (!vectorPosition) return null; // We don't have a colour vector, meaning there was no VQD for it.
		const offset = vectorPosition + y * this.numberOfPlanes;
		
		return this.data.slice(offset, offset + 5);
		
	
	} // getCartouche
	
	
	countColours(x, y)
	{
		// Code to be kept sync'd up with AqaColourMatrix.anyOfTheseColoursAt() !!!
		const cartouche = this.getCartouche(x, y);

		if (cartouche == null || ColourMap.isGreen(cartouche)) return [0, 0, 1];

		const counters = [0, 0, 0];
		let b, j, numberOfRagsToCheck;
		for(let i = 0; i < ColourMap.NUMBER_OF_BYTE_STORES_FOR_SPECIFIC_QUALITIES; i++)
		{
			numberOfRagsToCheck = i === ColourMap.FORMATS_LENGTHS_AND_VALUES ? 3 : 2;
			b = cartouche[i];
			for(j = 0; j < numberOfRagsToCheck; j++) counters[(b >> (j << 1)) & 3]++;
		}

		counters[2] = 0;
		return counters;
	} // countColours

	columnBasedCellPreciseColour(notNeeded, x, y)
	{
	
		const cartouche = this.getCartouche(x, y);
		if (cartouche === null) return ColourMap.ALL_GREENS; // We don't have a colour vector, meaning there was no VQD for it, so they're all green.

/*	
		if (this.data === null) return ColourMap.ALL_GREENS; // Green by default
		const vectorPosition = this.colourVectorMappings[0][x];
		if (!vectorPosition) return ColourMap.ALL_GREENS; // We don't have a colour vector, meaning there was no VQD for it, so they're all green.

		const offset = vectorPosition + y * this.numberOfPlanes;
		const opu = this.data[offset + ColourMap.POPULATION_AND_UNIQUENESS];
		const n   = this.data[offset + ColourMap.NUMBER_FORMAT_AND_BOUNDARIES];
		const d   = this.data[offset + ColourMap.DATE_FORMAT_AND_BOUNDARIES];
		const s   = this.data[offset + ColourMap.FORMATS_LENGTHS_AND_VALUES];
		const tn  = this.data[offset + ColourMap.COLUMN_TYPE_AND_NATIVE]
*/
		const [opu, n, d, s, tn] = cartouche;

		return {
			overall:        opu >> 6,
		
			populated:      opu & 3,
			uniqueness:     (opu >> 2) & 3,

			numberFormat:   n & 3,
			numberBoundary: (n >> 2) & 3,

			dateFormat:     d & 3,
			dateBoundary:   (d >> 2) & 3,

			stringFormat:   s & 3,
			stringLength:   (s >> 2) & 3,
			stringAllowed:  (s >> 4) & 3,

			type:           tn & 3,

			nativeError:    (tn >> 2) & 3
		};

	} // columnBasedCellPreciseColour
	
	
	dump()
	{
		let c;
		for(let i = 0; i < this.numberOfRows; i++)
		{
			c = "" + i + " ";
			for(let j = 0; j < this.numberOfColumns; j++) c = c + this.columnBasedCellColour(0, j, i);
			console.log(c);
		}
	}



} ////


// Dump for load

/*
onsole.log("Map of colour vectors");
onsole.log(AqaComponent.prettifyJson(this.colourVectorMappings));

let totalNumberOfBytes = 0;
for(i = 0; i < 2; i++)
{
	onsole.log("" + i + ": " + this.numberOfColours[i] + " colour vectors, made of: " + orthogonalNumberOfCells[i] + " cell(s) - at most");
	
	totalNumberOfBytes += (1 + this.numberOfColours[i]) << 2; // 4 bytes each (all ints)
	totalNumberOfBytes += (this.numberOfColours[i] * orthogonalNumberOfCells[i]) << 1; // 2 bytes per rag deck
}

onsole.log("Computed number of bytes: " + totalNumberOfBytes + " / Actual: " + this.data.length);
*/
							
// onsole.log("this.numberOfPlanes" + this.numberOfPlanes);
// this.dump();


/*

	narrowLoad(callback)
	{

		if (!this.view) return;

		// https://visionmedia.github.io/superagent/
		// ApiClient.js look for returnType === 'Blob' to understand how $%^&* swagger does it.

		this.numberOfRows = this.view.table.numberOfRows();
		this.numberOfColumns = this.view.table.numberOfColumns();

		AqaComponent.snapshotBackend.getColourmapUsingGET
		(
			this.view.id,
			(error, data, response) =>
			{
				if (error)
				{
					AqaComponent.staticReportError("A problem getting the snapshot colour map arose, sorry.", "ColourMap - load, backend call: " + error,this);
					if (callback !== null) callback();
					return;
				}
				
				
				// We parse and format our data
// if (blob === null) console.error("Blob is null - unsuccessful request.");

// onsole.log("Response: ");
// onsole.log(AqaComponent.prettifyJsonFromString(response));

	
				response.body.arrayBuffer().then
				(
					arrayBuffer =>
					{
						this.data = new Uint8Array(arrayBuffer);
							
						// Parse - mainly we're going to cache the positions of the colour vectors we have.
						// I am betting on the high probability that it should only be a handful of them.
						// Addendum 20240229 - although this is less likely with System errors added on all columns


						const orthogonalNumberOfCells = [this.numberOfRows, this.numberOfColumns]; // Yes, in this order

						let j, z, pos = 0;
						
						
						// First pass we map the vector position in the user's file to their order in the data
						for(let i = 0; i < 2; i++)
						{
							z = this.numberOfColours[i] = AqaComponent.readInteger(this.data, pos, 4);
							pos += 4;

// onsole.log("z(" + i + "):" + z);


//							if (i === 0) offset = (1 + z) << 2; // Number of ints, and then the ints themselves
							for(j = 0; j < z; j++)
							{
								this.colourVectorMappings[i][AqaComponent.readInteger(this.data, pos, 4)] = j;
								pos += 4;
							}
						}
						
						// Second pass, we compute the colours' actual byte position in the data
						// Note the the colour map does not have the user's file size (ie. number of cols and rows)
						// so we need that to be passed to us.

						// For each dimension: number of ints and then the ints @ 4 bytes each
						let offsetToShortsRepresentingColours = (2 + this.numberOfColours[0] + this.numberOfColours[1]) << 2;

						for(let i = 0; i < 2; i++)
						{
							if (i === 1) offsetToShortsRepresentingColours += (this.numberOfColours[0] * orthogonalNumberOfCells[0]) << 1; // Point after the column rag vectors
							Object.keys(this.colourVectorMappings[i]).forEach((k, v) => { this.colourVectorMappings[i][k] = offsetToShortsRepresentingColours + (orthogonalNumberOfCells[i] * (v << 1)); }); // 2 bytes per cell rag.
						}

						if (callback !== null) callback();
					}
				);
	
			}
		);

		return this;
	} // narrowLoad

*/

/*


	narrowColumnBasedCellColour(which, x, y)
	{

		if (this.data === null) return ColourMap.GREEN; // Green by default
		const vectorPosition = this.colourVectorMappings[0][x];
		if (!vectorPosition) return ColourMap.GREEN; // We don't have a colour vector, meaning there was no VQD for it, so they're all green.
		
		return (AqaComponent.readInteger(this.data, vectorPosition + (y << 1), 2) >> (which << 1)) & 3;
	
	} // colour



	/ ** This one takes several bit positions (which)
	  * /
	narrowColumnBasedCellColour2(types, x, y)
	{

		if (this.data === null || types === null || types.length === 0) return ColourMap.GREEN; // Green by default
		const vectorPosition = this.colourVectorMappings[0][x];
		if (!vectorPosition) return ColourMap.GREEN; // We don't have a colour vector, meaning there was no VQD for it, so they're all green.

		const i = AqaComponent.readInteger(this.data, vectorPosition + (y << 1), 2);
		let r, ret = ColourMap.GREEN; // Green to start with		

		for(const type of types)
		{

			r = (i >> (type << 1)) & 3;
			
//console.log("which->" + which + " r: " + r);

			if (r < ret) ret = r;
		}
		return ret;

	} // narrowColumnBasedCellColour2


*/

	/** AoTW - not used but who knows 20231129: Not ported to the wide matrix
	 */
	/*
	rowBasedCellColour(which, x, y)
	{
		if (this.data === null) return ColourMap.GREEN; // Green by default
		const vectorPosition = this.colourVectorMappings[1][y];
		if (!vectorPosition) return ColourMap.GREEN; // We don't have a colour vector, meaning there was no VQD for it, so they're all green.
		return (AqaComponent.readInteger(this.data, vectorPosition + (x << 1), 2) >> (which << 1)) & 3;
	} // colour
	*/
