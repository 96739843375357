/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/AqaResponse', '../model/Person', '../model/PersonPasswordUpdateRequest', '../model/PersonUpdateRequest', '../model/PersonUpdateWithPermissionsRequest', '../model/RegistrationRequest', '../model/ShareLinkRequest'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/AqaResponse'), require('../model/Person'), require('../model/PersonPasswordUpdateRequest'), require('../model/PersonUpdateRequest'), require('../model/PersonUpdateWithPermissionsRequest'), require('../model/RegistrationRequest'), require('../model/ShareLinkRequest'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AQAUserControllerApi = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.AqaResponse, root.ApiDocumentation.Person, root.ApiDocumentation.PersonPasswordUpdateRequest, root.ApiDocumentation.PersonUpdateRequest, root.ApiDocumentation.PersonUpdateWithPermissionsRequest, root.ApiDocumentation.RegistrationRequest, root.ApiDocumentation.ShareLinkRequest);
  }
}(this, function(ApiClient, AqaResponse, Person, PersonPasswordUpdateRequest, PersonUpdateRequest, PersonUpdateWithPermissionsRequest, RegistrationRequest, ShareLinkRequest) {
  'use strict';

  /**
   * AQAUserController service.
   * @module api/AQAUserControllerApi
   * @version 1.0
   */

  /**
   * Constructs a new AQAUserControllerApi. 
   * @alias module:api/AQAUserControllerApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the addUserUsingPOST operation.
     * @callback module:api/AQAUserControllerApi~addUserUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Adds a user under the current account - the principal&#39;s account
     * @param {module:model/RegistrationRequest} registrationRequest registrationRequest
     * @param {module:api/AQAUserControllerApi~addUserUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.addUserUsingPOST = function(registrationRequest, callback) {
      var postBody = registrationRequest;

      // verify the required parameter 'registrationRequest' is set
      if (registrationRequest === undefined || registrationRequest === null) {
        throw new Error("Missing the required parameter 'registrationRequest' when calling addUserUsingPOST");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/users', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the allUsingGET operation.
     * @callback module:api/AQAUserControllerApi~allUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Person>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves all users for the current account - the principal&#39;s account
     * @param {module:api/AQAUserControllerApi~allUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Person>}
     */
    this.allUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json'];
      var returnType = [Person];

      return this.apiClient.callApi(
        '/api/v1/users', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the checkForOldPasswordUsingPOST operation.
     * @callback module:api/AQAUserControllerApi~checkForOldPasswordUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Checks a new user&#39;s password wasn&#39;t used before
     * @param {module:model/PersonPasswordUpdateRequest} personPasswordUpdateRequest personPasswordUpdateRequest
     * @param {module:api/AQAUserControllerApi~checkForOldPasswordUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.checkForOldPasswordUsingPOST = function(personPasswordUpdateRequest, callback) {
      var postBody = personPasswordUpdateRequest;

      // verify the required parameter 'personPasswordUpdateRequest' is set
      if (personPasswordUpdateRequest === undefined || personPasswordUpdateRequest === null) {
        throw new Error("Missing the required parameter 'personPasswordUpdateRequest' when calling checkForOldPasswordUsingPOST");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/users/details/checkpassworduse', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteUserUsingDELETE operation.
     * @callback module:api/AQAUserControllerApi~deleteUserUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Deletes a user by id
     * @param {String} userId userId
     * @param {module:api/AQAUserControllerApi~deleteUserUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.deleteUserUsingDELETE = function(userId, callback) {
      var postBody = null;

      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling deleteUserUsingDELETE");
      }


      var pathParams = {
        'userId': userId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/users/{userId}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the loadUserUsingGET operation.
     * @callback module:api/AQAUserControllerApi~loadUserUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Person} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves one user
     * @param {String} userId userId
     * @param {module:api/AQAUserControllerApi~loadUserUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Person}
     */
    this.loadUserUsingGET = function(userId, callback) {
      var postBody = null;

      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling loadUserUsingGET");
      }


      var pathParams = {
        'userId': userId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json'];
      var returnType = Person;

      return this.apiClient.callApi(
        '/api/v1/users/{userId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the meUsingGET operation.
     * @callback module:api/AQAUserControllerApi~meUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Person} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves the current user as per the login
     * @param {module:api/AQAUserControllerApi~meUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Person}
     */
    this.meUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json'];
      var returnType = Person;

      return this.apiClient.callApi(
        '/api/v1/users/me', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the shareLinkPreviewUsingPOST operation.
     * @callback module:api/AQAUserControllerApi~shareLinkPreviewUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * shares preview - the principal&#39;s account
     * @param {module:model/ShareLinkRequest} slr slr
     * @param {module:api/AQAUserControllerApi~shareLinkPreviewUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.shareLinkPreviewUsingPOST = function(slr, callback) {
      var postBody = slr;

      // verify the required parameter 'slr' is set
      if (slr === undefined || slr === null) {
        throw new Error("Missing the required parameter 'slr' when calling shareLinkPreviewUsingPOST");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/users/sharepreview', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the shareLinkUsingPOST operation.
     * @callback module:api/AQAUserControllerApi~shareLinkUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Checks a user under the current account and shares link - the principal&#39;s account
     * @param {module:model/ShareLinkRequest} slr slr
     * @param {module:api/AQAUserControllerApi~shareLinkUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.shareLinkUsingPOST = function(slr, callback) {
      var postBody = slr;

      // verify the required parameter 'slr' is set
      if (slr === undefined || slr === null) {
        throw new Error("Missing the required parameter 'slr' when calling shareLinkUsingPOST");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/users/share', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the updateUserPasswordUsingPUT operation.
     * @callback module:api/AQAUserControllerApi~updateUserPasswordUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates a user&#39;s password
     * @param {module:model/PersonPasswordUpdateRequest} personPasswordUpdateRequest personPasswordUpdateRequest
     * @param {module:api/AQAUserControllerApi~updateUserPasswordUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.updateUserPasswordUsingPUT = function(personPasswordUpdateRequest, callback) {
      var postBody = personPasswordUpdateRequest;

      // verify the required parameter 'personPasswordUpdateRequest' is set
      if (personPasswordUpdateRequest === undefined || personPasswordUpdateRequest === null) {
        throw new Error("Missing the required parameter 'personPasswordUpdateRequest' when calling updateUserPasswordUsingPUT");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/users/details/password', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the updateUserUsingPUT operation.
     * @callback module:api/AQAUserControllerApi~updateUserUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates email, first name, last name of a user
     * @param {module:model/PersonUpdateRequest} personUpdateRequest personUpdateRequest
     * @param {module:api/AQAUserControllerApi~updateUserUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.updateUserUsingPUT = function(personUpdateRequest, callback) {
      var postBody = personUpdateRequest;

      // verify the required parameter 'personUpdateRequest' is set
      if (personUpdateRequest === undefined || personUpdateRequest === null) {
        throw new Error("Missing the required parameter 'personUpdateRequest' when calling updateUserUsingPUT");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/users/details', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the updateUserWithPermissionsUsingPUT operation.
     * @callback module:api/AQAUserControllerApi~updateUserWithPermissionsUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates eamil, first name, last name of a user as well as permissions (To be used by admins)
     * @param {module:model/PersonUpdateWithPermissionsRequest} personUpdateRequest personUpdateRequest
     * @param {module:api/AQAUserControllerApi~updateUserWithPermissionsUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.updateUserWithPermissionsUsingPUT = function(personUpdateRequest, callback) {
      var postBody = personUpdateRequest;

      // verify the required parameter 'personUpdateRequest' is set
      if (personUpdateRequest === undefined || personUpdateRequest === null) {
        throw new Error("Missing the required parameter 'personUpdateRequest' when calling updateUserWithPermissionsUsingPUT");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/users/detailsAndPermissions', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
