/* Welcome to Aqa*/
/* Version: 1 */

// Components Goes Here
import React, { Component } from 'react';

import AqaComponent from "./components/shared/aqacomponent/AqaComponent"
import AqaMainFrame from "./components/AqaMainFrame.js"
import "@fontsource/montserrat"
//import LoginOrRegister from "./components/LoginOrRegister.js"
//CSS Goes Here
import './App.css'
import './hexagons.css'
import AqaLoginRegister from "./components/AqaLoginRegister"
import AqaForgotPassword from "./components/AqaForgotPassword"
import AqaSettings from "./AqaSettings"
//var CryptoJS=require("crypto-js");

import {STRIPE_SUCCESS_PARAM, STRIPE_FAILURE_PARAM} from "./components/stripe/StripeConstants"
import base64 from "base-64";
import moment from "moment";


class App extends Component
{

	constructor(props)
	{
		super(props);
		this.state = {
			error: null,
			isLoaded: false,
			isLoggedIn: false,
			nav:1,
			events: [],
			username: null,
			password: null,
			passRedirect: false,
			uid: "",
			expiry: 0,
			requestId: 1,
			errorCode: "404",
			isFirstTime: false,
			userId:"",
			adminEmail:"",
			navigation:1,
			accountId:null,
			authCode:"",
			useremail:"",
			user:null,
			account:null,
			height:0
		};
		this.handleLoginRedirect = this.handleLoginRedirect.bind(this);
		this.handleFirstTime = this.handleFirstTime.bind(this);
		this.loginSuccessful = this.loginSuccessful.bind(this);
		this.handleAccountId=this.handleAccountId.bind(this);
		this.handleAdminEmail=this.handleAdminEmail.bind(this);
		this.handleUserId=this.handleUserId.bind(this);
		this.handleAuthCode=this.handleAuthCode.bind(this);
		this.handleNavigation=this.handleNavigation.bind(this);

		this.forcefullyLoggedOut = false;
		this.forcefullyLoggedOutForked = false;
		this.expiresIn = 3600; // 1 hour in seconds.

		// The main frame will start a timeout to show a popup to let the user know they need to refresh their session.
		// We want to be able to stop that time out when they log out.
		this.mainframe = null;
		this.chatframe = null;
	} //

	handleDimensions =(event)=>{
		event.preventDefault();
		this.setState({height:window.innerHeight-100});
	}

	handleFirstTime(firstTime){
		this.setState({isFirstTime:firstTime});
	}

	registerMainFrame(mf)
	{
		this.mainframe = mf;
	} // registerMainFrame

	registerChatFrame(cf)
	{
		this.chatframe = cf;
	} // registerMainFrame


	loginSuccessful(authenticationToken,user)
	{
		// Local storage: https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
		localStorage.setItem("access_token", authenticationToken.access_token); // For now only storing the access_token.
		localStorage.setItem("userId", user.email); // For now only storing the access_token.
		// localStorage.setItem("username", username); // For now only storing the access_token.
		let nav = localStorage.getItem("navigation");
		let ft = localStorage.getItem("ft");
		let dateAdded = moment(user.dateAdded);
		let diff = moment().diff(dateAdded, "minutes");
		if (diff < 10 && ft) {
			this.setState({isLoggedIn: true, nav: nav});
			this.handleFirstTime(true)
		} else {
			localStorage.removeItem("ft");
			localStorage.setItem("navigation", "1");
			nav = 1;
			this.setState({isLoggedIn: true, nav: nav});
		}
	} // loginSuccessful

	handleUser = (user,account)=>{
		if(user!==null && account!==null) {
			this.setState({user: user, account: account, authCode: user.permissions, adminEmail: account.name});
		}
		else {
			this.setState({user:user,account:account,authCode:"",adminEmail:""});

		}
	}

	clearStorage()
	{
		["access_token", "refresh_token", "username", "userId", "accountId", "navigation", "authCode", "adminEmail","ft","access","_grecaptcha","nav","ttl","ttlo","sid"].forEach(e => localStorage.removeItem(e));
		setTimeout(()=>window.location.reload(false),1000);
	} // clearStorage

	// handlers for storage goes here
	handleAccountId(e){this.setState({accountId:e})}
	handleAuthCode(e){this.setState({authCode:e})}
	handleUserId(e){this.setState({userId:e})}
	handleAdminEmail(e){this.setState({adminEmail:e})}
	handleNavigation(e){this.setState({navigation:e})}
	handleUsername(e){this.setState({username:e})}

	forceLogout = () =>{
		this.clearStorage();
		this.setState({isLoggedIn:false});
		this.handleUser(null,null);
	}

	// Call me from AqaMainFrame
	logout()
	{
	
	
		AqaComponent.backend.invalidateTokenUsingGET
		(
			"",
			(error, data, response) =>
			{
// onsole.log("Token invalidation response: " + AqaComponent.prettifyJson(data));

				if (this.mainframe) this.mainframe.clearTimeoutDialogTimeout();
				this.clearStorage();
				this.setState({isLoggedIn:false,useremail:""});
				this.handleUser(null,null);
			}	
		);	

		
	} // logout

	handleLoginRedirect()
	{
		this.setState({passRedirect: false, uid: "", expiry: 0});
	}

	componentDidMount()
	{

// onsole.log("Timeout 1?? " + AqaComponent.backend.apiClient.timeout);

		// Hack to change the frontend's timeout value.
		// Is it future proof? What if Swagger move and/or rename the variable?
		AqaComponent.backend.apiClient.timeout = 300000; // 5 minutes

// onsole.log("Timeout 2?? " + AqaComponent.backend.apiClient.timeout);


		// AqaSnapshotDetails will need the alchemy concocted in these phials.
		AqaComponent.howlonsapieceofstring();

		let token = localStorage.getItem("access_token");
		if (token)
		{
			var ttl = localStorage.getItem("ttl");
			var ttlo = localStorage.getItem("ttlo");
			if (!["ttl", "ttlo"].every(e =>localStorage.getItem(e)))
			{
				this.clearStorage();
				token = null;
				this.forcefullyLoggedOutForked=true;
			}
			else if(ttl !== undefined && ttlo !== undefined){
				// check if the values are forked
				let ttld = ttlo-ttl;
				if(ttld <3599 || ttld > 3601){
					this.clearStorage();
					token = null;
					this.forcefullyLoggedOutForked=true;
				}
			}
			else{}
		}

		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams && (urlParams.has("error")))
		{
			const errorCode = urlParams.get("error");
			this.setState({passRedirect: true, expiry: true, uid: "", errorCode: errorCode});
		}

		let nav = 1;

		if (urlParams && (urlParams.has("res")))
		{
			var res = urlParams.get("res");
			if(res!=="" && res!=="link") {
				let resData = JSON.parse(base64.decode(res));
				let resusername = resData.username;
				if (resusername !== undefined) {
					this.setState({useremail: resusername});
				}
			}

		}

		if (token != null)
		{
			this.setState({isLoggedIn: true});
		}
		else
		{

			//let rpp; // reset password please
			if (urlParams && (urlParams.has("rpp")))
			{
				// Check the password reset one-off key is legit.
				const rpp = urlParams.get("rpp");
				const url = AqaSettings.getBaseUrl() + "/checkpasswordreset/" + rpp;

				// And sure, await is nice BUT I don't like async.
				fetch(url).then
				(
					response => {
						if (response.status === 200) {
							this.setState({passRedirect: true, expiry: false, uid: rpp});
						} else if (response.status === 401) {
							this.setState({passRedirect: true, expiry: true, uid: rpp});
						} else {
							this.setState({passRedirect: true, expiry: true, uid: ""})
						}
						var location = window.location;
						var urlbase = location.href.slice(0, -((location.search + location.hash).length));
						if (location.search === "") {
							urlbase = location.href
						}

						window.history.pushState({path: urlbase}, '', urlbase);
						//window.location.replace(urlbase);
						//window.location.reload();
					}
				);

			}

			if (urlParams && (urlParams.has("cpp")))
			{
				//const cpp = urlParams.get("cpp");
				localStorage.setItem("navigation", 4);
				this.handleNavigation(4);
				localStorage.setItem("ft", true);
				nav = 4;
				var location = window.location;
				var urlbase = location.href.slice(0, -((location.search + location.hash).length));
				if (location.search === "") {
					urlbase = location.href
				}

				window.history.pushState({path: urlbase}, '', urlbase);
				window.location.reload();
			}
		}


			/*
			var location=window.location;
			var search=window.location.search;
			const params = new URLSearchParams(search);
			var plain =null;
			var res = params.get("res");
			if(res !== null){
				var reb64 = CryptoJS.enc.Hex.parse(res);
				var bytes = reb64.toString(CryptoJS.enc.Base64);
				var decrypt = CryptoJS.AES.decrypt(bytes,'aqarules@123');
				plain = decrypt.toString(CryptoJS.enc.Utf8);
				var urlbase = location.href.slice(0, - ((location.search + location.hash).length));
				if(location.search ===""){
					urlbase = location.href
				}

				localStorage.setItem("redirectData",plain);
				window.history.pushState({path:urlbase},'',urlbase);
			}
			var redirectData = localStorage.getItem("redirectData");
			redirectData = JSON.parse(redirectData);
			if(redirectData!==null && redirectData !== undefined){
				var nav = redirectData.nav;
				var uid = redirectData.uid;
				var expiry = redirectData.expiry;
				var requestId = redirectData.requestId;
				if(nav === 5){this.setState({passRedirect:true,uid:uid,expiry:expiry,requestId:requestId})};

				localStorage.removeItem("redirectData");
			}
			if(localStorage.getItem("navigation") === "4"){
				this.setState({showAccount:"credential",nav:4});
			}*/
			


		if (urlParams)
		{
			if (urlParams.has(STRIPE_FAILURE_PARAM)) nav = 11;
			else if (urlParams.has(STRIPE_SUCCESS_PARAM)) nav = 10;
//			else if (urlParams.has(STRIPE_PAYMENT_METHOD_SUCCESS_PARAM)) nav = 4;

			/*localStorage.setItem("navigation", nav);
			this.handleNavigation(nav);
			if(nav === 10 || nav === 11) {
				this.setState({nav:nav});
				var location = window.location;
				var urlbase = location.href.slice(0, -((location.search + location.hash).length));
				if (location.search === "") {
					urlbase = location.href
				}

				window.history.pushState({path: urlbase}, '', urlbase);
				//window.location.reload();
			}*/

		}
		this.setState({isLoaded:true, nav});
		/*window.addEventListener('beforeunload',(event)=>{
			event.preventDefault();
			localStorage.setItem("username",this.state.username);
			localStorage.setItem("userId",this.state.userId);
			localStorage.setItem("accountId",this.state.accountId);
			localStorage.setItem("authCode",this.state.authCode);
			localStorage.setItem("adminEmail",this.state.adminEmail);
			localStorage.setItem("navigation",this.state.navigation);
			localStorage.setItem("nav",this.state.navigation);
		});

		//Get the values here
		["username", "userId", "accountId", "navigation", "authCode", "adminEmail","ft"].forEach(e => this.setState({[e]:localStorage.getItem(e)}));
		// wipe the localstorage here
		//FIXME: Remove the other localstorage items
		//["username", "userId", "accountId", "navigation", "authCode", "adminEmail","ft"].forEach(e => localStorage.removeItem(e));
		["userId","username","accountId","authCode","adminEmail"].forEach(e => localStorage.removeItem(e));*/

		window.addEventListener("onload",this.handleDimensions);
		window.addEventListener("fullScreenChange",this.handleDimensions);
		window.addEventListener("resize",this.handleDimensions);

	} // componentDidMount

	componentWillUnmount() {
		window.removeEventListener("onload",this.handleDimensions);
		window.removeEventListener("fullScreenChange",this.handleDimensions);
		window.removeEventListener("resize",this.handleDimensions);
	}

	setExpiresIn(ttl) { this.expiresIn = ttl; }
	getExpiresIn() { return this.expiresIn; }

	setForcefullyLoggedOut(forcefullyLoggedOut) { this.forcefullyLoggedOut = forcefullyLoggedOut; }
	setForcefullyLoggedOutForked(forcefullyLoggedOut) { this.forcefullyLoggedOutForked = forcefullyLoggedOut; }

	render()
	{
		const {error, isLoaded, isLoggedIn,passRedirect,uid,expiry} = this.state;

		if (error)
		{
			return <div>Error: {error.message}</div>;
		}
		else if (!isLoaded)
		{
			return <div></div>;
		}
		else
		{
			if (isLoggedIn)
			{
				return (
					<AqaMainFrame parent={this} nav={this.state.nav}/>
				);
			}
			else
			{
				if(passRedirect)
				{
					return (
						/*<LoginOrRegister parent={this} />*/
						<AqaForgotPassword parent={this} uid={uid} expiry={expiry} errorCode={this.state.errorCode}/>
					);
				}
				else {
					return (
						/*<LoginOrRegister parent={this} />*/
						<AqaLoginRegister parent={this} forcefullyLoggedOut={this.forcefullyLoggedOut} forcefullyLoggedOutForked={this.forcefullyLoggedOutForked} username={this.state.useremail} />
					);
				}
			}
		}
	} // render

} ////

export default App;





// Update the URL
// https://dev.to/gaels/an-alternative-to-handle-global-state-in-react-the-url--3753

// Conditional Components
// https://blog.logrocket.com/conditional-rendering-in-react-c6b0e5af381e/



/*

{
                    <Router>
                        <div className="App">
                            <Switch>

                                <Route exact path="/" component={Welcome}/>
                                <Route path="/welcome" component={Welcome}/>
                                <Route path="/login" component={Login}/>
                                <Redirect to="/"/>

                            </Switch>
                        </div>
                    </Router>
                    }
                    

*/
