/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as AddIcon} from "../../images/aqa-icons/AQA-Add Snapshot - v2.svg";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import Input from "@material-ui/core/Input";
import {CircularProgress} from "@material-ui/core";
//import moment from "moment";
import HtmlTooltip from "@material-ui/core/Tooltip";


const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaNewSnapshot extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            openProgress: false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            file: null,
            SourceName: "",
            SourceDescription: null,
            errorMessage: "",
            accountObj:null,
            snapshots:[],
            snapshotCount:0,
            exts:props.dataFromParent.props.dataFromMainFrame.state.exts

        };
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleAccountDetails = this.handleAccountDetails.bind(this);
        this.handleSnapshots=this.handleSnapshots.bind(this);
    }

    handleSnapshotCreation(fileUploaded){
        var sourceid = this.props.dataFromGrid.id;

        AqaComponent.backend.uploadFileUsingPOST(
            fileUploaded,
            sourceid,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem posting the  new snapshot from the server was encountered.",
                    "AqaNewSnapshot.handleSnapshotCreation, backend call: " + error,this);
                else
                {
                    this.setState({isLoaded: true,openMessage:false,openProgress:false});
                    this.props.dataFromParent.handleRefresh();
                    /*if(data.length<1){
                        this.setState({error: false,notfound:true});
                    }*/
                }
            }
            );
    }
    handleCreateSnapshotClosePopup(e) {
        e.preventDefault();
        /*var periodicity= this.state.periodicity;
        var sourceName = this.state.SourceName;
        var sourceDescription = this.state.SourceDescription;
        var folderid = this.props.dataFromParent.props.dataAcquired;
        if(sourceName === "" || sourceName.trim() === "" || periodicity === null){
            this.setState({ errorMessage: "Please fill mandatory fields" });
        }
        else{
            this.handleSourceCreation(folderid,sourceName,periodicity,sourceDescription);
        }*/
        var file = this.state.file;
        if(file === null){
            this.setState({ errorMessage: "Please fill mandatory fields" });
        }
        else{
            this.handleSnapshotCreation();
        }
        this.setState({ openMessage: true });
    }

    handleClosePopup(e) {
        e.preventDefault();
        this.setState({ errorMessage:"",openMessage: false,file:null});
    }

    handleAccountDetails(){
//        var accountId=this.props.dataFromParent.props.dataFromRoot.props.parent.state.accountId;
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                var errorJSON = JSON.parse(response.text);
                if (errorJSON.error === "invalid_token") {
                    this.props.dataFromParent.props.dataFromRoot.popTimeoutDialog2();
                    //this.props.dataFromParent.props.controller.props.dataFromMain.props.parent.logout();
                }
                else {
                    if (error) this.reportError("A problem getting the account details from the server was encountered.",
                        "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                    else {
                        var accountObj = data;
                        this.setState({accountObj: accountObj});
                        this.handleSnapshots(this.props.dataFromGrid.id);
                    }
                }
            }
        );
    }

    handleSnapshots(sourceId){
        AqaComponent.backend.getSnapshotsForSourceUsingGET(
            sourceId,
            0,
            9999,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSnapshotView.handleSnapshots, backend call: " + error,this);
                else
                {
                    this.setState({snapshots:data,snapshotCount:data.length});
                }
            }
        );
    }

    handleNewSnapshotClick(e) {
        e.preventDefault();
        var authCode=this.props.dataFromParent.props.dataFromRoot.props.parent.state.authCode;
        var adminEmail=this.props.dataFromParent.props.dataFromRoot.props.parent.state.adminEmail;
        var context;
        if(authCode === "V"){
            this.removeAllNotifications();
            this.showNotification(4, null,context , null,adminEmail);
        }
        else {
            if(this.props.dataFromGrid.name === "Example Data"){
                var customNotification = {
                    title: "Restricted", titleIcon: "",
                    message: "You cannot upload data to this Data Source",
                    isCustom: false, type: "info",
                    insert: "center", position: "center", autoClose: true, duration: 5000
                };
                this.removeAllNotifications();
                this.showNotification(null, customNotification);
            }
            else {
                this.handleAccountDetails();
                /*if (this.state.accountObj.numberOfSnapshots !== -1 && this.state.snapshotCount >= this.state.accountObj.numberOfSnapshots) {
                    context = this.props.dataFromParent.props.dataFromRoot;
                    this.removeAllNotifications();
                    this.showNotification(3, null, context, "Snapshot");
                } else {*/
                    this.setState({openMessage: false, file: null});
                    document.getElementById('import-file').click();
               /* }*/
            }
        }

    }

    handleCheckFile = (ext)=>{
        let exts = this.state.exts;
        let found = false;
        for(let i=0;i<exts.length;i++) if(exts[i] === ext) found=true;
        return found;
    }

    handleFileChange(evt){
        var fileUploaded = evt.target.files[0];
        if(fileUploaded !== null && fileUploaded !== undefined) {
            let ext = fileUploaded.name.substr(fileUploaded.name.lastIndexOf(".")+1,fileUploaded.name.length);
            if(!this.handleCheckFile("."+ext)){
                this.reportError("You cannot select file other than .xlsx or .xls or .csv", "AqaSourceView.handleFileChange, backend call: ",this);
                this.setState({file: null, errorMessage: ""});
            }
            else {
                this.setState({file: fileUploaded, errorMessage: "", openProgress: false});
                //this.handleSnapshotCreation(fileUploaded);
                this.props.dataFromParent.handleFileUpload(fileUploaded);
                this.setState({file: null});
                document.getElementById('import-file').value = "";
            }
        }
        else{
            this.setState({file: null, errorMessage: ""});
        }
    }

    /*UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.handleAccountDetails();
        this.handleSnapshots(nextProps.dataFromGrid.id);
    }*/

    componentDidMount(){
        this.handleAccountDetails();
    }
    render() {
        const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,errorMessage,exts } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action">Click to upload the latest data file for this data source</Typography>
                                </div>

                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",padding:"0"}}>
                                <IconButton className="aqa-button" onClick={this.handleNewSnapshotClick.bind(this)} style={{padding:"3px"}} >
                                    <div className="aqa-icon" >
                                        <AddIcon width="32px" />
                                    </div>
                                    <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px",width:"150px"}}>
                                        LOAD LATEST DATA
                                    </Typography>


                                </IconButton>

                            </Typography>
                            </HtmlTooltip>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Input
                                    id="import-file"
                                    inputProps={{
                                        accept:exts.toString(),
                                    }}
                                    onChange={this.handleFileChange}
                                    style={{display:"none",opacity:'0'}}
                                    type="file"
                                />
                        </Grid>
                    </Grid>

                    <Dialog
                        open={this.state.openMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title">NEW SNAPSHOT FORM</DialogTitle>
                        <DialogContent align="center">
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",paddingLeft:"4px"}}>
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <InputLabel htmlFor="import-button" style={styles.importLabel}>
                                        <Input
                                            id="import-file"
                                            inputProps={{
                                                accept:
                                                    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                                            }}
                                            onChange={this.handleFileChange}
                                            style={styles.hidden}
                                            type="file"
                                        />
                                        Import Spreadsheet
                                    </InputLabel>
                                </Grid>
                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 10, marginBottom: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Button  variant="contained"
                                             onClick={this.handleCreateSnapshotClosePopup.bind(this)}
                                             color="primary"
                                             align="right"
                                             style={{
                                                 marginTop: 2,
                                                 marginBottom: 32,
                                                 backgroundColor: '#1976D2'
                                             }}>
                                        OK
                                    </Button>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="right"
                                            style={{
                                                marginTop: 2,
                                                marginLeft: 5,
                                                marginBottom: 32,
                                                backgroundColor: '#1976D2'
                                            }}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Typography align="center" className={classes.reportBodyInstruction} >
                                    Press OK to create or CANCEL to return
                                </Typography>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.openProgress}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"1.2rem",color:"white",paddingLeft:"4px"}}>
                            File Status
                        </Typography></DialogTitle>
                        <DialogContent align="center">
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",paddingLeft:"4px"}}>
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography className={"aqa-action-header"} variant="inherit" style={{textAlign:"Center",color:"#006",paddingLeft:"4px",paddingBottom:"20px",width:"300px",paddingTop:"40px"}}>
                                        File is being uploaded
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <CircularProgress />
                                </Grid>
                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 30, marginBottom: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    {/*<Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="right"
                                            style={{
                                                marginTop: 2,
                                                marginLeft: 5,
                                                marginBottom: 32,
                                                backgroundColor: '#1976D2'
                                            }}>
                                        Close
                                    </Button>*/}
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </div>
            );
        }
    }
}

AqaNewSnapshot.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaNewSnapshot);
