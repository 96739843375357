/* Welcome to Aqa Client*/
/* Version: 1 */

import MasterServant       from "../shared/masterservant/MasterServant.js";
import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as BackIcon} from "../../images/aqa-icons/Backcol.svg";
import {ReactComponent as UploadIcon} from "../../images/aqa-icons/Uploadcol.svg";
import Typography from "@material-ui/core/Typography";
//import AqaSnapshotView from "../sourcedetail/AqaSnapshotView";
// import AqaRules from "../shared/rules/AqaRules";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import Grid from "@material-ui/core/Grid";
//import AqaSnapshotMainHeader from "../sourcedetail/AqaSnapshotMainHeader";
import HtmlTooltip from "@material-ui/core/Tooltip";
// import AqaSnapshotOverview from "./AqaSnapshotOverview";
import Input from "@material-ui/core/Input";
import AqaLibraryMainHeader from "./AqaLibraryMainHeader";
import AqaLibraryHeader from "./AqaLibraryHeader";
import AqaLibraryQD from "./AqaLibraryQD";


const styles = theme => ({
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    welcomelabel: {
        fontSize: 18,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 20,
        marginRight: 20,
        color: "#006"
    }
});

class AqaAQDDetail extends AqaComponent {
    constructor(props) {
        super(props);
        // Assign A ref to state for refresh
        this.state = {
            isLoaded:false,
            showRules:false,
            selectedSnapshots: [],
            resetCheckbox:false,
            currentAspect:null,
            showEditor:false,
            currentSnapshot:null,
            currentDimensionAspect:null,
            showDimensionEditor:false,
            isFileReady:false,
            fileUploaded:null,
            snapshotcount:999,
            accountObj:null,
            snapshots:[],
            showHistory:false,
            currentQD:null
        };

        // This is used by AqaRules objects to know which snapshot is selected.
        this.currentlySelectedSnapshotId = null;
        this.vqdEditor = null;

        this.currentSnapshotView = null;

        this.handleSource=this.handleSource.bind(this);
        this.handleSnapshotSelection=this.handleSnapshotSelection.bind(this);
        this.handleSnapshotSelectionReset=this.handleSnapshotSelectionReset.bind(this);
        this.handleSnapshotCheckReset=this.handleSnapshotCheckReset.bind(this);
        this.handleShowRule=this.handleShowRule.bind(this);
        this.handleShowDimensionRule=this.handleShowDimensionRule.bind(this);
        this.handleFileUpload=this.handleFileUpload.bind(this);
        this.handleFileUploadReset=this.handleFileUploadReset.bind(this);
        this.handleRefresh=this.handleRefresh.bind(this);
        this.handleSnapshots=this.handleSnapshots.bind(this);
        this.handleNewSnapshotClick=this.handleNewSnapshotClick.bind(this);
        this.handleNewSnapshotDropClick=this.handleNewSnapshotDropClick.bind(this);
        this.handleAccountDetails=this.handleAccountDetails.bind(this);
        this.handleFileChange=this.handleFileChange.bind(this);
        this.handleDragOver=this.handleDragOver.bind(this);
        this.handleSnapshotHistory=this.handleSnapshotHistory.bind(this);
    }


    /** This is called by aqaSnapshotListRow to indicate what Snapshot was selected.
     * AqaRules object will query it.
     */
    setSelectedSnapshotId(snapshotId) { this.currentlySelectedSnapshotId = snapshotId; }

    getSelectedSnapshotId() { return this.currentlySelectedSnapshotId; }

    registerVqdEditor(vqdEditor)                             { this.vqdEditor = vqdEditor; }
    registerSnapshotView(snapshot)                             { this.currentSnapshotView = snapshot; }


    handleSnapshotHistory(active){
        this.setState({showHistory:active});
        let snapshot = this.currentSnapshotView.state.latestSnapshot;
        if(snapshot === null) {
            //this.currentSnapshotView.handleSnapshot(null,null);

        }
        else this.currentSnapshotView.handleSnapshot(snapshot.id,snapshot);
    }

    handleSnapshotSelectionReset(){
        this.setState({selectedSnapshots:[],resetCheckbox:true});
        //this.handleFolder(this.props.dataAcquired);
    }

    handleSnapshotCheckReset(){
        this.setState({resetCheckbox:false});
        //this.handleFolder(this.props.dataAcquired);
    }
    handleSnapshotSelection(snapshot,isSelected){
        var selectedSnapshots=this.state.selectedSnapshots;
        if(isSelected === true){
            if(snapshot !== null && snapshot !== undefined){
                var index = selectedSnapshots.indexOf(snapshot);
                if(index<0){
                    selectedSnapshots.push(snapshot);
                    this.setState({selectedSnapshots:selectedSnapshots});
                }
            }
        }
        else{
            if(snapshot !== null && snapshot !== undefined){

                var newSelectedSnapshots = [];
                for(var i=0;i<selectedSnapshots.length;i++){
                    var selectedSnapshot = selectedSnapshots[i];
                    if(selectedSnapshot.id !== snapshot.id){
                        newSelectedSnapshots.push(selectedSnapshot);
                    }
                }
                selectedSnapshots=newSelectedSnapshots;
                this.setState({selectedSnapshots:selectedSnapshots});

            }
        }
// onsole.log(selectedSnapshots);
    }

    handleSnapshots(sourceId){


        // TJ: 20211115 You're kidding right? You're loading the ALL the snapshots only to know how many there are??!??!?

        AqaComponent.backend.getSnapshotsForSourceUsingGET(
            sourceId,
            0,
            9999,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSourceDetail.handleSnapshots, backend call: " + error,this);
                else
                {
                    if(data.length>0){
                        this.setState({showRules:true,snapshots:data,snapshotcount:data.length,isLoaded:true});
                    }
                    else{
                        this.setState({showRules:false,snapshotcount:0,isLoaded:true});
                    }
                }
            }
        );
    }
    handleHome(){
        //this.props.dataFromParent.handleSourceReset();
        this.props.dataFromParent.navigation(this.props.prevnav);
    }
    handleRefresh(){
        if(this.state.isLoaded){
            this.setState({isLoaded:false});
            let sourceId=this.props.dataFromParent.id;
            this.handleSnapshots(sourceId);
            //this.setState({isLoaded:true});
        }
        else{
            let sourceId=this.props.dataFromParent.id;
            this.handleSnapshots(sourceId);
            //this.setState({isLoaded:true});
        }
    }

    handleAccountDetails(){
//        var accountId=this.props.dataFromRoot.props.parent.state.accountId;
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                var errorJSON = JSON.parse(response.text);
                if (errorJSON.error === "invalid_token") {
                    this.props.dataFromRoot.props.dataFromRoot.props.parent.logout();
                }
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    this.setState({accountObj:accountObj});
                }
            }
        );
    }

    handleNewSnapshotClick(e){
        e.preventDefault();
        var authCode=this.props.dataFromRoot.props.parent.state.authCode;
        var adminEmail=this.props.dataFromRoot.props.parent.state.adminEmail;
        var context;
        if(authCode !== "A"){
            this.removeAllNotifications();
            this.showNotification(4, null,context , null,adminEmail);
        }
        else {
            if(this.props.dataFromParent.name === "Example Data"){
                var customNotification = {
                    title: "Restricted", titleIcon: "",
                    message: "You cannot upload data to this Data Source",
                    isCustom: false, type: "info",
                    insert: "center", position: "center", autoClose: true, duration: 5000
                };
                this.removeAllNotifications();
                this.showNotification(null, customNotification);
            }
            else {
                this.handleAccountDetails();
                this.handleSnapshots(this.props.dataFromParent.id);
                if (this.state.accountObj.numberOfSnapshots !== -1 && this.state.snapshotcount >= this.state.accountObj.numberOfSnapshots) {
                    context = this.props.dataFromRoot;
                    this.removeAllNotifications();
                    this.showNotification(3, null, context, "Snapshot");
                } else {
                    this.setState({openMessage: false, file: null});
                    document.getElementById('import-file').click();
                }
            }
        }
    }

    handleDragOver(e){
        e.preventDefault();
    }

    handleNewSnapshotDropClick(e){
        e.preventDefault();
        var authCode=this.props.dataFromRoot.props.parent.state.authCode;
        var adminEmail=this.props.dataFromRoot.props.parent.state.adminEmail;
        var context;
        if(authCode !== "A"){
            this.removeAllNotifications();
            this.showNotification(4, null,context , null,adminEmail);
        }
        else {
            if(this.props.dataFromParent.name === "Example Data"){
                var customNotification = {
                    title: "Restricted", titleIcon: "",
                    message: "You cannot upload data to this Data Source",
                    isCustom: false, type: "info",
                    insert: "center", position: "center", autoClose: true, duration: 5000
                };
                this.removeAllNotifications();
                this.showNotification(null, customNotification);
            }
            else {
                this.handleAccountDetails();
                this.handleSnapshots(this.props.dataFromParent.id);
                if (this.state.accountObj.numberOfSnapshots !== -1 && this.state.snapshotcount >= this.state.accountObj.numberOfSnapshots) {
                    context = this.props.dataFromRoot;
                    this.removeAllNotifications();
                    this.showNotification(3, null, context, "Snapshot");
                } else {
                    this.setState({openMessage: false, file: null});
                    var fileUploaded = e.dataTransfer.files[0];
                    if(fileUploaded !== null && fileUploaded !== undefined) {
                        this.setState({file: fileUploaded, errorMessage: "",openProgress:false});
                        //this.handleSnapshotCreation(fileUploaded);
                        this.handleFileUpload(fileUploaded);
                        this.setState({file:null});
                        document.getElementById('import-file').value="";
                    }
                }
            }
        }
    }

    handleFileChange(evt){
        var fileUploaded = evt.target.files[0];
        if(fileUploaded !== null && fileUploaded !== undefined) {
            this.setState({file: fileUploaded, errorMessage: "",openProgress:false});
            //this.handleSnapshotCreation(fileUploaded);
            this.handleFileUpload(fileUploaded);
            this.setState({file:null,resetCheckbox:false});
            document.getElementById('import-file').value="";
        }
        else{
            this.setState({file: null, errorMessage: ""});
        }
    }

    handleFileUpload(fileUploaded){
        this.setState({fileUploaded:fileUploaded,isFileReady:true,resetCheckbox:true});
    }
    handleFileUploadReset(){
        this.setState({fileUploaded:null,isFileReady:false,resetCheckbox:true});
    }

    handleShowRule(aspect,snapshot){
// onsole.log(snapshot);
        this.setState({showRules:true,currentAspect:aspect,showEditor:true,currentDimensionAspect:null,showDimensionEditor:false,currentSnapshot:snapshot});
    }

    handleShowDimensionRule(aspect,snapshot){
        this.setState({currentAspect:null,showEditor:false,currentDimensionAspect:aspect,showDimensionEditor:true,currentSnapshot:snapshot});
    }

    handleSource(sourceId){
        AqaComponent.backend.sourceUsingGET(
            sourceId,
            (error,data,response)=>{
                var errorJSON = JSON.parse(response.text);
                if (errorJSON.error === "invalid_token") {
                    this.props.dataFromRoot.popTimeoutDialog2();
                    //this.props.dataFromParent.props.controller.props.dataFromMain.props.parent.logout();
                }
                else {
                    if (error) this.reportError("A problem getting the account details from the server was encountered.",
                        "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                    else {
                        this.handleSnapshots(sourceId);
                        this.handleAccountDetails();
                    }
                }
            }
        );
    }


    handleLibraryQDs = (aqdId)=>{
        AqaComponent.marketBackend.qdDirectoryUsingGET(
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the library from the server was encountered.",
                    "AqaLibraryManager.handleRulesLibrary, backend call: " + error,this);
                else
                {
                    let qds = [];
                    let currentQD=null;
                    for(let i=0;i<data.publicQualityDefinitions.length;i++){
                        let qd = data.publicQualityDefinitions[i];
                        let qdtemp= {scope:"PUBLIC"};
                        qd = {...qd,...qdtemp};
                        qds.push(qd);
                    }
                    for(let j=0;j<data.privateQualityDefinitions.length;j++){
                        let qd = data.privateQualityDefinitions[j];
                        let qdtemp= {scope:"PRIVATE"};
                        qd = {...qd,...qdtemp};
                        qds.push(qd);
                    }
                    for(let k=0;k<data.protectedQualityDefinitions.length;k++){
                        let qd = data.protectedQualityDefinitions[k];
                        let qdtemp= {scope:"PROTECTED"};
                        qd = {...qd,...qdtemp};
                        qds.push(qd);
                    }
                    if(aqdId !==""){
                        let currentQDs = qds.filter((d)=>{return d.id === aqdId;});
                        if(currentQDs.length>0) currentQD = currentQDs[0];
                    }
                    if(currentQD !== null) this.handleQualityDefinition(currentQD);
                    this.setState({qds:qds});
                }
            }
        );
    }

    handleQualityDefinition = (aqd) =>{
        AqaComponent.marketBackend.getQualityDefinitionFromMarketUsingGET(
            aqd.id,
            (error, data, response) => {
                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                else {
                    let currentQD = {...aqd,...data}
                    this.setState({currentQD:currentQD,isLoaded:true});
                }
            }
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.isAmber!==prevProps.isAmber || this.props.isRed!==prevProps.isRed || this.props.currentHeatMap !== prevProps.currentHeatMap || this.props.currentColumn !== prevProps.currentColumn){
            let snapshot = this.currentSnapshotView.state.latestSnapshot;
            this.currentSnapshotView.handleSnapshot(snapshot.id,snapshot);
        }
    }

    componentDidMount() {
        if(this.props.currentAQD!==""){
            this.handleLibraryQDs(this.props.currentAQD);
        }
    }
    render(){
        //const { classes } = this.props;
        const {isLoaded,currentAspect,showEditor,currentDimensionAspect,showDimensionEditor,currentSnapshot,snapshotcount,showHistory,currentQD} = this.state;
        if(!isLoaded){
            return (
                <div></div>
            );
        }
        else {
            return (

                <div className={"source-detail-masterservant"}>
                    <Grid container spacing={0} direction="row" justify="space-between"
                          alignItems="center"
                          style={{padding: 0, marginTop: "0px"}}>

                        <Grid item xs={3} sm={3} lg={3} xl={3}>
                            <div className="aqa-main-header"
                                 style={{borderBottom: "1px solid #F9F7F9", backgroundColor: "white", marginTop: "0px"}}>
                                <span style={{
                                    float: "left",
                                    width: "100%",
                                    backgroundColor: "white",
                                    height: "56px",
                                    borderBottom:"1px solid rgb(219, 219, 219)"
                                }}>
                                    <Typography variant="inherit" color="inherit"
                                                style={{textAlign: "left", fontSize: "0.8rem", width: "100%"}}>
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            <div>
                                                <Typography color="inherit" className="aqa-text-action">Click to go back to the Data manager view</Typography>
                                            </div>
                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <IconButton className="aqa-button" onClick={this.handleHome.bind(this)}
                                                    style={{padding: "5px",marginTop:"8px",marginBottom:"-5px",marginLeft:"5px"}}>

                                            <div className="aqa-icon">
                                                <BackIcon width="32px"/>
                                            </div>
                                            <Typography className={"aqa-text-bold"} variant="inherit"
                                                        style={{textAlign: "left",paddingLeft:"5px",marginTop:"-5px"}}>
                                                {this.props.currentSource !== null && this.props.currentSource !== ""?this.props.currentSource.name:(this.state.currentQD!==null?this.state.currentQD.title:"Find the id")}
                                            </Typography>
                                        </IconButton>
                                        </HtmlTooltip>

                                    </Typography>
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={9} sm={9} lg={9} xl={9}>
                            <AqaLibraryMainHeader
                                parent={this}
                                dataFromGrid={this.props.dataFromParent}
                                dataFromParent={this.props.dataFromParent}
                                currentQD={currentQD}
                                dataForAspect={currentAspect}
                                showEditor={showEditor}
                                dataForDimensionAspect={currentDimensionAspect}
                                showDimensionEditor={showDimensionEditor}
                                dataFromSnapshot={currentSnapshot}
                                snapshotcount={snapshotcount}
                                showHistory={showHistory}
                            />
                        </Grid>
                    </Grid>


                    <div style={{backgroundColor: "#F9F7F9"}}>
                        <MasterServant>
                            <MasterServant.Master>


                            </MasterServant.Master>
                            <MasterServant.Servant>
                                <div className={showHistory===true?"source-detail-servant-pane":"source-detail-servant-pane-one"}>
                                    <Grid container spacing={0} direction="row" justify="space-between"
                                          alignItems="center"
                                          style={{padding: 0, marginTop: "0px"}}>
                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                            <div style={{boxShadow:"0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)", backgroundColor:"white", "height": "108px", marginBottom:"8px", marginTop:"6px", margin:"6px", marginLeft:"12px"}}>
                                            <AqaLibraryHeader
                                                parent={this}
                                                dataFromGrid={this.props.dataFromParent}
                                                dataFromParent={this.props.dataFromParent}
                                                currentQD={currentQD}
                                                style={{border: "1px solid #DBDBDB"}}
                                            />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                            <div style={{boxShadow:"0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)", backgroundColor:"white", marginBottom:"8px", marginTop:"6px", margin:"6px", marginLeft:"12px"}}>
                                                <AqaLibraryQD
                                                    parent={this}
                                                    dataFromGrid={this.props.dataFromParent}
                                                    dataFromParent={this.props.dataFromParent}
                                                    currentQD={currentQD}
                                                    style={{border: "1px solid #DBDBDB"}}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>



                                    <Typography className="aqa-text-action-12" variant="inherit"
                                                style={{textAlign: "center", padding: "4px",height:"200px",position:"relative"}}>
                                        <Typography className="aqa-text-action-12" variant="inherit"
                                                    style={{textAlign: "center", padding: "4px",margin:"0",top:"6%",position:"absolute",left:"30%",right:"30%"}} >
                                            <Typography className="aqa-text-action-12" variant="inherit"
                                                        style={{textAlign: "center", padding: "4px"}}>

                                                {this.state.snapshotcount === 0?
                                                    <div className={"aqa-file-upload-outer"} onClick={this.handleNewSnapshotClick} onDrop={this.handleNewSnapshotDropClick} onDragOver={this.handleDragOver}>
                                                        <div className="aqa-icon" style={{paddingTop:"100px"}}>
                                                            <UploadIcon width="256px"/>
                                                        </div>
                                                        <Typography className="aqa-text-action-16" variant="inherit"
                                                                    style={{textAlign: "center", padding: "4px",color:"#909090",fontSize:"16px"}}>
                                                            Click or Drag-Drop to upload your data file
                                                        </Typography>
                                                        <Typography className="aqa-text-action-12" variant="inherit"
                                                                    style={{textAlign: "center", padding: "4px",opacity:"0.5",color:"#909090"}}>
                                                            or use LOAD LATEST DATA button
                                                        </Typography>
                                                        <Typography className="aqa-text-action-12" variant="inherit"
                                                                    style={{textAlign: "center", padding: "4px",opacity:"0.5",color:"#909090"}}>
                                                            Note: single sheet .xlsx or .csv with list of records
                                                        </Typography>
                                                    </div>
                                                    :""}
                                            </Typography>
                                        </Typography>

                                    </Typography>
                                    <Input
                                        id="import-file"
                                        inputProps={{
                                            accept:
                                                ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                                        }}
                                        onChange={this.handleFileChange}
                                        style={{display:"none",opacity:'0'}}
                                        type="file"
                                    />
                                </div>
                            </MasterServant.Servant>
                        </MasterServant>
                    </div>
                </div>
            );
        }
    }
}
AqaAQDDetail.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaAQDDetail);
