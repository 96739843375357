/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/AqaQDEntryForAccounts', '../model/AqaQDResponse', '../model/AqaQdPublishingRequest', '../model/AqaQualityDefinitionUpdateResponse', '../model/AqaResponse', '../model/AvaQdDirectoryResponse'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/AqaQDEntryForAccounts'), require('../model/AqaQDResponse'), require('../model/AqaQdPublishingRequest'), require('../model/AqaQualityDefinitionUpdateResponse'), require('../model/AqaResponse'), require('../model/AvaQdDirectoryResponse'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AQAQualityDefinitionsMarketControllerApi = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.AqaQDEntryForAccounts, root.ApiDocumentation.AqaQDResponse, root.ApiDocumentation.AqaQdPublishingRequest, root.ApiDocumentation.AqaQualityDefinitionUpdateResponse, root.ApiDocumentation.AqaResponse, root.ApiDocumentation.AvaQdDirectoryResponse);
  }
}(this, function(ApiClient, AqaQDEntryForAccounts, AqaQDResponse, AqaQdPublishingRequest, AqaQualityDefinitionUpdateResponse, AqaResponse, AvaQdDirectoryResponse) {
  'use strict';

  /**
   * AQAQualityDefinitionsMarketController service.
   * @module api/AQAQualityDefinitionsMarketControllerApi
   * @version 1.0
   */

  /**
   * Constructs a new AQAQualityDefinitionsMarketControllerApi. 
   * @alias module:api/AQAQualityDefinitionsMarketControllerApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the getListOfAccountsSharedWithUsingGET operation.
     * @callback module:api/AQAQualityDefinitionsMarketControllerApi~getListOfAccountsSharedWithUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaQDEntryForAccounts} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns a list of Accounts that a QD is shared with
     * @param {String} qdId qdId
     * @param {module:api/AQAQualityDefinitionsMarketControllerApi~getListOfAccountsSharedWithUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaQDEntryForAccounts}
     */
    this.getListOfAccountsSharedWithUsingGET = function(qdId, callback) {
      var postBody = null;

      // verify the required parameter 'qdId' is set
      if (qdId === undefined || qdId === null) {
        throw new Error("Missing the required parameter 'qdId' when calling getListOfAccountsSharedWithUsingGET");
      }


      var pathParams = {
        'qdId': qdId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaQDEntryForAccounts;

      return this.apiClient.callApi(
        '/api/v1/qd/accounts/{qdId}/shared', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getQualityDefinitionFromMarketUsingGET operation.
     * @callback module:api/AQAQualityDefinitionsMarketControllerApi~getQualityDefinitionFromMarketUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaQDResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a quality definition from market
     * @param {String} qdId qdId
     * @param {module:api/AQAQualityDefinitionsMarketControllerApi~getQualityDefinitionFromMarketUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaQDResponse}
     */
    this.getQualityDefinitionFromMarketUsingGET = function(qdId, callback) {
      var postBody = null;

      // verify the required parameter 'qdId' is set
      if (qdId === undefined || qdId === null) {
        throw new Error("Missing the required parameter 'qdId' when calling getQualityDefinitionFromMarketUsingGET");
      }


      var pathParams = {
        'qdId': qdId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaQDResponse;

      return this.apiClient.callApi(
        '/api/v1/qd/read/{qdId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the importQualityDefinitionFromMarketUsingPOST operation.
     * @callback module:api/AQAQualityDefinitionsMarketControllerApi~importQualityDefinitionFromMarketUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaQualityDefinitionUpdateResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Selects a market Quality Definition bundle and returns id and compatible sources
     * @param {String} qdId qdId
     * @param {module:api/AQAQualityDefinitionsMarketControllerApi~importQualityDefinitionFromMarketUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaQualityDefinitionUpdateResponse}
     */
    this.importQualityDefinitionFromMarketUsingPOST = function(qdId, callback) {
      var postBody = null;

      // verify the required parameter 'qdId' is set
      if (qdId === undefined || qdId === null) {
        throw new Error("Missing the required parameter 'qdId' when calling importQualityDefinitionFromMarketUsingPOST");
      }


      var pathParams = {
        'qdId': qdId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaQualityDefinitionUpdateResponse;

      return this.apiClient.callApi(
        '/api/v1/qd/acquire/{qdId}', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the publishQualityDefinitionUsingPOST operation.
     * @callback module:api/AQAQualityDefinitionsMarketControllerApi~publishQualityDefinitionUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Publishes an AQD
     * @param {String} sourceId sourceId
     * @param {module:model/AqaQdPublishingRequest} pr pr
     * @param {module:api/AQAQualityDefinitionsMarketControllerApi~publishQualityDefinitionUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.publishQualityDefinitionUsingPOST = function(sourceId, pr, callback) {
      var postBody = pr;

      // verify the required parameter 'sourceId' is set
      if (sourceId === undefined || sourceId === null) {
        throw new Error("Missing the required parameter 'sourceId' when calling publishQualityDefinitionUsingPOST");
      }

      // verify the required parameter 'pr' is set
      if (pr === undefined || pr === null) {
        throw new Error("Missing the required parameter 'pr' when calling publishQualityDefinitionUsingPOST");
      }


      var pathParams = {
        'sourceId': sourceId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/qd/{sourceId}', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the qdDirectoryUsingGET operation.
     * @callback module:api/AQAQualityDefinitionsMarketControllerApi~qdDirectoryUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AvaQdDirectoryResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns all the AQDs this account has access to
     * @param {module:api/AQAQualityDefinitionsMarketControllerApi~qdDirectoryUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AvaQdDirectoryResponse}
     */
    this.qdDirectoryUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AvaQdDirectoryResponse;

      return this.apiClient.callApi(
        '/api/v1/qd/directory', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the republishQualityDefinitionUsingPUT operation.
     * @callback module:api/AQAQualityDefinitionsMarketControllerApi~republishQualityDefinitionUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates an AQD. This takes a source id, the previous AQD will be lost.
     * @param {String} sourceId sourceId
     * @param {module:api/AQAQualityDefinitionsMarketControllerApi~republishQualityDefinitionUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.republishQualityDefinitionUsingPUT = function(sourceId, callback) {
      var postBody = null;

      // verify the required parameter 'sourceId' is set
      if (sourceId === undefined || sourceId === null) {
        throw new Error("Missing the required parameter 'sourceId' when calling republishQualityDefinitionUsingPUT");
      }


      var pathParams = {
        'sourceId': sourceId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/qd/source/{sourceId}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the unpublishQualityDefinitionUsingDELETE operation.
     * @callback module:api/AQAQualityDefinitionsMarketControllerApi~unpublishQualityDefinitionUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Unpublishes an AQD
     * @param {String} qdId qdId
     * @param {module:api/AQAQualityDefinitionsMarketControllerApi~unpublishQualityDefinitionUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.unpublishQualityDefinitionUsingDELETE = function(qdId, callback) {
      var postBody = null;

      // verify the required parameter 'qdId' is set
      if (qdId === undefined || qdId === null) {
        throw new Error("Missing the required parameter 'qdId' when calling unpublishQualityDefinitionUsingDELETE");
      }


      var pathParams = {
        'qdId': qdId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/qd/{qdId}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the updateQualityDefinitionFriendlyAccountIdsUsingPUT operation.
     * @callback module:api/AQAQualityDefinitionsMarketControllerApi~updateQualityDefinitionFriendlyAccountIdsUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates the accounts an AQD is available to
     * @param {String} qdId qdId
     * @param {module:model/AqaQdPublishingRequest} pr pr
     * @param {module:api/AQAQualityDefinitionsMarketControllerApi~updateQualityDefinitionFriendlyAccountIdsUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.updateQualityDefinitionFriendlyAccountIdsUsingPUT = function(qdId, pr, callback) {
      var postBody = pr;

      // verify the required parameter 'qdId' is set
      if (qdId === undefined || qdId === null) {
        throw new Error("Missing the required parameter 'qdId' when calling updateQualityDefinitionFriendlyAccountIdsUsingPUT");
      }

      // verify the required parameter 'pr' is set
      if (pr === undefined || pr === null) {
        throw new Error("Missing the required parameter 'pr' when calling updateQualityDefinitionFriendlyAccountIdsUsingPUT");
      }


      var pathParams = {
        'qdId': qdId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/qd/accounts/{qdId}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
