/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MsContext from "../shared/masterservant/MsContext";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import {ReactComponent as LoadingIcon} from "../../images/grid.svg";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import moment from "moment";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaAccountInvoices extends AqaComponent {
    // No, seriously, we need this.
    static contextType = MsContext;
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:"account",
            selectedFolders:[],
            resetCheckbox:false,
            folders: [],
            tabvalue:0,
            invoices:[],
            account:null
        };
        this.handleInvoices=this.handleInvoices.bind(this);
        this.handleAccountDetails=this.handleAccountDetails.bind(this);
    }

    handleAccountDetails(){
//        var accountId=this.props.dataFromRoot.props.dataFromRoot.state.accountId;
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountView.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    this.setState({account:accountObj});
                    this.handleInvoices();
                }
            }

        );
    }
    handleInvoices(){
        AqaComponent.accountBackend.getAllInvoicesForMyAccountUsingGET(
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the invoices from the server was encountered.",
                    "AqaAccountInvoices.handleInvoices, backend call: " + error,this);
                else{
                    var invoices=data;
                    invoices=invoices.sort(this.GetDescSortOrder('emittedWhen'))
                    this.setState({invoices:invoices});
                }
            }

        );
    }

    GetAscSortOrder(prop) {
        return function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }
    GetDescSortOrder(prop) {
        return function(a, b) {
            if (a[prop] < b[prop]) {
                return 1;
            } else if (a[prop] > b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    componentDidMount(){
        this.setState({isLoaded:true});
        this.handleAccountDetails();

    }

    render() {
        this.show = this.context.show;
        //const { classes } = this.props;
        const { error, isLoaded,redirect, notfound,invoices } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <div className={"source-detail-servant-pane"} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={10} sm={7} lg={11} xl={11}>
                            <Typography variant="title" color="inherit" align="left"
                                        style={{ fontSize: '1.5rem',
                                            padding: '0.1em 0.5em',
                                            fontFamily: 'Open Sans, sans-serif'}}>
                                Nothing to display
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} lg={1} xl={1}>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>

            </div>;
        } else if (!isLoaded) {
            return (<div className={"aqa-loader"}>
                <div className={"aqa-loading-icon"}>
                    <LoadingIcon fill={"#4cadc4"}/>
                </div>
            </div>);
        } else {
            return (
                <div className={"source-detail-servant-pane-one"} style={{background: "#FFF 0% 0% no-repeat padding-box"}} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0,marginLeft:"0px"}}>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <div style={{}}>
                            <Table id={"_invoicetable"} cellSpacing={0} aria-sort="ascending" style={{}}>
                                <colgroup>
                                    <col width="28%" />
                                    <col width="12%" />
                                    <col width="22%" />
                                    <col width="12%" />
                                    <col width="12%" />

                                </colgroup>
                                <TableHead>
                                    <TableRow style={{height:"28px"}}>
                                        <TableCell width="20%" style={{"padding":"0px 0px 0px 4px"}}><div className="aqa-text-bold-12"> Issue Date</div></TableCell>
                                        <TableCell width="10%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Payment</div></TableCell>
                                        <TableCell width="40%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Description</div></TableCell>
                                        <TableCell width="10%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Price</div></TableCell>
                                        <TableCell width="10%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Status</div></TableCell>
                                        <TableCell width="10%" style={{"padding":"0px"}}><div className="aqa-text-bold-12"></div></TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoices.map(item =>(
                                        <TableRow key={item.id}>
                                            <TableCell width="20%" style={{"padding":"0px 0px 0px 4px"}}><div className="aqa-text-bold-12">{(item.emittedWhen !== null)? (moment(item.emittedWhen ).format("YYYY/MM/DD HH:mm")):("")}</div></TableCell>
                                            <TableCell width="10%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">{item.paymentMethod!==null?"Credit Card":"Card"}</div></TableCell>
                                            <TableCell width="40%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">{item.description}</div><div className="aqa-text-bold-12">{item.invoiceGenerationError!==""&& item.invoiceGenerationError!==null?item.invoiceGenerationError:""}</div></TableCell>
                                            <TableCell width="10%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">{item.currency==="GBP"?"£":(item.currency==="EUR"?"€":"$")}{(item.amount/100).toLocaleString()}</div></TableCell>
                                            <TableCell width="10%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">{item.status===2?"Paid":(item.status===3?"Cancelled":(item.status===1?"Raised":(item.status===0?"Draft":"Pending")))}</div></TableCell>
                                            <TableCell width="10%" style={{"padding":"0px"}}><div className="aqa-text-bold-12"></div></TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            </div>

                        </Grid>

                    </Grid>
                </div>
            );
        }
    }
}

AqaAccountInvoices.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaAccountInvoices);
