import AqaComponent  from "../components/shared/aqacomponent/AqaComponent"
import ColourAndOverrideMaps from "./ColourAndOverrideMaps"
import ColourMap from "./ColourMap"


/** int (32 bits) number of vectors (N)
 *  int depth
 *  int x N - The vector indices for these vectors that have overriddes
 *  bytes for all the ovverides
*/
export default class OverrideMap
{


	static OR_FIX = 2;
	static OR_IGNORE = 1;
	static OR_UNSET = 0;


	constructor(view)
	{
		this.view = view;
		this.data = null;
	} //


	loadWithBuffer(id, nRows, nCols, colourMap, arrayBuffer, callback)
	{
		this.id = id;
		this.numberOfRows = nRows;
		this.numberOfColumns = nCols;

		this.data = new Uint8Array(arrayBuffer);

		this.availableVectors = []; // Quicker
		this.numberOfVectors = AqaComponent.readInteger(this.data, 0, 4);
		this.depth = AqaComponent.readInteger(this.data, 4, 4);


//console.log("OVERRIDES");
//console.log({numberOfVectors: this.numberOfVectors, depth: this.depth});



		let i, p = 8;
		for(i = 0; i < this.numberOfVectors; i++, p += 4) this.availableVectors[AqaComponent.readInteger(this.data, p, 4)] = i;

		this.dataStart = 8 + (this.numberOfVectors << 2);

		this.colOrientedMatrix = [];

		let x;	
		for(x = 0; x < this.numberOfColumns; x++) this.colOrientedMatrix[x] = [];

		// 2 is fix, 1 is ignore, 0 is override unset
		const counters =[0, 0, 0, 0];
		this.colourCounters = [[0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0]];
		this.cellCounters = [0, 0, 0];

		// 0 Missing, 1 Unique, 2,4,6 Format, 3,5,7 Outlier, 8 Invalid, 9 Type, 10 Native

		this.alertCounters = [
			[[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0]],
			[[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0]],
			[[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0,0]]
		];

		let osv;
		let cartouche
		let b, c, numberOfRagsToCheck, j;
		let k;
		
		for(let y = 0; y < this.numberOfRows; y++)
		{
			for(let x = 0; x < this.numberOfColumns; x++)
			{
				osv = this.getCellInColOrientation(y, x) % 3;
				
				counters[osv]++;

				cartouche = colourMap.getCartouche(x, y);
				
				// 0 Missing, 1 Unique, 2,4,6 Format, 3,5,7 Outlier, 8 Invalid, 9 Type, 10 Native
				k = 0;
				
				if (ColourMap.isGreen(cartouche))
				{
					this.colourCounters[ColourMap.GREEN][osv]++;
					this.cellCounters[ColourMap.GREEN]++;
				}
				else for(i = 0; i < ColourMap.NUMBER_OF_BYTE_STORES_FOR_SPECIFIC_QUALITIES; i++)
				{
					// Code to be kept sync'd up with AqaColourMatrix.anyOfTheseColoursAt() !!!
					numberOfRagsToCheck = i === ColourMap.FORMATS_LENGTHS_AND_VALUES ? 3 : 2;
					b = cartouche[i];
					for(j = 0; j < numberOfRagsToCheck; j++)
					{
						c = (b >> (j << 1)) & 3;
						//console.log(i +" - "+j+ " - "+c+"- "+k );
						this.colourCounters[c][osv]++;
						this.cellCounters[c]++;
						this.alertCounters[c][k][osv]++;
						k++;

					}
				}

/*				
				colour = colourMap.columnBasedOverallColour(x, y); // ici				
				this.colourCounters[colour][osv]++;
				this.cellCounters[colour]++;
*/			

				this.colOrientedMatrix[x][y] = osv; // Yes it's correct.
			}
		}

		this.numberOfFixers = counters[2];
		this.numberOfIgnores = counters[1];

		this.numberOfRedFixers = this.colourCounters[0][2];
		this.numberOfRedIgnores =  this.colourCounters[0][1];

		this.numberOfAmberFixers =  this.colourCounters[1][2];
		this.numberOfAmberIgnores =  this.colourCounters[1][1];

		this.numberOfGreenFixers =  this.colourCounters[2][2];
		this.numberOfGreenIgnores =  this.colourCounters[2][1];

		this.numberOfMissingRedFixers = this.alertCounters[0][0][2];
		this.numberOfUniqueRedFixers = this.alertCounters[0][1][2];
		this.numberOfFormatRedFixers = this.alertCounters[0][2][2] + this.alertCounters[0][4][2] + this.alertCounters[0][6][2];
		this.numberOfOutlierRedFixers = this.alertCounters[0][3][2] + this.alertCounters[0][5][2] + this.alertCounters[0][7][2];
		this.numberOfInvalidRedFixers = this.alertCounters[0][8][2];
		this.numberOfTypeRedFixers = this.alertCounters[0][9][2];
		this.numberOfNativeRedFixers = this.alertCounters[0][10][2];

		this.numberOfMissingAmberFixers = this.alertCounters[1][0][2];
		this.numberOfUniqueAmberFixers = this.alertCounters[1][1][2];
		this.numberOfFormatAmberFixers = this.alertCounters[1][2][2] + this.alertCounters[1][4][2] + this.alertCounters[1][6][2];
		this.numberOfOutlierAmberFixers = this.alertCounters[1][3][2] + this.alertCounters[1][5][2] + this.alertCounters[1][7][2];
		this.numberOfInvalidAmberFixers = this.alertCounters[1][8][2];
		this.numberOfTypeAmberFixers = this.alertCounters[1][9][2];
		this.numberOfNativeAmberFixers = this.alertCounters[1][10][2];

		this.numberOfMissingRedIgnores = this.alertCounters[0][0][1];
		this.numberOfUniqueRedIgnores = this.alertCounters[0][1][1];
		this.numberOfFormatRedIgnores = this.alertCounters[0][2][1] + this.alertCounters[0][4][1] + this.alertCounters[0][6][1];
		this.numberOfOutlierRedIgnores = this.alertCounters[0][3][1] + this.alertCounters[0][5][1] + this.alertCounters[0][7][1];
		this.numberOfInvalidRedIgnores = this.alertCounters[0][8][1];
		this.numberOfTypeRedIgnores = this.alertCounters[0][9][1];
		this.numberOfNativeRedIgnores = this.alertCounters[0][10][1];

		this.numberOfMissingAmberIgnores = this.alertCounters[1][0][1];
		this.numberOfUniqueAmberIgnores = this.alertCounters[1][1][1];
		this.numberOfFormatAmberIgnores = this.alertCounters[1][2][1] + this.alertCounters[1][4][1] + this.alertCounters[1][6][1];
		this.numberOfOutlierAmberIgnores = this.alertCounters[1][3][1] + this.alertCounters[1][5][1] + this.alertCounters[1][7][1];
		this.numberOfInvalidAmberIgnores = this.alertCounters[1][8][1];
		this.numberOfTypeAmberIgnores = this.alertCounters[1][9][1];
		this.numberOfNativeAmberIgnores = this.alertCounters[1][10][1];
		this.computePendings();
		//this.computeAlertCounters(colourMap);

		
// this.dump();


// TODO: Any new property: add it in the load below!!!



		if (callback !== null) callback();
	
	} // loadWithBuffer

	computePendings()
	{
		this.numberOfRedPendings = this.cellCounters[0] - this.numberOfRedFixers - this.numberOfRedIgnores;
		this.numberOfAmberPendings = this.cellCounters[1] - this.numberOfAmberFixers - this.numberOfAmberIgnores;
		this.numberOfPendings = this.numberOfRedPendings + this.numberOfAmberPendings;
	} // computePendings

	computeAlertTypeCounters(colourMap,row,col,oldosv,osv)
	{
		let cartouche = colourMap.getCartouche(col, row);
		let b, c, numberOfRagsToCheck, j;
		if (ColourMap.isGreen(cartouche))
		{

		}
		else{
			let k=0;
			for(let i = 0; i < ColourMap.NUMBER_OF_BYTE_STORES_FOR_SPECIFIC_QUALITIES; i++)
			{
				// Code to be kept sync'd up with AqaColourMatrix.anyOfTheseColoursAt() !!!
				numberOfRagsToCheck = i === ColourMap.FORMATS_LENGTHS_AND_VALUES ? 3 : 2;
				b = cartouche[i];
				for(j = 0; j < numberOfRagsToCheck; j++)
				{
					c = (b >> (j << 1)) & 3;
					this.alertCounters[c][k][osv]++;
					this.alertCounters[c][k][oldosv]--;
					k++;
				}
			}
		}

		this.numberOfMissingRedFixers = this.alertCounters[0][0][2];
		this.numberOfUniqueRedFixers = this.alertCounters[0][1][2];
		this.numberOfFormatRedFixers = this.alertCounters[0][2][2] + this.alertCounters[0][4][2] + this.alertCounters[0][6][2];
		this.numberOfOutlierRedFixers = this.alertCounters[0][3][2] + this.alertCounters[0][5][2] + this.alertCounters[0][7][2];
		this.numberOfInvalidRedFixers = this.alertCounters[0][8][2];
		this.numberOfTypeRedFixers = this.alertCounters[0][9][2];
		this.numberOfNativeRedFixers = this.alertCounters[0][10][2];

		this.numberOfMissingAmberFixers = this.alertCounters[1][0][2];
		this.numberOfUniqueAmberFixers = this.alertCounters[1][1][2];
		this.numberOfFormatAmberFixers = this.alertCounters[1][2][2] + this.alertCounters[1][4][2] + this.alertCounters[1][6][2];
		this.numberOfOutlierAmberFixers = this.alertCounters[1][3][2] + this.alertCounters[1][5][2] + this.alertCounters[1][7][2];
		this.numberOfInvalidAmberFixers = this.alertCounters[1][8][2];
		this.numberOfTypeAmberFixers = this.alertCounters[1][9][2];
		this.numberOfNativeAmberFixers = this.alertCounters[1][10][2];

		this.numberOfMissingRedIgnores = this.alertCounters[0][0][1];
		this.numberOfUniqueRedIgnores = this.alertCounters[0][1][1];
		this.numberOfFormatRedIgnores = this.alertCounters[0][2][1] + this.alertCounters[0][4][1] + this.alertCounters[0][6][1];
		this.numberOfOutlierRedIgnores = this.alertCounters[0][3][1] + this.alertCounters[0][5][1] + this.alertCounters[0][7][1];
		this.numberOfInvalidRedIgnores = this.alertCounters[0][8][1];
		this.numberOfTypeRedIgnores = this.alertCounters[0][9][1];
		this.numberOfNativeRedIgnores = this.alertCounters[0][10][1];

		this.numberOfMissingAmberIgnores = this.alertCounters[1][0][1];
		this.numberOfUniqueAmberIgnores = this.alertCounters[1][1][1];
		this.numberOfFormatAmberIgnores = this.alertCounters[1][2][1] + this.alertCounters[1][4][1] + this.alertCounters[1][6][1];
		this.numberOfOutlierAmberIgnores = this.alertCounters[1][3][1] + this.alertCounters[1][5][1] + this.alertCounters[1][7][1];
		this.numberOfInvalidAmberIgnores = this.alertCounters[1][8][1];
		this.numberOfTypeAmberIgnores = this.alertCounters[1][9][1];
		this.numberOfNativeAmberIgnores = this.alertCounters[1][10][1];

	} // computeAlertTypeCounters



	load(callback)
	{

		if (!this.view) return;

//if (OverrideMap.r[this.view.id]) return;
//OverrideMap.r[this.view.id] = true;


		// https://visionmedia.github.io/superagent/
		// ApiClient.js look for returnType === 'Blob' to understand how $%^&* swagger does it.

		this.numberOfRows = this.view.table.numberOfRows();
		this.numberOfColumns = this.view.table.numberOfColumns();
		
		ColourAndOverrideMaps.load
		(
			this.view.id,
			true,
			caom =>
			{
				this.id = caom.id;
				this.numberOfRows = caom.overrideMap.numberOfRows;
				this.numberOfColumns = caom.overrideMap.numberOfColumns;

				this.data = caom.overrideMap.data;
				this.availableVectors = caom.overrideMap.availableVectors;
				this.numberOfVectors = caom.overrideMap.numberOfVectors;
				this.depth = caom.overrideMap.depth;

				this.dataStart = caom.overrideMap.dataStart;
				this.colOrientedMatrix = caom.overrideMap.colOrientedMatrix;
				this.numberOfFixers = caom.overrideMap.numberOfFixers;
				this.numberOfIgnores = caom.overrideMap.numberOfIgnores;
				
				this.colourCounters = caom.overrideMap.colourCounters;
				this.cellCounters = caom.overrideMap.cellCounters;
				
				this.numberOfRedFixers = caom.overrideMap.numberOfRedFixers;
				this.numberOfRedIgnores =  caom.overrideMap.numberOfRedIgnores;

				this.numberOfAmberFixers =  caom.overrideMap.numberOfAmberFixers;
				this.numberOfAmberIgnores =  caom.overrideMap.numberOfAmberIgnores;

				this.numberOfGreenFixers =  caom.overrideMap.numberOfGreenFixers;
				this.numberOfGreenIgnores =  caom.overrideMap.numberOfGreenIgnores;

				this.numberOfMissingRedFixers = caom.overrideMap.numberOfMissingRedFixers;
				this.numberOfUniqueRedFixers = caom.overrideMap.numberOfUniqueRedFixers;
				this.numberOfTypeRedFixers = caom.overrideMap.numberOfTypeRedFixers;
				this.numberOfFormatRedFixers = caom.overrideMap.numberOfFormatRedFixers;
				this.numberOfOutlierRedFixers = caom.overrideMap.numberOfOutlierRedFixers;
				this.numberOfInvalidRedFixers = caom.overrideMap.numberOfInvalidRedFixers;
				this.numberOfNativeRedFixers = caom.overrideMap.numberOfNativeRedFixers;

				this.numberOfMissingAmberFixers = caom.overrideMap.numberOfMissingAmberFixers;
				this.numberOfUniqueAmberFixers = caom.overrideMap.numberOfUniqueAmberFixers;
				this.numberOfTypeAmberFixers = caom.overrideMap.numberOfTypeAmberFixers;
				this.numberOfFormatAmberFixers = caom.overrideMap.numberOfFormatAmberFixers;
				this.numberOfOutlierAmberFixers = caom.overrideMap.numberOfOutlierAmberFixers;
				this.numberOfInvalidAmberFixers = caom.overrideMap.numberOfInvalidAmberFixers;
				this.numberOfNativeAmberFixers = caom.overrideMap.numberOfNativeAmberFixers;

				this.numberOfMissingRedIgnores = caom.overrideMap.numberOfMissingRedIgnores;
				this.numberOfUniqueRedIgnores = caom.overrideMap.numberOfUniqueRedIgnores;
				this.numberOfTypeRedIgnores = caom.overrideMap.numberOfTypeRedIgnores;
				this.numberOfFormatRedIgnores = caom.overrideMap.numberOfFormatRedIgnores;
				this.numberOfOutlierRedIgnores = caom.overrideMap.numberOfOutlierRedIgnores;
				this.numberOfInvalidRedIgnores = caom.overrideMap.numberOfInvalidRedIgnores;
				this.numberOfNativeRedIgnores = caom.overrideMap.numberOfNativeRedIgnores;

				this.numberOfMissingAmberIgnores = caom.overrideMap.numberOfMissingAmberIgnores;
				this.numberOfUniqueAmberIgnores = caom.overrideMap.numberOfUniqueAmberIgnores;
				this.numberOfTypeAmberIgnores = caom.overrideMap.numberOfTypeAmberIgnores;
				this.numberOfFormatAmberIgnores = caom.overrideMap.numberOfFormatAmberIgnores;
				this.numberOfOutlierAmberIgnores = caom.overrideMap.numberOfOutlierAmberIgnores;
				this.numberOfInvalidAmberIgnores = caom.overrideMap.numberOfInvalidAmberIgnores;
				this.numberOfNativeAmberIgnores = caom.overrideMap.numberOfNativeAmberIgnores;

				this.computePendings();



				if (callback) callback(); // Pass something here?
			

			}
		)
		


		return this;

	} // load

	// 0 based AND **unfiltered** coordinates please!!!
	overrideStateForCellInColOrientation(row, column)
	{
		if (column < 0 || column >= this.numberOfColumns || row < 0 || row >= this.numberOfRows) return OverrideMap.OR_UNSET;
		return this.colOrientedMatrix[column][row];
	}

	/**
	  * This returns the override value of a cell - not a colour
	  */
	getCellInColOrientation(row, column)
	{
		let ix, tx;
		if
		(
			row >= this.numberOfRows
		||
			column >= this.numberOfColumns
		||
			(ix = row >> 2) >= this.depth
		||
			!this.availableVectors
		||
			(tx = this.availableVectors[column]) === undefined
		) return OverrideMap.OR_UNSET; // [col] -> [row] for row orientation
		return (AqaComponent.readInteger(this.data, this.dataStart + tx * this.depth + ix, 1) >> ((row & 3) << 1)) & 3;

	} // overrideStateForCellInColOrientation


	setForColumnOrientation(col, row, value)
	{
	
		// Assumption is that if we're asking for colours, we have have requested the colour and override maps and we asked them to be cached too.
//		const cellColour = ColourAndOverrideMaps.getCellColour(this.id, col, row);


		// Assumption is that if we're asking for colours, we have have requested the colour and override maps and we asked them to be cached too.
		const colourMap = ColourAndOverrideMaps.getColourMap(this.id);
		const colourCounts = colourMap == null ? [0, 0, 1] : colourMap.countColours(col, row);
		
		const oldValue = this.colOrientedMatrix[col][row];
		if (value === oldValue) return;


		for(let cellColour = 0; cellColour < 3; cellColour++)
		{
			if (colourCounts[cellColour] === 0) continue;


			if (oldValue === 1)
			{
				this.numberOfIgnores--;
				if (cellColour === ColourMap.GREEN) this.numberOfGreenIgnores--;
				else if (cellColour === ColourMap.AMBER) this.numberOfAmberIgnores -= colourCounts[cellColour];
				else if (cellColour === ColourMap.RED) this.numberOfRedIgnores -= colourCounts[cellColour];
			}
			else if (oldValue === 2)
			{
				this.numberOfFixers--;
				if (cellColour === ColourMap.GREEN) this.numberOfGreenFixers--;
				else if (cellColour === ColourMap.AMBER) this.numberOfAmberFixers -= colourCounts[cellColour];
				else if (cellColour === ColourMap.RED) this.numberOfRedFixers-= colourCounts[cellColour];

			}

			if (value === 1)
			{
				this.numberOfIgnores++;
				if (cellColour === ColourMap.GREEN) this.numberOfGreenIgnores++;
				else if (cellColour === ColourMap.AMBER) this.numberOfAmberIgnores += colourCounts[cellColour];
				else if (cellColour === ColourMap.RED) this.numberOfRedIgnores += colourCounts[cellColour];
			}
			else if (value === 2)
			{
				this.numberOfFixers++;
				if (cellColour === ColourMap.GREEN) this.numberOfGreenFixers++;
				else if (cellColour === ColourMap.AMBER) this.numberOfAmberFixers += colourCounts[cellColour];
				else if (cellColour === ColourMap.RED) this.numberOfRedFixers += colourCounts[cellColour];
			}
		}
		this.computePendings();
		this.colOrientedMatrix[col][row] = value;
		this.computeAlertTypeCounters(colourMap,row,col,oldValue,value);
	} // setForColumnOrientation



	dump()
	{
	
		console.log("" + this.numberOfColumns + " x " + this.numberOfRows);
		
		console.log("Number of vectors: " + this.numberOfVectors);
		console.log("Depth: " + this.depth);

		console.log("Number of available vectors: " + this.availableVectors.length);


		for(let y = 0; y < this.numberOfRows; y++)
		{
			let c = "" + (y < 10 ? "0" : "") + y + ": ";
			for(let x = 0; x < this.numberOfColumns; x++) c = c + this.colOrientedMatrix[x][y];
			console.log(c);
		}
		console.log(this);





	} // dump

} ////




/*

			if (oldValue === 1)
			{
				this.numberOfIgnores--;
				if (cellColour === ColourMap.GREEN) this.numberOfGreenIgnores--;
				else if (cellColour === ColourMap.AMBER) this.numberOfAmberIgnores--;
				else if (cellColour === ColourMap.RED) this.numberOfRedIgnores--;
			}
			else if (oldValue === 2)
			{
				this.numberOfFixers--;
				if (cellColour === ColourMap.GREEN) this.numberOfGreenFixers--;
				else if (cellColour === ColourMap.AMBER) this.numberOfAmberFixers--;
				else if (cellColour === ColourMap.RED) this.numberOfRedFixers--;

			}

			if (value === 1)
			{
				this.numberOfIgnores++;
				if (cellColour === ColourMap.GREEN) this.numberOfGreenIgnores++;
				else if (cellColour === ColourMap.AMBER) this.numberOfAmberIgnores++;
				else if (cellColour === ColourMap.RED) this.numberOfRedIgnores++;
			}
			else if (value === 2)
			{
				this.numberOfFixers++;
				if (cellColour === ColourMap.GREEN) this.numberOfGreenFixers++;
				else if (cellColour === ColourMap.AMBER) this.numberOfAmberFixers++;
				else if (cellColour === ColourMap.RED) this.numberOfRedFixers++;

			}



*/



/*
		AqaComponent.reviewBackend.getCellOverrideStatiUsingGET
		(
			this.view.id,
			"column",
			(error, data, response) =>
			{
				if (error)
				{
					AqaComponent.staticReportError("A problem getting the override stati colour map arose, sorry.", "ColourMap - load, backend call: " + error,this);
					if (callback !== null) callback();
					return;
				}

				response.body.arrayBuffer().then
				(
					arrayBuffer =>
					{
						this.data = new Uint8Array(arrayBuffer);

						this.availableVectors = []; // Quicker
						this.numberOfVectors = AqaComponent.readInteger(this.data, 0, 4);
						this.depth = AqaComponent.readInteger(this.data, 4, 4);

						let i, p = 8;						
						for(i = 0; i < this.numberOfVectors; i++, p += 4) this.availableVectors[AqaComponent.readInteger(this.data, p, 4)] = i;

						this.dataStart = 8 + (this.numberOfVectors << 2);

						this.colOrientedMatrix = [];

						let x;	
						for(x = 0; x < this.numberOfColumns; x++) this.colOrientedMatrix[x] = [];

						// 2 is fix, 1 is ignore, 0 is override unset
						const counters =[0, 0, 0, 0];
						let osv;

						for(let y = 0; y < this.numberOfRows; y++)
						{
							for(let x = 0; x < this.numberOfColumns; x++)
							{
								osv = this.getCellInColOrientation(y, x) % 3;
								counters[osv]++;
								this.colOrientedMatrix[x][y] = osv; // Yes it's correct.
								
							}
						}

						this.numberOfFixers = counters[2];
						this.numberOfIgnores = counters[1];
// this.dump();

						if (callback !== null) callback();
					}
				);
	
			}
		);

*/

