/* Welcome to Aqa*/
/* Version: 1 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
//import AqaRules from "../sourcedetail/rules/AqaRules";
import AqaNewUser from "./AqaNewUser";
import AqaUserEdit from "./AqaUserEdit";
import AqaUserDelete from "./AqaUserDelete";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaAccountUserHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            sources: []
        };

    }

    componentDidMount(){

    }
    render() {
        //const { classes } = this.props;
        const { error, isLoaded, redirect, notfound } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div style={{"borderBottom":"0px solid #DBDBDB","height":"56px"}}>
                    {(this.props.dataFromRoot.state.selectedUsers.length === 0) ?
                        (<Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                               style={{padding: 0, marginTop: 0}}>

                            <Grid item xs={2} sm={2} lg={2} xl={2}>
                                <AqaNewUser dataFromParent={this.props.dataFromParent} dataForAccount={this.props.dataForAccount}/>
                            </Grid>
                            <Grid item xs={2} sm={2} lg={2} xl={2}>


                            </Grid>
                            <Grid item xs={2} sm={2} lg={2} xl={2}>

                            </Grid>
                        </Grid>) : ("")
                    }
                    {((this.props.dataFromRoot.state.selectedUsers.length === 1) ? (
                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                              style={{padding: 0, marginTop: 0}}>
                            <Grid item xs={6} sm={6} lg={6} xl={6}>
                                    <span>
                                        <span style={{float:"left"}}>
                                    <AqaUserEdit dataFromParent={this.props.dataFromParent}
                                                   dataForFolders={this.props.dataForFolders} parent={this}
                                                   dataAcquired={this.props.dataAcquired}
                                                   dataFromRoot={this.props.dataFromRoot}
                                                 dataForAccount={this.props.dataForAccount}
                                                 dataForCurrentUser={this.props.dataForCurrentUser}
                                                   dataForDisabled={false}/>
                                                     </span>
                                        <span style={{float:"left"}}>

                                    <AqaUserDelete dataFromParent={this.props.dataFromParent}
                                                     dataForFolders={this.props.dataForFolders} parent={this}
                                                     dataAcquired={this.props.dataAcquired}
                                                   dataForAccount={this.props.dataForAccount}
                                                   dataForCurrentUser={this.props.dataForCurrentUser}
                                                     dataFromRoot={this.props.dataFromRoot}/>
                                                     </span>

                                    </span>
                            </Grid>

                            <Grid item xs={6} sm={6} lg={6} xl={6}>

                            </Grid>
                        </Grid>
                    ):((this.props.dataFromRoot.state.selectedUsers.length > 1) ? (
                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                              style={{padding: 0, marginTop: 0}}>
                            <Grid item xs={4} sm={4} lg={4} xl={4}>
                                    <span>
                                        <span style={{float:"left"}}>
                                    <AqaUserEdit dataFromParent={this.props.dataFromParent}
                                                   dataForFolders={this.props.dataForFolders} parent={this}
                                                   dataAcquired={this.props.dataAcquired}
                                                   dataFromRoot={this.props.dataFromRoot}
                                                   dataForDisabled={true}/>
                                                     </span>
                                        <span style={{float:"left"}}>
                                    <AqaUserDelete dataFromParent={this.props.dataFromParent}
                                                     dataForFolders={this.props.dataForFolders} parent={this}
                                                     dataAcquired={this.props.dataAcquired}
                                                   dataForAccount={this.props.dataForAccount}
                                                   dataForCurrentUser={this.props.dataForCurrentUser}
                                                     dataFromRoot={this.props.dataFromRoot}/>

                                                     </span>
                                    </span>
                            </Grid>

                            <Grid item xs={8} sm={8} lg={8} xl={8}>

                            </Grid>
                        </Grid>
                    ) : (""
                        )))

                    }
                    {/*<div style={{display:"none"}} >
                        <AqaRules dataFromParent={this} dataFromSource={this.props.dataFromSource} controller={this.props.dataFromParent} dataForAspect={this.props.dataForAspect} showEditor={this.props.showEditor} dataFromSnapshot={this.props.dataFromSnapshot}/>
                    </div>*/}
                </div>
            );
        }
    }
}

AqaAccountUserHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaAccountUserHeader);
