/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MsContext from "../shared/masterservant/MsContext";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
//import {ReactComponent as LoadingIcon} from "../../images/grid.svg";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AqaAccountOverview from "./AqaAccountOverview";
import AqaAccountInvoices from "./AqaAccountInvoices";
import AqaAccountBilling from "./AqaAccountBilling";
import AqaAccountPM from "./AqaAccountPM";
import AqaAccountUsers from "./AqaAccountUsers";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div >
                    <div>{children}</div>
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

class AqaAccountSettings extends AqaComponent {
    // No, seriously, we need this.
    static contextType = MsContext;
    constructor(props) {
        super(props);
        props.parent.accountSettings=this;
        this.state = {
            error: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:"account",
            selectedFolders:[],
            resetCheckbox:false,
            folders: [],
            tabvalue:0,
            accountObj:null,
            height:window.innerHeight-100
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDimensions=this.handleDimensions.bind(this);
        this.accountUsers = null;
    }


    handleChange = (event, newValue) => {
        let {isAccountExpired,isSubscriptionCancelled} = this.props.dataFromMain.state;
        if(isSubscriptionCancelled || isAccountExpired) return;
        this.setState({tabvalue:newValue});
        if(newValue === 1){
            this.props.dataFromRoot.handleHeaderTab(2,newValue)
        }
        else if(newValue === 4){
            this.props.dataFromRoot.handleHeaderTab(4,newValue)
        }
        else{
            this.props.dataFromRoot.handleHeaderTab(1,newValue);
        }
        //this.props.dataFromRoot.props.dataFromRoot.planNavigationReset();
        this.props.dataFromRoot.props.dataFromParent.handleAccountTab(newValue);
    };

    handleAccountDetails(){
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else {
                    var accountObj = data;
                    this.setState({accountObj:accountObj});
                }
            }
        );
    }

    handleDimensions =(event)=>{
        event.preventDefault();
        this.setState({height:window.innerHeight-100});
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        /*this.handleAccountDetails();
        if(nextProps.dataFromParent.state.openPlanEditor){
            this.setState({tabvalue:0});
        }*/
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.whichTab !== this.props.whichTab){
            this.setState({tabvalue:this.props.whichTab});
        }
    }

    componentDidMount(){
        this.handleAccountDetails();
        this.setState({isLoaded:true});

    }

    render() {
        this.show = this.context.show;
        //const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,tabvalue} = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <div className={"source-detail-servant-pane-one"}>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={10} sm={7} lg={11} xl={11}>
                            <Typography variant="title" color="inherit" align="left"
                                        style={{ fontSize: '1.5rem',
                                            padding: '0.1em 0.5em',
                                            fontFamily: 'Open Sans, sans-serif'}}>
                                Nothing to display
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} lg={1} xl={1}>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>

            </div>;
        } else if (!isLoaded) {
            return (<div className={""}>
                {/*<div className={"aqa-loading-icon"}>
                    <LoadingIcon fill={"#4cadc4"}/>
                </div>*/}
            </div>);
        } else {
            return (
                <div className={"source-detail-servant-pane-one"} style={{background: "#FFF 0% 0% no-repeat padding-box",borderTop:"1px solid #ccc",minHeight:"calc(76vh)",marginTop:"0px"}} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: "0px",paddingLeft:"4px", marginTop: -3}}>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Tabs
                                value={tabvalue}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={this.handleChange}
                                aria-label="scrollable tabs"
                            >
                                <Tab label="Details" {...a11yProps(0)} />
                                <Tab label="Users" {...a11yProps(1)}/>
                                <Tab label="Invoices" {...a11yProps(2)}/>
                                <Tab label="Info" {...a11yProps(3)}/>
                                {this.state.accountObj!==null && this.state.accountObj.subscriptionRef!==null?
                                    <Tab label="PM" {...a11yProps(4)}/>
                                    :""
                                }

                                {/* <Tab label="Payment Methods" {...a11yProps(4)}/> */}

                            </Tabs>
                            <TabPanel value={tabvalue} index={0}>
                                <span>
                                <AqaAccountOverview dataForPlanEditor={this.props.dataForPlanEditor} dataFromParent={this.props.dataFromMain} dataForPlanEditor2={this.props.dataForPlanEditor2} dataFromRoot={this.props.dataFromParent} parent={this}/>
                                </span>
                            </TabPanel>
                            <TabPanel value={tabvalue} index={1}>
                                <span>
                                <AqaAccountUsers parent={this} dataFromParent={this.props.dataFromParent} dataFromMain={this.props.dataFromRoot} dataForCheckbox={this.props.dataForCheckbox}/>
                                </span>
                            </TabPanel>
                            <TabPanel value={tabvalue} index={2}>
                                <span>
                                <AqaAccountInvoices dataFromRoot={this.props.dataFromParent} />
                                </span>
                            </TabPanel>
                            <TabPanel value={tabvalue} index={3}>
                                <span>
                                <AqaAccountBilling dataFromRoot={this.props.dataFromParent} />
                                </span>
                            </TabPanel>
                            <TabPanel value={tabvalue} index={4}>
                                <span>
                                <AqaAccountPM dataFromRoot={this.props.dataFromParent} parent={this} dataFromParent={this.props.dataFromParent} dataFromMain={this.props.dataFromParent} dataForCheckbox={this.props.dataForCheckbox} />
                                </span>
                            </TabPanel>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }
}

AqaAccountSettings.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaAccountSettings);
