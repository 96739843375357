/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as NewSourceIcon} from "../../images/aqa-icons/LinkOutline.svg";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import {
    CircularProgress, DialogActions, FormControlLabel, Radio, RadioGroup
} from "@material-ui/core";
//import {ReactComponent as SourceIcon} from "../../images/aqa-icons/Sourcecol.svg";
import HtmlTooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import Paper, {PaperProps} from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Logo from "../../images/aqaversant-blue.png";
//import AqaMainFrame from "../AqaMainFrame";
//import moment from "moment";
const base64 = require('base-64'); // import doesn't work

function PaperComponent(props: PaperProps) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaSnapshotGetLink extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            openConfirmMessage: false,
            openProgress:false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            periodicities: [],
            periodicity: "daily",
            SourceName: "",
            SourceDescription: null,
            errorMessage: "",
            file: null,
            folders:[],
            selectedSources:[],
            selectedFolders:[],
            urllink:"",
            resKey:"",
            users:[],
            selectedUser:"",
            selectedUserText:"",
            specialMessage:"",
            userType:"",
            firstname:"",
            lastname:"",
            errorMessage1:"",
            errorMessage2:"",
            total:0,
            accountStats:null,
            reviewComplete:false,
            showReviewPop:false,
            showPreviewReport:false,
            newUserEnabled:true

        };
        this.handleGetSnapshotClosePopup=this.handleGetSnapshotClosePopup.bind(this);
        this.handleNewSourceClick=this.handleNewSourceClick.bind(this);
        this.handleClosePopup=this.handleClosePopup.bind(this);
        this.handleAccountPeople=this.handleAccountPeople.bind(this);
        this.handleRadioChange=this.handleRadioChange.bind(this);
        this.handleTextChange=this.handleTextChange.bind(this);
        this.handleShareLink=this.handleShareLink.bind(this);

    }

    handleTextChange = (event) => {
        //var selectedText="";
        if(event.target.name ==="selectedUser" && event.target.value!==""){
            this.setState({[event.target.name]:event.target.value,selectedUserText:event.target.value,errorMessage: "",errorMessage1:"",errorMessage2:"",
                hasError: false});
        }
        else if(event.target.name ==="selectedUser" && event.target.value===""){
            this.setState({[event.target.name]:event.target.value,selectedUserText:"",errorMessage: "",errorMessage1:"",errorMessage2:"",
                hasError: false});
        }
        else if(event.target.name === "selectedUserText"){
            var checkEmail=this.handleValidateEmail(event.target.value);
            if(checkEmail){
                this.setState({[event.target.name]:event.target.value,errorMessage: "",errorMessage1:"",errorMessage2:"",
                    hasError: false});
            }
            else{
                this.setState({[event.target.name]:event.target.value,errorMessage: "Email not valid",errorMessage1:"",errorMessage2:"",
                    hasError: true});
            }
        }
        else{
            this.setState({[event.target.name]:event.target.value,errorMessage: "",errorMessage1:"",errorMessage2:"",
                hasError: false});
        }

    }

    handleAccountPage =()=>{
        this.removeAllNotifications();
        this.props.dataFromParent.props.parent.handleAccountClick();
    }

    handleRadioChange = (event)=>{
        var selectedText="";
        let popper = false;
        let authCode = this.props.dataFromParent.props.dataFromRoot.props.parent.state.authCode;
        let adminEmail = this.props.dataFromParent.props.dataFromRoot.props.parent.state.adminEmail;
        if(event.target.value==="newUser"){
            selectedText="";
            let {accountStats} = this.state;
            if(accountStats!==null) {
                var status = parseInt(accountStats.status);
                if (accountStats.numberOfPurchasedUsers <= accountStats.numberOfDefinedUsers || status < 2) {
                    popper = true;
                }
            }
        }
        if(popper){
            var customNotification = {
                title: "Cannot select New User", titleIcon: "",
                message: <span><span>{"Not enough User Licenses available. "}</span>
                    {authCode === "A" ?
                        <span>Go to your <Button onClick={(e) => this.handleAccountPage(e)} variant="contained"
                                                 color="primary"
                                                 align="left"
                                                 className="aqa-action-button"
                                                 style={{
                                                     marginTop: 0,
                                                     marginLeft: 4,
                                                     marginRight: 4,
                                                     color: '#4cadc4',
                                                     backgroundColor: 'white',
                                                     border: '1px solid #4cadc4',
                                                     fontSize: '0.7rem',
                                                     padding: '3px'
                                                 }}>
                                        Account Details
                                    </Button> page to add the User Licenses</span>
                        : <span> Please contact your administrator {adminEmail}</span>
                    }</span>,
                isCustom: false, type: "info",
                insert: "bottom", position: "bottom-center", autoClose: false, duration: 0
            };
            this.removeAllNotifications();
            setTimeout(()=>this.showNotification(null, customNotification),1000);
            return;
        }
        else {
            if (event.target.value !== "") {
                this.setState({
                    [event.target.name]: event.target.value,
                    selectedUser: "",
                    selectedUserText: selectedText,
                    firstname: "",
                    lastname: "",
                    errorMessage: "", errorMessage1: "", errorMessage2: "",
                    hasError: false
                });
            } else {
                this.setState({
                    selectedUser: "",
                    selectedUserText: selectedText,
                    firstname: "",
                    lastname: "",
                    errorMessage: "", errorMessage1: "", errorMessage2: "",
                    hasError: false
                });
            }
        }
    }

    handleValidateEmail(email){
        const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        const result = pattern.test(email);
        return result;
    }

    handleRandomPassword(lettersLength,numbersLength,specialCharsLength) {
        var j, x, i;
        var result           = '';
        var letters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var numbers       = '0123456789';
        var special       = '~!@-#$';
        for (i = 0; i < lettersLength; i++ ) {
            result += letters.charAt(Math.floor(Math.random() * letters.length));
        }
        for (i = 0; i < numbersLength; i++ ) {
            result += numbers.charAt(Math.floor(Math.random() * numbers.length));
        }
        for (i = 0; i < specialCharsLength; i++ ) {
            result += special.charAt(Math.floor(Math.random() * special.length));
        }
        result = result.split("");
        for (i = result.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = result[i];
            result[i] = result[j];
            result[j] = x;
        }
        result = result.join("");
        return result
    }

    handleResWithUsername(res,username){
        var user={username:username};
        res = {...res,...user};
        var reskey = base64.encode(JSON.stringify(res));
        return reskey;
    }

    handleShareLink(){
        var selectedSource=null;
        var selectedSnapshot = null;
        var selectedSnapshotId="";
        if(this.props.dataFromSnapshot ===undefined) {
            var selectedSnapshots = this.props.dataFromParent.state.selectedSnapshots;
            if (selectedSnapshots.length > 0) {
                selectedSource = this.props.parent.props.dataFromParent;
                selectedSnapshot = selectedSnapshots[0];
                selectedSnapshotId = selectedSnapshot.id;
            }
            else{
                selectedSource = this.props.dataFromGrid;
                selectedSnapshotId = this.props.dataFromParent.currentlySelectedSnapshotId;
                var snaps = this.props.dataFromParent.state.snapshots;
                for(var i=0;i<snaps.length;i++){
                    var snap=snaps[i];
                    if(snap.id === selectedSnapshotId){
                        selectedSnapshot = snap;
                    }
                }
            }
        }
        else {
            selectedSource = this.props.dataFromSource;
            selectedSnapshot = this.props.dataFromSnapshot;
            selectedSnapshotId = selectedSnapshot.id;
        }
        var slr={
            to:this.state.selectedUserText,
            password:this.handleRandomPassword(5,2,1),
            firstname:this.state.firstname,
            lastname:this.state.lastname,
            permissions:"V",
            urlLink: "res="+this.handleResWithUsername(this.state.resKey,this.state.selectedUserText),
            message: this.state.specialMessage,
            resourceType: "Data Upload",
            sourceId: (selectedSource!==null?selectedSource.id:""),
            snapshotId: (selectedSnapshot!==null?selectedSnapshot.id:""),
            snapshotName: (selectedSnapshot!==null?selectedSnapshot.label:""),
            snapshotDate: (selectedSnapshot!==null?moment(selectedSnapshot.dateCreated).format("YYYY/MM/DD HH:mm"):"")
        };
        AqaComponent.userBackend.shareLinkUsingPOST(
            slr,
            (error, data, response) =>
            {
                if (error){
                    var responseMessage=JSON.parse(response.text);
                    if(responseMessage.message !=="" && response.status === 400){
                        if(responseMessage.message==="No violations to share") {
                            this.setState({errorMessage: "Violations are not marked as Must Fix, Unable to share report", hasError: true,openConfirmMessage:true,openMessage:false});
                        }
                        else{
                            this.setState({errorMessage: "Email address already in use",hasError:true});
                        }
                    }
                    else {
                        this.reportError("A problem posting the share link from the server was encountered.",
                            "AqaSnapshotGetLink.handleShareLink, backend call: " + error,this);
                    }
                }
                else
                {
                    if(this.props.dataFromSnapshot ===undefined) {

                        AqaComponent.snapshotBackend.updateSnapshotStatusChangeUsingPOST(
                            selectedSnapshot.id,
                            "Pending",
                            (error, data, response) => {
                                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                                    "AqaSnapshotMainHeader.handleSnapshotReject, backend call: " + error,this);
                                else {
                                    //this.props.parent.handleSnapshotSelectionReset();
                                    //this.setState({showShare:true});
                                    //this.props.parent.handleRefresh();
                                    this.props.dataFromParent.handleSnapshotSelectionReset();
                                    //this.props.dataFromParent.handleRefresh();
                                    this.setState({openMessage: false,openConfirmMessage:true});
                                }
                            }
                        )
                    }
                    this.setState({openMessage: false,openConfirmMessage:true});
                }
            }
        )
    }

    handleClosePopup(e) {
        e.preventDefault();
        let showShare = false;
        if(this.props.dataFromSnapshot ===undefined) {
            this.props.dataFromParent.handleSnapshotSelectionReset();
            if(this.props.parent.state.showShare) {
                showShare=true;
                this.props.parent.handleSnapshotReset();
            }
        }
        this.setState({ errorMessage:"",openMessage: showShare,SourceName:"",SourceDescription:"",periodicity:"daily",userType:"",selectedUser:"",openConfirmMessage:false });
        this.props.dataFromParent.handleRefresh();
    }

    waitCursor() { document.body.style.cursor = "wait"; }
    normalCursor() { document.body.style.cursor = ""; }

    handleReviewOpen = (e,showReview) =>{
        e.stopPropagation();
        this.handleAccountStats();
        if(showReview) this.setState({showReviewPop:true});
    }

    handleReviewClose = (e) =>{
        e.stopPropagation();
        this.setState({showReviewPop:false,showPreviewReport:false});
    }

    handleReviewClosePop = (e) =>{
        e.stopPropagation();
        this.setState({showReviewPop:true,showPreviewReport:false});
    }

    handlePreviewReport = (e,showReview) =>{
        e.stopPropagation();
        this.waitCursor();
        if(!showReview ){
            var customNotification = {
                title: "Cannot Share", titleIcon: "",
                message: "There are no Alerts reviewed as Must Fix to share",
                isCustom: false, type: "info",
                insert: "center", position: "center", autoClose: true, duration: 5000
            };
            this.removeAllNotifications();
            this.showNotification(null, customNotification);
        }
        else {
            this.setState({previewReport: "", showPreviewReport: false});
            var selectedSource = this.props.dataFromGrid;
            var selectedSnapshot = this.props.dataFromParent.currentSnapshotView.latestSnapshot.props.dataFromGrid;
            //var selectedSnapshotId = this.props.dataFromGrid.id;
            var to = this.props.dataFromParent.props.dataFromRoot.props.parent.state.user.email;
            //let res = {nav: 2, fid: "", scid: selectedSource.id, snid: selectedSnapshot.id};
            //let reskey = base64.encode(JSON.stringify(res));
            var slr = {
                to: to,
                password: "",
                firstname: "",
                lastname: "",
                permissions: "V",
                urlLink: "res=link",
                message: "",
                resourceType: "Data Upload",
                sourceId: (selectedSource !== null ? selectedSource.id : ""),
                snapshotId: (selectedSnapshot !== null ? selectedSnapshot.id : ""),
                snapshotName: (selectedSnapshot !== null ? selectedSnapshot.label : ""),
                snapshotDate: (selectedSnapshot !== null ? moment(selectedSnapshot.dateCreated).format("YYYY/MM/DD HH:mm") : "")
            };
            AqaComponent.userBackend.shareLinkPreviewUsingPOST(
                slr,
                (error, data, response) => {
                    if (error) {
                        var responseMessage = JSON.parse(response.text);
                        if (responseMessage.message !== "" && response.status === 400) {
                            if (responseMessage.message === "No violations to share") {
                                this.setState({
                                    errorMessage: "Violations are not marked as Must Fix, Unable to share report",
                                    hasError: true,
                                    openConfirmMessage: true,
                                    openMessage: false
                                });
                            } else {
                                this.setState({errorMessage: "Email address already in use", hasError: true});
                            }
                        } else {
                            this.reportError("A problem posting the share link from the server was encountered.",
                                "AqaSnapshotGetLink.handleShareLink, backend call: " + error, this);
                        }
                    } else {
                        var message = data.message;
                        message = message.replace("cid:aqaversantLogo", Logo);
                        message = message.replace("Click here to access this data directly in AQA", "Action");
                        message = message.replace("http", "#http");
                        message = message.replace(/(<? *script)/gi, 'illegalscript')
                        this.setState({showPreviewReport: true, previewReport: message});
                        const container = document.getElementById('render-report');
                        container.innerHTML = message;
                        this.setState({showReviewPop:false});
                        this.normalCursor();
                    }
                }
            )
        }
    }

    handleShare = (e) =>{
        e.stopPropagation();
        this.handleAccountStats();
        this.handleNewSourceClick();

    }

    handleNewSourceClick() {
        let authCode = this.props.dataFromParent.props.dataFromRoot.props.parent.state.authCode;
        let adminEmail = this.props.dataFromParent.props.dataFromRoot.props.parent.state.adminEmail;
        if(authCode === "V"){
            this.removeAllNotifications();
            var context;
            //var context = this.props.dataFromParent.props.dataFromRoot.props.dataFromRoot.props.dataFromRoot;
            this.showNotification(4, null, context, null,adminEmail);
            return;
        }

        let currentSnapshotView = this.props.dataFromParent.currentSnapshotView;
        let overview = currentSnapshotView!==undefined && currentSnapshotView!==null?currentSnapshotView.latestSnapshot:null;
        let numToFix = 0;
        if(overview){
            let {reviewStats} = overview.state;
            if(reviewStats.length>0){
                for(let i=0;i<reviewStats.length;i++){
                    let stat = reviewStats[i];
                    let numF = parseInt(stat.numF);
                    if(!isNaN(numF)) numToFix = numToFix+numF;
                }
            }
        }
        if(numToFix <=0 ){
            var customNotification = {
                title: "Cannot Share", titleIcon: "",
                message: "There are no Alerts reviewed as Must Fix to share",
                isCustom: false, type: "info",
                insert: "center", position: "center", autoClose: true, duration: 5000
            };
            this.removeAllNotifications();
            this.showNotification(null, customNotification);
        }
        else {
            AqaComponent.accountBackend.getCanUseUsingGET(
                (error, data, response) => {
                    if (error) {
                        //alert("Error getting a quote: " + error);
                        this.reportError("A problem getting the account details from the server was encountered.",
                            "AqaAccountOverview.handleAccountDetails, backend call: " + error, this);
                    } else {

                        var urllink = "";
                        var location = window.location;
                        var urlbase = location.href.slice(0, -((location.search + location.hash).length));
                        if (location.search === "") {
                            urlbase = location.href
                        }
                        var total = 0;
                        let res, reskey, selectedSource, selectedSnapshotId, selectedSnapshot;
                        if (this.props.dataFromSnapshot === undefined) {
                            var selectedSnapshots = this.props.dataFromParent.state.selectedSnapshots;
                            if (selectedSnapshots.length > 0) {
                                selectedSource = this.props.parent.props.dataFromParent;
                                selectedSnapshot = selectedSnapshots[0];
                                total = selectedSnapshot.score;
                                res = {nav: 2, fid: "", scid: selectedSource.id, snid: selectedSnapshot.id};
                                reskey = base64.encode(JSON.stringify(res));
                                urllink = urlbase + "?res=" + reskey;
                            } else {
                                selectedSource = this.props.dataFromGrid;
                                selectedSnapshotId = this.props.dataFromParent.currentlySelectedSnapshotId;
                                res = {nav: 2, fid: "", scid: selectedSource.id, snid: selectedSnapshotId};
                                reskey = base64.encode(JSON.stringify(res));
                                urllink = urlbase + "?res=" + reskey;
                            }
                        } else {
                            selectedSource = this.props.dataFromSource;
                            selectedSnapshot = this.props.dataFromSnapshot;
                            total = selectedSnapshot.score;
                            res = {nav: 2, fid: "", scid: selectedSource.id, snid: selectedSnapshot.id};
                            reskey = base64.encode(JSON.stringify(res));
                            urllink = urlbase + "?res=" + reskey;
                        }
                        this.setState({
                            openMessage: true,
                            urllink: urllink,
                            resKey: res,
                            total: total,
                            accountStats: data
                        });
                    }
                }
            );
        }
    }

    handleAccountStats = () =>{
        AqaComponent.accountBackend.getCanUseUsingGET(
            (error, data, response) => {
                if (error) {
                    //alert("Error getting a quote: " + error);
                    this.reportError("A problem getting the account details from the server was encountered.",
                        "AqaAccountOverview.handleAccountDetails, backend call: " + error, this);
                } else {
                    let accountStats = data;
                    let popper=true;
                    var status = parseInt(accountStats.status);
                    if (accountStats.numberOfPurchasedUsers <= accountStats.numberOfDefinedUsers || status < 2) {
                        popper = false;
                    }
                    this.setState({accountStats:data,newUserEnabled:popper,userType:!popper?"existingUser":""});
                }
            }
        );
    }

    handleGetSnapshotClosePopup(e){
        //var urllink = this.state.urllink;
        if(this.state.selectedUserText === "" || this.state.selectedUserText === null){
            this.setState({errorMessage:"User was not provided to share",hasError:true});
        }
        else if(this.state.userType === "newUser" && !this.handleValidateEmail(this.state.selectedUserText)){
            this.setState({errorMessage:"Email is not valid",hasError:true});
        }
        else if(this.state.userType === "newUser" && (this.state.firstname === "" || this.state.firstname === null)){
            this.setState({errorMessage1:"First name was not provided"});
        }
        else if(this.state.userType === "newUser" && (this.state.lastname === "" || this.state.lastname === null)){
            this.setState({errorMessage2:"Last name was not provided"});
        }
        else {
            this.handleShareLink();

        }
    }

    handleAccountPeople(){
        AqaComponent.userBackend.allUsingGET(
            (error,data,response)=>{
                var errorJSON = JSON.parse(response.text);
                if (errorJSON.error === "invalid_token") {
                    this.props.dataFromParent.props.dataFromRoot.popTimeoutDialog2();
                    //this.props.dataFromParent.props.controller.props.dataFromMain.props.parent.logout();
                }
                else {
                    if (error) this.reportError("A problem getting the person object from the server was encountered.",
                        "AqaGetLink.handleAccountPeople, backend call: " + error,this);
                    else {
                        this.setState({users: data});
                    }
                }
            }
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.dataForPopup !==undefined && nextProps.dataForPopup === true ){
            this.handleNewSourceClick();
        }
    }

    componentDidMount(){
        this.handleAccountPeople();
        this.handleAccountStats();
    }
    render() {
        //const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,errorMessage,users,hasError,selectedUser,errorMessage1,errorMessage2,newUserEnabled } = this.state;
        let showReview = this.props.dataFromParent.state.reviewComplete;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        {/*<Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"8px"}}>
                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                    <div>
                                        <Typography color="inherit" className="aqa-text-action">Click to share alerts</Typography>
                                    </div>
                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <IconButton className="aqa-button" onClick={this.handleNewSourceClick.bind(this)} disabled={false} style={{padding:"4px"}}>

                                    <div className="aqa-icon" >
                                        <NewSourceIcon width="32px" />
                                    </div>
                                    <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                        SHARE
                                    </Typography>
                                </IconButton>
                                </HtmlTooltip>

                            </Typography>
                        </Grid>*/}

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"8px"}}>
                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                    <div>
                                        <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"14px",fontFamily:"Montserrat"}}>{showReview?"Review Completed: Click to see preview/share":"Review progress – shows you the progress of your review of the alerts shown against your data upload"} </Typography>
                                    </div>
                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                    <div>
                                    <IconButton className="aqa-button" onClick={(e)=>this.handleReviewOpen(e,showReview)} disabled={!showReview} style={{padding:"4px"}}>

                                        <div className="aqa-icon" >
                                            <NewSourceIcon width="32px" style={{fill:showReview?"#04A6FC":"rgb(218, 219, 219)"}}/>
                                        </div>
                                        <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:showReview?"#04A6FC":"rgb(218, 219, 219)",paddingLeft:"4px",marginTop:"-4px"}}>
                                            REVIEW {showReview===true?" STATUS":" STATUS"}
                                        </Typography>
                                    </IconButton>
                                    </div>
                                </HtmlTooltip>

                            </Typography>
                        </Grid>

                    </Grid>

                    <Dialog
                        open={this.state.openMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                Share Alerts reviewed as Must Fix
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={this.handleClosePopup} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"960px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"red",padding:"5px",paddingLeft:"20px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        Select user
                                    </Typography>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",width:"100%"}}>
                                        <div style={{paddingLeft:"10px"}}>
                                            <span style={{float:"left",width:"20%"}}>
                                            <RadioGroup aria-label="userType" name="userType" value={this.state.userType} color="primary" onChange={this.handleRadioChange} style={{width:"auto"}}>
                                                <FormControlLabel key={"existinguser"} value={"existingUser"} control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                 style={{padding:"0px" }}>
                                                        <span style={{float:"left",width:"280px"}}>{"Existing User"}</span>
                                                    </Typography>)
                                                } style={{width:"120px",margin:"0px"}} />
                                                {newUserEnabled?<FormControlLabel key={"newuser"} value={"newUser"} control={<Radio color="primary" disabled={false} style={{padding:"4px"}}/>} label={
                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                 style={{padding:"0px" }}>
                                                        <span style={{float:"left",width:"200px"}}>{"New User"}</span>
                                                    </Typography>)
                                                } style={{width:"100%",margin:"0px"}} />
                                                    :
                                                    <FormControlLabel disabled key={"newuser"} value={"newUser"} control={<Radio color="primary" disabled={false} style={{padding:"4px"}}/>} label={
                                                        (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                     style={{padding:"0px" }}>
                                                            <span style={{float:"left",width:"200px"}}>{"New User"}</span>
                                                        </Typography>)
                                                    } style={{width:"100%",margin:"0px"}} />
                                                    }

                                            </RadioGroup>
                                            </span>
                                            <span style={{float:"left",width:"70%",paddingTop:"10px"}}>
                                                {this.state.userType==="newUser"?
                                                    (
                                                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                              style={{padding: 0, marginTop: 0}}>
                                                            <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                                <TextField
                                                                    id="selectedUserText"
                                                                    name="selectedUserText"
                                                                    label="Email Address"
                                                                    value={this.state.selectedUserText}
                                                                    className={"aqa-text-action-10"}
                                                                    variant="standard"
                                                                    margin="none"
                                                                    type="text"
                                                                    multiline={false}
                                                                    error={hasError}
                                                                    helperText={errorMessage}
                                                                    rows={1}
                                                                    style={{ width: "90%",margin:0,padding:"3px"}}
                                                                    onChange={this.handleTextChange}
                                                                    required
                                                                    InputLabelProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},

                                                                    }}
                                                                    InputProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                                <TextField
                                                                    id="firstname"
                                                                    name="firstname"
                                                                    label="Firstname"
                                                                    value={this.state.firstname}
                                                                    className={"aqa-text-action-10"}
                                                                    variant="standard"
                                                                    margin="none"
                                                                    type="text"
                                                                    multiline={false}
                                                                    error={errorMessage1!==""?true:false}
                                                                    helperText={errorMessage1}
                                                                    rows={1}
                                                                    style={{ width: "90%",margin:0,padding:"3px"}}
                                                                    onChange={this.handleTextChange}
                                                                    required
                                                                    InputLabelProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},

                                                                    }}
                                                                    InputProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                                <TextField
                                                                    id="lastname"
                                                                    name="lastname"
                                                                    label="Lastname"
                                                                    value={this.state.lastname}
                                                                    className={"aqa-text-action-10"}
                                                                    variant="standard"
                                                                    margin="none"
                                                                    type="text"
                                                                    multiline={false}
                                                                    error={errorMessage2!==""?true:false}
                                                                    helperText={errorMessage2}
                                                                    rows={1}
                                                                    style={{ width: "90%",margin:0,padding:"3px"}}
                                                                    onChange={this.handleTextChange}
                                                                    required
                                                                    InputLabelProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},

                                                                    }}
                                                                    InputProps={{
                                                                        style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"12px",lineHeight:"10px",textAlign:"right"},
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    ):(this.state.userType==="existingUser"?
                                                        <>
                                                            <FormControl variant="standard" style={{ m: 1, minWidth: 520,width:"500" }}>
                                                                <InputLabel id="selectedUser">Existing User</InputLabel>
                                                                <Select
                                                                    id="selectedUser"
                                                                    name="selectedUser"
                                                                    value={selectedUser}
                                                                    onChange={this.handleTextChange}
                                                                    label="Existing User"
                                                                    className={"aqa-text-action"}
                                                                    fullWidth
                                                                >
                                                                    <MenuItem className="aqa-text-action" style={{paddingTop:"4px",paddingBottom:"4px"}} value="">
                                                                        <em>None</em>
                                                                    </MenuItem>
                                                                    {users.map(item=>(
                                                                            <MenuItem key={item.id} value={item.email} className={"aqa-text-action"} style={{paddingTop:"4px",paddingBottom:"4px"}}>{item.email}</MenuItem>
                                                                        )


                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </>:
                                                        <></>)
                                                }
                                            </span>
                                            <span style={{float:"left",width:"10%"}}>

                                            </span>
                                        </div>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",padding:"5px",paddingLeft:"20px"}}>
                                        Summary of the Alert rows reviewed as Must Fix will be included.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        Add a personal message:
                                    </Typography>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        <TextField
                                            id="specialMessage"
                                            name="specialMessage"
                                            label="Message"
                                            value={this.state.specialMessage}
                                            className={"aqa-text-action-10"}
                                            variant="outlined"
                                            margin="none"
                                            type="text"
                                            multiline={true}
                                            rows={5}
                                            style={{ width: "90%",margin:0,padding:"3px"}}
                                            onChange={this.handleTextChange}
                                            InputLabelProps={{
                                                style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"10px",textAlign:"right"},

                                            }}
                                            InputProps={{
                                                style:{"padding":"5px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"18px",textAlign:"left"},
                                            }}
                                        />
                                    </Typography>

                                </Grid>


                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",minWidth:window.pageXOffset,width:"960px"}}>

                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: 8,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Cancel
                                    </Button>


                                </Grid>

                                <Grid item xs={10} sm={10} lg={10} xl={10}>
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <Button  variant="contained"
                                             onClick={this.handleGetSnapshotClosePopup.bind(this)}
                                             color="primary"
                                             align="left"
                                             className="aqa-action-button"
                                             style={{
                                                 marginTop: 8,
                                                 color: '#4cadc4',
                                                 backgroundColor: 'white',
                                                 border: '1px solid #4cadc4',
                                                 fontSize: '0.7rem',
                                                 padding: '3px',
                                             }}>
                                        Share
                                    </Button>



                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.openProgress}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important"}}>Status</DialogTitle>
                        <DialogContent align="center">
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",paddingLeft:"4px"}}>
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography className={"aqa-action-header"} variant="inherit" style={{textAlign:"Center",color:"#006",paddingLeft:"4px",paddingBottom:"20px",width:"300px"}}>
                                        File is being uploaded
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <CircularProgress />
                                </Grid>
                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 30, marginBottom: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="right"
                                            style={{
                                                marginTop: 2,
                                                marginLeft: 5,
                                                marginBottom: 32,
                                                backgroundColor: '#1976D2'
                                            }}>
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        open={this.state.openConfirmMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                Share Alerts reviewed as Must Fix
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={this.handleClosePopup} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"960px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    {(errorMessage !=="")?
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.9rem",color:"red",padding:"5px",paddingLeft:"20px"}}>
                                        {errorMessage}
                                    </Typography>:
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.9rem",color:"black",padding:"5px",paddingLeft:"20px"}}>
                                        {"Email has been sent to "+this.state.selectedUserText}
                                    </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>

                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",minWidth:window.pageXOffset,width:"960px"}}>

                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: 8,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Close
                                    </Button>


                                </Grid>

                                <Grid item xs={10} sm={10} lg={10} xl={10}>
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} xl={1}>




                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.showReviewPop}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="draggable-dialog-title"
                        maxWidth="lg"
                        PaperComponent={PaperComponent}
                        //hideBackdrop={true}
                    >
                        <DialogTitle id="draggable-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px",width:"600px",cursor: "move"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                Review Status: Completed
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={(e)=>this.handleReviewClose(e)} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,width:"600px"}}>

                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: "16px", marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {("")
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" className="aqa-text-bold-14" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"0px"}}>
                                        You have now completed your review of the AQA identified alerts on your
                                        uploaded file (and you have chosen to either ignore or have identified fixes on
                                        all alerts)
                                    </Typography>
                                    <Typography variant="inherit" color="inherit" className="aqa-text-bold-14" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"4px 0px",paddingTop:"12px"}}>
                                        It is time to choose what to do next..
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        <Button onClick={(e)=>this.handlePreviewReport(e,showReview)} variant="contained"
                                                color="primary"
                                                align="left"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginLeft: 8,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}>
                                            View Fixes
                                        </Button>
                                        <Button onClick={(e)=>this.handleShare(e)} variant="contained"
                                                color="primary"
                                                align="left"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginLeft: 8,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}>
                                            Share Fixes
                                        </Button>
                                        <Button onClick={(e)=>this.handleReviewClose(e)} variant="contained"
                                                color="primary"
                                                align="left"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginLeft: 8,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}>
                                            Exit & Continue
                                        </Button>

                                    </Typography>

                                </Grid>


                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",width:"600px"}}>

                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <Button onClick={(e)=>this.handleReviewClose(e)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: 8,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Close
                                    </Button>


                                </Grid>

                                <Grid item xs={9} sm={9} lg={9} xl={9}>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} xl={2}>

                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.showPreviewReport}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="draggable-dialog-title"
                        maxWidth="lg"
                        PaperComponent={PaperComponent}
                        //hideBackdrop={true}
                    >
                        <DialogTitle id="draggable-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px",cursor: "move"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                Report Preview
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={(e)=>this.handleReviewClosePop(e)} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,width:"900px",maxHeight:"580px",overflow:"auto",scrollbarGutter:"stable"}}>

                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: "8px 0px", marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {("")
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" className="aqa-text-bold-11" style={{textAlign:"center",fontSize:"0.7rem",color:"#006",padding:"0px"}}>
                                        <div style={{}}>
                                            <div id={"render-report"} style={{padding:"0px 8px"}}></div>
                                        </div>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"0px"}}>

                                    </Typography>

                                </Grid>


                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 0,marginLeft:0, marginBottom: 0,borderTop:"1px solid #ccc",width:"890px"}}>

                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <Button onClick={(e)=>this.handleReviewClosePop(e)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: 8,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Close
                                    </Button>


                                </Grid>

                                <Grid item xs={9} sm={9} lg={9} xl={9}>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} xl={2}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"right",fontSize:"0.8rem",color:"#006",padding:"0px"}}>
                                        <Button onClick={(e)=>this.handleShare(e)} variant="contained"
                                                color="primary"
                                                align="right"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginLeft: 8,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}>
                                            Share Fixes
                                        </Button>
                                    </Typography>
                                </Grid>

                            </Grid>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
    }
}

AqaSnapshotGetLink.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSnapshotGetLink);
