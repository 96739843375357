/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';

import AqaComponent                  from "../shared/aqacomponent/AqaComponent"
import AmberRedCheckers              from "../shared/amberredcheckers/AmberRedCheckers"

import {ReactComponent as UniquenessIcon} from "../../images/aqa-icons/Uniquenesscol.svg";
import {ReactComponent as PopulatedIcon}  from "../../images/aqa-icons/Populatedcol.svg";
import {ReactComponent as StringIcon}     from "../../images/aqa-icons/Stringcol.svg";
import {ReactComponent as NumberIcon}     from "../../images/aqa-icons/Numbercol.svg";
import {ReactComponent as DateIcon}       from "../../images/aqa-icons/Datecol.svg";
import {ReactComponent as NativeErrorIcon}       from "../../images/aqa-icons/Nativecol.svg";


import {ReactComponent as TableIcon}      from "../../images/aqa-icons/Grid-Viewcol.svg";


/*
const styles = theme =>
({
    demo: { },
});
*/


const colours = ["#fcbd73", "red"];


export default class AqaTypeMetricsRow extends AmberRedCheckers
{
	static #serial = 0;

	// Yes, I know, still based on a number, but will improve it later.
	static #icons = 
	[
		<UniquenessIcon width="24px" />,
		<PopulatedIcon width="24px" />,
		<StringIcon width="24px" />,
		<NumberIcon width="24px" />,
		<DateIcon width="24px" />,
		<NativeErrorIcon width="24px" />,
		<TableIcon width="24px" />
	];


	constructor(props)
	{
		super(props);

		// Don't overwrite State that's already been defined in the parent!!!!!!!!!!!
		this.state.selected = props.selected;
		props.parent.registerStater(this);

	} //


	// Used to change colour counts from parent. Not invoked as of 2021.07.29
	setColourCount(which, count)
	{
		const colours = this.state.colours;
		colours[which] = count;
		this.setState({colours});
	} // setColourCount


	/**
	 * @param ar: 0: amber, 1: red
	 */
	selectColor(ar)
	{
	
// onsole.log("Local select color - I'm special");

		this.props.parent.specialSelect
		(
			this.props.type, // this,
			() =>
			{
// onsole.log("I AM BEING /super/ COLOR SELECTED!!");			
				super.selectColor(ar);
			} // This, people... is crucial.
		);
		
	
	} // selectColor


	selectAspect = () =>
	{
// onsole.log("Oh I *am* aspect selecting!");	
		if (this.props.type < 5) this.props.parent.doSelection(this.props.type);
	}; // selectAspect()


	filterForColours(postop)
	{
		super.selectColors(this.props.colours[0] > 0, this.props.colours[1] > 0, postop);
	} // filterForColours 


	filterForSelectedColours(amber, red, postop)
	{
		super.selectColors(amber && this.props.colours[0] > 0, red && this.props.colours[1] > 0, postop);
	} // filterForColours 





	render()
	{
		//const { classes } = this.props;

		// The stat item caption will never be longer than 80 characters.

		if (this.props.stat === null) return null;


// onsole.log("TMR: colours");
// onsole.log("Selection: " + this.state.selected);
// onsole.log(AqaComponent.prettifyJson(this.props.colours));
// onsole.log("CAPTION:" + this.props.caption + (this.props.colours ? " GOOD" : " Nooooooooooooooo!"));



		const selectedTypeIndex = this.props.parent.getSelectedTypeIndex();
		//const totalErrors = AqaComponent.formatBigNumber(this.props.colours[0] + this.props.colours[1]);


// this.props.rowCount

		return (
			<tr
				className={this.props.rowCount === 0 ? "aqa-table-list-unselectable" : (selectedTypeIndex === this.props.type ? "aqa-table-list-active" : "aqa-table-list")}
				key={`{AqaSnapshotTypeTableListRow.#serial++}`}
//				onClick={ (e) => { if (this.props.type < 5) this.props.parent.doSelection(this.props.type); }}
				onClick={this.selectAspect}
			>
				<td className="aqa-list-icon" style={{paddingLeft:"5px"}}>{AqaTypeMetricsRow.#icons[this.props.type]}</td>

				{/* <td>{this.props.stat[0]}</td> */}
				<td>{this.props.caption}</td>




				
				{
					colours.map
					(
 						(color, i) =>
						<td style={{color,fontWeight:"bold"}} key={i}>
							<div><span style={{paddingTop:"3px"}}>{AqaComponent.formatBigNumber(this.props.colours[i])}</span>
							<span style={{marginTop:"3px"}}>
							<input
								type="checkbox"
								onClick={e => { e.stopPropagation(); }}
								onChange={() => this.selectColor(i)} checked={this.state.checked[i]}
								disabled={this.props.colours[i] === 0}
							/>
								</span>
							</div>
						</td>
					)
				}

				{/*<td>
					{this.props.rowCount}
				</td>*/}

			</tr>
		);

	} // render

} ////

/*
{/*<td>
					{totalErrors}
					{ Math.round(this.props.stat[1]) } { Old percentage of cells. Teebo says: I wanted 2 decimal digits. Probably better rounded }
				</td>}
			<tr
				className={this.state.selected ? "aqa-table-list-active" : "aqa-table-list"}
				key={`{AqaSnapshotTypeTableListRow.#serial++}`}
				onClick={ (e) => { if (this.props.type < 5) this.props.parent.doSelection(this); }}
			>
*/



// this.props.parent.specialSelect(this);


// onClick={e => e.stopPropagation()}


/*
				<td style={{color:"red"}}>
					{AqaComponent.formatBigNumber(this.props.colours[1])}
					<input type="checkbox" onChange={() => this.selectColor(1)} checked={this.state.checked[1]} />
				</td>
*/


/*
 style={{textAlign:"right",fontSize:"0.8rem",color:"#000"}}
 style={{textAlign:"right",fontSize:"0.8rem",color:"#000000",padding:"0px"}}
 style={{textAlign:"right", fontSize:"0.8rem",color:"#000000",padding:"0px"}}
*/

/*
AqaTypeMetricsRow.propTypes =
{
	classes: PropTypes.object.isRequired,
};
*/

// export default withStyles(styles)(AqaTypeMetricsRow);


// style={{padding:"0px 0px 0px 0px"}}


/*

(this.props.dataFromParent[0] ==="uniqueness")?
		(<UniquenessIcon width="24px" />):
		((this.props.dataFromParent[0] ==="populated")?
		(<PopulatedIcon width="24px" />):
		((this.props.dataFromParent[0] ==="string")?
		(<StringIcon width="24px" />):
		((this.props.dataFromParent[0] ==="number")?
		(<NumberIcon width="24px" />):
		((this.props.dataFromParent[0] ==="flag")?
		(<FlagIcon width="24px" />):
		((this.props.dataFromParent[0] ==="date")?
		(<DateIcon width="24px" />):
		(<SourceIcon width="24px" />))))))


				<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#000",padding:"0px"}}>
				{(this.props.item[0] === "" || this.props.dataFromParent[0])? (this.props.dataFromParent[0].slice(0, 1).toUpperCase() + this.props.dataFromParent[0].slice(1, this.props.dataFromParent[0].length)) : (this.props.dataFromParent[0].substr(0,80)+"...")}
				</Typography>
				</TableCell>
				<TableCell width="20%" style={{padding:"0px 0px 0px 0px"}}>
				<Typography variant="inherit" color="inherit" style={{textAlign:"right",fontSize:"0.8rem",color:"#000",padding:"0px"}}>
				{(this.props.item[1] !== "" )? (Math.round(this.props.dataFromParent[1])) : ("N/A")}
				</Typography>
				</TableCell>
				<TableCell width="20%" style={{padding:"4px 4px 4px 10px"}}>
				<Typography variant="inherit" color="inherit" style={{textAlign:"right",fontSize:"0.8rem",color:"#000000",padding:"0px"}}>
				{(this.props.item[2] !== "" )? (this.props.dataFromParent[2]) : ("N/A")}
				</Typography>


		
*/

/*
				<td>
					{AqaComponent.formatBigNumber(this.props.stat[2])}
				</td>
*/
