
// -----------------------------
// |           GEN II          |
// -----------------------------


import AqaComponent  from "../../shared/aqacomponent/AqaComponent"
import AqaBoundaries from "../../../model/AqaBoundaries"


// Used by AqaRulesDate but neded here so everyone gets them
// import getTime from "date-fns/getTime"
// import toDate from "date-fns/toDate"
// import differenceInDays from "date-fns/differenceInDays"
// import {subDays} from "date-fns"; // startOfToday, format 


const EXTRA_PERCENT_TO_PUT_ON_EACH_SIDE_OF_BOUNDARY_GRAPH = 5;





const NUMBER_OF_MARKS = 5;

export const UNIQUE_PERCENTAGE  = "UniquePercentage";
export const DUPLICATES_ALLOWED = "DuplicatesAllowed";
export const REAPEATS_ALLOWED   = "RepeatsAllowed";

export const PERCENTAGE_POPULATED = "percentagePopulated";
export const ABSOLUTE_POPULATED   = "absolutePopulated";

// This is defined in AqaRules as well - it should be merged
const UNIQUENESS = "uniqueness";
const POPULATED  = "populated";
const NUMBER     = "number";
const DATE       = "date";
const STRING     = "string";
const RAG_COLOURS = ["red", "amber", "green"];


export default class AqaRulesParent extends AqaComponent
{


	static styles = theme =>
	({
		root:
		{
			maxWidth: 345,
			margin: "8px",
		},

		welcomelabel:
		{
			fontSize: 18,
			marginTop: 18,
			marginBottom: 0,
			marginLeft: 20,
			marginRight: 20,
			color: "white"
		},

		getstartedlabel:
		{
			fontSize: 14,
			marginTop: 4,
			marginBottom: 4,
			marginLeft: 20,
			marginRight: 20,
			color: "#1e2b56"
		},

		form:
		{
			display: 'flex',
			flexDirection: 'column',
			margin: 'auto',
			width: 'fit-content',
		},

		formControl:
		{
			marginTop: 0,
			minWidth: 100,
		},

		formControlLabel:
		{
			marginTop: 0,
			fontSize: 14,
		},

		textField:
		{
			fontFamily: 'Open Sans, sans-serif',
			fontSize: 16,
		},

		reportTitle:
		{
			fontSize: 15,
			fontFamily: "Open Sans, sans-serif",
			fontWeight: "bold",
			marginBottom: 4,
			marginLeft: 8,
			color: "#1e2b56"
		},
		
		reportInstruction:
		{
			fontSize: 13,
			fontFamily: 'Open Sans, sans-serif',
			marginBottom: 4,
			marginLeft: 8,
			width: "95%",
			height: 50,
			color: "#1e2b56"
		},
		
		demo: {},
		
		reportBody:
		{
			fontSize: 16,
			fontFamily: 'Open Sans, sans-serif',
			marginBottom: 4,
			color: "#1e2b56"
		}
	});



	constructor(props, TypeName)
	{

		super(props);

		this.rulesObject = props.rulesObject;
		this.rules = props.rules;
		
		this.TypeName = TypeName;

		// 		this.rules = AqaComponent.conflate(RULES); // Clone the array (But not actually a deep copy) // DEFINITELY NOT ANYMORE!!!!
		
		// Guess our type. (by removing 'AqaRules' at the beginning)
		// Note: this is going to be meaningless for Uniqueness and Populated

//		This DOESN'T work because of the transpiler's "optimisations"... GRRR...
//		KEEP FOR LEARNING
//		this.TypeName = this.constructor.name.substring(8); // Yeah I know... I am breaking some conventions here BUT it's legible and explicit.

		this.typeName = this.TypeName.substring(0, 1).toLowerCase() + this.TypeName.substring(1);
		this.textBoxes = {};
		this.marks = {};
		this.distributions={};
		this.distributionMarks = {};
		
	} //

	findDistributionCounts = (distributions, marks,d,max)=>{
		let c = 0;
		let ds = Object.keys(distributions);
		//console.log(marks);
		if(ds.length===0) return c;
		let val = marks[d];
		let val1 = marks[d-1];
		if(d===marks.length-1){
			for (let k = 0; k < ds.length; k++) {
				let dk = ds[k];
				if (dk >= parseInt(val1)) {
					c = c + distributions[dk];
				}
			}
			return c;
		}
		else if(val1 === undefined){
			for (let k = 0; k < ds.length; k++) {
				let dk = ds[k];
				if (dk <= parseInt(val)) {
					c = c + distributions[dk];
				}
			}
			return c;
		}
		else {
			val = parseInt(val);
			val1 = parseInt(val1);
			//console.log(val1 + " - " + val);
			//console.log(ds.length);
			let lj = parseInt(val1)+1;
			for (let j=val1;j<val;j++) {
				for (let k = 0; k < ds.length; k++) {
					let dk = ds[k];
					if (dk > j && dk <= lj && val1 !== undefined) {
						c = c + distributions[dk];
					}
				}
				lj = lj+1;
			}
			return c;

		}
		/*
		for(let k =0;k<ds.length;k++){
			let dskey = ds[k];
			console.log("Key " +dskey);
		}*/
	}

	loadRules()
	{
		this.rulesObject.loadQualityDefinitionsAndContext
		(
			(rules, vector) =>
			{
				this.rules = rules;

				const isNumber = this.typeName === NUMBER, isDate = this.typeName === DATE, isString = this.typeName === STRING;

				// AqaRules have boundaries.
				const {boundaryTypeName} = AqaBoundaries.getBoundaryComponentNames(this.typeName);
				let i, cc;
				for(cc = 0, i = 1; i <= 2; i++) if (!this.rules[this.getBoundaryName(boundaryTypeName, i)]) cc++;
				if (cc === 2) for(i = 1; i <= 2; i++) this.rules[this.getBoundaryName(boundaryTypeName, i)] = this.createBoundarySkeleton(i);

				if (isNumber || isDate || isString)
				{

					this.formats = {};								

					// Formats in VQD - if any
					RAG_COLOURS.forEach
					(
						c =>
						{
							const key = this.getRulesKeyForColourFormats(c);
							let formats = this.rules[key];
							if (!formats)
							{
								// Making sure we have an array to push to.
								formats = [];
								this.rules[key] = formats;
							}
							this.formats[c] = formats;
						}
					);
				}

				this.minVal = false;
				this.maxVal = false;

				let boundaryValues = [];

/*
				if (isNumber || isDate || isString)
				{
*/
					this.findings = vector.findings.data.AqaTypeStatsWalker;
					boundaryValues = this.computeBoundaryParameters();
/*
				}
*/
				if(isNumber && vector.data) this.distributions = vector.data.distributions[NUMBER];
				if(isDate && vector.data) this.distributions = vector.data.distributions[DATE];
				if(isString && vector.data) this.distributions = vector.data.distributions[`lens`];
				if (isNumber || isString) {
					//console.log(this.distributions);
					//console.log(this.marks);
					//console.log(boundaryValues);
					let minC = boundaryValues.length>0?boundaryValues[0]-1:0;
					let maxC = boundaryValues.length>0?(boundaryValues[boundaryValues.length-1]>10?boundaryValues[boundaryValues.length-1]+1:boundaryValues[boundaryValues.length-1]+(0.4)):0;
					let d = Object.keys(this.distributions);
					let m = Object.keys(this.marks);
					if(d.length>0) {
						if(maxC<3) {
							for (let k = 0; k < d.length; k++) {
								let mk = Math.round(d[k]);
								if (mk > maxC && maxC !== 0) this.marks[maxC] = maxC;
								else if (mk < minC && minC !== 0) this.marks[minC] = minC;
								else if (m.indexOf(mk) < 0) this.marks[mk] = mk;
								else this.marks[0] = 0;
							}
						}
						else{
							//console.log(minC+" - "+maxC);
							if(minC >10) minC = 0;
							let to = maxC-0.9;
							let mul = 10;
							//if(to>0 && to<20) {minC=0;to=20;mul=20;}
							if(to>=200) mul=15;
							let di = (to / mul);
							//console.log(to + " - "+ mul + " - "+di);
							let plp = Math.round(minC);
							for(let p=0;p<mul;p++){
								let pl = Math.round(minC+(p*Math.round(di,1)));
								let pl1 = Math.round(minC+(p*Math.round(di,1))+Math.round(di));
								plp = pl;
								let plk= pl1;
								for(let plk1=pl;plk1<plk;plk1++) if(this.marks[plk1]!==undefined) plp=plk1;
								if (m.indexOf(plp) < 0) {
									this.marks[plp] = plp;
								}
							}
							//let dms = Object.keys(this.marks);
							//dms = dms.sort((a,b)=> a - b);
							//let lastdms = dms[dms.length-1];
							//lastdms = parseInt(lastdms);
							//lastdms = lastdms>=40?(lastdms>100?lastdms+5:lastdms+2):lastdms+0.5;
							//this.marks[lastdms] = lastdms;
						}
						let dm = Object.keys(this.marks);
						dm = dm.sort((a,b)=> a - b);

						for(let dmi = 0;dmi<dm.length;dmi++){
							let dmc = this.findDistributionCounts(this.distributions,dm,dmi,maxC);
							let dmcmark = dm[dmi];
							this.distributionMarks[dmcmark] = dmc;
						}
					}
				}
				// Harcoded boundaryTypeName is on purpose.
				let boundary1Suffix =  (isNumber || isDate || isString) ? "Boundary1" : "Boundary";
				const boundary1 = this.rules[AqaBoundaries.getBoundaryComponentNames(this.typeName)["boundaryTypeName"] + boundary1Suffix];
				const invertZones = boundary1 ? boundary1.reversed === true : false;
				this.boundaryValues = boundaryValues;

				this.setState({isLoaded:true, invertZones, marks:[]});
			}
		);
	} // loadRules


	/** Fundamentally required by all the derived objects */
	componentDidMount() { this.loadRules(); }

	// Where have you been all my life?? I love you you useful hook!
	componentDidUpdate(previousProps)
	{
		if (this.props.dataForCol !== previousProps.dataForCol) this.loadRules();
	} // componentDidUpdate



	/** This allows us to keep a reference to the text boxes used by String, Number, Date
	  * so we can carry out operations on them after initialisation.
	  */
	registerTextBox(name, box) { this.textBoxes[name] = box; }

//	makeId = () => `${this.props.dataFromSource.id}_column_${this.props.dataForCol}`;

// Was handleSaveRules2


/*
	OLD_createCompositeValues = () =>
	{
		// For compatibility - but other code should not rely on these left values!!!!!
		// HOWEVER 20230331 it seems these values are convenient shortcut in the UI
		// These are virtual values for our FE benefit - they are not saved in the BE 20220316
		// The BE does send them back but they are *computed* according to the same rules as below.
		const typeEnabled = this.rules.typeEnabled && this.rules.typeSeverity && this.rules.typeSeverity.length > 0;
		const requiredType = this.rules.requiredType;

		this.rules.numberEnabled = this.rules.useNumberFormats || this.rules.useNumberBoundaries || (typeEnabled && requiredType === "number");
		this.rules.dateEnabled   = this.rules.useDateFormats   || this.rules.useDateBoundaries   || (typeEnabled && requiredType === "date");
		this.rules.stringEnabled = this.rules.useStringFormats || this.rules.useStringLengths    || this.rules.useAllowedValues    || (typeEnabled && requiredType === "string");
	
	
	}; // OLD_createCompositeValues
*/
	


	/** All based on this.rules - so much simpler! */
/*
	OLD_adjustCompositeRuleValues()
	{
	
		this.createCompositeValues();


		this.props.rulesObject.handleRulesModified(this.props.dataForCol, this.rules); // jsonObj


onsole.log(AqaComponent.prettifyJson(this.rules));


	} // OLD_adjustCompositeRuleValues

*/

/*
	OLD_handleModalSaveRules(callBackAfter)
	{
	
		this.createCompositeValues();
	
		const authCode = this.props.sourceDetailObject.props.dataFromRoot.props.parent.state.authCode;


onsole.log("MODAL SAVING 1: " + this.constructor.name);

		if (authCode === "V")
		{
onsole.log("MODAL SAVING 2");		
		
			const adminEmail = this.props.sourceDetailObject.props.dataFromRoot.props.parent.state.adminEmail;
			let context; // This should be... what?? @Vamsi

			this.removeAllNotifications();
			this.showNotification(4, null, context , null, adminEmail);
		}
		else
		{
onsole.log("MODAL SAVING 3");





onsole.log(AqaComponent.prettifyJson(this.rules));


			// To make a deep copy - in modal mode we don't care about preserving the origin object as we will close the popup
			let jsonObj = AqaComponent.conflate(this.rules);



			jsonObj.id = this.makeId();
			jsonObj.qualityEnabled = true;
			
onsole.log(AqaComponent.prettifyJson(jsonObj));
			

			AqaComponent.backend.createVectorQualityDefinitionUsingPOST
			(
				jsonObj,
				(error, data, response) =>
				{
onsole.log("MODAL SAVING 4");				
				
					if (error) this.reportError("A problem was encountered when updating check definitions.", "AqaRulesParent.handleModalSaveRules, call: " + error,this);
					else
					{
						if (callBackAfter) callBackAfter();
						else
						{
							//this.props.parent.handleColumnUpdate(jsonObj);
//							this.props.rulesObject.handleRulesModified(this.props.dataForCol, jsonObj);
						}
					}
				}
			);
		}
	} // OLD_handleModalSaveRules
*/


	/** Abstraction used by getBoundaryNumberReferences to push a number boundary reference onto the list it will return */
	pushBoundaryNumberReference(nr, boundaryTypeName, TypeName, typeName, which)
	{

		const boundaryName = this.getBoundaryName(boundaryTypeName, which); // `${boundaryTypeName}Boundary${i}`;

		let boundary = this.rules[boundaryName]; // ie. numberBoundary1, stringLengthBoundary2, etc.

		if (boundary) for(let j = 1; j <= 2; j++)
		{
			if (boundary[`use${TypeName}${j}`]) nr.push([boundaryName, `${typeName}${j}`]);
		}
	} // pushBoundaryNumberReference


	/** This function gets the inventory of Boundary values in use, depending on which booleans are set.
	  * This can be called without using the return value, simply to update the Boundary Number References
	  * This allows us to use the same code to retrieve and set boundary values.
	  * Each value in the returned array is in effect a series of path elements into this.rules.
	  */
	getBoundaryNumberReferences()
	{

// THIS LINE CAUSES PROBLEMS!!!!! Why am I thinking it's a good idea to cache this???
//		if (this.boundaryNumberReferences !== null) return this.boundaryNumberReferences;

		const {boundaryTypeName, TypeName, typeName} = AqaBoundaries.getBoundaryComponentNames(this.typeName);

		const nr = [];

		switch(this.typeName) // Uniqueness and populated only have one boundary. So gathering boundaries works slightly differently for them.
		{
		case UNIQUENESS:
		case POPULATED:

			if (this.rules[`${this.typeName}Enabled`]) this.pushBoundaryNumberReference(nr, boundaryTypeName, TypeName, typeName, "");
			break;

		default:

			for(let i = 1; i <= 2; i++) this.pushBoundaryNumberReference(nr, boundaryTypeName, TypeName, typeName, i);
			break;
		}

		return nr;

	} // getBoundaryNumberReferences


	/** This gets us those boundary numbers which are activated.
	  * These numbers will be instrumental to displaying the user changeable range
	  * as well as for calculating mins and maxes
	  */
	getBoundaryValues()
	{
		const boundaryValueReferences = this.getBoundaryNumberReferences();
		const bv = [];
		
// onsole.log("Boundary values I will be pushing:");
// boundaryValueReferences.forEach(vr => onsole.log("-> " + this.rules[vr[0]][vr[1]]));
		boundaryValueReferences.forEach(vr => bv.push(this.rules[vr[0]][vr[1]]));
		return bv;
	} // getBoundaryNubmbers


	scaleFromValueToScreenValue = v =>
	{
		const range = this.wideMaxRange - this.wideMinRange;
		if (range > 0) return (v - this.wideMinRange) * this.state.width / range;
		return 0;
	}; // scaleFromValueToScreenValue



	/** This will allow us to decide how to draw the range */
	computeBoundaryParameters()
	{

		// Calculating graphical range
	
		let startWideMinRangeAt0 = false;
		let endMaxRangeAt100 = false;
		//let specialNumber = false;

		switch(this.typeName)
		{
		case UNIQUENESS:
			startWideMinRangeAt0 = true;
			switch(this.rules.uniquenessType)
			{
			case UNIQUE_PERCENTAGE:
				this.minVal = 0;
				this.maxVal = 100;
				endMaxRangeAt100 = true;
				break;

			case DUPLICATES_ALLOWED:
			case REAPEATS_ALLOWED:

				this.minVal = 0;
				this.maxVal = 10;
				break;

			default:
				break;
			}
		
			break;
		
		case POPULATED:

			startWideMinRangeAt0 = true;

			switch(this.rules.populatedType)
			{
			case PERCENTAGE_POPULATED:
			
				this.minVal = 0;
				this.maxVal = 100;
				endMaxRangeAt100 = true;
				break;

			case ABSOLUTE_POPULATED:

				this.minVal = 0;
				this.maxVal = 1000;
				break;

			default:
				break;
			}
			break;


		case NUMBER:
			//if(this.props.dataForName ==="Year") specialNumber=true; // WHAT ON EARTH ?!?!?!?!?!?!????????
			break;

		case DATE:

			if (this.findings)
			{
				this.minVal = this.findings[0].metric;
				this.maxVal = this.findings[1].metric;
			}
			break;

		case STRING:

			this.minVal = 0;
			this.maxVal = 25; // Trying to guess the maximum number of a string length - We do not have access to distributions.
			break;

		default: // To shut up he transpiler
		


			break;
		}

		let boundaryValues = this.getBoundaryValues();
		
		const nBoundaries = boundaryValues.length;
		let minRange, maxRange;

		if (nBoundaries > 0)
		{
//			if(nBoundaries>1 && specialNumber) if(boundaryValues[0] === boundaryValues[1]) boundaryValues[1]=boundaryValues[1]+2;

			// We're going to define min and max range using the extrema AND the boundary values
			if (this.minVal !== false) minRange = this.minVal < boundaryValues[0] ? this.minVal : boundaryValues[0];
			else minRange = boundaryValues[0];

			if (this.maxVal !== false) maxRange = this.maxVal > boundaryValues[nBoundaries - 1] ? this.maxVal : boundaryValues[nBoundaries - 1];
			else maxRange = boundaryValues[nBoundaries - 1];
			
		}
		else
		{
			// Using only the extrema from the (backend) analysis
			if (this.minVal !== false && this.maxVal !== false)
			{
				minRange = this.minVal;
				maxRange = this.maxVal;
			}
			else
			{
				minRange = 0;
				maxRange = 0;
			}
		}


		// computing extended range: same as range but with 5% on each side so as to give us a bit of space
		const range = Math.abs(maxRange - minRange);
		if (range > 0)
		{
			const extra = range * EXTRA_PERCENT_TO_PUT_ON_EACH_SIDE_OF_BOUNDARY_GRAPH / 100;
			this.wideMinRange = startWideMinRangeAt0 ? 0 : minRange - extra;
			this.wideMaxRange = endMaxRangeAt100 ? 100 : maxRange + extra;

			const wideRange = this.wideMaxRange - this.wideMinRange;

			this.marks = {};
			let mark;
//			const dealingWithDates = this.typeName === DATE;
			for(let i = 0; i <= NUMBER_OF_MARKS; i++)
			{
				mark = this.wideMinRange + (wideRange * i / NUMBER_OF_MARKS);
				if(this.typeName==="string" || this.typeName==="number") mark = Math.round(mark);

				/*
				if(this.typeName==="number" && specialNumber){
					mark = Math.round(mark);
					this.marks[mark] = mark;
				}
				else */ this.marks[mark] = this.dataFormatter(mark);
			}
		}
		else
		{
			this.wideMinRange = 0;
			this.wideMaxRange = 0;
			this.marks = {};
		}

		return boundaryValues;

	} // computeBoundaryParameters


	dataFormatter(x) { return x; }

	getRulesKeyForColourFormats = colour => `${colour}${this.TypeName}Formats`;
	
	getKeyForUnlistedFormatColour = colour => `unlisted${this.TypeName}FormatColour`;


	/** This gets all we need from the server.
	  * - the VQD (this.rules)
	  * - the stats (not persisted)
	  */
/*
	OLD_loadQualityDefinitionsAndContext()
	{

		AqaComponent.backend.getQualityUsingGET
		(
			this.makeId(),
			(error, data, response) =>
			{
				if (error) this.reportError("A problem loading vector quality definitions occurred.", "AqaRulesParent.loadQualityDefinitionsAndContext, call: " + error,this);
				else
				{
					this.rules = data;


					this.props.dataForTable.getColumnVector
					(
						this.props.dataForCol,
						d =>
						{


							const isNumber = this.typeName === NUMBER, isDate = this.typeName === DATE, isString = this.typeName === STRING;

							// AqaRules have boundaries.
							const {boundaryTypeName} = AqaBoundaries.getBoundaryComponentNames(this.typeName);
							let i, cc;
							for(cc = 0, i = 1; i <= 2; i++) if (!this.rules[this.getBoundaryName(boundaryTypeName, i)]) cc++;
							if (cc === 2) for(i = 1; i <= 2; i++) this.rules[this.getBoundaryName(boundaryTypeName, i)] = this.createBoundarySkeleton(i);


							
							if (isNumber || isDate || isString)
							{

								this.formats = {};								

								// Formats in VQD - if any
								RAG_COLOURS.forEach
								(
									c =>
									{
										const key = this.getRulesKeyForColourFormats(c);
										let formats = this.rules[key];
										if (!formats)
										{
											// Making sure we have an array to push to.
											formats = [];
											this.rules[key] = formats;
										}
										this.formats[c] = formats;
									}
								);
							}

							this.minVal = false;
							this.maxVal = false;
							
							let boundaryValues = [];


								this.findings = d.findings.data.AqaTypeStatsWalker;
								boundaryValues = this.computeBoundaryParameters();
							

							// Harcoded boundaryTypeName is on purpose.
							let boundary1Suffix =  (isNumber || isDate || isString) ? "Boundary1" : "Boundary";
							const boundary1 = this.rules[AqaBoundaries.getBoundaryComponentNames(this.typeName)["boundaryTypeName"] + boundary1Suffix];
							const invertZones = boundary1 ? boundary1.reversed === true : false;
							this.boundaryValues = boundaryValues;

							this.setState({isLoaded:true, invertZones, marks:[]});
						}

					);
				}
			}
		);

	} // OLD_loadQualityDefinitionsAndContext
*/

	/** This will create a boundary (which it returns)
	  * and assign it to this.rules
	  * @param which 1 or 2
	  * @return the new boundary
	  */
	createBoundarySkeleton(which)
	{
		const {boundaryTypeName, TypeName, typeName} = AqaBoundaries.getBoundaryComponentNames(this.typeName);

		this.minVal = 0;
		this.maxVal = 100;

		let defaultValues;
		if (this.typeName === DATE)
		{
			if (which === 1) defaultValues = [0, 63115200000, 126273600000];
			else defaultValues = [0, 852033600000, 915105600000];
		}
		else
		{
			if (which === 1) defaultValues = [0, 10, 20];
			else defaultValues = [0, 80, 90];
		}

		const boundary = {};
		for(let i = 1; i <= 2; i++)
		{
			boundary[`use${TypeName}${i}`] = false; // uses[i];
			boundary[`${typeName}${i}`] = defaultValues[i];
		}
		boundary.reversed = which === 2; // Creating RAGAR by default.
		this.rules[this.getBoundaryName(boundaryTypeName, which)] = boundary;
		return boundary;
	
	} // createBoundarySkeleton




	/** For uniqueness and populated which don't work in exactly the same way and need this extra handler */
	handleRadioChange2 = e =>
	{
		this.rules[`${this.typeName}Type`] = e.target.value;
		this.boundaryValues = this.computeBoundaryParameters();
		this.rulesObject.adjustCompositeRuleValues();
		this.forceUpdate();
	
	}; // handleRadioChange2 


	/** Utility */
	getBoundaryName(boundaryTypeName, boundaryNumber) { return `${boundaryTypeName}Boundary${boundaryNumber}`; }

	/** This tells you if a value is activated in a boundary
	  * (A bit annoying there is code shared with getBoundaryNumberReferences)
	  * For example: numberBoundary1.useNumber1
	  */
	isBoundaryValueActivated(boundaryNumber, valueNumber)
	{
	
		const {boundaryTypeName, TypeName} = AqaBoundaries.getBoundaryComponentNames(this.typeName);
		const boundary = this.rules[this.getBoundaryName(boundaryTypeName, boundaryNumber)];
		if (!boundary) return false;
		return boundary[`use${TypeName}${valueNumber}`] === true; // Yes we need the cast to boolean
	} // getBoundaryValueActivated 


	setBoundaryValueActivated(boundaryNumber, valueNumber, b)
	{

		const {boundaryTypeName, TypeName, typeName} = AqaBoundaries.getBoundaryComponentNames(this.typeName);
		const boundaryName = this.getBoundaryName(boundaryTypeName, boundaryNumber);

		if (!this.rules[boundaryName])
		{
			this.rules[boundaryName] =
			{
				[`use${TypeName}1`]: false,
				[`use${TypeName}2`]: false,
				[`${typeName}1`]: 0,
				[`${typeName}2`]: 0,
				reversed: boundaryNumber === 2
			}
		}

		if (b === true) // If we're not enabling a boundary, we shouldn't bother calculating a value for it. We should just keep it.
		{
			let i, j, pos = 1;
			//let posPtr = 1;
			//let values = [0]; // 0 is just to create a value
			let values = [0,0,0,0]; // 0 is just to create a value
			let bn;

			// No, we can't zero in onto the positions
			// Yes, we need the loop

			let nPos = 0;
			for(i = 1; i<3;i++){
				bn = this.getBoundaryName(boundaryTypeName, i);
				for(j = 1; j<3;j++,nPos++){
					values[nPos] = this.rules[bn][`${typeName}${j}`];
				}
			}

			/*for(i = 1; i < 3; i++)
			{
				bn = this.getBoundaryName(boundaryTypeName, i);
				for(j = 1; j < 3; j++)
				{
					if (i === boundaryNumber && valueNumber === j)
					{
						pos = posPtr;
						values[posPtr++] = this.rules[bn][`${typeName}${j}`];
					}
					else
					{
						if (this.rules[bn][`use${TypeName}${j}`])
						{
							values[posPtr++] = this.rules[bn][`${typeName}${j}`];
							if (posPtr === (pos + 2)) break;
						}
					}
				}
			}

			// Need the left limit?
			if (pos === 1) values[0] = Math.round(this.wideMinRange);

			// Need the right limit?
			if (pos === values.length - 1) values.push(this.wideMaxRange);*/
			
			if (typeof values[pos] === 'undefined' || values[pos] <= values[pos - 1] || values[pos] >= values[pos + 1])
			{
				// Setting the sensible value that will do, as the one we have won't.
				let diff = Math.round(this.wideMaxRange-this.wideMinRange);
				if(diff<2) diff=1;
				let valuediff = values[pos]+diff;
				if(boundaryNumber === 1 && valueNumber === 1 ) valuediff = values[pos+1]-diff;
				//this.rules[boundaryName][`${typeName}${valueNumber}`] = (values[pos - 1] + values[pos + 1]) / 2;
				this.rules[boundaryName][`${typeName}${valueNumber}`] = (valuediff);

			}
			else{
				let diff = Math.round(this.wideMaxRange-this.wideMinRange);
				if(diff<2) diff=1;
				let valuediff = values[pos]+diff;
				if(boundaryNumber === 1 && valueNumber === 1 ) valuediff = values[pos+1]-diff;
				this.rules[boundaryName][`${typeName}${valueNumber}`] = (valuediff);
			}
		}

		this.rules[boundaryName][`use${TypeName}${valueNumber}`] = b;
		this.boundaryValues = this.computeBoundaryParameters();
		this.rulesObject.adjustCompositeRuleValues();
		this.forceUpdate();

	} // setBoundaryValueActivated


	/** Depending on our type (for example number will return this.rules.numberEnabled)
	  * This will return whether we are enabled or not.
	  */
	isRulesEnabled = () => this.rules[`${this.typeName}Enabled`];


	setRulesEnabled(b)
	{
		// In effect will only be called for populated and uniqueness
		this.rules[`${this.typeName}Enabled`] = b;
		this.rulesObject.adjustCompositeRuleValues();
		this.forceUpdate();

	} // setRulesEnabled


	// These next two functions do not use the available type names because the String bit is too different. (inconsistent?)
	// So it's a bit more generic and less automatic

	/** For example propNamc could be "dateFormats"
	  */
	isUsed(propName) { return this.rules["use" + propName] === true; }

	setUsed(propName, b)
	{
		this.rules["use" + propName] = b;
		this.rulesObject.adjustCompositeRuleValues();
		this.forceUpdate();
	} // setUsed


	typeEnforcedFor2() { return this.rules.typeEnabled && this.rules.requiredType === this.typeName; }

	handleEnforceTypeChange2(b)
	{
		if (b)
		{
			this.rules.typeEnabled = true;
			this.rules.requiredType = this.typeName;
		}
		else this.rules.typeEnabled = false;

		const typeNegCondition = this.rules.typeEnabled === false && this.rules.requiredType === this.typeName;


		let typeEnabler = null;

		// Why the this.rules["useAllowedValues"]  === false bit? (for example)
		
		// This replicates logic already implemented in AqaRules.createCompositeValues
		// Also the ["keyName"] notation is heavy.
		// TODO: refactor like we could actually write code!!
		if (this.typeName === "string")      typeEnabler = !(this.rules["useStringFormats"] === false && this.rules["useAllowedValues"]  === false && this.rules["useStringLengths"]    === false && typeNegCondition);
		else if (this.typeName === "number") typeEnabler = !(this.rules["useNumberFormats"]  === false && this.rules["useNumberBoundaries"] === false && typeNegCondition);
		else if(this.typeName === "date")    typeEnabler = !(this.rules["useDateFormats"]    === false && this.rules["useDateBoundaries"]   === false && typeNegCondition);
		if (typeEnabler !== null) this.rules[`${this.typeName}Enabled`] = typeEnabler;


		this.rulesObject.adjustCompositeRuleValues();
		this.forceUpdate();

	} // handleEnforceTypeChange
	
	
	handleTypeSeverityChange2 = e =>
	{
		this.rules.typeSeverity = e.target.value;
		this.rulesObject.adjustCompositeRuleValues();
		this.forceUpdate();

	} // handleTypeSeverityChange2



	handleInvertZone(b)
	{
		const {boundaryTypeName} = AqaBoundaries.getBoundaryComponentNames(this.typeName);
		
		if (this.typeName === UNIQUENESS || this.typeName === POPULATED)
		{
			this.rules[this.getBoundaryName(boundaryTypeName, "")].reversed = b;
		}
		else
		{
			this.rules[this.getBoundaryName(boundaryTypeName, 1)].reversed = b;
			this.rules[this.getBoundaryName(boundaryTypeName, 2)].reversed = !b;
		}

		this.boundaryValues = this.computeBoundaryParameters();
		this.setState({invertZones:b});
		this.rulesObject.adjustCompositeRuleValues();
		
		// No force update?

	} // handleInvertZone

	
	setRuleByPath(rulesPathElements, value)
	{
		const n = rulesPathElements.length - 1;
		let dest = this.rules;
		for(let i = 0; i < n; i++) dest = dest[rulesPathElements[i]];
		dest[rulesPathElements[n]] = value;
		
		


	} // setRuleByPath
	
	
	
	// For these Rules Objects that use Range
	handleRangeChange2 = e =>
	{
		// We set the values we have to the boundary elements we know are set.
		const bnr = this.getBoundaryNumberReferences();
		const n = bnr.length;
		if (n !== e.length)
		{
			console.error("AqaRulsParent.handleRangeChange2(): We have a discrepancy between the number of Boundary Number References and the number of values from the Range Change Event. This could be transient but if it keeps happening, it needs to be investigated.");
			return;
		}

		// Settings those boundary values that are enabled.
		const boundaryValues = Array(n);

		bnr.forEach((nr, i) => this.rules[nr[0]][nr[1]] = boundaryValues[i] = e[i]);

		// We have boundaryValues on the state, so it's handy to use that to force a refresh.
		// Our vqd state is otherwise preserved on this.rules

		this.boundaryValues = boundaryValues;
		this.rulesObject.adjustCompositeRuleValues();
		this.forceUpdate();

	} // handleRangeChange2

	

	/** This is for the text boxes where users can enter boundary values by hand.
	  *
	  */
	handleTextChange2 = e =>
	{
		const tValue = e.target.value;
		let value;
		let success = true;

		// This does validation for the different types of fields we have.
		// Note these should be implemented as methods in their respective derived objects for proper OO feel!
		try
		{
			switch(this.typeName)
			{
			case UNIQUENESS:
				// TODO - validate for floats 1-100 for percentage and integers 0 to whatever for the rest
				value = parseFloat(tValue);
				if (isNaN(value)) throw new Error("Value (" + tValue + ") is not a number");

				break;
	
			case POPULATED:

				// TODO - validate for floats 1-100 for percentage and integers 0 to whatever for the rest
				value = parseFloat(tValue);
				if (isNaN(value)) throw new Error("Value (" + tValue + ") is not a number");

				break;

			case DATE:
				const dEls = tValue.split('-'); // Highly dependent on the format returned by the date picker!!!
				value = Date.UTC(parseInt(dEls[0], 10), parseInt(dEls[1], 10) - 1, parseInt(dEls[2], 10)); // You have no idea how necessary those 10s are...
				break;

			case NUMBER:
				if (!tValue) throw new Error("no value available");
				if (/^\s*$/.test(tValue)) throw new Error("format error");
				value = parseFloat(tValue);
				if (isNaN(value)) throw new Error("Value (" + tValue + ") is not a number");
				break;

			case STRING: // We're dealing in string lengths here - so values are integers.
				// 20211015 = TJ: I am sure we still have some fall throughs....
				if (!tValue) throw new Error("no value available");
				if (!/^-?\d+$/.test(tValue)) throw new Error("incorrect format");
				value = parseInt(tValue);
				if (isNaN(value)) throw new Error("value (" + tValue + ") not evaluating to an integer");
				if (value < 0) throw new Error("Negative values are not acceptable");
				break;

			default: // To shut up the transpiler.
				break;
			}
		}
		catch(error)
		{
			console.error("AqaRulesParent: handleTextChange2: (" + this.typeName + ") " + error);
			success = false;
		}

		const tbn = e.target.name;
		const rulesPathEls = tbn.split('.');

		//let tbnn = "";

		if (success)
		{

			this.setRuleByPath(rulesPathEls, value);
			//let newValue = value;

/*		
			// What on Earth?!?!?!?!
			if(tbn === "numberBoundary1.number1") {tbnn = "numberBoundary1.number2";newValue= this.rules[`numberBoundary1`].number2}
			if(tbn === "numberBoundary1.number2") {tbnn = "numberBoundary2.number1";newValue= this.rules[`numberBoundary2`].number1}
			if(tbn === "numberBoundary2.number1") {tbnn = "numberBoundary2.number2";newValue= this.rules[`numberBoundary2`].number2}
			if(tbn === "stringLengthBoundary1.number1") {tbnn = "stringLengthBoundary1.number2";newValue= this.rules[`stringLengthBoundary1`].number2}
			if(tbn === "stringLengthBoundary1.number2") {tbnn = "stringLengthBoundary2.number1";newValue= this.rules[`stringLengthBoundary2`].number1}
			if(tbn === "stringLengthBoundary2.number1") {tbnn = "stringLengthBoundary2.number2";newValue= this.rules[`stringLengthBoundary2`].number2}
			if(tbnn !=="")
			{
				const rulesPathElsn = tbnn.split('.');
				if(newValue === value) newValue = value + 1;
				this.setRuleByPath(rulesPathElsn, newValue);
			}

*/

			this.boundaryValues = this.computeBoundaryParameters();
			this.rulesObject.adjustCompositeRuleValues();
			this.forceUpdate(() => this.textBoxes[tbn].resetError());
			
			
			// NO force update?
		}
		else
		{
			this.textBoxes[tbn].setErrorValue(tValue); // So we're just setting the UI to the error value, not this.rules!
		}

	} // handleTextChange2



	// From 20211110 Formats live in the VQD's formats.
	// Are they're not created, they're not destroyed, they just move around. (Inspired by Lavoisier)

	moveFormat = (fromColour, index, toColour) =>
	{
		if (fromColour === toColour) return; // D'uh.

		const fromFormats = this.formats[fromColour];
		if (!fromFormats) return;
		if (index < 0 || index >= fromFormats.length) return;

		const toFormats = this.formats[toColour];
		if (!toFormats) return;

		const format = fromFormats[index];

		if (toFormats.indexOf(format) < 0) toFormats.push(format);
		fromFormats.splice(index, 1);

		this.forceUpdate();
		this.rulesObject.adjustCompositeRuleValues();

	}; // moveFormat


	// Ok, sometimes they're added
	// We may need an extra param if we want to add values (Yeah for Strings, you know?)
	addNewFormat = newFormat =>
	{
		this.formats[RAG_COLOURS[2]].push(newFormat);
		this.forceUpdate();
		this.rulesObject.adjustCompositeRuleValues();
	}; // addNewFormat


	getUnlistedColour = () => this.rules[this.getKeyForUnlistedFormatColour()];

	setUnlistedColour = newColour =>
	{
		this.rules[this.getKeyForUnlistedFormatColour()] = newColour;
		this.forceUpdate();
		this.rulesObject.adjustCompositeRuleValues();
	} // setUnlistedColour
	
	
	packageFormatsOrValuesForRender(oneForFormatsAnyThingElseForValues)
	{
		const allFormats = [];
		if(this.formats !== undefined)
		{
			RAG_COLOURS.forEach 
			(
				color =>
				{
					let entries = oneForFormatsAnyThingElseForValues === 1 ? this.formats[color] : this.getStringValues(color);
					if (entries) entries.forEach((id, index) => allFormats.push({id, color, index}));
				}
			);
		}
		return allFormats;

	} // packageFormatsOrValuesForRender


} ////





// loadQualityDefinitionsAndContext
