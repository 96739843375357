// import React from 'react';

import AqaComponent      from "../aqacomponent/AqaComponent"

//const mapperColours = ["orange", "red"];



/** This class provides a backbone for any object that has a read and an amber checkbox.
  * For example TypeMetricRows or Column Headers */

export default class AmberRedCheckers extends AqaComponent
{

	constructor(props)
	{
		super(props);
		this.serial = 0;
		this.state = {checked:props.checks ? props.checks : [false, false]};
	} //

	resetChecks() { this.setState({checked:[false, false]}); }

	getChecks() { return this.state.checked; }

	selectColor(which)
	{


		// event.stopPropagation();

		const {checked} = this.state;
		checked[which] = !checked[which];
		
		/*
		if (this.props.arClicker) this.props.arClicker(checked);
		else this.setState({checked});
		*/

		
		this.setState
		(
			{checked},
			() => { if (this.props.arClicker) this.props.arClicker(this.state.checked); }
		);


// onsole.log(AqaComponent.prettifyJson(event));
// onsole.log("ARC which color: " + which); //  + " status: " + status

//		this.props.controller.raSelect(this.props.th, this.state.checked);

//		if (this.props.arClicker) this.props.arClicker(this.state.checked);
// else onsole.log("I ain't got clickers");

	} // selectColor

	/** To select one, none or two
	  */
	selectColors(amber, red, postop)
	{
		if (this.props.arClicker) this.props.arClicker([amber, red], postop);
		else this.setState
		(
			{checked:[amber, red]},
			postop
		);

		/*
		// This wasn't working as it was causing the object to be rebuilt. Go figure...
		this.setState
		(
			{checked:[amber, red]},
			() => { if (this.props.arClicker) { onsole.log("BUT I AMMMMM trying!!!"); this.props.arClicker(this.state.checked);
			
			 }}
		);
		*/
		
	} // selectColors

} ////



