/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/AqaTableQualityDefinition', '../model/AqaVectorQualityDefinitionTable'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./AqaTableQualityDefinition'), require('./AqaVectorQualityDefinitionTable'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaQualityDefinition = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.AqaTableQualityDefinition, root.ApiDocumentation.AqaVectorQualityDefinitionTable);
  }
}(this, function(ApiClient, AqaTableQualityDefinition, AqaVectorQualityDefinitionTable) {
  'use strict';




  /**
   * The AqaQualityDefinition model module.
   * @module model/AqaQualityDefinition
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaQualityDefinition</code>.
   * @alias module:model/AqaQualityDefinition
   * @class
   */
  var exports = function() {
    var _this = this;












  };

  /**
   * Constructs a <code>AqaQualityDefinition</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaQualityDefinition} obj Optional instance to populate.
   * @return {module:model/AqaQualityDefinition} The populated <code>AqaQualityDefinition</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('originalAccountId')) {
        obj['originalAccountId'] = ApiClient.convertToType(data['originalAccountId'], 'String');
      }
      if (data.hasOwnProperty('originalSourceId')) {
        obj['originalSourceId'] = ApiClient.convertToType(data['originalSourceId'], 'String');
      }
      if (data.hasOwnProperty('originalTimestamp')) {
        obj['originalTimestamp'] = ApiClient.convertToType(data['originalTimestamp'], 'Number');
      }
      if (data.hasOwnProperty('sourceId')) {
        obj['sourceId'] = ApiClient.convertToType(data['sourceId'], 'String');
      }
      if (data.hasOwnProperty('subscriptionId')) {
        obj['subscriptionId'] = ApiClient.convertToType(data['subscriptionId'], 'String');
      }
      if (data.hasOwnProperty('subscriptionQdId')) {
        obj['subscriptionQdId'] = ApiClient.convertToType(data['subscriptionQdId'], 'String');
      }
      if (data.hasOwnProperty('tableQuality')) {
        obj['tableQuality'] = AqaTableQualityDefinition.constructFromObject(data['tableQuality']);
      }
      if (data.hasOwnProperty('timestamp')) {
        obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Number');
      }
      if (data.hasOwnProperty('vectorQualityTable')) {
        obj['vectorQualityTable'] = AqaVectorQualityDefinitionTable.constructFromObject(data['vectorQualityTable']);
      }
    }
    return obj;
  }

  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {String} name
   */
  exports.prototype['name'] = undefined;
  /**
   * @member {String} originalAccountId
   */
  exports.prototype['originalAccountId'] = undefined;
  /**
   * @member {String} originalSourceId
   */
  exports.prototype['originalSourceId'] = undefined;
  /**
   * @member {Number} originalTimestamp
   */
  exports.prototype['originalTimestamp'] = undefined;
  /**
   * @member {String} sourceId
   */
  exports.prototype['sourceId'] = undefined;
  /**
   * @member {String} subscriptionId
   */
  exports.prototype['subscriptionId'] = undefined;
  /**
   * @member {String} subscriptionQdId
   */
  exports.prototype['subscriptionQdId'] = undefined;
  /**
   * @member {module:model/AqaTableQualityDefinition} tableQuality
   */
  exports.prototype['tableQuality'] = undefined;
  /**
   * @member {Number} timestamp
   */
  exports.prototype['timestamp'] = undefined;
  /**
   * @member {module:model/AqaVectorQualityDefinitionTable} vectorQualityTable
   */
  exports.prototype['vectorQualityTable'] = undefined;



  return exports;
}));


