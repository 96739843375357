/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MsContext from "../shared/masterservant/MsContext";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
//import AqaNewSnapshot from "./AqaNewSnapshot";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import AqaSnapshotListRow from "./AqaSnapshotListRow";
import AqaSnapshotOverview from "./AqaSnapshotOverview";
import {ReactComponent as LoadingIcon} from "../../images/grid.svg"
import LineTo from 'react-lineto';
import moment from "moment";
import AqaTable from "../../model/AqaTable";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaSnapshotView extends AqaComponent {
    // No, seriously, we need this.
    static contextType = MsContext;
    constructor(props) {
        super(props);
        props.dataFromParent.registerSnapshotView(this);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            secondary: true,
            redirect: false,
            currentSnapshot: "allsources",
            snapshotsWithOutScores: [],
            snapshotsWithScores: [],
            snapshots: [],
            width: window.innerWidth,
            x1:1,
            x2:2,
            x3:3,
            x4:4,
            x5:5,
            x6:6,
            x7:7,
            x8:8,
            x9:9,
            x10:10,
            height:window.innerHeight-150,
            numRows:1000,
            latestSnapshot:null,
            showHistory:false,
            isIssueDetected:false,
            isDataLoadedOnce:false,
        };
        this.latestSnapshot = null;
        this.handleSnapshot = this.handleSnapshot.bind(this);
        this.showSnapshotOverview = this.showSnapshotOverview.bind(this);
        //this.handleSnapshots(this.props.dataFromGrid.id);
        this.handleDimensions=this.handleDimensions.bind(this);
        this.handleFileUpload=this.handleFileUpload.bind(this);
        this.handleSnapshotReset=this.handleSnapshotReset.bind(this);
        this._isMounted=false;
    }


    registerSnapshot(snapshot)                             { this.latestSnapshot = snapshot; }

    handleSnapshot(snapshotId,snapshotObj){
        this.setState({currentSnapshot:snapshotId,latestSnapshot:snapshotObj});
        this.showSnapshotOverview(snapshotId,snapshotObj);
    }

    showSnapshotOverview(snapshotId,snapshotObj){
        this.context.show(<AqaSnapshotOverview dataFromMainFrame={this.props.dataFromMainFrame} dataAcquired={snapshotId} dataFromSource={this.props.dataFromGrid} dataFromGrid={snapshotObj} dataFromRoot={this} dataFromMain={this.props.dataFromRoot} dataForSnapshots={this.state.snapshots} dataFromParent={this.props.dataFromParent} showHistory={this.props.showHistory} currentHeatMap={this.props.dataFromParent.props.currentHeatMap} />);
    }

    GetDescSortOrder(prop) {
        return function(a, b) {
            if (a[prop] < b[prop]) {
                return 1;
            } else if (a[prop] > b[prop]) {
                return -1;
            }
            return 0;
        }
    }

/*
    handleSnapshotS coreComputation(snapshotId){

        AqaComponent.snapshotBackend.getSnapshotScoreUsingGET(
            snapshotId,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSnapshotView.handleSnapshotS coreComputation, backend call: " + error,this);
                else
                {
                    // we are done here

                }
            }
        );
    }
*/

    findIndexInData(data, property, value) {
        var result = -1;
        data.some(function (item, i) {
            if (item[property] === value) {
                result = i;
                return true;
            }
            return false;
        });
        return result;
    }

	/* ici */
    handleHistoricalScoresWithId(snapshots)
    {
        var score = null;
        var lastScore = null;
        var rag = null;
        var diff="";
        var lastScores = [["Last","Score",{role:'string'},{role:'string'},{role:'string'},{role:'string'},{role:'string'}]];
        var k = 6;
        for (var i = 1; i < 7; i++) {
            var snapshot = snapshots[k - i];
            if (snapshot === null || snapshot===undefined) {
                lastScores.push([i, null, null,null,null,null,null]);
            } else {
                var snapshotObjects = this.props.dataFromParent.state.snapshots;
                var snapshotObject = null;
                for (var l = 0; l < snapshotObjects.length; l++) {
                    var snapshotObj = snapshotObjects[l];
                    if (snapshotObj.id === snapshot.id) {
                        snapshotObject = snapshotObj;
                    }
                }
                var dateCreated = null;
                if(snapshotObject !== null ){
                    dateCreated = snapshotObject.dateCreated;
                }
                let numOverallReds = snapshot.numberOfOrthogonalVectorsWithErrors[0][0][0];
                let numOverallAmbers = snapshot.numberOfOrthogonalVectorsWithErrors[0][0][1];
                let numOverallDiscrete = snapshot.numberOfOrthogonalVectorsWithErrors[0][0][2];
                let rows = snapshot.numberOfRows !== undefined ? snapshot.numberOfRows:this.state.numRows;
                let totalErrors1 = numOverallReds + numOverallAmbers - numOverallDiscrete;
                let totalPercent1 = (totalErrors1/rows)*100;
                lastScores.push([i, totalPercent1, snapshot.rag,numOverallReds,numOverallAmbers,(dateCreated !== null)? (moment(dateCreated ).format("YYYY/MM/DD HH:mm")):(""),rows])
            }
        }
        var latestScoreObj = lastScores[k];
        var lastScoreObj = lastScores[k-1];
        score=latestScoreObj[1];
        rag=latestScoreObj[2];
        lastScore=lastScoreObj[1];
        if(score === undefined || lastScore === undefined){
            diff = "";
        }
        else if(score === null && lastScore === null){
            diff = "";
        }
        else if(score === null && lastScore !== null){
            diff = -(Math.round(lastScore));
        }
        else if(score !== null && lastScore === null){
            if(score <0){
                diff=""
            }
            else {
                diff = Math.round(score);
            }
        }
        else {
            diff = score - lastScore;
            diff = Math.round(diff);
        }
        return {score:score.toFixed(2),lastScore:lastScore,rag:rag,diff:diff,lastScores:lastScores,snapshotScores:snapshots};

    } // handleHistoricalScoresWithId

    handleGetRowsColumns(snapshot)
    {
        this.table = new AqaTable(
            snapshot.id,
            e=> {
                var rows=10000;
                var cols=16;
                if(this.table.data !== undefined){
                    rows=this.table.data.numberOfRows;
                    cols=this.table.data.numberOfColumns;
                }
                this.setState({numRows:rows,numCols:cols});

            }
        )
    } // handleGetRowsColumns

    handleSnapshotScores(sourceId){
        AqaComponent.backend.getScoresUsingGET(
            sourceId,
            {_number:-1},
            (error, data, response) =>
            {
                if (error){
                    //this.reportError("A problem getting the snapshots from the server was encountered.", "AqaSnapshotView.componentDidMount, backend call: " + error,this);
                    console.error("Unable to fetch the scores for "+sourceId+" try again");
                }
                else
                {
                    var snapshotObjs=[];
                    var snapshotsWithOutScores=this.state.snapshotsWithOutScores;
                    var snapsWOSSize=snapshotsWithOutScores.length;
                    var snapsWSSize = data.length;
// onsole.log(snapsWOSSize);
// onsole.log(snapsWSSize);
                    if(snapsWOSSize === snapsWSSize){
                        // both data objects are of same size now merge the two arrays carefully
                        for(var f=0;f<snapsWOSSize;f++){
                        
                            var snapshot = snapshotsWithOutScores[f];

                            if(snapshot !== undefined && snapshot !== null){
                                var snapId = snapshot.id;
                                var indexOfSnapId = this.findIndexInData(data,"id",snapId);
                                var snapscores=data.slice(indexOfSnapId,indexOfSnapId+6); // I guess to keep 6?
                                var history = this.handleHistoricalScoresWithId(snapscores);
                                var snapObj = {...snapshot,...history};
                                snapshotObjs.push(snapObj);
                            }
                        }
                        this.setState({isLoaded:true,snapshots:snapshotObjs,snapshotsWithScores:data});
                        if(snapshotObjs.length>0) {
// onsole.log("Current Snapshot obj");
                            //var currentSnapshot = snapshotObjs[0];
                            //var currentSnapshotScoreObj = currentSnapshot.snapshotScores[0];
                            var totalRules = 100;
                            var total = 5;
                            if(totalRules > 0){
                                var totaldiff = (totalRules / 5) >> 0;
                                var totalreminder = (totalRules % 5);
                                if(totalreminder >=0){
                                    total = (5)*(totaldiff);
                                }
                            }
                            var a1 = (total*0.1);
                            var a2 = (total*0.2);
                            var a3 = (total*0.3);
                            var a4 = (total*0.4);
                            var a5 = (total*0.5);
                            var a6 = (total*0.6);
                            var a7 = (total*0.7);
                            var a8 = (total*0.8);
                            var a9 = (total*0.9);
                            this.setState({x1:a1,x2:a2,x3:a3,x4:a4,x5:a5,x6:a6,x7:a7,x8:a8,x9:a9,x10:total});

                            var latestSnapshot=snapshotObjs[0];
                            for(var l=0;l<snapshotObjs.length;l++){
                                if(snapshotObjs[l].id === this.props.dataFromRoot.state.currentSnapshotId){
                                    latestSnapshot=snapshotObjs[l];
                                }
                            }

                            if(this.state.latestSnapshot === null) {
                                this.setState({latestSnapshot: latestSnapshot,currentSnapshot:latestSnapshot.id});
                                this.handleSnapshot(latestSnapshot.id, latestSnapshot);
                            }


                            this.props.dataFromParent.setSelectedSnapshotId(latestSnapshot.id);
                        }
                    }
                    else{
                        // snapshot Objects are not of the same size as snapshot score objects
                        // At this stage force the score object to calculate before proceeding
                        // rerun the score object computation
// onsole.log("Indefinite Loop in AqaSnapshotView.handleSnapshotScores"); TJ: indefinite? Wow this loop is going to wonder about itself forever!
                        if(data.length>0 && snapsWOSSize>0) {
                            for (var k = 0; k < snapsWOSSize; k++) {
                                snapObj = snapshotsWithOutScores[k];
                                //this.handleSnapshotScoreComputation(snapObj.id);
                            }
                            // I am aware this is not smart but rerun this loop for sanity check
                            //this.handleSnapshotScores(sourceId,null,null);
                        }
                    }
                }
            }

        )
    }

    handleSnapshots(sourceId)
    {
        AqaComponent.backend.getSnapshotsForSourceUsingGET(
            sourceId,
            0,
            9999,
            (error, data, response) =>
            {
                var errorJSON = JSON.parse(response.text);
                if (errorJSON.error === "invalid_token") {
                    this.props.dataFromParent.props.dataFromRoot.popTimeoutDialog2();
                    //this.props.dataFromParent.props.controller.props.dataFromMain.props.parent.logout();
                }
                else {
                    if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                        "AqaSnapshotView.handleSnapshots, backend call: " + error,this);
                    else {
                        if(this._isMounted) {this.setState({snapshotsWithOutScores: data});
                        //if(data.length>0) this.handleGetRowsColumns(data[0]);
                        let allSnapshots = data.filter((d)=>{return d.sheetChosen===true});
                        let pendingSnapshots = data.filter((d)=>{return d.sheetChosen===false});
                        if(data.length === allSnapshots.length) {
                            //alert ("All good");
                            this.setState({isIssueDetected:false,isDataLaodedOnce:true});
                            this.handleSnapshotScores(sourceId);
                        }
                        else {
                            this.setState({isIssueDetected:true});
                            if(pendingSnapshots.length>0){
                                this.showSnapshotOverview(pendingSnapshots[0].id,pendingSnapshots[0]);
                            }
                        }
                        }
                    }
                }
            }
        );
    }

    handleSnapshotReset(){
        this.setState({snapshots:[]});
    }

    async handleRefresh(){
    
// onsole.log("refresh invoked");
        //this.setState({isLoaded:false,snapshots:[]});
        //await this.handleSnapshots(this.props.dataFromGrid.id);
        await this.props.dataFromParent.handleRefresh();
    }
    handleDimensions =(event)=>{
        event.preventDefault();
        this.setState({width:window.innerWidth,height:window.innerHeight-100});
    }

    handleFileUpload(fileUploaded){
        var sourceid = this.props.dataFromGrid.id;
        AqaComponent.backend.uploadFileUsingPOST(
            fileUploaded,
            sourceid,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem posting the  new snapshot from the server was encountered.", "AqaNewSnapshot.handleSnapshotCreation, backend call: " + error,this);
                else
                {
                    //this.props.dataFromParent.handleFileUploadReset();
                    var resp = JSON.parse(response.text);
                    var snapid = resp.snapshotId;
                    var snapname = fileUploaded.name;
                    snapname = snapname.substring(0, snapname.lastIndexOf('.'));
                    var oldSnapshots=this.state.snapshots;
                    var snapshotObjs=[];
                    if(snapid !== null){
                        var date=new Date();
                        var dateCreated = date.getTime();
                        var snapObj = {id:snapid,name:snapname,label:snapname,stats:[],columnmap:null,score:-1,rag:null,status:"Loading",lastScores:[],dateCreated:dateCreated};
                        snapshotObjs.push(snapObj);
                        for(var l=0;l<oldSnapshots.length;l++){
                            snapshotObjs.push(oldSnapshots[l]);
                        }
                        this.setState({snapshots:snapshotObjs})
                        this.handleSnapshot(snapid,snapObj);
                    }
                    //this.handleSnapshots(sourceid,snapid,snapname);
                    /*if(data.length<1){
                        this.setState({error: false,notfound:true});
                    }*/
                }
            }
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.isFileReady === true && nextProps.dataForCheckbox ===true){
            this.handleFileUpload(nextProps.fileUploaded);
            nextProps.dataFromParent.handleFileUploadReset();
        }
        else{
            if(!this.state.isIssueDetected && !this.state.isDataLoadedOnce) this.handleSnapshots(nextProps.dataFromGrid.id);
        }
       // window.addEventListener("resize",this.handleDimensions);
        //window.addEventListener("onload",this.handleDimensions);
        //window.addEventListener("fullScreenChange",this.handleDimensions);
    }

    componentDidMount(){
        this._isMounted=true;
        if(this.props.isFileReady === true){
            this.handleFileUpload(this.props.fileUploaded);
        }
        else{
            if(this.props.dataFromGrid!==undefined) this.handleSnapshots(this.props.dataFromGrid.id);
        }
        window.addEventListener("resize",this.handleDimensions);
        window.addEventListener("onload",this.handleDimensions);
        window.addEventListener("fullScreenChange",this.handleDimensions);
    }

    componentWillUnmount() {
        this._isMounted=false;
        window.removeEventListener("resize",this.handleDimensions);
        window.removeEventListener("onload",this.handleDimensions);
        window.removeEventListener("fullScreenChange",this.handleDimensions);
    }

    render() {
        this.show = this.context.show;
        const { classes } = this.props;
        const { error, isLoaded, snapshots,redirect, notfound,x1,x2,x3,x4,x5,x6,x7,x8,x9,x10,height} = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <div className={classes.demo} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={10} sm={7} lg={11} xl={11}>
                            <Typography variant="title" color="inherit" align="left"
                                        style={{ fontSize: '1.5rem',
                                            padding: '0.1em 0.5em',
                                            fontFamily: 'Open Sans, sans-serif'}}>
                                Nothing to display
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} lg={1} xl={1}>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>

            </div>;
        } else if (!isLoaded) {
            return (<div className={"aqa-loader"}>
                <div className={"aqa-loading-icon"}>
                   <LoadingIcon fill={"#4cadc4"}/>
            </div>
            </div>);
        } else {
            return (
                <div className={this.props.showHistory===true?"source-detail-master-pane":"source-detail-master-pane-one"} style={{height:height-50}}>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            {snapshots.length < 1 ?
                                <Typography className="aqa-text-action-12" variant="inherit"
                                            style={{textAlign: "left", padding: "4px"}}>
                                    The history of your uploaded data will be shown here
                                </Typography> : <>
                                    <div style={{}}>


                                        <Table id={this.props.dataFromGrid.id + "_snaptable"} cellSpacing={0}
                                               aria-sort="ascending" style={{}}>
                                            <colgroup>
                                                <col width="30%"/>
                                                {/*<col width="10%"/>*/}
                                                <col width="7%"/>
                                                <col width="7%"/>
                                                <col width="7%"/>
                                                <col width="7%"/>
                                                <col width="7%"/>
                                                <col width="7%"/>
                                                <col width="7%"/>
                                                <col width="7%"/>
                                                <col width="7%"/>
                                                <col width="7%"/>

                                            </colgroup>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width="30%" style={{"padding": "0px 0px 0px 4px"}}>
                                                        <div className="aqa-text-bold">Alert Row %</div>
                                                    </TableCell>
                                                    {/*<TableCell width="8%" style={{"padding": "0px"}}>
                                                    <div className="aqa-text-bold-12"></div>
                                                </TableCell>*/}
                                                    <TableCell width="7%" style={{"padding": "0px"}}>
                                                        <div className="aqa-text-bold-12">0</div>
                                                    </TableCell>
                                                    <TableCell width="7%" style={{"padding": "0px"}}>
                                                        <div className="aqa-text-bold-12">{x1}</div>
                                                    </TableCell>
                                                    <TableCell width="7%" style={{"padding": "0px"}}>
                                                        <div className="aqa-text-bold-12">{x2}</div>
                                                    </TableCell>
                                                    <TableCell width="7%" style={{"padding": "0px"}}>
                                                        <div className="aqa-text-bold-12">{x3}</div>
                                                    </TableCell>
                                                    <TableCell width="7%" style={{"padding": "0px"}}>
                                                        <div className="aqa-text-bold-12">{x4}</div>
                                                    </TableCell>
                                                    <TableCell width="7%" style={{"padding": "0px"}}>
                                                        <div className="aqa-text-bold-12">{x5}</div>
                                                    </TableCell>
                                                    <TableCell width="7%" style={{"padding": "0px"}}>
                                                        <div className="aqa-text-bold-12">{x6}</div>
                                                    </TableCell>
                                                    <TableCell width="7%" style={{"padding": "0px"}}>
                                                        <div className="aqa-text-bold-12">{x7}</div>
                                                    </TableCell>
                                                    <TableCell width="7%" style={{"padding": "0px"}}>
                                                        <div className="aqa-text-bold-12">{x8}</div>
                                                    </TableCell>
                                                    <TableCell width="7%" style={{"padding": "0px"}}>
                                                        <div className="aqa-text-bold-12">{x9}</div>
                                                    </TableCell>
                                                    <TableCell width="7%"
                                                               style={{"padding": "0px", marginRight: "5px"}}>
                                                        <div className="aqa-text-bold-12"
                                                             style={{marginRight: "10px"}}>{x10}</div>
                                                    </TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {snapshots.map((item, index) => (
                                                    <AqaSnapshotListRow
                                                        key={item.id + "_" + index}
                                                        id={item.id + "_" + index}
                                                        dataFromParentIndex={index}
                                                        dataFromParent={item}
                                                        dataFromRoot={this}
                                                        dataForActive={this.state.currentSnapshot}
                                                        dataFromMain={this.props.dataFromParent}
                                                        dataForCheckbox={this.props.dataForCheckbox}
                                                    />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>


                                    <div>
                                        {this.props.showHistory===true?
                                            (snapshots.map((item, index, arr) => (
                                                (index > 0) ? (
                                                    <div key={index}>
                                                        <LineTo from={"snapshot_" + (index - 1) + "_acpb"}
                                                                to={"snapshot_" + (index) + "_acpb"}
                                                                fromAnchor={"50% 50%"} toAnchor={"50% 50%"}
                                                                borderColor="#707070"/>
                                                    </div>
                                                ) : ('')
                                            ))):null }

                                    </div>
                                </>
                            }




                        </Grid>
                    </Grid>
                </div>
            );
        }
    }
}

AqaSnapshotView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSnapshotView);
