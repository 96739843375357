import React, { Component } from "react";
import AqaComponent from "../aqacomponent/AqaComponent"

import "./TraditionalTd.scss";
import Typography from "@material-ui/core/Typography";
import moment from "moment/moment";
import HtmlTooltip from "@material-ui/core/Tooltip";
//import {ReactComponent as HelpIcon} from "../../../images/aqa-icons/HelpOutline2.svg";
import {ReactComponent as FixIcon} from "../../../images/aqa-icons/Repair2.svg";
import {ReactComponent as IgnoreIcon} from "../../../images/aqa-icons/CheckMark.svg";

// const RAG_COLOURS = ["red","orange","#fff"];

const RAG_STYLES = ["redTd", "amberTd", "greenTd"];
const RAG_STYLES_N = ["redTdN", "amberTdN", "greenTd"];
const RAG_STYLES_M = ["redTdM", "amberTdM", "greenTd"];

class RowStatus
{
	constructor(b) { this.b = b; }

	rag = () => Math.abs(this.b >> 6); // Change if / when bit storage is altered.
	status = () => this.b & 63;


} //// RowStatus

export default class TraditionalTd extends Component
{
//	static #number = 0;
	static #maxCellLengthToShow = 6;

	constructor(props)
	{
		super(props);
		let rs = new RowStatus(props.rs)
		this.state = 
		{
			text:props.text,
			selectedRow:"",
			rag:rs.rag(),
			status:rs.status(),
			os:props.os,
			detectedAlerts:[],
			detectedRag:false
//			bg:"#fff"
		}
		/*
        AQA_PROCESSING_ERROR     The cell may be OK but processing it caused an error in AQA (catch all)
		NO_CODE_AVAILABLE        Internal AQA problem: An error condition has been found yet, no code could be retrieved
		UNEXPECTED_ERROR_CODE    POI, the parser, has returned an inconsistent error code
		_NO_ERROR                Actually there may not be any error
		CIRCULAR_REF             This indicates there is a circular reference in the formula
		DIV0                     This indicates when any number, including zero, is divided by zero.
		FUNCTION_NOT_IMPLEMENTED This indicates the function required is not implemented in POI
		NA                       This indicates when a designated value is not available.
		NAME                     This indicates when what looks like a name is used, but no such name has been defined.
		NULL                     This indicates when two areas are required to intersect, but do not.
		NUM                      This indicates when an argument to a function has a compatible type, but has a value that is outside the domain over which that function is defined.
		REF                      This indicates when a cell reference is invalid.
		VALUE                    This indicates when an incompatible type argument is passed to a function, or an incompatible type operand is used with an operator
		 */

		this.errorCodes = [
			{ordinal:">>>E:NULL<<<",errorString:"#NULL!",errorStringType:"Null Intersection",errorDescription:"Intersection of two cell ranges is empty"},
			{ordinal:">>>E:DIV0<<<",errorString:"#DIV/0!",errorStringType:"Div Zero",errorDescription:"Division by zero"},
			{ordinal:">>>E:VALUE<<<",errorString:"#VALUE!",errorStringType:"Value Invalid",errorDescription:"Wrong type of operand"},
			{ordinal:">>>E:REF<<<",errorString:"#REF!",errorStringType:"Ref Invalid",errorDescription:"Illegal or deleted cell reference"},
			{ordinal:">>>E:NAME<<<",errorString:"#NAME?",errorStringType:"Name Invalid",errorDescription:"Wrong function or range name"},
			{ordinal:">>>E:NUM<<<",errorString:"#NUM!",errorStringType:"Num Error",errorDescription:"Value range overflow"},
			{ordinal:">>>E:NA<<<",errorString:"#N/A",errorStringType:"NA",errorDescription:"Argument or function not available"},
			{ordinal:">>>E:FUNCTION_NOT_IMPLEMENTED<<<",errorString:"#FNI!",errorStringType:"Function Not Implemented",errorDescription:"This indicates the function required is not implemented in POI"},
			{ordinal:">>>E:CIRCULAR_REF<<<",errorString:"#CR!",errorStringType:"Circular Reference",errorDescription:"This indicates there is a circular reference in the formula"},
			{ordinal:">>>E:_NO_ERROR<<<",errorString:"#NO_ERROR!",errorStringType:"NO ERROR",errorDescription:"There may not be an error"},
			{ordinal:">>>E:AQA_PROCESSING_ERROR<<<",errorString:"#APE!",errorStringType:"AQA Processing Error",errorDescription:"The cell may be OK but processing it caused an error in AQA (catch all)"},
			{ordinal:">>>E:NO_CODE_AVAILABLE<<<",errorString:"#NCE!",errorStringType:"No Code Available",errorDescription:"Internal AQA problem: An error condition has been found yet, no code could be retrieved"},
			{ordinal:">>>E:UNEXPECTED_ERROR_CODE<<<",errorString:"#UEC!",errorStringType:"Unexpected Error Code",errorDescription:"The parser, has returned an inconsistent error code"},
		];

	} //

//	componentDidMount() { if (this.props.register) this.props.register(this, this.props.x, this.props.y); }
	componentDidMount() {
		this.handleDescription();
	}

	handleBoundaryAlert = (value,boundary1,boundary2,type)=>{
		let description = "";
		if((boundary1 === null || boundary1 === undefined) && (boundary2 === null || boundary2===undefined)) return description;
		let b1=boundary1.reversed;
		let b2=boundary2.reversed;
		let a=false;let an=0;
		let b=false;let bn=0;
		let c=false;let cn=0;
		let d=false;let dn=0;
		switch (type){
			case "date":
				a=boundary1.reversed?boundary1.useDate2:boundary1.useDate1;
				b=boundary1.reversed?boundary1.useDate1:boundary1.useDate2;
				c=boundary2.reversed?boundary2.useDate1:boundary2.useDate2;
				d=boundary2.reversed?boundary2.useDate2:boundary2.useDate1;
				an=boundary1.reversed?boundary1.date2:boundary1.date1;
				bn=boundary1.reversed?boundary1.date1:boundary1.date2;
				cn=boundary2.reversed?boundary2.date1:boundary2.date2;
				dn=boundary2.reversed?boundary2.date2:boundary2.date1;
				an=an!==null && an!==0?moment(an).format("DD-MMM-YYYY"):"";
				bn=bn!==null && bn!==0?moment(bn).format("DD-MMM-YYYY"):"";
				cn=cn!==null && cn!==0?moment(cn).format("DD-MMM-YYYY"):"";
				dn=dn!==null && dn!==0?moment(dn).format("DD-MMM-YYYY"):"";
				break;
			case "number":
				a=boundary1.reversed?boundary1.useNumber2:boundary1.useNumber1;
				b=boundary1.reversed?boundary1.useNumber1:boundary1.useNumber2;
				c=boundary2.reversed?boundary2.useNumber1:boundary2.useNumber2;
				d=boundary2.reversed?boundary2.useNumber2:boundary2.useNumber1;
				an=boundary1.reversed?boundary1.number2:boundary1.number1;
				bn=boundary1.reversed?boundary1.number1:boundary1.number2;
				cn=boundary2.reversed?boundary2.number1:boundary2.number2;
				dn=boundary2.reversed?boundary2.number2:boundary2.number1;
				break;
			case "string":
				a=boundary1.reversed?boundary1.useNumber2:boundary1.useNumber1;
				b=boundary1.reversed?boundary1.useNumber1:boundary1.useNumber2;
				c=boundary2.reversed?boundary2.useNumber1:boundary2.useNumber2;
				d=boundary2.reversed?boundary2.useNumber2:boundary2.useNumber1;
				an=boundary1.reversed?boundary1.number2:boundary1.number1;
				bn=boundary1.reversed?boundary1.number1:boundary1.number2;
				cn=boundary2.reversed?boundary2.number1:boundary2.number2;
				dn=boundary2.reversed?boundary2.number2:boundary2.number1;
				break;
			default:
				break;
		}
		/*console.log("First Boundary: "+b1);
		console.log("Second Boundary: "+b2);
		console.log("A: "+a+ " Value: "+an);
		console.log("B: "+b+ " Value: "+bn);
		console.log("C: "+c+ " Value: "+cn);
		console.log("D: "+d+ " Value: "+dn);*/
		let amberRanges = [];
		let redRanges = [];
		if(!b1 && b2){
			if(a && b) {redRanges.push(" < "+an+" ");amberRanges.push(" > "+an+" && < "+bn+" ");}
			if(!a && b) {amberRanges.push(" < "+bn+ " ");}
			if(a && !b) {redRanges.push(" < "+an+" ");}
			if(c && d) {redRanges.push(" > "+dn+" ");amberRanges.push(" > "+cn+" && < "+dn+" ");}
			if(!c && d) {redRanges.push(" > "+dn+" ");}
			if(c && !d) {amberRanges.push(" > "+cn+" ");}
		}
		if(b1 && !b2){
			if(a && b) {amberRanges.push(" < "+an+" ");redRanges.push(" > "+an+" && < "+bn+" ");}
			if(!a && b) {redRanges.push(" < "+bn+ " ");}
			if(a && !b) {amberRanges.push(" < "+an+" ");}
			if(c && d) {amberRanges.push(" > "+dn+" ");redRanges.push(" > "+cn+" && < "+dn+" ");}
			if(!c && d) {amberRanges.push(" > "+dn+" ");}
			if(c && !d) {redRanges.push(" > "+cn+" ");}
		}
		if(amberRanges.length>0 && redRanges.length>0) description = "Amber: "+amberRanges.toString()+" and Red: "+redRanges.toString();
		if(amberRanges.length>0 && redRanges.length===0) description = "Amber: "+amberRanges.toString();
		if(amberRanges.length===0 && redRanges.length>0) description = "Red: "+redRanges.toString();
		return description;
	}

	handleRuleAlert = (value,ragName,rule,checkType,isNotAllowed)=>{
		if(rule === null || rule === undefined) return "";
		let rType = rule.stringEnabled === true ? "string" : (rule.numberEnabled === true ? "number" : (rule.dateEnabled === true ? "date" : ""));
		let pType = rule.stringEnabled === true ? "String" : (rule.numberEnabled === true ? "Number" : (rule.dateEnabled === true ? "Date" : ""));
		if(rType === "") return "Unable to detect value type";
		let description = checkType;
		let boundary1 = null;
		let boundary2 = null;
		let selectedFormats = null;
		let ret="";
		switch (checkType) {
			case "stringLength":
				boundary1 = rule[`${rType.toLowerCase()}LengthBoundary1`];
				boundary2 = rule[`${rType.toLowerCase()}LengthBoundary2`];
				ret = this.handleBoundaryAlert(value,boundary1,boundary2,rType);
				description = {description:"String length range defined as ",range:ret};
				break;
			case "stringAllowed":
				let selectedValues = rule[`${ragName.toLowerCase()}StringValues`];
				description = {description:"Value "+value+ " is detected in one of the "+ragName+" formats "+selectedValues.toString().replaceAll(",",", ")+" which is "+(isNotAllowed?"not":"")+" allowed",range:""};
				break;
			case "stringFormat":
				selectedFormats = rule[`${ragName.toLowerCase()}${pType}Formats`];
				description = {description:"Value "+value+ " is detected in one of the "+ragName+" formats "+selectedFormats.toString().replaceAll(",",", ")+" which is "+(isNotAllowed?"not":"")+" allowed",range:""};
				break;
			case "numberBoundary":
				boundary1 = rule[`${rType.toLowerCase()}Boundary1`];
				boundary2 = rule[`${rType.toLowerCase()}Boundary2`];
				ret = this.handleBoundaryAlert(value,boundary1,boundary2,rType);
				description = {description:"Numeric value range defined as ",range:ret};
				break;
			case "numberFormat":
				selectedFormats = rule[`${ragName.toLowerCase()}${pType}Formats`];
				description = {description:"Value "+value+ " is detected in one of the "+ragName+" formats "+selectedFormats.toString().replaceAll(",",", ")+" which is "+(isNotAllowed?"not":"")+" allowed",range:""};
				break;
			case "dateBoundary":
				boundary1 = rule[`${rType.toLowerCase()}Boundary1`];
				boundary2 = rule[`${rType.toLowerCase()}Boundary2`];
				ret = this.handleBoundaryAlert(value,boundary1,boundary2,rType);
				description = {description:"Date range defined as ",range:ret};
				break;
			case "dateFormat":
				selectedFormats = rule[`${ragName.toLowerCase()}${pType}Formats`];
				description = {description:"Value "+value+ " is detected in one of the "+ragName+" formats "+selectedFormats.toString().replaceAll(",",", ")+" which is "+(isNotAllowed?"not":"")+" allowed",range:""};
				break;
			default:
				break;
		}
		return description;
	}

	handleAlerts = (rags,value,rule)=>{
		//console.log(rule);
		let alerts=[];
		let detectedRag = true;
		if(rags === null || rags === undefined) return alerts;
		let overall = rags['overall'];
		let populated = rags['populated'];
		let uniqueness = rags['uniqueness'];
		let type = rags['type'];
		let nativeError = rags['nativeError'];
		let stringFormat = rags['stringFormat'];
		let stringAllowed = rags['stringAllowed'];
		let stringLength = rags['stringLength'];
		let dateFormat = rags['dateFormat'];
		let dateBoundary = rags['dateBoundary'];
		let numberFormat = rags['numberFormat'];
		let numberBoundary = rags['numberBoundary'];
		let ragName = overall===1?"Amber":(overall===0?"Red":"Green");
		let alert = {
			alertType: "Default value",
			description: "Cell with error",
			longDescription:"",
			alertCode:"K",
			alertRag: ragName
		};
		if(overall===2) {
			detectedRag = false;
			if(rule[`populatedEnabled`]){
				alert = {
					alertType: "Populated - Yes",
					description: "",
					longDescription:"",
					alertCode:"M",
					alertRag: ragName
				};
				alerts.push(alert);
			}
			if(rule[`uniquenessEnabled`]){
				alert = {
					alertType: "Unique - Yes",
					description: "",
					longDescription:"",
					alertCode:"D",
					alertRag: ragName
				};
				alerts.push(alert);
			}
			if(rule[`typeEnabled`]){
				alert = {
					alertType: "Data Type - Valid",
					description: "",
					longDescription:"",
					alertCode:"T",
					alertRag: ragName
				};
				alerts.push(alert);
			}
			if(this.props.content.type ==="string"){
				if(rule[`stringEnabled`]){
					if(rule[`useStringLengths`]){
						alert = {
							alertType: "Outlier - No",
							description: "",
							longDescription:"",
							alertCode:"O",
							//longDescription:this.handleRuleAlert(value,ragName,rule,"stringLength",false),
							alertRag: ragName
						};
						alerts.push(alert);
					}
					if(rule[`useStringFormats`]){
						alert = {
							alertType: "Formatting - Good",
							description: "",
							longDescription:"",
							alertCode:"F",
							//longDescription:this.handleRuleAlert(value,ragName,rule,"stringFormat",false),
							alertRag: ragName
						};
						alerts.push(alert);
					}
					if(rule[`useAllowedValues`]){
						alert = {
							alertType: "Valid - Yes",
							description: "",
							longDescription:"",
							alertCode:"I",
							//longDescription:this.handleRuleAlert(value,ragName,rule,"stringAllowed",false),
							alertRag: ragName
						};
						alerts.push(alert);
					}
				}
			}
			if(this.props.content.type ==="number"){
				if(rule[`numberEnabled`]){
					if(rule[`useNumberBoundaries`]){
						alert = {
							alertType: "Outlier - No",
							description: "",
							longDescription:"",
							alertCode:"O",
							//longDescription:this.handleRuleAlert(value,ragName,rule,"numberBoundary",false),
							alertRag: ragName
						};
						alerts.push(alert);
					}
					if(rule[`useNumberFormats`]){
						alert = {
							alertType: "Formatting - Good",
							description: "",
							longDescription:"",
							alertCode:"F",
							//longDescription:this.handleRuleAlert(value,ragName,rule,"numberFormat",false),
							alertRag: ragName
						};
						alerts.push(alert);
					}
				}
			}
			if(this.props.content.type ==="date"){
				if(rule[`dateEnabled`]){
					if(rule[`useDateFormats`]){
						alert = {
							alertType: "Formatting - Good",
							description: "",
							longDescription:"",
							alertCode:"F",
							//longDescription:this.handleRuleAlert(value,ragName,rule,"dateFormat",false),
							alertRag: ragName
						};
						alerts.push(alert);
					}
					if(rule[`useDateBoundaries`]){
						alert = {
							alertType: "Outlier - No",
							description: "",
							longDescription:"",
							alertCode:"O",
							//longDescription:this.handleRuleAlert(value,ragName,rule,"dateBoundary",false),
							alertRag: ragName
						};
						alerts.push(alert);
					}
				}
			}
			this.setState({detectedAlerts:alerts});
			return alerts;
		}

		if(nativeError<2){
			ragName = nativeError===1?"Amber":(nativeError===0?"Red":"Green");
			let errorCell = this.handleErrorCodeDescription(value);
			alert = {
				alertType: errorCell!==""?errorCell.errorStringType:"Excel Error",
				description: errorCell!==""?errorCell.errorDescription:"Cell with Native Excel Error",
				longDescription:"",
				alertCode:"N",
				alertRag: ragName
			};
			alerts.push(alert);
		}
		if(type<2){
			ragName = type===1?"Amber":(type===0?"Red":"Green");
			alert = {
				alertType: "Maybe Data Type mismatch",
				description: "Entered value is an incorrect type",
				longDescription:"",
				alertCode:"T",
				alertRag: ragName
			};
			alerts.push(alert);
		}
		if(populated<2){
			ragName = populated===1?"Amber":(populated===0?"Red":"Green");
			alert = {
				alertType: "Missing Data",
				description: "Cell with no Data",
				longDescription:"",
				alertCode:"M",
				alertRag: ragName
			};
			alerts.push(alert);
		}
		if(uniqueness<2){
			ragName = uniqueness===1?"Amber":(uniqueness===0?"Red":"Green");
			alert = {
				alertType: "Maybe Duplicate Data",
				description: "Cell with duplicate data",
				longDescription:"",
				alertCode:"D",
				alertRag: ragName
			};
			alerts.push(alert);
		}
		// String
		if(stringLength<2){
			ragName = stringLength===1?"Amber":(stringLength===0?"Red":"Green");
			alert = {
				alertType: "Outlier",
				description: "String length appears to be out of range in comparison to other Strings",
				longDescription:"",
				alertCode:"O",
				//longDescription:this.handleRuleAlert(value,ragName,rule,"stringLength",true),
				alertRag: ragName
			};
			alerts.push(alert);
		}
		if(stringAllowed<2){
			ragName = stringAllowed===1?"Amber":(stringAllowed===0?"Red":"Green");
			alert = {
				alertType: "Text maybe Invalid",
				description: "Text is invalid",
				longDescription:"",
				alertCode:"I",
				//longDescription:this.handleRuleAlert(value,ragName,rule,"stringAllowed",true),
				alertRag: ragName
			};
			alerts.push(alert);
		}
		if(stringFormat<2){
			ragName = stringFormat===1?"Amber":(stringFormat===0?"Red":"Green");
			alert = {
				alertType: "Maybe non conforming data format",
				description: "Cell appears to be using an incorrect format",
				longDescription:"",
				alertCode:"F",
				//longDescription:this.handleRuleAlert(value,ragName,rule,"stringFormat",true),
				alertRag: ragName
			};
			alerts.push(alert);
		}
		// Number
		if(numberBoundary<2){
			ragName = numberBoundary===1?"Amber":(numberBoundary===0?"Red":"Green");
			alert = {
				alertType: "Outlier",
				description: "Number appears to be out of range in comparison to other numbers",
				longDescription:"",
				alertCode:"O",
				//longDescription:this.handleRuleAlert(value,ragName,rule,"numberBoundary",true),
				alertRag: ragName
			};
			alerts.push(alert);
		}
		if(numberFormat<2){
			ragName = numberFormat===1?"Amber":(numberFormat===0?"Red":"Green");
			alert = {
				alertType: "Maybe non conforming data format",
				description: "Cell appears to be using an incorrect format",
				longDescription:"",
				alertCode:"F",
				//longDescription:this.handleRuleAlert(value,ragName,rule,"numberFormat",true),
				alertRag: ragName
			};
			alerts.push(alert);
		}
		// Date
		if(dateBoundary<2){
			ragName = dateBoundary===1?"Amber":(dateBoundary===0?"Red":"Green");
			alert = {
				alertType: "Outlier",
				description: " Date appears to be out of range in comparison to other dates",
				longDescription:"",
				alertCode:"O",
				//longDescription:this.handleRuleAlert(value,ragName,rule,"dateBoundary",true),
				alertRag: ragName
			};
			alerts.push(alert);
		}
		if(dateFormat<2){
			ragName = dateFormat===1?"Amber":(dateFormat===0?"Red":"Green");
			alert = {
				alertType: "Maybe non conforming data format",
				description: "Cell appears to be using an incorrect format",
				longDescription:"",
				alertCode:"F",
				//longDescription:this.handleRuleAlert(value,ragName,rule,"dateFormat",true),
				alertRag: ragName
			};
			alerts.push(alert);
		}
		this.setState({detectedAlerts:alerts,detectedRag:detectedRag});
		return alerts;
	}

	handleDescriptionText(){
		let notesObj = this.props.content!==undefined?this.props.content.note:"";
		let notes = notesObj!== undefined && notesObj!==""?notesObj:null;
		let text = this.props.text;
		let reviewStatus ="";
		let os = this.props.os;
		if(notes!==null){
			let personCache = this.props.parent.props.controller.personCache;
			if(os !== undefined && os === 1) reviewStatus=<div>
				<Typography color="inherit" className="aqa-text-action"><b>Review Status: </b><b>IGNORE</b></Typography>
				<Typography color="inherit" className="aqa-text-action"><b>Comment: </b>{notes.note}</Typography>
				<Typography color="inherit" className="aqa-text-action"><b>Updated: </b>{moment(notes.noteDate).fromNow()} </Typography>
				<Typography color="inherit" className="aqa-text-action"><b>By: </b> {personCache!==null?personCache[notes.noteEditorId]:""}</Typography>
			</div>;
			if(os !== undefined && os === 2) reviewStatus=<div>
				<Typography color="inherit" className="aqa-text-action"><b>Review Status: </b><b>FIX</b></Typography>
				<Typography color="inherit" className="aqa-text-action"><b>Comment: </b>{notes.note}</Typography>
				<Typography color="inherit" className="aqa-text-action"><b>Updated: </b>{moment(notes.noteDate).fromNow()}</Typography>
				<Typography color="inherit" className="aqa-text-action"><b>By: </b> {personCache!==null?personCache[notes.suggestionEditorId]:""}</Typography>
				<Typography color="inherit" className="aqa-text-action" style={{paddingTop:"10px"}}><b>Suggested Value: </b> {notes.suggestion}</Typography>
				<Typography color="inherit" className="aqa-text-action"><b>Updated: </b> {moment(notes.suggestionDate).fromNow()} </Typography>
				<Typography color="inherit" className="aqa-text-action"><b> By: </b> {personCache!==null?personCache[notes.suggestionEditorId]:""}</Typography>
			</div>;
		}
		else{
			if(os !== undefined && os === 1) reviewStatus=<div>
				<Typography color="inherit" className="aqa-text-action"><b>Review Status: </b><b>IGNORE</b></Typography>
			</div>;
			if(os !== undefined && os === 2) reviewStatus=<div>
				<Typography color="inherit" className="aqa-text-action"><b>Review Status: </b><b>FIX</b></Typography>
			</div>;
		}
		let detectedAlerts = this.state.detectedAlerts;
		let detectedRag = this.state.detectedRag;
		return <div>
			<Typography color="inherit" className="aqa-text-action"><b>{"Value: "}</b>{text==="" || text===null || text===undefined?"Blank":(text.indexOf(">>>E:")>=0?this.handleErrorCode(text):text)}</Typography>
			{reviewStatus!==""?reviewStatus:""}
			<div style={{paddingTop:"10px"}}>{
				detectedRag?(
					<div>
					{detectedAlerts.map((item,index)=>
			<div key={"al"+index} style={{paddingBottom:"10px"}}>
				<Typography color="inherit" className="aqa-text-action"><b>{item.alertRag} Alert </b> - {item.alertType}</Typography>
				<Typography color="inherit" className="aqa-text-action">{item.description}</Typography>
				<Typography color="inherit" className="aqa-text-action">{item.longDescription!==""?item.longDescription.description:""}</Typography>
				<Typography color="inherit" className="aqa-text-action">{item.longDescription!==""?item.longDescription.range:""}</Typography>


			</div>)}
					</div>):(
				<div>
						<Typography color="inherit" className="aqa-text-action"><b>Green Alert </b></Typography>
					{detectedAlerts.map((item,index)=>
						<div key={"al"+index} style={{paddingBottom:"0px"}}>
							<Typography color="inherit" className="aqa-text-action">{item.alertType}</Typography>

						</div>)}
						</div>
					)
			}
			</div>
			</div>;
	}

	handleDescription(){
		if(this.props.parent === undefined) return;
		if(this.props.parent.props.controller === undefined) return;
		let currentState = this.props.parent.props.controller.state;
		//let oPos = this.props.originalPosition;
		let column = this.props.x;
		let value = this.props.text;
		let rules = currentState.savedRules.columnQualities;
		let currentRule = rules.length>0?rules[column]:null;
		let rags = this.props.rags;
		//console.log(oPos+" - "+column);
		//console.log(rags);
		if(column!==undefined) {
			this.handleAlerts(rags, value, currentRule);
			//if(detectedAlerts.length>0) console.log(detectedAlerts);
		}
	}

	handleErrorCode(text){
		if(text==="" || text === null || text===undefined) return "";
		if(text.indexOf(AqaComponent.ELLIPSIS)>=0){
			text = text.replace(AqaComponent.ELLIPSIS,"");
		}
		let errorCodes = this.errorCodes.filter((d)=>{return d.ordinal === text || d.ordinal.indexOf(text)>=0});
		if(errorCodes.length>0){
			return errorCodes[0].errorString;
		}
		return "";
	}

	handleErrorCodeDescription(text){
		if(text==="" || text === null || text===undefined) return "";
		let errorCodes = this.errorCodes.filter((d)=>{return d.ordinal === text});
		if(errorCodes.length>0){
			return errorCodes[0];
		}
		return "";
	}

	handleSelectedStatus = (status) =>{
		this.setState({os:status});
	}

	handleClick(e){
		this.handleDescription();
		let authCode = this.props.parent.props.controller.props.dataFromMain.props.parent.state.authCode;
		let adminEmail = this.props.parent.props.controller.props.dataFromMain.props.parent.state.adminEmail;
		if(authCode === "V") {
			this.props.parent.handlePermission(adminEmail);
			return;
		}
		if(this.props.rag<=2 && this.props.header!==true){
		e.preventDefault();
		e.stopPropagation();
		AqaComponent.touch();
		//console.log(this.props);
			if (e.ctrlKey){
				if(this.props.parent.props.controller.state.selectedRow!==null && this.props.parent.props.controller.state.selectedRow.props.originalPosition===this.props.originalPosition && this.props.parent.props.controller.state.selectedRow.props.x===this.props.x){
					this.props.parent.props.controller.handleSelectedRow(null,"");
					this.setState({selectedRow:null})
				}
				else{this.props.parent.props.controller.handleSelectedRow(this,this.props.x);
					this.setState({selectedRow:this.props.originalPosition});
				}
			}
			else {
				if(this.props.parent.props.controller.state.selectedRow!==null && this.props.parent.props.controller.state.selectedRow.props.originalPosition===this.props.originalPosition  && this.props.parent.props.controller.state.selectedRow.props.x===this.props.x){
					this.props.parent.props.controller.handleSelectedRow(null,"");
					this.setState({selectedRow:null})
				}
				else{this.props.parent.props.controller.handleSelectedRow(this,this.props.x);
				this.setState({selectedRow:this.props.originalPosition});
				}
			}
		}
	}

	render()
	{
		const attrs = {};
		let text = this.state.text;
		let type = this.props.content!==undefined?this.props.content.type:"string";
		let rag = this.props.rag;
		let hasEllipses=false;
		let hasRs = false;
		let rs;
		hasRs = "rs" in this.props;
		if (hasRs)
		{
			//console.log(this.props);
			rs = new RowStatus(this.props.rs);
			//onsole.log(rs.gravity());
			//attrs.className = RAG_STYLES[rs.rag()];

			//onsole.log("rag: "+ rs.rag());

		}
		if (this.props.header)
		{
			attrs.style={fontWeight:"bold"};
		}
		else
		{
		
// onsole.log("rag: " + this.props.rag + ": " + (RAG_COLOURS[this.props.rag]));

if (!this.props.parent) return <></>;


			let controller = this.props.parent.props.controller;
			let selectedColumnIndex = controller.state.sColumnId;
			let selectedRow = controller.state.selectedRow;
			let selectedType = controller.state.selectedType;
			let finerGrain = controller.state.finerGrain;
			let selectedColumnId = controller.state.selectedColumnIndex;
			let selectedColor = controller.state.selectedColor;
			let reviewType = controller.state.selectedReviewType;
			let colorToDecode = selectedColor==="Red" || selectedColor==="RedGreen"?0:(selectedColor==="Amber"?1:(selectedColor==="RedAmber"?3:2));

			//console.log(controller.state);
			//console.log(this.props);
			//console.log(selectedType + " - "+selectedColumnId + " "+ selectedColor);
			reviewType = reviewType===undefined?-1:reviewType;
			selectedType = selectedType===""?"overall":selectedType;
			if(hasRs && this.props.os===1) rag = 2;
			if(hasRs && rag ===2 && this.props.os===2) rag = 0;
			// Filter rag specific colours for the specific type and column
			let special = selectedColor==="RedAmberGreen"?false:true;
			if(((selectedType!=="")||(selectedColumnId!==-1 && selectedColumnId!=="-1" && selectedColumnId!==null) || (finerGrain>=0)) && special ) {
				if(selectedColumnId !== null && selectedColumnId!==-1 && selectedColumnId!=="-1"){
					//console.log("I am in column selection");
					if(selectedColumnId === this.props.x) {
						if (hasRs && (rs.status() === 1 || rs.status() === 2) && rag < 2) attrs.className = RAG_STYLES_M[rag] + " "+RAG_STYLES_M[rag]+"s";
						else attrs.className = RAG_STYLES_M[rag] + " "+RAG_STYLES_M[rag]+(rag===2?"Ms":"s");
					}
					else{
						if (hasRs && (rs.status() === 1 || rs.status() === 2) && rag < 2) attrs.className = RAG_STYLES_N[rag];
						else attrs.className = RAG_STYLES[rag];
					}
				}
				else{
					//console.log("I am in no column state "+finerGrain);
					let ragN = rag;
					let detected = false;
					let rags=this.props.rags;
					let cGrain = selectedType;
					if(finerGrain >=0){
						//console.log("Filter the specifics");
						cGrain=finerGrain===1 && type ==="string"?"stringLength":
							(finerGrain===1 && (type ==="number" || type === "date")?`${type}Boundary`:
								(finerGrain === 0?`${type}Format`:
									(finerGrain === 2?"stringAllowed":"")));
					}
					else{
						cGrain=selectedType==="overall" || selectedType==="any" || selectedType ===""?"overall":(selectedType==="population"?"populated":selectedType);
						//console.log("General Filter "+selectedType);
					}
					if(cGrain!=="") {
						ragN=rags!==undefined?rags[`${cGrain}`]:2;
						if((selectedType === "overall" || selectedType==="any") && ragN === 0 && this.state.detectedAlerts.length>1){
							//console.log(this.state.detectedAlerts);
							//console.log("I am in1");
							detected = true;
						}
						if((selectedType === "overall" || selectedType==="any") && ragN === 2 && this.props.os===2 && finerGrain===-1){
							//console.log(this.state.detectedAlerts);
							//console.log("I am in2");
							detected = true;
						}
						if(colorToDecode === 3 && (reviewType === -1 || (reviewType!==-1 && reviewType === this.props.os))){
							if(ragN<2) {
								//console.log("I am in3");
								detected=true;
							}
						}
						if(ragN===colorToDecode && (reviewType === -1 || (reviewType!==-1 && reviewType === this.props.os))) {
							//console.log("I am in4");
							detected=true;
						}
						if(hasRs && this.props.os===1) ragN = 2;
						if(hasRs && ragN ===2 && this.props.os===2) ragN = 0;
					}
					//console.log(this.props.y+" "+this.props.x+" "+selectedType+ " "+cGrain + " "+ detected+" "+ragN +" "+ colorToDecode + " "+reviewType +" "+ this.props.os);
					if(detected){
						if (hasRs && (rs.status() === 1 || rs.status() === 2) && ragN < 2) attrs.className = RAG_STYLES_M[ragN] + " "+RAG_STYLES_M[ragN]+"s";
						else attrs.className = RAG_STYLES_M[ragN] + " "+RAG_STYLES_M[ragN]+(ragN===2?"Ms":"s");
					}
					else {
						if (hasRs && (rs.status() === 1 || rs.status() === 2) && ragN < 2) attrs.className = RAG_STYLES_N[rag];
						else attrs.className = RAG_STYLES[rag];
					}
				}
				//console.log(this.props.rags);
				//rag =2;


				if (selectedRow !== null && selectedRow.props.originalPosition === this.props.originalPosition && this.props.x === selectedColumnIndex) {
					//if(hasRs && this.props.os===1) rag = 2;
					//if(hasRs && rag ===2 && this.props.os===2) rag = 0;
					if (hasRs && (rs.status() === 1 || rs.status() === 2) && rag < 2) attrs.className = RAG_STYLES_M[rag] + " " + RAG_STYLES_M[rag] + "s "+ RAG_STYLES_M[rag] + "sel";
					else attrs.className = RAG_STYLES[rag] + " " + RAG_STYLES[rag] + "s "+ RAG_STYLES_M[rag] + "sel";
				}
			}
			else {
				//console.log("Iam out of this");
				if (hasRs && rs.status() === 1 && rag < 2) attrs.className = RAG_STYLES_N[rag];
				else attrs.className = RAG_STYLES[rag];

				if (selectedRow !== null && selectedRow.props.originalPosition === this.props.originalPosition && this.props.x === selectedColumnIndex) {
					//if(hasRs && this.props.os===1) rag = 2;
					//if(hasRs && rag ===2 && this.props.os===2) rag = 0;
					if (hasRs && (rs.status() === 1 || rs.status() === 2) && rag < 2) attrs.className = RAG_STYLES_N[rag] + " " + RAG_STYLES[rag] + "s";
					else attrs.className = RAG_STYLES[rag] + " " + RAG_STYLES[rag] + "s";
				}
			}

//			attrs.style = {backgroundColor:RAG_COLOURS[this.props.rag]}; // this.state.bg


// onsole.log("class: " + this.props.rag + ": " + attrs.className);


			const gWidthAsBits = AqaComponent.getStringPixelWidthCharByChar(text);
			const gwidth = AqaComponent.correctStringWidth(AqaComponent.sumUpArray(gWidthAsBits));

			if (gwidth > this.props.width)
			{
				// Houston, we have a large problem

				let numberOfChars = Math.floor(this.props.width / AqaComponent.averageCharWidth);
				
				let gw = 0;
				for(let i = 0; i < numberOfChars; i++) gw += gWidthAsBits[i];

				gw = AqaComponent.correctStringWidth(gw); // and yes, it's the last correction we'll do here.

				if (gw > this.props.width)
				{
					numberOfChars--;
					while(numberOfChars > 1)
					{
						gw -= gWidthAsBits[numberOfChars];
						if (gw < this.props.width) break;
						numberOfChars--;
					}
				
				}
				else
				{
					const l = text.length;
					while(numberOfChars < l)
					{
						gw += gWidthAsBits[numberOfChars];
						if (gw > this.props.width) break;
						numberOfChars++;
					}
				}
				text = text.substring(0, numberOfChars-2) + AqaComponent.ELLIPSIS;
				hasEllipses=true;
				//attrs.title = this.state.text;
			}
		}
		if(rag<=2 || hasEllipses){
			return (
				<HtmlTooltip enterDelay={1000} title={<React.Fragment>
					{this.handleDescriptionText()}

				</React.Fragment>} arrow={"true"} placement="bottom-start">
					<div {...attrs} style={{backgroundColor:this.props.selectedRow===this.props.originalPosition && rag>1?"#fcf4dd":(hasRs && rs.status()!==0 && rag>1?"rgb(204, 238, 255)":"")}} onContextMenu={(e) => this.handleClick(e)} onClick={(e)=>this.handleClick(e)}>
						{
							hasRs && rs.rag() !== 2 && this.props.header===true
								?
								<span style={{paddingLeft:"5px",marginTop:"2px"}}>{(
									this.state.status===0?null:
										(this.state.status===1?<IgnoreIcon width="22px" height="22px" style={{width:"22px",paddingTop:"1px",marginTop:"-6px"}} />:
											(this.state.status===2?<FixIcon width="22px" height="22px" style={{width:"22px",paddingTop:"1px",marginTop:"-3px"}} />:null))
								)}
					</span>
								:
								null
						}
						<span style={{textAlign:type==="number"|| this.props.header===true?"right":"left",width:this.props.header===true?"":this.props.width-14,display:type==="number"?"inline-block":"",float:this.props.header===true?"right":"",paddingRight:this.props.header===true?"10px":""}}>{text.indexOf(">>>E")>=0?this.handleErrorCode(text):text}</span><span style={{float:"right"}}>{(
						this.state.os===0 || this.props.header===true?null:
							(this.state.os===1?<IgnoreIcon width="22px" height="22px" style={{width:"20px",height:"20px",marginLeft:"-22px",marginTop:"-3px"}} />:
								(this.state.os===2?<FixIcon width="22px" height="22px" style={{width:"20px",height:"20px",marginLeft:"-22px",marginTop:"-3px"}} />:null))
					)}</span></div>
				</HtmlTooltip>
			);
		}
		else{ return <div {...attrs} style={{backgroundColor:this.props.selectedRow===this.props.originalPosition && rag>1?"#fcf4dd":(hasRs && rs.status()===1 && rag>1?"rgba(193, 225, 193,.5)":(hasRs && rs.status()===2 && rag>1?"rgb(204, 238, 255)":""))}} onContextMenu={(e) => this.handleClick(e)} onClick={(e)=>this.handleClick(e)}>
			{
				hasRs && this.props.header===true
					?
					<span style={{paddingLeft:"5px",marginTop:"2px"}}>{(
						this.state.status===0?null:
							(this.state.status===1?<IgnoreIcon width="22px" height="22px" style={{width:"22px",paddingTop:"1px",marginTop:"-6px"}} />:
								(this.state.status===2?<FixIcon width="22px" height="22px" style={{width:"22px",paddingTop:"1px",marginTop:"-3px"}} />:null))
					)}
					</span>
					:
					null
			}

			{this.props.header===true?<span style={{float:"right",paddingRight:"10px"}}>{text.indexOf(">>>E:")>=0?this.handleErrorCode(text):text}</span>
				:<span>
					{this.state.os === 0?<span style={{textAlign:type==="number"?"right":"left",width:this.props.width-14,display:type==="number"?"inline-block":""}}>{text.indexOf(">>>E:")>=0?this.handleErrorCode(text):text}</span>
						:<HtmlTooltip enterDelay={1000} title={<React.Fragment>
							{this.handleDescriptionText()}

						</React.Fragment>} arrow={"true"} placement="bottom-start"><div>
					<span style={{textAlign:type==="number"?"right":"left",width:this.props.width-14,display:type==="number"?"inline-block":""}}>{text.indexOf(">>>E:")>=0?this.handleErrorCode(text):text}</span>
					<span style={{float:"right"}}>{(
				this.state.os===0?null:(
					(this.state.os===1?<IgnoreIcon width="22px" height="22px" style={{width:"20px",height:"20px",marginLeft:"-22px",marginTop:"-3px"}} />:
						(this.state.os===2?<FixIcon width="22px" height="22px" style={{width:"20px",height:"20px",marginLeft:"-22px",marginTop:"-3px"}} />:null))
				))}</span>
						</div></HtmlTooltip>}
				</span>
			}</div>;
		}




	} // render

} ////


/*

	render()
	{
		const attrs = {};
		let text = this.state.text;
		
		if (this.props.header)
		{
			attrs.style={fontWeight:"bold"};
		}
		else
		{
		
			attrs.className = RAG_STYLES[this.props.rag];

			if (text.length > TraditionalTd.#maxCellLengthToShow)
			{
				attrs.title=this.state.text;
				text = text.substring(0, TraditionalTd.#maxCellLengthToShow) + "...";
			}
		}

		return <div {...attrs}>{text}</div>;

	} // render


*/
