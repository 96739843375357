import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import AqaRulesParent from "../AqaRulesParent";
import {withStyles} from "@material-ui/core/styles";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

//  extends AqaRulesParent
const styles = theme => ({
	root: {
		maxWidth: 345,
		margin: "8px",
	},
});
class AqaRulesRadioButton extends Component
{

	constructor(props)
	{
		super(props);
		this.state={color:"green"}
	} //

	handleRadioChange3 = event =>
	{
		var oldColor = this.state.color;
		this.setState({[event.target.name]: event.target.value});
		var index= this.props.dataAcquired.index;
		var value = this.props.dataAcquired.id;
		
//		if (this.props.dataForType === "String")
		if (this.props.dataForType === "values")
		{
			this.props.parent.removeValue(oldColor, index, false);
			this.props.parent.addValue(event.target.value, value);
		}
		else
		{
			this.props.parent.moveFormat(oldColor,index,event.target.value);
		}

		
	}; // handleRadioChange3

	// TODO: get rid of it!!!!!!!!!!!
	UNSAFE_componentWillReceiveProps(nextProps, nextContext)
	{
		this.setState({color:nextProps.dataForColor});
	}

	componentDidMount() { this.setState({color:this.props.dataForColor}); }

	render()
	{
		const {color} = this.state;
		return (
			<RadioGroup aria-label="color" name="color" value={color} color="primary" onChange={this.handleRadioChange3} row={true}>
				<FormControlLabel
					value="green"
					control={<Radio color="primary"
					style={{padding:"2px 2px 2px 6px"}}/>}
					label={
						(
							<Typography
								className={"aqa-text-action"}
								variant="title"
								align="left"
								style={{padding:"0px" }}
							>
								<span style={{float:"left",width:"80px"}}></span>
							</Typography>
						)
					}
					style={{width:"136px",margin:"0px"}}
				/>
				<FormControlLabel
					value="amber"
					control={<Radio color="primary" style={{padding:"2px 2px 2px 6px"}} />}
					label={
						(
							<Typography
								className={"aqa-text-action"}
								variant="title" align="left"
								style={{}}
							>
								<span style={{float:"left",width:"80px"}}></span>
							</Typography>
						)
					}
					style={{width:"136px",margin:"0px"}}
				/>
				<FormControlLabel
					value="red"
					control={<Radio color="primary" style={{padding:"2px 2px 2px 6px"}} />}
					label={
						(
							<Typography
								className={"aqa-text-action"}
								variant="title" align="left"
								style={{}}
							>
								<span style={{float:"left",width:"80px"}}></span>
							</Typography>
						)
					} style={{width:"136px",margin:"0px"}}
				/>

			</RadioGroup>
		);
	}
}
AqaRulesRadioButton.propTypes = {classes: PropTypes.object.isRequired};

export default withStyles(styles)(AqaRulesRadioButton);
