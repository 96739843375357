/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as NewSourceIcon} from "../../images/aqa-icons/AQA-Delete.svg";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import {ReactComponent as FolderIcon} from "../../images/aqa-icons/Foldercol.svg";
import {ReactComponent as SourceIcon} from "../../images/aqa-icons/Sourcecol.svg";
import HtmlTooltip from "@material-ui/core/Tooltip";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";


const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaSourceDelete extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            openDeleteMessage: false,
            openProgress:false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            periodicities: [],
            periodicity: "daily",
            SourceName: "",
            SourceDescription: null,
            errorMessage: "",
            file: null,
            folders:[],
            selectedSources:[],
            selectedFolders:[],

        };
        this.handleDeleteSourceClosePopup=this.handleDeleteSourceClosePopup.bind(this);
        this.handleDeleteFoldersClosePopup=this.handleDeleteFoldersClosePopup.bind(this);
        this.handleNewSourceClick=this.handleNewSourceClick.bind(this);
        this.handleFolderDeleteClick=this.handleFolderDeleteClick.bind(this);
        this.handleDeleteSource=this.handleDeleteSource.bind(this);
        this.handleRemoveSource=this.handleRemoveSource.bind(this);
        this.handleDeleteFolder=this.handleDeleteFolder.bind(this);
        this.handleRemoveFolder=this.handleRemoveFolder.bind(this);

    }


    handleClosePopup(e) {
        e.preventDefault();
// onsole.log(this.props.dataFromRoot);
        this.props.dataFromRoot.handleFolderSelectionReset();
        this.props.dataFromParent.handleSourcesDeleteReset();
        this.setState({ errorMessage:"",openMessage: false,openDeleteMessage: false,SourceName:"",SourceDescription:"",periodicity:"daily" });
    }
    handleNewSourceClick(e) {
        e.preventDefault();
        this.setState({ openMessage: true });
    }

    handleFolderDeleteClick(e) {
        e.preventDefault();
        this.setState({ openDeleteMessage: true });
    }

    handleDeleteBulkSources=(sources)=>{
        let sourceList = {folderIds:sources};
        this.props.dataFromParent.handleFolder("allsources");
        AqaComponent.backend.delMultipleSourcesUsingDELETE(
            sourceList,
            (error,data,response)=>{
                if (error) this.reportError("A problem removing source from the server was encountered.",
                    "AqaSourceDelete.handleDeleteSource, backend call: " + error,this);
                else{

                        this.props.dataFromRoot.handleSources();
                        this.props.dataFromParent.handleFolder("allsources");
                        this.props.dataFromParent.handleSourcesDeleteReset();
                }
            }
        )
    }

    handleDeleteSource(source,last){
        AqaComponent.backend.delSourceUsingDELETE(
            source.id,
            (error,data,response)=>{
                if (error) this.reportError("A problem removing source from the server was encountered.",
                    "AqaSourceDelete.handleDeleteSource, backend call: " + error,this);
                else{
                    if(last===true){
                        this.props.dataFromRoot.handleSources();
                        this.props.dataFromParent.handleSourcesDeleteReset();
                    }
                }
            }
        )
    }
    handleDeleteFolder(folderId,last){
        if(folderId !== "allsources") {
            AqaComponent.backend.deleteFolderUsingDELETE(
                folderId,
                (error, data, response) => {
                    if (error) this.reportError("A problem getting the sources from the server was encountered.",
                        "AqaSourceDelete.handleDeleteFolder, backend call: " + error,this);
                    else {
                        if(last===true){
                            this.props.dataFromRoot.handleFolderSelectionReset();
                            this.props.dataFromRoot.handleFoldersReset();
                            this.props.dataFromRoot.handleSources();
                            this.props.dataFromRoot.showSourceView("allsources");
                        }
                    }
                }
            );
        }

    }
    handleRemoveSource(folderId,source,last){
        if(source!==null){
            var existingFolders=source.umbrellaIds;
            var index=existingFolders.indexOf(folderId);
            if(index>=0){
                existingFolders.splice(index,1);
                this.handleSourceToFolders(source,existingFolders,last)
            }
        }

    }

    handleSourceToFolders(source,folderIds,last){
        AqaComponent.backend.setFoldersUsingPOST(
            source.id,
            {folderIds},
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the sources from the server was encountered.",
                    "AqaSourceDelete.handleSourceToFolders, backend call: " + error,this);
                else
                {
                    if(last===true) {
                        this.props.dataFromRoot.showSourceView("allsources");
                    }
                }
            }
        );
    }

    handleDeleteSourceClosePopup(e){
        var selectedSources=this.props.dataFromParent.state.selectedSources;
        if(this.props.dataAcquired === "allsources"){
            if(selectedSources.length>0){
                let deleteSources = [];
                for(let l=0;l<selectedSources.length;l++){
                    deleteSources.push(selectedSources[l].id);
                }
                if(deleteSources.length>0) this.handleDeleteBulkSources(deleteSources);
                else this.props.dataFromParent.handleSourcesDeleteReset();
                /*for(var l=0;l<selectedSources.length;l++)
                {
                    var last=false;
                    var selectedSource = selectedSources[l];
                    if(l===selectedSources.length-1){
                        last=true;
                    }
                    this.handleDeleteSource(selectedSource,last);
                }*/
            }
        }
        else{

            if(selectedSources.length === 0){
                selectedSources=this.props.dataFromParent.state.sources;
                /*for(var k=0;k<selectedSources.length;k++){
                    selectedSource = selectedSources[k];
                    last=false;
                    if(k===selectedSources.length-1){
                        last=true;
                    }
                    this.handleRemoveSource(this.props.dataAcquired,selectedSource,last);
                }*/


            }
            else{
                /*for(k=0;k<selectedSources.length;k++){
                    selectedSource = selectedSources[k];
                    last=false;
                    if(k===selectedSources.length-1){
                        last=true;
                    }
                    this.handleRemoveSource(this.props.dataAcquired,selectedSource,last);
                }*/
            }
        }
        this.setState({ openMessage: false });
    }

    handleDeleteFoldersClosePopup(e){
        var selectedFolders=this.props.dataFromRoot.state.selectedFolders;
        if(selectedFolders.length>0){
        for(var l=0;l<selectedFolders.length;l++) {
                var last=false;
                var selectedFolder = selectedFolders[l];
                if(l===selectedFolders.length-1){
                    last=true;
                }
                this.handleRemoveFolder(selectedFolder,last);
            }
        }
    }

    handleRemoveFolder(folder,last){
        AqaComponent.backend.sourcesInFolderUsingGET(
            folder.id,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem removing folder from the server was encountered.",
                    "AqaSourceDelete.handleRemoveFolder, backend call: " + error,this);
                else
                {
                    for(var k=0;k<data.length;k++){
                        var source = data[k];
                        this.handleRemoveSource(folder.id,source,last);
                    }
                }
            }
        );
        this.handleDeleteFolder(folder.id,last);

    }





    componentDidMount(){
        this.setState({selectedSources:this.props.dataFromParent.state.selectedSources});
    }
    render() {
        //const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,errorMessage } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%"}}>
                                {((this.props.dataFromRoot.state.selectedFolders.length>0))?(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Click to delete selected Folder(s)</Typography>
                                        </div>

                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                    <IconButton className="aqa-button" onClick={this.handleFolderDeleteClick.bind(this)} disabled={false} >

                                        <div className="aqa-icon" >
                                            <NewSourceIcon width="32px" />
                                        </div>
                                        <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                            DELETE
                                        </Typography>
                                    </IconButton>
                                    </HtmlTooltip>
                                ):(
                                    (this.props.dataFromParent.state.selectedSources.length>0 && this.props.dataForDisabled===false))?(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Click to delete selected data Source(s)</Typography>
                                        </div>

                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                    <IconButton className="aqa-button" onClick={this.handleNewSourceClick.bind(this)} disabled={false} >

                                        <div className="aqa-icon" >
                                            <NewSourceIcon width="32px" />
                                        </div>
                                        <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                            DELETE
                                        </Typography>
                                    </IconButton>
                                    </HtmlTooltip>
                                ):(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Operation not permitted</Typography>
                                        </div>

                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <div>
                                    <IconButton className="aqa-button" disabled={true} >

                                        <div className="aqa-icon aqa-icon-d-disabled" >
                                            <NewSourceIcon width="32px" />
                                        </div>
                                        <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#dadbdb",paddingLeft:"4px",marginTop:"-4px"}}>
                                            DELETE
                                        </Typography>
                                    </IconButton>
                                        </div>
                                    </HtmlTooltip>
                                )}

                            </Typography>
                        </Grid>

                    </Grid>

                    <Dialog
                        open={this.state.openMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                Delete Data Sources
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={this.handleClosePopup.bind(this)} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:"500px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {(this.props.dataAcquired === "allsources")?
                                            ("Are you sure you want to permanently delete source(s)"):
                                            ((this.props.dataFromParent.state.selectedSources.length>0)?
                                                    ("Are you sure you want to Exclude source(s) from the folder "):
                                                    (<div><div>Are you sure you want to exclude all sources from the folder</div></div>)
                                            )
                                        }
                                    </Typography>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {(this.props.dataFromParent.state.selectedSources.map((item,index)=>(
                                                <div key={item.id+"_source_"+index}>


                                            <IconButton className="aqa-button" style={{padding:"4px"}}>
                                                <div className="aqa-list-icon">
                                                    <SourceIcon width="24px" />
                                                </div>
                                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",fontFamily:"Montserrat",color:"#006",padding:"5px",marginTop:"-5px"}}>
                                                {item.name}
                                            </Typography>
                                            </IconButton>
                                                </div>
                                            ))
                                        )}
                                    </Typography>

                                </Grid>


                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",width:"500px"}}>

                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: 8,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Cancel
                                    </Button>


                                </Grid>

                                <Grid item xs={9} sm={9} lg={9} xl={9}>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} xl={2}>
                                    <Button  variant="contained"
                                             onClick={this.handleDeleteSourceClosePopup.bind(this)}
                                             color="primary"
                                             align="left"
                                             className="aqa-action-button"
                                             style={{
                                                 marginTop: 8,
                                                 color: '#4cadc4',
                                                 backgroundColor: 'white',
                                                 border: '1px solid #4cadc4',
                                                 fontSize: '0.7rem',
                                                 padding: '3px',
                                             }}>
                                        Delete
                                    </Button>



                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.openDeleteMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                Delete Folders
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={this.handleClosePopup.bind(this)} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:"500px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {("Are you sure you want to Delete selected folder(s)")}
                                    </Typography>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {(this.props.dataFromRoot.state.selectedFolders.map((item,index)=>(
                                            <div key={item.id+"_folder_"+index}>
                                                <span style={{float:"left",width:"32px"}}>
                                            <IconButton className="aqa-button" style={{padding:"4px"}}>
                                                <div className="aqa-folder-list-icon">
                                                    <FolderIcon width={"24px"} />
                                                </div>
                                            </IconButton>
                                                    </span>
                                            <span style={{float:"left"}}>
                                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",marginTop:"5px"}}>
                                                {item.name}
                                            </Typography>
                                            </span>
                                            </div>
                                            ))
                                        )}
                                    </Typography>
                                </Grid>


                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",width:"500px"}}>

                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: 8,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Cancel
                                    </Button>


                                </Grid>

                                <Grid item xs={9} sm={9} lg={9} xl={9}>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} xl={2}>
                                    <Button  variant="contained"
                                             onClick={this.handleDeleteFoldersClosePopup.bind(this)}
                                             color="primary"
                                             align="left"
                                             className="aqa-action-button"
                                             style={{
                                                 marginTop: 8,
                                                 color: '#4cadc4',
                                                 backgroundColor: 'white',
                                                 border: '1px solid #4cadc4',
                                                 fontSize: '0.7rem',
                                                 padding: '3px',
                                             }}>
                                        Delete
                                    </Button>



                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>
                </div>
            );
        }
    }
}

AqaSourceDelete.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSourceDelete);
