import AqaComponent from "../components/shared/aqacomponent/AqaComponent"



import AqaAnalysisObject  from "./AqaAnalysisObject"
import AqaFindings        from "./AqaFindings"
import AqaVector          from "./AqaVector"


export default class AqaTable extends AqaAnalysisObject
{
	
	/** You make a table with its id.
	  * We will call you back when the object has been loaded. The only parameter will either be an error or null.
	  */
	constructor(tableId, callback)
	{
		super();
		// Snapshot analysis
		AqaComponent.snapshotBackend.getTableUsingGET // implement extra param to request the BE to fluff in the row stati - but we lose the byte advantage
		(
			tableId,
			(error, data, response) =>
			{
				var errorJSON = JSON.parse(response.text);
				if (errorJSON.error === "invalid_token") { // WHY THIS HERE???? WHY????
					this.clearStorage();
					window.location.reload();
				}
				else {

					if (error) callback(this, error);
					else {
						this.data = data; // Object as loaded by our backend
						this.findings = new AqaFindings(data.findings);

// onsole.log("Table was loaded: " + tableId);

						callback(this, null);
					}
				}
			}
		);	

	} //


	clearStorage()
	{
		["access_token", "refresh_token", "username", "userId", "accountId", "navigation", "authCode", "adminEmail","ft","access","_grecaptcha"].forEach(e => localStorage.removeItem(e));
	} // clearStorage

	// Convenience
	getMetric(metricName, defaultValue) { return this.findings.getMetric("_", metricName, defaultValue); }

	getTotalNumberOfCells() { return this.data.numberOfRows * this.data.numberOfColumns; }


	/** orientation is either Col or Row
	  * id is the vector id
	  * callback given to us by the caller to get back to them when ready.
	  */
	getVector(orientation, id, callback)
	{
		// Getting the vector from the server
		AqaComponent.snapshotBackend[`get${orientation}VectorUsingGET`]
		(
			this.data.id,
			id,
			(error, data, response) =>
			{
				if (error) console.error("AqaModel, get vector: " + error);
				else callback(new AqaVector(data, this.data[orientation === "Col" ? "numberOfRows" : "numberOfColumns"]));
			}
		);
	} // getVector

	getColumnVector(id, callback) { this.getVector("Col", id, callback); }
	getRowVector(id, callback) { this.getVector("Row", id, callback); }

	getVectorDistribution(orientation, id, type, nBars, callback)
	{
		AqaComponent.snapshotBackend[`get${orientation}VectorDistributionUsingGET`]
		(
			this.data.id,
			id,
			type,
			nBars,
			(error, data, response) => callback(error ? error : data)
		);	
	} // 

	getColVectorDistribution(colId, type, nBars, callback) { return this.getVectorDistribution("Col", colId, type, nBars, callback); }
	getRowVectorDistribution(rowId, type, nBars, callback) { return this.getVectorDistribution("Row", rowId, type, nBars, callback); }
	

	getLocalVectorDistribution(axis, id, type, callback)
	{
		let ret = {};
		axis = `${axis}Vectors`;
		this.data && this.data[axis] && this.data[axis].length < id && this.data[axis][id].distributions && (ret = this.data[axis][id].distributions[type]);

		if (callback) callback(ret);
	} // getLocalVectorDistribution

	getLocalColDistribution(colId, type, callback) { return this.getLocalVectorDistribution("Col", colId, type, callback); }
	getLocalRowDistribution(rowId, type, callback) { return this.getLocalVectorDistribution("Row", rowId, type, callback); }
	
	// These come from the table itself
	hasHorizontalHeader() { return (this.data.headers & 1) === 1; } // Top and Bottom
	hasVerticalHeader()   { return (this.data.headers & 2) === 2; } // On the sides

	numberOfRows()    { return this.data.numberOfRows; }
	numberOfColumns() { return this.data.numberOfColumns; }


/*
	// Wanted to use this in colour map - but in the end I decided it was simpler to have the colour map with all the colours including unused ones for headers
	adjustedNumberOfRows()    { return this.data.numberOfRows - (this.hasHorizontalHeader() ? 1 : 0); }
	adjustedNumberOfColumns() { return this.data.numberOfColumns - (this.hasVerticalHeader() ? 1 : 0); }
*/



} //// AqaTable

