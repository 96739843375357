


import React from 'react';
import AqaComponent  from "../shared/aqacomponent/AqaComponent"

import {CardElement} from '@stripe/react-stripe-js' // ??


import AqaStripeSubscription from "../stripe/AqaStripeSubscription";





export default class AqaAccountStripe extends AqaComponent
{


    constructor(props)
    {
        super(props);

        this.state =
            {
                message:"Look for system messages here",
                submitButtonEnabled:true,
                nu:0,
                ns:0,
                currency:"GBP",
                quote:"[No quote retrieved yet]",
                subscriptionResponse:"",
                subscriptionClientSecret:null
            };


    } //


    handleCheckout(event)
    {
        event.preventDefault();
        this.setState({submitButtonEnabled:false});



// Here we should enable / disable the submit button


        this.setState({message:"doing Stripe intent"});


        AqaComponent.stripeBackend.createPaymentIntentUsingPOST
        (
            {},
            (error, intentResponseData, response) =>
            {
                if (error)
                {
                    this.setState({message:"Stripe intent has an error"});
                    console.error("Stripe Intent Rquest Error occurred:");
                    console.error(AqaComponent.prettifyJson(error));
                    this.setState({submitButtonEnabled:true});

                }
                else
                {
                    this.setState({message:"Stripe looks like it has worked"});

// onsole.log("Stripe Intent Request response:");
// onsole.log(AqaComponent.prettifyJson(intentResponseData));




// "clientSecret":"pi_1IpzzyCMuU0SfJubyzTl97O0_secret_rvClThQ....etc."

                    this.setState({message:"Confirming"});


                    const {stripe, elements} = this.props;


//   const payload = await



                    stripe.confirmCardPayment
                    (
                        intentResponseData.clientSecret,
                        {
                            payment_method:
                                {
                                    card: elements.getElement(CardElement)
                                }
                        }
                    ).then
                    (
                        confirmationData =>
                        {
// this.setState({message:"Confirmation message received"});

                            if (confirmationData.error)
                            {
                                this.setState({message:"Confirmation failed"});
                                console.error(AqaComponent.prettifyJson(confirmationData.error));
                            }
                            else
                            {
                                this.setState({message:"Confirmation succeded"});
// onsole.log(AqaComponent.prettifyJson(confirmationData));
                            }
                        }
                    ).catch
                    (
                        ex =>
                        {
                            this.setState({message:"Confirmation encountered an error"});
                            console.error("Exception: " + ex);
                        }
                    );
                }

            }
        )





    }; // handleCheckout






    render()
    {

        const {stripe, elements} = this.props;
// onChange={e => {this.setState({currency:e.target.value})}}

        return (

            <div>

                {
                    this.props.stripeSecret !== "" && elements !== null
                        ?
                        <AqaStripeSubscription
                            clientSecret={this.props.stripeSecret}
                            stripe={stripe}
                            elements={elements}
                            parent={this}
                            dataFromParent={this.props.parent}
                            type={this.props.type!==undefined?"pmSetup":null}
                        />
                        :
                        null
                }

            </div>

        );



    } // render




} ////
