

export default class AqaSettings
{

	static baseUrl = null;
	static apiBasePath = "/api/v1";


	static OAuthLogin = "jwt";
	static OAuthPassword = "aqas3cr3t4urIsOnly";

	// TEST KEY for V2 Version
	//static SITE_KEY="6LdrYT4dAAAAAEle82tV3ZJV6gQgzz9-dWViLjOX";
	static SITE_KEY="6LfHHTsdAAAAADT_tt6Hb6urR2U-b1q8abtcUnQD";

	// This is for dev mode
	// The front end and the back end will not be served from the same port - as long as CORS is setup properly at the Java end.


	
	static getApiBaseUrl() { return AqaSettings.getBaseUrl() + AqaSettings.apiBasePath; }

	static getBaseUrl()
	{
		// Production
//		return "";

		// While developing and not using: npm build (so we are on a different port)
		if (AqaSettings.baseUrl === null)
		{
			let location = window.location;

			const port =
				location.hostname.indexOf('localhost') >= 0 // || location.hostname.indexOf("dev.glaine.net") >= 0
			?
				":8585"
			:
				""
			;
	
			if (location.port === "3000")
			{
				AqaSettings.baseUrl=`${location.protocol}//${location.hostname}${port}`;
			}
			else
			{
				AqaSettings.baseUrl = location.href.slice(0, - ((location.search + location.hash).length+1));
			}
		}
		return AqaSettings.baseUrl;

	} // getBaseUrl

	
	// Production
	// let baseURL = 'api/v1';


} ////


