import React from 'react'
import PropTypes from 'prop-types'

import AqaRulesParent                from "./AqaRulesParent"
import AqaBoundaries                 from "../../../model/AqaBoundaries"
import AqaOutline                    from "../../shared/outline/AqaOutline"
import AqaRulesTextThreshold         from "./subelements/AqaRulesTextThreshold"
import AqaRulesGraph                 from "./subelements/AqaRulesGraph"
import AqaQualityButton              from "./subelements/AqaQualityButton"
import AqaTypeBreachSeveritySelector from "./subelements/AqaTypeBreachSeveritySelector"
import AqaFormatTable                from "./subelements/AqaFormatTable"

// import AqaRulesRadioButton           from "./subelements/AqaRulesRadioButton";

import {
	Checkbox,
	FormControlLabel
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles"
import FormControl    from "@material-ui/core/FormControl"
//import IconButton     from "@material-ui/core/IconButton"
//import TextField      from "@material-ui/core/TextField"
import Grid           from "@material-ui/core/Grid"
import Typography     from "@material-ui/core/Typography"

import Slider from "rc-slider";
import "rc-slider/assets/index.css"
import "rc-tooltip/assets/bootstrap.css";


/*
import AqaRulesPopulated from "./AqaRulesPopulated";
import AqaRulesUniqueness from "./AqaRulesUniqueness";
import AqaRulesNativeErrors from "./AqaRulesNativeErrors";
*/

import AqaPopulationUniquenessNativeErrorsCombo from "./AqaPopulationUniquenessNativeErrorsCombo"


// import {subDays, startOfToday, format } from "date-fns"


const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

// const RAG_COLOURS = ["red", "amber", "green"];

const NO_DATE = "2020-01-01";

class AqaRulesDate extends AqaRulesParent
{

	constructor(props)
	{
		super(props, "Date");
		this.state =
		{
			error: null,
			notfound: false,

			isLoaded: false,

			dense: false,
			redirect: false, // What is this??
			currentSnapshot:"allsources",
			greenDateFormats: [],
//			which: 0,
			width:960,

			markcount:12,
			invertZones: false,

			initialLoad: false,
		};

	} // 


// Candidate for move to RulesParent?? TODO: chck
	// Is it actually used??!??
	handleRuleSelection = (which) => this.setState({which});


	// Date specific conversions and utilities
	// ---------------------------------------

	pad0(num, size)
	{
		for(num = num.toString(); num.length < size;) num = "0" + num;
		return num;
	} // pad0


	/** Returns UTC Y M D from milliseconds date */
	utcComponents(millis)
	{
		var date = new Date(millis);
		return [date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()];
	} // utcComponents

	/** This is the format used by the Date picker */
	dateForPicker(millis)
	{
		const utcEls = this.utcComponents(millis);
		return `${this.pad0(utcEls[0], 4)}-${this.pad0(utcEls[1] + 1, 2)}-${this.pad0(utcEls[2], 2)}`;

	} // dateForPicker
	
	/** THIS IS WHERE WE COULD DECIDE WHICH FORMAT TO PRESENT THE USER WITH */
	dataFormatter(millis)
	{
		const utcEls = this.utcComponents(millis);
		return `${this.pad0(utcEls[2], 2)}/${this.pad0(utcEls[1] + 1, 2)}/${this.pad0(utcEls[0], 4)}`;
	} // dataFormatter


	// Renderer
	// --------
	

	render()
	{
		const { error, isLoaded, notfound, hasError, invertZones, width} = this.state;


		//const enabled = this.isRulesEnabled();

		const useDateFormats = this.rules.useDateFormats;
		const useDateBoundaries = this.rules.useDateBoundaries;
		const minMaxArray	= [this.wideMinRange, this.wideMaxRange];

		this.allFormats = this.packageFormatsOrValuesForRender(1);
		
		/*
		if(this.formats !== undefined){
			RAG_COLOURS.forEach
			(
				color=> { 
					var values = this.formats[color];
					if(values) {
						var l=0;
						for (var val of values) {
							var valColor = {id: val, color: color,index:l}
							this.allFormats.push(valColor);
							l=l+1;
						}
					}
				}
			);
		}
		*/

		if (notfound && error)
		{
			return (
				<div className={"snapshot-rules-servant-pane"}>
					<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>
						<Grid item xs={10} sm={7} lg={11} xl={11}>
							<Typography
								variant="title"
								color="inherit" align="left"
								style={
									{
										fontSize: '1.5rem',
										padding: '0.1em 0.5em',
										fontFamily: 'Open Sans, sans-serif'
									}
								}
							>
								Nothing to display
							</Typography>
						</Grid>
						<Grid item xs={2} sm={1} lg={1} xl={1} />
					</Grid>
				</div>
			);
		}
		else if (error && !notfound)
		{
			return (
				<div>
					<Typography
						variant="title"
						color="inherit"
						align="left"
						style={{ fontSize: '1.5rem', padding: '3.1em 0.5em', fontFamily: 'Open Sans, sans-serif'}}
					>
						Error: {error.message}
					</Typography>
				</div>
			);
		}
		else if (!isLoaded)
		{
			return <div>Loading...</div>;
		}
		else
		{
		
		
// for(const z of AqaBoundaries.makeColourZones(this.rules, this.typeName, this.scaleFromValueToScreenValue, minMaxArray)) onsole.log("---- " + JSON.stringify(z));
		
		
			return (
				<div className={"snapshot-rule-servant-pane"}>
					<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

						<Grid item xs={12} sm={12} lg={12} xl={12}>
							<div style={{padding:"0px"}}>

								<AqaQualityButton which={4} />

								<div style={{marginTop:"-48px"}}>
								
									{
										[
										//  0                        1                                                        2
											[useDateFormats,          e => {this.setUsed("DateFormats", e.target.checked)},    "Set Date formats"],
											[useDateBoundaries,       e => {this.setUsed("DateBoundaries", e.target.checked)}, "Set Date value thresholds"],
											[this.typeEnforcedFor2(), e => {this.handleEnforceTypeChange2(e.target.checked)},  "Values must be Dates"]
										].map
										(
											(srt, i) => (
												<div key={"Date_"+i} style={{textAlign:"left", width:"960px", padding:0, margin:0}}>
												
													<FormControlLabel
														key={`srt${i}`}
														control={
															<Checkbox
																checked={srt[0]}
																onChange={srt[1]}
																color="primary"
															/>
														}
														label={
															(
																<Typography className={"aqa-text-action"} variant="title" align="left">
																	{srt[2]}
																</Typography>
															)
														}
														labelPlacement="end"
													/>

													{
														i !== 0 ? null :
														<div style={{textAlign:"left"}}>
															{ useDateFormats ? <AqaFormatTable parent={this} allFormats={this.allFormats} canAdd={false} unlistedColour={this.getUnlistedColour()} hasHelp={false} /> : null }
														</div>
													}


													{
														i !== 1 ? null :
														<div style={{textAlign:"left"}}>
															{
																useDateBoundaries
																?
																	(
																		<div style={{paddingBottom:"10px"}}>
																			<FormControl component="fieldset" size="small" fullWidth>

																				<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: "-5px"}}>
																					<Grid item xs={12} sm={12} lg={12} xl={12}>
																						<div style={{textAlign:"right",marginTop:"-40px",marginLeft:"40px"}}>
																							{/*<FormGroup row> */}
																								<FormControlLabel
																									control={
																										<Checkbox
																											checked={this.state.invertZones}
																											onChange={e => this.handleInvertZone(e.target.checked)}
//																														name="invertZones"
																											color="primary"
																										/>
																									}
																									label={
																										(
																											<Typography className={"aqa-text-action"} variant="title" align="left" style={{ }}>
																												Invert Zones
																											</Typography>
																										)
																									}
																									labelPlacement="end"
																								/>
																						</div>
																					</Grid>

																					<Grid item xs={12} sm={12} lg={12} xl={12}>
																						<div style={{position: "relative", textAlign: "left", marginLeft:"0px"}} >
																							<div id="aqa_tooltip" display="none" style={{position: "absolute", display: "none",backgroundColor:"#6c6c6c",color:"white",fontSize:"12px",fontFamily:"Montserrat",padding:"2px",maxWidth:"80px",zIndex:1201,textAlign:"center"}}></div>
																						
																							<AqaRulesGraph
																								width={width}
																								height={200}
																								zones={AqaBoundaries.makeColourZones(this.rules, this.typeName, this.scaleFromValueToScreenValue, minMaxArray)}
																							/>


																						</div>
																						<div style={{width: width,marginTop:"65px",marginRight:"10px"}}>
																						
																							{/* All boudary values are passed here,  they are mapped back to their boundary values based on the boundary flags */}
																						

																							<Range
																								count={2}
																								min={this.wideMinRange}
																								max={this.wideMaxRange}
																								
																								// Yes I know most of the stuff is on this.rules and some like boundaryValues is on the state...

																								value={this.boundaryValues}
																								marks={this.marks}

																								included={false}
																								pushable={true}
																								step={1}
//																											allowCross={false}
																								tipProps={{visible:true}}
																								tipFormatter={v => this.dataFormatter(v)}
																								onChange={this.handleRangeChange2}
																								reverse={false}
																							/>

																						</div>
																					</Grid>

																					<Grid item xs={12} sm={12} lg={12} xl={12} style={{zIndex:1200}}>
																						<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

																							<Grid item xs={5} sm={5} lg={5} xl={5}>
																								<Typography className="" variant="inherit" style={{textAlign:"center",paddingLeft:"0px",paddingRight:"0px",marginTop:"25px",marginLeft:"-110px"}}>
																									<AqaOutline label={"Lower Threshold"}>
																										<div>

																											<AqaRulesTextThreshold
																												idName="dateBoundary1.date1"
																												type="date"
																												caption={invertZones ? "Amber" : "Red"}
																												checked={this.isBoundaryValueActivated(1, 1)}
																												changeHandler={e => this.setBoundaryValueActivated(1, 1, e.target.checked)}
																												value=
																												{
																													this.rules.dateBoundary1
																													?
																														this.dateForPicker(this.rules.dateBoundary1.date1)
																													:
																														NO_DATE
																												}
																												error={hasError}
																												textChangeHandler={this.handleTextChange2}
																												parent={this}
																											/>

																											<AqaRulesTextThreshold
																												idName="dateBoundary1.date2"
																												type="date"
																												caption={invertZones ? "Red" : "Amber"}
																												checked={this.isBoundaryValueActivated(1, 2)}
																												changeHandler={e => this.setBoundaryValueActivated(1, 2, e.target.checked)}
																												value=
																												{
																													this.rules.dateBoundary1
																													?
																														this.dateForPicker(this.rules.dateBoundary1.date2)
																													:
																														NO_DATE
																												}
																												error={hasError}
																												textChangeHandler={this.handleTextChange2}
																												parent={this}
																											/>

																										</div>
																									</AqaOutline>
																								</Typography>
																							</Grid>

																							<Grid item xs={3} sm={3} lg={3} xl={3}></Grid>

																							<Grid item xs={4} sm={4} lg={4} xl={4}>
																								<Typography className="" variant="inherit" style={{textAlign:"center",paddingLeft:"0px",paddingRight:"0px",marginTop:"25px",marginLeft:"25px"}}>


																									<AqaOutline label={"Upper Threshold"}>
																										<div>
																										
																											<AqaRulesTextThreshold
																												idName="dateBoundary2.date1"
																												type="date"
																												caption={invertZones ? "Red" : "Amber"}
																												checked={this.isBoundaryValueActivated(2, 1)}
																												changeHandler={e => this.setBoundaryValueActivated(2, 1, e.target.checked)}
																												checkboxName="showAmber2"
																												value=
																												{
																													this.rules.dateBoundary2
																													?
																														this.dateForPicker(this.rules.dateBoundary2.date1)
																													:
																														NO_DATE
																												}
																												error={hasError}
																												textChangeHandler={this.handleTextChange2}
																												parent={this}
																											/>

																											<AqaRulesTextThreshold
																												idName="dateBoundary2.date2"
																												type="date"
																												caption={invertZones ? "Amber" : "Red"}
																												checked={this.isBoundaryValueActivated(2, 2)}
																												changeHandler={e => this.setBoundaryValueActivated(2, 2, e.target.checked)}
																												checkboxName="showRed2"
																												value=
																												{
																													this.rules.dateBoundary2
																													?
																														this.dateForPicker(this.rules.dateBoundary2.date2)
																													:
																														NO_DATE
																												}
																												error={hasError}
																												textChangeHandler={this.handleTextChange2}
																												parent={this}
																											/>

																										</div>
																									</AqaOutline>
																								</Typography>
																							</Grid>

																						</Grid>
																					</Grid>



																				</Grid>

																			</FormControl>
																		</div>
																	)
																:
																	null
															} {/* Use Date Boudaries */}

														</div>
													}

													{
														i !== 2 ? null :
														<div style={{textAlign:"left"}}>
															{
																this.typeEnforcedFor2()
																?
																	<AqaTypeBreachSeveritySelector parent={this} typeSeverity={this.rules.typeSeverity} changeHandler={this.handleTypeSeverityChange2} />
																:
																	null
															}
														</div>
													}

												</div>
											)
										)

									}

								</div>

							</div>
						</Grid>


						<AqaPopulationUniquenessNativeErrorsCombo
							dataForCol={this.props.dataForCol}
							dataForWhich={this.state.which}
							dataForName={this.state.columnName}
							parent={this.props.parent}
							dataFromSource={this.props.dataFromSource}
							dataForTable={this.props.dataForTable}
							//			dataFromSnapshot:this.props.dataFromSnapshot
							sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
							rulesObject={this.props.rulesObject}
							rules={this.props.rules}
						/>

						{/*
						<Grid item xs={12} sm={12} lg={12} xl={12}>
							<AqaRulesPopulated
								dataForCol={this.props.dataForCol}
								dataForWhich={this.state.which}
								dataForName={this.state.columnName}
								parent={this.props.parent}
								dataFromSource={this.props.dataFromSource}
								dataForTable={this.props.dataForTable}
								//			dataFromSnapshot:this.props.dataFromSnapshot
								sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
								rulesObject={this.props.rulesObject}
							/>
						</Grid>
						<Grid item xs={12} sm={12} lg={12} xl={12}>
							<AqaRulesUniqueness
								dataForCol={this.props.dataForCol}
								dataForWhich={this.state.which}
								dataForName={this.state.columnName}
								parent={this.props.parent}
								dataFromSource={this.props.dataFromSource}
								dataForTable={this.props.dataForTable}
								//			dataFromSnapshot:this.props.dataFromSnapshot
								sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
								rulesObject={this.props.rulesObject}
							/>
						</Grid>
						<Grid item xs={12} sm={12} lg={12} xl={12}>
							<AqaRulesNativeErrors
								dataForCol={this.props.dataForCol}
								dataForWhich={this.state.which}
								dataForName={this.state.columnName}
								parent={this.props.parent}
								dataFromSource={this.props.dataFromSource}
								dataForTable={this.props.dataForTable}
								//			dataFromSnapshot:this.props.dataFromSnapshot
								sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
								rulesObject={this.props.rulesObject}
							/>
						</Grid>
						
					*/}
						
					</Grid>
				</div>
			);
		}
	} // render
}

AqaRulesDate.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(AqaRulesParent.styles)(AqaRulesDate);


