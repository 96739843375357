
import React, {Component} from 'react';


// const CLICKER_STYLE = {fill:"rgb(255,255,255)", fillOpacity:0};

export default class DistributionGraphBar extends Component
{

	static #serial = 1;

	constructor(props)
	{
		super(props);
//		this.state = {selected: props.bucket.selected};
		this.key = `rct${DistributionGraphBar.#serial++}`;
	} //


	/** SVG rectangles are... can't find a politically correct way to say it... massively idiotic */
	render()
	{

// this.props.rag
// 					className={`${this.props.selected ? "bar-selected" : "bar"}${this.props.bucket.rag} svg-clickable`}
// 					onClick={() => this.props.parent.filter(this)}
// <title>{this.props.bucket.title}</title>
//  svg-clickable
// style={CLICKER_STYLE}

		return (
			<>
				<rect
					key={this.key}
					className={`${this.props.selected ? "bar-selected" : "bar"}${this.props.bucket.rag}`}
					x={this.props.x}
					width={this.props.barWidth}
					y={this.props.y - this.props.barHeight}
					height={this.props.barHeight}

				/>

				<rect
					key={`${this.key}clkr`}
					className="bar svg-clickable"
					x={this.props.x}
					width={this.props.barWidth}
					y={this.props.y - this.props.fullHeight}
					height={this.props.fullHeight}
					onClick={() => this.props.parent.filter(this)}
				>
					<title>{this.props.bucket.title}</title>
				</rect>

				
				
			</>
		);

	} // render

} ////


// Why wouldn't the 2nd rack be clickable??
//				className={`${this.state.selected ? "bar-selected" : "bar"}${this.props.rag}${this.props.clickable ? " svg-clickable" : ""}`}
