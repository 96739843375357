import React from 'react';
// import ReactDOM from 'react-dom';
import AqaComponent from "../aqacomponent/AqaComponent";

import {BACKEND_TYPE_STRING, BACKEND_TYPE_NUMBER, BACKEND_TYPE_DATE} from "../../snapshotdetail/AqaSnapshotDetail"

import AqaBoundaries           from "../../../model/AqaBoundaries"
import DateFormat              from "../../../model/DateFormat"


import DistributionGraphBar    from "./DistributionGraphBar"
import DistributionGraphPinSet from "./DistributionGraphPinSet"
import DistributionGraphNeedle from "./DistributionGraphNeedle"
import NeedleKbdBoxes          from "./NeedleKbdBoxes"
// import Button                  from "../svg/Button"




import "./DistributionGraph.scss"
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


// L'etat des barres doit etre garde independemment de celles-ci car elles sont regenerees a chaque repaint
// et bien sur les objects React ca n'existe pas en vrai...


const MAX_SCALE = 5;
// const MINIMUM_NUMBER_OF_BUCKETS = 4;
const BASE_DIVISOR = 2; // Arbitrarily chosen. May need adjustment.

// const SORT_BUTTONS = [[1, "by value"], [0, "by count"], [2, "continuous"], [3, "cont-buckets"]];

const SORT_BUTTONS = [[11, "Frequency order"], [10, "Value order"]];
// const SORT_BUTTONS_FOR_STRINGS = [[11, "frequency order"]];
const SORT_BUTTONS_FOR_STRINGS = [SORT_BUTTONS[0]];


// const SORT_BUTTONS = [[0, "by count"], [2, "continuous"]];

// TODO: use Number() on getting the boundaries!!!


/*
const HTML_ZONE_COLOURS =
{
	red:"#ffcccc",
	amber:"#ffd9b3",
	green:"pink" // if we decide to draw green this will remind us to assign a decent colour
};
*/

/*
redZone
amberZone
greenZone
*/


const NORMAL_BUCKET_SPACING = 25;
const CONTINUOUS_BUCKET_SPACING = 1;
const NUMBER_OF_PIXELS_BETWEEN_BY_FREQUENCY_PINS = 40;

const SPACE_ENOUGH_TO_JUSTIFY_TICK = 320;

const GRID_AND_TICK_COLOUR = "rgb(219, 219, 219)";


/*
const elague = n =>
{
	let ns = n.toString();
//	let l;
	if (n > 1000 || n < 0.01) return n.toExponential(3);

	const coc = ns.indexOf('.');
	
	if (coc >= 0 && coc < ns.length - 4) ns = ns.substring(0, coc + 4); // 2 digits after the dot
	return ns;
} // elague
*/

class RecurrentTick
{

	static serial = 1;

	constructor(leftPosition, leftValue, allowMoreOnLeft, rightPosition, rightValue, allowMoreOnright, dataType, dateFormat)
	{
	
		this.ticks = [];
//		this.isDate = ;

// onsole.log("SP: " + (rightPosition - leftPosition) + " L = " + SPACE_ENOUGH_TO_JUSTIFY_TICK);

// onsole.log("T: " + dataType);


		if (rightPosition - leftPosition >= SPACE_ENOUGH_TO_JUSTIFY_TICK)
		{
			this.needsRendering = true;
//			this.y = y;
			this.x = (rightPosition + leftPosition) / 2;
			
			
			let intrinsicValue = (leftValue + rightValue) / 2;


			if (BACKEND_TYPE_DATE === dataType)
			{
				intrinsicValue = Math.floor(intrinsicValue);

// if (dateFormat) onsole .log("I *am* using the date formatter!!");
// else onsole .log("nah.");

				if (dateFormat) this.val = dateFormat.format(intrinsicValue);
				else this.val = AqaComponent.formatDate(intrinsicValue);
			
			}
			else
			{
				this.val = intrinsicValue;
			}
			
//			this.val = BACKEND_TYPE_DATE === dataType ? AqaComponent.formatDate(Math.floor(intrinsicValue)) : intrinsicValue;

			if (allowMoreOnLeft) this.ticks.push(new RecurrentTick(leftPosition, leftValue, true, this.x, intrinsicValue, true, dataType, dateFormat));
			if (allowMoreOnright) this.ticks.push(new RecurrentTick(this.x, intrinsicValue, true, rightPosition, rightValue, true, dataType, dateFormat));
		}
	
	
	} //
	
	
	// transform="rotate(20 0 9)"
	render(y)
	{
		return (
			<g key={`{tk${RecurrentTick.serial++}}`}>
				{
					this.needsRendering
				?
					<>
						<text y={y} x={this.x} className="tick" style={{textAnchor:"middle"}}>{AqaComponent.abreviateNumber(this.val)}</text>
						{
							this.ticks.map(t => t.render(y))
						}
						
						<line x1={this.x} y1={y - 18} x2={this.x} y2={y - 12} style={{stroke:"rgb(0, 0, 0)", strokeWidth:"1"}} shapeRendering="crispEdges" />
					</>
				:
					null
				}
			</g>
		);	
	
	
	} // render

// key={`dghl${DistributionGraph.#serial++}`}

} //// RecurrentTick


export default class DistributionGraph extends AqaComponent
{
	static #serial = 0;

	static DEFAULT_WIDTH = 750;
	static DEFAULT_HEIGHT = 235;

	static LEFT_MARGIN = 40;
	static RIGHT_MARGIN = 4;
	
	
	static parsers = {};

	constructor(props)
	{
		super(props);
		props.controller.registerDistriComponent(this, props.position);
		this.state = 
		{
			buckets: null,
			maxCount:0,
			distribution: null,
			page:0,
			width: props.width ? props.width+60 : DistributionGraph.DEFAULT_WIDTH, // Why a + 60???
			height: props.height ? props.height : DistributionGraph.DEFAULT_HEIGHT,
			float:"left",
			scale:1,
			selected:false,
			selX:0,
			selW:0,
			selectedBucketIndex:-1,


//			sortType:"Continuous",
//			needleFiltered:false
		};

		this.captionFontFamily = "Montserrat";

		this.textFontSize =    12;
		this.verticalClearing = 6;

		this.captionHeight = 40;
		this.captionFontSize = 13; // To align with type metrics
		this.topCaptionClearing = 24;

		this.leftCaptionWidth  = 60;
		this.rightCaptionWidth = 60

		this.barWidth =        30;
		this.barSpacing =      NORMAL_BUCKET_SPACING;

		this.numberOfPercentTicks = 3;
		this.maxCaptionLength  = 12;

		this.numberOfHorizontalGridZones = 16;
		this.numberOfPixelsBetweenVerticalGridBarsRoughly = 18;

		this.vqd = null;

		// Synthetic view by counts of the distribution
		this.distributionByCounts = null;

		this.colours = false;

		this.zoneCC = 0;

		// Used for continuous display but not for strings (which are their own animal)
		// They are the first and last values used for scaling onto display.
		this.boundaries = null; // boundaries is a confusing name. It's actually an array with two values: min and max.
		this.dataSpan = 1;

		// User changeable values
		// 0: N1, N2...; 1: a,b,c; 2: Continuous Distribution
		this.radioIndex = SORT_BUTTONS[0][0];
		this.order = 0; // 0: by value, 1: by frequency
		this.sortType = 2; // by value // Me thinks this should be state based.
		

//		this.scale = 1;
		this.maxScale = MAX_SCALE;
		this.yScale = AqaComponent.makeSequence(this.numberOfPercentTicks + 1);

		this.numberOfSamplesPerBucket = 0;

		this.setup(this.state.width, this.state.height);

//		this.graphicalBuckets = null;
		
//		this.ready = false;
		this.needles = [null, null];
		this.needleBox = null;
		this.needleBoxesValues = [0, 0];
		
		
		this.graphPadding = 8;
		this.dateFormat = null;

//		this.handleRadioChange=this.handleRadioChange.bind(this);


		// Yeah I know it's re-init'ing them every time. But javascript...
		DistributionGraph.parsers[BACKEND_TYPE_NUMBER] = parseFloat;
		DistributionGraph.parsers[BACKEND_TYPE_DATE] =  parseInt;
		DistributionGraph.parsers[BACKEND_TYPE_STRING] = s=>s;

		this.continuumTicks = []; // This is a cache for the continuum ticks

	} //
	
	getDescription()
	{
		return (
			"This contains a distribution graph which is driven by the column the user selected." +
			"When situated at the top of the screen, the bucket bars are clickable and will trigger the filtering of the snapshot according to the value(s) in the selected bucket.<br />" +
			"The Distribution Graph in the bottom combo will not have this filtering capability."
		);

	} // getDescription

	handleRadioChange = event =>
	{
		this.radioIndex = event.target.value;
//		this.setState({[event.target.name]: event.target.value});


		switch(event.target.value)
		{
		case "10":
			this.changeSortTypeAndOrder(3, 0);
			break;

		case "11":
			this.changeSortTypeAndOrder(2, 1);
			break;

		default: // To shut up the transpiler
			break;
		}


//		this.changeSortType(parseInt(event.target.value));

		/*
		let sortType = event.target.value === "Count"?0:2;
		this.sortType=sortType;
		this.changeSortType(sortType);
		*/
	} // handleRadioChange

	registerNeedle(n, which) { this.needles[which] = n; }

	registerNeedleKbdBoxes(nkb) { this.needleBox = nkb; }

	setup(width, height)
	{

//		this.captionHeight =  this.captionFontSize + (this.verticalClearing << 1);


		this.bottomCaptionHeight = 60; // Math.floor(this.captionHeight * 1.5); // Ratio could be parameterised.


// Why a + 100 to the usable width, Why?!?!?!

		this.usableWidth =    width - this.leftCaptionWidth - this.rightCaptionWidth - (this.graphPadding << 1);
		this.usableHeight =   height - (this.captionHeight + this.verticalClearing + this.bottomCaptionHeight); // 2 of them, on caption at the top, one at the bottom.

		this.spaceBetweenPcMarkers = this.usableHeight / this.numberOfPercentTicks;

		this.maxNumberOfShowableBars = Math.floor((this.usableWidth - this.barSpacing) / (this.barWidth + this.barSpacing));


		if (this.maxNumberOfShowableBars < 1) this.maxNumberOfShowableBars = 1; // otherwise boom!

		this.gridRegionsYs = AqaComponent.makeSequence(this.numberOfHorizontalGridZones + 1).map(y => this.captionHeight + y * this.usableHeight / this.numberOfHorizontalGridZones);

		let numberOrHorizontalGridRegions = Math.floor(this.usableWidth / this.numberOfPixelsBetweenVerticalGridBarsRoughly);
		let missingBit = (this.usableWidth - numberOrHorizontalGridRegions * this.numberOfPixelsBetweenVerticalGridBarsRoughly);
		
//		const xOff = this.leftCaptionWidth;

		if (missingBit > 0 && missingBit > (this.numberOfPixelsBetweenVerticalGridBarsRoughly >> 1)) numberOrHorizontalGridRegions++

		this.gridRegionsXs = AqaComponent.makeSequence(numberOrHorizontalGridRegions + 1).map(x => this.leftCaptionWidth + x * (this.usableWidth + (this.graphPadding << 1)) / numberOrHorizontalGridRegions);

	} // setup


	resize(params)
	{


/*
if (this.props.position === 0)
{
onsole.log("\nAll'inizio\n----------");
onsole.log("number of pages: " + this.numberOfPages);
onsole.log("maxNumberOfShowableBars: " + this.maxNumberOfShowableBars);
onsole.log("Page: " + this.state.page);
onsole.log("Buckets: " + (this.state.buckets ? this.state.buckets.length : 0));
}
*/
		let oldNumberOfShowableBars = this.maxNumberOfShowableBars;

		this.setup(params.dgWidth, this.state.height); // For now we are keeping a fixed height

		// And state it boldly


// TODO: THIS NEEDS TO BE REFINED
		let [numberOfPages, newPage] = AqaComponent.rewindow(this.state.page, this.state.buckets === null ? 0 : this.state.buckets.length, oldNumberOfShowableBars, this.maxNumberOfShowableBars);
		

		this.numberOfPages = numberOfPages;

		let newState = {width:params.dgWidth, float:params.dgFloat};

		let postStaterPostStater = () => {};
		if (oldNumberOfShowableBars !== this.maxNumberOfShowableBars)
		{
			const oldNumberOfSamplesPerBucket = this.numberOfSamplesPerBucket;
			newState = AqaComponent.conflate(newState, this.makeBuckets(this.state.scale, this.typeName, true));

			if (oldNumberOfSamplesPerBucket !== this.numberOfSamplesPerBucket)
			{
				postStaterPostStater = () =>
				{
					this.props.controller.resetFilter(this.props.position);
					this.props.controller.setView();
				}
			}
			// TODO: recompute new page and possibly tell parent to remove filter
		}

		newPage = this.checkNewPage(newPage);
		if (newPage !== this.state.page) newState.page = newPage;

/*
if (this.props.position === 0)
{
onsole.log("\nIn fine\n-------");
onsole.log("number of pages: " + this.numberOfPages);
onsole.log("maxNumberOfShowableBars: " + this.maxNumberOfShowableBars);
onsole.log("Page: " + this.state.page);
onsole.log("Buckets: " + (this.state.buckets ? this.state.buckets.length : 0));
}
*/

		this.setState(newState, postStaterPostStater);

	} // resize



	/** @param distribution distribution as output by the Backend (Distribution object with a list of buckets)
	  * @param distributionColours
	  * @param vqd
	  * @param sortType 0: order from highest count to lowest, 1: order by value
	  * @param typeName one of: BACKEND_TYPE_STRING, BACKEND_TYPE_NUMBER, BACKEND_TYPE_DATE
	  * @param dateFormat - only available if we are showing dates

	  */
	informDistributionGraph(distribution, distributionColours, vqd, sortType, typeName, dateFormatString)
	{

		this.sortType = sortType;
/*
onsole.log("############################################################");
onsole.log("sortType: [" + sortType + "]");
onsole.log("typeName: [" + typeName + "]");
onsole.log("dateFormatString: [" + dateFormatString + "]");
onsole.log("############################################################\n\n");
*/

/*
		this.radioIndex = 11;
		this.sortType = 2;
		this.order = 0; // 0: by value, 1: by frequency
*/


// You really want to disable this logging if you see it uncommented

/*
onsole.log("Distribution");
onsole.log(AqaComponent.prettifyJson(distribution));
onsole.log("typeName: " + typeName);
onsole.log("Distribution colours:");
onsole.log(AqaComponent.prettifyJson(distributionColours));
*/




		this.distribution = distribution;
		this.distributionColours = distributionColours;

		this.distributionByCounts = null;
		this.distributionByContinuum = null;

		this.typeName = typeName;

// onsole.log("Date format string: [" + dateFormatString + "]");

		this.dateFormat = dateFormatString ? new DateFormat(dateFormatString) : null;

		this.vqd = vqd;

		if (distribution === null)
		{
			this.buckets = null;
			this.setState({page:0, buckets:null});
			return;
		}

		// Making sure we have the right colours
		if (distributionColours && distributionColours["valueRagBytes"] && distributionColours["valueRagBytes"][typeName]) this.colours = distributionColours["valueRagBytes"][typeName];
		else this.colours = false;

//		this.changeSortType(sortType);

		this.radioIndex = 11;
		this.changeSortTypeAndOrder(2, 1);


/*
		// In effect here, we ignore the sort type requested by the Dataviewer
		if (BACKEND_TYPE_STRING === sortType)
		{
			// String
			this.changeSortTypeAndOrder(2, 1);
		}
		else
		{
			// date or number
			this.changeSortTypeAndOrder(3, 0);		
		
		}
*/



	} // informDistributionGraph


	checkNewPage(newPage)
	{
		if (newPage >= this.numberOfPages) newPage = this.numberOfPages - 1;
		if (newPage < 0) return 0;
		return newPage;
	} // checkNewPage

	// Absolute
	page(newPage)
	{
		newPage = this.checkNewPage(newPage);
		if (newPage !== this.state.page) this.setState({page:newPage});
	} // page


	// Relative
	pager(disp) { this.page(this.state.page + disp); }

	scaler(pm)
	{
		let scale = this.state.scale + pm;
		if (scale < 1 || scale > this.maxScale) return;

		switch(this.sortType)
		{
		case 0:
		case 1:

			const distributionData = this.makeBuckets(scale, this.typeName, true); // It neeeds to be rebuilt
			distributionData.page = 0; // TODO - recompute page - Take selection into account?
			distributionData.scale = scale;
			this.setState(distributionData);
			break;

		case 2: // Continuous view

			this.numberOfPages = (this.getScaleMultiplier(scale) - 1) * 5; // x 5 because each of our page is a quarter of a screen.
			this.setState
			(
				{scale},
				() => this.page(this.state.page * Math.pow(2, pm))
			);
			
			break;
			
		default: // To shut up the transpiler
			break;

		}

	} // scaler


	getLastBarIndex(numberOfValuesPerBar)
	{
		return (numberOfValuesPerBar > 1 ? numberOfValuesPerBar - 1 : -1); // -1 so that it never matches and we don't indicate an end of range
	} // getLastBarIndex


	truncate(s, maxLength)
	{
		const l = s ? s.length : 0;
		return l > maxLength ? s.substring(0, maxLength) + '…' : s;
	
	} // truncate
	
	makeCaption(rangeBottom, rangeTop)
	{
		if (rangeTop === null) return this.truncate(rangeBottom, this.maxCaptionLength);
		else
		{
			const mcl = this.maxCaptionLength >> 1;
			let tmcl = mcl;
			if (rangeBottom.length < mcl) tmcl += mcl - rangeBottom.length;
			if (rangeTop.length < mcl) tmcl += mcl - rangeTop.length;
			return this.truncate(rangeBottom, tmcl) + "-" + this.truncate(rangeTop, tmcl);
		}
	} // makeCaption


	makeTitle(rangeBottom, rangeTop, count, numberOfValues, formatter)
	{
		const els = [formatter ? formatter(rangeBottom) : rangeBottom];
		if (rangeTop !== null)
		{
			els[0] = "From: " + els[0];
			els.push("To: " + (formatter ? formatter(rangeTop) : rangeTop));
		}

//		const n = els.length;
		els.push(`Count: ${count}`);
		els.push(`# Values: ${numberOfValues}`);
		
		return els.join("\n");
	} // makeTitle


/*
	getValueColour_DEPRECATED(v)
	{
		let c;
		if (!this.colours || !(c = this.colours[v])) return 2; // Green (if actually white)
		return (c & 3); // For now the summation (worst case)
		
	} // getValueColour
*/


	makeFormatter()
	{
//		let format;
		if (this.typeName === BACKEND_TYPE_DATE) return this.dateFormat ? (v) => this.dateFormat.format(v) : AqaComponent.formatDate;
		return null;
	} // makeFormatter


	computeNumberOfPages(nSamples)
	{
		const gSpan = (nSamples + 1) * NUMBER_OF_PIXELS_BETWEEN_BY_FREQUENCY_PINS;
		this.numberOfPages = 5 * (gSpan / this.usableWidth);
		if (this.numberOfPages !== Math.floor(this.numberOfPages)) this.numberOfPages = Math.floor(this.numberOfPages) + 1;
	} // computeNumberOfPages



	// Return the buckets!
	// In fact return a distribution with the max count and the buckets - ready to put in state.
	makeBuckets(scaleParam, typeName, needsRecalculation)
	{

		if (needsRecalculation)
		{
			this.distributionByCounts = null;
			this.distributionByContinuum = null;
		}

		let nSamples = this.distribution && this.distribution.buckets ? this.distribution.buckets.length : 0;

		if (nSamples === 0)
		{
			this.numberOfSamplesPerBucket = 0;
			this.numberOfPages = 0;
			return ({buckets:[], page:0});
		}

		let weHaveColours = this.colours != null;
		let rag, nRag;

		let i, distribution;
		let buckets;
		let sample;
		let maxCount = 0;
		let totalSamples = 0;
		let count;

		this.ticks = null;

		// Idea... get the colours at this stage? Most probably for counts of counts

// onsole.log("MAKING BUCKETS!! " + this.sortType);



		this.setup(this.state.width, this.state.height);


		switch(this.sortType)
		{
		case 0: // N1, N2 - in fact making a count of counts. Or... how many values have a count of n, for each count we have.


			this.barSpacing = NORMAL_BUCKET_SPACING;
			this.setup(this.state.width, this.state.height);		


			// Note that the distribution we get as a count for each different value.
			// we have samples, their counts, no buckets, or rather buckets with just one value.

			if (this.distributionByCounts !== null)
			{
				// Here's one I made earlier
// onsole.log("We have a distribution by counts");
				distribution = this.distributionByCounts;
			}
			else
			{

// onsole.log("We DO NOT have a distribution by counts");

				const countDistributionMap = {};
				let ce;


// How many values we have in each count


				for(i = 0; i < nSamples; i++)
				{
					sample = this.distribution.buckets[i];
					ce = countDistributionMap[sample.count];
					if (!ce) ce = 1;
					else ce++;
					countDistributionMap[sample.count] = ce;
				}


// Create a distri

				buckets = [];
				for(const [count, numberOfEntries] of Object.entries(countDistributionMap)) // Can never remember this syntax. 
				{
					buckets.push(
						{
							count:numberOfEntries,
							value:count,
							rawRangeBottom:count		
						}
					);
				}

//				buckets.map((b, i) => b.index = i);

				// Sort the buckets in DECREASING order.
				// Without this, the code further below would be completely off.
				buckets.sort((a, b) => b.count - a.count);

				distribution =
				{
					axis:this.distribution.axis,
					position:this.distribution.position,
//					maxCount, not needed me thinks.
					numberOfBuckets:buckets.length,
					buckets
				};
				this.distributionByCounts = distribution; // Cache it.

			}

			// Very important to re-assign: here we have redone the distribution.
			nSamples = distribution.buckets.length;
			break;

		case 1: // a, b, c - by value - buckets.
		

			this.barSpacing = NORMAL_BUCKET_SPACING;
			this.setup(this.state.width, this.state.height);		


			distribution = this.distribution;
			break;

			
		//////////////////// 2 returns values directly from this switch structure, no further processing below.
		case 2: // continuous, pins

			// For pins this may not be needed
			this.barSpacing = NORMAL_BUCKET_SPACING;
			this.setup(this.state.width, this.state.height);		

/*
{
	"count":1,
	"onValue":true,
	"rawRangeBottom":"zwf ECBD_1787",
	"rawRangeTop":"zwf ECBD_1787",
	"value":"zwf ECBD_1787"
}
*/

			// The scaling on continuous view is downright graphical.
			// AOTW we will allow it to be go 5 levels down. We aint Google's 14. (Or is it 12? Me and numbers...)
			this.maxScale = MAX_SCALE;
			this.numberOfPages = 0;
			buckets = this.distribution.buckets;



			let b, rag;

			switch(typeName)
			{
			case BACKEND_TYPE_NUMBER:
			case BACKEND_TYPE_DATE:

// onsole.log("DATE ET NOMBRES");

				for(let i = 0; i < nSamples; i++) 
				{
					b = buckets[i];
//					b.index = i;
					count = b.count;
					if (count > maxCount) maxCount = count;
					totalSamples += count;
					b.rag = this.colours && (rag = this.colours[b.rawRangeBottom]) ? rag & 3 : 2;
				}



				switch(this.order)
				{
				case 0: // by value

// onsole.log("Par valeurs");

					this.boundaries = [buckets[0].rawRangeBottom, buckets[nSamples - 1].rawRangeBottom];
					this.dataSpan = this.boundaries[1] - this.boundaries[0];

					break;

				case 1: // by frequency

					// We need to clone the buckets.
					if (buckets)
					{
						const bucketsToSort = new Array(nSamples);
						buckets.forEach((b, i) => bucketsToSort[i] = b);

						// This is where the back end should send a number
						// NOT a string. We're climate warming like crazy here for not much!!
						// Although the formatting is a necessay evil. (But the conversion... grrr...)
						if (BACKEND_TYPE_NUMBER === typeName)
						{
							bucketsToSort.forEach(b => b.value = AqaComponent.abreviateNumber(Number.parseFloat(b.value)));
						}

						buckets = bucketsToSort;
						buckets.sort((a, b) => b.count - a.count);
						if (BACKEND_TYPE_DATE === typeName) buckets.forEach(b => b.value = this.dateFormat ? this.dateFormat.format(parseInt(b.rawRangeBottom)) : AqaComponent.formatDate(parseInt(b.rawRangeBottom)));
					}

					/*
					const gSpan = (nSamples + 1) * NUMBER_OF_PIXELS_BETWEEN_BY_FREQUENCY_PINS;
					this.numberOfPages = 5 * (gSpan / this.usableWidth);
					if (this.numberOfPages !== Math.floor(this.numberOfPages)) this.numberOfPages = Math.floor(this.numberOfPages) + 1;
					*/
					
					this.computeNumberOfPages(nSamples)
					
					
					break;

				default: // To shut up the transpiler
					break;

				}



				if (buckets) buckets.forEach((b, i) => b.index = i);


				// And we're passing the buckets back.
				return (
					{
						maxCount,
						maxPc: maxCount * 100 / totalSamples,
						buckets,
						page:0
					}
				);



			case BACKEND_TYPE_STRING:


				let val;		
				const intervals = [];



				switch(this.order)
				{
				case 0: // by value
					// We use the buckets but really they're an interval object
					// which we made bucket-ish compatible.
					// the big difference is that an interval can contain
					// other subservient intervals
					// as organised by the fusing step to order and
					// position String values.

					for(let i = 0; i < nSamples; i++) 
					{
						b = buckets[i];
						b.index = i;
						val = b.rawRangeBottom;

						count = b.count;
						if (count > maxCount) maxCount = count;
						totalSamples += count;

						intervals.push
						(
							{
								workval:val,
								intervals:[],
								count,
								value:b.value,
								rag: this.colours && (rag = this.colours[val]) ? rag & 3 : 2
							}
						);
					}


					// We don't have boundaries - Strings by value are a tad more complicated to handle.
					this.boundaries = null; 


					// And we're passing the buckets back.
					return (
						{
							maxCount,
							maxPc: maxCount * 100 / totalSamples,
							buckets:this.fuseIntervals(intervals, false), // This is where the string specific, by value, x-plotting occurs.
							page:0
						}
					);
					
				case 1: // by Frequency

// onsole.log("Doing strings by frequency");

					for(let i = 0; i < nSamples; i++) 
					{
						b = buckets[i];
						b.index = i;
						val = b.rawRangeBottom;

						count = b.count;
						if (count > maxCount) maxCount = count;
						totalSamples += count;
						
						b.rag = this.colours && (rag = this.colours[val]) ? rag & 3 : 2;

					}

					// Sorting them
					buckets.sort((a, b) => b.count - a.count);

					this.boundaries = [0, buckets.length];
					this.dataSpan = buckets.length;

// onsole.log("Done boundaries: " + JSON.stringify(this.boundaries));

/*
onsole.log("SORTING BUCKETS!!");
onsole.log(AqaComponent.prettifyJson(buckets));
onsole.log("n + 1: " + (nSamples + 1));
*/

					/*
					const gSpan = (nSamples + 1) * NUMBER_OF_PIXELS_BETWEEN_BY_FREQUENCY_PINS;
// console .log("gSpan: " + gSpan);					
					this.numberOfPages = 5 * gSpan / this.usableWidth;
// console .log("NoP 1: " + this.numberOfPages);
// Ca devrait etre une puissance de 2 ca...
					if (this.numberOfPages !== Math.floor(this.numberOfPages)) this.numberOfPages = Math.floor(this.numberOfPages) + 1;
// console .log("NoP 2: " + this.numberOfPages);
					*/


					this.computeNumberOfPages(nSamples);


					// And we're returning the buckets.
					return (
						{
							maxCount,
							maxPc: maxCount * 100 / totalSamples,
							buckets, // these are proper buckets.
//							page:0
						}
					);
					
				default: // To shut up the transpiler
					break;


				} // switch on order type

				break; // To shut up the transpiler

			default: // To shut up the transpiler
				break;

			} // switch on type within sort type 2
			break; // To shut up the transpiler

		case 3: // By buckets whose samples are grouped by value range

// onsole.log("I am doing CONTINUOUS buckets");

			this.maxScale = 1;
			this.numberOfPages = 1;

			switch(typeName)
			{
			
				case BACKEND_TYPE_NUMBER:
				case BACKEND_TYPE_DATE:

// onsole.log("I am doing the CONTINUOUS buckets for: " + typeName);			
			
				this.barSpacing = CONTINUOUS_BUCKET_SPACING;
				this.setup(this.state.width, this.state.height);

				if (this.distributionByContinuum)
				{
					// If we have the distribution, we have the tick cache.
					this.ticks = this.continuumTicks;
					return this.distributionByContinuum; // We already have a suitable one.
				}

				buckets = this.distribution.buckets;

				const floor = DistributionGraph.parsers[typeName](buckets[0].rawRangeBottom);

				this.boundaries = [floor, DistributionGraph.parsers[typeName](buckets[nSamples - 1].rawRangeBottom)];

// onsole.log("Boundaries");
// onsole.log(JSON.stringify(this.boundaries));


				this.dataSpan = DistributionGraph.parsers[typeName](this.boundaries[1]) - floor;
// onsole .log("Dataspan before: " + this.dataSpan);

/*
				let deviator = this.dataSpan * 5;
				deviator /= 100;
				this.dataSpan += deviator;
*/			
				
// onsole.log("deviator: " + deviator);

				
// onsole.log("Data Span after: " + this.dataSpan);

				const numberOfBuckets = this.maxNumberOfShowableBars;
				
// onsole.log("numberOfBuckets: " + numberOfBuckets);
				

				const bucketDataSpan = this.dataSpan / numberOfBuckets;
				
// onsole.log("bucketDataSpan: " + bucketDataSpan);

				if (numberOfBuckets === 0) return ({buckets:[], page:0});

				const continuumBuckets = Array(numberOfBuckets);
				
				
//				const bucketDataWidth = this.dataSpan / numberOfBuckets;

				let value; // , lastValue;
				// initialising the buckets
				for(i = 0; i < numberOfBuckets; i++)
				{
					value = floor + bucketDataSpan * i;
					
					
// onsole.log("----> Value: " + value);				
					
//					lastValue = value + bucketDataSpan;
					

					continuumBuckets[i] =
					{
						count:0,
						numberOfSamples:0,
						rawRangeBottom: value,
						rawRangeTop: value + bucketDataSpan,
						selected:false,
						rag:2, // Green, not knowing otherwise.
						index:i
					};
				}

//				if (BACKEND_TYPE_DATE === typeName) continuumBuckets.forEach(b => b.value = this.dateFormat ? this.dateFormat.format(parseInt(b.rawRangeBottom)) : AqaComponent.formatDate(parseInt(b.rawRangeBottom)));
// distribution = this.distribution;

				let bucket;
				let bix;
				totalSamples = 0;

/*
onsole.log("----> nSamples: " + nSamples);
onsole.log("Number of buckets: " + numberOfBuckets);
onsole.log("value: " + value);
onsole.log("floor: " + floor);
onsole.log("bucketDataSpan: " + bucketDataSpan);
*/


				for(i = 0; i < nSamples; i++)
				{
					sample = buckets[i];
					value = DistributionGraph.parsers[typeName](sample.rawRangeBottom);

					// Bearing in mind we have buckets from the server that have only one sample each.

// onsole.log("################ " + Math.floor((value - floor) / bucketDataSpan))

					bix = bucketDataSpan === 0 ? 0 : Math.floor((value - floor) / bucketDataSpan);

					if (bix >= numberOfBuckets) bix = numberOfBuckets - 1; // This should never happen but in case my x% added didn't work for precision reasons.

// onsole.log("bix: " + bix);
					
					bucket = continuumBuckets[bix];

					bucket.count += sample.count;
					bucket.numberOfSamples++;
					totalSamples += bucket.count;

// onsole.log("!!!!!!!!!!!!!!!!!!!!" + bucket.numberOfSamples);

					if (weHaveColours && bucket.rag !== 0) // We can't go any lower
					{
						if ((nRag = this.colours[sample.rawRangeBottom]))
						{
							nRag &= 3;
							if (nRag < bucket.rag) bucket.rag = nRag;
						}
					}

//

				}


				
// onsole.log("Buckets");

				// Finishing off the buckets
				maxCount = 0;
				for(i = 0; i < numberOfBuckets; i++)
				{
				
				
					bucket = continuumBuckets[i];

// onsole.log(JSON.stringify(bucket));

					if (bucket.count > maxCount) maxCount = bucket.count;
					//                  makeTitle(rangeBottom, rangeTop, count, numberOfValues)
				 	bucket.title = this.makeTitle(bucket.rawRangeBottom, bucket.rawRangeTop, bucket.count, bucket.numberOfSamples, this.makeFormatter());

// onsole.log("BOT TOP [" + bucket.rawRangeBottom + " | " + bucket.rawRangeTop + "]");
				 	
				 	bucket.caption = bucket.rawRangeBottom; // this.makeCaption(bucket.rawRangeBottom, bucket.rawRangeBottom);
//				 	bucket.index = i;

				}


				this.distributionByContinuum =
				{
					maxCount,
					maxPc: maxCount * 100 / totalSamples,
					buckets:continuumBuckets, // these are proper buckets.
					page:0
				};



/*
this.boundaries[]
this.dataSpan
this.usableWidth
this.leftCaptionWidth
*/


				this.ticks = [];
				this.ticks.push(new RecurrentTick(this.graphPadding + this.leftCaptionWidth - this.usableWidth, this.boundaries[0] - this.dataSpan, false, this.leftCaptionWidth + this.graphPadding + this.usableWidth,        this.boundaries[1], false, typeName, this.dateFormat));


				if (!Number.isNaN(this.dataSpan) && this.dataSpan > 0)
				{
					this.ticks.push(new RecurrentTick(this.graphPadding + this.leftCaptionWidth,                    this.boundaries[0],                 false, this.leftCaptionWidth + this.graphPadding + (this.usableWidth << 1), this.boundaries[1] + this.dataSpan, false, typeName, this.dateFormat));
					this.ticks.push(new RecurrentTick(this.graphPadding + this.leftCaptionWidth,                    this.boundaries[0],                  true, this.leftCaptionWidth + this.graphPadding + this.usableWidth,        this.boundaries[1], true, typeName, this.dateFormat));
					
					this.continuumTicks = this.ticks;
					
				}

				// And we're returning the buckets.
				return this.distributionByContinuum;

			case BACKEND_TYPE_STRING:


				break;
				
				
			default: // To shut up the transpiler
				break;


			} // data Type switch

			break;


		default: // To shut up the transpiler
			break;

		} // switch on sortType


		let numberOfBuckets;
		let numberOfSamplesPerBucket;

		if (nSamples <= this.maxNumberOfShowableBars)
		{
			numberOfBuckets = nSamples;
			numberOfSamplesPerBucket = 1;
			this.maxScale = 1;
		}
		else
		{
			// As many buckets as we can display
			let n1 = Math.floor(nSamples / this.maxNumberOfShowableBars);

			// Limit our scale.
			// What power of our base Diviser is n1?
			// diviser ^ 5 is enough to have five scales
			// diviser ^ 4 is enough to have 4 scales
			// Etc.

			let numberOfDivisors = Math.log(n1) / Math.log(BASE_DIVISOR);

			if (numberOfDivisors >= 5) this.maxScale = MAX_SCALE;
			else
			{
				if (numberOfDivisors < 1) numberOfDivisors = 1;
				this.maxScale = Math.floor(numberOfDivisors + .5); // Yes I round up...
			}

/*
if (this.props.position === 0)
{
	onsole.log("Number of divisors: " + numberOfDivisors);
	onsole.log("... MAX_SCALE: " + this.maxScale);
	onsole.log("... N SMAPLES: " + nSamples);
	onsole.log("... scale: " + this.scale);
	onsole.log("... Max Bars: " + this.maxNumberOfShowableBars);
	onsole.log("... n1: " + n1);
}
*/

//			Linear progression of number of samples per bar
//			let delta = -(n1 - 1);
//			numberOfSamplesPerBucket = Math.floor(n1 + ((delta * (this.scale - 1)) / (this.maxScale - 1)));

//			Inverse progression of number of samples per bar
//			let divisor = 1 + (((n1 - 1) * (this.state.scale - 1)) / (this.maxScale - 1));

			// Exponential progression of divisor of number of samples per bar
			let divisor;
			if (this.maxScale > 1) divisor = Math.pow(n1, (scaleParam - 1) / (this.maxScale - 1));
			else divisor = n1;


			numberOfSamplesPerBucket = Math.floor(n1 / divisor);

			numberOfBuckets = Math.floor(nSamples / numberOfSamplesPerBucket);
		}

//////////////////////////

		this.numberOfSamplesPerBucket = numberOfSamplesPerBucket;

		const baseNumberOfValuesPerBucket = Math.floor(nSamples / numberOfBuckets); // This should be a minimum of 1.
		const leftOver = nSamples % numberOfBuckets;

		let numberOfValuesPerBucket = baseNumberOfValuesPerBucket + 1; // We are assuming we HAVE some leftovers (It's going to be rectified in the bar loop in any case)
		let lastInBar = this.getLastBarIndex(numberOfValuesPerBucket);

		buckets = Array(numberOfBuckets); /* count, caption, rag */
		let p = 0, j; // Index into our full set of samples as they come in the distribution.


		let rangeBottom, rangeTop;
		let rawRangeBottom, rawRangeTop;


//		let countBottom, countTop;


// TODO Colours for counts need to be taken from the relevant VQD!!
if (this.sortType === 0) weHaveColours = false; // Temporary I don't know where to get colours for frequencies just yet.
                                               // Not to mention the fact that on a partial table it's different.
                                               // although the colours should have it!!



		for(i = 0; i < numberOfBuckets; i++)
		{
			if (i === leftOver)
			{
				// Following buckets have 1 less value as we have run out of left overs.
				numberOfValuesPerBucket = baseNumberOfValuesPerBucket;
				lastInBar = this.getLastBarIndex(numberOfValuesPerBucket);
			}

			count = 0;
			rangeTop = null;
			rawRangeTop = null;
			rag = 2; // ie. green


//			let countBottom = 0, countTop = 0;

			for(j = 0; j < numberOfValuesPerBucket; j++)
			{
				sample = distribution.buckets[p++];
				
				
				if (weHaveColours && rag !== 0) // We can't go any lower
				{
//					nRag = this.colours[sample.rawRangeBottom]; // this.getValueColour(sample.rawRangeBottom);
					if ((nRag = this.colours[sample.rawRangeBottom]))
					{
						nRag &= 3;
						if (nRag < rag) rag = nRag;
					}
				}

				count += sample.count;

				if (j === 0)
				{
					rangeBottom = sample.value;
					rawRangeBottom = sample.rawRangeBottom;
//					countBottom = sample.count;
				}
				else if (j === lastInBar)
				{
					rangeTop = sample.value;
					rawRangeTop = sample.rawRangeBottom; // Yes bottom, because we don't have buckets, we have actual samples. And we're sure bottom is set.
//					countTop = sample.count;
				}
			}

			if (count > maxCount) maxCount = count; // We'll need it for scale.
			totalSamples += count;

// rag={this.getValueColour(b.rawRangeBottom)}

			buckets[i] = (
				{
					count,
					caption:this.makeCaption(rangeBottom, rangeTop),
//					title: rangeTop === null ? rangeBottom : rangeBottom + "-" + rangeTop,
					title: this.makeTitle(rangeBottom, rangeTop, count, numberOfValuesPerBucket, this.makeFormatter()),
					rawRangeBottom,
					rawRangeTop,
//					countBottom,
//					countTop,
					rag,
					selected:false,
					index:i
				}
			);
		}

//		this.maxCount = maxCount;

// .rawRangeBottom, bucketComponent.props.bucket.rawRangeTop


//////////////////////////

		if (this.maxNumberOfShowableBars !== 0)
		{
			this.numberOfPages = Math.floor(numberOfBuckets / this.maxNumberOfShowableBars);
			if (this.numberOfPages * this.maxNumberOfShowableBars < numberOfBuckets) this.numberOfPages++;
		}
		else this.numberOfPages = 0;





		// And we're passing the buckets back.
		return (
			{
				maxCount,
				maxPc: maxCount * 100 / totalSamples,
				buckets,
//				page:0
			}
		);
	
	
	} // makeBuckets




	// Returns the max length of the common prefix of values in the intervals.
	commonPrefixOfSuite(intervals, start, end)
	{
	
			let i, d, c;
			
			// this finds the maximal prefix length for the whole set, not just between two strings.
			for(d = 0; ; d++)
			{
				
				for(i = start; i < end; i++)
				{
					if (intervals[i].workval.length === d) break;
					if (i === start) c = intervals[start].workval.charAt(d);
					else if (c !== intervals[i].workval.charAt(d)) break;
				}
				if (i < end) break;
			}

			return d;
	
	} // commonPrefixOfSuite




	fuseIntervals(intervals, fruncate)
	{
		let n;
		if (!intervals || (n = intervals.length) < 2) return intervals;

		let i, d;
	
		// Remove the common prefix?
		if (fruncate)
		{
			// Fruncate. Yes, it's a word. Truncate is at the back. We're frunkating, deal with it. Don't confuse it with frunikation which isn't held in high regards by some organised religions outside of a traditional pairing.
			d = this.commonPrefixOfSuite(intervals, 0, n);
			if (d > 0) for(i = 0; i < n; i++) intervals[i].workval = intervals[i].workval.substring(d);
		}

		const fusedIntervals = [];
//		let prefix;
		
		
		fusedIntervals[0] = intervals[0];
//		let previousPrefix = "";
		let lastInterval = fusedIntervals[0];

		// Now we accumulate as long as we have a common prefix, whatever its length
		for(i = 1; i < n; i++)
		{
			if (this.commonPrefixOfSuite(intervals, i - 1, i + 1) === 0)
			{
				lastInterval = intervals[i];
				fusedIntervals.push(lastInterval);
			} 
			else
			{
				lastInterval.intervals.push(intervals[i]);
			}
		}

		// And recursively, we separate the intervals again.
		n = fusedIntervals.length;
		for(i = 0; i < n; i++) fusedIntervals[i].intervals = this.fuseIntervals(fusedIntervals[i].intervals, true);
		return fusedIntervals;

	} // fuseIntervals


	filter(bucketComponent)
	{

		if (this.state.selectedBucketIndex === bucketComponent.props.bucket.index)
		{

			// That's a deselect
			this.props.controller.removeFilter
			(
				this.props.position,
				() => this.setState({selectedBucketIndex:-1})
			);
			return;
		}


		this.selectedBucket = bucketComponent;
/*
onsole.log("FILTERING:\n---------");
onsole.log("  1: " + bucketComponent.props.bucket.rawRangeBottom);
onsole.log("  2: " + bucketComponent.props.bucket.rawRangeTop);
*/

// onsole.log("About to filter on: [" + bucketComponent.props.bucket.rawRangeBottom + " | " + bucketComponent.props.bucket.rawRangeTop + "]");


		this.setState
		(
			{
				selected:false, // that's the red area betwen needles
				selectedBucketIndex: bucketComponent.props.bucket.index
			}, 
			() =>
			this.props.controller.filterOnColumn
			(
				this.props.position,
				this.sortType,
				bucketComponent.props.bucket.rawRangeBottom,
				bucketComponent.props.bucket.rawRangeTop
			)
		);

	} // filter

	// Used for the continuous view
	getScaleMultiplier(scale) { return Math.pow(2, (scale - 1)); } // 1 - 16

	changeSortType(newSortType)
	{
// onsole.log(newSortType);
		this.sortType = newSortType;	
		const distributionData = this.makeBuckets(1, this.typeName, false);
		distributionData.page = 0;
		distributionData.scale = 1;
		distributionData.selected = false;
		
		this.setState
		(
			distributionData,
			() => this.props.controller.removeFilter(this.props.position)
		);
// this.props.controller.changeSelectType(this.props.position, 0)
	
	} // changeSortType



	changeSortTypeAndOrder(newSortType, newOrder) // Written on a blue Monday
	{
// onsole.log(newSortType);
		this.sortType = newSortType;
		this.order = newOrder;

// onsole.log("CSTO: " + newSortType + " & " + newOrder);

		const distributionData = this.makeBuckets(1, this.typeName, false);
		distributionData.page = 0;
		distributionData.scale = 1;
		distributionData.selected = false;
		
		this.setState
		(
			distributionData,
			() => this.props.controller.removeFilter(this.props.position)
		);
// this.props.controller.changeSelectType(this.props.position, 0)
	
	} // changeSortTypeAndOrder

	flip()
	{
		if (this.state.buckets)
		{
			this.state.buckets.reverse();
			this.state.buckets.forEach((e, i) => e.index = i);
			this.forceUpdate();
		}
	} // flip


	getNeedlePos(which) { return this.needles[which].getPosition(); }

	makeNeedleState()
	{
		const newState = {};
		newState.selX = this.leftCaptionWidth + this.graphPadding + this.getNeedlePos(0);
		newState.selW = this.getNeedlePos(1) - this.getNeedlePos(0);
		return newState;
	} // makeNeedleState

	select(b)
	{
		let newState;

		if (b) newState = this.makeNeedleState();
		else newState = {};

		newState.selected = b === true;

		this.setState(newState);

		if (b) this.needleFilter();
		else this.props.controller.removeFilter(this.props.position); // Removing any filter.
		
		this.needleBox.setFiltered(b);
		
	} // select


	toggleFilter()
	{
		const newFilteredState = !this.state.selected;
		this.select(newFilteredState);
		return newFilteredState;
	} // togleFilter

	needleFilter() // the caller should add the slide. So we get an un-slid x
	{
	
//	this.needles

		const pixelsSpan = this.needles[0].getSpan(); // Both needles should have the same span so we need only ask one.
		const val = Array(2);

		for(let i = 0; i < 2; i++) val[i] = this.pixelsToDataUnits(this.needles[i].getAbsoludePosition(), pixelsSpan, this.dataSpan);

		this.props.controller.filterOnColumn
		(
			this.props.position,
			this.sortType,
			"" + val[0],
			"" + val[1]
		);



	} // needleFilter

	adjustNeedleBox(bx, value, span) { if (this.needleBox) this.needleBox.adjustNeedleBox(bx, this.pixelsToDataUnits(value, span, this.dataSpan)); } 

	updateSelectedZone()
	{
		if (this.state.selected) 
		{
			
			this.setState
			(
				{selected:false},
				() => this.props.controller.removeFilter(this.props.position)
			);
			this.needleBox.setFiltered(false);

//		IF we were allowing a selection to be kept while changing its needles		
//		this.setState(this.makeNeedleState());
		}
	} // updateSelectedZone





	// WTF Javascript??? How's my data span turned into a string?????????
	pixelsToDataUnits(position, pixelsSpan, dataSpan) { return (position * dataSpan / pixelsSpan + Number(this.boundaries[0])); }

	dataValueToPixels(v, graphicalSpan)
	{
	
// onsole.log("v: " + v + " dataspan: " + this.dataSpan + "  gSpan: " + graphicalSpan);
// onsole.log(JSON.stringify(this.boundaries));
	
		if (!this.boundaries) return 0; // Condition experinced the big 100k file.
		return (v - this.boundaries[0]) * graphicalSpan / this.dataSpan;
	} // dataValueToPixels

	zoner(zone, from, to)
	{
		if (!zone || zone.colour === "green") return null; // We don't draw green for now anyway.
	
		let [x0, x1] = zone.limits;
	
		if (x1 < from || x0 > to) return null;
		if (x0 < from) x0 = from;
		if (x1 > to) x1 = to;

// onsole.log("DOING ZONE:");
// onsole.log(AqaComponent.prettifyJson(zone));
// onsole.log("[" + x0 + " - " + x1 + "]");

		return (
			<rect
				key={`z${this.zoneCC++}`}
				x={x0}
				y={this.captionHeight}
				width={x1 - x0}
				height={this.usableHeight - 1}
				className={`${zone.colour}Zone`}
			/>
		);

	} // zoner

	getNeedle(which) { return this.needles[which]; }

	setNeedleValue(nx, val)
	{
		if (val < this.needleBoxesValues[0] || val > this.needleBoxesValues[1]) return false;
		const position = this.dataValueToPixels(val, this.needles[0].getSpan());
		this.needles[nx].moveAbsolute(position, false);

		return true;
	} // setNeedleValue



	// SVG: https://codepen.io/mdgrover/pen/eZENxO?editors=1000
	render()
	{
//		const {sortType}=this.state;
		if (this.state.buckets === null || !this.state.maxCount) return <></>;

		// Backend objects of interest:
		// ---------------------------

		// AqaDistribution
		// AqaDistributionBucket

		const svgStyle =
		{
			width:  `${this.state.width}px`,
			height: `${this.state.height-5}px`,
			margin: `0 ${DistributionGraph.RIGHT_MARGIN}px 0 ${DistributionGraph.LEFT_MARGIN}px`,
			float:  this.state.float
// ,border:"1px solid red"
		};

		// Pre-calculations
//		const barHorizontalFootprint = this.barSpacing + this.barWidth;
//		const bucketNamesXOffset     = this.leftCaptionWidth - this.barWidth / 2; // For the bottom bucket legends
		const bucketNamesY           = this.captionHeight + this.usableHeight + this.verticalClearing;
//		const paginatorAnchor        = this.leftCaptionWidth + (this.usableWidth >> 1); // to center
		const paginatorAnchor        = this.leftCaptionWidth + this.graphPadding + this.usableWidth; // to align right

		const sclalatorAnchor        = this.leftCaptionWidth + this.graphPadding + (this.usableWidth >> 1); // to center
		let scaleMultiplier; // For continuous view only
		let continuousSlide = 0;

		let buckets, captions;
		let n = this.state.buckets.length;
		let x0, span;
//		let graphicalSpan;

		let zones = [];
//		let needleBoxesValues = [0, 0];

		switch(this.sortType)
		{
		case 0:
		case 1:
		case 3:
			let startBucket = this.maxNumberOfShowableBars * this.state.page;
			let endBucket = startBucket + this.maxNumberOfShowableBars;
			if (endBucket > n) endBucket = n;

			n = endBucket - startBucket;
			if (n < 0) n = 0;
			buckets  = Array(n);
			captions = Array(n);

			for(let p = startBucket, i = 0; i < n; i++, p++)
			{
				buckets[i]  = this.state.buckets[p];
				captions[i] = this.state.buckets[p].caption; // this.captions[p];
			}
			break;

		case 2:

				continuousSlide = (this.usableWidth * this.state.page) / 4; // this is the crux of it. Each of my pages is a QUARTER of the available span.
				x0 = this.leftCaptionWidth + this.graphPadding - continuousSlide;
				buckets = this.state.buckets;

				switch(this.order)
				{
				case 0: // by value


					
					scaleMultiplier = this.getScaleMultiplier(this.state.scale);
//				graphicalSpan = this.state.width - this.leftCaptionWidth - this.rightCaptionWidth;

// this.usableWidth  =    width - this.leftCaptionWidth - this.rightCaptionWidth;

					span = this.usableWidth * scaleMultiplier;
	//				zones = this.makeColourZones(this.typeName, x0, span);


					zones = AqaBoundaries.makeColourZones
					(
						this.vqd,
						this.typeName,
						v => x0 + this.dataValueToPixels(v, span),
						this.boundaries
					);


// onsole.log("Dumping zones:");				
// onsole.log(AqaComponent.prettifyJson(zones));


					if (BACKEND_TYPE_STRING !== this.typeName)
					{
						this.needleBoxesValues[0] = this.pixelsToDataUnits(continuousSlide, span, this.dataSpan);
						this.needleBoxesValues[1] = this.pixelsToDataUnits(continuousSlide + this.usableWidth, span, this.dataSpan);
					}
					
					
					break;

/*				
onsole.log("PAGING");
onsole.log("  Scale: " + this.state.scale);
onsole.log("  scaleMultiplier: " + scaleMultiplier);
onsole.log("  Span: " + span);
onsole.log("  Page: " + this.state.page);
onsole.log("  continuousSlide: " + continuousSlide);
onsole.log("  X0: " + (this.leftCaptionWidth - continuousSlide));
*/
	
				case 1: // by frequency
				
					//	TBD
				
	
					break;

				default: // to shupt up the transpiler
					break;
				}
				
				

				break;

			default: // To shut up the transpiler
				break;
		}



		const pos = `${this.state.page + 1} / ${this.numberOfPages}`; // Page indicator for user

//		const topNavY = this.captionHeight; // this.verticalClearing + this.captionFontSize; // (this.textFontSize >> 1);

// Why was + 100 added?!??
		const rightCaptionX =  this.state.width - this.rightCaptionWidth;

//		const rightCaptionY0 =  this.captionHeight + this.usableHeight+2;
//		const rightCaptionYMax =  this.captionHeight;


// key={`dghb${DistributionGraph.#serial++}`}

//		const sortTypeTs =    {fontFamily:this.captionFontFamily, fontSize:this.captionFontSize}; // , color:"#00c"
//		const selectedStyle = {fill:"rgb(0,0,102)", textTransform:"uppercase", fontWeight:"bold"}; // fontWeight:"bold"

/*
		const n1n2Style = AqaComponent.conflate(sortTypeTs, this.sortType === 0 ? selectedStyle : null);		
		const abcStyle = AqaComponent.conflate(sortTypeTs, this.sortType === 1 ? selectedStyle : null);
		const cntStyle = AqaComponent.conflate(sortTypeTs, this.sortType === 2 ? selectedStyle : null);
*/


//		const paginatorelementsWidth = 60;


		// Making sure we won't get 0% 0% 0% 0% percentage scale.

		const nLegs = this.yScale.length;
		let pcLegs = Array(nLegs);
		let absoluteLegs = Array(nLegs);
		const scaleYs = Array(nLegs);

		for(let i = 0; i < nLegs; i++)
		{
			pcLegs[i] = this.state.maxPc * this.yScale[i] / this.numberOfPercentTicks;
//			absoluteLegs[i] = AqaComponent.formatBigNumber(Math.floor(this.state.maxCount * this.yScale[i] / this.numberOfPercentTicks));
			absoluteLegs[i] = this.state.maxCount * this.yScale[i] / this.numberOfPercentTicks;
			scaleYs[i] = this.captionHeight + this.usableHeight - this.yScale[i] * this.spaceBetweenPcMarkers;
		}


//		let allSame;
//		let legTest, pc, fpc;
//		let fixer;


		pcLegs = AqaComponent.getEnoughDecimalsForListOfValues(pcLegs);
		absoluteLegs = AqaComponent.getEnoughDecimalsForListOfValues(absoluteLegs);
		absoluteLegs.forEach((v, i, a) => a[i] = AqaComponent.formatBigNumber(v));

		pcLegs[0] = "0";
		absoluteLegs[0] = "0";
		absoluteLegs[nLegs - 1] = this.state.maxCount;

		/*
		// possible opti - but I'd need two loops for the vertical ticks
		let absoluteSaleYs;
		if (this.state.maxCount < nLegs)
		{
			absoluteLegs = [0, this.state.maxCount];
			let y0 = this.captionHeight + this.usableHeight;
			absoluteSaleYs = [y0, y0 - this.yScale[nLegs - 1] * this.spaceBetweenPcMarkers];
		}
		else
		{
			absoluteLegs = getEnoughDecimalsForListOfValues(absoluteLegs);
			absoluteSaleYs = scaleYs;
		}
		*/


		const firstAndLastBuckets = [null, null];
		// I know it's not subtle and it can probably be improved
		if (this.sortType === 2 && this.order === 0)
		{

// onsole.log("PRE CALCULATING POSITIONING - cs: " + continuousSlide);		

			new DistributionGraphPinSet
			(
				{
					controller:this,
					buckets,
					span,
					x:x0,
					from:this.leftCaptionWidth + this.graphPadding,
					to:rightCaptionX,
					boundaries:this.boundaries,
					pinWatch:(bucket) =>
					{
						if (firstAndLastBuckets[0] === null) firstAndLastBuckets[0] = bucket;
						else firstAndLastBuckets[1] = bucket;
					},
					order:0, // obviously
					typeName:this.typeName
				}
			).render();
			if (firstAndLastBuckets[1] === null) firstAndLastBuckets[1] = {value: ""}; // in case we have only one value.
		}
		else
		{
			const nBuckets = this.state.buckets.length;
			if (nBuckets > 0)
			{
				firstAndLastBuckets[0] = this.state.buckets[0];
				firstAndLastBuckets[1] = this.state.buckets[n - 1];
			}
			else
			{
				firstAndLastBuckets[0] = firstAndLastBuckets[1] = {value: ""};
			}
		}

		const fixedSpacingBetweenPins = NUMBER_OF_PIXELS_BETWEEN_BY_FREQUENCY_PINS;

		const nkbdWidth = 460;
		const nkbdHeight = 40;



		let showBuckets = false;
		let showPins = false;


// this.sortType === 2 && this.state.order === 0

		if (this.sortType === 0 || (this.sortType === 1 && this.order === 0) || this.sortType === 3) showBuckets = true;
		else showPins = true;

// onsole.log("Number of pages: " + this.numberOfPages);
// onsole.log("POS: " + pos);


		// To limit the views we can have with Strings
		const sort_buttons_to_use = BACKEND_TYPE_STRING === this.typeName ? SORT_BUTTONS_FOR_STRINGS : SORT_BUTTONS;


		// Radio doc:
		// https://mui.com/components/radio-buttons/
		

		return (
			<svg key={"dg_"+this.props.position} style={svgStyle}>

				<pattern id="selectFill" patternUnits="userSpaceOnUse" width="4" height="4">
						<path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2" className="band-selector-fill" />
				</pattern>


				<foreignObject x={52} y={0} width={this.state.width} height={32}>

					<RadioGroup aria-label="sortType" name="sortType" value={"" + this.radioIndex} defaultValue="Continuous" color="primary" onChange={this.handleRadioChange} row={true}>
						{
							sort_buttons_to_use.map
							(
								(b, i) =>
								<FormControlLabel
									key={`dgbk${i}`}
									value={"" + b[0]}
									control={<Radio color="primary" style={{padding:"4px"}} />}
									label={
										(
											<Typography className={"aqa-text-action"} variant="title" align="left" style={{}}>
												<span style={{float:"left"}}>{b[1]}</span>
											</Typography>
										)
									}
									style={{width:"160px",margin:"0"}}
								/>
							)
						}
					</RadioGroup>

				</foreignObject>

				{
					showBuckets
					?
						zones.map(z => this.zoner(z, this.leftCaptionWidth + this.graphPadding, rightCaptionX))
					:
						null
				}

				{
					this.gridRegionsYs.map
					(
						y => <line key={`dghl${DistributionGraph.#serial++}`} x1={this.leftCaptionWidth} y1={y} x2={this.leftCaptionWidth + (this.graphPadding << 1) + this.usableWidth} y2={y} style={{stroke:GRID_AND_TICK_COLOUR, strokeWidth:"1"}} shapeRendering="crispEdges" />
					)
				}


				{
					this.gridRegionsXs.map
					(
						x => <line key={`dgvl${DistributionGraph.#serial++}`} x1={x} y1={this.captionHeight} x2={x} y2={this.captionHeight + this.usableHeight} style={{stroke:GRID_AND_TICK_COLOUR, strokeWidth:"1"}} shapeRendering="crispEdges" />
					)
				}

				{
					this.numberOfPages > 1
					?
						<>
							<text className="svg-clickable" y={this.topCaptionClearing} x={paginatorAnchor - 70} style={{textAnchor:"end", fontFamily:this.captionFontFamily, fontSize:this.textFontSize}} onClick={() => this.pager(-1)}>←</text>
							<text y={this.topCaptionClearing} x={paginatorAnchor - 40} style={{textAnchor:"middle", fontFamily:this.captionFontFamily, fontSize:this.textFontSize}}>{pos}</text>
							<text className="svg-clickable" y={this.topCaptionClearing} x={paginatorAnchor} style={{textAnchor:"end", fontFamily:this.captionFontFamily, fontSize:this.textFontSize}} onClick={() => this.pager(1)}>→</text>
						</>
					:
						null
				}


				{/* This is the scaler - out of commission for now */}
				{/*
					this.maxScale > 1
					?
						<>
						
							<text className="svg-clickable" y={this.topCaptionClearing} x={sclalatorAnchor - 10} style={{textAnchor:"middle", fontSize:"16px",fontFamily:this.captionFontFamily}} onClick={() => this.scaler(-1)}>-</text>
							<text className="svg-clickable" y={this.topCaptionClearing} x={sclalatorAnchor + 10} style={{textAnchor:"middle", fontSize:"16px",fontFamily:this.captionFontFamily}} onClick={() => this.scaler(1)}>+</text>
						</>
					:
						null
				*/}



				{
					this.sortType === 2
					?
						<>
							<text className="svg-clickable" y={this.topCaptionClearing} x={sclalatorAnchor} style={{textAnchor:"middle", fontSize:"16px",fontFamily:this.captionFontFamily}} onClick={() => this.flip()}>⇆</text>
						</>
					:
						null
				}


				{/* this.sortType !== 2 */}

				{/* Show values under each bucket, at an angle - keep the code for now. */}
				{/*
					showBuckets
					?
						<g className="x axis">
							{
								buckets.map
								(
									(b, i) =>
									<g
										key={"k" + (DistributionGraph.#serial++)}
										className={"tick"}
										transform={"translate(" + (bucketNamesXOffset + (i + 1) * barHorizontalFootprint -5) + "," + (bucketNamesY + 1) + ")"}
										style={{opacity:"1"}}
									>
										<text y="9" x="0" style={{textAnchor:"left", fontSize:this.textFontSize,fontFamily:this.captionFontFamily}} transform="rotate(20 0 9)">{captions[i]}</text>
									</g>
								)
							}
						</g>
					:
						null
				*/}
				
				
				{ this.ticks ? this.ticks.map(t => t.render(bucketNamesY + 16)) : null }
				
				
				{/*
				<g className="y axis">
					{
						this.yScale.map
						(
							(y, i) =>
							<g
								key={"k" + (DistributionGraph.#serial++)}
								className="tick"
								transform={"translate(" + (this.leftCaptionWidth - 4) + "," + (this.captionHeight + this.usableHeight - y * this.spaceBetweenPcMarkers) + ")"}
								style={{opacity:"1"}}
							>
								<text x="0" y="0" style={{textAnchor:"end",fontSize:this.textFontSize}}>{pcLegs[i]}%</text>
							</g>
						) 
					}
				</g>


				<text y={rightCaptionY0} x={rightCaptionX + 4} style={{textAnchor:"left", fontSize:this.textFontSize}}>0</text>
				<text y={rightCaptionYMax} x={rightCaptionX + 4} style={{textAnchor:"left", fontSize:this.textFontSize}}>{this.state.maxCount.toLocaleString()}</text>
				*/}

{ /* absoluteLegs */}

				{/* this.captionHeight + this.usableHeight - y * this.spaceBetweenPcMarkers */}
				{/* {{stroke:GRID_AND_TICK_COLOUR, strokeWidth:"1"}} JUST CAN'T SEE THEM AS TICKS */}

				<g className="y axis">
					{
						this.yScale.map
						(
							(y, i) =>
							<g key={`ystck${i}`}>
								<text className="tick" x={this.leftCaptionWidth - 4} y={scaleYs[i]} style={{textAnchor:"end",fontSize:this.textFontSize}}>{pcLegs[i]}%</text>
								<line x1={this.leftCaptionWidth - 3} y1={scaleYs[i]} x2={this.leftCaptionWidth} y2={scaleYs[i]} style={{stroke:"rgb(128, 128, 128)", strokeWidth:"1"}} shapeRendering="crispEdges" />
							
								<text x={rightCaptionX + 4} y={scaleYs[i]} style={{textAnchor:"left", fontSize:this.textFontSize}}>{absoluteLegs[i]}</text>
								<line x1={rightCaptionX} y1={scaleYs[i]} x2={rightCaptionX + 3} y2={scaleYs[i]} style={{stroke:"rgb(128, 128, 128)", strokeWidth:"1"}} shapeRendering="crispEdges" />

							</g>
						) 
					}
				</g>


				{
					this.state.selected
					? 
						<rect
							x={this.state.selX}
							y={this.captionHeight}
							width={this.state.selW}
							height={this.usableHeight - 1}
							style={{strokeWidth:"1"}}
							fill="url(#selectFill)"
						/>

					:
						null
				}


				{
					showPins
					?

						<DistributionGraphPinSet
							controller={this}
							buckets={buckets}
							span={span}
							x={x0}
							y={this.captionHeight + this.usableHeight}
							usableHeight={this.usableHeight} 
							maxCount={this.state.maxCount}
							from={this.leftCaptionWidth + this.graphPadding}
							to={rightCaptionX}
							boundaries={this.boundaries}
							order={this.order}
							typeName={this.typeName}
							selectedIndex={this.state.selectedBucketIndex}
							fixedSpacingBetweenPins={fixedSpacingBetweenPins}
							sortType={this.sortType}
						/>
					:
						<>{
							buckets
							?
								buckets.map
								(
									(b, x) =>
									<DistributionGraphBar
										key={`dghb${DistributionGraph.#serial++}`}
										parent={this}
										bucket={b}
										x={this.leftCaptionWidth + this.graphPadding + this.barSpacing + x * (this.barSpacing + this.barWidth)}
										y={this.captionHeight + this.usableHeight}

										barWidth={this.barWidth}
										barHeight={b.count * this.usableHeight / this.state.maxCount}
										selected={this.state.selectedBucketIndex === b.index}
										fullHeight={this.usableHeight}
									/>
								)
							:
								null
						}</>
				}


				{
					(this.sortType === 2 && this.order !== 1) && this.typeName !== BACKEND_TYPE_STRING
					?
						<>
							<DistributionGraphNeedle
								parent={this}
								which={0}
								x={this.leftCaptionWidth + this.graphPadding}
								y={this.captionHeight}
								position={0}
								depth={this.usableHeight}
								graphicalSpan={this.usableWidth}
								totalGraphicalSpan={span}
								slide={continuousSlide}
							/>

							<DistributionGraphNeedle
								parent={this}
								which={1}
								x={this.leftCaptionWidth + this.graphPadding}
								position={span}
								y={this.captionHeight}
								depth={this.usableHeight}
								graphicalSpan={this.usableWidth}
								totalGraphicalSpan={span}
								slide={continuousSlide}
							/>
							<foreignObject
								x={this.leftCaptionWidth + this.graphPadding + ((this.usableWidth - nkbdWidth) >> 1)}
								y={this.usableHeight + (this.captionHeight << 1) - 16}
								width={nkbdWidth}
								height={nkbdHeight}
//	style={{border:"1px solid green"}}
							>
								<NeedleKbdBoxes controller={this} values={this.needleBoxesValues} />

							</foreignObject>
						</>

					:
						null
				}

			</svg>
					
			
		);
		

	
	} // render

	componentDidMount() { this.setState({page:0}); } 

} ////





/*

					<RadioGroup aria-label="sortType" name="sortType" value={sortType} defaultValue="Continuous" color="primary" onChange={this.handleRadioChange} row={true}>
					
						<FormControlLabel
							value="Count"
							control={<Radio color="primary" style={{padding:"4px"}} />}
							label={
								(
									<Typography className={"aqa-text-action"} variant="title" align="left" style={{}}>
										<span style={{float:"left", width:"80px"}}>By Count</span>
									</Typography>
								)
							}
							style={{width:"160px",margin:"0px",paddingLeft:"65px"}}
						/>

						<FormControlLabel
							value="Continuous"
							control={<Radio color="primary" style={{padding:"4px"}} />} label={
								(
									<Typography className={"aqa-text-action"} variant="title" align="left" style={{padding:"0px" }}>
										<span style={{float:"left", width:"80px"}}>Continuous</span>
									</Typography>
								)
							}
							style={{width:"90px",margin:"0px"}}
						/>


					</RadioGroup>



*/


/*
// First and last values

						<>
						firstAndLastBuckets
							<text y={bucketNamesY + this.textFontSize} x={this.leftCaptionWidth} style={{textAnchor:"start",  fontSize:this.textFontSize}}>{firstAndLastBuckets[0].value}</text>
							<text y={bucketNamesY + this.textFontSize} x={rightCaptionX}         style={{textAnchor:"end", fontSize:this.textFontSize}}>{firstAndLastBuckets[1].value}</text>
						</>



*/


// ⇆
