import React from 'react'
import ColumnScore from "./ColumnScore"

import './TraditionalTh.css';
import AqaComponent from "../aqacomponent/AqaComponent";


export default class TraditionalTh extends AqaComponent
{

	constructor(props)
	{
		super(props);
		if(props.which===0){
			let subcomponents = props.parent.getSubComponents().filter((item)=>{return item.props.col === props.col && item.props.which === 0;});
			if(subcomponents.length === 0) props.parent.registerSubComponent(this);
		}
		this.state = { selected:false };
		this.columnScore = null;
	} //

	registerScore(cs)	{ this.columnScore = cs; }

// style={this.state.selected ? selectedStyle : normalStyle}
	

	handleClick = (e) =>
	{
		e.preventDefault();
		e.stopPropagation();
		if (e.ctrlKey) this.props.parent.ctrlColSelect(this);
		else this.props.parent.colSelect(this);
	} // handleClick

	handleAutoClick = () =>
	{
		this.props.parent.colSelect(this);
	} // handleClick

	render()
	{
		const style = {};
		switch(this.props.which)
		{
		case 0:
			if (this.state.selected) style.borderTop = "1px solid #fff";
			break;

		case 1:
			style.borderTop = "0";
			if (this.state.selected) style.borderBottom = "1px solid #fff";
			break;

		default: // To shut up the transpiler
			break;	
		}
	
		return (
			<div
				className={this.state.selected ? "thSelectedStyle" : "thNormalStyle"}
				style={style}
				onClick={this.handleClick}
				onContextMenu={this.handleClick}
			>
				{this.props.caption}

				{/*
				// Keep: this may be useful later
				{this.props.plusLink ? <span onClick={(e) => this.props.parent.statsOnColumn(e, this.props.col) }> ?</span> : null}
				{this.props.plusLink ? <span onClick={(e) => this.props.parent.vqdForColumn(e, this.props.col) }> ✓</span> : null}
				*/}
				{/*this.props.score*/}

				<ColumnScore
					parent={this}
					score={this.props.score}
					active={this.state.selected}
					arClicker={arBooleans => this.props.parent.raSelect(this, arBooleans)}
				/>

			</div>
		);	
	} // render

} ////


// ar={this.props.ar}
// this.props.controller.raSelect(this.props.th, this.state.checked);

