import React, { Component } from 'react';

import {loadStripe} from "@stripe/stripe-js";

import { store } from 'react-notifications-component';

import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon    from '@material-ui/icons/Info';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon   from '@material-ui/icons/ErrorOutlined';
import Button      from "@material-ui/core/Button";

import AqaSettings from "../../../AqaSettings.js";

const AqaApi = require('aqa-api');

const MAX_INACTIVE_TIME_ALLOWED = 60 * 10; // 10 minutes

const base64 = require('base-64'); // import doesn't work



const BIG_NUMBER_MULTIPLIER_LETTERS = ["K","M","G"];
const DESIRABLE_NUMBER_OF_DECIMALS = 2;

// Date formatting constants
// -------------------------

const DFSC = "mdyhs~";
const MONTH_NAMES =
[
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];

const DAY_NAMES = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const pad2 = v =>  v < 10 ? "0" + v : v;
const month = m => MONTH_NAMES[m];

const DFFUNX =
{
	M:{g: "getMonth", a: m => m + 1}, 
	MM:{g: "getMonth", a: m => pad2(++m)},
	MMM:{g: "getMonth", a: m => month(m).substring(0, 3)}, 
	MMMM:{g: "getMonth", a: month},
	MMMMM:{g: "getMonth", a: m => month(m).substring(0, 1)},
	d:{g: "getDate"},
	dd:{g: "getDate", a:pad2},
	ddd:{g: "getDay", a: d => DAY_NAMES[d].substring(0, 3)},
	dddd:{g: "getDay", a: d => DAY_NAMES[d]},
	yy:{g: "getFullYear", a: y => y % 100},
	yyyy:{g: "getFullYear"},

	h:{g:"getHours"},
	hh:{g:"getHours", a:pad2},
	m:{g:"getMinutes"},
	mm:{g:"getMinutes", a:pad2},
	s:{g:"getSeconds"},
	ss:{g:"getSeconds", a:pad2},
	"↕":{g:"getHours", a: h => h >= 12 ? "pm" : "am"}
};






export default class AqaComponent extends Component
{
	static unloggedBackend  = new AqaApi.AQAUnloggedControllerApi(); // Not used but should be used for registration
	static backend          = new AqaApi.AQAAnalysisControllerApi();
	static snapshotBackend  = new AqaApi.AQASnapshotControllerApi();
	static accountBackend   = new AqaApi.AQAAccountControllerApi();
	static billingBackend   = new AqaApi.AQABillingControllerApi();
	static salesBackend     = new AqaApi.AQASalesControllerApi();
	static reviewBackend    = new AqaApi.AQAReviewControllerApi();
	static userBackend      = new AqaApi.AQAUserControllerApi();
	static stripeBackend    = new AqaApi.AQAStripeControllerApi();
	static marketBackend    = new AqaApi.AQAQualityDefinitionsMarketControllerApi();


	static CharWidths = false;

	static USER_EVENTS_THAT_TOUCH = ['click', 'scroll', 'load','keydown'];
	static TIME_TO_GIVE_THEM_TO_KEEP_THEIR_SESSION = 60; // 1 minute


	// TYPES
	// Why put them in here? Because this component is used by all my other components 
	// which makes it a good place to have all we need and not having to impoprt some
	// other minor object

	// Reflecting the same constants in com.multiversant.aqa.engine.trust.AqaVectorColours
	// And must be kept in sync obviously!!
	static OVERALL_POSITION_IN_OVERALL_RAG_SHORT    = 0;
	static POPULATION_POSITION_IN_OVERALL_RAG_SHORT = 1;
	static UNIQUENESS_POSITION_IN_OVERALL_RAG_SHORT = 2;
	static NUMBER_POSITION_IN_OVERALL_RAG_SHORT     = 3;
	static DATE_POSITION_IN_OVERALL_RAG_SHORT       = 4;
	static STRING_POSITION_IN_OVERALL_RAG_SHORT     = 5;

	static lastTimeTouched = 0;


	// Any change in the UI must be reflected here!!
	// Type indices as per the UI
	// 0: uniqueness, 1: population, 2: string, 3: number, 4: date as per AqaSnapshotDetail.selectType()

	static UI_TYPE_TO_POSITION_IN_RAG_SHORT =
	[
		AqaComponent.OVERALL_POSITION_IN_OVERALL_RAG_SHORT,
		AqaComponent.UNIQUENESS_POSITION_IN_OVERALL_RAG_SHORT,
		AqaComponent.POPULATION_POSITION_IN_OVERALL_RAG_SHORT,
		AqaComponent.STRING_POSITION_IN_OVERALL_RAG_SHORT,
		AqaComponent.NUMBER_POSITION_IN_OVERALL_RAG_SHORT,
		AqaComponent.DATE_POSITION_IN_OVERALL_RAG_SHORT
	];


	// Shared UI constants
	static HEATMAP_STAMP_WIDTH  = 16;
	static HEATMAP_STAMP_HEIGHT = 16;
	static HEATMAP_STAMP_SCALE  = 1;

	static DATE_FORMATS_AVAILABLE = // We can add more and let people choose too - if ever needed
	{
		"Great Britain": new Intl.DateTimeFormat("en-GB") // Also available: 'en-US', etc. fr-FR is obviously the best.
	};
	
	static uiTypeToPositionInRagShort(uiTypeNumber)
	{
		return AqaComponent.UI_TYPE_TO_POSITION_IN_RAG_SHORT[uiTypeNumber + 1];
	} // uiTypeToPositionInRagShort
	
	static formatDate(dateAsMillis)
	{
		if (!dateAsMillis || typeof dateAsMillis !== "number") return "";
		const d = new Date(dateAsMillis);
		return AqaComponent.DATE_FORMATS_AVAILABLE["Great Britain"].format(d);
// {(this.props.dataFromParent.dateCreated !== null)? (moment(this.props.dataFromParent.dateCreated ).format("YYYY/MM/DD HH:mm")):("")}
	} // formatDate

	static makeSequence(n)
	{
		if (n === undefined || n === null || n <= 0) return [];
		n = Math.floor(n);
		const seq = Array(n);
		for(let i = 0; i < n; i++) seq[i] = i;
		return seq;
	} // makeSequence


	static charWidths = false;
	static ellipsisWidth = 12;
	static ELLIPSIS = "…";

	static START_ASCII_VALUE = 32;
	static UPTO_ASCII_VALUE = 128;
	static widestCharacter = 12;
	static averageCharWidth = 12;

	static howlonsapieceofstring()
	{

		if (!AqaComponent.charWidths)
		{
			// We build our DB
			
// onsole.log("Doing char sizes");
	
			// THIS HAS TO BE IN SYNC WITH WHATEVER STYLE IS USED TO DISPLAY DATAVIEWER CELL DATA!!!!!!!! IGNORE AND SUFFER.

//

			// No better not do it the REACT way...
			let style = "padding:0;visibility:hidden;white-space:nowrap;font-size:12px;font-family:'Quicksand',sans-serif;"; // 

			let spanners = Array(AqaComponent.UPTO_ASCII_VALUE);

			AqaComponent.widestCharacter = 0;
			for(let i = AqaComponent.START_ASCII_VALUE; i <= AqaComponent.UPTO_ASCII_VALUE; i++)
			{

				spanners[i] = document.createElement("span");

				// TODO: use object entries
				spanners[i].setAttribute("style", style);

				spanners[i].innerHTML = (i === 32 ? "m m" : (i < AqaComponent.UPTO_ASCII_VALUE ? String.fromCharCode(i) : AqaComponent.ELLIPSIS));


				document.body.appendChild(spanners[i]);
			}

			requestAnimationFrame
			(
				() =>
				{
					// fires before next repaint

					requestAnimationFrame
					(
						() =>
						{
							AqaComponent.widestCharacter = 0;
							AqaComponent.charWidths = Array(AqaComponent.UPTO_ASCII_VALUE);
							let w;
							let t = 0;
							for(let i = AqaComponent.START_ASCII_VALUE; i <= AqaComponent.UPTO_ASCII_VALUE; i++)
							{
								w = spanners[i].offsetWidth;
								if (i < AqaComponent.UPTO_ASCII_VALUE)
								{
									AqaComponent.charWidths[i] = w;
									if (i > AqaComponent.START_ASCII_VALUE)
									{
										if (w > AqaComponent.widestCharacter) AqaComponent.widestCharacter = w;
										t += w;
									}
								}
								else AqaComponent.ellipsisWidth = w;
								document.body.removeChild(spanners[i]);
								
							}

							AqaComponent.charWidths[32] -= (AqaComponent.charWidths[109] << 1);
							AqaComponent.averageCharWidth = t / (AqaComponent.UPTO_ASCII_VALUE - AqaComponent.START_ASCII_VALUE - 1);

// onsole.log("char sizes Done: t=" + t + " N: " + (AqaComponent.UPTO_ASCII_VALUE - AqaComponent.START_ASCII_VALUE - 1) + " Average joe: " + AqaComponent.averageCharWidth);

						}
					)
				}
			);

		}


	} // howlonsapieceofstring


	static getStringPixelWidthCharByChar(s)
	{
		let n;
		if (!s || (n = s.length) === 0) return [];

		let c;	

		let ret = Array(n);
		for(var i = 0; i < n; i++)
		{
			c = s.charCodeAt(i);
			if (c >= AqaComponent.START_ASCII_VALUE && c < AqaComponent.UPTO_ASCII_VALUE) ret[i] = AqaComponent.charWidths[c];
			else ret[i] = AqaComponent.widestCharacter;
		}
		return ret;

	} // getStringPixelWidth


	static readInteger(byteArray, bytePosition, numberOfBytes)
	{
		// Note: java is sending us big endian data. (And we respect that)

		let shift = 0;
		let ret = 0;
		for(let pos = bytePosition + numberOfBytes; pos > bytePosition;)
		{
			ret |= (byteArray[--pos] << shift);
			shift += 8;
		}
		return ret;

	} // readInteger


	static sumUpArray(a) { return a.reduce((a, b) => a + b, 0); }

	static correctStringWidth(l) { return l * (1 + 8 / 340); }
	
	static getStringPixelWidthChar(s) { return AqaComponent.correctStringWidth(AqaComponent.sumUpArray(AqaComponent.getStringPixelWidthCharByChar(s))); }


	static formatBigNumber(n)
	{
		if (n === undefined || n === null) return "";

		if (typeof n !== "string") n = n.toString();

		let els = n.split(".");

		let nc;
		if ((nc = els.length) > 2) return "NaN";

		let l, first, last, inc, cc;
		let nels = [];

		for(let i = 0; i < nc; i++)
		{
			l = els[i].length;
			nels[i] = "";

			if (l > 0)
			{
				switch(i)
				{
				case 0:
					first = l - 1;
					last = 0;
					inc = -1;
					break;

				case 1:
					first = 0;
					last = l - 1;
					inc = 1;
					break;
				default:
				}

				cc = 0;
				do
				{
					if (cc++ === 3)
					{
						nels[i] += ','; 
						cc = 0;
					}
					nels[i] += els[i].charAt(first);
					if (first === last) break;
					first += inc;
				}
				while(true);
			}
			else nels[i] = "";
		}

		nels[0] = nels[0].split('').reverse().join('');
		return nels.join(".");

	} // formatBigNumber
	
	static getEnoughDecimalsForListOfValues(values)
	{
		let j;
		const nLegs = values.length;
		
		const ret = Array(nLegs); // These legs go a long way!

		const MAX_DECIMALS = 8;

		for(let fixer = 0; fixer < MAX_DECIMALS; fixer++) // Trying up to 8 decimals!
		{
			for(j = 0; j < nLegs; j++)
			{
				ret[j] = values[j].toFixed(fixer);
				if (fixer < MAX_DECIMALS - 1 && j > 0 && ret[j] === ret[j - 1]) break; // on the last one, no choice but to carry on.
			}
			if (j === nLegs) break;
		}
		return ret;

	} // getEnoughDecimalsForListOfValues

	static abreviateNumber = number =>
	{

// onsole.log("called with: [" + number + "] > " + typeof number);

		if (number === undefined || number === null) return "";

		if (!(typeof number == 'number')) return number;
		
		if (number === 0) return "0";

		const sgn = number < 0 ? '-' : '';
		number = Math.abs(number);

		if (number < 1)
		{
			if (number < 0.01) return sgn + number.toExponential(DESIRABLE_NUMBER_OF_DECIMALS);
			else
			{
				number = number.toString();
				const oc = number.indexOf('.');
				if (oc < number.length - DESIRABLE_NUMBER_OF_DECIMALS) number = number.substring(0, oc + DESIRABLE_NUMBER_OF_DECIMALS + 1);
				return sgn + number;
			} 
		}
		else
		{
			let runner, n = BIG_NUMBER_MULTIPLIER_LETTERS.length, limit = 1000;
			for(runner = 0; runner < n; runner++)
			{
				if (number < limit) break;
				limit *= 1000;
			}
			if (runner > 0) number = "" + (number / (limit / 1000)).toFixed(DESIRABLE_NUMBER_OF_DECIMALS) + BIG_NUMBER_MULTIPLIER_LETTERS[runner - 1];
			else number = number.toFixed(DESIRABLE_NUMBER_OF_DECIMALS);
		}
		return sgn + number;


	} // abreviateNumber




	/** Merges arrays, last array wins if there are duplicate keys.
	  */
	static conflate(...arrays)
	{
		const ret = {};
		if (!arrays) return ret;
		arrays.forEach(a => { if (a) for(const[k, v] of Object.entries(a)) ret[k] = v; } );
		return ret;
	} // conflate


	// JSON prettification code (c) Sentient Leaf
	// ------------------------------------------

	static prettifyJson(obj)
	{
		if (!obj) return "";
		var cache = [];
		return AqaComponent.prettifyJsonFromString
		(
			JSON.stringify
			(
				obj,
				(key, value) =>
				{
					if (typeof value === 'object' && value !== null)
					{
						if (cache.indexOf(value) !== -1) return;
						cache.push(value);
					}
					return value;
				}
			)
		);
	} // prettifyJson

	static prettifyJsonFromString(json)
	{
		const n = json.length;
		const chars = [];
		let ind = 0, j;	
		let inString = false;
		let stringer = '';
		let prevBackSlash = '';
		let breakme;
		let breakmeAgain = false;
		let c;
		
		for(let i = 0; i < n;)
		{
			breakme = false;
			breakmeAgain = false;
			c = json.substring(i, ++i)
			if (inString)
			{
				if (c === stringer && !prevBackSlash) inString = false;
			}
			else
			{
				do
				{
					if (!inString && (c === '"' || c === "'"))
					{
						stringer = c;
						inString = true;
						break;
					}

					switch(c)
					{
					case '[': 
					case '{':
						breakme = breakmeAgain = true;
						ind++;
						break;
					
					case ']':
					case '}':
					
						breakme = true;
						ind--;
						break;
						
					case ',':
						breakmeAgain = true;
						break;
					default:
					}
				}
				while(false);
			}
			
			if (breakme)
			{
				chars.push("\n");	
				for(j = 0; j < ind + (breakmeAgain ? -1 : 0); j++) chars.push("\t");
			}
			chars.push(c);
			if (breakmeAgain)
			{
				chars.push("\n");
				for(j = 0; j < ind; j++) chars.push("\t");
			}
			prevBackSlash = (c === '\\');
		}
		return chars.join('');

	} // prettifyJsonFromString



	static rewindow(currentPage, totalElements, oldVisibleElements, visibleElements)
	{
		const firstElementPosition = (currentPage < 0 ? 0 : currentPage) * oldVisibleElements;

		let numberOfPages = Math.floor(totalElements / visibleElements);
		if (numberOfPages * visibleElements < totalElements) numberOfPages++;

		let newPosition = firstElementPosition;
		if (firstElementPosition < currentPage * visibleElements || firstElementPosition >= (currentPage + 1) * visibleElements)
		{
			// it's before or after
			newPosition = Math.floor(firstElementPosition / visibleElements);
		}

		return [numberOfPages, newPosition];

	} // rewindow
	
	static makeCgiParam(k, v) { return `${k}=${encodeURI(v)}`; }

	static now() { return new Date().getTime(); }

	static nowInSeconds() { return Math.floor(AqaComponent.now() / 1000); }

	static touch(e)
	{
// onsole.log("lastTimeTouched before change: " + AqaComponent.lastTimeTouched);
		AqaComponent.lastTimeTouched = AqaComponent.nowInSeconds();
	} // touch

	static getLastTimeTouched() { return AqaComponent.lastTimeTouched; }

	static isRecentlyTouched()
	{ 
	
// onsole.log("In isRecentlyTouched");

// onsole.log("AqaComponent.nowInSeconds(): " + AqaComponent.nowInSeconds());
// onsole.log("AqaComponent.getLastTimeTouched() " + AqaComponent.getLastTimeTouched());
// onsole.log("D: " + (AqaComponent.nowInSeconds() - AqaComponent.getLastTimeTouched()));
// onsole.log("MAX: " + MAX_INACTIVE_TIME_ALLOWED);

		return (AqaComponent.nowInSeconds() - AqaComponent.getLastTimeTouched()) < MAX_INACTIVE_TIME_ALLOWED;
	
	
	} // isRecentlyTouched

	static saveCredentials(json)
	{
		localStorage.setItem("access_token", json.access_token);
		localStorage.setItem("refresh_token", json.refresh_token);
		localStorage.setItem("ttl",AqaComponent.nowInSeconds());
		localStorage.setItem("ttlo",AqaComponent.nowInSeconds()+json.expires_in);
	} // saveCredentials

	
	static refreshToken = async (callback) =>
	{

// onsole.log("Refreshing token !!!!!!!!!!!!!!!!!!!!!!");


		const refresh_token = localStorage.getItem("refresh_token");
		const username = localStorage.getItem("userId");
		if (!refresh_token)
		{
			console.error("AqaLoginOrRegister.refreshToken: refresh_token is not in local storage");
			return; // Assuming a missing refresh token means we have logged out - so not invoking call back. This is reasonable.
		}
		else
		{
//	onsole.log("I still have a refresh token [" + refresh_token + "]");
		}

		const postParams = [];
		postParams.push(AqaComponent.makeCgiParam("username", username));
		postParams.push(AqaComponent.makeCgiParam("refresh_token", refresh_token));
		postParams.push(AqaComponent.makeCgiParam("grant_type", "refresh_token"));

		let body = postParams.join('&');
		const url = AqaSettings.getBaseUrl() + "/oauth/token";

		const  headers = new Headers();
		headers.append("Content-Type", "application/x-www-form-urlencoded; charset=utf-8");
		headers.append("Authorization", "Basic " + base64.encode(AqaSettings.OAuthLogin + ":" + AqaSettings.OAuthPassword));



		try
		{
				// Yes, 2 x await, because body is a stream...
				const lresp = await fetch(url, {method:'POST', headers, body});
				const json = await lresp.json();


// onsole.log("Token refresh reply:");
// onsole.log(AqaComponent.prettifyJson(json));
// onsole.log("EXPIRE: [" + json.expires_in + "]");

				if (json.error)
				{
					console.error("An error has occurred refreshing token: " + json.error + ": " + json.error_description);
				}

				AqaComponent.saveCredentials(json);
				if (callback) callback(json.error, json.expires_in);
		}
		catch(e)
		{
			console.error(e);
			// Boom
			console.error("AqaLoginOrRegister.refreshToken: request error: [" + e + "]");
		}

	}; // refreshToken





	#subComponents = [];

	constructor(props)
	{
		super(props);


		if ("parent" in props && AqaComponent.prototype.isPrototypeOf(props.parent)) props.parent.registerSubComponent(this);

	} //


	registerSubComponent(component) { this.#subComponents.push(component); }
	
	getSubComponents() { return this.#subComponents; }

	/** Override this in your extended components. */
	getDescription() { return "getDescription() function returning a string needed"; }

	getClickables() { return []; }


	/** This is the recusive function that collates the analysis - don't call it */
	describe(componentInventory, clickableInventory, depth)
	{
		// We document each component only ONCE.
		let n = this.constructor.name;
		if (n in componentInventory) return "";
		componentInventory[n] = true;


		let hTag = (depth < 7 && depth > 0 ? "h" + depth : "b");

		let a = `<p /><${hTag}>${n}</${hTag}><br /><i>${this.getDescription()}</i>`;
		if (this.#subComponents.length > 0) this.#subComponents.forEach
		(
			child =>
			{
				a = a + "<div style=\"padding-left:64px\">" + child.describe(componentInventory, clickableInventory, depth + 1) + "</div>";
			}
		);
		return a;

	} // describe

	/** This sets strings containing the analysis of your component */
	analyse()
	{
		let componentInventory = {};
		let n = this.constructor.name;
		let analysis = "<html><head><title>Analysis of " + n + "</title></head><body>" + this.describe(componentInventory, null, 1) + "</body></html>";
		this.setState({analysed:true, analysis, analysisFilename:n + "-analysis.html"});
	} // analyse


	/** This gives you a button to generate the analysis of your component.
	  * You should only need one - in the parent component.
	  */
	analysisUI()
	{
		// https://stackoverflow.com/questions/3665115/how-to-create-a-file-in-memory-for-user-to-download-but-not-through-server
		return (
			<div style={{backgroundColor:"#ff6"}}>
				<input type="button" value="Generate Analysis" onClick={() => this.analyse()} />
				{
					this.state.analysed
					?
						<a href={"data:text/html;charset=utf-8," + encodeURIComponent(this.state.analysis)} download={this.state.analysisFilename}>Download Analysis</a>
					:
						null
				}
			</div>
		);	
	} // analysisUI
	
	reportError(userFacingError, devFacingError,context)
	{
		console.error("An error append in: " + this.constructor.name + ": ");
		AqaComponent.staticReportError(userFacingError, devFacingError,context);
	} // reportError

	static staticReportError(userFacingError, devFacingError,context)
	{
		//alert(userFacingError);
		var customNotification = {
			title: "Application Error", titleIcon: "",
			message: <div>
				<div>{"Error Description: "}</div>
				<div>{userFacingError}</div></div>,
			isCustom: false, type: "info",
			insert: "bottom", position: "bottom-center", autoClose: true, duration: 6000
		};
		if(context !== undefined && context.showNotification!==undefined) {
			//context.removeAllNotifications();
			setTimeout(()=>context.showNotification(null, customNotification),1000);
		}
		else{
			alert(userFacingError);
		}
		console.log(userFacingError);
		console.error(devFacingError);
	} // staticReportError
	


	parseStringFormat = f =>
	{
		// https://support.microsoft.com/en-us/office/format-numbers-as-dates-or-times-418bd3fe-0577-47c8-8caa-b4d30c528309
		// Because the am/pm flag could be anywhere, we can't render as we go :-( but we get pre-parsing.
		
		let n;
		if (!f || (n = f.length) === 0) return {use12:false, els:[]};
		f = f.replaceAll("am/pm", "↕");

		let c, i;
		let els = [], pr = null;
		let lf = f.toLowerCase(), tp;

		let use12 = false;
		let whb = false; // I hate microsoft

		for(i = 0; i < n; i++)
		{
			c = lf.charAt(i);
			tp = DFSC.indexOf(c) >= 0 ? c : "";

			if ("h" === tp) whb = true;
			else
			{
				if ("m" === tp && !whb) c = tp = "M";
				else if ("m" !== tp && "" !== tp) whb = false;
			}

			if (pr !== null && pr.t === c) pr.c += c;
			else
			{
				if (c === "↕") use12 = true;
				els.push(pr = {t: c, c});
			}
		}

		return ({use12, els});

	} // parseStringFormat


	buildDateStringFromParsedFormat = (d, format) =>
	{
		const {use12, els} = format;
		const da = new Date(d);

		let rels = [];
		els.forEach
		(
			el =>
			{
				let l;
				if ((l = el.c.length) > 0)
				{
					if (el.t.length === 0) rels.push(el.c);
					{
						const fx = DFFUNX[el.c];

						if (!fx) rels.push(el.c);
						else
						{
							let v = da[fx.g]();
							if (use12 && "h" === el.t && v > 12) v -= 12;
							if (fx.a) v = fx.a(v);
							else if (l === 2 && v < 10) v = "0" + v;
							rels.push(v);
						}
					}
				}
			}
		);
		return rels.join("");
	};

	buildDateStringFromRawFormat(d, f) { return this.buildDateStringFromParsedFormat(d, this.parseStringFormat(f)); }

	showNotification(toastId,customNotification,context,contextObj,adminEmail)
	{
		var title="Info";
		var titleIcon="";
		var message="Something went wrong";
		var isCustom=false;
		var type="info";
		var insert="bottom";
		var position="bottom-center";
		var autoClose=true;
		var duration=2000;
		adminEmail=adminEmail===undefined?"support@aqaversant.com":adminEmail;
		var viewerpermission=<div><span>You role does not have sufficient privileges to do this operation, Please contact your account administrator <a href={"mailto:"+adminEmail} style={{}}>{adminEmail}</a> to upgrade your role</span></div>
		var failedmessage=<div><span>Failed to load the rules, please try again. If problem persists, Please contact aqa support team <a href={"mailto:"+adminEmail} style={{}}>{adminEmail}</a> to verify the issue.</span></div>
		const defaultNotifications=[
			{title:"Warning",titleIcon:"",message:"Something went wrong",isCustom:false,type:"danger",insert:"top",position:"top-left",autoClose:true,duration:1000},
			{title:"Upgrade AQA Plan",titleIcon:"",message:"This account reached limit for creating Full User Licenses, Upgrade your AQA plan to create more Full User Licenses",isCustom:false,type:"info",insert:"bottom",position:"bottom-center",autoClose:false,duration:0},
			{title:"Upgrade AQA Plan",titleIcon:"",message:"This account reached limit for creating Data Sources, Upgrade your AQA plan to create more Data Sources",isCustom:false,type:"info",insert:"bottom",position:"bottom-center",autoClose:false,duration:0},
			{title:"Upgrade AQA Plan",titleIcon:"",message:"This account reached limit for creating Data Uploads, Upgrade your AQA plan to create more Data Uploads",isCustom:false,type:"info",insert:"bottom",position:"bottom-center",autoClose:false,duration:0},
			{title:"Insufficient privileges!!",titleIcon:"",message:viewerpermission,isCustom:false,type:"info",insert:"bottom",position:"bottom-center",autoClose:false,duration:3000},
			{title:"Failed",titleIcon:"",message:failedmessage,isCustom:false,type:"info",insert:"bottom",position:"bottom-center",autoClose:false,duration:0}
	];
		if(toastId === "" || toastId=== null || toastId === 0){
			toastId=0;
			if(customNotification === null){
				customNotification=defaultNotifications[toastId];
			}
		}
		else{
			customNotification=defaultNotifications[toastId];
		}
		if(customNotification !== null){
			title=customNotification.title;
			titleIcon=customNotification.titleIcon;
			message=customNotification.message;
			isCustom=customNotification.isCustom;
			type=customNotification.type;
			insert=customNotification.insert;
			position=customNotification.position;
			autoClose=customNotification.autoClose;
			duration=customNotification.duration;
			this.showNotifications(title,titleIcon,message,isCustom,type,insert,position,autoClose,duration,context,contextObj);
		}



	}

	showNotifications(title,titleIcon,message,isCustom,type,insert,position,autoClose,duration,context,contextObj)
	{
		var content="";
		if(titleIcon!=="" && isCustom){
			content=<div className={`notification__custom--${type}`}>
				<div className="notification__custom-icon">
					{titleIcon===""?<><div><SuccessIcon/></div></>:
						(titleIcon==="danger"?<><div><ErrorIcon/></div></>:
							(titleIcon==="info"?<><div><InfoIcon/></div></>:
								(titleIcon==="success"?<><div><SuccessIcon/></div></>:
									(titleIcon==="warning"?<><div><WarningIcon/></div></>:""))))}
				</div>
				<div className="notification__custom">
					<p className="notification__message">{message}</p>
				</div>
			</div>;
		}
		store.addNotification({
			title: title,
			message: (
				<div className={`notification__custom--${type}`}>
					{!isCustom && titleIcon!==""?
						<div className="notification__custom-icon">
							{titleIcon===""?<><div><SuccessIcon/></div></>:
								(titleIcon==="danger"?<><div><ErrorIcon/></div></>:
									(titleIcon==="info"?<><div><InfoIcon/></div></>:
										(titleIcon==="success"?<><div><SuccessIcon/></div></>:
											(titleIcon==="warning"?<><div><WarningIcon/></div></>:""))))}
						</div>:""}
					<div className="notification__custom__full">
						<div className="notification__message">{message}</div>
						{context!== undefined?(
							<div className="notification__custom__button" style={{textAlign:"center"}}>
								<Button onClick={()=>this.handlePlanNavigation(this,context,contextObj)} variant="contained"
										color="primary"
										align="left"
										className="aqa-action-button"
										style={{
											marginTop: 8,
											marginLeft: 8,
											color: '#4cadc4',
											backgroundColor: 'white',
											border: '1px solid #4cadc4',
											fontSize: '0.7rem',
											padding: '3px'
										}}>
									Change Plan
								</Button>
							</div>
						):""}



					</div>


				</div>
			),
			content:content,
			type: type,
			insert: insert,
			container: position,
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss:
			{
				duration: duration,
				onScreen: false,
				pauseOnHover:true,
				showIcon: true,
				click: autoClose,
				touch: autoClose
			}
		});
	}

	removeAllNotifications() { store.removeAllNotifications(); }

	handlePlanNavigation(e,context,contextObj)
	{
		if(context!==null)
		{
			this.removeAllNotifications();
			if(contextObj===undefined || contextObj === null || context === null || context === undefined)
			{
				console.log("Cant show plan");
			}
			else if(contextObj ==="User"){
				context.handleHeader(1);
				context.planNavigation();
				//context.props.dataFromParent.planNavigationReset();
			}
			else
			{
				context.planNavigation(4, "account", true);
			}
		}
	}
	getStyle()
	{
		let s =
		{
			border:"1px dashed #555",
			margin:"4px"
		};
		return s;

	} // getStyle


	startTimer()
	{
		this.t0 = new Date().getMilliseconds();
		console.log("Time started");
	} // startTimer
	
	time()
	{
		const nt = new Date().getMilliseconds();
		const d = nt - this.t0;
		this.t0 = nt;
		console.log("TIMER " + d);
	} // time



	// Code in here to go to any setup change (more / less editors. more / less sources)
	// IT MAKES SURE the stripe subcomponent is ready.
	loadStripe(runAfter)
	{
		// A function to invoke when async functions have come back
		// For example to reset the cursor to its normal aspect
		let postOp = false;

		// The code to run once we are ready.
		const completionFunction = () =>
		{
			if (runAfter) runAfter();
			if (postOp) postOp();
		}


// onsole.log("Loading stripe for component");

		if (!this.state.stripePromise)
		{

			// We must set the stripe promise which we will obtain by using the stripe publishable key which held by the server.

			// NOTE: Here, you should indicate a possible wait, like a hour-glass mouse pointer.
			//       WE SHOULD ALSO PREVENT ANY OTHER USER INPUT (with a a modal box maybe?)

			// CODE FOR WAIT DEVICE HERE
			// postOp = () => { ... }

			// Uncomment and populate this
			// postOp = () => { /* code to restore the mouse to its normal shape FOR EXAMPLE */ };


// onsole.log("About to get pk");

			// We haven't retrieved the stripe publishable key yet.
			AqaComponent.backend.getStripePublishableKeyUsingGET
			(
				(error, data, response) =>
				{
					if (error) this.reportError("Sorry, there was an error.\nPlease retry a bit later", error);
					else
					{
// onsole.log("pk OBTAINED");
						this.setState({stripePromise: loadStripe(data["message"])}, completionFunction);
					}
				}
			);

		}
		else
		{
			// The stripe promise has already been set, so we're good to go immediately.
			// No need to show any 'please wait device */
			completionFunction();
		}

	} // loadStripe





	render()
	{
		return <div style={this.getStyle()}><b>{this.constructor.name}</b><br />{this.getDescription()}</div>
	} // render

	

} ////
