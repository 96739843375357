/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/AqaAccountResponse', '../model/AqaFolder', '../model/AqaOtpResponse', '../model/AqaPaymentMethod', '../model/AqaPersonalisationSettings', '../model/AqaQualityDefinition', '../model/AqaQualityDefinitionResponse', '../model/AqaQualityDefinitionUpdateResponse', '../model/AqaRecordData', '../model/AqaRecordReviewRequest', '../model/AqaResponse', '../model/AqaScore', '../model/AqaSnapshot', '../model/AqaSnapshotResponse', '../model/AqaSource', '../model/AqaSourceResponse', '../model/AqaTableQualityDefinition', '../model/AqaVectorDistributionColours', '../model/AqaVectorQualityDefinition', '../model/AqaVectorQualityDefinitionArchive', '../model/AqaVectorQualityDefinitionTable', '../model/FolderCreationUpdateRequest', '../model/SourceCreationRequest', '../model/SourceEditRequest', '../model/SourceFolderList'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/AqaAccountResponse'), require('../model/AqaFolder'), require('../model/AqaOtpResponse'), require('../model/AqaPaymentMethod'), require('../model/AqaPersonalisationSettings'), require('../model/AqaQualityDefinition'), require('../model/AqaQualityDefinitionResponse'), require('../model/AqaQualityDefinitionUpdateResponse'), require('../model/AqaRecordData'), require('../model/AqaRecordReviewRequest'), require('../model/AqaResponse'), require('../model/AqaScore'), require('../model/AqaSnapshot'), require('../model/AqaSnapshotResponse'), require('../model/AqaSource'), require('../model/AqaSourceResponse'), require('../model/AqaTableQualityDefinition'), require('../model/AqaVectorDistributionColours'), require('../model/AqaVectorQualityDefinition'), require('../model/AqaVectorQualityDefinitionArchive'), require('../model/AqaVectorQualityDefinitionTable'), require('../model/FolderCreationUpdateRequest'), require('../model/SourceCreationRequest'), require('../model/SourceEditRequest'), require('../model/SourceFolderList'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AQAAnalysisControllerApi = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.AqaAccountResponse, root.ApiDocumentation.AqaFolder, root.ApiDocumentation.AqaOtpResponse, root.ApiDocumentation.AqaPaymentMethod, root.ApiDocumentation.AqaPersonalisationSettings, root.ApiDocumentation.AqaQualityDefinition, root.ApiDocumentation.AqaQualityDefinitionResponse, root.ApiDocumentation.AqaQualityDefinitionUpdateResponse, root.ApiDocumentation.AqaRecordData, root.ApiDocumentation.AqaRecordReviewRequest, root.ApiDocumentation.AqaResponse, root.ApiDocumentation.AqaScore, root.ApiDocumentation.AqaSnapshot, root.ApiDocumentation.AqaSnapshotResponse, root.ApiDocumentation.AqaSource, root.ApiDocumentation.AqaSourceResponse, root.ApiDocumentation.AqaTableQualityDefinition, root.ApiDocumentation.AqaVectorDistributionColours, root.ApiDocumentation.AqaVectorQualityDefinition, root.ApiDocumentation.AqaVectorQualityDefinitionArchive, root.ApiDocumentation.AqaVectorQualityDefinitionTable, root.ApiDocumentation.FolderCreationUpdateRequest, root.ApiDocumentation.SourceCreationRequest, root.ApiDocumentation.SourceEditRequest, root.ApiDocumentation.SourceFolderList);
  }
}(this, function(ApiClient, AqaAccountResponse, AqaFolder, AqaOtpResponse, AqaPaymentMethod, AqaPersonalisationSettings, AqaQualityDefinition, AqaQualityDefinitionResponse, AqaQualityDefinitionUpdateResponse, AqaRecordData, AqaRecordReviewRequest, AqaResponse, AqaScore, AqaSnapshot, AqaSnapshotResponse, AqaSource, AqaSourceResponse, AqaTableQualityDefinition, AqaVectorDistributionColours, AqaVectorQualityDefinition, AqaVectorQualityDefinitionArchive, AqaVectorQualityDefinitionTable, FolderCreationUpdateRequest, SourceCreationRequest, SourceEditRequest, SourceFolderList) {
  'use strict';

  /**
   * AQAAnalysisController service.
   * @module api/AQAAnalysisControllerApi
   * @version 1.0
   */

  /**
   * Constructs a new AQAAnalysisControllerApi. 
   * @alias module:api/AQAAnalysisControllerApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the assignQualityDefinitionUsingPUT operation.
     * @callback module:api/AQAAnalysisControllerApi~assignQualityDefinitionUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Assigns an AQD to a source or creates a new source for it. Specify \&quot;new\&quot; for sourceId if a new source must be created
     * @param {String} qualityDefinitionId qualityDefinitionId
     * @param {String} sourceId sourceId
     * @param {module:api/AQAAnalysisControllerApi~assignQualityDefinitionUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.assignQualityDefinitionUsingPUT = function(qualityDefinitionId, sourceId, callback) {
      var postBody = null;

      // verify the required parameter 'qualityDefinitionId' is set
      if (qualityDefinitionId === undefined || qualityDefinitionId === null) {
        throw new Error("Missing the required parameter 'qualityDefinitionId' when calling assignQualityDefinitionUsingPUT");
      }

      // verify the required parameter 'sourceId' is set
      if (sourceId === undefined || sourceId === null) {
        throw new Error("Missing the required parameter 'sourceId' when calling assignQualityDefinitionUsingPUT");
      }


      var pathParams = {
        'qualityDefinitionId': qualityDefinitionId,
        'sourceId': sourceId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/qualityDefinition/{qualityDefinitionId}/source/{sourceId}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the charsetsUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~charsetsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<'String'>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns a list of periodicities
     * @param {module:api/AQAAnalysisControllerApi~charsetsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<'String'>}
     */
    this.charsetsUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = ['String'];

      return this.apiClient.callApi(
        '/api/v1/charsets', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the checkOtpUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~checkOtpUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Checks a 6 digit OTP against the current user
     * @param {String} sixDigitCode sixDigitCode
     * @param {module:api/AQAAnalysisControllerApi~checkOtpUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.checkOtpUsingGET = function(sixDigitCode, callback) {
      var postBody = null;

      // verify the required parameter 'sixDigitCode' is set
      if (sixDigitCode === undefined || sixDigitCode === null) {
        throw new Error("Missing the required parameter 'sixDigitCode' when calling checkOtpUsingGET");
      }


      var pathParams = {
        'sixDigitCode': sixDigitCode
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/otp/check/{sixDigitCode}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the chgSourceUsingPUT operation.
     * @callback module:api/AQAAnalysisControllerApi~chgSourceUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Modifies a source, you change its name and description
     * @param {module:model/SourceEditRequest} request request
     * @param {String} id id
     * @param {module:api/AQAAnalysisControllerApi~chgSourceUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.chgSourceUsingPUT = function(request, id, callback) {
      var postBody = request;

      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling chgSourceUsingPUT");
      }

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling chgSourceUsingPUT");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/sources/{id}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the clearInterruptedPurchaseUsingPUT operation.
     * @callback module:api/AQAAnalysisControllerApi~clearInterruptedPurchaseUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {'String'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Clears Interrupted Purchases
     * @param {module:api/AQAAnalysisControllerApi~clearInterruptedPurchaseUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'String'}
     */
    this.clearInterruptedPurchaseUsingPUT = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = 'String';

      return this.apiClient.callApi(
        '/api/v1/purchases/clear', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the closePiecemealUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~closePiecemealUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Closes a piecemeal snapshot
     * @param {String} piecemealSnapshotId piecemealSnapshotId
     * @param {module:api/AQAAnalysisControllerApi~closePiecemealUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.closePiecemealUsingGET = function(piecemealSnapshotId, callback) {
      var postBody = null;

      // verify the required parameter 'piecemealSnapshotId' is set
      if (piecemealSnapshotId === undefined || piecemealSnapshotId === null) {
        throw new Error("Missing the required parameter 'piecemealSnapshotId' when calling closePiecemealUsingGET");
      }


      var pathParams = {
        'piecemealSnapshotId': piecemealSnapshotId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/mi/piecemeal/close/{piecemealSnapshotId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the createFolderUsingPOST operation.
     * @callback module:api/AQAAnalysisControllerApi~createFolderUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Creates a new folder
     * @param {module:model/FolderCreationUpdateRequest} request request
     * @param {module:api/AQAAnalysisControllerApi~createFolderUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.createFolderUsingPOST = function(request, callback) {
      var postBody = request;

      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling createFolderUsingPOST");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/folders', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the createPaymentMethodIntentUsingPOST operation.
     * @callback module:api/AQAAnalysisControllerApi~createPaymentMethodIntentUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Creates a Stripe Payment Intent
     * @param {module:api/AQAAnalysisControllerApi~createPaymentMethodIntentUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.createPaymentMethodIntentUsingPOST = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/paymentmethods/createintent', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the createSourceInFolderUsingPOST operation.
     * @callback module:api/AQAAnalysisControllerApi~createSourceInFolderUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Creates a source in a folder.
     * @param {module:model/SourceCreationRequest} request request
     * @param {String} id id
     * @param {module:api/AQAAnalysisControllerApi~createSourceInFolderUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.createSourceInFolderUsingPOST = function(request, id, callback) {
      var postBody = request;

      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling createSourceInFolderUsingPOST");
      }

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling createSourceInFolderUsingPOST");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/folders/{id}/sources', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the createSourceUsingPOST operation.
     * @callback module:api/AQAAnalysisControllerApi~createSourceUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Creates a source
     * @param {module:model/SourceCreationRequest} request request
     * @param {module:api/AQAAnalysisControllerApi~createSourceUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.createSourceUsingPOST = function(request, callback) {
      var postBody = request;

      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling createSourceUsingPOST");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/sources', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the createTableQualityDefinitionUsingPOST operation.
     * @callback module:api/AQAAnalysisControllerApi~createTableQualityDefinitionUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This saves or creates an AqaTableQualityDefinition object. Its id is the same as the source it is attached to.
     * @param {module:model/AqaTableQualityDefinition} tqd tqd
     * @param {module:api/AQAAnalysisControllerApi~createTableQualityDefinitionUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.createTableQualityDefinitionUsingPOST = function(tqd, callback) {
      var postBody = tqd;

      // verify the required parameter 'tqd' is set
      if (tqd === undefined || tqd === null) {
        throw new Error("Missing the required parameter 'tqd' when calling createTableQualityDefinitionUsingPOST");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/tablequalities', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the createVectorQualityDefinitionUsingPOST operation.
     * @callback module:api/AQAAnalysisControllerApi~createVectorQualityDefinitionUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This saves or creates a AqaVectorQualityDefinition object. Make sure you craft its id carefully
     * @param {module:model/AqaVectorQualityDefinition} vqd vqd
     * @param {module:api/AQAAnalysisControllerApi~createVectorQualityDefinitionUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.createVectorQualityDefinitionUsingPOST = function(vqd, callback) {
      var postBody = vqd;

      // verify the required parameter 'vqd' is set
      if (vqd === undefined || vqd === null) {
        throw new Error("Missing the required parameter 'vqd' when calling createVectorQualityDefinitionUsingPOST");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/qualities', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the dataTypesUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~dataTypesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<'String'>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns a list of data type names, in the same order they appear in typeCounts for vector and tables
     * @param {module:api/AQAAnalysisControllerApi~dataTypesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<'String'>}
     */
    this.dataTypesUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = ['String'];

      return this.apiClient.callApi(
        '/api/v1/types', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the delMultipleSourcesUsingDELETE operation.
     * @callback module:api/AQAAnalysisControllerApi~delMultipleSourcesUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Deletes multiple sources and all its slots permanently.
     * @param {module:model/SourceFolderList} sources sources
     * @param {module:api/AQAAnalysisControllerApi~delMultipleSourcesUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.delMultipleSourcesUsingDELETE = function(sources, callback) {
      var postBody = sources;

      // verify the required parameter 'sources' is set
      if (sources === undefined || sources === null) {
        throw new Error("Missing the required parameter 'sources' when calling delMultipleSourcesUsingDELETE");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/sources/bulk', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the delSourceUsingDELETE operation.
     * @callback module:api/AQAAnalysisControllerApi~delSourceUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Deletes a source and all its slots permanently.
     * @param {String} id id
     * @param {module:api/AQAAnalysisControllerApi~delSourceUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.delSourceUsingDELETE = function(id, callback) {
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling delSourceUsingDELETE");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/sources/{id}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the delete2FAProfileUsingDELETE operation.
     * @callback module:api/AQAAnalysisControllerApi~delete2FAProfileUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Deletes the current user&#39;s 2FA data, in effect disabling their 2FA set up
     * @param {module:api/AQAAnalysisControllerApi~delete2FAProfileUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.delete2FAProfileUsingDELETE = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/otp/delete', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteFolderUsingDELETE operation.
     * @callback module:api/AQAAnalysisControllerApi~deleteFolderUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Deletes a folder
     * @param {String} id id
     * @param {module:api/AQAAnalysisControllerApi~deleteFolderUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.deleteFolderUsingDELETE = function(id, callback) {
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteFolderUsingDELETE");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/folders/{id}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the deletePaymentMethodUsingDELETE operation.
     * @callback module:api/AQAAnalysisControllerApi~deletePaymentMethodUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaPaymentMethod>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Deletes a payment method and returns the list of remaining payment methods
     * @param {String} paymentMethodId paymentMethodId
     * @param {module:api/AQAAnalysisControllerApi~deletePaymentMethodUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaPaymentMethod>}
     */
    this.deletePaymentMethodUsingDELETE = function(paymentMethodId, callback) {
      var postBody = null;

      // verify the required parameter 'paymentMethodId' is set
      if (paymentMethodId === undefined || paymentMethodId === null) {
        throw new Error("Missing the required parameter 'paymentMethodId' when calling deletePaymentMethodUsingDELETE");
      }


      var pathParams = {
        'paymentMethodId': paymentMethodId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaPaymentMethod];

      return this.apiClient.callApi(
        '/api/v1/paymentmethods/{paymentMethodId}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the dirUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~dirUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaFolder>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets a list of all folders
     * @param {module:api/AQAAnalysisControllerApi~dirUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaFolder>}
     */
    this.dirUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaFolder];

      return this.apiClient.callApi(
        '/api/v1/folders', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the downloadQualityDefinitionUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~downloadQualityDefinitionUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {'Blob'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves a quality definition for a source and forces browser&#39;s save as.
     * @param {String} sourceId sourceId
     * @param {module:api/AQAAnalysisControllerApi~downloadQualityDefinitionUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'Blob'}
     */
    this.downloadQualityDefinitionUsingGET = function(sourceId, callback) {
      var postBody = null;

      // verify the required parameter 'sourceId' is set
      if (sourceId === undefined || sourceId === null) {
        throw new Error("Missing the required parameter 'sourceId' when calling downloadQualityDefinitionUsingGET");
      }


      var pathParams = {
        'sourceId': sourceId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = 'Blob';

      return this.apiClient.callApi(
        '/api/v1/sources/{sourceId}/qualityDefinition/download', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the get2FAProfileUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~get2FAProfileUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaOtpResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Check success field, if true: 2FA is set up and qrUrl contains the qrCode, otherwise the message gives you more details
     * @param {Number} ts ts
     * @param {module:api/AQAAnalysisControllerApi~get2FAProfileUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaOtpResponse}
     */
    this.get2FAProfileUsingGET = function(ts, callback) {
      var postBody = null;

      // verify the required parameter 'ts' is set
      if (ts === undefined || ts === null) {
        throw new Error("Missing the required parameter 'ts' when calling get2FAProfileUsingGET");
      }


      var pathParams = {
        'ts': ts
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = AqaOtpResponse;

      return this.apiClient.callApi(
        '/api/v1/otp/profile/{ts}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getDistributionColoursUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getDistributionColoursUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaVectorDistributionColours} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets Vector Disribution colours
     * @param {String} id id
     * @param {module:api/AQAAnalysisControllerApi~getDistributionColoursUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaVectorDistributionColours}
     */
    this.getDistributionColoursUsingGET = function(id, callback) {
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getDistributionColoursUsingGET");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaVectorDistributionColours;

      return this.apiClient.callApi(
        '/api/v1/distributions/colours/{id}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getFolderUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getFolderUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaFolder} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets a folder
     * @param {String} folderId folderId
     * @param {module:api/AQAAnalysisControllerApi~getFolderUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaFolder}
     */
    this.getFolderUsingGET = function(folderId, callback) {
      var postBody = null;

      // verify the required parameter 'folderId' is set
      if (folderId === undefined || folderId === null) {
        throw new Error("Missing the required parameter 'folderId' when calling getFolderUsingGET");
      }


      var pathParams = {
        'folderId': folderId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaFolder;

      return this.apiClient.callApi(
        '/api/v1/folders/{folderId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getFoldersSourceIsInUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getFoldersSourceIsInUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaFolder>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets all the folders this source is in
     * @param {String} id id
     * @param {module:api/AQAAnalysisControllerApi~getFoldersSourceIsInUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaFolder>}
     */
    this.getFoldersSourceIsInUsingGET = function(id, callback) {
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getFoldersSourceIsInUsingGET");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaFolder];

      return this.apiClient.callApi(
        '/api/v1/sources/{id}/folders', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getPaymentMethodsUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getPaymentMethodsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaPaymentMethod>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns a list of payment methods
     * @param {module:api/AQAAnalysisControllerApi~getPaymentMethodsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaPaymentMethod>}
     */
    this.getPaymentMethodsUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaPaymentMethod];

      return this.apiClient.callApi(
        '/api/v1/paymentmethods', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getPersonalisationSettingsUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getPersonalisationSettingsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaPersonalisationSettings} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets the accounts setup (ie. no. of Sources and Editors)
     * @param {module:api/AQAAnalysisControllerApi~getPersonalisationSettingsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaPersonalisationSettings}
     */
    this.getPersonalisationSettingsUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaPersonalisationSettings;

      return this.apiClient.callApi(
        '/api/v1/settings/personalisation', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getQualitiesUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getQualitiesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaVectorQualityDefinitionTable} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This retrieves an object containing all the existing Qualities for a source. (You will need to parse the id of each quality to determine which vector it belongs to)
     * @param {String} sourceId sourceId
     * @param {module:api/AQAAnalysisControllerApi~getQualitiesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaVectorQualityDefinitionTable}
     */
    this.getQualitiesUsingGET = function(sourceId, callback) {
      var postBody = null;

      // verify the required parameter 'sourceId' is set
      if (sourceId === undefined || sourceId === null) {
        throw new Error("Missing the required parameter 'sourceId' when calling getQualitiesUsingGET");
      }


      var pathParams = {
        'sourceId': sourceId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaVectorQualityDefinitionTable;

      return this.apiClient.callApi(
        '/api/v1/sources/{sourceId}/qualities', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getQualityDefinitionUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getQualityDefinitionUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaQualityDefinition} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves a quality definition for a source.
     * @param {String} sourceId sourceId
     * @param {module:api/AQAAnalysisControllerApi~getQualityDefinitionUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaQualityDefinition}
     */
    this.getQualityDefinitionUsingGET = function(sourceId, callback) {
      var postBody = null;

      // verify the required parameter 'sourceId' is set
      if (sourceId === undefined || sourceId === null) {
        throw new Error("Missing the required parameter 'sourceId' when calling getQualityDefinitionUsingGET");
      }


      var pathParams = {
        'sourceId': sourceId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaQualityDefinition;

      return this.apiClient.callApi(
        '/api/v1/sources/{sourceId}/qualityDefinition', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getQualityDefinitionsUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getQualityDefinitionsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaQualityDefinitionResponse>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns a list of AQDs, filtered by compatibility with a source if the Source Id extra parameter is specified
     * @param {String} compatibleSourceId compatibleSourceId
     * @param {module:api/AQAAnalysisControllerApi~getQualityDefinitionsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaQualityDefinitionResponse>}
     */
    this.getQualityDefinitionsUsingGET = function(compatibleSourceId, callback) {
      var postBody = null;

      // verify the required parameter 'compatibleSourceId' is set
      if (compatibleSourceId === undefined || compatibleSourceId === null) {
        throw new Error("Missing the required parameter 'compatibleSourceId' when calling getQualityDefinitionsUsingGET");
      }


      var pathParams = {
      };
      var queryParams = {
        'compatibleSourceId': compatibleSourceId,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaQualityDefinitionResponse];

      return this.apiClient.callApi(
        '/api/v1/qualitydefinitions/', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getQualityUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getQualityUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaVectorQualityDefinition} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This retrieves a AqaVectorQualityDefinition object for a given source, orientation, and vector index. Make sure you craft the id correctly
     * @param {String} qualityId qualityId
     * @param {module:api/AQAAnalysisControllerApi~getQualityUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaVectorQualityDefinition}
     */
    this.getQualityUsingGET = function(qualityId, callback) {
      var postBody = null;

      // verify the required parameter 'qualityId' is set
      if (qualityId === undefined || qualityId === null) {
        throw new Error("Missing the required parameter 'qualityId' when calling getQualityUsingGET");
      }


      var pathParams = {
        'qualityId': qualityId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaVectorQualityDefinition;

      return this.apiClient.callApi(
        '/api/v1/qualities/{qualityId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getScoresUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getScoresUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaScore>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This retrieves at most number of scores for the latest files in the provided source.
     * @param {String} sourceId sourceId
     * @param {Object} opts Optional parameters
     * @param {Number} opts._number number (default to 6)
     * @param {module:api/AQAAnalysisControllerApi~getScoresUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaScore>}
     */
    this.getScoresUsingGET = function(sourceId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'sourceId' is set
      if (sourceId === undefined || sourceId === null) {
        throw new Error("Missing the required parameter 'sourceId' when calling getScoresUsingGET");
      }


      var pathParams = {
        'sourceId': sourceId
      };
      var queryParams = {
        'number': opts['_number'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaScore];

      return this.apiClient.callApi(
        '/api/v1/sources/{sourceId}/scores', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getSnapshotsForSourceUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getSnapshotsForSourceUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaSnapshot>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets all the snapshots of source
     * @param {String} sourceId sourceId
     * @param {String} start start
     * @param {String} end end
     * @param {module:api/AQAAnalysisControllerApi~getSnapshotsForSourceUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaSnapshot>}
     */
    this.getSnapshotsForSourceUsingGET = function(sourceId, start, end, callback) {
      var postBody = null;

      // verify the required parameter 'sourceId' is set
      if (sourceId === undefined || sourceId === null) {
        throw new Error("Missing the required parameter 'sourceId' when calling getSnapshotsForSourceUsingGET");
      }

      // verify the required parameter 'start' is set
      if (start === undefined || start === null) {
        throw new Error("Missing the required parameter 'start' when calling getSnapshotsForSourceUsingGET");
      }

      // verify the required parameter 'end' is set
      if (end === undefined || end === null) {
        throw new Error("Missing the required parameter 'end' when calling getSnapshotsForSourceUsingGET");
      }


      var pathParams = {
        'sourceId': sourceId
      };
      var queryParams = {
        'start': start,
        'end': end,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaSnapshot];

      return this.apiClient.callApi(
        '/api/v1/sources/{sourceId}/snapshots', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getSourcesCompatibleWithAqdUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getSourcesCompatibleWithAqdUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaSourceResponse>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns a list of Sources compatible with the provided AQD
     * @param {String} qualityDefinitionId qualityDefinitionId
     * @param {module:api/AQAAnalysisControllerApi~getSourcesCompatibleWithAqdUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaSourceResponse>}
     */
    this.getSourcesCompatibleWithAqdUsingGET = function(qualityDefinitionId, callback) {
      var postBody = null;

      // verify the required parameter 'qualityDefinitionId' is set
      if (qualityDefinitionId === undefined || qualityDefinitionId === null) {
        throw new Error("Missing the required parameter 'qualityDefinitionId' when calling getSourcesCompatibleWithAqdUsingGET");
      }


      var pathParams = {
        'qualityDefinitionId': qualityDefinitionId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaSourceResponse];

      return this.apiClient.callApi(
        '/api/v1/sources/qualitydefinition/{qualityDefinitionId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getStripePublishableKeyUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getStripePublishableKeyUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns system information for debugging purposes
     * @param {module:api/AQAAnalysisControllerApi~getStripePublishableKeyUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.getStripePublishableKeyUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/stripepk', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getSupportedExtensionsUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getSupportedExtensionsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {'String': ['String']}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets a list of all folders
     * @param {module:api/AQAAnalysisControllerApi~getSupportedExtensionsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {'String': ['String']}>}
     */
    this.getSupportedExtensionsUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = {'String': ['String']};

      return this.apiClient.callApi(
        '/api/v1/supportedextensions', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getTableQualityDefinitionUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getTableQualityDefinitionUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaTableQualityDefinition} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This retrieves an AqaTableQualityDefinition object for a given source Id
     * @param {String} sourceId sourceId
     * @param {module:api/AQAAnalysisControllerApi~getTableQualityDefinitionUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaTableQualityDefinition}
     */
    this.getTableQualityDefinitionUsingGET = function(sourceId, callback) {
      var postBody = null;

      // verify the required parameter 'sourceId' is set
      if (sourceId === undefined || sourceId === null) {
        throw new Error("Missing the required parameter 'sourceId' when calling getTableQualityDefinitionUsingGET");
      }


      var pathParams = {
        'sourceId': sourceId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaTableQualityDefinition;

      return this.apiClient.callApi(
        '/api/v1/tablequalities/{sourceId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getVectorQualityDefinitionArchiveUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~getVectorQualityDefinitionArchiveUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaVectorQualityDefinitionArchive>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This retrieves all the archived VQDs of a given vqd
     * @param {String} vqdId vqdId
     * @param {module:api/AQAAnalysisControllerApi~getVectorQualityDefinitionArchiveUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaVectorQualityDefinitionArchive>}
     */
    this.getVectorQualityDefinitionArchiveUsingGET = function(vqdId, callback) {
      var postBody = null;

      // verify the required parameter 'vqdId' is set
      if (vqdId === undefined || vqdId === null) {
        throw new Error("Missing the required parameter 'vqdId' when calling getVectorQualityDefinitionArchiveUsingGET");
      }


      var pathParams = {
        'vqdId': vqdId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaVectorQualityDefinitionArchive];

      return this.apiClient.callApi(
        '/api/v1/qualities/archive/{vqdId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the importQualityDefinitionUsingPOST operation.
     * @callback module:api/AQAAnalysisControllerApi~importQualityDefinitionUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaQualityDefinitionUpdateResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Imports a Quality Definition bundle and returns id and compatible sources
     * @param {File} file file
     * @param {module:api/AQAAnalysisControllerApi~importQualityDefinitionUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaQualityDefinitionUpdateResponse}
     */
    this.importQualityDefinitionUsingPOST = function(file, callback) {
      var postBody = null;

      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling importQualityDefinitionUsingPOST");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
        'file': file
      };

      var authNames = [];
      var contentTypes = ['multipart/form-data'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaQualityDefinitionUpdateResponse;

      return this.apiClient.callApi(
        '/api/v1/qualityDefinition', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the invalidateTokenUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~invalidateTokenUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns system information for debugging purposes
     * @param {String} authorization Authorization
     * @param {module:api/AQAAnalysisControllerApi~invalidateTokenUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.invalidateTokenUsingGET = function(authorization, callback) {
      var postBody = null;

      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling invalidateTokenUsingGET");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
        'Authorization': authorization
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/invalidateToken', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the listAccountsUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~listAccountsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaAccountResponse>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns a list of existing accounts
     * @param {module:api/AQAAnalysisControllerApi~listAccountsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaAccountResponse>}
     */
    this.listAccountsUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = [AqaAccountResponse];

      return this.apiClient.callApi(
        '/api/v1/accounts', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the openPiecemealFromAqdUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~openPiecemealFromAqdUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Starts a piecemeal upload from an aqd
     * @param {String} aqdId aqdId
     * @param {module:api/AQAAnalysisControllerApi~openPiecemealFromAqdUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.openPiecemealFromAqdUsingGET = function(aqdId, callback) {
      var postBody = null;

      // verify the required parameter 'aqdId' is set
      if (aqdId === undefined || aqdId === null) {
        throw new Error("Missing the required parameter 'aqdId' when calling openPiecemealFromAqdUsingGET");
      }


      var pathParams = {
        'aqdId': aqdId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/mi/piecemeal/open/aqd/{aqdId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the openPiecemealInSourceUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~openPiecemealInSourceUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Starts a piecemeal upload in a source
     * @param {String} sourceId sourceId
     * @param {module:api/AQAAnalysisControllerApi~openPiecemealInSourceUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.openPiecemealInSourceUsingGET = function(sourceId, callback) {
      var postBody = null;

      // verify the required parameter 'sourceId' is set
      if (sourceId === undefined || sourceId === null) {
        throw new Error("Missing the required parameter 'sourceId' when calling openPiecemealInSourceUsingGET");
      }


      var pathParams = {
        'sourceId': sourceId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/mi/piecemeal/open/source/{sourceId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the otpUsingPOST operation.
     * @callback module:api/AQAAnalysisControllerApi~otpUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaOtpResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This creates a new key for the user and returns its QR code. If called when the user already has a key, a new one is generated (and it invalidates any previous Authenticator entry)
     * @param {module:api/AQAAnalysisControllerApi~otpUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaOtpResponse}
     */
    this.otpUsingPOST = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['*/*'];
      var returnType = AqaOtpResponse;

      return this.apiClient.callApi(
        '/api/v1/otp/setget', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the putSourceInFolderUsingPOST operation.
     * @callback module:api/AQAAnalysisControllerApi~putSourceInFolderUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Puts a source in a folder (And you can add the same source in several folders with several calls)
     * @param {String} folderId folderId
     * @param {String} sourceId sourceId
     * @param {module:api/AQAAnalysisControllerApi~putSourceInFolderUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.putSourceInFolderUsingPOST = function(folderId, sourceId, callback) {
      var postBody = null;

      // verify the required parameter 'folderId' is set
      if (folderId === undefined || folderId === null) {
        throw new Error("Missing the required parameter 'folderId' when calling putSourceInFolderUsingPOST");
      }

      // verify the required parameter 'sourceId' is set
      if (sourceId === undefined || sourceId === null) {
        throw new Error("Missing the required parameter 'sourceId' when calling putSourceInFolderUsingPOST");
      }


      var pathParams = {
        'folderId': folderId,
        'sourceId': sourceId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/folders/{folderId}/sources/{sourceId}', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the remveSourceFromFolderUsingDELETE operation.
     * @callback module:api/AQAAnalysisControllerApi~remveSourceFromFolderUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * removes a source from a folder (no effect if source was not there in the first place)
     * @param {String} folderId folderId
     * @param {String} sourceId sourceId
     * @param {module:api/AQAAnalysisControllerApi~remveSourceFromFolderUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.remveSourceFromFolderUsingDELETE = function(folderId, sourceId, callback) {
      var postBody = null;

      // verify the required parameter 'folderId' is set
      if (folderId === undefined || folderId === null) {
        throw new Error("Missing the required parameter 'folderId' when calling remveSourceFromFolderUsingDELETE");
      }

      // verify the required parameter 'sourceId' is set
      if (sourceId === undefined || sourceId === null) {
        throw new Error("Missing the required parameter 'sourceId' when calling remveSourceFromFolderUsingDELETE");
      }


      var pathParams = {
        'folderId': folderId,
        'sourceId': sourceId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/folders/{folderId}/sources/{sourceId}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the renameFolderUsingPUT operation.
     * @callback module:api/AQAAnalysisControllerApi~renameFolderUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Renames a folder
     * @param {module:model/FolderCreationUpdateRequest} request request
     * @param {String} folderId folderId
     * @param {module:api/AQAAnalysisControllerApi~renameFolderUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.renameFolderUsingPUT = function(request, folderId, callback) {
      var postBody = request;

      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling renameFolderUsingPUT");
      }

      // verify the required parameter 'folderId' is set
      if (folderId === undefined || folderId === null) {
        throw new Error("Missing the required parameter 'folderId' when calling renameFolderUsingPUT");
      }


      var pathParams = {
        'folderId': folderId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/folders/{folderId}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the reviewRecordUsingPOST operation.
     * @callback module:api/AQAAnalysisControllerApi~reviewRecordUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Review Record
     * @param {String} snapshotId snapshotId
     * @param {Number} position position
     * @param {String} orientation orientation
     * @param {module:model/AqaRecordReviewRequest} request request
     * @param {module:api/AQAAnalysisControllerApi~reviewRecordUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.reviewRecordUsingPOST = function(snapshotId, position, orientation, request, callback) {
      var postBody = request;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling reviewRecordUsingPOST");
      }

      // verify the required parameter 'position' is set
      if (position === undefined || position === null) {
        throw new Error("Missing the required parameter 'position' when calling reviewRecordUsingPOST");
      }

      // verify the required parameter 'orientation' is set
      if (orientation === undefined || orientation === null) {
        throw new Error("Missing the required parameter 'orientation' when calling reviewRecordUsingPOST");
      }

      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling reviewRecordUsingPOST");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'position': position,
        'orientation': orientation
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/mi/review/{snapshotId}/{position}/{orientation}', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the selectDefaultPaymentMethodUsingPUT operation.
     * @callback module:api/AQAAnalysisControllerApi~selectDefaultPaymentMethodUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaPaymentMethod>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns a list of payment methods
     * @param {String} paymentMethodId paymentMethodId
     * @param {module:api/AQAAnalysisControllerApi~selectDefaultPaymentMethodUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaPaymentMethod>}
     */
    this.selectDefaultPaymentMethodUsingPUT = function(paymentMethodId, callback) {
      var postBody = null;

      // verify the required parameter 'paymentMethodId' is set
      if (paymentMethodId === undefined || paymentMethodId === null) {
        throw new Error("Missing the required parameter 'paymentMethodId' when calling selectDefaultPaymentMethodUsingPUT");
      }


      var pathParams = {
        'paymentMethodId': paymentMethodId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaPaymentMethod];

      return this.apiClient.callApi(
        '/api/v1/paymentmethods/{paymentMethodId}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the setFoldersUsingPOST operation.
     * @callback module:api/AQAAnalysisControllerApi~setFoldersUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Sets the folders this source appears in
     * @param {String} id id
     * @param {module:model/SourceFolderList} folders folders
     * @param {module:api/AQAAnalysisControllerApi~setFoldersUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.setFoldersUsingPOST = function(id, folders, callback) {
      var postBody = folders;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling setFoldersUsingPOST");
      }

      // verify the required parameter 'folders' is set
      if (folders === undefined || folders === null) {
        throw new Error("Missing the required parameter 'folders' when calling setFoldersUsingPOST");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/sources/{id}/folders', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the setPersonalisationSettingsUsingPUT operation.
     * @callback module:api/AQAAnalysisControllerApi~setPersonalisationSettingsUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns a list of payment methods
     * @param {module:model/AqaPersonalisationSettings} settings settings
     * @param {module:api/AQAAnalysisControllerApi~setPersonalisationSettingsUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.setPersonalisationSettingsUsingPUT = function(settings, callback) {
      var postBody = settings;

      // verify the required parameter 'settings' is set
      if (settings === undefined || settings === null) {
        throw new Error("Missing the required parameter 'settings' when calling setPersonalisationSettingsUsingPUT");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/settings/personalisation', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the sourceUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~sourceUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaSource} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns a source
     * @param {String} id id
     * @param {module:api/AQAAnalysisControllerApi~sourceUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaSource}
     */
    this.sourceUsingGET = function(id, callback) {
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling sourceUsingGET");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaSource;

      return this.apiClient.callApi(
        '/api/v1/sources/{id}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the sourcesInFolderUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~sourcesInFolderUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaSource>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves all sources in folder
     * @param {String} id id
     * @param {module:api/AQAAnalysisControllerApi~sourcesInFolderUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaSource>}
     */
    this.sourcesInFolderUsingGET = function(id, callback) {
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling sourcesInFolderUsingGET");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaSource];

      return this.apiClient.callApi(
        '/api/v1/folders/{id}/sources', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the sourcesUsingGET operation.
     * @callback module:api/AQAAnalysisControllerApi~sourcesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaSource>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves all sources. Set request parameter &#39;independent&#39; to true to only get sources NOT in a folder.
     * @param {Object} opts Optional parameters
     * @param {String} opts.independent independent (default to false)
     * @param {module:api/AQAAnalysisControllerApi~sourcesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaSource>}
     */
    this.sourcesUsingGET = function(opts, callback) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        'independent': opts['independent'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaSource];

      return this.apiClient.callApi(
        '/api/v1/sources', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the uploadFileUsingPOST operation.
     * @callback module:api/AQAAnalysisControllerApi~uploadFileUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaSnapshotResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Uploads a file into a source
     * @param {File} file file
     * @param {String} sourceid sourceid
     * @param {module:api/AQAAnalysisControllerApi~uploadFileUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaSnapshotResponse}
     */
    this.uploadFileUsingPOST = function(file, sourceid, callback) {
      var postBody = null;

      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling uploadFileUsingPOST");
      }

      // verify the required parameter 'sourceid' is set
      if (sourceid === undefined || sourceid === null) {
        throw new Error("Missing the required parameter 'sourceid' when calling uploadFileUsingPOST");
      }


      var pathParams = {
        'sourceid': sourceid
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
        'file': file
      };

      var authNames = [];
      var contentTypes = ['multipart/form-data'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaSnapshotResponse;

      return this.apiClient.callApi(
        '/api/v1/sources/{sourceid}/snapshots', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the uploadRecordDataUsingPUT operation.
     * @callback module:api/AQAAnalysisControllerApi~uploadRecordDataUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Uploads a record into a snapshot - in the form of a CSV string
     * @param {module:model/AqaRecordData} requestData requestData
     * @param {String} piecemealSnapshotId piecemealSnapshotId
     * @param {module:api/AQAAnalysisControllerApi~uploadRecordDataUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.uploadRecordDataUsingPUT = function(requestData, piecemealSnapshotId, callback) {
      var postBody = requestData;

      // verify the required parameter 'requestData' is set
      if (requestData === undefined || requestData === null) {
        throw new Error("Missing the required parameter 'requestData' when calling uploadRecordDataUsingPUT");
      }

      // verify the required parameter 'piecemealSnapshotId' is set
      if (piecemealSnapshotId === undefined || piecemealSnapshotId === null) {
        throw new Error("Missing the required parameter 'piecemealSnapshotId' when calling uploadRecordDataUsingPUT");
      }


      var pathParams = {
        'piecemealSnapshotId': piecemealSnapshotId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/mi/piecemeal/records/data/{piecemealSnapshotId}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the uploadRecordFileUsingPUT operation.
     * @callback module:api/AQAAnalysisControllerApi~uploadRecordFileUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Uploads a record into an open snapshot
     * @param {File} file file
     * @param {String} piecemealSnapshotId piecemealSnapshotId
     * @param {module:api/AQAAnalysisControllerApi~uploadRecordFileUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.uploadRecordFileUsingPUT = function(file, piecemealSnapshotId, callback) {
      var postBody = null;

      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling uploadRecordFileUsingPUT");
      }

      // verify the required parameter 'piecemealSnapshotId' is set
      if (piecemealSnapshotId === undefined || piecemealSnapshotId === null) {
        throw new Error("Missing the required parameter 'piecemealSnapshotId' when calling uploadRecordFileUsingPUT");
      }


      var pathParams = {
        'piecemealSnapshotId': piecemealSnapshotId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
        'file': file
      };

      var authNames = [];
      var contentTypes = ['multipart/form-data'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/mi/piecemeal/records/file/{piecemealSnapshotId}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
