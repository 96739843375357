import React from 'react';
import Typography from '@material-ui/core/Typography';
import AqaComponent from "../../../shared/aqacomponent/AqaComponent";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";


const SEVERITIES = 
[
	["amber", "Amber"],
	["red", "Red"]
]
;
// const TYPE_SEVERITY = "typeSeverity";


export default class AqaTypeBreachSeveritySelector extends AqaComponent
{

	render()
	{
		return (
			<Typography
				variant="inherit"
				color="inherit"
				style={{textAlign:"left",fontSize:"16px",color:"#4CADC4",paddingLeft:"4px",fontFamily:"Montserrat",marginLeft:"20px"}}
			>
				{/*<div>
					{SEVERITIES.map((s, i) => <div key={`tk${i}`} className={"aqa-text-action"}><input type="radio" name="severity" value={s[0]} onChange={this.props.changeHandler} checked={this.props.typeSeverity === s[0]} />{s[1]}</div>)}
				</div>*/}

				<RadioGroup
					aria-label="severity"
					name="severity"
					value={this.props.typeSeverity}
					color="primary"
					onChange={this.props.changeHandler}
					row={true}
				>
					{SEVERITIES.map((s, i) =>
						(<FormControlLabel key={`tk${i}`} value={s[0]} control={<Radio color="primary" style={{padding:"2px"}}/>} label={
						(<Typography className={"aqa-text-action"} variant="title" align="left"
									 style={{padding:"0px" }}>
							<span style={{float:"left",width:"80px"}}>{s[1]}</span>
						</Typography>)


					} style={{width:"140px",margin:"0px"}} />)
					)}
				</RadioGroup>
			</Typography>
		);

	} // render



} ////
