/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as FolderIcon} from "../../images/aqa-icons/AQA-Edit.svg";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import HtmlTooltip from "@material-ui/core/Tooltip";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaFolderEdit extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            FolderName: "",
            errorMessage: ""
        };
    }

    handleSources(){
        AqaComponent.backend.sourcesUsingGET(
            {},
            (error, data, response) =>
            {
                if (error) {
                    this.reportError("A problem getting the sources from the server was encountered.",
                        "AqaFolderView.handleSources, backend call: " + error,this);
                    var errorJSON = JSON.parse(response.text);
                    if (errorJSON.error === "invalid_token") {
                        this.props.dataFromRoot.props.dataFromRoot.props.parent.logout();
                    }
                }
                else
                {
                    this.setState({isLoaded:false,sources:data});
                    this.props.dataFromRoot.handleFolders(data);
                }
            }
        );
    }
    handleFolderCreation(folderName){
        var folders = this.props.dataFromRoot.state.selectedFolders;
        if(folders.length === 1) {
            var folder=folders[0];
            var bodyParams = "{\"name\":\"" + folderName + "\"}"
            AqaComponent.backend.renameFolderUsingPUT(
                bodyParams,
                folder.id,
                (error, data, response) => {
                    if (error) this.reportError("A problem posting the  new folder from the server was encountered.", "AqaNewFolder.handleFolderCreation, backend call: " + error,this);
                    else {
                        this.setState({isLoaded: true, openMessage: false});
                        this.props.dataFromRoot.handleFolderSelectionReset();
                        //this.props.dataFromRoot.handleFoldersReset();
                        this.handleSources();
                    }
                }
            );
        }
    }
    handleCreateFolderClosePopup(e) {
        e.preventDefault();
        var folderName = this.state.FolderName;
        if(folderName === "" || folderName.trim() === ""){
            this.setState({ errorMessage: "Please fill mandatory fields" });
        }
        else{
// onsole.log(folderName);
            this.handleFolderCreation(folderName);
        }
        this.setState({ openMessage: true });
    }

    handleClosePopup(e) {
        e.preventDefault();
        this.props.dataFromRoot.handleFolderSelectionReset();
        this.setState({ errorMessage:"",openMessage: false,FolderName:"" });
    }

    handleNewFolderClick(e) {
        e.preventDefault();
        var folders = this.props.dataFromRoot.state.selectedFolders;
        if(folders.length === 1){
            var folder = folders[0];
            this.setState({FolderName:folder.name});
        }
        this.setState({ openMessage: true });
    }

    handleNameChange(evt){
        var name = evt.target.value;
        this.setState({"FolderName": name,errorMessage:""});
    }
    componentDidMount(){

    }
    render() {
        const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,errorMessage } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return (<div>Loading...</div>);
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%"}}>
                                {(this.props.dataForDisabled ===true)?(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Operation not permitted</Typography>
                                        </div>
                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <div>
                                <IconButton className="aqa-button" >
                                    <div className="aqa-icon aqa-icon-disabled"  style={{width:"32px"}}>
                                        <FolderIcon width="32px" />
                                    </div>
                                    <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#dadbdb",paddingLeft:"4px",marginTop:"-4px"}}>
                                        RENAME
                                    </Typography>
                                </IconButton>
                                        </div>
                                    </HtmlTooltip>
                                    ):
                                    (
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            <div>
                                                <Typography color="inherit" className="aqa-text-action">Click to rename Folder</Typography>
                                            </div>
                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <IconButton className="aqa-button" onClick={this.handleNewFolderClick.bind(this)} >
                                        <div className="aqa-g-icon" style={{width:"32px"}}>
                                            <FolderIcon width="32px" />
                                        </div>
                                        <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                            RENAME
                                        </Typography>
                                    </IconButton>
                                        </HtmlTooltip>
                                            )}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Dialog
                        open={this.state.openMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                Edit Folder
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={this.handleClosePopup.bind(this)} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:"500px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <TextField
                                        id="folder-name"
                                        //onChange={this.handleTextChange('guest')}
                                        label="Folder Name"
                                        value={this.state.FolderName}
                                        className={classes.textField}
                                        variant="filled"
                                        margin="dense"
                                        multiline={false}
                                        rows={1}
                                        style={{ width: "95%"}}
                                        onChange={this.handleNameChange.bind(this)}
                                        required
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop:8,marginBottom:8, marginLeft:"0px",borderTop:"1px solid #ccc"}}>
                                <Grid item xs={2} sm={2} lg={2} xl={2}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="right"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: 8,
                                                marginRight: 8,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Cancel
                                    </Button>

                                </Grid>
                                <Grid item xs={4} sm={4} lg={4} xl={4}>
                                    <Button  variant="contained"
                                             onClick={this.handleCreateFolderClosePopup.bind(this)}
                                             color="primary"
                                             align="left"
                                             className="aqa-action-button"
                                             style={{
                                                 marginTop: 8,
                                                 marginLeft: "-70px",
                                                 marginRight: 8,
                                                 color: '#4cadc4',
                                                 backgroundColor: 'white',
                                                 border: '1px solid #4cadc4',
                                                 fontSize: '0.7rem',
                                                 padding: '3px'
                                             }}>
                                        Save & Close
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sm={6} lg={6} xl={6}>
                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>
                </div>
            );
        }
    }
}

AqaFolderEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaFolderEdit);
