/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/AqaCellNote'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./AqaCellNote'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaCellReviewRequest = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.AqaCellNote);
  }
}(this, function(ApiClient, AqaCellNote) {
  'use strict';




  /**
   * The AqaCellReviewRequest model module.
   * @module model/AqaCellReviewRequest
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaCellReviewRequest</code>.
   * @alias module:model/AqaCellReviewRequest
   * @class
   */
  var exports = function() {
    var _this = this;



  };

  /**
   * Constructs a <code>AqaCellReviewRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaCellReviewRequest} obj Optional instance to populate.
   * @return {module:model/AqaCellReviewRequest} The populated <code>AqaCellReviewRequest</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('note')) {
        obj['note'] = AqaCellNote.constructFromObject(data['note']);
      }
      if (data.hasOwnProperty('review')) {
        obj['review'] = ApiClient.convertToType(data['review'], 'Number');
      }
    }
    return obj;
  }

  /**
   * @member {module:model/AqaCellNote} note
   */
  exports.prototype['note'] = undefined;
  /**
   * @member {Number} review
   */
  exports.prototype['review'] = undefined;



  return exports;
}));


