import React, {Component} from 'react';

import DistributionGraphPin from "./DistributionGraphPin";
// import {BACKEND_TYPE_STRING, BACKEND_TYPE_NUMBER, BACKEND_TYPE_DATE} from "../../snapshotdetail/AqaSnapshotDetail"


export default class DistributionGraphPinSet extends Component
{

	static #serial = 0;

	constructor(props)
	{
		super(props);
	
		this.n = this.props.buckets ? this.props.buckets.length : 0;
	
	} //

	render()
	{

		if (this.n === 0) return null;

// onsole.log("DGPS: " + this.props.yOffset);

		const x = this.props.x;

		const span = this.props.span;

//		const offset = this.props.x + (this.elementSpan >> 1);

		// Clipping
		if (x + span < this.props.from || x > this.props.to) return null;

		const y = this.props.y;
		let elementSpan = null;


// onsole.log("In DGPS render");


// rawRangeBottom

		let xFunc;
		
		
		if (this.props.sortType === 2 && this.props.order === 1)
		{
		
// onsole.log("I am using the fixed spacing: " + this.props.fixedSpacingBetweenPins);
		
			xFunc = i => x + i * this.props.fixedSpacingBetweenPins
		
		}
		else 
		
		if (this.props.boundaries)
		{
// onsole.log("I have boundaries");
		
			// No sub intervals - we're talking dates and numbers
			elementSpan = null;
			const dataSpan = this.props.boundaries[1] - this.props.boundaries[0];
			const buckets = this.props.buckets;


// onsole.log("boundaries: " + JSON.stringify(this.props.boundaries));

// onsole.log("type name: " + this.props.typeName + " order: " + this.props.order);

/*
			if (this.props.typeName === BACKEND_TYPE_STRING)
			{
				// Strings on an equal footing
				
// onsole.log("XFunc A: " + dataSpan);
				
				
				xFunc = dataSpan === 0 ? i => 0 : i => i * this.props.fixedSpacingBetweenPins
				
				
				
				 // x + this.props.controller.dataValueToPixels(i, span)
			}
			else
			{
*/		
			
// onsole.log("XFunc B");
			
				xFunc = dataSpan === 0 ?
					i => x
				:
//					i => x + (buckets[i].rawRangeBottom - this.props.boundaries[0]) * span / dataSpan;
					i => x + this.props.controller.dataValueToPixels(buckets[i].rawRangeBottom, span)
				;
/*			} */

		}
		else
		{
		
// onsole.log("I DO NOT have boundaries");
		
			// Strings - with "sub buckets"
			elementSpan =  this.n !== 0 ? span / (this.n + 1) : 0;
			xFunc = i => x + elementSpan * i;
		}

// {x + elementSpan * i}





		if (this.props.pinWatch)
		{
			// fake run
			
// onsole.log("I have a pin watch");


			let i, n = this.props.buckets ? this.props.buckets.length : 0;
			for(i = 0; i < n; i++)
			{

				if (new DistributionGraphPin
				(
					{
						controller:this.props.controller,
						bucket:this.props.buckets[i],
						x:xFunc(i),
						y:y,
						span:elementSpan,

						from:this.props.from,
						to:this.props.to,
						pinWatch:this.props.pinWatch,
						
					}

				).render() === null) return null;
			}		
			return true;
		}



		return (
			<g>
				{
					this.props.buckets.map
					(
						(b, i) =>
						<DistributionGraphPin
							controller={this.props.controller}
							key={`dghbp${DistributionGraphPinSet.#serial++}`}
							bucket={b}
							x={xFunc(i)}
							y={y}
							span={elementSpan}

							usableHeight={this.props.usableHeight}
							maxCount={this.props.maxCount}

							from={this.props.from}
							to={this.props.to}
							pinWatch={this.props.pinWatch}
							
							selectedIndex={this.props.selectedIndex}
							selected={this.props.selectedIndex === b.index}
						/>
					)
				}
			</g>
		);
	
	} // render



} ////




