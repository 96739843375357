/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/AqaCellColours', '../model/AqaDistribution', '../model/AqaHeatmap', '../model/AqaResponse', '../model/AqaScore', '../model/AqaSheetChoice', '../model/AqaSheetSelection', '../model/AqaSnapshot', '../model/AqaSnapshotFilter', '../model/AqaSnapshotResponse', '../model/AqaSubsnapshotCreationResponse', '../model/AqaTable', '../model/AqaVector', '../model/AqaVectorColours', '../model/AqaVectorQualityDefinition', '../model/AqaVectorValues', '../model/CharsetForSnapshotRequest', '../model/InputStreamResource', '../model/SimplifiedVectorSummary', '../model/StreamingResponseBody', '../model/UploadMonitor'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/AqaCellColours'), require('../model/AqaDistribution'), require('../model/AqaHeatmap'), require('../model/AqaResponse'), require('../model/AqaScore'), require('../model/AqaSheetChoice'), require('../model/AqaSheetSelection'), require('../model/AqaSnapshot'), require('../model/AqaSnapshotFilter'), require('../model/AqaSnapshotResponse'), require('../model/AqaSubsnapshotCreationResponse'), require('../model/AqaTable'), require('../model/AqaVector'), require('../model/AqaVectorColours'), require('../model/AqaVectorQualityDefinition'), require('../model/AqaVectorValues'), require('../model/CharsetForSnapshotRequest'), require('../model/InputStreamResource'), require('../model/SimplifiedVectorSummary'), require('../model/StreamingResponseBody'), require('../model/UploadMonitor'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AQASnapshotControllerApi = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.AqaCellColours, root.ApiDocumentation.AqaDistribution, root.ApiDocumentation.AqaHeatmap, root.ApiDocumentation.AqaResponse, root.ApiDocumentation.AqaScore, root.ApiDocumentation.AqaSheetChoice, root.ApiDocumentation.AqaSheetSelection, root.ApiDocumentation.AqaSnapshot, root.ApiDocumentation.AqaSnapshotFilter, root.ApiDocumentation.AqaSnapshotResponse, root.ApiDocumentation.AqaSubsnapshotCreationResponse, root.ApiDocumentation.AqaTable, root.ApiDocumentation.AqaVector, root.ApiDocumentation.AqaVectorColours, root.ApiDocumentation.AqaVectorQualityDefinition, root.ApiDocumentation.AqaVectorValues, root.ApiDocumentation.CharsetForSnapshotRequest, root.ApiDocumentation.InputStreamResource, root.ApiDocumentation.SimplifiedVectorSummary, root.ApiDocumentation.StreamingResponseBody, root.ApiDocumentation.UploadMonitor);
  }
}(this, function(ApiClient, AqaCellColours, AqaDistribution, AqaHeatmap, AqaResponse, AqaScore, AqaSheetChoice, AqaSheetSelection, AqaSnapshot, AqaSnapshotFilter, AqaSnapshotResponse, AqaSubsnapshotCreationResponse, AqaTable, AqaVector, AqaVectorColours, AqaVectorQualityDefinition, AqaVectorValues, CharsetForSnapshotRequest, InputStreamResource, SimplifiedVectorSummary, StreamingResponseBody, UploadMonitor) {
  'use strict';

  /**
   * AQASnapshotController service.
   * @module api/AQASnapshotControllerApi
   * @version 1.0
   */

  /**
   * Constructs a new AQASnapshotControllerApi. 
   * @alias module:api/AQASnapshotControllerApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the delUsingDELETE operation.
     * @callback module:api/AQASnapshotControllerApi~delUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Deletes a snapshot
     * @param {String} id id
     * @param {module:api/AQASnapshotControllerApi~delUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.delUsingDELETE = function(id, callback) {
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling delUsingDELETE");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{id}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the downloadSnapshotUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~downloadSnapshotUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InputStreamResource} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Download a snapshot as its original file
     * @param {String} id id
     * @param {module:api/AQASnapshotControllerApi~downloadSnapshotUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InputStreamResource}
     */
    this.downloadSnapshotUsingGET = function(id, callback) {
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling downloadSnapshotUsingGET");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = InputStreamResource;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{id}/original', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the filterSnapshotUsingPOST operation.
     * @callback module:api/AQASnapshotControllerApi~filterSnapshotUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaSubsnapshotCreationResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Filters a snapshot into a different snapshot, from a provided list of elementary filters.
     * @param {String} id id
     * @param {Array.<module:model/AqaSnapshotFilter>} filters filters
     * @param {module:api/AQASnapshotControllerApi~filterSnapshotUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaSubsnapshotCreationResponse}
     */
    this.filterSnapshotUsingPOST = function(id, filters, callback) {
      var postBody = filters;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling filterSnapshotUsingPOST");
      }

      // verify the required parameter 'filters' is set
      if (filters === undefined || filters === null) {
        throw new Error("Missing the required parameter 'filters' when calling filterSnapshotUsingPOST");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaSubsnapshotCreationResponse;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{id}/subsnapshot', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getAllUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getAllUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaSnapshot>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This retrieves all the existing snapshots
     * @param {module:api/AQASnapshotControllerApi~getAllUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaSnapshot>}
     */
    this.getAllUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaSnapshot];

      return this.apiClient.callApi(
        '/api/v1/snapshots/', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getColVectorDistributionUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getColVectorDistributionUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaDistribution} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets the distributions for this column
     * @param {String} snapshotId snapshotId
     * @param {String} colId colId
     * @param {String} type type
     * @param {String} nBars nBars
     * @param {module:api/AQASnapshotControllerApi~getColVectorDistributionUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaDistribution}
     */
    this.getColVectorDistributionUsingGET = function(snapshotId, colId, type, nBars, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getColVectorDistributionUsingGET");
      }

      // verify the required parameter 'colId' is set
      if (colId === undefined || colId === null) {
        throw new Error("Missing the required parameter 'colId' when calling getColVectorDistributionUsingGET");
      }

      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling getColVectorDistributionUsingGET");
      }

      // verify the required parameter 'nBars' is set
      if (nBars === undefined || nBars === null) {
        throw new Error("Missing the required parameter 'nBars' when calling getColVectorDistributionUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'colId': colId
      };
      var queryParams = {
        'type': type,
        'nBars': nBars,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaDistribution;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/columns/{colId}/distribution', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getColVectorSimplifiedUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getColVectorSimplifiedUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SimplifiedVectorSummary} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets a simplified column summary with extra statistics
     * @param {String} snapshotId snapshotId
     * @param {String} colId colId
     * @param {module:api/AQASnapshotControllerApi~getColVectorSimplifiedUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SimplifiedVectorSummary}
     */
    this.getColVectorSimplifiedUsingGET = function(snapshotId, colId, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getColVectorSimplifiedUsingGET");
      }

      // verify the required parameter 'colId' is set
      if (colId === undefined || colId === null) {
        throw new Error("Missing the required parameter 'colId' when calling getColVectorSimplifiedUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'colId': colId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = SimplifiedVectorSummary;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/columns/{colId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getColVectorUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getColVectorUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaVector} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets a column summary
     * @param {String} snapshotId snapshotId
     * @param {String} colId colId
     * @param {module:api/AQASnapshotControllerApi~getColVectorUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaVector}
     */
    this.getColVectorUsingGET = function(snapshotId, colId, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getColVectorUsingGET");
      }

      // verify the required parameter 'colId' is set
      if (colId === undefined || colId === null) {
        throw new Error("Missing the required parameter 'colId' when calling getColVectorUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'colId': colId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaVector;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/columns/{colId}/simplification', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getColVectorValuesUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getColVectorValuesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaVectorValues>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets the values of this column. Offset and limit can be used to get a subset of the vectors values. quantity indicates how many columns you want, starting from rowid.
     * @param {String} snapshotId snapshotId
     * @param {Number} colId colId
     * @param {Object} opts Optional parameters
     * @param {Number} opts.offset offset (default to 0)
     * @param {Number} opts.limit limit (default to -1)
     * @param {Number} opts.quantity quantity (default to 1)
     * @param {module:api/AQASnapshotControllerApi~getColVectorValuesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaVectorValues>}
     */
    this.getColVectorValuesUsingGET = function(snapshotId, colId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getColVectorValuesUsingGET");
      }

      // verify the required parameter 'colId' is set
      if (colId === undefined || colId === null) {
        throw new Error("Missing the required parameter 'colId' when calling getColVectorValuesUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'colId': colId
      };
      var queryParams = {
        'offset': opts['offset'],
        'limit': opts['limit'],
        'quantity': opts['quantity'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaVectorValues];

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/columns/{colId}/values', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getColourAndOverrideMapsUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getColourAndOverrideMapsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/StreamingResponseBody} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * getColourAndOverrideMaps
     * @param {String} snapshotId snapshotId
     * @param {String} orientation orientation
     * @param {module:api/AQASnapshotControllerApi~getColourAndOverrideMapsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/StreamingResponseBody}
     */
    this.getColourAndOverrideMapsUsingGET = function(snapshotId, orientation, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getColourAndOverrideMapsUsingGET");
      }

      // verify the required parameter 'orientation' is set
      if (orientation === undefined || orientation === null) {
        throw new Error("Missing the required parameter 'orientation' when calling getColourAndOverrideMapsUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'orientation': orientation
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = 'Blob';

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/colourandoverridemaps/{orientation}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getColourmapUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getColourmapUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {'Blob'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets the whole snapshot colours as a binary file
     * @param {String} snapshotId snapshotId
     * @param {module:api/AQASnapshotControllerApi~getColourmapUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'Blob'}
     */
    this.getColourmapUsingGET = function(snapshotId, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getColourmapUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/octet-stream'];
      var returnType = 'Blob';

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/colourmap', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getColumnVectorColoursUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getColumnVectorColoursUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaVectorColours} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets the column&#39;s colours
     * @param {String} snapshotId snapshotId
     * @param {Number} colId colId
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.withSpecifics withSpecifics (default to false)
     * @param {module:api/AQASnapshotControllerApi~getColumnVectorColoursUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaVectorColours}
     */
    this.getColumnVectorColoursUsingGET = function(snapshotId, colId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getColumnVectorColoursUsingGET");
      }

      // verify the required parameter 'colId' is set
      if (colId === undefined || colId === null) {
        throw new Error("Missing the required parameter 'colId' when calling getColumnVectorColoursUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'colId': colId
      };
      var queryParams = {
        'withSpecifics': opts['withSpecifics'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaVectorColours;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/columns/{colId}/colours', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getHeatImageUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getHeatImageUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {'Blob'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets a column-scale heat map for the snapshot
     * @param {String} snapshotId snapshotId
     * @param {module:api/AQASnapshotControllerApi~getHeatImageUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'Blob'}
     */
    this.getHeatImageUsingGET = function(snapshotId, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getHeatImageUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['image/png'];
      var returnType = 'Blob';

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/heat', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getNarrowColourmapUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getNarrowColourmapUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {'Blob'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets the whole snapshot colours as a binary file
     * @param {String} snapshotId snapshotId
     * @param {module:api/AQASnapshotControllerApi~getNarrowColourmapUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'Blob'}
     */
    this.getNarrowColourmapUsingGET = function(snapshotId, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getNarrowColourmapUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/octet-stream'];
      var returnType = 'Blob';

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/narrowcolourmap', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getQualityFromSnapshotUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getQualityFromSnapshotUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaVectorQualityDefinition} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This retrieves a AqaVectorQualityDefinition object for a given snapshot, an orientation, and vector index
     * @param {String} snapshotId snapshotId
     * @param {String} orientation orientation
     * @param {Number} vectorId vectorId
     * @param {module:api/AQASnapshotControllerApi~getQualityFromSnapshotUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaVectorQualityDefinition}
     */
    this.getQualityFromSnapshotUsingGET = function(snapshotId, orientation, vectorId, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getQualityFromSnapshotUsingGET");
      }

      // verify the required parameter 'orientation' is set
      if (orientation === undefined || orientation === null) {
        throw new Error("Missing the required parameter 'orientation' when calling getQualityFromSnapshotUsingGET");
      }

      // verify the required parameter 'vectorId' is set
      if (vectorId === undefined || vectorId === null) {
        throw new Error("Missing the required parameter 'vectorId' when calling getQualityFromSnapshotUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'orientation': orientation,
        'vectorId': vectorId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaVectorQualityDefinition;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/{orientation}/{vectorId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRowVectorColoursAcrossColumnsAsJsonUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getRowVectorColoursAcrossColumnsAsJsonUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, module:model/{'String': AqaCellColours}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets a row&#39;s colours based on column VQDs
     * @param {String} snapshotId snapshotId
     * @param {Number} rowId rowId
     * @param {module:api/AQASnapshotControllerApi~getRowVectorColoursAcrossColumnsAsJsonUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, module:model/{'String': AqaCellColours}>}
     */
    this.getRowVectorColoursAcrossColumnsAsJsonUsingGET = function(snapshotId, rowId, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getRowVectorColoursAcrossColumnsAsJsonUsingGET");
      }

      // verify the required parameter 'rowId' is set
      if (rowId === undefined || rowId === null) {
        throw new Error("Missing the required parameter 'rowId' when calling getRowVectorColoursAcrossColumnsAsJsonUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'rowId': rowId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = {'String': AqaCellColours};

      return this.apiClient.callApi(
        '/api/v1/snapshots/colours/json/{snapshotId}/rows/{rowId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRowVectorColoursAcrossColumnsUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getRowVectorColoursAcrossColumnsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {'String': 'Number'}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets a row&#39;s colours based on column VQDs
     * @param {String} snapshotId snapshotId
     * @param {Number} rowId rowId
     * @param {module:api/AQASnapshotControllerApi~getRowVectorColoursAcrossColumnsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {'String': 'Number'}>}
     */
    this.getRowVectorColoursAcrossColumnsUsingGET = function(snapshotId, rowId, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getRowVectorColoursAcrossColumnsUsingGET");
      }

      // verify the required parameter 'rowId' is set
      if (rowId === undefined || rowId === null) {
        throw new Error("Missing the required parameter 'rowId' when calling getRowVectorColoursAcrossColumnsUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'rowId': rowId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = {'String': 'Number'};

      return this.apiClient.callApi(
        '/api/v1/snapshots/colours/{snapshotId}/rows/{rowId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRowVectorColoursUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getRowVectorColoursUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaVectorColours} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets the row&#39;s colours
     * @param {String} snapshotId snapshotId
     * @param {Number} rowId rowId
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.withSpecifics withSpecifics (default to false)
     * @param {module:api/AQASnapshotControllerApi~getRowVectorColoursUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaVectorColours}
     */
    this.getRowVectorColoursUsingGET = function(snapshotId, rowId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getRowVectorColoursUsingGET");
      }

      // verify the required parameter 'rowId' is set
      if (rowId === undefined || rowId === null) {
        throw new Error("Missing the required parameter 'rowId' when calling getRowVectorColoursUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'rowId': rowId
      };
      var queryParams = {
        'withSpecifics': opts['withSpecifics'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaVectorColours;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/rows/{rowId}/colours', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRowVectorDistributionUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getRowVectorDistributionUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaDistribution} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets the distributions for this row
     * @param {String} snapshotId snapshotId
     * @param {String} rowId rowId
     * @param {String} type type
     * @param {String} nBars nBars
     * @param {module:api/AQASnapshotControllerApi~getRowVectorDistributionUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaDistribution}
     */
    this.getRowVectorDistributionUsingGET = function(snapshotId, rowId, type, nBars, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getRowVectorDistributionUsingGET");
      }

      // verify the required parameter 'rowId' is set
      if (rowId === undefined || rowId === null) {
        throw new Error("Missing the required parameter 'rowId' when calling getRowVectorDistributionUsingGET");
      }

      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling getRowVectorDistributionUsingGET");
      }

      // verify the required parameter 'nBars' is set
      if (nBars === undefined || nBars === null) {
        throw new Error("Missing the required parameter 'nBars' when calling getRowVectorDistributionUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'rowId': rowId
      };
      var queryParams = {
        'type': type,
        'nBars': nBars,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaDistribution;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/rows/{rowId}/distribution', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRowVectorSimplifiedUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getRowVectorSimplifiedUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SimplifiedVectorSummary} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets a simplified row summary with extra statistics
     * @param {String} snapshotId snapshotId
     * @param {String} rowId rowId
     * @param {module:api/AQASnapshotControllerApi~getRowVectorSimplifiedUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SimplifiedVectorSummary}
     */
    this.getRowVectorSimplifiedUsingGET = function(snapshotId, rowId, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getRowVectorSimplifiedUsingGET");
      }

      // verify the required parameter 'rowId' is set
      if (rowId === undefined || rowId === null) {
        throw new Error("Missing the required parameter 'rowId' when calling getRowVectorSimplifiedUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'rowId': rowId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = SimplifiedVectorSummary;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/rows/{rowId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRowVectorUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getRowVectorUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaVector} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets a row summary
     * @param {String} snapshotId snapshotId
     * @param {String} rowId rowId
     * @param {module:api/AQASnapshotControllerApi~getRowVectorUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaVector}
     */
    this.getRowVectorUsingGET = function(snapshotId, rowId, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getRowVectorUsingGET");
      }

      // verify the required parameter 'rowId' is set
      if (rowId === undefined || rowId === null) {
        throw new Error("Missing the required parameter 'rowId' when calling getRowVectorUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'rowId': rowId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaVector;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/rows/{rowId}/simplification', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRowVectorValuesUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getRowVectorValuesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaVectorValues>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets the values of this row. Offset and limit can be used to get a subset of the vectors values. quantity indicates how many rows you want, starting from rowid.
     * @param {String} snapshotId snapshotId
     * @param {Number} rowId rowId
     * @param {Object} opts Optional parameters
     * @param {Number} opts.offset offset (default to 0)
     * @param {Number} opts.limit limit (default to -1)
     * @param {Number} opts.quantity quantity (default to 1)
     * @param {String} opts.notes notes (default to true)
     * @param {module:api/AQASnapshotControllerApi~getRowVectorValuesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaVectorValues>}
     */
    this.getRowVectorValuesUsingGET = function(snapshotId, rowId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getRowVectorValuesUsingGET");
      }

      // verify the required parameter 'rowId' is set
      if (rowId === undefined || rowId === null) {
        throw new Error("Missing the required parameter 'rowId' when calling getRowVectorValuesUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'rowId': rowId
      };
      var queryParams = {
        'offset': opts['offset'],
        'limit': opts['limit'],
        'quantity': opts['quantity'],
        'notes': opts['notes'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = [AqaVectorValues];

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/rows/{rowId}/values', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getSheetsUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getSheetsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaSheetChoice} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets a list of sheets for a snapshot - only use after an upload
     * @param {String} snapshotId snapshotId
     * @param {module:api/AQASnapshotControllerApi~getSheetsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaSheetChoice}
     */
    this.getSheetsUsingGET = function(snapshotId, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getSheetsUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaSheetChoice;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/sheets', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getSnapshotColumnsHeatmapUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getSnapshotColumnsHeatmapUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaHeatmap} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets the columns-based heatmap for this snapshot
     * @param {String} snapshotId snapshotId
     * @param {Object} opts Optional parameters
     * @param {Number} opts.width width (default to 16)
     * @param {Number} opts.height height (default to 16)
     * @param {String} opts.recompute recompute (default to false)
     * @param {Number} opts.columnIndex columnIndex (default to -1)
     * @param {module:api/AQASnapshotControllerApi~getSnapshotColumnsHeatmapUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaHeatmap}
     */
    this.getSnapshotColumnsHeatmapUsingGET = function(snapshotId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getSnapshotColumnsHeatmapUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId
      };
      var queryParams = {
        'width': opts['width'],
        'height': opts['height'],
        'recompute': opts['recompute'],
        'columnIndex': opts['columnIndex'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaHeatmap;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/columnsheatmap', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getSnapshotRowsHeatmapUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getSnapshotRowsHeatmapUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaHeatmap} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets the rows-based heatmap for this snapshot
     * @param {String} snapshotId snapshotId
     * @param {Object} opts Optional parameters
     * @param {Number} opts.width width (default to 16)
     * @param {Number} opts.height height (default to 16)
     * @param {String} opts.recompute recompute (default to false)
     * @param {Number} opts.rowIndex rowIndex (default to -1)
     * @param {module:api/AQASnapshotControllerApi~getSnapshotRowsHeatmapUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaHeatmap}
     */
    this.getSnapshotRowsHeatmapUsingGET = function(snapshotId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getSnapshotRowsHeatmapUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId
      };
      var queryParams = {
        'width': opts['width'],
        'height': opts['height'],
        'recompute': opts['recompute'],
        'rowIndex': opts['rowIndex'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaHeatmap;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/rowsheatmap', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getSnapshotScoreUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getSnapshotScoreUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaScore} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets the score for this snapshot
     * @param {String} snapshotId snapshotId
     * @param {module:api/AQASnapshotControllerApi~getSnapshotScoreUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaScore}
     */
    this.getSnapshotScoreUsingGET = function(snapshotId, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getSnapshotScoreUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaScore;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/score', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getSnapshotStatusUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getSnapshotStatusUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UploadMonitor} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Snapshot upload status
     * @param {String} id id
     * @param {module:api/AQASnapshotControllerApi~getSnapshotStatusUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UploadMonitor}
     */
    this.getSnapshotStatusUsingGET = function(id, callback) {
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getSnapshotStatusUsingGET");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = UploadMonitor;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{id}/status', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getSnapshotUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getSnapshotUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaSnapshot} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets a snapshot
     * @param {String} id id
     * @param {module:api/AQASnapshotControllerApi~getSnapshotUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaSnapshot}
     */
    this.getSnapshotUsingGET = function(id, callback) {
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getSnapshotUsingGET");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaSnapshot;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{id}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getTableUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getTableUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaTable} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets a table summary
     * @param {String} snapshotId snapshotId
     * @param {module:api/AQASnapshotControllerApi~getTableUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaTable}
     */
    this.getTableUsingGET = function(snapshotId, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getTableUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaTable;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/table', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getTypesMapUsingGET operation.
     * @callback module:api/AQASnapshotControllerApi~getTypesMapUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {'String'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets a text map of the table&#39;s types
     * @param {String} snapshotid snapshotid
     * @param {module:api/AQASnapshotControllerApi~getTypesMapUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'String'}
     */
    this.getTypesMapUsingGET = function(snapshotid, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotid' is set
      if (snapshotid === undefined || snapshotid === null) {
        throw new Error("Missing the required parameter 'snapshotid' when calling getTypesMapUsingGET");
      }


      var pathParams = {
        'snapshotid': snapshotid
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['text/plain;charset=UTF-8'];
      var returnType = 'String';

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotid}/types', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the selectSheetUsingPUT operation.
     * @callback module:api/AQASnapshotControllerApi~selectSheetUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaSnapshotResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Sets the sheet to use in a snapshot and launches the analysis
     * @param {String} snapshotId snapshotId
     * @param {module:model/AqaSheetSelection} selector selector
     * @param {module:api/AQASnapshotControllerApi~selectSheetUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaSnapshotResponse}
     */
    this.selectSheetUsingPUT = function(snapshotId, selector, callback) {
      var postBody = selector;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling selectSheetUsingPUT");
      }

      // verify the required parameter 'selector' is set
      if (selector === undefined || selector === null) {
        throw new Error("Missing the required parameter 'selector' when calling selectSheetUsingPUT");
      }


      var pathParams = {
        'snapshotId': snapshotId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaSnapshotResponse;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotId}/sheets', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the setCharsetUsingPOST operation.
     * @callback module:api/AQASnapshotControllerApi~setCharsetUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Assigns a charset to a snapshot
     * @param {String} id id
     * @param {module:model/CharsetForSnapshotRequest} request request
     * @param {module:api/AQASnapshotControllerApi~setCharsetUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.setCharsetUsingPOST = function(id, request, callback) {
      var postBody = request;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling setCharsetUsingPOST");
      }

      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling setCharsetUsingPOST");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{id}/charset', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the updateSnapshotStatusChangeUsingPOST operation.
     * @callback module:api/AQASnapshotControllerApi~updateSnapshotStatusChangeUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update the status change in the snapshot
     * @param {String} snapshotid snapshotid
     * @param {String} status status
     * @param {module:api/AQASnapshotControllerApi~updateSnapshotStatusChangeUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.updateSnapshotStatusChangeUsingPOST = function(snapshotid, status, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotid' is set
      if (snapshotid === undefined || snapshotid === null) {
        throw new Error("Missing the required parameter 'snapshotid' when calling updateSnapshotStatusChangeUsingPOST");
      }

      // verify the required parameter 'status' is set
      if (status === undefined || status === null) {
        throw new Error("Missing the required parameter 'status' when calling updateSnapshotStatusChangeUsingPOST");
      }


      var pathParams = {
        'snapshotid': snapshotid
      };
      var queryParams = {
        'status': status,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/snapshots/{snapshotid}', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
