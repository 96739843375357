import React from 'react';
import AqaComponent from "../../../shared/aqacomponent/AqaComponent";

import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';

import {ReactComponent as UniquenessIcon} from "../../../../images/aqa-icons/Uniquenesscol.svg";
import {ReactComponent as PopulatedIcon}  from "../../../../images/aqa-icons/Populatedcol.svg";
import {ReactComponent as StringIcon}     from "../../../../images/aqa-icons/Stringcol.svg";
import {ReactComponent as NumberIcon}     from "../../../../images/aqa-icons/Numbercol.svg";
import {ReactComponent as DateIcon}       from "../../../../images/aqa-icons/Datecol.svg";
import {ReactComponent as TypeIcon}       from "../../../../images/aqa-icons/Typecol.svg";
import {ReactComponent as NativeIcon}       from "../../../../images/aqa-icons/Nativecol.svg";
import {ReactComponent as SourceIcon}     from "../../../../images/aqa-icons/Sourcecol.svg";



const ICONS =
[
	<UniquenessIcon width="36px" />, // 0
	<PopulatedIcon width="36px" />,  // 1
	<StringIcon width="36px" />,     // 2
	<NumberIcon width="36px" />,     // 3
	<DateIcon width="36px" />,       // 4
	<TypeIcon width="36px" />,        // 5
	<NativeIcon width="36px" />        // 6
];



export default class AqaQualityButton extends AqaComponent
{

/*
	// If managing some state then yes, have a constructor.
	constructor(props)
	{
		super(props);
	} //
*/


	render()
	{
	
		return (
			<Typography
				variant="inherit"
				color="inherit"
				style={{textAlign:"left",fontSize:"16px",color:"#4CADC4",paddingLeft:"4px",fontFamily:"Montserrat"}}
			>
				<IconButton className="aqa-button" style={{padding:0,marginTop:"20px",marginLeft:"10px"}} >
					<div className="aqa-list-icon">
						{this.props.which >= 0 && this.props.which < 7 ? ICONS[this.props.which] : <SourceIcon width="36px" />}
					</div>
				</IconButton>
			</Typography>
		);
	} // render



} ////
