/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as NewSourceIcon} from "../../images/aqa-icons/Usercol.svg";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//import FormControl from '@material-ui/core/FormControl';
//import InputLabel from '@material-ui/core/InputLabel';
//import Select from '@material-ui/core/Select';
//import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import AqaComponent from "../shared/aqacomponent/AqaComponent";
//import Input from "@material-ui/core/Input";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import HtmlTooltip from "@material-ui/core/Tooltip";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";



const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaNewUser extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            openProgress:false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            email: "",
            firstname: "",
            lastname: "",
            password:"",
            role:"V",
            isAdmin:false,
            errorMessage: "",
            errorMessage1:false,
            users:[],
            usercount:0,
            accountObj:null

        };
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRandomPassword=this.handleRandomPassword.bind(this);
        this.handleAccountPeople=this.handleAccountPeople.bind(this);
        this.handleAccountDetails=this.handleAccountDetails.bind(this);

    }

    handleRandomPassword(lettersLength,numbersLength,specialCharsLength) {
        var j, x, i;
        var result           = '';
        var letters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var numbers       = '0123456789';
        var special       = '~!@-#$';
        for (i = 0; i < lettersLength; i++ ) {
            result += letters.charAt(Math.floor(Math.random() * letters.length));
        }
        for (i = 0; i < numbersLength; i++ ) {
            result += numbers.charAt(Math.floor(Math.random() * numbers.length));
        }
        for (i = 0; i < specialCharsLength; i++ ) {
            result += special.charAt(Math.floor(Math.random() * special.length));
        }
        result = result.split("");
        for (i = result.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = result[i];
            result[i] = result[j];
            result[j] = x;
        }
        result = result.join("");
        return result
    }

    handleNewUserCreation(account){
        var registration={
            email:this.state.email,
            firstname:this.state.firstname,
            lastname:this.state.lastname,
            password:this.state.password,
            account:account.id,
            permissions:this.state.role
        }
        AqaComponent.userBackend.addUserUsingPOST(
            registration,
            (error, data, response) =>
            {
                if (error)
                {
                    if(response.body.message==="Sorry, this email is already in use"){
                        this.setState({errorMessage:response.body.message,errorMessage1:true});
                    }
                    else if(response.body.message === "You cannot add new users unless you pay for a plan"){
                        var customNotification = {
                            title: "Cannot create New User", titleIcon: "",
                            message: <span><span>{"Not enough User Licenses available. Go to your"}</span>
                                <span><Button onClick={(e)=>this.handleAccountPage(e)} variant="contained"
                                              color="primary"
                                              align="left"
                                              className="aqa-action-button"
                                              style={{
                                                  marginTop: 0,
                                                  marginLeft: 4,
                                                  color: '#4cadc4',
                                                  backgroundColor: 'white',
                                                  border: '1px solid #4cadc4',
                                                  fontSize: '0.7rem',
                                                  padding: '3px'
                                              }}>
                                        Account Details
                                    </Button></span> {"page to add the User Licenses"}</span>,
                            isCustom: false, type: "info",
                            insert: "bottom", position: "bottom-center", autoClose: false, duration: 0
                        };
                        this.removeAllNotifications();
                        setTimeout(()=>this.showNotification(null, customNotification),1000);
                        return;
                    }

                    else {
                        this.reportError("A problem posting the  new user from the server was encountered.",
                            "AqaNewUser.handleNewUserCreation, backend call: " + error,this);
                    }
                }

                else
                {
                    this.setState({openMessage:false});
                    this.props.dataFromParent.handleHeader(2);
                    this.props.dataFromParent.handleUserSelectionReset();

                }
            }
        );
    }

    handleCreateUserClosePopup(e) {
        e.preventDefault();
        var email= this.state.email;
        var firstname= this.state.firstname;
        var lastname= this.state.lastname;
        var password= this.state.password;
        if(email === "" || email.trim() === "" || firstname === "" || firstname.trim() === "" || lastname === "" || lastname.trim() === "" || password === "" || password.trim() === ""){
            this.setState({ errorMessage: "Please fill mandatory fields" });
        }
        else{
            this.handleNewUserCreation(this.props.dataForAccount);
        }
        this.setState({ openMessage: true });
    }

    handleClosePopup(e) {
        e.preventDefault();
        this.setState({ errorMessage:"",openMessage: false,SourceName:"",SourceDescription:"",periodicity:"daily" });
    }

    handleAccountDetails(){
//        var accountId=this.props.dataFromParent.props.dataFromRoot.state.accountId;
        //var account=localStorage.getItem("account");
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    this.setState({accountObj:accountObj});
                }
            }
        );
    }

    handleAccountPeople(){
        AqaComponent.userBackend.allUsingGET(
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the person object from the server was encountered.",
                    "AqaAccountOverview.handleAccountPeople, backend call: " + error,this);
                else{
                    this.setState({users:data,usercount:data.length});
                }
            }
        );
    }

    handleNewUserClick(e) {
        e.preventDefault();
        AqaComponent.accountBackend.getCanUseUsingGET(
            (error, data, response) =>
            {
                if (error) {
                    //alert("Error getting a quote: " + error);
                    this.reportError("A problem getting the account details from the server was encountered.",
                        "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                }
                else {
                    this.setState({accountStats:data});
                    var accountStats = data;
                    var status = parseInt(accountStats.status);
                    if(accountStats.numberOfPurchasedUsers <= accountStats.numberOfDefinedUsers || status < 2){
                        var customNotification = {
                            title: "Cannot create New User", titleIcon: "",
                            message: <span><span>{"Not enough User Licenses available. Go to your"}</span>
                                <span><Button onClick={(e)=>this.handleAccountPage(e)} variant="contained"
                                              color="primary"
                                              align="left"
                                              className="aqa-action-button"
                                              style={{
                                                  marginTop: 0,
                                                  marginLeft: 4,
                                                  color: '#4cadc4',
                                                  backgroundColor: 'white',
                                                  border: '1px solid #4cadc4',
                                                  fontSize: '0.7rem',
                                                  padding: '3px'
                                              }}>
                                        Account Details
                                    </Button></span> {"page to add the User Licenses"}</span>,
                            isCustom: false, type: "info",
                            insert: "bottom", position: "bottom-center", autoClose: false, duration: 0
                        };
                        this.removeAllNotifications();
                        setTimeout(()=>this.showNotification(null, customNotification),1000);
                        return;
                    }
                    else {
                        var newPass = this.handleRandomPassword(5, 2, 1);
                        this.setState({openMessage: true, isAdmin: false, password: newPass});
                    }
                }
            }
        );

    }

    handleAccountPage =(e)=>{
        this.props.dataFromParent.handleHeaderTab(0,0);
        this.removeAllNotifications();
    }

    handleNameChange(event){
        const { name, value } = event.target;
        this.setState({[name]: value,errorMessage:"",errorMessage1:false});
    }

    handleChange(event){
        const { name} = event.target;
        this.setState({[name]: event.target.checked,errorMessage:"",errorMessage1:""});
    }

    handleRadioChange = (e)=>{
        const{name,value} = e.target;
        this.setState({[name]:value,errorMessage:""});
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        //this.handleAccountPeople();
        //this.handleAccountDetails();
    }

    componentDidMount(){
        //this.handleAccountPeople();
        //this.handleAccountDetails();
    }
    render() {
        const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,errorMessage,errorMessage1 } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"5px"}}>
                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                    <div>
                                        <Typography color="inherit" className="aqa-text-action">Click to add new User</Typography>
                                    </div>
                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <IconButton className="aqa-button" onClick={this.handleNewUserClick.bind(this)} >

                                    <div className="aqa-icon" >
                                        <NewSourceIcon width="32px" />
                                    </div>
                                    <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                        NEW USER
                                    </Typography>
                                </IconButton>
                                </HtmlTooltip>

                            </Typography>
                        </Grid>

                    </Grid>

                    <Dialog
                        open={this.state.openMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                New User
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={this.handleClosePopup.bind(this)} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:"400px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{paddingLeft: 10, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <TextField
                                        id="email"
                                        //onChange={this.handleTextChange('guest')}
                                        label="Email"
                                        name="email"
                                        error={errorMessage1}
                                        className={classes.textField}
                                        variant="filled"
                                        margin="dense"
                                        multiline={false}
                                        rows={1}
                                        style={{ width: "95%"}}
                                        onChange={this.handleNameChange.bind(this)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <TextField
                                        id="firstname"
                                        //onChange={this.handleTextChange('guest')}
                                        label="First Name"
                                        name="firstname"
                                        className={classes.textField}
                                        variant="filled"
                                        margin="dense"
                                        multiline={false}
                                        rows={1}
                                        style={{ width: "95%"}}
                                        onChange={this.handleNameChange.bind(this)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <TextField
                                        id="lastname"
                                        //onChange={this.handleTextChange('guest')}
                                        label="Last Name"
                                        name="lastname"
                                        className={classes.textField}
                                        variant="filled"
                                        margin="dense"
                                        multiline={false}
                                        rows={1}
                                        style={{ width: "95%"}}
                                        onChange={this.handleNameChange.bind(this)}
                                        required
                                    />
                                </Grid>
                                {/*<Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <TextField
                                        id="password"
                                        //onChange={this.handleTextChange('guest')}
                                        label="Password"
                                        name="password"
                                        type="password"
                                        className={classes.textField}
                                        variant="filled"
                                        margin="dense"
                                        multiline={false}
                                        rows={1}
                                        style={{ width: "95%"}}
                                        onChange={this.handleNameChange.bind(this)}
                                        required
                                    />
                                </Grid>*/}
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <div style={{paddingTop:"10px"}}>
                                        <Typography className={"aqa-text-action-bold"} variant="title" align="left"
                                                    style={{padding:"0px" }}>
                                            <span style={{float:"left",width:"80px",fontSize:'14px',paddingTop:'0px',marginTop:"-2px",paddingLeft:"25px",fontFamily:"Montserrat"}}>Role</span>
                                        </Typography>
                                        <RadioGroup aria-label="role" name="role" value={this.state.role} color="primary" onChange={this.handleRadioChange} row={true}>
                                            <FormControlLabel value="A" control={<Radio color="primary" style={{padding:"0px 0px 0px 4px"}} />} label={
                                                (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                             style={{}}>
                                                    <span style={{float:"left",width:"80px"}}>Admin</span>
                                                </Typography>)
                                            } style={{width:"80px",margin:"0px"}} />
                                            <FormControlLabel value="U" control={<Radio color="primary" style={{padding:"0px 0px 0px 4px"}} />} label={
                                                (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                             style={{}}>
                                                    <span style={{float:"left",width:"80px"}}>Analyst</span>
                                                </Typography>)
                                            } style={{width:"80px",margin:"0px"}} />
                                            <FormControlLabel value="V" control={<Radio color="primary" style={{padding:"0px 0px 0px 4px"}}/>} label={
                                                (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                             style={{padding:"0px" }}>
                                                    <span style={{float:"left",width:"80px"}}>Viewer</span>
                                                </Typography>)


                                            } style={{width:"80px",margin:"0px"}} />


                                        </RadioGroup>
                                    </div>
                                </Grid>
                                {/*<Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <div className={"aqa-list-checkbox"} style={{width:"10%"}}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.isAdmin}
                                                    onChange={(evt) => this.handleChange(evt)}
                                                    name="isAdmin"
                                                    color="primary"
                                                />
                                            }
                                            label={<Typography variant="inherit" color="inherit" className={"aqa-text-bold-12"} style={{textAlign:"left"}}>
                                                Admin
                                            </Typography>}
                                            labelPlacement="start"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={10} sm={10} lg={10} xl={10}>
                                </Grid>*/}

                            </Grid>

                            <div style={{borderTop:"1px solid #ccc",marginTop:10}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 0, marginBottom: 10}}>

                                <Grid item xs={6} sm={6} lg={6} xl={6}>
                                    <Typography variant="inherit" color="inherit" className={"aqa-text-bold-12"} style={{textAlign:"left"}}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: 8,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Cancel
                                    </Button>
                                    <Button  variant="contained"
                                             onClick={this.handleCreateUserClosePopup.bind(this)}
                                             color="primary"
                                             align="left"
                                             className="aqa-action-button"
                                             style={{
                                                 marginTop: 8,
                                                 marginLeft: 8,
                                                 color: '#4cadc4',
                                                 backgroundColor: 'white',
                                                 border: '1px solid #4cadc4',
                                                 fontSize: '0.7rem',
                                                 padding: '3px',
                                             }}>
                                        Save & Close
                                    </Button>
                                    </Typography>


                                </Grid>

                                <Grid item xs={6} sm={6} lg={6} xl={6}>
                                </Grid>

                            </Grid>
                            </div>
                        </DialogContent>
                    </Dialog>

                </div>
            );
        }
    }
}

AqaNewUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaNewUser);
