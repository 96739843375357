import React, {Component} from 'react';

// For prettify
// import AqaComponent from "../aqacomponent/AqaComponent";

import "./DistributionGraphNeedle.scss";


const W = 12;
const H = 6;

/*
const NORMAL_STRIKE = "rgb(0,0,0)";
const ACTIVE_STRIKE = "rgb(255,0,0)";
*/


const NORMAL = "normalNeedle";
const ACTIVE = "selectedNeedle";



export default class DistributionGraphNeedle extends Component
{

	constructor(props)
	{
		super(props);


		this.state =
		{
//			gns:NORMAL_STRIKE,
			className:NORMAL,
			position:this.props.position,
			gotCapture:false
		};

		this.x = props.x;
		this.oldX = 0; // This one is for the mouse. Because of possible zooming, on such x is not ncessarily the same as a rendering x in the SVG
		this.sliding = false;

//		this.X = 0;


		props.parent.registerNeedle(this, props.which);
		this.parent = props.parent;
		
		this.otherNeedle = null;

	} ////


	getPosition() { return this.state.position; }
	
	getAbsoludePosition() { return this.state.position + this.props.slide; }

	getSpan() { return this.props.totalGraphicalSpan; }
	
	getOtherNeedle() { this.otherNeedle = this.props.parent.getNeedle(1 - this.props.which); }

	focus()
	{
		this.setState({className:ACTIVE});
	} // focus


	blur()
	{
		this.setState({className:NORMAL});
	} // blur


	mDown = e =>
	{
			this.sliding = true;
			e.target.setPointerCapture(e.pointerId);
			this.oldX = e.screenX;
	} // mDown


	moveBy(dp)
	{
		if (dp === 0) return;
		this.moveAbsolute(this.state.position + dp, true);

	} // moveBu

	moveAbsolute(position, informBoxes)
	{
		if (position < 0) position = 0;
		if (position >= this.props.graphicalSpan) position = this.props.graphicalSpan;

		if (this.otherNeedle === null) this.getOtherNeedle();
		const otherPosition = this.otherNeedle.getPosition();

		switch(this.props.which)
		{
		case 0:
			if (position > otherPosition) position = otherPosition;
			break;

		case 1:
			if (position < otherPosition) position = otherPosition;
			break;
		default:
			break; // To shut up the transpiler
		}


		if (this.state.position === position) return; // no change

		if (informBoxes) this.props.parent.adjustNeedleBox(this.props.which, this.getAbsoludePosition(), this.props.totalGraphicalSpan);

// onsole.log("p: " + position + "  |  " + this.props.span);

		// Move it
		this.setState
		(
			{position},
			() => this.parent.updateSelectedZone()
		);		

	} // moveAbsolute


// https://medium.com/@leonardobrunolima/react-tips-drag-and-drop-using-point-events-2ba33cf7653e

	mMove = e =>
	{

		if (this.sliding) // e.nativeEvent.buttons === 1
		{
/*
onsole.log("   E: ");
onsole.log(AqaComponent.prettifyJson(e));
*/

			this.moveBy(Math.round((e.screenX - this.oldX) / window.devicePixelRatio));
			this.oldX = e.screenX;
		}

	} // mMove


	keys = e =>
	{

// onsole.log("Kc: " + e.keyCode);

		const m = e.ctrlKey ? 10 : 1;

		switch(e.keyCode)
		{
		case 37:
			this.moveBy(-1 * m);
			break;

		case 39:
			this.moveBy(1 * m);
			break;

		case 13:
			this.parent.select(true);
			break;
			
		case 27:
			this.parent.select(false);
			break;

		default: // To shut up the transpiler
			break;
		}


	} // keys


	mUp = e =>
	{
		this.sliding = false;
		e.target.releasePointerCapture(e.pointerId);
	} // mUp


	render()
	{
		const x = this.x + this.state.position;
		const y0 = this.props.y;
		const y1 = y0 + this.props.depth;


// style={{strokeWidth:"1",strokeDasharray:"2 2",stroke:"rgb(255,0,0)"}}
// style={{fill:"rgb(204,204,204)",strokeWidth:"1",stroke:this.state.gns}}

		return (
			<g className={this.state.className}>

				<line
					key={this.key}
					x1={x}
					y1={y0}
					x2={x}
					y2={y1}
					stroke="black"
					strokeWidth="1"
					style={{strokeDasharray:"2 2"}}
				/>

				<rect
					x={x - (W >> 1)}
					y={y1}
					width={W}
					height={H}

					onFocus={() => this.focus()}
					onBlur={() => this.blur()}
					tabIndex={0}

					onPointerDown={this.mDown}
					onPointerMove={this.mMove}
					onPointerUp={this.mUp}
					onPointerCancel={this.mUp}
               
					onKeyUp={this.keys}
					
				/>

			</g>
		);


	} //// render


} ////



