/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react'
import "./AqaMainFrame.css"
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import AqaSettings from "../AqaSettings.js"
import AqaSourceManager from "./sourcemanager/AqaSourceManager"
import AqaHeader from "./AqaHeader"
import AqaFooter from "./AqaFooter"
import AqaSourceDetail from "./sourcedetail/AqaSourceDetail"
import AqaSnapshotDetail from "./snapshotdetail/AqaSnapshotDetail"
import AqaAccountManager from "./settings/AqaAccountManager"
import AqaComponent from "./shared/aqacomponent/AqaComponent"
//import IconButton from "@material-ui/core/IconButton";
//import CloseIcon from '@material-ui/icons/Close';
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";


// Trying 2FA
import TwoFactor from "./TwoFactor"

// Testing stripe
// import {loadStripe} from '@stripe/stripe-js'
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js'
import AqaTestStripe from "./AqaTestStripe"
import StripeSuccess from "./stripe/StripeSuccess"
import StripeFailure from "./stripe/StripeFailure"
import AqaAQDDetail from "./market/AqaAQDDetail";
import moment from "moment";
import Button from "@material-ui/core/Button";

// var stripePromise = null;
const base64 = require('base-64'); // import doesn't work

const styles = theme => ({
	snackbar: {
		[theme.breakpoints.down('xs')]: {
			bottom: 2,
		},
		[theme.breakpoints.down('sm')]: {
			bottom: 2,
		},
		[theme.breakpoints.up('lg')]: {
			bottom: 38,
		},
		fontFamily:"Montserrat",
		padding:0,
		margin:1

	},
	root:{
		padding:'2px 18px'
	},
	message:{
		padding:"4px 0",
		marginTop:"8px"
	},
	paper:{
		margin:0
	}
});

class AqaMainFrame extends AqaComponent
{

	constructor(props)
	{
		super(props);
		this.state =
		{
			nav:props.nav,
			isLoaded:false,
			username:"",
			currentSource: "",
			currentSnapshot: "",
			currentFolder:"allsources",
			currentSnapshotId:"",
			currentAQD:"",
			aqdOrigin:"",
			currentFile:null,
			currentAspect:null,
			isAmber:false,
			isRed:false,
			isGreen:false,
			isOverallSelected:false,
			selectedType:"overall",
			selectedReviewType:-1,
			finerGrain:-1,
			currentHeatMap:null,
			currentColumn:"",
			showAccount:props.parent.state.authCode==="V"?"credential":"account",
			openPlanEditor:false,
			timeoutDialogVisible:false,
			timeoutCoundownValue:0,
			stripeSuccess:false,
			authCode:props.parent.state.authCode,
			prevnav:0,
			isGrid:false,
			isLibrary:false,
			isAccountFree:false,
			isAccountExpired:false,
			accountDate:"",
			isSubscriptionCancelled:false,
			isAccountSuper:false,
			isAccountWaiting:false,
			isCancellationEnabled:false,
			isAuthorized:false,
			isAuthorizedVerified:false,
			isSnackOpen:false,
			whichTab:0,
			exts:[],
			abnormalColumns:[],
			selectedStat:null

		};
		this.parent = props.parent;
		this.chatRef = null;
		this.navigation = this.navigation.bind(this);
		this.planNavigation = this.planNavigation.bind(this);
		this.planNavigationReset = this.planNavigationReset.bind(this);
		this.handleGetSource=this.handleGetSource.bind(this);
		this.handleSnapshotFilter=this.handleSnapshotFilter.bind(this);
		this.handleSnapshotFilterReset=this.handleSnapshotFilterReset.bind(this);
		this.handleFirstSource = this.handleFirstSource.bind(this);
		this.handleStripeReset = this.handleStripeReset.bind(this);
		this.countDownIntervalFunction = null;
		this.countDownIntervalForExpiryFunction = null;
		this.timeoutDialogTimeout = null;

	} //


	handleChatRef = (chatRef)=>{
		this.chatRef = chatRef;
	}

	getUsername()
	{
		const url = AqaSettings.getApiBaseUrl() + "/username";
		const headers =
		{
			Authorization: "bearer " + localStorage.getItem("access_token"),
			cache: "no-cache"
		};

		fetch(url, {headers}).then(r => r.json()).then
		(
			json =>
			{
// onsole.log("JSON returned by username call: " + JSON.stringify(json));
				this.setState({username:json.firstname + " " + json.lastname});
			}
		);
	} // getUsername

	handleFirstSource(nav)
	{
		this.navigation(nav);
		/*AqaComponent.backend.sourcesUsingGET
		(
			{},
			(error, data, response) =>
			{
				if (error) {
					this.reportError("A problem getting the Source from the server was encountered.",
						"AqaMainFrame.handleGetSource, backend call: " + error,this);
				}
				else{
					for(var i=0;i<data.length;i++){
						var source = data[i];
						if(source.name ==="My First Data Source"){
							this.setState({currentSource:source});
							this.navigation(nav);
						}
					}
				}
			}
		)*/
	}
	handleSource(source){
		this.setState({currentSource:source});
		if(source!==null && source!=="" && source!==undefined) localStorage.setItem("sid",source.id);
		else localStorage.removeItem("sid");
	}

	handleToggle = (toggle)=>{
		this.setState({isGrid:toggle});
	}

	handleAQDSource = (aqd,origin,isGrid,isLibrary,prevnav) =>{
		this.setState({currentAQD:aqd,aqdOrigin:origin,isGrid:isGrid,isLibrary:isLibrary,prevnav:prevnav});
	}

	handleSourceReset(){
		this.setState({currentSource:"",currentSnapshotId:"",showAccount:"credential",isRed:false,isAmber:false});
	}
	handleSourceFile(source,file){
		this.setState({currentSource:source,currentFile:file});
	}
	handleSourceFileReset(){
		this.setState({currentSource:"",currentFile:null});
	}
	handleFileUploadReset(){
		this.setState({currentFile:null});
	}
	handleSnapshot(snapshot){
		this.setState({currentSnapshot:snapshot});
	}

	handleSnapshotReset(){
		this.setState({currentSnapshot:"",isRed:false,isAmber:false});
	}

	handleSelectedStat = (stat)=>{
		this.setState({selectedStat:stat});
	}

	handleTableData(tableData){
		this.setState({currentTableData:tableData});
	}
	handleTableDataReset(){
		this.setState({currentTableData:""});
	}

	handleStripeReset(){
		this.setState({stripeSuccess:false});
		setTimeout((e)=>this.handleLoggedInUser(),5000);
	}

	handleSnapshotFilter(nav,source,snapshot,aspect,isAmber,isRed,heatmap,column,isOverall,isGreen,selectedType,finerGrain,selectedReviewType)
	{
		AqaComponent.touch();
		this.setState({currentSource:source,currentSnapshot:snapshot,currentAspect:aspect,isAmber:isAmber,isRed:isRed,currentHeatMap:heatmap,currentColumn:column,isOverallSelected:isOverall,isGreen:isGreen,selectedType:selectedType,finerGrain:finerGrain,selectedReviewType:selectedReviewType});
		this.navigation(nav);
	}

	handleSnapshotFilter3(source, snapshot, aspect, isAmber, isRed, heatmap,column) { this.handleSnapshotFilter(3, source, snapshot, aspect, isAmber, isRed, heatmap,column); }

	handleSnapshotFilter2(source, snapshot, aspect, isAmber, isRed, heatmap,column,isOverall,isGreen,selectedType,finerGrain,selectedReviewType) {this.handleSnapshotFilter(2, source, snapshot, aspect, isAmber, isRed, heatmap,column,isOverall,isGreen,selectedType,finerGrain,selectedReviewType); }

	handleSnapshotFilterReset(){
		this.setState({currentSource:null,currentSnapshot:"",currentAspect:null,isAmber:false,isRed:false,currentHeatMap:[0,0,0,0,0],currentColumn:"",selectedType:"overall",finerGrain:-1,selectedReviewType:-1})
	}

	planNavigation(which,showAccount,openPlanEditor){
		this.setState({nav:which,showAccount:showAccount,openPlanEditor:openPlanEditor});
	}

	planNavigationReset(){
		this.setState({openPlanEditor:false});
	}

	navigation(which) {
		if(which!==2) localStorage.removeItem("sid");
		localStorage.setItem("navigation",which);
		this.props.parent.handleNavigation(which);
		this.setState({nav: which});
		AqaComponent.touch();
	}

	handleGetSource(sourceid, snapshotid, nav)
	{
		AqaComponent.backend.sourceUsingGET
		(
			sourceid,
			(error,data,response)=>
			{
				if (error) {
					this.reportError("A problem getting the Source from the server was encountered.",
						"AqaMainFrame.handleGetSource, backend call: " + error,this);
					this.navigation(1);
					this.setState({isLoaded:true});
				}
				else{
					if(data === undefined){
						var customNotification = {
							title: "Restricted", titleIcon: "",
							message: "You cannot access the shared report, either link is expired or resource doesnt exist",
							isCustom: false, type: "info",
							insert: "center", position: "center", autoClose: true, duration: 5000
						};
						this.removeAllNotifications();
						this.showNotification(null, customNotification);
						this.navigation(1);
						this.setState({isLoaded:true});
					}
					else {
						localStorage.setItem("navigation",nav);
						this.setState({currentSource: data, currentSnapshotId: snapshotid,nav:nav,isLoaded:true});
					}
				}
			}
		)
	}

	handleSupportedExtensions = () =>{
		AqaComponent.backend.getSupportedExtensionsUsingGET(
			(error, data, response) =>
			{
				if (error) this.reportError("A problem getting the supported extensions from the server was encountered.", "AqaSourceView.handleSupportedExtensions, backend call: " + error,this);
				else
				{
					let exts = [];
					exts.push(".aqd");
					exts.push(".csv");
					exts.push("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
					exts.push("application/vnd.ms-excel");
					let extKeys = Object.keys(data);
					for(let i=0;i<extKeys.length;i++){
						let extData = data[extKeys[i]];
						for(let k=0;k<extData.length;k++) exts.push(extData[k]);
					}
					this.setState({exts:exts});
				}
			}
		);

	}

	refreshSession()
	{
		//localStorage.setItem("userId",this.props.parent.state.userId);
		AqaComponent.refreshToken
		(
			(error, ttl) =>
			{
				// The next round of dialog.
				if (error)
				{
					//alert("We are sorry, but an error prevented us from keeping your session going.");
					this.props.parent.logout();
				}					
				else this.prepareTimeoutDialog(ttl);

			}
		);
	} // refreshSession


	closeTimeOutDialog(logout)
	{
		if (this.countDownIntervalFunction)
		{
			clearInterval(this.countDownIntervalFunction);
			this.countDownIntervalFunction = null;
		}
		this.setState
		(
			{timeoutDialogVisible:false},
			() =>
			{
				if (logout)
				{
//					alert("OK, you're out")
					this.props.parent.setForcefullyLoggedOut(true);
					this.props.parent.logout();
					if (this.countDownIntervalForExpiryFunction)
					{
						clearInterval(this.countDownIntervalForExpiryFunction);
						this.countDownIntervalForExpiryFunction = null;
					}
				}
				else
				{
					// They've clicked just in time.
					// So we are going to refresh their session.
					this.refreshSession();
				}
			}
		);

	} // closeTimeOutDialog


	countDownForTimeoutDialog()
	{
	
		if (this.countDownIntervalFunction) clearInterval(this.countDownIntervalFunction);
	
		this.countDownIntervalFunction = setInterval
		(
			() =>
			{
				const cv = this.state.timeoutCoundownValue;
				if (cv <= 0) this.closeTimeOutDialog(true);
				else this.setState({timeoutCoundownValue: cv - 1});
			}
			,
			1000
		);	
	
	
	} // countDownForTimeoutDialog


	popTimeoutDialog()
	{
		if (this.state.timeoutDialogVisible) return; // || this.countDownIntervalFunction
		this.setState
		(
			{
				timeoutDialogVisible:true,
				timeoutCoundownValue:AqaComponent.TIME_TO_GIVE_THEM_TO_KEEP_THEIR_SESSION
			},
			() => this.countDownForTimeoutDialog()
		);	

	} // popTimeoutDialog

	popTimeoutDialog2()
	{
		if (this.state.timeoutDialogVisible) return; // || this.countDownIntervalFunction
		this.setState
		(
			{
				timeoutDialogVisible:true,
				timeoutCoundownValue:0
			},
			() => this.countDownForTimeoutDialog()
		);

	} // popTimeoutDialog

	/** Invoked by logout which is App.js */
	clearTimeoutDialogTimeout()
	{
		if (this.countDownIntervalForExpiryFunction) clearInterval(this.countDownIntervalForExpiryFunction);
		this.countDownIntervalForExpiryFunction = null;
	} // clearTimeoutDialogTimeout

	prepareTimeoutDialog(ttl)
	{
	
// onsole.log("AMF: pptd: ttl: " + ttl);
	
		// The 10 seconds are to account for any delay en route.
		//if (this.timeoutDialogTimeout) clearTimeout(this.timeoutDialogTimeout);
		if (this.countDownIntervalForExpiryFunction) clearInterval(this.countDownIntervalForExpiryFunction);
		

		/*this.timeoutDialogTimeout = setTimeout
		(
			() =>
			{
				if (AqaComponent.isRecentlyTouched())
				{
// onsole.log("Discrete refresh");

					// Have we logged out?
// A tester une fois qu'on a vu si on traitait bien le retour de refresh tokenEnki
					if (localStorage.getItem("access_token")) this.refreshSession(); // Refresh session quietly without telling them.
				}
				else this.popTimeoutDialog();
			},
			(ttl - 10 - AqaComponent.TIME_TO_GIVE_THEM_TO_KEEP_THEIR_SESSION) * 1000
		);*/
		this.countDownIntervalForExpiryFunction = setInterval
		(
			() =>
			{
				// Get the current time in Seconds
				let ctl = AqaComponent.nowInSeconds();
				let ttl = localStorage.getItem("ttlo");
				let expiresIn = ttl-ctl;
				if(expiresIn<70 || !AqaComponent.isRecentlyTouched()){
					this.popTimeoutDialog();
				}
				else if(expiresIn<70 && AqaComponent.isRecentlyTouched()){
					if (localStorage.getItem("access_token")) this.refreshSession();
				}
				else{}

			},
			10000
		);
	} // prepareTimeoutDialog

	handleUnAuthorized = () =>{
		//this.props.parent.setForcefullyLoggedOut(true);
		this.props.parent.forceLogout();
	}

	handleSetAuthorized = () =>{
		this.setState({isAuthorized:false});
	}

	handleAuthorized = () =>{
		this.setState({isAuthorizedVerified:false});
		AqaComponent.userBackend.meUsingGET
		(
			(error, data, response) =>
			{
				if(error){
					if(response.status === 401) {
						this.setState({isAuthorized:false,isAuthorizedVerified:true});
					}
					else this.reportError("A problem getting the logged in user from the server was encountered.", "AqaLoginRegister.handleLoggedInUser, backend call: " + error,this);
				}
				else
				{
					this.setState({isAuthorized:true,isAuthorizedVerified:true});
				}
			}
		);
	}

	getDuration = (d,t) =>{
		let dt = "";
		if(d === 0) return dt;
		if(t !== undefined && t === "hours") return Math.floor(d / (1000 * 60 * 60));
		var days = Math.floor(d / (1000 * 60 * 60 * 24));
		return days;
	}

	handleLoggedInUser()
	{
		AqaComponent.userBackend.meUsingGET
		(
			(error, data, response) =>
			{
				if(error){
					if(response.status === 401) {
						this.setState({isAuthorized:false});
					}
					else this.reportError("A problem getting the logged in user from the server was encountered.", "AqaLoginRegister.handleLoggedInUser, backend call: " + error,this);
				}
				else
				{
					this.setState({isAuthorized:true,authCode:data.permissions},()=>this.handleMainAccountDetails(data));
				}
			}
		);
	} // handleLoggedInUser

	handleMainAccountDetails =(user)=>
	{
		AqaComponent.accountBackend.getMyAccountUsingGET
		(
			(error,data,response) =>
			{
				if (error) this.reportError("A problem getting the account details from the server was encountered.", "AqaLoginRegister.handleAccountDetails, backend call: " + error,this);
				else {
					this.props.parent.handleUser(user,data);
					this.handleAccountPlanDetails(user,data);
				}
			}
		);
	} // handleMainAccountDetails

	handleAccountPlanDetails =(user,account)=>
	{
		AqaComponent.accountBackend.getCanUseUsingGET
		(
			(error,data,response) =>
			{
				if (error) {
					if(response.status === 401 ){
						this.handleSetAuthorized();
					}
					else this.reportError("A problem getting the account details from the server was encountered.", "AqaLoginRegister.handleAccountDetails, backend call: " + error,this);
				}
				else {
					let free = data.canUse || data.status===0;
					let nav=localStorage.getItem("navigation");
					let sourceid = localStorage.getItem("sid");
					if(data.status === 10){
						this.setState({isAccountSuper:true,isAccountFree:false,isAccountWaiting:false,isAccountExpired: false,isSubscriptionCancelled:false});
					}
					else if(data.status === 1){
						this.setState({isAccountSuper:false,isAccountFree:false,isAccountExpired: true,isAccountWaiting:true,isSubscriptionCancelled:false,nav:4,isSnackOpen:true});
					}
					else if(data.status === 2){
						let cancellation=false;
						if(user.id === account.defaultAdmin) cancellation=true;
						if(nav === undefined || nav === null) nav = 1;
						nav=parseInt(nav);
						if(nav === 2 && (sourceid!==undefined && sourceid!==null && sourceid!=="") ) nav = 2;
						else nav=1;
						if(nav >=4) nav = 4;
						localStorage.setItem("navigation",nav.toString());
						this.setState({isAccountSuper:false,isAccountFree:false,isAccountWaiting:false,isAccountExpired:false,isSubscriptionCancelled:false,isCancellationEnabled:cancellation,nav:nav});
						if(sourceid!==undefined && sourceid!==null && sourceid!=="") this.handleGetSource(sourceid,"",2);
					}
					else if(data.status === 3){
						let subscriptionCancelled = moment(data.subscriptionCancelationDate);
						this.setState({isSubscriptionCancelled:true,isAccountFree: free,isAccountSuper:false,isAccountWaiting:false,isAccountExpired: true, accountDate: subscriptionCancelled.fromNow(),nav:4});
					}
					else {
						let expired = false;
						let now = moment();
						let nav = localStorage.getItem("navigation");
						let sourceid = localStorage.getItem("sid");
						if(nav === undefined || nav === null) nav = 1;
						nav=parseInt(nav);
						if(nav === 2 && (sourceid!==undefined && sourceid!==null && sourceid!=="") ) nav = 2;
						else nav=1;
						if(nav >=4) nav = 4;
						let planDuration = this.getDuration(data.freePlanDuration);
						//planDuration = planDuration - 13;
						let expiresOn = moment(data.created).add(planDuration, "days");
						if (expiresOn > now) {
							let left1 = expiresOn - now;
							let left = this.getDuration(left1);
							if(left === 0) left = this.getDuration(left1,"hours")+" hours";
							else left = left + " days";
							this.setState({isAccountExpired: false,isAccountSuper:false, accountDate: " in "+left});
						}
						if (expiresOn < now && free) {
							expired = true;
							nav=4;
							this.setState({isAccountExpired: true,isAccountSuper:false, accountDate: expiresOn.fromNow()});
						}
						localStorage.setItem("navigation",nav.toString());
						this.setState({isAccountFree: free,isAccountWaiting:false,isSnackOpen:true,nav:nav});
						if(sourceid!==undefined && sourceid!==null && sourceid!=="" && !expired) this.handleGetSource(sourceid,"",2);

					}
					if(sourceid!==undefined && sourceid!==null && sourceid!=="") this.setState({isLoaded:true});
					else this.setState({isLoaded:true});
				}
			}
		);
	} // handleMainAccountDetails

	handleAccountStatus =()=>
	{
		AqaComponent.accountBackend.getCanUseUsingGET
		(
			(error,data,response) =>
			{
				if (error) {
					if(response.status === 401 ){
						this.handleSetAuthorized();
					}
					else this.reportError("A problem getting the account details from the server was encountered.", "AqaLoginRegister.handleAccountDetails, backend call: " + error,this);
				}
				else {
					let free = data.canUse || data.status===0;
					if(data.status === 10){
						this.setState({isAccountSuper:true,isAccountFree:false,isAccountWaiting:false,isAccountExpired: false,isSubscriptionCancelled:false});
					}
					else if(data.status === 1){
						this.setState({isAccountSuper:false,isAccountFree:false,isAccountExpired: true,isAccountWaiting:true,isSubscriptionCancelled:false,nav:4,isSnackOpen:true});
					}
					else if(data.status === 2){
						let cancellation=false;
						if(this.props.parent.state.user.id === this.props.parent.state.account.defaultAdmin) cancellation=true;
						let nav = localStorage.getItem("navigation");
						if(nav === undefined || nav === null) nav = 1;
						nav=parseInt(nav);
						if(nav === 2) nav = 1;
						if(nav === 4) nav = 4;
						localStorage.setItem("navigation",nav);
						this.setState({isAccountSuper:false,isAccountFree:false,isAccountWaiting:false,isAccountExpired:false,isSubscriptionCancelled:false,isCancellationEnabled:cancellation,nav:parseInt(nav)});
					}
					else if(data.status === 3){
						let subscriptionCancelled = moment(data.subscriptionCancelationDate);
						this.setState({isSubscriptionCancelled:true,isAccountFree: free,isAccountSuper:false,isAccountWaiting:false,isAccountExpired: true, accountDate: subscriptionCancelled.fromNow(),nav:4,isSnackOpen:true});
					}
					else {
						let expired = false;
						let now = moment();
						let planDuration = this.getDuration(data.freePlanDuration);
						let expiresOn = moment(data.created).add(planDuration, "days");
						if (expiresOn > now) {
							this.setState({isAccountExpired: true,isAccountSuper:false, accountDate: expiresOn.fromNow()});
						}
						if (expiresOn < now && free) {
							expired = true;
							this.setState({isAccountExpired: true,isAccountSuper:false, accountDate: expiresOn.fromNow()});
						}
						this.setState({isAccountFree: free,isAccountWaiting:false,isSnackOpen:true});
						if (expired) {
							this.setState({nav: 4});
						}
					}
				}
			}
		);
	} // handleMainAccountDetails

	componentDidMount()
	{
		this.handleAuthorized();
		this.handleLoggedInUser();
		this.handleSupportedExtensions();

		AqaComponent.touch();

		// Detect user's idle time
		// https://baljindersingh013.medium.com/idle-session-time-out-in-react-da5139234ba3
		AqaComponent.USER_EVENTS_THAT_TOUCH.forEach(name => window.addEventListener(name, AqaComponent.touch));

		let ctl = AqaComponent.nowInSeconds();
		let ttl = localStorage.getItem("ttlo");
		let expiresIn = ttl-ctl;

		// When we're going to ask the user to keep his session.
		this.prepareTimeoutDialog(expiresIn);

// Needed if we test stripe from here
// this.loadStripe();


		var location=window.location;
		var search=window.location.search;
		const params = new URLSearchParams(search);
		var plain =null;
		var res = params.get("res");
		if(res !== null || res ==="link"){
			plain = res;
			var urlbase = location.href.slice(0, - ((location.search + location.hash).length+1));
			if(location.search ===""){
				urlbase = location.href
			}

			localStorage.setItem("redirectData",plain);
			window.history.pushState({path:urlbase},'',urlbase);
		}
		var redirectData = localStorage.getItem("redirectData");

		if (redirectData!==null && redirectData !== undefined && redirectData !=="link")
		{
			redirectData = JSON.parse(base64.decode(redirectData));
			var nav = redirectData.nav;
			var fid = redirectData.fid;
			var scid = redirectData.scid;
			var snid = redirectData.snid;
			var stripeSuccess = redirectData.stripesuccess;
			if(nav === undefined) nav=1;

/*
			if(nav === 4 && stripeSuccess === undefined){this.setState({showAccount:"credential"})}
			else if(nav === 4 && stripeSuccess === true){this.setState({showAccount:"account",stripeSuccess:true});}
*/


			if (nav === 4) switch(stripeSuccess)
			{
			case undefined:
				this.setState({showAccount: "credential"});
				break;

			case true:
				this.setState({showAccount: "account", stripeSuccess: true});
				break;

			case "pmSetup":
				this.setState({showAccount: "account"}); // 20220426 - not quite sure how to drive this
				break;
			default:
					break;
			} 



			if(fid!==""){this.setState({currentFolder:fid});
				this.navigation(nav);}
			// With Source Id we can only navigate to the second screen
			if(snid!=="" && scid!==""){
				this.handleGetSource(scid,snid,nav);
			}

			localStorage.removeItem("redirectData");
		}

		if (localStorage.getItem("navigation") === "4")
		{
			if(this.props.parent.state.authCode==="V") {
				this.setState({showAccount: "credential", nav: 4});
			}
			else{

				this.setState({showAccount: "credential", nav: 4});
				if (nav === 4) switch(stripeSuccess)
				{
					case undefined:
						this.setState({showAccount: "credential"});
						break;

					case true:
						this.setState({showAccount: "account", stripeSuccess: true});
						break;

					case "pmSetup":
						this.setState({showAccount: "account"}); // 20220426 - not quite sure how to drive this
						break;
					default:
						break;
				}
			}
		}

	} // componentDidMount

	navigate(nav)
	{
		localStorage.removeItem("navigation", 4); // This OOB navigation system is clumzy.
		this.setState({nav});
	}

	home() { this.setState({nav:1}); }

/*
	// Code in here to go to any setup change (more / less editors. more / less sources)
	// IT MAKES SURE the stripe subcomponent is ready.
	testStripeOld = () =>
	{

		// A function to invoke when async functions have come back
		// For example to reset the cursor to its normal aspect
		let postOp = false;

		// The code to run once we are ready.
		const completionFunction = () =>
		{
			if (postOp) postOp();
			this.setState({nav:5});	
		
		}
		

		if (stripePromise === null)
		{
		
			// We must set the stripe promise which we will obtain by using the stripe publishable key which held by the server.

			// NOTE: Here, you should indicate a possible wait, like a hour-glass mouse pointer.
			//       WE SHOULD ALSO PREVENT ANY OTHER USER INPUT (with a a modal box maybe?)


			// CODE FOR WAIT DEVICE HERE
			// postOp = () => { ... }

			// Uncomment and populate this
			// postOp = () => { / * code to restore the mouse to its normal shape FOR EXAMPLE * / };

			// We haven't retrieved the stripe publishable key yet.
			AqaComponent.backend.getStripePublishableKeyUsingGET
			(
				(error, data, response) =>
				{
					if (error) this.reportError("Sorry, there was an error.\nPlease retry a bit later", error,this);
					else
					{
						stripePromise = loadStripe(data["message"]);
						completionFunction();
					}
				}
			);

		}
		else
		{
			// The stripe promise has already been set, so we're good to go immediately.
			// No need to show any 'please wait device'
			completionFunction();		
		}

	} // testStripeOld
*/

// TODO: 20220425: retest
	testStripe = () =>
	{
		this.setState({nav:5});	
	} // teststripe

	handleAccountClick=()=>{
		// What should be done for normal user?
		this.setState({nav:4,whichTab:0});
	}

	handleAccountTab = (which)=>{
		this.setState({whichTab:which});
	}

	handleOnClose = ()=>{
		this.setState({isSnackOpen:false});
	}

	handleCheckStatus = ()=>{
		if(this.state.isAccountFree || this.state.isAccountExpired || this.state.isAccountWaiting) {
			this.setState({isSnackOpen: true});
		}
	}

	componentWillUnmount() {
		AqaComponent.USER_EVENTS_THAT_TOUCH.forEach(name => window.removeEventListener(name, AqaComponent.touch));
		this.clearTimeoutDialogTimeout();
	}

	render()
	{
		const {classes} = this.props;
		//if (this.state.username === "") this.getUsername();
		const {openPlanEditor,stripeSuccess,isAuthorized,isAuthorizedVerified,timeoutDialogVisible,isLoaded} = this.state; // showAccount,nav
		let {authCode} = this.props.parent.state;
		if(authCode === "") authCode ="V";
		return (
			<>
				<AqaHeader dataFromRoot={this.props.parent} dataFromParent={this} />
				<AqaFooter dataFromRoot={this.props.parent} dataFromMain={this} chatRef={this.props.parent.state.user===null?null:this.chatRef} />
	
{/*
<div style={{paddingTop:"70px", textDecoration:"underline",cursor:"pointer"}} onClick={() => this.setState({nav:5})}>test user management</div>
<div style={{textDecoration:"underline",cursor:"pointer"}} onClick={this.testStripe}>test stripe payment</div>
<div style={{textDecoration:"underline",cursor:"pointer"}} onClick={() => this.setState({nav:7})}>test accounts</div>
*/}




				<Dialog
					open={this.state.timeoutDialogVisible}
					aria-labelledby="max-width-dialog-title"
					classes={{ paper: classes.paper}}
					style={{margin:"0px"}}
				>

					<DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
						<div style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>Session Timeout</div>
					</DialogTitle>

					<DialogContent align="center" style={{padding: 0}}>

						<div style={{margin:"16px",fontFamily:"Montserrat",fontSize:"14px"}}>
							You have been inactive for a little while.
							So for security reasons, we will close your session.
							Unless you click<Button type="submit"
													variant="contained"
													color="primary"
													className="aqa-action-button"
													value="ok"
													style={
														{
															marginTop: 0,
															marginLeft: 4,
															marginRight: 4,
															color: '#4cadc4',
															backgroundColor: 'white',
															border: '1px solid #4cadc4',
															fontSize: '0.7rem',
															padding: '3px'
														}
													}
													onClick={() => this.closeTimeOutDialog(false)}>
							{"Continue"}
						</Button>
							in the next {this.state.timeoutCoundownValue} second{this.state.timeoutCoundownValue > 1 ? 's' : ""}.

						</div>


					</DialogContent>

				</Dialog>

				<Dialog
					open={!isAuthorized && isAuthorizedVerified}
					aria-labelledby="max-width-dialog-title"
					classes={{ paper: classes.paper}}
					style={{margin:"0px"}}
				>

					<DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
						<div style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>Token Expired</div>
					</DialogTitle>

					<DialogContent align="center" style={{padding: 0}}>

						<div style={{margin:"16px",fontFamily:"Montserrat",fontSize:"14px"}}>
							<div>Your token seems to be expired!!</div>
							<div>For security reasons, we will close your session.</div>
							<div style={{textAlign:"center"}}><Button type="submit"
																	  variant="contained"
																	  color="primary"
																	  className="aqa-action-button"
																	  value="ok"
																	  style={
																		  {
																			  marginTop: 8,
																			  marginLeft: 8,
																			  marginRight: 8,
																			  color: '#4cadc4',
																			  backgroundColor: 'white',
																			  border: '1px solid #4cadc4',
																			  fontSize: '0.7rem',
																			  padding: '3px'
																		  }
																	  }
																	  onClick={() => this.handleUnAuthorized()}>
								{"Ok"}
							</Button>
							</div>

						</div>


					</DialogContent>

				</Dialog>

				{/*
					<div>
						<input type="button" value="Test Stripe" onClick={this.testStripe} style={{marginTop: "60px"}}/>
						<input type="button" value="Try 2FA" onClick={() => this.setState({nav: 20})}
							   style={{marginTop: "60px"}}/>
					</div>
				*/}

				<div id="utility-area" className="aqa-content">
					{
						isAuthorized && isAuthorizedVerified && !timeoutDialogVisible && isLoaded ?

						(this.state.nav === 1 ? <AqaSourceManager parent={this} dataFromRoot={this} dataFromParent={this.state.currentFolder} authCode={this.state.authCode} isGrid={this.state.isGrid} isLibrary={this.state.isLibrary}/> :
						this.state.nav === 2 ? <AqaSourceDetail parent={this} dataFromParent={this.state.currentSource} dataFromRoot={this} dataFromMainFrame={this} currentFile={this.state.currentFile} isAmber={this.state.isAmber} isRed={this.state.isRed} currentHeatMap={this.state.currentHeatMap} currentColumn={this.state.currentColumn} authCode={this.state.authCode} finerGrain={this.state.finerGrain} />  :
						this.state.nav === 3 ? <AqaSnapshotDetail snapshotId={this.state.currentSnapshot.id} sourcename={this.state.currentSource.name} dataFromSnapshot={this.state.currentSnapshot} dataFromSource={this.state.currentSource} dataFromRoot={this} currentAspect={this.state.currentAspect} isAmber={this.state.isAmber} isRed={this.state.isRed} currentHeatMap={this.state.currentHeatMap} currentColumn={this.state.currentColumn}/> :
						this.state.nav === 4 ? <AqaAccountManager parent={this} dataFromRoot={this.props.parent} dataFromParent={this} which={this.state.showAccount} dataForPlanEditor={openPlanEditor} dataForStripeSuccess={stripeSuccess} whichTab={this.state.whichTab}/> :
						this.state.nav === 6 ? <AqaAQDDetail parent={this} dataFromParent={this} dataFromRoot={this.props.parent} currentSource={this.state.currentSource} currentAQD={this.state.currentAQD} aqdOrigin={this.state.aqdOrigin} authCode={this.state.authCode} prevnav={this.state.prevnav} />  :

this.state.nav === 5 ? <Elements stripe={this.state.stripePromise}><ElementsConsumer>{({stripe, elements}) => (<AqaTestStripe stripe={stripe} elements={elements} />)}</ElementsConsumer></Elements> :


						// These states are production not debug. They are needed
										this.state.nav === 10 ? <StripeSuccess parent={this} /> :
											this.state.nav === 11 ? <StripeFailure parent={this} /> :



this.state.nav === 20 ? <TwoFactor /> :											
						null):""

					}
					<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						ContentProps={{
							classes: {
								root: classes.root,
								message:classes.message
							}
						}}
						style={{bottom:"1px"}}
						open={(this.state.isAccountFree || this.state.isAccountExpired || this.state.isAccountWaiting) && this.state.isSnackOpen} message={
						<Typography style={{color:"white",fontFamily:"Montserrat",fontSize:"14px",marginTop:"-8px"}} >
							{this.state.isAccountWaiting?"Awaiting payment":(this.state.isAccountExpired && !this.state.isSubscriptionCancelled?"Free Plan expired "+this.state.accountDate:(this.state.isSubscriptionCancelled?"Unsubscribed "+this.state.accountDate:"Free Plan expires "+this.state.accountDate))}
						</Typography>
					} action={<>{authCode==="A"?
						<Button color="inherit" size="small" style={{padding:"0"}} onClick={(e)=>this.handleAccountClick(e)}>
							{this.state.isSubscriptionCancelled || this.state.isAccountWaiting || this.state.isAccountExpired?"REVIEW":"UPGRADE"}
						</Button>:""}
						{/*<IconButton size="small" aria-label="close" onClick={(e)=>this.handleOnClose(e)}>
							<CloseIcon fontSize="small" style={{fill:"white"}} />
						</IconButton>*/}
					</>
					} className={classes.snackbar}

					/>
				</div>
			</>
		);
	} // render

} ////
AqaMainFrame.propTypes = {
	classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AqaMainFrame);


/*

/*
Stripe Test page

this.state.nav === 5 ? <Elements stripe={stripePromise}><ElementsConsumer>{({stripe, elements}) => (<AqaTestStripe stripe={stripe} elements={elements} />)}</ElementsConsumer></Elements> :

*/


