/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MsContext from "../shared/masterservant/MsContext";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import {ReactComponent as AccountIcon} from "../../images/aqa-icons/CreditCardOutline2.svg";
import {Checkbox, FormControlLabel, TableCell, TableRow} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import HtmlTooltip from "@material-ui/core/Tooltip";

import {ReactComponent as CheckIcon} from "../../images/aqa-icons/CheckMark.svg";
//import {ReactComponent as CrossIcon} from "../../images/aqa-icons/XMark.svg";


const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaAccountPMListRow extends AqaComponent {
    // No, seriously, we need this.
    static contextType = MsContext;
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:"account",
            selectedFolders:[],
            resetCheckbox:false,
            folders: [],
            tabvalue:0,
            users:[],
            account:null,
            showCheckbox: false,
            isSelected:false,
            is2FAEnabled:false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleMouseOverIcon = this.handleMouseOverIcon.bind(this);
        this.handleMouseEnterIcon = this.handleMouseEnterIcon.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    handleMouseOverIcon(e){
        e.preventDefault();
        var target = e.target;
        var inputSelections = target.getElementsByTagName("input");
        if(inputSelections.length===0) {
            if (this.state.showCheckbox === true && this.state.isSelected === false) {
                this.setState({showCheckbox: false});
            }
        }
    }

    handleMouseOut(e){
        e.stopPropagation();
        if(this.state.showCheckbox === true && this.state.isSelected === false) {
            this.setState({showCheckbox: false});
        }
    }

    handleMouseEnterIcon(e){
        e.preventDefault();
        if(this.state.showCheckbox === false){
            this.setState({showCheckbox:true});
        }

    }

    handleChange(event) {
        //event.preventDefault();
        var isSelected = this.state.isSelected;
        if (this.state.isSelected === false) {
            isSelected = true;
            this.setState({isSelected: true});
        } else {
            isSelected = false;
            this.setState({isSelected: false});
        }
        this.props.dataFromMain.props.dataFromMain.handlePMSelection(this.props.dataFromParent, isSelected);
        this.props.dataFromMain.props.dataFromMain.handlePMSelectionCheckReset();

    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({currentSource:nextProps.dataFromParent});
        if(this.state.showCheckbox === true && nextProps.dataForCheckbox===true){
            this.setState({showCheckbox:false,isSelected:false});
        }
    }

    componentDidMount(){
        this.setState({isLoaded:true});

    }

    render() {
        this.show = this.context.show;
        //const { classes } = this.props;
        const { showCheckbox/*,error, isLoaded, folders, dense, secondary,redirect, notfound,currentFolder,tabvalue,users,isSelected*/ } = this.state;


        return (
            <TableRow key={this.props.item.id} className={"aqa-folder"} onMouseOut={this.handleMouseOverIcon} onMouseLeave={this.handleMouseOut}>

                <TableCell width="4%" style={{"padding":"0px 0px 0px 4px"}} onMouseOver={this.handleMouseEnterIcon}>
                    <div className="aqa-text-bold-12">
                        <div className={"aqa-list-select-action"} >
                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action" >Select</Typography>
                                </div>

                            </React.Fragment>} arrow={"true"} placement="bottom-start" >
                                <div className={"aqa-list-checkbox"}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.isSelected}
                                                onChange={(evt) => this.handleChange(evt)}
                                                name="isSelected"
                                                color="primary"
                                                disabled={!showCheckbox}
                                            />
                                        }
                                        label=""
                                        labelPlacement="start"
                                    />
                                </div>
                            </HtmlTooltip>

                        </div></div></TableCell>
                <TableCell width="4%" style={{"padding":"0px"}}><div className="aqa-text-bold-12"><div className="aqa-list-icon" style={{paddingTop:"3px"}}>
                    <AccountIcon width={"32px"} />
                </div></div></TableCell>
                <TableCell width="30%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">{this.props.item.last4Digits!==null?"**** "+this.props.item.last4Digits:""}</div></TableCell>
                <TableCell width="20%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">{this.props.item.cardType!==null?this.props.item.cardType.toLocaleUpperCase():""}</div></TableCell>
                <TableCell width="20%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">{this.props.item.expiryMonth!== null && this.props.item.expiryYear !==null?this.props.item.expiryMonth+"/"+this.props.item.expiryYear:""}</div></TableCell>
                <TableCell width="5%" style={{"padding":"0px"}}><div className="aqa-text-bold-12"></div>{this.props.item.default?<CheckIcon />:""}</TableCell>

            </TableRow>
        );
    }
}

AqaAccountPMListRow.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaAccountPMListRow);