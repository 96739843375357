/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaHeatmap = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The AqaHeatmap model module.
   * @module model/AqaHeatmap
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaHeatmap</code>.
   * @alias module:model/AqaHeatmap
   * @class
   */
  var exports = function() {
    var _this = this;



















  };

  /**
   * Constructs a <code>AqaHeatmap</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaHeatmap} obj Optional instance to populate.
   * @return {module:model/AqaHeatmap} The populated <code>AqaHeatmap</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('dateColours')) {
        obj['dateColours'] = ApiClient.convertToType(data['dateColours'], 'String');
      }
      if (data.hasOwnProperty('height')) {
        obj['height'] = ApiClient.convertToType(data['height'], 'Number');
      }
      if (data.hasOwnProperty('horizontalOffset')) {
        obj['horizontalOffset'] = ApiClient.convertToType(data['horizontalOffset'], 'Number');
      }
      if (data.hasOwnProperty('horizontalPostPad')) {
        obj['horizontalPostPad'] = ApiClient.convertToType(data['horizontalPostPad'], 'Number');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('numberColours')) {
        obj['numberColours'] = ApiClient.convertToType(data['numberColours'], 'String');
      }
      if (data.hasOwnProperty('orientation')) {
        obj['orientation'] = ApiClient.convertToType(data['orientation'], 'String');
      }
      if (data.hasOwnProperty('originallyRequestedHeight')) {
        obj['originallyRequestedHeight'] = ApiClient.convertToType(data['originallyRequestedHeight'], 'Number');
      }
      if (data.hasOwnProperty('originallyRequestedWidth')) {
        obj['originallyRequestedWidth'] = ApiClient.convertToType(data['originallyRequestedWidth'], 'Number');
      }
      if (data.hasOwnProperty('overallColours')) {
        obj['overallColours'] = ApiClient.convertToType(data['overallColours'], 'String');
      }
      if (data.hasOwnProperty('populationColours')) {
        obj['populationColours'] = ApiClient.convertToType(data['populationColours'], 'String');
      }
      if (data.hasOwnProperty('stringColours')) {
        obj['stringColours'] = ApiClient.convertToType(data['stringColours'], 'String');
      }
      if (data.hasOwnProperty('tableId')) {
        obj['tableId'] = ApiClient.convertToType(data['tableId'], 'String');
      }
      if (data.hasOwnProperty('timestamp')) {
        obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Number');
      }
      if (data.hasOwnProperty('uniquenessColours')) {
        obj['uniquenessColours'] = ApiClient.convertToType(data['uniquenessColours'], 'String');
      }
      if (data.hasOwnProperty('verticalOffset')) {
        obj['verticalOffset'] = ApiClient.convertToType(data['verticalOffset'], 'Number');
      }
      if (data.hasOwnProperty('verticalPostPad')) {
        obj['verticalPostPad'] = ApiClient.convertToType(data['verticalPostPad'], 'Number');
      }
      if (data.hasOwnProperty('width')) {
        obj['width'] = ApiClient.convertToType(data['width'], 'Number');
      }
    }
    return obj;
  }

  /**
   * @member {String} dateColours
   */
  exports.prototype['dateColours'] = undefined;
  /**
   * @member {Number} height
   */
  exports.prototype['height'] = undefined;
  /**
   * @member {Number} horizontalOffset
   */
  exports.prototype['horizontalOffset'] = undefined;
  /**
   * @member {Number} horizontalPostPad
   */
  exports.prototype['horizontalPostPad'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {String} numberColours
   */
  exports.prototype['numberColours'] = undefined;
  /**
   * @member {module:model/AqaHeatmap.OrientationEnum} orientation
   */
  exports.prototype['orientation'] = undefined;
  /**
   * @member {Number} originallyRequestedHeight
   */
  exports.prototype['originallyRequestedHeight'] = undefined;
  /**
   * @member {Number} originallyRequestedWidth
   */
  exports.prototype['originallyRequestedWidth'] = undefined;
  /**
   * @member {String} overallColours
   */
  exports.prototype['overallColours'] = undefined;
  /**
   * @member {String} populationColours
   */
  exports.prototype['populationColours'] = undefined;
  /**
   * @member {String} stringColours
   */
  exports.prototype['stringColours'] = undefined;
  /**
   * @member {String} tableId
   */
  exports.prototype['tableId'] = undefined;
  /**
   * @member {Number} timestamp
   */
  exports.prototype['timestamp'] = undefined;
  /**
   * @member {String} uniquenessColours
   */
  exports.prototype['uniquenessColours'] = undefined;
  /**
   * @member {Number} verticalOffset
   */
  exports.prototype['verticalOffset'] = undefined;
  /**
   * @member {Number} verticalPostPad
   */
  exports.prototype['verticalPostPad'] = undefined;
  /**
   * @member {Number} width
   */
  exports.prototype['width'] = undefined;


  /**
   * Allowed values for the <code>orientation</code> property.
   * @enum {String}
   * @readonly
   */
  exports.OrientationEnum = {
    /**
     * value: "column"
     * @const
     */
    "column": "column",
    /**
     * value: "row"
     * @const
     */
    "row": "row"  };


  return exports;
}));


