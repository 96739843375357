import React from 'react';
import AqaComponent  from "../shared/aqacomponent/AqaComponent"

import {STRIPE_SUCCESS_PARAM/*, STRIPE_FAILURE_PARAM*/} from "./StripeConstants"




// @Deprecated - used for tests only
export default class AqaStripeSubscriptionForm extends AqaComponent
{


	constructor(props)
	{
		super(props);
		this.state = 
		{
			clientSecret:props.clientSecret,
			error:""
		};
		this.elements = null;
	} //

	componentDidMount()
	{
		// https://stripe.com/docs/billing/subscriptions/build-subscription?ui=elements
		// https://stripe.com/docs/js/payment_intents/confirm_payment
		// https://stripe.com/docs/payments/checkout/custom-success-page << quite interesting but does it apply?

		const {stripe} = this.props;

		const options =
		{
			clientSecret: this.state.clientSecret,
			// Fully customizable with appearance API.
			appearance: {/*...*/},
		};

		// Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 5
		this.elements = stripe.elements(options);

		// Create and mount the Payment Element
		const paymentElement = this.elements.create('payment');
		paymentElement.mount('#payment-element');

	} // componentDidMount

	submit(e)
	{
		e.preventDefault();
		const stripe = this.props.stripe;

// onsole.log("Current page: {" + window.location.href + "}");

		const redirection = `${window.location.href}?${STRIPE_SUCCESS_PARAM}=1`;
// onsole.log("Redirection: {" + redirection + "}");

		stripe.confirmPayment
		(
			{
				//`Elements` instance that was used to create the Payment Element
				elements:this.elements,
				confirmParams:
				{
					return_url: redirection,
				}
			}
		).then
		(
			result =>
			{
				if (result.error) this.setState({error:result.error.message});
			},
			systemError => alert("System error: " + systemError)
		)
/*

  const {error} = await stripe.confirmPayment({
    //`Elements` instance that was used to create the Payment Element
    elements:this.elements,
    confirmParams:
    {
      return_url: "http://www.glaine.net/",
    }
  });

  if (error) {
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Show error to your customer (for example, payment
    // details incomplete)
    const messageContainer = document.querySelector('#error-message');
    messageContainer.textContent = error.message;
  } else {
    // Your customer will be redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer will be redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.
  }
*/
	
	
	} // submit
	

	render()
	{
		// id="payment-form"
		return (
			<form onSubmit={e => this.submit(e)}>
				<div id="payment-element" />	
				<button id="submit">Pay for Subscription</button>
				<div style={{backgroundColor:"red", color:"#fff", padding:"8px"}} >{this.state.error}</div>
			</form>
		);
	
	
	} // render




} ////
