/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MsContext from "../shared/masterservant/MsContext";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import {ReactComponent as LoadingIcon} from "../../images/grid.svg";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaAccountBilling extends AqaComponent {
    // No, seriously, we need this.
    static contextType = MsContext;
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            error1: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:"account",
            selectedFolders:[],
            resetCheckbox:false,
            name:"",
            firstname:"",
            lastname:"",
            company:"",
            country:"",
            state:"",
            city:"",
            postcode:"",
            street:"",
            suite:"",
            phone:"",
            vat:"",
            hasError:false,
            submitted:false,
            loading:true,
            accountObj:null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleAccountDetails = this.handleAccountDetails.bind(this);
        this.handleAccountUpdate=this.handleAccountUpdate.bind(this);
    }

    handleAccountCancel = (e)=>{
        this.handleAccountDetails();
    }

    handleAccountUpdate= (e)=>{
        e.preventDefault();
        var accountId=this.state.accountObj.id;
        var accountToUpdate={
            "city": this.state.city,
            "companyName": this.state.company,
            "country": this.state.country,
            "firstName": this.state.firstname,
            "id": accountId,
            "lastName": this.state.lastname,
            "phoneNumber": this.state.phone,
            "postcode": this.state.postcode,
            "state": this.state.state,
            "streetAddress": this.state.street,
            "suite": this.state.suite,
            "vat":this.state.vat
        };
        AqaComponent.accountBackend.updateAccountUsingPUT(
            accountToUpdate,
            (error,data,response)=>{
                if (error) {
                    var resp=JSON.parse(response.text);
                    this.setState({error1:resp.message});
                    if(response.status === 401 ){
                        this.props.dataFromParent.handleSetAuthorized();
                    }
                    else this.reportError("A problem occured when updating account.",
                        "AqaAccountBilling.handleAccountUpdate, call: " + error,this);
                }
                else {
                    //console.log("Account Updated");
                }
            }
        );
    }

    handleAccountDetails(){
//        var accountId=this.props.dataFromRoot.props.dataFromRoot.state.accountId;
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                if(error) {
                    if(response.status === 401 ){
                        this.props.dataFromParent.handleSetAuthorized();
                    }
                    else this.reportError("A problem getting the folder from the server was encountered.",
                        "AqaAccountBilling.handleAccountDetails, backend call: " + error,this);
                }
                else{
                    var accountObj = data;
                    this.setState({firstname:accountObj.firstName!==null?accountObj.firstName:"",
                        lastname:accountObj.lastName!==null?accountObj.lastName:"",
                        company:accountObj.companyName!==null?accountObj.companyName:"",
                        country:accountObj.country!==null?accountObj.country:"",
                        state:accountObj.state!==null?accountObj.state:"",
                        city:accountObj.city!==null?accountObj.city:"",
                        postcode:accountObj.postcode!==null?accountObj.postcode:"",
                        street:accountObj.streetAddress!==null?accountObj.streetAddress:"",
                        suite:accountObj.suite!==null?accountObj.suite:"",
                        phone:accountObj.phoneNumber!==null?accountObj.phoneNumber:"",
                        vat:accountObj.vat!==null?accountObj.vat:"",
                        accountObj:accountObj
                    });
                }
            }

        );
    }

    handleChange(e)
    {
        const { name, value } = e.target;
        this.setState({ [name]: value ,loading:false,error:"",error1:"",hasError: false});
    } // handleChange

    componentDidMount(){
        this.setState({isLoaded:true});
        this.handleAccountDetails();
    }

    render() {
        this.show = this.context.show;
        //const { classes } = this.props;
        const { error, isLoaded,redirect, notfound,firstname,lastname,company,country,state,city,postcode,street,suite,phone,vat,submitted,hasError } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <div className={"source-detail-servant-pane"} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={10} sm={7} lg={11} xl={11}>
                            <Typography variant="title" color="inherit" align="left"
                                        style={{ fontSize: '1.5rem',
                                            padding: '0.1em 0.5em',
                                            fontFamily: 'Open Sans, sans-serif'}}>
                                Nothing to display
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} lg={1} xl={1}>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>

            </div>;
        } else if (!isLoaded) {
            return (<div className={"aqa-loader"}>
                <div className={"aqa-loading-icon"}>
                    <LoadingIcon fill={"#4cadc4"}/>
                </div>
            </div>);
        } else {
            return (
                <div className={""} style={{background: "#FFF 0% 0% no-repeat padding-box",width:"90%"}} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0,marginLeft:"0px",marginBottom:"30px"}}>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                            <Typography className={"aqa-text-bold-11"} style={{"paddingLeft":"10px",color:"red",paddingTop:"10px"}} >
                                {this.state.error1!==null?this.state.error1:""}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <div style={{height:((window.innerHeight-100)*0.62),overflow:'auto'}}>
                                <Grid container spacing={8} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: "6px",paddingLeft:"15px", marginTop: 0,margin:"-8px"}}>
                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                        <FormControl margin="normal" required fullWidth style={{margin:"0px"}}>
                                            <InputLabel htmlFor="firstname" shrink={true}>First Name</InputLabel>
                                            <Input id="firstname" name="firstname" autoComplete="firstname" value={firstname} error={hasError}
                                                   onChange={this.handleChange} autoFocus style={{marginTop:"12px"}}/>
                                            {submitted && !firstname &&
                                                <div className="help-block">First Name is required</div>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                        <FormControl margin="normal" required fullWidth style={{margin:"0px"}}>
                                            <InputLabel htmlFor="lastname" shrink={true}>Last Name</InputLabel>
                                            <Input id="lastname" name="lastname" autoComplete="lastname" value={lastname} error={hasError}
                                                   onChange={this.handleChange} style={{marginTop:"12px"}}/>
                                            {submitted && !lastname &&
                                                <div className="help-block">Last Name is required</div>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <FormControl margin="normal" required fullWidth style={{margin:"0px"}}>
                                            <InputLabel htmlFor="company" shrink={true}>Company</InputLabel>
                                            <Input id="company" name="company" autoComplete="company" value={company} error={hasError}
                                                   onChange={this.handleChange} style={{marginTop:"12px"}} />
                                            {submitted && !company &&
                                                <div className="help-block">Company is required</div>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                        <FormControl margin="normal" required fullWidth style={{margin:"0px"}}>
                                            <InputLabel htmlFor="country" shrink={true}>Country</InputLabel>
                                            <Input id="country" name="country" autoComplete="country" value={country} error={hasError}
                                                   onChange={this.handleChange} style={{marginTop:"12px"}} />
                                            {submitted && !country &&
                                                <div className="help-block">Country is required</div>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                        <FormControl margin="normal" required fullWidth style={{margin:"0px"}}>
                                            <InputLabel htmlFor="state" shrink={true}>State/Region</InputLabel>
                                            <Input id="state" name="state" autoComplete="state" value={state} error={hasError}
                                                   onChange={this.handleChange} style={{marginTop:"12px"}} />
                                            {submitted && !state &&
                                                <div className="help-block">State/Region is required</div>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                        <FormControl margin="normal" required fullWidth style={{margin:"0px"}}>
                                            <InputLabel htmlFor="city" shrink={true}>City</InputLabel>
                                            <Input id="city" name="city" autoComplete="city" value={city} error={hasError}
                                                   onChange={this.handleChange} style={{marginTop:"12px"}}/>
                                            {submitted && !city &&
                                                <div className="help-block">City is required</div>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                        <FormControl margin="normal" required fullWidth style={{margin:"0px"}}>
                                            <InputLabel htmlFor="postcode" shrink={true}>Postcode</InputLabel>
                                            <Input id="postcode" name="postcode" autoComplete="postcode" value={postcode} error={hasError}
                                                   onChange={this.handleChange} style={{marginTop:"12px"}} />
                                            {submitted && !postcode &&
                                                <div className="help-block">Postcode is required</div>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                        <FormControl margin="normal" required fullWidth style={{margin:"0px"}}>
                                            <InputLabel htmlFor="street" shrink={true}>Street</InputLabel>
                                            <Input id="street" name="street" autoComplete="street" value={street} error={hasError}
                                                   onChange={this.handleChange} style={{marginTop:"12px"}} />
                                            {submitted && !street &&
                                                <div className="help-block">Street is required</div>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                        <FormControl margin="normal" required fullWidth style={{margin:"0px"}}>
                                            <InputLabel htmlFor="suite" shrink={true}>Suite/Unit</InputLabel>
                                            <Input id="suite" name="suite" autoComplete="suite" value={suite} error={hasError}
                                                   onChange={this.handleChange} style={{marginTop:"12px"}} />
                                            {submitted && !suite &&
                                                <div className="help-block">Suite/Unit is required</div>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                        <FormControl margin="normal" required fullWidth style={{margin:"0px"}}>
                                            <InputLabel htmlFor="phone" shrink={true}>Phone Number</InputLabel>
                                            <Input id="phone" name="phone" autoComplete="phone" value={phone} error={hasError}
                                                   onChange={this.handleChange} style={{marginTop:"12px"}}/>
                                            {submitted && !phone &&
                                                <div className="help-block">Phone Number is required</div>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                        <FormControl margin="normal" required fullWidth style={{margin:"0px"}}>
                                            <InputLabel htmlFor="vat" shrink={true}>VAT</InputLabel>
                                            <Input id="vat" name="vat" autoComplete="vat" value={vat} error={hasError}
                                                   onChange={this.handleChange} style={{marginTop:"12px"}}/>
                                            {submitted && !vat &&
                                                <div className="help-block">VAT is required</div>
                                            }
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: 0,marginLeft:"0px",paddingRight:"5px",paddingBottom:"10px"}}>
                                    <Grid item xs={6} sm={6} lg={9} xl={9}>

                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={3} xl={3}>
                                        <div style={{textAlign:"right"}}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginRight: 8,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}
                                                onClick={(e)=>this.handleAccountCancel(e)}

                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className="aqa-action-button"
                                                style={{
                                                    marginTop: 8,
                                                    marginRight: 8,
                                                    color: '#4cadc4',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #4cadc4',
                                                    fontSize: '0.7rem',
                                                    padding: '3px'
                                                }}
                                                onClick={(e)=>this.handleAccountUpdate(e)}

                                            >
                                                Save
                                            </Button>
                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </div>
            );
        }
    }
}

AqaAccountBilling.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaAccountBilling);
