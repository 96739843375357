/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.UserStatResponse = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The UserStatResponse model module.
   * @module model/UserStatResponse
   * @version 1.0
   */

  /**
   * Constructs a new <code>UserStatResponse</code>.
   * @alias module:model/UserStatResponse
   * @class
   */
  var exports = function() {
    var _this = this;








  };

  /**
   * Constructs a <code>UserStatResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserStatResponse} obj Optional instance to populate.
   * @return {module:model/UserStatResponse} The populated <code>UserStatResponse</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('averageSessionDuration')) {
        obj['averageSessionDuration'] = ApiClient.convertToType(data['averageSessionDuration'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = ApiClient.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('lastLogin')) {
        obj['lastLogin'] = ApiClient.convertToType(data['lastLogin'], 'String');
      }
      if (data.hasOwnProperty('loginCount')) {
        obj['loginCount'] = ApiClient.convertToType(data['loginCount'], 'Number');
      }
      if (data.hasOwnProperty('numberOfSessions')) {
        obj['numberOfSessions'] = ApiClient.convertToType(data['numberOfSessions'], 'Number');
      }
      if (data.hasOwnProperty('successfulUploadCount')) {
        obj['successfulUploadCount'] = ApiClient.convertToType(data['successfulUploadCount'], 'Number');
      }
      if (data.hasOwnProperty('uploadCount')) {
        obj['uploadCount'] = ApiClient.convertToType(data['uploadCount'], 'Number');
      }
    }
    return obj;
  }

  /**
   * @member {String} averageSessionDuration
   */
  exports.prototype['averageSessionDuration'] = undefined;
  /**
   * @member {String} email
   */
  exports.prototype['email'] = undefined;
  /**
   * @member {String} lastLogin
   */
  exports.prototype['lastLogin'] = undefined;
  /**
   * @member {Number} loginCount
   */
  exports.prototype['loginCount'] = undefined;
  /**
   * @member {Number} numberOfSessions
   */
  exports.prototype['numberOfSessions'] = undefined;
  /**
   * @member {Number} successfulUploadCount
   */
  exports.prototype['successfulUploadCount'] = undefined;
  /**
   * @member {Number} uploadCount
   */
  exports.prototype['uploadCount'] = undefined;



  return exports;
}));


