/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.Invoice = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The Invoice model module.
   * @module model/Invoice
   * @version 1.0
   */

  /**
   * Constructs a new <code>Invoice</code>.
   * @alias module:model/Invoice
   * @class
   */
  var exports = function() {
    var _this = this;





















  };

  /**
   * Constructs a <code>Invoice</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Invoice} obj Optional instance to populate.
   * @return {module:model/Invoice} The populated <code>Invoice</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('accountId')) {
        obj['accountId'] = ApiClient.convertToType(data['accountId'], 'String');
      }
      if (data.hasOwnProperty('amount')) {
        obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
      }
      if (data.hasOwnProperty('currency')) {
        obj['currency'] = ApiClient.convertToType(data['currency'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('emittedWhen')) {
        obj['emittedWhen'] = ApiClient.convertToType(data['emittedWhen'], 'Number');
      }
      if (data.hasOwnProperty('freqency')) {
        obj['freqency'] = ApiClient.convertToType(data['freqency'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('invoiceGenerationError')) {
        obj['invoiceGenerationError'] = ApiClient.convertToType(data['invoiceGenerationError'], 'String');
      }
      if (data.hasOwnProperty('numberOfEditors')) {
        obj['numberOfEditors'] = ApiClient.convertToType(data['numberOfEditors'], 'Number');
      }
      if (data.hasOwnProperty('numberOfSnapshots')) {
        obj['numberOfSnapshots'] = ApiClient.convertToType(data['numberOfSnapshots'], 'Number');
      }
      if (data.hasOwnProperty('numberOfSources')) {
        obj['numberOfSources'] = ApiClient.convertToType(data['numberOfSources'], 'Number');
      }
      if (data.hasOwnProperty('numberOfViewers')) {
        obj['numberOfViewers'] = ApiClient.convertToType(data['numberOfViewers'], 'Number');
      }
      if (data.hasOwnProperty('paid')) {
        obj['paid'] = ApiClient.convertToType(data['paid'], 'Boolean');
      }
      if (data.hasOwnProperty('paidWhen')) {
        obj['paidWhen'] = ApiClient.convertToType(data['paidWhen'], 'Number');
      }
      if (data.hasOwnProperty('paymentProcessorId')) {
        obj['paymentProcessorId'] = ApiClient.convertToType(data['paymentProcessorId'], 'String');
      }
      if (data.hasOwnProperty('paymentReference')) {
        obj['paymentReference'] = ApiClient.convertToType(data['paymentReference'], 'String');
      }
      if (data.hasOwnProperty('period')) {
        obj['period'] = ApiClient.convertToType(data['period'], 'Number');
      }
      if (data.hasOwnProperty('planId')) {
        obj['planId'] = ApiClient.convertToType(data['planId'], 'String');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'Number');
      }
      if (data.hasOwnProperty('subscriptionId')) {
        obj['subscriptionId'] = ApiClient.convertToType(data['subscriptionId'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {String} accountId
   */
  exports.prototype['accountId'] = undefined;
  /**
   * @member {Number} amount
   */
  exports.prototype['amount'] = undefined;
  /**
   * @member {String} currency
   */
  exports.prototype['currency'] = undefined;
  /**
   * @member {String} description
   */
  exports.prototype['description'] = undefined;
  /**
   * @member {Number} emittedWhen
   */
  exports.prototype['emittedWhen'] = undefined;
  /**
   * @member {String} freqency
   */
  exports.prototype['freqency'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {String} invoiceGenerationError
   */
  exports.prototype['invoiceGenerationError'] = undefined;
  /**
   * @member {Number} numberOfEditors
   */
  exports.prototype['numberOfEditors'] = undefined;
  /**
   * @member {Number} numberOfSnapshots
   */
  exports.prototype['numberOfSnapshots'] = undefined;
  /**
   * @member {Number} numberOfSources
   */
  exports.prototype['numberOfSources'] = undefined;
  /**
   * @member {Number} numberOfViewers
   */
  exports.prototype['numberOfViewers'] = undefined;
  /**
   * @member {Boolean} paid
   */
  exports.prototype['paid'] = undefined;
  /**
   * @member {Number} paidWhen
   */
  exports.prototype['paidWhen'] = undefined;
  /**
   * @member {String} paymentProcessorId
   */
  exports.prototype['paymentProcessorId'] = undefined;
  /**
   * @member {String} paymentReference
   */
  exports.prototype['paymentReference'] = undefined;
  /**
   * @member {Number} period
   */
  exports.prototype['period'] = undefined;
  /**
   * @member {String} planId
   */
  exports.prototype['planId'] = undefined;
  /**
   * @member {Number} status
   */
  exports.prototype['status'] = undefined;
  /**
   * @member {String} subscriptionId
   */
  exports.prototype['subscriptionId'] = undefined;



  return exports;
}));


