/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Checkbox/*, CircularProgress*/, FormControlLabel, TableCell, TableRow} from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import AqaCircularProgressBar from "../shared/meter/AqaCircularProgressBar";
import moment from "moment";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import {ReactComponent as SnapshotIcon} from "../../images/aqa-icons/AQA-Snapshot - v2.svg";
/*import {ReactComponent as CheckIcon} from "../../images/aqa-icons/CheckOutline.svg";
import {ReactComponent as XIcon} from "../../images/aqa-icons/XMarkOutline.svg";
import {ReactComponent as HelpIcon} from "../../images/aqa-icons/HelpOutline.svg";*/
import HtmlTooltip from "@material-ui/core/Tooltip";
//import ReactDOM from 'react-dom';

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaSnapshotListRow extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            secondary: true,
            redirect: false,
            currentSnapshot:{"name":"allsources"},
            folders: [],
            percentage: -1,
            isSelected:false,
            showCheckbox:false,
            numRed:0,
            numAmber:0
        };
        this.handleFolder = this.handleFolder.bind(this);
        this.handleSnapshot = this.handleSnapshot.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleMouseOverIcon = this.handleMouseOverIcon.bind(this);
        this.handleMouseEnterIcon = this.handleMouseEnterIcon.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        this.handleSnapshotScoreClick = this.handleSnapshotScoreClick.bind(this);
    }

    handleFolder(event){
        //this.props.dataFromRoot.handleFolder(event);
    }

	handleSnapshot(e,snapshotId)
	{
		if(this.state.showCheckbox === false)
		{
			this.setState({currentSnapshot: snapshotId});
			this.props.dataFromRoot.handleSnapshot(snapshotId, this.props.dataFromParent);
			
			// This lets AqaSourceDetail know which (if any) snapshotId is selected so that
			// The rules can know which Snapshot to play with. (is currently selected)
			this.props.dataFromMain.setSelectedSnapshotId(snapshotId);
		}
	} // handleSnapshot


    handleMouseOverIcon(e){
        e.preventDefault();
        var target = e.target;
        var inputSelections = target.getElementsByTagName("input");
        if(inputSelections.length===0) {
            if (this.state.showCheckbox === true && this.state.isSelected === false) {
                this.setState({showCheckbox: false});
            }
        }
    }

    handleMouseOut(e){
        e.stopPropagation();
        if(this.state.showCheckbox === true && this.state.isSelected === false) {
            this.setState({showCheckbox: false});
        }
    }

    handleMouseEnterIcon(e){
        e.preventDefault();
        if(this.state.showCheckbox === false){
            this.setState({showCheckbox:true});
        }

    }

    handleSnapshotScoreClick(e){
        e.stopPropagation();
        this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter3(this.props.dataFromMain.props.dataFromParent, this.props.dataFromParent, null, false, false, null);
    }

    handleChange = (event) => {
        event.preventDefault();
        var authCode=this.props.dataFromMain.props.dataFromRoot.props.parent.state.authCode;
        var adminEmail=this.props.dataFromMain.props.dataFromRoot.props.parent.state.adminEmail;
        var context;
        if(authCode !== "A"){
            this.removeAllNotifications();
            this.showNotification(4, null,context , null,adminEmail);
        }
        else {
            if(this.props.dataFromMain.props.dataFromParent.name === "Example Data"){
                var customNotification = {
                    title: "Restricted", titleIcon: "",
                    message: "You cannot Delete this Data Upload",
                    isCustom: false, type: "info",
                    insert: "center", position: "center", autoClose: true, duration: 5000
                };
                this.removeAllNotifications();
                this.showNotification(null, customNotification);
            }
            else {
                var isSelected = this.state.isSelected;
                if (this.state.isSelected === false) {
                    isSelected = true;
                    this.setState({isSelected: true});
                } else {
                    isSelected = false;
                    this.setState({isSelected: false});
                }
                this.props.dataFromMain.handleSnapshotSelection(this.props.dataFromParent, isSelected);
                this.props.dataFromMain.handleSnapshotCheckReset();
            }
        }

    };


    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(this.state.showCheckbox === true && nextProps.dataForCheckbox===true){
            this.setState({showCheckbox:false,isSelected:false});
        }

    }

    componentDidMount(){
// onsole.log(this.props.dataFromParent);
        if(this.state.showCheckbox === true && this.props.dataForCheckbox===true){
            this.setState({showCheckbox:false,isSelected:false});
        }
        var numRed=0;
        var numAmber=0;
        if(this.props.dataFromParent.snapshotScores !== undefined && this.props.dataFromParent.snapshotScores.length>0){
            for(var i=0;i<this.props.dataFromParent.snapshotScores.length;i++){
                var snapshot=this.props.dataFromParent.snapshotScores[i];
                if(this.props.dataFromParent.id === snapshot.id){
                    numRed = snapshot.totalNumberOfReds;
                    numAmber = snapshot.totalNumberOfAmbers;
                }
            }
        }
        this.setState({percentage:this.props.dataFromParent.score,rag:this.props.dataFromParent.rag,numRed:numRed,numAmber:numAmber})
    }
    render() {
        //const { classes } = this.props;
        const {  isLoaded, percentage,rag,showCheckbox/*,numRed,numAmber*/} = this.state;
        if(!isLoaded) {
            return (
                <div id={this.props.id}>Loading...</div>
            );
        } else {
            return (
                <TableRow
                    className={(this.props.dataFromParent.id === this.props.dataForActive) ? "aqa-snapshot-active" : "aqa-snapshot"}
                    key={this.props.dataFromParent.id} id={this.props.id}
                    onClick={(e) => this.handleSnapshot(e, this.props.dataFromParent.id)}
                    onMouseOut={this.handleMouseOverIcon} onMouseLeave={this.handleMouseOut}>

                    <TableCell size="small" width="30%" style={{padding: "0px",border:"none"}}>
                        <Typography className="aqa-text-bold-2" variant="inherit" style={{
                            textAlign: "left",
                            paddingLeft: "4px",
                            width: "140px"
                        }}>
                            <span style={{float:"left",width:"28px",height:"50px"}} onMouseOver={this.handleMouseEnterIcon}>
                        <div className={"aqa-list-select-action"} >
                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action" >Select</Typography>
                                </div>

                            </React.Fragment>} arrow={"true"} placement="bottom-start" >
                                <div className={"aqa-list-snapshot-checkbox"}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.isSelected}
                                                onChange={this.handleChange}
                                                name="isSelected"
                                                color="primary"
                                                disabled={!showCheckbox}
                                            />
                                        }
                                        label=""
                                        labelPlacement="start"
                                    />
                                </div>
                            </HtmlTooltip>
                        </div>
                            </span>
                            <span style={{float:"left",width:"24px",height:"50px"}} >
                        <div className={"aqa-list-select-action"} >
                                <div className="aqa-list-icon" style={{paddingLeft:"0px",paddingTop:"12px"}}>
                                    <SnapshotIcon width="24px" />
                                </div>
                        </div>
                            </span>
                            <span style={{float:"left",width:"60px",height:"50px"}} >
                        <Typography className="aqa-text-bold-2" variant="inherit" style={{
                            textAlign: "left",
                            paddingLeft: "4px",
                            paddingTop:"10px",
                            width: "50px",
                            float:"left"
                        }}>
                            {(this.props.dataFromParent.dateCreated !== null) ? (moment(this.props.dataFromParent.dateCreated).format("YYYY/MM/DD HH:mm")) : ("")}

                        </Typography>
                            </span>
                        </Typography>
                    </TableCell>
                    {/*<TableCell width="5%" style={{padding: "0px", margin: 0,border:"none"}}>
                        {(this.props.dataFromParent.status === "Pending" ) ?
                            (<HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <Typography color="inherit" className="aqa-text-action">This Data Upload is neither Approved nor Rejected</Typography>

                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <div className="aqa-list-icon" style={{paddingLeft:"0px",paddingTop:"4px",marginLeft:"0px"}}>
                                <HelpIcon width="24px" />
                            </div>
                            </HtmlTooltip>) :
                            (this.props.dataFromParent.status === "Approved"?(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            <div>
                                                <Typography color="inherit" className="aqa-text-action">Approved By: {this.props.dataFromParent.statusChangedBy}</Typography>
                                                <Typography color="inherit" className="aqa-text-action">Approved On: {(this.props.dataFromParent.statusChangedDate !== null)? (moment(this.props.dataFromParent.statusChangedDate ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                            </div>
                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                    <div className="aqa-list-icon" style={{paddingLeft:"0px",paddingTop:"4px"}}>
                                        <CheckIcon width="24px" />
                                    </div>
                                    </HtmlTooltip>
                                ):
                                (this.props.dataFromParent.status === "Rejected"?(
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                <div>
                                                    <Typography color="inherit" className="aqa-text-action">Rejected By: {this.props.dataFromParent.statusChangedBy}</Typography>
                                                    <Typography color="inherit" className="aqa-text-action">Rejected On: {(this.props.dataFromParent.statusChangedDate !== null)? (moment(this.props.dataFromParent.statusChangedDate ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                                </div>

                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                    <div className="aqa-list-icon" style={{paddingLeft:"0px",paddingTop:"4px"}}>
                                        <XIcon width="24px" />
                                    </div>
                                        </HtmlTooltip>
                                ):(<CircularProgress />)))
                        }
                    </TableCell>
                    */}
                    <TableCell width="7%" style={{padding: "0px", margin: 0,border:"none"}}>
                        {(percentage === 0 ) ?
                            (<HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action">Error Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?percentage+"%":0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Red: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][3].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Amber: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][4].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][6].toLocaleString():0}</Typography>
                                </div>
                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <div id={this.props.dataFromParent.id + "_acpb"} className={"snapshot_"+this.props.dataFromParentIndex+"_acpb"}
                                     style={{width: 40, height: 40, padding: "0px", marginLeft: "-8px",position:"absolute",zIndex:"10",marginTop:"-20px"}} onClick={this.handleSnapshotScoreClick}>
                                    <AqaCircularProgressBar height="32px" displayText={true} textMoveUp={true}
                                                        percentage={percentage} dataForRAG={rag}/>
                            </div>
                            </HtmlTooltip>) :
                            ("")}
                    </TableCell>
                    <TableCell width="7%" style={{padding: "0px", margin: 0,border:"none"}}>
                        {(percentage > 0 && percentage <= this.props.dataFromRoot.state.x1) ?
                            (<HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action">Error Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?percentage+"%":0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Red: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][3].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Amber: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][4].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][6].toLocaleString():0}</Typography>
                                </div>
                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <div id={this.props.dataFromParent.id + "_acpb"} className={"snapshot_"+this.props.dataFromParentIndex+"_acpb"}
                                     style={{width: 40, height: 40, padding: "0px", marginLeft: "-8px",position:"absolute",zIndex:"10",marginTop:"-20px"}} onClick={this.handleSnapshotScoreClick}>
                                    <AqaCircularProgressBar height="20px" displayText={true} textMoveUp={true} percentage={percentage} dataForRAG={rag}/>
                            </div>
                            </HtmlTooltip>) :
                            ("")}
                    </TableCell>
                    <TableCell width="7%" style={{padding: "0px",border:"none"}}>
                        {(percentage > this.props.dataFromRoot.state.x1 && percentage <= this.props.dataFromRoot.state.x2) ?
                            (<HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action">Error Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?percentage+"%":0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Red: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][3].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Amber: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][4].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][6].toLocaleString():0}</Typography>
                                </div>
                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <div id={this.props.dataFromParent.id + "_acpb"} className={"snapshot_"+this.props.dataFromParentIndex+"_acpb"}
                                  style={{width: 40, height: 40, padding: "0px", marginLeft: "-8px",position:"absolute",zIndex:"10",marginTop:"-20px"}} onClick={this.handleSnapshotScoreClick}>
                                <AqaCircularProgressBar height="20px" displayText={true} textMoveUp={true}
                                                        percentage={percentage} dataForRAG={rag}/>
                            </div>
                            </HtmlTooltip>) :
                            ("")}
                    </TableCell>
                    <TableCell width="7%" style={{padding: "0px",border:"none"}}>
                        {(percentage > this.props.dataFromRoot.state.x2 && percentage <= this.props.dataFromRoot.state.x3) ?
                            (<HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action">Error Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?percentage+"%":0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Red: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][3].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Amber: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][4].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][6].toLocaleString():0}</Typography>
                                </div>
                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <div id={this.props.dataFromParent.id + "_acpb"} className={"snapshot_"+this.props.dataFromParentIndex+"_acpb"}
                                     style={{width: 40, height: 40, padding: "0px", marginLeft: "-8px",position:"absolute",zIndex:"10",marginTop:"-20px"}} onClick={this.handleSnapshotScoreClick}>
                                    <AqaCircularProgressBar height="20px" displayText={true} textMoveUp={true}
                                                        percentage={percentage} dataForRAG={rag}/>
                            </div>
                            </HtmlTooltip>) :
                            ("")}
                    </TableCell>
                    <TableCell width="7%" style={{padding: "0px",border:"none"}}>
                        {(percentage > this.props.dataFromRoot.state.x3 && percentage <= this.props.dataFromRoot.state.x4) ?
                            (<HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action">Error Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?percentage+"%":0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Red: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][3].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Amber: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][4].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][6].toLocaleString():0}</Typography>
                                </div>
                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <div id={this.props.dataFromParent.id + "_acpb"} className={"snapshot_"+this.props.dataFromParentIndex+"_acpb"}
                                     style={{width: 40, height: 40, padding: "0px", marginLeft: "-8px",position:"absolute",zIndex:"10",marginTop:"-20px"}} onClick={this.handleSnapshotScoreClick}>
                                    <AqaCircularProgressBar height="32px" displayText={true} textMoveUp={true}
                                                        percentage={percentage} dataForRAG={rag}/>
                            </div>
                            </HtmlTooltip>) :
                            ("")}
                    </TableCell>
                    <TableCell width="7%" style={{padding: "0px",border:"none"}}>
                        {(percentage > this.props.dataFromRoot.state.x4 && percentage <= this.props.dataFromRoot.state.x5) ?
                            (<HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action">Error Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?percentage+"%":0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Red: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][3].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Amber: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][4].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][6].toLocaleString():0}</Typography>
                                </div>
                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <div id={this.props.dataFromParent.id + "_acpb"} className={"snapshot_"+this.props.dataFromParentIndex+"_acpb"}
                                     style={{width: 40, height: 40, padding: "0px", marginLeft: "-8px",position:"absolute",zIndex:"10",marginTop:"-20px"}} onClick={this.handleSnapshotScoreClick}>
                                    <AqaCircularProgressBar height="32px" displayText={true} textMoveUp={true}
                                                            percentage={percentage} dataForRAG={rag}/>
                                </div>
                            </HtmlTooltip>) :
                            ("")}
                    </TableCell>
                    <TableCell width="7%" style={{padding: "0px",border:"none"}}>
                        {(percentage > this.props.dataFromRoot.state.x5 && percentage <= this.props.dataFromRoot.state.x6) ?
                            (<HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action">Error Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?percentage+"%":0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Red: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][3].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Amber: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][4].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][6].toLocaleString():0}</Typography>
                                </div>
                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <div id={this.props.dataFromParent.id + "_acpb"} className={"snapshot_"+this.props.dataFromParentIndex+"_acpb"}
                                     style={{width: 40, height: 40, padding: "0px", marginLeft: "-8px",position:"absolute",zIndex:"10",marginTop:"-20px"}} onClick={this.handleSnapshotScoreClick}>
                                    <AqaCircularProgressBar height="32px" displayText={true} textMoveUp={true}
                                                            percentage={percentage} dataForRAG={rag}/>
                                </div>
                            </HtmlTooltip>) :
                            ("")}
                    </TableCell>
                    <TableCell width="7%" style={{padding: "0px",border:"none"}}>
                        {(percentage > this.props.dataFromRoot.state.x6 && percentage <= this.props.dataFromRoot.state.x7) ?
                            (<HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action">Error Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?percentage+"%":0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Red: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][3].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Amber: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][4].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][6].toLocaleString():0}</Typography>
                                </div>
                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <div id={this.props.dataFromParent.id + "_acpb"} className={"snapshot_"+this.props.dataFromParentIndex+"_acpb"}
                                     style={{width: 40, height: 40, padding: "0px", marginLeft: "-8px",position:"absolute",zIndex:"10",marginTop:"-20px"}} onClick={this.handleSnapshotScoreClick}>
                                    <AqaCircularProgressBar height="32px" displayText={true} textMoveUp={true}
                                                            percentage={percentage} dataForRAG={rag}/>
                                </div>
                            </HtmlTooltip>) :
                            ("")}
                    </TableCell>
                    <TableCell width="7%" style={{padding: "0px",border:"none"}}>
                        {(percentage > this.props.dataFromRoot.state.x7 && percentage <= this.props.dataFromRoot.state.x8) ?
                            (<HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action">Error Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?percentage+"%":0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Red: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][3].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Amber: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][4].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][6].toLocaleString():0}</Typography>
                                </div>
                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <div id={this.props.dataFromParent.id + "_acpb"} className={"snapshot_"+this.props.dataFromParentIndex+"_acpb"}
                                     style={{width: 40, height: 40, padding: "0px", marginLeft: "-8px",position:"absolute",zIndex:"10",marginTop:"-20px"}} onClick={this.handleSnapshotScoreClick}>
                                    <AqaCircularProgressBar height="32px" displayText={true} textMoveUp={true}
                                                            percentage={percentage} dataForRAG={rag}/>
                                </div>
                            </HtmlTooltip>) :
                            ("")}
                    </TableCell>
                    <TableCell width="7%" style={{padding: "0px",border:"none"}}>
                        {(percentage > this.props.dataFromRoot.state.x8 && percentage <= this.props.dataFromRoot.state.x9) ?
                            (<HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action">Error Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?percentage+"%":0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Red: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][3].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Amber: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][4].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][6].toLocaleString():0}</Typography>
                                </div>
                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <div id={this.props.dataFromParent.id + "_acpb"} className={"snapshot_"+this.props.dataFromParentIndex+"_acpb"}
                                     style={{width: 40, height: 40, padding: "0px", marginLeft: "-8px",position:"absolute",zIndex:"10",marginTop:"-20px"}} onClick={this.handleSnapshotScoreClick}>
                                    <AqaCircularProgressBar height="32px" displayText={true} textMoveUp={true}
                                                            percentage={percentage} dataForRAG={rag}/>
                                </div>
                            </HtmlTooltip>) :
                            ("")}
                    </TableCell>
                    <TableCell width="7%" style={{padding: "0px",border:"none"}}>
                        {(percentage > this.props.dataFromRoot.state.x9 ) ?
                            (<HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action">Error Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?percentage+"%":0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Red: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][3].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Amber: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][4].toLocaleString():0}</Typography>
                                    <Typography color="inherit" className="aqa-text-action" >Rows: {this.props.dataFromParent.lastScores !== undefined && this.props.dataFromParent.lastScores.length!==0?this.props.dataFromParent.lastScores[6][6].toLocaleString():0}</Typography>
                                </div>
                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <div id={this.props.dataFromParent.id + "_acpb"} className={"snapshot_"+this.props.dataFromParentIndex+"_acpb"}
                                     style={{width: 40, height: 40, padding: "0px", marginLeft: "-8px",position:"absolute",zIndex:"10",marginTop:"-20px"}} onClick={this.handleSnapshotScoreClick}>
                                    <AqaCircularProgressBar height="20px" displayText={true} textMoveUp={true}
                                                        percentage={percentage} dataForRAG={rag}/>
                            </div>
                            </HtmlTooltip>) :
                            ("")}
                    </TableCell>

                </TableRow>

            );
        }

    }
}

AqaSnapshotListRow.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSnapshotListRow);

