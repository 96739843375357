

/** This implements
  * https://docs.oracle.com/javase/7/docs/api/java/text/SimpleDateFormat.html
  */

const MEANINGFUL_LETTERS = "GyYMwWDdFEuaHkKhmsSzZX";
const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const DAY_COUNTS = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];

// https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
const weekInYear = (date) =>
{
	var d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
	var dayNum = d.getUTCDay() || 7;
	d.setUTCDate(d.getUTCDate() + 4 - dayNum);
	var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
	return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
}; // weekInYear


// https://stackoverflow.com/questions/43105674/getting-current-week-of-current-month
const weekInMonth = (date) =>
{
	// Copy date so don't affect original
	var d = new Date(+date);
//	if (isNaN(d)) return;
	// Move to previous Monday
	d.setDate(d.getDate() - d.getDay() + 1);
	// Week number is ceil date/7
  return Math.ceil(d.getDate() / 7);
}; // weekInMonth


// https://stackoverflow.com/questions/8619879/javascript-calculate-the-day-of-the-year-1-366
const dayOfYear = (date) =>
{
    var mn = date.getMonth();
//    var dn = ;
    var dayOfYear = DAY_COUNTS[mn] + date.getDate();
    if(mn > 1 && date.isLeapYear()) dayOfYear++;
    return dayOfYear;
}; // dayOfYear

const pad = (number, desiredSize) =>
{
	let missing, ret = number.toString();
	if ((missing = desiredSize - ret.length) > 0) ret = "0".repeat(missing) + ret;
	return ret;
}; // pad



export default class DateFormat
{

	/** @param fs the formatting String
	 */
	constructor(fs)
	{
		this.elements = [];	


		// Let's parse this formatting string
		let n;
		if (!fs || (n = fs.length) === 0) return;

		let i;
		let oldC = false;
		let ctr = 0;
		let c;
		
		let stringStart = false;
		let escaped = false;

		const push = (c, ctr) => this.elements.push([c, ctr, MEANINGFUL_LETTERS.indexOf(c) >= 0]);

		for(i = 0; i < n; i++)
		{
		
			c = fs.charAt(i);
			
			if (escaped)
			{
				this.elements.push([c, 1, false]);
				escaped = false;
//				oldC = false;
				continue;
			}
			
			
			if (stringStart !== false)
			{
				if (c === "'")
				{
					if (stringStart === i) this.elements.push(["'", 1, false]);
					else this.elements.push([fs.substring(stringStart, i), 1, false]);
//					oldC = false;
					stringStart = false;
				}
				continue;		
			}

			if (c !== oldC)
			{
				if (oldC !== false) push(oldC, ctr);
			
				if (c === "\\")
				{
					escaped = true;
					oldC = false;
				}
				else if (c === "'")
				{
					stringStart = i + 1;
					oldC = false;
				}
				else 
				{
					oldC = c;
					ctr = 1;
				}
			}
			else ctr++;
		}
		if (oldC !== false) push(oldC, ctr);


// for(const e of this.elements) onsole.log(e[0] + " : " + e[1] + " Meaningful: " + e[2]);

	
	} //



	/** @param date datetime in ms since the epoch */
	format(dateInMs)
	{
		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date

		let date = new Date(dateInMs);
		let y = date.getFullYear();
		let x;

		const bits = [];
		for(const e of this.elements)
		{
			if (e[2])
			{

				switch(e[0])
				{
				case 'G': // AD or BC
					bits.push(y < 0 ? "BC" : "AD");
					break;

				case 'y':
				case 'Y': // Week year. We don't support it and that's OK
					bits.push(e[1] === 2 ? pad(y % 100, 2) : y.toString());
					break;

				case 'M':
					x = date.getMonth();
					bits.push(e[1] < 3 ? pad(x + 1, e[1]) : (e[1] === 3 ? MONTHS[x].substring(0, 3) : MONTHS[x]));
					break;

				case 'w':
					bits.push(pad(weekInYear(date), e[1]));
					break;

				case 'W':
					bits.push(pad(weekInMonth(date), e[1]));
					break;

				case 'D':
					bits.push(pad(dayOfYear(date), e[1]));
					break;

				case 'd':
					bits.push(pad(date.getDate(), e[1]));
					break;

				case 'F': // Day of week in month
					bits.push((Math.floor((date.getDate() - 1) / 7) + 1).toString());
					break;

				case 'E':
					bits.push(DAYS[date.getDay()]);
					break;

				case 'u':
					x = date.getDay();
					bits.push(pad(x === 0 ? 7 : x, e[1]));
					break;

				case 'a':
					x = date.getHours();
					bits.push(x < 12 ? "am" : "pm");
					break;

				case 'H':
					bits.push(pad(date.getHours(), e[1]));
					break;

				case 'k':
					x = date.getHours();
					bits.push(pad(x === 0 ? 24 : 0, e[1]));
					break;

				case 'K':
					bits.push(pad(date.getHours() % 12, e[1]));
					break;

				case 'h':
					x = date.getHours() % 12;
					bits.push(pad(x === 0 ? 12 : x, e[1]));
					break;

				case 'm':
					bits.push(pad(date.getMinutes(), e[1]));
					break;

				case 's':
					bits.push(pad(date.getSeconds(), e[1]));
					break;

				case 'S':
					bits.push(pad(date.getMilliseconds(), e[1]));
					break;

				case 'z':
					// TODO
					break;

				case 'Z':
					// TODO
					break;

				case 'X':
					// TODO
					break;

				default:
// log unknown directive??
					break;
				}
			}
			else
			{
				bits.push(e[0].repeat(e[1]));
			}	
		}

		return bits.join('');
	
	} // format

} ////

/*
var f1 = new DateFormat("dd MMMM yyyy HH:mm:ss")
onsole.log(f1.format(new Date().getTime()));

f1 = new DateFormat("dd MMM yyyy G")
onsole.log(f1.format(new Date().getTime()));


f1 = new DateFormat("dd MMM yyyy HH:mm:ss")
onsole.log(f1.format(new Date().getTime()));

f1 = new DateFormat("dd MM yyyy HH:mm:ss")
onsole.log(f1.format(new Date().getTime()));

f1 = new DateFormat("dd MM yyyy KK:mm:ss")
onsole.log(f1.format(new Date().getTime()));

f1 = new DateFormat("dd MM yyyy KK.mma")
onsole.log(f1.format(new Date().getTime()));

f1 = new DateFormat("''dd MM yyyy KK.mma")
onsole.log(f1.format(new Date().getTime()));

f1 = new DateFormat("'now: 'dd MM yyyy KK.mma")
onsole.log(f1.format(new Date().getTime()));

f1 = new DateFormat("'now: 'dd MM yyyy KK.mma 'Thanks!'")
onsole.log(f1.format(new Date().getTime()));

f1 = new DateFormat("'now: 'dd MM yyyy KK.mm\\'a\\' 'Thanks!'")
onsole.log(f1.format(new Date().getTime()));
*/
