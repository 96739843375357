import React from 'react';
import AqaComponent  from "../shared/aqacomponent/AqaComponent"

//import {STRIPE_PAYMENT_METHOD_SUCCESS_PARAM} from "./StripeConstants"
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
const base64 = require('base-64'); // import doesn't work



/** This is going to be used forboth payments and storage of new Payment methods */


export default class AqaStripeSubscription extends AqaComponent
{


	constructor(props)
	{
		super(props);
		this.state =
		{
			clientSecret:props.clientSecret,
			error:""
		};
		this.elements = null;

	} //

	componentDidMount()
	{
		// https://stripe.com/docs/billing/subscriptions/build-subscription?ui=elements
		// https://stripe.com/docs/js/payment_intents/confirm_payment
		// https://stripe.com/docs/payments/checkout/custom-success-page << quite interesting but does it apply?

		const {stripe} = this.props;

		const options =
		{
			clientSecret: this.state.clientSecret,
			// Fully customizable with appearance API.
			appearance: {/*...*/},
		};

		// Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 5
		this.elements = stripe.elements(options);

		// Create and mount the Payment Element
		const paymentElement = this.elements.create('payment');
		paymentElement.mount('#payment-element');

	} // componentDidMount

// TODO - customise this so it handles payments and methods!
// STRIPE_PAYMENT_METHOD_SUCCESS_PARAM

	submit = e =>
	{
		e.preventDefault();
		const stripe = this.props.stripe;

//		const redirection = `${window.location.href}?${STRIPE_SUCCESS_PARAM}=1`;


		const pmSetup = "pmSetup" === this.props.type;


		let urllink="";
		let location = window.location;
		let urlbase = location.href.slice(0, - ((location.search + location.hash).length));
		if (location.search ==="") urlbase = location.href;

		var res={nav:4, fid:"", scid:"", snid:"", stripesuccess: pmSetup ? "pmSetup" : true};
		var reskey = base64.encode(JSON.stringify(res));
		urllink=urlbase+"?res="+reskey;

		if (pmSetup)
		{
// 				return_url: 'https://example.com/account/payments/setup-complete',
			stripe.confirmSetup
			(
				{
				//`Elements` instance that was used to create the Payment Element
					elements:this.elements,
					confirmParams: { return_url: urllink}
				}
			).then
			(
				result =>
				{
					if (result.error) this.setState({error:result.error.message});
				},
				systemError => alert("System error: " + systemError) // We should use reportError here!!
			);

		
			/*
			if (error) {
			// This point will only be reached if there is an immediate error when
			// confirming the payment. Show error to your customer (for example, payment
			// details incomplete)
			const messageContainer = document.querySelector('#error-message');
			messageContainer.textContent = error.message;
			}
			*/
			
			/*
			else
			{
				// Your customer will be redirected to your `return_url`. For some payment
				// methods like iDEAL, your customer will be redirected to an intermediate
				// site first to authorize the payment, then redirected to the `return_url`.
			}
			*/
		
		
		}
		else
		{
			stripe.confirmPayment
			(
				{
					//`Elements` instance that was used to create the Payment Element
					elements:this.elements,
					confirmParams: { return_url: urllink }
				}
			).then
			(
				result =>
				{
					if (result.error) this.setState({error:result.error.message});
				},
				systemError => alert("System error: " + systemError) // We should use reportError here!!
			);
		}
		
		








	}; // submit


	handleCancelPayment = () => {
		if(this.props.dataFromParent.state.showAddPaymentForm=== undefined){
			AqaComponent.backend.clearInterruptedPurchaseUsingPUT
			(
				(error, plans, response) => {
					if (error) console.error("Error checking state: " + error);
					else {
						this.props.dataFromParent.handleCloseTeamPopup();
					}
				}
			);

		}
		else{
			this.props.dataFromParent.handleClosePopup();
		}
	}

	handleTermsLink = (e)=>{
		e.preventDefault();
		e.stopPropagation();
		window.open("https://www.aqaversant.com/terms-of-service/","_new");
	}

	render()
	{

		return (
			<form>
				<Grid
					container
					spacing={0}
					direction="row"
					justify="space-between"
					alignItems="center"
					style={{padding: 0, marginTop: "0px"}}
				>
					<Grid item xs={12} sm={12} lg={12} xl={12}>

						<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: "20px", marginTop: "0px"}}>
							<Grid item xs={12} sm={12} lg={12} xl={12}>
								<div style={{color:"red", paddingTop:"8px"}} >{this.state.error}</div>
							</Grid>
							<Grid item xs={12} sm={12} lg={12} xl={12}>
								<div id="payment-element" />
							</Grid>
							<Grid item xs={12} sm={12} lg={12} xl={12}>
								<Typography variant={"inherit"} style={{textAlign:"left",fontSize: '0.7rem',
									fontFamily:'Montserrat'}}>
										Read the <Button
											type="submit"
											variant="text"
											color="default"
											className=""
											style={
												{
													marginTop: 0,
													marginRight: 2,
													color: '#04A6FC',
													backgroundColor: 'transparent',
													border: '0px solid #04A6FC !important',
													fontSize: '0.7rem',
													fontFamily:'Montserrat',
													padding: '3px',
													textTransform:'capitalize'
												}
											}
											onClick={(e)=>this.handleTermsLink(e)}
											//component={Link}
											//to="/8Z0015E11RVbrE05/welcome"
										>
											Terms and Conditions
										</Button>
								</Typography>
							</Grid>
						</Grid>
						
					</Grid>

					<Grid item xs={12} sm={12} lg={12} xl={12}>
						<Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start" style={{padding: 0, marginTop:0,marginBottom:8, marginLeft:"0px",borderTop:"1px solid #ccc"}}>
							<Grid item xs={3} sm={3} lg={3} xl={3}>
								<Button
									onClick={this.submit}
									variant="contained"
									color="primary"
									align="right"
									className="aqa-action-button"
									style={{
										marginTop: 10,
										marginLeft: "-20px",
										marginRight: 8,
										marginBottom: 8,
										color: '#4cadc4',
										backgroundColor: 'white',
										border: '1px solid #4cadc4',
										fontSize: '0.7rem',
										padding: '3px'
									}}
								>
									OK
								</Button>

								<Button
									onClick={this.handleCancelPayment}
									variant="contained"
									color="primary"
									align="right"
									className="aqa-action-button"
									style={{
										marginTop: 10,
										marginLeft: "0px",
										marginRight: 8,
										marginBottom: 8,
										color: '#4cadc4',
										backgroundColor: 'white',
										border: '1px solid #4cadc4',
										fontSize: '0.7rem',
										padding: '3px'
									}}
								>
									Cancel
								</Button>

							</Grid>
							<Grid item xs={9} sm={9} lg={9} xl={9}>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

			</form>
		);


	} // render



} ////








        /*

          const {error} = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements:this.elements,
            confirmParams:
            {
              return_url: "http://www.glaine.net/",
            }
          });

          if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            const messageContainer = document.querySelector('#error-message');
            messageContainer.textContent = error.message;
          } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
          }
        */





