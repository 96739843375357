// import React, { Component } from 'react';
import React from 'react';

// Model
import AqaComponent                  from "../shared/aqacomponent/AqaComponent"
import ColourMap                     from "../../model/ColourMap"
import {SnapshotView, Filter}        from "../../model/SnapshotView"
import Completer                     from "../../model/Completer"
import RowArrayCachingObject         from "../../model/RowArrayCachingObject"

// Library elements
// import Typography from "@material-ui/core/Typography";

// Our sub components
import AqaSnapShotHeader             from "./AqaSnapShotHeader";
import AqaTypeMetrics                from "./AqaTypeMetrics"
// import AqaCensusMetrics              from "./AqaCensusMetrics"
import AqaHeatMapForDataViewer       from "./AqaHeatMapForDataViewer"
import DistributionGraph             from "../shared/distributiongraph/DistributionGraph"
import TraditionalTable              from "../shared/traditionaltable/TraditionalTable"
import AqaSnapshotSummaryDistributionCensusCombo from "./AqaSnapshotSummaryDistributionCensusCombo"

// import WorkerBuilder                 from "../../workers/WorkerBuilder"


import AqaRules from "../sourcedetail/rules/AqaRules";




export const BACKEND_TYPE_UNIQUENESS    = "uniqueness";
export const BACKEND_TYPE_POPULATION    = "population";
export const BACKEND_TYPE_STRING        = "string";
export const BACKEND_TYPE_NUMBER        = "number";
export const BACKEND_TYPE_DATE          = "date";
export const BACKEND_TYPE_NATIVE_ERRORS = "nativeError";
export const BACKEND_TYPE_OVERALL       = "overall";

//const COUNTING_ROWS_JOB_TYPE = "countingRows";

// To convert Aqa Front End Type number into a backend's AqaCell.Type
// "bool" as of 20210213 we know we won't have a flag type
const UI_TYPE_NUMBER_TO_BACKEND_TYPE_NAME =
[
	BACKEND_TYPE_UNIQUENESS,    // 0
	BACKEND_TYPE_POPULATION,    // 1
	BACKEND_TYPE_STRING,        // 2
	BACKEND_TYPE_NUMBER,        // 3
	BACKEND_TYPE_DATE,          // 4
	BACKEND_TYPE_NATIVE_ERRORS, // 5
	BACKEND_TYPE_OVERALL        // 6

];


const FE_TO_BE_TYPE_INDEX_TRANSLATION = // Give FE index get BE index, used to get data from scores in the correct FE slot
[
	2, // Uniq.
	1, // Pop.
	5, // String
	3, // Number
	4, // Date
	7, // Native
	0  // Overall
];


/*
	OVERALL_POSITION_IN_OVERALL_RAG_SHORT        = 0;
	POPULATION_POSITION_IN_OVERALL_RAG_SHORT     = 1;
	UNIQUENESS_POSITION_IN_OVERALL_RAG_SHORT     = 2;
	NUMBER_POSITION_IN_OVERALL_RAG_SHORT         = 3;
	DATE_POSITION_IN_OVERALL_RAG_SHORT           = 4;
	STRING_POSITION_IN_OVERALL_RAG_SHORT         = 5;
	TYPE_POSITION_IN_OVERALL_RAG_SHORT           = 6;
	NATIVE_ERROR_POSITION_IN_OVERALL_RAG_SHORT   = 7;
*/



const DEFAULT_SORT_TYPE = 3; // See DistributionGraph


const convertUITypeNumberToBackendTypeName = (uiTypeNumber, dontConvertPopAndUniqueness) =>
{
	if (uiTypeNumber === -1) return null;
	if (dontConvertPopAndUniqueness && uiTypeNumber < 2) return "";
	return UI_TYPE_NUMBER_TO_BACKEND_TYPE_NAME[uiTypeNumber];
} // convertUITypeNumberToBackendTypeName


const convertUITypeNumberToBackendTypeNameForHeatmap = (uiTypeNumber) =>
{
	const ret = convertUITypeNumberToBackendTypeName(uiTypeNumber, false);
	if (ret === null) return BACKEND_TYPE_OVERALL;
	return ret;
} // convertUITypeNumberToBackendTypeNameForHeatmap


class InvokeAfter
{

	// convenience to stay DRY
	static forRadarRefresher(asd, n) { return new InvokeAfter(n, () => asd.dataViewer.adjustRadar()); }


	constructor(n, fnToRunAfter)
	{
		this.n = n;
		this.counter = 0;
		this.fnToRunAfter = fnToRunAfter;
	} //

	runIfReady()
	{ 
		this.counter++;
		if (this.counter === this.n) this.run();
	} // runIfReady
	
	run() { this.fnToRunAfter(); }

} //// InvokeAfter


export default class AqaSnapshotDetail extends AqaComponent
{

	static #IDEAL_NUMBER_OF_BARS = 2147483647; // Yep, we're taking our number of bars seriously.
	static #serial = 0;

	constructor(props)
	{
		super(props);
		this.serial = ++AqaSnapshotDetail.#serial;

		this.state =
		{
			filename:   "",
			dimensions: "",
			sourcename: "Unknown source", // should be passed from previous screen
			typeMap:""
		};


		// The elements we are made of
		// ---------------------------

		this.header = null;
		this.combo = [null, null];
		this.tm = [null, null];
		this.distriGraph = [null, null];
		this.heatmaps = [null, null];
		this.dataViewer = null;

		// Debouncing refreshes
		this.timer = null;

		// Resizer
		this.resizer = () => this.updateWidthAndHeight();


		// Anima Machinae
		// --------------

		this.currentView = null;
		this.motherHeaders = null;
		this.typeSelected = Array(2);
		this.filterValues = Array(2);
		this.selectedColumnVector = Array(2); // [first vector selected, second vector selected]
		this.ragColumnSelectors = Array(2); // A/R selectors in (selected) columns
		this.globalAmberRed = Array(2); // The A/R selectors in the TypeMetrics
		this.distributionFilterTypes = Array(2);

		// Initialisations
		for(let i = 0; i < 2; i++)
		{
			this.resetArSelection(i);
			this.selectedColumnVector[i] = null;
			this.typeSelected[i] = -1;
			this.resetFilter(i);
			this.globalAmberRed[i] = [false, false];
			this.distributionFilterTypes[i] = DEFAULT_SORT_TYPE; // Not central as the info is centrally kept in the Distributions graph of which this is a servant.
		}

		// Caches - they MUST be held here.
		// ------

		/** colours must not be cached agressively.
		  * Why? Because they may change if users change VQDs.
		  * I am assuming 1 user is going to work on one snapshot at a time.
		  * So my caches will be reset everytime they re-enter the DataView (ie. here)
		  */
		this.snapshotViewCache = []; // Yes, this one is a square array!.
		this.distributionCache = {};
		this.colourMapCache = {};
		this.heatMapCache = {};
		this.scoresColoursCache = {};
		this.vqdCache = {};


		this.rowCache = {}; // A key based array of RowArrayCachingObject objects


		// This is to avoid Out of Sync row count refreshes
		this.colourMapWorkerTicket = 0;


		// The VQD editor
		this.vqdEditor = null;

		// Poubelle
		// --------

//		this.columnSelected = [-1, -1]; // TODO: remove and only use selectedColumnVector
//		this.filters = [SnapshotView.nullFilter, SnapshotView.nullFilter];

	} // 



	getDescription()
	{
		return "To inspect a snapshot under various approaches (Type andor distribution) and possibly drill into subsets of the data, hopefully to find anomalies and correct them, and / or extract secondary knowledge from the data set (correlations, trends, etc.).<br />";

	} // getDescription

	handleHome()
	{
		this.props.dataFromRoot.handleSnapshotReset();
		this.props.dataFromRoot.navigation(2);
	} // handleHome


	/* So that we can refresh our components
	 * They call us once created.
	 */

	registerCombo(combo, position)                           { this.combo[position] = combo; }
//	registerCensusComponent(censusComponent, position)       { this.censi[position] = censusComponent; }
	registerHeatmap(heatmap, position)                       { this.heatmaps[position] = heatmap; }
	registerDistriComponent(distributionComponent, position) { this.distriGraph[position] = distributionComponent; }
	registerTMComponent(typeMetricComponent, position)       { this.tm[position] = typeMetricComponent; }
	registerDataViewer(dataViewer)                           { this.dataViewer = dataViewer; }
	registerHeader(h)                                        { this.header = h; }
	registerVqdEditor(vqdEditor)                             { this.vqdEditor = vqdEditor; }



	statsOnColumn(cId)
	{
		AqaComponent.snapshotBackend.getColVectorSimplifiedUsingGET
		(
			this.currentView.id,
			cId,
			(error, data, response) =>
			{
				if (error) alert("Error getting the vector stats");
				else alert(AqaComponent.prettifyJson(data));
			}	
		);	
	} // statsOnColumn


	vqdForColumn(cId, callback)
	{
	
		AqaComponent.snapshotBackend.getQualityFromSnapshotUsingGET
		(
			this.currentView.id,
			"column",
			cId,
			(error, data, response) =>
			{
				if (error) alert("Error getting the VQD");
				else callback(data);
			}	
		);
	} // vqdForColumn



	static alphabase(x)
	{
		var range;
		var e;

		for(e = 1; true; x -= range, e++) if (x < (range = Math.pow(26, e))) break;

		var r, ret = "";
		for(var i = 0; i < e; i++)
		{
			r = x % 26;
			ret = String.fromCharCode(65 + r) + ret;
			x -= r;
			x /= 26;
		}
		return ret;

	} // alphabase


// Have one for discrete and one for not?

	/** 
	  * @param which index into UI_TYPE_NUMBER_TO_BACKEND_TYPE_NAME
	  * @param colour 'red' or 'amber'
	  * @param dicrete true(cell counts) or false(rule violations)
	  */
	static getScorePropertyNameFor(which, colour, discrete)
	{
// numberOfDiscretePopulationReds
		let propName = UI_TYPE_NUMBER_TO_BACKEND_TYPE_NAME[which];
		propName = propName.substring(0, 1).toUpperCase() + propName.substring(1);
		return `numberOf${discrete ? "Discrete" : ''}${propName}${colour}s`;
	} // getScorePropertyNameFor


	static getOrthogonalScorePropertyNameFor(which, colour, scoreData)
	{
// numberOfDiscretePopulationReds
		//let propName = UI_TYPE_NUMBER_TO_BACKEND_TYPE_NAME[which];
		//propName = propName.substring(0, 1).toUpperCase() + propName.substring(1);
		//console.log(propName);
		//return `numberOf${discrete ? "Discrete" : ''}${propName}${colour}s`;
		//console.log(scoreData[`numberOfOrthogonalVectorsWithErrors`][0][which][colour]);
		return scoreData[`numberOfOrthogonalVectorsWithErrors`][0][which][colour];
	} // getOrthogonalScorePropertyNameFor

	/** specify -1 if you want the score for the whole table or a column id otherwise.
	  * This is used by AqaTypeMetrics AND setView when it informs the data viewer!
	  */
	getScore(columnId, callback) { return this.getScoreWithViewId(this.currentView.id, columnId, callback); }

	getScoreWithViewId(id, columnId, callback)
	{
//		const id = this.currentView.id;
		const scoreId = `${id}_${columnId}`;

		let ret = this.scoresColoursCache[scoreId];
		if (ret)
		{
			// Getting from cache
			if (callback) callback(ret);
		}
		else
		{
			// We don't have it so we fetch.
			if (columnId === -1)
			{
			
				// We load the snapshot's score
				AqaComponent.snapshotBackend.getSnapshotScoreUsingGET
				(
					id,
					(error, data, response) =>
					{
						// This gets us an AqaScore
						if (error) AqaComponent.staticReportError("Error getting the number of red and amber violations", "getScoreWithViewId() (snapshot) failed for " + scoreId + "\n" + error,this);
						else
						{
							this.scoresColoursCache[scoreId] = data;
							callback(data);
						}
					}
				);
			}
			else
			{
				// We load an AqaVectorColours
				AqaComponent.snapshotBackend.getColumnVectorColoursUsingGET
				(
					id,
					columnId,
					{}, // Se un giorno abbiamo bisogno dei colori, lo diremo qui.
					(error, data, response) =>
					{
						if (error) AqaComponent.staticReportError("Error getting the number of red and amber violations for the column", "getScoreWithViewId() (vector) failed for " + scoreId + "\n" + error,this);
						else
						{
							this.scoresColoursCache[scoreId] = data;
							callback(data);
						}
					}
				);
			}
		}

	} // getScoreWithViewId


//	resetColourMapCache() { this.colourMapCache = {}; }


	/** See notes on caches in constructor */
	getColourMap(view, callback) // In theory, we should never be asking for colours other than the currrent view's. So maybe my parameterisation is a tad overkill? 
	{
		let colourMap;
		if ((colourMap = this.colourMapCache[view.id]))
		{
			if (callback) callback(colourMap);
		}
		else
		{
			colourMap = new ColourMap(view);
			colourMap.load
			(
				() =>
				{
					this.colourMapCache[view.id] = colourMap;
					callback(colourMap);
				}
			);
		}

	} // getColourMap


	/** The Table calls this to get its data
	  */
	pumpRows(topology, sequenceNumber, callback, postOp)
	{

// onsole.log("Pumping A " + sequenceNumber);

// onsole.log(topology);

		let startRow = topology.startVisibleVector;
		let endRow = topology.endVisibleVector;

		if (this.currentView.getHasHorizontalHeader())
		{
			startRow++;
			endRow++;
		}


// (useful) DEBUG ONLY!! ** KEEP **
/*
let R;
const data = [];
for(let y = startRow; y < endRow; y++)
{
	R = [];
	for(let x = 0; x < this.currentView.table.data.numberOfColumns; x++)
	{
		R.push("");	
	}
	data.push(R);

}

const colourMap = new ColourMap(this.currentView);
callback(topology, sequenceNumber, data, colourMap, postOp); // We send all the data gathered to the Traditional Table
return;
*/	
		
		

// onsole.log("Pumping B");

		if (!this.dataViewer.checkSequence(sequenceNumber))
		{
// onsole.log("Pumping DECLOGGED");
			return;
		}


// onsole.log("Pumping C");

/*
onsole.log("TOPOLOGY:");
onsole.log(AqaComponent.prettifyJson(topology));
*/

		topology.adjustedStartRow = startRow;

		if (endRow > this.currentView.table.data.numberOfRows) endRow = this.currentView.table.data.numberOfRows;
		let rowsNeeded = endRow - startRow;

// onsole.log("Pumping D");

//		RowArrayCachingObject = 


		const postLoadOp = data =>
		{
//			let j, n = data ? data.length : 0;
//			for(j = 0; j < n; j++) data[j].values = data[j].values.map(v => v.coercedValue);  // this.extractAndPadVectorValues(data[j].values, this.currentView.table.data.numberOfColumns);

			// need to get the colour map first.
			this.getColourMap
			(
				this.currentView,
				(colourMap) =>
				{
// onsole.log("Pumping E");				

					callback(topology, sequenceNumber, data, colourMap, postOp); // We send all the data gathered to the Traditional Table
				}
			);		
		};

// onsole.log("Pumping F");


		let currentRowsWindow = this.rowCache[this.currentView.id];

		if (!currentRowsWindow) this.rowCache[this.currentView.id] = currentRowsWindow = new RowArrayCachingObject(this.currentView.id);
// else onsole.log("Pumping F.1");

		currentRowsWindow.load(startRow, rowsNeeded, postLoadOp);

// onsole.log("Pumping G");

	} // pumpRows


	refreshInformation()
	{
		const id = this.currentView.id;
		//const stats = this.currentView.table.getStats();
//		const rr = new RadarRefresher(this, 2);

		const rr = InvokeAfter.forRadarRefresher(this, 2);
		
//		for(let i = 0; i < 2; i++) this.refreshInformationFor(id, stats, i, rr);
		for(let i = 0; i < 2; i++) this.refreshInformationFor2(id, i, rr);

//		if (this.dataViewer) this.dataViewer.repaint(); // 20220707 Attempt to get proper cell colours after a column deselection

		
	} // refreshInformation


	getSelectedColIdFor(which)
	{
		const v = this.selectedColumnVector[which];
		return v === null ? -1 : v.data.position;
	} // getSelectedColIdFor
	

//	getTypeMetricSelectedType = position => this.tm[position].getType();

	getTypeMetricSelectedType = position => this.tm[position].getSelectedTypeIndex();


	refreshInformationFor2(id, u, radarRefresher)
	{

// console .log("ASD: (ri)>" + u + ": type selected: " + this.typeSelected[u] + " eqv " + convertUITypeNumberToBackendTypeNameForHeatmap(this.typeSelected[u]));
		const col = this.getSelectedColIdFor(u);
		if (this.heatmaps[u]) this.heatmaps[u].informOfSnapshotId
		(
			id,
			col,
			convertUITypeNumberToBackendTypeNameForHeatmap(this.typeSelected[u]),
			radarRefresher ? () => radarRefresher.runIfReady() : null
		);
		if (this.tm[u])
		{

//			Removed to make the TypeMetrics static
//			this.tm[u].informOfRedAmberChanges(col, null);

//			this.tm[u].informTypeMetrics(this.typeSelected[u], stats);


// onsole.log("ASD:refreshInfomationFor2: Doing some Type metrics information: [" + u + ": " + this.typeSelected[u] + "]");


			this.tm[u].informTypeMetrics2(this.typeSelected[u]);
			
		}
//		this.dataViewer.adjustRadar();

	} // refreshInformationFor2


	selectGlobalAmberRed(which, booleans, postOp)
	{

// onsole.log("Selecting Global Amber Red:");
// onsole.log(AqaComponent.prettifyJson(booleans));

		this.globalAmberRed[which] = booleans;
		this.setView
		(
			() =>
			{
				this.refreshInformation();
				if (postOp) postOp();
			}
		);
	} // selectGlobalAmberRed


	// Used by Type Metrics
	getTypeSelections(which)
	{
		const gar = this.globalAmberRed[which];
		return ([this.typeSelected[which], gar[0], gar[1]]);
	} // getTypeSelection



	resetArSelection(which)
	{
		this.ragColumnSelectors[which] =
		[
			-1,		// Column Id
			false,   // amber 
			false    // red
		];
	} // resetArSelection



	/** We keep the rag selection here
	  * The Traditional Table will query it (with instant retrieval) when it needs to display a table.
	  */
	columnRagSelect(which, position, onlyAmbers, onlyReds) 
	{
		this.ragColumnSelectors[which] = [position, onlyAmbers, onlyReds];
		this.setView(() => {	 this.refreshInformation(); });
		
	} // columnRagSelect


	// Invoked by TraditionalTable
	getColumnRagSelections() { return this.ragColumnSelectors; }


	heatmapClick(which, x, xScale, y, yScale) // Yes, I know which is not used.
	{
	
// console .log("Heat map click");
	
		const bounders =  [this.dataViewer.nRows, this.dataViewer.nColumns];
		const viewSpans = [this.dataViewer.numberOfRowsToDraw, this.dataViewer.numberOfColumnsToDraw];

		const positions = [y, x];
		const scales =    [yScale - 1, xScale - 1];
		const actuators = ["scrollVertically", "scrollHorizontally"];

		let max, position;
		
		for(let i = 0; i < 2; i++)
		{
			position = Math.floor(positions[i] * bounders[i] / scales[i]);
			max = bounders[i] - viewSpans[i];
			if (position > max) position = max;
			if (position < 0) position = 0;
			this.dataViewer[actuators[i]](position, false, true); // Yes we're missing an argument, and no, it wouldn't work otherwise.
		}

	} // heatmapClick


	/** typeMetrics the Component calling us
	  *  which 0:top, 1: bottom
	  *  type number 0: uniqueness, 1: population, 2: string, 3: number, 4: date, 5: overall
	  */
	selectType(which, typeNumber, postOp)
	{
		this.selectTypeWithSortType(which, typeNumber, DEFAULT_SORT_TYPE, postOp);
	} // selectType
	

	resetDistributionGraph(which)
	{
		//                                             (distribution, distributionColours, vqd, sortType, typeName, dateFormatString)
		
// console .log("Calling Distribution graph from resetDistributionGraph: nuu-uu-ll!");
		this.distriGraph[which].informDistributionGraph(null, null, null, DEFAULT_SORT_TYPE, null, null);
	} // resetDistributionGraph


	makeDistributionColoursId(position) { return this.makeDistributionColoursIdWithViewId(this.currentView.id, position); } //         `${this.currentView.id}_column_${position}`;

	makeDistributionColoursIdWithViewId = (viewId, position) => `${viewId}_column_${position}`;



	// 20220608 - There are too many steps.
	// TODO ici
	selectTypeWithSortType(which, typeNumber, sortType, postOp)
	{

// onsole.log("ASD: selectTypeWithSortType() [" + which + " | " + typeNumber + " | " + sortType + " | " + postOp + "]");

// onsole.log("ASD: stwst: original Type number" + this.typeSelected[which]);
// onsole.log("ASD: stwst: new Type number" + typeNumber);

		this.typeSelected[which] = typeNumber;

		const refresher = () =>
		{
			// Not providing a radar refresher because it's going to happen further down eventually
			
// onsole.log("ASD: stwst: refresher invoked");

// NOTE: IMPORTANT This may only work because after the resets, currentViewId is the mother snap!!!!!!!!!

//			this.refreshInformationFor(this.currentView.id, this.currentView.table.getStats(), which);

			this.refreshInformationFor2(this.currentView.id, which);
			this.selectTypeWithSortType(which, typeNumber, sortType, postOp);
		};

		const hasColSelection = this.selectedColumnVector[which] !== null;
		if (hasColSelection && this.filterValues[which] !== null)
		{
			// This an intermediary step, after which we'll carry out our selection.

			// If a value filter is in place, there is no way we can allow it to stay on a type change.
			this.resetFilter(which);
			this.setView(refresher);
			return;
		}

		if (this.globalAmberRed[which][0] || this.globalAmberRed[which][1])
		{
// onsole.log("STWST: Resetting colours");
			// IF A/R global filters are in place, we need to remove them
			this.globalAmberRed[which] = [false, false];
			this.setView(refresher);
			return;
		}

		if (typeNumber >= 2 && typeNumber !== 5) // population and uniqueness don't affect stats
		{

			if (hasColSelection) // && this.censi[which] !== null
			{
				// We have a column selection in place
				// ONLY for data types are we interested about value distribution!!

				// Get the census data about this type
//				this.censi[which].informCensusMetrics(this.selectedColumnVector[which], typeNumber);

				const position = this.selectedColumnVector[which].data.position;

// Might only work thanks to our resets
				const distributionColoursId = this.makeDistributionColoursId(position); // `${this.currentView.id}_column_${position}`;

				const backendTypeName = convertUITypeNumberToBackendTypeName(typeNumber, true);

				// This one will sort by value.
				const distribution = this.selectedColumnVector[which].getDistribution2(backendTypeName);

				// We ned to carry out some tasks before we can inform the distribution graph.

				const distriTasks = Array(2);

				let postOpJustForMe = null;

				let distributionColours = this.distributionCache[distributionColoursId];



				// Task 0: get distribution colours (or not)
				if (!distributionColours)
				{
					postOpJustForMe = postOp;
					postOp = null;

					distriTasks[0] = completionInformer =>
					{
					
// console .log("ASD: selectTypeWithSortType: Getting distribution colours for: " + distributionColoursId);
					
						AqaComponent.backend.getDistributionColoursUsingGET
						(
							distributionColoursId,
							(error, data, response) =>
							{

								if (error) console.error("Distribution Colours retrieval failed");
								else distributionColours = this.distributionCache[distributionColoursId] = data; // Got the colour data

								completionInformer();
//								if (postOpJustForMe) postOpJustForMe();
							}
						);
					};
				}
				else distriTasks[0] = completer => completer();

				let vqd = this.vqdCache[position];
				if (vqd) distriTasks[1] = completer => completer();
				else
				{
				
					distriTasks[1] = completer =>
					{
// console .log("Calling vqdForColumn from SelectTypeWithSortType");
					
						this.vqdForColumn
						(
							position,
							(data) =>
							{
								this.vqdCache[position] = vqd = data; // Got the VQD
								completer();
							}
						);
					};
				}

// console .log("datatype: " + backendTypeName + " vs " +  BACKEND_TYPE_DATE);
				let dateFormat = null;
				if (BACKEND_TYPE_DATE === backendTypeName)
				{

//console .log("Trying to find format for date");
// dateFormat = this.selectedColumnVector[which].getMostFrequentValueOfDistribution("dateFormat");

					dateFormat = this.selectedColumnVector[which].getDateFormat();

// console .log("Format: " + dateFormat);
// console .log(AqaComponent.prettifyJson(dfs));


				}

				const completer = new Completer
				(
					distriTasks,
					() =>
					{
						// When this is run, we have both the VQD and the Colours
// console .log("Calling Distribution graph from selectTypeWithSortType: " + distributionColoursId);
						this.distriGraph[which].informDistributionGraph(distribution, distributionColours, vqd, sortType, backendTypeName, dateFormat);
						this.normalCursor();
						if (postOpJustForMe) postOpJustForMe();
					}
				);

				this.waitCursor();
				completer.run();
			}

		}
		else
		{
			// Reset Distribution graph and Statistics.
			this.resetDistributionGraph(which);
		}


// console .log("STWST: going the long way!!");

		// The heatmap
		const rr = InvokeAfter.forRadarRefresher(this, 2);

		for(let u = 0; u < 2; u++ ) this.heatmaps[u].informOfSnapshotId
		(
			this.currentView.id,
			this.getSelectedColIdFor(u), // v === null ? -1 : v.data.position,
			convertUITypeNumberToBackendTypeNameForHeatmap(this.typeSelected[u]),
			() => rr.runIfReady()
		);

		this.dataViewer.informOfAspect(which, typeNumber);

// console .log("STWST: final");

		if (postOp) postOp(); // This should be to let any AR selection happen (We might have been called because our type wasn't selected)

	} // selectTypeWithSortType



	waitCursor() { document.body.style.cursor = "wait"; }
	normalCursor() { document.body.style.cursor = ""; }

	deSelectColumn(which)
	{


// onsole.log("ASD: Deselecting column: " + which);


		// We reset pretty much everything.
		this.selectedColumnVector[which] = null;
		this.typeSelected[which] = -1; // Reset to overall. J'entends d'ici les gens me demander que le type soit remis a ce qui etait selectionne avant. Oui mais non.
		this.resetDistributionGraph(which);
//		this.censi[which].informCensusMetrics(null, 0);
		this.resetArSelection(which);
		this.resetFilter(which);



		this.selectType
		(
			which,
			-1,
			() =>
			this.selectGlobalAmberRed
			(
				which,
				[false, false],
				() =>
				{
					if (which === 1 && this.combo[1].setVisibility(false)) this.updateWidthAndHeightImmediately(); // We only hide the bottom combo.
				}
			)
		);





//	this.globalAmberRed[which] = booleans;


/*
		this.setView
		(
			() =>
			{
				if (which === 1 && this.combo[1].setVisibility(false)) this.updateWidthAndHeightImmediately(); // We only hide the bottom combo.
//				this.refreshInformationFor(this.currentView.id, this.currentView.table.getStats(), which);
				this.refreshInformation(); // Because we removed filters, etc. so the whole table might have changed
				
// onsole.log("Post deSelectColumn");
				
			}
		);
		
*/
		
	} // deSelectColumn



	findDefaultTypeToSelectAgainst(which, colId, after)
	{
		this.currentView.table.getColumnVector // We need to obtain the correct vector.
		(
			colId,
			vector =>
			{
				this.selectedColumnVector[which] = vector;
				const stats = vector.getStats(); // We need the stats here to determine what the dominant type (Aspect) is.
				if (this.tm[which] !== null) this.tm[which].informTypeMetrics2(this.typeSelected[which]);

				// Auto selection
				const n = stats === null ? 0 : stats.length;
				let max = 0;
				let maxPos = -1;
				// Starting past uniqueness and populated
				for(let i = 2; i < n; i++) if (stats[i][2] > max)
				{
					max = stats[i][2];
					maxPos = i;
				}
				after(maxPos);
			}
		);
	
	} // findDefaultTypeToSelectAgainst



	/** When / if we do row analysis, this function can be generalised - it wouldn't take much.
	  * which which selection, ie, 0: first or 1: second
	  * colId the id of the column to analyse
	  */
	selectColumn(which, colId) // colourSetter, start, limit
	{
		//	When selecting a column:
		//	- Any other column (on the same combo is deselected)
		//	  - If there was any filter in place on the previously selected column, the filter is discarded (But a filter on the other combo, if any, is kept)
		//	- The Type Metrics data (left in combo) is reloaded with the selected columns metrics
		//	- The Distribution graph is automatically displayed for the most numerous type (Ie, if there are more strings than anything else their distribution is shown)
		//	- The Type Census metrics are shown (right in combo)
		//	- If the combo was toggled off, it will be toggled on (page redraw ensues).


// onsole.log("ASD: select Column 1");

		// Important - selecting a new column removes any filtering on any previously selected column.
		this.resetFilter(which);

// onsole.log("ASD: select Column 2");

		// For our selection doesn't magically reappear like magic without the filtering that goes with it.
		this.resetArSelection(which);

// onsole.log("ASD: select Column 3");

		// So consequently it takes away the distribution -TODO: check this is necessary
		this.resetDistributionGraph(which);

// onsole.log("ASD: select Column 4");

		
		// Remove any selected type and make sure any new selected type is taken into account...
		
		
		
		this.findDefaultTypeToSelectAgainst
		(
			which,
			colId,
			(t) =>
			{
			
				this.tm[which].doSelection
				(
					t, 
					() =>
					{
// onsole.log("Do Selection POST 1");
					
						if (t !== -1)
						{
// onsole.log("Do Selection POST 2 (t not -1)");
						
							const rr = InvokeAfter.forRadarRefresher(this, 2);

							for (let u = 0; u < 2; u++) this.heatmaps[u].informOfSnapshotId
							(
								this.currentView.id,
								u === which ? colId : this.getSelectedColIdFor(u),
								convertUITypeNumberToBackendTypeNameForHeatmap(this.typeSelected[u]),
								() => rr.runIfReady()
							); // C'est bien mais les ascenseurs par contre... 
						}
					}		
				);
			
			}
		);

	} // selectColumn








	resetFilter(which) { this.filterValues[which] = null; }


	// Also invoked by DistributionGraph
	removeFilter(which, after)
	{
		this.resetFilter(which);
		this.setView
		(
			() =>
			{
				this.refreshInformation();
				if (after) after();
			}
		);
	} // removeFilter



	filterOnColumn(which, distributionFilterType, valueFrom, valueTo) 
	{
		this.filterValues[which] = [valueFrom, valueTo];
		this.distributionFilterTypes[which] = distributionFilterType;

// console .log("FILTER: " + this.typeSelected[which]);		
		
		this.setView(() => this.refreshInformation());
	} // filterOnColumn



	/** Call this to resize the components.
	  * Very tricky to get right.
	  */
	updateWidthAndHeight()
	{
		// Debounce
		if (this.timer) clearTimeout(this.timer)
		setTimeout
		(
			() =>
			{
				this.timer = null;
				this.updateWidthAndHeightImmediately();
			},
			750
		);
	} // updateWidthAndHeight


	updateWidthAndHeightImmediately(postOp)
	{
	
	
// console .log("updateWidthAndHeightImmediately");
	
		const params = this.computeDisplayParams();
		for(let i = 0; i < 2; i++)
		{
			if (this.distriGraph[i] !== null) this.distriGraph[i].resize(params);
			if (this.tm[i]) this.tm[i].resize(params);
//			if (this.censi[i]) this.censi[i].resize(params);
			if (this.heatmaps[i]) this.heatmaps[i].resize(params);
			if (this.dataViewer) this.dataViewer.resize(params, postOp);
		}
	} // updateWidthAndHeight


	// Computing sizes for our components
	computeDisplayParams()
	{
		const aw = window.innerWidth;
		const ah = window.innerHeight;

		const usedByTypeMetricsAndTypeCensus = AqaTypeMetrics.width + AqaHeatMapForDataViewer.getWidth() + 40; // some padding
		const leftToPlayWidth = aw - usedByTypeMetricsAndTypeCensus;
		
		let dgWidth, dgFloat, tmFloat, cmFloat;

//		let width;
		if (leftToPlayWidth > 150) // arbitrarilly chosen
		{
			dgWidth = leftToPlayWidth - DistributionGraph.LEFT_MARGIN - DistributionGraph.RIGHT_MARGIN - 4;
			dgFloat = "left";
			tmFloat = "left";
			cmFloat = "right";
		}
		else
		{
			dgWidth = AqaTypeMetrics.width; // At least these two will be aligned
			dgFloat = "none";
			tmFloat = "none";
			cmFloat = "none";
		}

		const ret =
		{
			aw,
			ah,
			dgWidth,
			dgFloat,
			tmFloat,
			cmFloat,
			topComboShowing: this.combo[0] === null ? false : this.combo[0].getVisibility(),
			bottomComboShowing: this.combo[1] === null ? false : this.combo[1].getVisibility()
		};

		return ret;
	} // computeDisplayParams



	/** Generate the filters reflecting the user's choices
	  * On decompose notre filtre en ses sous-filtres constituants, de maniere a ce qu'ils puissent etre appliques en serie.
	  */
	makeFilters()
	{

		/*
		orientation,
		type,
		position,
		valueFrom,
		valueTo,
		onlyReds,
		onlyAmbers
		*/

		const filters = [];

		// Note: as of 2021.07 ALL our filtering and criterias are on columns.
		const orientation = "column";

		let vector;
		let columnColourFilters;
		
		for(let i = 0; i < 2; i++) // Top and bottom racks
		{
		
			const type = convertUITypeNumberToBackendTypeName(this.typeSelected[i], false);

			if ((vector = this.selectedColumnVector[i]) !== null)
			{
				const position = vector.data.position;
				// if (value is selected) create value filter
				if (type !== null && this.filterValues[i] !== null) filters.push
				(
					new Filter
					(
						{
							orientation,
							type,
							position,
							valueFrom:this.filterValues[i][0],
							valueTo:this.filterValues[i][1],
							subType:this.distributionFilterTypes[i]
						}
					)
				);

				/*
				// COLOUR INTERSECTION
				// if (amber is selected) create amber column filter
				if (this.ragColumnSelectors[i][1]) filters.push(new Filter({orientation, BACKEND_TYPE_OVERALL, position, onlyAmbers:true}));
				// if (red is selected) create red column filter
				if (this.ragColumnSelectors[i][2]) filters.push(new Filter({orientation, BACKEND_TYPE_OVERALL, position, onlyReds:true}));
				*/
				
				
				// COLOUR UNION
				columnColourFilters = {};
				if (this.ragColumnSelectors[i][1]) columnColourFilters.onlyAmbers = true;
				if (this.ragColumnSelectors[i][2]) columnColourFilters.onlyReds = true;
				if (Object.keys(columnColourFilters).length > 0)
				{
					columnColourFilters.orientation = orientation;
					columnColourFilters.type = BACKEND_TYPE_OVERALL;
					columnColourFilters.position = position;
					filters.push(new Filter(columnColourFilters));
				}

				
				
			}


			/*
			// COLOUR INTERSECTION
			if (this.globalAmberRed[i][0]) filters.push(new Filter({orientation, type, onlyAmbers:true}));
			if (this.globalAmberRed[i][1]) filters.push(new Filter({orientation, type, onlyReds:true}));
			*/


			// COLOUR UNION - we keep the colours together. Note that the 'only' terminology is kind of misleading here.
			const colourFilterParams = {};
			if (this.globalAmberRed[i][0]) colourFilterParams.onlyAmbers = true;
			if (this.globalAmberRed[i][1]) colourFilterParams.onlyReds = true;

/*
if (i === 0)
{
	console .log("========> GLOBAL COLOURS!!");
	console .log(AqaComponent.prettifyJson(this.globalAmberRed[0]));
}*/	
			if (Object.keys(colourFilterParams).length > 0)
			{
				colourFilterParams.orientation = orientation;
				colourFilterParams.type = type;
				filters.push(new Filter(colourFilterParams));
			}

		}


// Very useful debug! Keep.
// console .log("ASD: FILTERS:");
// console .log(AqaComponent.prettifyJson(filters));


		return filters;

	} // makeFilters





// ERROR 1: The refresh of the graph takes its data from the displayed table.
//            It's wrong: it needs to come from the mother snapshot, because it doesn't change!!!

	handleRefresh(callback)
	{
		// We need to clear all of them.
		SnapshotView.clearCacheOfFilteredViews(this.snapshotViewCache);
//		this.resetColourMapCache();
		
		this.distributionCache = {};
		this.colourMapCache = {};
		this.heatMapCache = {};
		this.scoresColoursCache = {};
		this.vqdCache = {};

		const filters = this.makeFilters();
//		SnapshotView.invalidate(this.props.snapshotId, this.snapshotViewCache, filters);

//console .log("DOING REFRESH NOW!!!!!!!!!!!!!!!!!");

		const motherView = this.snapshotViewCache[0];
		const motherViewId = motherView.id;


		this.doSetView
		(
			filters,
			() =>
			{
			
				this.getScoreWithViewId
				(
					motherViewId,
					-1,
					() =>
					{
						if (callback) callback();

//						const stats = motherView.table.getStats();
						

						const needsInformationRefresh = [true, true];
						for (let i = 0; i < 2; i++)
						{
							const vector = this.selectedColumnVector[i];
							//const hasColSelection = this.selectedColumnVector[i] !== null; // The one as per before

							if (vector !== null)
							{
								needsInformationRefresh[i] = false;
								const position = vector.data.position; // This is still valid
							
								motherView.table.getColumnVector // We need to obtain the correct vector - Why reload? Because if there are filters on colours... this is a brand new table...
								(
									position,
									vector =>
									{

										this.selectedColumnVector[i] = vector;

										let typeNumber = this.typeSelected[i];
										if(typeNumber === -1) typeNumber = 3;
										const backendTypeName = convertUITypeNumberToBackendTypeName(typeNumber, true);

										const distribution = vector.getDistribution2(backendTypeName);

										const distributionColoursId = this.makeDistributionColoursIdWithViewId(motherViewId, position); // `${this.currentView.id}_column_${position}`;
										
										let dateFormat = null;
										if (BACKEND_TYPE_DATE === backendTypeName) dateFormat = vector.getDateFormat();
										
									
										AqaComponent.backend.getDistributionColoursUsingGET
										(
											distributionColoursId,
											(error, distributionColours, response) =>
											{

												if (error) console.error("Distribution Colours retrival failed: " + error);
												else this.distributionCache[distributionColoursId] = distributionColours;



// Penso che l'abbiamo gia quello VQD quando veniamo dallo VQD Editor...

												this.vqdForColumn
												(
													position,
													(vqd) =>
													{
														this.vqdCache[position] = vqd;
														this.distriGraph[i].informDistributionGraph(distribution, distributionColours, vqd, this.distributionFilterTypes[i], backendTypeName, dateFormat);
														this.refreshInformationFor2
														(
															motherViewId,  // this.currentView.id,
//															stats,
															i
														);

													}
												);

											}
										);
									}
								);


							}

						}
						
						if (needsInformationRefresh[0] || needsInformationRefresh[1])
						{

							for(let i = 0; i < 2; i++)
							{
								if (needsInformationRefresh[i]) this.refreshInformationFor2(motherViewId, i); // , stats
							}
						}

					}
				);
				
			} // ...

		);

	} // handleRefresh



	/** The snapshot we want to see is the result of the user's actions
	  * The snapshot we want to see is based on the filters selected
	  * Snapshot + Filter(0/s) = SnapshotView
	  * return null if we haven't changed the View. // Don't think so Teebo 20220514
	  */
	setView(callback)
	{

// onsole.log("ASD: SETTING VIEW");

		const filters = this.makeFilters();

// onsole.log("filters: ");
// onsole.log(filters);


		this.doSetView(filters, callback)

	} // setView
	
/*
Pass the score in the callback
Invoke the postop synchronously after call to dataViewier init
*/
	
	doSetView(filters, callback)
	{

// onsole.log("ASD: SV");

// console .log(AqaComponent.prettifyJson(filters));

		this.waitCursor();

		SnapshotView.find
		(
			this.props.snapshotId,
			this.snapshotViewCache,
			filters,
			newView =>
			{
// onsole.log("ASD: SV: doing new view");

				this.normalCursor();

// console .log("ASD SV: nvs" + newView.serial + " cvs: " + (this.currentView === null ? "null view" : this.currentView.serial));

				if (this.currentView === null || newView.serial !== this.currentView.serial)
				{

// onsole.log("ASD SV: New view IS NEW!");


					this.currentView = newView;
					const numberOfColumns = this.currentView.table.data.numberOfColumns;
					const numberOfRows = this.currentView.getNumberOfRows(); // Adjusted for presence of headers or not.
					//const stati = this.currentView.stati;
					

					const updater = () =>
					{
						this.getScore
						(
							-1,
							score =>
							{
								// [0] means rows
								// [1] would be columns if we had row based rules

								const redAmberRowCounts = [];
								if
								(
									score.numberOfOrthogonalVectorsWithErrors
								&&
									score.numberOfOrthogonalVectorsWithErrors[0]
								&&
									score.numberOfOrthogonalVectorsWithErrors[0].length >= 8 // Was 6 but now we want Native ones too - 20230127
								)
								{
								
									for(const tx of FE_TO_BE_TYPE_INDEX_TRANSLATION) redAmberRowCounts.push(score.numberOfOrthogonalVectorsWithErrors[0][tx]);

									// Top and bottom									
									for(let i = 0; i < 2; i++) if (this.tm[i]) this.tm[i].setRowErrorCounts(redAmberRowCounts);
								}

// onsole.log("ASD: DV init");

// if coming from a refresh we might need to reload the colour maps!!

								this.dataViewer.init
								(
									score,
									this.motherHeaders,
									numberOfRows,
									numberOfColumns,
									newView.stati // Row stati
									// callbak
								);
								if (callback) callback(score);

							}
						);


					};

					// should we count the header in there or not?
					this.header.setState({dimensions: `${AqaComponent.formatBigNumber(numberOfColumns)} x ${AqaComponent.formatBigNumber(this.currentView.table.data.numberOfRows)}`});

					if (this.motherHeaders === null && filters.length === 0)
					{
						// We're not filtered here MEANING we are the mother view!!!!
						if (this.currentView.hasHorizontalHeader())
						{
							// The table's first row is a header
							// Get header row - we keep the same header for all the filtered views

							// Extra step: Get the headers.
							this.currentView.loadHeaders(headers => { this.motherHeaders = headers; updater(); });
							return; // important.
						}
						else
						{
							// Not derived, no headers, we generate our own.
							this.motherHeaders = Array(numberOfColumns);
							for(let i = 0; i < numberOfColumns; i++) this.motherHeaders[i] = AqaSnapshotDetail.alphabase(i);

						}
					}
					updater();
				}
				else
				{
				
// onsole.log("ASD SV: New view AIN'T NEW!");				
				
					if (callback) callback();
				}
			}
		);
		
		

	} // doSetView

	getTypeMap = () =>
	{
		AqaComponent.snapshotBackend.getTypesMapUsingGET
		(
			this.currentView.id,
			(error, data, response) =>
			{
				if (error) alert("Error getting the Types Map");
				else this.setState({typeMap:response.text});
			}	
		);	

	
	}; // getTypeMap


	componentDidMount()
	{
		this.setState({filename:this.props.dataFromSnapshot.label});
		window.addEventListener('resize', this.resizer);

/*
console .log("PROPS:");
// console .log(AqaComponent.prettifyJson(this.props)); // Do this if you want to explode your browser, the electrical grid, the local nuclear power station, create a tear in the space time continuum and get the universe to collapse onto itself.
for (const [key, value] of Object.entries(this.props)) if (key.substring(0, 7)==="current") console .log(`${key}: ${value}`);

console .log("isAmber: " + this.props.isAmber);
console .log("isRed: " + this.props.isRed);
*/

// console .log("DID MOUNT");



		// The code below is precisley orchestrated to make REACT work **properly**.
		// Don't modify. And if you do, don't go crying to mummy because you will have been warned.
		this.setView
		(
		
		

		
			(scoreData) =>
			{
				this.updateWidthAndHeightImmediately
				(
					() =>
					{
					
	// console .log("I DID mount!!!");				
					

						// Several operations must happen in sequence especially if some heatmap coordinates have been given to us.
						// ... that's why we have a couple of invokeAfters.

						let aspect = this.props.currentAspect;
						let column = this.props.currentColumn;



						if (aspect === "populated") aspect = UI_TYPE_NUMBER_TO_BACKEND_TYPE_NAME[1]; // populated... population 2022.02.09 can't remember what it should be... too late now.


						let tx, txs;
						for(tx = 0; tx < 5; tx++) if (UI_TYPE_NUMBER_TO_BACKEND_TYPE_NAME[tx] === aspect) break;
						
						if (tx === 5)
						{
							tx = -1;
							txs = BACKEND_TYPE_OVERALL;
						}
						else txs = UI_TYPE_NUMBER_TO_BACKEND_TYPE_NAME[tx];
						this.typeSelected[0] = tx;

	// this.dataViewer.informOfAspect(which, typeNumber);

						// Do we have a number of red/amber selection?
						const doFilterByColour = (!this.props.currentHeatMap && (this.props.isAmber || this.props.isRed) && tx !== -1);

						let i, numberOfItmPostoPs;
						for(i = 0, numberOfItmPostoPs = 0; i < 2; i++) if (this.tm[i]) numberOfItmPostoPs++;

						const itmPostOp = new InvokeAfter
						(
							numberOfItmPostoPs,
							this.props.currentHeatMap
							?
								() =>
								{
									this.heatmapClick(-1, this.props.currentHeatMap[1], this.props.currentHeatMap[3], this.props.currentHeatMap[2], this.props.currentHeatMap[4]);
								}
							:
								(
									doFilterByColour
									?
										() => this.tm[0].staters[tx].filterForSelectedColours
										(
											this.props.isAmber,
											this.props.isRed,
											() => this.dataViewer.informOfAspect(0, tx)
										) // Si ce n'est toi, c'est donc ton frere!
									:
										() => 0
								)
						);

						const hmPostOp = new InvokeAfter
						(
							2,
							() =>
							{ 
								this.dataViewer.adjustRadar();
//								for(let u = 0; u < 2; u++) if (this.tm[u] !== null) this.tm[u].informTypeMetrics(this.typeSelected[u], this.currentView.table.getStats(), () => itmPostOp.runIfReady());

								if (numberOfItmPostoPs > 0) for(let u = 0; u < 2; u++) if (this.tm[u] !== null) this.tm[u].informTypeMetrics2(this.typeSelected[u], () => itmPostOp.runIfReady());
								else itmPostOp.run();
							}
						);

						for(i = 0; i < 2; i++)
						{
							this.heatmaps[i].informOfSnapshotId(this.currentView.id, -1, i === 0 ? txs : BACKEND_TYPE_OVERALL, () => hmPostOp.runIfReady());
							// if (scoreData) this.tm[i].informOfRedAmberChanges(-1); Ici - not cool! We will be getting score data here left right and center. We should have the score. If not, no call.

							if (scoreData && this.tm[i])	this.tm[i].informOfRedAmberData(scoreData);
						}


						if(column !=="" && column !== undefined && column !== null){
							let subComponents = this.dataViewer.getSubComponents().filter((item)=>{return item.props.col === column && item.props.which===0;});
							if(subComponents.length>0){
								let subComponent = subComponents[0];
								subComponent.handleAutoClick();
								//

								//this.selectColumn(0,column);
								if(this.props.isAmber) subComponent.columnScore.selectColor(0);
								if(this.props.isRed) subComponent.columnScore.selectColor(1);
								//this.columnRagSelect(0,column,this.props.isAmber,this.props.isRed);
								//this.selectType(0,tx)

							}
						}

						
					} // all filtering
				)
			}

		);

	} // componentDidMount

	componentWillUnmount()
	{
		window.removeEventListener('resize', this.resizer);
	} // componentWillUnmount

	
	
	
	


	render()
	{
		const displayParameters = this.computeDisplayParams();
		const [tdw, tdh] = TraditionalTable.computeDimensions(this.computeDisplayParams());

		return (
			<div className={"source-detail-masterservant"}>

				<AqaSnapShotHeader
					parent={this}
					handleHome={() => this.handleHome()}
					sourceName={this.props.dataFromSource.name}
					label={this.props.dataFromSnapshot.label}
					dimensions={this.state.dimensions}
				/>				

				{/* normal content */}

				<div style={{backgroundColor: "#F7F8F9"}}>

					<div style={{margin: "0px", padding: "5px"}}>
						<AqaSnapshotSummaryDistributionCensusCombo
							key={"topCombo"}
							parent={this}
							controller={this}
							
							visible={true}
							position={0}

							distributionGraphWidth={displayParameters.dgWitdh}
							distributionGraphFloat={displayParameters.dgFloat}
							typeMetricsFloat = {displayParameters.tmFloat}
							censusMetricsFloat = {displayParameters.cmFloat}

						/>
					</div>

					<AqaRules
						dataFromParent={this}
						dataFromSource={this.props.dataFromSource}
						// controller={this.props.dataFromParent}
						controller={this}
						dataForAspect={this.props.dataForAspect}
						showEditor={false}
						dataFromSnapshot={this.props.dataFromSnapshot}
						suppress={true}
					/>
					
					<div style={{padding:"0 5px"}}>
						<TraditionalTable
							parent={this}
							controller={this}
							key={"ET" + this.serial}
							
							width={tdw}
							height={tdh}
						/>
					</div>
						
					

					{/* No, there is NO combo if there are no stats for it!!! */ }
					{/* The combo MUST MANAGE ITS OWN toggle state! */ }
					<div style={{margin: "0px", padding: "5px"}}>

						<AqaSnapshotSummaryDistributionCensusCombo
							key={"bottomCombo"}
							parent={this}
							controller={this}

							visible={false}
							position={1}

							distributionGraphWidth={displayParameters.witdh}
							distributionGraphFloat={displayParameters.float}
							typeMetricsFloat = {displayParameters.tmFloat}
							censusMetricsFloat = {displayParameters.cmFloat}
						/>

					</div>
				</div>
				
{/* Debug */}				
{/*
<div onClick={this.getTypeMap}>type map</div>
<pre>
{this.state.typeMap}
</pre>
*/}			
			</div>

		);
	} // render

} ////


/* Click and double clicks
https://stackoverflow.com/questions/25777826/onclick-works-but-ondoubleclick-is-ignored-on-react-component
In conclusion, it's not advisable to use double clicks here...
*/


/* Right clicks
   https://stackoverflow.com/questions/2405771/is-right-click-a-javascript-event
*/

/*
Getting length of string
https://blog.mastykarz.nl/measuring-the-length-of-a-string-in-pixels-using-javascript/

*/




// <div style={{margin: "0px", padding: "5px"}}>
// <Typography variant="inherit" color="inherit" style={{textAlign: "left", fontSize: "18px", color: "#006", margin: 0}}>

// </Typography>
// </div>

/*

https://developer.mozilla.org/en-US/docs/Web/API/Blob
https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Uint8Array?retiredLocale=it
https://riptutorial.com/javascript/example/1390/converting-between-blobs-and-arraybuffers
https://www.webkitx.com/doc/light/Working%20with%20Blob%20and%20Uint8Array.html

blob.arrayBuffer().then(arrayBuffer => 
                                        WriteUint8ArrayToFile("test2.xlsx", new Uint8Array(arrayBuffer)));  



*/







/*


Heatmap: 		Heatmap.informOfSnapshotId(snapshotId, colNumber, type, callback)

Radar:   		Heatmap.setRadarPosition(xPos, xSpan, xScale, yPos, ySpan, yScale)
					From: Traditional Table 
			
Distribution:	DistributionGraph.in formDistributionGraph(distribution, distributionColours, sortType, typeName)
					From: AqaSnapshotDetail x 5


Cell colours:	Provided by AqaSnapshotDetail with cell data

					AqaSnapshotDetail. scrollVertically -> AqaSnapshotDetail.pumpRows -> receiveNewContent
					AqaSnapshotDetail. changeRagType^ VSlider^ Setup^
					
					Note availability of: changeRagType(newType) to change the rag type to show
					
Type Counts:	AqaSnapshotDetail x 3 -> AqaTypeMetrics.inform TypeMetrics(stats)


// if (this.tm[which] !== null) this.tm[which].inform TypeMetrics(stats);

RA counts in cols:	Not implemented yet.



		const v = this.selectedColumnVector[which];
		
		// TODO (maybe) specify a caching boolean here, true if this.currentView.id === this.props.snapshotId
		// BUT it's trickier than you'd think...
		this.heatmaps[which].informOfSnapshotId(this.currentView.id, v === null ? -1 : v.data.position, convertUITypeNumberToBackendTypeNameForHeatmap(this.typeSelected[which]));


*/



/*						
console .log("Preparing worker");
							const colourMapWorker = new WorkerBuilder("row-counter");

							colourMapWorker.onmessage = message =>
							{
									message = message.data;

console .log("Message received!!");

									if (message.ticketNumber !== this.colourMapWorkerTicket)
									{
console .log("row colour counting is out of whack so not acting on result");
									}

console .log("COLOUR ROW COUNTER:");
console .log(AqaComponent.prettifyJson(message));

									if (message.ticketNumber === this.colourMapWorkerTicket)
									{
console .log("row colour counting is in sync");
									}

									// Either way
									colourMapWorker.terminate();


							};

console .log("Sending job to worker");


// TODO Uncomment when developing row counters!!!!
//							colourMapWorker.postMessage({colourMap, ticketNumber:this.colourMapWorkerTicket});
*/







