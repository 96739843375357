/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';

import Grid           from '@material-ui/core/Grid';
import HtmlTooltip    from "@material-ui/core/Tooltip";
import Typography     from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton     from "@material-ui/core/IconButton";

import AqaComponent from "../shared/aqacomponent/AqaComponent";

import {ReactComponent as LoadingIcon} from "../../images/grid.svg"

import AqaSnapshotDelete  from "../sourcedetail/AqaSnapshotDelete";
import AqaSnapshotGetLink from "../sourcedetail/AqaSnapshotGetLink";
//import AqaNewSnapshot     from "../sourcedetail/AqaNewSnapshot";

//import AqaRules      from "../sourcedetail/rules/AqaRules";
//import AqaTableRules from "./rules/AqaTableRules";

//import {ReactComponent as NewSourceIcon} from "../../images/aqa-icons/AQA-Delete.svg";

import {ReactComponent as CheckIcon} from "../../images/aqa-icons/CheckOutline2.svg";
//import {ReactComponent as XMarkIcon} from "../../images/aqa-icons/XMarkOutline2.svg";
import {ReactComponent as HelpIcon}  from "../../images/aqa-icons/HelpOutline2.svg";
//import {ReactComponent as SnapshotIcon} from "../../images/aqa-icons/AQA-Snapshot - v2.svg";
//import {ReactComponent as ExportIcon} from "../../images/aqa-icons/Export Data.svg";
//import {ReactComponent as PublishIcon} from "../../images/aqa-icons/Aqa Publish.svg";
import moment from "moment";
import DialogTitle from "@material-ui/core/DialogTitle";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";
import DialogContent from "@material-ui/core/DialogContent";
import {Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";



const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaLibraryMainHeader extends AqaComponent {
    constructor(props) {

        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            secondary: true,
            redirect: false,
            currentSnapshot:"allsources",
            snapshotsWithOutScores:[],
            snapshotsWithScores:[],
            snapshots: [],
            showRules:true,
            showShare:false,
            isPublish:false,
            showPublish:false,
            isEditPublish:false,
            showUnPublish:false,
            isRepublish:false,
            scope:"PRIVATE",
            title:"",
            description:"",
            selectedAccounts:[],
            accounts:[],
            qd:null,
            publicationId:"",
            defaultAccountId:"00000000000000000000000000000000",
            defaultAccountUUID:"00000000-0000-0000-0000-000000000000",
            authCode:props.parent.props.authCode,
            //accountId:props.parent.props.dataFromRoot.state.accountId,
            updateRules:false,
            confirmMessage:false,
            confirmHeader:"Publish Successful",
            confirmationContent:"This quality definiton is now published successfully",
            lastPublished:""
        };
        this.handleSnapshots=this.handleSnapshots.bind(this);
        this.handleSnapshotApprove=this.handleSnapshotApprove.bind(this);
        this.handleSnapshotReject=this.handleSnapshotReject.bind(this);
        this.handleSnapshotReset=this.handleSnapshotReset.bind(this);
        this.handleSnapshotHistory=this.handleSnapshotHistory.bind(this);
    }

    handleSnapshotHistory(active){
        this.props.parent.handleSnapshotHistory(active);
    }

    findUsageStat = (d) =>{
        let usage = 0;
        if(this.state.statement !== undefined) {
            let blockSize = this.state.statement.blockSize;
            let activities = this.state.statement.activities;
            for(let i=0;i<activities.length;i++){
                let activity = activities[i][`sourceActivity`];
                if(activity.sourceId === d){
                    usage = (activity.numberOfCumulatedRows/blockSize).toFixed(2);

                }
            }
        }
        return usage;}

    handleAccountDetails(){
//        var accountId=this.props.dataFromRoot.props.parent.state.accountId;
        var authCode = this.props.parent.props.authCode;
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    this.setState({accountObj:accountObj});
                    if(authCode ==="A") this.handleStatement(accountObj);
                }
            }
        );
    }

    handleStatement(accountObj){
        AqaComponent.billingBackend.getCurrentStatementUsingGET(
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the plans from the server was encountered.",
                    "AqaAccountOverview.handleAllPlans, backend call: " + error,this);
                else {
                    this.setState({statement:data});
                    let blocksAllocated = 0;
                    let blocksUsed = 0;
                    let blockSize = data.blockSize;
                    //let currency = accountObj.currencyCode;
                    //let planWithPrices = data.planWithPrices.prices[currency][0];
                    //if(planWithPrices !== null && planWithPrices !== undefined) blocksAllocated = planWithPrices.quantityUpTo;
                    for(let i=0;i<data.activities.length;i++){
                        let activity = data.activities[i].sourceActivity;
                        blocksUsed = blocksUsed + Math.floor(activity.numberOfCumulatedRows/blockSize);
                    }
                    this.setState({blocksAllocated:blocksAllocated,blocksUsed:blocksUsed});
                }
            }
        );
    }

    handleSnapshotApprove(e){
        var status="Approved";
        var snapshot=this.props.parent.state.selectedSnapshots[0];
        AqaComponent.snapshotBackend.updateSnapshotStatusChangeUsingPOST(
            snapshot.id,
            status,
            (error, data, response) => {
                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                else {
                    this.props.parent.handleSnapshotSelectionReset();
                    this.props.parent.handleRefresh();
                }
            }
        )
    }

    handleSnapshotReset(e){
        var status="Pending";
        var snapshot=this.props.parent.state.selectedSnapshots[0];
        if(snapshot===null || snapshot === undefined) {
            this.setState({showShare:false});
            return;
        }
        AqaComponent.snapshotBackend.updateSnapshotStatusChangeUsingPOST(
            snapshot.id,
            status,
            (error, data, response) => {
                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshotReset, backend call: " + error,this);
                else {
                    this.props.parent.handleSnapshotSelectionReset();
                    this.props.parent.handleRefresh();
                }
            }
        )
    }
    handleSnapshotReject(e){
        var status="Rejected";
        var snapshot=this.props.parent.state.selectedSnapshots[0];
        AqaComponent.snapshotBackend.updateSnapshotStatusChangeUsingPOST(
            snapshot.id,
            status,
            (error, data, response) => {
                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshotReject, backend call: " + error,this);
                else {
                    //this.props.parent.handleSnapshotSelectionReset();
                    this.setState({showShare:true});
                    //this.props.parent.handleRefresh();
                }
            }
        )
    }

    handleSnapshots(sourceId){
        AqaComponent.backend.getSnapshotsForSourceUsingGET(
            sourceId,
            0,
            9999,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshots, backend call: " + error,this);
                else
                {
                    if(data.length>0){
                        this.setState({showRules:true});
                    }
                    else{
                        this.setState({showRules:false});
                    }
                }
            }
        );
    }

    handleAccounts = () =>{
        AqaComponent.backend.listAccountsUsingGET(
            (error, data, response) => {
                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                else {
                    this.setState({accounts:data});
                }
            }
        )
    }


    handleRadioChange = (event) =>{
        let {name,value} = event.target;
        if(name === "description") {if(value.length>10000){ this.setState({errorMessage:"Description cannot exceed be more than 10000 characters"}); return}}
        this.setState({[name]:value,errorMessage:""});
        if(name==="scope") this.setState({selectedAccounts:[]});
    }

    handleRuleCheckChange = (event) =>{
        const {name,checked} = event.target;
        this.setState({[name]:checked});
    }

    handleCheckChange = (event,item) =>{
        const {name,checked} = event.target;
        let selectedAccounts = this.state.selectedAccounts;
        if(checked){
            if(!selectedAccounts.includes(name)) selectedAccounts.push(name);
        }
        else{
            if(selectedAccounts.includes(name)) selectedAccounts= selectedAccounts.filter((d)=>{return d!==name;})
        }
        //alert(selectedAccounts.toString());
        this.setState({selectedAccounts:selectedAccounts});
    }

    handleClosePopup = (e) =>{
        this.setState({showPublish:false,showUnPublish:false});
    }

    handleFriendlyAccountsSharedWith = () =>{
        AqaComponent.marketBackend.getListOfAccountsSharedWithUsingGET(
            this.state.publicationId,
            (error, data, response) => {
                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                else {
                    if(data.success === true){

                        let selectedAccounts = [];
                        let scope = data.scope;
                        let title = data.title;
                        let lastPublished = data.publishTimestamp;
                        if(data.accounts.length>0){
                            for(let i=0;i<data.accounts.length;i++){
                                selectedAccounts.push(data.accounts[i].id)
                            }
                            if(scope==="PUBLIC" || scope === "PRIVATE") selectedAccounts=[];
                        }
                        this.setState({showUnPublish:true,isRepublish:true,title:title,scope:scope,lastPublished:lastPublished,selectedAccounts:selectedAccounts,errorMessage:"",updateRules:false});
                    }
                    else{

                    }
                }
            }
        );
    }

    handlePublish = (e,type) =>{
        if(type === "publish") {
            this.setState({showPublish: true,isEditPublish:false,title:"",scope:"",selectedAccounts:[],errorMessage:""});
        }
        else{
            //this.setState({showPublish: true,isEditPublish:true});
            this.setState({errorMessage:""},this.handleFriendlyAccountsSharedWith());
        }
    }

    handleUnPublish = (e,type) =>{
        if(type === "unpublish"){
            this.setState({showUnPublish:true,isRepublish:false,errorMessage:""});
        }
        else{
            this.setState({showUnPublish:true,isRepublish:true,errorMessage:""});
        }

    }

    handlePublishClosePopup = (e,type) =>{
        let source=this.props.dataFromParent;
        let {scope,title,selectedAccounts,description} = this.state;
        let errors=[];
        if(scope === null || scope === "") errors.push(" Scope");
        if((title === null || title === "")) errors.push(" Title");
        if((description === null || description === "" || description.length>10000)) errors.push(" Description");
        if(scope === "PROTECTED" && selectedAccounts.length===0 ) {
            errors.push(" Accounts");
        }
        if(scope === "PUBLIC" || scope === "PRIVATE") selectedAccounts=[];
        if(errors.length>0) {
            this.setState({errorMessage:errors.toString()+" are mandatory"});
            return;
        }
        let pr={scope:scope,title:title,description:description,accountsToShareWith:selectedAccounts};
        if(type === "publish") {
            AqaComponent.marketBackend.publishQualityDefinitionUsingPOST(
                source.id,
                pr,
                (error, data, response) => {
                    if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                        "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                    else {
                        //console.log(data);
                        if (data.success) {
                            this.setState({showPublish: false, showUnPublish: false, isPublish: true,publicationId:data.message});
                        } else {
                            this.setState({errorMessage: data.message});
                        }
                    }
                }
            );
        }
        else if(type ==="editpublish"){
            AqaComponent.marketBackend.updateQualityDefinitionFriendlyAccountIdsUsingPUT(
                this.state.publicationId,
                pr,
                (error, data, response) => {
                    if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                        "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                    else {
                        //console.log(data);
                        if (data.success) {
                            if(this.state.updateRules){
                                this.handleRepublish();
                            }
                            else {
                                this.setState({showPublish: false, showUnPublish: false, isPublish: true});
                            }
                        } else {
                            this.setState({errorMessage: data.message});
                        }
                    }
                }
            );
        }
        else {
            this.setState({showPublish: false, showUnPublish: false});
        }

    }


    handleRepublish = ()=>{
        let source=this.props.dataFromParent;
        AqaComponent.marketBackend.republishQualityDefinitionUsingPUT(
            source.id,
            (error, data, response) => {
                if (error) this.reportError("A problem unpublishing the aqd from the server was encountered.",
                    "AqaLibraryManager.handleUnPublishClosePopup, backend call: " + error,this);
                else {
                    if (data.success) {
                        //this.setState({accounts:data});
                        this.setState({showPublish: false, showUnPublish: false,confirmMessage:true});
                    } else {
                        this.setState({errorMessage: "Unable to perform this operation"});
                    }
                }
            }
        );
    }

    handleConfirmClosePopup =()=>{
        this.setState({confirmMessage:false});
    }

    handleExportAQD = (e) =>
    {
        let source=this.props.dataFromParent;
        let name = source.name !== null ? source.name :"My Source";
        let dt = moment();
        let timestamp = dt.format("MMM") +"_"+ dt.format("DD")+"_"+dt.format("yyyy")+"_"+dt.format("HH:mm");
        AqaComponent.backend.downloadQualityDefinitionUsingGET
        (
            source.id,
            (error, blob,response) =>
            {
                if (error) this.reportError("Sorry, an error occurred", "Deleting calculator error: " + error,this);
                else
                {
                    const url = window.URL.createObjectURL(new Blob([response.body]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', name+"_"+timestamp+`.aqd`);
                    // 3. Append to html page
                    document.body.appendChild(link);
                    // 4. Force download
                    link.click();
                    // 5. Clean up and remove the link
                    link.parentNode.removeChild(link);
                    /*if (!responseObject) alert("Sorry, an error occurred: " + responseObject);
                    else
                    {
                        console.log(responseObject);
                    }*/
                }
            }
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        //this.handleSnapshots(nextProps.dataFromGrid.id);
        this.setState({showRules:nextProps.parent.state.showRules});
        this.handleAccountDetails();
    }

    componentDidMount(){
        //this.handleSnapshots(this.props.dataFromGrid.id);
        //console.log(this.props.parent.props.dataFromRoot.props.parent);
        if(this.props.snapshotcount>0 && this.props.dataFromParent.example!==true){
            //this.handleQualityDefinition();
            this.handleAccounts();
            if(this.props.dataFromParent.publicationId === null || this.props.dataFromParent.publicationId === ""){
                this.setState({isPublish:false});
            }
            else{
                this.setState({isPublish:true});
            }
        }

    }

    render() {
        const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,errorMessage,selectedAccounts,accounts,confirmHeader,confirmationContent} = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <div className={classes.demo} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={10} sm={7} lg={11} xl={11}>
                            <Typography variant="title" color="inherit" align="left"
                                        style={{ fontSize: '1.5rem',
                                            padding: '0.1em 0.5em',
                                            fontFamily: 'Open Sans, sans-serif'}}>
                                Nothing to display
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} lg={1} xl={1}>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>

            </div>;
        } else if (!isLoaded) {
            return (<div className={"aqa-loader"}>
                <div className={"aqa-loading-icon"}>
                    <LoadingIcon fill={"#4cadc4"}/>
                </div>
            </div>);
        } else {
            return (
                <div style={{backgroundColor: "white",padding:0,borderBottom:"1px solid #DBDBDB",height:"56px"}}>
                    {(this.props.parent.state.selectedSnapshots.length===0)?(
                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                              style={{padding: 0, marginTop: 0}}>





                            <Grid item xs={2} sm={2} lg={2} xl={2}>
                                {/*(this.props.snapshotcount>=1)?
                                    (<AqaTableRules dataFromParent={this} dataFromSource={this.props.dataFromParent} controller={this.props.parent} dataForAspect={this.props.dataForDimensionAspect} showEditor={this.props.showDimensionEditor} dataFromSnapshot={this.props.dataFromSnapshot}/>):
                                    ('')*/}
                                {/*<Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: "0px"}}>
                                    <Grid item xs={3} sm={3} lg={3} xl={3}>
                                    </Grid>
                                    <Grid item xs={3} sm={3} lg={3} xl={3}>
                                    </Grid>
                                    <Grid item xs={3} sm={3} lg={3} xl={3}>
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            <Typography color="inherit" className="aqa-text-action">Usage Stats - 1k per block</Typography>

                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                            <Typography className="aqa-text-bold-12" variant="inherit" style={{textAlign:"right",paddingLeft:"4px",paddingRight:"4px"}}>
                                                Blocks Used:
                                            </Typography>
                                        </HtmlTooltip>
                                    </Grid>
                                    <Grid item xs={3} sm={3} lg={3} xl={3}>
                                        <Typography className="aqa-text-bold-14" variant="inherit" style={{textAlign:"right",paddingLeft:"4px",paddingRight:"4px"}}>
                                            {this.findUsageStat(this.props.dataFromGrid.id)}
                                        </Typography>
                                    </Grid>

                                </Grid>*/}
                            </Grid>

                            <Grid item xs={4} sm={4} lg={4} xl={4}>

                            </Grid>
                        </Grid>
                    ):(
                        (this.props.parent.state.selectedSnapshots.length===1)?(

                            ((this.props.parent.state.selectedSnapshots[0].status === "Approved")?
                                    (
                                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                              style={{padding: 0, marginTop: 0}}>

                                            <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"5px"}}>
                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                        <div>
                                                            <Typography color="inherit" className="aqa-text-action">Click to reset the status of Data Upload</Typography>
                                                        </div>
                                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                        <IconButton className="aqa-button" onClick={this.handleSnapshotReset.bind(this)} disabled={false} style={{padding:"4px"}}>

                                                            <div className="aqa-icon" >
                                                                <HelpIcon width="32px" />
                                                            </div>
                                                            <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                                                RESET REVIEW
                                                            </Typography>
                                                        </IconButton>
                                                    </HtmlTooltip>

                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                <div style={{background:"#FFFFFF 0% 0% no-repeat padding-box",marginTop:"1px",padding:0}}>
                                                    <AqaSnapshotDelete parent={this} dataFromGrid={this.props.dataFromGrid} dataFromParent={this.props.parent}/>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} sm={2} lg={2} xl={2}>

                                            </Grid>

                                            <Grid item xs={6} sm={6} lg={6} xl={6}>

                                            </Grid>
                                        </Grid>
                                    ):(
                                        this.props.parent.state.selectedSnapshots[0].status === "Rejected"?(
                                                (

                                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                          style={{padding: 0, marginTop: 0}}>

                                                        <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"8px"}}>
                                                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                    <div>
                                                                        <Typography color="inherit" className="aqa-text-action">Click to complete the review of the Data Upload</Typography>
                                                                    </div>
                                                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                    <IconButton className="aqa-button" onClick={this.handleSnapshotApprove.bind(this)} disabled={false} style={{padding:"4px"}}>

                                                                        <div className="aqa-icon" >
                                                                            <CheckIcon width="32px" />
                                                                        </div>
                                                                        <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                                                            COMPLETE REVIEW
                                                                        </Typography>
                                                                    </IconButton>
                                                                </HtmlTooltip>

                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                            <div style={{background:"#FFFFFF 0% 0% no-repeat padding-box",marginTop:"1px",padding:0}}>
                                                                <AqaSnapshotGetLink parent={this} dataFromGrid={this.props.dataFromGrid} dataFromParent={this.props.parent} dataForPopup={this.state.showShare}/>
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                            <div style={{background:"#FFFFFF 0% 0% no-repeat padding-box",marginTop:"1px",padding:0}}>
                                                                <AqaSnapshotDelete parent={this} dataFromGrid={this.props.dataFromGrid} dataFromParent={this.props.parent}/>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={2} sm={2} lg={2} xl={2}>

                                                        </Grid>

                                                        <Grid item xs={4} sm={4} lg={4} xl={4}>

                                                        </Grid>
                                                    </Grid>
                                                )
                                            ):
                                            (
                                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                      style={{padding: 0, marginTop: 0}}>

                                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"8px"}}>
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action">Click to complete the review of the Data Upload</Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <IconButton className="aqa-button" onClick={this.handleSnapshotApprove.bind(this)} disabled={false} style={{padding:"4px"}}>

                                                                    <div className="aqa-icon" >
                                                                        <CheckIcon width="32px" />
                                                                    </div>
                                                                    <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                                                        COMPLETE REVIEW
                                                                    </Typography>
                                                                </IconButton>
                                                            </HtmlTooltip>

                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                        <div style={{background:"#FFFFFF 0% 0% no-repeat padding-box",marginTop:"1px",padding:0}}>
                                                            <AqaSnapshotGetLink parent={this} dataFromGrid={this.props.dataFromGrid} dataFromParent={this.props.parent} dataForPopup={this.state.showShare}/>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                        <div style={{background:"#FFFFFF 0% 0% no-repeat padding-box",marginTop:"1px",padding:0}}>
                                                            <AqaSnapshotDelete parent={this} dataFromGrid={this.props.dataFromGrid} dataFromParent={this.props.parent}/>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} lg={2} xl={2}>

                                                    </Grid>
                                                    <Grid item xs={4} sm={4} lg={4} xl={4}>

                                                    </Grid>
                                                </Grid>
                                            )
                                    )
                            )
                        ):(

                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={2} sm={2} lg={2} xl={2}>
                                    <div style={{background:"#FFFFFF 0% 0% no-repeat padding-box",marginTop:"1px",padding:0}}>
                                        <AqaSnapshotDelete parent={this} dataFromGrid={this.props.dataFromGrid} dataFromParent={this.props.parent}/>
                                    </div>
                                </Grid>

                                <Grid item xs={2} sm={2} lg={2} xl={2}>

                                </Grid>
                                <Grid item xs={8} sm={8} lg={8} xl={8}>

                                </Grid>
                            </Grid>
                        )
                    )}

                    <Dialog
                        open={this.state.showPublish}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                {this.state.isEditPublish?"Modify the published AQD":"Publish the Quality Definition"}
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={(e)=>this.handleClosePopup(e)} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"960px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"red",padding:"5px",paddingLeft:"20px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        Select scope
                                    </Typography>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",width:"100%"}}>
                                        <div style={{paddingLeft:"10px"}}>
                                            <span style={{float:"left",width:"100%"}}>
                                            <RadioGroup aria-label="scope" name="scope" value={this.state.scope} color="primary" onChange={this.handleRadioChange} style={{width:"auto"}} row>
                                                <FormControlLabel key={"PRIVATE"} value={"PRIVATE"} control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                 style={{padding:"0px" }}>
                                                        <span style={{float:"left",width:"200px"}}>{"PRIVATE"}</span>
                                                    </Typography>)
                                                } style={{width:"120px",margin:"0px"}} />
                                                <FormControlLabel key={"PUBLIC"} value={"PUBLIC"} control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                 style={{padding:"0px" }}>
                                                        <span style={{float:"left",width:"200px"}}>{"PUBLIC"}</span>
                                                    </Typography>)
                                                } style={{width:"120px",margin:"0px"}} />
                                                <FormControlLabel key={"PROTECTED"} value={"PROTECTED"} control={<Radio color="primary" style={{padding:"4px"}}/>} label={
                                                    (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                                 style={{padding:"0px" }}>
                                                        <span style={{float:"left",width:"200px"}}>{"PROTECTED"}</span>
                                                    </Typography>)
                                                } style={{width:"120px",margin:"0px"}} />

                                            </RadioGroup>
                                            </span>
                                            <span style={{float:"left",width:"70%",paddingTop:"10px"}}>

                                            </span>
                                            <span style={{float:"left",width:"10%"}}>

                                            </span>
                                        </div>
                                    </Typography>

                                </Grid>
                                {this.state.scope === "PROTECTED" ?
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontFamily:"Montserrat",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                            Share with:
                                        </Typography>
                                        <Typography variant="inherit" color="inherit" style={{
                                            textAlign: "left",
                                            fontSize: "0.8rem",
                                            color: "#006",
                                            padding: "0px",
                                            width: "100%",
                                            height:"200px",
                                            overflow:"auto"
                                        }}>
                                            <div style={{paddingLeft: "20px"}}>

                                                <>
                                                    <FormGroup row>
                                                        {accounts.map((item, index) =>
                                                            <FormControlLabel key={item.id}
                                                                              control={
                                                                                  <Checkbox
                                                                                      checked={selectedAccounts.includes(item.id)}
                                                                                      onChange={(e) => this.handleCheckChange(e, item)}
                                                                                      name={item.id}
                                                                                      color="primary"
                                                                                  />
                                                                              }
                                                                              label={(<Typography className={"aqa-text-bold-14"} variant="title" align="left"
                                                                                                  style={{padding:"0px" }}>
                                                                                  <span style={{}}>{item.name}</span>
                                                                              </Typography>)}

                                                            />
                                                        )

                                                        }
                                                    </FormGroup>

                                                </>
                                            </div>
                                        </Typography>
                                    </Grid> : ""
                                }

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontFamily:"Montserrat",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        Title of Quality Definition
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        <TextField
                                            id="title"
                                            name="title"
                                            label=""
                                            value={this.state.title}
                                            className={"aqa-text-action-10"}
                                            variant="outlined"
                                            margin="dense"
                                            type="text"
                                            multiline={false}
                                            rows={1}
                                            style={{ width: "97%",margin:0,padding:"3px"}}
                                            onChange={this.handleRadioChange}
                                            InputLabelProps={{
                                                style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"10px",textAlign:"right"},

                                            }}
                                            InputProps={{
                                                style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"18px",textAlign:"left"},
                                            }}
                                            inputProps={{
                                                style:{"padding":"5px 5px 5px 8px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"18px",textAlign:"left"},
                                            }}
                                        />
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontFamily:"Montserrat",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        Description
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        <TextField
                                            id="description"
                                            name="description"
                                            label=""
                                            value={this.state.description}
                                            className={"aqa-text-action-10"}
                                            variant="outlined"
                                            margin="dense"
                                            type="text"
                                            multiline={true}
                                            rows={5}
                                            style={{ width: "97%",margin:0,padding:"3px"}}
                                            onChange={this.handleRadioChange}
                                            InputLabelProps={{
                                                style:{"padding":"0px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"10px",textAlign:"right"},

                                            }}
                                            InputProps={{
                                                style:{"padding":"5px 0px 5px 8px","fontFamily":"Montserrat","fontSize":"14px",lineHeight:"18px",textAlign:"left"},
                                            }}
                                        />
                                    </Typography>

                                </Grid>


                                {this.state.isEditPublish ?
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography variant="inherit" color="inherit" style={{
                                            textAlign: "left",
                                            fontSize: "0.8rem",
                                            color: "#006",
                                            padding: "5px",
                                            paddingLeft: "30px"
                                        }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.updateRules}
                                                        onChange={(e) => this.handleRuleCheckChange(e)}
                                                        name={"updateRules"}
                                                        color="primary"
                                                        style={{padding: "4px"}}
                                                    />
                                                }
                                                label={(<Typography className={"aqa-text-action"} variant="title"
                                                                    align="left"
                                                                    style={{padding: "0px"}}>
                                                    <span style={{}}>{"Update Rules"}</span>
                                                </Typography>)}

                                            />
                                        </Typography>
                                    </Grid> : ""
                                }

                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",minWidth:window.pageXOffset,width:"960px"}}>

                                <Grid item xs={2} sm={2} lg={2} xl={2}>
                                    <Button onClick={(e)=>this.handleClosePopup(e)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: "-54px",
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Cancel
                                    </Button>


                                </Grid>

                                <Grid item xs={8} sm={8} lg={8} xl={8}>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} xl={2}>
                                    <Button  variant="contained"
                                             onClick={(e)=>this.handlePublishClosePopup(e,this.state.isEditPublish?"editpublish":"publish")}
                                             color="primary"
                                             align="left"
                                             className="aqa-action-button"
                                             style={{
                                                 marginTop: 8,
                                                 marginRight:"-20px",
                                                 color: '#4cadc4',
                                                 backgroundColor: 'white',
                                                 border: '1px solid #4cadc4',
                                                 fontSize: '0.7rem',
                                                 padding: '3px',
                                             }}>
                                        {this.state.isEditPublish?"Republish":"Publish"}
                                    </Button>



                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.showUnPublish}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                {this.state.isRepublish?"Publish":"Un-Publish"} the Quality Definition
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={(e)=>this.handleClosePopup(e)} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"660px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"red",padding:"5px",paddingLeft:"20px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        This quality definition will now be republished
                                    </Typography>

                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        Last Published: {this.state.lastPublished !== ""?moment(this.state.lastPublished).locale("en-USA").fromNow():""}
                                    </Typography>

                                </Grid>

                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",minWidth:window.pageXOffset,width:"660px"}}>

                                <Grid item xs={2} sm={2} lg={2} xl={2}>
                                    <Button onClick={(e)=>this.handleClosePopup(e)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: 0,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Cancel
                                    </Button>


                                </Grid>

                                <Grid item xs={8} sm={8} lg={8} xl={8}>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} xl={2}>
                                    <Button  variant="contained"
                                             onClick={(e)=>this.handleRepublish()}
                                             color="primary"
                                             align="left"
                                             className="aqa-action-button"
                                             style={{
                                                 marginTop: 8,
                                                 color: '#4cadc4',
                                                 backgroundColor: 'white',
                                                 border: '1px solid #4cadc4',
                                                 fontSize: '0.7rem',
                                                 padding: '3px',
                                             }}>
                                        {this.state.isRepublish?"Re-Publish":"Un-Publish"}
                                    </Button>



                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        open={this.state.confirmMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                {confirmHeader}
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={(e)=>this.handleConfirmClosePopup(e)} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:window.pageXOffset,width:"660px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"red",padding:"5px",paddingLeft:"20px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",paddingLeft:"20px"}}>
                                        {confirmationContent}
                                    </Typography>

                                </Grid>

                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",minWidth:window.pageXOffset,width:"660px"}}>

                                <Grid item xs={2} sm={2} lg={2} xl={2}>

                                </Grid>

                                <Grid item xs={8} sm={8} lg={8} xl={8}>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} xl={2}>
                                    <Button  variant="contained"
                                             onClick={(e)=>this.handleConfirmClosePopup(e)}
                                             color="primary"
                                             align="left"
                                             className="aqa-action-button"
                                             style={{
                                                 marginTop: 8,
                                                 color: '#4cadc4',
                                                 backgroundColor: 'white',
                                                 border: '1px solid #4cadc4',
                                                 fontSize: '0.7rem',
                                                 padding: '3px',
                                             }}>
                                        {"OK"}
                                    </Button>



                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>

                </div>
            );
        }
    }
}

AqaLibraryMainHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaLibraryMainHeader);
