/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';

import AqaComponent from "../shared/aqacomponent/AqaComponent"
import ColourAndOverrideMaps from "../../model/ColourAndOverrideMaps"


import Heatmap      from "../shared/heatmap/Heatmap"

import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox, DialogActions,
    FormControlLabel, LinearProgress,
    Table, TableBody, TableCell,
    TableHead, TableRow
} from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
//import AqaCircularProgressBar from "../shared/meter/AqaCircularProgressBar";
import moment from "moment"
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos"
import {ReactComponent as SourceIcon} from "../../images/aqa-icons/Sourcecol.svg"
import {ReactComponent as EventIcon} from "../../images/aqa-icons/Uploadcol.svg"
//import {ReactComponent as PersonIcon} from "../../images/aqa-icons/Usercol.svg";
//import AqaSparklineChart from "../shared/charts/AqaSparklineChart";
//import {ReactComponent as TrendDownIcon} from "../../images/aqa-icons/Trending Downcol.svg";
//import {ReactComponent as TrendUpIcon} from "../../images/aqa-icons/Trending Upcol.svg";
//import {ReactComponent as TrendNeutralIcon} from "../../images/aqa-icons/Flat Trendcol.svg";
// import AqaComponent from "../shared/aqacomponent/AqaComponent";
// import {SnapshotColumnsHeatmap} from "../../model/SnapshotColumnsHeatmaps";
import AqaGridIcon from "../../images/aqa-grid.png"
/*import {ReactComponent as HelpIcon} from "../../images/aqa-icons/HelpOutline.svg";
import {ReactComponent as CheckIcon} from "../../images/aqa-icons/CheckOutline.svg";
import {ReactComponent as XIcon} from "../../images/aqa-icons/XMarkOutline.svg";*/
import HtmlTooltip from "@material-ui/core/Tooltip"
import AqaTable from "../../model/AqaTable"
import {ReactComponent as LibraryIcon} from "../../images/aqa-icons/Aqa_Library.svg"
import Logo from "../../images/aqaversant-blue.png";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Paper, {PaperProps} from "@material-ui/core/Paper";
import Draggable from "react-draggable";

function PaperComponent(props: PaperProps) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const styles = theme => ({
    root:{
        margin: "8px",
    },
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaSourceGridRow extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            sources: [],
            isGrid: false,
            showCheckbox:false,
            isSelected:false,
            score: -1,
            lastScore: -1,
            lastScores:[],
            diff: "",
            rag:null,
            historicalValues:[
                ["Last", "Scores"],
                [1, Math.round(Math.random()*90)],
                [2, Math.round(Math.random()*20)],
                [3, Math.round(Math.random()*60)],
                [4, Math.round(Math.random()*98)],
                [5, Math.round(Math.random()*80)],
                [6, Math.round(Math.random()*98)],
            ],
            stats:[],
            reviewStats:[],
            healthStats:[],
//          columnmap:null,
				heatmapData:null,
            latestSnapshot:null,
            snapshotScores:[],
            numRows:16,
            numCols:16,
            reviewComplete:false,
            showReviewPop:false,
            showPreviewReport:false,
            previewReport:""
        };
        this.showRules=false;
        this.rowStats =[];
        this.handleSource = this.handleSource.bind(this);
        this.handleMouseOverIcon = this.handleMouseOverIcon.bind(this);
        this.handleMouseEnterIcon = this.handleMouseEnterIcon.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSnapshot = this.handleSnapshot.bind(this);
        this.handleSnapshots = this.handleSnapshots.bind(this);
        this.handleSnapshotScoreClick = this.handleSnapshotScoreClick.bind(this);
        this.handleGetRowsColumns = this.handleGetRowsColumns.bind(this);
    }

    waitCursor() { document.body.style.cursor = "wait"; }
    normalCursor() { document.body.style.cursor = ""; }

    handleReviewOpen = (e) =>{
        e.stopPropagation();
        if(this.state.reviewComplete) this.setState({showReviewPop:true});
    }

    handleReviewClose = (e) =>{
        e.stopPropagation();
        this.setState({showReviewPop:false,showPreviewReport:false});
    }

    handleReviewClosePop = (e) =>{
        e.stopPropagation();
        this.setState({showReviewPop:true,showPreviewReport:false});
    }

    handlePreviewReport = (e) =>{
        e.stopPropagation();
        this.waitCursor();
        if(!this.state.reviewComplete ){
            var customNotification = {
                title: "Cannot Share", titleIcon: "",
                message: "There are no Alerts reviewed as Must Fix to share",
                isCustom: false, type: "info",
                insert: "center", position: "center", autoClose: true, duration: 5000
            };
            this.removeAllNotifications();
            this.showNotification(null, customNotification);
        }
        else {
            this.setState({previewReport: "", showPreviewReport: false});
            var selectedSource = this.props.dataFromGrid;
            var selectedSnapshot = this.state.latestSnapshot;
            //var selectedSnapshotId = this.props.dataFromGrid.id;
            var to = this.props.dataFromRoot.props.dataFromMainFrame.props.parent.state.user.email;
            //let res = {nav: 2, fid: "", scid: selectedSource.id, snid: selectedSnapshot.id};
            //let reskey = base64.encode(JSON.stringify(res));
            var slr = {
                to: to,
                password: "",
                firstname: "",
                lastname: "",
                permissions: "V",
                urlLink: "res=link",
                message: "",
                resourceType: "Data Upload",
                sourceId: (selectedSource !== null ? selectedSource.id : ""),
                snapshotId: (selectedSnapshot !== null ? selectedSnapshot.id : ""),
                snapshotName: (selectedSnapshot !== null ? selectedSnapshot.label : ""),
                snapshotDate: (selectedSnapshot !== null ? moment(selectedSnapshot.dateCreated).format("YYYY/MM/DD HH:mm") : "")
            };
            AqaComponent.userBackend.shareLinkPreviewUsingPOST(
                slr,
                (error, data, response) => {
                    if (error) {
                        var responseMessage = JSON.parse(response.text);
                        if (responseMessage.message !== "" && response.status === 400) {
                            if (responseMessage.message === "No violations to share") {
                                this.setState({
                                    errorMessage: "Violations are not marked as Must Fix, Unable to share report",
                                    hasError: true,
                                    openConfirmMessage: true,
                                    openMessage: false
                                });
                            } else {
                                this.setState({errorMessage: "Email address already in use", hasError: true});
                            }
                        } else {
                            this.reportError("A problem posting the share link from the server was encountered.",
                                "AqaSnapshotGetLink.handleShareLink, backend call: " + error, this);
                        }
                    } else {
                        var message = data.message;
                        message = message.replace("cid:aqaversantLogo", Logo);
                        message = message.replace("Click here to access this data directly in AQA", "Action");
                        message = message.replace("http", "#http");
                        message = message.replace(/(<? *script)/gi, 'illegalscript')
                        this.setState({showPreviewReport: true, previewReport: message});
                        const container = document.getElementById('render-report');
                        container.innerHTML = message;
                        this.setState({showReviewPop:false});
                        this.normalCursor();
                    }
                }
            )
        }
    }

    handleShare = (e) =>{
        e.stopPropagation();
        let link = null;
        let mySubComponents = this.props.parent.getSubComponents();
        if(mySubComponents.length>0){
            let headerComponents = mySubComponents.filter((d)=>{return d.props.myheader === "AqaSourceHeader"});
            if(headerComponents.length>0) {
                headerComponents = headerComponents[0];
                let myLinks = headerComponents.getSubComponents();
                if(myLinks.length>0) {
                    let myGetLinks = myLinks.filter((d)=>{return d.props.supress=== true});
                    if(myGetLinks.length>0) {
                        link = myGetLinks[0];
                        link.handleGetLinkSource(this.props.dataFromParent,this.state.reviewComplete);
                        this.setState({showReviewPop:false});
                    }
                }
            }
        }
        if(link === null){
            var customNotification = {
                title: "Cannot open Share", titleIcon: "",
                message: "Unable to open share dialog as link cannot be established",
                isCustom: false, type: "info",
                insert: "center", position: "center", autoClose: true, duration: 5000
            };
            this.removeAllNotifications();
            this.showNotification(null, customNotification);
        }

    }

    handleSource(){
        if(this.state.showCheckbox ===false) {
            this.props.dataFromParent.handleSource(this.props.dataFromGrid);
        }
    }

    handleRowsNavigation = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.handleSnapshotFilter2(this.props.dataFromGrid, this.state.latestSnapshot, null, true, true, null);
    }

    handleAspectStat(e,index,root,type,value,stat){
        e.preventDefault();
        this.showRules=true;
        if(root !== undefined && root.numRules !== undefined && value > 0) {
            if (index === 0) {
                var aspect = {index: 0, firstColumn: {id: "a_0_0"}};
                this.props.dataFromRoot.handleShowRule(aspect, this.props.dataFromGrid, this.state.latestSnapshot);
            } else if (index === 1) {
                this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.handleSelectedStat(stat);
                this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.handleSnapshotFilter2(this.props.dataFromGrid, this.state.latestSnapshot, null, false, true, null,null,false,type===2?true:false,"overall",-1,type);
            } else if (index === 2) {
                this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.handleSelectedStat(stat);
                this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.handleSnapshotFilter2(this.props.dataFromGrid, this.state.latestSnapshot, null, true, false, null,null,false,false,"any",-1,type);
            } else {
                return false;
            }
        }
        else{
            return false;
        }
        return false;
    }

    handleReviewAspectStat(e,index,root){
        e.preventDefault();
        this.showRules=true;
        if(root !== undefined && root.numR !== undefined && root.numR > 0) {
            if (index === 0) {
                var aspect = {index: 0, firstColumn: {id: "a_0_0"}};
                this.props.dataFromRoot.handleShowRule(aspect, this.props.dataFromGrid, this.state.latestSnapshot);
            } else if (index === 1) {
                this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.handleSnapshotFilter2(this.props.dataFromGrid, this.state.latestSnapshot, null, false, true, null);
            } else if (index === 2) {
                this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.handleSnapshotFilter2(this.props.dataFromGrid, this.state.latestSnapshot, null, true, false, null);
            } else {
                return false;
            }
        }
        else{
            return false;
        }
        return false;
    }

    handleHealthAspectStat(e,index,root,type,stat){
        e.preventDefault();
        e.stopPropagation();
        let isAmber = true;
        let isRed = true;
        if(type === "amber") isRed = false;
        if(type === "red") isAmber = false;
        this.showRules=true;
        if(root !== undefined && root.numF !== undefined && root.numF < 2) {
            if (index === 0) {
                var aspect = {index: 0, firstColumn: {id: "a_0_0"}};
                var authCode=this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.props.parent.state.authCode;
                var adminEmail=this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.props.parent.state.adminEmail;
                var context;
                if(authCode !== "A"){
                    this.removeAllNotifications();
                    this.showNotification(4, null,context , null,adminEmail);
                }
                else {
                    this.props.dataFromRoot.handleShowRule(aspect, this.props.dataFromParent, this.state.latestSnapshot);
                }
            } else if (index > 0 && index<12) {
                let ctype = index===1?"population":
                    (index===2?"uniqueness":
                        (index===3?"type":
                            (index===4?"overall":
                                (index===5?"overall":
                                    (index===6?"string":
                                        (index===7?"nativeError":
                                            (index===8?"overall":
                                                (index===9?"overall":
                                                    (index===10?"overall":
                                                        (index===11?"nativeError":"overall"))))))))));
                let grain = index===6?2:(index===4?0:(index===5?1:-1));
                if((type ==="amber" && root.numR>0) || (type==="red" && root.numI>0) || (type==="overall" && root.numF<2) ) {
                    this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.handleSelectedStat(stat);
                    this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.handleSnapshotFilter2(this.props.dataFromGrid, this.state.latestSnapshot, 7, isAmber, isRed, null, null, true, false, ctype, grain);
                }
                else return false;
            } else {
                return false;
            }
        }
        else{
            return false;
        }
        return false;
    }

    handleMouseOverIcon(e){
        e.preventDefault();
        var target = e.target;
        var inputSelections = target.getElementsByTagName("input");
        this.showRules = false;
        if(inputSelections.length===0) {
            if (this.state.showCheckbox === true && this.state.isSelected === false) {
                this.setState({showCheckbox: false});
            }
        }
    }

    handleMouseOut(e){
        e.stopPropagation();
        if(this.state.showCheckbox === true && this.state.isSelected === false) {
            this.setState({showCheckbox: false});
        }
    }

    handleMouseEnterIcon(e){
        e.preventDefault();
        if(this.state.showCheckbox === false){
            this.setState({showCheckbox:true});
        }

    }

    handleSnapshotScoreClick(e){
        e.stopPropagation();
        this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.handleSnapshotFilter3(this.props.dataFromGrid, this.state.latestSnapshot, null, false, false, null);
    }

    handleChange = (event) => {
        event.stopPropagation();
        //alert(event.target.checked);
        var authCode=this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.props.parent.state.authCode;
        var adminEmail=this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.props.parent.state.adminEmail;
        var context;
        if(authCode === "V"){
            this.removeAllNotifications();
            this.showNotification(4, null,context , null,adminEmail);
        }
        if(this.props.dataFromMain.state.selectedFolders.length ===0 && authCode!== "V") {
            if(this.props.dataFromGrid.example === true){
                var customNotification = {
                    title: "Restricted", titleIcon: "",
                    message: "You cannot Rename or Delete this Data Source",
                    isCustom: false, type: "info",
                    insert: "center", position: "center", autoClose: true, duration: 5000
                };
                this.removeAllNotifications();
                this.showNotification(null, customNotification);
            }
            else {
                //var isSelected = event.target.checked;

                this.setState({isSelected: event.target.checked});

                this.props.dataFromRoot.handleSourceSelection(this.props.dataFromGrid, event.target.checked);
                this.props.dataFromRoot.handleSourceSelectionCheckReset();
            }
        }
    };


    handleSnapshotScores(sourceId,snapshotObjects){
        AqaComponent.backend.getScoresUsingGET(
            sourceId,
            {_number:6},
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the snapshot scores from the server was encountered.",
                    "AqaSourceGridRow.handleSnapshotScores, backend call: " + error,this);
                else
                {
                    if(data.length>0){
                        let scores = [];
                        for(let k=0;k<data.length;k++){
                            let score = data[k];
                            let scoreObjs = this.rowStats.filter((item)=>{return item.id === score.id;});
                            if(scoreObjs.length>0){
                                let scoreObj = scoreObjs[0];
                                score ={...score,...scoreObj};
                            }
                            scores.push(score);
                        }
                        this.handleHistoricalScores(scores,snapshotObjects);
                    }
                    else{
                        var lastScores=[["Last","Score",{role:'string'}],
                            [1,-1,null],
                            [2,null,null],
                            [3,null,null],
                            [4,null,null],
                            [5,null,null],
                            [6,null,null]];
                        var score=null;
                        var lastScore=null;
                        var rag=null;
                        var diff="";
                        this.setState({score:score,rag:rag,lastScore:lastScore,lastScores:lastScores,diff:diff});
                    }
                }
            }

        )
    }

    handleHistoricalScores(snapshots,snapshotObjects){
        var score = null;
        var lastScore = null;
        var rag = null;
        var diff="";
        var lastScores = [["Last","Score",{role:'string'},{role:'string'},{role:'string'},{role:'string'},{role:'string'}]];
        var k = 6;
        for (var i = 1; i < 7; i++) {
            var snapshot = snapshots[k - i];
            if (snapshot === null || snapshot===undefined) {
                lastScores.push([i, null, null,null,null,null,null]);
            } else {var snapshotObject = null;
                for (var l = 0; l < snapshotObjects.length; l++) {
                    var snapshotObj = snapshotObjects[l];
                    if (snapshotObj.id === snapshot.id) {
                        snapshotObject = snapshotObj;
                    }
                }
                var dateCreated = null;
                if(snapshotObject !== null ){
                    dateCreated = snapshotObject.dateCreated;
                }
                let numOverallReds = snapshot.numberOfOrthogonalVectorsWithErrors[0][0][0];
                let numOverallAmbers = snapshot.numberOfOrthogonalVectorsWithErrors[0][0][1];
                let numOverallDiscrete = snapshot.numberOfOrthogonalVectorsWithErrors[0][0][2];
                let rows = snapshot.numberOfRows!==undefined?snapshot.numberOfRows-1:this.state.numRows;
                let totalErrors1 = numOverallReds+numOverallAmbers-numOverallDiscrete;
                let totalPercent1 = (totalErrors1/rows)*100;
                lastScores.push([i, totalPercent1, snapshot.rag,numOverallReds,numOverallAmbers,(dateCreated !== null)? (moment(dateCreated ).format("YYYY/MM/DD HH:mm")):(""),totalErrors1])
            }
        }
        var latestScoreObj = lastScores[k];
        var lastScoreObj = lastScores[k-1];
        score=latestScoreObj[1];
        rag=latestScoreObj[2];
        lastScore=lastScoreObj[1];
        if(score === undefined || lastScore === undefined){
            diff = "";
        }
        else if(score === null && lastScore === null){
            diff = "";
        }
        else if(score === null && lastScore !== null){
            diff = -(Math.round(lastScore));
        }
        else if(score !== null && lastScore === null){
            if(score <0){
                diff=""
            }
            else {
                diff = Math.round(score);
            }
        }
        else {
            diff = score - lastScore;
            diff = Math.round(diff);
        }
        //var numOverallRules=0;
        var numOverallReds=0;
        var numOverallAmbers=0;
        let numOverallDiscrete = 0;
        let totalErrors = 0;
        let redPercent = 0;
        let amberPercent = 0;
        //let totalPercent = 0;
        let rows =0;
        let numPendingAmber=0;
        let numIgnoreAmber=0;
        let numFixAmber=0;
        let numPendingRed=0;
        let numIgnoreRed=0;
        let numFixRed=0;
        let numWhiteFixRed=0;
        let populatedAmber = 0;
        let populatedRed = 0;
        let uniquenessAmber = 0;
        let uniquenessRed = 0;
        let nativeAmber = 0;
        let nativeRed = 0;
        let typeAmber = 0;
        let typeRed = 0;
        let formatAmber = 0;
        let formatRed = 0;
        let valueAmber = 0;
        let valueRed = 0;
        let valueAllowedAmber = 0;
        let valueAllowedRed = 0;
        let populatedRS = 0;
        let uniquenessRS = 0;
        let typeRS = 0;
        let formatRS = 0;
        let valueRS = 0;
        let valueAllowedRS = 0;
        let nativeRS = 0;
        let populatedAS = 0;
        let uniquenessAS = 0;
        let typeAS = 0;
        let formatAS = 0;
        let valueAS = 0;
        let valueAllowedAS = 0;
        let nativeAS = 0;
        let populatedROS = 0;
        let uniquenessROS = 0;
        let typeROS = 0;
        let formatROS = 0;
        let valueROS = 0;
        let valueAllowedROS = 0;
        let nativeROS = 0;
        let populatedAOS = 0;
        let uniquenessAOS = 0;
        let typeAOS = 0;
        let formatAOS = 0;
        let valueAOS = 0;
        let valueAllowedAOS = 0;
        let nativeAOS = 0;
        let populatedRag = 2;
        let uniquenessRag = 2;
        let typeRag = 2;
        let formatRag = 2;
        let valueRag = 2;
        let valueAllowedRag = 2;
        let nativeRag = 2;
        if(snapshots.length >0 ){
            latestScoreObj = snapshots[0];
            this.setState({latestSnapshot:latestScoreObj});
            this.handleSnapshot(latestScoreObj);
            this.handleGetColumnHeatmap(latestScoreObj,false);
            numOverallReds = latestScoreObj.numberOfOrthogonalVectorsWithErrors[0][0][0];
            numOverallAmbers = latestScoreObj.numberOfOrthogonalVectorsWithErrors[0][0][1];
            numOverallDiscrete = latestScoreObj.numberOfOrthogonalVectorsWithErrors[0][0][2];
            rows = latestScoreObj.numberOfRows!==undefined?latestScoreObj.numberOfRows:this.state.numRows;
            totalErrors = numOverallReds+numOverallAmbers-numOverallDiscrete;
            redPercent = (numOverallReds/rows)*100;
            amberPercent = (numOverallAmbers/rows)*100;
            //totalPercent = (totalErrors/rows)*100;

            // Health stats to go here

            populatedAmber = latestScoreObj[`numberOfDiscretePopulationAmbers`];
            populatedRed = latestScoreObj[`numberOfDiscretePopulationReds`];
            uniquenessAmber = latestScoreObj[`numberOfDiscreteUniquenessAmbers`];
            uniquenessRed = latestScoreObj[`numberOfDiscreteUniquenessReds`];
            nativeAmber = latestScoreObj[`numberOfDiscreteNativeErrorAmbers`];
            nativeRed = latestScoreObj[`numberOfDiscreteNativeErrorReds`];
            typeAmber = latestScoreObj[`numberOfDiscreteTypeAmbers`];
            typeRed = latestScoreObj[`numberOfDiscreteTypeReds`];
            formatAmber = latestScoreObj[`numberOfDiscreteStringFormatAmbers`] + latestScoreObj[`numberOfDiscreteNumberFormatAmbers`] + latestScoreObj[`numberOfDiscreteDateFormatAmbers`];
            formatRed = latestScoreObj[`numberOfDiscreteStringFormatReds`] + latestScoreObj[`numberOfDiscreteNumberFormatReds`] + latestScoreObj[`numberOfDiscreteDateFormatReds`];
            valueAmber = latestScoreObj[`numberOfDiscreteStringBoundaryAmbers`] + latestScoreObj[`numberOfDiscreteNumberBoundaryAmbers`] + latestScoreObj[`numberOfDiscreteDateBoundaryAmbers`];
            valueRed = latestScoreObj[`numberOfDiscreteStringBoundaryReds`] + latestScoreObj[`numberOfDiscreteNumberBoundaryReds`] + latestScoreObj[`numberOfDiscreteDateBoundaryReds`];
            valueAllowedAmber = latestScoreObj[`numberOfDiscreteStringAllowedAmbers`];
            valueAllowedRed = latestScoreObj[`numberOfDiscreteStringAllowedReds`];
            populatedRag = populatedRed>0?0:(populatedAmber>0?1:2);
            uniquenessRag = uniquenessRed>0?0:(uniquenessAmber>0?1:2);
            typeRag = typeRed>0?0:(typeAmber>0?1:2);
            formatRag = formatRed>0?0:(formatAmber>0?1:2);
            valueRag = valueRed>0?0:(valueAmber>0?1:2);
            valueAllowedRag = valueAllowedRed>0?0:(valueAllowedAmber>0?1:2);
            nativeRag = nativeRed>0?0:(nativeAmber>0?1:2);

            // Row Stats to go here
            if(latestScoreObj.snapshotReviewStats!==undefined){
                let rStats = latestScoreObj.snapshotReviewStats;
                let cStats = latestScoreObj.correctedReviewStats;
                numWhiteFixRed = cStats[`totalNumberOfRowsConcerned`];
                numPendingAmber = rStats[`numberOfAmberPendings`];
                numPendingRed = rStats[`numberOfRedPendings`];
                numIgnoreAmber = rStats[`numberOfAmberIgnores`];
                numIgnoreRed = rStats[`numberOfRedIgnores`];
                numFixAmber = rStats[`numberOfAmberFixers`];
                numFixRed = rStats[`numberOfRedFixers`];
                numFixRed = numFixRed + numWhiteFixRed;
                numOverallReds = numPendingRed + numIgnoreRed + numFixRed;
                numOverallAmbers = numPendingAmber + numIgnoreAmber + numFixAmber;
                let cols = latestScoreObj.numberOfColumns!==undefined?latestScoreObj.numberOfColumns:this.state.numCols;
                totalErrors = rows * cols;
                redPercent = (numOverallReds/totalErrors)*100;
                amberPercent = (numOverallAmbers/totalErrors)*100;
                populatedRS = (rStats[`numberOfMissingRedFixers`] + rStats[`numberOfMissingRedIgnores`]);
                populatedAS = (rStats[`numberOfMissingAmberFixers`] + rStats[`numberOfMissingAmberIgnores`]);
                uniquenessRS = (rStats[`numberOfUniqueRedFixers`] + rStats[`numberOfUniqueRedIgnores`]);
                uniquenessAS = (rStats[`numberOfUniqueAmberFixers`] + rStats[`numberOfUniqueAmberIgnores`]);
                typeRS = (rStats[`numberOfTypeRedFixers`] + rStats[`numberOfTypeRedIgnores`]);
                typeAS = (rStats[`numberOfTypeAmberFixers`] + rStats[`numberOfTypeAmberIgnores`]);
                formatRS = (rStats[`numberOfFormatRedFixers`] + rStats[`numberOfFormatRedIgnores`]);
                formatAS = (rStats[`numberOfFormatAmberFixers`] + rStats[`numberOfFormatAmberIgnores`]);
                valueRS = (rStats[`numberOfOutlierRedFixers`] + rStats[`numberOfOutlierRedIgnores`]);
                valueAS = (rStats[`numberOfOutlierAmberFixers`] + rStats[`numberOfOutlierAmberIgnores`]);
                valueAllowedRS = (rStats[`numberOfInvalidRedFixers`] + rStats[`numberOfInvalidRedIgnores`]);
                valueAllowedAS = (rStats[`numberOfInvalidAmberFixers`] + rStats[`numberOfInvalidAmberIgnores`]);
                nativeRS = (rStats[`numberOfNativeRedFixers`] + rStats[`numberOfNativeRedIgnores`]);
                nativeAS = (rStats[`numberOfNativeAmberFixers`] + rStats[`numberOfNativeAmberIgnores`]);
                populatedROS = populatedRed === 0? 0: (((rStats[`numberOfMissingRedFixers`] + rStats[`numberOfMissingRedIgnores`]) / populatedRed) * 100);
                populatedAOS = populatedAmber === 0? 0: (((rStats[`numberOfMissingAmberFixers`] + rStats[`numberOfMissingAmberIgnores`]) / populatedAmber) * 100);
                uniquenessROS = uniquenessRed === 0? 0: (((rStats[`numberOfUniqueRedFixers`] + rStats[`numberOfUniqueRedIgnores`]) / uniquenessRed) * 100);
                uniquenessAOS = uniquenessAmber === 0? 0: (((rStats[`numberOfUniqueAmberFixers`] + rStats[`numberOfUniqueAmberIgnores`]) / uniquenessAmber) * 100);
                typeROS = typeRed === 0? 0: (((rStats[`numberOfTypeRedFixers`] + rStats[`numberOfTypeRedIgnores`]) / typeRed) * 100);
                typeAOS = typeAmber === 0? 0: (((rStats[`numberOfTypeAmberFixers`] + rStats[`numberOfTypeAmberIgnores`]) / typeAmber) * 100);
                formatROS = formatRed === 0? 0: (((rStats[`numberOfFormatRedFixers`] + rStats[`numberOfFormatRedIgnores`]) / formatRed) * 100);
                formatAOS = formatAmber === 0? 0: (((rStats[`numberOfFormatAmberFixers`] + rStats[`numberOfFormatAmberIgnores`]) / formatAmber) * 100);
                valueROS = valueRed === 0? 0: (((rStats[`numberOfOutlierRedFixers`] + rStats[`numberOfOutlierRedIgnores`]) / valueRed) * 100);
                valueAOS = valueAmber === 0? 0: (((rStats[`numberOfOutlierAmberFixers`] + rStats[`numberOfOutlierAmberIgnores`]) / valueAmber) * 100);
                valueAllowedROS = valueAllowedRed === 0? 0: (((rStats[`numberOfInvalidRedFixers`] + rStats[`numberOfInvalidRedIgnores`]) / valueAllowedRed) * 100);
                valueAllowedAOS = valueAllowedAmber === 0? 0: (((rStats[`numberOfInvalidAmberFixers`] + rStats[`numberOfInvalidAmberIgnores`]) / valueAllowedAmber) * 100);
                nativeROS = nativeRed === 0? 0: (((rStats[`numberOfNativeRedFixers`] + rStats[`numberOfNativeRedIgnores`]) / nativeRed) * 100);
                nativeAOS = nativeAmber === 0? 0: (((rStats[`numberOfNativeAmberFixers`] + rStats[`numberOfNativeAmberIgnores`]) / nativeAmber) * 100);
            }

        }
        var overallStats = [
            {id:"Table_Aspect_Stat_0",name:"Alert Row Counts",numRules:"#",percentage:"%"},
            {id:"Table_Aspect_Stat_1",name:"Red",numRules:numOverallReds,percentage:redPercent.toFixed(2)},
            {id:"Table_Aspect_Stat_2",name:"Amber",numRules:numOverallAmbers,percentage:amberPercent.toFixed(2)},
            {id:"Table_Aspect_Stat_3",name:"# Rows",numRules:rows,percentage:""}
        ];
        let totalR=numOverallReds+numOverallAmbers;
        let leftR = totalR - (numIgnoreRed + numIgnoreAmber + numFixRed + numFixAmber);
        let reviewComplete = (100 - ((leftR/totalR)*100))===100;
        let reviewPercent = (100 - ((leftR/totalR)*100));
        var overallReviewStats = [
            {id:"Table_Aspect_Stat_0",name:window.innerWidth<1280?"Counts":"Cell Counts",numRules:"#",numR:"Pending",numI:"Ignore",numF:"Fix",percentage:"%"},
            {id:"Table_Aspect_Stat_1",name:"Red",numRules:numOverallReds,percentage:redPercent.toFixed(2),numR:numPendingRed,numI:numIgnoreRed,numF:numFixRed},
            {id:"Table_Aspect_Stat_2",name:"Amber",numRules:numOverallAmbers,percentage:amberPercent.toFixed(2),numR:numPendingAmber,numI:numIgnoreAmber,numF:numFixAmber},
            {id:"Table_Aspect_Stat_3",name:"Review: "+reviewPercent.toFixed(0)+"%",numRules:totalR,percentage:reviewPercent.toFixed(0),numR:leftR,numI:numIgnoreAmber,numF:numFixAmber}
        ];

        var healthStats = [
            {id:"Table_Health_Stat_0",name:"Alert Type",description:"List of Alert Types",numR:"Amber",numI:"Red",numF:"Health"},
            {id:"Table_Health_Stat_1",name:"Missing?",description:"Does the data contain any cells that have missing data?",numR:populatedAmber,numI:populatedRed,numF:populatedRag,numRS:populatedAS,numIS:populatedRS,numROS:populatedAOS,numIOS:populatedROS},
            {id:"Table_Health_Stat_2",name:"Duplicate?",description:"For columns containing alpha-numeric data, are any values duplicated?",numR:uniquenessAmber,numI:uniquenessRed,numF:uniquenessRag,numRS:uniquenessAS,numIS:uniquenessRS,numROS:uniquenessAOS,numIOS:uniquenessROS},
            {id:"Table_Health_Stat_3",name:"Wrong Type?",description:"If a column contains data of one type, are there any values in any cells of a different type?",numR:typeAmber,numI:typeRed,numF:typeRag,numRS:typeAS,numIS:typeRS,numROS:typeAOS,numIOS:typeROS},
            {id:"Table_Health_Stat_4",name:"Bad Format?",description:"If there are columns with specific format types, are there any cells that do not conform?",numR:formatAmber,numI:formatRed,numF:formatRag,numRS:formatAS,numIS:formatRS,numROS:formatAOS,numIOS:formatROS},
            {id:"Table_Health_Stat_5",name:"Outlier?",description:"In specific columns, do any cells contain values significantly outside of the range of the data contained in the other cells?",numR:valueAmber,numI:valueRed,numF:valueRag,numRS:valueAS,numIS:valueRS,numROS:valueAOS,numIOS:valueROS},
            {id:"Table_Health_Stat_6",name:"Invalid?",description:"In columns with a specific set of repeated values, are there any cells that look different?",numR:valueAllowedAmber,numI:valueAllowedRed,numF:valueAllowedRag,numRS:valueAllowedAS,numIS:valueAllowedRS,numROS:valueAllowedAOS,numIOS:valueAllowedROS},
            {id:"Table_Health_Stat_7",name:"Excel Error?",description:"Are there any cells that contain native excel errors?",numR:nativeAmber,numI:nativeRed,numF:nativeRag,numRS:nativeAS,numIS:nativeRS,numROS:nativeAOS,numIOS:nativeROS},
        ];
        this.setState({score:score.toFixed(2),lastScore:lastScore,rag:rag,diff:diff,stats:overallStats,reviewStats:overallReviewStats,lastScores:lastScores,snapshotScores:snapshots,healthStats:healthStats,reviewComplete:reviewComplete});
    }

    handleSnapshot(snapshot){
        AqaComponent.snapshotBackend.getSnapshotUsingGET(
            snapshot.id,
            (error,data,response)=>
            {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSourceGridRow.handleSnapshot, backend call: " + error,this);
                else
                {
                    var snapObj = {};
                    snapObj={...snapshot,...data};
                    this.setState({latestSnapshot:snapObj});
                    //this.handleGetRowsColumns(snapObj);
                }
            }
        )
    }

    handleGetRowsColumns(snapshot)
    {
        this.table = new AqaTable(
            snapshot.id,
            e=> {
                var rows=16;
                var cols=16;

                if(this.table.data !== undefined){
                    rows=this.table.data.numberOfRows;
                    cols=this.table.data.numberOfColumns;
                }
                this.setState({numRows:rows,numCols:cols});

            }
        )
    } // handleGetRowsColumns

	handleSnapshotRowCounts(snapshot)
	{

		// If this proves to load too much data set the wantsCache flag to false (2nd param)
		ColourAndOverrideMaps.retrieveColBasedStatistics(snapshot.id, true, data => this.handleSnapshotCorrectedRowCounts(snapshot, data));

   /*
        AqaComponent.reviewBackend.retrieveStatiStatisticsUsingGET(
            snapshot.id,
            "row",
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSourceListRow.handleSnapshot, backend call: " + error,this);
                else {
                    this.handleSnapshotCorrectedRowCounts(snapshot,data);

                }
            }
        );
*/
	} // handleSnapshotRowCounts

    handleSnapshotCorrectedRowCounts(snapshot,statsData){
        AqaComponent.reviewBackend.getAnnotationsScoreUsingGET(
            snapshot.id,
            "row",
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSourceListRow.handleSnapshot, backend call: " + error,this);
                else {
                    //console.log(data);
                    var snapRevs = {id:snapshot.id,snapshotReviewStats:statsData,correctedReviewStats:data};
                    this.rowStats.push(snapRevs);

                }
            }
        );
    }

/*
    handleGetColumnHeatmap(snapshot,recompute)
    {
        // Getting the maps - Tests and demo only
        new SnapshotColumnsHeatmap
        (
            snapshot.id, // snapshot id
            16, // width
            16, // height
            recompute, // Don't recompute
            sch => // callback that will be run when heat map data is available.
            {
                const maps = [];
                for (let i = 0; i < 6; i++) {
                    // Getting maps by number - they could also be obtained by name. See SnapshotColumnsHeatmap, heatTypes.
                    maps[i] = sch.heatmapByIndex(i);
                }
                this.setState({maps});
                this.setState({columnmap: maps[5]});
            }
        );
    }
*/

	handleGetColumnHeatmap(snapshot, recompute)
	{
		Heatmap.getHeatmapColumnsData
		(
			snapshot.id,
			AqaComponent.HEATMAP_STAMP_WIDTH,
			AqaComponent.HEATMAP_STAMP_HEIGHT,
			-1, // Meaning we want all the columns
			heatmapData => this.setState({heatmapData})
		);
	} // handleGetColumnHeatmap

    handleSnapshots(source){
        AqaComponent.backend.getSnapshotsForSourceUsingGET(
            source.id,
            0,
            6,
            (error,data,response)=>
            {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSourceListRow.handleSnapshot, backend call: " + error,this);
                else
                {
                    let snapshots = data.filter((d)=>{return d.sheetChosen===true;})
                    this.setState({snapshots:snapshots});
                    //if(data.length>0) this.handleGetRowsColumns(data[0]);
                    let l=snapshots.length;
                    this.rowStats=[];
                    for(let k=0;k<l;k++){
                        this.handleSnapshotRowCounts(snapshots[k]);
                        if(k===l-1){
                            setTimeout(()=>this.handleSnapshotScores(source.id,snapshots),500);
                        }
                    }
                }
            }
        )
    }

    handleQualityDefinition = () =>{
        let source=this.props.dataFromGrid;
        AqaComponent.backend.getQualityDefinitionUsingGET(
            source.id,
            (error, data, response) => {
                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                else {
                    //console.log(data);
                    let numColumns = 0;
                    if(data.vectorQualityTable !== undefined) numColumns = data.vectorQualityTable.columnQualities.length;
                    this.setState({qd:data,numCols:numColumns});
                }
            }
        )
    }

    handleShowLibraryQD = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        this.props.dataFromRoot.props.dataFromMain.props.dataFromRoot.handleAQDSource(this.props.dataFromGrid.subscribedId,"source",true,false,1);
        this.props.dataFromRoot.props.dataFromMain.props.dataFromRoot.navigation(6);
    }

    /*UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(this.state.showCheckbox === true && nextProps.dataForCheckbox===true){
            this.setState({showCheckbox:false,isSelected:false});
        }
        this.handleSnapshots(nextProps.dataFromGrid);
    }*/

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.dataForCheckbox!==this.props.dataForCheckbox && prevState.showCheckbox !== this.state.showCheckbox){
            this.setState({showCheckbox:false,isSelected:false});
            //this.handleSnapshots(this.props.dataFromGrid);
        }
    }

    componentDidMount(){
        this.handleSnapshots(this.props.dataFromGrid);
        let source = this.props.dataFromGrid;
        if(source !== null && source !== ""){
            if(source.subscribedQdId !== null && source.subscribedQdId!==""){
                this.setState({fromLibrary:true},this.handleQualityDefinition());
            }
        }

    }

    render() {
        const { classes } = this.props;
        const { error, isLoaded, redirect, notfound/*,diff*/,showCheckbox,reviewComplete } = this.state;
        let borderStyle = {padding:"4px"};
        let borderStyle1 = {padding:"0px 4px"};
        let noborderStyle = {padding:"4px",border:"none"};
        let noborderStyle1 = {padding:"0px 4px",border:"none"};
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <div className={"source-detail-servant-pane"}>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={10} sm={7} lg={11} xl={11}>
                            <Typography variant="title" color="inherit" align="left"
                                        style={{ fontSize: '1.5rem',
                                            padding: '0.1em 0.5em',
                                            fontFamily: 'Open Sans, sans-serif'}}>
                                Nothing to display
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} lg={1} xl={1}>

                        </Grid>
                    </Grid>
                </div>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
                return(
                    <div className="aqa-card">
                    
                                    <Card className={classes.root} style={{cursor:"pointer"}} onClick={this.handleSource}>
                                        <CardHeader style={{padding:"5px 5px 0px 5px",borderBottom:"1px solid #ccc"}} onMouseOut={(e)=>this.handleMouseOverIcon(e)} onMouseLeave={(e)=>this.handleMouseOut(e)}
                                            avatar={
                                                <Avatar aria-label="recipe" className={classes.avatar} style={{backgroundColor:"transparent",width:"64px"}} onMouseOver={(e)=>this.handleMouseEnterIcon(e)}>
                                                    <span style={{float:"left",width:"32px"}}>
                                                    <div className={"aqa-list-select-action"} >
                                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                            <div>
                                                                <Typography color="inherit" className="aqa-text-action" >Select</Typography>
                                                            </div>

                                                        </React.Fragment>} arrow={"true"} placement="bottom-start" >
                                                        <div className={"aqa-list-checkbox"}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={this.state.isSelected}
                                                                        onClick={(e)=>this.handleChange(e)}
                                                                        name="isSelected"
                                                                        color="primary"
                                                                        disabled={!showCheckbox}
                                                                    />
                                                                }
                                                                label=""
                                                                labelPlacement="start"
                                                            />
                                                        </div>
                                                        </HtmlTooltip>
                                                    </div>
                                                        </span>
                                                        <span style={{float:"left",width:"34px"}}>
                                                        <div className="aqa-list-icon" style={{marginTop:"3px"}}>
                                                            <SourceIcon width="32px" />
                                                        </div>
                                                        </span>
                                                </Avatar>
                                            }

                                            title={<Typography variant="inherit" color="inherit" className={"aqa-text-bold"} style={{textAlign:"left",fontSize:"0.8rem",paddingLeft:"0px",marginTop:"-4px",marginLeft:"-5px"}}>
                                                {/*(this.props.dataFromGrid.name === "" || this.props.dataFromGrid.name)? (this.props.dataFromGrid.name) : (this.props.dataFromGrid.name.substr(0,80)+"...")*/}
                                                {(this.state.latestSnapshot===null)? (this.props.dataFromGrid.name) : (this.state.latestSnapshot.originalFilename+(this.state.latestSnapshot.sheetName!=="[Default sheet]"?" - ["+this.state.latestSnapshot.sheetName+"]":""))}
                                            </Typography>}
                                            subheader={<Typography variant="inherit" color="inherit" className={"aqa-text-sub"} style={{textAlign:"left",fontSize:"0.7rem",paddingLeft:"0px",marginLeft:"-5px"}}>
                                                {/*(this.props.dataFromGrid.description !== "" && this.props.dataFromGrid.description !== null && this.props.dataFromGrid.description !== "null") ? (this.props.dataFromGrid.description) : ""*/}
                                                <div>
                                                    {this.state.latestSnapshot===null?(<>
                                                        <div style={{float:"left",width:"45%"}} >
                                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                            <div>
                                                                <Typography color="inherit" className="aqa-text-action">Loaded By: {this.props.dataFromGrid.createdBy}</Typography>
                                                                <Typography color="inherit" className="aqa-text-action">Loaded On: {(this.props.dataFromGrid.dateCreated !== null)? (moment(this.props.dataFromGrid.dateCreated ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                                            </div>

                                                        </React.Fragment>} arrow={"true"} placement="bottom-start">

                                                            <IconButton className="aqa-button" style={{margin:0,padding:"0px",marginTop:"4px"}} >

                                                                <div className="aqa-list-icon" style={{width: "24px"}}>
                                                                    <EventIcon width="24px"/>
                                                                </div>
                                                                <Typography className="aqa-text-action" variant="inherit" style={{
                                                                    textAlign: "left",
                                                                    paddingLeft: "4px",
                                                                    paddingRight:"0px !important",
                                                                    marginTop: "-4px",
                                                                    width:"120px"
                                                                }}>
                                                                    {(this.props.dataFromGrid.dateCreated !== null)? (moment(this.props.dataFromGrid.dateCreated ).format("YYYY/MM/DD HH:mm")):("")}

                                                                </Typography>
                                                            </IconButton>

                                                        </HtmlTooltip>
                                                        </div>
                                                        <div style={{float:"left",width:"55%"}} >
                                                                    <Grid container spacing={0} direction="row" justify="space-between"
                                                                          alignItems="center" style={{padding: 0, marginBottom: "0px"}}>
                                                                        <Grid item xs={6} sm={6} lg={6} xl={6}>
                                                                            <IconButton className="aqa-button" style={{margin:0,padding:"0px",marginTop:"4px"}} >
                                                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                                                textAlign: "right",
                                                                                paddingLeft: "4px",
                                                                                paddingRight:"0px !important",
                                                                                marginTop: "-4px",
                                                                            }}>
                                                                                {"Rows: "+(this.state.latestSnapshot!==null && this.state.latestSnapshot!==undefined?(this.state.latestSnapshot.numberOfRows).toLocaleString():"0")}
                                                                            </Typography>
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid item xs={6} sm={6} lg={6} xl={6}>
                                                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                                                textAlign: "left",
                                                                                paddingLeft: "4px",
                                                                                paddingRight:"0px !important",
                                                                            }}>
                                                                                {this.props.dataFromGrid.subscribedQdId!==null && this.props.dataFromGrid.subscribedQdId !==""?
                                                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                        <div>
                                                                                            <Typography color="inherit" className="aqa-text-action">Loaded from the library</Typography>
                                                                                        </div>

                                                                                    </React.Fragment>} arrow={"true"} placement="bottom-start">

                                                                                        <IconButton className="aqa-button" style={{margin:0,padding:"0px",marginTop:"4px"}} onClick={(e)=>this.handleShowLibraryQD(e)}>

                                                                                            <div className="aqa-list-icon" style={{width: "24px"}}>
                                                                                                <LibraryIcon width="24px"/>
                                                                                            </div>
                                                                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                                                                textAlign: "left",
                                                                                                paddingLeft: "4px",
                                                                                                paddingRight:"0px !important",
                                                                                                marginTop: "-4px",
                                                                                                width:"0px"
                                                                                            }}>
                                                                                                {"Columns: "+(this.state.latestSnapshot!==null && this.state.latestSnapshot!==undefined?this.state.latestSnapshot.numberOfColumns.toLocaleString():
                                                                                                    (this.props.dataFromGrid.subscribedQdId!==null?this.state.numCols:"0"))}
                                                                                            </Typography>
                                                                                        </IconButton>
                                                                                    </HtmlTooltip>
                                                                                    :"Columns: "+(this.state.latestSnapshot!==null && this.state.latestSnapshot!==undefined?this.state.latestSnapshot.numberOfColumns.toLocaleString():
                                                                                    (this.props.dataFromGrid.subscribedQdId!==null?this.state.numCols:"0"))}

                                                                            </Typography>
                                                                        </Grid>

                                                                    </Grid>
                                                        </div>


                                                    </>):(
                                                        <div>
                                                            <div style={{float:"left",width:"45%"}} >
                                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                            <div>
                                                                <Typography color="inherit" className="aqa-text-action">Loaded By: {this.state.latestSnapshot.createdBy}</Typography>
                                                                <Typography color="inherit" className="aqa-text-action">Loaded On: {(this.state.latestSnapshot.dateCreated !== null)? (moment(this.state.latestSnapshot.dateCreated ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                                            </div>

                                                        </React.Fragment>} arrow={"true"} placement="bottom-start">

                                                            <IconButton className="aqa-button" style={{margin:0,padding:"0px",marginTop:"4px"}} >

                                                                <div className="aqa-list-icon" style={{width: "24px"}}>
                                                                    <EventIcon width="24px"/>
                                                                </div>
                                                                <Typography className="aqa-text-action" variant="inherit" style={{
                                                                    textAlign: "left",
                                                                    paddingLeft: "4px",
                                                                    paddingRight:"0px !important",
                                                                    marginTop: "-4px",
                                                                    width:"120px"
                                                                }}>
                                                                    {(this.state.latestSnapshot.dateCreated !== null)? (moment(this.state.latestSnapshot.dateCreated ).format("YYYY/MM/DD HH:mm")):("")}

                                                                </Typography>
                                                            </IconButton>
                                                        </HtmlTooltip>
                                                        </div>

                                                            <div style={{float:"left",width:"55%"}} >
                                                                    <Grid container spacing={0} direction="row" justify="space-between"
                                                                          alignItems="center"
                                                                          style={{padding: 0, marginTop: "2px"}}>
                                                                        <Grid item xs={5} sm={5} lg={5} xl={5}>
                                                                            <IconButton className="aqa-button" style={{margin:0,padding:"0px",marginTop:"0px"}} onClick={(e)=>this.handleRowsNavigation(e)} >
                                                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                                                textAlign: "right",
                                                                                paddingLeft: "4px",
                                                                                paddingRight:"0px !important",
                                                                                marginTop:this.props.dataFromGrid.subscribedQdId!==null?"0px":"8px"
                                                                            }}>
                                                                                {"Rows: "+(this.state.latestSnapshot!==null && this.state.latestSnapshot!==undefined?(this.state.latestSnapshot.numberOfRows).toLocaleString():"0")}

                                                                            </Typography>
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid item xs={7} sm={7} lg={7} xl={7}>
                                                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                                                textAlign: "left",
                                                                                paddingLeft: "4px",
                                                                                paddingRight:"0px !important",
                                                                                marginTop: "4px",
                                                                            }}>
                                                                                {this.props.dataFromGrid.subscribedQdId!==null && this.props.dataFromGrid.subscribedQdId !==""?
                                                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                        <div>
                                                                                            <Typography color="inherit" className="aqa-text-action">Loaded from the library</Typography>
                                                                                        </div>

                                                                                    </React.Fragment>} arrow={"true"} placement="bottom-start">

                                                                                        <IconButton className="aqa-button" style={{margin:0,padding:"0px",marginTop:"-4px"}} onClick={(e)=>this.handleShowLibraryQD(e)}>

                                                                                            <div className="aqa-list-icon" style={{width: "24px"}}>
                                                                                                <LibraryIcon width="24px"/>
                                                                                            </div>
                                                                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                                                                textAlign: "left",
                                                                                                paddingLeft: "4px",
                                                                                                paddingRight:"0px !important",
                                                                                                marginTop: "-4px"
                                                                                            }}>
                                                                                                {"Columns: "+(this.state.latestSnapshot!==null && this.state.latestSnapshot!==undefined?this.state.latestSnapshot.numberOfColumns.toLocaleString():"0")}
                                                                                            </Typography>
                                                                                        </IconButton>
                                                                                    </HtmlTooltip>
                                                                                    :<Typography className="aqa-text-action" variant="inherit" style={{
                                                                                        textAlign: "left",
                                                                                        paddingLeft: "4px",
                                                                                        paddingRight:"0px !important",
                                                                                        marginTop: "8px"
                                                                                    }}>
                                                                                        {"Columns: "+(this.state.latestSnapshot!==null && this.state.latestSnapshot!==undefined?this.state.latestSnapshot.numberOfColumns.toLocaleString():"0")}
                                                                                    </Typography>}

                                                                            </Typography>
                                                                        </Grid>

                                                                    </Grid>
                                                                </div>
                                                        </div>
                                                    )}
                                                </div>

                                            </Typography>}
                                            action={
                                                <IconButton className="aqa-button" onClick={this.handleSource}>

                                                    <div className="">
                                                        <ArrowForwardIcon style={{color:"#006",width:"16px",marginTop:"20px"}} />
                                                    </div>

                                                </IconButton>
                                            }
                                        />

                                        <CardContent style={{padding:"8px"}}>
                                            <Grid container spacing={0} direction="row" justify="space-between"
                                                      alignItems="center"
                                                      style={{padding: 0, marginTop: 0}}>


                                                    <Grid item xs={8} sm={8} lg={8} xl={8}>
                                                        <div style={{background: "none"}}>
                                                            <Table id={this.props.dataFromParent.id+"_statstable"} cellSpacing={0} aria-sort="ascending" style={{}}>
                                                                <colgroup>
                                                                    <col width="55%" />
                                                                    <col width="15%" />
                                                                    <col width="15%" />
                                                                    <col width="15%" />
                                                                </colgroup>
                                                                <TableHead>

                                                                </TableHead>
                                                                <TableBody>
                                                                    {this.state.healthStats.map((item,index)=>

                                                                        (item.numR>0 || item.numI>0 || item.numF>0?(
                                                                            (

                                                                                <TableRow key={item.id} className={"aqa-violation-folder"} style={{height:"18px"}} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"overall")}>
                                                                                    <TableCell width="55%" style={index>6?noborderStyle:borderStyle}>
                                                                                        {index === 0 ? <div>
                                                                                                <Typography className="aqa-text-bold-11" variant="inherit"
                                                                                                            style={{
                                                                                                                textAlign: "left",
                                                                                                                color: index === 0 ? "grey" : "#181818",
                                                                                                                height: "16px",
                                                                                                                padding: "4px 0px"
                                                                                                            }}>
                                                                                                    {item.name}
                                                                                                </Typography>
                                                                                            </div> :
                                                                                            <HtmlTooltip enterDelay={500} key={item.id}
                                                                                                         title={<React.Fragment>
                                                                                                             <div>
                                                                                                                 <Typography color="inherit"
                                                                                                                             className="aqa-text-action">{item.description}</Typography>
                                                                                                             </div>

                                                                                                         </React.Fragment>} arrow={"true"}
                                                                                                         placement="bottom-start">
                                                                                                <div>

                                                                                                    <Typography className="aqa-text-bold-11"
                                                                                                                variant="inherit" style={{
                                                                                                        textAlign: "left",
                                                                                                        color: index === 0 ? "grey" : "#181818",
                                                                                                        height: "16px",
                                                                                                        padding: "4px",
                                                                                                        whiteSpace:"nowrap",
                                                                                                        width:"100px"
                                                                                                    }}>
                                                                                                        {item.name}
                                                                                                    </Typography>
                                                                                                </div>
                                                                                            </HtmlTooltip>
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell width="15%" style={index>6?noborderStyle:borderStyle}>
                                                                                        <Typography className={item.numR>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                            textAlign: "right",
                                                                                            color: index===0?"grey":(index>1?(item.numR>0?"rgb(251, 154, 40)":"#181818"):(item.numR>0?"rgb(251, 154, 40)":"#181818")),
                                                                                            paddingLeft: "0px",
                                                                                            padding: "0px",

                                                                                            height:"16px",
                                                                                            fontWeight:item.numR>0?"bold":"400"
                                                                                        }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"amber","Header_Health_Stat_"+(index===1?"M":(index===2?"D":(index===3?"T":(index===4?"F":(index===5?"O":(index===6?"I":(index===7?"E":"")))))))+"_2")}>
                                                                                            {item.numR>0?
                                                                                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                                    <div>
                                                                                                        <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numROS===100?"Review Completed":"Review progress : "+item.numRS+" / "+ item.numR} </Typography>
                                                                                                    </div>
                                                                                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                                                    <div>
                                                                                                        {item.numR.toLocaleString()}
                                                                                                    </div>
                                                                                                </HtmlTooltip>
                                                                                                :
                                                                                                <div>{item.numR.toLocaleString()}</div>
                                                                                            }

                                                                                        </Typography>
                                                                                        {item.numR>0?
                                                                                            <Typography className={item.numR>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                textAlign: "right",
                                                                                                color: index===0?"grey":(index>1?(item.numR>0?"rgb(251, 154, 40)":"#181818"):(item.numR>0?"rgb(251, 154, 40)":"#181818")),
                                                                                                paddingLeft: "0px",
                                                                                                padding: "0px",
                                                                                                height:"2px",
                                                                                                marginBottom:"-6px",
                                                                                                fontWeight:item.numR>0?"bold":"400"
                                                                                            }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"amber","Header_Health_Stat_"+(index===1?"M":(index===2?"D":(index===3?"T":(index===4?"F":(index===5?"O":(index===6?"I":(index===7?"E":"")))))))+"_2")}>
                                                                                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                                    <div>
                                                                                                        <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numROS===100?"Review Completed":"Review progress : "+item.numRS+" / "+ item.numR} </Typography>
                                                                                                    </div>
                                                                                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                                                    <div>
                                                                                                        <LinearProgress variant="determinate" value={item.numROS} style={{height:"2px"}}/>
                                                                                                    </div>
                                                                                                </HtmlTooltip>

                                                                                            </Typography>
                                                                                        :""
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell width="15%" style={index>6?noborderStyle:borderStyle}>
                                                                                        <Typography className={item.numI>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                            textAlign: "right",
                                                                                            color: index===0?"grey":(index>0?(item.numI>0?"red":"#181818"):(item.numI>0?"red":"#181818")),
                                                                                            paddingLeft: "0px",
                                                                                            padding: "0px",
                                                                                            height:"16px",
                                                                                            fontWeight:item.numI>0?"bold":"400"
                                                                                        }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"red","Header_Health_Stat_"+(index===1?"M":(index===2?"D":(index===3?"T":(index===4?"F":(index===5?"O":(index===6?"I":(index===7?"E":"")))))))+"_1")}>
                                                                                            {item.numI>0?
                                                                                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                                    <div>
                                                                                                        <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numIOS===100?"Review Completed":"Review progress : "+item.numIS+" / "+ item.numI} </Typography>
                                                                                                    </div>
                                                                                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                                                    <div>
                                                                                                        {item.numI.toLocaleString()}
                                                                                                    </div>
                                                                                                </HtmlTooltip>
                                                                                                :
                                                                                                <div>{item.numI.toLocaleString()}</div>
                                                                                            }

                                                                                        </Typography>
                                                                                        {item.numI>0?
                                                                                            <Typography className={item.numI>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                textAlign: "right",
                                                                                                color: index===0?"grey":(index>0?(item.numI>0?"red":"#181818"):(item.numI>0?"red":"#181818")),
                                                                                                paddingLeft: "0px",
                                                                                                padding: "0px",
                                                                                                height:"2px",
                                                                                                marginBottom:"-6px",
                                                                                                fontWeight:item.numI>0?"bold":"400"
                                                                                            }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"red","Header_Health_Stat_"+(index===1?"M":(index===2?"D":(index===3?"T":(index===4?"F":(index===5?"O":(index===6?"I":(index===7?"E":"")))))))+"_1")}>
                                                                                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                                    <div>
                                                                                                        <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numIOS===100?"Review Completed":"Review progress : "+item.numIS+" / "+ item.numI} </Typography>
                                                                                                    </div>
                                                                                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                                                    <div>
                                                                                                        <LinearProgress variant="determinate" value={item.numIOS} style={{height:"2px"}}/>
                                                                                                    </div>
                                                                                                </HtmlTooltip>

                                                                                            </Typography>
                                                                                            :""
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell width="15%" style={index>6?noborderStyle1:borderStyle1}>
                                                                                        <Typography className={item.numF>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                            textAlign: "right",
                                                                                            color: index===0?"grey":(index>0?(item.numF===0?"red":(item.numF===1?"rgb(251, 154, 40)":"#181818")):(item.numF===1?"rgb(251, 154, 40)":"#181818")),
                                                                                            paddingLeft: "4px",
                                                                                            padding: index>0?"0px":"4px",
                                                                                            height:"5px",
                                                                                            fontWeight:item.numF>0?"bold":"400",
                                                                                            backgroundColor: (index>0?(item.numF===0?"red":(item.numF===1?"rgb(251, 154, 40)":"green")):"")

                                                                                        }}>

                                                                                        </Typography>
                                                                                    </TableCell>

                                                                                </TableRow>

                                                                            )
                                                                        ):(
                                                                            (

                                                                                <TableRow key={item.id} className={"aqa-violation-folder"} style={{height:"18px"}} onClick={(e)=>this.handleReviewAspectStat(e, index,item)}>
                                                                                    <TableCell width="55%" style={index>6?noborderStyle:borderStyle}>
                                                                                        {index === 0 ? <div>
                                                                                                <Typography className="aqa-text-bold-11" variant="inherit"
                                                                                                            style={{
                                                                                                                textAlign: "left",
                                                                                                                color: index === 0 ? "grey" : "#181818",
                                                                                                                height: "16px",
                                                                                                                padding: "4px"
                                                                                                            }}>
                                                                                                    {item.name}
                                                                                                </Typography>
                                                                                            </div> :
                                                                                            <HtmlTooltip enterDelay={500} key={item.id}
                                                                                                         title={<React.Fragment>
                                                                                                             <div>
                                                                                                                 <Typography color="inherit"
                                                                                                                             className="aqa-text-action">{item.description}</Typography>
                                                                                                             </div>

                                                                                                         </React.Fragment>} arrow={"true"}
                                                                                                         placement="bottom-start">
                                                                                                <div>
                                                                                                    <Typography className="aqa-text-bold-11"
                                                                                                                variant="inherit" style={{
                                                                                                        textAlign: "left",
                                                                                                        color: index === 0 ? "grey" : "#181818",
                                                                                                        height: "16px",
                                                                                                        padding: "4px",
                                                                                                        width: "140px"
                                                                                                    }}>
                                                                                                        {item.name}
                                                                                                    </Typography>
                                                                                                </div>
                                                                                            </HtmlTooltip>
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell width="15%" style={index>6?noborderStyle:borderStyle}>
                                                                                        <Typography className={item.numR>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                            textAlign: "right",
                                                                                            color: index===0?"grey":"#181818",
                                                                                            paddingLeft: "4px",
                                                                                            padding: "4px",
                                                                                            height:"16px",
                                                                                        }}>
                                                                                            {item.numR.toLocaleString()}

                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell width="15%" style={index>6?noborderStyle:borderStyle}>
                                                                                        <Typography className={item.numI>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                            textAlign: "right",
                                                                                            color: index===0?"grey":"#181818",
                                                                                            paddingLeft: "4px",
                                                                                            padding: "4px",
                                                                                            height:"16px",
                                                                                        }}>
                                                                                            {item.numI.toLocaleString()}

                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell width="15%" style={index>6?noborderStyle:borderStyle}>
                                                                                        <Typography className={item.numF>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                            textAlign: "right",
                                                                                            color: index===0?"grey":"#181818",
                                                                                            paddingLeft: "4px",
                                                                                            padding: "4px",
                                                                                            height:"16px",
                                                                                        }}>
                                                                                            {item.numF.toLocaleString()}

                                                                                        </Typography>
                                                                                    </TableCell>

                                                                                </TableRow>

                                                                            )
                                                                        ))

                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                        <Grid container spacing={0} direction="row" justify="space-between"
                                                              alignItems="center"
                                                              style={{padding: 0}}>
                                                            {/*<Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                <div style={{background: "none",marginTop:"-18px",marginLeft:"8px"}}>
                                                                    <Table id={this.props.dataFromGrid.id+"_statstable"} cellSpacing={0} aria-sort="ascending" style={{}}>
                                                                        <colgroup>
                                                                            <col width="60%" />
                                                                            <col width="15%" />
                                                                            <col width="25%" />
                                                                        </colgroup>
                                                                        <TableHead>

                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {this.state.stats.filter((item,index)=>{return index<3;}).map((item,index)=>
                                                                                (index<3)?(

                                                                                        <TableRow key={item.id} className={"aqa-violation-folder"} style={{height:"18px"}} onClick={(e)=>this.handleAspectStat(e, index,item)}>
                                                                                            <TableCell width="60%" style={{padding:"4px"}}>
                                                                                                {index === 0 ? <div>
                                                                                                        <Typography
                                                                                                            className="aqa-text-bold-11"
                                                                                                            variant="inherit"
                                                                                                            style={{
                                                                                                                textAlign: "left",
                                                                                                                color: index === 0 ? "grey" : "#181818",
                                                                                                                height: "16px",
                                                                                                                padding: "4px"
                                                                                                            }}>
                                                                                                            {item.name}
                                                                                                        </Typography>
                                                                                                    </div> :
                                                                                                    <HtmlTooltip key={item.id}
                                                                                                                 title={
                                                                                                                     <React.Fragment>
                                                                                                                         <div>
                                                                                                                             <Typography
                                                                                                                                 color="inherit"
                                                                                                                                 className="aqa-text-action">{item.numRules > 0 && index === 0 ? "Click to edit column rules" : (item.numRules > 0 && index === 1 ? "Click to view " + item.numRules.toLocaleString() + " Red row Alerts" : (item.numRules > 0 && index === 2 ? "Click to view " + item.numRules.toLocaleString() + " Amber row Alerts" : (item.numRules > 0 && index === 3 ? "Click to view " + item.numRules + " Amber row Alerts" : "No Alerts")))}</Typography>
                                                                                                                         </div>

                                                                                                                     </React.Fragment>}
                                                                                                                 arrow={"true"}
                                                                                                                 placement="bottom-start">
                                                                                                        <div>
                                                                                                            <Typography
                                                                                                                className="aqa-text-bold-11"
                                                                                                                variant="inherit"
                                                                                                                style={{
                                                                                                                    textAlign: "left",
                                                                                                                    color: index === 0 ? "grey" : "#181818",
                                                                                                                    height: "16px",
                                                                                                                    padding: "4px"
                                                                                                                }}>
                                                                                                                {item.name}
                                                                                                            </Typography>
                                                                                                        </div>
                                                                                                    </HtmlTooltip>
                                                                                                }
                                                                                            </TableCell>
                                                                                            <TableCell width="15%" style={{padding:"4px"}}>
                                                                                                <Typography className={item.numRules>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                    textAlign: "right",
                                                                                                    color: index===0?"grey":(index===1?(item.numRules>0?"red":"#181818"):(index===2?(item.numRules>0?"rgb(251, 154, 40)":"#181818"):"#181818")),
                                                                                                    paddingLeft: "4px",
                                                                                                    padding: "4px",
                                                                                                    height:"16px",
                                                                                                    fontWeight:item.numRules>0?"bold":"400"
                                                                                                }}>
                                                                                                    {item.numRules.toLocaleString()}

                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell width="25%" style={{padding:"4px"}}>
                                                                                                <Typography className="aqa-text-bold-11" variant="inherit" style={{
                                                                                                    textAlign: "right",
                                                                                                    color: index===0?"grey":"#181818",
                                                                                                    paddingLeft: "4px",
                                                                                                    padding: "4px",
                                                                                                    height:"16px",
                                                                                                }}>
                                                                                                    {item.percentage}

                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    ):
                                                                                    (

                                                                                        <TableRow key={item.id} className={"aqa-violation-folder"} style={{height:"18px"}} onClick={(e)=>this.handleAspectStat(e, index,item)}>
                                                                                            <TableCell width="60%" style={{padding:"4px",border:"none"}}>
                                                                                                <HtmlTooltip key={item.id} title={<React.Fragment>
                                                                                                    <div>
                                                                                                        <Typography color="inherit" className="aqa-text-action">{item.numRules>0?item.numRules.toLocaleString()+" rows":"There are 0 rows"}</Typography>
                                                                                                    </div>

                                                                                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                                                    <div>
                                                                                                        <Typography className="aqa-text-bold-11" variant="inherit" style={{
                                                                                                            textAlign: "left",
                                                                                                            color: index===0?"grey":"#181818",
                                                                                                            height:"16px",
                                                                                                        }}>
                                                                                                            {item.name}
                                                                                                        </Typography>
                                                                                                    </div>
                                                                                                </HtmlTooltip>
                                                                                            </TableCell>
                                                                                            <TableCell width="15%" style={{padding:"4px",border:"none"}}>
                                                                                                <Typography className={item.numRules>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                    textAlign: "right",
                                                                                                    color: index===0?"grey":"#181818",
                                                                                                    paddingLeft: "4px",
                                                                                                    padding: "4px",
                                                                                                    height:"16px",
                                                                                                }}>
                                                                                                    {item.numRules.toLocaleString()}

                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell width="25%" style={{padding:"4px",border:"none"}}>
                                                                                                <Typography className="aqa-text-bold-11" variant="inherit" style={{
                                                                                                    textAlign: "right",
                                                                                                    color: index===0?"grey":"#181818",
                                                                                                    paddingLeft: "4px",
                                                                                                    padding: "4px",
                                                                                                    height:"16px",
                                                                                                }}>
                                                                                                    {item.percentage}

                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                        </TableRow>

                                                                                    )
                                                                            )}
                                                                        </TableBody>
                                                                    </Table>
                                                                </div>
                                                            </Grid>*/}

                                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                {
                                                                    this.state.heatmapData
                                                                        ?
                                                                        <div style={{marginLeft:"0px",marginTop:"10px",textAlign:"left"}}>

                                                                            <div>
                                                                                <Heatmap
                                                                                    data={this.state.heatmapData}
                                                                                    type={Heatmap.OVERALL_HEATMAP_NAME}
                                                                                    scale={AqaComponent.HEATMAP_STAMP_SCALE *2}
                                                                                    numRows={this.state.numRows}
                                                                                    numCols={this.state.numCols}
                                                                                    clickerCallback = {
                                                                                        (x, w, y, h, n, amber, red, type) =>
                                                                                        {
                                                                                            if (this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot) this.props.dataFromMain.props.dataFromRoot.props.dataFromRoot.handleSnapshotFilter2
                                                                                            (
                                                                                                this.props.dataFromGrid,
                                                                                                this.state.latestSnapshot,
                                                                                                type,
                                                                                                amber,
                                                                                                red,
                                                                                                [n, x, y, w, h]
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <img src={AqaGridIcon} width="95px" alt="grid"/>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                            </Grid>
                                                        <Grid container spacing={0} direction="row" justify="space-between"
                                                              alignItems="center"
                                                              style={{padding: 0, marginTop: "10px"}}>

                                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                <div style={{background: "none"}}>
                                                                    <Table id={this.props.dataFromGrid.id+"_statstable"} cellSpacing={0} aria-sort="ascending" style={{}}>
                                                                        <colgroup>
                                                                            <col width="25%" />
                                                                            <col width="15%" />
                                                                            <col width="15%" />
                                                                            <col width="15%" />
                                                                            <col width="15%" />
                                                                            <col width="15%" />
                                                                        </colgroup>
                                                                        <TableHead>

                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {this.state.reviewStats.map((item,index)=>
                                                                                (index===1 || index === 2)?(

                                                                                        <TableRow key={item.id} className={"aqa-violation-folder"} style={{height:"18px"}}>
                                                                                            <TableCell width="25%" style={{padding:"4px",border:index>2?"none":""}}>
                                                                                                {index === 0 ? <div>
                                                                                                        <Typography
                                                                                                            className="aqa-text-bold-11"
                                                                                                            variant="inherit" style={{
                                                                                                            textAlign: "left",
                                                                                                            color: index === 0 ? "grey" : "#181818",
                                                                                                            height: "16px",
                                                                                                            padding: "4px",
                                                                                                            whiteSpace:"nowrap",
                                                                                                            width:"100px"
                                                                                                        }} onClick={(e)=>this.handleAspectStat(e, index,item,-1,item.numRules,"Aspect_"+index+"_-1")}>
                                                                                                            {item.name}
                                                                                                        </Typography>
                                                                                                    </div> :
                                                                                                    <HtmlTooltip key={item.id}
                                                                                                                 title={
                                                                                                                     <React.Fragment>
                                                                                                                         <div>
                                                                                                                             <Typography
                                                                                                                                 color="inherit"
                                                                                                                                 className="aqa-text-action">{item.numRules > 0 && index === 0 ? "Click to edit column rules" : (item.numRules > 0 && index === 1 ? "Click to view " + item.numRules.toLocaleString() + " Red cell Alerts" : (item.numR > 0 && index === 2 ? "Click to view " + item.numRules.toLocaleString() + " Amber cell Alerts" : (item.numRules > 0 && index === 3 ? "Click to view " + item.numRules + " Amber cell Alerts" : "No Alerts")))}</Typography>
                                                                                                                         </div>

                                                                                                                     </React.Fragment>}
                                                                                                                 arrow={"true"}
                                                                                                                 placement="bottom-start">
                                                                                                        <div>
                                                                                                            <Typography
                                                                                                                className="aqa-text-bold-11"
                                                                                                                variant="inherit"
                                                                                                                style={{
                                                                                                                    textAlign: "left",
                                                                                                                    color: index === 0 ? "grey" : "#181818",
                                                                                                                    height: "16px",
                                                                                                                    padding: "4px"
                                                                                                                }} onClick={(e)=>this.handleAspectStat(e, index,item,-1,item.numRules,"Aspect_"+index+"_-1")}>
                                                                                                                {item.name}
                                                                                                            </Typography>
                                                                                                        </div>
                                                                                                    </HtmlTooltip>
                                                                                                }
                                                                                            </TableCell>
                                                                                            <TableCell width="15%" style={{padding:"4px",border:index>2?"none":""}}>
                                                                                                <Typography className={item.numRules>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                    textAlign: "right",
                                                                                                    color: index===0?"grey":(index===1?(item.numRules>0?"red":"#181818"):(index===2?(item.numRules>0?"rgb(251, 154, 40)":"#181818"):"#181818")),
                                                                                                    paddingLeft: "4px",
                                                                                                    padding: "4px",
                                                                                                    height:"16px",
                                                                                                    fontWeight:item.numRules>0?"bold":"400"
                                                                                                }} onClick={(e)=>this.handleAspectStat(e, index,item,-1,item.numRules,"Aspect_"+index+"_-1")}>
                                                                                                    {item.numRules.toLocaleString()}

                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell width="15%" style={{padding:"4px",border:index>2?"none":""}}>
                                                                                                <Typography className="aqa-text-bold-11" variant="inherit" style={{
                                                                                                    textAlign: "right",
                                                                                                    color: index===0?"grey":"#181818",
                                                                                                    paddingLeft: "4px",
                                                                                                    padding: "4px",
                                                                                                    height:"16px",
                                                                                                }} onClick={(e)=>this.handleAspectStat(e, index,item,-1,item.numRules,"Aspect_"+index+"_-1")}>
                                                                                                    {item.percentage}

                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell width="15%" style={{padding:"4px",border:index>2?"none":""}}>
                                                                                                <Typography className={item.numR>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                    textAlign: "right",
                                                                                                    color: index===0?"grey":(index===1?(item.numR>0?"red":"#181818"):(item.numR>0?"rgb(251, 154, 40)":"#181818")),
                                                                                                    paddingLeft: "4px",
                                                                                                    padding: "4px",
                                                                                                    height:"16px",
                                                                                                    fontWeight:item.numR>0?"bold":"400"
                                                                                                }} onClick={(e)=>this.handleAspectStat(e, index,item,0,item.numR,"Aspect_"+index+"_0")}>
                                                                                                    {item.numR.toLocaleString()}

                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell width="15%" style={{padding:"4px",border:index>2?"none":""}}>
                                                                                                <Typography className={item.numI>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                    textAlign: "right",
                                                                                                    color: index===0?"grey":(index===1?(item.numI>0?"red":"#181818"):(item.numI>0?"rgb(251, 154, 40)":"#181818")),
                                                                                                    paddingLeft: "4px",
                                                                                                    padding: "4px",
                                                                                                    height:"16px",
                                                                                                    fontWeight:item.numI>0?"bold":"400"
                                                                                                }} onClick={(e)=>this.handleAspectStat(e, index,item,1,item.numI,"Aspect_"+index+"_1")}>
                                                                                                    {item.numI.toLocaleString()}

                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell width="15%" style={{padding:"4px",border:index>2?"none":""}}>
                                                                                                <Typography className={item.numF>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                    textAlign: "right",
                                                                                                    color: index===0?"grey":(index===1?(item.numF>0?"red":"#181818"):(item.numF>0?"rgb(251, 154, 40)":"#181818")),
                                                                                                    paddingLeft: "4px",
                                                                                                    padding: "4px",
                                                                                                    height:"16px",
                                                                                                    fontWeight:item.numF>0?"bold":"400"
                                                                                                }} onClick={(e)=>this.handleAspectStat(e, index,item,2,item.numF,"Aspect_"+index+"_2")}>
                                                                                                    {item.numF.toLocaleString()}

                                                                                                </Typography>
                                                                                            </TableCell>

                                                                                        </TableRow>
                                                                                    ):(
                                                                                    (index===3)?(
                                                                                            <TableRow key={item.id} className={"aqa-violation-folder"} style={{height:"6px"}} onClick={(e)=>this.handleReviewOpen(e)}>
                                                                                                <TableCell width="25%" style={{padding:"0px 4px",border:index>2?"none":""}}>
                                                                                                    {index===3?
                                                                                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                                            <div>
                                                                                                                <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"14px",fontFamily:"Montserrat"}}>{reviewComplete?"Review Completed: Click to see preview/share":"Review progress – this status bar shows you the progress of your review of the alerts shown against your data upload"} </Typography>
                                                                                                            </div>

                                                                                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                                                            <Typography className="aqa-text-bold-11" variant="inherit" style={{
                                                                                                            textAlign: "left",
                                                                                                            color: index===0?"grey":"#181818",
                                                                                                            height:"8px",
                                                                                                            padding:"0px 4px",
                                                                                                            marginTop:"-4px"
                                                                                                        }} >
                                                                                                            {item.name}
                                                                                                        </Typography>
                                                                                                        </HtmlTooltip>:
                                                                                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                                            <div>
                                                                                                                <Typography color="inherit" className="aqa-text-action">{item.numR>0?""+item.numR.toLocaleString()+" rows":"There are 0 rows"}</Typography>
                                                                                                            </div>

                                                                                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                                                            <div>
                                                                                                                <Typography className="aqa-text-bold-11" variant="inherit" style={{
                                                                                                                    textAlign: "left",
                                                                                                                    color: index===0?"grey":"#181818",
                                                                                                                    height:"16px",
                                                                                                                    padding:"4px"
                                                                                                                }} >
                                                                                                                    {item.name}
                                                                                                                </Typography>
                                                                                                            </div>
                                                                                                        </HtmlTooltip>}
                                                                                                </TableCell>
                                                                                                <TableCell width={"75%"} colSpan={5} style={{padding:"4px",border:index>2?"none":""}}>
                                                                                                    <Typography className={item.numRules>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                        textAlign: "right",
                                                                                                        color: index===0?"grey":(index===1?(item.numRules>0?"red":"#181818"):(item.numRules>0?"rgb(251, 154, 40)":"#181818")),
                                                                                                        paddingLeft: "4px",
                                                                                                        padding: "4px",
                                                                                                        height:"5px",
                                                                                                        fontWeight:item.numRules>0?"bold":"400"
                                                                                                    }}>
                                                                                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                                                            <div>
                                                                                                                <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"14px",fontFamily:"Montserrat"}}>{reviewComplete?"Review Completed: Click to see preview/share":"Review progress – this status bar shows you the progress of your review of the alerts shown against your data upload"} </Typography>
                                                                                                            </div>
                                                                                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                                                            <div>
                                                                                                                <LinearProgress variant="determinate" value={(100 - ((item.numR/item.numRules)*100))} style={{height:"5px"}}/>
                                                                                                            </div>
                                                                                                        </HtmlTooltip>

                                                                                                    </Typography>
                                                                                                </TableCell>

                                                                                            </TableRow>
                                                                                        ):
                                                                                    (

                                                                                        <TableRow key={item.id} className={"aqa-violation-folder"} style={{height:"18px"}} onClick={(e)=>this.handleAspectStat(e, index,item,-1,0)}>
                                                                                            <TableCell width="25%" style={{padding:"4px"}}>
                                                                                                {item.numRules > 0 ?
                                                                                                    <HtmlTooltip
                                                                                                        key={item.id}
                                                                                                        title={
                                                                                                            <React.Fragment>
                                                                                                                <div>
                                                                                                                    <Typography
                                                                                                                        color="inherit"
                                                                                                                        className="aqa-text-action">{item.numRules > 0 ? item.numRules.toLocaleString() + " rows" : "There are 0 rows"}</Typography>
                                                                                                                </div>

                                                                                                            </React.Fragment>}
                                                                                                        arrow={"true"}
                                                                                                        placement="bottom-start">
                                                                                                        <div>
                                                                                                            <Typography
                                                                                                                className="aqa-text-bold-11"
                                                                                                                variant="inherit"
                                                                                                                style={{
                                                                                                                    textAlign: "left",
                                                                                                                    color: index === 0 ? "grey" : "#181818",
                                                                                                                    height: "16px",
                                                                                                                    padding: "4px"
                                                                                                                }}>
                                                                                                                {item.name}
                                                                                                            </Typography>
                                                                                                        </div>
                                                                                                    </HtmlTooltip> :
                                                                                                    <Typography
                                                                                                        className="aqa-text-bold-11"
                                                                                                        variant="inherit"
                                                                                                        style={{
                                                                                                            textAlign: "left",
                                                                                                            color: index === 0 ? "grey" : "#181818",
                                                                                                            height: "16px",
                                                                                                            padding: "4px"
                                                                                                        }}>
                                                                                                        {item.name}
                                                                                                    </Typography>
                                                                                                }
                                                                                            </TableCell>
                                                                                            <TableCell width="15%" style={{padding:"4px"}}>
                                                                                                <Typography className={item.numRules>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                    textAlign: "right",
                                                                                                    color: index===0?"grey":(index===1?(item.numRules>0?"red":"#181818"):(index===2?(item.numRules>0?"rgb(251, 154, 40)":"#181818"):"#181818")),
                                                                                                    paddingLeft: "4px",
                                                                                                    padding: "4px",
                                                                                                    height:"16px",
                                                                                                    fontWeight:item.numRules>0?"bold":"400"
                                                                                                }}>
                                                                                                    {item.numRules.toLocaleString()}

                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell width="15%" style={{padding:"4px"}}>
                                                                                                <Typography className="aqa-text-bold-11" variant="inherit" style={{
                                                                                                    textAlign: "right",
                                                                                                    color: index===0?"grey":"#181818",
                                                                                                    paddingLeft: "4px",
                                                                                                    padding: "4px",
                                                                                                    height:"16px",
                                                                                                }}>
                                                                                                    {item.percentage}

                                                                                                </Typography>
                                                                                            </TableCell>

                                                                                            <TableCell width="15%" style={{padding:"4px"}}>
                                                                                                <Typography className={item.numR>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                    textAlign: "right",
                                                                                                    color: index===0?"grey":"#181818",
                                                                                                    paddingLeft: "4px",
                                                                                                    padding: "4px",
                                                                                                    height:"16px",
                                                                                                }}>
                                                                                                    {item.numR.toLocaleString()}

                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell width="15%" style={{padding:"4px"}}>
                                                                                                <Typography className={item.numI>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                    textAlign: "right",
                                                                                                    color: index===0?"grey":"#181818",
                                                                                                    paddingLeft: "4px",
                                                                                                    padding: "4px",
                                                                                                    height:"16px",
                                                                                                }}>
                                                                                                    {item.numI.toLocaleString()}

                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell width="15%" style={{padding:"4px"}}>
                                                                                                <Typography className={item.numF>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                                                    textAlign: "right",
                                                                                                    color: index===0?"grey":"#181818",
                                                                                                    paddingLeft: "4px",
                                                                                                    padding: "4px",
                                                                                                    height:"16px",
                                                                                                }}>
                                                                                                    {item.numF.toLocaleString()}

                                                                                                </Typography>
                                                                                            </TableCell>

                                                                                        </TableRow>

                                                                                    ))
                                                                            )}
                                                                        </TableBody>
                                                                    </Table>
                                                                </div>
                                                            </Grid>

                                                        </Grid>



                                        </CardContent>
                                        <CardActions disablespacing="true" style={{padding:"5px"}} >
                                            <div>
                                                <Dialog
                                                    open={this.state.showReviewPop}
                                                    //onClose={this.handleCloseMessage}
                                                    aria-labelledby="draggable-dialog-title"
                                                    maxWidth="lg"
                                                    PaperComponent={PaperComponent}
                                                    //hideBackdrop={true}
                                                >
                                                    <DialogTitle id="draggable-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px",width:"600px",cursor: "move"}}>
                                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                                            Review Status: Completed
                                                        </Typography>
                                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                                            <IconButton className="aqa-button" onClick={(e)=>this.handleReviewClose(e)} style={{padding:"0px"}} >
                                                                <div className="aqa-g-icon" style={{width:"20px"}}>
                                                                    <CloseIcon width="20px" />
                                                                </div>
                                                            </IconButton>
                                                        </Typography>
                                                    </DialogTitle>
                                                    <DialogContent align="center" style={{padding: 0,width:"600px"}}>

                                                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                              style={{padding: "16px", marginTop: 0}}>
                                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                                                    {("")
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                <Typography variant="inherit" color="inherit" className="aqa-text-bold-14" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"0px"}}>
                                                                    You have now completed your review of the AQA identified alerts on your
                                                                    uploaded file (and you have chosen to either ignore or have identified fixes on
                                                                    all alerts)
                                                                </Typography>
                                                                <Typography variant="inherit" color="inherit" className="aqa-text-bold-14" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"4px 0px",paddingTop:"12px"}}>
                                                                    It is time to choose what to do next..
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                <Typography variant="inherit" color="inherit" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                                                    <Button onClick={(e)=>this.handlePreviewReport(e)} variant="contained"
                                                                            color="primary"
                                                                            align="left"
                                                                            className="aqa-action-button"
                                                                            style={{
                                                                                marginTop: 8,
                                                                                marginLeft: 8,
                                                                                color: '#4cadc4',
                                                                                backgroundColor: 'white',
                                                                                border: '1px solid #4cadc4',
                                                                                fontSize: '0.7rem',
                                                                                padding: '3px'
                                                                            }}>
                                                                        View Fixes
                                                                    </Button>
                                                                    <Button onClick={(e)=>this.handleShare(e)} variant="contained"
                                                                            color="primary"
                                                                            align="left"
                                                                            className="aqa-action-button"
                                                                            style={{
                                                                                marginTop: 8,
                                                                                marginLeft: 8,
                                                                                color: '#4cadc4',
                                                                                backgroundColor: 'white',
                                                                                border: '1px solid #4cadc4',
                                                                                fontSize: '0.7rem',
                                                                                padding: '3px'
                                                                            }}>
                                                                        Share Fixes
                                                                    </Button>

                                                                </Typography>

                                                            </Grid>


                                                        </Grid>


                                                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                                              style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",width:"600px"}}>

                                                            <Grid item xs={1} sm={1} lg={1} xl={1}>
                                                                <Button onClick={(e)=>this.handleReviewClose(e)} variant="contained"
                                                                        color="primary"
                                                                        align="left"
                                                                        className="aqa-action-button"
                                                                        style={{
                                                                            marginTop: 8,
                                                                            marginLeft: 8,
                                                                            color: '#4cadc4',
                                                                            backgroundColor: 'white',
                                                                            border: '1px solid #4cadc4',
                                                                            fontSize: '0.7rem',
                                                                            padding: '3px'
                                                                        }}>
                                                                    Close
                                                                </Button>


                                                            </Grid>

                                                            <Grid item xs={9} sm={9} lg={9} xl={9}>
                                                            </Grid>
                                                            <Grid item xs={2} sm={2} lg={2} xl={2}>

                                                            </Grid>

                                                        </Grid>
                                                    </DialogContent>
                                                </Dialog>
                                            </div>
                                            <div>
                                                <Dialog
                                                    open={this.state.showPreviewReport}
                                                    //onClose={this.handleCloseMessage}
                                                    aria-labelledby="draggable-dialog-title"
                                                    maxWidth="lg"
                                                    PaperComponent={PaperComponent}
                                                    //hideBackdrop={true}
                                                >
                                                    <DialogTitle id="draggable-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px",width:"900px",cursor: "move"}}>
                                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                                            Report Preview
                                                        </Typography>
                                                        <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                                            <IconButton className="aqa-button" onClick={(e)=>this.handleReviewClosePop(e)} style={{padding:"0px"}} >
                                                                <div className="aqa-g-icon" style={{width:"20px"}}>
                                                                    <CloseIcon width="20px" />
                                                                </div>
                                                            </IconButton>
                                                        </Typography>
                                                    </DialogTitle>
                                                    <DialogContent align="center" style={{padding: 0,width:"900px",maxHeight:"450px",height:"450px",overflow:"auto",scrollbarGutter:"stable"}}>

                                                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                                              style={{padding: "8px 0px", marginTop: 0}}>
                                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                                                    {("")
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                <Typography variant="inherit" color="inherit" className="aqa-text-bold-11" style={{textAlign:"center",fontSize:"0.7rem",color:"#006",padding:"0px"}}>
                                                                    <div style={{}}>
                                                                        <div id={"render-report"} style={{padding:"0px 8px"}}></div>
                                                                    </div>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                <Typography variant="inherit" color="inherit" style={{textAlign:"center",fontSize:"0.8rem",color:"#006",padding:"0px"}}>

                                                                </Typography>

                                                            </Grid>


                                                        </Grid>

                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                                              style={{padding: 0, marginTop: 0,marginLeft:0, marginBottom: 0,borderTop:"1px solid #ccc",width:"890px"}}>

                                                            <Grid item xs={1} sm={1} lg={1} xl={1}>
                                                                <Button onClick={(e)=>this.handleReviewClosePop(e)} variant="contained"
                                                                        color="primary"
                                                                        align="left"
                                                                        className="aqa-action-button"
                                                                        style={{
                                                                            marginTop: 8,
                                                                            marginLeft: 8,
                                                                            color: '#4cadc4',
                                                                            backgroundColor: 'white',
                                                                            border: '1px solid #4cadc4',
                                                                            fontSize: '0.7rem',
                                                                            padding: '3px'
                                                                        }}>
                                                                    Close
                                                                </Button>


                                                            </Grid>

                                                            <Grid item xs={9} sm={9} lg={9} xl={9}>
                                                            </Grid>
                                                            <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                                <Typography variant="inherit" color="inherit" style={{textAlign:"right",fontSize:"0.8rem",color:"#006",padding:"0px"}}>
                                                                    <Button onClick={(e)=>this.handleShare(e)} variant="contained"
                                                                            color="primary"
                                                                            align="right"
                                                                            className="aqa-action-button"
                                                                            style={{
                                                                                marginTop: 8,
                                                                                marginLeft: 8,
                                                                                color: '#4cadc4',
                                                                                backgroundColor: 'white',
                                                                                border: '1px solid #4cadc4',
                                                                                fontSize: '0.7rem',
                                                                                padding: '3px'
                                                                            }}>
                                                                        Share Fixes
                                                                    </Button>
                                                                </Typography>
                                                            </Grid>

                                                        </Grid>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                            {/*<Grid container spacing={0} direction="row" justify="space-between"
                                                  alignItems="center"
                                                  style={{padding: 0, marginTop: 0}}>

                                                <Grid item xs={7} sm={7} lg={7} xl={7}>

                                                    <Typography style={{marginTop:"-4px"}}>
                                                        {this.state.latestSnapshot === null || this.state.latestSnapshot.status === "Pending"?(
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <Typography color="inherit" className="aqa-text-action">This Data Upload is under review</Typography>

                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <IconButton className="aqa-button" style={{padding:"0px"}}>

                                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                                        textAlign: "left",
                                                                        paddingLeft: "4px",
                                                                        marginTop: "-4px",
                                                                        width:"100px",
                                                                        color:"darkgrey"
                                                                    }}>
                                                                        Review Status:
                                                                    </Typography>
                                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                                        textAlign: "left",
                                                                        paddingLeft: "4px",
                                                                        marginTop: "-4px",
                                                                        width:"140px"
                                                                    }}>
                                                                        {this.state.latestSnapshot === null?"":(this.state.latestSnapshot.status==="Pending"?"Under Review":(this.state.latestSnapshot.status==="Approved"?"Review Completed":(this.state.latestSnapshot.status==="Rejected"?"Shared with Team":this.state.latestSnapshot.status)))}
                                                                    </Typography>
                                                                </IconButton>
                                                            </HtmlTooltip>
                                                            ):
                                                            (
                                                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                    {this.state.latestSnapshot.status === "Approved" ?(
                                                                        <div>
                                                                            <Typography color="inherit" className="aqa-text-action">Completed By: {this.state.latestSnapshot.statusChangedBy}</Typography>
                                                                            <Typography color="inherit" className="aqa-text-action">Completed On: {(this.state.latestSnapshot.statusChangedDate !== null)? (moment(this.state.latestSnapshot.statusChangedDate ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                                                        </div>
                                                                    ):(this.state.latestSnapshot.status === "Rejected"?(
                                                                        <div>
                                                                            <Typography color="inherit" className="aqa-text-action">Shared By: {this.state.latestSnapshot.statusChangedBy}</Typography>
                                                                            <Typography color="inherit" className="aqa-text-action">Shared On: {(this.state.latestSnapshot.statusChangedDate !== null)? (moment(this.state.latestSnapshot.statusChangedDate ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                                                        </div>
                                                                    ):(
                                                                        <Typography color="inherit"></Typography>
                                                                    ))

                                                                    }

                                                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                    <IconButton className="aqa-button" style={{padding:"0px"}}>

                                                                        <Typography className="aqa-text-action" variant="inherit" style={{
                                                                            textAlign: "left",
                                                                            paddingLeft: "4px",
                                                                            marginTop: "-4px",
                                                                            width:"100px",
                                                                            color:"darkgrey"
                                                                        }}>
                                                                            Review Status:
                                                                        </Typography>
                                                                        <Typography className="aqa-text-action" variant="inherit" style={{
                                                                            textAlign: "left",
                                                                            paddingLeft: "4px",
                                                                            marginTop: "-4px",
                                                                            width:"140px"
                                                                        }}>
                                                                            {this.state.latestSnapshot === null?"":(this.state.latestSnapshot.status==="Pending"?"Under Review":(this.state.latestSnapshot.status==="Approved"?"Review Completed":(this.state.latestSnapshot.status==="Rejected"?"Shared with Team":this.state.latestSnapshot.status)))}
                                                                        </Typography>
                                                                    </IconButton>
                                                                </HtmlTooltip>
                                                            )}

                                                    </Typography>

                                                </Grid>
                                                    <Grid item xs={5} sm={5} lg={5} xl={5}>

                                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                            <Typography color="inherit" className="aqa-text-action">Usage Stats - 1k per block</Typography>

                                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                            <div>
                                    <span style={{float:"left",width:""}}>
                                        <Typography className="aqa-text-action" variant="inherit" style={{
                                            textAlign: "left",
                                            paddingLeft: "4px",
                                            marginTop: "-4px",
                                            color:"darkgrey"
                                        }}>
                                        Blocks Used:
                                    </Typography>
                                    </span>
                                    <span className="aqa-button" style={{padding:"0px",float:"left",width:"80px"}}>

                                        <Typography className="aqa-text-action" variant="inherit" style={{
                                            textAlign: "left",
                                            paddingLeft: "4px",
                                            marginTop: "-4px",
                                            width:"140px"
                                        }}>
                                            {this.props.dataFromGrid.example===true?"N/A":this.props.dataFromRoot.findUsageStat(this.props.dataFromGrid.id)}
                                        </Typography>
                                    </span>
                                                            </div>
                                                        </HtmlTooltip>*/}

                                                        {/*{this.state.latestSnapshot===null?(<></>):(
                                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                            <div>
                                                                <Typography color="inherit" className="aqa-text-action">Loaded By: {this.state.latestSnapshot.createdBy}</Typography>
                                                                <Typography color="inherit" className="aqa-text-action">Loaded On: {(this.state.latestSnapshot.dateCreated !== null)? (moment(this.state.latestSnapshot.dateCreated ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                                            </div>

                                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                            <IconButton className="aqa-button" style={{padding:"0px"}}>
                                                                <div className="aqa-list-icon" style={{width: "24px"}}>
                                                                    <EventIcon width="24px"/>
                                                                </div>
                                                                <Typography className="aqa-text-action" variant="inherit" color="inherit" style={{
                                                                    textAlign: "left",
                                                                    fontSize: "0.8rem",
                                                                    color: "#838183",
                                                                    paddingLeft: "4px",
                                                                    marginTop:"-6px",
                                                                    width:"160px"
                                                                }}>
                                                                    {(this.state.latestSnapshot.dateCreated !== null)? (moment(this.state.latestSnapshot.dateCreated ).format("YYYY/MM/DD HH:mm")):("")}

                                                                </Typography>
                                                            </IconButton>
                                                        </HtmlTooltip>
                                                        )}*/}

                                            {/* </Grid>

                                                </Grid>*/}

                                        </CardActions>

                                    </Card>
                    </div>
                );

            }
        }

}

AqaSourceGridRow.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSourceGridRow);
