/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/Person'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./Person'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.ShareLinkRequest = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.Person);
  }
}(this, function(ApiClient, Person) {
  'use strict';




  /**
   * The ShareLinkRequest model module.
   * @module model/ShareLinkRequest
   * @version 1.0
   */

  /**
   * Constructs a new <code>ShareLinkRequest</code>.
   * @alias module:model/ShareLinkRequest
   * @class
   */
  var exports = function() {
    var _this = this;














  };

  /**
   * Constructs a <code>ShareLinkRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ShareLinkRequest} obj Optional instance to populate.
   * @return {module:model/ShareLinkRequest} The populated <code>ShareLinkRequest</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('dbPerson')) {
        obj['dbPerson'] = Person.constructFromObject(data['dbPerson']);
      }
      if (data.hasOwnProperty('firstname')) {
        obj['firstname'] = ApiClient.convertToType(data['firstname'], 'String');
      }
      if (data.hasOwnProperty('lastname')) {
        obj['lastname'] = ApiClient.convertToType(data['lastname'], 'String');
      }
      if (data.hasOwnProperty('message')) {
        obj['message'] = ApiClient.convertToType(data['message'], 'String');
      }
      if (data.hasOwnProperty('password')) {
        obj['password'] = ApiClient.convertToType(data['password'], 'String');
      }
      if (data.hasOwnProperty('permissions')) {
        obj['permissions'] = ApiClient.convertToType(data['permissions'], 'String');
      }
      if (data.hasOwnProperty('resourceType')) {
        obj['resourceType'] = ApiClient.convertToType(data['resourceType'], 'String');
      }
      if (data.hasOwnProperty('snapshotDate')) {
        obj['snapshotDate'] = ApiClient.convertToType(data['snapshotDate'], 'String');
      }
      if (data.hasOwnProperty('snapshotId')) {
        obj['snapshotId'] = ApiClient.convertToType(data['snapshotId'], 'String');
      }
      if (data.hasOwnProperty('snapshotName')) {
        obj['snapshotName'] = ApiClient.convertToType(data['snapshotName'], 'String');
      }
      if (data.hasOwnProperty('sourceId')) {
        obj['sourceId'] = ApiClient.convertToType(data['sourceId'], 'String');
      }
      if (data.hasOwnProperty('to')) {
        obj['to'] = ApiClient.convertToType(data['to'], 'String');
      }
      if (data.hasOwnProperty('urlLink')) {
        obj['urlLink'] = ApiClient.convertToType(data['urlLink'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {module:model/Person} dbPerson
   */
  exports.prototype['dbPerson'] = undefined;
  /**
   * @member {String} firstname
   */
  exports.prototype['firstname'] = undefined;
  /**
   * @member {String} lastname
   */
  exports.prototype['lastname'] = undefined;
  /**
   * @member {String} message
   */
  exports.prototype['message'] = undefined;
  /**
   * @member {String} password
   */
  exports.prototype['password'] = undefined;
  /**
   * @member {String} permissions
   */
  exports.prototype['permissions'] = undefined;
  /**
   * @member {String} resourceType
   */
  exports.prototype['resourceType'] = undefined;
  /**
   * @member {String} snapshotDate
   */
  exports.prototype['snapshotDate'] = undefined;
  /**
   * @member {String} snapshotId
   */
  exports.prototype['snapshotId'] = undefined;
  /**
   * @member {String} snapshotName
   */
  exports.prototype['snapshotName'] = undefined;
  /**
   * @member {String} sourceId
   */
  exports.prototype['sourceId'] = undefined;
  /**
   * @member {String} to
   */
  exports.prototype['to'] = undefined;
  /**
   * @member {String} urlLink
   */
  exports.prototype['urlLink'] = undefined;



  return exports;
}));


