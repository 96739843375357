/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
    Checkbox,
    FormControlLabel, FormGroup/*, FormLabel,
    Radio, RadioGroup*/
} from "@material-ui/core";

//import FormControl from "@material-ui/core/FormControl"

//import AqaBoundaries         from "../../../model/AqaBoundaries"
import AqaQualityButton      from "./subelements/AqaQualityButton"
//import AqaRulesGraph         from "./subelements/AqaRulesGraph"
//import AqaRulesTextThreshold from "./subelements/AqaRulesTextThreshold"

import AqaRulesParent   from "./AqaRulesParent"

//import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
//import AqaOutline from '../../shared/outline/AqaOutline'

//const RADIO_BUTTONS = [[UNIQUE_PERCENTAGE, "% Unique"], [DUPLICATES_ALLOWED, "# Duplicates"], [REAPEATS_ALLOWED, "# Repeats"]];


//const createSliderWithTooltip = Slider.createSliderWithTooltip;
//const Range = createSliderWithTooltip(Slider.Range);


class AqaRulesNativeErrors extends AqaRulesParent
{
	constructor(props)
	{
		super(props, "NativeErrors");

		this.state =
		{

			currentSnapshot:"allsources", // What is this for??

			width:960,

		};


	} //



	render()
	{

		//const {width} = this.state;
		

		const enabled = this.isRulesEnabled();
		
		return (
			<div className={"snapshot-rule-servant-pane"}>
				<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

					<Grid item xs={12} sm={12} lg={12} xl={12}>
						<div style={{padding:"0px"}}>
							<AqaQualityButton which={6} />

							<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"16px",color:"#4CADC4",paddingLeft:"4px",fontFamily:"Montserrat",borderBottom:"0px solid #ccc",marginLeft:"55px"}}>
								<div id="aqa-number-slider" style={{paddingTop:"0px",paddingLeft:"0px",marginLeft:"8px",marginTop:"-45px"}}>
									<FormGroup row>

										<FormControlLabel
											control={
												<Checkbox
													checked={enabled}
													onChange={e => this.setRulesEnabled(e.target.checked)}
													color="primary"
												/>
											}
											label={
												(
													<Typography className={"aqa-text-action"} variant="title" align="left" style={{ }}>
														Set Native checks
													</Typography>
												)
											}
											labelPlacement="end"
										/>
										
										
									</FormGroup>
								</div>
							</Typography>
						</div>


					</Grid>

				</Grid>

			</div>
		);

	} // render

} ////

AqaRulesNativeErrors.propTypes =
{
	classes: PropTypes.object.isRequired
};

export default withStyles(AqaRulesParent.styles)(AqaRulesNativeErrors);



