/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MsContext from "../shared/masterservant/MsContext";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import {ReactComponent as LoadingIcon} from "../../images/grid.svg";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import AqaAccountUserListRow from "./AqaAccountUserListRow";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaAccountUsers extends AqaComponent {
    // No, seriously, we need this.
    static contextType = MsContext;
    constructor(props) {
        super(props);
        props.parent.accountUsers = this;
        props.parent.props.parent.accountUsers=this;
        this.state = {
            error: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:"account",
            selectedFolders:[],
            resetCheckbox:false,
            folders: [],
            tabvalue:0,
            users:[],
            account:null,
            selectedUsers:[],
            currentUser:null
        };
        this.handleUsers=this.handleUsers.bind(this);
        this.handleAccountDetails=this.handleAccountDetails.bind(this);
        this.handleUserSelectionReset=this.handleUserSelectionReset.bind(this);
        this.handleUserSelectionCheckReset=this.handleUserSelectionCheckReset.bind(this);
        this.handleUserDeleteReset=this.handleUserDeleteReset.bind(this);
        this.handleUserSelection=this.handleUserSelection.bind(this);
        this.handleUserDetails=this.handleUserDetails.bind(this);

    }

    handleUserSelectionReset(){
        this.setState({selectedUsers:[],resetCheckbox:true});
    }

    handleUserDeleteReset(){
        this.setState({selectedUsers:[],resetCheckbox:true});
    }

    handleUserSelectionCheckReset(){
        this.setState({resetCheckbox:false});
    }
    handleUserSelection(user,isSelected){
        var selectedUsers=this.state.selectedUsers;
        if(isSelected === true){
            if(user !== null && user !== undefined){
                var index = selectedUsers.indexOf(user);
                if(index<0){
                    selectedUsers.push(user);
                    this.setState({selectedUsers:selectedUsers});
                }
            }
        }
        else{
            if(user !== null && user !== undefined){
                index = selectedUsers.indexOf(user);
                if(index!==-1){
                    var newSelectedUsers = [];
                    for(var i=0;i<selectedUsers.length;i++){
                        var selectedUser = selectedUsers[i];
                        if(selectedUser !== user){
                            newSelectedUsers.push(selectedUser);
                        }
                    }
                    selectedUsers=newSelectedUsers;
                    this.setState({selectedUsers:selectedUsers});
                }
            }
        }
    }

    handleAccountDetails(){
//        var accountId=this.props.dataFromParent.props.dataFromRoot.state.accountId;
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountUsers.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    this.setState({account:accountObj});
                    this.handleUsers();
                    this.handleUserDetails();
                }
            }

        );
    }
    handleUserDetails(){
        AqaComponent.userBackend.meUsingGET(
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the user details from the server was encountered.",
                    "AqaAccountUsers.handleUserDetails, backend call: " + error,this);
                else{
                    this.setState({currentUser:data});
                }
            }

        );
    }
    handleUsers(){
        AqaComponent.userBackend.allUsingGET(
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the users from the server was encountered.",
                    "AqaAccountUsers.handleUsers, backend call: " + error,this);
                else{
                    this.setState({users:data});
                }
            }

        );
    }
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

        /*if(nextProps.dataFromMain.state.selectedUsers.length  === 0 ){
            this.setState({users:[]});
        }
        this.handleAccountDetails();*/
    }

    componentDidMount(){
        this.setState({isLoaded:true});
        this.handleAccountDetails();

    }

    render() {
        this.show = this.context.show;
        //const { classes } = this.props;
        const { error, isLoaded,redirect, notfound,users } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <div className={"source-detail-servant-pane"} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={10} sm={7} lg={11} xl={11}>
                            <Typography variant="title" color="inherit" align="left"
                                        style={{ fontSize: '1.5rem',
                                            padding: '0.1em 0.5em',
                                            fontFamily: 'Open Sans, sans-serif'}}>
                                Nothing to display
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} lg={1} xl={1}>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>

            </div>;
        } else if (!isLoaded) {
            return (<div className={"aqa-loader"}>
                <div className={"aqa-loading-icon"}>
                    <LoadingIcon fill={"#4cadc4"}/>
                </div>
            </div>);
        } else {
            return (
                <div className={"source-detail-servant-pane-one"} style={{background: "#FFF 0% 0% no-repeat padding-box"}} >
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Table id={"_usrtable"} cellSpacing={0} aria-sort="ascending" style={{}}>
                                <colgroup>
                                    <col width="4%" />
                                    <col width="4%" />
                                    <col width="30%" />
                                    <col width="20%" />
                                    <col width="20%" />
                                    <col width="20%" />
                                    <col width="5%" />

                                </colgroup>
                                <TableHead>
                                    <TableRow style={{height:"28px"}}>
                                        <TableCell width="4%" style={{"padding":"0px 0px 0px 4px"}}><div className="aqa-text-bold-12"> </div></TableCell>
                                        <TableCell width="4%" style={{"padding":"0px 0px 0px 4px"}}><div className="aqa-text-bold-12"> </div></TableCell>
                                        <TableCell width="30%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Email</div></TableCell>
                                        <TableCell width="20%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">First Name</div></TableCell>
                                        <TableCell width="20%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Last Name</div></TableCell>
                                        <TableCell width="20%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Role</div></TableCell>
                                        <TableCell width="5%" style={{"padding":"0px"}}><div className="aqa-text-bold-12"></div>2FA</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map(item =>(
                                        <AqaAccountUserListRow key={item.id} item={item} dataFromMain={this} dataFromRoot={this.props.dataFromMain} dataFromParent={item} dataForCheckbox={this.state.resetCheckbox} dataForAccount={this.state.account}/>

                                    ))}
                                </TableBody>
                            </Table>

                        </Grid>

                    </Grid>
                </div>
            );
        }
    }
}

AqaAccountUsers.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaAccountUsers);
