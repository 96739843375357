import AqaComponent  from "../components/shared/aqacomponent/AqaComponent"
import AqaTable      from "./AqaTable"

import Completer from "./Completer"

/** Filters are objects with the following properties:
  * "orientation", "type", "position", "valueFrom", "valueTo"
  */



// MAX_FILTER_TRIES x WAIT_BETWEEN_FILTER_ATTEMPTS should be the maximum amount of time users find acceptable to wait.
const MAX_FILTER_TRIES = 40;
const WAIT_BETWEEN_FILTER_ATTEMPTS = 100; // in ms



export class Filter
{

	/**
	  * orientation,
	  * type,
	  * position,
	  * valueFrom,
	  * valueTo,
	  * onlyReds,    // When filtering on red / amber is intersection
	  * onlyAmbers,  // When filtering on red / amber is intersection
//	  * unionColours // When filtering on red / amber is Union
	  */
	constructor(filtr) { this.filtr = filtr; }

	// returns true if the other filter is the same as this one.
	equals(f)
	{
		if (this.filtr === null) return f.filtr === null;
		if (f.filtr === null) return false;
		for (let p of ["orientation", "type", "position", "valueFrom", "valueTo", "onlyReds", "onlyAmbers", "subType"]) // , "unionColours"
		{
			if ((!this.filtr[p]) && (!f.filtr[p])) continue; // Effing javascript, don't ask (faux = la meme chose que pas defini)
			if (this.filtr[p] !== f.filtr[p]) return false;
		}
		return true;
	} // equals

	criteria() { return this.filtr; }


} //// Filter



/** This object is used to cache tables (The data structure that contains snapshot stats)
  * to avoid recomputing snapshots all the time.
  * Snapshot Details uses this to cache tables (which are not too massive)
  */
export class SnapshotView
{

	// Statics and definitions
	// -----------------------


	static serialMaker = 0;


	/** snapshotId is the mother's id (never a subsnapshot id)
	  * cache is an [] array.
	  * filters is a list of filters - if no filters, we get the snapshot, otherwise we get a filtered snapshot.
	  * callback is anything that needs to be done postload.
	  */
	static find(snapshotId, cache, filters, callback)
	{

		// Find any already loaded view that matches
		const newView = new SnapshotView(filters);
		let nCachedViews;

		if (cache !== null && (nCachedViews = cache.length) > 0) for(let i = 0; i < nCachedViews; i++) if (cache[i].equals(newView))
		{

// onsole.log("SnapV: CACHE HIT for: " + snapshotId);

/*
onsole.log("SNAPSHOT VIEW CACHE HIT @ " + i + "  !!!!!!!!!");
if (!cache[i].table) onsole.log("Ah ben oui j'ai pas de table la!");
else onsole .log("Ben si si	 que je l'ai la table!");
*/

			if (!cache[i].table) cache[i].waitForTableToLoad(callback);
			else callback(cache[i]);

			return;
		}

		// No cached view, so we need to load it.	


		cache.push(newView);

		if (filters.length === 0) newView.loadTable(snapshotId, callback, null);
		else
		{
		
// onsole.log("SnapV: FILTER SNAPSHOT!");		
//			const stati = cache[0].stati;

			SnapshotView.doFilter(1, snapshotId, newView, cache[0].stati, filters.map(f => f.criteria()), callback);
		}

	} // find
	
	
	static doFilter(count, snapshotId, newView, stati, filters, callback)
	{

/*
onsole.log("DOING DO_FILTER: " + snapshotId);
onsole.log(filters);
*/



// onsole.log("DOING DO FILTER");

		if (count > MAX_FILTER_TRIES) AqaComponent.staticReportError("Obtaining a filtered subsnapshot from the server took too long", "SnapshotView.find;doFilter: all attempts ",this);

		AqaComponent.snapshotBackend.filterSnapshotUsingPOST
		(
			snapshotId, // We always filter on the mother, although technically, we don't have to.
			filters,
			(error, data) =>
			{
				if (error) AqaComponent.staticReportError("An error occurred obtaining a subsnapshot from the server.", "SnapshotView.find:1, filter call error, backend call: " + error,this);
				else
				{

// onsole.log("DOING DO FILTER: data");
// onsole.log(AqaComponent.prettifyJson(data));

// onsole.log("DOING DO FILTER: FILTERS:");
// onsole.log(AqaComponent.prettifyJson(filters));


					if (data.success) newView.loadTable(data.subnapshotId, callback, stati);
					else
					{
						console.error("Error while getting filtered snapshot (but trying again):" + data.message);
						setTimeout(() => SnapshotView.doFilter(count + 1, snapshotId, newView, filters, callback), WAIT_BETWEEN_FILTER_ATTEMPTS);
					}
				}
			}
		);
	} // doFilter




	static clearCache(cache) { cache.length = 0; }

	static clearCacheOfFilteredViews(cache)  { if (cache && cache.length > 0) cache.length = 1; }

	static invalidate(cache, filters)
	{
		let nCachedViews;
		if (cache === null || (nCachedViews = cache.length) === 0) return;

		const newView = new SnapshotView(filters);		
		for(let i = 0; i < nCachedViews; i++) if (cache[i].equals(newView))
		{
			cache.splice(i, 1); 
			break;
		}
	} // invalidate


	constructor(filters)
	{
		this.filters = filters;
		this.serial = ++SnapshotView.serialMaker;
// this.cacheIndex = -1;
	} //

	loadTable(id, callback, stati)
	{

// Or we could load the row stati here

// onsole .log("I am ACTUALLY LOADING THE TABLE for: " + id);
// en utilisant un completer


// OR we ask the table to carry the row stati. (But swagger doesn't do )

		this.id = id;
		const tasks = [];

		tasks.push
		(
			completer =>
			{
				this.table = new AqaTable
				(
					id,
					(table, error) =>
					{
						if (error !== null) AqaComponent.staticReportError("A problem getting the snapshot from the server was encountered.", "SnapshotView.loadTable, backend call: " + error,this);
						else completer();
					}
				)
			}
		);

		if (stati === null) tasks.push // We get the stati if we didn't have them coming in.
		(
			completer =>
			{
				AqaComponent.reviewBackend.retrieveStatiUsingGET
				(

					id,
					"row",
					(error, data, response) =>
					{
						if (error) AqaComponent.staticReportError("A problem occurred while getting stati from the server.", "SnapshotView.loadTable, backend call: " + error,this);
						else
						{
							new Response(response.body).arrayBuffer().then(ab => { this.stati = new Int8Array(ab); completer() });
						}
					}
				);
			}
		
		);
		else this.stati = stati;
	
		new Completer(tasks, () => { if (callback) callback(this); }).run();

		/*
		this.table = new AqaTable
		(
			id,
			(table, e) =>
			{
				if (e != null) AqaComponent.staticReportError("A problem getting the snapshot from the server was encountered.", "SnapshotView.loadTable, backend call: " + e);
				else if (callback) callback(this);
			}
		);
		*/

	} // loadTable


	equals(otherView)
	{
		const nFilters = (this.filters ? this.filters.length : 0);
		let nOtherFilters = (otherView.filters ? otherView.filters.length : 0);

		if (nFilters !== nOtherFilters) return false;
		if (nFilters === 0) return true;

		const matched = Array(nFilters);
		while(nOtherFilters--) matched[nOtherFilters] = false;

// onsole.log("MY FILTERS");
// onsole.log(AqaComponent.prettifyJson(this.filters));


		let i, j;
		for(i = 0; i < nFilters; i++)
		{
			for(j = 0; j < nFilters; j++) if (!matched[j])
			{
				if (this.filters[i].equals(otherView.filters[j]))
				{
					matched[j] = true;
					break;
				}
			}
			if (j === nFilters) return false; // We haven't matched any.
		}
		return true; // All matched.

	} // equals


	hasFilters() { return this.filters != null && this.filters.length > 0; }

	// These come from the table itself
	hasHorizontalHeader() { return this.table.hasHorizontalHeader(); } // Top and Bottom
	hasVerticalHeader()   { return this.table.hasVerticalHeader();   } // On the sides

	getHasHorizontalHeader() { return this.table.hasHorizontalHeader(); } // Fix for the extra row - but why did I do it otherwise before?



	loadHeaders(lHCallback) // Note from 2021.06.19 we are conformising tables, so we do not need the number of columns anymore.
	{
		AqaComponent.snapshotBackend.getRowVectorValuesUsingGET
		(
			this.id,
			0,
			{},
			(error, data) =>
			{
				if (error) AqaComponent.staticReportError("A problem getting the snapshot header from the server was encountered.", "SnapshotView.loadHeaders, backend call: " + error,this);
				else lHCallback(data[0].values.map(v => v.coercedValue));
				/*
				{
					this.headers = data[0].values.map(v => v.coercedValue); // this.extractAndPadVectorValues(data[0].values, numberOfColumns);
					if (lHCallback) lHCallback();
				}
				*/
			}
		);

	} // loadHeaders


	getNumberOfRows()
	{
		return this.table.data.numberOfRows - (this.hasFilters() ? 0 : (this.hasHorizontalHeader() ? 1 : 0));

//		if (this.hasHorizontalHeader()) ret--;
//		return ret;
	} // getNumberOfRows


	/** This is for when calls to setView() follow in quick succession
	  * and a view IS in the cache but its table has NOT been downloaded yet.
	  * We DO want the view in the cache, but obviously it cannot be used if
	  * if it's not complete. So we wait.
	  */
	waitForTableToLoad(postop)
	{
		if (this.table && this.table.data)
		{
			if (postop) postop(this);
		}
		else
		{
			setTimeout(() => this.waitForTableToLoad(postop), 10);
		}
		
	} // waitForTableToLoad


} //// SnapshotView








/*
dump(w)
{
	onsole.log("");
	onsole.log("SnapshotView DUMP (" + w + ")");
	onsole.log(AqaComponent.prettifyJson(this));
	onsole.log("");

}
*/




