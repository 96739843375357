import AqaComponent from "../components/shared/aqacomponent/AqaComponent"

import AqaAnalysisObject  from "./AqaAnalysisObject"
import AqaFindings        from "./AqaFindings"


// Not building full buckets as would be returned by the API as we don't need top values if our buckets are 1 big only.
const bucketisers =
{

	string: (v, c) =>
	{
		return ({
			count:c,
			onValue:true,
//			rangeBottom:v,
			rawRangeBottom:v,
			rawRangeTop:v,
			value:v
		});
	},
	
	number: (v, c) =>
	{
		return ({
			count:c,
			onValue:true,
//			rangeBottom:v.toString(),
			rawRangeBottom:v,
			rawRangeTop:v,
			value:v
		});
	},

	date: (v, c) =>
	{
		return ({
			count:c,
//			onValue:true, // Most probably not needed
//			rangeBottom:v.toString(),
			rawRangeBottom:v,
			rawRangeTop:v,			
			value:AqaComponent.formatDate(v)
		});
	}

};

// onsole.log("[" + a.rawRangeBottom + "] <?> [" + b.rawRangeBottom + "]"); return 

const sorters =
{
	string: (a, b) => a.rawRangeBottom.localeCompare(b.rawRangeBottom),
	number: (a, b) => a.rawRangeBottom - b.rawRangeBottom,
	date:   (a, b) => a.rawRangeBottom - b.rawRangeBottom
};






export default class AqaVector extends AqaAnalysisObject
{
	constructor(vector, expectedSize)
	{
		super();
		this.data = vector;
		this.expectedSize = expectedSize;
		this.findings = new AqaFindings(vector.findings);

/*		
onsole.log("The vector I loaded:");
onsole.log(vector);
*/
		
	} //

	getTotalNumberOfCells() { return this.expectedSize; }

	// Convenience
	getMetric(familyName, metricName, defaultValue) { return this.findings.getMetric(familyName, metricName, defaultValue); }
	

	/** Returns a type distribution as buckets
	  * DEPRECATED Comment
	  * Although the back end DOES have an API to provide a distribution with a parameterisable number of values
	  * in each bucket, since we only always ever use 1 value buckets, we can build our own buckets from the type distribution
	  * we get from our vectors - especially as the vectors are cached! (avoiding us a round trip for data we actually have)
	  * THIS WOULD BE DEFEATED IF WE WANTED MORE THAN ONE VALUE PER BUCKET!!!! (And we'd have to use the API again)
	  *
	  *
	  * From 2021.08 we want the possibility of buckets with more than 1 value.
	  * Despite the comment above, the backend algorithm appears flawed
	  * so the front end does bucket stuffing.
	  * MEANING we still just get the census for ALL values
	  * equivalent to a distribution with only value per bucket.
	  * The Front End then decides how many values to stick in each bucket.
	  *
	  * @param typeName: one of{string, number, date}
	  * @param sortType 0: count, 1: value order
	  */
	getDistribution(typeName, sortType)
	{
		const buckets = [];
		const bucketiser = bucketisers[typeName];

		let maxCount = 0; // Possibly not needed as we are assuming buckets with one value AND maxcount is recomputed later anyway.
		
// onsole.log("VECTOR " + this.data.position);
// onsole.log(AqaComponent.prettifyJson(this.data.distributions[typeName]));

// onsole.log("AV: typeName: " + typeName);
// onsole.log("AV: sortType: " + sortType);

		for (const [value, count] of Object.entries(this.data.distributions[typeName]))
		{
			if (count > maxCount) maxCount = count; // probably not needed
			buckets.push(bucketiser(value, count));
		}

		const sorter = sortType === 0 ? (a, b) => b.count - a.count : sorters[typeName];

		buckets.sort(sorter);

		return ({
			axis:this.data.orientation,
			position:this.data.position,
			maxCount, // probably not needed
			numberOfBuckets:buckets.length,
			buckets
		});
		
	} // 



	/** This turns a simple distribution into an old style bucket list */
	getDistribution2(typeName)
	{
		const buckets = [];
		const bucketiser = bucketisers[typeName];

		let maxCount = 0;

// onsole.log("VECTOR " + this.data.position);
// onsole.log(AqaComponent.prettifyJson(this.data.distributions[typeName]));
// onsole.log("AV2: typeName: " + typeName);


		for (const [value, count] of Object.entries(this.data.distributions[typeName]))
		{
			if (count > maxCount) maxCount = count;
			buckets.push(bucketiser(value, count));
		}

		// We always sort on value.
		// To make things easier for Displaying code.

//		const sorter = sortType === 0 ? (a, b) => b.count - a.count : sorters[typeName];

		buckets.sort(sorters[typeName]);

		return ({
			axis:this.data.orientation,
			position:this.data.position,
			maxCount,
			numberOfBuckets:buckets.length,
			buckets
		});
		
	} // 


	getMostFrequentValueOfDistribution(typeName)
	{
		const distribution = this.data.distributions[typeName];
		if (!distribution) return null;

// onsole.log("Distribution");
// onsole.log(JSON.stringify(distribution));

		let max = 0;
		let ret;
		for(const [k, v] of Object.entries(distribution))
		{
			if (v > max)
			{
				max = v;
				ret = k;
			}
		}
		return ret;

	} // getMostFrequentValueOfDistribution

	getDateFormat() { return this.data.dateFormat; }


} //// AqaVector


