/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.Account = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The Account model module.
   * @module model/Account
   * @version 1.0
   */

  /**
   * Constructs a new <code>Account</code>.
   * @alias module:model/Account
   * @class
   */
  var exports = function() {
    var _this = this;


































  };

  /**
   * Constructs a <code>Account</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Account} obj Optional instance to populate.
   * @return {module:model/Account} The populated <code>Account</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('city')) {
        obj['city'] = ApiClient.convertToType(data['city'], 'String');
      }
      if (data.hasOwnProperty('companyName')) {
        obj['companyName'] = ApiClient.convertToType(data['companyName'], 'String');
      }
      if (data.hasOwnProperty('country')) {
        obj['country'] = ApiClient.convertToType(data['country'], 'String');
      }
      if (data.hasOwnProperty('currencyCode')) {
        obj['currencyCode'] = ApiClient.convertToType(data['currencyCode'], 'String');
      }
      if (data.hasOwnProperty('currentPlanId')) {
        obj['currentPlanId'] = ApiClient.convertToType(data['currentPlanId'], 'String');
      }
      if (data.hasOwnProperty('dateAdded')) {
        obj['dateAdded'] = ApiClient.convertToType(data['dateAdded'], 'Number');
      }
      if (data.hasOwnProperty('dateFormat')) {
        obj['dateFormat'] = ApiClient.convertToType(data['dateFormat'], 'String');
      }
      if (data.hasOwnProperty('defaultAdmin')) {
        obj['defaultAdmin'] = ApiClient.convertToType(data['defaultAdmin'], 'String');
      }
      if (data.hasOwnProperty('defaultEmail')) {
        obj['defaultEmail'] = ApiClient.convertToType(data['defaultEmail'], 'String');
      }
      if (data.hasOwnProperty('firstName')) {
        obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('lastName')) {
        obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
      }
      if (data.hasOwnProperty('lastReminder')) {
        obj['lastReminder'] = ApiClient.convertToType(data['lastReminder'], 'Number');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('numberOfEditors')) {
        obj['numberOfEditors'] = ApiClient.convertToType(data['numberOfEditors'], 'Number');
      }
      if (data.hasOwnProperty('numberOfSnapshots')) {
        obj['numberOfSnapshots'] = ApiClient.convertToType(data['numberOfSnapshots'], 'Number');
      }
      if (data.hasOwnProperty('numberOfSources')) {
        obj['numberOfSources'] = ApiClient.convertToType(data['numberOfSources'], 'Number');
      }
      if (data.hasOwnProperty('numberOfViewers')) {
        obj['numberOfViewers'] = ApiClient.convertToType(data['numberOfViewers'], 'Number');
      }
      if (data.hasOwnProperty('paymentLength')) {
        obj['paymentLength'] = ApiClient.convertToType(data['paymentLength'], 'String');
      }
      if (data.hasOwnProperty('paymentMethod')) {
        obj['paymentMethod'] = ApiClient.convertToType(data['paymentMethod'], 'String');
      }
      if (data.hasOwnProperty('paymentProcessorId')) {
        obj['paymentProcessorId'] = ApiClient.convertToType(data['paymentProcessorId'], 'String');
      }
      if (data.hasOwnProperty('paymentType')) {
        obj['paymentType'] = ApiClient.convertToType(data['paymentType'], 'String');
      }
      if (data.hasOwnProperty('phoneNumber')) {
        obj['phoneNumber'] = ApiClient.convertToType(data['phoneNumber'], 'String');
      }
      if (data.hasOwnProperty('postcode')) {
        obj['postcode'] = ApiClient.convertToType(data['postcode'], 'String');
      }
      if (data.hasOwnProperty('state')) {
        obj['state'] = ApiClient.convertToType(data['state'], 'String');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'Number');
      }
      if (data.hasOwnProperty('streetAddress')) {
        obj['streetAddress'] = ApiClient.convertToType(data['streetAddress'], 'String');
      }
      if (data.hasOwnProperty('subscriptionCancelationDate')) {
        obj['subscriptionCancelationDate'] = ApiClient.convertToType(data['subscriptionCancelationDate'], 'Number');
      }
      if (data.hasOwnProperty('subscriptionRef')) {
        obj['subscriptionRef'] = ApiClient.convertToType(data['subscriptionRef'], 'String');
      }
      if (data.hasOwnProperty('subscriptionStartDate')) {
        obj['subscriptionStartDate'] = ApiClient.convertToType(data['subscriptionStartDate'], 'Number');
      }
      if (data.hasOwnProperty('suite')) {
        obj['suite'] = ApiClient.convertToType(data['suite'], 'String');
      }
      if (data.hasOwnProperty('validUntil')) {
        obj['validUntil'] = ApiClient.convertToType(data['validUntil'], 'Date');
      }
      if (data.hasOwnProperty('vat')) {
        obj['vat'] = ApiClient.convertToType(data['vat'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {String} city
   */
  exports.prototype['city'] = undefined;
  /**
   * @member {String} companyName
   */
  exports.prototype['companyName'] = undefined;
  /**
   * @member {String} country
   */
  exports.prototype['country'] = undefined;
  /**
   * @member {String} currencyCode
   */
  exports.prototype['currencyCode'] = undefined;
  /**
   * @member {String} currentPlanId
   */
  exports.prototype['currentPlanId'] = undefined;
  /**
   * @member {Number} dateAdded
   */
  exports.prototype['dateAdded'] = undefined;
  /**
   * @member {String} dateFormat
   */
  exports.prototype['dateFormat'] = undefined;
  /**
   * @member {String} defaultAdmin
   */
  exports.prototype['defaultAdmin'] = undefined;
  /**
   * @member {String} defaultEmail
   */
  exports.prototype['defaultEmail'] = undefined;
  /**
   * @member {String} firstName
   */
  exports.prototype['firstName'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {String} lastName
   */
  exports.prototype['lastName'] = undefined;
  /**
   * @member {Number} lastReminder
   */
  exports.prototype['lastReminder'] = undefined;
  /**
   * @member {String} name
   */
  exports.prototype['name'] = undefined;
  /**
   * @member {Number} numberOfEditors
   */
  exports.prototype['numberOfEditors'] = undefined;
  /**
   * @member {Number} numberOfSnapshots
   */
  exports.prototype['numberOfSnapshots'] = undefined;
  /**
   * @member {Number} numberOfSources
   */
  exports.prototype['numberOfSources'] = undefined;
  /**
   * @member {Number} numberOfViewers
   */
  exports.prototype['numberOfViewers'] = undefined;
  /**
   * @member {String} paymentLength
   */
  exports.prototype['paymentLength'] = undefined;
  /**
   * @member {String} paymentMethod
   */
  exports.prototype['paymentMethod'] = undefined;
  /**
   * @member {String} paymentProcessorId
   */
  exports.prototype['paymentProcessorId'] = undefined;
  /**
   * @member {String} paymentType
   */
  exports.prototype['paymentType'] = undefined;
  /**
   * @member {String} phoneNumber
   */
  exports.prototype['phoneNumber'] = undefined;
  /**
   * @member {String} postcode
   */
  exports.prototype['postcode'] = undefined;
  /**
   * @member {String} state
   */
  exports.prototype['state'] = undefined;
  /**
   * @member {Number} status
   */
  exports.prototype['status'] = undefined;
  /**
   * @member {String} streetAddress
   */
  exports.prototype['streetAddress'] = undefined;
  /**
   * @member {Number} subscriptionCancelationDate
   */
  exports.prototype['subscriptionCancelationDate'] = undefined;
  /**
   * @member {String} subscriptionRef
   */
  exports.prototype['subscriptionRef'] = undefined;
  /**
   * @member {Number} subscriptionStartDate
   */
  exports.prototype['subscriptionStartDate'] = undefined;
  /**
   * @member {String} suite
   */
  exports.prototype['suite'] = undefined;
  /**
   * @member {Date} validUntil
   */
  exports.prototype['validUntil'] = undefined;
  /**
   * @member {String} vat
   */
  exports.prototype['vat'] = undefined;



  return exports;
}));


