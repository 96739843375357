/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
// import ReactDOM from 'react-dom';


import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { GridList, GridListTile} from "@material-ui/core";
import AqaComponent from "../../shared/aqacomponent/AqaComponent";
import IconButton from "@material-ui/core/IconButton";

import {SMALL_ASPECT_ICONS as QD_ICONS} from "../../shared/AvaAspectIcons";

import AqaRulesUniqueness   from "./AqaRulesUniqueness";
import AqaRulesPopulated    from "./AqaRulesPopulated";
import AqaRulesString       from "./AqaRulesString";
import AqaRulesNumber       from "./AqaRulesNumber";
import AqaRulesDate         from "./AqaRulesDate";
import AqaRulesNativeErrors from "./AqaRulesNativeErrors"

const styles = theme => ({
    root:{
        maxWidth: 345,
        margin: "8px",
    },
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});


// Used to display the QD Buttons, activated or not.
const QD_ICON_CLASS_NAMES =
[
	[
		"aqa-button-u",
		"aqa-button-u-active"
	],
	[
		"aqa-button-p",
		"aqa-button-p-active"
	],
	[
		"aqa-button-s",
		"aqa-button-s-active"
	],
	[
		"aqa-button-number",
		"aqa-button-number-active"
	],
	[
		"aqa-button-date",
		"aqa-button-date-active"
	],
	[
		"aqa-button-type",
		"aqa-button-type-active"
	],
	[
		"aqa-button-native",
		"aqa-button-native-active"
	]
];








// The order is important.
const RULE_EDITORS = [AqaRulesUniqueness, AqaRulesPopulated, AqaRulesString, AqaRulesNumber, AqaRulesDate, AqaRulesNativeErrors];
Object.freeze(RULE_EDITORS);

const ASPECT_NAMES = ["uniqueness", "populated", "string", "number", "date", "nativeErrors"];

const capitalise = s => s.substring(0, 1).toUpperCase() + s.substring(1);


class AqaRulesEditor extends AqaComponent
{
	constructor(props)
	{
		super(props);
		this.state =
		{
			error: null,
			notfound: false,
			isLoaded: true,
			dense: false,
			secondary: true,
			redirect: false,
			currentSnapshot:"allsources",
			percentage: 100,
			col:this.props.dataForCol,
			column:this.props.dataForColumn,
			columnName:this.props.dataForName,
			which: this.props.dataForType,
		};
		
	} //


	/**
	  * 0 Uniqueness
	  * 1 Populated
	  * 2 String
	  * 3 Number
	  * 4 Date
	  * (5 Type)
	  */
	handleRuleSelection = (which, columnName) => this.setState({which, columnName}); // Why do we need the column name here?

	componentDidUpdate = () =>
	{
		if (this.props.dataForCol !== this.state.col) this.setState({which: this.props.dataForType, col:this.props.dataForCol, column:this.props.dataForColumn, name: this.props.dataForName}); // , typeTableData
		 
	} // componentDidUpdate



	/** Needed because we can't assign variables inside JSX */
	dynamicTag(dt)
	{
//		return <DynamicTag {...attributes} /> // this works but it $%^&* throws warnings!!
		return QD_ICONS[dt];
	} // dynamicTag


	render()
	{
		// Grid doc
		// https://mui.com/components/grid/


		const { error, redirect, notfound } = this.state;

		if(redirect) { } //return <Redirect to="/login" push={true} />
		else if (notfound && error)
		{
			return (
				<div className={"snapshot-rules-servant-pane"}>
					<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

						<Grid item xs={10} sm={7} lg={11} xl={11}>
							<Typography
								variant="title"
								color="inherit"
								align="left"
								style={{
									fontSize: '1.5rem',
									padding: '0.1em 0.5em',
									fontFamily: 'Open Sans, sans-serif'
								}}
							>
								Nothing to display
							</Typography>
						</Grid>

						<Grid item xs={2} sm={1} lg={1} xl={1}></Grid>
					</Grid>
				</div>
			);
		}
		else if (error && !notfound)
		{
			return (
				<div>
					<Typography
						variant="title"
						color="inherit" align="left"
						style={{
							fontSize: '1.5rem',
							padding: '3.1em 0.5em',
							fontFamily: 'Open Sans, sans-serif'
						}}
					>
						Error: {error.message}
					</Typography>
				</div>
			);
		}
		else
		{
	
			const ChosenEditor = RULE_EDITORS[this.state.which];

			// To display the icone of the active Check Aspects next to "Checks"
			const activeAspectChecks = [];
		
			ASPECT_NAMES.map(name => { if (this.props.dataForColumn[`${name}Enabled`]) activeAspectChecks.push(capitalise(name)) ; return name});
		
			return (
				<div className={"snapshot-rule-servant-pane"} style={{marginTop:"-70px"}}>
					{
						this.props.suppress
						?
							null
						:
							<div style={{padding: "10px"}}>
								<Typography
									variant="inherit"
									color="inherit"
									style={{
										textAlign: "left",
										fontSize: "16px",
										color: "#4CADC4",
										paddingLeft: "4px",
										paddingBottom: "6px",
										fontFamily: "Montserrat",
										borderBottom: "1px solid #ccc",
										height: "24px"
									}}
								>

									<span style={{float: "left", height: "30px"}}></span>
									<span style={{
										fontSize: "18px",
										color: "#3f51b5",
										float: "left",
										marginTop: "-3px"
									}}>{this.props.dataForName}</span>
									<span>
									<span style={{float: "left", paddingLeft: "30px", paddingTop: "0px"}}>Checks: </span>
									<span style={{float: "left", paddingLeft: "4px", marginTop: "-3px"}}>
										{/* Showing currently enabled QDs as small 16x16 icons */}
										<GridList cellHeight={'auto'}>
											{ /* typeTableData */ }
											{
												activeAspectChecks.map
												(
													(name, index) =>
													(
														<GridListTile
															key={index + "-ico-" + index}
															style={{width: "20px"}}
														>
															<IconButton className="aqa-button" style={{padding: 0}}>
																<div className="aqa-list-icon">
																	{/* Renders UniquenessIcon ... NativeErrorsIcon that indicate which check is active */}
																	{this.dynamicTag(name)}

																</div>
															</IconButton>
														</GridListTile>
													)
												)
											}
										</GridList>
									</span>

								</span>
								</Typography>
							</div>
					}

					<div style={{paddingTop:"0px",paddingLeft:"10px",margin:"0 auto"}}>
						<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>
							<Grid item xs={2} sm={2} lg={2} xl={2}></Grid>
							<Grid item xs={7} sm={7} lg={7} xl={7}>
							
								
								{/* This is the rack of buttons available to the user to choose which QD to edit - TJ 20211006 Why is SourceIcon the default? */}
								<GridList style={{padding:"0px",width:"99%",marginLeft:"50px"}} cellHeight={'auto'} spacing={4}>
									{

										this.props.suppress?"":

										ASPECT_NAMES.map

										(
											(name, index) =>
											(
												<GridListTile
													key={"aqa-rule-type-" + index}
													id={"aqa-rule-type-" + index}
													className={QD_ICON_CLASS_NAMES[index][index === this.state.which ? 1 : 0]}
													

													style={{width:"95px",borderRadius:"4%",margin:"2px",padding:"0px"}}
												>
													<div
														style={{textAlign:"left", width:"90px", height:"30px", margin:"-2px", padding:"0px", paddingLeft:"5px"}}
														onClick={() => this.handleRuleSelection(index, name)}
													>
													{/* this.props.dataForCol */}

														<Typography  variant="inherit" >
															<IconButton className="aqa-button" style={{padding:0}} >
																<div className="aqa-list-icon">
	
																	{/* Renders UniquenessIcon ... Native Error: selectors */}
																	{this.dynamicTag(name)}

																</div>
																<Typography className="aqa-text-action-10" variant="inherit" style={{textAlign:"left",paddingLeft:"4px",marginTop:"0px",fontSize:"6px !important"}}>{capitalise(name)}</Typography>
															</IconButton>
														</Typography>
													</div>
												</GridListTile>
											)
										)
									}
								</GridList>
							</Grid>

							<Grid item xs={3} sm={3} lg={3} xl={3}></Grid>
						</Grid>


					</div>
					
					
					<div style={{padding:"0px",marginLeft:"0px",background: "0% 0% no-repeat padding-box padding-box rgba(248, 248, 248, 0.75)", marginTop:"0px"}}>
						{

							this.state.which >= 0 && this.state.which < RULE_EDITORS.length
							?
								<ChosenEditor
									dataForCol={this.state.col}
									dataForWhich={this.state.which}
									dataForName={this.state.columnName} // Not sure we need this
									parent={this}
									dataFromSource={this.props.dataFromSource}
//									dataForTable={this.props.dataForTable}
									sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
									rulesObject={this.props.parent}
									rules={this.props.rules}
								/>
							:
								null
						}

						{ /* <div id="rule-type-editor"></div> */ }

					</div>
				</div>
			);
		}
	} // render
}

AqaRulesEditor.propTypes =
{
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaRulesEditor);


