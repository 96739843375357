
import AqaComponent  from "../components/shared/aqacomponent/AqaComponent"
import ColourMap from "./ColourMap"
import OverrideMap from "./OverrideMap"

/** if in my cache, then both colours and overrides have been loaded */

export default class ColourAndOverrideMaps
{

	static cache = {};
	
	
	
	// This should be called by the ColourMap and the OverrideMap Objects
	static load(id, doCache, callback)
	{

		// If we were using fetch - this would be interesting but effing Swagger is muddling everything up
		// // https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream/ReadableStream


		const alreadyCachedVersion = ColourAndOverrideMaps.cache[id];
		if (alreadyCachedVersion)
		{
			callback(alreadyCachedVersion);
			return;
		}
		
		AqaComponent.snapshotBackend.getColourAndOverrideMapsUsingGET
		(
			id,
			"column", // We want the colum VQDs
			(error, data, response) =>
			{
				if (error)
				{
					AqaComponent.staticReportError("A problem getting the snapshot colour and override maps arose, sorry.", "ColourAndOverrideMaps - load, backend call: " + error,this);
					return;
				}



				// We parse and format our data
// if (blob === null) console.error("Blob is null - unsuccessful request.");

// onsole.log("Response: ");
// onsole.log(AqaComponent.prettifyJsonFromString(response));

//onsole.log("CAOM: " + id + " processing response: " + (typeof response)+ " " + (typeof response.text)); //
// onsole.log(response);
// console.log("LEN " + response.length);


				//	https://yottabrain.org/blog/javascript/how-to-convert-string-to-blob/
				// https://bobbyhadz.com/blog/convert-blob-to-arraybuffer-in-javascript
				

	

				
//				(new Blob([response.text], { type : 'text/plain' })).arrayBuffer().then
				response.body.arrayBuffer().then
				(
					arrayBuffer =>
					{


// const arrayBuffer = Buffer.from(response.text);

						this.data = new Uint8Array(arrayBuffer);

//						const arrayBuffer = new ArrayBuffer(response.text);

						this.data = new Uint8Array(arrayBuffer);

						const nRows = AqaComponent.readInteger(this.data, 0, 4);
						const nCols = AqaComponent.readInteger(this.data, 4, 4);
						const colourMapSize = AqaComponent.readInteger(this.data, 8, 8);
						const whereOverridesStart = 16 + colourMapSize;

						// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/ArrayBuffer/slice
						const colourMapBuffer = arrayBuffer.slice(16, whereOverridesStart);
						const overridesBuffer = arrayBuffer.slice(whereOverridesStart);

						const colourMap = new ColourMap(null);
						colourMap.loadWithBuffer(id, nRows, nCols, colourMapBuffer, null);

						const overrideMap = new OverrideMap(null);
						overrideMap.loadWithBuffer(id, nRows, nCols, colourMap, overridesBuffer, null);

						const caom = {id, colourMap, overrideMap};
						if (doCache) ColourAndOverrideMaps.cache[id] = caom;

						if (callback !== null) callback(caom);


					}
				);

	
			}
		);
		
	
	
	} // load









	static clearAll() { ColourAndOverrideMaps.cache = {}; }

	static clear(id) { delete ColourAndOverrideMaps.cache[id]; }
	
	static getColourMap(id)
	{
		const caom = ColourAndOverrideMaps.cache[id];
		return caom == null ? null : caom.colourMap;
	} // getColourMap

	static getOverrideMap(id)
	{
		const caom = ColourAndOverrideMaps.cache[id];
		return caom == null ? null : caom.overrideMap;
	} // getOverrideMap


	static getCellColour(id, x, y)
	{
		const cm = this.getColourMap(id); // ColourAndOverrideMaps.cache[id];
		if (!cm) return ColourMap.GREEN;
		return cm.columnBasedOverallColour(x, y);
	} // getCellColour
	
	// This should be called by objects that only want statistics for display
	// If not calling from a page that will display table data, then you may specify a wantsCache of false.
	static retrieveColBasedStatistics(id, wantsCache, callback)
	{
		this.load
		(
			id,
			wantsCache,
			caom =>
			{
				if (callback) callback({
					numberOfFixers: caom.overrideMap.numberOfFixers,
					numberOfIgnores: caom.overrideMap.numberOfIgnores,

					numberOfRedFixers: caom.overrideMap.numberOfRedFixers,
					numberOfRedIgnores: caom.overrideMap.numberOfRedIgnores,

					numberOfAmberFixers: caom.overrideMap.numberOfAmberFixers,
					numberOfAmberIgnores: caom.overrideMap.numberOfAmberIgnores,

					numberOfGreenFixers: caom.overrideMap.numberOfGreenFixers,
					numberOfGreenIgnores: caom.overrideMap.numberOfGreenIgnores,

					numberOfRedPendings: caom.overrideMap.numberOfRedPendings,
					numberOfAmberPendings: caom.overrideMap.numberOfAmberPendings,
					numberOfPendings: caom.overrideMap.numberOfPendings,

					numberOfMissingRedFixers:caom.overrideMap.numberOfMissingRedFixers,
					numberOfUniqueRedFixers:caom.overrideMap.numberOfUniqueRedFixers,
					numberOfTypeRedFixers:caom.overrideMap.numberOfTypeRedFixers,
					numberOfFormatRedFixers:caom.overrideMap.numberOfFormatRedFixers,
					numberOfOutlierRedFixers:caom.overrideMap.numberOfOutlierRedFixers,
					numberOfInvalidRedFixers:caom.overrideMap.numberOfInvalidRedFixers,
					numberOfNativeRedFixers:caom.overrideMap.numberOfNativeRedFixers,

					numberOfMissingAmberFixers:caom.overrideMap.numberOfMissingAmberFixers,
					numberOfUniqueAmberFixers:caom.overrideMap.numberOfUniqueAmberFixers,
					numberOfTypeAmberFixers:caom.overrideMap.numberOfTypeAmberFixers,
					numberOfFormatAmberFixers:caom.overrideMap.numberOfFormatAmberFixers,
					numberOfOutlierAmberFixers:caom.overrideMap.numberOfOutlierAmberFixers,
					numberOfInvalidAmberFixers:caom.overrideMap.numberOfInvalidAmberFixers,
					numberOfNativeAmberFixers:caom.overrideMap.numberOfNativeAmberFixers,

					numberOfMissingRedIgnores:caom.overrideMap.numberOfMissingRedIgnores,
					numberOfUniqueRedIgnores:caom.overrideMap.numberOfUniqueRedIgnores,
					numberOfTypeRedIgnores:caom.overrideMap.numberOfTypeRedIgnores,
					numberOfFormatRedIgnores:caom.overrideMap.numberOfFormatRedIgnores,
					numberOfOutlierRedIgnores:caom.overrideMap.numberOfOutlierRedIgnores,
					numberOfInvalidRedIgnores:caom.overrideMap.numberOfInvalidRedIgnores,
					numberOfNativeRedIgnores:caom.overrideMap.numberOfNativeRedIgnores,

					numberOfMissingAmberIgnores:caom.overrideMap.numberOfMissingAmberIgnores,
					numberOfUniqueAmberIgnores:caom.overrideMap.numberOfUniqueAmberIgnores,
					numberOfTypeAmberIgnores:caom.overrideMap.numberOfTypeAmberIgnores,
					numberOfFormatAmberIgnores:caom.overrideMap.numberOfFormatAmberIgnores,
					numberOfOutlierAmberIgnores:caom.overrideMap.numberOfOutlierAmberIgnores,
					numberOfInvalidAmberIgnores:caom.overrideMap.numberOfInvalidAmberIgnores,
					numberOfNativeAmberIgnores:caom.overrideMap.numberOfNativeAmberIgnores

				});
			}
		)
	
	
	
	
	} // retrieveColBasedStatistics
	
	
	

/*
	constructor(id) {
	
		this.id = id;
	}
*/




} ////




/*
AqaComponent.reviewBackend.getCellOverrideStatiUsingGET
(
	id,
	"column",
	(error, data, response) =>
	{
		if (error)
		{
			console.error("Stati Map - load, backend call: " + error);
			return;
		}

		response.body.arrayBuffer().then
		(
			arrayBuffer =>
			{
				console.log("STATI MAP DONE");
			}
		);

	}
);
*/
	
