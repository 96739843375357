/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaSnapshot = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The AqaSnapshot model module.
   * @module model/AqaSnapshot
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaSnapshot</code>.
   * @alias module:model/AqaSnapshot
   * @class
   */
  var exports = function() {
    var _this = this;
























  };

  /**
   * Constructs a <code>AqaSnapshot</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaSnapshot} obj Optional instance to populate.
   * @return {module:model/AqaSnapshot} The populated <code>AqaSnapshot</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('charsetName')) {
        obj['charsetName'] = ApiClient.convertToType(data['charsetName'], 'String');
      }
      if (data.hasOwnProperty('creadtedById')) {
        obj['creadtedById'] = ApiClient.convertToType(data['creadtedById'], 'String');
      }
      if (data.hasOwnProperty('createdBy')) {
        obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
      }
      if (data.hasOwnProperty('dataFilepath')) {
        obj['dataFilepath'] = ApiClient.convertToType(data['dataFilepath'], 'String');
      }
      if (data.hasOwnProperty('dateCreated')) {
        obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Number');
      }
      if (data.hasOwnProperty('failedInsertions')) {
        obj['failedInsertions'] = ApiClient.convertToType(data['failedInsertions'], 'Number');
      }
      if (data.hasOwnProperty('filesize')) {
        obj['filesize'] = ApiClient.convertToType(data['filesize'], 'Number');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('label')) {
        obj['label'] = ApiClient.convertToType(data['label'], 'String');
      }
      if (data.hasOwnProperty('openForStatusChanges')) {
        obj['openForStatusChanges'] = ApiClient.convertToType(data['openForStatusChanges'], 'Boolean');
      }
      if (data.hasOwnProperty('originalFilename')) {
        obj['originalFilename'] = ApiClient.convertToType(data['originalFilename'], 'String');
      }
      if (data.hasOwnProperty('reviewStatus')) {
        obj['reviewStatus'] = ApiClient.convertToType(data['reviewStatus'], 'String');
      }
      if (data.hasOwnProperty('sheetChosen')) {
        obj['sheetChosen'] = ApiClient.convertToType(data['sheetChosen'], 'Boolean');
      }
      if (data.hasOwnProperty('sheetIndex')) {
        obj['sheetIndex'] = ApiClient.convertToType(data['sheetIndex'], 'Number');
      }
      if (data.hasOwnProperty('sheetName')) {
        obj['sheetName'] = ApiClient.convertToType(data['sheetName'], 'String');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'String');
      }
      if (data.hasOwnProperty('statusChangedBy')) {
        obj['statusChangedBy'] = ApiClient.convertToType(data['statusChangedBy'], 'String');
      }
      if (data.hasOwnProperty('statusChangedById')) {
        obj['statusChangedById'] = ApiClient.convertToType(data['statusChangedById'], 'String');
      }
      if (data.hasOwnProperty('statusChangedDate')) {
        obj['statusChangedDate'] = ApiClient.convertToType(data['statusChangedDate'], 'Number');
      }
      if (data.hasOwnProperty('successfulInsertions')) {
        obj['successfulInsertions'] = ApiClient.convertToType(data['successfulInsertions'], 'Number');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('umbrellaIds')) {
        obj['umbrellaIds'] = ApiClient.convertToType(data['umbrellaIds'], ['String']);
      }
      if (data.hasOwnProperty('version')) {
        obj['version'] = ApiClient.convertToType(data['version'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {String} charsetName
   */
  exports.prototype['charsetName'] = undefined;
  /**
   * @member {String} creadtedById
   */
  exports.prototype['creadtedById'] = undefined;
  /**
   * @member {String} createdBy
   */
  exports.prototype['createdBy'] = undefined;
  /**
   * @member {String} dataFilepath
   */
  exports.prototype['dataFilepath'] = undefined;
  /**
   * @member {Number} dateCreated
   */
  exports.prototype['dateCreated'] = undefined;
  /**
   * @member {Number} failedInsertions
   */
  exports.prototype['failedInsertions'] = undefined;
  /**
   * @member {Number} filesize
   */
  exports.prototype['filesize'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {String} label
   */
  exports.prototype['label'] = undefined;
  /**
   * @member {Boolean} openForStatusChanges
   */
  exports.prototype['openForStatusChanges'] = undefined;
  /**
   * @member {String} originalFilename
   */
  exports.prototype['originalFilename'] = undefined;
  /**
   * @member {module:model/AqaSnapshot.ReviewStatusEnum} reviewStatus
   */
  exports.prototype['reviewStatus'] = undefined;
  /**
   * @member {Boolean} sheetChosen
   */
  exports.prototype['sheetChosen'] = undefined;
  /**
   * @member {Number} sheetIndex
   */
  exports.prototype['sheetIndex'] = undefined;
  /**
   * @member {String} sheetName
   */
  exports.prototype['sheetName'] = undefined;
  /**
   * @member {String} status
   */
  exports.prototype['status'] = undefined;
  /**
   * @member {String} statusChangedBy
   */
  exports.prototype['statusChangedBy'] = undefined;
  /**
   * @member {String} statusChangedById
   */
  exports.prototype['statusChangedById'] = undefined;
  /**
   * @member {Number} statusChangedDate
   */
  exports.prototype['statusChangedDate'] = undefined;
  /**
   * @member {Number} successfulInsertions
   */
  exports.prototype['successfulInsertions'] = undefined;
  /**
   * @member {module:model/AqaSnapshot.TypeEnum} type
   */
  exports.prototype['type'] = undefined;
  /**
   * @member {Array.<String>} umbrellaIds
   */
  exports.prototype['umbrellaIds'] = undefined;
  /**
   * @member {String} version
   */
  exports.prototype['version'] = undefined;


  /**
   * Allowed values for the <code>reviewStatus</code> property.
   * @enum {String}
   * @readonly
   */
  exports.ReviewStatusEnum = {
    /**
     * value: "UNSET"
     * @const
     */
    "UNSET": "UNSET",
    /**
     * value: "OPEN"
     * @const
     */
    "OPEN": "OPEN",
    /**
     * value: "BUSY"
     * @const
     */
    "BUSY": "BUSY",
    /**
     * value: "CLOSED"
     * @const
     */
    "CLOSED": "CLOSED"  };

  /**
   * Allowed values for the <code>type</code> property.
   * @enum {String}
   * @readonly
   */
  exports.TypeEnum = {
    /**
     * value: "excel"
     * @const
     */
    "excel": "excel",
    /**
     * value: "csv"
     * @const
     */
    "csv": "csv",
    /**
     * value: "tsv"
     * @const
     */
    "tsv": "tsv",
    /**
     * value: "piecemeal"
     * @const
     */
    "piecemeal": "piecemeal"  };


  return exports;
}));


