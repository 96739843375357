/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
//import AqaNewSource from "./AqaNewSource";
//import AqaNewFolder from "./AqaNewFolder";
import AqaSourceToggle from "./AqaSourceToggle";
import AqaSourceSearch from "./AqaSourceSearch";
import AqaSourceSorter from "./AqaSourceSorter";
//import AqaSourceInclude from "./AqaSourceInclude";
//import AqaSourceExclude from "./AqaSourceExclude";
import AqaSourceDelete from "./AqaSourceDelete";
import AqaSourceEdit from "./AqaSourceEdit";
import AqaFolderEdit from "./AqaFolderEdit";
import AqaRules from "../sourcedetail/rules/AqaRules";
import AqaGetLink from "./AqaGetLink";
import AqaNewSourceSnapshot from "./AqaNewSourceSnapshot";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
//import AqaSourceLibrary from "./AqaSourceLibrary";
//import HtmlTooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaSourceHeader extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            sources: [],
            isGrid:false,
            blocksAllocated:0,
            blocksUsed:0,
            authCode:props.dataFromParent.state.authCode
        };
        this._isMounted=false;

    }

    handleAccountDetails() {
        AqaComponent.accountBackend.getMyAccountUsingGET(
            (error, data, response) => {
                if (error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else {
                    var accountObj = data;
                    if(this._isMounted) {
                        this.setState({accountObj: data});
                        if (this.state.authCode === "A") this.handleStatement(accountObj);
                    }
                }
            }
        );
    }

    handleStatement(accountObj){
        AqaComponent.billingBackend.getCurrentStatementUsingGET(
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the plans from the server was encountered.",
                    "AqaAccountOverview.handleAllPlans, backend call: " + error,this);
                else {
                    this.setState({statement:data});
                    let blocksAllocated = 0;
                    let blocksUsed = 0;
                    let blockSize = data.blockSize;
                    //let currency = accountObj.currencyCode;
                    //let planWithPrices = data.planWithPrices.prices[currency][0];
                    //if(planWithPrices !== null && planWithPrices !== undefined) blocksAllocated = planWithPrices.quantityUpTo;
                    for(let i=0;i<data.activities.length;i++){
                        let activity = data.activities[i].sourceActivity;
                        blocksUsed = blocksUsed + (activity.numberOfCumulatedRows/blockSize);
                    }
                    this.setState({blocksAllocated:blocksAllocated,blocksUsed:blocksUsed.toFixed(2)});
                }
            }
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({isGrid:nextProps.dataForIcon});
        //this.handleAccountDetails();
    }

    componentDidMount(){
        this._isMounted=true;
        this.setState({isLoaded:true});
    }
    componentWillUnmount() {
        this._isMounted=false;
    }

    render() {
        //const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,isGrid } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={10} sm={7} lg={11} xl={11}>
                            <Typography variant="title" color="inherit" align="left"
                                        style={{ fontSize: '1.5rem',
                                            padding: '0.1em 0.5em',
                                            fontFamily: 'Open Sans, sans-serif'}}>
                                Nothing to display
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} lg={1} xl={1}>

                        </Grid>
                    </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                    <div>
                        {(this.props.dataFromRoot.state.selectedFolders.length === 0 && this.props.dataFromParent.state.selectedSources.length === 0) ?
                            (<Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                   style={{padding: 0, marginTop: 0}}>

                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <AqaSourceToggle dataFromParent={this} dataForIcon={isGrid} style={{paddingRight: "20px"}}/>
                                </Grid>
                                <Grid item xs={3} sm={3} lg={3} xl={3}>
                                    <AqaSourceSorter dataFromParent={this.props.dataFromParent}/>
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    {/*<AqaNewSource dataFromParent={this.props.dataFromParent}/>*/}
                                </Grid>
                                <Grid item xs={3} sm={3} lg={3} xl={3}>
                                    {/*<AqaNewFolder dataFromParent={this.props.dataFromParent}
                                                  dataFromRoot={this.props.dataFromRoot}/>*/}
                                    <AqaNewSourceSnapshot style={{paddingLeft: "40px"}} dataFromParent={this.props.dataFromParent}
                                                  dataFromRoot={this.props.dataFromRoot}/>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} xl={2}>
                                    <AqaGetLink dataFromParent={this.props.dataFromParent}
                                                dataForFolders={this.props.dataForFolders} parent={this}
                                                dataAcquired={this.props.dataAcquired}
                                                dataFromMainFrame={this.props.dataFromMainFrame}
                                                dataFromRoot={this.props.dataFromRoot} supress={true} />
                                    {/*<Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                          style={{padding: 0, marginTop: "-8px"}}>

                                        <Grid item xs={6} sm={6} lg={6} xl={6}>
                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                <Typography color="inherit" className="aqa-text-action">Usage Stats - 1k per block</Typography>

                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                <div>
                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                textAlign: "right",
                                                paddingLeft: "4px",
                                                marginTop: "-4px",
                                                width:"140px",
                                                color:"darkgrey"
                                            }}>
                                                Blocks Used:
                                            </Typography>
                                                <Typography className="aqa-text-bold-12" variant="inherit" style={{textAlign:"right",paddingLeft:"4px",paddingRight:"4px"}}>
                                                    {this.state.blocksUsed}
                                                </Typography>
                                                </div>
                                            </HtmlTooltip>
                                        </Grid>
                                        <Grid item xs={6} sm={6} lg={6} xl={6}>

                                        </Grid>

                                        </Grid>*/}
                                    {/*<AqaSourceLibrary dataFromParent={this.props.dataFromParent} dataForDisabled={false} updatesCount={this.props.updatesCount} />*/}

                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} xl={2}>
                                    <AqaSourceSearch dataFromParent={this.props.dataFromParent}/>
                                </Grid>
                            </Grid>) : ("")
                        }
                        {((this.props.dataFromRoot.state.selectedFolders.length === 1) ? (
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={6} sm={6} lg={6} xl={6}>
                                    <span>
                                        <span style={{float:"left"}}>
                                    <AqaFolderEdit dataFromParent={this.props.dataFromParent}
                                                     dataForFolders={this.props.dataForFolders} parent={this}
                                                     dataAcquired={this.props.dataAcquired}
                                                     dataFromRoot={this.props.dataFromRoot}
                                                     dataForDisabled={false}/>
                                                     </span>
                                        <span style={{float:"left"}}>
                                    <AqaSourceDelete dataFromParent={this.props.dataFromParent}
                                                     dataForFolders={this.props.dataForFolders} parent={this}
                                                     dataAcquired={this.props.dataAcquired}
                                                     dataFromRoot={this.props.dataFromRoot}/>
                                                     </span>
                                        <span style={{float:"left"}}>
                                    <AqaGetLink dataFromParent={this.props.dataFromParent}
                                                     dataForFolders={this.props.dataForFolders} parent={this}
                                                     dataAcquired={this.props.dataAcquired}
                                                dataFromMainFrame={this.props.dataFromMainFrame}
                                                     dataFromRoot={this.props.dataFromRoot} supress={false} />
                                                     </span>
                                    </span>
                                </Grid>

                                <Grid item xs={6} sm={6} lg={6} xl={6}>

                                </Grid>
                            </Grid>
                        ):((this.props.dataFromRoot.state.selectedFolders.length > 1) ? (
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={4} sm={4} lg={4} xl={4}>
                                    <span>
                                        <span style={{float:"left"}}>
                                    <AqaFolderEdit dataFromParent={this.props.dataFromParent}
                                                   dataForFolders={this.props.dataForFolders} parent={this}
                                                   dataAcquired={this.props.dataAcquired}
                                                   dataFromRoot={this.props.dataFromRoot}
                                                   dataForDisabled={true}/>
                                                     </span>
                                        <span style={{float:"left"}}>
                                    <AqaSourceDelete dataFromParent={this.props.dataFromParent}
                                                     dataForFolders={this.props.dataForFolders} parent={this}
                                                     dataAcquired={this.props.dataAcquired}
                                                     dataFromRoot={this.props.dataFromRoot}/>
                                                     </span>
                                    </span>
                                </Grid>

                                <Grid item xs={8} sm={8} lg={8} xl={8}>

                                </Grid>
                            </Grid>
                        ) : (
                            ((this.props.dataFromParent.state.selectedSources.length===1 && this.props.dataAcquired ==="allsources")?(
                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                          style={{padding: 0, marginTop: 0}}>
                                        <Grid item xs={8} sm={8} lg={8} xl={8}>
                                            <span>
                                                <span style={{float:"left"}}>
                                                    <AqaSourceEdit dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot} dataForDisabled={false}/>
                                                </span>
                                                {/*<span style={{float:"left"}}>
                                                    <AqaSourceInclude dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot}/>
                                                </span>
                                                <span style={{float:"left"}}>
                                                    <AqaSourceExclude dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot}/>
                                                </span>*/}
                                                <span style={{float:"left"}}>
                                                    <AqaSourceDelete dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot} dataForDisabled={false}/>
                                                </span>
                                                <span style={{float:"left"}}>
                                                    <AqaGetLink dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot} dataFromMainFrame={this.props.dataFromMainFrame} dataForDisabled={false} supress={false}/>
                                                </span>
                                            </span>
                                        </Grid>

                                        <Grid item xs={4} sm={4} lg={4} xl={4}>

                                        </Grid>
                                    </Grid>
                                ):((this.props.dataFromParent.state.selectedSources.length>1 && this.props.dataAcquired ==="allsources")?(<Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                         style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                            <span>
                                                <span style={{float:"left"}}>
                                                    <AqaSourceEdit dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot} dataForDisabled={true}/>
                                                </span>
                                                {/*<span style={{float:"left"}}>
                                                    <AqaSourceInclude dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot}/>
                                                </span>
                                                <span style={{float:"left"}}>
                                                    <AqaSourceExclude dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot}/>
                                                </span>*/}
                                                <span style={{float:"left"}}>
                                                    <AqaSourceDelete dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot} dataForDisabled={false}/>
                                                </span>
                                            </span>
                                    </Grid>

                                <Grid item xs={6} sm={6} lg={6} xl={6}>

                                </Grid>

                                </Grid>):(
                                (this.props.dataFromParent.state.selectedSources.length===1 && this.props.dataAcquired !=="allsources")?
                                    (<Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                           style={{padding: 0, marginTop: 0}}>
                                        <Grid item xs={8} sm={8} lg={8} xl={8}>
                                            <span>
                                                <span style={{float:"left"}}>
                                                    <AqaSourceEdit dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot} dataForDisabled={false}/>
                                                </span>
                                                {/*<span style={{float:"left"}}>
                                                    <AqaSourceInclude dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot}/>
                                                </span>
                                                <span style={{float:"left"}}>
                                                    <AqaSourceExclude dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot}/>
                                                </span>*/}
                                                <span style={{float:"left"}}>
                                                    <AqaSourceDelete dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot} dataForDisabled={true}/>
                                                </span>{/* onsole.log(this.props.dataFromParent.state.selectedSources) */}
                                                <span style={{float:"left"}}>
                                                    <AqaGetLink dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot} dataFromMainFrame={this.props.dataFromMainFrame} dataForDisabled={false} supress={false}/>
                                                </span>
                                            </span>
                                        </Grid>
                                        <Grid item xs={4} sm={4} lg={4} xl={4}>

                                        </Grid>

                                    </Grid>):(
                                        (this.props.dataFromParent.state.selectedSources.length>1 && this.props.dataAcquired !=="allsources")?
                                    (<Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                           style={{padding: 0, marginTop: 0}}>
                                        <Grid item xs={6} sm={6} lg={6} xl={6}>
                                            <span>
                                                <span style={{float:"left"}}>
                                                    <AqaSourceEdit dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot} dataForDisabled={true}/>
                                                </span>
                                                {/*<span style={{float:"left"}}>
                                                    <AqaSourceInclude dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot}/>
                                                </span>
                                                <span style={{float:"left"}}>
                                                    <AqaSourceExclude dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot}/>
                                                </span>*/}
                                                <span style={{float:"left"}}>
                                                    <AqaSourceDelete dataFromParent={this.props.dataFromParent} dataForFolders={this.props.dataForFolders} parent={this} dataAcquired={this.props.dataAcquired} dataFromRoot={this.props.dataFromRoot} dataForDisabled={true}/>
                                                </span>
                                            </span>
                                        </Grid>
                                        <Grid item xs={6} sm={6} lg={6} xl={6}>

                                        </Grid>

                                    </Grid>):(""))
                            )
                            )))))

                        }
                        <div style={{display:"none"}} >
                        <AqaRules dataFromParent={this} dataFromSource={this.props.dataFromSource} controller={this.props.dataFromParent} dataForAspect={this.props.dataForAspect} showEditor={this.props.showEditor} dataFromSnapshot={this.props.dataFromSnapshot}/>
                        </div>
                    </div>
            );
        }
    }
}

AqaSourceHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSourceHeader);
