/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaVectorDistributionColours = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The AqaVectorDistributionColours model module.
   * @module model/AqaVectorDistributionColours
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaVectorDistributionColours</code>.
   * @alias module:model/AqaVectorDistributionColours
   * @class
   */
  var exports = function() {
    var _this = this;






  };

  /**
   * Constructs a <code>AqaVectorDistributionColours</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaVectorDistributionColours} obj Optional instance to populate.
   * @return {module:model/AqaVectorDistributionColours} The populated <code>AqaVectorDistributionColours</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('orientation')) {
        obj['orientation'] = ApiClient.convertToType(data['orientation'], 'String');
      }
      if (data.hasOwnProperty('snapshotId')) {
        obj['snapshotId'] = ApiClient.convertToType(data['snapshotId'], 'String');
      }
      if (data.hasOwnProperty('valueRagBytes')) {
        obj['valueRagBytes'] = ApiClient.convertToType(data['valueRagBytes'], {'String': {'String': 'Number'}});
      }
      if (data.hasOwnProperty('vectorId')) {
        obj['vectorId'] = ApiClient.convertToType(data['vectorId'], 'Number');
      }
    }
    return obj;
  }

  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {module:model/AqaVectorDistributionColours.OrientationEnum} orientation
   */
  exports.prototype['orientation'] = undefined;
  /**
   * @member {String} snapshotId
   */
  exports.prototype['snapshotId'] = undefined;
  /**
   * @member {Object.<String, Object.<String, Number>>} valueRagBytes
   */
  exports.prototype['valueRagBytes'] = undefined;
  /**
   * @member {Number} vectorId
   */
  exports.prototype['vectorId'] = undefined;


  /**
   * Allowed values for the <code>orientation</code> property.
   * @enum {String}
   * @readonly
   */
  exports.OrientationEnum = {
    /**
     * value: "column"
     * @const
     */
    "column": "column",
    /**
     * value: "row"
     * @const
     */
    "row": "row"  };


  return exports;
}));


