/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.SecretKeySpec = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The SecretKeySpec model module.
   * @module model/SecretKeySpec
   * @version 1.0
   */

  /**
   * Constructs a new <code>SecretKeySpec</code>.
   * @alias module:model/SecretKeySpec
   * @class
   */
  var exports = function() {
    var _this = this;





  };

  /**
   * Constructs a <code>SecretKeySpec</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SecretKeySpec} obj Optional instance to populate.
   * @return {module:model/SecretKeySpec} The populated <code>SecretKeySpec</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('algorithm')) {
        obj['algorithm'] = ApiClient.convertToType(data['algorithm'], 'String');
      }
      if (data.hasOwnProperty('destroyed')) {
        obj['destroyed'] = ApiClient.convertToType(data['destroyed'], 'Boolean');
      }
      if (data.hasOwnProperty('encoded')) {
        obj['encoded'] = ApiClient.convertToType(data['encoded'], 'Blob');
      }
      if (data.hasOwnProperty('format')) {
        obj['format'] = ApiClient.convertToType(data['format'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {String} algorithm
   */
  exports.prototype['algorithm'] = undefined;
  /**
   * @member {Boolean} destroyed
   */
  exports.prototype['destroyed'] = undefined;
  /**
   * @member {Blob} encoded
   */
  exports.prototype['encoded'] = undefined;
  /**
   * @member {String} format
   */
  exports.prototype['format'] = undefined;



  return exports;
}));


