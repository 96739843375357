import React, {Component} from 'react';


const BUTTON_HEIGHT = 21;
const HEXA_SKEW = 6;



const FONT_FAMILY = "Montserrat";
const FONT_SIZE = 13;

export default class Button extends Component
{

	static #serial = 0;

	render()
	{
		// props
		//   x
		//   y
		//   on
		//   onClick
		//   w
		//   label
	
	
		const h3 = BUTTON_HEIGHT / 3;
		const straightw = this.props.w - (HEXA_SKEW << 1);
		const d = `M ${HEXA_SKEW} 0 h ${straightw} l ${HEXA_SKEW} ${h3} v ${h3} l -${HEXA_SKEW} ${h3} h -${straightw} l -${HEXA_SKEW} -${h3} v -${h3} l ${HEXA_SKEW} -${h3}`;


		const ts =
		{
			fontFamily:FONT_FAMILY,
			fontSize:FONT_SIZE,
			textAnchor:"middle",
			cursor:"pointer"
		};

		const fill = this.props.on ? "none" : "rgb(204,204,204)";


		return(
			<g
				key={`btn${Button.#serial++}`}
				transform={`translate(${this.props.x},${this.props.y})`}
				style={{shapeRendering:"geometricPrecision"}}
			>
		
				<path d={d} fill={fill} stroke="rgb(0, 0, 0)" strokeWidth="1" />

				<text
					y={14}
					x={this.props.w >> 1}
					style={ts}
					onClick={this.props.onClick}
				>
					{this.props.label}
				</text>
		
			</g>
		);

	
	
	} // render
	




} ////


//					className="svg-clickable"
