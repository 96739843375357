/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/AqaFinding'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./AqaFinding'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaTable = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.AqaFinding);
  }
}(this, function(ApiClient, AqaFinding) {
  'use strict';




  /**
   * The AqaTable model module.
   * @module model/AqaTable
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaTable</code>.
   * @alias module:model/AqaTable
   * @class
   */
  var exports = function() {
    var _this = this;















  };

  /**
   * Constructs a <code>AqaTable</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaTable} obj Optional instance to populate.
   * @return {module:model/AqaTable} The populated <code>AqaTable</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('analysisOrientation')) {
        obj['analysisOrientation'] = ApiClient.convertToType(data['analysisOrientation'], 'String');
      }
      if (data.hasOwnProperty('derived')) {
        obj['derived'] = ApiClient.convertToType(data['derived'], 'Boolean');
      }
      if (data.hasOwnProperty('findings')) {
        obj['findings'] = ApiClient.convertToType(data['findings'], {'String': [AqaFinding]});
      }
      if (data.hasOwnProperty('headerCaptions')) {
        obj['headerCaptions'] = ApiClient.convertToType(data['headerCaptions'], ['String']);
      }
      if (data.hasOwnProperty('headers')) {
        obj['headers'] = ApiClient.convertToType(data['headers'], 'Number');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('numberOfColumns')) {
        obj['numberOfColumns'] = ApiClient.convertToType(data['numberOfColumns'], 'Number');
      }
      if (data.hasOwnProperty('numberOfEmpties')) {
        obj['numberOfEmpties'] = ApiClient.convertToType(data['numberOfEmpties'], 'Number');
      }
      if (data.hasOwnProperty('numberOfMissings')) {
        obj['numberOfMissings'] = ApiClient.convertToType(data['numberOfMissings'], 'Number');
      }
      if (data.hasOwnProperty('numberOfMissingsOrEmpties')) {
        obj['numberOfMissingsOrEmpties'] = ApiClient.convertToType(data['numberOfMissingsOrEmpties'], 'Number');
      }
      if (data.hasOwnProperty('numberOfRows')) {
        obj['numberOfRows'] = ApiClient.convertToType(data['numberOfRows'], 'Number');
      }
      if (data.hasOwnProperty('originalTableId')) {
        obj['originalTableId'] = ApiClient.convertToType(data['originalTableId'], 'String');
      }
      if (data.hasOwnProperty('typeCounts')) {
        obj['typeCounts'] = ApiClient.convertToType(data['typeCounts'], ['Number']);
      }
      if (data.hasOwnProperty('version')) {
        obj['version'] = ApiClient.convertToType(data['version'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {module:model/AqaTable.AnalysisOrientationEnum} analysisOrientation
   */
  exports.prototype['analysisOrientation'] = undefined;
  /**
   * @member {Boolean} derived
   */
  exports.prototype['derived'] = undefined;
  /**
   * @member {Object.<String, Array.<module:model/AqaFinding>>} findings
   */
  exports.prototype['findings'] = undefined;
  /**
   * @member {Array.<String>} headerCaptions
   */
  exports.prototype['headerCaptions'] = undefined;
  /**
   * @member {Number} headers
   */
  exports.prototype['headers'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {Number} numberOfColumns
   */
  exports.prototype['numberOfColumns'] = undefined;
  /**
   * @member {Number} numberOfEmpties
   */
  exports.prototype['numberOfEmpties'] = undefined;
  /**
   * @member {Number} numberOfMissings
   */
  exports.prototype['numberOfMissings'] = undefined;
  /**
   * @member {Number} numberOfMissingsOrEmpties
   */
  exports.prototype['numberOfMissingsOrEmpties'] = undefined;
  /**
   * @member {Number} numberOfRows
   */
  exports.prototype['numberOfRows'] = undefined;
  /**
   * @member {String} originalTableId
   */
  exports.prototype['originalTableId'] = undefined;
  /**
   * @member {Array.<Number>} typeCounts
   */
  exports.prototype['typeCounts'] = undefined;
  /**
   * @member {String} version
   */
  exports.prototype['version'] = undefined;


  /**
   * Allowed values for the <code>analysisOrientation</code> property.
   * @enum {String}
   * @readonly
   */
  exports.AnalysisOrientationEnum = {
    /**
     * value: "column"
     * @const
     */
    "column": "column",
    /**
     * value: "row"
     * @const
     */
    "row": "row"  };


  return exports;
}));


