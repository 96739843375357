/* Welcome to Aqa*/
/* Version: 1 */


import React from 'react';


import AqaComponent from "../shared/aqacomponent/AqaComponent";
//import Heatmap from "../shared/heatmap/Heatmap";


import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import ListItemText from '@material-ui/core/ListItemText';
import {Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
//import {ReactComponent as SnapshotIcon} from "../../images/aqa-icons/AQA-Snapshot - v2.svg";
//import {ReactComponent as PersonIcon} from "../../images/aqa-icons/Usercol.svg";
import Typography from "@material-ui/core/Typography";
//import AqaCircularProgressBar from "../shared/meter/AqaCircularProgressBar";
//import {ReactComponent as EventIcon} from "../../images/aqa-icons/Uploadcol.svg";
//import moment from "moment";
//import AqaSparklineChart from "../shared/charts/AqaSparklineChart";
//import {ReactComponent as TrendDownIcon} from "../../images/aqa-icons/Trending Downcol.svg";
//import {ReactComponent as TrendUpIcon} from "../../images/aqa-icons/Trending Upcol.svg";
//import {ReactComponent as TrendNeutralIcon} from "../../images/aqa-icons/Flat Trendcol.svg";
//import AqaSnapshotDelete from "./AqaSnapshotDelete";
//import AqaGridIcon from "../../images/aqa-grid.png";

//import {ReactComponent as CheckIcon} from "../../images/aqa-icons/CheckOutline.svg";
//import {ReactComponent as XIcon} from "../../images/aqa-icons/XMarkOutline.svg";
//import {ReactComponent as HelpIcon} from "../../images/aqa-icons/HelpOutline.svg";
import HtmlTooltip from "@material-ui/core/Tooltip";
import AqaTable from "../../model/AqaTable";
//import Grid from "@material-ui/core/Grid";
import {SMALL_24_ASPECT_ICONS as QD_ICONS} from "../shared/AvaAspectIcons";
import moment from "moment";
//import {ReactComponent as RulesIcon} from "../../images/aqa-icons/AQA-Rules.svg";



const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaLibraryQD extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:{"name":"allsources"},
            folders: [],
            score: Math.round(Math.random()*100),
            lastScore: Math.round(Math.random()*100),
            diff: 0,
            historicalValues:[
                ["Last", "Scores"],
                [1, Math.round(Math.random()*90)],
                [2, Math.round(Math.random()*20)],
                [3, Math.round(Math.random()*60)],
                [4, Math.round(Math.random()*98)],
                [5, Math.round(Math.random()*80)],
                [6, Math.round(Math.random()*98)],
            ],
            numRows:16,
            numCols:0,
            columnData:[]
        };
        this.handleFolder = this.handleFolder.bind(this);
        this.handleSource = this.handleSource.bind(this);
        this.handleSnapshot = this.handleSnapshot.bind(this);
        this.handleSnapshotAll = this.handleSnapshotAll.bind(this);
        this.handleAspectStat=this.handleAspectStat.bind(this);
        this.handleReviewAspectStat=this.handleReviewAspectStat.bind(this);
        this.handleGetRowsColumns=this.handleGetRowsColumns.bind(this);
    }

    GetAscSortOrder(prop) {
        return function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }
    GetDescSortOrder(prop) {
        return function(a, b) {
            if (a[prop] < b[prop]) {
                return 1;
            } else if (a[prop] > b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    handleFolder(event){
        //this.props.dataFromRoot.handleFolder(event);
    }

    handleSnapshot(){
        this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter2(this.props.dataFromSource, this.props.dataFromParent, null, false, false, null);
    }

    handleSnapshotAll(){
        //console.log(this.props);
        if(this.props.dataForScore !== null) this.props.controller.handleSnapshotDetail("RedAmber");
    }

    handleAspectStat(e,index,root){
        e.preventDefault();
        if(root !== undefined && root.numRules !== undefined && root.numRules > 0) {

            if (index === 0) {
                // rules editor to go here
                //var aspect = {index: 0, firstColumn: {id: "a_0_0"}};
                var authCode=localStorage.getItem("authCode");
                var context;
                if(authCode !== "A"){
                    this.removeAllNotifications();
                    this.showNotification(4, null,context , null);
                }
                else {
                    //this.props.dataFromRoot.props.dataFromParent.handleShowRule(aspect, this.props.dataFromParent);
                    this.props.controller.handleSnapshotDetail("RedAmber");
                }
                /*if(aspect.name === "Dimension"){
                    // table aspect rule editor to go here
                    this.props.dataFromRoot.handleShowDimensionRule(aspect,this.props.dataAcquired);
                }
                else{
                    // column aspect rule editor to go here
                    this.props.dataFromRoot.handleShowRule(aspect,this.props.dataAcquired);
                }*/
            } else if (index === 1) {
                //this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter3(this.props.dataFromSource, this.props.dataFromParent, null, false, true, null);
                this.props.controller.handleSnapshotDetail("Red");
            } else if (index === 2) {
                this.props.controller.handleSnapshotDetail("Amber");
                // this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter3(this.props.dataFromSource, this.props.dataFromParent, null, true, false, null);
            } else {
                // snapshot detail screen call to go here
            }
        }
        else{
            this.props.controller.handleSnapshotDetail("RedAmber",true);
        }
    }

    handleReviewAspectStat(e,index,root,type){
        e.preventDefault();
        if(root !== undefined && root.numR !== undefined) {

            if (index === 0) {
                // rules editor to go here
                //var aspect = {index: 0, firstColumn: {id: "a_0_0"}};
                var authCode=localStorage.getItem("authCode");
                var context;
                if(authCode !== "A"){
                    this.removeAllNotifications();
                    this.showNotification(4, null,context , null);
                }
                else {
                    //this.props.dataFromRoot.props.dataFromParent.handleShowRule(aspect, this.props.dataFromParent);
                    this.props.controller.handleSnapshotDetail2("RedAmber",type);
                }
                /*if(aspect.name === "Dimension"){
                    // table aspect rule editor to go here
                    this.props.dataFromRoot.handleShowDimensionRule(aspect,this.props.dataAcquired);
                }
                else{
                    // column aspect rule editor to go here
                    this.props.dataFromRoot.handleShowRule(aspect,this.props.dataAcquired);
                }*/
            } else if (index === 1) {
                //this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter3(this.props.dataFromSource, this.props.dataFromParent, null, false, true, null);
                if((type===0 && root.numR >0) || (type===1 && root.numI >0) || (type===2 && root.numF >0) ) this.props.controller.handleSnapshotDetail2("Red",type);
            } else if (index === 2) {
                if((type===0 && root.numR >0) || (type===1 && root.numI >0) || (type===2 && root.numF >0) )this.props.controller.handleSnapshotDetail2("Amber",type);
                // this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter3(this.props.dataFromSource, this.props.dataFromParent, null, true, false, null);
            } else {
                // snapshot detail screen call to go here
            }
        }
    }

    handleGetRowsColumns(snapshot)
    {
        this.table = new AqaTable(
            snapshot.id,
            e=> {
                var rows=16;
                var cols=16;
                if(this.table.data !== undefined){
                    rows=this.table.data.numberOfRows;
                    cols=this.table.data.numberOfColumns;
                }
                this.setState({numRows:rows,numCols:cols});

            }
        )
    } // handleGetRowsColumns

    handleSource(){
        this.props.dataFromRoot.handleSource(this.props.dataFromParent);
    }

    handleQualityDefinition = () =>{
        let source=this.props.dataFromParent;
        AqaComponent.backend.getQualityDefinitionUsingGET(
            source.id,
            (error, data, response) => {
                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                else {
                    let numCols = 0;
                    if(data.vectorQualityTable !== undefined) numCols = data.vectorQualityTable.columnQualities.length;
                    this.setState({qd:data,numCols:numCols});
                }
            }
        )
    }

    handleSnapshotRow = ()=>{

    }

    handleColumnQualities = (columnQualities) =>{
        let columnData=[];
        for(let i=0;i<columnQualities.length;i++){
            let column = columnQualities[i];
            let type = "String";
            let typeValue = column.typeEnabled?column.typeSeverity:"";
            let populatedValue = column.populatedEnabled?(
                column.populatedType==="percentagePopulated"?
                    (column.populatedBoundary.reversed? column.populatedBoundary.number1+"%":column.populatedBoundary.number2+"%"):
                    column.populatedType==="absolutePopulated"?
                        (column.populatedBoundary.reversed? column.populatedBoundary.number1:column.populatedBoundary.number2):""
                ):"";
            let uniquenessValue = column.uniquenessEnabled?(
                column.uniquenessType==="RepeatsAllowed"?
                    (column.uniquenessBoundary.reversed? column.uniquenessBoundary.number2:column.uniquenessBoundary.number1):
                    column.uniquenessType==="UniquePercentage"?
                        (column.uniquenessBoundary.reversed? column.uniquenessBoundary.number1+"%":column.uniquenessBoundary.number2+"%"):
                        column.uniquenessType==="DuplicatesAllowed"?
                            (column.uniquenessBoundary.reversed? column.uniquenessBoundary.number2:column.uniquenessBoundary.number2):""
            ):"";
            let formatEnabled=false;
            let valueEnabled=false;
            let formats = [];
            let greenFormats = [];
            let amberFormats = [];
            let redFormats = [];
            let greenFormatString="";
            let amberFormatString="";
            let redFormatString="";
            let formatValue ="";
            let greenAllowedStrings="";
            let amberAllowedStrings="";
            let redAllowedStrings="";
            let boundary1=null;
            let boundary2=null;
            let allowedValues = [];
            if(column.stringEnabled) {
                type="String";
                formatEnabled=column.useStringFormats;
                if(formatEnabled){
                    greenFormats=column.greenStringFormats;
                    amberFormats=column.amberStringFormats;
                    redFormats=column.redStringFormats;
                    formats.push(greenFormats);formats.push(amberFormats);formats.push(redFormats);
                    greenFormatString = greenFormats!==null && greenFormats.length>0?"Green: "+greenFormats.toString():"";
                    amberFormatString = amberFormats!==null && amberFormats.length>0?"Amber: "+amberFormats.toString():"";
                    redFormatString = redFormats!==null && redFormats.length>0?"Red: "+redFormats.toString():"";
                    if(formats.length === 1) {formatValue = formats[0];}
                    else {formatValue = formats.length;}
                }
                valueEnabled = column.useAllowedValues || column.useStringLengths;
                if(column.useAllowedValues){
                    allowedValues.push(column.greenStringValues);
                    allowedValues.push(column.amberStringValues);
                    allowedValues.push(column.redStringValues);
                    if(allowedValues.length>0){
                        greenAllowedStrings = column.greenStringValues!==null && column.greenStringValues.length>0?"Green: "+column.greenStringValues.toString():"";
                        amberAllowedStrings = column.amberStringValues!==null && column.amberStringValues.length>0?"Amber: "+column.amberStringValues.toString():"";
                        redAllowedStrings = column.redStringValues!==null && column.redStringValues.length>0?"Red: "+column.redStringValues.toString():"";
                    }
                }
                if(column.useStringLengths){
                    boundary1=column.stringLengthBoundary1;
                    boundary2=column.stringLengthBoundary2;
                }
            }
            else if(column.numberEnabled){
                type="Number";
                formatEnabled=column.useNumberFormats;
                if(formatEnabled){
                    greenFormats=column.greenNumberFormats;
                    amberFormats=column.amberNumberFormats;
                    redFormats=column.redNumberFormats;
                    formats.push(greenFormats);formats.push(amberFormats);formats.push(redFormats);
                    greenFormatString = greenFormats!==null && greenFormats.length>0?"Green: "+greenFormats.toString():"";
                    amberFormatString = amberFormats!==null && amberFormats.length>0?"Amber: "+amberFormats.toString():"";
                    redFormatString = redFormats!==null && redFormats.length>0?"Red: "+redFormats.toString():"";
                    if(formats.length === 1) {formatValue = formats[0];}
                    else {formatValue = formats.length;}
                }
                valueEnabled=column.useNumberBoundaries;
                if(valueEnabled){
                    boundary1=column.numberBoundary1;
                    boundary2=column.numberBoundary2;
                }
            }
            else if(column.dateEnabled) {
                type="Date";
                formatEnabled=column.useDateFormats;
                if(formatEnabled){
                    greenFormats=column.greenDateFormats;
                    amberFormats=column.amberDateFormats;
                    redFormats=column.redDateFormats;
                    formats.push(greenFormats);
                    formats.push(amberFormats);
                    formats.push(redFormats);
                    greenFormatString = greenFormats!==null && greenFormats.length>0?"Green: "+greenFormats.toString():"";
                    amberFormatString = amberFormats!==null && amberFormats.length>0?"Amber: "+amberFormats.toString()+"\n":"";
                    redFormatString = redFormats!==null && redFormats.length>0?"Red: "+redFormats.toString():"";
                    if(formats.length === 1) {formatValue = formats[0];}
                    else {formatValue = formats.length;}
                }
                valueEnabled=column.useDateBoundaries;
                if(valueEnabled){
                    boundary1=column.dateBoundary1;
                    boundary2=column.dateBoundary2;
                }
            }
            else type="String";
            let greenValue = "Green: ";
            let amberValue = "Amber: ";
            let redValue = "Red: ";
            if(type ==="String" || type === "Number") {
                if (boundary1 !== null) {
                    if (!boundary1.reversed) {
                        if (boundary1.useNumber1 && boundary1.useNumber2) {
                            greenValue = greenValue + " > " + boundary1.number2 + " && ";
                            amberValue = amberValue + " > " + boundary1.number1 + " && < " + boundary1.number2 + " && ";
                            redValue = redValue + " < " + boundary1.number1 + " || ";
                        }
                        if (!boundary1.useNumber1 && boundary1.useNumber2) {
                            greenValue = greenValue + " > " + boundary1.number2 + " && ";
                            amberValue = amberValue + " < " + boundary1.number2 + " || ";
                        }
                        if (boundary1.useNumber1 && !boundary1.useNumber2) {
                            greenValue = greenValue + " > " + boundary1.number1 + " && ";
                            amberValue = amberValue + " < " + boundary1.number1 + " || ";
                        }
                    } else {
                        if (boundary1.useNumber1 && boundary1.useNumber2) {
                            greenValue = greenValue + " < " + boundary1.number1 + " || ";
                            amberValue = amberValue + " > " + boundary1.number1 + " < " + boundary1.number2 + " && ";
                            redValue = redValue + " > " + boundary1.number2 + " && ";
                        }
                        if (!boundary1.useNumber1 && boundary1.useNumber2) {
                            greenValue = greenValue + " < " + boundary1.number1 + " || ";
                            redValue = redValue + " > " + boundary1.number2 + " && ";
                        }
                        if (boundary1.useNumber1 && !boundary1.useNumber2) {
                            amberValue = amberValue + " < " + boundary1.number1 + " && ";
                            redValue = redValue + " > " + boundary1.number1 + " || ";
                        }
                    }
                }

                if (boundary2 !== null) {
                    if (boundary2.reversed) {
                        if (boundary2.useNumber1 && boundary2.useNumber2) {
                            greenValue = greenValue + " < " + boundary2.number1;
                            amberValue = amberValue + " > " + boundary2.number1 + " < " + boundary2.number2;
                            redValue = redValue + " > " + boundary2.number2;
                        }
                        if (!boundary2.useNumber1 && boundary2.useNumber2) {
                            amberValue = amberValue + " < " + boundary2.number2;
                            redValue = redValue + " > " + boundary2.number2;
                        }
                        if (boundary2.useNumber1 && !boundary2.useNumber2) {
                            greenValue = greenValue + " < " + boundary2.number1;
                            amberValue = amberValue + " > " + boundary2.number1;
                        }
                    } else {
                        if (boundary2.useNumber1 && boundary2.useNumber2) {
                            greenValue = greenValue + " < " + boundary2.number1;
                            amberValue = amberValue + " > " + boundary2.number1 + " < " + boundary2.number2;
                            redValue = redValue + " > " + boundary2.number2;
                        }
                        if (!boundary2.useNumber1 && boundary2.useNumber2) {
                            amberValue = amberValue + " < " + boundary2.number2;
                            redValue = redValue + " > " + boundary2.number2;
                        }
                        if (boundary2.useNumber1 && !boundary2.useNumber2) {
                            greenValue = greenValue + " < " + boundary2.number1;
                            amberValue = redValue + " > " + boundary2.number1;
                        }
                    }
                }
            }
            else{
                if (boundary1 !== null) {
                    if (!boundary1.reversed) {
                        if (boundary1.useDate1 && boundary1.useDate2) {
                            greenValue = greenValue + " > " + moment(boundary1.date2).format("DD-MMM-YYYY") + " && ";
                            amberValue = amberValue + " > " + moment(boundary1.date1).format("DD-MMM-YYYY") + " && < " + moment(boundary1.date2).format("DD-MMM-YYYY") + " && ";
                            redValue = redValue + " < " + moment(boundary1.date1).format("DD-MMM-YYYY") + " || ";
                        }
                        if (!boundary1.useDate1 && boundary1.useDate2) {
                            greenValue = greenValue + " > " + moment(boundary1.date2).format("DD-MMM-YYYY") + " && ";
                            amberValue = amberValue + " < " + moment(boundary1.date2).format("DD-MMM-YYYY") + " || ";
                        }
                        if (boundary1.useDate1 && !boundary1.useDate2) {
                            greenValue = greenValue + " > " + moment(boundary1.date1).format("DD-MMM-YYYY") + " && ";
                            amberValue = amberValue + " < " + moment(boundary1.date1).format("DD-MMM-YYYY") + " || ";
                        }
                    } else {
                        if (boundary1.useDate1 && boundary1.useDate2) {
                            greenValue = greenValue + " < " + moment(boundary1.date1).format("DD-MMM-YYYY") + " || ";
                            amberValue = amberValue + " > " + moment(boundary1.date1).format("DD-MMM-YYYY") + " < " + moment(boundary1.date2).format("DD-MMM-YYYY") + " && ";
                            redValue = redValue + " > " + moment(boundary1.date2).format("DD-MMM-YYYY") + " && ";
                        }
                        if (!boundary1.useDate1 && boundary1.useDate2) {
                            greenValue = greenValue + " < " + moment(boundary1.date1).format("DD-MMM-YYYY") + " || ";
                            redValue = redValue + " > " + moment(boundary1.date2).format("DD-MMM-YYYY") + " && ";
                        }
                        if (boundary1.useDate1 && !boundary1.useDate2) {
                            amberValue = amberValue + " < " + moment(boundary1.date1).format("DD-MMM-YYYY") + " && ";
                            redValue = redValue + " > " + moment(boundary1.date1).format("DD-MMM-YYYY") + " || ";
                        }
                    }
                }

                if (boundary2 !== null) {
                    if (boundary2.reversed) {
                        if (boundary2.useDate1 && boundary2.useDate2) {
                            greenValue = greenValue + " < " + moment(boundary2.date1).format("DD-MMM-YYYY");
                            amberValue = amberValue + " > " + moment(boundary2.date1).format("DD-MMM-YYYY") + " < " + moment(boundary2.date2).format("DD-MMM-YYYY");
                            redValue = redValue + " > " + moment(boundary2.date2).format("DD-MMM-YYYY");
                        }
                        if (!boundary2.useDate1 && boundary2.useDate2) {
                            amberValue = amberValue + " < " + moment(boundary2.date2).format("DD-MMM-YYYY");
                            redValue = redValue + " > " + moment(boundary2.date2).format("DD-MMM-YYYY");
                        }
                        if (boundary2.useDate1 && !boundary2.useDate2) {
                            greenValue = greenValue + " < " + moment(boundary2.date1).format("DD-MMM-YYYY");
                            amberValue = amberValue + " > " + moment(boundary2.date1).format("DD-MMM-YYYY");
                        }
                    } else {
                        if (boundary2.useDate1 && boundary2.useDate2) {
                            greenValue = greenValue + " < " + moment(boundary2.date1).format("DD-MMM-YYYY");
                            amberValue = amberValue + " > " + moment(boundary2.date1).format("DD-MMM-YYYY") + " < " + moment(boundary2.date2).format("DD-MMM-YYYY");
                            redValue = redValue + " > " + moment(boundary2.date2).format("DD-MMM-YYYY");
                        }
                        if (!boundary2.useDate1 && boundary2.useDate2) {
                            amberValue = amberValue + " < " + moment(boundary2.date2).format("DD-MMM-YYYY");
                            redValue = redValue + " > " + moment(boundary2.date2).format("DD-MMM-YYYY");
                        }
                        if (boundary2.useDate1 && !boundary2.useDate2) {
                            greenValue = greenValue + " < " + moment(boundary2.date1).format("DD-MMM-YYYY");
                            amberValue = redValue + " > " + moment(boundary2.date1).format("DD-MMM-YYYY");
                        }
                    }
                }
            }


            let columnTemp = {id:column.id,name:column.header,type:type,
                populatedEnabled:column.populatedEnabled,populatedValue:populatedValue,populatedType:column.populatedType,
                valueEnabled:valueEnabled,allowedValues:column.useAllowedValues,
                greenValue:greenValue,amberValue:amberValue,redValue:redValue,
                greenAllowedStrings:greenAllowedStrings,amberAllowedStrings:amberAllowedStrings,redAllowedStrings:redAllowedStrings,
                uniquenessEnabled:column.uniquenessEnabled,uniquenessType:column.uniquenessType,uniquenessValue:uniquenessValue,
                nativeEnabled:column.nativeErrorsEnabled,
                typeEnabled:column.typeEnabled,typeValue:typeValue,
                formatEnabled:formatEnabled,formatValue:formatValue,
                greenFormatString:greenFormatString,amberFormatString:amberFormatString,redFormatString:redFormatString
            };
            columnData.push(columnTemp);
        }
        columnData = columnData.sort(this.GetAscSortOrder('id'));
        this.setState({numCols:columnQualities.length,columnData:columnData})
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.dataFromParent.status!== "Loading") {
            //this.handleGetRowsColumns(nextProps.dataFromParent);
        }
    }

    componentDidMount(){
        if(this.props.currentQD !==null){
            let currentQD = this.props.currentQD;
            let columns = currentQD.qualityDefinition.vectorQualityTable.columnQualities;
            this.handleColumnQualities(columns);
        }
    }
    render() {
        //const { /*classes*/currentQD } = this.props;
        const {  columnData} = this.state;
        return (
            <div>
                <Card width="100%">
                    <CardContent style={{padding:"6px"}}>

                        <Table id={this.props.dataFromGrid.id + "_snaptable"}
                               cellSpacing={0}
                               aria-sort="ascending" style={{}}>
                            <colgroup>
                                <col width="4%"/>
                                <col width="22%"/>
                                <col width="12%"/>
                                <col width="12%"/>
                                <col width="12%"/>
                                <col width="12%"/>
                                <col width="12%"/>
                                <col width="12%"/>
                                <col width="4%"/>

                            </colgroup>
                            <TableHead style={{
                                'overflow': 'auto',
                                display: 'table',
                                width: this.props.dataFromParent.state.showHistory===true ? window.innerWidth * 0.70 : window.innerWidth * 0.97
                            }}>
                                <TableRow style={{height: "18px"}}>
                                    <TableCell width="4%"
                                               style={{"padding": "0px 0px 0px 4px"}}>
                                        <div className="aqa-text-bold"></div>
                                    </TableCell>
                                    <TableCell width="22%"
                                               style={{"padding": "0px 0px 0px 4px"}}>
                                        <div className="aqa-text-bold">Column</div>
                                    </TableCell>
                                    <TableCell width="12%"
                                               style={{"padding": "0px"}}>
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            <div>
                                                <Typography color="inherit" className="aqa-text-action">Missing Value Errors</Typography>
                                            </div>

                                        </React.Fragment>} arrow={"true"} placement="bottom-end">
                                            <div className="aqa-text-bold"
                                                 style={{textAlign: "right"}}>Populate
                                            </div>
                                        </HtmlTooltip>
                                    </TableCell>
                                    <TableCell width="12%"
                                               style={{"padding": "0px"}}>
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            <div>
                                                <Typography color="inherit" className="aqa-text-action">Errors in Formats</Typography>
                                            </div>

                                        </React.Fragment>} arrow={"true"} placement="bottom-end">
                                            <div className="aqa-text-bold"
                                                 style={{textAlign: "right"}}>Format
                                            </div>
                                        </HtmlTooltip>
                                    </TableCell>
                                    <TableCell width="12%" style={{
                                        "padding": "0px",
                                        marginRight: "5px"
                                    }}>
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            <div>
                                                <Typography color="inherit" className="aqa-text-action">Errors in allowed values or lengths</Typography>
                                            </div>

                                        </React.Fragment>} arrow={"true"} placement="bottom-end">
                                            <div className="aqa-text-bold" style={{
                                                marginRight: "0px",
                                                textAlign: "right"
                                            }}>Value
                                            </div>
                                        </HtmlTooltip>
                                    </TableCell>
                                    <TableCell width="12%"
                                               style={{"padding": "0px"}}>
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            <div>
                                                <Typography color="inherit" className="aqa-text-action">Duplicate Value Errors</Typography>
                                            </div>

                                        </React.Fragment>} arrow={"true"} placement="bottom-end">
                                            <div className="aqa-text-bold"
                                                 style={{textAlign: "right"}}>Uniqueness
                                            </div>
                                        </HtmlTooltip>
                                    </TableCell>
                                    <TableCell width="12%"
                                               style={{"padding": "0px"}}>
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            <div>
                                                <Typography color="inherit" className="aqa-text-action">Type Errors</Typography>
                                            </div>

                                        </React.Fragment>} arrow={"true"} placement="bottom-end">
                                            <div className="aqa-text-bold"
                                                 style={{textAlign: "right"}}>Type
                                            </div>
                                        </HtmlTooltip>
                                    </TableCell>
                                    <TableCell width="12%"
                                               style={{"padding": "0px"}}>
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            <div>
                                                <Typography color="inherit" className="aqa-text-action">Process Errors</Typography>
                                            </div>

                                        </React.Fragment>} arrow={"true"} placement="bottom-end">
                                            <div className="aqa-text-bold"
                                                 style={{textAlign: "right"}}>Native
                                            </div>
                                        </HtmlTooltip>
                                    </TableCell>
                                    <TableCell width="4%"
                                               style={{"padding": "0px 0px 0px 4px"}}>
                                        <div className="aqa-text-bold"></div>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody style={{
                                'height': (window.innerHeight - 100) * 0.50,
                                'overflowY': 'auto',
                                display: 'block',
                                width: this.props.dataFromParent.state.showHistory===true ? window.innerWidth * 0.71 : window.innerWidth * 0.97
                            }}>
                                {columnData.map((item, index) => (
                                    <TableRow className="aqa-list" key={item.id} style={{
                                        display: 'table',
                                        tableLayout: 'fixed',
                                        height: "18px",
                                        width:window.innerWidth * 0.99
                                    }}>
                                        <TableCell size="small" width="4%"
                                                   style={{padding: "4px 4px 4px 10px"}}>
                                            <IconButton className="aqa-button"
                                                        style={{padding: 0}}>
                                                <div className="aqa-list-icon">
                                                    {QD_ICONS[item.type]}
                                                </div>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell size="small" width="22%"
                                                   style={{padding: "4px 4px 4px 10px"}}>
                                            <div className="aqa-text-bold-12"
                                                 style={{textAlign: "left"}}
                                                 onClick={(e) => this.handleSnapshotRow(item, "overall", "redamber", index)}>{item.name}</div>
                                        </TableCell>
                                        <TableCell size="small" width="12%" style={{padding: "4px 4px 4px 10px"}}>
                                            {!item.populatedEnabled ?
                                                <div
                                                    className={item.populatedEnabled ? "aqa-text-bold-12" : "aqa-text-bold-12"}
                                                    style={{
                                                        textAlign: "right",
                                                        //color: item.populatedEnabled ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                        fontWeight: item.populatedEnabled ? "bold" : "100"
                                                    }}>{item.populatedEnabled ? item.populatedValue : "-"}</div>
                                                :
                                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                    <div>
                                                        <Typography color="inherit" className="aqa-text-action">
                                                            {item.populatedType === "percentagePopulated"?item.populatedValue+ " % populated":
                                                                item.populatedType === "absolutePopulated"?item.populatedValue+" Absolute populated":
                                                                        " Missing Value Errors "
                                                            }
                                                        </Typography>
                                                    </div>

                                                </React.Fragment>} arrow={"true"} placement="bottom-end">

                                                    <div
                                                        className={item.populatedEnabled ? "aqa-text-bold-12" : "aqa-text-bold-12"}
                                                        style={{
                                                            textAlign: "right",
                                                            //color: item.populatedEnabled ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                            fontWeight: item.populatedEnabled ? "bold" : "100"
                                                        }}
                                                        onClick={(e) => this.handleSnapshotRow(item, "populated", "amber", index)}>{item.populatedEnabled ? item.populatedValue : "-"}</div>
                                                </HtmlTooltip>
                                            }
                                        </TableCell>
                                        <TableCell size="small" width="12%" style={{padding: "4px 4px 4px 10px"}}>
                                            {!item.formatEnabled ?
                                                <div
                                                    className={item.formatEnabled ? "aqa-text-bold-12" : "aqa-text-bold-12"}
                                                    style={{
                                                        textAlign: "right",
                                                        //color: item.formatEnabled ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                        fontWeight: item.formatEnabled ? "bold" : "100"
                                                    }}>{item.formatEnabled ? item.formatValue : "-"}</div>
                                                :
                                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                    <div>
                                                        <Typography color="inherit" className="aqa-text-action">
                                                            <span>{item.greenFormatString}</span><br/>
                                                            <span>{item.amberFormatString}</span><br/>
                                                            <span>{item.redFormatString}</span>
                                                        </Typography>
                                                    </div>

                                                </React.Fragment>} arrow={"true"} placement="bottom-end">

                                                    <div
                                                        className={item.formatEnabled ? "aqa-text-bold-12" : "aqa-text-bold-12"}
                                                        style={{
                                                            textAlign: "right",
                                                            //color: item.formatEnabled ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                            fontWeight: item.formatEnabled ? "bold" : "100"
                                                        }}
                                                        onClick={(e) => this.handleSnapshotRow(item, "format", "amber", index)}>{item.formatEnabled ? item.formatValue : "-"}</div>
                                                </HtmlTooltip>
                                            }
                                        </TableCell>
                                        <TableCell size="small" width="12%"
                                                   style={{padding: "4px 4px 4px 10px"}}>
                                            {!item.valueEnabled ?
                                                <div
                                                    className={item.valueEnabled ? "aqa-text-bold-12" : "aqa-text-bold-12"}
                                                    style={{
                                                        textAlign: "right",
                                                        //color: item.valueEnabled ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                        fontWeight: item.valueEnabled ? "bold" : "100"
                                                    }}>{item.valueEnabled ? "On" : "-"}</div>
                                                :
                                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                    <div>
                                                        {item.allowedValues?<div>
                                                            <Typography color="inherit" className="aqa-text-action">
                                                                <span>Allowed Values</span>
                                                                <br/>
                                                                <span>{item.greenAllowedStrings}</span><br/>
                                                                <span>{item.amberAllowedStrings}</span><br/>
                                                                <span>{item.redAllowedStrings}</span><br/>
                                                            </Typography>
                                                        </div>:""

                                                        }
                                                        <Typography color="inherit" className="aqa-text-action">
                                                            <span style={{paddingTop:"10px"}}>Range</span>
                                                            <br />
                                                            <span>{item.greenValue}</span><br/>
                                                            <span>{item.amberValue}</span><br/>
                                                            <span>{item.redValue}</span>
                                                        </Typography>
                                                    </div>

                                                </React.Fragment>} arrow={"true"} placement="bottom-end">

                                                    <div
                                                        className={item.valueEnabled ? "aqa-text-bold-12" : "aqa-text-bold-12"}
                                                        style={{
                                                            textAlign: "right",
                                                            //color: item.valueEnabled ? "rgb(40,40,40)" : "rgb(40,40,40)",
                                                            fontWeight: item.valueEnabled ? "bold" : "100"
                                                        }}
                                                        onClick={(e) => this.handleSnapshotRow(item, "value", "amber", index)}>{item.valueEnabled ? "On" : "-"}</div>
                                                </HtmlTooltip>
                                            }
                                        </TableCell>
                                        <TableCell size="small" width="12%" style={{padding: "4px 4px 4px 10px"}}>

                                            {!item.uniquenessEnabled ?
                                                <div
                                                    className={item.uniquenessEnabled ? "aqa-text-bold-12" : "aqa-text-bold-12"}
                                                    style={{
                                                        textAlign: "right",
                                                        //color: item.uniquenessEnabled ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                        fontWeight: item.uniquenessEnabled ? "bold" : "100"
                                                    }}>{item.uniquenessEnabled ? item.uniquenessValue : "-"}</div>
                                                :
                                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                    <div>
                                                        <Typography color="inherit" className="aqa-text-action">
                                                            {item.uniquenessType === "UniquePercentage"?item.uniquenessValue+ " % unique":
                                                                item.uniquenessType === "DuplicatesAllowed"?item.uniquenessValue+" duplicates allowed":
                                                                    item.uniquenessType === "RepeatsAllowed"?item.uniquenessValue+" repeats allowed":
                                                                        " Duplicate Value Errors "
                                                            }
                                                        </Typography>
                                                    </div>

                                                </React.Fragment>} arrow={"true"} placement="bottom-end">

                                                    <div
                                                        className={item.uniquenessEnabled ? "aqa-text-bold-12" : "aqa-text-bold-12"}
                                                        style={{
                                                            textAlign: "right",
                                                            //color: item.uniquenessEnabled ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                            fontWeight: item.uniquenessEnabled ? "bold" : "100"
                                                        }}
                                                        onClick={(e) => this.handleSnapshotRow(item, "uniqueness", "amber", index)}>{item.uniquenessEnabled ? item.uniquenessValue : "-"}</div>
                                                </HtmlTooltip>
                                            }
                                        </TableCell>
                                        <TableCell size="small" width="12%"
                                                   style={{padding: "4px 4px 4px 10px"}}>
                                            <div
                                                className={item.typeEnabled ? "aqa-text-bold-12" : "aqa-text-bold-12"}
                                                style={{
                                                    textAlign: "right",
                                                    //color: item.typeEnabled ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                    fontWeight: item.typeEnabled ? "bold" : "100"
                                                }}
                                                onClick={(e) => this.handleSnapshotRow(item, "type", "amber", index)}>{item.typeEnabled ? "On" : "-"}</div>
                                        </TableCell>
                                        <TableCell size="small" width="12%"
                                                   style={{padding: "4px 4px 4px 10px"}}>
                                            <div
                                                className={item.nativeEnabled ? "aqa-text-bold-12" : "aqa-text-bold-12"}
                                                style={{
                                                    textAlign: "right",
                                                    //color: item.nativeEnabled ? "rgb(251, 154, 40)" : "rgb(40,40,40)",
                                                    fontWeight: item.nativeEnabled? "bold" : "100"
                                                }}
                                                onClick={(e) => this.handleSnapshotRow(item, "native", "amber", index)}>{item.nativeEnabled ? "On" : "-"}</div>
                                        </TableCell>

                                        <TableCell size="small" width="4%"
                                                   style={{padding: "4px 4px 4px 10px"}}>
                                            {/*<IconButton className="aqa-button" style={{
                                                padding: 0,
                                                textAlign: "right"
                                            }}
                                                        onClick={(e) => this.handleShowEditor(item, item.index)}>
                                                <div className="aqa-list-icon">
                                                    <RulesIcon width="24px"/>
                                                </div>
                                            </IconButton>*/}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </div>
        );

    }
}

AqaLibraryQD.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaLibraryQD);

