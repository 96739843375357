/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react'
import AqaComponent       from "../shared/aqacomponent/AqaComponent"
// import Grid from "@material-ui/core/Grid";p
import {ReactComponent as ExpandVerticalIcon} from "../../images/aqa-icons/Expand Verticalcol.svg"
import {withStyles}       from "@material-ui/core/styles"
import PropTypes          from "prop-types"

import AqaTypeMetrics     from "./AqaTypeMetrics"
import DistributionGraph  from "../shared/distributiongraph/DistributionGraph"
// import AqaCensusMetrics   from "./AqaCensusMetrics";
import AqaHeatMapForDataViewer from "./AqaHeatMapForDataViewer"

// import AqaSnapshotTypeTable from "./AqaSnapshotTypeTable";

const styles = theme =>
({
	root:
	{
		maxWidth: 345,
		margin: "8px",
	},
});

class AqaSnapshotSummaryDistributionCensusCombo extends AqaComponent
{
	constructor(props)
	{
		super(props);
		this.state =
		{
			statistics:props.statistics,
			visible:props.visible
		};

		props.parent.registerCombo(this, props.position);
		this.visible = props.visible; // We have to mirror the state because React cannot be trusted.

	} //

	toggle()
	{
	
// onsole.log("visible: [" + visible + "] this.state.visible: [" + this.state.visible + "]");	
// onsole.log("this.state.visible: [" + this.state.visible + "]");	

		this.visible = !this.state.visible;
		this.setState({visible: this.visible});
		this.props.controller.updateWidthAndHeightImmediately();
	} // toggle

	/** Returns true if a redraw is necessary */
	setVisibility(visible)
	{
	

	
		const ret = this.visible !== visible;
		if (ret)
		{
			this.visible = visible;
			this.setState({visible});
		}
		return ret;
	} // setVisibility

	getVisibility() { return this.visible; }

	render()
	{
/*
onsole.log("Rendering combo");
const v = "[" + this.state.visible + "]";
*/
		return (
			<div className="aqa-toggle-body">


								<div onClick={() => this.toggle()} className="aqa-button" style={{width:"34px",marginBottom:this.state.visible ? "-34px" : "0", marginLeft:"0px"}}>
									<div className="" style={{width:"32px"}}>
										<ExpandVerticalIcon width="32px" />
									</div>
								</div>



							<div className={this.state.visible ? "aqa-display-toggle" : "aqa-hide-toggle"} style={{minHeight:"160px",overflow:"hidden", paddingRight:"10px"}}>

								<AqaTypeMetrics
									key={"tt" + this.props.position}
									controller={this.props.controller}
									parent={this}
									position={this.props.position}
									statistics={this.state.statistics}
									style={{borderRight:"2px solid #F9F7F9"}}
									float={this.props.typeMetricsFloat}
								/>

								<DistributionGraph
									key={"sdg_" + this.props.position}
									id={"sdg_" + this.props.position}
									controller={this.props.controller}
									parent={this.props.parent}
									position={this.props.position}
									width={this.props.comboDistributionGraphParameters}
									float={this.props.distributionGraphFloat}
								/>

								{/*
								<AqaCensusMetrics
									key={"cm" + this.props.position}
									controller={this.props.controller}
									parent={this.props.parent}
									position={this.props.position}
									float={this.props.censusMetricsFloat}
								/>
								*/}
								
								<AqaHeatMapForDataViewer
									key={"cm" + this.props.position}
									controller={this.props.controller}
									position={this.props.position}
									float={this.props.censusMetricsFloat}
									clickerCallback={(x, xScale, y, yScale) => this.props.controller.heatmapClick(this.props.position, x, xScale, y, yScale) }
								/>

							</div>


			</div>
		);
//      }

	} // render

} ////


AqaSnapshotSummaryDistributionCensusCombo.propTypes =
{
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSnapshotSummaryDistributionCensusCombo);


