/* Welcome to Aqa Client*/
/* Version: 1 */

import React from "react";
import AqaComponent from "../../shared/aqacomponent/AqaComponent";
import AqaTable from "../../../model/AqaTable";
import AqaRulesEditor from "./AqaRulesEditor";
//import AqaBoundaries from "../../../model/AqaBoundaries"

import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as FolderIcon} from "../../../images/aqa-icons/AQA-Rules.svg";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import AqaSnapshotRulesColumn from "./AqaRulesColumn"; // WHY???????????????

//import ReactDOM from 'react-dom';
import HtmlTooltip from "@material-ui/core/Tooltip";
import {ReactComponent as CloseIcon} from "../../../images/aqa-icons/AQA-Exclude.svg";
import {DialogActions} from "@material-ui/core";
import ColourAndOverrideMaps from "../../../model/ColourAndOverrideMaps";



const styles = theme => ({
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    welcomelabel: {
        fontSize: 18,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 20,
        marginRight: 20,
        color: "#006"
    }
});


// Defined in AqaRulesParent as well - should be merged
//const UNIQUENESS = "uniqueness";
//const POPULATED  = "populated";
const NUMBER     = "number";
const DATE       = "date";
const STRING     = "string";
// const RAG_COLOURS = ["red", "amber", "green"];
// End const also deined in AqaRulesParent


const RULES =
{

	qualityEnabled:false,

	// Uniqueness	
	uniquenessEnabled:false,
	uniquenessType:"UniquePercentage",
	uniquenessBoundary:null,

	// Populated	
	populatedEnabled:false,
	populatedType:null,
	populatedBoundary:null,
	
	// Number
	numberEnabled:false,
	useNumberFormats:false,
	useNumberBoundaries:false,
	numberBoundary1:null,
	numberBoundary2:null,


	numberFormatsAllowed:null,
	greenNumberFormats:null,
	amberNumberFormats:null,
	redNumberFormats: null,
	unlistedNumberFormatColour: "green",
	

	// Date
	dateEnabled:false,
	useDateFormats:false,
	useDateBoundaries:false,
	dateBoundary1:null,
	dateBoundary2:null,
	
	dateFormatsAllowed:false,
	greenDateFormats:null,
	amberDateFormats: null,
	redDateFormats: null,
	unlistedDateFormatColour: "green",

	// String
	stringEnabled:false,
	useStringLengths:false,
	

//	allowedValues:null,
	stringLengthBoundary1:null,
	stringLengthBoundary2:null,
	
	useAllowedValues:false,
	redStringValues:null,
	amberStringValues:null,
	greenStringValues:null,
	unlistedStringValueColour: "green",

	useStringFormats:false,
	greenStringFormats: null,
	amberStringFormats: null,
	redStringFormats: null,
	unlistedStringFormatColour: "green",
	
	
	// Native Errors
	nativeErrorsEnabled:false,

	// Type
	typeEnabled:false,
	requiredType: null, // Inventory obtained from backend. Skip 'unknown'
	typeSeverity: "amber" // one of["amber", "red"]

};

Object.freeze(RULES);



class AqaRules extends AqaComponent
{

static id = 0;


	constructor(props)
	{
		super(props);


		this.rules = AqaComponent.conflate(RULES); // Clone the array (But not actually a deep copy)
		this.vector = null;
		this.blank = true;



		// Assign A ref to state for refresh
		this.state =
		{
			openEditor: false,
			changeOccurred:false,
			isComputationRequired:false,
			errorMessage: "",
			rules:
			{
				"qualityEnabled":false,
				"uniquenessEnabled":false,
				"uniquenessType":null,
				"uniquenessRed":0.0,
				"uniquenessAmber":0.0,
				"populatedEnabled":false,
				"populatedType":null,
				"populatedRed":0.0,
				"populatedAmber":0.0,
				"numberEnabled":false,
				"numberFormatsAllowed":null,
				"numberBoundary1":null,
				"numberBoundary2":null,
				"dateEnabled":false,
				"dateFormatsAllowed":null,
				"dateBoundary1":null,
				"dateBoundary2":null,
				"stringEnabled":false
			},
			table:null,
			retry:0,
//			firstColId:0,
			type:0, // What is this a type of?? Seems like it's the VQD data type - to keep it between column moves. 20230125
//			columnBeingEditedName: "" // used by the data viewer
			
			selectedColumnId:0,
			selectedColumnName: "",
			columns:[],
			suppress:false
		};
		let controller = this.props.controller.props.controller !== undefined?this.props.controller.props.controller:this.props.controller;
		//this.authCode = (this.props.controller.props.controller !== undefined?this.props.controller.props.controller.props.dataFromRoot.state.authCode:this.props.controller.props.dataFromRoot.state.authCode);
		this.authCode = controller.props.dataFromMainFrame.props.parent.state.authCode;
		this.snapshotCache = [];
		this.amDerived = false;
		this.headerRow="";
		this.distriGraph = [null, null];

// ! None of this binding calls looks necessary.
//		this.handleGetColumnsFromSnapshot = this.handleGetColumnsFromSnapshot.bind(this);
//		this.handleGetRulesForSource = this.handleGetRulesForSource.bind(this);
		//      this.handleGetColumnHeatmap = this.handleGetColumnHeatmap.bind(this);
//		this.handleShowRulesEditor = this.handleShowRulesEditor.bind(this);
//		this.handleRulesModified = this.handleRulesModified.bind(this);
//		this.loadSnapshot=this.loadSnapshot.bind(this);
		
		this.currentRuleEditorElement = null;

//		this.s electedColumnId = -1;


		if (this.props.dataFromParent && this.props.dataFromParent.registerVqdEditor) this.props.dataFromParent.registerVqdEditor(this);
		if(this.props.controller && this.props.controller.registerVqdEditor) this.props.controller.registerVqdEditor(this);

	} //
	
//	registerCurrentRuleElement(crel) { this.currentRuleEditorElement = crel; }

	handleGetColumnsFromSnapshot = snapshotId => this.loadSnapshot(snapshotId, null, null, this.derived);


	findColIndexInData(data, property, value)
	{
		let n = data ? data.length : 0, item;
		for(let i = 0; i < n; i++)
		{
			item = data[i];
			if (item[property] === value || item[property].indexOf(value) >= 0) return i
		}
		return -1;

/*		
		data.some
		(
			(item, i) =>
			{
				if (item[property] === value || item[property].indexOf(value) >= 0)
				{
					result == i;
				 return true;
				}
				return false;
			}
		);
		return result;
*/
	} // findColIndexInData

	extractAndPadRowValues(cellValues, sizeWanted)
	{
		let i, n = (cellValues !== null ? cellValues.length : 0);
		if (n > sizeWanted) n = sizeWanted; // Highly improbable, and if the case, a serious problem.

		const ret = Array(sizeWanted);
		for(i = 0; i < n; i++) ret[i] = cellValues[i].coercedValue; // should use map: ret = cellValues.map(c => c.coercedValue);
		while(i < sizeWanted) ret[i++] = ""; // should use slice or splice or whatever 

		return ret;

	} // extractAndPadRowValues

	loadSnapshot = (snapshotId, motherSnapshotId, filtr, derived) =>
	{
		// If we're here, we haven't loaded this snapshot before.
		// So we load it.
		let n = this.snapshotCache.length;
		let cacheItem = {};
		this.snapshotCache[n] = cacheItem;

		cacheItem.snapshotId = snapshotId;
		cacheItem.motherSnapshotId = motherSnapshotId;
		cacheItem.filtr = filtr;
		this.amDerived = cacheItem.amDerived = derived;

		this.snapshotId = snapshotId;


		this.table = new AqaTable
		(
			this.snapshotId,
			(table, e) =>
			{
				if (e != null)
				{
					this.reportError("A problem getting the snapshot statistics from the server was encountered.", "AqaRules.loadSnapshot, table instanciaton:\n" + e);
					return;
				}

				if(this.table.data === undefined) return;

				this.nRows    = table.data.numberOfRows;
				this.nColumns = table.data.numberOfColumns;
				if (this.nRows < 0) this.nRows = 0;

				cacheItem.nRows = this.nRows;
				cacheItem.nColumns = this.nColumns;

				// Get header row
				// We only get it for the main snapshot
				// As we keep the same header for the filtered views
				AqaComponent.snapshotBackend.getRowVectorValuesUsingGET
				(
					this.snapshotId,
					0,
					{},
					(error, data, response) =>
					{
						if (error) this.reportError("A problem getting the snapshot header from the server was encountered.", "AqaRules.loadSnapshot, table, header, backend call: " + error,this);
						else
						{
							this.headerRow = this.extractAndPadRowValues(data[0].values, this.nColumns);
							this.handleGetRulesForSource(true);
						}
					}
				);
			}

		);
		cacheItem.table = this.table;
		if (!this.amDerived) this.motherTable = this.table;
		this.setState({table:this.table});


	} // loadSnapshot



	handleGetRulesForSource(showEditor)
	{

		AqaComponent.backend.getQualitiesUsingGET
		(
			this.props.dataFromSource.id,
			(error, data, response) =>
			{
				if (error) this.reportError
				(
					"A problem getting the rules from the server was encountered.",
					"AqaRules.handleGetRulesForSource, backend call: " + error
				);
				else
				{
					// we are done here
					this.setState({savedRules:data});
					this.handleRulesChange(showEditor);
				}
			}
		);

	} // handleGetRulesForSource

	handleSuppress = isActive => this.setState({suppress:isActive});

	handleRuleEditor = () =>
	{

		var context;
		if (this.authCode !== "A")
		{
			this.removeAllNotifications();
			this.showNotification(4, null, context, null);
		}
		else
		{
			/*if(this.props.dataFromSource.name === "Example Data"){
			var customNotification = {
			title: "Restricted", titleIcon: "",
			message: "You cannot Modify the rules for this Data Source",
			isCustom: false, type: "info",
			insert: "center", position: "center", autoClose: true, duration: 5000
			};
			this.removeAllNotifications();
			this.showNotification(null, customNotification);
			}
			else {*/
			this.handleSnapshots();
			/* }*/
		}

	}; // handleRuleEditor

	handleRulesChange(showEditor)
	{
		const columns = [];
        
 //       this.setState({columns:columns}); // Why? This will have no effect!

		const savedRules = this.state.savedRules.columnQualities;
		for(var r = 0; r < this.headerRow.length; r++)
		{
			var columnObj = {};
			var columntemp={rid:0,name:"dummy",isActive:false};
			columntemp['rid']=r;
			columntemp['name']=this.headerRow[r];
			if (savedRules !== undefined)
			{
				const index = this.findColIndexInData(savedRules, "id", "column_" + r);
				if (index >= 0)
				{
					const savedRule = savedRules[index];
					if (savedRule!==null)
					{
						if
						(
							savedRule.uniquenessEnabled === true
						||
							savedRule.populatedEnabled===true
						||
							savedRule.stringEnabled ===true
						||
							 savedRule.numberEnabled === true
						||
							savedRule.dateEnabled === true)
						{
							columntemp['isActive'] = true;
						}
					}
					columnObj = {...columntemp, ...savedRule}
				}
				else
				{
					columnObj={...columntemp};
				}
			}
			else
			{
				columnObj={...columntemp};
			}
			columns.push(columnObj);
		}

//        this.setState({columns:columns});

		this.blank = true;
		this.setState
		(
			{
//										statistics:[this.motherTable.getStats(), this.amDerived ? this.table.getStats() : null],
				columns,
				headerAvailable:true,
				openEditor: true
			}
		);

		if(showEditor) this.handleShowRulesEditor(this.state.selectedColumnId, this.headerRow[this.state.selectedColumnId], this.state.type);

	} // handleRulesChange

	handleNameChange() {  }

	handleSnapshots()
	{
		var sourceId=this.props.dataFromSource.id;
		AqaComponent.backend.getSnapshotsForSourceUsingGET
		(
			sourceId,
			0,
			9999,
			(error, data, response) =>
			{
				if (error) this.reportError("A problem getting the snapshots from the server was encountered.", "AqaRules.handleSnapshots, backend call: " + error,this);
				else
				{
					if (this.state.isComputationRequired === true)
					{
						for (var i = 0; i < data.length; i++)
						{
							var snapshot = data[i];
							var refresh=false;
							//                          this.handleGetColumnHeatmap(snapshot,true);
							if(i === data.length-1)
							{
								refresh=true
								this.handleSnapshotScoreComputation(snapshot.id,refresh);
							}
							else
							{
								this.handleSnapshotScoreComputation(snapshot.id,refresh);
							}
						}
						// Re-render the view to show the rules are computed

						this.setState({openEditor: false, isComputationRequired:false});
					}
					else
					{
						if (data.length > 0)
						{
							snapshot = data[0];
							this.handleGetColumnsFromSnapshot(snapshot.id); // This takes the id of the first snapshot in the source.
						}
						else
						{
							alert("No Snapshots Available");
						}
					}
				}
			}
		);
	} // handleSnapshots

	handleSnapshotScoreComputation(snapshotId, refresh)
	{

		AqaComponent.snapshotBackend.getSnapshotScoreUsingGET
		(
			snapshotId,
			(error, data, response) =>
			{
				if (error)
				{
// onsole.log("Problem occured while recomputing the scores for " + snapshotId);
					console.error("Problem occured while recomputing the scores for " + snapshotId + "\n" + error);

					// TODO: implement retry!
					if(this.state.retry === 1)
					{

					}
					else
					{
//						this.setState({openEditor: true, errorMessage: "There is a problem with rule(s)",retry:1});
						this.setState({openEditor: true, errorMessage: "There is a problem with the rule(s)", retry:1});
					}
				}
				else
				{
					// we are done here
					// @Teebo Change if you want
					
// onsole.log("WE ARE IN RULES AND WE WANT TO REFRESH");					

//					if (refresh === true && this.props.controller !== undefined && ) this.props.controller.handleRefresh();
					ColourAndOverrideMaps.clear(snapshotId);
					setTimeout(()=> {
						if (refresh === true && this.props.controller && this.props.controller.handleRefresh) this.props.controller.handleRefresh();
					},1000);


				}
			}
		);
	} // handleSnapshotScoreComputation

/*
    handleGetColumnHeatmap(snapshot,recompute){
        // Getting the maps - Tests and demo only NOTE: THIS IS NOT DOING ANYTHING! (apart from calling the server for nothing)
        new SnapshotColumnsHeatmap
        (
            snapshot.id, // snapshot id
            16, // width
            16, // height
            recompute, // Don't recompute
            sch => // callback that will be run when heat map data is available.
            {

            }
        );
    }
*/

/*

    handleClosePopup(){
        this.setState({isComputationRequired:true});
        this.handleSnapshots();
    }
*/  

	makeId = () => `${this.props.dataFromSource.id}_column_${this.state.selectedColumnId}`;

 
	createCompositeValues = () =>
	{
		// For compatibility - but other code should not rely on these left values!!!!!
		// HOWEVER 20230331 it seems these values are convenient shortcut in the UI
		// These are virtual values for our FE benefit - they are not saved in the BE 20220316
		// The BE does send them back but they are *computed* according to the same rules as below.
		const typeEnabled = this.rules.typeEnabled && this.rules.typeSeverity && this.rules.typeSeverity.length > 0;
		const requiredType = this.rules.requiredType;

		this.rules.numberEnabled = this.rules.useNumberFormats || this.rules.useNumberBoundaries || (typeEnabled && requiredType === NUMBER);
		this.rules.dateEnabled   = this.rules.useDateFormats   || this.rules.useDateBoundaries   || (typeEnabled && requiredType === DATE);
		this.rules.stringEnabled = this.rules.useStringFormats || this.rules.useStringLengths    || this.rules.useAllowedValues || (typeEnabled && requiredType === STRING);
	
	
	}; // createCompositeValues
	
	


	/** All based on this.rules - so much simpler! */
	adjustCompositeRuleValues()
	{
	
		this.createCompositeValues();
		this.handleRulesModified(this.rules); // jsonObj

	} // adjustCompositeRuleValues

    
    
   handleModalSaveRules(callBackAfter)
	{
	
		this.createCompositeValues();
	

//		const authCode = this.props.sourceDetailObject.props.dataFromRoot.props.parent.state.authCode;

		if (this.authCode !== "A")
		{
			const adminEmail = this.props.sourceDetailObject.props.dataFromRoot.props.parent.state.adminEmail;
			let context; // This should be... what?? @Vamsi

			this.removeAllNotifications();
			this.showNotification(4, null, context , null, adminEmail);
		}
		else
		{
			// To make a deep copy - in modal mode we don't care about preserving the origin object as we will close the popup
			// UNLESS an error occurs I guess and we want to go back to the display
			let jsonObj = AqaComponent.conflate(this.rules);



			jsonObj.id = this.makeId();
			jsonObj.qualityEnabled = true;
			

			AqaComponent.backend.createVectorQualityDefinitionUsingPOST
			(
				jsonObj,
				(error, data, response) =>
				{

					if (error) this.reportError
					(
						"A problem was encountered when updating check definitions.",
						"AqaRulesParent.handleModalSaveRules, call: " + error
					);
					else
					{
						if (callBackAfter) callBackAfter();
						else
						{
							//this.props.parent.handleColumnUpdate(jsonObj);
//							this.props.rulesObject.handleRulesModified(this.props.dataForCol, jsonObj);
						}
					}
				}
			);
		}
	} // handleModalSaveRules

	loadQualityDefinitionsAndContext(callback)
	{
		if (this.blank)
		{
			this.blank = false;
			this.callbacks = [callback];
			this.rules = AqaComponent.conflate(RULES);
			this.vector = null;
		}
		else
		{
			if (this.vector !== null) callback(this.rules, this.vector);
			else this.callbacks.push(callback);
			return;
		}

		AqaComponent.backend.getQualityUsingGET
		(
			this.makeId(),
			(error, data, response) =>
			{

				if (error) this.reportError
				(
					"A problem loading vector quality definitions occurred.",
					"AqaRulesParent.loadQualityDefinitionsAndContext, call: " + error
				);
				else
				{
					this.rules = data;

					this.state.table.getColumnVector
					(
						this.state.selectedColumnId,
						vector =>
						{
							this.vector = vector;
							for(const cb of this.callbacks) cb(this.rules, vector);							
						}

					);
				}
			}
		);

	} // loadQualityDefinitionsAndContext


    
    
	// In case we want to do something here
	handleCancelPopup = () =>
	{
		this.setState({openEditor: false, isComputationRequired: false});
		this.blank = true;
	}

	handleSaveRulesClosePopup = () =>
	{

		this.handleModalSaveRules
		(
			() =>
			{
				this.blank = true;
				this.setState({isComputationRequired:true});
				this.handleSnapshots();
			}
		);
	}; // handleSaveRulesClosePopup

	handleRulesModified = (columnObj) =>
	{
		if (this.props.suppress) return; // no need


/*
		var columns = this.state.columns;
		var column = columns[this.state.selectedColumnId];
		var newColumns = [];
		
onsole.log("HRM 2");



		if (column !== null)
		{
			column = {...column,...columnObj}
			
onsole.log("HRM 3");

			/ *
			if(columnObj['stringEnabled'] || columnObj['numberEnabled'] || columnObj['dateEnabled'] || columnObj['uniquenessEnabled'] || olumnObj['populatedEnabled'] )
			{
				column['isActive'] = true;
			}
			else
			{
				column['isActive'] = false;
			}
			* /
onsole.log("HRM 4");
			column['isActive'] = columnObj['stringEnabled'] || columnObj['numberEnabled'] || columnObj['dateEnabled'] || columnObj['uniquenessEnabled'] || columnObj['populatedEnabled'];

			let n = columns.length;
			for(let i = 0; i < n; i++)
			{
				if (i === this.state.selectedColumnId) newColumns.push(column);
				else newColumns.push(columns[i]);
			}

onsole.log("HRM 5");


			// @Teebo 
			this.setState({columns:newColumns, changeOccurred:true});
		} 
		*/

		this.setState({changeOccurred:true});


	}; // handleRulesModified




	handleShowRulesEditor = (selectedColumnId, selectedColumnName, type) =>
	{

//onsole.log("selectedColumnId: ", selectedColumnId);
//onsole.log("selectedColumnId: ", selectedColumnName);
//onsole.log("selectedColumnId: ", type);

		this.setState
		(
			{
				selectedColumnId,
				selectedColumnName,
				type
			}
		);

	} // handleShowRulesEditor



	getSelectedColumnId() { return this.state.selectedColumnId; }

	// WE SHOULD NOT BE USING UNSAFE HOOKS!!! 2023.02.07: Removed - but not sure all it was catering for has been covered.

//	componentDidMount() { }
   
	render()
	{
		//const { classes } = this.props;
		const { openEditor, columns, errorMessage,suppress} = this.state;

		return (
			<div>
			
				{
					this.props.suppress || suppress
					?
						null
					:
						<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0,display:"none"}}>

							<Grid item xs={12} sm={12} lg={12} xl={12}>
								<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"8px"}}>
									<HtmlTooltip
										enterDelay={500}
										title={
											<React.Fragment>
												<div>
													<Typography color="inherit" className="aqa-text-action">Click to edit column Checks</Typography>
												</div>
											</React.Fragment>
										}
										arrow={"true"}
										placement="bottom-start"
									>
										<IconButton className="aqa-button" onClick={this.handleRuleEditor} style={{padding:"5px"}} >
											<div className="aqa-g-icon" style={{width:"32px"}}>
												<FolderIcon width="32px" />
											</div>
											<Typography
												className="aqa-action-header-text"
												variant="inherit"
												style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}
											>
												COLUMN CHECKS
											</Typography>
										</IconButton>
									</HtmlTooltip>
								</Typography>
							</Grid>
						</Grid>
				}

				<Dialog
					open={openEditor}
					//onClose={this.handleCloseMessage}
					aria-labelledby="max-width-dialog-title"
					maxWidth="lg"
				>
					<DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
						<Typography
							variant="inherit"
							color="inherit"
							style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}
						>

							{this.props.suppress || suppress ? "Column Checks: " + this.state.selectedColumnName: "Quality Checks"}

						</Typography>
						<Typography
							variant="inherit"
							color="inherit"
							style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}
						>
							<IconButton className="aqa-button" onClick={this.handleCancelPopup} style={{padding:"0px"}} >
								<div className="aqa-g-icon" style={{width:"20px"}}>
									<CloseIcon width="20px" />
								</div>
							</IconButton>
						</Typography>
					</DialogTitle>
					<DialogContent align="center" style={{padding: 0,minWidth: "1090px",width: "1090px"}}>
						<div>
							<div id="rule-master-pane">
								{
									this.props.suppress || suppress
									?
										<div style={{minWidth: "1060px", width:"1060px", height:"64px"}}/>
									
									:
										<AqaSnapshotRulesColumn
											dataForColumns={columns}
											parent={this}
											dataForActiveColumn={this.state.selectedColumnId}
											sourceDetailObject={this.props.controller}
										/>
								}

							</div>
							{
								this.state.columns !== null && this.state.columns.length > 0
								?
									<AqaRulesEditor
										dataFromSnapshot={this.props.dataFromSnapshot} // Might not be needed Teebo 20211115
										dataForName={this.state.selectedColumnName}
										dataForCol={this.state.selectedColumnId}
										dataForType={this.state.type}
										dataFromSource={this.props.dataFromSource}
										dataForColumn={this.state.columns[this.state.selectedColumnId]}
										parent={this}
	//									dataForTable={this.state.table}
										sourceDetailObject={this.props.controller}
										suppress={suppress||this.props.suppress}
										rules={this.rules}
									/>
								:
									null
							}


						</div>
						<Grid
							container spacing={0}
							direction="row"
							justify="space-between"
							alignItems="center"
							style={{padding: 0, marginTop: 0}}
						>
							<Grid item xs={12} sm={12} lg={12} xl={12}>
								<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
									{(errorMessage !=="")?errorMessage:""}
								</Typography>
							</Grid>
						</Grid>



					</DialogContent>
					<DialogActions style={{textAlign:"left",justifyContent:"flex-start"}}>
						<div>
							<Grid
								container
								spacing={0}
								direction="row"
								justify="space-between"
								alignItems="flex-start"
								style={{padding: 0, marginTop:0,marginBottom:0, marginLeft:"0px"}}
							>
								<Grid item xs={3} sm={3} lg={3} xl={3}>
									<Button
										onClick={this.handleSaveRulesClosePopup}
										variant="contained"
										color="primary"
										align="left"
										className="aqa-action-button"
										style={{
											marginTop: 8,
											marginLeft: "0px",
											marginRight: 8,
											color: '#4cadc4',
											backgroundColor: 'white',
											border: '1px solid #4cadc4',
											fontSize: '0.7rem',
											padding: '3px',
											width:"150px"
										}}
									>
										{/* This could even be more subtle: if no change has occurred, the cancel button should not even be here!!! */}
										{this.state.changeOccurred ? "Close & Recompute" : "Close"}
									</Button>

								</Grid>
								<Grid item xs={6} sm={6} lg={6} xl={6}></Grid>
								<Grid item xs={4} sm={4} lg={4} xl={4}></Grid>
							</Grid>
						</div>
					</DialogActions>
				</Dialog>
			</div>



		);
	} // render

} ////

// <div style={{minWidth: "1060px",width:"1060px",height:"32px"}}>{this.state.selectedColumnName}</div>

AqaRules.propTypes = { classes: PropTypes.object.isRequired};

export default withStyles(styles)(AqaRules);
