/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/AqaNewPlan', '../model/AqaSubscriptionDetails', '../model/AqaSubscriptionUpdateResponse', '../model/PriceRequest'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/AqaNewPlan'), require('../model/AqaSubscriptionDetails'), require('../model/AqaSubscriptionUpdateResponse'), require('../model/PriceRequest'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AQASalesControllerApi = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.AqaNewPlan, root.ApiDocumentation.AqaSubscriptionDetails, root.ApiDocumentation.AqaSubscriptionUpdateResponse, root.ApiDocumentation.PriceRequest);
  }
}(this, function(ApiClient, AqaNewPlan, AqaSubscriptionDetails, AqaSubscriptionUpdateResponse, PriceRequest) {
  'use strict';

  /**
   * AQASalesController service.
   * @module api/AQASalesControllerApi
   * @version 1.0
   */

  /**
   * Constructs a new AQASalesControllerApi. 
   * @alias module:api/AQASalesControllerApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the getAllPlansUsingGET operation.
     * @callback module:api/AQASalesControllerApi~getAllPlansUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/AqaNewPlan>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves all plans
     * @param {module:api/AQASalesControllerApi~getAllPlansUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/AqaNewPlan>}
     */
    this.getAllPlansUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json'];
      var returnType = [AqaNewPlan];

      return this.apiClient.callApi(
        '/api/v1/sales/plans', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getPlanUsingGET operation.
     * @callback module:api/AQASalesControllerApi~getPlanUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaNewPlan} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves one plan from its id
     * @param {String} planId planId
     * @param {module:api/AQASalesControllerApi~getPlanUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaNewPlan}
     */
    this.getPlanUsingGET = function(planId, callback) {
      var postBody = null;

      // verify the required parameter 'planId' is set
      if (planId === undefined || planId === null) {
        throw new Error("Missing the required parameter 'planId' when calling getPlanUsingGET");
      }


      var pathParams = {
        'planId': planId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json'];
      var returnType = AqaNewPlan;

      return this.apiClient.callApi(
        '/api/v1/sales/plans/{planId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getSubscriptionDetailsUsingGET operation.
     * @callback module:api/AQASalesControllerApi~getSubscriptionDetailsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaSubscriptionDetails} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets some details of a subscription. Use to decide whether a user already has a subscription
     * @param {module:api/AQASalesControllerApi~getSubscriptionDetailsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaSubscriptionDetails}
     */
    this.getSubscriptionDetailsUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaSubscriptionDetails;

      return this.apiClient.callApi(
        '/api/v1/sales/account/subscriptionDetails', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the priceUsingGET operation.
     * @callback module:api/AQASalesControllerApi~priceUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaNewPlan} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Prices an order based on the number of users and sources, now returns an AqaNewPlan so it will show Monthly and Yearly prices
     * @param {String} planId planId
     * @param {String} paymentFrequency paymentFrequency
     * @param {Object} opts Optional parameters
     * @param {Number} opts.numberOfEditors numberOfEditors (default to 0)
     * @param {Number} opts.numberOfSources numberOfSources (default to 0)
     * @param {String} opts.currency currency (default to 0)
     * @param {module:api/AQASalesControllerApi~priceUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaNewPlan}
     */
    this.priceUsingGET = function(planId, paymentFrequency, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'planId' is set
      if (planId === undefined || planId === null) {
        throw new Error("Missing the required parameter 'planId' when calling priceUsingGET");
      }

      // verify the required parameter 'paymentFrequency' is set
      if (paymentFrequency === undefined || paymentFrequency === null) {
        throw new Error("Missing the required parameter 'paymentFrequency' when calling priceUsingGET");
      }


      var pathParams = {
      };
      var queryParams = {
        'numberOfEditors': opts['numberOfEditors'],
        'numberOfSources': opts['numberOfSources'],
        'currency': opts['currency'],
        'planId': planId,
        'paymentFrequency': paymentFrequency,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaNewPlan;

      return this.apiClient.callApi(
        '/api/v1/sales/products/price', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the updateSubscriptionUsingPUT operation.
     * @callback module:api/AQASalesControllerApi~updateSubscriptionUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaSubscriptionUpdateResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates a subscription
     * @param {module:model/PriceRequest} priceRequest priceRequest
     * @param {module:api/AQASalesControllerApi~updateSubscriptionUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaSubscriptionUpdateResponse}
     */
    this.updateSubscriptionUsingPUT = function(priceRequest, callback) {
      var postBody = priceRequest;

      // verify the required parameter 'priceRequest' is set
      if (priceRequest === undefined || priceRequest === null) {
        throw new Error("Missing the required parameter 'priceRequest' when calling updateSubscriptionUsingPUT");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = AqaSubscriptionUpdateResponse;

      return this.apiClient.callApi(
        '/api/v1/sales/subscriptions', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
