/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaSnapshotFilter = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The AqaSnapshotFilter model module.
   * @module model/AqaSnapshotFilter
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaSnapshotFilter</code>.
   * @alias module:model/AqaSnapshotFilter
   * @class
   */
  var exports = function() {
    var _this = this;













  };

  /**
   * Constructs a <code>AqaSnapshotFilter</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaSnapshotFilter} obj Optional instance to populate.
   * @return {module:model/AqaSnapshotFilter} The populated <code>AqaSnapshotFilter</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('finerGrain')) {
        obj['finerGrain'] = ApiClient.convertToType(data['finerGrain'], 'Number');
      }
      if (data.hasOwnProperty('onlyAmbers')) {
        obj['onlyAmbers'] = ApiClient.convertToType(data['onlyAmbers'], 'Boolean');
      }
      if (data.hasOwnProperty('onlyGreens')) {
        obj['onlyGreens'] = ApiClient.convertToType(data['onlyGreens'], 'Boolean');
      }
      if (data.hasOwnProperty('onlyReds')) {
        obj['onlyReds'] = ApiClient.convertToType(data['onlyReds'], 'Boolean');
      }
      if (data.hasOwnProperty('orientation')) {
        obj['orientation'] = ApiClient.convertToType(data['orientation'], 'String');
      }
      if (data.hasOwnProperty('position')) {
        obj['position'] = ApiClient.convertToType(data['position'], 'Number');
      }
      if (data.hasOwnProperty('reviewType')) {
        obj['reviewType'] = ApiClient.convertToType(data['reviewType'], 'Number');
      }
      if (data.hasOwnProperty('rowBased')) {
        obj['rowBased'] = ApiClient.convertToType(data['rowBased'], 'Boolean');
      }
      if (data.hasOwnProperty('subType')) {
        obj['subType'] = ApiClient.convertToType(data['subType'], 'Number');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('valueFrom')) {
        obj['valueFrom'] = ApiClient.convertToType(data['valueFrom'], 'String');
      }
      if (data.hasOwnProperty('valueTo')) {
        obj['valueTo'] = ApiClient.convertToType(data['valueTo'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {Number} finerGrain
   */
  exports.prototype['finerGrain'] = undefined;
  /**
   * @member {Boolean} onlyAmbers
   */
  exports.prototype['onlyAmbers'] = undefined;
  /**
   * @member {Boolean} onlyGreens
   */
  exports.prototype['onlyGreens'] = undefined;
  /**
   * @member {Boolean} onlyReds
   */
  exports.prototype['onlyReds'] = undefined;
  /**
   * @member {String} orientation
   */
  exports.prototype['orientation'] = undefined;
  /**
   * @member {Number} position
   */
  exports.prototype['position'] = undefined;
  /**
   * @member {Number} reviewType
   */
  exports.prototype['reviewType'] = undefined;
  /**
   * @member {Boolean} rowBased
   */
  exports.prototype['rowBased'] = undefined;
  /**
   * @member {Number} subType
   */
  exports.prototype['subType'] = undefined;
  /**
   * @member {String} type
   */
  exports.prototype['type'] = undefined;
  /**
   * @member {String} valueFrom
   */
  exports.prototype['valueFrom'] = undefined;
  /**
   * @member {String} valueTo
   */
  exports.prototype['valueTo'] = undefined;



  return exports;
}));


