
export const AqaTypes =
{
	unknown:0,
	string:1,
	number:2,
	date:3,
	nativeErrors:4 // Check this!!
};


/*

0		unknown,
1		string,
2		number,
3		bool,
4		date,

*/


const UNIQUENESS_ASPECT   = "uniqueness";
const POPULATED_ASPECT    = "populated";
const STRING_ASPECT       = "string";
const NUMBER_ASPECT       = "number";
const DATE_ASPECT         = "date";
const NATIVEERRORS_ASPECT = "native errors";

export const ASPECTS =
[
	UNIQUENESS_ASPECT,
	POPULATED_ASPECT,
	STRING_ASPECT,
	NUMBER_ASPECT,
	DATE_ASPECT,
	NATIVEERRORS_ASPECT
];


export default class AqaAnalysisObject
{

	/** return an array. 0: total cells, 1: cells filled, 2: cells empty, 3: percentage of cells filled (ie. populated) */
	getPopulation()
	{
		const ret = Array(4);
		ret[0] = this.getTotalNumberOfCells();
		ret[2] = this.data.numberOfMissingsOrEmpties;
		ret[1] = ret[0] - ret[2];
		ret[3] = ret[1] * 100 / ret[0];
		return ret;
	
	} // getPopulation()


	/** returns an array where each entry is an array of: metric name, %, absolute
	 */
	getStats()
	{
		const population = this.getPopulation()
	
		let ret =
		[
			[UNIQUENESS_ASPECT, 0, 0], // because hell... what is it?
			[POPULATED_ASPECT, population[3], population[1]],
		];		


		[STRING_ASPECT, NUMBER_ASPECT, DATE_ASPECT, NATIVEERRORS_ASPECT].forEach
		(
			t =>
			{
				let count = this.data.typeCounts[AqaTypes[t]];
				ret.push([t, count * 100 / population[0], count]);
			}
		);

		return ret;
	
	} // getStats
	
	
	safeGet(path, defaultValue)
	{
		if (!path || path.length === 0) return null;
		const els = path.split('.');

		let o = this.data;
		for(let pe of els) if (!(o = o[pe])) return defaultValue;
		return o;

	} // safeGet
	

} //// AqaAnalysisObject
