import React from 'react';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as BackIcon} from "../../images/aqa-icons/Backcol.svg";
import HtmlTooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
//import AqaSnapshotGetLink from "../sourcedetail/AqaSnapshotGetLink";
import {ReactComponent as XMarkIcon} from "../../images/aqa-icons/XMarkOutline2.svg";
import {ReactComponent as CheckIcon} from "../../images/aqa-icons/CheckOutline2.svg";
import {ReactComponent as HelpIcon} from "../../images/aqa-icons/HelpOutline2.svg";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import {ReactComponent as FolderIcon} from "../../images/aqa-icons/AQA-Rules.svg";


export default class AqaSnapShotHeader extends AqaComponent
{


	constructor(props)
	{
		super(props);
		props.parent.registerHeader(this);
		this.state = {
			dimensions:props.dimensions,
			status:props.parent.props.dataFromSnapshot.status,
			snapshot: props.parent.props.dataFromSnapshot,
			openShareMessage: false
		};
		this.handleSnapshotReject=this.handleSnapshotReject.bind(this);
		this.handleSnapshotApprove=this.handleSnapshotApprove.bind(this);
		this.handleSnapshotReset=this.handleSnapshotReset.bind(this);
		this.handleColumnRules=this.handleColumnRules.bind(this);
	} //

	handleSnapshotApprove(e){
		var status="Approved";
		var snapshot=this.state.snapshot;
		AqaComponent.snapshotBackend.updateSnapshotStatusChangeUsingPOST(
			snapshot.id,
			status,
			(error, data, response) => {
				if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
					"AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error);
				else {
					this.setState({status:status,openShareMessage:false});
					//this.props.parent.handleRefresh();
				}
			}
		)
	}

	handleSnapshotReset(e){
		var status="Pending";
		var snapshot=this.state.snapshot;
		AqaComponent.snapshotBackend.updateSnapshotStatusChangeUsingPOST(
			snapshot.id,
			status,
			(error, data, response) => {
				if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
					"AqaSnapshotMainHeader.handleSnapshotReset, backend call: " + error,this);
				else {
					this.setState({status:status,openShareMessage:false});
					//this.props.parent.handleRefresh();
				}
			}
		)
	}
	handleSnapshotReject(e){
		var status="Rejected";
		var snapshot=this.state.snapshot;
		AqaComponent.snapshotBackend.updateSnapshotStatusChangeUsingPOST(
			snapshot.id,
			status,
			(error, data, response) => {
				if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
					"AqaSnapshotMainHeader.handleSnapshotReject, backend call: " + error,this);
				else {
					this.setState({status:status,openShareMessage:true});
					//this.props.parent.handleRefresh();
				}
			}
		)
	}

	handleColumnRules(e){
		const parent = this.props.parent;
		// Currently only setting for top context;
		const th = parent.dataViewer.selectedColumns[0];
		if(th === null) return;
		parent.vqdEditor.handleRuleEditor();
		parent.vqdEditor.handleShowRulesEditor(th.props.col, th.props.caption, parent.getTypeMetricSelectedType(th.props.which));
	}

	render()
	{
		return (
			<div style={{borderBottom: "2px solid #F9F7F9",margin:"-5px",height:"56px"}}>

				<Grid container spacing={0} direction="row" justify="space-between"
					  alignItems="center"
					  style={{padding: 0, marginBottom: "-5px"}}>
					<Grid item xs={7} sm={7} lg={7} xl={7}>
						<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"4px"}}>
							<HtmlTooltip enterDelay={500} title={<React.Fragment>
								<div>
									<Typography color="inherit" className="aqa-text-action">Click to go back to the Data summary view</Typography>
								</div>
							</React.Fragment>} arrow={"true"} placement="bottom-start">
								<IconButton className="aqa-button" onClick={this.props.handleHome} style={{padding:"12px 12px 4px 15px"}} >

									<div className="aqa-icon" >
										<BackIcon width="32px" />
									</div>
									<Typography className={"aqa-text-bold"} variant="inherit" color="inherit" style={{textAlign:"left",color:"#404040",paddingLeft:"5px",marginTop:"-5px"}}>
										{this.props.sourceName + " : Analysis " }
									</Typography>
								</IconButton>
							</HtmlTooltip>

						</Typography>
					</Grid>
					<Grid item xs={1} sm={1} lg={1} xl={1}>
						{this.state.status==="Pending" && this.props.parent.dataViewer !== null && this.props.parent.dataViewer.selectedColumns[0] !== null ?
							<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"8px"}}>
								<HtmlTooltip enterDelay={500} title={<React.Fragment>
									<div>
										<Typography color="inherit" className="aqa-text-action">Click to edit column checks for the selected column {this.props.parent.dataViewer.selectedColumns[0] !== null ? this.props.parent.dataViewer.selectedColumns[0].props.caption:""} or ctrl click to edit column rules on the selected column</Typography>
									</div>
								</React.Fragment>} arrow={"true"} placement="bottom-start">
									<IconButton className="aqa-button" onClick={this.handleColumnRules.bind(this)} disabled={false} style={{padding:"4px"}}>

										<div className="aqa-icon" >
											<FolderIcon width="32px" />
										</div>
										<Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px",width:"130px"}}>
											EDIT CHECKS
										</Typography>
									</IconButton>
								</HtmlTooltip>

							</Typography>:""}
					</Grid>
					<Grid item xs={1} sm={1} lg={1} xl={1}>
						{this.state.status==="Pending"?
						<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"8px",paddingLeft:"8px"}}>
							<HtmlTooltip enterDelay={500} title={<React.Fragment>
								<div>
									<Typography color="inherit" className="aqa-text-action">Click to approve the status of Data Upload</Typography>
								</div>
							</React.Fragment>} arrow={"true"} placement="bottom-start">
								<IconButton className="aqa-button" onClick={this.handleSnapshotApprove.bind(this)} disabled={false} style={{padding:"4px"}}>

									<div className="aqa-icon" >
										<CheckIcon width="32px" />
									</div>
									<Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
										APPROVE
									</Typography>
								</IconButton>
							</HtmlTooltip>

						</Typography>:
							(this.props.parent.dataViewer !== null && this.props.parent.dataViewer.selectedColumns[0] !== null?
								<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"8px"}}>
									<HtmlTooltip enterDelay={500} title={<React.Fragment>
										<div>
											<Typography color="inherit" className="aqa-text-action">Click to edit column rules for the selected column {this.props.parent.dataViewer.selectedColumns[0] !== null ? this.props.parent.dataViewer.selectedColumns[0].props.caption:""} or ctrl click to edit column rules on the selected column</Typography>
										</div>
									</React.Fragment>} arrow={"true"} placement="bottom-start">
										<IconButton className="aqa-button" onClick={this.handleColumnRules.bind(this)} disabled={false} style={{padding:"4px"}}>

											<div className="aqa-icon" >
												<FolderIcon width="32px" />
											</div>
											<Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px",width:"130px"}}>
												EDIT CHECKS
											</Typography>
										</IconButton>
									</HtmlTooltip>

								</Typography>:""
							)}
					</Grid>
					<Grid item xs={1} sm={1} lg={1} xl={1}>
						{this.state.status === "Pending" ?
							<Typography variant="inherit" color="inherit" style={{
								textAlign: "left",
								fontSize: "0.8rem",
								width: "100%",
								paddingTop: "8px"
							}}>
								<HtmlTooltip enterDelay={500} title={<React.Fragment>
									<div>
										<Typography color="inherit" className="aqa-text-action">Click to reject the
											status of Data Upload</Typography>
									</div>
								</React.Fragment>} arrow={"true"} placement="bottom-start">
									<IconButton className="aqa-button" onClick={this.handleSnapshotReject.bind(this)}
												disabled={false} style={{padding: "4px"}}>

										<div className="aqa-icon">
											<XMarkIcon width="32px"/>
										</div>
										<Typography className="aqa-action-header-text" variant="inherit" style={{
											textAlign: "left",
											color: "#04A6FC",
											paddingLeft: "4px",
											marginTop: "-4px"
										}}>
											REJECT
										</Typography>
									</IconButton>
								</HtmlTooltip>

							</Typography> :
							<Typography variant="inherit" color="inherit" style={{
								textAlign: "left",
								fontSize: "0.8rem",
								width: "100%",
								paddingTop: "5px",
								paddingLeft:"8px"
							}}>
								<HtmlTooltip enterDelay={500} title={<React.Fragment>
									<div>
										<Typography color="inherit" className="aqa-text-action">Click to reset the
											status of Data Upload</Typography>
									</div>
								</React.Fragment>} arrow={"true"} placement="bottom-start">
									<IconButton className="aqa-button" onClick={this.handleSnapshotReset.bind(this)}
												disabled={false} style={{padding: "4px"}}>

										<div className="aqa-icon">
											<HelpIcon width="32px"/>
										</div>
										<Typography className="aqa-action-header-text" variant="inherit" style={{
											textAlign: "left",
											color: "#04A6FC",
											paddingLeft: "4px",
											marginTop: "-4px"
										}}>
											RESET
										</Typography>
									</IconButton>
								</HtmlTooltip>

							</Typography>
						}
					</Grid>
					<Grid item xs={1} sm={1} lg={1} xl={1}>
						{/*<div style={{marginTop:"1px",padding:0}}>
							<AqaSnapshotGetLink parent={this} dataFromGrid={this.props.dataFromGrid} dataFromParent={this.props.parent} dataFromSnapshot={this.props.parent.props.dataFromSnapshot} dataFromSource={this.props.parent.props.dataFromSource} dataForPopup={this.state.openShareMessage}/>
						</div>*/}
					</Grid>
					<Grid item xs={1} sm={1} lg={1} xl={1}>
						<Typography className={"aqa-text-bold"} variant="inherit" color="inherit" style={{textAlign:"right",color:"#404040",paddingLeft:"0px",marginTop:"-5px",marginRight:"30px",paddingTop:"12px"}}>
							{this.state.dimensions}
						</Typography>
					</Grid>
				</Grid>


			</div>
		);

	} // render



} //// 



