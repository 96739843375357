/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/Account', '../model/AqaCanUseResponse', '../model/AqaPersonResponse', '../model/AqaResponse', '../model/Currency', '../model/Invoice'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/Account'), require('../model/AqaCanUseResponse'), require('../model/AqaPersonResponse'), require('../model/AqaResponse'), require('../model/Currency'), require('../model/Invoice'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AQAAccountControllerApi = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.Account, root.ApiDocumentation.AqaCanUseResponse, root.ApiDocumentation.AqaPersonResponse, root.ApiDocumentation.AqaResponse, root.ApiDocumentation.Currency, root.ApiDocumentation.Invoice);
  }
}(this, function(ApiClient, Account, AqaCanUseResponse, AqaPersonResponse, AqaResponse, Currency, Invoice) {
  'use strict';

  /**
   * AQAAccountController service.
   * @module api/AQAAccountControllerApi
   * @version 1.0
   */

  /**
   * Constructs a new AQAAccountControllerApi. 
   * @alias module:api/AQAAccountControllerApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the delete2FAProfileForUserUsingDELETE operation.
     * @callback module:api/AQAAccountControllerApi~delete2FAProfileForUserUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Deletes the specified user&#39;s 2FA data, in effect disabling their 2FA set up
     * @param {String} personId personId
     * @param {module:api/AQAAccountControllerApi~delete2FAProfileForUserUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.delete2FAProfileForUserUsingDELETE = function(personId, callback) {
      var postBody = null;

      // verify the required parameter 'personId' is set
      if (personId === undefined || personId === null) {
        throw new Error("Missing the required parameter 'personId' when calling delete2FAProfileForUserUsingDELETE");
      }


      var pathParams = {
        'personId': personId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/accounts/otp/delete/{personId}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteAccountUsingDELETE operation.
     * @callback module:api/AQAAccountControllerApi~deleteAccountUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Deletes an account
     * @param {module:api/AQAAccountControllerApi~deleteAccountUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.deleteAccountUsingDELETE = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/accounts/', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the doesUserHave2FAUsingGET operation.
     * @callback module:api/AQAAccountControllerApi~doesUserHave2FAUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Check success field, if true: 2FA is set
     * @param {String} personId personId
     * @param {module:api/AQAAccountControllerApi~doesUserHave2FAUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.doesUserHave2FAUsingGET = function(personId, callback) {
      var postBody = null;

      // verify the required parameter 'personId' is set
      if (personId === undefined || personId === null) {
        throw new Error("Missing the required parameter 'personId' when calling doesUserHave2FAUsingGET");
      }


      var pathParams = {
        'personId': personId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/accounts/otp/profile/{personId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getAllCurrenciesUsingGET operation.
     * @callback module:api/AQAAccountControllerApi~getAllCurrenciesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Currency>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves all currencies
     * @param {module:api/AQAAccountControllerApi~getAllCurrenciesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Currency>}
     */
    this.getAllCurrenciesUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json'];
      var returnType = [Currency];

      return this.apiClient.callApi(
        '/api/v1/accounts/currencies', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getAllInvoicesForAccountsUsingGET operation.
     * @callback module:api/AQAAccountControllerApi~getAllInvoicesForAccountsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Invoice>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves all invoices for account
     * @param {String} accountId accountId
     * @param {module:api/AQAAccountControllerApi~getAllInvoicesForAccountsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Invoice>}
     */
    this.getAllInvoicesForAccountsUsingGET = function(accountId, callback) {
      var postBody = null;

      // verify the required parameter 'accountId' is set
      if (accountId === undefined || accountId === null) {
        throw new Error("Missing the required parameter 'accountId' when calling getAllInvoicesForAccountsUsingGET");
      }


      var pathParams = {
        'accountId': accountId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json'];
      var returnType = [Invoice];

      return this.apiClient.callApi(
        '/api/v1/accounts/{accountId}/invoices', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getAllInvoicesForMyAccountUsingGET operation.
     * @callback module:api/AQAAccountControllerApi~getAllInvoicesForMyAccountUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Invoice>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves all invoices for my account
     * @param {module:api/AQAAccountControllerApi~getAllInvoicesForMyAccountUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Invoice>}
     */
    this.getAllInvoicesForMyAccountUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json'];
      var returnType = [Invoice];

      return this.apiClient.callApi(
        '/api/v1/accounts/myInvoices', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getCanUseUsingGET operation.
     * @callback module:api/AQAAccountControllerApi~getCanUseUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaCanUseResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gets an account&#39;s status regarding free plan and whether the account can be used.
     * @param {module:api/AQAAccountControllerApi~getCanUseUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaCanUseResponse}
     */
    this.getCanUseUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json'];
      var returnType = AqaCanUseResponse;

      return this.apiClient.callApi(
        '/api/v1/accounts/use', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getInvoiceUsingGET operation.
     * @callback module:api/AQAAccountControllerApi~getInvoiceUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Invoice} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves one invoice from its id
     * @param {String} invoiceId invoiceId
     * @param {module:api/AQAAccountControllerApi~getInvoiceUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Invoice}
     */
    this.getInvoiceUsingGET = function(invoiceId, callback) {
      var postBody = null;

      // verify the required parameter 'invoiceId' is set
      if (invoiceId === undefined || invoiceId === null) {
        throw new Error("Missing the required parameter 'invoiceId' when calling getInvoiceUsingGET");
      }


      var pathParams = {
        'invoiceId': invoiceId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json'];
      var returnType = Invoice;

      return this.apiClient.callApi(
        '/api/v1/accounts/invoices/{invoiceId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getMyAccountUsingGET operation.
     * @callback module:api/AQAAccountControllerApi~getMyAccountUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Account} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves account of currently logged user
     * @param {module:api/AQAAccountControllerApi~getMyAccountUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Account}
     */
    this.getMyAccountUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json'];
      var returnType = Account;

      return this.apiClient.callApi(
        '/api/v1/accounts/me', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getPersonUsingGET operation.
     * @callback module:api/AQAAccountControllerApi~getPersonUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaPersonResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns a person object from their id
     * @param {String} id id
     * @param {module:api/AQAAccountControllerApi~getPersonUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaPersonResponse}
     */
    this.getPersonUsingGET = function(id, callback) {
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getPersonUsingGET");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json'];
      var returnType = AqaPersonResponse;

      return this.apiClient.callApi(
        '/api/v1/accounts/person/{id}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getZeroDecimalCurrenciesCodeSetUsingGET operation.
     * @callback module:api/AQAAccountControllerApi~getZeroDecimalCurrenciesCodeSetUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<'String'>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * gets Zero Decimal Currencies Codes
     * @param {module:api/AQAAccountControllerApi~getZeroDecimalCurrenciesCodeSetUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<'String'>}
     */
    this.getZeroDecimalCurrenciesCodeSetUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = ['String'];

      return this.apiClient.callApi(
        '/api/v1/accounts/currencies/zdcodes', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the stopPayingUsingDELETE operation.
     * @callback module:api/AQAAccountControllerApi~stopPayingUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Removes subscription attached to account
     * @param {module:api/AQAAccountControllerApi~stopPayingUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.stopPayingUsingDELETE = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/accounts/subscriptions', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the updateAccountUsingPUT operation.
     * @callback module:api/AQAAccountControllerApi~updateAccountUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates an account
     * @param {module:model/Account} accountWithUpdates accountWithUpdates
     * @param {module:api/AQAAccountControllerApi~updateAccountUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.updateAccountUsingPUT = function(accountWithUpdates, callback) {
      var postBody = accountWithUpdates;

      // verify the required parameter 'accountWithUpdates' is set
      if (accountWithUpdates === undefined || accountWithUpdates === null) {
        throw new Error("Missing the required parameter 'accountWithUpdates' when calling updateAccountUsingPUT");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/accounts/', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
