/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/AqaResponse', '../model/AqaSystemRegistrationresponse', '../model/AqaUserStatsResponse', '../model/MemoryInformationObject'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/AqaResponse'), require('../model/AqaSystemRegistrationresponse'), require('../model/AqaUserStatsResponse'), require('../model/MemoryInformationObject'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AQASystemControllerApi = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.AqaResponse, root.ApiDocumentation.AqaSystemRegistrationresponse, root.ApiDocumentation.AqaUserStatsResponse, root.ApiDocumentation.MemoryInformationObject);
  }
}(this, function(ApiClient, AqaResponse, AqaSystemRegistrationresponse, AqaUserStatsResponse, MemoryInformationObject) {
  'use strict';

  /**
   * AQASystemController service.
   * @module api/AQASystemControllerApi
   * @version 1.0
   */

  /**
   * Constructs a new AQASystemControllerApi. 
   * @alias module:api/AQASystemControllerApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the clearExpiredPaymentIntentsUsingGET operation.
     * @callback module:api/AQASystemControllerApi~clearExpiredPaymentIntentsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {'String'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Clears interrupted purchases
     * @param {module:api/AQASystemControllerApi~clearExpiredPaymentIntentsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'String'}
     */
    this.clearExpiredPaymentIntentsUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = 'String';

      return this.apiClient.callApi(
        '/system/stripe/clearexpiredpaymentintents', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the dumpPricesUsingGET operation.
     * @callback module:api/AQASystemControllerApi~dumpPricesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {'String'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This dumps the prices as per our Database
     * @param {module:api/AQASystemControllerApi~dumpPricesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'String'}
     */
    this.dumpPricesUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = 'String';

      return this.apiClient.callApi(
        '/system/dumpprices', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the generateInvoicesUsingGET operation.
     * @callback module:api/AQASystemControllerApi~generateInvoicesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Creates all the invoices for next month, unless month and year are specified as parameters
     * @param {Object} opts Optional parameters
     * @param {Number} opts.year year (default to 0)
     * @param {Number} opts.month month (default to 0)
     * @param {module:api/AQASystemControllerApi~generateInvoicesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.generateInvoicesUsingGET = function(opts, callback) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        'year': opts['year'],
        'month': opts['month'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/system/generateinvoices', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getUserStatsUsingGET operation.
     * @callback module:api/AQASystemControllerApi~getUserStatsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaUserStatsResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Provides user stats, company name filter available
     * @param {String} cf cf
     * @param {module:api/AQASystemControllerApi~getUserStatsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaUserStatsResponse}
     */
    this.getUserStatsUsingGET = function(cf, callback) {
      var postBody = null;

      // verify the required parameter 'cf' is set
      if (cf === undefined || cf === null) {
        throw new Error("Missing the required parameter 'cf' when calling getUserStatsUsingGET");
      }


      var pathParams = {
      };
      var queryParams = {
        'cf': cf,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaUserStatsResponse;

      return this.apiClient.callApi(
        '/system/userstats', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the memoryUsingGET operation.
     * @callback module:api/AQASystemControllerApi~memoryUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MemoryInformationObject} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns system information for debugging purposes
     * @param {module:api/AQASystemControllerApi~memoryUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MemoryInformationObject}
     */
    this.memoryUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = MemoryInformationObject;

      return this.apiClient.callApi(
        '/system/memory', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the modeUsingGET operation.
     * @callback module:api/AQASystemControllerApi~modeUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns aqa response giving the mode in the message field: test or normal
     * @param {module:api/AQASystemControllerApi~modeUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.modeUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/system/mode', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the normalModeUsingPUT operation.
     * @callback module:api/AQASystemControllerApi~normalModeUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns system to normal mode
     * @param {module:api/AQASystemControllerApi~normalModeUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.normalModeUsingPUT = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/system/normalmode', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the overrideCaptchaUsingGET operation.
     * @callback module:api/AQASystemControllerApi~overrideCaptchaUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Overrides captcha settings
     * @param {String} o o
     * @param {module:api/AQASystemControllerApi~overrideCaptchaUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.overrideCaptchaUsingGET = function(o, callback) {
      var postBody = null;

      // verify the required parameter 'o' is set
      if (o === undefined || o === null) {
        throw new Error("Missing the required parameter 'o' when calling overrideCaptchaUsingGET");
      }


      var pathParams = {
      };
      var queryParams = {
        'o': o,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/system/overridecaptcha', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the primeTestAccountNoCreationUsingPUT operation.
     * @callback module:api/AQASystemControllerApi~primeTestAccountNoCreationUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Just prime the account
     * @param {String} accountId accountId
     * @param {module:api/AQASystemControllerApi~primeTestAccountNoCreationUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.primeTestAccountNoCreationUsingPUT = function(accountId, callback) {
      var postBody = null;

      // verify the required parameter 'accountId' is set
      if (accountId === undefined || accountId === null) {
        throw new Error("Missing the required parameter 'accountId' when calling primeTestAccountNoCreationUsingPUT");
      }


      var pathParams = {
        'accountId': accountId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/system/tests/prime/account/{accountId}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the primeTestAccountUsingGET operation.
     * @callback module:api/AQASystemControllerApi~primeTestAccountUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaSystemRegistrationresponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Gives you the command line to test
     * @param {Object} opts Optional parameters
     * @param {String} opts.off off (default to false)
     * @param {module:api/AQASystemControllerApi~primeTestAccountUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaSystemRegistrationresponse}
     */
    this.primeTestAccountUsingGET = function(opts, callback) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        'off': opts['off'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaSystemRegistrationresponse;

      return this.apiClient.callApi(
        '/system/tests/info', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the pumpStripePricesUsingGET operation.
     * @callback module:api/AQASystemControllerApi~pumpStripePricesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This pumps our Stripe Prices into our Database
     * @param {module:api/AQASystemControllerApi~pumpStripePricesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.pumpStripePricesUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/system/stripe/pumpprices', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the sendUserStatsUsingGET operation.
     * @callback module:api/AQASystemControllerApi~sendUserStatsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaUserStatsResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Provides user stats, company name filter available
     * @param {String} cf cf
     * @param {module:api/AQASystemControllerApi~sendUserStatsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaUserStatsResponse}
     */
    this.sendUserStatsUsingGET = function(cf, callback) {
      var postBody = null;

      // verify the required parameter 'cf' is set
      if (cf === undefined || cf === null) {
        throw new Error("Missing the required parameter 'cf' when calling sendUserStatsUsingGET");
      }


      var pathParams = {
      };
      var queryParams = {
        'cf': cf,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaUserStatsResponse;

      return this.apiClient.callApi(
        '/system/userstats/send', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the setEncryptionUsingGET operation.
     * @callback module:api/AQASystemControllerApi~setEncryptionUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {'String'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Sets system wide encryption
     * @param {String} enc enc
     * @param {module:api/AQASystemControllerApi~setEncryptionUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'String'}
     */
    this.setEncryptionUsingGET = function(enc, callback) {
      var postBody = null;

      // verify the required parameter 'enc' is set
      if (enc === undefined || enc === null) {
        throw new Error("Missing the required parameter 'enc' when calling setEncryptionUsingGET");
      }


      var pathParams = {
      };
      var queryParams = {
        'enc': enc,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = 'String';

      return this.apiClient.callApi(
        '/system/encryption', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the setMailActiveUsingGET operation.
     * @callback module:api/AQASystemControllerApi~setMailActiveUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {'String'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Enables or disables mails
     * @param {String} ma ma
     * @param {module:api/AQASystemControllerApi~setMailActiveUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'String'}
     */
    this.setMailActiveUsingGET = function(ma, callback) {
      var postBody = null;

      // verify the required parameter 'ma' is set
      if (ma === undefined || ma === null) {
        throw new Error("Missing the required parameter 'ma' when calling setMailActiveUsingGET");
      }


      var pathParams = {
      };
      var queryParams = {
        'ma': ma,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = 'String';

      return this.apiClient.callApi(
        '/system/mailactive', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the testModeUsingPUT operation.
     * @callback module:api/AQASystemControllerApi~testModeUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Puts the app into test mode (uses a different storage folder ) AND CLEARS THE TEST STORE!
     * @param {module:api/AQASystemControllerApi~testModeUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.testModeUsingPUT = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/system/testmode', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the timeUsingGET operation.
     * @callback module:api/AQASystemControllerApi~timeUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {'String'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This dumps the prices as per our Database
     * @param {module:api/AQASystemControllerApi~timeUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'String'}
     */
    this.timeUsingGET = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = 'String';

      return this.apiClient.callApi(
        '/system/time', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
