import React, {Component} from 'react';
import AqaRulesRadioButton from "./AqaRulesRadioButton";


import
{
//	Checkbox,
//	FormControlLabel,
//	FormGroup,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core"
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import HtmlTooltip from "@material-ui/core/Tooltip";
import {ReactComponent as AddIcon} from "../../../../images/aqa-icons/AQA-Include.svg";
import {ReactComponent as HelpIcon} from "../../../../images/aqa-icons/HelpOutline2.svg";
import {ReactComponent as DeleteIcon} from "../../../../images/aqa-icons/AQA-Delete.svg";



export default class AqaFormatTable extends Component
{

	constructor(props)
	{
		super(props);
		this.state = { useHelp:false,
			valueBeingAdded: "" }
	}

	handleValueBeingAddedChanged = e => { this.setState({valueBeingAdded: e.target.value}); }

	handleValueBeingAdded = () =>
	{
		if (this.state.valueBeingAdded && this.state.valueBeingAdded.length > 0)
		{
			this.props.parent.addNewFormat(this.state.valueBeingAdded);
			this.setState({valueBeingAdded:""});
		}
	} // handleValueBeingAdded

	handleUnlistedColourchange = e =>
	{
 //console.log("Ta valeur: " + e.target.value);

		this.props.parent.setUnlistedColour(e.target.value);

	} // handleUnlistedColourchange

	handleHelp = e =>
	{
		if(this.state.useHelp) this.setState({useHelp:false});
		else this.setState({useHelp:true});
	}
	
	render()
	{
		return (
		
			<div style={{marginTop:"-50px"}}>

				<div>
					<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>
						<Grid item xs={3} sm={3} lg={3} xl={3}>
						</Grid>
						<Grid item xs={1} sm={1} lg={1} xl={1}>
							{this.props.hasHelp?
							<HtmlTooltip enterDelay={500} title={<React.Fragment>
								<div>
									<Typography color="inherit" className="aqa-text-action">Click to show help on formats</Typography>
								</div>
							</React.Fragment>} arrow={"true"} placement="bottom-start">
								<div style={{float:"left", padding:"4px 0 0 8px", cursor:"pointer"}} onClick={this.handleHelp}>
									<HelpIcon width="22px" />
								</div>
							</HtmlTooltip>:""}
						</Grid>
						<Grid item xs={4} sm={4} lg={4} xl={4}>
							<span style={{float:"left",width:"40%",marginTop:"6px"}}>
								<Typography color="inherit" className="aqa-text-action">Unlisted Formats:</Typography>
							</span>
<span style={{float:"left",width:"50%",marginTop:"3px"}}>
								<Select
									label=""
									id="unlistedColour-label"
									name={"unlistedColour"}
									value={this.props.unlistedColour}
									onChange={this.handleUnlistedColourchange}
									displayEmpty
									fullWidth
									className={"aqa-text-action"}
								>
									<MenuItem className={"aqa-text-action"} style={{padding:"6px 10px"}} key={"red"} value={"red"}>{"Red"}</MenuItem>
									<MenuItem className={"aqa-text-action"} style={{padding:"6px 10px"}} key={"amber"} value={"amber"}>{"Amber"}</MenuItem>
									<MenuItem className={"aqa-text-action"} style={{padding:"6px 10px"}} key={"green"} value={"green"}>{"Green"}</MenuItem>
								</Select>
							</span>
						</Grid>

						<Grid item xs={4} sm={4} lg={4} xl={4}>
							{
								this.props.canAdd
								?
									<>
										<span style={{float:"left",width:"40%",marginTop:"6px"}}>
											<Typography color="inherit" className="aqa-text-action">Add Format</Typography>
										</span>

										<span style={{float:"left",width:"50%",marginTop:"3px"}}>

											<TextField
												id="valueBeingAdded"
												name="valueBeingAdded"
												label=""
												className={""}
												value={this.state.valueBeingAdded}
												variant="standard"
												margin="dense"
												multiline={false}
												rows={1}
												style={{ width: "95%",margin:"0px"}}
												onChange={this.handleValueBeingAddedChanged}
												InputProps={{style:{padding:"0px",
														fontFamily:"Montserrat",
														fontSize:"12px",
														lineHeight:"10px",
														textAlign:"left"}}}
												required
											/>
										</span>
										<span style={{float:"left",width:"10%"}}>
											<div className="aqa-text-bold-12">
												<HtmlTooltip enterDelay={500} title={<React.Fragment>
													<div>
														<Typography color="inherit" className="aqa-text-action">Click to add the String format to list</Typography>
													</div>
												</React.Fragment>} arrow={"true"} placement="bottom-start">
													<div style={{float:"left", padding:"4px 0 0 8px", cursor:"pointer"}} onClick={this.handleValueBeingAdded}>
														<AddIcon width="22px" />
													</div>
												</HtmlTooltip>
											</div>
										</span>
									</>
								:
									""
							}

						</Grid>
					</Grid>
				</div>

				<Table width="100%" size="medium" cellSpacing={0} aria-sort="ascending">
					<colgroup>
						<col width="40%"/>
						<col width="15%"/>
						<col width="15%"/>
						<col width="15%"/>
						<col width="15%"/>
					</colgroup>
					<TableHead>
						<TableRow style={{height:"30px",width:"800px"}}>
							<TableCell width="40%" style={{"padding":"0px 0px 0px 4px"}}><div className="aqa-text-bold-12"> Format</div></TableCell>
							<TableCell width="15%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Green</div></TableCell>
							<TableCell width="15%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Amber</div></TableCell>
							<TableCell width="15%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Red</div></TableCell>
							<TableCell width="15%" style={{"padding":"0px"}}><div className="aqa-text-bold-12"></div></TableCell>
						</TableRow>
					</TableHead>
				</Table>
				<div style={{'minHeight': "145px", 'overflow':'auto',width:"960px"}}>
				
					<Table width="100%" size="medium" cellSpacing={0} aria-sort="ascending">
						<colgroup>
							<col width="40%"/>
							<col width="15%"/>
							<col width="15%"/>
							<col width="15%"/>
							<col width="15%"/>
						</colgroup>
						<TableBody style={{'minHeight': "145px", 'overflow':'scroll', width:"960px"}}>
							{
								this.props.allFormats.map
								(
									(item, key) =>
									(
										<TableRow key={"Format_" + key} style={{tableLayout: 'fixed',height:"22px"}}>
											<TableCell width="40%" style={{"padding":"0px 0px 0px 4px"}}>
												<div className="aqa-text-bold-12"> {item.id}</div>
											</TableCell>
											<TableCell width="45%" style={{"padding":"0px"}}>
												<div className="aqa-text-bold-12">
													{/* Once this is enriched to handle values for strings, we will need to drive the 'dataForType' attribute, ok?? */}
													<AqaRulesRadioButton dataForColor={item.color} parent={this.props.parent} dataAcquired={item} dataForType={"formats"} />
												</div>
											</TableCell>
											<TableCell width="15%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">
												{this.props.canAdd?
													<HtmlTooltip
														enterDelay={500}
														title=
															{
																<React.Fragment>
																	<div>
																		<Typography color="inherit" className="aqa-text-action">Click to remove the format from list</Typography>
																	</div>
																</React.Fragment>
															}
														arrow={"true"} placement="bottom-start"
													>
														<div style={{float:"left", padding:"4px 14px 0 4px", cursor:"pointer"}} onClick={() => this.props.parent.removeFormat(item.color, item.index, true)}>
															<DeleteIcon width="22px" />
														</div>
													</HtmlTooltip>
												:""}
											</div></TableCell>
										</TableRow>
									)
								)
							}
						</TableBody>
					</Table>

					<div>
						{/* When this is enriched to handle values for strings, we will need to drive the word after 'unlisted', ok??
						Severity of unlisted formats:*/}
						{/*<select value={this.props.unlistedColour} onChange={this.handleUnlistedColourchange}>
							<option></option>
							<option value="red">Red</option>
							<option value="amber">Amber</option>
							<option value="green">Green</option>
						</select>
					</div>*/}

						{
							this.props.hasHelp && this.state.useHelp
							?
								<div className={"aqa-text-action"}>
									<table>
										<thead>
										<tr>
											<th>Character</th>
											<th>What it does</th>
										</tr>
										</thead>

										<tbody>
										<tr>
											<td>0</td>
											<td>compulsory digit (0 to 9)</td>
										</tr>
										<tr>
											<td>9</td>
											<td>optional digit (0 to 9)</td>
										</tr>
										<tr>
											<td>#</td>
											<td>optional digit, space, plus or minus sign</td>
										</tr>
										<tr>
											<td>L</td>
											<td>compulsory letter</td>
										</tr>
										<tr>
											<td>?</td>
											<td>optional letter</td>
										</tr>
										<tr>
											<td>A</td>
											<td>compulsory letter or a digit</td>
										</tr>
										<tr>
											<td>a</td>
											<td>optional letter or digit</td>
										</tr>
										<tr>
											<td>&amp;</td>
											<td>compulsory character or space</td>
										</tr>
										<tr>
											<td>C</td>
											<td>optional characters or spaces</td>
										</tr>
										<tr>
											<td>Anything else</td>
											<td>compulsory character</td>
										</tr>
										</tbody>
									</table>
								</div>
							:
								null
						}
					</div>
					

				</div>
			</div>
																
		);															
	} // render

} ////
