/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaVectorScore = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The AqaVectorScore model module.
   * @module model/AqaVectorScore
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaVectorScore</code>.
   * @alias module:model/AqaVectorScore
   * @class
   */
  var exports = function() {
    var _this = this;


































































  };

  /**
   * Constructs a <code>AqaVectorScore</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaVectorScore} obj Optional instance to populate.
   * @return {module:model/AqaVectorScore} The populated <code>AqaVectorScore</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('anyAmber')) {
        obj['anyAmber'] = ApiClient.convertToType(data['anyAmber'], 'Number');
      }
      if (data.hasOwnProperty('anyGreen')) {
        obj['anyGreen'] = ApiClient.convertToType(data['anyGreen'], 'Number');
      }
      if (data.hasOwnProperty('anyRed')) {
        obj['anyRed'] = ApiClient.convertToType(data['anyRed'], 'Number');
      }
      if (data.hasOwnProperty('dateRag')) {
        obj['dateRag'] = ApiClient.convertToType(data['dateRag'], 'String');
      }
      if (data.hasOwnProperty('nativeErrorRag')) {
        obj['nativeErrorRag'] = ApiClient.convertToType(data['nativeErrorRag'], 'String');
      }
      if (data.hasOwnProperty('numberOfAmbers')) {
        obj['numberOfAmbers'] = ApiClient.convertToType(data['numberOfAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDateAmbers')) {
        obj['numberOfDateAmbers'] = ApiClient.convertToType(data['numberOfDateAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDateQualityDefinitions')) {
        obj['numberOfDateQualityDefinitions'] = ApiClient.convertToType(data['numberOfDateQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDateReds')) {
        obj['numberOfDateReds'] = ApiClient.convertToType(data['numberOfDateReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteAmbers')) {
        obj['numberOfDiscreteAmbers'] = ApiClient.convertToType(data['numberOfDiscreteAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteDateAmbers')) {
        obj['numberOfDiscreteDateAmbers'] = ApiClient.convertToType(data['numberOfDiscreteDateAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteDateBoundaryAmbers')) {
        obj['numberOfDiscreteDateBoundaryAmbers'] = ApiClient.convertToType(data['numberOfDiscreteDateBoundaryAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteDateBoundaryReds')) {
        obj['numberOfDiscreteDateBoundaryReds'] = ApiClient.convertToType(data['numberOfDiscreteDateBoundaryReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteDateFormatAmbers')) {
        obj['numberOfDiscreteDateFormatAmbers'] = ApiClient.convertToType(data['numberOfDiscreteDateFormatAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteDateFormatReds')) {
        obj['numberOfDiscreteDateFormatReds'] = ApiClient.convertToType(data['numberOfDiscreteDateFormatReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteDateReds')) {
        obj['numberOfDiscreteDateReds'] = ApiClient.convertToType(data['numberOfDiscreteDateReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNativeErrorAmbers')) {
        obj['numberOfDiscreteNativeErrorAmbers'] = ApiClient.convertToType(data['numberOfDiscreteNativeErrorAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNativeErrorReds')) {
        obj['numberOfDiscreteNativeErrorReds'] = ApiClient.convertToType(data['numberOfDiscreteNativeErrorReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNumberAmbers')) {
        obj['numberOfDiscreteNumberAmbers'] = ApiClient.convertToType(data['numberOfDiscreteNumberAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNumberBoundaryAmbers')) {
        obj['numberOfDiscreteNumberBoundaryAmbers'] = ApiClient.convertToType(data['numberOfDiscreteNumberBoundaryAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNumberBoundaryReds')) {
        obj['numberOfDiscreteNumberBoundaryReds'] = ApiClient.convertToType(data['numberOfDiscreteNumberBoundaryReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNumberFormatAmbers')) {
        obj['numberOfDiscreteNumberFormatAmbers'] = ApiClient.convertToType(data['numberOfDiscreteNumberFormatAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNumberFormatReds')) {
        obj['numberOfDiscreteNumberFormatReds'] = ApiClient.convertToType(data['numberOfDiscreteNumberFormatReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteNumberReds')) {
        obj['numberOfDiscreteNumberReds'] = ApiClient.convertToType(data['numberOfDiscreteNumberReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscretePopulationAmbers')) {
        obj['numberOfDiscretePopulationAmbers'] = ApiClient.convertToType(data['numberOfDiscretePopulationAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscretePopulationReds')) {
        obj['numberOfDiscretePopulationReds'] = ApiClient.convertToType(data['numberOfDiscretePopulationReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteReds')) {
        obj['numberOfDiscreteReds'] = ApiClient.convertToType(data['numberOfDiscreteReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringAllowedAmbers')) {
        obj['numberOfDiscreteStringAllowedAmbers'] = ApiClient.convertToType(data['numberOfDiscreteStringAllowedAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringAllowedReds')) {
        obj['numberOfDiscreteStringAllowedReds'] = ApiClient.convertToType(data['numberOfDiscreteStringAllowedReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringAmbers')) {
        obj['numberOfDiscreteStringAmbers'] = ApiClient.convertToType(data['numberOfDiscreteStringAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringBoundaryAmbers')) {
        obj['numberOfDiscreteStringBoundaryAmbers'] = ApiClient.convertToType(data['numberOfDiscreteStringBoundaryAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringBoundaryReds')) {
        obj['numberOfDiscreteStringBoundaryReds'] = ApiClient.convertToType(data['numberOfDiscreteStringBoundaryReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringFormatAmbers')) {
        obj['numberOfDiscreteStringFormatAmbers'] = ApiClient.convertToType(data['numberOfDiscreteStringFormatAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringFormatReds')) {
        obj['numberOfDiscreteStringFormatReds'] = ApiClient.convertToType(data['numberOfDiscreteStringFormatReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteStringReds')) {
        obj['numberOfDiscreteStringReds'] = ApiClient.convertToType(data['numberOfDiscreteStringReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteTypeAmbers')) {
        obj['numberOfDiscreteTypeAmbers'] = ApiClient.convertToType(data['numberOfDiscreteTypeAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteTypeReds')) {
        obj['numberOfDiscreteTypeReds'] = ApiClient.convertToType(data['numberOfDiscreteTypeReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteUniquenessAmbers')) {
        obj['numberOfDiscreteUniquenessAmbers'] = ApiClient.convertToType(data['numberOfDiscreteUniquenessAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDiscreteUniquenessReds')) {
        obj['numberOfDiscreteUniquenessReds'] = ApiClient.convertToType(data['numberOfDiscreteUniquenessReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfNativeErrorAmbers')) {
        obj['numberOfNativeErrorAmbers'] = ApiClient.convertToType(data['numberOfNativeErrorAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfNativeErrorQualityDefinitions')) {
        obj['numberOfNativeErrorQualityDefinitions'] = ApiClient.convertToType(data['numberOfNativeErrorQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfNativeErrorReds')) {
        obj['numberOfNativeErrorReds'] = ApiClient.convertToType(data['numberOfNativeErrorReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfNumberAmbers')) {
        obj['numberOfNumberAmbers'] = ApiClient.convertToType(data['numberOfNumberAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfNumberQualityDefinitions')) {
        obj['numberOfNumberQualityDefinitions'] = ApiClient.convertToType(data['numberOfNumberQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfNumberReds')) {
        obj['numberOfNumberReds'] = ApiClient.convertToType(data['numberOfNumberReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfPopulationAmbers')) {
        obj['numberOfPopulationAmbers'] = ApiClient.convertToType(data['numberOfPopulationAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfPopulationQualityDefinitions')) {
        obj['numberOfPopulationQualityDefinitions'] = ApiClient.convertToType(data['numberOfPopulationQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfPopulationReds')) {
        obj['numberOfPopulationReds'] = ApiClient.convertToType(data['numberOfPopulationReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfReds')) {
        obj['numberOfReds'] = ApiClient.convertToType(data['numberOfReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfStringAmbers')) {
        obj['numberOfStringAmbers'] = ApiClient.convertToType(data['numberOfStringAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfStringQualityDefinitions')) {
        obj['numberOfStringQualityDefinitions'] = ApiClient.convertToType(data['numberOfStringQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfStringReds')) {
        obj['numberOfStringReds'] = ApiClient.convertToType(data['numberOfStringReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfTypeAmbers')) {
        obj['numberOfTypeAmbers'] = ApiClient.convertToType(data['numberOfTypeAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfTypeQualityDefinitions')) {
        obj['numberOfTypeQualityDefinitions'] = ApiClient.convertToType(data['numberOfTypeQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfTypeReds')) {
        obj['numberOfTypeReds'] = ApiClient.convertToType(data['numberOfTypeReds'], 'Number');
      }
      if (data.hasOwnProperty('numberOfUniquenessAmbers')) {
        obj['numberOfUniquenessAmbers'] = ApiClient.convertToType(data['numberOfUniquenessAmbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfUniquenessQualityDefinitions')) {
        obj['numberOfUniquenessQualityDefinitions'] = ApiClient.convertToType(data['numberOfUniquenessQualityDefinitions'], 'Number');
      }
      if (data.hasOwnProperty('numberOfUniquenessReds')) {
        obj['numberOfUniquenessReds'] = ApiClient.convertToType(data['numberOfUniquenessReds'], 'Number');
      }
      if (data.hasOwnProperty('numberRag')) {
        obj['numberRag'] = ApiClient.convertToType(data['numberRag'], 'String');
      }
      if (data.hasOwnProperty('populationRag')) {
        obj['populationRag'] = ApiClient.convertToType(data['populationRag'], 'String');
      }
      if (data.hasOwnProperty('rag')) {
        obj['rag'] = ApiClient.convertToType(data['rag'], 'String');
      }
      if (data.hasOwnProperty('stringRag')) {
        obj['stringRag'] = ApiClient.convertToType(data['stringRag'], 'String');
      }
      if (data.hasOwnProperty('timestamp')) {
        obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Number');
      }
      if (data.hasOwnProperty('typeRag')) {
        obj['typeRag'] = ApiClient.convertToType(data['typeRag'], 'String');
      }
      if (data.hasOwnProperty('uniquenessRag')) {
        obj['uniquenessRag'] = ApiClient.convertToType(data['uniquenessRag'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {Number} anyAmber
   */
  exports.prototype['anyAmber'] = undefined;
  /**
   * @member {Number} anyGreen
   */
  exports.prototype['anyGreen'] = undefined;
  /**
   * @member {Number} anyRed
   */
  exports.prototype['anyRed'] = undefined;
  /**
   * @member {module:model/AqaVectorScore.DateRagEnum} dateRag
   */
  exports.prototype['dateRag'] = undefined;
  /**
   * @member {module:model/AqaVectorScore.NativeErrorRagEnum} nativeErrorRag
   */
  exports.prototype['nativeErrorRag'] = undefined;
  /**
   * @member {Number} numberOfAmbers
   */
  exports.prototype['numberOfAmbers'] = undefined;
  /**
   * @member {Number} numberOfDateAmbers
   */
  exports.prototype['numberOfDateAmbers'] = undefined;
  /**
   * @member {Number} numberOfDateQualityDefinitions
   */
  exports.prototype['numberOfDateQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfDateReds
   */
  exports.prototype['numberOfDateReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteAmbers
   */
  exports.prototype['numberOfDiscreteAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteDateAmbers
   */
  exports.prototype['numberOfDiscreteDateAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteDateBoundaryAmbers
   */
  exports.prototype['numberOfDiscreteDateBoundaryAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteDateBoundaryReds
   */
  exports.prototype['numberOfDiscreteDateBoundaryReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteDateFormatAmbers
   */
  exports.prototype['numberOfDiscreteDateFormatAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteDateFormatReds
   */
  exports.prototype['numberOfDiscreteDateFormatReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteDateReds
   */
  exports.prototype['numberOfDiscreteDateReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNativeErrorAmbers
   */
  exports.prototype['numberOfDiscreteNativeErrorAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNativeErrorReds
   */
  exports.prototype['numberOfDiscreteNativeErrorReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNumberAmbers
   */
  exports.prototype['numberOfDiscreteNumberAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNumberBoundaryAmbers
   */
  exports.prototype['numberOfDiscreteNumberBoundaryAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNumberBoundaryReds
   */
  exports.prototype['numberOfDiscreteNumberBoundaryReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNumberFormatAmbers
   */
  exports.prototype['numberOfDiscreteNumberFormatAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNumberFormatReds
   */
  exports.prototype['numberOfDiscreteNumberFormatReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteNumberReds
   */
  exports.prototype['numberOfDiscreteNumberReds'] = undefined;
  /**
   * @member {Number} numberOfDiscretePopulationAmbers
   */
  exports.prototype['numberOfDiscretePopulationAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscretePopulationReds
   */
  exports.prototype['numberOfDiscretePopulationReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteReds
   */
  exports.prototype['numberOfDiscreteReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringAllowedAmbers
   */
  exports.prototype['numberOfDiscreteStringAllowedAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringAllowedReds
   */
  exports.prototype['numberOfDiscreteStringAllowedReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringAmbers
   */
  exports.prototype['numberOfDiscreteStringAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringBoundaryAmbers
   */
  exports.prototype['numberOfDiscreteStringBoundaryAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringBoundaryReds
   */
  exports.prototype['numberOfDiscreteStringBoundaryReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringFormatAmbers
   */
  exports.prototype['numberOfDiscreteStringFormatAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringFormatReds
   */
  exports.prototype['numberOfDiscreteStringFormatReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteStringReds
   */
  exports.prototype['numberOfDiscreteStringReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteTypeAmbers
   */
  exports.prototype['numberOfDiscreteTypeAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteTypeReds
   */
  exports.prototype['numberOfDiscreteTypeReds'] = undefined;
  /**
   * @member {Number} numberOfDiscreteUniquenessAmbers
   */
  exports.prototype['numberOfDiscreteUniquenessAmbers'] = undefined;
  /**
   * @member {Number} numberOfDiscreteUniquenessReds
   */
  exports.prototype['numberOfDiscreteUniquenessReds'] = undefined;
  /**
   * @member {Number} numberOfNativeErrorAmbers
   */
  exports.prototype['numberOfNativeErrorAmbers'] = undefined;
  /**
   * @member {Number} numberOfNativeErrorQualityDefinitions
   */
  exports.prototype['numberOfNativeErrorQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfNativeErrorReds
   */
  exports.prototype['numberOfNativeErrorReds'] = undefined;
  /**
   * @member {Number} numberOfNumberAmbers
   */
  exports.prototype['numberOfNumberAmbers'] = undefined;
  /**
   * @member {Number} numberOfNumberQualityDefinitions
   */
  exports.prototype['numberOfNumberQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfNumberReds
   */
  exports.prototype['numberOfNumberReds'] = undefined;
  /**
   * @member {Number} numberOfPopulationAmbers
   */
  exports.prototype['numberOfPopulationAmbers'] = undefined;
  /**
   * @member {Number} numberOfPopulationQualityDefinitions
   */
  exports.prototype['numberOfPopulationQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfPopulationReds
   */
  exports.prototype['numberOfPopulationReds'] = undefined;
  /**
   * @member {Number} numberOfReds
   */
  exports.prototype['numberOfReds'] = undefined;
  /**
   * @member {Number} numberOfStringAmbers
   */
  exports.prototype['numberOfStringAmbers'] = undefined;
  /**
   * @member {Number} numberOfStringQualityDefinitions
   */
  exports.prototype['numberOfStringQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfStringReds
   */
  exports.prototype['numberOfStringReds'] = undefined;
  /**
   * @member {Number} numberOfTypeAmbers
   */
  exports.prototype['numberOfTypeAmbers'] = undefined;
  /**
   * @member {Number} numberOfTypeQualityDefinitions
   */
  exports.prototype['numberOfTypeQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfTypeReds
   */
  exports.prototype['numberOfTypeReds'] = undefined;
  /**
   * @member {Number} numberOfUniquenessAmbers
   */
  exports.prototype['numberOfUniquenessAmbers'] = undefined;
  /**
   * @member {Number} numberOfUniquenessQualityDefinitions
   */
  exports.prototype['numberOfUniquenessQualityDefinitions'] = undefined;
  /**
   * @member {Number} numberOfUniquenessReds
   */
  exports.prototype['numberOfUniquenessReds'] = undefined;
  /**
   * @member {module:model/AqaVectorScore.NumberRagEnum} numberRag
   */
  exports.prototype['numberRag'] = undefined;
  /**
   * @member {module:model/AqaVectorScore.PopulationRagEnum} populationRag
   */
  exports.prototype['populationRag'] = undefined;
  /**
   * @member {module:model/AqaVectorScore.RagEnum} rag
   */
  exports.prototype['rag'] = undefined;
  /**
   * @member {module:model/AqaVectorScore.StringRagEnum} stringRag
   */
  exports.prototype['stringRag'] = undefined;
  /**
   * @member {Number} timestamp
   */
  exports.prototype['timestamp'] = undefined;
  /**
   * @member {module:model/AqaVectorScore.TypeRagEnum} typeRag
   */
  exports.prototype['typeRag'] = undefined;
  /**
   * @member {module:model/AqaVectorScore.UniquenessRagEnum} uniquenessRag
   */
  exports.prototype['uniquenessRag'] = undefined;


  /**
   * Allowed values for the <code>dateRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.DateRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>nativeErrorRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.NativeErrorRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>numberRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.NumberRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>populationRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.PopulationRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>rag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.RagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>stringRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.StringRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>typeRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.TypeRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>uniquenessRag</code> property.
   * @enum {String}
   * @readonly
   */
  exports.UniquenessRagEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };


  return exports;
}));


