import React from 'react';


import {ReactComponent as UniquenessIcon}   from "../../images/aqa-icons/Uniquenesscol.svg"
import {ReactComponent as PopulatedIcon}    from "../../images/aqa-icons/Populatedcol.svg"
import {ReactComponent as StringIcon}       from "../../images/aqa-icons/Stringcol.svg"
import {ReactComponent as NumberIcon}       from "../../images/aqa-icons/Numbercol.svg"
// import {ReactComponent as FlagIcon}         from "../../images/aqa-icons/Flagcol.svg"
import {ReactComponent as DateIcon}         from "../../images/aqa-icons/Datecol.svg"
import {ReactComponent as NativeErrorsIcon} from "../../images/aqa-icons/Nativecol.svg"
import {ReactComponent as DimensionIcon}    from "../../images/aqa-icons/Grid-Viewcol.svg" // Not really part of the club but we're easy


const SMALL_SIZE_ICON_PROPERTIES = {width: "16px"};
const SMALL_24_SIZE_ICON_PROPERTIES = {width: "24px"};
const MEDIUM_SIZE_ICON_PROPERTIES = {width: "32px"};

const SMALL_ASPECT_ICONS =
{
	Uniqueness:    <UniquenessIcon   {...SMALL_SIZE_ICON_PROPERTIES} />,
	Populated:     <PopulatedIcon    {...SMALL_SIZE_ICON_PROPERTIES} />,
	Number:        <NumberIcon       {...SMALL_SIZE_ICON_PROPERTIES} />,
	Date:          <DateIcon         {...SMALL_SIZE_ICON_PROPERTIES} />,
	String:        <StringIcon       {...SMALL_SIZE_ICON_PROPERTIES} />,
	NativeErrors:  <NativeErrorsIcon {...SMALL_SIZE_ICON_PROPERTIES} />
};
Object.freeze(SMALL_ASPECT_ICONS);

const SMALL_24_ASPECT_ICONS =
	{
		Uniqueness:    <UniquenessIcon   {...SMALL_24_SIZE_ICON_PROPERTIES} />,
		Populated:     <PopulatedIcon    {...SMALL_24_SIZE_ICON_PROPERTIES} />,
		Number:        <NumberIcon       {...SMALL_24_SIZE_ICON_PROPERTIES} />,
		Date:          <DateIcon         {...SMALL_24_SIZE_ICON_PROPERTIES} />,
		String:        <StringIcon       {...SMALL_24_SIZE_ICON_PROPERTIES} />,
		NativeErrors:  <NativeErrorsIcon {...SMALL_24_SIZE_ICON_PROPERTIES} />
	};
Object.freeze(SMALL_24_ASPECT_ICONS);


const MEDIUM_ASPECT_ICONS =
{
	Uniqueness:    <UniquenessIcon   {...MEDIUM_SIZE_ICON_PROPERTIES} />,
	Populated:     <PopulatedIcon    {...MEDIUM_SIZE_ICON_PROPERTIES} />,
	Number:        <NumberIcon       {...MEDIUM_SIZE_ICON_PROPERTIES} />,
	Date:          <DateIcon         {...MEDIUM_SIZE_ICON_PROPERTIES} />,
	String:        <StringIcon       {...MEDIUM_SIZE_ICON_PROPERTIES} />,
	NativeErrors:  <NativeErrorsIcon {...MEDIUM_SIZE_ICON_PROPERTIES} />,
	Dimension:     <DimensionIcon    {...MEDIUM_SIZE_ICON_PROPERTIES} />
};
Object.freeze(MEDIUM_ASPECT_ICONS);

export {SMALL_ASPECT_ICONS,SMALL_24_ASPECT_ICONS, MEDIUM_ASPECT_ICONS};


