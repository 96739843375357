

/** All Aqa computed stats on snapshots are kept in findings.
  * Findings have a finding family. Their family name is the name of the AqaWalker who gather findings it's specialised in.
  * For example: AqaTypeStatsWalker
  * In a given Findings, there could be several families.
  * Each family has its own metrics
  * Example: (still on AqaTypeStatsWalker)
  * - minStringLength
  * - maxStringLength
  * - variantsInventorySize
  * - lengthsInventorySize
  * -etc.
  *
  * So if you want a metric of any sort, you need to find out which family it is in and the name of the metric.
  * If you have a findings object, you can use the getMetric method on it.
  *
  * Tables do not have walkers so all their findings are stored under one and only one family, named '_'.
  * There is a convenience menthod, on AqaTable, also called getMetric that does not take a family name  (because it's implied)
  * 
  * Metrics that with a value of empty or 0 will not be present - you may specify a default value to return for convenience.
  *
  */


export default class AqaFindings
{
	constructor(findings)
	{
		this.data = findings;	
	} //

	// ♫ ♪ get metric in the USA...
	getMetric(familyName, metricName, defaultValue)
	{
		if (defaultValue === undefined) defaultValue = null;
		if (!this.data) return defaultValue;

		const findingFamily = this.data[familyName];
		let n;
		if (!findingFamily || (n = findingFamily.length) === 0)  return defaultValue;
		for(let i = 0; i < n; i++) if (findingFamily[i].ename === metricName) return findingFamily[i].metric;

		return defaultValue;
	} // getMetric

} //// AqaFindings

