/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaCellColours = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The AqaCellColours model module.
   * @module model/AqaCellColours
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaCellColours</code>.
   * @alias module:model/AqaCellColours
   * @class
   */
  var exports = function() {
    var _this = this;









  };

  /**
   * Constructs a <code>AqaCellColours</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaCellColours} obj Optional instance to populate.
   * @return {module:model/AqaCellColours} The populated <code>AqaCellColours</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('date')) {
        obj['date'] = ApiClient.convertToType(data['date'], 'String');
      }
      if (data.hasOwnProperty('native')) {
        obj['native'] = ApiClient.convertToType(data['native'], 'String');
      }
      if (data.hasOwnProperty('number')) {
        obj['number'] = ApiClient.convertToType(data['number'], 'String');
      }
      if (data.hasOwnProperty('overall')) {
        obj['overall'] = ApiClient.convertToType(data['overall'], 'String');
      }
      if (data.hasOwnProperty('population')) {
        obj['population'] = ApiClient.convertToType(data['population'], 'String');
      }
      if (data.hasOwnProperty('string')) {
        obj['string'] = ApiClient.convertToType(data['string'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('uniqueness')) {
        obj['uniqueness'] = ApiClient.convertToType(data['uniqueness'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {module:model/AqaCellColours.DateEnum} date
   */
  exports.prototype['date'] = undefined;
  /**
   * @member {module:model/AqaCellColours.NativeEnum} native
   */
  exports.prototype['native'] = undefined;
  /**
   * @member {module:model/AqaCellColours.NumberEnum} number
   */
  exports.prototype['number'] = undefined;
  /**
   * @member {module:model/AqaCellColours.OverallEnum} overall
   */
  exports.prototype['overall'] = undefined;
  /**
   * @member {module:model/AqaCellColours.PopulationEnum} population
   */
  exports.prototype['population'] = undefined;
  /**
   * @member {module:model/AqaCellColours.StringEnum} string
   */
  exports.prototype['string'] = undefined;
  /**
   * @member {module:model/AqaCellColours.TypeEnum} type
   */
  exports.prototype['type'] = undefined;
  /**
   * @member {module:model/AqaCellColours.UniquenessEnum} uniqueness
   */
  exports.prototype['uniqueness'] = undefined;


  /**
   * Allowed values for the <code>date</code> property.
   * @enum {String}
   * @readonly
   */
  exports.DateEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>native</code> property.
   * @enum {String}
   * @readonly
   */
  exports.NativeEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>number</code> property.
   * @enum {String}
   * @readonly
   */
  exports.NumberEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>overall</code> property.
   * @enum {String}
   * @readonly
   */
  exports.OverallEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>population</code> property.
   * @enum {String}
   * @readonly
   */
  exports.PopulationEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>string</code> property.
   * @enum {String}
   * @readonly
   */
  exports.StringEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>type</code> property.
   * @enum {String}
   * @readonly
   */
  exports.TypeEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };

  /**
   * Allowed values for the <code>uniqueness</code> property.
   * @enum {String}
   * @readonly
   */
  exports.UniquenessEnum = {
    /**
     * value: "red"
     * @const
     */
    "red": "red",
    /**
     * value: "amber"
     * @const
     */
    "amber": "amber",
    /**
     * value: "green"
     * @const
     */
    "green": "green"  };


  return exports;
}));


