/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react'

import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import {Checkbox, FormControlLabel} from "@material-ui/core" // , FormGroup

import {withStyles}   from "@material-ui/core/styles"
import FormControl    from "@material-ui/core/FormControl"
import Typography     from "@material-ui/core/Typography"


import "rc-slider/assets/index.css"
import "rc-tooltip/assets/bootstrap.css"

import AqaComponent  from "../../shared/aqacomponent/AqaComponent"

import AqaBoundaries                 from "../../../model/AqaBoundaries"
import AqaOutline                    from "../../shared/outline/AqaOutline"

import AqaRulesParent                from "./AqaRulesParent"
import AqaRulesTextThreshold         from "./subelements/AqaRulesTextThreshold"
import AqaTypeBreachSeveritySelector from "./subelements/AqaTypeBreachSeveritySelector"
import AqaQualityButton              from "./subelements/AqaQualityButton"
import AqaRulesGraph                 from "./subelements/AqaRulesGraph"
// import AqaRulesFormatsManager        from "./subelements/AqaRulesFormatsManager"
import AqaFormatTable                from "./subelements/AqaFormatTable"


import Slider from "rc-slider"

import AqaPopulationUniquenessNativeErrorsCombo from "./AqaPopulationUniquenessNativeErrorsCombo"

/*
import AqaRulesPopulated from "./AqaRulesPopulated";
import AqaRulesUniqueness from "./AqaRulesUniqueness";
import AqaRulesNativeErrors from "./AqaRulesNativeErrors";
*/



const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);




class AqaRulesNumber extends AqaRulesParent
{
	constructor(props)
	{
		super(props, "Number");

		this.state =
		{
			error: null,
			notfound: false,
			isLoaded: true, // this is most likely redundant
			currentSnapshot:"allsources",
			width:960,
			invertZones: false
//			, 			which: 0 // WTH is this for??
		};

	} //


	dataFormatter = number => AqaComponent.abreviateNumber(number);
	dataFormatterX = number => (number);


	render()
	{
		const {error, isLoaded, notfound, hasError, invertZones, width} = this.state;

		const useNumberFormats = this.rules.useNumberFormats;
		const useNumberBoundaries = this.rules.useNumberBoundaries;
		const minMaxArray	= [this.wideMinRange, this.wideMaxRange];

		this.allFormats = this.packageFormatsOrValuesForRender(1);
		
		if (!this.boundaryValues) return null;
		
		

		if (notfound && error)
		{
			return (
				<div className={"snapshot-rules-servant-pane"}>
					<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

						<Grid item xs={10} sm={7} lg={11} xl={11}>
							<Typography
								variant="title"
								color="inherit"
								align="left"
								style={
									{
										fontSize: '1.5rem',
										padding: '0.1em 0.5em',
										fontFamily: 'Open Sans, sans-serif'
									}
								}
							>
								Nothing to display
							</Typography>
						</Grid>
						<Grid item xs={2} sm={1} lg={1} xl={1}></Grid>
					</Grid>
				</div>
			);
		}
		else if (error && !notfound)
		{
			return (
				<div>
					<Typography
						variant="title"
						color="inherit"
						align="left"
						style={
							{
								fontSize: '1.5rem',
								padding: '3.1em 0.5em',
								fontFamily: 'Open Sans, sans-serif'
							}
						}
					>
						Error: {error.message}
					</Typography>
				</div>
			);
		}
		else if (!isLoaded)
		{
			return <div>Loading...</div>;
		}
		else
		{
			return (
				<div className={"snapshot-rule-servant-pane"}>
					<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

						<Grid item xs={12} sm={12} lg={12} xl={12}>
							<div style={{padding:"0px"}}>

								<AqaQualityButton which={3} />

								<div style={{marginTop:"-48px"}}>

									{
										[
										//  0                          1                                                           2
											[useNumberFormats,          e => {this.setUsed("NumberFormats", e.target.checked)},    "Set Number formats"],
											[useNumberBoundaries,       e => {this.setUsed("NumberBoundaries", e.target.checked)}, "Set Number value thresholds"],
											[this.typeEnforcedFor2(),   e => {this.handleEnforceTypeChange2(e.target.checked)},    "Values must be Numbers"]
										].map
										(
											(srt, i) => (
												<div key={"Number_"+i} style={{textAlign:"left", width:"960px", padding:0, margin:0}}>
													<FormControlLabel
														key={`srt${i}`}
														control={
															<Checkbox
																checked={srt[0]}
																onChange={srt[1]}
																color="primary"
															/>
														}
														label={
															(
																<Typography className={"aqa-text-action"} variant="title" align="left">
																	{srt[2]}
																</Typography>
															)
														}
														labelPlacement="end"
													/>
												
													{
														i !== 0 ? null :
													 	<div style={{textAlign:"left"}}>
													 		{ useNumberFormats ? <AqaFormatTable parent={this} allFormats={this.allFormats} canAdd={false} unlistedColour={this.getUnlistedColour()} hasHelp={false} /> : null }
													 	</div>
													}
												
													{
														i !== 1 ? null :
													 	<div style={{textAlign:"left"}}>
													 	
														 	{
																useNumberBoundaries
																?
																	(
																		<div style={{paddingBottom:"10px",width:width}}>
																			<FormControl component="div" size="small" fullWidth>

																				<Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start" style={{padding: 0, marginTop: "-5px"}}>

																					<Grid item xs={12} sm={12} lg={12} xl={12}>
																						<div style={{textAlign:"right",marginTop:"-40px",marginLeft:"40px"}}>
																							{/* <FormGroup row> */}
																								<FormControlLabel
																									control={
																										<Checkbox
																											checked={invertZones}
																											onChange={e => this.handleInvertZone(e.target.checked)}
//																														name="invertZones"
																											color="primary"
																										/>
																									}
																									label={(
																										<Typography className={"aqa-text-action"} variant="title" align="left" style={{ }}>
																											Invert Zones
																										</Typography>
																									)}
																									labelPlacement="end"
																								/>
																							{/* </FormGroup> */}
																						</div>
																					</Grid>

																					<Grid item xs={12} sm={12} lg={12} xl={12}>


																						<div id={"aqa_slider"} style={{width: width,marginTop:"65px",marginLeft:"0",position:"relative",zIndex:"1110"}}>

																							{/* All boundary values are passed here, they are mapped back to their boundary values based on the boundary flags */}

																							{/* https://www.npmjs.com/package/rc-slider?activeTab=readme */}
																							<Range
																								count={this.boundaryValues.length}
																								min={this.wideMinRange}
																								max={this.wideMaxRange}
																								value={this.boundaryValues}
																								marks={this.marks}
																								included={false}
																								pushable={1}
																								step={1}
																								//allowCross={true}
																								tipProps={{visible:true}}
																								tipFormatter={this.props.dataForName==="Year"?this.dataFormatterX:this.dataFormatter}
																								onChange={this.handleRangeChange2}
																								reverse={false}
																							/>
																						</div>
																						<div style={{position: "relative", textAlign: "left", marginTop:"-80px",height:"70px"}} >
																							<div id="aqa_tooltip" display="none" style={{position: "absolute", display: "none",backgroundColor:"#6c6c6c",color:"white",fontSize:"12px",fontFamily:"Montserrat",padding:"2px",maxWidth:"80px",zIndex:1201,textAlign:"center"}}></div>
																						<AqaRulesGraph
																								width={width}
																								height={200}
																								zones={AqaBoundaries.makeColourZones(this.rules, this.typeName, this.scaleFromValueToScreenValue, minMaxArray)}
																								marks={this.distributionMarks}
																							/>

																						</div>
																					</Grid>

																					<Grid item xs={12} sm={12} lg={12} xl={12} style={{zIndex:1200}}>
																						<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: "10px"}}>

																							<Grid item xs={5} sm={5} lg={5} xl={5}>
																								<Typography className="" variant="inherit" style={{textAlign:"center",paddingLeft:"0px",paddingRight:"0px",marginTop:"25px",marginLeft:"-110px"}}>

																									<AqaOutline label={"Lower Threshold"}>
																										<div>

																											<AqaRulesTextThreshold
																												idName="numberBoundary1.number1"
																												type="number"
																												caption={invertZones ? "Amber" : "Red"}
																												checked={this.isBoundaryValueActivated(1, 1)}
																												changeHandler={e => this.setBoundaryValueActivated(1, 1, e.target.checked)}
																												value=
																												{
																													this.rules.numberBoundary1
																													?
																														this.rules.numberBoundary1.number1.toFixed(2)
																													:
																														0
																												}
																												error={hasError}
																												textChangeHandler={this.handleTextChange2}
																												parent={this}
																											/>

																											<AqaRulesTextThreshold
																												idName="numberBoundary1.number2"
																												type="number"
																												caption={invertZones ? "Red" : "Amber"}
																												checked={this.isBoundaryValueActivated(1, 2)}
																												changeHandler={e => this.setBoundaryValueActivated(1, 2, e.target.checked)}
																												value=
																												{
																													this.rules.numberBoundary1
																													?
																														this.rules.numberBoundary1.number2.toFixed(2)
																													:
																														0
																												}
																												error={hasError}
																												textChangeHandler={this.handleTextChange2}
																												parent={this}
																											/>

																										</div>
																									</AqaOutline>
																								</Typography>
																							</Grid>

																							<Grid item xs={3} sm={3} lg={3} xl={3}></Grid>
																							
																							<Grid item xs={4} sm={4} lg={4} xl={4}>
																								<Typography variant="inherit" style={{textAlign:"right",paddingLeft:"0px",paddingRight:"0px",marginTop:"25px",marginLeft:"0px",marginRight:"-5px"}}>


																									<AqaOutline label={"Upper Threshold"}>
																										<div>
																											<AqaRulesTextThreshold
																												idName="numberBoundary2.number1"
																												type="number"
																												caption={invertZones ? "Red" : "Amber"}
																												checked={this.isBoundaryValueActivated(2, 1)}
																												changeHandler={e => this.setBoundaryValueActivated(2, 1, e.target.checked)}
																												value=
																												{
																													this.rules.numberBoundary2
																													?
																														this.rules.numberBoundary2.number1.toFixed(2)
																													:
																														0
																												}
																												error={hasError}
																												textChangeHandler={this.handleTextChange2}
																												parent={this}
																											/>

																											<AqaRulesTextThreshold
																												idName="numberBoundary2.number2"
																												type="number"
																												caption={invertZones ? "Amber" : "Red"}
																												checked={this.isBoundaryValueActivated(2, 2)}
																												changeHandler={e => this.setBoundaryValueActivated(2, 2, e.target.checked)}
																												value=
																												{
																													this.rules.numberBoundary2
																													?
																														this.rules.numberBoundary2.number2.toFixed(2)
																													:
																														0
																												}
																												error={hasError}
																												textChangeHandler={this.handleTextChange2}
																												parent={this}
																											/>

																										</div>
																									</AqaOutline>
																								</Typography>
																							</Grid>

																						</Grid>
																					</Grid>

																				</Grid>
																			</FormControl>
																		</div>
																	)
																:
																	null
															} {/* Use Number Boundaries */}

													 	</div>
													}

													{
														i !== 2 ? null :
													 	<div style={{textAlign:"left"}}>
															{
																this.typeEnforcedFor2()
																?
																	<AqaTypeBreachSeveritySelector parent={this} typeSeverity={this.rules.typeSeverity} changeHandler={this.handleTypeSeverityChange2} />
																:
																	null
															}
													 	</div>
													}

												</div>															
											)
										)
								
								
									}
								
								</div>

							</div>
						</Grid>


						<AqaPopulationUniquenessNativeErrorsCombo
							dataForCol={this.props.dataForCol}
							dataForWhich={this.state.which}
							dataForName={this.state.columnName}
							parent={this.props.parent}
							dataFromSource={this.props.dataFromSource}
							dataForTable={this.props.dataForTable}
							//			dataFromSnapshot:this.props.dataFromSnapshot
							sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
							rulesObject={this.props.rulesObject}
							rules={this.props.rules}
						/>

						{/*
						<Grid item xs={12} sm={12} lg={12} xl={12}>
							<AqaRulesPopulated
								dataForCol={this.props.dataForCol}
								dataForWhich={this.state.which}
								dataForName={this.state.columnName}
								parent={this.props.parent}
								dataFromSource={this.props.dataFromSource}
								dataForTable={this.props.dataForTable}
								//			dataFromSnapshot:this.props.dataFromSnapshot
								sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
								rulesObject={this.props.rulesObject}
							/>
						</Grid>
						<Grid item xs={12} sm={12} lg={12} xl={12}>
							<AqaRulesUniqueness
								dataForCol={this.props.dataForCol}
								dataForWhich={this.state.which}
								dataForName={this.state.columnName}
								parent={this.props.parent}
								dataFromSource={this.props.dataFromSource}
								dataForTable={this.props.dataForTable}
								//			dataFromSnapshot:this.props.dataFromSnapshot
								sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
								rulesObject={this.props.rulesObject}
							/>
						</Grid>
						<Grid item xs={12} sm={12} lg={12} xl={12}>
							<AqaRulesNativeErrors
								dataForCol={this.props.dataForCol}
								dataForWhich={this.state.which}
								dataForName={this.state.columnName}
								parent={this.props.parent}
								dataFromSource={this.props.dataFromSource}
								dataForTable={this.props.dataForTable}
								//			dataFromSnapshot:this.props.dataFromSnapshot
								sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
								rulesObject={this.props.rulesObject}
							/>
						</Grid>
						*/}
						
					</Grid>
				</div>
			);
		}
	} // render


} ////

AqaRulesNumber.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(AqaRulesParent.styles)(AqaRulesNumber);



