import React from 'react'


import AqaComponent from "./shared/aqacomponent/AqaComponent"


// https://medium.com/@ihorsokolyk/two-factor-authentication-with-java-and-google-authenticator-9d7ea15ffee6
export default class TwoFactor extends AqaComponent
{


	constructor(props)
	{
		super(props);
		this.state =
		{
			key:"",
			af2:"",
			otp:"",
			qrcode:null
		};
		this.to = null;

	} //


	getKey = () =>
	{
	
		AqaComponent.backend.otpUsingPOST
		(
			(error, data, response) =>
			{
				if (error) alert("Error getting 2FA key: " + error);
				else
				{
					if (!data.success) alert("There was an error:\n" + data["message"]);
					this.setState
					(
						{
							key:data["message"],
							qrcode:data.qrUrl
						}
					);
				}
			}
		);

	} // getKey


	af2Handler = (e) =>
	{
		const sixer = e.target.value;
		this.setState({[e.target.name]:sixer});

		switch(sixer.length)
		{
		case 6:
		
			AqaComponent.backend.checkOtpUsingGET
			(
				sixer,
				(error, data, response) =>
				{
					if (error) alert("Error cheking OTP:" + error);
					else
					{
						let color;
						if (!data.success) color = "f00";
						else color = "0f0";
						this.setState({af2bg:color});
					}
				}
			);
			break;

		case 0:
			this.setState({af2bg:"fff"});
			break;

		default:
			this.setState({af2bg:"f00"});
			break;
		}
	
	
	} // af2Handler
	

	/*
	loadCurrentOtp()
	{

		onsole.log("Fetching otp");
		AqaComponent.backend.getOtpUsingGET
		(
			new Date().getTime(),
			(error, data, response) =>
			{
				if (error) alert("Error getting OTP key");
				else
				{
					this.setState({otp:(data.success ? "OK: " : "error: ") + data["message"]});
					this.to = setTimeout
					(
						() => this.loadCurrentOtp(),
						20000 // 20 s
					);
				}
			}
		);

	} // loadCurrentOtp
	*/
	
	
	loadCurrent2FASetup()
	{
		AqaComponent.backend.get2FAProfileUsingGET
		(
			new Date().getTime(),
			(error, data, response) =>
			{
				if (error) alert("Error getting OTP key");
				else
				{
					this.setState
					(
						{
							key:data["message"],
							qrcode:data.qrUrl
						}
					);
				}
			}
		);
	
	
	} // loadCurrent2FASetup
	
	
	delete2FA = () =>
	{
		if (!window.confirm("Are you sure??")) return;


		AqaComponent.backend.delete2FAProfileUsingDELETE
		(
			(error, data, response) =>
			{
				if (error) alert("Error removing 2FA");
				else
				{
					this.loadCurrent2FASetup();
				}
			}
		);




	}; // delete2FA



	componentDidMount()
	{
		this.loadCurrent2FASetup();


//		this.loadCurrentOtp(); // To check it changes on the server with the app

	} // componentDidMount


	componentWillUnmount()
	{
		if (this.to !== null) clearTimeout(this.to);
	} // componentWillUnmount


	render()
	{
	
	
		return (
			<div>
			
				<b>TEST</b><br />
				<span style={{fontSize:"10px", fontStyle:"italic", color:"#070"}}>Note: when 2FA is not set up, then tests will return green always</span><br />
				<input type="text" name="af2" value={this.state.af2} onChange={this.af2Handler} style={{backgroundColor:`#${this.state.af2bg}`}} />

				<p /><b>Set up 2FA</b>
				<button onClick={this.getKey}>(re-)generate code</button>
				
				
				
				<p /><b>Current Key:</b><br />
				<div>{this.state.key}</div>


{/*
				<div style={{border:"1px dashed red", width:"150px"}}>{this.state.otp}</div>
				<div>{this.state.qrcode}</div>
 */}
 				<p />
 				{this.state.qrcode === null ? null : <img src={"data:image/png;base64, " + this.state.qrcode} alt="QR Code" /> }
				
				<p /><b style={{color:"red", cursor:"pointer"}} onClick={this.delete2FA}>[X] Remove 2FA</b>

			</div>
		);

	
	} // render





} ////
