/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as NewSourceIcon} from "../../images/aqa-icons/AQA-Edit.svg";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//import TextField from '@material-ui/core/TextField';
import AqaComponent from "../shared/aqacomponent/AqaComponent";
//import {Checkbox, FormControlLabel} from "@material-ui/core";
import HtmlTooltip from "@material-ui/core/Tooltip";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaPMDefault extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            openProgress:false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            periodicities: [],
            periodicity: "daily",
            userid:"",
            email: "",
            firstname: "",
            lastname: "",
            isAdmin: false,
            errorMessage: "",
            file: null,
            currentUser:null,
            users:[],
            account:null,
            is2FAEnabled:false,
            disableAction:true

        };


    }


    handleCreateUserClosePopup(e) {
        e.preventDefault();
        if(this.props.dataFromParent.state.selectedPMs.length ===1){
            var pm = this.props.dataFromParent.state.selectedPMs[0];
            //var isAdmin = user.permissions==="A"?true:false;
            //this.setState({email:user.email,firstname:user.firstname,lastname:user.lastname,isAdmin:isAdmin,userid:user.id,currentUser:user});
            AqaComponent.backend.selectDefaultPaymentMethodUsingPUT
            (
                pm.id,
                (error, paymentMethodList, response) =>
                {
                    //let removePleaseWaitMessage = false;
                    if (error)
                    {
                        this.reportError("Sorry an error occurred, please try again later", error);
                        //this.setState({showWaitForPaymentMethodChange:false});
                    }
                    else
                    {
                        // It succeeded, we display the new payment methods.
                        this.setState({paymentMethodList, showWaitForPaymentMethodChange: false,openMessage:false});
                        this.props.dataFromParent.handlePMSelectionReset();
                    }
                }
            );
        }

    }

    handleClosePopup(e) {
        e.preventDefault();
        this.props.dataFromParent.handlePMSelectionReset();
        this.setState({ errorMessage:"",openMessage: false,SourceName:"",SourceDescription:"",periodicity:"daily" });
    }
    handleNewSourceClick(e) {
        e.preventDefault();
        if(this.props.dataFromParent.state.selectedPMs.length ===1){
            //var pm = this.props.dataFromParent.state.selectedPMs[0];
            //var isAdmin = user.permissions==="A"?true:false;
            //this.setState({email:user.email,firstname:user.firstname,lastname:user.lastname,isAdmin:isAdmin,userid:user.id,currentUser:user});

        }
        this.setState({ openMessage: true });
    }








    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        var selectedPMs=nextProps.dataFromRoot.state.selectedPMs;
        var disabled=false;
        if(selectedPMs.length===1){
            for(var l=0;l<selectedPMs.length;l++) {
                var selectedPM = selectedPMs[l];
                if(selectedPM.default === true) {
                    disabled=true;
                }
            }
        }
        else{
            disabled=true;
        }
        this.setState({disableAction:disabled});
    }

    componentDidMount(){
        var selectedPMs=this.props.dataFromRoot.state.selectedPMs;
        var disabled=false;
        if(selectedPMs.length===1){
            for(var l=0;l<selectedPMs.length;l++) {
                var selectedPM = selectedPMs[l];
                if(selectedPM.default === true) {
                    disabled=true;
                }
            }
        }
        else{
            disabled=true;
        }
        this.setState({disableAction:disabled});

    }
    render() {
        //const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,errorMessage/*,currentUser,disableAction*/ } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"5px"}}>
                                {(this.state.disableAction === false)?(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Click to set default</Typography>
                                        </div>
                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <IconButton className="aqa-button" onClick={this.handleNewSourceClick.bind(this)} >

                                            <div className="aqa-icon" >
                                                <NewSourceIcon width="32px" />
                                            </div>
                                            <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                                SET DEFAULT
                                            </Typography>
                                        </IconButton>
                                    </HtmlTooltip>
                                ):(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Operation not permitted</Typography>
                                        </div>
                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <div>
                                            <IconButton className="aqa-button" >

                                                <div className="aqa-icon aqa-icon-e-disabled" >
                                                    <NewSourceIcon width="32px" />
                                                </div>
                                                <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#dadbdb",paddingLeft:"4px",marginTop:"-4px"}}>
                                                    SET DEFAULT
                                                </Typography>
                                            </IconButton>
                                        </div>
                                    </HtmlTooltip>
                                )}

                            </Typography>
                        </Grid>

                    </Grid>

                    <Dialog
                        open={this.state.openMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                Default Payment Method Confirmation
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={this.handleClosePopup.bind(this)} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:"600px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {("Are you sure you want to mark the selected payment as default")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>

                                </Grid>


                            </Grid>

                            <div style={{borderTop:"1px solid #ccc",marginTop:10}}>
                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                          style={{padding: 0, marginTop: 0,marginLeft:-120, marginBottom: 10}}>

                                        <Grid item xs={6} sm={6} lg={6} xl={6}>
                                            <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                                    color="primary"
                                                    align="left"
                                                    className="aqa-action-button"
                                                    style={{
                                                        marginTop: 8,
                                                        marginLeft: 8,
                                                        color: '#4cadc4',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #4cadc4',
                                                        fontSize: '0.7rem',
                                                        padding: '3px'
                                                    }}>
                                                Cancel
                                            </Button>
                                            <Button  variant="contained"
                                                     onClick={this.handleCreateUserClosePopup.bind(this)}
                                                     color="primary"
                                                     align="left"
                                                     className="aqa-action-button"
                                                     style={{
                                                         marginTop: 8,
                                                         marginLeft: 8,
                                                         color: '#4cadc4',
                                                         backgroundColor: 'white',
                                                         border: '1px solid #4cadc4',
                                                         fontSize: '0.7rem',
                                                         padding: '3px',
                                                     }}>
                                                Save & Close
                                            </Button>


                                        </Grid>

                                        <Grid item xs={1} sm={1} lg={1} xl={1}>
                                        </Grid>
                                        <Grid item xs={5} sm={5} lg={5} xl={5} style={{margin: '0px'}}>

                                        </Grid>

                                    </Grid>

                            </div>
                        </DialogContent>
                    </Dialog>

                </div>
            );
        }
    }
}

AqaPMDefault.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaPMDefault);
