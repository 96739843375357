/* Welcome to Aqa*/
/* Version: 1 */


import React from 'react';


import AqaComponent from "../shared/aqacomponent/AqaComponent";
//import Heatmap from "../shared/heatmap/Heatmap";


import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import {Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as SnapshotIcon} from "../../images/aqa-icons/AQA-Snapshot - v2.svg";
//import {ReactComponent as PersonIcon} from "../../images/aqa-icons/Usercol.svg";
import Typography from "@material-ui/core/Typography";
//import AqaCircularProgressBar from "../shared/meter/AqaCircularProgressBar";
import {ReactComponent as EventIcon} from "../../images/aqa-icons/Uploadcol.svg";
import moment from "moment";
//import AqaSparklineChart from "../shared/charts/AqaSparklineChart";
//import {ReactComponent as TrendDownIcon} from "../../images/aqa-icons/Trending Downcol.svg";
//import {ReactComponent as TrendUpIcon} from "../../images/aqa-icons/Trending Upcol.svg";
//import {ReactComponent as TrendNeutralIcon} from "../../images/aqa-icons/Flat Trendcol.svg";
//import AqaSnapshotDelete from "./AqaSnapshotDelete";
//import AqaGridIcon from "../../images/aqa-grid.png";

//import {ReactComponent as CheckIcon} from "../../images/aqa-icons/CheckOutline.svg";
//import {ReactComponent as XIcon} from "../../images/aqa-icons/XMarkOutline.svg";
//import {ReactComponent as HelpIcon} from "../../images/aqa-icons/HelpOutline.svg";
import HtmlTooltip from "@material-ui/core/Tooltip";
import AqaTable from "../../model/AqaTable";
import Grid from "@material-ui/core/Grid";



const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaLibraryHeader extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:{"name":"allsources"},
            folders: [],
            score: Math.round(Math.random()*100),
            lastScore: Math.round(Math.random()*100),
            diff: 0,
            historicalValues:[
                ["Last", "Scores"],
                [1, Math.round(Math.random()*90)],
                [2, Math.round(Math.random()*20)],
                [3, Math.round(Math.random()*60)],
                [4, Math.round(Math.random()*98)],
                [5, Math.round(Math.random()*80)],
                [6, Math.round(Math.random()*98)],
            ],
            numRows:16,
            numCols:0
        };
        this.handleFolder = this.handleFolder.bind(this);
        this.handleSource = this.handleSource.bind(this);
        this.handleSnapshot = this.handleSnapshot.bind(this);
        this.handleSnapshotAll = this.handleSnapshotAll.bind(this);
        this.handleAspectStat=this.handleAspectStat.bind(this);
        this.handleReviewAspectStat=this.handleReviewAspectStat.bind(this);
        this.handleGetRowsColumns=this.handleGetRowsColumns.bind(this);
    }

    handleFolder(event){
        //this.props.dataFromRoot.handleFolder(event);
    }

    handleSnapshot(){
        this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter2(this.props.dataFromSource, this.props.dataFromParent, null, false, false, null);
    }

    handleSnapshotAll(){
        //console.log(this.props);
        if(this.props.dataForScore !== null) this.props.controller.handleSnapshotDetail("RedAmber");
    }

    handleAspectStat(e,index,root){
        e.preventDefault();
        if(root !== undefined && root.numRules !== undefined && root.numRules > 0) {

            if (index === 0) {
                // rules editor to go here
                //var aspect = {index: 0, firstColumn: {id: "a_0_0"}};
                var authCode=localStorage.getItem("authCode");
                var context;
                if(authCode !== "A"){
                    this.removeAllNotifications();
                    this.showNotification(4, null,context , null);
                }
                else {
                    //this.props.dataFromRoot.props.dataFromParent.handleShowRule(aspect, this.props.dataFromParent);
                    this.props.controller.handleSnapshotDetail("RedAmber");
                }
                /*if(aspect.name === "Dimension"){
                    // table aspect rule editor to go here
                    this.props.dataFromRoot.handleShowDimensionRule(aspect,this.props.dataAcquired);
                }
                else{
                    // column aspect rule editor to go here
                    this.props.dataFromRoot.handleShowRule(aspect,this.props.dataAcquired);
                }*/
            } else if (index === 1) {
                //this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter3(this.props.dataFromSource, this.props.dataFromParent, null, false, true, null);
                this.props.controller.handleSnapshotDetail("Red");
            } else if (index === 2) {
                this.props.controller.handleSnapshotDetail("Amber");
                // this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter3(this.props.dataFromSource, this.props.dataFromParent, null, true, false, null);
            } else {
                // snapshot detail screen call to go here
            }
        }
        else{
            this.props.controller.handleSnapshotDetail("RedAmber",true);
        }
    }

    handleReviewAspectStat(e,index,root,type){
        e.preventDefault();
        if(root !== undefined && root.numR !== undefined) {

            if (index === 0) {
                // rules editor to go here
                //var aspect = {index: 0, firstColumn: {id: "a_0_0"}};
                var authCode=localStorage.getItem("authCode");
                var context;
                if(authCode !== "A"){
                    this.removeAllNotifications();
                    this.showNotification(4, null,context , null);
                }
                else {
                    //this.props.dataFromRoot.props.dataFromParent.handleShowRule(aspect, this.props.dataFromParent);
                    this.props.controller.handleSnapshotDetail2("RedAmber",type);
                }
                /*if(aspect.name === "Dimension"){
                    // table aspect rule editor to go here
                    this.props.dataFromRoot.handleShowDimensionRule(aspect,this.props.dataAcquired);
                }
                else{
                    // column aspect rule editor to go here
                    this.props.dataFromRoot.handleShowRule(aspect,this.props.dataAcquired);
                }*/
            } else if (index === 1) {
                //this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter3(this.props.dataFromSource, this.props.dataFromParent, null, false, true, null);
                if((type===0 && root.numR >0) || (type===1 && root.numI >0) || (type===2 && root.numF >0) ) this.props.controller.handleSnapshotDetail2("Red",type);
            } else if (index === 2) {
                if((type===0 && root.numR >0) || (type===1 && root.numI >0) || (type===2 && root.numF >0) )this.props.controller.handleSnapshotDetail2("Amber",type);
                // this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter3(this.props.dataFromSource, this.props.dataFromParent, null, true, false, null);
            } else {
                // snapshot detail screen call to go here
            }
        }
    }

    handleGetRowsColumns(snapshot)
    {
        this.table = new AqaTable(
            snapshot.id,
            e=> {
                var rows=16;
                var cols=16;
                if(this.table.data !== undefined){
                    rows=this.table.data.numberOfRows;
                    cols=this.table.data.numberOfColumns;
                }
                this.setState({numRows:rows,numCols:cols});

            }
        )
    } // handleGetRowsColumns

    handleSource(){
        this.props.dataFromRoot.handleSource(this.props.dataFromParent);
    }

    handleQualityDefinition = () =>{
        let source=this.props.dataFromParent;
        AqaComponent.backend.getQualityDefinitionUsingGET(
            source.id,
            (error, data, response) => {
                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                else {
                    let numCols = 0;
                    if(data.vectorQualityTable !== undefined) numCols = data.vectorQualityTable.columnQualities.length;
                    this.setState({qd:data,numCols:numCols});
                }
            }
        )
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.dataFromParent.status!== "Loading") {
            //this.handleGetRowsColumns(nextProps.dataFromParent);
        }
    }

    componentDidMount(){
        if(this.props.currentQD !==null){
            let currentQD = this.props.currentQD;
            let numCols = currentQD.qualityDefinition.vectorQualityTable.columnQualities.length;
            this.setState({numCols:numCols});
        }
    }
    render() {
        const { /*classes*/currentQD } = this.props;
        //const {  secondary,score,diff} = this.state;
        return (
            <Table>
                <TableBody>
                    <TableRow className="aqa-list" key={this.props.dataFromParent!==null?this.props.dataFromParent.id:"myheader"} style={{"border":"0px",paddingTop:"4px",cursor:"default"}} >

                        <TableCell size="small" width="1%" style={{padding:"4px 4px 4px 14px",border:"0px"}}>


                        </TableCell>
                        <TableCell width="26%" style={{border:"0px",padding:"4px 4px 4px 4px"}}>
                            <ListItemText
                                primary= {<IconButton className="aqa-button" style={{padding:"0px",cursor:"default"}}>
                                    <div className="aqa-list-icon" style={{padding:"4px"}}>
                                        <SnapshotIcon width="32px" />
                                    </div>
                                    <Typography className="aqa-text-bold" variant="inherit" style={{textAlign:"left",padding:"0px",marginTop:"-4px"}}>
                                        {this.props.currentQD===null?"":(this.props.currentQD.title)}
                                    </Typography>
                                </IconButton>}
                                secondary={<Typography component={'span'} style={{marginLeft:"4px"}}>

                                    {/*this.props.dataFromParent === null || this.props.dataFromParent.status === "Pending"?(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <Typography color="inherit" className="aqa-text-action">This Data Upload is neither Approved nor Rejected</Typography>

                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <IconButton className="aqa-button" style={{padding:"0px"}}>

                                            <div className="aqa-list-user-icon" style={{width: "32px"}}>
                                                {this.props.dataFromParent === null?(
                                                    <HelpIcon width="32px"/>
                                                ):(
                                                    this.props.dataFromParent.status === "Pending"?
                                                        <HelpIcon width="32px"/>:
                                                        (
                                                            this.props.dataFromParent.status === "Approved"?
                                                                <CheckIcon width="32px"/>:
                                                                (
                                                                    this.props.dataFromParent.status === "Rejected"?
                                                                        <XIcon width="32px"/>:
                                                                        <HelpIcon width="32px"/>

                                                                )
                                                        )
                                                )}

                                            </div>
                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                textAlign: "left",
                                                paddingLeft: "4px",
                                                marginTop: "-4px",
                                                width:"140px"
                                            }}>
                                                {this.props.dataFromParent === null?"Pending Approval":(this.props.dataFromParent.status==="Pending"?"Pending Approval":this.props.dataFromParent.status)}
                                            </Typography>
                                        </IconButton>
                                    </HtmlTooltip>
                                    ):
                                    (
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            {this.props.dataFromParent.status === "Approved" ?(
                                                <div>
                                                    <Typography color="inherit" className="aqa-text-action">Approved By: {this.props.dataFromParent.statusChangedBy}</Typography>
                                                    <Typography color="inherit" className="aqa-text-action">Approved On: {(this.props.dataFromParent.statusChangedDate !== null)? (moment(this.props.dataFromParent.statusChangedDate ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                                </div>
                                            ):(this.props.dataFromParent.status === "Rejected"?(
                                                <div>
                                                    <Typography color="inherit" className="aqa-text-action">Rejected By: {this.props.dataFromParent.statusChangedBy}</Typography>
                                                    <Typography color="inherit" className="aqa-text-action">Rejected On: {(this.props.dataFromParent.statusChangedDate !== null)? (moment(this.props.dataFromParent.statusChangedDate ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                                </div>
                                            ):(
                                                <Typography color="inherit"></Typography>
                                            ))

                                            }

                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                            <IconButton className="aqa-button" style={{padding:"0px"}}>

                                                <div className="aqa-list-user-icon" style={{width: "32px"}}>
                                                    {this.props.dataFromParent === null?(
                                                        <HelpIcon width="32px"/>
                                                    ):(
                                                        this.props.dataFromParent.status === "Pending"?
                                                            <HelpIcon width="32px"/>:
                                                            (
                                                                this.props.dataFromParent.status === "Approved"?
                                                                    <CheckIcon width="32px"/>:
                                                                    (
                                                                        this.props.dataFromParent.status === "Rejected"?
                                                                            <XIcon width="32px"/>:
                                                                            <HelpIcon width="32px"/>

                                                                    )
                                                            )
                                                    )}

                                                </div>
                                                <Typography className="aqa-text-action" variant="inherit" style={{
                                                    textAlign: "left",
                                                    paddingLeft: "4px",
                                                    marginTop: "-4px",
                                                    width:"140px"
                                                }}>
                                                    {this.props.dataFromParent === null?"Pending Approval":(this.props.dataFromParent.status==="Pending"?"Pending Approval":this.props.dataFromParent.status)}
                                                </Typography>
                                            </IconButton>
                                        </HtmlTooltip>
                                    )*/}

                                    <span style={{width:"150px",float:"left"}}>
                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                    <div>
                                        <Typography color="inherit" className="aqa-text-action">Loaded By: {this.props.dataFromParent===null?"":this.props.dataFromParent.createdBy}</Typography>
                                        <Typography color="inherit" className="aqa-text-action">Loaded On: {this.props.dataFromParent===null?"":(this.props.dataFromParent.dateCreated !== null)? (moment(this.props.dataFromParent.dateCreated ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                    </div>

                                </React.Fragment>} arrow={"true"} placement="bottom-start">

                                <IconButton className="aqa-button" style={{margin:0,padding:"0px",cursor:"default"}} >

                                    <div className="aqa-list-icon" style={{width: "32px"}}>
                                        <EventIcon width="32px"/>
                                    </div>
                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                        textAlign: "left",
                                        paddingLeft: "4px",
                                        paddingRight:"0px !important",
                                        marginTop: "-4px",
                                        width:"150px"
                                    }}>
                                        {this.props.dataFromParent===null?"":(this.props.dataFromParent.dateCreated !== null)? (moment(this.props.dataFromParent.dateCreated ).format("YYYY/MM/DD HH:mm")):("")}

                                    </Typography>
                                </IconButton>
                            </HtmlTooltip>
                            </span>
                                    <span style={{width:"200px",float:"left",paddingTop:"7px"}}>
                                    <IconButton className="aqa-button" style={{margin:0,padding:"0px"}}>
                                        <Grid container spacing={0} direction="row" justify="space-between"
                                              alignItems="center"
                                              style={{padding: 0, marginBottom: "0px"}}>
                                            <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                <Typography className="aqa-text-action" variant="inherit" style={{
                                                    textAlign: "right",
                                                    paddingLeft: "4px",
                                                    paddingRight:"0px !important",
                                                    marginTop: "-4px",
                                                }}>
                                                    Rows:

                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                <Typography className="aqa-text-action" variant="inherit" style={{
                                                    textAlign: "left",
                                                    paddingLeft: "16px",
                                                    paddingRight:"0px !important",
                                                    marginTop: "-4px",
                                                }} >
                                                    {this.props.dataFromParent!==null && this.props.dataForScore!==null && this.props.dataForScore!==undefined?(this.props.dataForScore.numberOfRows).toLocaleString():"0"}

                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={4} lg={4} xl={4}>
                                                <Typography className="aqa-text-action" variant="inherit" style={{
                                                    textAlign: "right",
                                                    paddingLeft: "4px",
                                                    paddingRight:"4px !important",
                                                    marginTop: "-4px",
                                                }}>
                                                    Columns:

                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                <Typography className="aqa-text-action" variant="inherit" style={{
                                                    textAlign: "right",
                                                    paddingLeft: "18px",
                                                    paddingRight:"0px !important",
                                                    marginTop: "-4px",
                                                }}>
                                                    {this.props.dataFromParent && this.props.dataForScore!==null && this.props.dataForScore!==undefined?this.props.dataForScore.numberOfColumns.toLocaleString():this.state.numCols}

                                                </Typography>
                                            </Grid>

                                            </Grid>
                                    </IconButton>
                                </span>

                                </Typography>}
                            >
                            </ListItemText>
                        </TableCell>
                        <TableCell width="39%" style={{padding:"4px 4px 4px 4px",border:"0px"}}>
                            <div style={{background: "none"}}>
                                <span>
                                        <Typography className="aqa-text-action" variant="inherit" style={{
                                            textAlign: "left",
                                            paddingLeft: "4px",
                                            marginTop: "-4px",
                                            color:"darkgrey"
                                        }}>
                                        Description:
                                    </Typography>
                                    <span className="aqa-button" style={{padding:"0px"}}>

                                        <Typography className="aqa-text-action" variant="inherit" style={{
                                            textAlign: "left",
                                            paddingLeft: "4px",
                                            marginTop: "4px",
                                            height:"80px",
                                            overflow:"auto"
                                        }}>
                                            {this.props.currentQD!==null?this.props.currentQD.description:""}

                                        </Typography>
                                    </span>
                                    </span>
                            </div>
                        </TableCell>

                        <TableCell width="35%" style={{padding:"4px",border:"0px"}}>
                            <div style={{background: "none"}}>
                                {currentQD !== null ?
                                    <Grid container spacing={0} direction="row" justify="space-between"
                                          alignItems="center"
                                          style={{padding: 0, marginTop: 0}}>

                                        <Grid item xs={2} sm={2} lg={2} xl={2}>
                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                textAlign: "left",
                                                paddingLeft: "4px",
                                                width: "100px",
                                                color: "darkgrey"
                                            }}>
                                                Published
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={4} lg={4} xl={4}>
                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                textAlign: "left",
                                                paddingLeft: "14px",
                                                paddingTop: "6px",
                                                paddingBottom: "6px"
                                            }}>    {moment(currentQD.publishTimestamp).locale('en_USA').fromNow()}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sm={2} lg={2} xl={2}>
                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                textAlign: "left",
                                                paddingLeft: "4px",
                                                width: "100px",
                                                color: "darkgrey"
                                            }}>
                                                Created
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={4} lg={4} xl={4}>
                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                textAlign: "left",
                                                paddingLeft: "4px",
                                                paddingTop: "6px",
                                                paddingBottom: "6px"
                                            }}>    {moment(currentQD.timestamp).locale('en_USA').fromNow()}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={2} sm={2} lg={2} xl={2}>
                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                textAlign: "left",
                                                paddingLeft: "4px",
                                                width: "100px",
                                                color: "darkgrey"
                                            }}>
                                                Version
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={4} lg={4} xl={4}>
                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                textAlign: "left",
                                                paddingLeft: "14px",
                                                paddingTop: "6px",
                                                paddingBottom: "6px"
                                            }}>    {currentQD.version}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sm={2} lg={2} xl={2}>
                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                textAlign: "left",
                                                paddingLeft: "4px",
                                                width: "100px",
                                                color: "darkgrey"
                                            }}>

                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={4} lg={4} xl={4}>
                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                textAlign: "left",
                                                paddingLeft: "4px",
                                                paddingTop: "6px",
                                                paddingBottom: "6px"
                                            }}>
                                            </Typography>
                                        </Grid>

                                    </Grid> : ""
                                }
                            </div>
                        </TableCell>


                    </TableRow>
                </TableBody>
            </Table>
        );

    }
}

AqaLibraryHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaLibraryHeader);

/*

                    { this.props.dataForMap === null?
                        <img src={AqaGridIcon} width="95px" alt="grid"/>
                        :
                        (
                            <div style={{marginLeft:"8px"}}>
                                {this.props.dataForMap!== undefined?this.props.dataForMap.handleRenderer(this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot,this.props.dataFromSource,this.props.dataFromParent) :""}
                                {this.props.dataForMap!== undefined?this.props.dataForMap.render():""}
                            </div>
                        )
                     }
*/



