/* Welcome to Aqa*/
/* Version: 1 */


import React from 'react';


import AqaComponent from "../shared/aqacomponent/AqaComponent";
import Heatmap from "../shared/heatmap/Heatmap";


import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import {LinearProgress, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as SnapshotIcon} from "../../images/aqa-icons/AQA-Snapshot - v2.svg";
//import {ReactComponent as PersonIcon} from "../../images/aqa-icons/Usercol.svg";
import Typography from "@material-ui/core/Typography";
//import AqaCircularProgressBar from "../shared/meter/AqaCircularProgressBar";
import {ReactComponent as EventIcon} from "../../images/aqa-icons/Uploadcol.svg";
import moment from "moment";
//import AqaSparklineChart from "../shared/charts/AqaSparklineChart";
//import {ReactComponent as TrendDownIcon} from "../../images/aqa-icons/Trending Downcol.svg";
//import {ReactComponent as TrendUpIcon} from "../../images/aqa-icons/Trending Upcol.svg";
//import {ReactComponent as TrendNeutralIcon} from "../../images/aqa-icons/Flat Trendcol.svg";
//import AqaSnapshotDelete from "./AqaSnapshotDelete";
import AqaGridIcon from "../../images/aqa-grid.png";

//import {ReactComponent as CheckIcon} from "../../images/aqa-icons/CheckOutline.svg";
//import {ReactComponent as XIcon} from "../../images/aqa-icons/XMarkOutline.svg";
//import {ReactComponent as HelpIcon} from "../../images/aqa-icons/HelpOutline.svg";
import HtmlTooltip from "@material-ui/core/Tooltip";
import AqaTable from "../../model/AqaTable";
import Grid from "@material-ui/core/Grid";
import {ReactComponent as LibraryIcon} from "../../images/aqa-icons/Aqa_Library.svg";



const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaSnapshotHeader extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:{"name":"allsources"},
            folders: [],
            score: Math.round(Math.random()*100),
            lastScore: Math.round(Math.random()*100),
            diff: 0,
            historicalValues:[
                ["Last", "Scores"],
                [1, Math.round(Math.random()*90)],
                [2, Math.round(Math.random()*20)],
                [3, Math.round(Math.random()*60)],
                [4, Math.round(Math.random()*98)],
                [5, Math.round(Math.random()*80)],
                [6, Math.round(Math.random()*98)],
            ],
            numRows:16,
            numCols:0,
            isWaiting:false
        };
        this.handleFolder = this.handleFolder.bind(this);
        this.handleSource = this.handleSource.bind(this);
        this.handleSnapshot = this.handleSnapshot.bind(this);
        this.handleSnapshotAll = this.handleSnapshotAll.bind(this);
        this.handleAspectStat=this.handleAspectStat.bind(this);
        this.handleReviewAspectStat=this.handleReviewAspectStat.bind(this);
        this.handleGetRowsColumns=this.handleGetRowsColumns.bind(this);
    }

    waitCursor() {
        this.setState({isWaiting:true});
        document.body.style.cursor = "wait"; }
    normalCursor() { this.setState({isWaiting:false});document.body.style.cursor = "default"; }

    handleFolder(event){
        //this.props.dataFromRoot.handleFolder(event);
    }

    handleSnapshot(){
        this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter2(this.props.dataFromSource, this.props.dataFromParent, null, false, false, null);
    }

    handleSnapshotAll =(e)=>{
        e.preventDefault();
        //console.log(this.props);
        if(this.props.dataForScore !== null) this.props.controller.handleSnapshotDetail("RedAmberGreen",true);
    }

    handleReviewOpen = () =>{
        let showReview = this.props.controller!== undefined && this.props.controller.state.showReviewPop!==undefined && this.props.controller.state.showReviewPop;
        if(showReview) this.props.controller.handleReviewOpen();
    }

    handleAspectStat(e,index,root){
        e.preventDefault();
        if(root !== undefined && root.numRules !== undefined && index >= 0) {

            if (index === 0) {
                // rules editor to go here
                //var aspect = {index: 0, firstColumn: {id: "a_0_0"}};
                var authCode=this.props.dataFromRoot.props.dataFromRoot.state.authCode;
                var context;
                if(authCode === "V"){
                    this.removeAllNotifications();
                    this.showNotification(4, null,context , null);
                }
                else {
                    //this.props.dataFromRoot.props.dataFromParent.handleShowRule(aspect, this.props.dataFromParent);
                    this.props.controller.handleSnapshotDetail("RedAmber",true);
                }
                /*if(aspect.name === "Dimension"){
                    // table aspect rule editor to go here
                    this.props.dataFromRoot.handleShowDimensionRule(aspect,this.props.dataAcquired);
                }
                else{
                    // column aspect rule editor to go here
                    this.props.dataFromRoot.handleShowRule(aspect,this.props.dataAcquired);
                }*/
            } else if (index === 1) {
                //this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter3(this.props.dataFromSource, this.props.dataFromParent, null, false, true, null);
                this.props.controller.handleSnapshotDetail("Red");
            } else if (index === 2) {
                this.props.controller.handleSnapshotDetail("Amber");
                // this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter3(this.props.dataFromSource, this.props.dataFromParent, null, true, false, null);
            } else {
                // snapshot detail screen call to go here
            }
        }
        else{
            this.props.controller.handleSnapshotDetail("RedAmber",true);
        }
    }

    handleReviewAspectStat(e,index,root,type){
        e.preventDefault();
        if(root !== undefined && index>=0) {
            this.waitCursor();
            var stat = "Aspect_"+index+"_"+type;
            if (index === 0) {
                // rules editor to go here
                //var aspect = {index: 0, firstColumn: {id: "a_0_0"}};
                var authCode=this.props.dataFromRoot.props.dataFromRoot.state.authCode;
                var context;
                if(authCode === "V"){
                    this.removeAllNotifications();
                    this.showNotification(4, null,context , null);
                    this.normalCursor();
                }
                else {
                    //this.props.dataFromRoot.props.dataFromParent.handleShowRule(aspect, this.props.dataFromParent);
                    // Get totals before we start

                    if(type<0) this.props.controller.handleSnapshotDetail2("RedAmber",type,stat);
                    else {
                        let num = 0;
                        for (let i = 1; i < this.props.dataForReviewStats.length; i++) {
                            if (type === 0) num = num + this.props.dataForReviewStats[i].numR;
                            if (type === 1) num = num + this.props.dataForReviewStats[i].numI;
                            if (type === 2) num = num + this.props.dataForReviewStats[i].numF;
                        }

                        if (num > 0) this.props.controller.handleSnapshotDetail2("RedAmber", type,stat);
                        else this.normalCursor();
                    }
                }
                /*if(aspect.name === "Dimension"){
                    // table aspect rule editor to go here
                    this.props.dataFromRoot.handleShowDimensionRule(aspect,this.props.dataAcquired);
                }
                else{
                    // column aspect rule editor to go here
                    this.props.dataFromRoot.handleShowRule(aspect,this.props.dataAcquired);
                }*/
            } else if (index === 1) {
                //this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter3(this.props.dataFromSource, this.props.dataFromParent, null, false, true, null);
                if((type===1 && root.numI >0) || (type===2 && root.numF >0) ) this.props.controller.handleSnapshotDetail2("RedGreen",type,stat);
                if(type===0 && root.numR > 0) this.props.controller.handleSnapshotDetail2("Red",type,stat);
                if(type<0 && root.numR >= 0) this.props.controller.handleSnapshotDetail2("Red",type,stat);
            } else if (index === 2) {
                if((type===0 && root.numR >0) || (type===1 && root.numI >0) || (type===2 && root.numF >0) ) this.props.controller.handleSnapshotDetail2("Amber",type,stat);
                if(type<0) this.props.controller.handleSnapshotDetail2("Amber",type,stat);
                // this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter3(this.props.dataFromSource, this.props.dataFromParent, null, true, false, null);
            } else {
                // snapshot detail screen call to go here
                this.normalCursor();
            }
        }
    }

    handleHealthAspectStat(e,index,root,type,stat){
        e.preventDefault();
        e.stopPropagation();
        /*let isAmber = true;
        let isRed = true;
        if(index === 2) isRed = false;
        if(index === 1) isAmber = false;*/
        this.showRules=true;
        if(root !== undefined && index>0 && index<3) {
            if (index === 0) {
                //var aspect = {index: 0, firstColumn: {id: "a_0_0"}};
                var authCode=this.props.dataFromRoot.props.dataFromRoot.state.authCode;
                var adminEmail=this.props.dataFromRoot.props.dataFromRoot.state.adminEmail;
                var context;
                if(authCode !== "A"){
                    this.removeAllNotifications();
                    this.showNotification(4, null,context , null,adminEmail);
                }
                else {
                    //this.props.dataFromRoot.handleShowRule(aspect, this.props.dataFromParent, this.state.latestSnapshot);
                }
            } else if (index > 0 && index<12) {
                let ctype = type==="populated"?"population":
                    (type==="uniqueness"?"uniqueness":
                        (type==="type"?"type":
                            (type==="format"?"overall":
                                (type==="outlier"?"overall":
                                    /*(index===6?"date":
                                    (index===7?"string":
                                    (index===8?"number":
                                    (index===9?"date":*/
                                    (type==="invalid"?"string":
                                        (type==="native"?"nativeError":"overall"))))));
                //let grain = index===10?2:(index>3&& index<7?0:(index>6&& index<10?1:-1));
                let grain = type==="invalid"?2:(type==="format"?0:(type==="outlier"?1:-1));
                let val = type==="populated"?root.numM:(type==="uniqueness"?root.numD:(type==="type"?root.numT:(type==="format"?root.numF:(type==="outlier"?root.numO:(type==="invalid"?root.numI:(type==="native"?root.numE:0))))));
                if((index===2 || index === 1) && ((type === "populated" && root.numM>0) || (type === "uniqueness" && root.numD>0) ||
                    (type === "type" && root.numT>0) || (type === "format" && root.numF>0) ||
                    (type === "outlier" && root.numO>0) || (type === "invalid" && root.numI>0) || (type === "native" && root.numE>0)
                )  ) {
                    this.setState({isWaiting:true});
                    this.props.controller.handleHeaderRow(index===1?"Red":(index===2?"Amber":"Green"),ctype,grain,val,stat);
                    //this.props.controller.props.dataFromMainFrame.handleSnapshotFilter2(this.props.dataFromSource, this.props.dataFromParent, 7, isAmber, isRed, null, null, true, false, ctype, grain,-1);
                }
                else {
                    this.normalCursor();
                    return false;
                }
            } else {
                this.normalCursor();
                return false;
            }
        }
        else{
            return false;
        }
        return false;
    }

    handleGetRowsColumns(snapshot)
    {
        this.table = new AqaTable(
            snapshot.id,
            e=> {
                var rows=16;
                var cols=16;
                if(this.table.data !== undefined){
                    rows=this.table.data.numberOfRows;
                    cols=this.table.data.numberOfColumns;
                }
                this.setState({numRows:rows,numCols:cols});

            }
        )
    } // handleGetRowsColumns

    handleSource(){
        this.props.dataFromRoot.handleSource(this.props.dataFromParent);
    }

    handleShowLibraryQD = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        if(this.props.dataForScore !== null) {
            this.props.dataFromRoot.props.dataFromRoot.handleAQDSource(this.props.dataFromSource.subscribedId, "source",false,false,2);
            this.props.dataFromRoot.props.dataFromRoot.navigation(6);
        }
        else{
            this.props.dataFromRoot.handleAQDSource(this.props.dataFromSource.subscribedId, "source",false,false,2);
            this.props.dataFromRoot.navigation(6);
        }
    }

    handleQualityDefinition = () =>{
        let source=this.props.dataFromSource;
        AqaComponent.backend.getQualityDefinitionUsingGET(
            source.id,
            (error, data, response) => {
                if (error) this.reportError("A problem updating the snapshots from the server was encountered.",
                    "AqaSnapshotMainHeader.handleSnapshotApprove, backend call: " + error,this);
                else {
                    let numCols = 0;
                    if(data.vectorQualityTable !== undefined) numCols = data.vectorQualityTable.columnQualities.length;
                    this.setState({qd:data,numCols:numCols});
                }
            }
        )
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.dataFromParent.status!== "Loading") {
            //this.handleGetRowsColumns(nextProps.dataFromParent);
        }
    }

    componentDidMount(){
        if(this.props.dataFromSource.subscribedId !== null){
            this.handleQualityDefinition();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.isWaiting !== this.props.isWaiting){
            this.setState({isWaiting:this.props.isWaiting});
        }
    }

    render() {
        //const { classes } = this.props;
        //const {  secondary,score,diff} = this.state;
        let borderStyle = {padding:"4px"};
        let borderStyle1 = {padding:"0px 4px"};
        let noborderStyle = {padding:"4px",border:"none"};
        let noborderStyle1 = {padding:"0px 4px",border:"none"};
        let {selectedStat} = this.props.controller.state;
        let showReview = this.props.controller!== undefined && this.props.controller.state.showReviewPop!==undefined && this.props.controller.state.showReviewPop;

        return (
            <Table>
                <TableBody>
            <TableRow className="aqa-list" key={this.props.dataFromParent!==null?this.props.dataFromParent.id:"myheader"} style={{"border":"0px",paddingTop:"4px",cursor:"default"}} >

                <TableCell size="small" width="1%" style={{padding:"4px 4px 4px 4px",border:"0px"}}>


                </TableCell>
                <TableCell width="22%" style={{border:"0px",padding:"4px 4px 4px 4px"}}>
                    <ListItemText style={{padding:"0px"}}
                        primary= {<IconButton className="aqa-button" style={{padding:"0px",cursor:"default"}}>
                            <div className="aqa-list-icon" style={{padding:"0px 4px"}}>
                                <SnapshotIcon width="32px" />
                            </div>
                            <Typography className="aqa-text-bold" variant="inherit" style={{textAlign:"left",padding:"0px",marginTop:"-4px"}}>
                            {this.props.dataFromParent===null?"":(this.props.dataFromParent.originalFilename === "" || this.props.dataFromParent.originalFilename ===undefined)? (this.props.dataForStats.length>0?this.props.dataFromParent.label:this.props.dataFromParent.name) : (this.props.dataFromParent.originalFilename+(this.props.dataFromParent.sheetName!=="[Default sheet]"?" - ["+this.props.dataFromParent.sheetName+"]":""))}
                        </Typography>
                        </IconButton>}
                        secondary={<Typography component={'span'} style={{marginLeft:"0px"}}>

                                {/*this.props.dataFromParent === null || this.props.dataFromParent.status === "Pending"?(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <Typography color="inherit" className="aqa-text-action">This Data Upload is neither Approved nor Rejected</Typography>

                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <IconButton className="aqa-button" style={{padding:"0px"}}>

                                            <div className="aqa-list-user-icon" style={{width: "32px"}}>
                                                {this.props.dataFromParent === null?(
                                                    <HelpIcon width="32px"/>
                                                ):(
                                                    this.props.dataFromParent.status === "Pending"?
                                                        <HelpIcon width="32px"/>:
                                                        (
                                                            this.props.dataFromParent.status === "Approved"?
                                                                <CheckIcon width="32px"/>:
                                                                (
                                                                    this.props.dataFromParent.status === "Rejected"?
                                                                        <XIcon width="32px"/>:
                                                                        <HelpIcon width="32px"/>

                                                                )
                                                        )
                                                )}

                                            </div>
                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                textAlign: "left",
                                                paddingLeft: "4px",
                                                marginTop: "-4px",
                                                width:"140px"
                                            }}>
                                                {this.props.dataFromParent === null?"Pending Approval":(this.props.dataFromParent.status==="Pending"?"Pending Approval":this.props.dataFromParent.status)}
                                            </Typography>
                                        </IconButton>
                                    </HtmlTooltip>
                                    ):
                                    (
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            {this.props.dataFromParent.status === "Approved" ?(
                                                <div>
                                                    <Typography color="inherit" className="aqa-text-action">Approved By: {this.props.dataFromParent.statusChangedBy}</Typography>
                                                    <Typography color="inherit" className="aqa-text-action">Approved On: {(this.props.dataFromParent.statusChangedDate !== null)? (moment(this.props.dataFromParent.statusChangedDate ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                                </div>
                                            ):(this.props.dataFromParent.status === "Rejected"?(
                                                <div>
                                                    <Typography color="inherit" className="aqa-text-action">Rejected By: {this.props.dataFromParent.statusChangedBy}</Typography>
                                                    <Typography color="inherit" className="aqa-text-action">Rejected On: {(this.props.dataFromParent.statusChangedDate !== null)? (moment(this.props.dataFromParent.statusChangedDate ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                                </div>
                                            ):(
                                                <Typography color="inherit"></Typography>
                                            ))

                                            }

                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                            <IconButton className="aqa-button" style={{padding:"0px"}}>

                                                <div className="aqa-list-user-icon" style={{width: "32px"}}>
                                                    {this.props.dataFromParent === null?(
                                                        <HelpIcon width="32px"/>
                                                    ):(
                                                        this.props.dataFromParent.status === "Pending"?
                                                            <HelpIcon width="32px"/>:
                                                            (
                                                                this.props.dataFromParent.status === "Approved"?
                                                                    <CheckIcon width="32px"/>:
                                                                    (
                                                                        this.props.dataFromParent.status === "Rejected"?
                                                                            <XIcon width="32px"/>:
                                                                            <HelpIcon width="32px"/>

                                                                    )
                                                            )
                                                    )}

                                                </div>
                                                <Typography className="aqa-text-action" variant="inherit" style={{
                                                    textAlign: "left",
                                                    paddingLeft: "4px",
                                                    marginTop: "-4px",
                                                    width:"140px"
                                                }}>
                                                    {this.props.dataFromParent === null?"Pending Approval":(this.props.dataFromParent.status==="Pending"?"Pending Approval":this.props.dataFromParent.status)}
                                                </Typography>
                                            </IconButton>
                                        </HtmlTooltip>
                                    )*/}

                            <span style={{width:"145px",float:"left"}}>
                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                <div>
                                    <Typography color="inherit" className="aqa-text-action">Loaded By: {this.props.dataFromParent===null?"":this.props.dataFromParent.createdBy}</Typography>
                                    <Typography color="inherit" className="aqa-text-action">Loaded On: {this.props.dataFromParent===null?"":(this.props.dataFromParent.dateCreated !== null)? (moment(this.props.dataFromParent.dateCreated ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                </div>

                            </React.Fragment>} arrow={"true"} placement="bottom-start">

                                <IconButton className="aqa-button" style={{margin:0,padding:"0px",cursor:"default"}} >

                                    <div className="aqa-list-icon" style={{width: "32px",padding:"0px 4px"}}>
                                        <EventIcon width="32px"/>
                                    </div>
                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                        textAlign: "left",
                                        paddingLeft: "8px",
                                        paddingRight:"0px !important",
                                        marginTop: "-4px",
                                        width:"150px"
                                    }}>
                                        {this.props.dataFromParent===null?"":(this.props.dataFromParent.dateCreated !== null)? (moment(this.props.dataFromParent.dateCreated ).format("YYYY/MM/DD HH:mm")):("")}

                                    </Typography>
                                </IconButton>
                            </HtmlTooltip>
                            </span>
                                <span style={{width:window.innerHeight>580 && window.innerHeight<680?"200px":"220px",float:"left",paddingTop:"6px"}}>

                                        <Grid container spacing={0} direction="row" justify="space-between"
                                              alignItems="center"
                                              style={{padding: 0, marginBottom: "0px"}}>
                                            <Grid item xs={7} sm={7} lg={7} xl={7}>
                                                <IconButton className="aqa-button" style={{margin:0,padding:"0px"}} onClick={(e)=>this.handleSnapshotAll(e)}>
                                                <Typography className="aqa-text-action" variant="inherit" style={{
                                                    textAlign: "right",
                                                    paddingLeft: "4px",
                                                    paddingRight:"0px !important",
                                                    marginTop: "-2px",
                                                }}>
                                                    {"Rows: "+(this.props.dataFromParent!==null && this.props.dataForScore!==null && this.props.dataForScore!==undefined?(this.props.dataForScore.numberOfRows).toLocaleString():"0")}

                                                </Typography>
                                                </IconButton>
                                            </Grid>

                                            <Grid item xs={5} sm={5} lg={5} xl={5}>
                                                {this.props.dataFromSource !== null && this.props.dataFromSource.subscribedId !== null ?
                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                        <div>
                                                            <Typography color="inherit" className="aqa-text-action">Loaded from the library</Typography>
                                                        </div>

                                                    </React.Fragment>} arrow={"true"} placement="bottom-start">

                                                        <IconButton className="aqa-button" style={{margin:0,padding:"0px",marginTop:"0px"}} onClick={(e)=>this.handleShowLibraryQD(e)}>

                                                            <div className="aqa-list-icon" style={{width: "24px"}}>
                                                                <LibraryIcon width="24px"/>
                                                            </div>
                                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                                textAlign: "left",
                                                                paddingLeft: "4px",
                                                                paddingRight:"0px !important",
                                                                marginTop: "0px"
                                                            }}>
                                                                {"Columns: "+(this.props.dataFromParent && this.props.dataForScore!==null && this.props.dataForScore!==undefined?this.props.dataForScore.numberOfColumns.toLocaleString():this.state.numCols)}
                                                            </Typography>
                                                        </IconButton>
                                                    </HtmlTooltip>
                                                    :
                                                    <Typography className="aqa-text-action" variant="inherit" style={{
                                                        textAlign: "right",
                                                        paddingLeft: "4px",
                                                        paddingRight: "4px !important",
                                                        marginTop: "0px",
                                                    }}>
                                                        {"Columns: "+(this.props.dataFromParent && this.props.dataForScore!==null && this.props.dataForScore!==undefined?this.props.dataForScore.numberOfColumns.toLocaleString():this.state.numCols)}

                                                    </Typography>
                                                }
                                            </Grid>

                                            </Grid>
                                </span>

                        </Typography>}
                    >
                    </ListItemText>
                </TableCell>
                <TableCell width="34%" style={{padding:"0px 4px 0px 0px"}}>
                    <div style={{background: "none"}}>
                        <Table id={this.props.dataFromParent.id+"_statstable"} cellSpacing={0} aria-sort="ascending" style={{}}>
                            <colgroup>
                                <col width="16%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                            </colgroup>
                            <TableHead>

                            </TableHead>
                            <TableBody>
                                {this.props.dataForHealthStats.map((item,index)=>

                                    (index>0 && index<3?(
                                        (

                                            <TableRow key={item.id} className={"aqa-violation-folder"} style={{height:"18px"}} >
                                                <TableCell width="16%" className={"aqa-no-select"} style={index>2?noborderStyle:borderStyle}>
                                                    {index > 0 ? <div>
                                                            <Typography className="aqa-text-bold-11" variant="inherit"
                                                                        style={{
                                                                            textAlign: "left",
                                                                            color: index === 0 ? "grey" : "#181818",
                                                                            height: "16px",
                                                                            padding: "4px",
                                                                            whiteSpace:"nowrap"
                                                                        }}>
                                                                {item.name}
                                                            </Typography>
                                                        </div> :
                                                        <HtmlTooltip enterDelay={500} key={item.id}
                                                                     title={<React.Fragment>
                                                                         <div>
                                                                             <Typography color="inherit"
                                                                                         className="aqa-text-action">{item.description}</Typography>
                                                                         </div>

                                                                     </React.Fragment>} arrow={"true"}
                                                                     placement="bottom-start">
                                                            <div>

                                                                <Typography className="aqa-text-bold-11"
                                                                            variant="inherit" style={{
                                                                    textAlign: "left",
                                                                    color: index === 0 ? "grey" : "#181818",
                                                                    height: "16px",
                                                                    padding: "4px",
                                                                }}>
                                                                    {item.name}
                                                                </Typography>
                                                            </div>
                                                        </HtmlTooltip>
                                                    }
                                                </TableCell>
                                                <TableCell width="12%" className={selectedStat==="Header_Health_Stat_M_"+index?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.numM>0?"aqa-select":"aqa-no-select")} style={index>2?noborderStyle1:borderStyle1}>
                                                    <Typography className={item.numM>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                        textAlign: "right",
                                                        color: index===0?"grey":(index===2?(item.numM>0?"rgb(251, 154, 40)":"#181818"):(item.numM>0?"red":"#181818")),
                                                        paddingLeft: "0px",
                                                        padding: "0px",

                                                        height:item.numM>0?"16px":"8px",
                                                        fontWeight:item.numM>0?"bold":"400"
                                                    }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"populated","Header_Health_Stat_M_"+index)}>
                                                        {item.numM>0?
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numMO===100?"Review Completed":"Review progress : "+item.numMS+" / "+ item.numM} </Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    {item.numM.toLocaleString()}
                                                                </div>
                                                            </HtmlTooltip>
                                                            :
                                                            <div>{item.numM.toLocaleString()}</div>
                                                        }

                                                    </Typography>
                                                    {item.numM>0?
                                                        <Typography className={item.numM>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                            textAlign: "right",
                                                            color: index===0?"grey":(index===2?(item.numM>0?"rgb(251, 154, 40)":"#181818"):(item.numM>0?"red":"#181818")),
                                                            paddingLeft: "0px",
                                                            padding: "0px",
                                                            height:"2px",
                                                            marginBottom:"-6px",
                                                            fontWeight:item.numM>0?"bold":"400"
                                                        }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"populated","Header_Health_Stat_M_"+index)}>
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numMO===100?"Review Completed":"Review progress : "+item.numMS+" / "+ item.numM} </Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    <LinearProgress variant="determinate" value={item.numMO} style={{height:"2px"}}/>
                                                                </div>
                                                            </HtmlTooltip>

                                                        </Typography>
                                                        :""}
                                                </TableCell>
                                                <TableCell width="12%" className={selectedStat==="Header_Health_Stat_D_"+index?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.numD>0?"aqa-select":"aqa-no-select")} style={index>2?noborderStyle1:borderStyle1}>
                                                    <Typography className={item.numD>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                        textAlign: "right",
                                                        color: index===0?"grey":(index===2?(item.numD>0?"rgb(251, 154, 40)":"#181818"):(item.numD>0?"red":"#181818")),
                                                        paddingLeft: "0px",
                                                        padding: "0px",
                                                        height:item.numD>0?"16px":"8px",
                                                        fontWeight:item.numD>0?"bold":"400"
                                                    }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"uniqueness","Header_Health_Stat_D_"+index)}>
                                                        {item.numD>0?
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numDO===100?"Review Completed":"Review progress : "+item.numDS+" / "+ item.numD} </Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    {item.numD.toLocaleString()}
                                                                </div>
                                                            </HtmlTooltip>
                                                            :
                                                            <div>{item.numD.toLocaleString()}</div>
                                                        }

                                                    </Typography>
                                                    {item.numD>0?
                                                        <Typography className={item.numD>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                            textAlign: "right",
                                                            color: index===0?"grey":(index===2?(item.numD>0?"rgb(251, 154, 40)":"#181818"):(item.numD>0?"red":"#181818")),
                                                            paddingLeft: "0px",
                                                            padding: "0px",
                                                            height:"2px",
                                                            marginBottom:"-6px",
                                                            fontWeight:item.numD>0?"bold":"400"
                                                        }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"uniqueness","Header_Health_Stat_D_"+index)}>
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numDO===100?"Review Completed":"Review progress : "+item.numDS+" / "+ item.numD} </Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    <LinearProgress variant="determinate" value={item.numDO} style={{height:"2px"}}/>
                                                                </div>
                                                            </HtmlTooltip>

                                                        </Typography>:""}
                                                </TableCell>
                                                <TableCell width="12%" className={selectedStat==="Header_Health_Stat_T_"+index?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.numT>0?"aqa-select":"aqa-no-select")} style={index>2?noborderStyle1:borderStyle1}>
                                                    <Typography className={item.numT>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                        textAlign: "right",
                                                        color: index===0?"grey":(index===2?(item.numT>0?"rgb(251, 154, 40)":"#181818"):(item.numT>0?"red":"#181818")),
                                                        paddingLeft: "0px",
                                                        padding: "0px",
                                                        height:item.numT>0?"16px":"8px",
                                                        fontWeight:item.numT>0?"bold":"400",

                                                    }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"type","Header_Health_Stat_T_"+index)}>
                                                        {item.numT>0?
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numTO===100?"Review Completed":"Review progress : "+item.numTS+" / "+ item.numT} </Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    {item.numT.toLocaleString()}
                                                                </div>
                                                            </HtmlTooltip>
                                                            :
                                                            <div>{item.numT.toLocaleString()}</div>
                                                        }
                                                    </Typography>
                                                    {item.numT>0?
                                                        <Typography className={item.numT>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                            textAlign: "right",
                                                            color: index===0?"grey":(index===2?(item.numT>0?"rgb(251, 154, 40)":"#181818"):(item.numT>0?"red":"#181818")),
                                                            paddingLeft: "0px",
                                                            padding: "0px",
                                                            height:"2px",
                                                            marginBottom:"-6px",
                                                            fontWeight:item.numT>0?"bold":"400",

                                                        }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"type","Header_Health_Stat_T_"+index)}>
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numTO===100?"Review Completed":"Review progress : "+item.numTS+" / "+ item.numT} </Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    <LinearProgress variant="determinate" value={item.numTO} style={{height:"2px"}}/>
                                                                </div>
                                                            </HtmlTooltip>
                                                        </Typography>:""}
                                                </TableCell>
                                                <TableCell width="12%" className={selectedStat==="Header_Health_Stat_F_"+index?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.numF>0?"aqa-select":"aqa-no-select")} style={index>2?noborderStyle1:borderStyle1}>
                                                    <Typography className={item.numF>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                        textAlign: "right",
                                                        color: index===0?"grey":(index===2?(item.numF>0?"rgb(251, 154, 40)":"#181818"):(item.numF>0?"red":"#181818")),
                                                        paddingLeft: "0px",
                                                        padding: "0px",

                                                        height:item.numF>0?"16px":"8px",
                                                        fontWeight:item.numF>0?"bold":"400"
                                                    }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"format","Header_Health_Stat_F_"+index)}>
                                                        {item.numF>0?
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numFO===100?"Review Completed":"Review progress : "+item.numFS+" / "+ item.numF} </Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    {item.numF.toLocaleString()}
                                                                </div>
                                                            </HtmlTooltip>
                                                            :
                                                            <div>{item.numF.toLocaleString()}</div>
                                                        }

                                                    </Typography>
                                                    {item.numF>0?
                                                        <Typography className={item.numF>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                            textAlign: "right",
                                                            color: index===0?"grey":(index===2?(item.numF>0?"rgb(251, 154, 40)":"#181818"):(item.numF>0?"red":"#181818")),
                                                            paddingLeft: "0px",
                                                            padding: "0px",

                                                            height:"2px",
                                                            marginBottom:"-6px",
                                                            fontWeight:item.numF>0?"bold":"400"
                                                        }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"format","Header_Health_Stat_F_"+index)}>
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numFO===100?"Review Completed":"Review progress : "+item.numFS+" / "+ item.numF} </Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    <LinearProgress variant="determinate" value={item.numFO} style={{height:"2px"}}/>
                                                                </div>
                                                            </HtmlTooltip>

                                                        </Typography>:""}
                                                </TableCell>
                                                <TableCell width="12%" className={selectedStat==="Header_Health_Stat_O_"+index?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.numO>0?"aqa-select":"aqa-no-select")} style={index>2?noborderStyle1:borderStyle1}>
                                                    <Typography className={item.numO>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                        textAlign: "right",
                                                        color: index===0?"grey":(index===2?(item.numO>0?"rgb(251, 154, 40)":"#181818"):(item.numO>0?"red":"#181818")),
                                                        paddingLeft: "0px",
                                                        padding: "0px",
                                                        height:item.numO>0?"16px":"8px",
                                                        fontWeight:item.numO>0?"bold":"400"
                                                    }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"outlier","Header_Health_Stat_O_"+index)}>
                                                        {item.numO>0?
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numOO===100?"Review Completed":"Review progress : "+item.numOS+" / "+ item.numO} </Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    {item.numO.toLocaleString()}
                                                                </div>
                                                            </HtmlTooltip>
                                                            :
                                                            <div>{item.numO.toLocaleString()}</div>
                                                        }

                                                    </Typography>
                                                    {item.numO>0?
                                                        <Typography className={item.numO>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                            textAlign: "right",
                                                            color: index===0?"grey":(index===2?(item.numO>0?"rgb(251, 154, 40)":"#181818"):(item.numO>0?"red":"#181818")),
                                                            paddingLeft: "0px",
                                                            padding: "0px",
                                                            height:"2px",
                                                            marginBottom:"-6px",
                                                            fontWeight:item.numO>0?"bold":"400"
                                                        }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"outlier","Header_Health_Stat_O_"+index)}>
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numOO===100?"Review Completed":"Review progress : "+item.numOS+" / "+ item.numO} </Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    <LinearProgress variant="determinate" value={item.numOO} style={{height:"2px"}}/>
                                                                </div>
                                                            </HtmlTooltip>

                                                        </Typography>:""}
                                                </TableCell>
                                                <TableCell width="12%" className={selectedStat==="Header_Health_Stat_I_"+index?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.numI>0?"aqa-select":"aqa-no-select")} style={index>2?noborderStyle1:borderStyle1}>
                                                    <Typography className={item.numI>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                        textAlign: "right",
                                                        color: index===0?"grey":(index===2?(item.numI>0?"rgb(251, 154, 40)":"#181818"):(item.numI>0?"red":"#181818")),
                                                        paddingLeft: "0px",
                                                        padding: "0px",
                                                        height:item.numI>0?"16px":"8px",
                                                        fontWeight:item.numI>0?"bold":"400",

                                                    }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"invalid","Header_Health_Stat_I_"+index)}>
                                                        {item.numI>0?
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numIO===100?"Review Completed":"Review progress : "+item.numIS+" / "+ item.numI} </Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    {item.numI.toLocaleString()}
                                                                </div>
                                                            </HtmlTooltip>
                                                            :
                                                            <div>{item.numI.toLocaleString()}</div>
                                                        }

                                                    </Typography>
                                                    {item.numI>0?
                                                        <Typography className={item.numI>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                            textAlign: "right",
                                                            color: index===0?"grey":(index===2?(item.numI>0?"rgb(251, 154, 40)":"#181818"):(item.numI>0?"red":"#181818")),
                                                            paddingLeft: "4px",
                                                            padding: "0px",
                                                            height:"2px",
                                                            marginBottom:"-6px",
                                                            fontWeight:item.numI>0?"bold":"400",

                                                        }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"invalid","Header_Health_Stat_I_"+index)}>
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numIO===100?"Review Completed":"Review progress : "+item.numIS+" / "+ item.numI} </Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    <LinearProgress variant="determinate" value={item.numIO} style={{height:"2px"}}/>
                                                                </div>
                                                            </HtmlTooltip>

                                                        </Typography>:""}
                                                </TableCell>
                                                <TableCell width="12%" className={selectedStat==="Header_Health_Stat_X_"+index?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.numE>0?"aqa-select":"aqa-no-select")} style={index>2?noborderStyle1:borderStyle1}>
                                                    <Typography className={item.numE>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                        textAlign: "right",
                                                        color: index===0?"grey":(index===2?(item.numE>0?"rgb(251, 154, 40)":"#181818"):(item.numE>0?"red":"#181818")),
                                                        paddingLeft: "0px",
                                                        padding: "0px",
                                                        height:item.numE>0?"16px":"8px",
                                                        fontWeight:item.numE>0?"bold":"400",

                                                    }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"native","Header_Health_Stat_X_"+index)}>
                                                        {item.numE>0?
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numEO===100?"Review Completed":"Review progress : "+item.numES+" / "+ item.numE} </Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    {item.numE.toLocaleString()}
                                                                </div>
                                                            </HtmlTooltip>
                                                            :
                                                            <div>{item.numE.toLocaleString()}</div>
                                                        }

                                                    </Typography>
                                                    {item.numE>0?
                                                        <Typography className={item.numE>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                            textAlign: "right",
                                                            color: index===0?"grey":(index===2?(item.numE>0?"rgb(251, 154, 40)":"#181818"):(item.numE>0?"red":"#181818")),
                                                            paddingLeft: "4px",
                                                            padding: "0px",
                                                            height:"2px",
                                                            marginBottom:"-6px",
                                                            fontWeight:item.numE>0?"bold":"400",

                                                        }} onClick={(e)=>this.handleHealthAspectStat(e, index,item,"native","Header_Health_Stat_E_"+index)}>
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action-14" style={{fontSize:"11px",fontFamily:"Montserrat"}}>{item.numEO===100?"Review Completed":"Review progress : "+item.numES+" / "+ item.numE} </Typography>
                                                                </div>
                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    <LinearProgress variant="determinate" value={item.numEO} style={{height:"2px"}}/>
                                                                </div>
                                                            </HtmlTooltip>

                                                        </Typography>:""}
                                                </TableCell>

                                            </TableRow>

                                        )
                                    ):(
                                        (

                                            <TableRow key={item.id} className={"aqa-violation-folder"} style={{height:"18px",cursor:"default"}} onClick={(e)=>this.handleHealthAspectStat(e, index,item)}>
                                                <TableCell width="16%" style={index>2?noborderStyle:borderStyle}>
                                                    {index === 0 || index === 3 ? <div>
                                                            <Typography className="aqa-text-bold-11" variant="inherit"
                                                                        style={{
                                                                            textAlign: "left",
                                                                            color: index === 0 ? "grey" : "#181818",
                                                                            padding: "4px",
                                                                            height:"16px",
                                                                            whiteSpace:"nowrap"
                                                                        }}>
                                                                {item.name}
                                                            </Typography>
                                                        </div> :
                                                        <HtmlTooltip enterDelay={500} key={item.id}
                                                                     title={<React.Fragment>
                                                                         <div>
                                                                             <Typography color="inherit"
                                                                                         className="aqa-text-action">{"Health status"}</Typography>
                                                                         </div>

                                                                     </React.Fragment>} arrow={"true"}
                                                                     placement="bottom-start">
                                                            <div>
                                                                <Typography className="aqa-text-bold-11"
                                                                            variant="inherit" style={{
                                                                    textAlign: "left",
                                                                    color: index === 0 ? "grey" : "#181818",
                                                                    height: "5px",
                                                                    padding: "4px",
                                                                    whiteSpace:"nowrap"
                                                                }}>
                                                                    {item.name}
                                                                </Typography>
                                                            </div>
                                                        </HtmlTooltip>
                                                    }
                                                </TableCell>
                                                <TableCell width="12%" style={index>2?noborderStyle:borderStyle}>
                                                    {index > 0 ? <div>
                                                            <Typography className={item.numR>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                textAlign: "right",
                                                                color: index===0?"grey":(index>1?(item.numR>0?"rgb(251, 154, 40)":"#181818"):(item.numR>0?"rgb(251, 154, 40)":"#181818")),
                                                                paddingLeft: "0px",
                                                                padding: index>0?"0px":"4px",

                                                                height:index===0?"":"5px",
                                                                fontWeight:item.numR>0?"bold":"400",
                                                                backgroundColor: (index>2?(item.numM===0?"red":(item.numM===1?"rgb(251, 154, 40)":"green")):"")
                                                            }} >
                                                                {index===3?"":item.numM}

                                                            </Typography>
                                                        </div> :
                                                        <HtmlTooltip enterDelay={500} key={item.id}
                                                                     title={<React.Fragment>
                                                                         <div>
                                                                             <Typography color="inherit"
                                                                                         className="aqa-text-action">{"Does the data contain any cells that have missing data?"}</Typography>
                                                                         </div>

                                                                     </React.Fragment>} arrow={"true"}
                                                                     placement="bottom-start">
                                                            <div>
                                                                <Typography className="aqa-text-bold-11"
                                                                            variant="inherit" style={{
                                                                    textAlign: "right",
                                                                    color: index === 0 ? "grey" : "#181818",
                                                                    height:index===0?"16px":"5px",
                                                                    padding: index>0?"0px":"4px"
                                                                }}>
                                                                    {item.numM}
                                                                </Typography>
                                                            </div>
                                                        </HtmlTooltip>
                                                    }

                                                </TableCell>
                                                <TableCell width="12%" style={index>2?noborderStyle:borderStyle}>
                                                    {index > 0 ? <div>
                                                            <Typography className={item.numD>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                textAlign: "right",
                                                                color: index===0?"grey":(index>1?(item.numD>0?"rgb(251, 154, 40)":"#181818"):(item.numD>0?"rgb(251, 154, 40)":"#181818")),
                                                                paddingLeft: "0px",
                                                                padding: index>0?"0px":"4px",

                                                                height:index===0?"16px":"5px",
                                                                fontWeight:item.numR>0?"bold":"400",
                                                                backgroundColor: (index>2?(item.numD===0?"red":(item.numD===1?"rgb(251, 154, 40)":"green")):"")
                                                            }} >
                                                                {index===3?"":item.numD}

                                                            </Typography>
                                                        </div> :
                                                        <HtmlTooltip enterDelay={500} key={item.id}
                                                                     title={<React.Fragment>
                                                                         <div>
                                                                             <Typography color="inherit"
                                                                                         className="aqa-text-action">{"For columns containing alpha-numeric data, are an values duplicated?"}</Typography>
                                                                         </div>

                                                                     </React.Fragment>} arrow={"true"}
                                                                     placement="bottom-start">
                                                            <div>
                                                                <Typography className="aqa-text-bold-11"
                                                                            variant="inherit" style={{
                                                                    textAlign: "right",
                                                                    color: index === 0 ? "grey" : "#181818",
                                                                    padding: index>0?"0px":"4px",
                                                                    height:index===0?"16px":"5px",
                                                                }}>
                                                                    {item.numD}
                                                                </Typography>
                                                            </div>
                                                        </HtmlTooltip>
                                                    }
                                                </TableCell>
                                                <TableCell width="12%" style={index>2?noborderStyle:borderStyle}>
                                                    {index > 0 ? <div>
                                                            <Typography className={item.numT>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                textAlign: "right",
                                                                color: index===0?"grey":(index>1?(item.numT>0?"rgb(251, 154, 40)":"#181818"):(item.numT>0?"rgb(251, 154, 40)":"#181818")),
                                                                paddingLeft: "0px",
                                                                height:index===0?"16px":"5px",
                                                                padding: index>0?"0px":"4px",
                                                                fontWeight:item.numT>0?"bold":"400",
                                                                backgroundColor: (index>2?(item.numT===0?"red":(item.numT===1?"rgb(251, 154, 40)":"green")):"")
                                                            }} >
                                                                {index===3?"":item.numT}

                                                            </Typography>
                                                        </div> :
                                                        <HtmlTooltip enterDelay={500} key={item.id}
                                                                     title={<React.Fragment>
                                                                         <div>
                                                                             <Typography color="inherit"
                                                                                         className="aqa-text-action">{"If a column contains data of one type, are there any values in any cells of a different type?"}</Typography>
                                                                         </div>

                                                                     </React.Fragment>} arrow={"true"}
                                                                     placement="bottom-start">
                                                            <div>
                                                                <Typography className="aqa-text-bold-11"
                                                                            variant="inherit" style={{
                                                                    textAlign: "right",
                                                                    color: index === 0 ? "grey" : "#181818",
                                                                    height:index===0?"16px":"5px",
                                                                    padding: index>0?"0px":"4px"
                                                                }}>
                                                                    {item.numT}
                                                                </Typography>
                                                            </div>
                                                        </HtmlTooltip>
                                                    }
                                                </TableCell>
                                                <TableCell width="12%" style={index>2?noborderStyle:borderStyle}>
                                                    {index > 0 ? <div>
                                                            <Typography className={item.numF>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                textAlign: "right",
                                                                color: index===0?"grey":(index>1?(item.numF>0?"rgb(251, 154, 40)":"#181818"):(item.numF>0?"rgb(251, 154, 40)":"#181818")),
                                                                paddingLeft: "0px",
                                                                height:index===0?"16px":"5px",
                                                                padding: index>0?"0px":"4px",
                                                                fontWeight:item.numF>0?"bold":"400",
                                                                backgroundColor: (index>2?(item.numF===0?"red":(item.numF===1?"rgb(251, 154, 40)":"green")):"")
                                                            }} >
                                                                {index===3?"":item.numF}

                                                            </Typography>
                                                        </div> :
                                                        <HtmlTooltip enterDelay={500} key={item.id}
                                                                     title={<React.Fragment>
                                                                         <div>
                                                                             <Typography color="inherit"
                                                                                         className="aqa-text-action">{"If there are columns with specific format types, are there any cells that do not conform?"}</Typography>
                                                                         </div>

                                                                     </React.Fragment>} arrow={"true"}
                                                                     placement="bottom-start">
                                                            <div>
                                                                <Typography className="aqa-text-bold-11"
                                                                            variant="inherit" style={{
                                                                    textAlign: "right",
                                                                    color: index === 0 ? "grey" : "#181818",
                                                                    height:index===0?"16px":"5px",
                                                                    padding: index>0?"0px":"4px"
                                                                }}>
                                                                    {item.numF}
                                                                </Typography>
                                                            </div>
                                                        </HtmlTooltip>
                                                    }
                                                </TableCell>
                                                <TableCell width="12%" style={index>2?noborderStyle:borderStyle}>
                                                    {index > 0 ? <div>
                                                            <Typography className={item.numO>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                textAlign: "right",
                                                                color: index===0?"grey":(index>1?(item.numO>0?"rgb(251, 154, 40)":"#181818"):(item.numO>0?"rgb(251, 154, 40)":"#181818")),
                                                                paddingLeft: "0px",
                                                                height:index===0?"16px":"5px",
                                                                padding: index>0?"0px":"4px",
                                                                fontWeight:item.numO>0?"bold":"400",
                                                                backgroundColor: (index>2?(item.numO===0?"red":(item.numO===1?"rgb(251, 154, 40)":"green")):"")
                                                            }} >
                                                                {index===3?"":item.numO}

                                                            </Typography>
                                                        </div> :
                                                        <HtmlTooltip enterDelay={500} key={item.id}
                                                                     title={<React.Fragment>
                                                                         <div>
                                                                             <Typography color="inherit"
                                                                                         className="aqa-text-action">{"In specific columns, do any cells contain values significantly outside of the range of the data contained in the other cells?"}</Typography>
                                                                         </div>

                                                                     </React.Fragment>} arrow={"true"}
                                                                     placement="bottom-start">
                                                            <div>
                                                                <Typography className="aqa-text-bold-11"
                                                                            variant="inherit" style={{
                                                                    textAlign: "right",
                                                                    color: index === 0 ? "grey" : "#181818",
                                                                    height:index===0?"16px":"5px",
                                                                    padding: index>0?"0px":"4px"
                                                                }}>
                                                                    {item.numO}
                                                                </Typography>
                                                            </div>
                                                        </HtmlTooltip>
                                                    }
                                                </TableCell>
                                                <TableCell width="12%" style={index>2?noborderStyle:borderStyle}>
                                                    {index > 0 ? <div>
                                                            <Typography className={item.numI>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                textAlign: "right",
                                                                color: index===0?"grey":(index>1?(item.numI>0?"rgb(251, 154, 40)":"#181818"):(item.numI>0?"rgb(251, 154, 40)":"#181818")),
                                                                paddingLeft: "0px",
                                                                height:index===0?"16px":"5px",
                                                                padding: index>0?"0px":"4px",
                                                                fontWeight:item.numI>0?"bold":"400",
                                                                backgroundColor: (index>2?(item.numI===0?"red":(item.numI===1?"rgb(251, 154, 40)":"green")):"")
                                                            }} >
                                                                {index===3?"":item.numI}

                                                            </Typography>
                                                        </div> :
                                                        <HtmlTooltip enterDelay={500} key={item.id}
                                                                     title={<React.Fragment>
                                                                         <div>
                                                                             <Typography color="inherit"
                                                                                         className="aqa-text-action">{"In columns with a specific set of repeated values, are there any cells that look different?"}</Typography>
                                                                         </div>

                                                                     </React.Fragment>} arrow={"true"}
                                                                     placement="bottom-start">
                                                            <div>
                                                                <Typography className="aqa-text-bold-11"
                                                                            variant="inherit" style={{
                                                                    textAlign: "right",
                                                                    color: index === 0 ? "grey" : "#181818",
                                                                    height:index===0?"16px":"5px",
                                                                    padding: index>0?"0px":"4px"
                                                                }}>
                                                                    {item.numI}
                                                                </Typography>
                                                            </div>
                                                        </HtmlTooltip>
                                                    }
                                                </TableCell>
                                                <TableCell width="12%" style={index>2?noborderStyle1:borderStyle1}>
                                                    {index > 0 ? <div>
                                                            <Typography className={item.numE>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                                textAlign: "right",
                                                                color: index===0?"grey":(index>1?(item.numE>0?"rgb(251, 154, 40)":"#181818"):(item.numE>0?"rgb(251, 154, 40)":"#181818")),
                                                                paddingLeft: "0px",
                                                                height:index===0?"16px":"5px",
                                                                padding: index>0?"0px":"4px",
                                                                fontWeight:item.numE>0?"bold":"400",
                                                                whiteSpace:"nowrap",
                                                                backgroundColor: (index>2?(item.numE===0?"red":(item.numE===1?"rgb(251, 154, 40)":"green")):"")
                                                            }} >
                                                                {index===3?"":item.numE}

                                                            </Typography>
                                                        </div> :
                                                        <HtmlTooltip enterDelay={500} key={item.id}
                                                                     title={<React.Fragment>
                                                                         <div>
                                                                             <Typography color="inherit"
                                                                                         className="aqa-text-action">{"Are there any cells that contain native excel errors?"}</Typography>
                                                                         </div>

                                                                     </React.Fragment>} arrow={"true"}
                                                                     placement="bottom-start">
                                                            <div>
                                                                <Typography className="aqa-text-bold-11"
                                                                            variant="inherit" style={{
                                                                    textAlign: "right",
                                                                    color: index === 0 ? "grey" : "#181818",
                                                                    height:index===0?"16px":"5px",
                                                                    padding: index>0?"0px":"4px 0px",
                                                                    whiteSpace:"nowrap"
                                                                }}>
                                                                    {item.numE}
                                                                </Typography>
                                                            </div>
                                                        </HtmlTooltip>
                                                    }
                                                </TableCell>

                                            </TableRow>

                                        )
                                    ))

                                )}
                            </TableBody>
                        </Table>
                    </div>
                </TableCell>
                <TableCell size="small" width="1%" style={{padding:"4px 4px 4px 4px",border:"0px"}}>

                </TableCell>

                <TableCell width="8%" style={{padding:"4px 4px 4px 4px",border:"0px"}}>
                    {
                        this.props.heatmapData
                            ?
                            <div style={{marginLeft:"8px"}}>

                                <div>
                                    <Heatmap
                                        data={this.props.heatmapData}
                                        type={Heatmap.OVERALL_HEATMAP_NAME}
                                        scale={AqaComponent.HEATMAP_STAMP_SCALE}
                                        numRows={this.state.numRows}
                                        numCols={this.state.numCols}
                                        clickerCallback = {
                                            (x, w, y, h, n, amber, red, type) =>
                                            {
                                                if (this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot) this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot.handleSnapshotFilter2
                                                (
                                                    this.props.dataFromSource,
                                                    this.props.dataFromParent,
                                                    type,
                                                    amber,
                                                    red,
                                                    [n, x, y, w, h],
                                                    null,
                                                    true
                                                )
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            :
                            (this.props.isPostLoading || this.props.dataFromParent.status==="PostLoading"?"":<img src={AqaGridIcon} width="95px" alt="grid"/>)
                    }

                </TableCell>
                <TableCell size="small" width="1%" style={{padding:"4px 4px 4px 4px",border:"0px"}}>

                </TableCell>

                <TableCell width="30%" style={{padding:"4px",border:"0px"}}>
                    <div style={{background: "none",marginTop:"0px"}}>
                        <Table id={this.props.dataFromParent!==null?this.props.dataFromParent.id+"_statstable":"_statstable"} cellSpacing={0} aria-sort="ascending" style={{}}>
                            <colgroup>
                                <col width="25%" />
                                <col width="15%" />
                                <col width="15%" />
                                <col width="15%" />
                                <col width="15%" />
                                <col width="15%" />
                            </colgroup>
                            <TableHead>

                            </TableHead>
                            <TableBody>
                                {this.props.dataForReviewStats.map((item,index)=>
                                    (index===1 || index===2)?
                                        (
                                            <TableRow key={item.id} className={"aqa-violation-folder"} style={{height:"18px"}}>
                                            <TableCell width="25%" style={{padding:"4px",border:index>2?"none":""}}>
                                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                        <div>
                                                            <Typography color="inherit"
                                                                        className="aqa-text-action">{index === 0 ? "Click to view rows" : (item.numRules > 0 && index === 1 ? ("Click to view " + item.numRules.toLocaleString() + " Red cell alerts") : (item.numRules > 0 && index === 2 ? ("Click to view "+item.numRules.toLocaleString() + " Amber cell alerts") : (item.numRules > 0 && index === 3 ? ("" + item.numRules.toLocaleString() + " cells") : "There are 0 cells")))}</Typography>
                                                        </div>

                                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                        <div>
                                                            <Typography className="aqa-text-bold-11" variant="inherit"
                                                                        style={{
                                                                            textAlign: "left",
                                                                            color: index === 0 ? "grey" : "#181818",
                                                                            height: "16px",
                                                                            padding: "4px"
                                                                        }} onClick={(e)=>this.handleReviewAspectStat(e, index,item,-1)}>
                                                                {item.name}
                                                            </Typography>
                                                        </div>
                                                    </HtmlTooltip>
                                            </TableCell>
                                                <TableCell width="15%" className={selectedStat==="Aspect_"+index+"_-1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.numRules>0?"aqa-select":"aqa-no-select")} style={{padding:"0px 4px",border:selectedStat==="Aspect_"+index+"_-1"?"2px solid cornflowerblue":""}}>
                                                    <Typography className={item.numRules>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                        textAlign: "right",
                                                        color: index===0?"grey":(index===1?(item.numRules>0?"red":"#181818"):(item.numRules>0?"rgb(251, 154, 40)":"#181818")),
                                                        paddingLeft: "0px",
                                                        padding: "0px",
                                                        height:"16px",
                                                        fontWeight:item.numRules>0?"bold":"400"
                                                    }} onClick={(e)=>this.handleReviewAspectStat(e, index,item,-1)}>
                                                        {item.numRules.toLocaleString()}

                                                    </Typography>
                                                </TableCell>
                                                <TableCell width="15%" style={{padding:"4px",border:index>2?"none":""}}>
                                                    <Typography className={item.numRules>0?"aqa-text-bold-11":"aqa-text-bold-11"} variant="inherit" style={{
                                                        textAlign: "right",
                                                        color: index===0?"grey":"grey",
                                                        paddingLeft: "4px",
                                                        padding: "4px",
                                                        height:"16px",
                                                        fontWeight:item.numRules>0?"bold":"400"
                                                    }} onClick={(e)=>this.handleReviewAspectStat(e, index,item,-1)}>
                                                        {item.percentage}

                                                    </Typography>
                                                </TableCell>
                                            <TableCell width="15%" className={selectedStat==="Aspect_"+index+"_0"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.numR>0?"aqa-select":"aqa-no-select")} style={{padding:"0px 4px",border:selectedStat==="Aspect_"+index+"_0"?"2px solid cornflowerblue":""}}>
                                                <Typography className={item.numR>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                    textAlign: "right",
                                                    color: index===0?"grey":(index===1?(item.numR>0?"red":"#181818"):(item.numR>0?"rgb(251, 154, 40)":"#181818")),
                                                    paddingLeft: "4px",
                                                    padding: "4px",
                                                    height:"16px",
                                                    fontWeight:item.numR>0?"bold":"400"
                                                }} onClick={(e)=>this.handleReviewAspectStat(e, index,item,0)}>
                                                    {item.numR.toLocaleString()}

                                                </Typography>
                                            </TableCell>
                                                <TableCell width="15%" className={selectedStat==="Aspect_"+index+"_1"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.numI>0?"aqa-select":"aqa-no-select")} style={{padding:"0px 4px",border:selectedStat==="Aspect_"+index+"_1"?"2px solid cornflowerblue":""}}>
                                                    <Typography className={item.numI>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                        textAlign: "right",
                                                        color: index===0?"grey":(index===1?(item.numI>0?"red":"#181818"):(item.numI>0?"rgb(251, 154, 40)":"#181818")),
                                                        paddingLeft: "4px",
                                                        padding: "4px",
                                                        height:"16px",
                                                        fontWeight:item.numI>0?"bold":"400"
                                                    }} onClick={(e)=>this.handleReviewAspectStat(e, index,item,1)}>
                                                        {item.numI.toLocaleString()}

                                                    </Typography>
                                                </TableCell>
                                                <TableCell width="15%" className={selectedStat==="Aspect_"+index+"_2"?(this.state.isWaiting?"aqa-select-active-waiting":"aqa-select-active"):(item.numF>0?"aqa-select":"aqa-no-select")} style={{padding:"0px 4px",border:selectedStat==="Aspect_"+index+"_2"?"2px solid cornflowerblue":""}}>
                                                    <Typography className={item.numF>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                        textAlign: "right",
                                                        color: index===0?"grey":(index===1?(item.numF>0?"red":"#181818"):(item.numF>0?"rgb(251, 154, 40)":"#181818")),
                                                        paddingLeft: "4px",
                                                        padding: "4px",
                                                        height:"16px",
                                                        fontWeight:item.numF>0?"bold":"400"
                                                    }} onClick={(e)=>this.handleReviewAspectStat(e, index,item,2)}>
                                                        {item.numF.toLocaleString()}

                                                    </Typography>
                                                </TableCell>

                                        </TableRow>)
                                        :
                                        ((index===3)?(

                                                <TableRow key={item.id} className={"aqa-violation-folder"} style={{height:"6px",cursor:showReview?"pointer":"default"}} onClick={(e)=>this.handleReviewOpen()}>
                                                    <TableCell width="25%" style={{padding:"0px 4px",border:index>2?"none":""}}>
                                                        {index===3?
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-bold-16" style={{fontSize:"14px",fontFamily:"Montserrat"}}>{showReview?"Review Completed: Click to see preview/share":"Review progress – this status bar shows you the progress of your review of the alerts shown against your data upload"} </Typography>
                                                                </div>

                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <Typography className="aqa-text-bold-11" variant="inherit" style={{
                                                                textAlign: "left",
                                                                color: index===0?"grey":"#181818",
                                                                height:"8px",
                                                                padding:"0px 4px",
                                                                marginTop:"-4px",
                                                            whiteSpace:"nowrap"
                                                            }} >
                                                                {item.name}
                                                                </Typography>
                                                            </HtmlTooltip>:
                                                            <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                <div>
                                                                    <Typography color="inherit" className="aqa-text-action">{item.numR>0?""+item.numR.toLocaleString()+" rows":"There are 0 rows"}</Typography>
                                                                </div>

                                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                                <div>
                                                                    <Typography className="aqa-text-bold-11" variant="inherit" style={{
                                                                        textAlign: "left",
                                                                        color: index===0?"grey":"#181818",
                                                                        height:"16px",
                                                                        padding:"4px"
                                                                    }} >
                                                                        {item.name}
                                                                    </Typography>
                                                                </div>
                                                            </HtmlTooltip>}
                                                    </TableCell>
                                                    <TableCell width={"75%"} colSpan={5} style={{padding:"4px",border:index>2?"none":""}}>
                                                        <Typography className={item.numRules>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                            textAlign: "right",
                                                            color: index===0?"grey":(index===1?(item.numRules>0?"red":"#181818"):(item.numRules>0?"rgb(251, 154, 40)":"#181818")),
                                                            paddingLeft: "4px",
                                                            padding: "4px",
                                                            height:"5px",
                                                            fontWeight:item.numRules>0?"bold":"400"
                                                        }}>

                                                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                                    <div>
                                                                        <Typography color="inherit" className="aqa-text-bold-16" style={{fontSize:"14px",fontFamily:"Montserrat"}}>{showReview?"Review Completed: Click to see preview/share":"Review progress – this status bar shows you the progress of your review of the alerts shown against your data upload"} </Typography>
                                                                    </div>
                                                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                            <div>
                                                                <LinearProgress variant="determinate" value={(100 - ((item.numR/item.numRules)*100))} style={{height:"5px"}}/>
                                                            </div>
                                                                </HtmlTooltip>

                                                        </Typography>
                                                    </TableCell>

                                                </TableRow>
                                            ):
                                    (

                                        <TableRow key={item.id} className={"aqa-violation-folder"} style={{height:"18px"}} >
                                            <TableCell width="25%" style={{padding:"4px",border:index>2?"none":""}}>
                                                {index===0?<Typography className="aqa-text-bold-11" variant="inherit" style={{
                                                    textAlign: "left",
                                                    color: index===0?"grey":"#181818",
                                                    height:"16px",
                                                    padding:"4px",
                                                    whiteSpace:"nowrap"
                                                }} onClick={(e)=>this.handleReviewAspectStat(e, index,item,-1)}>
                                                    {item.name}
                                                </Typography>:
                                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                                    <div>
                                                        <Typography color="inherit" className="aqa-text-action">{item.numR>0?""+item.numR.toLocaleString()+" rows":"There are 0 rows"}</Typography>
                                                    </div>

                                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                                    <div>
                                                <Typography className="aqa-text-bold-11" variant="inherit" style={{
                                                    textAlign: "left",
                                                    color: index===0?"grey":"#181818",
                                                    height:"16px",
                                                    padding:"4px",
                                                    whiteSpace:"nowrap"
                                                }} onClick={(e)=>this.handleReviewAspectStat(e, index,item,-1)}>
                                                    {item.name}
                                                </Typography>
                                                    </div>
                                                </HtmlTooltip>}
                                            </TableCell>
                                            <TableCell width="15%" style={{padding:"4px",border:selectedStat==="Aspect_0_-1"?"2px solid cornflowerblue":""}}>
                                                <Typography className={item.numRules>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                    textAlign: "right",
                                                    color: index===0?"grey":(index===1?(item.numRules>0?"red":"#181818"):(item.numRules>0?"rgb(251, 154, 40)":"#181818")),
                                                    paddingLeft: "4px",
                                                    padding: "4px",
                                                    height:"16px",
                                                    fontWeight:item.numRules>0?"bold":"400"
                                                }} onClick={(e)=>this.handleReviewAspectStat(e, index,item,-1)}>
                                                    {item.numRules.toLocaleString()}

                                                </Typography>
                                            </TableCell>
                                            <TableCell width="15%" style={{padding:"4px",border:index>2?"none":""}}>
                                                <Typography className={item.numRules>0?"aqa-text-bold-11":"aqa-text-bold-11"} variant="inherit" style={{
                                                    textAlign: "right",
                                                    color: index===0?"grey":"grey",
                                                    paddingLeft: "4px",
                                                    padding: "4px",
                                                    height:"16px",
                                                    fontWeight:item.numRules>0?"bold":"400"
                                                }} onClick={(e)=>this.handleReviewAspectStat(e, index,item,-1)}>
                                                    {item.percentage}

                                                </Typography>
                                            </TableCell>
                                            <TableCell width="15%" style={{padding:"4px",border:selectedStat==="Aspect_0_0"?"2px solid cornflowerblue":""}}>
                                                <Typography className={item.numR>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                    textAlign: "right",
                                                    color: index===0?"grey":(index===1?(item.numR>0?"red":"#181818"):(item.numR>0?"rgb(251, 154, 40)":"#181818")),
                                                    paddingLeft: "4px",
                                                    padding: "4px",
                                                    height:"16px",
                                                    fontWeight:item.numR>0?"bold":"400"
                                                }} onClick={(e)=>this.handleReviewAspectStat(e, index,item,0)}>
                                                    {item.numR.toLocaleString()}

                                                </Typography>
                                            </TableCell>
                                            <TableCell width="15%" style={{padding:"4px",border:selectedStat==="Aspect_0_1"?"2px solid cornflowerblue":""}}>
                                                <Typography className={item.numI>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                    textAlign: "right",
                                                    color: index===0?"grey":(index===1?(item.numI>0?"red":"#181818"):(item.numI>0?"rgb(251, 154, 40)":"#181818")),
                                                    paddingLeft: "4px",
                                                    padding: "4px",
                                                    height:"16px",
                                                    fontWeight:item.numI>0?"bold":"400"
                                                }} onClick={(e)=>this.handleReviewAspectStat(e, index,item,1)}>
                                                    {item.numI.toLocaleString()}

                                                </Typography>
                                            </TableCell>
                                            <TableCell width="15%" style={{padding:"4px",border:selectedStat==="Aspect_0_2"?"2px solid cornflowerblue":""}}>
                                                <Typography className={item.numF>0?"aqa-text-bold-14":"aqa-text-bold-11"} variant="inherit" style={{
                                                    textAlign: "right",
                                                    color: index===0?"grey":(index===1?(item.numF>0?"red":"#181818"):(item.numF>0?"rgb(251, 154, 40)":"#181818")),
                                                    paddingLeft: "4px",
                                                    padding: "4px",
                                                    height:"16px",
                                                    fontWeight:item.numF>0?"bold":"400"
                                                }} onClick={(e)=>this.handleReviewAspectStat(e, index,item,2)}>
                                                    {item.numF.toLocaleString()}

                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                        )
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </TableCell>
                {/*<TableCell width="10%" style={{padding:"4px 20px 4px 20px",border:"0px"}}>
                    {this.props.dataFromParent === null || this.props.dataFromParent.status === "Pending"?(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <Typography color="inherit" className="aqa-text-action">This Data Upload is under review</Typography>

                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <span>
                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                textAlign: "left",
                                                paddingLeft: "4px",
                                                marginTop: "-8px",
                                                marginBottom: "4px",
                                                width:"100px",
                                                color:"darkgrey"
                                            }}>
                                                Review Status:
                                            </Typography>
                                        <span className="aqa-button" style={{padding:"0px"}}>

                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                textAlign: "left",
                                                paddingLeft: "4px",
                                                marginTop: "4px",
                                                width:"140px"
                                            }}>
                                                {this.props.dataFromParent === null?"Under Review":(this.props.dataFromParent.status==="Pending"?"Under Review":(this.props.dataFromParent.status==="Approved"?"Review Completed":(this.props.dataFromParent.status==="Rejected"?"Shared with Team":this.props.dataFromParent.status)))}
                                            </Typography>
                                        </span>
                                        </span>
                                    </HtmlTooltip>
                                    ):
                                    (
                                        <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                            {this.props.dataFromParent.status === "Approved" ?(
                                                <div>
                                                    <Typography color="inherit" className="aqa-text-action">Completed By: {this.props.dataFromParent.statusChangedBy}</Typography>
                                                    <Typography color="inherit" className="aqa-text-action">Completed On: {(this.props.dataFromParent.statusChangedDate !== null)? (moment(this.props.dataFromParent.statusChangedDate ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                                </div>
                                            ):(this.props.dataFromParent.status === "Rejected"?(
                                                <div>
                                                    <Typography color="inherit" className="aqa-text-action">Shared By: {this.props.dataFromParent.statusChangedBy}</Typography>
                                                    <Typography color="inherit" className="aqa-text-action">Shared On: {(this.props.dataFromParent.statusChangedDate !== null)? (moment(this.props.dataFromParent.statusChangedDate ).format("YYYY/MM/DD HH:mm")):("")}</Typography>
                                                </div>
                                            ):(
                                                <Typography color="inherit"></Typography>
                                            ))

                                            }

                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                            <span>
                                            <Typography className="aqa-text-action" variant="inherit" style={{
                                                textAlign: "left",
                                                paddingLeft: "4px",
                                                marginTop: "-8px",
                                                marginBottom: "4px",
                                                width:"100px",
                                                color:"darkgrey"
                                            }}>
                                                Review Status:
                                            </Typography>
                                            <span className="aqa-button" style={{padding:"0px"}}>


                                                <Typography className="aqa-text-action" variant="inherit" style={{
                                                    textAlign: "left",
                                                    paddingLeft: "4px",
                                                    marginTop: "4px",
                                                    width:"140px"
                                                }}>
                                                    {this.props.dataFromParent === null?"Under Review":(this.props.dataFromParent.status==="Pending"?"Under Review":(this.props.dataFromParent.status==="Approved"?"Review Completed":(this.props.dataFromParent.status==="Rejected"?"Shared with Team":this.props.dataFromParent.status)))}
                                                </Typography>
                                            </span>
                                            </span>
                                        </HtmlTooltip>
                                    )}
                        
                </TableCell>
                <TableCell width="5%" style={{padding:"4px 20px 4px 20px",border:"0px"}}>
                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                        <Typography color="inherit" className="aqa-text-action">Usage Stats - 1k per block</Typography>

                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                    <span>
                                        <Typography className="aqa-text-action" variant="inherit" style={{
                                            textAlign: "right",
                                            paddingLeft: "4px",
                                            marginTop: "-4px",
                                            width:"82px",
                                            color:"darkgrey"
                                        }}>
                                        Blocks Used:
                                    </Typography>
                                    <span className="aqa-button" style={{padding:"0px"}}>

                                        <Typography className="aqa-text-action" variant="inherit" style={{
                                            textAlign: "right",
                                            paddingLeft: "4px",
                                            marginTop: "4px",
                                            width:"82px"
                                        }}>

                                            {this.props.dataFromSource.example===true || this.props.dataForStats.length===0?"N/A":this.props.controller.findUsageStat(this.props.dataFromSource.id)}
                                        </Typography>
                                    </span>
                                    </span>
                    </HtmlTooltip>
                </TableCell>*/}

            </TableRow>
                </TableBody>
            </Table>
        );

    }
}

AqaSnapshotHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSnapshotHeader);

/*

                    { this.props.dataForMap === null?
                        <img src={AqaGridIcon} width="95px" alt="grid"/>
                        :
                        (
                            <div style={{marginLeft:"8px"}}>
                                {this.props.dataForMap!== undefined?this.props.dataForMap.handleRenderer(this.props.dataFromRoot.props.dataFromParent.props.dataFromRoot,this.props.dataFromSource,this.props.dataFromParent) :""}
                                {this.props.dataForMap!== undefined?this.props.dataForMap.render():""}
                            </div>
                        )
                     }
*/



