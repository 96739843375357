


import React from 'react';
import AqaComponent  from "./shared/aqacomponent/AqaComponent"

//import {CardElement} from '@stripe/react-stripe-js' // ??


import AqaStripeSubscriptionForm from "./stripe/AqaStripeSubscriptionForm"





export default class AqaTestStripe extends AqaComponent
{


	constructor(props)
	{
		super(props);
		
		this.state =
		{
			message:"Look for system messages here",
			submitButtonEnabled:true,
			planId:"",
			plans:[],
			nu:0,
			ns:0,
			currency:"GBP",
			quote:"[No quote retrieved yet]",
			subscriptionResponse:"",
			subscriptionClientSecret:null,

			// Used to present a wait screen while we clear any existing (and interrupted) payment attempt
			ready:false
		};
		
		
	} //

/*
	handleCheckout(event)
	{
		event.preventDefault();
		this.setState({submitButtonEnabled:false});
		

// Here we should enable / disable the submit button


this.setState({message:"doing Stripe intent"});


		AqaComponent.stripeBackend.createPaymentIntentUsingPOST
		(
			{},
			(error, intentResponseData, response) =>
			{
				if (error)			
				{
this.setState({message:"Stripe intent has an error"});
					console.error("Stripe Intent Rquest Error occurred:");
					console.error(AqaComponent.prettifyJson(error));
					this.setState({submitButtonEnabled:true});

				}
				else
				{
					this.setState({message:"Stripe looks like it has worked"});

onsole.log("Stripe Intent Request response:");
onsole.log(AqaComponent.prettifyJson(intentResponseData));




// "clientSecret":"pi_1IpzzyCMuU0SfJubyzTl97O0_secret_rvClThQ....etc."

					this.setState({message:"Confirming"});


					const {stripe, elements} = this.props;


//   const payload = await
   
   
   
   				stripe.confirmCardPayment
   				(
   					intentResponseData.clientSecret,
   					{
							payment_method:
							{
								card: elements.getElement(CardElement)
							}
						}
					).then
					(
						confirmationData =>
						{
// this.setState({message:"Confirmation message received"});

							if (confirmationData.error)
							{
								this.setState({message:"Confirmation failed"});
								console.error(AqaComponent.prettifyJson(confirmationData.error));
							}
							else
							{
								this.setState({message:"Confirmation succeded"});
								onsole.log(AqaComponent.prettifyJson(confirmationData));
							}
						}
					).catch
					(
						ex =>
						{
							this.setState({message:"Confirmation encountered an error"});
							console.error("Exception: " + ex);
						}
					);
				}

			}
		)





	}; // handleCheckout
*/

	getProduct(productPersistentId)
	{

		AqaComponent.backend.productUsingGET
		(
			productPersistentId,
			(error, data, response) =>
			{
				if (error) alert("Error getting the product: " + productPersistentId + ": " + error);
				else this.setState({[`${productPersistentId}Product`]:AqaComponent.prettifyJson(data)});
			}	
		);	

	
	} // productPersistentId


	makePriceRequest()
	{
		/*
		paymentFrequency;
		currency;
		planId;
		numberOfEditors;
		numberOfSources;
		*/
		return ({
			paymentFrequency:"Monthly", // Not used for now
			numberOfEditors:this.state.nu,
			numberOfSources:this.state.ns,
			currency:this.state.currency,
			planId:this.state.planId
		});
	} // makePriceRequest




	updateQuote(e)
	{
	
// onsole.log("updating: [" + e.target.name + ":" + e.target.value + "]");

		
		
// onsole.log("Price request: ");


	
	
		this.setState
		(
			{[e.target.name]:e.target.value},
			() =>
			{
				// Why has that piece of shit swagger decided that planId and paymentFrequency were less optional that other parameters?!?!?!?!?
				const pr = this.makePriceRequest();
				
				
// onsole.log(AqaComponent.prettifyJson(pr));
				
				AqaComponent.backend.priceUsingGET
				(
					pr.planId,
					pr.paymentFrequency,
					pr,
					(error, data, response) =>
					{
						if (error) alert("Error getting a quote: " + error);
						else this.setState({quote:AqaComponent.prettifyJson(data)});
					}	
				);	

			}
		);
	
	
	} // updateQuote

	getStripeSubscription()
	{


//		AqaComponent.backend.createStripeSubscriptionUsingPOST
		
		AqaComponent.accountBackend.updateSubscriptionUsingPUT
		(
			this.makePriceRequest(),
			(error, data, response) =>
			{
				if (error)
				{
					alert("System error while establishing subscription: " + error);
					return;
				}
				
				/*
				if (!data.successful)
				{
					alert("There was an error while setting up the subscription: " + data.message);
					return;	
				}
				*/

				// proceed
				
				switch(data.status)
				{
				case "needsStripe":

					this.setState
					(
						{
							subscriptionResponse:AqaComponent.prettifyJson(data),
							subscriptionClientSecret:data.stripeSecret
						}
					);
					break;
				case "ok":
					alert("Your changes have been requested, they will be applied shortly as soon as verifications have been carried out");
					break;

				case "error":
					alert("An error occurred:\n" + data["message"]);
					break;
				default:
					break;
				}
			}	
		);

	} // getStripeSubscription


	componentDidMount()
	{
	
// onsole.log("Mounting stripe test");
		this.loadStripe
		(
			() =>
			{
				AqaComponent.backend.clearInterruptedPurchaseUsingPUT
				(
					(error, plans, response) =>
					{
						if (error) console.error("Error checking state: " + error);		
						else AqaComponent.backend.getPlansUsingGET
						(
							(error, plans, response) =>
							{
								if (error) console.error("Error getting plans: " + error);
								else
								{
// onsole.log("Plans:");
// onsole.log(AqaComponent.prettifyJson(plans));

									AqaComponent.accountBackend.getAccountPlanDetailsUsingGET
									(
										(error, data, reponse) =>
										{

// onsole.log("Plan details:");
// onsole.log(AqaComponent.prettifyJson(data));

											if (error) console.error("Getting plan details brought back an error: " + error);
											else
											{
												this.setState
												(
													{
														nu:data.numberOfPurchasedEditors,
														ns:data.numberOfPurchasedSources,
														plans,
														ready:true
													}
												);
											}
										}
									);
								}
							}
						);

					}

				);
			}
		);

	} // componentDidMount()


	render()
	{

// const PRICE_LOOKUP_KEY = "some_id";
// xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"


		const {stripe, elements} = this.props;

// onChange={e => {this.setState({currency:e.target.value})}} 


// onsole.log("RENDER. PLANS:");
// onsole.log(AqaComponent.prettifyJson(this.state.plans));
// e => this.setState({planId: e.target.value})

		return (

			<div>
				<div>TESTING STRIPE</div>
			
				{
					this.state.ready
					?
			
			
						<div>
							This is where we'll stripe.

							<pre>

							Bad, doesn't exist:      1234567891234563{"\n"}
							US, no authorisation:    4242424242424242{"\n"}
							Authentication required: 4000002760003184{"\n"}
							{"\n"}
							</pre>


							<hr />
							Plan:{" "}
							<select name="planId" onChange={e => this.updateQuote(e)} value={this.state.planId}>
								<option></option>
								{this.state.plans.map(p => <option value={p.id} key={p.id}>{p.name}</option>)}
							</select><br />

							Editors: <input type="text" name="nu" onChange={e => this.updateQuote(e)} value={this.state.nu} /><br />
							Sources: <input type="text" name="ns" onChange={e => this.updateQuote(e)} value={this.state.ns} /><br />
							<select name="currency" value={this.state.currency} onChange={e => this.updateQuote(e)}>
								<option>GBP</option>
								<option>EUR</option>
								<option>USD</option>
							</select>

							<div style={{border:"1px solid #000", margin:"5px", padding:"4px"}}>{this.state.quote}</div>

							<hr />

							<input type="button" onClick={() => this.getStripeSubscription()} value="PROCEED" />
							<div style={{margin:"5px", padding:"4px", border:"1px dashed red"}}><pre>{this.state.subscriptionResponse}</pre></div>

							{
								this.state.subscriptionClientSecret !== null
								?
									<AqaStripeSubscriptionForm
										clientSecret={this.state.subscriptionClientSecret}
										stripe={stripe}
										elements={elements}
									/>
								:
									null
							}


							<hr />
							<input type="button" onClick={() => this.getProduct("user")} value="Get user" />
							<div><pre>{this.state.userProduct}</pre></div>

							<hr />
							<input type="button" onClick={() => this.getProduct("source")} value="Get source" />
							<div><pre>{this.state.sourceProduct}</pre></div>
						</div>
					:
						<div>Please wait while we check your account</div>
				}
			</div>
		
		);



	} // render




} ////



// Test cheap       prod_KwEuXD6wifSYdt  $ price_1KGMQzCMuU0SfJubAyO6k5FG  £ price_1KGMQzCMuU0SfJubDfgDYY8a
// Test reasonable  prod_KwEwhyMyHXe70H  $ price_1KGMT1CMuU0SfJubVIeTfVAA  £ price_1KGMT1CMuU0SfJubEh2gLtKg
// Test dear        prod_KwExOpYHDw1Avq  $ price_1KGMU1CMuU0SfJubsb8GWiLY  £ price_1KGMU1CMuU0SfJub3xK1ZTYP







// https://stripe.com/docs/stripe-js/react


// https://stripe.com/docs/payments/integration-builder


// With classes:
// https://github.com/stripe/react-stripe-js




