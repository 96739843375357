import React, {Component} from 'react'
//import PropTypes from "prop-types";


const HEIGHT = 70;


const FILL = // no green because we can't be bothered to draw green zones
{
	red:   "#fc5834",
	amber: "#fb9a28"
};

class AqaRulesGraph extends Component
{
	constructor(props)
	{
		super(props);

		this.state =
			{
				isLoaded: false, // this is most likely redundant
		};

	} //

	handleMouseOver = (e,text,val,leftstyle)=>{
		let left = leftstyle.substring(0,leftstyle.length-1);
		left = (parseFloat(left)-3);
		if(left>90) left = left-5;
		let tooltip = document.getElementById("aqa_tooltip");
		tooltip.innerHTML = text+": "+val +" records";
		tooltip.style.display = "block";
		tooltip.style.left = left+"%";
		tooltip.style.top =  80 + 'px';
	}

	handleMouseOut = (e)=>{
		var tooltip = document.getElementById("aqa_tooltip");
		tooltip.style.display = "none";
	}

	renderDots = ()=>{
		let rects=[];
		if(this.props.marks === undefined) return;
		let rangeSlider = document.getElementById("aqa_slider");
		if(rangeSlider === null) return;
		let sliderDivs = rangeSlider.querySelectorAll(".rc-slider-mark-text");
		let height = 70;
		let total = 0;
		let ms = Object.keys(this.props.marks);
		for(let k=0;k<ms.length;k++) total = total+this.props.marks[ms[k]];
		for(let i=0;i<sliderDivs.length;i++){
			//console.log(sliderDivs[i].style.left);
			let leftStyle=sliderDivs[i].style.left;
			let text = sliderDivs[i].textContent;
			let val = this.props.marks[parseInt(text)];
			height = (val/total)*60;
			rects[i]=<rect key={"sliderval"+i} width="20px" height={height>0?height+5:0} x={leftStyle} y={0} style={{transform:"translateX(-10px)",left:leftStyle,stroke:"#fff",fill:"#04A6FC"}} onMouseOver={(e)=>this.handleMouseOver(e,text,val,leftStyle)} onMouseOut={(e)=>this.handleMouseOut(e)} />
		}
		return rects;
	}

	renderTexts = ()=>{
		let texts=[];
		if(this.props.marks === undefined) return;
		let rangeSlider = document.getElementById("aqa_slider");
		if(rangeSlider === null) return;
		let sliderDivs = rangeSlider.querySelectorAll(".rc-slider-mark-text");
		let height = 60;
		let total = 0;
		let ms = Object.keys(this.props.marks);
		for(let k=0;k<ms.length;k++) total = total+this.props.marks[ms[k]];
		for(let i=0;i<sliderDivs.length;i++){
			//console.log(sliderDivs[i].style.left);
			let leftStyle=sliderDivs[i].style.left;
			let text = sliderDivs[i].textContent;
			let val = this.props.marks[parseInt(text)];
			height = (val/total)*60;
			texts[i]=<text key={"slidervaltext"+i} width="20px" height={height>0?height+5:0} x={leftStyle} y={65-height} style={{transform:"translateX(-5px)",left:leftStyle,fill:"black",fontSize:"10px",fontFamily:"Montserrat"}} >{val>0?val:""}</text>
		}
		return texts;
	}

	componentDidMount() {
		setTimeout(()=>this.setState({isLoaded:true}),1000);
	}

	render()
	{

		const {isLoaded} =this.state;

		if(!isLoaded){
			return (
				<svg width={this.props.width} height={this.props.height} style={{position: "absolute", opacity: 0.5,zIndex:1099}}>
					<g>
						{
							this.props.zones.map
							(
								(z, i) => z.colour === "green" ? null : <rect key={`gz${i}`} x={z.limits[0]} y={0} width={z.limits[1] - z.limits[0]} height={HEIGHT} fill={FILL[z.colour]} stroke="#333333" />
							)
						}
					</g>
				</svg>
			);
		}

// Useful debugging. Keep.
// onsole.log("Rendering AqaRulesGraph");
// for(const z of this.props.zones) onsole.log("---- " + JSON.stringify(z));


		// Note: We're skipping green zone rendering but we might want to reconsider that if we decide to render green as actually green.
	else {
			return (
				<svg width={this.props.width} height={this.props.height} style={{position: "absolute", opacity: 0.5,zIndex:1099}}>
					<g>
						{
							this.props.zones.map
							(
								(z, i) => z.colour === "green" ? null :
									<rect key={`gz${i}`} x={z.limits[0]} y={0} width={z.limits[1] - z.limits[0]}
										  height={HEIGHT} fill={FILL[z.colour]} stroke="#333333"/>
							)
						}
					</g>
					<g style={{transform: "rotateX(180deg) translateY(-70px)"}}>
						{
							this.renderDots()
						}
					</g>
					<g>
						{
							this.renderTexts()
						}
					</g>
				</svg>
			);
		}

	} // render


} ////

/*AqaRulesGraph.propTypes = {
	//classes: PropTypes.object.isRequired,
};*/

export default AqaRulesGraph;



