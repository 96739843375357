/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import MsContext from "../shared/masterservant/MsContext"
import AqaComponent from "../shared/aqacomponent/AqaComponent"
import {ReactComponent as LoadingIcon} from "../../images/grid.svg"
/*import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg"
import DialogContent from "@material-ui/core/DialogContent"*/
import {/*FormControlLabel, Radio, RadioGroup,*/ Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core"

//import AqaStripePaymentMethodForm from "../stripe/AqaStripePaymentMethodForm"


//import {Elements, ElementsConsumer} from '@stripe/react-stripe-js'


//import AqaPaymentMethod from "./AqaPaymentMethod"
//import moment from "moment";
//import AqaAccountUserListRow from "./AqaAccountUserListRow";
import AqaAccountPMListRow from "./AqaAccountPMListRow";


const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaAccountPM extends AqaComponent
{

	// No, seriously, we need this.
	static contextType = MsContext;
	constructor(props)
	{
		super(props);
		this.state =
		{
			error: null,
			notfound: false,
			isLoaded: true,
			dense: false,
			secondary: true,
			redirect: false,
			currentFolder:"account",
			selectedFolders:[],
			resetCheckbox:false,
			folders: [],
			tabvalue:0,
			paymentMethod:"Credit Card",

			showAddPaymentMetodButton: true,
			stripePromise:null,
			paymentMethodList:[],
			showAddPaymentForm:false,
			showWaitForPaymentMethodChange:false
		};
		this.handlePaymentPopupOpen=this.handlePaymentPopupOpen.bind(this);
		this.handlePopupClose=this.handlePopupClose.bind(this);
		this.handleAccountDetails=this.handleAccountDetails.bind(this);
		this.handlePaymentMethods=this.handlePaymentMethods.bind(this);
	} //

	handleRadioChange = (event) =>
	{
		this.setState({[event.target.name]: event.target.value});
	};

    handlePaymentPopupOpen(e){
        e.preventDefault();
        this.setState({openPaymentMethod:true});
    }

    handlePaymentMethod(e){
        e.preventDefault();
        this.handleAccountUpdate(this.state.accountObj);
    }

    handlePopupClose(e){
        e.preventDefault();
        this.setState({openCancelSubscription:false,openPaymentMethod:false,openEmailEditor:false});
    }

    handleAccountDetails(){
//        var accountId=this.props.dataFromRoot.props.dataFromParent.props.parent.state.accountId;
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error, data, response) =>
            {
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountPM.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    this.setState({accountObj:accountObj,paymentMethod:accountObj.paymentMethod});
                }
            }
        );
    }

	handleAccountUpdate(account)
	{
		var accountToUpdate =
		{
			"currentPlanId": account.currentPlanId,
			"id": account.id,
			"name":account.name,
			"numberOfEditors": account.numberOfEditors,
			"numberOfSnapshots": account.numberOfSnapshots,
			"numberOfSources": account.numberOfSources,
			"numberOfViewers": account.numberOfViewers,
			"paymentMethod": this.state.paymentMethod,
			"paymentLength": account.paymentLength,
			"city": account.city,
			"companyName": account.companyName,
			"country": account.country,
			"firstName": account.firstName,
			"lastName": account.lastName,
			"phoneNumber": account.phoneNumber,
			"postcode": account.postcode,
			"state": account.state,
			"streetAddress": account.streetAddress,
			"suite": account.suite,
			"defaultAdmin":account.defaultAdmin,
			"defaultEmail":account.defaultEmail
		};
		AqaComponent.accountBackend.updateAccountUsingPUT
		(
			accountToUpdate,
			(error, data, response) =>
			{
				if (error) this.reportError("A problem occured when updating account.", "AqaAccountPM.handleAccountUpdate, call: " + error,this);
				else  this.setState({openPaymentMethod: false,openEmailEditor:false});
			}
		);
	} // handleAccountUpdate
	
	
	
	
	
	handleDeletePaymentMethod = (which) =>
	{

// alert("Deleting PM [" + this.state.paymentMethodList[which].id + "]");

		if (this.state.showWaitForPaymentMethodChange) return;

//		const which = e.target.value;

		// Change in back end and reload PMs
		this.setState
		(
			{showWaitForPaymentMethodChange:true},
			() =>
			{
				AqaComponent.backend.deletePaymentMethodUsingDELETE
				(
					this.state.paymentMethodList[which].id,
					(error, paymentMethodList, response) =>
					{
						//let removePleaseWaitMessage = false;
						if (error)
						{
							this.reportError("Sorry an error occurred, please try again later", error);
							this.setState({showWaitForPaymentMethodChange:false});
						}
						else
						{
							// It succeeded, we display the new payment methods.
							this.setState({paymentMethodList, showWaitForPaymentMethodChange: false});
						}
					}
				);
			}
		);






	}; // handleDeletePaymentMethod
	
	
	
	handlePaymentMethodRadio = e =>
	{
		if (this.state.showWaitForPaymentMethodChange) return;

		const which = e.target.value;

		// Change in back end and reload PMs
		this.setState
		(
			{showWaitForPaymentMethodChange:true},
			() =>
			{
				AqaComponent.backend.selectDefaultPaymentMethodUsingPUT
				(
					this.state.paymentMethodList[which].id,
					(error, paymentMethodList, response) =>
					{
						//let removePleaseWaitMessage = false;
						if (error)
						{
							this.reportError("Sorry an error occurred, please try again later", error);
							this.setState({showWaitForPaymentMethodChange:false});
						}
						else
						{
							// It succeeded, we display the new payment methods.
							this.setState({paymentMethodList, showWaitForPaymentMethodChange: false});
						}
					}
				);
			}
		);


/*
		// TODO: PROPER CHANGE OF DEFAULT PAYMENT BY ASKING THE BACKEND!! (This is just FE simulation)

		const n = this.state.paymentMethodList === null ? 0 : this.state.paymentMethodList.length;
		const which = e.target.value;

onsole.log("RECEIVED RADIO SIGNAL: [" + which + "]");

		for(let i = 0; i < n; i++) if (this.state.paymentMethodList[i].default)
		{
			this.state.paymentMethodList[i].default = false;
			break;
		}

		if (which >= 0 && which < n) this.state.paymentMethodList[which].default = true;
		this.forceUpdate();
*/

	}; // handlePaymentMethodRadio



	


	setupAddPayment(clientSecret)
	{


// onsole.log("Secret: " + clientSecret);

// this.setState({stripePromise


		const options =
		{
			clientSecret: `{{${clientSecret}}}`,
			// Fully customizable with appearance API.
			appearance: {/*...*/},
		};

		// Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
//		const elements = stripe.elements(options);

const elements = this.state.stripePromise.elements(options);


		// Create and mount the Payment Element
		const paymentElement = elements.create('payment');
		paymentElement.mount('#payment-element');
	
	
	} // setupAddPayment

/*
	handleAddPaymentMethod = () =>
	{
		this.setState
		(
			{showAddPaymentForm: true},
			() =>
			{
				AqaComponent.backend.createPaymentMethodIntentUsingPOST
				(
					(error, data, response) =>
					{
						if (error) this.reportError("Sorry an error occurred, please try again later", error);
						else
						{
							if (!data.success) alert("An error occured: " + data.message);
							else this.setupAddPayment(data["message"]);
						}
					}
				);

			}
		);

	}; // handleAddPaymentMethod
*/


	handleAddPaymentMethod = () =>
	{
		// All good things come to those who wait!
		
		this.setState
		(
			{showAddPaymentMetodButton: false},
			() => AqaComponent.backend.createPaymentMethodIntentUsingPOST
			(
				(error, data, response) =>
				{
					if (error) this.reportError("Sorry an error occurred, please try again later", error);
					else
					{
						if (!data.success) alert("An error occured: " + data.message);
						else this.setState({showAddPaymentForm: true, setupIntentClientSecret:data["message"]});
						

					}
				}
			)
		);


	}; // handleAddPaymentMethod


	handlePaymentMethods(){
		AqaComponent.backend.getPaymentMethodsUsingGET
		(
			(error, paymentMethodList, response) =>
			{
				if (error) this.reportError("A problem occured when retrieving payment methods.", "AqaAccountPM.componentDidMount(): " + error,this);
				else this.setState({paymentMethodList});
			}
		);
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		this.handlePaymentMethods();
	}

	componentDidMount()
	{
		this.handleAccountDetails();

		AqaComponent.backend.getPaymentMethodsUsingGET
		(
			(error, paymentMethodList, response) =>
			{
				if (error) this.reportError("A problem occured when retrieving payment methods.", "AqaAccountPM.componentDidMount(): " + error,this);
				else this.setState({paymentMethodList});
			}
		);

	} // componentDidMount


	render()
	{
	
	
//const {stripe, elements} = this.props;
	
	
		this.show = this.context.show;
		//const { classes } = this.props;
		const { error, isLoaded, redirect, notfound } = this.state;
		if (redirect)
		{
			//return <Redirect to="/login" push={true} />
		}
		else if (notfound && error)
		{
			return (
				<div className={"source-detail-servant-pane"}>
					<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

						<Grid item xs={10} sm={7} lg={11} xl={11}>
							<Typography
								variant="title"
								color="inherit"
								 align="left"
								style={{
									fontSize: '1.5rem',
									padding: '0.1em 0.5em',
									fontFamily: 'Open Sans, sans-serif'
								}}
							>
								Nothing to display
							</Typography>
						</Grid>

						<Grid item xs={2} sm={1} lg={1} xl={1}></Grid>
					</Grid>
				</div>
			);
		}
		else if (error && !notfound)
		{
			return <div>
				<Typography variant="title" color="inherit" align="left" style={{ fontSize: '1.5rem', padding: '3.1em 0.5em', fontFamily: 'Open Sans, sans-serif'}}>Error: {error.message}</Typography>
			</div>;
		}
		else if (!isLoaded)
		{
			return (
				<div className={"aqa-loader"}>
					<div className={"aqa-loading-icon"}>
						<LoadingIcon fill={"#4cadc4"}/>
					</div>
				</div>
			);
		}
		else
		{

			let pms = this.state.paymentMethodList;
			if (!pms) pms = [];

			return (
				<div className={"source-detail-servant-pane-one"} style={{background: "#FFF 0% 0% no-repeat padding-box"}} >
					<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0,marginLeft:"0px"}}>
						<Grid item xs={12} sm={12} lg={12} xl={12}>
							{/*<Typography className={"aqa-main-header-text"} >
								Payment Methods
							</Typography>*/}
							<div style={{}}>
								<Table id={"_pmstable"} cellSpacing={0} aria-sort="ascending" style={{}}>
									<colgroup>
										<col width="4%" />
										<col width="4%" />
										<col width="30%" />
										<col width="20%" />
										<col width="20%" />
										<col width="20%" />

									</colgroup>
									<TableHead>
										<TableRow style={{height:"28px"}}>
											<TableCell width="4%" style={{"padding":"0px 0px 0px 4px"}}><div className="aqa-text-bold-12"> </div></TableCell>
											<TableCell width="4%" style={{"padding":"0px 0px 0px 4px"}}><div className="aqa-text-bold-12"> </div></TableCell>
											<TableCell width="30%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Card Number</div></TableCell>
											<TableCell width="20%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Brand</div></TableCell>
											<TableCell width="20%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Expires</div></TableCell>
											<TableCell width="20%" style={{"padding":"0px"}}><div className="aqa-text-bold-12">Current</div></TableCell>

										</TableRow>
									</TableHead>
									<TableBody>
										{pms.map(item =>(
											<AqaAccountPMListRow key={item.id} item={item} dataFromMain={this} dataFromParent={item} dataForCheckbox={this.props.dataFromParent.state.resetCheckbox} dataForAccount={this.state.account}/>

										))}
									</TableBody>
								</Table>
							</div>

						</Grid>


					</Grid>
				</div>
			);
			

		}

	} // render
    
} ////

AqaAccountPM.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaAccountPM);

														/*<form id="payment-form">
															<div id="payment-element">
																{/ * Elements will create form elements here * /}
															</div>
															
															<div id="error-message">
																{/ * Display error message to your customers here * /}
															</div>
															<button id="submit">Submit</button>
														</form>
														*/


										/* dummy values for debugging */
										/* [0, 1, 2].map( i => <AqaPaymentMethod y={i} key={`pm${i}`} used={i === 1} />) */
