/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaCanUseResponse = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The AqaCanUseResponse model module.
   * @module model/AqaCanUseResponse
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaCanUseResponse</code>.
   * @alias module:model/AqaCanUseResponse
   * @class
   */
  var exports = function() {
    var _this = this;














  };

  /**
   * Constructs a <code>AqaCanUseResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaCanUseResponse} obj Optional instance to populate.
   * @return {module:model/AqaCanUseResponse} The populated <code>AqaCanUseResponse</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('canAddUser')) {
        obj['canAddUser'] = ApiClient.convertToType(data['canAddUser'], 'Boolean');
      }
      if (data.hasOwnProperty('canUse')) {
        obj['canUse'] = ApiClient.convertToType(data['canUse'], 'Boolean');
      }
      if (data.hasOwnProperty('created')) {
        obj['created'] = ApiClient.convertToType(data['created'], 'Number');
      }
      if (data.hasOwnProperty('freePlanDuration')) {
        obj['freePlanDuration'] = ApiClient.convertToType(data['freePlanDuration'], 'Number');
      }
      if (data.hasOwnProperty('left')) {
        obj['left'] = ApiClient.convertToType(data['left'], 'Number');
      }
      if (data.hasOwnProperty('message')) {
        obj['message'] = ApiClient.convertToType(data['message'], 'String');
      }
      if (data.hasOwnProperty('numberOfDefinedUsers')) {
        obj['numberOfDefinedUsers'] = ApiClient.convertToType(data['numberOfDefinedUsers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfPurchasedUsers')) {
        obj['numberOfPurchasedUsers'] = ApiClient.convertToType(data['numberOfPurchasedUsers'], 'Number');
      }
      if (data.hasOwnProperty('planId')) {
        obj['planId'] = ApiClient.convertToType(data['planId'], 'String');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'Number');
      }
      if (data.hasOwnProperty('subscriptionCancelationDate')) {
        obj['subscriptionCancelationDate'] = ApiClient.convertToType(data['subscriptionCancelationDate'], 'Number');
      }
      if (data.hasOwnProperty('success')) {
        obj['success'] = ApiClient.convertToType(data['success'], 'Boolean');
      }
      if (data.hasOwnProperty('successful')) {
        obj['successful'] = ApiClient.convertToType(data['successful'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * @member {Boolean} canAddUser
   */
  exports.prototype['canAddUser'] = undefined;
  /**
   * @member {Boolean} canUse
   */
  exports.prototype['canUse'] = undefined;
  /**
   * @member {Number} created
   */
  exports.prototype['created'] = undefined;
  /**
   * @member {Number} freePlanDuration
   */
  exports.prototype['freePlanDuration'] = undefined;
  /**
   * @member {Number} left
   */
  exports.prototype['left'] = undefined;
  /**
   * @member {String} message
   */
  exports.prototype['message'] = undefined;
  /**
   * @member {Number} numberOfDefinedUsers
   */
  exports.prototype['numberOfDefinedUsers'] = undefined;
  /**
   * @member {Number} numberOfPurchasedUsers
   */
  exports.prototype['numberOfPurchasedUsers'] = undefined;
  /**
   * @member {String} planId
   */
  exports.prototype['planId'] = undefined;
  /**
   * @member {Number} status
   */
  exports.prototype['status'] = undefined;
  /**
   * @member {Number} subscriptionCancelationDate
   */
  exports.prototype['subscriptionCancelationDate'] = undefined;
  /**
   * @member {Boolean} success
   */
  exports.prototype['success'] = undefined;
  /**
   * @member {Boolean} successful
   */
  exports.prototype['successful'] = undefined;



  return exports;
}));


