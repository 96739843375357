/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import AqaComponent from "../shared/aqacomponent/AqaComponent"
import AqaSnapshotDetail from "./AqaSnapshotDetail"
import {ASPECTS} from "../../model/AqaAnalysisObject"


import AqaTypeMetricsRow from "./AqaTypeMetricsRow"

import "./AqaTypeMetrics.css"


const COLOURS = ["Amber", "Red"];
const ALL_CAPTION = "all";

const NUMBER_OF_COLOURS = 7;

export default class AqaTypeMetrics extends AqaComponent
{

	static width = 460;
	static marginBottom = 5;

	static #serial = 0;

	constructor(props)
	{
		super(props);

		const counts = Array(NUMBER_OF_COLOURS);
		for(let i = 0; i < NUMBER_OF_COLOURS; i++) counts[i] = [0, 0];

		this.state =
		{
			selection:-1, // none to start with
			dense: false,
			statistics:null,
			float:props.float ? props.float : "left",
			tableStatistics: ["All", 100, "x"],
			selectedType: -1,
			counts,
			redAmberRowCounts: // 0: amber, 1: red, 2: amber ∩ red
			[
				[0, 0, 0],
				[0, 0, 0],
				[0, 0, 0],
				[0, 0, 0],
				[0, 0, 0],
				[0, 0, 0],
				[0, 0, 0],
//				[0,0,0] // ?
			]
		};

		this.selectedStat = null;
		props.controller.registerTMComponent(this, props.position);
		this.staters = Array(NUMBER_OF_COLOURS);
		
		
		
	} //

	registerStater(s) { this.staters[s.props.type] = s; }


	// Called by TMRows (our graphical children)
	getSelectedTypeIndex() { return this.state.selectedType; }


	// What is this needed by??
	// Tentatively removed 20220708 St Me BTW
/*
	select(stat) { this.doSelection(stat); }
*/


	specialSelect(newSelectedType, postOp)
	{
		// We only select if the selection is not already this one. (stat)
		// That's when users click on a a/r checkbox
		if (this.state.selectedType !== newSelectedType) this.doSelection(newSelectedType, postOp);
		else if (postOp) postOp();
		
	} // specialSelect



	doSelection(newSelectedType, postOp)
	{
		// Can't select something that's 0 neither can we use something that's zero to deselect.

// onsole.log("#################");
// onsole.log("ATM -----------> newSelectedType [" + newSelectedType + "]");

/*
// onsole.log(AqaComponent.prettifyJson(this.state.statistics));
 onsole.log("ATM -----------> old type selection: " + this.state.selectedType);
*/
		

/*
// Block removed 20220602 -this never seemed to be invoked as the test seemed misconstructed anyway. Also we do not have the stats anymore.
//		if (newSelectedType >= 2 && newSelectedType !== 5 && this.state.statistics[newSelectedType][2] <= 0) 
		{
// onsole.log("ATM: doSelectiondoSelection: Postoping IMMEDIATELY for: " + newSelectedType);
			if (postOp) postOp();
		 	return;
		}
*/

// onsole.log("TM doSelection newSelectedType: " + newSelectedType);

		let parentNeedsResetting = false;
		//let redAndAmberFilteringMustBeEnabled = false;

		const after = () =>
		{
			this.setState
			(
				{selectedType:newSelectedType},
				() =>
				this.props.controller.selectType
				(
					this.props.position,
					newSelectedType,
					() =>
					{
						/*
						if (redAndAmberFilteringMustBeEnabled && newSelectedType !== -1) this.staters[newSelectedType].filterForColours(postOp);
						else if (postOp) postOp();
						*/

						// 20220707
						if (postOp) postOp();
					}
				)
			);
		}

		if (this.state.selectedType === newSelectedType)
		{
// Deselection
			parentNeedsResetting = true;
			newSelectedType = -1;
		}
		else
		{
		
// Active selection
		
			//let redAndAmberFilteringMustBeEnabled = true;
			
	
			
			// Reset the checkboxes in the previously selected type.
			if (this.state.selectedType !== -1)
			{
				this.staters[this.state.selectedType].resetChecks();
			}
		}


// console .log("newSelectedType: " + newSelectedType + " state selection: " + this.state.selection);
// console .log("position" + this.props.position);
// console .log("ATM -----------> calling mother ship")

/*
		this.setState({selectedType:newSelectedType});
		this.props.controller.selectType(this.props.position, newSelectedType, postOp);
*/

		if (parentNeedsResetting)
		{
//console .log("I know parent needs resetting");		
		 this.props.controller.selectGlobalAmberRed(this.props.position, [false, false], after);
		}
		else after();

	} // doSelection



	// This is probably not needed if we do not display
	// any stats. OF COURSE, the postop needs to be carried out.
	// This was used when we were displaying stats from the the Table object.
	
	/*
	informTypeMetrics(newSelectedType, statistics, postOp)
	{

// console .log("Informing of types: " + newSelectedType + " which: " + this.props.position);

		if (!postOp) postOp = () => 0;
		this.setState({selectedType:newSelectedType, statistics}, postOp);
	} // informTypeMetrics
	*/


	informTypeMetrics2(newSelectedType, postOp)
	{
//		if (!postOp) postOp = () => 0;
		this.setState({selectedType:newSelectedType}, postOp);
	} // informTypeMetrics2




	// Row counts DO change with different views
	setRowErrorCounts(redAmberRowCounts) { this.setState({redAmberRowCounts}); } // from parents getScore2



	informOfRedAmberData(scoreData)
	{

// onsole.log("FETCHING RED AND AMBERS!! COZ WE BEEN INFORMED!!!");
	

// onsole.log("ATM: [" + this.props.position + "] called back I have score!");

/*
onsole.log("SCORE DATA:");
onsole.log(AqaComponent.prettifyJson(scoreData));
*/

		const counts = Array(7);
		for(let i = 0; i < 7; i++) counts[i] = new Array(2);

		let i, j, t, v;
		for(i = 0; i < 2; i++) // Red and ambers
		{
			t = 0;
			for(j = 0; j < 6; j++)
			{
				v = scoreData[AqaSnapshotDetail.getScorePropertyNameFor(j, COLOURS[i], true)];
				t += v;


				// Why? Why? Why? Why??
				// let l = j===2?3:(j===3?4:(j===4?2:(j===0?1:(j===1?0:j))));
				// counts[l][i] = v;

				counts[j][i] = v; // DO NOT CHANGE!

//						this.staters[j].setColourCount(i, v);
			}
			counts[6][i] = t;
		}
		this.setState({counts});

	} // informOfRedAmberData

	
	


	resize(params)
	{
		// And state it boldly
		this.setState({float:params.tmFloat});
	} // resize


	selectAmberRed(booleans, postop)
	{
		this.props.controller.selectGlobalAmberRed(this.props.position, booleans, postop);
	} // selectAmberRed




	render()
	{

/*
onsole.log("Rendering TM");
if (this.state.statistics !== null) onsole.log("I have stats!!!");
else onsole.log("no stats");
*/

		const tableStyle =
		{
			width:`${AqaTypeMetrics.width}px`,
			marginBottom:AqaTypeMetrics.marginBottom,
			float:this.state.float
		}

		const selections = this.props.controller.getTypeSelections(this.props.position);
		const rowCounts = Array(7);
		let t /*, both, amberRedsForType*/;

		for(let i = 0; i < 7; i++)
		{
			t = 0;
			for(let j = 0; j < 2; j++) t += this.state.redAmberRowCounts[i][1 - j];
			t -= this.state.redAmberRowCounts[i][2]; // 2: intersections
			rowCounts[i] = t;
		}
		
		return (

			<table className="typeMatrixTable" style={tableStyle} cellSpacing={0}>
				<colgroup>
					<col style={{width:"24px"}} />
					<col style={{textAlign:"left",  width:"196px"}} />
					{/*<col style={{textAlign:"right", width:"50px"}} />*/}
					<col style={{textAlign:"right", width:"120px"}} />
					<col style={{textAlign:"right", width:"120px"}} />
					{/*<col style={{textAlign:"right", width:"80px"}} />*/}
				</colgroup>

				<thead style={{height:"46px !important"}}>

					<tr>{/* style={{height:"46px !important"}} */}
						<th></th>
						<th>CHECK</th>
						<th># AMBER ROWS</th>
						<th># RED ROWS</th>

					</tr>
				</thead>

				<tbody>
					<AqaTypeMetricsRow
						key={`asttlr${AqaTypeMetrics.#serial++}`}
						parent={this}
						type={6}
						caption={ALL_CAPTION}
						// stat={this.state.tableStatistics}
						colours={this.state.counts[6]}
						arClicker={(booleans, postop) => this.selectAmberRed(booleans, postop)}
						checks={6 === selections[0] ? ([selections[1], selections[2]]) : ([false, false])}
						selected={false}
						rowCount={rowCounts[6]}
					/>
					{
						//this.state.statistics.map
						ASPECTS.map
						(
							(item, index) =>
							(
								<AqaTypeMetricsRow
									key={`asttlr${AqaTypeMetrics.#serial++}`}
									parent={this}
									type={index}
									// stat={item}
									caption={item}
									colours={this.state.counts[index]}
									arClicker={(booleans, postop) => this.selectAmberRed(booleans, postop)}
									checks={index === selections[0] ? ([selections[1], selections[2]]) : ([false, false])}
									selected={index === selections[0]}
									rowCount={rowCounts[index]}
								/>
							)
						)
					}
				</tbody>
			</table>

		);
		
	} // render

	
} ////


/*
		// Row counts only showing if selected		
		for(let i = 0; i < 6; i++)
		{
			t = 0;
			amberRedsForType = this.staters[i] ? this.staters[i].getChecks() : [false, false]; // 0: amber, 1: red
			
			both = 0;
			for(let j = 0; j < 2; j++)
			{

				if (amberRedsForType[j])
				{

					t += this.state.redAmberRowCounts[i][1 - j];
					both++;
				}
			}
			if (both === 2) t -= this.state.redAmberRowCounts[i][2]; // ∩
// if (this.props.position === 0) console .log("t for " + i + ": " + t);
			rowCounts[i] = t;
		}
*/	





/*
 style={{textAlign:"left",fontSize:"0.8rem",fontWeight:"600",color:"#006", padding:"4px 0"}}
 style={{padding:"4px 0",textAlign:"right",fontSize:"0.8rem",fontWeight:"700",color:"#006"}}
 style={{padding:"4px 0",fontSize:"0.8rem",fontWeight:"700",color:"#006"}}
 style={{padding:"4px 0",textAlign:"right",fontSize:"0.8rem",fontWeight:"700",color:"#006"}}
 style={{padding:"4px 0",textAlign:"right",fontSize:"0.8rem",fontWeight:"700",color:"#006"}}
*/


// AqaTypeMetrics.propTypes = { classes: PropTypes.object.isRequired };

// export default withStyles(styles)(AqaTypeMetrics);




// 2022.02.17
// NOTE, TODO: As we do not update the type metrics anymore, we probably don't need to worry about this here!
// WARNING! This is still called by ASD to initialise the scores!!!

	/*
	informOfRedAmberChanges(selectedColumn, postop)
	{

// console .log("FETCHING RED AND AMBERS!! COZ WE BEEN INFORMED!!!");
	
		this.props.controller.getScore
		(
			selectedColumn,
			scoreData =>
			{

// console .log("ATM: [" + this.props.position + "] called back I have score!");

				const counts = Array(6);
				for(let i = 0; i < 6; i++) counts[i] = new Array(2);

				let i, j, t, v;
				for(i = 0; i < 2; i++)
				{
					t = 0;
					for(j = 0; j < 5; j++)
					{
						v = scoreData[AqaSnapshotDetail.getScorePropertyNameFor(j, COLOURS[i], false)];
// console .log("v: " + v);						
						t += v;
						counts[j][i] = v;
//						this.staters[j].setColourCount(i, v);
					}
					counts[5][i] = t;
				}
				this.setState({counts}, postop);
//				if (postop) postop();
			}
		);
	
	
	} // informOfRedAmberChanges
	*/
	
