import React from 'react';
import AqaComponent  from "../aqacomponent/AqaComponent"
import './heatmap.scss';
import Typography from "@material-ui/core/Typography";
import HtmlTooltip from "@material-ui/core/Tooltip";

// TODO = this file should now be Heatmap.js in shared!!!
//        NONE of the other classes are necessary.

const heatTypes =
[
	"population",  // 0
	"uniqueness",  // 1
	"number",      // 2
	"date",        // 3
	"string",      // 4
	"overall"      // 5
];



const heatmapStyle =
{
	display: "inline-grid",
	gridGap: "1px",
	border:  "1px solid #DADBDB",
	backgroundColor:"#DADBDB"
};




const d2s =
{
	'0':"hmred",
	'1':"hmamber",
	'2':"hmgreen"
};

// const BASE_PIXEL_SIZE_OF_HEAT_CELL = 5;


/** Generates a heatmap from a map and some dimensions you need to provide.
  * IMPORTANT NOTE: This class can render EITHER Row or Colum based heatmaps.
  *                 The specialised Column(s) based objects can't.
  */


export default class Heatmap extends AqaComponent
{

	static BASE_PIXEL_SIZE_OF_HEAT_CELL = 5;

	static POPULATION_HEATMAP_NAME = heatTypes[0];
	static UNIQUENESS_HEATMAP_NAME = heatTypes[1];
	static NUMBER_HEATMAP_NAME     = heatTypes[2];
	static DATE_HEATMAP_NAME       = heatTypes[3];
	static STRING_HEATMAP_NAME     = heatTypes[4];
	static OVERALL_HEATMAP_NAME    = heatTypes[5];


	static getHeatmapColumnsData(snapshotId, width, height, columnIndex, callback)
	{
		const params =
		{
			width,
			height,
			recompute:false
		};
		if (columnIndex !== -1) params.columnIndex = columnIndex;
	

		AqaComponent.snapshotBackend.getSnapshotColumnsHeatmapUsingGET
		(
			snapshotId,
			params,
			(error, data, response) =>
			{
				if (error) AqaComponent.staticReportError("An error occurred obtaining a heatmap from the server.", "Heatmap.getSnapshotColumnsHeatmapUsingGET, call error, backend call: " + error,this);
				else if (callback) callback(data);
			}
		);



	} // getHeatmapColumnsData



	/** 
     * Required: data, type, scale
     * Click handling:  clickerCallback
     * clickerCallback can be null or undefined. 
	  */
	constructor(props)
	{
		super(props);

		if (props.parent) props.parent.registerMap(this);

// onsole.log("CONSTRUCTING BY TYPE [" + props.type + "]");
		
		this.state =
		{
//			radarIsOn:false,
//			radarCoordinates:this.props.radarCoordinates
		};

		this.frame=null;
		this.currentSource=null;
		this.currentSnapshot=null;

	} //


	handleColorMapCords(event, cords, color)
	{
		event.stopPropagation();
		if (!this.props.clickerCallback) return;

		let {width, height} = this.props.data;
		width += this.props.data.horizontalOffset + this.props.data.horizontalPostPad; // Subtle but needed!
		let isAmber = (color === "hmamber");
		let isRed =   (color === "hmred");
		this.props.clickerCallback(cords % width, width, Math.floor(cords / width), height, cords, isAmber, isRed, this.props.type);

	} // handleColorMapCords


//	setRadarOff() { this.setState({radarIsOn:false}); }

	render()
	{
		// width and height are the actual size of the map returned to us - as adjusted (reduced) by the backend.

		if (!this.props.data) return <div />;

		const numRows=this.props.numRows===undefined?16:this.props.numRows;
		const numCols=this.props.numCols===undefined?16:this.props.numCols;
		const numRangeRows = numRows>16?Math.round(numRows/16):1;
		const numRangeCols = numCols>16?((numCols/16)):1;
		const type = this.props.type; // , desiredWidth, desiredHeight
		const {width, height, horizontalOffset, horizontalPostPad, verticalOffset, verticalPostPad} = this.props.data;
		const map = this.props.data[`${type}Colours`]; // Type extracted from props above

// onsole.log("HM: RENDERING HEATMAP!");
// onsole.log(this.props.data);
// onsole.log("HM: Type: " + type);
// onsole.log("desiredWidth: " + desiredWidth);
// onsole.log("desiredHeight: " + desiredHeight);
// onsole.log("physicalwidth: " + width);
// onsole.log("physicalHeight: " + height);


		let y, x, p = 0, f = 0;
		let visibleWidth  = horizontalOffset + width + horizontalPostPad;
		let visibleHeight = verticalOffset + height + verticalPostPad;

		const colours = new Array(visibleWidth * visibleHeight); // TODO If we're ever going to pad for desired sizes then desired sizes should be used here instead.


		// Vertical prepad
		for(y = 0; y < verticalOffset; y++) for(x = 0; x < visibleWidth; x++) colours[p++] = 'hmgrey';

		for(y = 0; y < height; y++)
		{
			// prepad - will happen when we are displaying only 1 column
			for(x = 0; x < horizontalOffset; x++) colours[p++] = 'hmgrey';

			if (map) for(x = 0; x < width; x++) colours[p++] = d2s[map.charAt(f++)];
			else for(; x < width; x++) colours[p++] = 'hmgrey';

			// postpad
			for(x = 0; x < horizontalPostPad; x++) colours[p++] = 'hmgrey';

			// Padding to get a consistently wide heatmap
//			for(; x < desiredWidth; x++) colours[p++] = 'hmgrey'; 
		}

		// Vertical postpad
		for(y = 0; y < verticalPostPad; y++) for(x = 0; x < visibleWidth; x++) colours[p++] = 'hmgrey';
		
// onsole.log("p vs. array size: " + p + " / " + (desiredWidth * desiredHeight));

		// Bottom padding for heatmaps that would have less than height rows
//		for(; y < desiredHeight; y++) for(x = 0; x < desiredWidth; x++) colours[p++] = 'hmgrey';  // Padding to get a consistently high heatmap

		const columns = [];
		for(x = 0; x < visibleWidth; x++) columns.push("auto");
		const myStyle = {};

		// Shallow clone TODO - use cloning method available in AqaObject
		for (const [k, v] of Object.entries(heatmapStyle)) myStyle[k] = v;
		myStyle.gridTemplateColumns = columns.join(' ');
		myStyle.position = "absolute";

// onsole.log("Pixel base: ");


		const cellSize = Heatmap.BASE_PIXEL_SIZE_OF_HEAT_CELL * this.props.scale;
		const pxSize = `${cellSize}px`;
		const sizeStyle = {width:pxSize, height:pxSize, cursor:"crosshair"};
		
		// +1: the border, +1 the border at the end
		const elementWidth1 =  (cellSize + 1) * (visibleWidth) + 1;
		const elementWidth = elementWidth1<120?100:elementWidth1;
		const elementHeight = (cellSize + 1) * (verticalOffset + height + verticalPostPad) + 1;

		let radarStyle = this.props.radarCoordinates
		?
			{
				position:"absolute",
				border:"1px solid #000",
				left:  `${this.props.radarCoordinates[0][0]}px`,
				top:   `${this.props.radarCoordinates[1][0]}px`,
				width: `${this.props.radarCoordinates[0][1]}px`,
				height:`${this.props.radarCoordinates[1][1]}px`
			}
		:
			{display:"none"}
		;


// onsole.log("Radar style");
// onsole.log(AqaComponent.prettifyJson(radarStyle));


		return (
			<div style={{position:"relative", width:`${elementWidth}px`, height:`${elementHeight}px`}}>
				<div style={myStyle}>
				{
					colours.map((e, i) => <HtmlTooltip key={i} enterDelay={500} title={<React.Fragment>
						<Typography color="inherit" className="aqa-text-action">Column(s): {(numCols)<17?((i%width)+1): ((Math.round(((i%width))*numRangeCols)+1)+ " - " + (Math.round((((i%width))*numRangeCols)+numRangeCols)+1))}</Typography>
						<Typography color="inherit" className="aqa-text-action">Row(s): {(Math.floor(i / width)===0)?((Math.floor(i / width)+1) +" - "+((Math.floor(i / width)+1)+numRangeRows)):((Math.floor(i / width))*numRangeRows +" - "+((Math.floor(i / width)+1)*numRangeRows))}</Typography>
						<Typography color="inherit" className="aqa-text-action"><span style={{paddingLeft:"0px"}}>{e==="hmred"?"    Violation: Red":(e==="hmamber"?"    Violation: Amber":"    Violation: None")} </span></Typography>
						<Typography color="inherit" className="aqa-text-action">Click to view detail</Typography>

					</React.Fragment>} arrow={"true"} placement="top-start"><div><div key={i} className={e} style={sizeStyle} onClick={me => this.handleColorMapCords(me, i, e)} />
					</div></HtmlTooltip>)
				}
				</div>
				{
					this.props.radarCoordinates
					?
						<div style={radarStyle} />
					:
						null
				}
			</div>
		);

	} // render

} //// Heatmap

