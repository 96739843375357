
import React, {Component} from 'react'

export default class StripeSuccess  extends Component
{

	render()
	{
		return (
			<div style={{margin:"5px", padding:"5px", border:"1px solid red"}}>
				Thank you! Your payment was successful!<br />
				<input type="button" value="OK" onClick={e => this.props.parent.home() } />
			</div>
		);
	} // render

} ////
