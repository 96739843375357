/* Welcome to Aqa Client*/
/* Version: 1 */

import React, {Component} from 'react' ;
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as LogoutIcon} from "../images/aqa-icons/Log Outcol.svg";
import {ReactComponent as SettingsIcon} from "../images/aqa-icons/AQA-Usercol.svg";
import LoginLogo from "../images/aqa-mark-logo.svg";
import {ReactComponent as LoginIcon} from "../images/aqa-mark-logo.svg"
import HtmlTooltip from "@material-ui/core/Tooltip";
import AqaComponent from "./shared/aqacomponent/AqaComponent";

class AqaHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            username: "",
            redirect: false,
            skipRedirect: true,
            notfound: false

        };
        this.dataFromParent=props.parent;
        this.dataFromRoot=props.parent;
        this.handleLogout = this.handleLogout.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this);
    }
    handleLogout(){
        this.props.dataFromRoot.logout();
    }

    handleNavigation(e,which){
        let {isAccountExpired,isSubscriptionCancelled} = this.props.dataFromParent.state;
        if(isSubscriptionCancelled || isAccountExpired) return;
        localStorage.setItem("navigation",which);
        this.props.dataFromParent.navigation(which);
        this.props.dataFromParent.handleSelectedStat(null);
        this.props.dataFromParent.handleSourceReset();
        if(which===1) window.location.reload();
    }

    handleUserDetails(){
        AqaComponent.userBackend.meUsingGET(
            (error,data,response)=>{
                var errorJSON = JSON.parse(response.text);
                if (errorJSON.error === "invalid_token") {
                    this.props.dataFromRoot.props.dataFromRoot.popTimeoutDialog2();
                    //this.props.dataFromRoot.props.dataFromRoot.props.parent.logout();
                }
                else {
                    if (error) this.reportError("A problem getting the user details from the server was encountered.",
                        "AqaAccountUsers.handleUserDetails, backend call: " + error,this);
                    else {
                        let username = data.email;
                        if(data.firstname !=="" && data.lastname !=="" && data.firstname !==null && data.lastname !==null) username = data.firstname + " " + data.lastname;
                        this.setState({username: username});
                        this.props.dataFromRoot.handleAccountId(data.accountId)
                        this.props.dataFromRoot.handleAuthCode(data.permissions);
                        this.props.dataFromRoot.handleUserId(data.id);
                        this.props.dataFromRoot.handleUsername(data.email);
                        this.handleAccountDetails();
                    }
                }
            }

        );
    }

    handleAccountDetails(){
//        var accountId = this.props.dataFromParent.props.dataFromMain.props.dataFromRoot.props.parent.state.accountId;
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    this.props.dataFromRoot.handleAdminEmail(data.name);
                    this.setState({accountObj:accountObj});
                }
            }
        );
    }


    componentDidMount(){
        //this.handleUserDetails();
        //this.setState({username:this.props.dataFromRoot.state.username});
    }

    render() {

        const { error, isLoaded, redirect, notfound,skipRedirect} = this.state;
        let user = this.props.dataFromRoot.state.user;
        let username = "";
        if(user!==null){
            username = (user.firstname!== "" && user.lastname!==""&& user.firstname!== null && user.lastname!== null?user.firstname+" "+user.lastname:user.email);
        }
        if(redirect && skipRedirect){
            //return <Redirect to="/" push={true} />
        }
        else if (notfound && error) {
            return (
                <div>
                    <AppBar position="fixed" >
                        <ToolBar>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <IconButton onClick={(e)=>this.handleNavigation(e,1)} >
                                        <div className="aqa-icon" width="90px">
                                            <LoginIcon />
                                        </div>
                                    </IconButton>

                                </Grid>
                                <Grid item xs={5} sm={5} lg={5} xl={5}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"right",fontSize:"0.8rem"}}>

                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} xl={1}>

                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} xl={1}>

                                </Grid>
                                <Grid item xs={1} sm={1} lg={1} xl={1}>

                                </Grid>
                                <Grid className="aqa-hover" item xs={1} sm={1} lg={1} xl={1}>

                                </Grid>
                                <Grid className="aqa-hover" item xs={1} sm={1} lg={1} xl={1}>
                                    <HtmlTooltip title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Click to end the session</Typography>
                                        </div>
                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                    <IconButton className="aqa-button" onClick={this.handleLogout}  >
                                        <div className="hb hb-xs hb-custom-white">
                                            <LogoutIcon  style={{color:"white",width:"16px",marginTop:"-4px"}} />
                                        </div>
                                        <Typography className={"aqa-header-button-text"} variant="inherit" color="inherit" style={{textAlign:"right",fontSize:"0.8rem",color:"white",paddingLeft:"4px"}}>
                                            LOGOUT
                                        </Typography>
                                    </IconButton>
                                    </HtmlTooltip>
                                </Grid>
                            </Grid>
                        </ToolBar>
                    </AppBar>
                </div>
            );
        }
        else if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
                return (
                    <div>
                        <AppBar position="fixed" className="aqa-toolbar-header" >
                            <ToolBar>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{padding: 0, marginTop: 0}}>
                                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                                        <IconButton onClick={(e)=>this.handleNavigation(e,1)} >
                                            <img src={LoginLogo} alt="Welcome" width="120px" style={{verticalAlign:"left",marginLeft:"-30px"}}/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={4} sm={4} lg={4} xl={4}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"right",fontSize:"0.8rem"}}>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"right",fontSize:"0.8rem"}}>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    </Grid>
                                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    </Grid>
                                    <Grid className="aqa-hover" item xs={2} sm={2} lg={2} xl={2}>
                                        <Typography variant="inherit" color="inherit" style={{textAlign:"right",fontSize:"0.8rem"}}>
                                            <HtmlTooltip title={<React.Fragment>
                                                <div>
                                                    <Typography color="inherit" className="aqa-text-action">Click to access your profile</Typography>
                                                </div>
                                            </React.Fragment>} arrow={"true"} placement="bottom-start">
                                            <IconButton className="aqa-button" onClick={(e)=>this.handleNavigation(e,4)}>
                                                <div className="aqa-icon">
                                                    <SettingsIcon width="30px" />
                                                </div>
                                                <Typography className={"aqa-header-button-text"} variant="inherit" >
                                                    {username}
                                                </Typography>
                                            </IconButton>
                                            </HtmlTooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid className="aqa-hover" item xs={1} sm={1} lg={1} xl={1}>
                                        <HtmlTooltip title={<React.Fragment>
                                            <div>
                                                <Typography color="inherit" className="aqa-text-action">Click to end the session</Typography>
                                            </div>
                                        </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <IconButton className="aqa-button" onClick={this.handleLogout}  >
                                            <div className="aqa-icon">
                                                <LogoutIcon  width="30px" />
                                            </div>
                                            <Typography className={"aqa-header-button-text"} variant="inherit" >
                                                LOGOUT
                                            </Typography>
                                        </IconButton>
                                        </HtmlTooltip>
                                    </Grid>
                                </Grid>
                            </ToolBar>
                        </AppBar>
                    </div>
                );
        }
    }
}
export default AqaHeader;
