/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as NewSourceIcon} from "../../images/aqa-icons/AQA-Edit.svg";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import HtmlTooltip from "@material-ui/core/Tooltip";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaUserEdit extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            openProgress:false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            periodicities: [],
            periodicity: "daily",
            userid:"",
            email: "",
            firstname: "",
            lastname: "",
            role:"V",
            isAdmin: false,
            errorMessage: "",
            file: null,
            currentUser:null,
            users:[],
            account:null,
            is2FAEnabled:false

        };
        this.handleUserUpdation=this.handleUserUpdation.bind(this);
        this.handleAccountDetails=this.handleAccountDetails.bind(this);
        this.handleUsers=this.handleUsers.bind(this);
        this.handleCheck2FA=this.handleCheck2FA.bind(this);
        this.handleRemove2FA=this.handleRemove2FA.bind(this);

    }

    handleUserUpdation(){
        var personUpdateRequest={
            email:this.state.email,
            id:this.state.currentUser.id,
            firstname:this.state.firstname,
            lastname:this.state.lastname,
            permissions:this.state.role
        }
        AqaComponent.userBackend.updateUserWithPermissionsUsingPUT(
            personUpdateRequest,
            (error, data, response) =>
            {
                if (error) this.reportError("Sorry, we exprienced a server issue trying to update your details.", "AqaUserEdit.handleUserUpdation, backend call: " + error,this);
                else
                {
                    this.setState({isLoaded: true, openMessage:false});
                    this.props.dataFromParent.handleUserSelectionReset();

                }
            }
        );
    }

    handleCreateUserClosePopup(e) {
        e.preventDefault();
        var firstname= this.state.firstname;
        var lastname = this.state.lastname;
        /*if(this.props.dataFromParent.state.selectedUsers.length ===1){
            var user = this.props.dataFromParent.state.selectedUsers[0];
        }*/

        if(firstname === "" || firstname.trim() === "" || lastname === "" || lastname.trim() === ""){
            this.setState({ errorMessage: "Please fill mandatory fields" });
        }
        else{
            this.handleUserUpdation();
        }
        this.setState({ openMessage: true });
    }

    handleClosePopup(e) {
        e.preventDefault();
        this.setState({ errorMessage:"",openMessage: false,SourceName:"",SourceDescription:"",periodicity:"daily" });
        this.props.dataFromParent.handleUserSelectionReset();
    }
    handleNewSourceClick(e) {
        e.preventDefault();
        if(this.props.dataFromParent.state.selectedUsers.length ===1){
            var user = this.props.dataFromParent.state.selectedUsers[0];
            var isAdmin = user.permissions==="A"?true:false;
            this.setState({email:user.email,firstname:user.firstname,lastname:user.lastname,isAdmin:isAdmin,userid:user.id,currentUser:user,role:user.permissions});
            this.handleAccountDetails();
        }
        this.setState({ openMessage: true });
    }


    handleNameChange(evt){
        const{name,value} = evt.target;
        this.setState({[name]:value,errorMessage:""});
    }

    handleRadioChange = (e)=>{
        const{name,value} = e.target;
        this.setState({[name]:value,errorMessage:""});
    }

    handleChange(event){
        const { name} = event.target;
        this.setState({[name]: event.target.checked, errorMessage: "", errorMessage1: ""});

    }

    handleAccountDetails(){
//        var accountId=localStorage.getItem("accountId");
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountUsers.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    this.setState({account:accountObj});
                    this.handleUsers();
                }
            }

        );
    }

    handleUsers(){
        AqaComponent.userBackend.allUsingGET(
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the users from the server was encountered.",
                    "AqaAccountUsers.handleUsers, backend call: " + error,this);
                else{
                    this.setState({users:data});
                }
            }

        );
    }

    handleRemove2FA(){
        var user = this.props.dataFromParent.state.selectedUsers[0];
        AqaComponent.accountBackend.delete2FAProfileForUserUsingDELETE(
            user.id,
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the users from the server was encountered.",
                    "AqaAccountUsers.handleUsers, backend call: " + error,this);
                else{
                    this.props.dataFromParent.handleUserSelectionReset();
                    this.setState({isLoaded: true,openMessage:false});
                }
            }
        );
    }
    handleCheck2FA(user){
        AqaComponent.accountBackend.doesUserHave2FAUsingGET(
            user.id,
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the users from the server was encountered.",
                    "AqaAccountUsers.handleUsers, backend call: " + error,this);
                else{
                    this.setState({is2FAEnabled:data.success});
                }
            }
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

    }

    componentDidMount(){
        if(this.props.dataFromParent.state.selectedUsers.length===1){
            var user = this.props.dataFromParent.state.selectedUsers[0];
            this.handleCheck2FA(user);
        }

    }

    handleCheckEditable = (a,b,cid)=>{
        //a is always loggedin user
        //b is target user
        //cid is default Admin
        if(a === null) return true;
        if(a.id === cid || a.id === b.id) return true;
        if((b.id!==a.id && b.permissions==="A" && b.id===cid) || (a.id!==b.id && a.permissions!=="A")) return false;
        return true;
    }

    render() {
        const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,errorMessage,currentUser } = this.state;
        let disableRadio = this.handleCheckEditable(currentUser,this.props.dataForCurrentUser,this.props.dataForAccount.defaultAdmin);
        let disableAdminRadio = (currentUser !== null && ((currentUser.id === this.props.dataForAccount.defaultAdmin && currentUser.id === this.props.dataForCurrentUser.id)||(this.props.dataForAccount.defaultAdmin !==this.props.dataForCurrentUser.id)))?true:false;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"5px"}}>
                                {(this.props.dataForDisabled === false)?(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Click to edit User</Typography>
                                        </div>
                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                    <IconButton className="aqa-button" onClick={this.handleNewSourceClick.bind(this)} >

                                        <div className="aqa-icon" >
                                            <NewSourceIcon width="32px" />
                                        </div>
                                        <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                            EDIT
                                        </Typography>
                                    </IconButton>
                                    </HtmlTooltip>
                                ):(
                                    <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                        <div>
                                            <Typography color="inherit" className="aqa-text-action">Operation not permitted</Typography>
                                        </div>
                                    </React.Fragment>} arrow={"true"} placement="bottom-start">
                                        <div>
                                    <IconButton className="aqa-button" >

                                        <div className="aqa-icon aqa-icon-e-disabled" >
                                            <NewSourceIcon width="32px" />
                                        </div>
                                        <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#dadbdb",paddingLeft:"4px",marginTop:"-4px"}}>
                                            EDIT
                                        </Typography>
                                    </IconButton>
                                        </div>
                                    </HtmlTooltip>
                                )}

                            </Typography>
                        </Grid>

                    </Grid>

                    <Dialog
                        open={this.state.openMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                Edit User
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={this.handleClosePopup.bind(this)} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,minWidth:"400px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <TextField
                                        id="email"
                                        //onChange={this.handleTextChange('guest')}
                                        label="Email"
                                        name="email"
                                        value={this.state.email}
                                        className={classes.textField}
                                        variant="filled"
                                        margin="dense"
                                        multiline={false}
                                        rows={1}
                                        style={{ width: "95%"}}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <TextField
                                        id="firstname"
                                        //onChange={this.handleTextChange('guest')}
                                        label="First Name"
                                        name="firstname"
                                        value={this.state.firstname}
                                        className={classes.textField}
                                        variant="filled"
                                        margin="dense"
                                        multiline={false}
                                        rows={1}
                                        style={{ width: "95%"}}
                                        onChange={this.handleNameChange.bind(this)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <TextField
                                        id="lastname"
                                        //onChange={this.handleTextChange('guest')}
                                        label="Last Name"
                                        name="lastname"
                                        value={this.state.lastname}
                                        className={classes.textField}
                                        variant="filled"
                                        margin="dense"
                                        multiline={false}
                                        rows={1}
                                        style={{ width: "95%"}}
                                        onChange={this.handleNameChange.bind(this)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <div style={{paddingTop:"10px"}}>
                                        <Typography className={"aqa-text-action-bold"} variant="title" align="left"
                                                    style={{padding:"0px" }}>
                                            <span style={{float:"left",width:"80px",fontSize:'14px',paddingTop:'0px',marginTop:"-2px",paddingLeft:"25px",fontFamily:"Montserrat"}}>Role</span>
                                        </Typography>
                                        <RadioGroup aria-label="role" name="role" value={this.state.role}
                                                    color="primary" onChange={this.handleRadioChange} row={true}>
                                            <FormControlLabel value="A" disabled={disableAdminRadio} control={<Radio color="primary" style={{padding:"0px 0px 0px 4px"}} />} label={
                                                (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                             style={{}}>
                                                    <span style={{float:"left",width:"80px"}}>Admin</span>
                                                </Typography>)
                                            } style={{width:"80px",margin:"0px"}} />
                                            <FormControlLabel value="U" disabled={disableRadio} control={<Radio color="primary" style={{padding:"0px 0px 0px 4px"}} />} label={
                                                (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                             style={{}}>
                                                    <span style={{float:"left",width:"80px"}}>Analyst</span>
                                                </Typography>)
                                            } style={{width:"80px",margin:"0px"}} />
                                            <FormControlLabel value="V" disabled={disableRadio} control={<Radio color="primary" style={{padding:"0px 0px 0px 4px"}}/>} label={
                                                (<Typography className={"aqa-text-action"} variant="title" align="left"
                                                             style={{padding:"0px" }}>
                                                    <span style={{float:"left",width:"80px"}}>Viewer</span>
                                                </Typography>)


                                            } style={{width:"80px",margin:"0px"}} />


                                        </RadioGroup>
                                    </div>
                                </Grid>
                                {/*<Grid item xs={1} sm={1} lg={1} xl={1}>
                                    {currentUser=== null || currentUser.id === this.props.dataForAccount.defaultAdmin || currentUser.id === this.props.dataForCurrentUser.id?
                                        (
                                    <div className={"aqa-list-checkbox"} style={{width:"10%"}}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.isAdmin}
                                                    onChange={(evt) => this.handleChange(evt)}
                                                    name="isAdmin"
                                                    color="primary"
                                                    disabled
                                                />
                                            }
                                            label={<Typography variant="inherit" color="inherit" className={"aqa-text-bold-12"} style={{textAlign:"left"}}>
                                                Admin
                                            </Typography>}
                                            labelPlacement="start"
                                        />
                                    </div>
                                        ):
                                        (<div className={"aqa-list-checkbox"} style={{width:"10%"}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.isAdmin}
                                                        onChange={(evt) => this.handleChange(evt)}
                                                        name="isAdmin"
                                                        color="primary"
                                                    />
                                                }
                                                label={<Typography variant="inherit" color="inherit" className={"aqa-text-bold-12"} style={{textAlign:"left"}}>
                                                    Admin
                                                </Typography>}
                                                labelPlacement="start"
                                            />
                                        </div>
                                        )}

                                </Grid>*/}

                            </Grid>

                            <div style={{borderTop:"1px solid #ccc",marginTop:10}}>
                                {this.state.is2FAEnabled === true?(
                                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                          style={{padding: 0, marginTop: 0, marginBottom: 10}}>

                                        <Grid item xs={7} sm={7} lg={7} xl={7}>
                                            <Typography variant="inherit" color="inherit" className={"aqa-text-bold-12"} style={{textAlign:"left"}}>
                                            <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                                    color="primary"
                                                    align="left"
                                                    className="aqa-action-button"
                                                    style={{
                                                        marginTop: 8,
                                                        marginLeft: 8,
                                                        color: '#4cadc4',
                                                        backgroundColor: 'white',
                                                        border: '1px solid #4cadc4',
                                                        fontSize: '0.7rem',
                                                        padding: '3px'
                                                    }}>
                                                Cancel
                                            </Button>
                                            <Button  variant="contained"
                                                     onClick={this.handleCreateUserClosePopup.bind(this)}
                                                     color="primary"
                                                     align="left"
                                                     className="aqa-action-button"
                                                     style={{
                                                         marginTop: 8,
                                                         marginLeft: 8,
                                                         color: '#4cadc4',
                                                         backgroundColor: 'white',
                                                         border: '1px solid #4cadc4',
                                                         fontSize: '0.7rem',
                                                         padding: '3px',
                                                     }}>
                                                Save & Close
                                            </Button>

                                                <Button  variant="contained"
                                                         onClick={this.handleRemove2FA}
                                                         color="primary"
                                                         align="left"
                                                         className="aqa-action-button"
                                                         style={{
                                                             marginTop: 8,
                                                             marginLeft: 8,
                                                             color: '#4cadc4',
                                                             backgroundColor: 'white',
                                                             border: '1px solid #4cadc4',
                                                             fontSize: '0.7rem',
                                                             padding: '3px',
                                                         }}>
                                                    Disable 2FA
                                                </Button>
                                            </Typography>


                                        </Grid>

                                        <Grid item xs={1} sm={1} lg={1} xl={1}>
                                        </Grid>
                                        <Grid item xs={4} sm={4} lg={4} xl={4} style={{margin: '0px'}}>


                                        </Grid>

                                    </Grid>
                                ):(
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 0, marginBottom: 10}}>

                                <Grid item xs={6} sm={6} lg={6} xl={6}>
                                    <Typography variant="inherit" color="inherit" className={"aqa-text-bold-12"} style={{textAlign:"left"}}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: 8,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Cancel
                                    </Button>
                                    <Button  variant="contained"
                                             onClick={this.handleCreateUserClosePopup.bind(this)}
                                             color="primary"
                                             align="left"
                                             className="aqa-action-button"
                                             style={{
                                                 marginTop: 8,
                                                 marginLeft: 8,
                                                 color: '#4cadc4',
                                                 backgroundColor: 'white',
                                                 border: '1px solid #4cadc4',
                                                 fontSize: '0.7rem',
                                                 padding: '3px',
                                             }}>
                                        Save & Close
                                    </Button>
                                    </Typography>


                                </Grid>

                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                </Grid>
                                <Grid item xs={5} sm={5} lg={5} xl={5} style={{margin: '0px'}}>

                                </Grid>

                            </Grid>
                                    )}
                            </div>
                        </DialogContent>
                    </Dialog>

                </div>
            );
        }
    }
}

AqaUserEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaUserEdit);
