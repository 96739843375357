import React from "react";
import AqaComponent from "../../shared/aqacomponent/AqaComponent";


import AqaRulesPopulated from "./AqaRulesPopulated";
import AqaRulesUniqueness from "./AqaRulesUniqueness";
import AqaRulesNativeErrors from "./AqaRulesNativeErrors";

import Grid from '@material-ui/core/Grid'



export default class AqaPopulationUniquenessNativeErrorsCombo extends AqaComponent
{
	constructor(props)
	{
		super(props);
		this.state={}
	} //

	render()
	{
		return <>
			<Grid item xs={12} sm={12} lg={12} xl={12}>
				<AqaRulesPopulated
					dataForCol={this.props.dataForCol}
					dataForWhich={this.props.which}
					dataForName={this.props.columnName}
					parent={this.props.parent}
					dataFromSource={this.props.dataFromSource}
					dataForTable={this.props.dataForTable}
					sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
					rulesObject={this.props.rulesObject}
					rules={this.props.rules}
				/>
			</Grid>

			<Grid item xs={12} sm={12} lg={12} xl={12}>
				<AqaRulesUniqueness
					dataForCol={this.props.dataForCol}
					dataForWhich={this.props.which}
					dataForName={this.props.columnName}
					parent={this.props.parent}
					dataFromSource={this.props.dataFromSource}
					dataForTable={this.props.dataForTable}
					sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
					rulesObject={this.props.rulesObject}
					rules={this.props.rules}
				/>
			</Grid>

			<Grid item xs={12} sm={12} lg={12} xl={12}>
				<AqaRulesNativeErrors
					dataForCol={this.props.dataForCol}
					dataForWhich={this.props.which}
					dataForName={this.props.columnName}
					parent={this.props.parent}
					dataFromSource={this.props.dataFromSource}
					dataForTable={this.props.dataForTable}
					sourceDetailObject={this.props.sourceDetailObject} // no used for anything??
					rulesObject={this.props.rulesObject}
					rules={this.props.rules}
				/>
			</Grid>
		</>;
	} // render

} //
