import React from 'react'
import AqaComponent from "../../../shared/aqacomponent/AqaComponent"

import {Checkbox, FormControlLabel, FormGroup} from "@material-ui/core"
import TextField  from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography"


const INPUT_LABEL_PROP_STYLE =
{
	padding:"0px",
	fontFamily:"Montserrat",
	fontSize:"12px",
	lineHeight:"10px",
	textAlign:"right"
};

const INPUT_PROP_STYLE =
{
	padding:"0px",
	fontFamily:"Montserrat",
	fontSize:"12px",
	lineHeight:"10px",
	textAlign:"right"
};



export default class AqaRulesTextThreshold extends AqaComponent
{

	constructor(props)
	{
		super(props);
		props.parent.registerTextBox(props.idName, this);
		this.state = 
		{
			value:props.value,
			errorValue:null,
		};
	} //

	/** Our parent can tell us when the value we hold is invalid */
	resetError()
	{
		this.setState({errorValue:null});
	} // setError


	setErrorValue(errorValue)
	{
		if (!errorValue) errorValue = "";
		this.setState({errorValue});

	} // setError




/*

	componentDidUpdate(prevProps)
	{
		if (prevProps.value !== this.props.value) this.setState({value:this.props.value});
	} // componentDidUpdate

*/


	render()
	{
		const inError = this.state.errorValue !== null;

		const style=
		{
			width:   this.props.type === "date" ? "95%" : "80%",
			margin:  0,
			padding: "3px"
		}
		if (inError) style.backgroundColor = "#f66";

		//const value = inError ? this.state.errorValue : this.state.value;

		// In Gen2, we probably won't need to have an id for these fields - but the names, yes, we will need them.
		return (
			<FormGroup row>
				<FormControlLabel
					control=
					{
						<Checkbox
							checked={this.props.checked}
							onChange={this.props.changeHandler}
							color="primary"
							style={{padding:"6px 8px 4px 12px"}}
						/>
					}
					label=
					{
						<div>
							{this.props.checked === true ?
								<Typography className={"aqa-text-action"} variant="title" align="left"
											style={{float: "left", width: "60px", paddingTop: "12px"}}>
									{this.props.caption}
								</Typography> :
								<Typography className={"aqa-text-action"} variant="title" align="left"
											style={{float: "left", width: "60px", paddingTop: "4px"}}>
									{this.props.caption}
								</Typography>
							}
							<Typography
								className={"aqa-text-action"}
								variant="title"
								align="left"
								style={{float:"left",width:"140px"}}
							>
								{
									this.props.checked === true
									?
										(
											<TextField
												id={this.props.idName}
												name={this.props.idName}
												value={this.props.value}
												className={"aqa-text-action-10"}
												variant="standard"
												margin="none"
												type={this.props.type}
												multiline={false}
												error={this.props.error}
												rows={1}
												style={style}
												onChange={this.props.textChangeHandler}
												required
												InputLabelProps={{style:INPUT_LABEL_PROP_STYLE}}
												InputProps={{style:INPUT_PROP_STYLE}}
											/>
										)
									:
										""
								}
							</Typography>

						</div>
					}
					labelPlacement="end"
					style={{margin:"0px",padding:"0px"}}
				/>
			</FormGroup>





		);
	} // render




} ////
