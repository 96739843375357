/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/AqaAnnotationScore', '../model/AqaBulkRowStatiRequest', '../model/AqaCellNote', '../model/AqaCellStatusResponse', '../model/AqaNoteResponse', '../model/AqaResponse', '../model/AqaRowCellNoteBlock', '../model/AqaRowStatusStatistics'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/AqaAnnotationScore'), require('../model/AqaBulkRowStatiRequest'), require('../model/AqaCellNote'), require('../model/AqaCellStatusResponse'), require('../model/AqaNoteResponse'), require('../model/AqaResponse'), require('../model/AqaRowCellNoteBlock'), require('../model/AqaRowStatusStatistics'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AQAReviewControllerApi = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.AqaAnnotationScore, root.ApiDocumentation.AqaBulkRowStatiRequest, root.ApiDocumentation.AqaCellNote, root.ApiDocumentation.AqaCellStatusResponse, root.ApiDocumentation.AqaNoteResponse, root.ApiDocumentation.AqaResponse, root.ApiDocumentation.AqaRowCellNoteBlock, root.ApiDocumentation.AqaRowStatusStatistics);
  }
}(this, function(ApiClient, AqaAnnotationScore, AqaBulkRowStatiRequest, AqaCellNote, AqaCellStatusResponse, AqaNoteResponse, AqaResponse, AqaRowCellNoteBlock, AqaRowStatusStatistics) {
  'use strict';

  /**
   * AQAReviewController service.
   * @module api/AQAReviewControllerApi
   * @version 1.0
   */

  /**
   * Constructs a new AQAReviewControllerApi. 
   * @alias module:api/AQAReviewControllerApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the closeStatusEditingOnSnapshotUsingPUT operation.
     * @callback module:api/AQAReviewControllerApi~closeStatusEditingOnSnapshotUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Closes a snapshot stati editing window
     * @param {String} snapshotId snapshotId
     * @param {module:api/AQAReviewControllerApi~closeStatusEditingOnSnapshotUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.closeStatusEditingOnSnapshotUsingPUT = function(snapshotId, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling closeStatusEditingOnSnapshotUsingPUT");
      }


      var pathParams = {
        'snapshotId': snapshotId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/review/snapshots/{snapshotId}/close', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getAnnotationsScoreUsingGET operation.
     * @callback module:api/AQAReviewControllerApi~getAnnotationsScoreUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaAnnotationScore} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves a Snapshot&#39;s cell override stati as a bitmap
     * @param {String} snapshotId snapshotId
     * @param {String} orientation orientation
     * @param {module:api/AQAReviewControllerApi~getAnnotationsScoreUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaAnnotationScore}
     */
    this.getAnnotationsScoreUsingGET = function(snapshotId, orientation, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getAnnotationsScoreUsingGET");
      }

      // verify the required parameter 'orientation' is set
      if (orientation === undefined || orientation === null) {
        throw new Error("Missing the required parameter 'orientation' when calling getAnnotationsScoreUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'orientation': orientation
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = AqaAnnotationScore;

      return this.apiClient.callApi(
        '/api/v1/review/snapshots/{snapshotId}/{orientation}/annotationscore', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getCellOverrideStatiUsingGET operation.
     * @callback module:api/AQAReviewControllerApi~getCellOverrideStatiUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {'Blob'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves a Snapshot&#39;s cell override stati as a bitmap
     * @param {String} snapshotId snapshotId
     * @param {String} orientation orientation
     * @param {module:api/AQAReviewControllerApi~getCellOverrideStatiUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'Blob'}
     */
    this.getCellOverrideStatiUsingGET = function(snapshotId, orientation, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getCellOverrideStatiUsingGET");
      }

      // verify the required parameter 'orientation' is set
      if (orientation === undefined || orientation === null) {
        throw new Error("Missing the required parameter 'orientation' when calling getCellOverrideStatiUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'orientation': orientation
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = 'Blob';

      return this.apiClient.callApi(
        '/api/v1/review/snapshots/{snapshotId}/{orientation}/stati/cells', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getNotesUsingGET operation.
     * @callback module:api/AQAReviewControllerApi~getNotesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaRowCellNoteBlock} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves a list of Cell notes whose index got from from to to - 1
     * @param {String} snapshotId snapshotId
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from from (default to 0)
     * @param {Number} opts.to to (default to 0)
     * @param {module:api/AQAReviewControllerApi~getNotesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaRowCellNoteBlock}
     */
    this.getNotesUsingGET = function(snapshotId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling getNotesUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId
      };
      var queryParams = {
        'from': opts['from'],
        'to': opts['to'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaRowCellNoteBlock;

      return this.apiClient.callApi(
        '/api/v1/review/snapshots/{snapshotId}/notes', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the retrieveStatiStatisticsUsingGET operation.
     * @callback module:api/AQAReviewControllerApi~retrieveStatiStatisticsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaRowStatusStatistics} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves a Snapshot&#39;s stati statistics - you must specify the unfiltered table id
     * @param {String} snapshotId snapshotId
     * @param {String} orientation orientation
     * @param {module:api/AQAReviewControllerApi~retrieveStatiStatisticsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaRowStatusStatistics}
     */
    this.retrieveStatiStatisticsUsingGET = function(snapshotId, orientation, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling retrieveStatiStatisticsUsingGET");
      }

      // verify the required parameter 'orientation' is set
      if (orientation === undefined || orientation === null) {
        throw new Error("Missing the required parameter 'orientation' when calling retrieveStatiStatisticsUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'orientation': orientation
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaRowStatusStatistics;

      return this.apiClient.callApi(
        '/api/v1/review/snapshots/{snapshotId}/{orientation}/stati/statistics', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the retrieveStatiUsingGET operation.
     * @callback module:api/AQAReviewControllerApi~retrieveStatiUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {'Blob'} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves a Snapshot&#39;s stati - you must specify the unfiltered table id
     * @param {String} snapshotId snapshotId
     * @param {String} orientation orientation
     * @param {module:api/AQAReviewControllerApi~retrieveStatiUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link 'Blob'}
     */
    this.retrieveStatiUsingGET = function(snapshotId, orientation, callback) {
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling retrieveStatiUsingGET");
      }

      // verify the required parameter 'orientation' is set
      if (orientation === undefined || orientation === null) {
        throw new Error("Missing the required parameter 'orientation' when calling retrieveStatiUsingGET");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'orientation': orientation
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = [];
      var accepts = ['*/*'];
      var returnType = 'Blob';

      return this.apiClient.callApi(
        '/api/v1/review/snapshots/{snapshotId}/{orientation}/stati', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the saveCellNoteUsingPUT operation.
     * @callback module:api/AQAReviewControllerApi~saveCellNoteUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaNoteResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Saves a combo cell suggested value / note
     * @param {module:model/AqaCellNote} note note
     * @param {module:api/AQAReviewControllerApi~saveCellNoteUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaNoteResponse}
     */
    this.saveCellNoteUsingPUT = function(note, callback) {
      var postBody = note;

      // verify the required parameter 'note' is set
      if (note === undefined || note === null) {
        throw new Error("Missing the required parameter 'note' when calling saveCellNoteUsingPUT");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaNoteResponse;

      return this.apiClient.callApi(
        '/api/v1/review/snapshots/notes', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the updateCellStatusInBulkUsingPUT operation.
     * @callback module:api/AQAReviewControllerApi~updateCellStatusInBulkUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaCellStatusResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates a cell stati in bulk - you must specify the unfiltered table id
     * @param {String} snapshotId snapshotId
     * @param {String} orientation orientation
     * @param {module:model/AqaBulkRowStatiRequest} brsr brsr
     * @param {Object} opts Optional parameters
     * @param {Number} opts.index index (default to -1)
     * @param {module:api/AQAReviewControllerApi~updateCellStatusInBulkUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaCellStatusResponse}
     */
    this.updateCellStatusInBulkUsingPUT = function(snapshotId, orientation, brsr, opts, callback) {
      opts = opts || {};
      var postBody = brsr;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling updateCellStatusInBulkUsingPUT");
      }

      // verify the required parameter 'orientation' is set
      if (orientation === undefined || orientation === null) {
        throw new Error("Missing the required parameter 'orientation' when calling updateCellStatusInBulkUsingPUT");
      }

      // verify the required parameter 'brsr' is set
      if (brsr === undefined || brsr === null) {
        throw new Error("Missing the required parameter 'brsr' when calling updateCellStatusInBulkUsingPUT");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'orientation': orientation
      };
      var queryParams = {
        'index': opts['index'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaCellStatusResponse;

      return this.apiClient.callApi(
        '/api/v1/review/snapshots/{snapshotId}/{orientation}/stati/cell/bulk', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the updateCellStatusUsingPUT operation.
     * @callback module:api/AQAReviewControllerApi~updateCellStatusUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaCellStatusResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates a cell&#39;s status - you must specify the unfiltered table id
     * @param {String} snapshotId snapshotId
     * @param {String} orientation orientation
     * @param {Object} opts Optional parameters
     * @param {Number} opts.position position (default to -1)
     * @param {Number} opts.index index (default to -1)
     * @param {Number} opts.value value (default to -1)
     * @param {module:api/AQAReviewControllerApi~updateCellStatusUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaCellStatusResponse}
     */
    this.updateCellStatusUsingPUT = function(snapshotId, orientation, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling updateCellStatusUsingPUT");
      }

      // verify the required parameter 'orientation' is set
      if (orientation === undefined || orientation === null) {
        throw new Error("Missing the required parameter 'orientation' when calling updateCellStatusUsingPUT");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'orientation': orientation
      };
      var queryParams = {
        'position': opts['position'],
        'index': opts['index'],
        'value': opts['value'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaCellStatusResponse;

      return this.apiClient.callApi(
        '/api/v1/review/snapshots/{snapshotId}/{orientation}/stati/cell', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the updateStatusInBulkUsingPUT operation.
     * @callback module:api/AQAReviewControllerApi~updateStatusInBulkUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates a vector multiple stati - you must specify the unfiltered table id
     * @param {String} snapshotId snapshotId
     * @param {String} orientation orientation
     * @param {module:model/AqaBulkRowStatiRequest} brsr brsr
     * @param {module:api/AQAReviewControllerApi~updateStatusInBulkUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.updateStatusInBulkUsingPUT = function(snapshotId, orientation, brsr, callback) {
      var postBody = brsr;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling updateStatusInBulkUsingPUT");
      }

      // verify the required parameter 'orientation' is set
      if (orientation === undefined || orientation === null) {
        throw new Error("Missing the required parameter 'orientation' when calling updateStatusInBulkUsingPUT");
      }

      // verify the required parameter 'brsr' is set
      if (brsr === undefined || brsr === null) {
        throw new Error("Missing the required parameter 'brsr' when calling updateStatusInBulkUsingPUT");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'orientation': orientation
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json;charset=UTF-8'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/review/snapshots/{snapshotId}/{orientation}/stati/bulk', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the updateStatusUsingPUT operation.
     * @callback module:api/AQAReviewControllerApi~updateStatusUsingPUTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AqaResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates a vector status - you must specify the unfiltered table id
     * @param {String} snapshotId snapshotId
     * @param {String} orientation orientation
     * @param {Object} opts Optional parameters
     * @param {Number} opts.position position (default to -1)
     * @param {Number} opts.value value (default to -1)
     * @param {module:api/AQAReviewControllerApi~updateStatusUsingPUTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AqaResponse}
     */
    this.updateStatusUsingPUT = function(snapshotId, orientation, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'snapshotId' is set
      if (snapshotId === undefined || snapshotId === null) {
        throw new Error("Missing the required parameter 'snapshotId' when calling updateStatusUsingPUT");
      }

      // verify the required parameter 'orientation' is set
      if (orientation === undefined || orientation === null) {
        throw new Error("Missing the required parameter 'orientation' when calling updateStatusUsingPUT");
      }


      var pathParams = {
        'snapshotId': snapshotId,
        'orientation': orientation
      };
      var queryParams = {
        'position': opts['position'],
        'value': opts['value'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json;charset=UTF-8'];
      var returnType = AqaResponse;

      return this.apiClient.callApi(
        '/api/v1/review/snapshots/{snapshotId}/{orientation}/stati', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
