/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.SimplifiedVectorSummary = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The SimplifiedVectorSummary model module.
   * @module model/SimplifiedVectorSummary
   * @version 1.0
   */

  /**
   * Constructs a new <code>SimplifiedVectorSummary</code>.
   * @alias module:model/SimplifiedVectorSummary
   * @class
   */
  var exports = function() {
    var _this = this;


























  };

  /**
   * Constructs a <code>SimplifiedVectorSummary</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SimplifiedVectorSummary} obj Optional instance to populate.
   * @return {module:model/SimplifiedVectorSummary} The populated <code>SimplifiedVectorSummary</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('allDictionaryWords')) {
        obj['allDictionaryWords'] = ApiClient.convertToType(data['allDictionaryWords'], 'Boolean');
      }
      if (data.hasOwnProperty('dateFirstQuartile')) {
        obj['dateFirstQuartile'] = ApiClient.convertToType(data['dateFirstQuartile'], 'String');
      }
      if (data.hasOwnProperty('dateThirdQuartile')) {
        obj['dateThirdQuartile'] = ApiClient.convertToType(data['dateThirdQuartile'], 'String');
      }
      if (data.hasOwnProperty('highestDate')) {
        obj['highestDate'] = ApiClient.convertToType(data['highestDate'], 'String');
      }
      if (data.hasOwnProperty('highestNumber')) {
        obj['highestNumber'] = ApiClient.convertToType(data['highestNumber'], 'Number');
      }
      if (data.hasOwnProperty('highestNumberOfRepeats')) {
        obj['highestNumberOfRepeats'] = ApiClient.convertToType(data['highestNumberOfRepeats'], 'Number');
      }
      if (data.hasOwnProperty('lowestDate')) {
        obj['lowestDate'] = ApiClient.convertToType(data['lowestDate'], 'String');
      }
      if (data.hasOwnProperty('lowestNumber')) {
        obj['lowestNumber'] = ApiClient.convertToType(data['lowestNumber'], 'Number');
      }
      if (data.hasOwnProperty('meanNumber')) {
        obj['meanNumber'] = ApiClient.convertToType(data['meanNumber'], 'Number');
      }
      if (data.hasOwnProperty('medianDate')) {
        obj['medianDate'] = ApiClient.convertToType(data['medianDate'], 'String');
      }
      if (data.hasOwnProperty('medianNumber')) {
        obj['medianNumber'] = ApiClient.convertToType(data['medianNumber'], 'Number');
      }
      if (data.hasOwnProperty('numberFirstQuartile')) {
        obj['numberFirstQuartile'] = ApiClient.convertToType(data['numberFirstQuartile'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDates')) {
        obj['numberOfDates'] = ApiClient.convertToType(data['numberOfDates'], 'Number');
      }
      if (data.hasOwnProperty('numberOfEmpties')) {
        obj['numberOfEmpties'] = ApiClient.convertToType(data['numberOfEmpties'], 'Number');
      }
      if (data.hasOwnProperty('numberOfMissings')) {
        obj['numberOfMissings'] = ApiClient.convertToType(data['numberOfMissings'], 'Number');
      }
      if (data.hasOwnProperty('numberOfMissingsOrEmpties')) {
        obj['numberOfMissingsOrEmpties'] = ApiClient.convertToType(data['numberOfMissingsOrEmpties'], 'Number');
      }
      if (data.hasOwnProperty('numberOfNumbers')) {
        obj['numberOfNumbers'] = ApiClient.convertToType(data['numberOfNumbers'], 'Number');
      }
      if (data.hasOwnProperty('numberOfStrings')) {
        obj['numberOfStrings'] = ApiClient.convertToType(data['numberOfStrings'], 'Number');
      }
      if (data.hasOwnProperty('numberOfUniques')) {
        obj['numberOfUniques'] = ApiClient.convertToType(data['numberOfUniques'], 'Number');
      }
      if (data.hasOwnProperty('numberOfduplicates')) {
        obj['numberOfduplicates'] = ApiClient.convertToType(data['numberOfduplicates'], 'Number');
      }
      if (data.hasOwnProperty('numberStandardDeviation')) {
        obj['numberStandardDeviation'] = ApiClient.convertToType(data['numberStandardDeviation'], 'Number');
      }
      if (data.hasOwnProperty('numberThirdQuartile')) {
        obj['numberThirdQuartile'] = ApiClient.convertToType(data['numberThirdQuartile'], 'Number');
      }
      if (data.hasOwnProperty('specialUniform')) {
        obj['specialUniform'] = ApiClient.convertToType(data['specialUniform'], 'Boolean');
      }
      if (data.hasOwnProperty('uniform')) {
        obj['uniform'] = ApiClient.convertToType(data['uniform'], 'Boolean');
      }
      if (data.hasOwnProperty('uniformType')) {
        obj['uniformType'] = ApiClient.convertToType(data['uniformType'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {Boolean} allDictionaryWords
   */
  exports.prototype['allDictionaryWords'] = undefined;
  /**
   * @member {String} dateFirstQuartile
   */
  exports.prototype['dateFirstQuartile'] = undefined;
  /**
   * @member {String} dateThirdQuartile
   */
  exports.prototype['dateThirdQuartile'] = undefined;
  /**
   * @member {String} highestDate
   */
  exports.prototype['highestDate'] = undefined;
  /**
   * @member {Number} highestNumber
   */
  exports.prototype['highestNumber'] = undefined;
  /**
   * @member {Number} highestNumberOfRepeats
   */
  exports.prototype['highestNumberOfRepeats'] = undefined;
  /**
   * @member {String} lowestDate
   */
  exports.prototype['lowestDate'] = undefined;
  /**
   * @member {Number} lowestNumber
   */
  exports.prototype['lowestNumber'] = undefined;
  /**
   * @member {Number} meanNumber
   */
  exports.prototype['meanNumber'] = undefined;
  /**
   * @member {String} medianDate
   */
  exports.prototype['medianDate'] = undefined;
  /**
   * @member {Number} medianNumber
   */
  exports.prototype['medianNumber'] = undefined;
  /**
   * @member {Number} numberFirstQuartile
   */
  exports.prototype['numberFirstQuartile'] = undefined;
  /**
   * @member {Number} numberOfDates
   */
  exports.prototype['numberOfDates'] = undefined;
  /**
   * @member {Number} numberOfEmpties
   */
  exports.prototype['numberOfEmpties'] = undefined;
  /**
   * @member {Number} numberOfMissings
   */
  exports.prototype['numberOfMissings'] = undefined;
  /**
   * @member {Number} numberOfMissingsOrEmpties
   */
  exports.prototype['numberOfMissingsOrEmpties'] = undefined;
  /**
   * @member {Number} numberOfNumbers
   */
  exports.prototype['numberOfNumbers'] = undefined;
  /**
   * @member {Number} numberOfStrings
   */
  exports.prototype['numberOfStrings'] = undefined;
  /**
   * @member {Number} numberOfUniques
   */
  exports.prototype['numberOfUniques'] = undefined;
  /**
   * @member {Number} numberOfduplicates
   */
  exports.prototype['numberOfduplicates'] = undefined;
  /**
   * @member {Number} numberStandardDeviation
   */
  exports.prototype['numberStandardDeviation'] = undefined;
  /**
   * @member {Number} numberThirdQuartile
   */
  exports.prototype['numberThirdQuartile'] = undefined;
  /**
   * @member {Boolean} specialUniform
   */
  exports.prototype['specialUniform'] = undefined;
  /**
   * @member {Boolean} uniform
   */
  exports.prototype['uniform'] = undefined;
  /**
   * @member {String} uniformType
   */
  exports.prototype['uniformType'] = undefined;



  return exports;
}));


