/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaSource = factory(root.ApiDocumentation.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The AqaSource model module.
   * @module model/AqaSource
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaSource</code>.
   * @alias module:model/AqaSource
   * @class
   */
  var exports = function() {
    var _this = this;


















  };

  /**
   * Constructs a <code>AqaSource</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaSource} obj Optional instance to populate.
   * @return {module:model/AqaSource} The populated <code>AqaSource</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('creadtedById')) {
        obj['creadtedById'] = ApiClient.convertToType(data['creadtedById'], 'String');
      }
      if (data.hasOwnProperty('createdBy')) {
        obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
      }
      if (data.hasOwnProperty('dateCreated')) {
        obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Number');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('example')) {
        obj['example'] = ApiClient.convertToType(data['example'], 'Boolean');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('imported')) {
        obj['imported'] = ApiClient.convertToType(data['imported'], 'Boolean');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('orientation')) {
        obj['orientation'] = ApiClient.convertToType(data['orientation'], 'String');
      }
      if (data.hasOwnProperty('originalAccountId')) {
        obj['originalAccountId'] = ApiClient.convertToType(data['originalAccountId'], 'String');
      }
      if (data.hasOwnProperty('originalSourceId')) {
        obj['originalSourceId'] = ApiClient.convertToType(data['originalSourceId'], 'String');
      }
      if (data.hasOwnProperty('originalTimestamp')) {
        obj['originalTimestamp'] = ApiClient.convertToType(data['originalTimestamp'], 'Number');
      }
      if (data.hasOwnProperty('publicationId')) {
        obj['publicationId'] = ApiClient.convertToType(data['publicationId'], 'String');
      }
      if (data.hasOwnProperty('subscribedId')) {
        obj['subscribedId'] = ApiClient.convertToType(data['subscribedId'], 'String');
      }
      if (data.hasOwnProperty('subscribedQdId')) {
        obj['subscribedQdId'] = ApiClient.convertToType(data['subscribedQdId'], 'String');
      }
      if (data.hasOwnProperty('timestamp')) {
        obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Number');
      }
      if (data.hasOwnProperty('umbrellaIds')) {
        obj['umbrellaIds'] = ApiClient.convertToType(data['umbrellaIds'], ['String']);
      }
    }
    return obj;
  }

  /**
   * @member {String} creadtedById
   */
  exports.prototype['creadtedById'] = undefined;
  /**
   * @member {String} createdBy
   */
  exports.prototype['createdBy'] = undefined;
  /**
   * @member {Number} dateCreated
   */
  exports.prototype['dateCreated'] = undefined;
  /**
   * @member {String} description
   */
  exports.prototype['description'] = undefined;
  /**
   * @member {Boolean} example
   */
  exports.prototype['example'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {Boolean} imported
   */
  exports.prototype['imported'] = undefined;
  /**
   * @member {String} name
   */
  exports.prototype['name'] = undefined;
  /**
   * @member {module:model/AqaSource.OrientationEnum} orientation
   */
  exports.prototype['orientation'] = undefined;
  /**
   * @member {String} originalAccountId
   */
  exports.prototype['originalAccountId'] = undefined;
  /**
   * @member {String} originalSourceId
   */
  exports.prototype['originalSourceId'] = undefined;
  /**
   * @member {Number} originalTimestamp
   */
  exports.prototype['originalTimestamp'] = undefined;
  /**
   * @member {String} publicationId
   */
  exports.prototype['publicationId'] = undefined;
  /**
   * @member {String} subscribedId
   */
  exports.prototype['subscribedId'] = undefined;
  /**
   * @member {String} subscribedQdId
   */
  exports.prototype['subscribedQdId'] = undefined;
  /**
   * @member {Number} timestamp
   */
  exports.prototype['timestamp'] = undefined;
  /**
   * @member {Array.<String>} umbrellaIds
   */
  exports.prototype['umbrellaIds'] = undefined;


  /**
   * Allowed values for the <code>orientation</code> property.
   * @enum {String}
   * @readonly
   */
  exports.OrientationEnum = {
    /**
     * value: "column"
     * @const
     */
    "column": "column",
    /**
     * value: "row"
     * @const
     */
    "row": "row"  };


  return exports;
}));


