/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
    Checkbox,
    FormControlLabel, FormGroup, FormLabel,
    Radio, RadioGroup
} from "@material-ui/core";

import FormControl from "@material-ui/core/FormControl"

import AqaBoundaries         from "../../../model/AqaBoundaries"
import AqaQualityButton      from "./subelements/AqaQualityButton"
import AqaRulesGraph         from "./subelements/AqaRulesGraph"
import AqaRulesTextThreshold from "./subelements/AqaRulesTextThreshold"

import AqaRulesParent, {UNIQUE_PERCENTAGE, DUPLICATES_ALLOWED, REAPEATS_ALLOWED}   from "./AqaRulesParent"

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import AqaOutline from '../../shared/outline/AqaOutline'

const RADIO_BUTTONS = [[UNIQUE_PERCENTAGE, "% Unique"], [DUPLICATES_ALLOWED, "# Duplicates"], [REAPEATS_ALLOWED, "# Repeats"]];


const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);


class AqaRulesUniqueness extends AqaRulesParent
{
	constructor(props)
	{
		super(props, "Uniqueness");

		this.state =
		{
			error: null,
			notfound: false,
			isLoaded: true,

			currentSnapshot:"allsources", // What is this for??

			invertZones:false,
			width:960,

		};


	} //



	render()
	{

		const {error, isLoaded, notfound, hasError, width, invertZones} = this.state;
		
		const minMaxArray	= [this.wideMinRange, this.wideMaxRange];
		const enabled = this.isRulesEnabled();

		if (notfound && error)
		{
			return (
				<div className={"snapshot-rules-servant-pane"}>
					<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

						<Grid item xs={10} sm={7} lg={11} xl={11}>
							<Typography variant="title" color="inherit" align="left" style={{ fontSize: '1.5rem', padding: '0.1em 0.5em', fontFamily: 'Open Sans, sans-serif'}}>
								Nothing to display
							</Typography>
						</Grid>

						<Grid item xs={2} sm={1} lg={1} xl={1} />


					</Grid>
				</div>
			);
		}
		else if (error && !notfound)
		{
			return <div>
				<Typography variant="title" color="inherit" align="left" style={{ fontSize: '1.5rem', padding: '3.1em 0.5em', fontFamily: 'Open Sans, sans-serif'}}>
					Error: {error.message}
				</Typography>
			</div>;
		}
		else if (!isLoaded)
		{
			return <div>Loading...</div>;
		}
		else
		{
			return (
				<div className={"snapshot-rule-servant-pane"}>
					<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

						<Grid item xs={12} sm={12} lg={12} xl={12}>
							<div style={{padding:"0px"}}>
								<AqaQualityButton which={0} />

								<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"16px",color:"#4CADC4",paddingLeft:"4px",fontFamily:"Montserrat",borderBottom:"0px solid #ccc",marginLeft:"55px"}}>
									<div id="aqa-number-slider" style={{paddingTop:"0px",paddingLeft:"0px",marginLeft:"8px",marginTop:"-45px"}}>
										<FormGroup row>

											<FormControlLabel
												control={
													<Checkbox
														checked={enabled}
														onChange={e => this.setRulesEnabled(e.target.checked)}
														color="primary"
													/>
												}
												label={
													(
														<Typography className={"aqa-text-action"} variant="title" align="left" style={{ }}>
															Set Uniqueness thresholds
														</Typography>
													)
												}
												labelPlacement="end"
											/>
											
											
										</FormGroup>
									</div>
								</Typography>
							</div>


							{
								enabled
								?
								(

									<div>
										<div style={{padding:"0px", marginLeft:"0px", width:"960px"}}>
											<Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start" style={{padding: 0, marginTop: "-45px"}}>

												<Grid item xs={12} sm={12} lg={12} xl={12}>
													<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"16px",color:"#4CADC4",paddingLeft:"4px",fontFamily:"Montserrat",marginLeft:"20px"}}>
														<div style={{paddingBottom:"10px"}}>
															<FormControl component="fieldset" size="small" fullWidth>

																<Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start" style={{padding: 0, marginTop: "-5px"}}>
																	<Grid item xs={12} sm={12} lg={12} xl={12}>
																		<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>
																			<Grid item xs={10} sm={10} lg={10} xl={10}>
																				<Typography className="aqa-text-action" variant="inherit" style={{textAlign:"left",paddingLeft:"0px",paddingRight:"0px",marginTop:"0px",marginLeft:"0px"}}>

																				</Typography>
																			</Grid>
																			<Grid item xs={2} sm={2} lg={2} xl={2}>
																				<div style={{paddingLeft:"50px",marginRight:"-35px"}}>
																					<FormGroup row>
																						<FormControlLabel
																							control={
																								<Checkbox
																									checked={invertZones}
																									onChange={e => this.handleInvertZone(e.target.checked)}
																									name="invertZones"
																									color="primary"
																								/>
																							}
																							label={
																								(
																									<Typography className={"aqa-text-action"} variant="title" align="right" style={{ }}>
																										Invert Zones
																									</Typography>
																								)
																							}
																							labelPlacement="end"
																						/>
																					</FormGroup>
																				</div>
																			</Grid>
																		</Grid>
																	</Grid>
																	
																	<Grid item xs={12} sm={12} lg={12} xl={12}>
																		<div
																			style={{
																				position: "relative",
																				textAlign: "left",
																				marginLeft:"0px",
																			}}
																		>

																			<AqaRulesGraph
																				width={width}
																				height={200}
																				zones={AqaBoundaries.makeColourZones(this.rules, this.typeName, this.scaleFromValueToScreenValue, minMaxArray)}
																			/>

																		</div>

																		<div style={{width: width, marginTop:"65px", marginLeft:"0"}}>

																			<Range
																				count={2}
																				min={this.wideMinRange}
																				max={this.wideMaxRange}
																				value={this.boundaryValues}
																				marks={this.marks}

																				included={false}
																				pushable={1}
																				step={0.01}
//																				allowCross={false}
																				tipProps={{visible:true}}
																				onChange={this.handleRangeChange2}
																				reverse={false}
																			/>
																			


																		</div>
																	</Grid>

																	<Grid item xs={12} sm={12} lg={12} xl={12} style={{zIndex:1200}}>
																		<Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{padding: 0, marginTop: 0}}>

																			<Grid item xs={5} sm={5} lg={5} xl={5}>
																				<Typography className="aqa-text-action" variant="inherit" style={{textAlign:"center",paddingLeft:"0px",paddingRight:"0px",marginTop:"25px",marginLeft:"-110px"}} />





																					<AqaOutline label={"Options"}>
																						<div>
																							<FormControl component="fieldset" size="small" fullWidth>
																								<FormLabel component="legend" />

																								<RadioGroup
																									aria-label="uniqueness"
																									name="uniquenessType"
																									value={this.rules.uniquenessType}
																									color="primary"
																									onChange={this.handleRadioChange2}
																								>

																									{
																										RADIO_BUTTONS.map
																										(
																											(r, i) =>
																											<FormControlLabel
																												key={`utrb${i}`}
																												value={r[0]}
																												control={<Radio color="primary" style={{padding:"4px"}}/>}
																												label={
																													(
																														<Typography className={"aqa-text-action"} variant="title" align="left">
																															{r[1]}
																														</Typography>
																													)
																												}
																												style={{width:"140px", margin:"0"}}
																											/>
																										)
																									}

																								</RadioGroup>
																							</FormControl>


																						</div>
																					</AqaOutline>

																				</Grid>
																				<Grid item xs={3} sm={3} lg={3} xl={3} />

																				<Grid item xs={4} sm={4} lg={4} xl={4}>
																					<Typography variant="inherit" style={{textAlign:"right",paddingLeft:"0px",paddingRight:"0px",marginTop:"25px",marginLeft:"0px",marginRight:"-5px"}}>


																						<AqaOutline label={"Threshold"}>
																							<div>

																								<AqaRulesTextThreshold
																									idName="uniquenessBoundary.number1"
																									type="number"
																									caption={invertZones ? "Amber" : "Red"}
																									checked={this.isBoundaryValueActivated("", 1)}
																									changeHandler={e => this.setBoundaryValueActivated("", 1, e.target.checked)}
																									value=
																									{
																										this.rules.uniquenessBoundary
																										?
																											this.rules.uniquenessBoundary.number1
																										:
																											0
																									}
																									error={hasError}
																									textChangeHandler={this.handleTextChange2}
																									parent={this}
																								/>

																								<AqaRulesTextThreshold
																									idName="uniquenessBoundary.number2"
																									type="number"
																									caption={invertZones ? "Red" : "Amber"}
																									checked={this.isBoundaryValueActivated("", 2)}
																									changeHandler={e => this.setBoundaryValueActivated("", 2, e.target.checked)}
																									value=
																									{
																										this.rules.uniquenessBoundary
																										?
																											this.rules.uniquenessBoundary.number2
																										:
																											0
																									}
																									error={hasError}
																									textChangeHandler={this.handleTextChange2}
																									parent={this}
																								/>


																							</div>
																						</AqaOutline>
																					</Typography>
																				</Grid>

																			</Grid>
																		</Grid>

																	</Grid>

																</FormControl>
															</div>
														</Typography>
													</Grid>
												</Grid>
											</div>

										</div>
									)
								:
									null
							}
						</Grid>

					</Grid>

				</div>
			);
		}
	} // render

} ////

AqaRulesUniqueness.propTypes =
{
	classes: PropTypes.object.isRequired
};

export default withStyles(AqaRulesParent.styles)(AqaRulesUniqueness);



