
import AqaComponent from "../components/shared/aqacomponent/AqaComponent"

export default class RowArrayCachingObject
{


	constructor(viewId)
	{
	
// onsole.log("CONSTRUCTING");
	
		this.viewId = viewId;
		this.rows = false;
	}


	load(startRow, rowsNeeded, postLoadOp)
	{

// onsole.log("RowArrayCachingObject: Loading " + startRow + " " + rowsNeeded);


		const endRow = startRow + rowsNeeded;
		let extraRowsNeeded, newRows;
		let loaded = false;

		if (this.rows)
		{
		
			// We have rows, we've been here before.

// onsole.log("RowArrayCachingObject: Match!!");

			let i;
			if (startRow === this.start && endRow === this.end)
			{
// onsole.log("RowArrayCachingObject: SAME ?!?!?!?");
				postLoadOp(this.rows);
				return;
			}
			else if (startRow >= this.start && endRow <= this.end)
			{
				// We already have all the rows we need, we just need to extract them
				// No call to BE needed, yay! (Ilua)	
// onsole.log("RowArrayCachingObject: We need a subset of the cached rows");
				let d = startRow - this.start;
				newRows = Array(rowsNeeded);
				for(i = 0; i < rowsNeeded; i++) newRows[i] = this.rows[d++];
				this.rows = newRows;
				
				postLoadOp(newRows);
				loaded = true;
			}
			else if (startRow < this.start && endRow > this.start && endRow <= this.end)
			{
				// We need to get some rows BEFORE the current cache
				
// onsole.log("RowArrayCachingObject: We need a bit BEFORE");
				
				extraRowsNeeded = this.start - startRow;

				this.systemLoad
				(
					startRow,
					extraRowsNeeded,
					data =>
					{
						newRows = Array(rowsNeeded);
						for(i = 0; i < extraRowsNeeded; i++) newRows[i] = data[i];
						const numberOfExistingRowsNeeded = rowsNeeded - extraRowsNeeded;
						for(i = 0; i < numberOfExistingRowsNeeded; i++) newRows[extraRowsNeeded++] = this.rows[i];

						this.rows = newRows;
						this.start = startRow;
						this.end = endRow;
						postLoadOp(newRows);
					}
				);
				return;
			
			
			}
			else if (startRow >= this.start && startRow < this.end && endRow > this.end)
			{
				// We need a bit after the current cache
				
// onsole.log("RowArrayCachingObject: We need a bit AFTER");
				
				extraRowsNeeded = endRow - this.end;
				this.systemLoad
				(
					this.end,
					extraRowsNeeded,
					data =>
					{
						newRows = Array(rowsNeeded);
						let from = startRow - this.start;
						let to = this.end - this.start;

// onsole.log("1: " + startRow + " >< " + this.start);
// onsole.log("2: " + this.start + " >< " + this.end);

// onsole.log("<" + from + " | " + to + ">");
						
						
						let p = 0;
						for(i = from; i < to; i++) newRows[p++] = this.rows[i];
						for(i = 0; i < extraRowsNeeded; i++) newRows[p++] = data[i];
						
						
						
						this.rows = newRows;
						this.start = startRow;
						this.end = endRow;
						postLoadOp(newRows);
					}
				);
				return;
			
			}
			else if (startRow < this.start && endRow > this.end)
			{
				// We need rows BEFORE *and* AFTER (Yes it's unlikely but 1. I like to be complete and 2 it may happen when the user resizes their page) 
				// Possible opti: skip if only a few rows in between: && this.rows.length > (for example) 5 


// onsole.log("RowArrayCachingObject: We need a bit AFTER");

				newRows = Array(rowsNeeded);
				extraRowsNeeded = this.start - startRow;
				let p = 0;
				
				
				// First call
				this.systemLoad
				(
					startRow,
					extraRowsNeeded,
					data =>
					{
						for(i = 0; i < extraRowsNeeded; i++) newRows[p++] = data[i]; // What we just loaded (before)
						let n = this.end - this.start;
						for(i = 0; i < n; i++) newRows[p++] = this.rows[i]; // All we got already
						extraRowsNeeded = endRow - this.end;
						this.systemLoad
						(
							this.endRow,
							extraRowsNeeded,
							data =>
							{
								for(i = 0; i < extraRowsNeeded; i++) newRows[p++] = data[i]; // What we just loaded (after)

								this.rows = newRows;
								this.start = startRow;
								this.end = endRow;
								postLoadOp(newRows);
							}
						);
					}
				);
				return;
			}
	
		}


// onsole.log("RowArrayCachingObject: no match!!");


		// No match
		// Easy one: we just load all the needed rows.
		this.start = startRow;
		this.end = endRow;
		if (!loaded) this.systemLoad
		(
			startRow,
			rowsNeeded,
			(data) => 
			{
				this.rows = data; // caching
				postLoadOp(data);
			}
		);



	
	
	
	} // load




	systemLoad(startRow, quantity, dataProcessor)
	{
		AqaComponent.snapshotBackend.getRowVectorValuesUsingGET
		(
			this.viewId,
			startRow,
			{quantity},
			(error, data) =>
			{
				if (error)
				{
					this.reportError("There was a problem retrieving the data.", "SnapshotDetail, pumpRows: " + error,this);
					return;
				}
				else
				{
					let j, n = data ? data.length : 0;
					for(j=0; j < n; j++) {
						data[j].valuesMap = data[j].values.map(v => v.coercedValue);
						data[j].types = data[j].values.map(v => v.type);
					}
						// this.extractAndPadVectorValues(data[j].values, this.currentView.table.data.numberOfColumns);
					dataProcessor(data);
				}
			}
		);

	} // load


} ////
