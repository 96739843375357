/* Welcome to Aqa*/
/* Version: 1 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as GridIcon} from "../../images/aqa-icons/Grid-Viewcol.svg";
import {ReactComponent as ListIcon} from "../../images/aqa-icons/List-Viewcol.svg";
import HtmlTooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaSourceToggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            periodicities: [],
            periodicity: "daily",
            SourceName: "",
            SourceDescription: null,
            errorMessage: "",
            toggleListClasses: "aqa-icon-active",
            toggleGridClasses: "aqa-icon",
            isGrid: false,
            toggleListIconColor:"white",
            toggleGridIconColor: "#000066"

        };

    }

    handleToggle = (e,isGrid)=>{
        e.preventDefault();

        if(!isGrid){

            this.setState({isGrid:true,"toggleGridClasses":"aqa-icon-active",
                "toggleListClasses":"aqa-icon",toggleListIconColor:"#000066",toggleGridIconColor:"white"});
        }else{
            this.setState({isGrid: false,"toggleListClasses":"aqa-icon-active",
                "toggleGridClasses":"aqa-icon",toggleGridIconColor:"#000066",toggleListIconColor:"white"});
        }
        this.props.dataFromParent.props.dataFromMainFrame.handleToggle(!isGrid);
        this.props.dataFromParent.props.dataFromParent.handleToggle(isGrid);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({isGrid:nextProps.dataForIcon});
        if(nextProps.dataForIcon){

            this.setState({isGrid:true,"toggleGridClasses":"aqa-icon-active",
                "toggleListClasses":"aqa-icon",toggleListIconColor:"#000066",toggleGridIconColor:"white"});
        }else{
            this.setState({isGrid: false,"toggleListClasses":"aqa-icon-active",
                "toggleGridClasses":"aqa-icon",toggleGridIconColor:"#000066",toggleListIconColor:"white"});
        }
    }

    componentDidMount(){
        this.setState({isGrid:this.props.dataForIcon});
        if(this.props.dataForIcon){

            this.setState({isGrid:true,"toggleGridClasses":"aqa-icon-active",
                "toggleListClasses":"aqa-icon",toggleListIconColor:"#000066",toggleGridIconColor:"white"});
        }else{
            this.setState({isGrid: false,"toggleListClasses":"aqa-icon-active",
                "toggleGridClasses":"aqa-icon",toggleGridIconColor:"#000066",toggleListIconColor:"white"});
        }
    }
    render() {
        //const { classes } = this.props;
        const { error, redirect, notfound,isLoaded,toggleListClasses,toggleGridClasses } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={3} sm={3} lg={3} xl={3}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%"}}>
                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                    <div>
                                        <Typography color="inherit" className="aqa-text-action">Click to change to list view</Typography>
                                    </div>
                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <IconButton onClick={(e)=>this.handleToggle(e,true)} className="aqa-button" >

                                    <div className={toggleListClasses}>
                                        <ListIcon width="32px" />
                                    </div>

                                </IconButton>
                                </HtmlTooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} lg={2} xl={2}>
                        </Grid>
                        <Grid item xs={3} sm={3} lg={3} xl={3}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%"}}>
                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                    <div>
                                        <Typography color="inherit" className="aqa-text-action">Click to change to grid view</Typography>
                                    </div>
                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                <IconButton onClick={(e)=>this.handleToggle(e,false)} className="aqa-button" >

                                    <div className={toggleGridClasses} style={{width:"32px"}}>
                                        <GridIcon width="32px" />
                                    </div>

                                </IconButton>
                                </HtmlTooltip>

                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} lg={4} xl={4}>
                        </Grid>

                    </Grid>

                </div>
            );
        }


    }
}

AqaSourceToggle.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSourceToggle);

