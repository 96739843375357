/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.3.0
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['../ApiClient', '../model/AqaFinding'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./AqaFinding'));
  } else {
    // Browser globals (root is window)
    if (!root.ApiDocumentation) {
      root.ApiDocumentation = {};
    }
    root.ApiDocumentation.AqaVector = factory(root.ApiDocumentation.ApiClient, root.ApiDocumentation.AqaFinding);
  }
}(this, function(ApiClient, AqaFinding) {
  'use strict';




  /**
   * The AqaVector model module.
   * @module model/AqaVector
   * @version 1.0
   */

  /**
   * Constructs a new <code>AqaVector</code>.
   * @alias module:model/AqaVector
   * @class
   */
  var exports = function() {
    var _this = this;

























  };

  /**
   * Constructs a <code>AqaVector</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AqaVector} obj Optional instance to populate.
   * @return {module:model/AqaVector} The populated <code>AqaVector</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('allDictionaryWordsFlag')) {
        obj['allDictionaryWordsFlag'] = ApiClient.convertToType(data['allDictionaryWordsFlag'], 'Boolean');
      }
      if (data.hasOwnProperty('dateFormat')) {
        obj['dateFormat'] = ApiClient.convertToType(data['dateFormat'], 'String');
      }
      if (data.hasOwnProperty('distributions')) {
        obj['distributions'] = ApiClient.convertToType(data['distributions'], {'String': {'String': 'Number'}});
      }
      if (data.hasOwnProperty('findings')) {
        obj['findings'] = ApiClient.convertToType(data['findings'], {'String': [AqaFinding]});
      }
      if (data.hasOwnProperty('firstCellStringFlag')) {
        obj['firstCellStringFlag'] = ApiClient.convertToType(data['firstCellStringFlag'], 'Boolean');
      }
      if (data.hasOwnProperty('header')) {
        obj['header'] = ApiClient.convertToType(data['header'], 'String');
      }
      if (data.hasOwnProperty('highestNumberOfRepeats')) {
        obj['highestNumberOfRepeats'] = ApiClient.convertToType(data['highestNumberOfRepeats'], 'Number');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('matrix')) {
        obj['matrix'] = ApiClient.convertToType(data['matrix'], [['Boolean']]);
      }
      if (data.hasOwnProperty('numberOfDifferentTypes')) {
        obj['numberOfDifferentTypes'] = ApiClient.convertToType(data['numberOfDifferentTypes'], 'Number');
      }
      if (data.hasOwnProperty('numberOfDuplicates')) {
        obj['numberOfDuplicates'] = ApiClient.convertToType(data['numberOfDuplicates'], 'Number');
      }
      if (data.hasOwnProperty('numberOfEmpties')) {
        obj['numberOfEmpties'] = ApiClient.convertToType(data['numberOfEmpties'], 'Number');
      }
      if (data.hasOwnProperty('numberOfMissings')) {
        obj['numberOfMissings'] = ApiClient.convertToType(data['numberOfMissings'], 'Number');
      }
      if (data.hasOwnProperty('numberOfMissingsOrEmpties')) {
        obj['numberOfMissingsOrEmpties'] = ApiClient.convertToType(data['numberOfMissingsOrEmpties'], 'Number');
      }
      if (data.hasOwnProperty('numberOfUniques')) {
        obj['numberOfUniques'] = ApiClient.convertToType(data['numberOfUniques'], 'Number');
      }
      if (data.hasOwnProperty('orientation')) {
        obj['orientation'] = ApiClient.convertToType(data['orientation'], 'String');
      }
      if (data.hasOwnProperty('originalPosition')) {
        obj['originalPosition'] = ApiClient.convertToType(data['originalPosition'], 'Number');
      }
      if (data.hasOwnProperty('position')) {
        obj['position'] = ApiClient.convertToType(data['position'], 'Number');
      }
      if (data.hasOwnProperty('snapshotId')) {
        obj['snapshotId'] = ApiClient.convertToType(data['snapshotId'], 'String');
      }
      if (data.hasOwnProperty('specialUniform')) {
        obj['specialUniform'] = ApiClient.convertToType(data['specialUniform'], 'Boolean');
      }
      if (data.hasOwnProperty('typeCounts')) {
        obj['typeCounts'] = ApiClient.convertToType(data['typeCounts'], ['Number']);
      }
      if (data.hasOwnProperty('uniformFlag')) {
        obj['uniformFlag'] = ApiClient.convertToType(data['uniformFlag'], 'Boolean');
      }
      if (data.hasOwnProperty('uniformType')) {
        obj['uniformType'] = ApiClient.convertToType(data['uniformType'], 'String');
      }
      if (data.hasOwnProperty('uniformityType')) {
        obj['uniformityType'] = ApiClient.convertToType(data['uniformityType'], 'Number');
      }
    }
    return obj;
  }

  /**
   * @member {Boolean} allDictionaryWordsFlag
   */
  exports.prototype['allDictionaryWordsFlag'] = undefined;
  /**
   * @member {String} dateFormat
   */
  exports.prototype['dateFormat'] = undefined;
  /**
   * @member {Object.<String, Object.<String, Number>>} distributions
   */
  exports.prototype['distributions'] = undefined;
  /**
   * @member {Object.<String, Array.<module:model/AqaFinding>>} findings
   */
  exports.prototype['findings'] = undefined;
  /**
   * @member {Boolean} firstCellStringFlag
   */
  exports.prototype['firstCellStringFlag'] = undefined;
  /**
   * @member {String} header
   */
  exports.prototype['header'] = undefined;
  /**
   * @member {Number} highestNumberOfRepeats
   */
  exports.prototype['highestNumberOfRepeats'] = undefined;
  /**
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {Array.<Array.<Boolean>>} matrix
   */
  exports.prototype['matrix'] = undefined;
  /**
   * @member {Number} numberOfDifferentTypes
   */
  exports.prototype['numberOfDifferentTypes'] = undefined;
  /**
   * @member {Number} numberOfDuplicates
   */
  exports.prototype['numberOfDuplicates'] = undefined;
  /**
   * @member {Number} numberOfEmpties
   */
  exports.prototype['numberOfEmpties'] = undefined;
  /**
   * @member {Number} numberOfMissings
   */
  exports.prototype['numberOfMissings'] = undefined;
  /**
   * @member {Number} numberOfMissingsOrEmpties
   */
  exports.prototype['numberOfMissingsOrEmpties'] = undefined;
  /**
   * @member {Number} numberOfUniques
   */
  exports.prototype['numberOfUniques'] = undefined;
  /**
   * @member {module:model/AqaVector.OrientationEnum} orientation
   */
  exports.prototype['orientation'] = undefined;
  /**
   * @member {Number} originalPosition
   */
  exports.prototype['originalPosition'] = undefined;
  /**
   * @member {Number} position
   */
  exports.prototype['position'] = undefined;
  /**
   * @member {String} snapshotId
   */
  exports.prototype['snapshotId'] = undefined;
  /**
   * @member {Boolean} specialUniform
   */
  exports.prototype['specialUniform'] = undefined;
  /**
   * @member {Array.<Number>} typeCounts
   */
  exports.prototype['typeCounts'] = undefined;
  /**
   * @member {Boolean} uniformFlag
   */
  exports.prototype['uniformFlag'] = undefined;
  /**
   * @member {module:model/AqaVector.UniformTypeEnum} uniformType
   */
  exports.prototype['uniformType'] = undefined;
  /**
   * @member {Number} uniformityType
   */
  exports.prototype['uniformityType'] = undefined;


  /**
   * Allowed values for the <code>orientation</code> property.
   * @enum {String}
   * @readonly
   */
  exports.OrientationEnum = {
    /**
     * value: "column"
     * @const
     */
    "column": "column",
    /**
     * value: "row"
     * @const
     */
    "row": "row"  };

  /**
   * Allowed values for the <code>uniformType</code> property.
   * @enum {String}
   * @readonly
   */
  exports.UniformTypeEnum = {
    /**
     * value: "unknown"
     * @const
     */
    "unknown": "unknown",
    /**
     * value: "string"
     * @const
     */
    "string": "string",
    /**
     * value: "number"
     * @const
     */
    "number": "number",
    /**
     * value: "date"
     * @const
     */
    "date": "date",
    /**
     * value: "nativeError"
     * @const
     */
    "nativeError": "nativeError"  };


  return exports;
}));


