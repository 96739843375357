
import React, {Component} from 'react';

import IconButton from "@material-ui/core/IconButton";

import {ReactComponent as JTLIcon} from "../../../images/aqa-icons/Jump To Left Arrowcol.svg"
import {ReactComponent as JTRIcon} from "../../../images/aqa-icons/Jump To Right Arrowcol.svg"
import {ReactComponent as MTLIcon} from "../../../images/aqa-icons/Move Left Arrowcol.svg"
import {ReactComponent as MTRIcon} from "../../../images/aqa-icons/Move Right Arrowcol.svg"
import {ReactComponent as JTLGreyIcon} from "../../../images/aqa-icons/Jump To Left Arrowcol_grey.svg"
import {ReactComponent as JTRGreyIcon} from "../../../images/aqa-icons/Jump To Right Arrowcol_grey.svg"
import {ReactComponent as MTLGreyIcon} from "../../../images/aqa-icons/Move Left Arrowcol_grey.svg"
import {ReactComponent as MTRGreyIcon} from "../../../images/aqa-icons/Move Right Arrowcol_grey.svg"
import {ReactComponent as JTTIcon} from "../../../images/aqa-icons/Jump To Top Arrowcol.svg";
import {ReactComponent as MTTIcon} from "../../../images/aqa-icons/Move Up Arrowcol.svg";
import {ReactComponent as MTBIcon} from "../../../images/aqa-icons/Move Down Arrowcol.svg";
import {ReactComponent as JTBIcon} from "../../../images/aqa-icons/Jump To Bottom Arrowcol.svg";




const arrowTypes =
{
	bigLeft: <JTLIcon width="32px" />,
	left:    <MTLIcon width="32px" />,
	right:   <MTRIcon width="32px" />,
	bigRight:<JTRIcon width="32px" />,

	bigUp:   <JTTIcon width="32px" />,
	up:      <MTTIcon width="32px" />,
	down:    <MTBIcon width="32px" />,
	bigDown: <JTBIcon width="32px" />
};

const arrowTypesGrey =
	{
		bigLeft: <JTLGreyIcon width="32px" />,
		left:    <MTLGreyIcon width="32px" />,
		right:   <MTRGreyIcon width="32px" />,
		bigRight:<JTRGreyIcon width="32px" />,

		bigUp:   <JTTIcon width="32px" />,
		up:      <MTTIcon width="32px" />,
		down:    <MTBIcon width="32px" />,
		bigDown: <JTBIcon width="32px" />
	};


/*
const verticalStyle =
{


};
*/

/*
let style =
{
	padding: "0px",
	marginLeft:"-4px"
};
*/



export default class Arrow extends Component
{
	render()
	{
		let totalCols = this.props.controller!==undefined?this.props.controller.nColumns:0;
		let currentPosition = this.props.controller!==undefined?this.props.controller.horizontalPosition:0;
		let colsToDraw = this.props.controller!==undefined?this.props.controller.numberOfColumnsToDraw:0;
		let allCols = currentPosition + colsToDraw;
		return (
			<IconButton className="aqa-button" onClick={this.props.onClick} style={this.props.style}>
				<div className="aqa-toggle-nav-icon">
					{((this.props.type==="left" || this.props.type==="bigLeft")&& currentPosition===0) || ((this.props.type==="right" || this.props.type==="bigRight") && allCols === totalCols)?
						arrowTypesGrey[this.props.type]:
						arrowTypes[this.props.type]
					}
				</div>
			</IconButton>
		);

	} // render

} ////
