/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as NewSourceIcon} from "../../images/aqa-icons/AQA-Delete.svg";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import {
    CircularProgress
} from "@material-ui/core";
import {ReactComponent as SourceIcon} from "../../images/aqa-icons/AQA-Snapshot - v2.svg";
import moment from "moment";
import HtmlTooltip from "@material-ui/core/Tooltip";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";

const styles = theme => ({
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        width: "200px",
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});

class AqaSnapshotDelete extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: true,
            dense: false,
            openMessage: false,
            openProgress:false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            periodicities: [],
            periodicity: "daily",
            SourceName: "",
            SourceDescription: null,
            errorMessage: "",
            file: null,
            folders:[],
            selectedSources:[],
            selectedFolders:[],

        };
        this.handleDeleteSnapshotClosePopup=this.handleDeleteSnapshotClosePopup.bind(this);
        this.handleDeleteSnapshot=this.handleDeleteSnapshot.bind(this);
        this.handleNewSourceClick=this.handleNewSourceClick.bind(this);

    }


    handleClosePopup(e) {
        e.preventDefault();
        this.props.dataFromParent.handleSnapshotSelectionReset();
        this.setState({ errorMessage:"",openMessage: false,SourceName:"",SourceDescription:"",periodicity:"daily" });
    }
    handleNewSourceClick(e) {
        e.preventDefault();
        this.setState({ openMessage: true });
    }

    handleDeleteSnapshotClosePopup(e){
       var snapshots= this.props.dataFromParent.state.selectedSnapshots;
       var last = false;
       for(var k=0;k<snapshots.length;k++){
           var snapshot = snapshots[k];
           if(k=== snapshots.length-1){
               last = true;
           }
           this.handleDeleteSnapshot(snapshot,last);
       }
    }

    handleDeleteSnapshot(snapshot,last){
        AqaComponent.snapshotBackend.delUsingDELETE(
            snapshot.id,
            (error,data,response)=>{
                if (error) this.reportError("A problem deleting Snapshot from the server was encountered.",
                    "AqaSnapshotDelete.handleDeleteSnapshot, backend call: " + error,this);
                else{
                    if(last) {
                        this.props.dataFromParent.handleSnapshotSelectionReset();
                        this.props.parent.props.parent.handleRefresh();
                    }
                }

            }
        );
        this.setState({ openMessage: false });
    }




    componentDidMount(){

    }
    render() {
        //const { classes } = this.props;
        const { error, isLoaded, redirect, notfound,errorMessage } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: 0, marginTop: 0}}>

                    <Grid item xs={10} sm={7} lg={11} xl={11}>
                        <Typography variant="title" color="inherit" align="left"
                                    style={{ fontSize: '1.5rem',
                                        padding: '0.1em 0.5em',
                                        fontFamily: 'Open Sans, sans-serif'}}>
                            Nothing to display
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={1} lg={1} xl={1}>

                    </Grid>
                </Grid>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",width:"100%",paddingTop:"8px"}}>
                                <HtmlTooltip enterDelay={500} title={<React.Fragment>
                                    <div>
                                        <Typography color="inherit" className="aqa-text-action">Click to delete selected Data Upload(s)</Typography>
                                    </div>
                                </React.Fragment>} arrow={"true"} placement="bottom-start">
                                    <IconButton className="aqa-button" onClick={this.handleNewSourceClick.bind(this)} disabled={false} style={{padding:"4px"}}>

                                        <div className="aqa-icon" >
                                            <NewSourceIcon width="32px" />
                                        </div>
                                        <Typography className="aqa-action-header-text" variant="inherit" style={{textAlign:"left",color:"#04A6FC",paddingLeft:"4px",marginTop:"-4px"}}>
                                            DELETE
                                        </Typography>
                                    </IconButton>
                                </HtmlTooltip>

                            </Typography>
                        </Grid>

                    </Grid>

                    <Dialog
                        open={this.state.openMessage}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important",padding:"14px 14px 10px",width:"600px"}}>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"4px",fontFamily:"Montserrat",float:"left",width:"96%"}}>
                                Delete Data Upload(s)
                            </Typography>
                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"14px",color:"white",paddingLeft:"0px",fontFamily:"Montserrat",float:"left",width:"3%"}}>
                                <IconButton className="aqa-button" onClick={this.handleClosePopup} style={{padding:"0px"}} >
                                    <div className="aqa-g-icon" style={{width:"20px"}}>
                                        <CloseIcon width="20px" />
                                    </div>
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent align="center" style={{padding: 0,width:"600px"}}>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {(errorMessage !=="")?errorMessage:"."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {("Are you sure you want to permanently delete selected Data Upload(s)")
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                        {(this.props.dataFromParent.state.selectedSnapshots.map((item,index)=>(
                                                <div key={item.id+"_source_"+index}>
                                                <span style={{float:"left",width:"32px"}}>
                                            <IconButton className="aqa-button" style={{padding:"4px"}}>
                                                <div className="aqa-list-icon">
                                                    <SourceIcon width="24px" />
                                                </div>
                                            </IconButton>
                                                    </span>
                                                    <span style={{float:"left"}}>
                                            <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px",marginTop:"5px"}}>
                                                {(item.dateCreated !== null) ? (moment(item.dateCreated).format("YYYY/MM/DD HH:mm")) : ("")}
                                            </Typography>
                                            </span>
                                                </div>
                                            ))
                                        )}
                                    </Typography>
                                </Grid>


                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="flex-start"
                                  style={{padding: 0, marginTop: 10,marginLeft:0, marginBottom: 10,borderTop:"1px solid #ccc",width:"600px"}}>

                                <Grid item xs={1} sm={1} lg={1} xl={1}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="left"
                                            className="aqa-action-button"
                                            style={{
                                                marginTop: 8,
                                                marginLeft: 8,
                                                color: '#4cadc4',
                                                backgroundColor: 'white',
                                                border: '1px solid #4cadc4',
                                                fontSize: '0.7rem',
                                                padding: '3px'
                                            }}>
                                        Cancel
                                    </Button>


                                </Grid>

                                <Grid item xs={9} sm={9} lg={9} xl={9}>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} xl={2}>
                                    <Button  variant="contained"
                                             onClick={this.handleDeleteSnapshotClosePopup.bind(this)}
                                             color="primary"
                                             align="left"
                                             className="aqa-action-button"
                                             style={{
                                                 marginTop: 8,
                                                 color: '#4cadc4',
                                                 backgroundColor: 'white',
                                                 border: '1px solid #4cadc4',
                                                 fontSize: '0.7rem',
                                                 padding: '3px',
                                             }}>
                                        Delete
                                    </Button>



                                </Grid>

                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.openProgress}
                        //onClose={this.handleCloseMessage}
                        aria-labelledby="max-width-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="simple-dialog-title" className="aqa-dialog-header" style={{"color":"white !important"}}>Status</DialogTitle>
                        <DialogContent align="center">
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",paddingLeft:"4px"}}>
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Typography className={"aqa-action-header"} variant="inherit" style={{textAlign:"Center",color:"#006",paddingLeft:"4px",paddingBottom:"20px",width:"300px"}}>
                                        File is being uploaded
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <CircularProgress />
                                </Grid>
                            </Grid>


                            <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                  style={{padding: 0, marginTop: 30, marginBottom: 0}}>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                    <Button onClick={this.handleClosePopup.bind(this)} variant="contained"
                                            color="primary"
                                            align="right"
                                            style={{
                                                marginTop: 2,
                                                marginLeft: 5,
                                                marginBottom: 32,
                                                backgroundColor: '#1976D2'
                                            }}>
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </div>
            );
        }
    }
}

AqaSnapshotDelete.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSnapshotDelete);
