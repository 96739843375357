import React from 'react';

import AqaComponent from "../shared/aqacomponent/AqaComponent"
import Heatmap      from "../shared/heatmap/Heatmap"



export default class AqaHeatMapForDataViewer extends AqaComponent
{
	// Problem is...
	// Because I cache heatmaps...
	// ... here, we will get the cached version that was calculated for the overview screen...
	// ... meaning it will always be in 16x16 whatever I say here.
	// Fix? Yes. If I:
	// - include the dimensions in the id of the heatmap
	// - get the snapshot deletion to delete them all
	static HMWidth = 30;
	static HMHeight = 38;
	static SCALE_FACTOR = 1;

	static getWidth()
	{
		return AqaHeatMapForDataViewer.HMWidth * (Heatmap.BASE_PIXEL_SIZE_OF_HEAT_CELL + 1) * AqaHeatMapForDataViewer.SCALE_FACTOR;
	} // getWdth
	
	
	/**
	  * The controller we're given is AqaSnapshotDetails, not the Combo. (Which cuts an intermediary)
	  */
	constructor(props)
	{
	
		super(props);
	
		props.controller.registerHeatmap(this, props.position);

		this.state =
		{
			float:props.float ? props.float : "right",
			// heatmap:null
			data:null,
			type:null
		};

	} //


	informOfSnapshotId(snapshotId, colNumber, type, callback)
	{
		const heatMapId = `${snapshotId}_${colNumber}_${type}`;

		let data = this.props.controller.heatMapCache[heatMapId];
		if (!data)
		{
			Heatmap.getHeatmapColumnsData
			(
				snapshotId,
				AqaHeatMapForDataViewer.HMWidth,
				AqaHeatMapForDataViewer.HMHeight,
				colNumber,
				(data) => 
				{
					this.props.controller.heatMapCache[heatMapId] = data; // It must be done this way. Listen or suffer.
					this.setState
					(
						{data, type},
						() => { if (callback) callback() }
					);
				}
			);
		}
		else
		{
			this.setState
			(
				{data, type},
				() => { if (callback) callback() }
			);
		}

	} // informOfSnapshotId

	registerMap(map) { this.map = map; }


	/** Coordinates are expected to be in range. */
	setRadarPosition(xPos, xDimension, xScale, yPos, yDimension, yScale)
	{
// onsole.log("HM: Set radar position!!!");
// onsole.log("this.props.data.height: " + this.props.data.height);

		if (!this.state.data) return;

// onsole.log("DATA IS AVAILABLE");
// onsole.log(AqaComponent.prettifyJson(this.state.data));

		const {horizontalOffset, horizontalPostPad} = this.state.data; // , verticalOffset, verticalPostPad not taken into account - for now.
		const mensurations = [this.state.data.width + horizontalOffset + horizontalPostPad, this.state.data.height];
		const positions    = [xPos, yPos];
		const scales       = [xScale, yScale];
//		const dimensions   = [xScale > xDimension ? xDimension : xScale, yScale > yDimension ? yDimension : yScale];
		const dimensions   = [xDimension, yDimension];
		
		
// onsole.log(dimensions);


		const radarCoordinates = [Array(2), Array(2)];

		let i, j, span, toConvert;
		for(i = 0; i < 2; i++)
		{
			span = mensurations[i] * (Heatmap.BASE_PIXEL_SIZE_OF_HEAT_CELL * AqaHeatMapForDataViewer.SCALE_FACTOR + 1);
			toConvert = [positions[i], dimensions[i]];
			for(j = 0; j < 2; j++) radarCoordinates[i][j] = Math.floor(toConvert[j] * span / scales[i]);
		}

		// Adjustment: in case of extreme scales, make sure it's visible.
		for(i = 0; i < 2; i++)
		{
			if (radarCoordinates[i][1] < 1) radarCoordinates[i][1] = 1;
			radarCoordinates[i][0] += 1;
		}

// onsole.log("mensurations");
// onsole.log(AqaComponent.prettifyJson(radarCoordinates));

		// Showing we're ready.
		this.setState({radarCoordinates});

	} // setRadarPositionOnMap





	resize(params)
	{
		// And state it boldly
		this.setState({float:params.cmFloat});
	} // resize

// , margin:"0 4px"
// border:"1px solid purple"

	render()
	{
		const tblStyle = 
		{
			width:`${AqaHeatMapForDataViewer.width}`,
			fontSize:"14px",
			float:this.state.float
		}; // this.state.float

		// Important! Type needs to be string type!!
		return (
			<div style={tblStyle}>
				{
					this.state.data
				?
					<Heatmap
						parent={this}
						data={this.state.data}
						type={this.state.type} 
						scale={AqaHeatMapForDataViewer.SCALE_FACTOR}
						clickerCallback={this.props.clickerCallback}
						radarCoordinates={this.state.radarCoordinates}
					/>
				:
					null
				}
			</div>
		);

	} // render

} ////



