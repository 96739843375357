/* Welcome to Aqa Client*/
/* Version: 1 */

import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AqaComponent from "../../shared/aqacomponent/AqaComponent";
import {GridList, GridListTile} from "@material-ui/core";
import MsContext from "../../shared/masterservant/MsContext";
import {ReactComponent as JTLIcon} from "../../../images/aqa-icons/Jump To Left Arrowcol.svg";
import {ReactComponent as MTLIcon} from "../../../images/aqa-icons/Move Left Arrowcol.svg";
import {ReactComponent as MTRIcon} from "../../../images/aqa-icons/Move Right Arrowcol.svg";
import {ReactComponent as JTRIcon} from "../../../images/aqa-icons/Jump To Right Arrowcol.svg";

const styles = theme => ({
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    welcomelabel: {
        fontSize: 18,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 20,
        marginRight: 20,
        color: "#006"
    }
});
class AqaRulesColumn extends AqaComponent {
    static contextType = MsContext;
    constructor(props) {
        super(props);
        // Assign A ref to state for refresh
        this.state = {
            errorMessage: "",
            columns:[],
            which: 0,
            whichType: -1,
            whichName: "",
            startRow: 0,
            lastRow: 8

        };
        this.firstVisibleRow=0;
        this.lastVisibleRow=9;
        this.numberOfFullyVisibleColumns=6;
        this.handleColumnClick=this.handleColumnClick.bind(this);
    }

    handleColumnClick(colId, colName)
    {
        this.props.parent.table.getColumnVector
        (
            colId, // this.complexSelection[which][1],
            vector =>
            {
                const stats = vector.getStats();
                 // Auto selection
                const n = vector.getStats() === null ? 0 : vector.getStats().length;
                let max = 0;
                let maxPos = -1;
                // Starting past uniqueness and populated
                for(let i = 2; i < n; i++) if (stats[i][2] > max)
                {
                    max = stats[i][2];
                    maxPos = i;
                }
                this.setState({which:colId,whichName:colName,whichType:this.props.parent.state.type});
                this.props.parent.handleShowRulesEditor(colId,colName,this.props.parent.state.type)
                if (maxPos !== -1)
                {

                }
            }
        );
    } // handleColumnClick

    scrollHorizontally(e){
        if(this.state.startRow < 0) return;
        var startRow=this.state.startRow+e;
        var lastRow=this.state.lastRow+e;
        var cols = this.props.dataForColumns.length;
        if(startRow>=0 && lastRow < cols) {
            this.setState({startRow: startRow, lastRow: lastRow});
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.dataForActiveColumn > this.state.lastRow) this.scrollHorizontally(this.numberOfFullyVisibleColumns);
        this.setState({columns:nextProps.dataForColumns,which:nextProps.dataForActiveColumn});
    }


    componentDidMount() {
        this.setState({columns:this.props.dataForColumns,which:this.props.dataForActiveColumn});
        //this.props.parent.handleShowRulesEditor(0,"Name",-1)
    }
    render(){
        //const { classes } = this.props;
        const { columns,which,startRow,lastRow} = this.state;
        return(
            <div style={{minWidth: "1060px",width:"1060px"}}>
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                      style={{padding: "0px",paddingLeft:"10px", marginTop: "5px"}}>

                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <GridList style={{margin:0}}>
                            {columns.map((item, index) => (
                                (index >= startRow && index <= lastRow)?(
                                <GridListTile key={"aqa_snapshot_rule_column"+index} className={(parseInt(which) === index)?("aqa-rule-box-active"):("aqa-rule-box")} style={{width:"114px",textAlign:"center",height:"50px",verticalAlign:"middle",padding:0}} onClick={()=>this.handleColumnClick(index,item.name)}>
                                    {(item.isActive===true)?(
                                        <Typography variant="inherit" style={{textAlign:"center",width:"100%",height:"10px",color:"#01579b",backgroundColor:"#01579b",padding:0,margin:"0px"}}>

                                        </Typography>
                                    ):(
                                        <Typography variant="inherit" style={{textAlign:"center",height:"10px",padding:0,margin:"-1px"}}>

                                    </Typography>)}
                                    <Typography className="aqa-rule-header-text" variant="inherit" style={{textAlign:"center"}}>
                                        {item.name}
                                    </Typography>
                                </GridListTile>):null
                            ))}
                        </GridList>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                              style={{padding: "0px",paddingLeft:"5px",paddingRight:"5px", marginTop: "5px"}}>
                            <Grid item xs={10} sm={10} lg={10} xl={10}>
                                {/*<Typography className={"aqa-text-sub"} variant="inherit" color="inherit"
                                            style={{textAlign: "center", fontSize: "0.8rem", width: "100%"}}>
                                    {startRow+"-"+((this.props.dataForColumns.length<lastRow)?this.props.dataForColumns.length:lastRow)+" of "+this.props.dataForColumns.length}
                                </Typography>*/}
                            </Grid>
                            <Grid item xs={1} sm={1} lg={1} xl={1}>
                                <IconButton className="aqa-button" onClick={() => this.scrollHorizontally(-this.numberOfFullyVisibleColumns)}
                                            style={{padding: "0px",marginLeft:"-4px"}}>

                                    <div className="aqa-toggle-nav-icon">
                                        <JTLIcon width="32px"/>
                                    </div>
                                </IconButton>
                                <IconButton className="aqa-button" onClick={() => this.scrollHorizontally(-1)}
                                            style={{padding: "0px",marginLeft:"-4px"}}>

                                    <div className="aqa-toggle-nav-icon">
                                        <MTLIcon width="32px"/>
                                    </div>
                                </IconButton>


                            </Grid>

                            <Grid item xs={1} sm={1} lg={1} xl={1}>
                                <IconButton className="aqa-button" onClick={() => this.scrollHorizontally(1)}
                                            style={{padding: "0px",marginLeft:"-4px"}}>

                                    <div className="aqa-toggle-nav-icon">
                                        <MTRIcon width="32px"/>
                                    </div>
                                </IconButton>
                                <IconButton className="aqa-button" onClick={() => this.scrollHorizontally(this.numberOfFullyVisibleColumns)}
                                            style={{padding: "0px",marginLeft:"-4px"}}>

                                    <div className="aqa-toggle-nav-icon">
                                        <JTRIcon width="32px"/>
                                    </div>
                                </IconButton>

                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>


            </div>



        )
    }
}
AqaRulesColumn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaRulesColumn);
