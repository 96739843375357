/* Welcome to Aqa*/
/* Version: 1 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {FormControlLabel, GridList, GridListTile, Radio, RadioGroup, Table, TableBody} from "@material-ui/core";
import AqaSourceListRow from "./AqaSourceListRow";
import AqaSourceHeader from "./AqaSourceHeader";
import AqaSourceGridRow from "./AqaSourceGridRow";
import AqaComponent from "../shared/aqacomponent/AqaComponent";
import {ReactComponent as UploadIcon} from "../../images/aqa-icons/Uploadcol.svg";
import Input from "@material-ui/core/Input";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as CloseIcon} from "../../images/aqa-icons/AQA-Exclude.svg";
import DialogContent from "@material-ui/core/DialogContent";
import {ReactComponent as SourceIcon} from "../../images/aqa-icons/Sourcecol.svg";
//import AqaFolderIncludeListRow from "./AqaFolderIncludeListRow";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AqaLibraryManager from "../market/AqaLibraryManager";

const styles = theme => ({
    root:{
      maxWidth: 345,
        margin: "8px",
    },
    gridTile:{
      marginTop:"-4px"
    },
    welcomelabel: {
        fontSize: 18,
        marginTop: 18,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        color: "white"
    },
    getstartedlabel: {
        fontSize: 14,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 20,
        marginRight: 20,
        color: "#1e2b56"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: 0,
        minWidth: 100,
    },
    formControlLabel: {
        marginTop: 0,
        fontSize: 14,
    },
    textField: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 16,
    },
    reportTitle: {
        fontSize: 15,
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: "bold",
        marginBottom: 4,
        marginLeft: 8,
        color: "#1e2b56"
    },
    reportInstruction: {
        fontSize: 13,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        marginLeft: 8,
        width: "95%",
        height: 50,
        color: "#1e2b56"
    },
    demo: {

    },
    reportBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: 4,
        color: "#1e2b56"
    }
});



class AqaSourceView extends AqaComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            notfound: false,
            isLoaded: false,
            dense: false,
            secondary: true,
            redirect: false,
            currentFolder:"allsources",
            sources: [],
            filteredSources:[],
            isGrid: false,
            isLibrary: false,
            sortDirection: true,
            sortByType: "name",
            selectedSources:[],
            resetCheckbox:false,
            updateHeader:false,
            height:window.innerHeight-100,
            currentAspect:null,
            currentSource:null,
            currentSourceId:"newsource",
            currentSnapshot:null,
            isUploadEnabled:false,
            accountObj:"",
            openMessage:false,
            snapshotcount:0,
            datasources:[],
            aqdIdToUse:"",
            openRuleMessage:false,
            updatesCount:0,
            authCode:props.dataFromMain.props.authCode,
            exts:props.dataFromMainFrame.state.exts
        };
        this._isMounted=false;
        //this.handleFolder = this.handleFolder.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleToggleGrid = this.handleToggleGrid.bind(this);
        this.handleSource = this.handleSource.bind(this);
        this.handleSourceSelection = this.handleSourceSelection.bind(this);
        this.handleSourceSelectionReset = this.handleSourceSelectionReset.bind(this);
        this.handleSourcesDeleteReset = this.handleSourcesDeleteReset.bind(this);
        this.handleSourceSelectionCheckReset = this.handleSourceSelectionCheckReset.bind(this);
        this.handleDimensions=this.handleDimensions.bind(this);
        this.handleShowRule = this.handleShowRule.bind(this);
        this.handleNewSnapshotClick = this.handleNewSnapshotClick.bind(this);
        this.handleNewSnapshotDropClick=this.handleNewSnapshotDropClick.bind(this);
        this.handleAccountDetails=this.handleAccountDetails.bind(this);
        this.handleFileChange=this.handleFileChange.bind(this);
        this.handleDragOver=this.handleDragOver.bind(this);
        this.handleRadioChange=this.handleRadioChange.bind(this);
        this.handleSourceCreation=this.handleSourceCreation.bind(this);

// onsole.log("This is in AqaSourceView");
// onsole.log(props.dataAcquired);

        /*this.handleFolder(props.dataAcquired);*/
    }

    handleSourceSelectionReset(){
        if(this._isMounted) this.setState({selectedSources:[],resetCheckbox:true});
        //this.handleFolder(this.props.dataAcquired);
    }

    handleSourcesDeleteReset(){
        this.setState({filteredSources:[],selectedSources:[],resetCheckbox:true});
        this.handleFolder(this.props.dataAcquired,null,true);
    }

    handleRefresh(){
        this.setState({showEditor:false});
    }

    handleShowRule(aspect,source,snapshot){
        this.setState({currentSource:source,currentAspect:aspect,showEditor:true,currentSnapshot:snapshot});
    }

    handleSourceSelectionCheckReset(){
        this.setState({resetCheckbox:false});
        //this.handleFolder(this.props.dataAcquired);
    }
    handleSourceSelection(source,isSelected){
        var selectedSources=this.state.selectedSources;
        if(isSelected === true){
            if(source !== null && source !== undefined){
                var index = selectedSources.indexOf(source);
                if(index<0){
                    selectedSources.push(source);
                    this.setState({selectedSources:selectedSources});
                }
            }
        }
        else{
            if(source !== null && source !== undefined){
                index = selectedSources.indexOf(source);
                if(index!==-1){
                    var newSelectedSources = [];
                    for(var i=0;i<selectedSources.length;i++){
                        var selectedSource = selectedSources[i];
                        if(selectedSource !== source){
                            newSelectedSources.push(selectedSource);
                        }
                    }
                    selectedSources=newSelectedSources;
                    this.setState({selectedSources:selectedSources});
                }
            }
        }
    }

    GetAscSortOrder(prop) {
        return function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }
    GetDescSortOrder(prop) {
        return function(a, b) {
            if (a[prop] < b[prop]) {
                return 1;
            } else if (a[prop] > b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    findUsageStat = (d) =>{
        let usage = 0;
        if(this.state.statement !== undefined) {
            let blockSize = this.state.statement.blockSize;
            let activities = this.state.statement.activities;
            for(let i=0;i<activities.length;i++){
                let activity = activities[i][`sourceActivity`];
                if(activity.sourceId === d){
                    usage = (activity.numberOfCumulatedRows/blockSize).toFixed(2);

                }
            }
        }
        return usage;}

   handleSort(type,sortDirection){
       const types = {
           name: 'name',
           dateCreated: 'dateCreated',
           createdBy: 'createdById',
       };
        const sortProperty = types[type];
// onsole.log("Type "+type+" Direction "+sortDirection+" sortType "+sortProperty);
        var sorted=this.state.filteredSources;
// onsole.log(sorted);
        //sorted = JSON.parse(JSON.stringify(sorted));
        if(sortDirection) {
            sorted = sorted.sort(this.GetAscSortOrder(sortProperty));
        }
        else{
            sorted = sorted.sort(this.GetDescSortOrder(sortProperty));
        }
// onsole.log(sorted);
        if(this._isMounted) this.setState({filteredSources: sorted});
    }


    handleToggle=(isGrid)=>{
        this.handleFolder(this.props.dataAcquired,null,false);
        if(isGrid){
            this.setState({isGrid:false,isLibrary:false,isUploadEnabled:false})
        }
        else{
            this.setState({isGrid:true,isLibrary:false,isUploadEnabled:false})
        }

    }

    handleToggleGrid(){
        this.handleFolder(this.props.dataAcquired,null,false);
        this.setState({isGrid:true,isLibrary:false});
    }

    handleLibrary = () =>{
        this.setState({isLibrary:true,isUploadEnabled:false});
    }


    handleUploadEnabled(active){
        this.setState({isUploadEnabled:active,isLibrary:false});
    }

    handleSearch(search){
// onsole.log(search);
        if(search === "" || search === 'undefined' || search.trim() === "" ){
            this.setState({filteredSources:this.state.sources});
        }
        else{
            var newFilteredSources = this.state.sources.filter((data)=>{
                if(data.name.toLowerCase().includes(search.toLowerCase())
                    || data.createdBy.toLowerCase().includes(search.toLowerCase())

                    ){
                    return data
                }
                else{
                    return false;
                }
            });

            this.setState({filteredSources:newFilteredSources});
        }
    }

    handleSortTypeChange(type){
// onsole.log(type);
        this.setState({sortByType: type});
        this.handleSort(type,this.state.sortDirection);
    }
    handleSortChange(sortDirection){
// onsole.log(sortDirection);
        this.setState({sortDirection: sortDirection});
        this.handleSort(this.state.sortByType,sortDirection);
    }

    handleSource(source){
// onsole.log(source);
        this.props.dataFromMain.handleSource(source);
        this.props.dataFromMain.props.dataFromRoot.navigation(2);
        //this.props.dataFromMain.props.dataFromRoot.refreshSession();
    }

    handleSourceFile(source,file){
// onsole.log(source);
        this.handleUploadEnabled(false);
        this.props.dataFromMain.handleSourceFile(source,file);
        this.props.dataFromMain.props.dataFromRoot.navigation(2);

        //this.props.dataFromMain.props.dataFromRoot.refreshSession();
    }

    handleSourceFolder(folderid,sourceId) {
        if (folderid === "" || folderid === null || folderid === 'undefined' || folderid === "allsources") {
            AqaComponent.backend.sourcesUsingGET(
                "false",
                (error, data, response) => {
                    if (error) this.reportError("A problem getting the sources from the server was encountered.",
                        "AqaSourceView.handleFolder, backend call: " + error,this);
                    else {
                        if (data.length < 1) {
                            this.setState({error: false, notfound: true});
                        } else {
                            let currentSources = data.filter((item) => {
                                return item.id === sourceId;
                            });
                            this.setState({
                                isLoaded: true,
                                sources: data,
                                filteredSources: data,
                                isUploadEnabled: false,
                                currentSource: currentSources[0]
                            });
                            this.handleSourceFile(currentSources[0],this.state.file);
                            this.handleSort(this.state.sortByType, this.state.sortDirection);
                            this.handleRulesLibrary();
                        }
                    }
                }
            );
        }
    }

    handleFolder(folderid,sourceid,showUpload){
        if(folderid === "" || folderid === null || folderid === undefined || folderid === "allsources"){
            AqaComponent.backend.sourcesUsingGET(
                "false",
                (error, data, response) =>
                {
                    if (error) this.reportError("A problem getting the sources from the server was encountered.",
                        "AqaSourceView.handleFolder, backend call: " + error,this);
                    else
                    {
                        if(data.length<1){
                            this.setState({error: false,isLoaded:true});
                        }
                        else {
                            if (this._isMounted) {
                                this.setState({isLoaded: true, sources: data, filteredSources: data, snapshotcount: 0});
                                this.handleSort(this.state.sortByType, this.state.sortDirection);
                                if (sourceid === undefined || sourceid === null) {
                                    let newFilterData = data.filter((item) => {
                                        return item.example === false;
                                    })
                                    if (newFilterData.length < 1 && showUpload===true) this.handleUploadEnabled(true);
                                    for (let i = 0; i < newFilterData.length; i++) {
                                        let source = newFilterData[i];
                                        let last = false;
                                        if (i === newFilterData.length - 1) last = true;
                                        this.handleSnapshots(source.id, last);
                                    }
                                } else {
                                    let newFilterData = data.filter((item) => {
                                        return item.id === sourceid;
                                    })
                                    if (newFilterData.length > 0) this.handleSource(newFilterData[0]);
                                }

                            }
                            this.handleRulesLibrary();
                        }
                    }
                }
            );
        }
        else{
// onsole.log(folderid);
            AqaComponent.backend.sourcesInFolderUsingGET(
                folderid,
                (error, data, response) =>
                {
                    if (error) this.reportError("A problem getting the sources from the server was encountered.",
                        "AqaSourceView.handleFolder, backend call: " + error,this);
                    else
                    {
                        if(data.length<1){
                            this.setState({error: false,notfound:true,sources:data,filteredSources:data});
                        }
                        else {
                            if(this._isMounted){
                            this.setState({isLoaded: true, sources: data, filteredSources: data,isUploadEnabled:false});
                            this.handleSort(this.state.sortByType, this.state.sortDirection);
                            }
                        }
                    }
                }
            );
        }

    }

    handleRulesLibrary = () =>{
        AqaComponent.marketBackend.qdDirectoryUsingGET(
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the library from the server was encountered.",
                    "AqaLibraryManager.handleRulesLibrary, backend call: " + error,this);
                else
                {
                    let datasources = this.state.sources;
                    let qds = [];
                    let sources = [];
                    let updates = false;
                    let updatesCount =0;
                    for(let i=0;i<data.publicQualityDefinitions.length;i++){
                        let qd = data.publicQualityDefinitions[i];
                        sources = datasources.filter((d)=>{return d.subscribedId===qd.id});
                        updates = sources.filter((d)=>{return d.subscribedQdId !== qd.aqdId});
                        if(updates.length>0) updatesCount = updatesCount+1;
                        let qdtemp= {scope:"PUBLIC",sources:sources,updates:updates,usageCount:sources.length};
                        qd = {...qd,...qdtemp};
                        qds.push(qd);
                    }
                    for(let j=0;j<data.privateQualityDefinitions.length;j++){
                        let qd = data.privateQualityDefinitions[j];
                        sources = datasources.filter((d)=>{return d.subscribedId===qd.id});
                        updates = sources.filter((d)=>{return d.subscribedQdId !== qd.aqdId});
                        if(updates.length>0) updatesCount = updatesCount+1;
                        let qdtemp= {scope:"PRIVATE",sources:sources,updates:updates,usageCount:sources.length};
                        qd = {...qd,...qdtemp};
                        qds.push(qd);
                    }
                    for(let k=0;k<data.protectedQualityDefinitions.length;k++){
                        let qd = data.protectedQualityDefinitions[k];
                        sources = datasources.filter((d)=>{return d.subscribedId===qd.id});
                        updates = sources.filter((d)=>{return d.subscribedQdId !== qd.aqdId});
                        if(updates.length>0) updatesCount = updatesCount+1;
                        let qdtemp= {scope:"PROTECTED",sources:sources,updates:updates,usageCount:sources.length};
                        qd = {...qd,...qdtemp};
                        qds.push(qd);
                    }
                    if(this._isMounted) this.setState({qds:qds,updatesCount:updatesCount});
                }
            }
        );
    }

    handleDimensions =(event)=>{
        event.preventDefault();
        this.setState({height:window.innerHeight-100});
    }

    handleSnapshots(sourceId,last){


        // TJ: 20211115 You're kidding right? You're loading the ALL the snapshots only to know how many there are??!??!?

        AqaComponent.backend.getSnapshotsForSourceUsingGET(
            sourceId,
            0,
            9999,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem getting the snapshots from the server was encountered.",
                    "AqaSourceDetail.handleSnapshots, backend call: " + error,this);
                else
                {
                    if(this._isMounted) {
                        //let count = this.state.snapshotcount+data.length;
                        if (data.length > 0) {
                            this.setState({snapshotcount: this.state.snapshotcount + data.length});
                        } else {
                            this.setState({showRules: false, snapshotcount: this.state.snapshotcount, isLoaded: true});
                        }
                        //if(last === true && count ===0 && this.state.isUploadEnabled!==true) this.handleUploadEnabled(true);
                    }
                }
            }
        );
    }

    handleAccountDetails(){
//        var accountId=this.props.dataFromRoot.props.parent.state.accountId;
        //console.log(this.state.authCode);
        AqaComponent.accountBackend.getMyAccountUsingGET(
//            accountId,
            (error,data,response)=>{
                if(error) this.reportError("A problem getting the account details from the server was encountered.",
                    "AqaAccountOverview.handleAccountDetails, backend call: " + error,this);
                else{
                    var accountObj = data;
                    if(this._isMounted) {
                        this.setState({accountObj: accountObj});
                        if (this.state.authCode === "A") this.handleStatement(accountObj);
                    }
                }
            }
        );
    }

    handleStatement(accountObj){
        AqaComponent.billingBackend.getCurrentStatementUsingGET(
            (error,data,response)=> {
                if (error) this.reportError("A problem getting the plans from the server was encountered.",
                    "AqaAccountOverview.handleAllPlans, backend call: " + error,this);
                else {
                    if(this._isMounted) {
                        this.setState({statement: data});
                        let blocksAllocated = 0;
                        let blocksUsed = 0;
                        let blockSize = data.blockSize;
                        //let currency = accountObj.currencyCode;
                        //let planWithPrices = data.planWithPrices.prices[currency][0];
                        //if(planWithPrices !== null && planWithPrices !== undefined) blocksAllocated = planWithPrices.quantityUpTo;
                        for (let i = 0; i < data.activities.length; i++) {
                            let activity = data.activities[i].sourceActivity;
                            blocksUsed = blocksUsed + Math.floor(activity.numberOfCumulatedRows / blockSize);
                        }
                        this.setState({blocksAllocated: blocksAllocated, blocksUsed: blocksUsed});
                    }
                }
            }
        );
    }

    handleNewSnapshotClick(e){
        e.preventDefault();
        var authCode=this.props.dataFromMain.props.dataFromRoot.props.parent.state.authCode;
        var adminEmail=this.props.dataFromMain.props.dataFromRoot.props.parent.state.adminEmail;
        var context;
        if(authCode === "V"){
            this.removeAllNotifications();
            this.showNotification(4, null,context , null,adminEmail);
        }
        else {
            /*if(this.props.dataFromParent.name === "Example Data"){
                var customNotification = {
                    title: "Restricted", titleIcon: "",
                    message: "You cannot upload data to this Data Source",
                    isCustom: false, type: "info",
                    insert: "center", position: "center", autoClose: true, duration: 5000
                };
                this.removeAllNotifications();
                this.showNotification(null, customNotification);
            }
            else {*/
                //this.handleSnapshots(this.props.dataFromParent.id);
                /*if (this.state.accountObj.numberOfSnapshots !== -1 && this.state.snapshotcount >= this.state.accountObj.numberOfSnapshots) {
                    context = this.props.dataFromRoot;
                    this.removeAllNotifications();
                    this.showNotification(3, null, context, "Snapshot");
                }
            else if (this.state.accountObj.numberOfSources !== -1 && this.state.sources.length-1 >= this.state.accountObj.numberOfSources) {
                context = this.props.dataFromMain.props.dataFromRoot;
                this.removeAllNotifications();
                this.showNotification(3, null, context, "Source");
            }
                else {*/
                    this.setState({openMessage: false, file: null});
                    document.getElementById('import-file').click();
               /* }*/
            /*}*/
        }
    }

    handleDragOver(e){
        e.preventDefault();
    }

    handleNewSnapshotDropClick(e){
        e.preventDefault();
        var authCode=this.props.dataFromMain.props.dataFromRoot.props.parent.state.authCode;
        var adminEmail=this.props.dataFromMain.props.dataFromRoot.props.parent.state.adminEmail;
        var context;
        if(authCode === "V"){
            this.removeAllNotifications();
            this.showNotification(4, null,context , null,adminEmail);
        }
        else {
            /*if(this.props.dataFromParent.name === "Example Data"){
                var customNotification = {
                    title: "Restricted", titleIcon: "",
                    message: "You cannot upload data to this Data Source",
                    isCustom: false, type: "info",
                    insert: "center", position: "center", autoClose: true, duration: 5000
                };
                this.removeAllNotifications();
                this.showNotification(null, customNotification);
            }
            else {*/
                //this.handleAccountDetails();
                //this.handleSnapshots(this.props.dataFromParent.id);
                /*if (this.state.accountObj.numberOfSnapshots !== -1 && this.state.snapshotcount >= this.state.accountObj.numberOfSnapshots) {
                    context = this.props.dataFromRoot;
                    this.removeAllNotifications();
                    this.showNotification(3, null, context, "Snapshot");
                }
            else if (this.state.accountObj.numberOfSources !== -1 && this.state.sources.length-1 >= this.state.accountObj.numberOfSources) {
                context = this.props.dataFromMain.props.dataFromRoot;
                this.removeAllNotifications();
                this.showNotification(3, null, context, "Source");
            }
                else {*/
                    this.setState({openMessage: false, file: null});
                    var fileUploaded = e.dataTransfer.files[0];
                    if(fileUploaded !== null && fileUploaded !== undefined) {
                        let ext = fileUploaded.name.substr(fileUploaded.name.lastIndexOf(".")+1,fileUploaded.name.length);
                        if(ext === "aqd"){
                            this.handleRulesUpload(fileUploaded);
                        }
                        else if(!this.handleCheckFile("."+ext)){
                            this.reportError("Selected file is not supported", "AqaSourceView.handleFileChange, backend call: ",this);
                            this.setState({file: null, errorMessage: ""});
                        }
                        else {
                            let fsources = this.state.sources.filter((item) => {
                                return item.name !== "Example Data";
                            });
                            if (fsources.length === 0) {
                                this.setState({currentSourceId: "newsource", file: fileUploaded});
                                this.handleSaveFileClosePopupWithFile(fileUploaded);
                            } else {
                                this.setState({file: fileUploaded, errorMessage: "", openMessage: true});
                            }
                        }

                        //this.handleSnapshotCreation(fileUploaded);
                        //this.handleFileUpload(fileUploaded);
                        //this.setState({file:null});
                        //document.getElementById('import-file').value="";
                        //this.setState({openMessage: false, file: null});
                        //document.getElementById('import-file').click();
                    }
               /* }*/
           /* }*/
        }
    }

    handleAssignDataSource = (sourceid, aqdIdToUse) =>{
        if(sourceid === "newsource") sourceid="new";
        AqaComponent.backend.assignQualityDefinitionUsingPUT(
            aqdIdToUse,
            sourceid,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem assigning the source for aqd from the server was encountered.", "AqaSourceView.handleAssignSource, backend call: " + error,this);
                else
                {
                    if(data.success){
                        this.setState({file:null,resetCheckbox:false,fileUploaded:null,isFileReady:false,openMessage:false,isUploadEnabled:false,openRuleMessage:false},()=>this.handleFolder(this.props.dataAcquired,data.message));
                    }
                    else{
                        this.setState({file:null,resetCheckbox:false,fileUploaded:null,isFileReady:false,openMessage:false,isUploadEnabled:false,openRuleMessage:false});
                        this.removeAllNotifications();
                        this.showNotification(5);
                    }
                }
            }
        );

    }

    handleRulesUpload = (fileUpload) =>{
        //console.log(fileUpload);
        AqaComponent.backend.importQualityDefinitionUsingPOST(
            fileUpload,
            (error, data, response) =>
            {
                if (error) this.reportError("A problem posting the aqd file from the server was encountered.", "AqaSourceView.handleRuleUpload, backend call: " + error,this);
                else
                {
                    //this.props.dataFromParent.handleFileUploadReset();
                    if(data.success){
                        if(data.sources.length ===0 ){
                            this.handleAssignDataSource("new",data.aqdIdToUse);
                        }
                        else{
                            this.setState({aqdIdToUse:data.aqdIdToUse,datasources:data.sources,openRuleMessage:true});
                        }
                    }
                    else{
                        /*var customNotification = {
                            title: "Failed", titleIcon: "",
                            message: "Failed to load the rules, please try again. If problem persists please contact aqa  ",
                            isCustom: false, type: "info",
                            insert: "center", position: "center", autoClose: true, duration: 5000
                        };*/
                        this.removeAllNotifications();
                        this.showNotification(5);
                        this.setState({file:null,resetCheckbox:false,fileUploaded:null,isFileReady:false,openMessage:false});
                    }
                    //this.handleClosePopup();
                    var fileNode=document.getElementById('import-file');
                    if(fileNode!==null) fileNode.value="";
                }
            }
        );
    }

    handleCheckFile = (ext)=>{
        let exts = this.state.exts;
        let found = false;
        for(let i=0;i<exts.length;i++) if(exts[i] === ext) found=true;
        return found;
    }

    handleFileChange(evt){
        var fileUploaded = evt.target.files[0];
        if(fileUploaded !== null && fileUploaded !== undefined) {
            let ext = fileUploaded.name.substr(fileUploaded.name.lastIndexOf(".")+1,fileUploaded.name.length);
            if(ext === "aqd"){
                this.handleRulesUpload(fileUploaded);
            }
            else if(!this.handleCheckFile("."+ext)){
                this.reportError("Selected file is not supported", "AqaSourceView.handleFileChange, backend call: ",this);
                this.setState({file: null, errorMessage: ""});
            }
            else {
                this.setState({file: fileUploaded, errorMessage: "",openProgress:false});
                let fsources = this.state.sources.filter((item) => {
                    return item.name !== "Example Data";
                });
                if (fsources.length === 0) {
                    this.setState({currentSourceId: "newsource", file: fileUploaded});
                    this.handleSaveFileClosePopupWithFile(fileUploaded);
                } else {
                    this.setState({openMessage: true});
                }
            }
            //this.handleSnapshotCreation(fileUploaded);
            //this.handleFileUpload(fileUploaded);
            //onsole.log(fileUploaded);
            //this.setState({file:null,resetCheckbox:false});
            //document.getElementById('import-file').value="";

        }
        else{
//            onsole.log("Test");
            this.setState({file: null, errorMessage: ""});
        }
    }

    handleFileUpload(fileUploaded){
        this.setState({fileUploaded:fileUploaded,isFileReady:true,resetCheckbox:true});
    }
    handleFileUploadReset(){
        this.setState({fileUploaded:null,isFileReady:false});
    }

    handleClosePopup=()=>{
        this.setState({file:null,resetCheckbox:false,fileUploaded:null,isFileReady:false,openMessage:false,openRuleMessage:false});
        var fileNode=document.getElementById('import-file');
        if(fileNode!==null) fileNode.value="";
    }

    handleSaveFileClosePopup(){
        if(this.state.currentSourceId === "") this.setState({errorMessage:"Please select the source"});
        else {
            this.setState({errorMessage:""});
            if(this.state.currentSourceId === "newsource"){
                var file = this.state.file;
                var filename = file!==null?file.name:"New Data";
                var fname = filename.substring(0,filename.lastIndexOf("."))
                var sourceName = fname;
                var sourceDescription = "This is a new data";
                var folderid = "allsources";
                this.handleSourceCreation(folderid,sourceName,sourceDescription);
                this.handleUploadEnabled(false);
            }
            else{
                let sources = this.state.filteredSources.filter((item)=>{return item.id === this.state.currentSourceId;});
                if(sources.length>0) {
                    let currentSource = sources[0];
                    this.setState({currentSource:currentSource});
                    this.handleSourceFile(currentSource,this.state.file);
                    this.handleUploadEnabled(false);
                }
                else{
                    this.setState({errorMessage:"Source doesnt exist"});
                }
            }
        }
    }

    handleSaveRuleFileClosePopup = () =>{
        if(this.state.currentSourceId === "") this.setState({errorMessage:"Please select the source"});
        else {
            this.setState({errorMessage:""});
            this.handleAssignDataSource(this.state.currentSourceId,this.state.aqdIdToUse);
        }
    }

    handleSaveFileClosePopupWithFile(file){
        if(this.state.currentSourceId === "") this.setState({errorMessage:"Please select the source"});
        else {
            this.setState({errorMessage:""});
            if(this.state.currentSourceId === "newsource"){
                var filename = file!==null?file.name:"New Data";
                var fname = filename.substring(0,filename.lastIndexOf("."))
                var sourceName = fname;
                var sourceDescription = "This is a new data";
                var folderid = "allsources";
                this.handleSourceCreation(folderid,sourceName,sourceDescription);
                this.handleUploadEnabled(false);
            }
            else{
                let sources = this.state.filteredSources.filter((item)=>{return item.id === this.state.currentSourceId;});
                if(sources.length>0) {
                    let currentSource = sources[0];
                    this.setState({currentSource:currentSource});
                    this.handleSourceFile(currentSource,this.state.file);
                    this.handleUploadEnabled(false);
                }
                else{
                    this.setState({errorMessage:"Source doesnt exist"});
                }
            }
        }
    }

    handleSourceCreation(folderid,sourceName,sourceDescription){
        var bodyParams="{\"name\":\""+sourceName+"\",\"description\":\""+sourceDescription+"\"}"
        if(folderid === "" || folderid === null || folderid === 'undefined' || folderid === "allsources"){
            AqaComponent.backend.createSourceUsingPOST(
                bodyParams,
                (error, data, response) =>
                {
                    if (error) this.reportError("A problem posting the  new source from the server was encountered.",
                        "AqaNewSource.handleSourceCreation, backend call: " + error,this);
                    else
                    {
                        this.setState({isLoaded: true,openMessage:false});
                        if(data.length<1){
                            this.setState({error: false,notfound:true});
                        }
                        this.handleSourceFolder(folderid,response.body.sourceId);

                        /*var datasplit = data.message.split(" > ");
                        var datasplitlength = datasplit.length;
                        if(datasplitlength > 1 && this.state.file !== undefined && this.state.file !== null) {
                            var datasourceid = datasplit[0];
                            this.setState({openProgress:true});
                            this.handleSnapshotCreation(folderid,datasourceid,this.state.file);
                        }
                        else{
                            this.props.dataFromParent.props.dataFromRoot.handleSources();
                            this.handleFolder(folderid);
                        }*/
                    }
                }
            );
        }
        else{
            AqaComponent.backend.createSourceInFolderUsingPOST(
                bodyParams,
                folderid,
                (error, data, response) =>
                {
                    if (error) this.reportError("A problem posting the  new source from the server was encountered.",
                        "AqaNewSource.handleSourceCreation, backend call: " + error,this);
                    else
                    {
                        this.setState({isLoaded: true,openMessage:false});
                        if(data.length<1){
                            this.setState({error: false,notfound:true});
                        }
                        var datasplit = data.message.split(" > ");
                        var datasplitlength = datasplit.length;
                        if(datasplitlength > 1 && this.state.file !== undefined && this.state.file !== null) {
                            var datasourceid = datasplit[0];
                            this.setState({openProgress:true});
                            this.handleSnapshotCreation(folderid,datasourceid,this.state.file);
                        }
                        else{
                            this.props.dataFromParent.props.dataFromRoot.handleSources();
                            this.handleFolder(folderid);
                        }
                    }
                }
            );
        }
    }

    handleRadioChange(evt){
        this.setState({currentSourceId:evt.target.value,errorMessage:""});
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({currentFolder: nextProps.dataAcquired})
        //this.handleFolder(nextProps.dataAcquired);
        this.handleSourceSelectionReset();
    }

    async componentDidMount(){
        this._isMounted=true;
        this.handleFolder(this.props.dataAcquired,null,true);
        this.handleAccountDetails();
        let isGrid=this.props.dataFromMain.props.parent.state.isGrid;
        let isLibrary=this.props.dataFromMain.props.isLibrary;
        this.setState({isGrid:isGrid,isLibrary:isLibrary});
        window.addEventListener("onload",this.handleDimensions);
        window.addEventListener("fullScreenChange",this.handleDimensions);
        window.addEventListener("resize",this.handleDimensions);

    }

    componentWillUnmount() {
        this._isMounted=false;
        window.removeEventListener("onload",this.handleDimensions);
        window.removeEventListener("fullScreenChange",this.handleDimensions);
        window.removeEventListener("resize",this.handleDimensions);
    }

    render() {
        const { classes } = this.props;
        const { error, isLoaded, filteredSources, redirect, notfound, isGrid,resetCheckbox,height,currentAspect,currentSource,showEditor,currentSnapshot,isUploadEnabled,isLibrary,exts } = this.state;
        if(redirect){
            //return <Redirect to="/login" push={true} />
        }
        else if (notfound && error) {
            return (
                <div className={"source-detail-servant-pane-main"} style={{height:height}}>
                    <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                          style={{padding: 0, marginTop: 0}}>

                        <Grid item xs={10} sm={7} lg={11} xl={11}>
                            <Typography variant="title" color="inherit" align="left"
                                        style={{ fontSize: '1.5rem',
                                            padding: '0.1em 0.5em',
                                            fontFamily: 'Open Sans, sans-serif'}}>
                                Nothing to display
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={1} lg={1} xl={1}>

                        </Grid>
                    </Grid>
                </div>
            );
        }
        else if (error && !notfound) {
            return <div>
                <Typography variant="title" color="inherit" align="left"
                            style={{ fontSize: '1.5rem',
                                padding: '3.1em 0.5em',
                                fontFamily: 'Open Sans, sans-serif'}}>
                    Error: {error.message}
                </Typography>


            </div>;
        } else if (!isLoaded) {
            return (
                <div className={"source-detail-servant-pane-main"} style={{height:height}}>
                    <div style={{"borderBottom":"1px solid #DBDBDB","height":"52px"}}>
                        <AqaSourceHeader parent={this} dataFromParent={this} dataFromRoot={this.props.dataFromRoot} dataForFolders={this.props.dataForFolders} dataAcquired={this.props.dataAcquired} dataFromSource={currentSource} dataForAspect={currentAspect} showEditor={showEditor} dataFromSnapshot={currentSnapshot} dataFromMainFrame={this.props.dataFromMainFrame} myheader={"AqaSourceHeader"}/>
                        <Typography className="aqa-text-action-12" variant="inherit"
                                    style={{textAlign: "center", padding: "4px",height:window.innerHeight-100,position:"relative"}}>
                            <Typography className="aqa-text-action-12" variant="inherit"
                                        style={{textAlign: "center", padding: "4px",margin:"0",top:"40%",position:"absolute",left:"30%",right:"30%"}}>
                                <Typography className="aqa-text-action-12" variant="inherit"
                                            style={{textAlign: "center", padding: "4px"}}>
                                    Click on UPLOAD DATA to load a data file
                                </Typography>
                            </Typography>
                        </Typography>
                    </div>
                </div>
            );
        } else {
            if (isLibrary) {
                return (
                    <div className={"source-detail-servant-pane-main"} style={{height: height}}>
                        <div style={{"height": "56px", backgroundColor: "white", borderBottom: "1px solid #DBDBDB"}}>
                            <AqaSourceHeader parent={this} dataFromParent={this} dataFromRoot={this.props.dataFromRoot}
                                             dataForFolders={this.props.dataForFolders}
                                             dataAcquired={this.props.dataAcquired} dataFromSource={currentSource}
                                             dataForAspect={currentAspect} showEditor={showEditor}
                                             dataFromSnapshot={currentSnapshot} dataForIcon={isGrid} updatesCount={this.state.updatesCount}
                                             dataFromMainFrame={this.props.dataFromMainFrame} myheader={"AqaSourceHeader"}
                                             style={{border: "1px solid #DBDBDB"}}/>
                        </div>
                    <div>
                        <AqaLibraryManager dataFromParent={this} dataFromRoot={this.props.dataFromRoot} />
                    </div>
                    </div>
                );
            } else {
                if (!isGrid) {
                    return (
                        <div className={"source-detail-servant-pane-main"} style={{height: height}}>
                            <div style={{"height": "56px", backgroundColor: "white", borderBottom: "1px solid #DBDBDB"}}>
                                <AqaSourceHeader parent={this} dataFromParent={this} dataFromRoot={this.props.dataFromRoot}
                                                 dataForFolders={this.props.dataForFolders}
                                                 dataAcquired={this.props.dataAcquired} dataFromSource={currentSource}
                                                 dataForAspect={currentAspect} showEditor={showEditor}
                                                 dataFromSnapshot={currentSnapshot} dataForIcon={isGrid} updatesCount={this.state.updatesCount}
                                                 dataFromMainFrame={this.props.dataFromMainFrame} myheader={"AqaSourceHeader"}
                                                 style={{border: "1px solid #DBDBDB"}}/>
                            </div>
                            {isUploadEnabled ?
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center">
                                    <Grid item xs={3} sm={3} lg={3} xl={3}>
                                        <div>
                                            <Typography className="aqa-text-action-12" variant="inherit"
                                                        style={{
                                                            textAlign: "center",
                                                            padding: "4px",
                                                            margin: "0",
                                                            height:"460px"
                                                        }}>
                                                <Typography className="aqa-text-action-12" variant="inherit"
                                                            style={{
                                                                textAlign: "left",
                                                                padding: "4px",
                                                                marginTop: "0px",
                                                                paddingTop:window.innerWidth>1400?"0px":"100px"
                                                            }}>
                                                    <Typography className="" variant="inherit"
                                                                style={{
                                                                    textAlign: "left",
                                                                    padding: "4px",
                                                                    marginTop: "0px",
                                                                    fontSize:"16px",
                                                                    fontFamily:"Montserrat",
                                                                    paddingBottom:"10px",
                                                                    fontWeight:"bold",
                                                                    color:"rgb(1, 38, 112)"
                                                                }}>
                                                        What kind of data can I upload?
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        <span style={{fontSize:"14px"}}><b>Marketing:</b></span> Customer data, Prospect lists (Users of Hubspot, Pardot, Zoho, etc.)
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        <span style={{fontSize:"14px"}}><b>Sales:</b></span> Pipeline data, Sales reports (users of Salesforce, Adobe, Oracle, etc.)
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        <span style={{fontSize:"14px"}}><b>Manufacturing:</b></span> Production plans, Results data (users of ABB, Dassault, Epicor, etc.)
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        <span style={{fontSize:"14px"}}><b>Finance:</b></span> Transaction data, Orders data (users of Sage, Intuit, SAP, Oracle, etc.)
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        <span style={{fontSize:"14px"}}><b>Product:</b></span> Test data, Bill of Materials (BOM) (users of Aha!, Atlassian, Microsoft, etc.)
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        <span style={{fontSize:"14px"}}><b>Procurement:</b></span> Supply chain data, Manufacturing BoM (users of SAP, Coupa, Mercateo, etc.)
                                                    </Typography>
                                                </Typography>

                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{paddingLeft: "5px", margin: window.innerWidth>1400?"60px auto":"-10px auto", paddingBottom: "30px"}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Typography className="aqa-text-action-12" variant="inherit"
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "4px",
                                                        height: window.innerWidth>1400?window.innerHeight - 150:window.innerHeight - 200,
                                                        position: "relative"
                                                    }}>
                                            <Typography className="aqa-text-action-12" variant="inherit"
                                                        style={{
                                                            textAlign: "center",
                                                            padding: "4px",
                                                            margin: "0",
                                                            top: window.innerWidth>1280?"4%":"4%",
                                                            position: "absolute",
                                                            left: window.innerWidth>1280?"20%":"6%",
                                                            right: window.innerWidth>1280?"20%":"6%"
                                                        }}>
                                                <Typography className="aqa-text-action-12" variant="inherit"
                                                            style={{
                                                                textAlign: "center",
                                                                padding: "4px",
                                                                marginTop: "30px"
                                                            }}>
                                                    <div className={"aqa-file-upload-outer"}
                                                         onClick={this.handleNewSnapshotClick}
                                                         onDrop={this.handleNewSnapshotDropClick}
                                                         onDragOver={this.handleDragOver}>
                                                        <Typography className="" variant="inherit"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        padding: "24px 0px 16px 0px",
                                                                        color: "rgb(1, 38, 112)",
                                                                        fontSize: "48px",
                                                                        fontWeight:"bold",
                                                                        fontFamily:"Montserrat",
                                                                        marginTop:window.innerWidth>1200?"8px":"0px"
                                                                    }}>
                                                            <span style={{fontSize:"48px"}}>Upload</span>
                                                        </Typography>
                                                        <div className="aqa-icon" style={{paddingTop: "0px"}}>
                                                            <UploadIcon width="256px"/>
                                                        </div>
                                                        <Typography className="aqa-text-action-16" variant="inherit"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        padding: "4px",
                                                                        color: "#909090",
                                                                        fontSize: "16px"
                                                                    }}>
                                                            Click or Drag-Drop to upload your file
                                                        </Typography>
                                                        <Typography className="aqa-text-action-12" variant="inherit"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        padding: "4px",
                                                                        opacity: "0.5",
                                                                        color: "#909090"
                                                                    }}>

                                                        </Typography>
                                                        <Typography className="aqa-text-action-12" variant="inherit"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        padding: "4px",
                                                                        opacity: "0.5",
                                                                        color: "#909090"
                                                                    }}>
                                                            Note: single sheet .xlsx or .csv with list of records
                                                        </Typography>
                                                    </div>
                                                </Typography>
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Input
                                            id="import-file"
                                            inputProps={{
                                                accept: exts.toString(),
                                            }}
                                            onChange={this.handleFileChange}
                                            style={{display: "none", opacity: '0'}}
                                            type="file"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Dialog
                                            open={this.state.openMessage}
                                            //onClose={this.handleCloseMessage}
                                            aria-labelledby="max-width-dialog-title"
                                            maxWidth="lg"
                                        >
                                            <DialogTitle id="simple-dialog-title" className="aqa-dialog-header"
                                                         style={{"color": "white !important", padding: "14px 14px 10px"}}>
                                                <Typography variant="inherit" color="inherit" style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    color: "white",
                                                    paddingLeft: "4px",
                                                    fontFamily: "Montserrat",
                                                    float: "left",
                                                    width: "96%"
                                                }}>
                                                    Upload Data
                                                </Typography>
                                                <Typography variant="inherit" color="inherit" style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    color: "white",
                                                    paddingLeft: "0px",
                                                    fontFamily: "Montserrat",
                                                    float: "left",
                                                    width: "3%"
                                                }}>
                                                    <IconButton className="aqa-button"
                                                                onClick={this.handleClosePopup.bind(this)}
                                                                style={{padding: "0px"}}>
                                                        <div className="aqa-g-icon" style={{width: "20px"}}>
                                                            <CloseIcon width="20px"/>
                                                        </div>
                                                    </IconButton>
                                                </Typography>
                                            </DialogTitle>
                                            <DialogContent align="center" style={{padding: 0, minWidth: "650px"}}>
                                                <Grid container spacing={0} direction="row" justify="space-between"
                                                      alignItems="center"
                                                      style={{padding: 0, marginTop: 0}}>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                        {/*<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                                            {(errorMessage !=="")?errorMessage:"."}
                                                        </Typography>*/}
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                        {/*<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                                            Select Source to Upload the Data
                                                        </Typography>*/}

                                                        <Typography variant="inherit" color="inherit" style={{
                                                            textAlign: "left",
                                                            fontSize: "0.8rem",
                                                            color: "#006",
                                                            padding: "5px"
                                                        }}>
                                                            <Typography className={"aqa-text-action"} variant="title"
                                                                        align="left"
                                                                        style={{paddingLeft: "8px"}}>
                                                                Either:
                                                            </Typography>
                                                            <RadioGroup aria-label="currentSourceId" name="currentSourceId"
                                                                        value={this.state.currentSourceId} color="primary"
                                                                        onChange={this.handleRadioChange} row={false}
                                                                        style={{width: "auto"}}>
                                                                <FormControlLabel key={"newsource"} value={"newsource"}
                                                                                  control={<Radio color="primary"
                                                                                                  style={{padding: "4px"}}/>}
                                                                                  label={
                                                                                      (<Typography
                                                                                          className={"aqa-text-action"}
                                                                                          variant="title" align="left"
                                                                                          style={{paddingTop: "3px"}}>
                                                                                          <div key={"_source_0"}>
                                                    <span style={{float: "left", width: "32px"}}>
                                                <IconButton className="aqa-button" style={{padding: "4px"}}>
                                                    <div className="aqa-list-icon">
                                                        <SourceIcon width="24px"/>
                                                    </div>
                                                </IconButton>
                                                        </span>
                                                                                              <span style={{float: "left"}}>
                                                <Typography variant="inherit" color="inherit" style={{
                                                    textAlign: "left",
                                                    fontSize: "0.8rem",
                                                    color: "#006",
                                                    padding: "5px",
                                                    marginTop: "5px"
                                                }}>
                                                    {"New Data"}
                                                </Typography>
                                                </span>
                                                                                          </div>
                                                                                      </Typography>)


                                                                                  }
                                                                                  style={{width: "380px", margin: "0px"}}/>

                                                            </RadioGroup>

                                                        </Typography>
                                                        <Typography className={"aqa-text-action"} variant="title"
                                                                    align="left"
                                                                    style={{paddingLeft: "12px"}}>
                                                            Or Update Existing:
                                                        </Typography>
                                                        <RadioGroup aria-label="currentSourceId" name="currentSourceId"
                                                                    value={this.state.currentSourceId} color="primary"
                                                                    onChange={this.handleRadioChange} row={false}
                                                                    style={{width: "auto"}}>
                                                            {this.state.sources.filter((d) => {
                                                                return d.name !== "Example Data"
                                                            }).map((item, index) => (
                                                                    <FormControlLabel key={item.id} value={item.id}
                                                                                      control={<Radio color="primary"
                                                                                                      style={{padding: "4px"}}/>}
                                                                                      label={
                                                                                          (<Typography
                                                                                              className={"aqa-text-action"}
                                                                                              variant="title" align="left"
                                                                                              style={{padding: "3px"}}>
                                                                                              <div
                                                                                                  key={item.id + "_source_" + index}>
                                                    <span style={{float: "left", width: "32px"}}>
                                                <IconButton className="aqa-button" style={{padding: "4px"}}>
                                                    <div className="aqa-list-icon">
                                                        <SourceIcon width="24px"/>
                                                    </div>
                                                </IconButton>
                                                        </span>
                                                                                                  <span style={{float: "left"}}>
                                                <Typography variant="inherit" color="inherit" style={{
                                                    textAlign: "left",
                                                    fontSize: "0.8rem",
                                                    color: "#006",
                                                    padding: "5px",
                                                    marginTop: "5px"
                                                }}>
                                                    {item.name}
                                                </Typography>
                                                </span>
                                                                                              </div>
                                                                                          </Typography>)


                                                                                      } style={{
                                                                        width: "600px",
                                                                        marginLeft: "5px"
                                                                    }}/>
                                                                )
                                                            )}
                                                        </RadioGroup>

                                                    </Grid>
                                                </Grid>


                                                <Grid container spacing={0} direction="row" justify="space-between"
                                                      alignItems="flex-start"
                                                      style={{
                                                          padding: 0,
                                                          marginTop: 10,
                                                          marginLeft: 0,
                                                          marginBottom: 10,
                                                          borderTop: "1px solid #ccc",
                                                          width: "650px"
                                                      }}>

                                                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                                                        <Button onClick={this.handleClosePopup.bind(this)}
                                                                variant="contained"
                                                                color="primary"
                                                                align="left"
                                                                className="aqa-action-button"
                                                                style={{
                                                                    marginTop: 8,
                                                                    marginLeft: 8,
                                                                    color: '#4cadc4',
                                                                    backgroundColor: 'white',
                                                                    border: '1px solid #4cadc4',
                                                                    fontSize: '0.7rem',
                                                                    padding: '3px'
                                                                }}>
                                                            Cancel
                                                        </Button>


                                                    </Grid>

                                                    <Grid item xs={9} sm={9} lg={9} xl={9}>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                        <Button variant="contained"
                                                                onClick={this.handleSaveFileClosePopup.bind(this)}
                                                                color="primary"
                                                                align="left"
                                                                className="aqa-action-button"
                                                                style={{
                                                                    marginTop: 8,
                                                                    color: '#4cadc4',
                                                                    backgroundColor: 'white',
                                                                    border: '1px solid #4cadc4',
                                                                    fontSize: '0.7rem',
                                                                    padding: '3px',
                                                                }}>
                                                            Continue
                                                        </Button>


                                                    </Grid>

                                                </Grid>
                                            </DialogContent>
                                        </Dialog>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        <Dialog
                                            open={this.state.openRuleMessage}
                                            //onClose={this.handleCloseMessage}
                                            aria-labelledby="max-width-dialog-title"
                                            maxWidth="lg"
                                        >
                                            <DialogTitle id="simple-dialog-title" className="aqa-dialog-header"
                                                         style={{"color": "white !important", padding: "14px 14px 10px"}}>
                                                <Typography variant="inherit" color="inherit" style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    color: "white",
                                                    paddingLeft: "4px",
                                                    fontFamily: "Montserrat",
                                                    float: "left",
                                                    width: "96%"
                                                }}>
                                                    Upload Rules
                                                </Typography>
                                                <Typography variant="inherit" color="inherit" style={{
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    color: "white",
                                                    paddingLeft: "0px",
                                                    fontFamily: "Montserrat",
                                                    float: "left",
                                                    width: "3%"
                                                }}>
                                                    <IconButton className="aqa-button"
                                                                onClick={this.handleClosePopup.bind(this)}
                                                                style={{padding: "0px"}}>
                                                        <div className="aqa-g-icon" style={{width: "20px"}}>
                                                            <CloseIcon width="20px"/>
                                                        </div>
                                                    </IconButton>
                                                </Typography>
                                            </DialogTitle>
                                            <DialogContent align="center" style={{padding: 0, minWidth: "420px"}}>
                                                <Grid container spacing={0} direction="row" justify="space-between"
                                                      alignItems="center"
                                                      style={{padding: 0, marginTop: 0}}>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                        {/*<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                                            {(errorMessage !=="")?errorMessage:"."}
                                                        </Typography>*/}
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                        {/*<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                                            Select Source to Upload the Data
                                                        </Typography>*/}

                                                        <Typography variant="inherit" color="inherit" style={{
                                                            textAlign: "left",
                                                            fontSize: "0.8rem",
                                                            color: "#006",
                                                            padding: "5px"
                                                        }}>
                                                            <Typography className={"aqa-text-action"} variant="title"
                                                                        align="left"
                                                                        style={{paddingLeft: "8px"}}>
                                                                Either:
                                                            </Typography>
                                                            <RadioGroup aria-label="currentSourceId" name="currentSourceId"
                                                                        value={this.state.currentSourceId} color="primary"
                                                                        onChange={this.handleRadioChange} row={false}
                                                                        style={{width: "auto"}}>
                                                                <FormControlLabel key={"newsource"} value={"newsource"}
                                                                                  control={<Radio color="primary"
                                                                                                  style={{padding: "4px"}}/>}
                                                                                  label={
                                                                                      (<Typography
                                                                                          className={"aqa-text-action"}
                                                                                          variant="title" align="left"
                                                                                          style={{paddingTop: "3px"}}>
                                                                                          <div key={"_source_0"}>
                                                    <span style={{float: "left", width: "32px"}}>
                                                <IconButton className="aqa-button" style={{padding: "4px"}}>
                                                    <div className="aqa-list-icon">
                                                        <SourceIcon width="24px"/>
                                                    </div>
                                                </IconButton>
                                                        </span>
                                                                                              <span style={{float: "left"}}>
                                                <Typography variant="inherit" color="inherit" style={{
                                                    textAlign: "left",
                                                    fontSize: "0.8rem",
                                                    color: "#006",
                                                    padding: "5px",
                                                    marginTop: "5px"
                                                }}>
                                                    {"New Source"}
                                                </Typography>
                                                </span>
                                                                                          </div>
                                                                                      </Typography>)


                                                                                  }
                                                                                  style={{width: "380px", margin: "0px"}}/>

                                                            </RadioGroup>

                                                        </Typography>
                                                        <Typography className={"aqa-text-action"} variant="title"
                                                                    align="left"
                                                                    style={{paddingLeft: "12px"}}>
                                                            Or Update Existing:
                                                        </Typography>
                                                        <RadioGroup aria-label="currentSourceId" name="currentSourceId"
                                                                    value={this.state.currentSourceId} color="primary"
                                                                    onChange={this.handleRadioChange} row={false}
                                                                    style={{width: "auto"}}>
                                                            {this.state.datasources.filter((d) => {
                                                                return d.name !== "Example Data"
                                                            }).map((item, index) => (
                                                                    <FormControlLabel key={item.id} value={item.id}
                                                                                      control={<Radio color="primary"
                                                                                                      style={{padding: "4px"}}/>}
                                                                                      label={
                                                                                          (<Typography
                                                                                              className={"aqa-text-action"}
                                                                                              variant="title" align="left"
                                                                                              style={{padding: "3px"}}>
                                                                                              <div
                                                                                                  key={item.id + "_source_" + index}>
                                                    <span style={{float: "left", width: "32px"}}>
                                                <IconButton className="aqa-button" style={{padding: "4px"}}>
                                                    <div className="aqa-list-icon">
                                                        <SourceIcon width="24px"/>
                                                    </div>
                                                </IconButton>
                                                        </span>
                                                                                                  <span style={{float: "left"}}>
                                                <Typography variant="inherit" color="inherit" style={{
                                                    textAlign: "left",
                                                    fontSize: "0.8rem",
                                                    color: "#006",
                                                    padding: "5px",
                                                    marginTop: "5px"
                                                }}>
                                                    {item.name}
                                                </Typography>
                                                </span>
                                                                                              </div>
                                                                                          </Typography>)


                                                                                      } style={{
                                                                        width: "380px",
                                                                        marginLeft: "5px"
                                                                    }}/>
                                                                )
                                                            )}
                                                        </RadioGroup>

                                                    </Grid>
                                                </Grid>


                                                <Grid container spacing={0} direction="row" justify="space-between"
                                                      alignItems="flex-start"
                                                      style={{
                                                          padding: 0,
                                                          marginTop: 10,
                                                          marginLeft: 0,
                                                          marginBottom: 10,
                                                          borderTop: "1px solid #ccc",
                                                          width: "420px"
                                                      }}>

                                                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                                                        <Button onClick={this.handleClosePopup.bind(this)}
                                                                variant="contained"
                                                                color="primary"
                                                                align="left"
                                                                className="aqa-action-button"
                                                                style={{
                                                                    marginTop: 8,
                                                                    marginLeft: 8,
                                                                    color: '#4cadc4',
                                                                    backgroundColor: 'white',
                                                                    border: '1px solid #4cadc4',
                                                                    fontSize: '0.7rem',
                                                                    padding: '3px'
                                                                }}>
                                                            Cancel
                                                        </Button>


                                                    </Grid>

                                                    <Grid item xs={9} sm={9} lg={9} xl={9}>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                        <Button variant="contained"
                                                                onClick={this.handleSaveRuleFileClosePopup}
                                                                color="primary"
                                                                align="left"
                                                                className="aqa-action-button"
                                                                style={{
                                                                    marginTop: 8,
                                                                    color: '#4cadc4',
                                                                    backgroundColor: 'white',
                                                                    border: '1px solid #4cadc4',
                                                                    fontSize: '0.7rem',
                                                                    padding: '3px',
                                                                }}>
                                                            Continue
                                                        </Button>


                                                    </Grid>

                                                </Grid>
                                            </DialogContent>
                                        </Dialog>
                                    </Grid>
                                </Grid>
                                    </Grid>
                                    <Grid item xs={3} sm={3} lg={3} xl={3}>
                                        <div>
                                            <Typography className="aqa-text-action-12" variant="inherit"
                                                        style={{
                                                            textAlign: "center",
                                                            padding: "4px",
                                                            margin: "0",
                                                            height:"460px"
                                                        }}>
                                                <Typography className="aqa-text-action-12" variant="inherit"
                                                            style={{
                                                                textAlign: "left",
                                                                padding: "4px",
                                                                marginTop: "0px",
                                                                paddingTop:window.innerWidth>1400?"0px":"100px"
                                                            }}>
                                                    <Typography className="" variant="inherit"
                                                                style={{
                                                                    textAlign: "left",
                                                                    padding: "4px",
                                                                    marginTop: "0px",
                                                                    fontSize:"16px",
                                                                    fontFamily:"Montserrat",
                                                                    paddingBottom:"10px",
                                                                    fontWeight:"bold",
                                                                    color:"rgb(1, 38, 112)"
                                                                }}>
                                                        What is the sequence?
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        1. You choose a file and <span style={{fontSize:"14px"}}><b> Upload</b></span> it here
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        2. We <span style={{fontSize:"14px"}}><b>Check</b></span> the file is valid
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        3. We <span style={{fontSize:"14px"}}><b>Analyse</b></span> the file contents
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        4. We  <span style={{fontSize:"14px"}}><b>Generate Alerts</b></span> for potential issues
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        5. You <span style={{fontSize:"14px"}}><b>Review</b></span> the alerts
                                                    </Typography>
                                                </Typography>

                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>

                                :
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{paddingLeft: "5px", marginTop: 0, paddingBottom: "30px"}}>


                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        {filteredSources.length < 1 ?
                                            <Typography className="aqa-text-action-12" variant="inherit"
                                                        style={{
                                                            textAlign: "center",
                                                            padding: "4px",
                                                            height: window.innerHeight - 100,
                                                            position: "relative"
                                                        }}>
                                                <Typography className="aqa-text-action-12" variant="inherit"
                                                            style={{
                                                                textAlign: "center",
                                                                padding: "4px",
                                                                margin: "0",
                                                                top: "40%",
                                                                position: "absolute",
                                                                left: "30%",
                                                                right: "30%"
                                                            }}>
                                                    <Typography className="aqa-text-action-12" variant="inherit"
                                                                style={{textAlign: "center", padding: "4px"}}>
                                                        Click on UPLOAD DATA to load a data file
                                                    </Typography>
                                                </Typography>
                                            </Typography> :
                                            <Table width="100%" size="medium" cellSpacing={0} aria-sort="ascending">
                                                <colgroup>
                                                    <col width="1%"/>
                                                    <col width="1%"/>
                                                    <col width="21%"/>
                                                    <col width="39%"/>
                                                    <col width="1%"/>
                                                    <col width="6%"/>
                                                    <col width="30%"/>
                                                    <col width="1%"/>
                                                    <col width="1%"/>
                                                    {/*<col width="1%"/>*/}
                                                </colgroup>
                                                <TableBody style={{
                                                    'height': window.innerWidth<1300?height * 0.902:height*0.928,
                                                    'overflow': 'auto',
                                                    display: 'block',
                                                    width: window.innerWidth * 0.996
                                                }}>

                                                    {filteredSources.map(item => (
                                                        <AqaSourceListRow key={item.id} id={item.id} parent={this} dataFromParent={item}
                                                                          dataFromRoot={this}
                                                                          dataForCheckbox={resetCheckbox}
                                                                          dataFromMain={this.props.dataFromRoot}/>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        }
                                    </Grid>

                                </Grid>
                            }
                        </div>
                    );
                } else {
                    return (
                        <div className={"source-detail-servant-pane-main"} style={{height: height}}>
                            <div style={{"borderBottom": "1px solid #cccccc", "height": "56px", marginBottom: "8px"}}>
                                <AqaSourceHeader parent={this} dataFromParent={this} dataFromRoot={this.props.dataFromRoot}
                                                 dataForFolders={this.props.dataForFolders}
                                                 dataAcquired={this.props.dataAcquired} dataFromSource={currentSource}
                                                 dataForAspect={currentAspect} showEditor={showEditor} updatesCount={this.state.updatesCount}
                                                 dataFromMainFrame={this.props.dataFromMainFrame} myheader={"AqaSourceHeader"}
                                                 dataFromSnapshot={currentSnapshot} dataForIcon={isGrid}/>
                            </div>
                            {isUploadEnabled ?
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center">
                                    <Grid item xs={3} sm={3} lg={3} xl={3}>
                                        <div>
                                            <Typography className="aqa-text-action-12" variant="inherit"
                                                        style={{
                                                            textAlign: "center",
                                                            padding: "4px",
                                                            margin: "0",
                                                            height:"460px"
                                                        }}>
                                                <Typography className="aqa-text-action-12" variant="inherit"
                                                            style={{
                                                                textAlign: "left",
                                                                padding: "4px",
                                                                marginTop: "0px",
                                                                paddingTop:window.innerWidth>1400?"0px":"100px"
                                                            }}>
                                                    <Typography className="" variant="inherit"
                                                                style={{
                                                                    textAlign: "left",
                                                                    padding: "4px",
                                                                    marginTop: "0px",
                                                                    fontSize:"16px",
                                                                    fontFamily:"Montserrat",
                                                                    paddingBottom:"10px",
                                                                    fontWeight:"bold",
                                                                    color:"rgb(1, 38, 112)"
                                                                }}>
                                                        What sort of data can I upload?
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        <span style={{fontSize:"14px"}}><b>Marketing:</b></span> Customer data, Prospect lists (Users of Hubspot, Pardot, Zoho, etc.)
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        <span style={{fontSize:"14px"}}><b>Sales:</b></span> Pipeline data, Sales reports (users of Salesforce, Adobe, Oracle, etc.)
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        <span style={{fontSize:"14px"}}><b>Manufacturing:</b></span> Production plans, Results data (users of ABB, Dassault, Epicor, etc.)
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        <span style={{fontSize:"14px"}}><b>Finance:</b></span> Transaction data, Orders data (users of Sage, Intuit, SAP, Oracle, etc.)
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        <span style={{fontSize:"14px"}}><b>Product:</b></span> Test data, Bill of Materials (BOM) (users of Aha!, Atlassian, Microsoft, etc.)
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        <span style={{fontSize:"14px"}}><b>Procurement:</b></span> Supply chain data, Manufacturing BoM (users of SAP, Coupa, Mercateo, etc.)
                                                    </Typography>
                                                </Typography>

                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                                        <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                              style={{paddingLeft: "5px", margin: window.innerWidth>1400?"60px auto":"-10px auto", paddingBottom: "30px"}}>
                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                <Typography className="aqa-text-action-12" variant="inherit"
                                                            style={{
                                                                textAlign: "center",
                                                                padding: "4px",
                                                                height: window.innerWidth>1400?window.innerHeight - 150:window.innerHeight - 200,
                                                                position: "relative"
                                                            }}>
                                                    <Typography className="aqa-text-action-12" variant="inherit"
                                                                style={{
                                                                    textAlign: "center",
                                                                    padding: "4px",
                                                                    margin: "0",
                                                                    top: window.innerWidth>1280?"4%":"4%",
                                                                    position: "absolute",
                                                                    left: window.innerWidth>1280?"20%":"6%",
                                                                    right: window.innerWidth>1280?"20%":"6%"
                                                                }}>
                                                        <Typography className="aqa-text-action-12" variant="inherit"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        padding: "4px",
                                                                        marginTop: "30px"
                                                                    }}>
                                                            <div className={"aqa-file-upload-outer"}
                                                                 onClick={this.handleNewSnapshotClick}
                                                                 onDrop={this.handleNewSnapshotDropClick}
                                                                 onDragOver={this.handleDragOver}>
                                                                <Typography className="" variant="inherit"
                                                                            style={{
                                                                                textAlign: "center",
                                                                                padding: "24px 0px 16px 0px",
                                                                                color: "rgb(1, 38, 112)",
                                                                                fontSize: "48px",
                                                                                fontWeight:"bold",
                                                                                fontFamily:"Montserrat",
                                                                                marginTop:window.innerWidth>1200?"8px":"0px"
                                                                            }}>
                                                                    <span style={{fontSize:"48px"}}>Upload</span>
                                                                </Typography>
                                                                <div className="aqa-icon" style={{paddingTop: "0px"}}>
                                                                    <UploadIcon width="256px"/>
                                                                </div>
                                                                <Typography className="aqa-text-action-16" variant="inherit"
                                                                            style={{
                                                                                textAlign: "center",
                                                                                padding: "4px",
                                                                                color: "#909090",
                                                                                fontSize: "16px"
                                                                            }}>
                                                                    Click or Drag-Drop to upload your file
                                                                </Typography>
                                                                <Typography className="aqa-text-action-12" variant="inherit"
                                                                            style={{
                                                                                textAlign: "center",
                                                                                padding: "4px",
                                                                                opacity: "0.5",
                                                                                color: "#909090"
                                                                            }}>

                                                                </Typography>
                                                                <Typography className="aqa-text-action-12" variant="inherit"
                                                                            style={{
                                                                                textAlign: "center",
                                                                                padding: "4px",
                                                                                opacity: "0.5",
                                                                                color: "#909090"
                                                                            }}>
                                                                    Note: single sheet .xlsx or .csv with list of records
                                                                </Typography>
                                                            </div>
                                                        </Typography>
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                <Input
                                                    id="import-file"
                                                    inputProps={{
                                                        accept: exts.toString(),
                                                    }}
                                                    onChange={this.handleFileChange}
                                                    style={{display: "none", opacity: '0'}}
                                                    type="file"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                <Dialog
                                                    open={this.state.openMessage}
                                                    //onClose={this.handleCloseMessage}
                                                    aria-labelledby="max-width-dialog-title"
                                                    maxWidth="lg"
                                                >
                                                    <DialogTitle id="simple-dialog-title" className="aqa-dialog-header"
                                                                 style={{"color": "white !important", padding: "14px 14px 10px"}}>
                                                        <Typography variant="inherit" color="inherit" style={{
                                                            textAlign: "left",
                                                            fontSize: "14px",
                                                            color: "white",
                                                            paddingLeft: "4px",
                                                            fontFamily: "Montserrat",
                                                            float: "left",
                                                            width: "96%"
                                                        }}>
                                                            Upload Data
                                                        </Typography>
                                                        <Typography variant="inherit" color="inherit" style={{
                                                            textAlign: "left",
                                                            fontSize: "14px",
                                                            color: "white",
                                                            paddingLeft: "0px",
                                                            fontFamily: "Montserrat",
                                                            float: "left",
                                                            width: "3%"
                                                        }}>
                                                            <IconButton className="aqa-button"
                                                                        onClick={this.handleClosePopup.bind(this)}
                                                                        style={{padding: "0px"}}>
                                                                <div className="aqa-g-icon" style={{width: "20px"}}>
                                                                    <CloseIcon width="20px"/>
                                                                </div>
                                                            </IconButton>
                                                        </Typography>
                                                    </DialogTitle>
                                                    <DialogContent align="center" style={{padding: 0, minWidth: "650px"}}>
                                                        <Grid container spacing={0} direction="row" justify="space-between"
                                                              alignItems="center"
                                                              style={{padding: 0, marginTop: 0}}>
                                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                {/*<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                                            {(errorMessage !=="")?errorMessage:"."}
                                                        </Typography>*/}
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                {/*<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                                            Select Source to Upload the Data
                                                        </Typography>*/}

                                                                <Typography variant="inherit" color="inherit" style={{
                                                                    textAlign: "left",
                                                                    fontSize: "0.8rem",
                                                                    color: "#006",
                                                                    padding: "5px"
                                                                }}>
                                                                    <Typography className={"aqa-text-action"} variant="title"
                                                                                align="left"
                                                                                style={{paddingLeft: "8px"}}>
                                                                        Either:
                                                                    </Typography>
                                                                    <RadioGroup aria-label="currentSourceId" name="currentSourceId"
                                                                                value={this.state.currentSourceId} color="primary"
                                                                                onChange={this.handleRadioChange} row={false}
                                                                                style={{width: "auto"}}>
                                                                        <FormControlLabel key={"newsource"} value={"newsource"}
                                                                                          control={<Radio color="primary"
                                                                                                          style={{padding: "4px"}}/>}
                                                                                          label={
                                                                                              (<Typography
                                                                                                  className={"aqa-text-action"}
                                                                                                  variant="title" align="left"
                                                                                                  style={{paddingTop: "3px"}}>
                                                                                                  <div key={"_source_0"}>
                                                    <span style={{float: "left", width: "32px"}}>
                                                <IconButton className="aqa-button" style={{padding: "4px"}}>
                                                    <div className="aqa-list-icon">
                                                        <SourceIcon width="24px"/>
                                                    </div>
                                                </IconButton>
                                                        </span>
                                                                                                      <span style={{float: "left"}}>
                                                <Typography variant="inherit" color="inherit" style={{
                                                    textAlign: "left",
                                                    fontSize: "0.8rem",
                                                    color: "#006",
                                                    padding: "5px",
                                                    marginTop: "5px"
                                                }}>
                                                    {"New Data"}
                                                </Typography>
                                                </span>
                                                                                                  </div>
                                                                                              </Typography>)


                                                                                          }
                                                                                          style={{width: "380px", margin: "0px"}}/>

                                                                    </RadioGroup>

                                                                </Typography>
                                                                <Typography className={"aqa-text-action"} variant="title"
                                                                            align="left"
                                                                            style={{paddingLeft: "12px"}}>
                                                                    Or Update Existing:
                                                                </Typography>
                                                                <RadioGroup aria-label="currentSourceId" name="currentSourceId"
                                                                            value={this.state.currentSourceId} color="primary"
                                                                            onChange={this.handleRadioChange} row={false}
                                                                            style={{width: "auto"}}>
                                                                    {this.state.sources.filter((d) => {
                                                                        return d.name !== "Example Data"
                                                                    }).map((item, index) => (
                                                                            <FormControlLabel key={item.id} value={item.id}
                                                                                              control={<Radio color="primary"
                                                                                                              style={{padding: "4px"}}/>}
                                                                                              label={
                                                                                                  (<Typography
                                                                                                      className={"aqa-text-action"}
                                                                                                      variant="title" align="left"
                                                                                                      style={{padding: "3px"}}>
                                                                                                      <div
                                                                                                          key={item.id + "_source_" + index}>
                                                    <span style={{float: "left", width: "32px"}}>
                                                <IconButton className="aqa-button" style={{padding: "4px"}}>
                                                    <div className="aqa-list-icon">
                                                        <SourceIcon width="24px"/>
                                                    </div>
                                                </IconButton>
                                                        </span>
                                                                                                          <span style={{float: "left"}}>
                                                <Typography variant="inherit" color="inherit" style={{
                                                    textAlign: "left",
                                                    fontSize: "0.8rem",
                                                    color: "#006",
                                                    padding: "5px",
                                                    marginTop: "5px"
                                                }}>
                                                    {item.name}
                                                </Typography>
                                                </span>
                                                                                                      </div>
                                                                                                  </Typography>)


                                                                                              } style={{
                                                                                width: "600px",
                                                                                marginLeft: "5px"
                                                                            }}/>
                                                                        )
                                                                    )}
                                                                </RadioGroup>

                                                            </Grid>
                                                        </Grid>


                                                        <Grid container spacing={0} direction="row" justify="space-between"
                                                              alignItems="flex-start"
                                                              style={{
                                                                  padding: 0,
                                                                  marginTop: 10,
                                                                  marginLeft: 0,
                                                                  marginBottom: 10,
                                                                  borderTop: "1px solid #ccc",
                                                                  width: "650px"
                                                              }}>

                                                            <Grid item xs={1} sm={1} lg={1} xl={1}>
                                                                <Button onClick={this.handleClosePopup.bind(this)}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        align="left"
                                                                        className="aqa-action-button"
                                                                        style={{
                                                                            marginTop: 8,
                                                                            marginLeft: 8,
                                                                            color: '#4cadc4',
                                                                            backgroundColor: 'white',
                                                                            border: '1px solid #4cadc4',
                                                                            fontSize: '0.7rem',
                                                                            padding: '3px'
                                                                        }}>
                                                                    Cancel
                                                                </Button>


                                                            </Grid>

                                                            <Grid item xs={9} sm={9} lg={9} xl={9}>
                                                            </Grid>
                                                            <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                                <Button variant="contained"
                                                                        onClick={this.handleSaveFileClosePopup.bind(this)}
                                                                        color="primary"
                                                                        align="left"
                                                                        className="aqa-action-button"
                                                                        style={{
                                                                            marginTop: 8,
                                                                            color: '#4cadc4',
                                                                            backgroundColor: 'white',
                                                                            border: '1px solid #4cadc4',
                                                                            fontSize: '0.7rem',
                                                                            padding: '3px',
                                                                        }}>
                                                                    Continue
                                                                </Button>


                                                            </Grid>

                                                        </Grid>
                                                    </DialogContent>
                                                </Dialog>
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                <Dialog
                                                    open={this.state.openRuleMessage}
                                                    //onClose={this.handleCloseMessage}
                                                    aria-labelledby="max-width-dialog-title"
                                                    maxWidth="lg"
                                                >
                                                    <DialogTitle id="simple-dialog-title" className="aqa-dialog-header"
                                                                 style={{"color": "white !important", padding: "14px 14px 10px"}}>
                                                        <Typography variant="inherit" color="inherit" style={{
                                                            textAlign: "left",
                                                            fontSize: "14px",
                                                            color: "white",
                                                            paddingLeft: "4px",
                                                            fontFamily: "Montserrat",
                                                            float: "left",
                                                            width: "96%"
                                                        }}>
                                                            Upload Rules
                                                        </Typography>
                                                        <Typography variant="inherit" color="inherit" style={{
                                                            textAlign: "left",
                                                            fontSize: "14px",
                                                            color: "white",
                                                            paddingLeft: "0px",
                                                            fontFamily: "Montserrat",
                                                            float: "left",
                                                            width: "3%"
                                                        }}>
                                                            <IconButton className="aqa-button"
                                                                        onClick={this.handleClosePopup.bind(this)}
                                                                        style={{padding: "0px"}}>
                                                                <div className="aqa-g-icon" style={{width: "20px"}}>
                                                                    <CloseIcon width="20px"/>
                                                                </div>
                                                            </IconButton>
                                                        </Typography>
                                                    </DialogTitle>
                                                    <DialogContent align="center" style={{padding: 0, minWidth: "420px"}}>
                                                        <Grid container spacing={0} direction="row" justify="space-between"
                                                              alignItems="center"
                                                              style={{padding: 0, marginTop: 0}}>
                                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                {/*<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                                            {(errorMessage !=="")?errorMessage:"."}
                                                        </Typography>*/}
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                {/*<Typography variant="inherit" color="inherit" style={{textAlign:"left",fontSize:"0.8rem",color:"#006",padding:"5px"}}>
                                                            Select Source to Upload the Data
                                                        </Typography>*/}

                                                                <Typography variant="inherit" color="inherit" style={{
                                                                    textAlign: "left",
                                                                    fontSize: "0.8rem",
                                                                    color: "#006",
                                                                    padding: "5px"
                                                                }}>
                                                                    <Typography className={"aqa-text-action"} variant="title"
                                                                                align="left"
                                                                                style={{paddingLeft: "8px"}}>
                                                                        Either:
                                                                    </Typography>
                                                                    <RadioGroup aria-label="currentSourceId" name="currentSourceId"
                                                                                value={this.state.currentSourceId} color="primary"
                                                                                onChange={this.handleRadioChange} row={false}
                                                                                style={{width: "auto"}}>
                                                                        <FormControlLabel key={"newsource"} value={"newsource"}
                                                                                          control={<Radio color="primary"
                                                                                                          style={{padding: "4px"}}/>}
                                                                                          label={
                                                                                              (<Typography
                                                                                                  className={"aqa-text-action"}
                                                                                                  variant="title" align="left"
                                                                                                  style={{paddingTop: "3px"}}>
                                                                                                  <div key={"_source_0"}>
                                                    <span style={{float: "left", width: "32px"}}>
                                                <IconButton className="aqa-button" style={{padding: "4px"}}>
                                                    <div className="aqa-list-icon">
                                                        <SourceIcon width="24px"/>
                                                    </div>
                                                </IconButton>
                                                        </span>
                                                                                                      <span style={{float: "left"}}>
                                                <Typography variant="inherit" color="inherit" style={{
                                                    textAlign: "left",
                                                    fontSize: "0.8rem",
                                                    color: "#006",
                                                    padding: "5px",
                                                    marginTop: "5px"
                                                }}>
                                                    {"New Source"}
                                                </Typography>
                                                </span>
                                                                                                  </div>
                                                                                              </Typography>)


                                                                                          }
                                                                                          style={{width: "380px", margin: "0px"}}/>

                                                                    </RadioGroup>

                                                                </Typography>
                                                                <Typography className={"aqa-text-action"} variant="title"
                                                                            align="left"
                                                                            style={{paddingLeft: "12px"}}>
                                                                    Or Update Existing:
                                                                </Typography>
                                                                <RadioGroup aria-label="currentSourceId" name="currentSourceId"
                                                                            value={this.state.currentSourceId} color="primary"
                                                                            onChange={this.handleRadioChange} row={false}
                                                                            style={{width: "auto"}}>
                                                                    {this.state.datasources.filter((d) => {
                                                                        return d.name !== "Example Data"
                                                                    }).map((item, index) => (
                                                                            <FormControlLabel key={item.id} value={item.id}
                                                                                              control={<Radio color="primary"
                                                                                                              style={{padding: "4px"}}/>}
                                                                                              label={
                                                                                                  (<Typography
                                                                                                      className={"aqa-text-action"}
                                                                                                      variant="title" align="left"
                                                                                                      style={{padding: "3px"}}>
                                                                                                      <div
                                                                                                          key={item.id + "_source_" + index}>
                                                    <span style={{float: "left", width: "32px"}}>
                                                <IconButton className="aqa-button" style={{padding: "4px"}}>
                                                    <div className="aqa-list-icon">
                                                        <SourceIcon width="24px"/>
                                                    </div>
                                                </IconButton>
                                                        </span>
                                                                                                          <span style={{float: "left"}}>
                                                <Typography variant="inherit" color="inherit" style={{
                                                    textAlign: "left",
                                                    fontSize: "0.8rem",
                                                    color: "#006",
                                                    padding: "5px",
                                                    marginTop: "5px"
                                                }}>
                                                    {item.name}
                                                </Typography>
                                                </span>
                                                                                                      </div>
                                                                                                  </Typography>)


                                                                                              } style={{
                                                                                width: "380px",
                                                                                marginLeft: "5px"
                                                                            }}/>
                                                                        )
                                                                    )}
                                                                </RadioGroup>

                                                            </Grid>
                                                        </Grid>


                                                        <Grid container spacing={0} direction="row" justify="space-between"
                                                              alignItems="flex-start"
                                                              style={{
                                                                  padding: 0,
                                                                  marginTop: 10,
                                                                  marginLeft: 0,
                                                                  marginBottom: 10,
                                                                  borderTop: "1px solid #ccc",
                                                                  width: "420px"
                                                              }}>

                                                            <Grid item xs={1} sm={1} lg={1} xl={1}>
                                                                <Button onClick={this.handleClosePopup.bind(this)}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        align="left"
                                                                        className="aqa-action-button"
                                                                        style={{
                                                                            marginTop: 8,
                                                                            marginLeft: 8,
                                                                            color: '#4cadc4',
                                                                            backgroundColor: 'white',
                                                                            border: '1px solid #4cadc4',
                                                                            fontSize: '0.7rem',
                                                                            padding: '3px'
                                                                        }}>
                                                                    Cancel
                                                                </Button>


                                                            </Grid>

                                                            <Grid item xs={9} sm={9} lg={9} xl={9}>
                                                            </Grid>
                                                            <Grid item xs={2} sm={2} lg={2} xl={2}>
                                                                <Button variant="contained"
                                                                        onClick={this.handleSaveRuleFileClosePopup}
                                                                        color="primary"
                                                                        align="left"
                                                                        className="aqa-action-button"
                                                                        style={{
                                                                            marginTop: 8,
                                                                            color: '#4cadc4',
                                                                            backgroundColor: 'white',
                                                                            border: '1px solid #4cadc4',
                                                                            fontSize: '0.7rem',
                                                                            padding: '3px',
                                                                        }}>
                                                                    Continue
                                                                </Button>


                                                            </Grid>

                                                        </Grid>
                                                    </DialogContent>
                                                </Dialog>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} sm={3} lg={3} xl={3}>
                                        <div>
                                            <Typography className="aqa-text-action-12" variant="inherit"
                                                        style={{
                                                            textAlign: "center",
                                                            padding: "4px",
                                                            margin: "0",
                                                            height:"460px"
                                                        }}>
                                                <Typography className="aqa-text-action-12" variant="inherit"
                                                            style={{
                                                                textAlign: "left",
                                                                padding: "4px",
                                                                marginTop: "0px",
                                                                paddingTop:window.innerWidth>1400?"0px":"100px"
                                                            }}>
                                                    <Typography className="" variant="inherit"
                                                                style={{
                                                                    textAlign: "left",
                                                                    padding: "4px",
                                                                    marginTop: "0px",
                                                                    fontSize:"16px",
                                                                    fontFamily:"Montserrat",
                                                                    paddingBottom:"10px",
                                                                    fontWeight:"bold",
                                                                    color:"rgb(1, 38, 112)"
                                                                }}>
                                                        What is the sequence?
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        1. You choose a file and <span style={{fontSize:"14px"}}><b> Upload</b></span> it here
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        2. We <span style={{fontSize:"14px"}}><b>Check</b></span> the file is valid
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        3. We <span style={{fontSize:"14px"}}><b>Analyse</b></span> the file contents
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        4. We  <span style={{fontSize:"14px"}}><b>Generate Alerts</b></span> for potential issues
                                                    </Typography>
                                                    <Typography className="aqa-text-action-12" variant="inherit" style={{textAlign: "left", padding: "4px", marginTop: "0px"}}>
                                                        5. You <span style={{fontSize:"14px"}}><b>Review</b></span> the alerts
                                                    </Typography>
                                                </Typography>

                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                                :
                                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center"
                                      style={{paddingLeft: "0px", marginTop: 0, paddingBottom: "0px"}}>


                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                        {filteredSources.length < 1 ?
                                            <Typography className="aqa-text-action-12" variant="inherit"
                                                        style={{
                                                            textAlign: "center",
                                                            padding: "4px",
                                                            height: window.innerHeight - 100,
                                                            position: "relative"
                                                        }}>
                                                <Typography className="aqa-text-action-12" variant="inherit"
                                                            style={{
                                                                textAlign: "center",
                                                                padding: "4px",
                                                                margin: "0",
                                                                top: "40%",
                                                                position: "absolute",
                                                                left: "30%",
                                                                right: "30%"
                                                            }}>
                                                    <Typography className="aqa-text-action-12" variant="inherit"
                                                                style={{textAlign: "center", padding: "4px"}}>
                                                        Click on UPLOAD DATA to load a data file
                                                    </Typography>
                                                </Typography>
                                            </Typography> :
                                            <GridList cellHeight={'auto'} cols={window.innerWidth>1400?3:2} style={{
                                                'height': height * 0.90,
                                                'overflow': 'auto',
                                                width: window.innerWidth * 0.99,
                                                margin:"8px 8px 8px 8px"
                                            }}>
                                                {filteredSources.map(item => (
                                                    <GridListTile className={classes.gridTile} key={item.id} style={{}}>
                                                        <AqaSourceGridRow key={item.id} id={item.id} parent={this} dataFromGrid={item}
                                                                          dataFromParent={this} dataFromRoot={this}
                                                                          dataForFolders={this.props.dataForFolders}
                                                                          dataAcquired={this.props.dataAcquired}
                                                                          dataForCheckbox={this.state.resetCheckbox}
                                                                          dataFromMain={this.props.dataFromRoot}/>
                                                    </GridListTile>
                                                ))}

                                            </GridList>
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </div>
                    );
                }
            }
        }
    }
}

AqaSourceView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSourceView);

