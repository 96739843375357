import React from 'react';

//import AqaComponent      from "../aqacomponent/AqaComponent"
import AmberRedCheckers  from "../amberredcheckers/AmberRedCheckers"



const mapperColours = ["orange", "red"]; // Teebo FFS, it's "amber", not "orange"!!!


export default class ColumnScore extends AmberRedCheckers
{

	constructor(props)
	{
		super(props);
		
		props.parent.registerScore(this);
		
		this.serial = 0;

	} //
	

	render()
	{
		return (
			<div style={{border:0}}>
				{
					mapperColours.map
					(
						(c, i) =>
						<span key={++this.serial} style={{marginLeft:"10px",display:"none"}}>
							{" "}
							{
								this.props.active
								?
									<input
										type="checkbox"
										style={{marginLeft:"-2px",marginTop:"0px"}}
										onClick={e => e.stopPropagation()}
										onChange={() => this.selectColor(i)}
										checked={this.state.checked[i]}
										disabled={this.props.score[i] === 0}
									/>
								:
									null
							}
							<span style={{color:c,marginTop:"-6px",display:"none"}}>{this.props.score[i]}</span>
						</span>
					)
				}
			</div>
		);
	} // render

} ////




/*
					mapperColours.map
					(
						(c, i) =>

						this.props.score[i] > 0
						?
							<span key={++this.serial}>
								{" "}
								{this.props.active ? <input type="checkbox" onClick={e => e.stopPropagation()} onChange={() => this.selectColor(i)} checked={this.state.checked[i]} /> : null}
								<span style={{color:c}}>{this.props.score[i]}</span>
							</span>
						:
							null
					)



*/

/*
	
	resetChecks() { this.setState({checked:[false, false]}); }

	selectColor(which)
	{

		// event.stopPropagation();

		const {checked} = this.state;
		checked[which] = !checked[which];
		this.setState({checked});
// onsole.log(AqaComponent.prettifyJson(event.target));

// onsole.log(AqaComponent.prettifyJson(event));
 onsole.log("CS which color: " + which); //  + " status: " + status

		this.props.controller.raSelect(this.props.th, this.state.checked);


	} // selectColor
*/

