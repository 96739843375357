/* Welcome to Aqa Client*/
/* Version: 1 */

import MasterServant       from "../shared/masterservant/MasterServant.js";
import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import AqaFolderView from "./AqaFolderView";
import Typography from "@material-ui/core/Typography";


const styles = theme => ({
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    welcomelabel: {
        fontSize: 18,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 20,
        marginRight: 20,
        color: "#006"
    }
});
class AqaSourceManager extends React.Component {
    constructor(props) {
        super(props);
        // Assign A ref to state for refresh
        this.state = {
            height:window.innerHeight-100,
            isLoaded: false
        };
        this.handleDimensions=this.handleDimensions.bind(this);
    }

    handleSource(source){
        this.props.dataFromRoot.handleSource(source);
    }

    handleSourceFile(source,file){
        this.props.dataFromRoot.handleSourceFile(source,file);
    }

    handleDimensions =(event)=>{
        event.preventDefault();
        this.setState({height:window.innerHeight-100});
    }

    componentDidMount() {
        window.addEventListener("onload",this.handleDimensions);
        window.addEventListener("fullScreenChange",this.handleDimensions);
        window.addEventListener("resize",this.handleDimensions);
        this.setState({isLoaded:true});
    }

    componentWillUnmount() {
        window.removeEventListener("onload",this.handleDimensions);
        window.removeEventListener("fullScreenChange",this.handleDimensions);
        window.removeEventListener("resize",this.handleDimensions);
    }

    render(){
        //const { classes } = this.props;
        const {isLoaded,height} = this.state;
        if(!isLoaded){
            return(
                <div></div>
            );
        }
        else {
            return (

                <div className={"source-detail-masterservant-main"}>
                    {/*<div className={"aqa-main-header"}>
                    <div className={"aqa-main-header-text"}>
                    Source Manager
                    </div>
                </div>*/}
                    <div>
                        <MasterServant>
                            <MasterServant.Master>
                           
                                <div className={"source-detail-master-pane-main"} style={{height: height}}>
                                    <AqaFolderView dataFromRoot={this} dataFromParent={this.props.dataFromParent} dataFromMain={this.props.dataFromRoot}/>
                                </div>
                            </MasterServant.Master>
                            <MasterServant.Servant>
                                <div className={"source-detail-servant-pane-main"}>
                                    <Typography className="aqa-text-action-12" variant="inherit"
                                                style={{textAlign: "center", padding: "4px",height:window.innerHeight-100,position:"relative"}}>
                                        <Typography className="aqa-text-action-12" variant="inherit"
                                                    style={{textAlign: "center", padding: "4px",margin:"0",top:"40%",position:"absolute",left:"30%",right:"30%"}}>
                                            <Typography className="aqa-text-action-12" variant="inherit"
                                                        style={{textAlign: "center", padding: "4px"}}>
                                                Click on UPLOAD DATA to load a data file
                                            </Typography>
                                        </Typography>
                                    </Typography>
                                </div>
                            </MasterServant.Servant>
                        </MasterServant>
                    </div>
                </div>
            )
        }
    }
}
AqaSourceManager.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AqaSourceManager);
